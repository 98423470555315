import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Container, Divider, Link, Typography } from '@material-ui/core'

import ForgotPasswordForm from './components/Form'

import { routes } from 'Routes'
import useStyles from './styles'

const ForgotPassword = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Container maxWidth="xs" fullWidth>
        <Typography variant="h3" color="textPrimary">
          Olá!
        </Typography>
        <Typography variant="subtitle1">
          Digite seu e-mail para recuperar a senha
        </Typography>
        <Box mt={3}>
          <ForgotPasswordForm />
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        <Link
          component={RouterLink}
          to={routes.root}
          variant="body2"
          color="textSecondary"
        >
          Voltar
        </Link>
      </Container>
    </Box>
  )
}

export default ForgotPassword
