const PENDING_STATUS = 'pending'
const ONGOING_STATUS = 'ongoing'
const OVERDUE_STATUS = 'overdue'
const FINALIZED_STATUS = 'finalized'

const ALL_ACTIVITIES_VALUE = 'all'
const ACTIVITIES_PENDING_VALUE = PENDING_STATUS
const ACTIVITIES_IN_PROGRESS_VALUE = ONGOING_STATUS
const ACTIVITIES_DELAYED_VALUE = OVERDUE_STATUS
const ACTIVITIES_CONCLUDED_VALUE = FINALIZED_STATUS

const ACTIVITIES_TAB = [
  { value: ALL_ACTIVITIES_VALUE, label: 'Todas' },
  { value: ACTIVITIES_PENDING_VALUE, label: 'Pendentes' },
  { value: ACTIVITIES_IN_PROGRESS_VALUE, label: 'Em Andamento' },
  { value: ACTIVITIES_DELAYED_VALUE, label: 'Atrasadas' },
  { value: ACTIVITIES_CONCLUDED_VALUE, label: 'Finalizadas' },
]

export default {
  ACTIVITIES_CONCLUDED_VALUE,
  ACTIVITIES_DELAYED_VALUE,
  ACTIVITIES_IN_PROGRESS_VALUE,
  ACTIVITIES_PENDING_VALUE,
  ACTIVITIES_TAB,
  ALL_ACTIVITIES_VALUE,
  FINALIZED_STATUS,
  ONGOING_STATUS,
  OVERDUE_STATUS,
  PENDING_STATUS,
}
