import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { ToggleButton } from 'components'
import {
  Collapse as CollapseMaterial,
  makeStyles,
  IconButton,
  Box,
} from '@material-ui/core'
import { Trash as TrashIcon } from 'react-feather'

import styles from './styles'

const useStyles = makeStyles(styles)

const Collapse = ({
  children,
  title,
  visibilityChildren,
  mx,
  defaultOpen,
  onRemove,
  notBackgroundChildren,
}) => {
  const [expanded, setExpanded] = useState(defaultOpen)
  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="column" className={classes.root} mx={mx}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        mx={2}
        my={!visibilityChildren && !onRemove ? 2 : 1}
      >
        {title}
        <Box className={classes.boxButton} mr={2}>
          {visibilityChildren && (
            <ToggleButton
              size={18}
              expanded={expanded}
              onClick={() => setExpanded(!expanded)}
            />
          )}
          {!visibilityChildren && onRemove && (
            <IconButton onClick={onRemove}>
              <TrashIcon size={18} className={classes.iconRemove} />
            </IconButton>
          )}
        </Box>
      </Box>
      <CollapseMaterial
        className={clsx(classes.collapse, {
          [classes.collapseLight]: notBackgroundChildren,
        })}
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </CollapseMaterial>
    </Box>
  )
}

Collapse.propTypes = {
  title: PropTypes.node,
  visibilityChildren: PropTypes.bool,
  mx: PropTypes.number,
  defaultOpen: PropTypes.bool,
  onRemove: PropTypes.func,
  notBackgroundChildren: PropTypes.bool,
}

Collapse.defaultProps = {
  visibilityChildren: true,
  mx: 1,
  notBackgroundChildren: false,
  defaultOpen: false,
}

export default Collapse
