import React from 'react'
import PropTypes from 'prop-types'

import { Box, Card, CardContent, Typography } from '@material-ui/core'

import useStyles from './styles'
import { SolutionIcon } from '..'

const WhatIsSection = ({ icons, sectionTitle, description }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{sectionTitle}</Typography>
      {description && (
        <Typography className={classes.description}>{description}</Typography>
      )}
      <Box className={classes.cardsContainer}>
        {icons.map((icon, key) => (
          <Card className={classes.card} key={key}>
            <CardContent>
              <SolutionIcon
                title={icon.title}
                altText={icon.altText}
                icon={icon.src}
                withoutDivider
                imgHeight={120}
              />
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  )
}

WhatIsSection.propTypes = {
  icons: PropTypes.array.isRequired,
  sectionTitle: PropTypes.string,
  description: PropTypes.string,
}

WhatIsSection.defaultProps = {
  sectionTitle: '',
  description: '',
}

export default WhatIsSection
