import { makeStyles } from '@material-ui/core'

import exclamation from 'images/exclamacao.png'

const styles = makeStyles((theme) => ({
  textBox: {
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${exclamation})`,
      backgroundPositionX: '',
      backgroundPosition: '84%',
      backgroundRepeat: 'no-repeat',
    },
  },
  itemsBox: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export default styles
