import permissions from '../permissions'

const NON_COMPLIANCE_REPORT_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.NON_COMPLIANCE_REPORT.EDIT,
  },
  {
    name: 'Gerenciar Planos de Ação',
    tag: permissions.NON_COMPLIANCE_REPORT.HANDLE_ACTIONS,
  },
  {
    name: 'Gerenciar Riscos Específicos',
    tag: permissions.NON_COMPLIANCE_REPORT.HANDLE_SPECIFIC_RISKS,
  },
  {
    name: 'Listar todos de Processamento de Dados',
    tag: permissions.NON_COMPLIANCE_REPORT.LIST_DATA_PROCESSES,
  },
  {
    name: 'Listar todos de Processamento de Dados vinculados ao usuário',
    tag: permissions.NON_COMPLIANCE_REPORT.LIST_LINKED_DATA_PROCESS,
  },
  {
    name: 'Listar todos de Questionário',
    tag: permissions.NON_COMPLIANCE_REPORT.LIST_QUESTIONNAIRE,
  },
  {
    name: 'Listar de Questionário vinculados ao usuário',
    tag: permissions.NON_COMPLIANCE_REPORT.LIST_LINKED_QUESTIONNAIRE,
  },
  {
    name: 'Listar todos de Incidentes',
    tag: permissions.NON_COMPLIANCE_REPORT.LIST_INCIDENT,
  },
  {
    name: 'Listar todos os RMCs do qual o usuário é responsável',
    tag: permissions.NON_COMPLIANCE_REPORT.LIST_RESPONSIBLE,
  },
]

export default NON_COMPLIANCE_REPORT_LIST
