import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Box, IconButton, Slider } from '@material-ui/core'
import {
  MinusCircle as MinusCircleIcon,
  PlusCircle as PlusCircleIcon,
} from 'react-feather'
import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'

const FragilitySlider = ({ defaultValue, setControllerValue, fieldName }) => {
  const classes = useStyles()
  const { convertFragilityValue, idToLabel } = helpers.fragilities

  const [value, setValue] = useState(
    convertFragilityValue(defaultValue) ||
      convertFragilityValue(constants.fragilities.UNDEFINED_ID),
  )

  const increaseFragility = () => {
    if (value >= 0 && value < 4) {
      handleChange(null, value + 1)
    }
  }

  const decreaseFragility = () => {
    if (value > 0 && value <= 5) {
      handleChange(null, value - 1)
    }
  }

  const handleChange = (e, newValue) => {
    setControllerValue(fieldName, convertFragilityValue(newValue, false))
    setValue(newValue)
    return e
  }

  const adjustedMarks = constants.fragilities.FRAGILITY_IDS.map((id) => ({
    value: convertFragilityValue(id),
  })).sort((a, b) => {
    return a.value - b.value
  })

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <IconButton onClick={decreaseFragility} className={classes.iconButton}>
        <MinusCircleIcon />
      </IconButton>
      <Box width="100%" mx={2} mt={0.3}>
        <Slider
          defaultValue={
            convertFragilityValue(defaultValue) ||
            convertFragilityValue(constants.fragilities.UNDEFINED_ID)
          }
          value={value}
          min={0}
          max={5}
          onChange={handleChange}
          classes={{
            root: classes.root,
            valueLabel: classes.valueLabel,
            track: classes.track,
            rail: classes.rail,
            mark: classes.mark,
            markActive: classes.markActive,
            thumb: clsx(classes.thumb, {
              [classes.thumbLowFragility]:
                value === constants.fragilities.LOW_ID,
              [classes.thumbMediumFragility]:
                value === constants.fragilities.MEDIUM_ID,
              [classes.thumbHighFragility]:
                value === constants.fragilities.HIGH_ID,
              [classes.thumbSevereFragility]:
                value === constants.fragilities.SEVERE_ID,
              [classes.thumbUndefinedFragility]:
                value ===
                convertFragilityValue(constants.fragilities.UNDEFINED_ID),
            }),
          }}
          valueLabelDisplay="on"
          valueLabelFormat={(value) =>
            idToLabel(convertFragilityValue(value, false))
          }
          marks={adjustedMarks}
        />
      </Box>
      <IconButton onClick={increaseFragility} className={classes.iconButton}>
        <PlusCircleIcon />
      </IconButton>
    </Box>
  )
}

FragilitySlider.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setControllerValue: PropTypes.func,
  fieldName: PropTypes.string,
}

export default FragilitySlider
