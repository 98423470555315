const styles = (theme) => ({
  root: {
    cursor: 'pointer',
  },
  boxAlertIncidents: {
    backgroundColor: theme.palette.primary.main,
    height: '41px',
    width: '41px',
    borderRadius: '40px',
  },
  chipIncidents: {
    minWidth: '50px',
  },
})

export default styles
