import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  statusLabelAuthorized: {
    color: theme.palette.statusLabel.success.primary,
    backgroundColor: theme.palette.statusLabel.success.secondary,
  },
  statusLabelRevoked: {
    color: theme.palette.statusLabel.error.primary,
    backgroundColor: theme.palette.statusLabel.error.secondary,
  },
  requesterBoxContainer: {
    border: `1px solid ${theme.palette.divider}`,
    marginBlock: theme.spacing(2),
  },
  requesterHeader: {
    display: 'flex',
    backgroundColor: theme.palette.lightGray,
    padding: theme.spacing(1),
    justifyContent: 'space-between',
  },
  userIconBox: {
    height: 39,
    width: 39,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    paddingInline: theme.spacing(1),
    marginInline: theme.spacing(1),
    color: theme.palette.white,
  },
  contentRequesterLine: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(1),
    minHeight: theme.spacing(10),
  },
  lineObservation: {
    backgroundColor: theme.palette.white,
  },
  buttonLink: {
    textTransform: 'none',
  },
}))

export default useStyles
