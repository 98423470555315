const dataType = (type) => {
  const options = {
    DataTreatment: 'Dados Compartilhado',
    CollectedField: 'Dados tratado',
    LifeCicle: 'Salvaguarda e Ciclo de Vida',
  }

  return options[type] || ''
}

export default dataType
