import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { MiniDrawer, SuspendedDrawer } from './components'

import useDrawer from 'hooks/useDrawer'

const NavBar = ({ customThemeIsActive }) => {
  const {
    openedDrawer,
    handleCloseDrawer,
    handleOpenFullDrawer,
    handleCloseFullDrawer,
  } = useDrawer()
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  })
  const isTablet = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  })
  const isLaptop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  useEffect(() => {
    if (isMobile) {
      handleOpenFullDrawer()
    } else {
      handleCloseFullDrawer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  useEffect(() => {
    if (openedDrawer) handleCloseDrawer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLaptop])

  if (isMobile || isTablet)
    return <SuspendedDrawer customThemeIsActive={customThemeIsActive} />

  return <MiniDrawer />
}

NavBar.propTypes = {
  customThemeIsActive: PropTypes.func,
}

export default NavBar
