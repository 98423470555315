import React from 'react'

import { TextField, Grid, Box } from '@material-ui/core'

import { Controller } from 'react-hook-form'

import { Label } from 'components'

const DescriptionIncident = ({ errors, control, isDpo }) => {
  return (
    <>
      <Grid item xs={12}>
        <Box mt={2}>
          <Label title="Explicar resumidamente, por que o incidente ocorreu (identifique a causa raiz, se conhecida):">
            <Controller
              as={
                <TextField
                  type="text"
                  minRows={5}
                  multiline
                  color="primary"
                  variant="outlined"
                  error={!!errors.descriptionRootIncident}
                  helperText={errors?.descriptionRootIncident?.message}
                  fullWidth
                />
              }
              control={control}
              disabled={isDpo()}
              name="descriptionRootIncident"
              mode="onBlur"
            />
          </Label>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={2}>
          <Label title="Quais medidas foram adotadas para corrigir as causas do incidente?">
            <Controller
              as={
                <TextField
                  type="text"
                  minRows={5}
                  multiline
                  color="primary"
                  variant="outlined"
                  error={!!errors.descriptionMeasures}
                  helperText={errors?.descriptionMeasures?.message}
                  fullWidth
                />
              }
              control={control}
              disabled={isDpo()}
              name="descriptionMeasures"
              mode="onBlur"
            />
          </Label>
        </Box>
      </Grid>
    </>
  )
}

export default DescriptionIncident
