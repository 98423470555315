import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  rootBox: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
    },
  },
  rootTab: {
    cursor: 'pointer',
    minWidth: '10vw',
    height: theme.spacing(4.5),
    margin: theme.spacing(1),
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.subscription.primary.main,
    backgroundColor: theme.palette.white,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      minWidth: 'fit-content',
    },
  },
  selectedTab: {
    backgroundColor: theme.palette.subscription.primary.main,
    color: theme.palette.white,
  },
  tabInactive: {
    color: theme.palette.blueDark,
    fontWeight: 500,
    paddingTop: theme.spacing(1),
  },
  tabActive: {
    color: theme.palette.blueDark,
    fontWeight: 500,
    paddingTop: theme.spacing(1),
  },
  icon: {
    fontSize: '14px',
    marginRight: theme.spacing(2),
  },
  subtitle: {
    color: theme.palette.blueDark,
    fontWeight: 500,
    paddingTop: theme.spacing(1),
  },
  label: {
    fontSize: 12,
  },
}))

export default styles
