import React, { useEffect, useState } from 'react'
import { isBoolean } from 'lodash'
import {
  Controller,
  FormContext,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'

import { Label, Select as SelectComponent, SelectCountry } from 'components'
import SharedSoftwareForm from '../../../SharedSoftwareForm'
import DataProcessTemplateForm from 'views/DataProcesses/components/DataProcessTemplateForm'

import useSelectCountry from 'hooks/useSelectCountry'
import useAuth from 'hooks/useAuth'
import useDataProcessTemplateQuestion from 'hooks/useDataProcessTemplateQuestion'
import useDataProcess from 'hooks/useDataProcess'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import constants from 'constants/index'
import styles from './styles'

const useStyles = makeStyles(styles)

const NewForm = ({ onSubmit = () => {}, onEvent = () => {}, ...props }) => {
  const { company } = useAuth()
  const [isLoading, setLoading] = useState(false)
  const [dataTreatment, setDataTreatment] = useState(props?.dataTreatment)

  const snackbar = useSnackbar()
  const classes = useStyles()
  const {
    triggerValidation: triggerTemplateValidation,
    getValues: getTemplateValues,
  } = useDataProcessTemplateQuestion()
  const { shareProcesses, internalAllocationModes } = useDataProcess()

  const isRequiredField =
    company?.kind !== constants.userCompanies.KIND_TEMPLATE

  const {
    selectedCountries,
    setSelectedCountries,
    manySelectedCountries,
    setManySelectedCountries,
    sortNewNamesToObject,
  } = useSelectCountry()

  const fieldTreatment = (field) => {
    if (isRequiredField && !isBoolean(field)) return 'false'
    if (isRequiredField && isBoolean(field)) return field?.toString()
    return field?.toString()
  }

  const formMethods = useForm({
    defaultValues: {
      shareProcessId: dataTreatment?.shareProcess?.id || '',
      shareTypeId: dataTreatment?.shareType?.id || '',
      description: dataTreatment?.description || '',
      controllMechanism: dataTreatment?.controllMechanism || '',
      dataTreatmentId: dataTreatment?.id || '',
      sharedInAnotherSoftware: fieldTreatment(
        dataTreatment?.sharedInAnotherSoftware,
      ),
      internationalTransferDataTreatment: fieldTreatment(
        dataTreatment?.haveInternationalTransfer,
      ),
    },
  })

  const {
    errors,
    watch,
    control,
    setValue,
    setError,
    triggerValidation: triggerFormValidation,
    getValues,
  } = formMethods

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sharedSoftwares',
    serverAllocation: '',
    keyName: 'key',
  })

  const setInternationalTransfersCountries = (fields) => {
    let newObject = Object.assign({}, manySelectedCountries)

    for (let index in fields) {
      const sharedSoftware = fields[index]
      newObject[`sharedSoftwares-${index}`] =
        sharedSoftware.internationalTransfers
    }

    setManySelectedCountries(newObject)
  }

  const verifyInternationalTransfer = (data) => {
    if (
      data.internationalTransferDataTreatment === 'true' &&
      selectedCountries <= 0
    ) {
      setError(
        'internationalTransferDataTreatment',
        'required',
        'Escolha no mínimo um país caso a resposta seja "Sim".',
      )
      return false
    }

    return true
  }

  useEffect(() => {
    if (getValues('internationalTransferDataTreatment') === 'false') {
      setSelectedCountries([])
    }

    // eslint-disable-next-line
  }, [getValues('internationalTransferDataTreatment')])

  useEffect(() => {
    if (getValues('sharedInAnotherSoftware') === 'true') {
      if (!dataTreatment?.sharedSoftwares?.length && !fields.length) {
        return append({ id: '', name: '', softwareAllocationType: '' })
      }

      if (!fields.length) {
        setInternationalTransfersCountries(fields)
        return append(dataTreatment?.sharedSoftwares)
      }
    }

    if (getValues('sharedInAnotherSoftware') === 'false') {
      setManySelectedCountries({})
      remove()
    }

    // eslint-disable-next-line
  }, [dataTreatment, getValues('sharedInAnotherSoftware')])

  const addSharedSoftware = () => {
    append({ id: '', name: '', softwareAllocationType: '' })
  }

  const handleRemoveCountrySharedSoftwares = async (sharedSoftware, index) => {
    let newManySelectedCountries = manySelectedCountries
    delete newManySelectedCountries[`sharedSoftwares-${index}`]

    newManySelectedCountries = sortNewNamesToObject(newManySelectedCountries)
    setManySelectedCountries(newManySelectedCountries)

    for (let index in sharedSoftware?.internationalTransfers) {
      const internationalTransfer =
        sharedSoftware?.internationalTransfers[index]

      await service.dponet.sharedSoftwares.internationalTransferDestroy({
        dataProcessId: dataTreatment?.dataProcessId,
        dataTreatmentId: dataTreatment?.id,
        sharedSoftwareId: sharedSoftware?.id,
        internationalTransferId: internationalTransfer?.id,
      })
    }
  }

  const removeSharedSoftware = async (index, sharedSoftware) => {
    setLoading(true)

    if (!!sharedSoftware?.id) {
      try {
        await handleRemoveCountrySharedSoftwares(sharedSoftware, index)

        await service.dponet.sharedSoftwares.destroy({
          dataProcessId: dataTreatment?.dataProcessId,
          dataTreatmentId: dataTreatment?.id,
          sharedSoftwareId: sharedSoftware.id,
        })

        remove(index)

        if (fields.length === 1) {
          await service.dponet.dataTreatments.put({
            dataProcessId: dataTreatment?.dataProcessId,
            dataTreatmentId: dataTreatment?.id,
            dataTreatment: { sharedInAnotherSoftware: false },
          })
        }

        let response = await service.dponet.dataTreatments.get({
          dataProcessId: dataTreatment?.dataProcessId,
          dataTreatmentId: dataTreatment?.id,
        })

        setDataTreatment(response?.data?.dataTreatment)
        setLoading(false)
        snackbar.open({
          message: 'Meio de compartilhamento removido com sucesso!',
          variant: 'success',
        })
        setValue(
          'sharedInAnotherSoftware',
          response?.data?.dataTreatment?.sharedInAnotherSoftware?.toString() ||
            'false',
        )
      } catch {
        setLoading(false)
        snackbar.open({
          message:
            'Ocorreu um erro ao tentar remover o meio de compartilhamento! Por favor, tente novamente mais tarde.',
          variant: 'error',
        })
      }
    } else {
      remove(index)

      let newManySelectedCountries = manySelectedCountries
      delete newManySelectedCountries[`sharedSoftwares-${index}`]

      newManySelectedCountries = sortNewNamesToObject(newManySelectedCountries)
      setManySelectedCountries(newManySelectedCountries)

      if (fields.length === 1) {
        setValue('sharedInAnotherSoftware', 'false')
      }

      setLoading(false)
      snackbar.open({
        message: 'Meio de compartilhamento removido com sucesso!',
        variant: 'success',
      })
    }
  }

  const handleRemoveCountry = async (country) => {
    const internationalTransferCreated =
      dataTreatment?.internationalTransfers?.find(
        (internationalTransfer) =>
          internationalTransfer.country === country.country,
      )

    if (
      dataTreatment?.id &&
      dataTreatment?.dataProcessId &&
      internationalTransferCreated
    ) {
      await service.dponet.dataTreatments.internationalTransferDestroy({
        dataProcessId: dataTreatment?.dataProcessId,
        dataTreatmentId: dataTreatment?.id,
        internationalTransferId: internationalTransferCreated.id,
      })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const templateValidation = await triggerTemplateValidation()
    const dataCollectedValidation = await triggerValidation()

    if (templateValidation && dataCollectedValidation) {
      onSubmit(getValues(), getTemplateValues())
    }
  }

  const triggerValidation = async () => {
    const dataTreatmentValidation = await triggerFormValidation()
    const internationalTransferValidation = verifyInternationalTransfer(
      getValues(),
    )

    return dataTreatmentValidation && internationalTransferValidation
  }

  return (
    <>
      {isLoading ? (
        <Box
          id="loadBox"
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <FormContext {...formMethods}>
          <form onSubmit={handleSubmit} id="data-treatment-form">
            <Grid container spacing={1}>
              <Grid item xl={12} lg={12} md={12} xs={12}>
                <Controller
                  as={<></>}
                  name="dataTreatmentId"
                  control={control}
                />
                <Label
                  title="Com quem o dado é compartilhado?"
                  description="É o nome do terceiro ou do departamento da organização a quem o registro é compartilhado"
                  item
                >
                  <Controller
                    as={
                      <SelectComponent
                        items={shareProcesses}
                        anotherOption={true}
                        error={!!errors.shareProcessId}
                        helperText={
                          errors?.shareProcessId && 'Preencha este campo'
                        }
                      />
                    }
                    control={control}
                    rules={{ required: true }}
                    name="shareProcessId"
                    mode="onBlur"
                  />
                </Label>
                {watch('shareProcessId') === 'another' && (
                  <Box mr={2} mt={1} width="100%">
                    <Label
                      description="Outro: com quem o dado é compartilhado?"
                      item
                    >
                      <Controller
                        as={
                          <TextField
                            color="primary"
                            variant="outlined"
                            fullWidth
                            error={!!errors.anotherShareProcess}
                            helperText={
                              errors?.anotherShareProcess &&
                              'Preencha este campo'
                            }
                          />
                        }
                        control={control}
                        rules={{ required: true }}
                        name="anotherShareProcess"
                        mode="onBlur"
                      />
                    </Label>
                  </Box>
                )}
              </Grid>
              <Grid item xl={12} lg={12} md={12} xs={12}>
                <Label
                  title="Por quê o dado é compartilhado?"
                  description="É a finalidade do compartilhamento do registro com o terceiro ou com o departamento da organização"
                  item
                >
                  <Controller
                    as={
                      <TextField
                        multiline
                        rows={3}
                        color="primary"
                        variant="outlined"
                        error={!!errors.description}
                        helperText={
                          errors?.description && 'Preencha este campo'
                        }
                        fullWidth
                      />
                    }
                    control={control}
                    rules={{ required: true }}
                    name="description"
                    mode="onBlur"
                  />
                </Label>
              </Grid>
              <Grid item xs={12}>
                <Label
                  title="Como o dado é compartilhado?"
                  description="É o mecanismo de compartilhamento do registro, como via pasta, e-mail, pen drive ou sistema de gestão"
                  item
                >
                  <Controller
                    as={
                      <TextField
                        multiline
                        rows={3}
                        color="primary"
                        variant="outlined"
                        error={!!errors.controllMechanism}
                        helperText={
                          errors?.controllMechanism && 'Preencha este campo'
                        }
                        fullWidth
                      />
                    }
                    control={control}
                    rules={{ required: true }}
                    name="controllMechanism"
                    mode="onBlur"
                  />
                </Label>
              </Grid>
              <Grid item xs={12}>
                <Label
                  title="Tipo de compartilhamento"
                  description="Tipo de compartilhamento"
                  item
                >
                  <Controller
                    as={
                      <SelectComponent
                        items={internalAllocationModes}
                        error={!!errors.shareTypeId}
                        fullWidth
                        helperText={
                          errors?.shareTypeId && 'Preencha este campo'
                        }
                      />
                    }
                    control={control}
                    rules={{ required: true }}
                    name="shareTypeId"
                    mode="onBlur"
                  />
                </Label>
              </Grid>
              <Grid item xs={12}>
                <Label
                  title="Este compartilhamento é uma transferência internacional?"
                  description='Caso a resposta for "Sim" selecione os países que é feito o compartilhamento.'
                  item
                >
                  <Controller
                    as={
                      <TextField
                        select
                        color="primary"
                        variant="outlined"
                        error={!!errors.internationalTransferDataTreatment}
                        helperText={
                          errors?.internationalTransferDataTreatment?.message
                        }
                        fullWidth
                      >
                        <MenuItem value="false">
                          <Typography>Não</Typography>
                        </MenuItem>
                        <MenuItem value="true">
                          <Typography>Sim</Typography>
                        </MenuItem>
                      </TextField>
                    }
                    control={control}
                    rules={{ required: isRequiredField }}
                    name="internationalTransferDataTreatment"
                    mode="onBlur"
                  />
                </Label>
              </Grid>
              {watch('internationalTransferDataTreatment') === 'true' && (
                <Grid item xs={12}>
                  <Box
                    className={classes.internationalTransferField}
                    p={2}
                    pb={3}
                  >
                    <SelectCountry
                      withTable
                      label="Qual o país destinatário?"
                      handleRemoveCountry={handleRemoveCountry}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Label
                  title="Para realizar este compartilhamento, você utiliza alguma plataforma, software ou aplicativo?"
                  description='Caso a resposta for "Sim" liste os meios de compartilhamentos de dados'
                  item
                >
                  <Controller
                    as={
                      <TextField
                        select
                        color="primary"
                        variant="outlined"
                        error={!!errors.sharedInAnotherSoftware}
                        helperText={
                          errors?.sharedInAnotherSoftware &&
                          'Preencha este campo'
                        }
                        fullWidth
                      >
                        <MenuItem value="false">
                          <Typography>Não</Typography>
                        </MenuItem>
                        <MenuItem value="true">
                          <Typography>Sim</Typography>
                        </MenuItem>
                      </TextField>
                    }
                    control={control}
                    rules={{ required: isRequiredField }}
                    name="sharedInAnotherSoftware"
                    mode="onBlur"
                  />
                </Label>
              </Grid>
              {watch('sharedInAnotherSoftware') === 'true' && (
                <Grid item xs={12}>
                  <Box mt={3} mr={2}>
                    <Divider />
                    {fields?.map((item, index) => (
                      <SharedSoftwareForm
                        sharedSoftware={item}
                        key={item.key}
                        index={index}
                        removeSharedSoftware={removeSharedSoftware}
                      />
                    ))}
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                      <Button
                        variant="outlined"
                        onClick={() => addSharedSoftware()}
                      >
                        Adicionar Meio de Compartilhamento
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <DataProcessTemplateForm step="data_collected" />
              </Grid>
            </Grid>
          </form>
        </FormContext>
      )}
    </>
  )
}

export default NewForm
