import { useContext } from 'react'

import ConsentContext from 'contexts/ConsentContext'

const useConsent = () => {
  const context = useContext(ConsentContext)

  if (context === undefined) {
    throw new Error('useConsent must be used within a ConsentContext.Provider')
  }

  return {
    activeStep: context.activeStep,
    lastActiveStep: context.lastActiveStep,
    setLastActiveStep: context.setLastActiveStep,
    setActiveStep: context.setActiveStep,
    toNextStep: context.toNextStep,
    setToNextStep: context.setToNextStep,
    reloadOptions: context.reloadOptions,
    counter: context.counter,
    dataConsentForm: context.dataConsentForm,
    setDataConsentForm: context.setDataConsentForm,
    toStep: context.toStep,
    nextStep: context.nextStep,
    backStep: context.backStep,
    nextDisabled: context.nextDisabled,
    setNextDisabled: context.setNextDisabled,
  }
}

export default useConsent
