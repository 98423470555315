import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import useStyles from './styles'
import PropTypes from 'prop-types'

const DescriptionPaper = ({
  id = '',
  description,
  children,
  isDocument = false,
}) => {
  const classes = useStyles()
  return (
    <Grid id={id} container className={classes.root}>
      <Grid item xs={12} sm={6} lg={8}>
        <Typography variant="caption">{description}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={4} className={classes.children}>
        {children}
      </Grid>
      {isDocument && (
        <Grid item xs={12}>
          <Typography variant="caption">
            Arquivos suportados: jpg, jpeg, png, pdf, docx.
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

DescriptionPaper.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string.isRequired,
  children: PropTypes.node,
  isDocument: PropTypes.bool,
}

export default DescriptionPaper
