import { colors } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    border: `1px solid ${theme.palette.navigator.borderColor}`,
    borderRadius: '4px',
    margin: theme.spacing(4, 2, 2, 2),
    display: 'flex',
    flexDirection: 'column',
  },
  helpCentralButton: {
    backgroundColor: colors.green[500],
    color: colors.grey[50],
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: colors.green[500],
      opacity: 0.8,
    },
  },
  helpCentralButtoIcon: {
    color: colors.grey[50],
  },
  accountabilityStatementButton: {
    color: colors.grey[50],
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  link: {
    color: theme.palette.navigator.text.secondary,
    cursor: 'pointer',
    marginTop: theme.spacing(1),
  },
})

export default styles
