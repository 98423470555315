import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { PlusCircle } from 'react-feather'
import { makeStyles, Grid, Typography, Button, Box } from '@material-ui/core'

import DocumentsTable from './components/DocumentsTable'
import { LoadingBox, DialogConfirmAddDocument } from 'components'
import { DescriptionPaper } from '../../'

import useDataProcess from 'hooks/useDataProcess'
import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'

import constants from 'constants/index'
import * as service from 'service'
import styles from '../styles'

const useStyles = makeStyles(styles)

const Documents = ({ description }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const [dialogConfirmAddDocument, setDialogConfirmAddDocument] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const { data, toNextStep, setToNextStep, nextStep } = useDataProcess()
  const { dataProcess } = data

  useEffect(() => {
    if (!dialogConfirmAddDocument) {
      document.getElementById('documents').value = ''
    }
  }, [dialogConfirmAddDocument])

  const { response, isLoading, refresh } = useFetch(
    service.dponet.dataProcesses.get,
    {
      dataProcessId: dataProcess?.id,
    },
  )

  const dataProcessResponse = response?.data?.dataProcess

  const { control } = useForm()

  useEffect(() => {
    if (toNextStep) {
      if (!isLoading) {
        nextStep()
        setToNextStep(false)
      }
    }
    // eslint-disable-next-line
  }, [nextStep])

  const onChangeCreate = async () => {
    const documentInput = document.getElementById('documents')
    const files = documentInput.files

    const { allowed, forbidedDocuments } =
      helpers.dataProcess.allowDocumentUpload(files)

    if (files.length === 0) return

    if (!allowed) {
      return snackbar.open({
        message:
          'Ocorreu um erro ao adicionar o(s) documento(s), verifique se a extensão do(s) arquivo(s) é compatível: ' +
          forbidedDocuments?.map((forbidedDocument) => ' ' + forbidedDocument),
        variant: 'error',
      })
    }

    try {
      setLoading(true)
      setDialogConfirmAddDocument(false)
      await service.dponet.documents.addDocument({
        dataProcessId: dataProcess.id,
        files: files,
      })
      setLoading(false)
      documentInput.value = ''
      refresh()
      snackbar.open({
        message: 'Documento(s) adicionado(s) com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: 'Ocorreu um erro ao adicionar o(s) documento(s)',
        variant: 'error',
      })
    }
  }

  return (
    <>
      <DescriptionPaper
        id={constants.dataProcess.DATA_PROCESS_NEW_DRIVER_STEP_10}
        description={description}
        isDocument
      >
        <form id="documents-form">
          <Controller
            as={
              <Button
                variant="outlined"
                startIcon={<PlusCircle size={20} />}
                htmlFor="documents"
                component="label"
              >
                <Box mr={1}>
                  <Typography>Selecionar documento(s)</Typography>
                </Box>
                <input
                  type="file"
                  multiple
                  id="documents"
                  style={{ display: 'none' }}
                />
              </Button>
            }
            control={control}
            onChange={() => setDialogConfirmAddDocument(true)}
            name="documents"
          />
        </form>
      </DescriptionPaper>
      <Grid container className={classes.root}>
        <Grid xs={12} item>
          {isLoading || loading ? (
            <LoadingBox />
          ) : (
            <>
              <DocumentsTable
                dataProcessId={dataProcess?.id}
                onEvent={refresh}
                documents={dataProcessResponse?.documents}
              />
              <DialogConfirmAddDocument
                onChangeCreate={onChangeCreate}
                open={dialogConfirmAddDocument}
                setOpen={setDialogConfirmAddDocument}
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default Documents
