import { colors } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.navigator.borderColor}`,
  },
  icon: {
    color: colors.grey[50],
  },
})

export default styles
