import { isEmpty } from 'lodash'

import components from '../../components'

import helpers from 'helpers'
import palette from 'theme/palette'

import { WIDTH as WIDTHPdf, GAP, PADDING } from '../../constants'

const fragilities = (pdf, date, customStyles, fragilitiesData) => {
  const WIDTH = WIDTHPdf(pdf) - PADDING * 2

  const firstTableColsStyle = {
    0: {
      cellWidth: WIDTH,
      halign: 'left',
    },
  }

  const customHeader = {
    textColor: palette.white,
    font: 'Roboto-Bold',
    fillColor: '#707990',
    cellPadding: 6,
  }

  const securityMeasuresHeaderStyles = {
    textColor: palette.primary.main,
    font: 'Roboto-Bold',
    fillColor: '#EEE',
    cellPadding: 6,
  }

  const mountFragilityRow = (fragility) => [
    ['Impacto', helpers.fragilities.idToLabel(fragility?.impactId)],
    ['Probabilidade', helpers.fragilities.idToLabel(fragility?.probabilityId)],
    ['Nível', helpers.fragilities.idToLabel(fragility?.fragilityId)],
  ]

  const mountFragilityMeasureRow = (fragilityMeasure) => [
    [`#${fragilityMeasure.id} ${fragilityMeasure.title}`],
  ]

  const mountFragilityEntityRow = (fragilityEntity) => [
    [
      `${fragilityEntity.name} - ${
        helpers.fragilityEntities.labels[fragilityEntity.entityType]
      }`,
    ],
  ]

  pdf.addPage()
  components.numberPage(pdf, palette.primary.main, true)
  components.lineWithDate(pdf, date)
  components.subtitle(pdf, 'Ameaças', GAP)

  let pageNumber = pdf.getCurrentPageInfo().pageNumber

  fragilitiesData.forEach((fragility, index) => {
    let startY = index === 0 ? GAP * 1.25 : pdf.autoTable.previous.finalY + 24

    components.table(
      pdf,
      [[fragility.name]],
      [[fragility.description]],
      startY,
      0.5,
      PADDING,
      true,
      firstTableColsStyle,
    )

    components.table(
      pdf,
      null,
      mountFragilityRow(fragility),
      pdf.autoTable.previous.finalY,
      0.5,
      PADDING,
      true,
    )

    components.table(
      pdf,
      [['Medidas de Segurança']],
      null,
      pdf.autoTable.previous.finalY,
      0.5,
      PADDING,
      true,
      firstTableColsStyle,
      customHeader,
    )

    if (isEmpty(fragility.companyFragilityMeasures)) {
      components.table(
        pdf,
        null,
        [['Não há nenhuma medida de segurança vinculada à esta ameaça.']],
        pdf.autoTable.previous.finalY,
        0.5,
        PADDING,
        true,
        firstTableColsStyle,
      )
    } else {
      fragility.companyFragilityMeasures.forEach((fragilityMeasure) => {
        components.table(
          pdf,
          [[fragilityMeasure.questionnaireKind]],
          mountFragilityMeasureRow(fragilityMeasure),
          pdf.autoTable.previous.finalY,
          0.5,
          PADDING,
          true,
          firstTableColsStyle,
          securityMeasuresHeaderStyles,
        )
      })
    }

    components.table(
      pdf,
      [['Local de ocorrência']],
      null,
      pdf.autoTable.previous.finalY,
      0.5,
      PADDING,
      true,
      firstTableColsStyle,
      customHeader,
    )

    if (isEmpty(fragility.fragilityEntities)) {
      components.table(
        pdf,
        null,
        [['Não há nenhum local de ocorrência vinculado à esta ameaça.']],
        pdf.autoTable.previous.finalY,
        0.5,
        PADDING,
        true,
        firstTableColsStyle,
      )
    } else {
      fragility.fragilityEntities.forEach((fragilityEntity) => {
        components.table(
          pdf,
          null,
          mountFragilityEntityRow(fragilityEntity),
          pdf.autoTable.previous.finalY,
          0.5,
          PADDING,
          true,
          firstTableColsStyle,
        )
      })
    }

    if (pageNumber !== pdf.getCurrentPageInfo().pageNumber) {
      components.numberPage(pdf, palette.primary.main, true)
      pageNumber = pdf.getCurrentPageInfo().pageNumber
    }
  })
}

export default fragilities
