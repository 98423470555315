import constants from 'constants/index'

const { LOGO_STEP_INDEX, SITE_CONFIRM_STEP_INDEX, SITE_FORM_INDEX } =
  constants.userTrailSteps

const mountHelpObject = (isStep = () => {}) => {
  if (isStep(LOGO_STEP_INDEX)) {
    return {
      textButton: 'O que é logo?',
      title: 'O que é uma logo?',
      description:
        'A logomarca é uma imagem que representa uma organização e ajuda as pessoas a identificá-la e lembrar dela.',
    }
  }

  if (isStep(SITE_CONFIRM_STEP_INDEX)) {
    return {
      textButton: 'O que é um site?',
      title: 'O que é um site?',
      description:
        'Um site é como uma página na internet, onde as pessoas podem ver informações, imagens ou vídeos sobre algo, como notícias, produtos ou uma empresa/organização.',
    }
  }

  if (isStep(SITE_FORM_INDEX)) {
    return {
      textButton: 'O que é URL?',
      title: 'O que é URL?',
      description:
        'Uma URL é o endereço de um site na internet. Para encontrá-la, basta olhar na parte de cima da tela quando você está visitando um site. É onde você digita o nome do site, como "www.exemplo.com".',
    }
  }

  return {
    textButton: 'O que é banner de cookies?',
    title: 'O que é um banner de cookies?',
    description:
      'Um banner de cookies é uma mensagem que aparece nos sites para dizer aos usuários que o site vai guardar informações sobre como eles navegam na internet, e pede a permissão deles para fazer isso.',
  }
}

export default mountHelpObject
