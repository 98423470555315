import permissions from '../permissions'

const COMPLAINT_LIST = [
  {
    name: 'Listagem de denúncias',
    tag: permissions.COMPLAINTS.LIST,
  },
  {
    name: 'Visualizar de denúncia',
    tag: permissions.COMPLAINTS.SHOW,
  },
  {
    name: 'Registrar responsável',
    tag: permissions.COMPLAINTS.REGISTER_RESPONSIBLE,
  },
  {
    name: 'Responder mensagem na denúncia',
    tag: permissions.COMPLAINT_MESSAGES.CREATE,
  },
  {
    name: 'Ativar ou inativar canal de denúncia',
    tag: permissions.COMPLAINTS.ACTIVATE_COMPLAINTS,
  },
]

export default COMPLAINT_LIST
