import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Card as MaterialCard,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { Skeleton } from '@material-ui/lab'

import useStyles from './styles'

const Card = ({
  title,
  subtitle,
  icon,
  valueProgress,
  loading,
  redirectTo,
}) => {
  const classes = useStyles()

  const handleProgressColors = () => {
    if (valueProgress === 100) return classes.progressColorGreen
    if (valueProgress >= 25) return classes.progressColorOrange

    return classes.progressColorRed
  }

  const checkValue = valueProgress === 0 ? valueProgress + 3 : valueProgress

  return (
    <Grid item xs={12} sm={6} lg={3}>
      <MaterialCard className={classes.materialCardRoot}>
        <Box>
          <CardHeader avatar={icon} style={{ paddingBottom: 2 }} />

          <CardContent className={classes.cardContent}>
            <Typography
              component={Box}
              fontSize={15}
              fontWeight="bold"
              paddingTop={0.3}
              paddingBottom={0.6}
              className={classes.title}
            >
              {title}
            </Typography>

            <Tooltip title={subtitle}>
              <Typography
                component={Box}
                textOverflow="ellipsis"
                noWrap
                fontSize={14}
                fontWeight={400}
                paddingBottom={0.1}
              >
                {subtitle}
              </Typography>
            </Tooltip>

            {loading ? (
              <Skeleton height={12.5} />
            ) : (
              <LinearProgress
                variant="determinate"
                value={checkValue}
                classes={{
                  bar: handleProgressColors(),
                  root: classes.linearProgressRoot,
                }}
              />
            )}
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Button
              size="small"
              color="primary"
              onClick={redirectTo}
              className={classes.cardButton}
            >
              VER MAIS
            </Button>
          </CardActions>
        </Box>
      </MaterialCard>
    </Grid>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  redirectTo: PropTypes.func.isRequired,
}

Card.defaultProps = {
  loading: false,
}

export default Card
