const dataCollectedLabelOptions = {
  '0': 'Outros',
  '1': 'Dados sensíveis',
  '2': 'Dados pessoais',
  '3': 'Dados financeiros',
  '4': 'Dados comportamentais',
  '5': 'Outros dados pessoais',
  '6': 'Outros dados financeiros',
  '7': 'Outros dados comportamentais',
}

const mountSelectedList = (data) => {
  if (!data) return

  return data.map((val) => ({
    id: val.dataCollectedOption && val.dataCollectedOption.id,
    label: val.dataCollectedOption && val.dataCollectedOption.name,
    group:
      dataCollectedLabelOptions[
        val?.dataCollectedOption?.dataType?.toString() || '0'
      ],
  }))
}

export default mountSelectedList
