import React from 'react'
import clsx from 'clsx'
import { Button, Chip } from '@material-ui/core'

import findAnswer from './findAnswer'

import helpers from 'helpers'

import constants from 'constants/index'

const mountComplaintInfos = (complaint, classes, handleRedirectIncident) => {
  const { CONVERTED_INCIDENT_STATUS_ID } = constants.complaint.ALL_STATUS_ID
  const companyRelation = findAnswer(
    complaint,
    constants?.complaint?.COMPANY_RELATION_ANSWER_KIND,
  )

  const reportClassification = findAnswer(
    complaint,
    constants?.complaint?.REPORT_CLASSIFICATION_ANSWER_KIND,
  )

  return [
    {
      label: 'Título da solicitação',
      value: 'Canal de denúncia',
      highlight: true,
      visible: true,
    },
    {
      label: `Qual a sua relação com ${complaint?.company?.name}`,
      value: companyRelation?.answerDecrypt,
      visible: true,
    },
    ...(companyRelation?.complaintAnswerSpecific
      ? [
          {
            label: companyRelation?.complaintAnswerSpecific?.label,
            value: companyRelation?.complaintAnswerSpecific?.answerDecrypt,
            visible: true,
          },
        ]
      : []),
    {
      label: 'Classifique o seu relato',
      value: reportClassification?.answerDecrypt,
      visible: true,
    },
    ...(reportClassification?.complaintAnswerSpecific
      ? [
          {
            label: reportClassification?.complaintAnswerSpecific?.label,
            value: reportClassification?.complaintAnswerSpecific?.answerDecrypt,
            visible: true,
          },
        ]
      : []),
    {
      label: 'Criado em',
      value: helpers.formatters.ptBrFormatDateTime(complaint?.createdAt),
      visible: true,
    },
    {
      label: 'Atualizado em',
      value: helpers.formatters.ptBrFormatDateTime(complaint?.updatedAt),
      visible: true,
    },
    {
      label: 'Status',
      value: (
        <Chip
          size="small"
          className={clsx(
            classes.chip,
            helpers.complaint.statusColor(complaint?.status, classes),
          )}
          label={helpers.complaint.statusLabel(complaint?.status)}
        />
      ),
    },
    ...(complaint?.status !== CONVERTED_INCIDENT_STATUS_ID
      ? [
          {
            value: (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleRedirectIncident}
              >
                Converter em incidente
              </Button>
            ),
          },
        ]
      : []),
  ]
}

export default mountComplaintInfos
