import React from 'react'
import PropTypes from 'prop-types'

import { Container } from '../'

const ContainerWithClassName = ({ children, className }) => {
  return (
    <Container
      maxWidth={false}
      className={className}
      spacingXl={30}
      spacingLg={18}
      spacingMd={10}
      spacingSm={10}
      spacingXs={5}
    >
      {children}
    </Container>
  )
}

ContainerWithClassName.propTypes = {
  className: PropTypes.string,
}

export default ContainerWithClassName
