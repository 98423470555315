import api from 'service/api'

const dponetAPI = api.create('dponet')

const chooseAction = async ({ auditProcessId, ...params }) => {
  return await dponetAPI.put(
    `audit_processes/${auditProcessId}/choose_action`,
    { ...params },
  )
}

export default { chooseAction }
