import helpers from 'helpers'

import { isEmpty, isUndefined } from 'lodash'

const cnpjOrCpf = (document) => {
  if (isEmpty(document) || isUndefined(isUndefined)) return document

  const cpfCnpj = document.replace(/[^a-z0-9]/gi, '')

  if (cpfCnpj.length <= 11) {
    return helpers.formatters.cpf(cpfCnpj)
  }

  return helpers.formatters.cnpj(cpfCnpj)
}

export default cnpjOrCpf
