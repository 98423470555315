import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Box, Typography, Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { LoadingFeedback, Permitted } from 'components'
import { ClientActions, SupplierActions } from './components'
import {
  ResendSolicitationDialog,
  ConfirmationDialog,
  SuccessDialog,
} from '../'

import helpers from 'helpers'
import permission from 'constants/permissions'

import styles from '../../styles'
import clsx from 'clsx'
const useStyles = makeStyles(styles)

const TableRowSupplier = ({
  supplier,
  isSupplier,
  setCompanySupplier,
  setOpenResendSolicitation,
  setOpenConfirmation,
  setConfirmationData,
  ...rest
}) => {
  const classes = useStyles()
  const company = isSupplier ? supplier?.company : supplier?.supplier
  const companyCompliance = company?.companyCompliance
    ?.toFixed(1)
    ?.replace('.', ',')

  return (
    <TableRow {...rest}>
      <TableCell>{supplier?.id}</TableCell>
      <TableCell>
        <Typography>{company?.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{company?.segment?.name || '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{company?.document}</Typography>
      </TableCell>
      <TableCell>
        <Chip
          size="small"
          className={clsx(
            classes.chipRoot,
            helpers.companySuppliers.statusChipColor(supplier?.status, classes),
          )}
          label={helpers.companySuppliers.label(supplier?.status)}
          id={helpers.companySuppliers.statusDriverId(supplier?.status)}
        />
      </TableCell>
      {!isSupplier && (
        <TableCell>
          <Typography>
            {!!companyCompliance ? `${companyCompliance}%` : '-'}
          </Typography>
        </TableCell>
      )}
      <Permitted
        someTags={[
          permission.COMPANY_SUPPLIERS.SHOW,
          permission.COMPANY_SUPPLIERS.UPDATE,
        ]}
      >
        <TableCell align="right">
          {isSupplier ? (
            <SupplierActions
              supplier={supplier}
              setOpenConfirmation={setOpenConfirmation}
              setConfirmationData={setConfirmationData}
              setCompanySupplier={setCompanySupplier}
            />
          ) : (
            <ClientActions
              supplier={supplier}
              setOpenConfirmation={setOpenConfirmation}
              setConfirmationData={setConfirmationData}
              setCompanySupplier={setCompanySupplier}
              setOpenResendSolicitation={setOpenResendSolicitation}
            />
          )}
        </TableCell>
      </Permitted>
    </TableRow>
  )
}

TableRowSupplier.propTypes = {
  supplier: PropTypes.object,
}

const SuppliersTable = ({
  companySuppliers,
  isLoading,
  isSupplier,
  refresh,
}) => {
  const [openResendSolicitation, setOpenResendSolicitation] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [confirmationData, setConfirmationData] = useState({})
  const [successData, setSuccessData] = useState({})
  const [companySupplier, setCompanySupplier] = useState()
  const confirmationCallback = (data) => {
    setSuccessData(data)
    setOpenSuccess(true)
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <Box width="100%">
        <PerfectScrollbar
          options={{
            useBothWheelAxes: true,
            suppressScrollX: false,
          }}
        >
          <Table
            size="medium"
            emptyMessage="Nenhum vínculo encontrado"
            isLoading={isLoading}
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="10%">Identificador</TableCell>
                <TableCell width={isSupplier ? '30%' : '25%'}>
                  {isSupplier ? 'Empresa' : 'Fornecedor'}
                </TableCell>
                <TableCell width="15%">Segmento</TableCell>
                <TableCell width="20%">Documento</TableCell>
                <TableCell width={isSupplier ? '15%' : '10%'}>
                  Status do vínculo
                </TableCell>
                {!isSupplier && <TableCell width="10%">Conformidade</TableCell>}
                <Permitted
                  someTags={[
                    permission.COMPANY_SUPPLIERS.SHOW,
                    permission.COMPANY_SUPPLIERS.UPDATE,
                  ]}
                >
                  <TableCell width="10%" align="right">
                    Ações
                  </TableCell>
                </Permitted>
              </TableRow>
            </TableHead>
            <TableBody>
              {companySuppliers?.map((supplier) => (
                <TableRowSupplier
                  supplier={supplier}
                  key={supplier.id}
                  isSupplier={isSupplier}
                  setOpenResendSolicitation={setOpenResendSolicitation}
                  setCompanySupplier={setCompanySupplier}
                  setOpenConfirmation={setOpenConfirmation}
                  setConfirmationData={setConfirmationData}
                  hover
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
        <ResendSolicitationDialog
          companySupplier={companySupplier}
          open={openResendSolicitation}
          setOpen={setOpenResendSolicitation}
          refresh={refresh}
        />
        <ConfirmationDialog
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          callback={confirmationCallback}
          isSupplier={isSupplier}
          supplier={companySupplier}
          {...confirmationData}
        />
        <SuccessDialog
          open={openSuccess}
          setOpen={setOpenSuccess}
          reload={refresh}
          isSupplier={isSupplier}
          {...successData}
        />
      </Box>
    </>
  )
}

SuppliersTable.propTypes = {
  companySuppliers: PropTypes.array,
  isLoading: PropTypes.bool,
  isSupplier: PropTypes.bool,
  refresh: PropTypes.func,
}

TableRowSupplier.propTypes = {
  isSupplier: PropTypes.bool,
  supplier: PropTypes.object,
  setOpenResendSolicitation: PropTypes.func,
  setCompanySupplier: PropTypes.func,
  setOpenConfirmation: PropTypes.func,
  setConfirmationData: PropTypes.func,
}

SuppliersTable.defaultProps = {
  isSupplier: true,
  refresh: () => {},
}

export default SuppliersTable
