import React from 'react'
import PropTypes from 'prop-types'

import { ConfirmationDialog } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'

const ActivitiesDestroyConfirmation = ({ open, setOpen, taskId, refresh }) => {
  const snackbar = useSnackbar()

  const handleClose = () => setOpen(false)

  const handleDestroy = async () => {
    try {
      await service.dponet.activities.destroy({ taskId })
      refresh()
      snackbar.open({
        message: 'Atividades removida com sucesso',
        variant: 'success',
      })
      handleClose()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) ||
          'Ocorreu um erro ao excluir a atividade, Tente novamente mais tarde',
        variant: 'error',
      })
    }
  }
  return (
    <ConfirmationDialog
      open={open}
      setOpen={setOpen}
      text="Você tem certeza que deseja excluir essa atividade"
      textButton="Excluir"
      textButtonCancel="Cancelar"
      actionAcceptButton={handleDestroy}
    />
  )
}

ActivitiesDestroyConfirmation.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refresh: PropTypes.func,
}

export default ActivitiesDestroyConfirmation
