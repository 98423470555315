/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'
import useCustomTheme from 'hooks/useCustomTheme'

import helpers from 'helpers'

const SupplierCompliancePdf = ({
  exportingPdf,
  setExportingPdf,
  questionControls,
  questionnaires,
  supplier,
}) => {
  const snackbar = useSnackbar()
  const { customTheme, customLogo } = useCustomTheme()

  const customStyles = customTheme?.palette?.layout

  useEffect(() => {
    if (exportingPdf) {
      if (questionControls && questionnaires) {
        helpers.reports.supplierCompliance.exportPdf(
          customStyles,
          customLogo,
          questionnaires,
          questionControls,
          supplier,
          setExportingPdf,
          snackbar,
        )
      }
    }
  }, [exportingPdf, questionControls, questionnaires])

  return <></>
}

SupplierCompliancePdf.propTypes = {
  exportingPdf: PropTypes.bool,
  setExportingPdf: PropTypes.func,
  questionControls: PropTypes.array,
  questionnaires: PropTypes.array,
  supplier: PropTypes.object,
}

SupplierCompliancePdf.defaultProps = {
  setExportingPdf: () => {},
}

export default SupplierCompliancePdf
