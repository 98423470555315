import { Minus as MinusIcon } from 'react-feather'
import { colors } from '@material-ui/core'

import theme from 'theme'
import constants from 'constants/index'

const chipStatusColor = (status) => {
  return (
    (status && constants.cookies.banner.STATUS_CHIP_COLOR[status]) ||
    colors.grey[300]
  )
}

const chipFontStatusColor = (status) => {
  return (
    (status && constants.cookies.banner.STATUS_CHIP_FONT_COLOR[status]) ||
    colors.grey[800]
  )
}

const chipStatusLabel = (status) => {
  return (
    (status && constants.cookies.banner.STATUS_TRANSLATION[status]) ||
    'indeferido'
  ).toUpperCase()
}

const scannerStatusLabel = (status) => {
  return (
    (status && constants.cookies.banner.SCANNER_STATUS_TRANSLATION[status]) ||
    'indeferido'
  ).toUpperCase()
}

const scannerStatusColor = (status) => {
  return (
    (status && constants.cookies.banner.SCANNER_STATUS_COLOR[status]) ||
    theme.palette.custom.dark
  )
}

const scannerStatusIcon = (status) => {
  return (
    (status && constants.cookies.banner.SCANNER_STATUS_ICON[status]) ||
    MinusIcon
  )
}

const status = {
  chipFontStatusColor,
  chipStatusColor,
  chipStatusLabel,
  scannerStatusColor,
  scannerStatusIcon,
  scannerStatusLabel,
}

export default status
