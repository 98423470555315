import React from 'react'

import { Box, Container } from '@material-ui/core'
import { Page, ContentHeader } from 'components'

import useStyles from './styles'
import { Form } from './components'

const SecurityMasureNew = () => {
  const classes = useStyles()

  return (
    <Page title="Nova medida de segurança">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Nova medida de segurança" />
        <Box width="100%" mt={1}>
          <Form />
        </Box>
      </Container>
    </Page>
  )
}

export default SecurityMasureNew
