import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { isNil } from 'lodash'

import { Box, Container, useMediaQuery } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { LoadingFeedback, Page } from 'components'
import { ContentBase, TicketButton } from './components'

import rocket from 'images/foguete.png'

import useFirstStep from 'hooks/useFirstStep'
import useAuth from 'hooks/useAuth'

import helpers from 'helpers'
import constants from 'constants/index'

import useStyles from './styles'
import theme from 'theme'
import qs from 'query-string'

const UserSteps = () => {
  const history = useHistory()
  const search = qs.parse(history?.location?.search)
  const { step: viewerStep } = search

  const [loading, setLoading] = useState(true)

  const classes = useStyles()
  const { user, company, homeRoute } = useAuth()
  const {
    getActivePage,
    isCenteredScreen,
    isWelcomeStep,
    isCheckpointStep,
    setActiveStep,
    setActiveSubStep,
    pageConfigs,
    loadingStep,
  } = useFirstStep()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  useEffect(() => {
    if (!!user?.actualStep?.tag && loading) {
      setLoading(false)
      return setActiveStep(
        helpers.userSteps.getIndexByTag(user?.actualStep?.tag),
      )
    }

    if (!!viewerStep && helpers.userSteps.validRedirectionStep(viewerStep)) {
      setLoading(false)
      setActiveStep(helpers.userSteps.getIndexByTag(viewerStep))
      setActiveSubStep(viewerStep === constants.userSteps.STEP_DPO_TAG ? 1 : 0)
    }
    //eslint-disable-next-line
  }, [user?.actualStep?.tag, viewerStep])

  if (
    company &&
    user &&
    (user?.allStepsConcluded ||
      isNil(company?.templateCompany?.id) ||
      helpers.company.isSupplier(company))
  ) {
    if (!helpers.userSteps.validRedirectionStep(viewerStep)) {
      history.replace(homeRoute)
      return null
    }
  }

  return (
    <>
      <Page title="Primeiro acesso" transparent>
        <LoadingFeedback open={loading || loadingStep} />
        <Container
          maxWidth="lg"
          className={clsx({ [classes.container]: !isCenteredScreen })}
          disableGutters={isCenteredScreen && !isMobile}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={10}
          >
            {(isWelcomeStep || isCheckpointStep) && (
              <Box
                className={clsx(classes.penOrRocketBackground, {
                  [classes.welcomeBackground]: isWelcomeStep,
                })}
                width="100%"
              >
                {isCheckpointStep && (
                  <img
                    src={rocket}
                    alt="foguete"
                    className={classes.rocketImage}
                  />
                )}
              </Box>
            )}
            <ContentBase {...pageConfigs}>
              {!loading && !loadingStep && getActivePage()}
            </ContentBase>
          </Box>
          <TicketButton />
        </Container>
      </Page>
    </>
  )
}

export default UserSteps
