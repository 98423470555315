import React from 'react'
import PropTypes from 'prop-types'

import { DashboardChartPaper, DonutChart } from 'components'

import theme from 'theme'

const FragilitiesWithLinkedMeasures = ({ fragilityStats, id, ...rest }) => {
  const securityMeasures = fragilityStats?.securityMeasures || 0
  const noSecurityMeasures =
    fragilityStats?.fragility?.totalCount - fragilityStats?.securityMeasures ||
    0

  return (
    <DashboardChartPaper title="Ameaças com medidas vinculadas" {...rest}>
      <DonutChart
        legendShow
        names={['Sim', 'Não']}
        values={[securityMeasures, noSecurityMeasures]}
        title="Ameaças"
        colors={[
          theme.palette.dashboard.chart.measures.adopted,
          theme.palette.dashboard.chart.measures.overdue,
        ]}
        showName={false}
        id={id}
      />
    </DashboardChartPaper>
  )
}

FragilitiesWithLinkedMeasures.propTypes = {
  id: PropTypes.string,
  fragilityStats: PropTypes.object.isRequired,
}

export default FragilitiesWithLinkedMeasures
