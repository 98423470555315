const companySizeByPrice = (plans) => {
  const newPlans = plans.sort(function (a, b) {
    const firstPrice = parseInt(a.price)
    const secondPrice = parseInt(b.price)

    if (firstPrice > secondPrice) {
      return 1
    }
    if (firstPrice < secondPrice) {
      return -1
    }
    return 0
  })

  if (newPlans.length !== 3) {
    return newPlans
  }

  const companySizes = ['Pequena empresa', 'Média empresa', 'Grande empresa']

  companySizes.map((companySize, index) => {
    newPlans[index].title = companySize
    return newPlans
  })

  return newPlans
}

export default companySizeByPrice
