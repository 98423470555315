import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Box, Typography, Grid, Divider, IconButton } from '@material-ui/core'
import { Save as SaveIcon } from 'react-feather'

import FormCoupon from 'components/PreRegistration/FormMain/components/FormCoupon'

import useSubscription from 'hooks/useSubscription'

import helpers from 'helpers'
import useStyles from './styles'
import schema from './schema'
import constants from 'constants/index'

const ResumePrice = () => {
  const [loading, setLoading] = useState(false)

  const {
    data,
    coupon,
    setFinalPrice,
    selectedSegment,
    selectedPlan,
    isDemoSubscription,
  } = useSubscription()

  const classes = useStyles()

  const formMethods = useForm({
    validationSchema: schema,
    defaultValues: {
      coupon: data?.coupon?.code || '',
    },
  })

  const price = parseFloat(selectedPlan?.price).toFixed(2)

  const resultPriceWithDiscount = coupon
    ? helpers.preRegistration.discountCouponValue(
        coupon?.discountType,
        coupon?.discountValue,
        selectedPlan?.price,
      )
    : 0

  const resultTotalPrice = coupon
    ? parseFloat(Number(resultPriceWithDiscount)).toFixed(2)
    : parseFloat(Number(price)).toFixed(2)

  useEffect(() => {
    setFinalPrice(
      `R$ ${helpers.formatters.formatToCurrencyBrl(resultTotalPrice)}`,
    )
    // eslint-disable-next-line
  }, [])

  const priceText = () => {
    if (!selectedPlan) return '-'

    if (coupon?.discountValue) {
      return (
        <Typography variant="subtitle1" gutterBottom>
          De R$ {helpers.formatters.formatToCurrencyBrl(price)}/mês
        </Typography>
      )
    }

    return (
      <Typography variant="subtitle1" gutterBottom>
        R$ {helpers.formatters.formatToCurrencyBrl(price)}/mês
      </Typography>
    )
  }

  const priceWithDiscount = () => {
    return (
      <>
        <Box display="flex">
          <Typography variant="subtitle1">
            Por R${' '}
            {helpers.formatters.formatToCurrencyBrl(resultPriceWithDiscount)}
            /mês
          </Typography>
          <Box ml={1}>
            <Typography variant="body1">
              {coupon?.discountType === constants.discountCoupon.PERCENTAGE_TYPE
                ? `${coupon?.discountValue}% de desconto`
                : coupon?.discountType === constants.discountCoupon.VALUE_TYPE
                ? `R$${helpers.formatters.formatToCurrencyBrl(
                    coupon?.discountValue,
                  )} de desconto`
                : `Preço fixo de RS${helpers.formatters.formatToCurrencyBrl(
                    coupon?.discountValue,
                  )}`}
            </Typography>
          </Box>
        </Box>
      </>
    )
  }

  if (isDemoSubscription) {
    return (
      <Box p={2.5} className={classes.root}>
        <Box>
          <Typography>Mensalidade</Typography>
          <Typography variant="h5" gutterBottom>
            1 ou 3 mes(es) gratuito(s)
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Grid item xs={12} sm={12}>
      <Box p={0.7} ml={2}>
        <Box mt={2}>
          <Typography variant="body2">Segmento</Typography>
          <Typography variant="subtitle1">{selectedSegment}</Typography>
        </Box>
        <Box mt={1.5}>
          <Typography variant="body2">Mensalidade</Typography>
          {priceText()}
          {coupon?.discountValue && priceWithDiscount()}
        </Box>
        <Box mt={1.5}>
          <Typography variant="body2">Duração do contrato</Typography>
          <Typography variant="subtitle1">
            Renovação mensal automática
          </Typography>
        </Box>
      </Box>
      <Box ml={2.8} mt={1.5} mb={1.5}>
        <Typography variant="body2">Cupom de desconto</Typography>
        <Box mt={2.5} display="flex">
          <Box width="72%">
            <FormCoupon formMethods={formMethods} setLoading={setLoading} />
          </Box>
          <IconButton
            className={classes.saveButton}
            classes={{ label: classes.labelSaveButton }}
            disabled={!!formMethods?.errors?.coupon || loading}
            type="submit"
            form="form-coupon"
          >
            <SaveIcon color="white" size={30} />
          </IconButton>
        </Box>
      </Box>
      <Box maxWidth="85%" my={(2, 1)} ml={2.8}>
        <Typography className={classes.colorOBS} align="justify">
          Obs.: Os pagamentos das demais mensalidades serão enviados para o
          e-mail de cobrança junto com a nota fiscal, enquanto o cadastro da
          conta será enviado para o e-mail do representante.
        </Typography>
      </Box>
      <Divider />
      <Box ml={2.8} my={2} className={classes.gridStart}>
        <Typography variant="h6">Total</Typography>
        <Typography variant="h5">
          {selectedPlan
            ? `R$ ${helpers.formatters.formatToCurrencyBrl(
                resultTotalPrice,
              )}/mês`
            : '-'}
        </Typography>
      </Box>
    </Grid>
  )
}

export default ResumePrice
