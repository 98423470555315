import React from 'react'

import { Typography, Box, TextField, MenuItem } from '@material-ui/core'

import { Controller, useForm } from 'react-hook-form'

import useSnackbar from 'hooks/useSnackbar'
import * as service from 'service'
import { useHistory } from 'react-router-dom'
import { BaseDialog } from 'components'

import schema from './schema'

const DeleteDataProcessDialog = ({
  dataProcessId,
  open,
  setOpen = () => {},
  action = () => {},
  redirectTo,
}) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const { handleSubmit, errors, control, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      reasonExclusion: '',
      anotherReasonExclusion: '',
    },
  })

  const handleDelete = async (data) => {
    if (data) {
      try {
        await service.dponet.dataProcesses.softDelete({
          dataProcessId: dataProcessId,
          reasonExclusion: data.anotherReasonExclusion || data.reasonExclusion,
        })
        snackbar.open({
          message: 'Processamento de dados excluído com sucesso!',
          variant: 'success',
        })
        setOpen(false)
        action()
        if (redirectTo) history.push(redirectTo)
      } catch (error) {
        snackbar.open({
          message: 'Erro ao excluir processamento de dados.',
          variant: 'error',
        })
        setOpen(false)
      }
    }
  }

  return (
    <BaseDialog
      open={open}
      title="Você tem certeza de que deseja excluir este processo?"
      closeButtonText="Cancelar"
      actionButtonText="Confirmar exclusão"
      actionButton={handleDelete}
      setOpen={setOpen}
      formRef={'reasons-form'}
    >
      <Box mt={1}>
        <Typography>
          Ao excluir um processo, ele será eliminado definitivamente da base de
          processos da empresa.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography>
          Excluir um processo significa que ele nunca existiu na rotina da
          empresa. Caso a sua intenção seja indicar que essa atividade de
          tratamento de dados pessoais deixou de ser realizada, considere a
          opção "Inativar" em vez de "Excluir".
        </Typography>
      </Box>
      <Box mt={3} mb={2}>
        <Typography variant="h5">
          Informe o motivo da exclusão do processo
        </Typography>
        <Box mt={2}>
          <form onSubmit={handleSubmit(handleDelete)} id="reasons-form">
            <Controller
              as={
                <TextField
                  label="Motivo da exclusão"
                  select
                  color="primary"
                  variant="outlined"
                  error={!!errors.reasonExclusion}
                  helperText={errors?.reasonExclusion && 'Preencha este campo'}
                  fullWidth
                >
                  <MenuItem
                    key="nonExistentInternProcess"
                    value="non existent intern process"
                  >
                    <Typography>
                      Processo não existe na rotina da empresa
                    </Typography>
                  </MenuItem>
                  <MenuItem key="duplicatedProcess" value="duplicated process">
                    <Typography>Processo duplicado</Typography>
                  </MenuItem>
                  <MenuItem key="another" value="another">
                    <Typography>Outro</Typography>
                  </MenuItem>
                </TextField>
              }
              control={control}
              name="reasonExclusion"
              mode="onChange"
            />
            {watch('reasonExclusion') === 'another' && (
              <Box mt={1}>
                <Controller
                  as={
                    <TextField
                      label="Outro: qual a razão para exclusão?"
                      color="primary"
                      variant="outlined"
                      error={!!errors.anotherReasonExclusion}
                      helperText={
                        errors?.anotherReasonExclusion && 'Preencha este campo'
                      }
                      fullWidth
                    />
                  }
                  control={control}
                  name="anotherReasonExclusion"
                  mode="onBlur"
                />
              </Box>
            )}
          </form>
        </Box>
      </Box>
    </BaseDialog>
  )
}

export default DeleteDataProcessDialog
