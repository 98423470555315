const styles = () => ({
  popover: {
    width: 200,
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})

export default styles
