import Banners from './BannerList'
import Consents from './ConsentList'
import Settings from './BannerConfiguration'

const Cookies = {
  Banners,
  Consents,
  Settings,
}

export default Cookies
