/* eslint-disable no-unused-expressions */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Box, Tooltip, ClickAwayListener } from '@material-ui/core'
import { CardRadio } from 'components'

import useSubscription from 'hooks/useSubscription'

import useStyles from './styles'

const CardCompanySize = ({
  title,
  subTitle,
  price,
  selected,
  handleSelect,
}) => {
  const classes = useStyles()
  const { isDemoSubscription } = useSubscription()

  const [openTitle, setOpenTitle] = useState(false)
  const [openSubtitle, setOpenSubtitle] = useState(false)

  const handleTooltipClose = () => {
    setOpenTitle(false)
    setOpenSubtitle(false)
  }

  const handleTooltipOpen = {
    openTitle: () => setOpenTitle(true),
    openSubtitle: () => setOpenSubtitle(true),
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box m={0}>
        <CardRadio
          display="flex"
          alignCard="space-between"
          action={handleSelect}
          checked={selected}
        >
          <Box p={2}>
            <Box>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={openTitle}
                disableFocusListener
                disableTouchListener
                title={title}
                placement="bottom"
              >
                <Typography
                  onClick={handleTooltipOpen.openTitle}
                  className={classes.title}
                  noWrap
                >
                  {title}
                </Typography>
              </Tooltip>
            </Box>

            <Box>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={openSubtitle}
                disableFocusListener
                disableTouchListener
                title={subTitle}
                placement="right"
              >
                <Typography
                  onClick={handleTooltipOpen.openSubtitle}
                  className={classes.subTitle}
                  noWrap
                >
                  {subTitle}
                </Typography>
              </Tooltip>
            </Box>
            {!isDemoSubscription && (
              <Box mt={1}>
                <Typography className={classes.price}>
                  {`R$ ${price}/mês`}
                </Typography>
              </Box>
            )}
          </Box>
        </CardRadio>
      </Box>
    </ClickAwayListener>
  )
}

CardCompanySize.propTypes = {
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  handleSelect: PropTypes.func,
}

CardCompanySize.defaultProps = {
  subTitle: '',
  title: '',
  selected: false,
  handleSelect: () => {},
}

export default CardCompanySize
