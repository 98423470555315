import { useContext } from 'react'

import SpecificRisksContext from 'contexts/SpecificRisksContext'

const useSpecificRisks = () => {
  const context = useContext(SpecificRisksContext)

  if (context === undefined) {
    throw new Error(
      'useSpecificRisks must be used within a SpecificRisksContext.Provider',
    )
  }

  return {
    data: context.data,
    loadData: context.loadData,
    setData: context.setData,
    loading: context.loading,
    setLoading: context.setLoading,
  }
}

export default useSpecificRisks
