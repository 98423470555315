import { isNull } from 'lodash'

const userCertificationStatus = (user) => {
  const hasIntroductionLGPD = !isNull(user?.introductionLgpdData)
  const hasFundamentalConcepts = !isNull(user?.fundamentalConceptsData)

  if (hasIntroductionLGPD && hasFundamentalConcepts) {
    return 'Concluído'
  }

  if (hasIntroductionLGPD || hasFundamentalConcepts) {
    return 'Em andamento'
  }

  return 'Pendente'
}

export default userCertificationStatus
