import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import useDriver from 'hooks/useDriver'

import useStyles from './styles'

const DriveTour = ({ stepsMatrix, customStyle, open, setOpen }) => {
  const classes = useStyles()

  const driver = useDriver({
    stepsMatrix: stepsMatrix,
    defaultStyle: classes.defaultPopover,
    customStyle: customStyle,
    setOpen: setOpen,
  })

  useEffect(() => {
    if (open) driver.drive()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return <></>
}

DriveTour.propTypes = {
  stepsMatrix: PropTypes.arrayOf(PropTypes.object),
  customStyle: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

export default DriveTour
