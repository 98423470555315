import PerfectScrollbar from 'react-perfect-scrollbar'
import React from 'react'
import DOMPurify from 'dompurify'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Avatar, Box, Paper, Typography } from '@material-ui/core'

import { AttachmentItems, Notification } from './components'

import helpers from 'helpers'

import useAuth from 'hooks/useAuth'

import styles from './styles'
import theme from 'theme'
import constants from 'constants/index'

const ComplaintMessages = ({
  attachments,
  complaintMessage,
  description,
  subTitle,
  title,
}) => {
  const { avatarLetters } = helpers

  const useStyles = styles(description, complaintMessage)
  const classes = useStyles()

  const { company } = useAuth()

  const messageSanitizer = DOMPurify.sanitize(complaintMessage?.content, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  })

  return (
    <>
      {complaintMessage?.complaintMessageInteractions?.map(
        (notification, index) => (
          <Notification key={index} interaction={notification} />
        ),
      )}
      <Paper component={Box} width="100%">
        <Box className={classes.boxTitle}>
          {complaintMessage?.authorName &&
            complaintMessage?.kind !==
              constants.complaint.AUTOMATIC_MESSAGE_KIND && (
              <Box>
                <Avatar>{avatarLetters(complaintMessage?.authorName)}</Avatar>
              </Box>
            )}
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            overflow="hidden"
          >
            <Typography
              variant="body1"
              color="primary"
              className={classes.messageInfo}
            >
              {complaintMessage?.authorName ?? title}
            </Typography>
            <Typography variant="body2" color="secondary">
              {subTitle ||
                `${company?.name} | ${helpers.formatters.ptBrFormatDateTime(
                  complaintMessage?.createdAt,
                )}`}
            </Typography>
          </Box>
        </Box>
        <Box py={2} pl={2}>
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
            className={classes.contentContainer}
          >
            <Box
              maxHeight={!!description ? 140 : 'fit-content'}
              className={classes.boxContent}
              dangerouslySetInnerHTML={{
                __html: messageSanitizer || description,
              }}
            />
          </PerfectScrollbar>
        </Box>
        {(!isEmpty(complaintMessage?.attachments) || !isEmpty(attachments)) && (
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Box display="flex" pb={2} px={2} gridGap={theme.spacing(2)}>
              {complaintMessage?.attachments?.map((attachment, index) => (
                <AttachmentItems
                  key={index}
                  name={attachment?.name}
                  url={attachment?.url}
                />
              ))}
              {attachments?.map((attachment, index) => (
                <AttachmentItems
                  key={index}
                  name={attachment?.name}
                  url={attachment?.url}
                />
              ))}
            </Box>
          </PerfectScrollbar>
        )}
      </Paper>
    </>
  )
}

ComplaintMessages.propTypes = {
  attachments: PropTypes.array,
  complaintMessage: PropTypes.object,
  description: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
}

export default ComplaintMessages
