import React, { useEffect, useRef } from 'react'

import PropTypes from 'prop-types'

import { Box, Grid, Typography, makeStyles } from '@material-ui/core'

import { CardRadio } from 'components/'

import useSubscription from 'hooks/useSubscription'
import constants from 'constants/index'
import styles from './styles'

const useStyles = makeStyles(styles)

const CardMethod = ({ title, selected, handleSelect, classes }) => {
  return (
    <CardRadio
      checked={selected}
      alignX="center"
      action={handleSelect}
      noBorder={!selected}
    >
      <Box p={2}>
        <Typography className={classes.title}>{title}</Typography>
      </Box>
    </CardRadio>
  )
}

CardMethod.propTypes = {
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  handleSelect: PropTypes.func,
}

CardMethod.defaultProps = {
  title: '',
  handleSelect: () => {},
}

const Methods = () => {
  const classes = useStyles()
  const refContainerTab = useRef(null)
  const { selectedMethod, setSelectedMethod } = useSubscription()

  const methods = [
    // {
    //   id: constants.preRegistrations.CARD_METHOD,
    //   title: 'Cartão de Crédito',
    // },
    {
      id: constants.preRegistrations.BILLET_METHOD,
      title: 'Boleto Bancário',
    },
  ]

  useEffect(() => {
    setSelectedMethod(methods[0])
    // eslint-disable-next-line
  }, [])
  const handleSelect = (method) => {
    setSelectedMethod(method)
    handleScroll(method?.id)
  }

  const handleScroll = (tabValue) => {
    return refContainerTab?.current?.scrollTo({
      behavior: 'smooth',
      left: tabValue === constants.preRegistrations.BILLET_METHOD ? 100 : 0,
    })
  }

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      wrap="nowrap"
      ref={refContainerTab}
      className={classes.gridScroll}
    >
      {methods.map((method) => (
        <Grid item key={method.title}>
          <CardMethod
            title={method.title}
            selected={method?.title === selectedMethod?.title}
            handleSelect={() => handleSelect(method)}
            classes={classes}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default Methods
