import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { size } from 'lodash'
import { Box, Typography, Hidden } from '@material-ui/core'

import { SVG } from 'components'
import { BoxPolygon, BoxPolygonOutlined } from './components'

import helpers from 'helpers'

import useStyles from './styles'

const MySuppliersCompliance = ({ supplierStats }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box p={2}>
        <Typography variant="h5" className={classes.cardTitle}>
          Conformidade de meus fornecedores
        </Typography>
        <Typography variant="body2">
          Acompanhe o progresso de seus fornecedores
        </Typography>
      </Box>
      <Box>
        <Hidden smDown>
          <BoxPolygon
            classes={classes}
            data={[
              {
                value: size(
                  helpers.myLgpd.getSupplierByCompliance(supplierStats, 0, 20),
                ),
                label: '0 - 20%',
                properties: { mr: 2 },
                polygonProps: {
                  stroke: '#E3FFBA',
                  width: '74',
                  height: '74',
                },
                type: 'outline',
              },
              {
                value: size(
                  helpers.myLgpd.getSupplierByCompliance(supplierStats, 20, 40),
                ),
                label: '20 - 40%',
                properties: { mr: 2 },
                polygonProps: {
                  stroke: '#CFFB8E',
                  width: '90',
                  height: '90',
                },
                type: 'outline',
              },
              {
                value: size(
                  helpers.myLgpd.getSupplierByCompliance(supplierStats, 40, 60),
                ),
                label: '40 - 60%',
                properties: { mr: 2 },
                polygonProps: {
                  stroke: '#C1F07A',
                  width: '125',
                  height: '125',
                },
                type: 'outline',
              },
              {
                value: size(
                  helpers.myLgpd.getSupplierByCompliance(supplierStats, 60, 80),
                ),
                label: '60 - 80%',
                properties: { mr: 2 },
                polygonProps: {
                  stroke: '#AADD5F',
                  width: '146',
                  height: '146',
                },
                type: 'outline',
              },
              {
                value: size(
                  helpers.myLgpd.getSupplierByCompliance(
                    supplierStats,
                    80,
                    101,
                  ),
                ),
                label: 'Acima de 80%',
                polygonProps: {
                  stroke: '#80B92B',
                  fill: '#80B92B',
                  width: '170',
                  height: '170',
                },
                type: 'contained',
              },
            ]}
          />
        </Hidden>
      </Box>
      <Box>
        <Hidden mdUp>
          <BoxPolygonOutlined
            classes={classes}
            data={[
              {
                value: size(
                  helpers.myLgpd.getSupplierByCompliance(supplierStats, 0, 20),
                ),
                label: '0 - 20%',
                properties: { mr: 2, mb: 1 },
                polygonProps: {
                  stroke: '#E3FFBA',
                },
              },
              {
                value: size(
                  helpers.myLgpd.getSupplierByCompliance(supplierStats, 20, 40),
                ),
                label: '20 - 40%',
                properties: { ml: 2, mb: 1 },
                polygonProps: {
                  stroke: '#CFFB8E',
                },
              },
            ]}
          />
          <Box className={classes.polygonBox}>
            <SVG.PolygonContained />
            <Box className={classes.polygonValues}>
              <Typography
                variant="h4"
                className={clsx(
                  classes.labelTypography,
                  classes.specialValueTypographyResponse,
                )}
              >
                {size(
                  helpers.myLgpd.getSupplierByCompliance(
                    supplierStats,
                    80,
                    101,
                  ),
                )}
              </Typography>
              <Typography
                variant="body1"
                className={clsx(
                  classes.labelTypography,
                  classes.specialLabelTypography,
                )}
              >
                Acima de 80%
              </Typography>
            </Box>
          </Box>
          <BoxPolygonOutlined
            classes={classes}
            data={[
              {
                value: size(
                  helpers.myLgpd.getSupplierByCompliance(supplierStats, 40, 60),
                ),
                label: '40 - 60%',
                properties: { mr: 2, mb: 1 },
                polygonProps: {
                  stroke: '#C1F07A',
                },
              },
              {
                value: size(
                  helpers.myLgpd.getSupplierByCompliance(supplierStats, 60, 80),
                ),
                label: '60 - 80%',
                properties: { ml: 2, mb: 1 },
                polygonProps: {
                  stroke: '#AADD5F',
                },
              },
            ]}
          />
        </Hidden>
      </Box>
    </Box>
  )
}

MySuppliersCompliance.propTypes = {
  supplierStats: PropTypes.object,
}

export default MySuppliersCompliance
