import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  logo: {
    width: '164px',
    height: '50px !important',
    margin: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(5, 2),
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 10,
  },
}))
