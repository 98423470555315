import React from 'react'
import { Box, TextField, Typography } from '@material-ui/core'

import useStyles from './styles'

const QuestionsReview = ({ order, text, answer }) => {
  const classes = useStyles()

  return (
    <Box my={3}>
      <Typography className={classes.questionText}>
        {order} - {text}
      </Typography>
      <TextField
        defaultValue={answer?.answer}
        disabled
        fullWidth
        multiline
        minRows={4}
      />
    </Box>
  )
}

export default QuestionsReview
