import React from 'react'
import {
  Layers as LayersIcon,
  File as FileIcon,
  TrendingUp as TrendingUpIcon,
  BookOpen as BookOpenIcon,
} from 'react-feather'

import helpers from 'helpers'
import constants from 'constants/index'
const typeName = constants.partner.TYPE_CATEGORIES

const getIcon = (name) => {
  const named = helpers.formatters.formattedConstantName(name)

  switch (named) {
    case typeName[0]:
      return <LayersIcon />
    case typeName[1]:
      return <FileIcon />
    case typeName[2]:
      return <TrendingUpIcon />
    case typeName[3]:
      return <BookOpenIcon />
    default:
      return ''
  }
}

export default getIcon
