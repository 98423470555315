import React, { useRef, useState, useCallback } from 'react'

import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'

import { toPng } from 'html-to-image'

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core'
import { Download as DownloadIcon } from 'react-feather'
import { SocialMediaImage } from 'components'
import helpers from 'helpers'

import styles from './styles'
const useStyles = makeStyles(styles)

const SocialMedia = () => {
  const [loading, setLoading] = useState(false)
  const snackbar = useSnackbar()

  const classes = useStyles()
  const auth = useAuth()

  const stylesSizeName = helpers.formatters.stylesForSocialMedia(
    auth?.company?.name,
    classes,
  )

  const ref = useRef(null)

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return
    }

    toPng(ref.current, {
      cacheBust: true,
      canvasHeight: 1084,
      canvasWidth: 1084,
    })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'imagem-midias-sociais.png'
        link.href = dataUrl
        link.click()
      })
      .catch(() => {
        snackbar.open({
          message:
            'Não foi possível gerar a imagem para compartilhamento em redes sociais.',
          variant: 'error',
        })
        setLoading(false)
      })
    //eslint-disable-next-line
  }, [ref])

  const isActive = useMediaQuery('(max-width: 1381px)')
  const isActiveMd1 = useMediaQuery('(max-width: 1160px)')
  const isActiveMd2 = useMediaQuery('(max-width: 1013px)')
  const isActiveLg = useMediaQuery('(max-width: 1540px)')


  const flagResponsiveLg = () => {
    return isActive ? 12 : isActiveLg ? 6 : 7
  }

  const flagResponsiveMd = () => {
    return isActiveMd1 ? (isActiveMd2 ? 5 : 5) : 6
  }

  return (
    <Card>
      <Box mt={2}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box px={2} mb={2}>
                <Typography variant="h6" color="textPrimary">
                  Publique em suas redes sociais
                </Typography>
              </Box>
            <Divider />
            </Grid>
            <Box px={2} mt={2}>
              <SocialMediaImage
                reference={ref}
                stylesSizeName={stylesSizeName}
                company={auth?.company}
              />
            </Box>
            <Grid
              item
              xl={7}
              lg={flagResponsiveLg()}
              md={flagResponsiveMd()}
              sm={12}
              xs={12}
            >
              <Box px={2} mt={2}>
                <Typography variant="body1" color="textPrimary" align="justify">
                    Publique de forma recorrente em suas redes sociais sobre a sua
                    organização adotar boas práticas de proteção de dados. Ganhe
                    credibilidade com os seus clientes e parceiros.
                </Typography>
              </Box>
              <Box px={2} mt={2}>
                <Typography variant="h6" color="textPrimary">
                  Imagem de postagem
                </Typography>
              </Box>
              <Box px={2} mt={1}>
                <Typography variant="body1" color="textPrimary">
                  Realize o download desta imagem para publicá-la em suas redes sociais
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <Divider />
              </Box>
              <Box px={2} my={2} display="flex" justifyContent="flex-end">
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onButtonClick}
                    startIcon={<DownloadIcon width="18" />}
                    size="large"
                  >
                    DOWNLOAD
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Card>
  )
}

export default SocialMedia