import React from 'react'

import { Box, Typography, makeStyles } from '@material-ui/core'
import helpers from 'helpers'

import styles from './styles'
const useStyles = makeStyles(styles)

const SocialMediaImage = ({ reference, stylesSizeName, company }) => {
  const classes = useStyles()

  return (
    <div ref={reference}>
      <Box className={classes?.socialMediaImageBackground}>
        <Box className={classes?.socialMediaImageContainer}>
          <Typography variant="h1" className={stylesSizeName}>
            {company?.name.toUpperCase()}
          </Typography>
          <Typography variant="body1" className={classes.bodyDocument}>
            {helpers.company.documentType(company)}
          </Typography>
        </Box>
      </Box>
    </div>
  )
}

export default SocialMediaImage
