import { makeStyles } from '@material-ui/core'
import palette from 'theme/palette'

const styles = makeStyles(() => ({
  selectProfileBox: {
    background: palette.white,
  },
  link: {
    cursor: 'pointer',
  },
}))

export default styles
