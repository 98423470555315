import permissions from '../permissions'

const COMPANY_FRAGILITIES = [
  {
    name: 'Visualizar todas as ameaças',
    tag: permissions.COMPANY_FRAGILITIES.LIST_COMPANY_FRAGILITIES,
  },
  {
    name: 'Gerenciar todas as ameaças',
    tag: permissions.COMPANY_FRAGILITIES.MANAGE_COMPANY_FRAGILITIES,
    description:
      'Ao permitir, o usuário poderá criar, editar e excluir ameaças',
  },
]

export default COMPANY_FRAGILITIES
