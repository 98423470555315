import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  Paper,
  IconButton as IconButtonMaterial,
  Dialog,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import FormModal from './Form'

import useSnackbar from 'hooks/useSnackbar'
import useDataLifeCycle from 'hooks/useDataLifeCycle'
import useDataProcessOptions from 'hooks/useDataProcessOptions'

import DataProcessTemplateQuestionProvider from 'providers/DataProcessTemplateQuestionProvider'

const DataLifeCycleModal = ({
  open,
  dataProcessId,
  setOpen = () => {},
  dataProcess,
  classes = {},
  dataLifeCycle,
  mode = 'edit',
}) => {
  const snackbar = useSnackbar()
  const dataLifeCycleHook = useDataLifeCycle()
  const { loadData: loadDataProcessOption, dataProcessTemplate } =
    useDataProcessOptions()

  const edit = mode === 'edit'
  const show = mode === 'show'

  const textMode = edit ? 'Editar' : show ? 'Visualizar' : 'Adicionar'

  const submitSuccess = async () => {
    snackbar.open({
      message: `Salvaguarda e ciclo de vida ${
        dataLifeCycle ? 'atualizadas' : 'criada'
      } com sucesso`,
      variant: 'success',
    })
    setOpen(false)
    await loadDataProcessOption()
    dataLifeCycleHook.loadDataLifeCycle(dataProcessId)
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className={classes.modal}
      disableAutoFocus
      fullWidth
      maxWidth="md"
    >
      <Paper className={classes.paperModal}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h4">
            {textMode} salvaguarda e ciclo de vida
          </Typography>
          <IconButtonMaterial
            onClick={() => setOpen(false)}
            color="primary"
            aria-label="close modal"
          >
            <CloseIcon />
          </IconButtonMaterial>
        </Grid>
        <DataProcessTemplateQuestionProvider
          dataProcessTemplate={dataProcessTemplate}
          step="life_cycle"
          dataProcessTemplateAnswers={
            dataLifeCycle?.dataProcessTemplateAnswers || []
          }
        >
          <FormModal
            dataLifeCycle={dataLifeCycle}
            handleCloseModal={() => setOpen(false)}
            dataProcessId={dataProcessId}
            submitSuccess={submitSuccess}
            dataProcess={dataProcess}
            mode={mode}
          />
        </DataProcessTemplateQuestionProvider>
      </Paper>
    </Dialog>
  )
}

DataLifeCycleModal.propTypes = {
  dataProcessId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  dataProcess: PropTypes.object.isRequired,
}

export default DataLifeCycleModal
