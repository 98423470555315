import React from 'react'

import { Typography, Box } from '@material-ui/core'

import useStyle from '../../styles'

const Congratulations = () => {
  const classes = useStyle()
  return (
    <Box className={classes.congratulation}>
      <Box>
        <Typography variant="h1" color="primary" className={classes.title}>
          Obrigado pelo seu cadastro!
        </Typography>
        <Typography
          variant="h2"
          color="secondary"
          className={classes.description}
        >
          Um E-mail de cadastro de senha foi enviado pra você, confira sua caixa
          de entrada
        </Typography>
      </Box>
    </Box>
  )
}

export default Congratulations
