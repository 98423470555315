const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  textElipsiss: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})

export default styles
