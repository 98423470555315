import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import { Box } from '@material-ui/core'
import { Check as CheckIcon, Loader as LoaderIcon } from 'react-feather'
import { Text } from 'views/UserSteps/components'

import useStyles from './styles'

const StatusIcon = ({ label, time }) => {
  const [completed, setCompleted] = useState(false)
  useEffect(() => {
    const timeoutId = setTimeout(() => setCompleted(true), time)

    return () => clearTimeout(timeoutId)
    //eslint-disable-next-line
  }, [])

  const classes = useStyles()

  return (
    <Box display="flex" alignItems="center" mt={1}>
      <Box
        className={clsx(classes.statusIcon, {
          [classes.completed]: completed,
        })}
        width={32}
        height={32}
        mr={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {completed ? (
          <CheckIcon size={20} color="#FFF" />
        ) : (
          <LoaderIcon size={20} color="#657786" />
        )}
      </Box>
      <Text fontSize={24} variant="h3">
        {label}
      </Text>
    </Box>
  )
}

export default StatusIcon
