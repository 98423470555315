import { makeStyles } from '@material-ui/core'

export default makeStyles(() => ({
  root: {
    width: '100%',
  },
  boxButton: {
    justifyContent: 'end',
  },
  boxDialog: {
    width: 650,
  },
}))
