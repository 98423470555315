const getContinuedText = (fragilityFinality, continued, isRisk) => {
  if (!fragilityFinality && !isRisk) {
    return 'Os itens indicados como desnecessários/desproporcionais estão aguardando reanálise.'
  }

  if (continued) {
    return 'A organização optou por continuar com a atividade de tratamento de dados e abordar o risco por meio da vinculação de medidas de segurança. No momento, o processo está aguardando a vinculação de medidas de segurança administrativas e técnicas aplicadas para abordar o risco calculado.'
  }

  return 'Aguardando inativação do processo devido ao risco calculado ou vinculação de medidas de segurança administrativas e técnicas aplicadas para abordar o risco calculado.'
}

export default getContinuedText
