const categoryOptions = (categories, withAnother = true) => {
  const options = categories?.map((category) => ({
    name: category?.name,
    id: category?.id,
  }))

  if (withAnother) {
    options.push({
      name: 'Outro',
      id: 'another',
    })
  }

  return options
}

export default categoryOptions
