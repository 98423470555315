import React, { useState } from 'react'

import SuggestedChangeContext from 'contexts/SuggestedChangeContext'

import * as service from 'service'

const SuggestedChangeProvider = ({ children }) => {
  const [data, setData] = useState([])
  const [suggestionsQuantity, setSuggestionsQuantity] = useState([])
  const [departments, setDepartments] = useState([])
  const [dataProcess, setDataProcess] = useState([])

  const [loading, setLoading] = useState(false)

  const loadDataProcess = async (dataProcessId) => {
    const response = await service.dponet.dataProcesses.get({
      dataProcessId,
    })

    setDataProcess(response?.data?.dataProcess)
  }

  const incrementSuggestionsQuantity = (id) => {
    if (!suggestionsQuantity.includes(id)) {
      suggestionsQuantity.push(id)
      setSuggestionsQuantity(suggestionsQuantity)
    }
  }

  const submit = async (dataProcessId) => {
    try {
      setLoading(true)
      for await (let change of data) {
        if (change.answer === 'accept') {
          await service.dponet.suggestedChanges.accept({
            dataProcessId,
            changeId: change.id,
            ...change.params,
          })
        } else {
          await service.dponet.suggestedChanges.refuse({
            dataProcessId,
            changeId: change.id,
          })
        }
      }
      setLoading(false)
      clearData()
    } catch (error) {
      setLoading(false)

      throw error
    }
  }

  const handleSelect = ({ value, changeId, ...params }) => {
    const actualValue = data.find((change) => change.id === changeId)

    if (actualValue) {
      actualValue.answer = value
      actualValue.params = params
      const newData = data.filter((change) => change.id !== changeId)

      return setData([...newData, actualValue])
    }

    setData([
      ...data,
      {
        id: changeId,
        answer: value,
        params,
      },
    ])
  }

  const clearData = async () => setData([])

  return (
    <SuggestedChangeContext.Provider
      value={{
        data,
        setData,
        loading,
        setLoading,
        submit,
        clearData,
        handleSelect,
        suggestionsQuantity: suggestionsQuantity.length,
        incrementSuggestionsQuantity,
        departments,
        setDepartments,
        dataProcess,
        loadDataProcess,
      }}
    >
      {children}
    </SuggestedChangeContext.Provider>
  )
}

export default SuggestedChangeProvider
