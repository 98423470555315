import constants from 'constants/index'

const getIndexByTag = (tag) => {
  const {
    COMPANY_LOGO_TAG,
    COMPANY_SITE_TAG,
    COOKIE_BANNER_TAG,
    SITE_CONFIGURATION_TAG,
    COMPANY_DOCUMENTS_TAG,
    CONCLUSION_TAG,
    STEP_LOGO,
    STEP_SITE,
    STEP_COOKIE,
    STEP_CONFIG,
    STEP_DOCS,
    STEP_CONCLUSION,
  } = constants.userTrailSteps

  switch (tag) {
    case COMPANY_LOGO_TAG:
      return STEP_LOGO
    case COMPANY_SITE_TAG:
      return STEP_SITE
    case COOKIE_BANNER_TAG:
      return STEP_COOKIE
    case SITE_CONFIGURATION_TAG:
      return STEP_CONFIG
    case COMPANY_DOCUMENTS_TAG:
      return STEP_DOCS
    case CONCLUSION_TAG:
      return STEP_CONCLUSION
    default:
      return STEP_LOGO
  }
}

export default getIndexByTag
