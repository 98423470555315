import helpers from 'helpers'

const defineSubtitle = (trail) => {
  if (trail.completed || trail.jumped) {
    return helpers.formatters.dateLocaleFormat(
      trail.updatedAt,
      'DD/MM/YYYY - HH:mm',
    )
  }

  if (trail.jumped) {
    return ' Pulou a etapa'
  }

  return '-'
}

export default defineSubtitle
