import React from 'react'
import { isEmpty } from 'lodash'
import { Tooltip, Chip } from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import { reverse } from 'named-urls'

const RedirectHelpButton = ({ documentationId, classes }) => {
  const history = useHistory()
  const handleHelp = () => {
    if (!isEmpty(documentationId)) return
    history.push(
      reverse(routes.faqsDocumentations.show, {
        faqsDocumentationId: documentationId,
      }),
    )
  }

  return (
    <Tooltip title="Acesso a documentação">
      <Chip
        className={classes.helpChip}
        onClick={handleHelp}
        icon={<HelpCircleIcon className={classes.iconChip} />}
        label="Ajuda"
        clickable={false}
      />
    </Tooltip>
  )
}

export default RedirectHelpButton
