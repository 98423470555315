import React from 'react'

const Elo = ({
  alt = 'Bandeira Elo',
  height = 20,
  viewBox = '0 0 55 20', // Valor total da imagem
  width = 55,
  grayscale = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
    >
      {grayscale && (
        <filter id="grayscale">
          <feColorMatrix
            type="matrix"
            values="0.3333 0.3333 0.3333 0 0
                                           0.3333 0.3333 0.3333 0 0
                                           0.3333 0.3333 0.3333 0 0
                                           0      0      0      1 0"
          />
        </filter>
      )}
      <g filter={grayscale ? 'url(#grayscale)' : ''}>
        <path
          d="M15.8354 11.1111C15.2741 13.8165 12.8374 15.8508 9.91699 15.8508C9.24693 15.8508 8.60197 15.7434 8.00021 15.5455L6.65875 19.4814C7.6825 19.818 8.77783 20 9.91699 20C14.8811 20 19.022 16.5407 19.9762 11.9438L15.8354 11.1111Z"
          fill="#EC412A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.9052 2.22224V13.6028L41.9475 14.4214L40.9813 16.666L38.9617 15.8542C38.5076 15.6638 38.2003 15.3737 37.9668 15.046C37.7426 14.7107 37.5763 14.2525 37.5763 13.6341V2.22224H39.9052ZM24.4161 10.6806C24.4668 7.4261 27.2384 4.82821 30.6015 4.87807C33.4559 4.92136 35.821 6.85469 36.4422 9.42609L25.4022 13.9889C24.7609 13.0408 24.3965 11.9003 24.4161 10.6806ZM26.9421 11.1369C26.927 11.0003 26.9163 10.8603 26.9204 10.7191C26.952 8.80161 28.5837 7.27159 30.5652 7.30337C31.6437 7.3173 32.6014 7.79487 33.2487 8.53404L26.9421 11.1369ZM33.0163 13.2498C32.351 13.8756 31.4487 14.257 30.4524 14.2433C29.7696 14.2322 29.1368 14.0345 28.5989 13.7051L27.2652 15.76C28.1786 16.3183 29.2555 16.6486 30.4166 16.666C32.1066 16.6903 33.647 16.0483 34.7671 14.9883L33.0163 13.2498ZM48.405 7.30331C48.0072 7.30331 47.6249 7.36548 47.2677 7.48142L46.473 5.17874C47.0796 4.98289 47.7291 4.87651 48.405 4.87651C51.3547 4.87651 53.8154 6.90264 54.3797 9.59413L51.9197 10.0791C51.5882 8.49506 50.1406 7.30331 48.405 7.30331ZM44.3661 15.187L46.0283 13.3694C45.2859 12.7339 44.8184 11.8057 44.8184 10.7711C44.8184 9.73751 45.2859 8.80976 46.0276 8.17468L44.3642 6.35706C43.1031 7.43701 42.3086 9.01504 42.3086 10.7711C42.3086 12.5293 43.1037 14.1067 44.3661 15.187ZM48.4049 14.2401C50.1389 14.2401 51.5863 13.0494 51.9197 11.4677L54.3789 11.9547C53.8119 14.6433 51.3519 16.6667 48.4049 16.6667C47.7284 16.6667 47.0781 16.5599 46.4699 16.3632L47.2665 14.0613C47.6243 14.1769 48.0068 14.2401 48.4049 14.2401Z"
          fill="black"
        />
        <path
          d="M3.06883 17.7778L5.54895 14.5755C4.44189 13.4557 3.74316 11.8209 3.74316 9.99872C3.74316 8.17788 4.44124 6.5431 5.54797 5.4242L3.06655 2.22224C1.18543 4.12467 0 6.90361 0 9.99872C0 13.0954 1.18716 15.8754 3.06883 17.7778Z"
          fill="#1BA7DE"
        />
        <path
          d="M7.9983 4.45063C8.60001 4.25334 9.24372 4.14623 9.91349 4.14623C12.8365 4.14623 15.2747 6.18309 15.8341 8.88889L19.9762 8.06036C19.0258 3.46168 14.8822 0 9.91349 0C8.7759 0 7.68091 0.181795 6.65875 0.516688L7.9983 4.45063Z"
          fill="#FECA2F"
        />
      </g>
    </svg>
  )
}

export default Elo
