import React from 'react'

import { useHistory } from 'react-router-dom'

import { Box, TablePagination, makeStyles } from '@material-ui/core'

import * as service from 'service'
import styles from './styles'
import useFetch from 'hooks/useFetch'
import usePagination from 'hooks/usePagination'

import { reverse } from 'named-urls'
import { routes } from 'Routes'

import { PartnerServiceCard } from '../'
import { LoadingFeedback } from 'components'

const useStyles = makeStyles(styles)

const CompaniesServicesList = ({ filter }) => {
  const history = useHistory()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(4)

  const handleClick = (partnerServiceId) => {
    return history.push(
      reverse(routes.marketplace.show, {
        partnerServiceId,
      }),
    )
  }

  const classes = useStyles()

  const { response, isLoading } = useFetch(
    service.marketPlace.partners.getAll,
    { ...filter.filters, perPage, page },
    [page, perPage, filter.filters],
  )

  const partnerServices = response?.data?.partnerServices

  return (
    <Box className={classes.container}>
      {isLoading && <LoadingFeedback open={isLoading} />}
      {partnerServices?.map((partnerService) => (
        <PartnerServiceCard
          partnerService={partnerService}
          action={() => handleClick(partnerService.id)}
          key={partnerService.id}
        />
      ))}
      <Box>
        <TablePagination
          component="div"
          count={response?.data?.meta?.totalCount || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[4, 12, 36, 100]}
          labelRowsPerPage="Por página"
          nextIconButtonProps={{ size: 'small' }}
          backIconButtonProps={{ size: 'small' }}
        />
      </Box>
    </Box>
  )
}

export default CompaniesServicesList
