import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import { LoadingBox } from 'components'
import { Button, Box } from '@material-ui/core'
import { GeneralInformationForm, PermissionsForm } from './components'
import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'

import * as service from 'service'

import constants from 'constants/index'
import helpers from 'helpers'
import schema from './schema'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const ProfilesForm = ({ isEdit, profile }) => {
  const auth = useAuth()
  const company = auth?.userCompany?.company

  const [loading, setLoading] = useState(true)
  const [permissions, setPermissions] = useState({})
  const profileStatus = profile?.status

  const history = useHistory()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, register, reset } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: profile?.name ?? '',
      description: profile?.description ?? '',
      status: profile?.status ?? false,
      default: profile?.default ?? null,
      companyId: profile?.company ?? null,
      profileType: constants.profile.PROFILE_CLIENT_TYPE,
      originProfileId: profile?.originProfileId ?? null,
    },
  })

  useEffect(() => {
    setLoading(true)
    if (profile?.id) {
      for (let item of profile.profileFunctionalities) {
        permissions[item.tag] = item.status
      }
      setPermissions(permissions)
    }
    setLoading(false)
    // eslint-disable-next-line
  }, [profile?.id])

  const redirectRollBack = () => history.push(routes.profiles.all)

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      if (company?.id) {
        if (isEdit) {
          await service.dponet.profiles.edit({
            profileId: profile?.id,
            profile: {
              ...data,
              companyId: company,
              permissions,
            },
          })
        } else {
          await service.dponet.profiles.create({
            profile: {
              ...data,
              companyId: company,
              permissions,
            },
          })
        }
        snackbar.open({
          message: `Perfil ${isEdit ? 'editado' : 'criado'} com sucesso`,
          variant: 'success',
        })
        redirectRollBack()
      }
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      reset(data)
      setLoading(false)
    }
  }

  const handleChange = (value, tag) => {
    setPermissions({ ...permissions, [tag]: value })
  }

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <GeneralInformationForm errors={errors} control={control} />
            <PermissionsForm
              register={register}
              permissions={permissions}
              handleChange={handleChange}
              activeProfile={profileStatus}
              isEdit={isEdit}
            />
            <Box mt={2} display="flex" justifyContent="flex-start">
              <Box pr={1}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={redirectRollBack}
                >
                  Voltar
                </Button>
              </Box>
              <Button color="primary" variant="contained" type="submit">
                Salvar
              </Button>
            </Box>
          </form>
        </>
      )}
    </>
  )
}

ProfilesForm.propTypes = {
  isEdit: PropTypes.bool,
  profile: PropTypes.object,
}

ProfilesForm.defaultProps = {
  profile: {},
}

export default ProfilesForm
