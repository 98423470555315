import { colors } from '@material-ui/core'
const white = '#FFFFFF'

const styles = (theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  onTime: {
    backgroundColor: colors.blue[500],
    color: white,
    fontWeight: 700,
    height: '21px',
  },
  finalized: {
    backgroundColor: colors.green[500],
    color: white,
    fontWeight: 700,
    height: '21px',
  },
  overdue: {
    backgroundColor: colors.red[500],
    color: white,
    fontWeight: 700,
    height: '21px',
  },
  substituted: {
    backgroundColor: colors.grey[500],
    color: white,
    fontWeight: 700,
    height: '21px',
  },
  pending: {
    backgroundColor: colors.cyan[500],
    color: white,
    fontWeight: 700,
    height: '21px',
  },
  frozen: {
    backgroundColor: colors.teal[200],
    color: white,
    fontWeight: 700,
  },
  inactive: {
    backgroundColor: colors.grey[500],
    color: white,
    fontWeight: 700,
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  frozenAlert: {
    backgroundColor: colors.teal[100],
  },
  alertTitle: {
    fontWeight: 700,
    fontSize: '16px',
  },

  // Classes de estilo do chip de risco do processo

  lowFragility: {
    backgroundColor: '#087DF138',
    color: '#007bff',
    fontWeight: 700,
  },
  mediumFragility: {
    backgroundColor: '#E8F5E9',
    color: '#4CAF50',
    fontWeight: 700,
  },
  highFragility: {
    backgroundColor: '#FFF3E0',
    color: '#F4A460',
    fontWeight: 700,
  },
  severeFragility: {
    backgroundColor: '#FEEBEE',
    color: '#E53935',
    fontWeight: 700,
  },
})

export default styles
