import React from 'react'
import { DatePicker } from '@material-ui/pickers'
import { TextField, Grid, Typography, Box, Divider } from '@material-ui/core'

import { Label } from 'components'

import { Controller } from 'react-hook-form'

const CommunicationDate = ({ control }) => {
  return (
    <>
      <Grid item xs={12}>
        <Box px={2}>
          <Typography variant="h6">
            Informe as seguintes datas, sobre o incidente:
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Controller
            as={
              <DatePicker
                fullWidth
                format="DD/MM/yyyy"
                label="Quando ocorreu"
                inputVariant="outlined"
                onChange={(newValue) => ({ value: newValue })}
                disableFuture
                clearable
              />
            }
            control={control}
            name="occurred"
            mode="onChange"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Controller
            as={
              <DatePicker
                fullWidth
                format="DD/MM/yyyy"
                label="Quando tomou ciência"
                inputVariant="outlined"
                onChange={(newValue) => ({ value: newValue })}
                disableFuture
                clearable
              />
            }
            control={control}
            name="incidentAware"
            mode="onChange"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Controller
            as={
              <DatePicker
                fullWidth
                format="DD/MM/yyyy"
                label="Quando comunicou à ANPD"
                inputVariant="outlined"
                onChange={(newValue) => ({ value: newValue })}
                disableFuture
                clearable
              />
            }
            control={control}
            name="anpdNotificationDate"
            mode="onChange"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Controller
            as={
              <DatePicker
                fullWidth
                format="DD/MM/yyyy"
                label="Quando comunicou aos titulares"
                inputVariant="outlined"
                onChange={(newValue) => ({ value: newValue })}
                disableFuture
                clearable
              />
            }
            control={control}
            name="informedHolder"
            mode="onChange"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={1} px={2}>
          <Label
            title={
              'Justifique, se cabível, a não realização da comunicação completa á ANPD e aos titulares de dados ' +
              'afetados no prazo sugerido de 2 (dois) dias após a ciência do incidente:'
            }
          >
            <Controller
              as={
                <TextField
                  type="text"
                  rows={5}
                  multiline
                  color="primary"
                  variant="outlined"
                  fullWidth
                />
              }
              control={control}
              name="justificationNotNotifyingAnpd"
              mode="onBlur"
            />
          </Label>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={1} px={2}>
          <Label title="Se cabível, informe quando e a quais outras autoridades o incidente foi comunicado:">
            <Controller
              as={
                <TextField
                  type="text"
                  color="primary"
                  variant="outlined"
                  fullWidth
                />
              }
              control={control}
              name="informedOtherAuthority"
              mode="onBlur"
            />
          </Label>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box py={2}>
          <Divider />
        </Box>
      </Grid>
    </>
  )
}

export default CommunicationDate
