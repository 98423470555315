import { isEmpty } from 'lodash'

const responsableUserAction = (data) => {
  const responsableAction = []
  let specificText = ''

  if (isEmpty(data)) {
    return 'Nenhum responsável vinculado'
  }

  for (const obj of data) {
    const responsableUserName = obj?.responsableUser?.name
    if (!responsableAction.includes(responsableUserName)) {
      specificText += responsableUserName + 'lineBreak'
      responsableAction.unshift(responsableUserName)
    }
  }
  return specificText.replace(/lineBreak/g, '\n')
}

export default responsableUserAction
