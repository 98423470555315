import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
    width: '105px',
  },
}))

export default styles
