import components from '../components'

import { ratio } from '../helpers'

import {
  WIDTH as WIDTHPdf,
  HEIGHT as HEIGHTPdf,
  fontSize,
  PADDING,
} from '../constants'
import palette from 'theme/palette'

const chapterCover = (
  pdf,
  title,
  subtitle,
  customStyles,
  numberPage = false,
) => {
  const WIDTH = WIDTHPdf(pdf)
  const HEIGHT = HEIGHTPdf(pdf)

  pdf.addPage()
  pdf.setFont('Raleway-Bold')
  pdf.setFillColor(customStyles?.main)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')
  pdf.setFontSize(fontSize.title)
  pdf.setTextColor(customStyles?.textPrimary)
  var splited_title = pdf.splitTextToSize(title, ratio(1500))
  pdf.text(splited_title, PADDING, ratio(290))
  pdf.setFontSize(fontSize.subtitle)
  pdf.setFont('Raleway-Regular')
  var splited_subtitle = pdf.splitTextToSize(subtitle, ratio(1000))
  pdf.text(splited_subtitle, PADDING, HEIGHT - ratio(280))
  numberPage && components.numberPage(pdf, palette.white, true)
}

export default chapterCover
