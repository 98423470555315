const overviewPdfTable = (startY, overviewStats = {}) => {
  return {
    styles: {
      cellPadding: 0.5,
      fontSize: 14,
      halign: 'center',
      valign: 'middle',
      fontStyle: 'bold',
      lineWidth: 0.2,
      lineColor: [0, 0, 0],
    },
    startY: startY,
    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.5,
    head: [
      {
        processesApproved: 'PROCESSOS APROVADOS',
        processesRncs: 'RMCS DE PROCESSOS EM ABERTO',
        gapRncs: 'RMCS DE GAP ANALYSIS EM ABERTO',
        incidentsRncs: 'RMCS DE INCIDENTES EM ABERTO',
      },
    ],
    body: [overviewStats],
    margin: {
      bottom: 60,
      top: 40,
    },
  }
}

export default overviewPdfTable
