import React from 'react'

import { PaymentStep, BilletStep } from './components'
import { PaymentFeedbackScreen } from 'components/PreRegistration'

import useSubscription from 'hooks/useSubscription'

const Payment = ({ submitPayment }) => {
  const { activeSubStep } = useSubscription()

  const getContentStep = () => {
    return (
      [
        <PaymentStep submitPayment={submitPayment} />,
        <PaymentFeedbackScreen />,
        <BilletStep />,
      ][activeSubStep] || <></>
    )
  }

  return getContentStep()
}

export default Payment
