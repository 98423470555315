import React from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

const DialogExclusion = ({ open, setOpen, handleRemove }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Excluir o agente de tratamento</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box mt={1} mb={1}>
        <DialogContent>
          <Box>
            <Typography>
              Tem certeza que deseja excluir este agente de tratamento?
            </Typography>
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => handleRemove(true)}
        >
          Confirmar exclusão
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogExclusion.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleRemove: PropTypes.func,
}

DialogExclusion.defaultProps = {
  setOpen: () => {},
  handleRemove: () => {},
}

export default DialogExclusion
