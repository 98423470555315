import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Box,
  MenuItem,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MultiSelect from '@kenshooui/react-multi-select'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

import { Label } from 'components'
import DataProcessTemplateForm from 'views/DataProcesses/components/DataProcessTemplateForm'

import useDataProcess from 'hooks/useDataProcess'
import useAuth from 'hooks/useAuth'
import useDataProcessTemplateQuestion from 'hooks/useDataProcessTemplateQuestion'

import helpers from 'helpers'
import schema from '../../schema'
import useStyles from './styles'
import constants from 'constants/index'

const FormModal = ({ onSubmit, dataCollected }) => {
  const { titularCategories, dataCollectedOptions } = useDataProcess()
  const [selectedItems, changeSelectedItems] = useState(
    helpers.dataCollected.mountSelectedList(dataCollected?.collectedFields) ||
      [],
  )
  const [expanded, setExpanded] = useState(false)

  const { company } = useAuth()
  const {
    triggerValidation: triggerTemplateValidation,
    getValues: getTemplateValues,
  } = useDataProcessTemplateQuestion()
  const classes = useStyles()

  const handleChange = (selected) => {
    changeSelectedItems(selected)
  }

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const multiSelectSearch = (value) => (item) => {
    return (
      String(item.label).toLowerCase().includes(value.toLowerCase()) ||
      String(item.group).toLowerCase().includes(value.toLowerCase())
    )
  }

  const { errors, control, getValues, triggerValidation } = useForm({
    validationSchema: schema(
      company?.kind !== constants.userCompanies.KIND_TEMPLATE,
    ),
    defaultValues: {
      dataCollectedId: dataCollected?.id || '',
      titularAgeRanges:
        helpers.dataCollected.nameOfTitularAgeRange(
          dataCollected?.titularAgeRange,
        ) || [],
      holderNumber: dataCollected?.holderNumber || null,
      titularCategoryId: dataCollected?.titularCategory?.id,
      anotherPersonalNames: '',
      anotherComportamentalNames: '',
      anotherFinancialNames: '',
    },
  })

  const handleSubmit = async (e) => {
    e.preventDefault()

    const templateValidation = await triggerTemplateValidation()
    const dataCollectedValidation = await triggerValidation()

    if (templateValidation && dataCollectedValidation) {
      onSubmit(getValues(), getTemplateValues())
    }
  }

  return (
    <form onSubmit={handleSubmit} id="data-collected-form">
      <Grid
        container
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Label title="Categoria do titular" item>
            <Controller
              as={
                <TextField
                  select
                  color="primary"
                  variant="outlined"
                  error={!!errors.titularCategoryId}
                  helperText={errors?.titularCategoryId?.message}
                  fullWidth
                >
                  {titularCategories.map((titularCategory) => (
                    <MenuItem
                      key={titularCategory.id}
                      value={titularCategory.id}
                    >
                      <Typography>{titularCategory.name}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              }
              control={control}
              name="titularCategoryId"
              mode="onBlur"
            />
          </Label>
        </Grid>
        <Grid item xs={12}>
          <Label title="Faixa etária" item>
            <Controller
              control={control}
              name="titularAgeRanges"
              onChange={([, data]) => data}
              mode="onChange"
              as={
                <Autocomplete
                  multiple
                  options={constants.dataCollected.OPTION_AGE_RANGE}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) =>
                    option.id === value.id || value.id === 0
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={!!errors?.titularAgeRanges}
                      helperText={errors?.titularAgeRanges?.message}
                    />
                  )}
                />
              }
            />
          </Label>
        </Grid>
        <Grid item xs={12}>
          <Label title="Quantidade aproximada de titulares" item>
            <Controller
              as={
                <TextField
                  type="number"
                  color="primary"
                  variant="outlined"
                  error={!!errors.holderNumber}
                  helperText={errors?.holderNumber?.message ?? ''}
                  fullWidth
                  inputProps={{
                    min: 0,
                  }}
                />
              }
              control={control}
              name="holderNumber"
              mode="onBlur"
            />
          </Label>
        </Grid>
        <Grid item xs={12}>
          <Label title="Dado(s) Tratado(s)" lg={6} item xs={12} />
          <Controller
            name="dataCollectedMultiSelect"
            control={control}
            defaultValue={selectedItems}
            as={
              <MultiSelect
                messages={helpers.dataCollected.messagesMultiSelect()}
                items={dataCollectedOptions}
                withGrouping
                selectedItems={selectedItems}
                onChange={handleChange}
                filterFunction={multiSelectSearch}
                wrapperClassName={classes.multiSelect}
              />
            }
          />
        </Grid>
        <Grid container item xs={12}>
          <Box width="100%">
            <Accordion
              expanded={expanded === 'panel-another'}
              onChange={handleAccordionChange('panel-another')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-another-content"
                className={classes.accordionSummaryColor}
                id="panel-another-header"
              >
                <Grid container>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography className={classes.accordionHeader}>
                      Não encontrou o dado que procurava na lista acima?
                    </Typography>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Typography className={classes.secondaryAccordionHeader}>
                      Clique aqui e insira estes dados manualmente
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Box mt={1}>
                    <Box mb={2}>
                      <Typography variant="h6">
                        <strong>Importante!</strong> Antes de cadastrar qualquer
                        dado tratado manualmente certifique-se de que o mesmo
                        não está presente na lista acima.
                      </Typography>
                    </Box>
                    <Typography variant="subtitle1" color="textSecondary">
                      Outros dados tratados
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Informe outros dados tratados no processo (que não estão
                      listados acima), separando-os por vírgula (,)
                    </Typography>
                  </Box>
                  <Grid item xs={12}>
                    <Box mt={2} mr={2}>
                      <Label
                        title="Outros dados pessoais"
                        description="Ex: Nacionalidade, Idade"
                        lg={6}
                        item
                        xs={12}
                      />
                      <Controller
                        as={
                          <TextField
                            color="primary"
                            variant="outlined"
                            error={!!errors.anotherPersonalNames}
                            helperText={errors?.anotherPersonalNames?.message}
                            fullWidth
                          />
                        }
                        control={control}
                        name="anotherPersonalNames"
                        mode="onBlur"
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box mr={2}>
                      <Label
                        title="Outros dados financeiros"
                        description="Ex: Validade do cartão de crédito, Renda mensal"
                        lg={6}
                        item
                        xs={12}
                      />
                      <Controller
                        as={
                          <TextField
                            color="primary"
                            variant="outlined"
                            error={!!errors.anotherFinancialNames}
                            helperText={errors?.anotherFinancialNames?.message}
                            fullWidth
                          />
                        }
                        control={control}
                        name="anotherFinancialNames"
                        mode="onBlur"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mr={2}>
                      <Label
                        title="Outros dados comportamentais"
                        description="Ex: Hábitos alimentares, Pratica atividades físicas"
                        lg={6}
                        item
                        xs={12}
                      />
                      <Controller
                        as={
                          <TextField
                            color="primary"
                            variant="outlined"
                            error={!!errors.anotherComportamentalNames}
                            helperText={
                              errors?.anotherComportamentalNames?.message
                            }
                            fullWidth
                          />
                        }
                        control={control}
                        name="anotherComportamentalNames"
                        mode="onBlur"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DataProcessTemplateForm step="data_collected" />
        </Grid>
      </Grid>
    </form>
  )
}

FormModal.propTypes = {
  onSubmit: PropTypes.func,
}

FormModal.defaultProps = {
  onSubmit: () => {},
}

export default FormModal
