import React, { Fragment, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Calendar as CalendarIcon,
  Plus as PlusIcon,
  Trash as TrashIcon,
} from 'react-feather'
import { Box, Button, FormHelperText, Grid, TextField } from '@material-ui/core'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'
import formatters from 'helpers/formatters'

const PaymentBillet = ({ submit, loading, paymentContract }) => {
  const [newEmail, setNewEmail] = useState('')
  const snackbar = useSnackbar()

  const { control, register, errors, handleSubmit, setValue, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      nextDateAdvance: formatters.date(paymentContract?.nextDateAdvance),
      invoiceAmount: paymentContract?.price,
      billingEmails: paymentContract?.billingEmails,
    },
  })

  const emails = watch('billingEmails')

  const handleChangeEmail = (event) => setNewEmail(event.target.value)

  const handleAddEmail = () => {
    if (newEmail) {
      setValue('billingEmails', [...emails, { email: newEmail }])
      setNewEmail('')
    }
  }

  const handleRemoveEmail = (index) => {
    const updatedEmails = emails.filter((_, i) => i !== index)
    setValue('billingEmails', updatedEmails)
  }

  const onSubmit = async (data) => {
    try {
      await submit({
        billingEmails: data?.billingEmails,
        methodPayment: 'billet',
      })

      snackbar.open({
        message: 'Método de pagamento atualizado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    register('billingEmails')
  }, [register])

  return (
    <Grid
      container
      spacing={2}
      component="form"
      id="blank-slip-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="nextDateAdvance"
          onChange={([event]) =>
            helpers.formatters.float.input(event.target.value)
          }
          as={
            <TextField
              variant="outlined"
              label="Data de emissão"
              error={!!errors?.nextDateAdvance}
              helperText={<>{errors?.nextDateAdvance?.message}</>}
              InputProps={{
                endAdornment: <CalendarIcon />,
              }}
              disabled
              fullWidth
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="invoiceAmount"
          onChange={([event]) =>
            helpers.formatters.float.input(event.target.value)
          }
          as={
            <TextField
              variant="outlined"
              label="Valor da cobrança"
              error={!!errors?.invoiceAmount}
              helperText={<>{errors?.invoiceAmount?.message}</>}
              disabled
              InputProps={{
                inputMode: 'decimal',
                startAdornment: 'R$',
              }}
              fullWidth
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} md={9}>
            <TextField
              variant="outlined"
              label="Email de cobrança"
              error={!!errors?.emails}
              value={newEmail}
              onChange={handleChangeEmail}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            component={Box}
            pl={2}
            display="flex"
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlusIcon />}
              fullWidth
              onClick={handleAddEmail}
              disabled={loading}
            >
              Adicionar
            </Button>
          </Grid>
          {!!errors?.billingEmails && (
            <Grid item xs={12} component={Box} pl={2}>
              <FormHelperText error={!!errors?.billingEmails}>
                {errors?.billingEmails?.message}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </Grid>
      {emails?.map((email, index) => (
        <Fragment key={index}>
          <Grid item xs={12} sm={8} md={9}>
            <TextField
              variant="outlined"
              size="small"
              value={email?.email}
              label={`${index + 1}ª Email definido`}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            component={Box}
            pl={2}
            display="flex"
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<TrashIcon />}
              fullWidth
              disabled={loading}
              onClick={() => handleRemoveEmail(index)}
            >
              Excluir
            </Button>
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          type="submit"
          form="blank-slip-form"
          disabled={loading}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  )
}

export default PaymentBillet
