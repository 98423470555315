import templates from '../../templates'
import components from '../../components'

import palette from 'theme/palette'

import { PADDING } from '../../constants'

import { ratio } from '../../helpers'

const glossary = (pdf, date, customStyles) => {
  templates.chapterCover(
    pdf,
    'GLOSSÁRIO',
    'Síntese das principais informações das funcionalidades',
    customStyles,
  )

  pdf.addPage()

  components.lineWithDate(pdf, date)

  components.numberAndTitle(pdf, '2', 'Glossário')

  pdf.setFont('Roboto-Bold')
  pdf.setFontSize(ratio(48))
  pdf.setTextColor(palette.subscription.primary.main)

  pdf.text('     LGPD:', PADDING, ratio(931), null, null, 'left')
  pdf.text('     RMC:', PADDING, ratio(1005), null, null, 'left')
  pdf.text('     RMC pendente:', PADDING, ratio(1079), null, null, 'left')
  pdf.text('     RMC no prazo:', PADDING, ratio(1269), null, null, 'left')
  pdf.text('     RMC vencido:', PADDING, ratio(1420), null, null, 'left')
  pdf.text('     RMC risco assumido:', PADDING, ratio(1615), null, null, 'left')
  pdf.text('     RMC finalizado:', PADDING, ratio(1780), null, null, 'left')

  pdf.setFont('Roboto-Regular')

  pdf.text(
    '     Lei Geral de Proteção de Dados.',
    PADDING + ratio(110),
    ratio(931),
    null,
    null,
    'left',
  )

  pdf.text(
    '     Registro de melhoria contínua.',
    PADDING + ratio(100),
    ratio(1005),
    null,
    null,
    'left',
  )

  components.paragraph(
    pdf,
    '                                  Indica que a melhoria contínua proposta ou planejada ainda não foi iniciada ou está aguardando revisão do usuário. Essa etapa envolve a identificação de oportunidades de aprimoramento e a definição de ações específicas para implementar as melhorias.',
    ratio(1079),
    palette.subscription.primary.main,
  )

  components.paragraph(
    pdf,
    '                                 Refere-se às melhorias contínuas que estão sendo executadas dentro do prazo estabelecido. Nessa fase, as ações planejadas estão em andamento, e é importante monitorar o progresso para garantir que sejam concluídas conforme o cronograma.',
    ratio(1269),
    palette.subscription.primary.main,
  )

  components.paragraph(
    pdf,
    '                                Indica que o prazo estabelecido para a implementação das melhorias contínuas foi ultrapassado. Nesse caso, é necessário investigar os motivos do atraso e tomar medidas corretivas para retomar o progresso e evitar que as melhorias sejam abandonadas.',
    ratio(1420),
    palette.subscription.primary.main,
  )

  components.paragraph(
    pdf,
    '                                             Quando o status é classificado como "Risco Assumido", significa que a decisão foi tomada conscientemente de não prosseguir com a implementação das melhorias contínuas devido a razões específicas ou à aceitação dos riscos envolvidos.',
    ratio(1615),
    palette.subscription.primary.main,
  )

  components.paragraph(
    pdf,
    '                                   Indica que as ações de melhoria contínua foram concluídas com sucesso. Nessa etapa, as ações planejadas foram implementadas, e os resultados e benefícios esperados foram alcançados, fortalecendo as práticas de conformidade com a LGPD.',
    ratio(1780),
    palette.subscription.primary.main,
  )
  components.numberPage(pdf, palette.black)
}

export default glossary
