import React, { useCallback, useEffect, useState } from 'react'

import { Box, Button, Typography, Link } from '@material-ui/core'
import { Upload as UploadIcon, Download as DownloadIcon } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import { routes } from 'Routes'

import * as service from 'service'
import { getBaseURL } from 'service/env'

import {
  Permitted,
  Select as SelectComponent,
  BaseDialog,
  LoadingFeedback,
  ProfileSection,
} from 'components'
import { AlertDialog } from './components'

import useSnackbar from 'hooks/useSnackbar'
import constants from 'constants/index'
import useStyles from './styles'
import schemaUser from './schema'

const ImportUsersDialog = ({ open, setOpen, profiles, isLoading, refresh }) => {
  const [file, setFile] = useState(null)
  const [profile, setProfile] = useState({})
  const [loading, setLoading] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()

  const history = useHistory()

  const { errors, watch, control, handleSubmit, setValue } = useForm({
    validationSchema: schemaUser(),
    mode: 'onChange',
  })

  const selectedProfile = () => {
    const profileId = watch('profileId')

    if (!!profileId && !!profiles) {
      const profile = profiles.find((profile) => profile.id === profileId)

      if (!!profile) {
        return setProfile(profile)
      }
    }

    return setProfile({})
  }

  const downloadModelUsers = () => {
    window.open(
      `${getBaseURL('dponet')}/modelo_importacao_de_usuarios.xlsx/`,
      '_blank',
    )
  }

  const onDrop = useCallback((acceptedFile) => {
    setFile(acceptedFile[0])
    setValue('fileToImport', acceptedFile[0])
    // eslint-disable-next-line
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const { ref, ...rootProps } = getRootProps()

  const validateFile = () => {
    const validTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ]
    return file && validTypes.includes(file.type)
  }

  const onSubmit = async (data) => {
    if (validateFile()) {
      setLoading(true)
      await service.dponet.users.importUsers({
        fileToImport: file,
        profileId: data.profileId,
      })

      setLoading(false)
      setOpenSuccess(true)
    } else {
      setLoading(false)
      snackbar.open({
        message: 'Por favor selecione um documento Excel (xlsx/xls)',
        variant: 'error',
      })
    }
    setFile(null)
  }

  const handleCloseConfirm = () => {
    setOpenSuccess(false)
    setOpen(false)
    refresh()
  }

  useEffect(() => {
    selectedProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('profileId')])

  const navigateToCreateProfile = () => {
    history.push(routes.profiles.new)
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      closeButtonText="Cancelar"
      actionButtonText="Importar"
      formRef="user-create-form"
      title="Importação de Usuários"
    >
      <LoadingFeedback open={loading} />
      <Box className={classes.box}>
        <Typography align="justify" className={classes.typography}>
          Para importar usuários, é necessário seguir o modelo de planilha que
          disponibilizamos. Caso não seja seguido, podem ocorrer falhas na
          importação dos dados.
        </Typography>
        <Link onClick={downloadModelUsers}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className={classes.buttonDownloadModel}
          >
            <DownloadIcon />
            <Typography>Baixar modelo</Typography>
          </Button>
        </Link>{' '}
        <LoadingFeedback open={loading || isLoading} />
        <form onSubmit={handleSubmit(onSubmit)} id="user-create-form">
          <Box>
            <Typography variant="h5">Perfil de usuário e permissão</Typography>
            <Box mt={1} mb={2}>
              <Box className={classes.selectProfileBox}>
                <Controller
                  control={control}
                  name="profileId"
                  mode="onBlur"
                  as={
                    <SelectComponent
                      label="Selecione o perfil de usuário"
                      items={profiles || []}
                      error={!!errors.profileId}
                      helperText={errors?.profileId?.message}
                    />
                  }
                />
              </Box>
              <Permitted tag={constants.permissions.PROFILES.CREATE}>
                <Box pt={1}>
                  <Typography variant="body1" color="textPrimary">
                    Não encontrou a opção? Clique{' '}
                    <Link
                      onClick={navigateToCreateProfile}
                      className={classes.link}
                    >
                      aqui
                    </Link>{' '}
                    para adicionar
                  </Typography>
                </Box>
              </Permitted>
            </Box>
            {!isEmpty(profile) && (
              <Box mt={2}>
                <ProfileSection
                  simplifiedProfile={profile}
                  profileId={profile.id}
                />
              </Box>
            )}
          </Box>
          <Controller
            control={control}
            name="fileToImport"
            mode="onChange"
            as={
              <Box
                {...rootProps}
                className={classes.inputImageContainer}
                rootref={ref}
                mt={2}
              >
                <input {...getInputProps()} />

                <Box m={1} mt={2}>
                  <UploadIcon />
                </Box>
                <Box m={1} mb={2}>
                  <Typography>
                    Arraste e solte ou selecione o documento com os usuários a
                    serem importados
                  </Typography>
                </Box>
              </Box>
            }
          />
          {file && (
            <Box>
              <Typography>Arquivo:</Typography>
              <Typography variant="h5"> {file.path}</Typography>
            </Box>
          )}
        </form>
        <AlertDialog
          open={openSuccess}
          setOpen={setOpenSuccess}
          isRegistrationInProgress={true}
          title="Cadastro em andamento!"
          subtitle="Informaremos por e-mail assim que todos os usuários forem cadastrados!"
          onClose={handleCloseConfirm}
        />
      </Box>
    </BaseDialog>
  )
}

ImportUsersDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.bool,
  refresh: PropTypes.func,
}

export default ImportUsersDialog
