import React, { useState } from 'react'
import isEmpty from 'lodash'
import { Divider, Box, Button } from '@material-ui/core'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Controller, FormContext, useForm } from 'react-hook-form'

import { DialogInformation } from 'components'
import {
  DataCollected,
  DataTreatment,
  DataLifeCycle,
  GeneralInformation,
  NecessityProportionality,
  DataTreatmentAgent,
} from '../'
import { LegalFrameworks, Description, Fragilities } from '../../'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import useDataProcessTemplateQuestion from 'hooks/useDataProcessTemplateQuestion'

import DataTreatmentModalProvider from 'providers/DataTreatmentModalProvider'
import DataCollectedModalProvider from 'providers/DataCollectedModalProvider'
import DataLifeCycleProvider from 'providers/DataLifeCycleProvider'
import DocumentsProvider from 'providers/DocumentsProvider'
import DataProcessProvider from 'providers/DataProcessProvider'

import { routes } from 'Routes'
import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'
import schemaGeneralInformation from '../GeneralInformations/schema'

const Form = ({ dataProcess, loading, refresh }) => {
  const { company } = useAuth()
  const {
    triggerValidation: triggerTemplateValidation,
    getValues: getTemplateValues,
  } = useDataProcessTemplateQuestion()

  const [openDialog, setOpenDialog] = useState(false)
  const [dialogMessage, setDialogMessage] = useState('')
  const [volumetryType, setVolumetryType] = useState(
    dataProcess?.storageMode || constants.dataProcess.STORY_MODE_UNDEFINED_TYPE,
  )
  const dataLaws = dataProcess?.laws?.map((law) => law?.id)
  const [laws, setLaws] = useState(dataLaws || [])

  const history = useHistory()

  const defaultSourceDescription = helpers.dataProcess.convertSourceDescription(
    dataProcess?.sourceDescription,
    dataProcess?.dataProcessSources || [],
  )

  const formMethods = useForm({
    defaultValues: {
      dataProcessId: dataProcess.id,
      departmentId: dataProcess?.department?.id ?? '',
      protectionId: dataProcess?.protection?.id ?? '',
      name: dataProcess.name ?? '',
      code: dataProcess.code ?? '',
      finality: dataProcess.finality ?? '',
      value: dataProcess.value ?? '',
      volumetry: dataProcess.volumetry ?? '',
      deadlineJustification: dataProcess.deadlineJustification ?? '',
      sourceDescription: '',
      sourceDescriptionAnother: '',
      dataProcessDataSources: dataProcess?.dataProcessDataSources
        ? helpers.dataProcess.covertDataSourcesToOptions(
            dataProcess?.dataProcessDataSources,
          )
        : [],
    },
    validationSchema: schemaGeneralInformation(volumetryType, company),
  })

  const snackbar = useSnackbar()

  const handleOpenDialog = (message) => {
    setDialogMessage(message)
    setOpenDialog(true)
    return true
  }

  const onSubmit = async (data, template) => {
    if (
      helpers.necessityAndProportionality.verifyUnnecessaryScopes(
        data,
        handleOpenDialog,
      )
    ) {
      return
    }

    const documents = data.documents
    delete data.documents

    const responseCollecteds = await service.dponet.dataCollecteds.list({
      dataProcessId: dataProcess.id,
    })

    const responseTreatment = await service.dponet.dataTreatments.list({
      dataProcessId: dataProcess.id,
    })

    const responseLifeCycle = await service.dponet.dataLifeCycles.get({
      dataProcessId: dataProcess.id,
    })

    const isEmptyObject = (obj) => !Object.keys(obj).length

    responseCollecteds.data.dataCollecteds.forEach(async (dataCollected) => {
      try {
        let dataCollecteds =
          helpers.dataCollected.mountNecessityAndProportionalityParams(
            data,
            dataCollected,
          )
        if (dataCollecteds.collectedFields.length > 0) {
          await service.dponet.dataCollecteds.put({
            dataProcessId: dataProcess?.id,
            dataCollectedId: dataCollected?.id,
            dataCollecteds,
          })
        }
      } catch (error) {
        snackbar.open({
          message:
            helpers.functions.dig(error.response, 'error') ??
            'Ocorreu um erro, tente novamente!',
          variant: 'error',
        })
      }
    })

    responseTreatment.data.dataTreatments.forEach(async (dataTreat) => {
      try {
        let dataTreatment =
          helpers.dataTreatment.mountNecessityAndProportionalityParams(
            data,
            dataTreat.id,
          )
        if (!isEmptyObject(dataTreatment)) {
          await service.dponet.dataTreatments.put({
            dataProcessId: dataProcess.id,
            dataTreatmentId: dataTreat.id,
            dataTreatment,
          })
        }
      } catch (error) {
        snackbar.open({
          message:
            helpers.functions.dig(error.response, 'error') ??
            'Ocorreu um erro, tente novamente!',
          variant: 'error',
        })
      }
    })

    responseLifeCycle.data.lifeCycles.forEach(async (dataLife) => {
      try {
        let dataLifeCycle =
          helpers.dataLifeCycle.mountNecessityAndProportionalityParams(
            data,
            dataLife.id,
          )

        if (!isEmptyObject(dataLifeCycle)) {
          await service.dponet.dataLifeCycles.put({
            dataProcessId: dataProcess.id,
            dataLifeCycleId: dataLife.id,
            lifeCycle: {
              retentionFinality: dataLife.retentionFinality,
              storageLocationId: dataLife.storageLocation.id,
              storageTimeId: dataLife.storageTime.id,
              discardModeId: dataLife.discardMode.id,
              internalAllocationModeId: dataLife.internalAllocationMode.id,
              protectionId: dataLife.protection.id,
              recoveryId: dataLife.recovery.id,
              value: dataLife?.value,
              volumetry: dataLife?.volumetry,
              ...dataLifeCycle,
            },
          })
        }
      } catch (error) {
        snackbar.open({
          message:
            helpers.functions.dig(error.response, 'error') ??
            'Ocorreu um erro, tente novamente!',
          variant: 'error',
        })
      }
    })

    try {
      if (data.sourceDescription === constants.dataProcess.ANOTHER_SOURCE) {
        data.sourceDescription = data.sourceDescriptionAnother
      }
      await service.dponet.dataProcesses
        .put({
          dataProcessId: dataProcess.id,
          data_process: {
            ...data,
            storageMode: volumetryType,
            laws: laws.map((law) => ({
              id: law,
            })),
            dataProcessTemplateQuestions: template,
          },
        })
        .then(async () => {
          if (documents !== '') {
            await service.dponet.documents.addDocument({
              dataProcessId: dataProcess.id,
              files: document.getElementById('documents').files,
            })
          }
          snackbar.open({
            message: 'Processamento atualizado com sucesso!',
            variant: 'success',
          })
          history.push(`/data-processes/show/${dataProcess.id}`)
        })
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.error?.message ||
          `Verifique se os campos ${
            isEmpty(laws) ? 'do tempo de armazenamento' : 'de origem dos dados'
          } estão devidamente preenchidos ou tente novamente!`,
        variant: 'error',
      })
    }
  }

  const handleReturn = () => {
    history.push(
      reverse(routes.dataProcess.view, { dataProcessId: dataProcess.id }),
    )
  }

  const { control, triggerValidation, getValues } = formMethods

  const handleSubmit = async (e) => {
    e.preventDefault()

    const templateValidation = await triggerTemplateValidation()
    const generalInformationValidation = await triggerValidation()

    if (templateValidation && generalInformationValidation) {
      onSubmit(getValues(), getTemplateValues())
    }
  }

  return (
    <>
      <FormContext {...formMethods}>
        <form onSubmit={handleSubmit}>
          <DataProcessProvider>
            <GeneralInformation
              dataProcess={dataProcess}
              isLoading={loading}
              defaultSourceDescription={defaultSourceDescription}
              sourceValues={dataProcess?.dataProcessDataSources || []}
              volumetryType={volumetryType}
              setVolumetryType={setVolumetryType}
              laws={laws}
              setLaws={setLaws}
            />
          </DataProcessProvider>
        </form>
      </FormContext>
      <Divider />
      <Description dataProcess={dataProcess} />
      <Divider />
      <DataCollectedModalProvider
        dataCollecteds={dataProcess.dataCollecteds}
        dataProcessesId={dataProcess.id}
      >
        <DocumentsProvider documents={dataProcess.documents}>
          <FormContext {...formMethods} onSubmit>
            <DataCollected
              dataProcessId={dataProcess.id}
              isLoading={loading}
              refresh={refresh}
            />
          </FormContext>
        </DocumentsProvider>
        <Divider />
        <DataTreatmentModalProvider
          dataTreatments={dataProcess.dataTreatments}
          dataProcessId={dataProcess.id}
        >
          <DataTreatment dataProcessId={dataProcess.id} isLoading={loading} />
          <Divider />
          <DataLifeCycleProvider
            dataLifeCycles={dataProcess.lifeCycles}
            dataProcessId={dataProcess.id}
          >
            <DataTreatmentAgent dataProcessId={dataProcess?.id} />
            <Divider />
            <DataLifeCycle data={dataProcess} isLoading={loading} />
            <Divider />
            <Box my={3}>
              <FormContext {...formMethods}>
                <form onSubmit={handleSubmit}>
                  <NecessityProportionality
                    isLoading={loading}
                    dataProcess={dataProcess}
                    type="edit"
                  />
                </form>
              </FormContext>
            </Box>
          </DataLifeCycleProvider>
        </DataTreatmentModalProvider>
      </DataCollectedModalProvider>
      <Fragilities dataProcessId={dataProcess?.id} />
      <LegalFrameworks data={dataProcess} isLoading={loading} />
      <form onSubmit={handleSubmit}>
        <Controller name="dataProcessId" control={control} as={<></>} />
        <Box mt={2} display="flex" justifyContent="flex-start">
          <Box pr={1}>
            <Button type="button" variant="outlined" onClick={handleReturn}>
              Voltar
            </Button>
          </Box>
          <Box>
            <Button color="primary" type="submit" variant="contained">
              Salvar
            </Button>
          </Box>
        </Box>
      </form>
      <DialogInformation
        open={openDialog}
        setOpen={setOpenDialog}
        title="Necessidade e proporcionalidade"
        description={dialogMessage}
      />
    </>
  )
}

Form.propTypes = {
  dataProcess: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  refresh: PropTypes.func,
}

Form.defaultProps = {
  loading: false,
}

export default Form
