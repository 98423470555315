const LEGITIMATE_INTEREST = 'Legítimo interesse do controlador ou de terceiro'
const FRAUD_PREVENTION_HOLDER_SECURITY =
  'Prevenção à fraude e segurança do titular'
const LEGITIMATE_INTEREST_SHORTNED = 'Legítimo interesse'

const legalFrameworks = {
  FRAUD_PREVENTION_HOLDER_SECURITY,
  LEGITIMATE_INTEREST,
  LEGITIMATE_INTEREST_SHORTNED,
}

export default legalFrameworks
