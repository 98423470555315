import React, { useState, Children } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import {
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'

import { KeyboardDatePicker } from '@material-ui/pickers'

import moment from 'moment'
import humps from 'humps'

import { SorterInput } from 'components'

import styles from './styles'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const Filters = ({
  filter,
  defaultOrder,
  children,
  isDataProcess,
  alphabeticalOrder,
  alphabeticalFieldOrder,
}) => {
  const classes = useStyles()
  const [values, setValues] = useState(() => {
    let filters = filter.filters
    delete filters['page']
    return filters
  })

  const setDefaultOrderValue = () => {
    switch ({ ...filter.filters }.order) {
      case 'created_at DESC':
        return constants.orderFilter.NEWLY_CREATED
      case 'created_at ASC':
        return constants.orderFilter.OLDER_CREATED
      case 'updated_at DESC':
        return constants.orderFilter.NEWLY_UPDATED
      case 'updated_at ASC':
        return constants.orderFilter.OLDER_UPDATED
      case `${alphabeticalFieldOrder} ASC`:
        return constants.orderFilter.ALPHABETICAL_A_TO_Z
      case `${alphabeticalFieldOrder} DESC`:
        return constants.orderFilter.ALPHABETICAL_Z_TO_A
      default:
        return defaultOrder
    }
  }

  const { register, handleSubmit } = useForm()
  const [minDates, setMinDates] = useState({})
  const [maxDates, setMaxDates] = useState({})
  const [orderValue, setOrderValue] = useState(setDefaultOrderValue())

  const handleChange = (e) => {
    // Regras para alteracoes do entityType na tela de RNC
    if (e?.target?.name === 'finality') {
      handleChangeRNCEntityType(e)
    } else {
      setValues({ ...values, [e.target.name]: e.target.value })
    }
  }

  const handleMultipleChange = (selectedValues, targetName) => {
    setValues({
      ...values,
      [targetName]: selectedValues.map((value) => {
        return value.id
      }),
    })
  }

  const handleChangeOrder = (event) => {
    const filters = filter.filters
    delete filters.order

    switch (event.target.value) {
      case constants.orderFilter.NEWLY_CREATED:
        setOrderValue(constants.orderFilter.NEWLY_CREATED)
        filter.setFilters({ order: 'created_at DESC', ...filters })
        break
      case constants.orderFilter.OLDER_CREATED:
        setOrderValue(constants.orderFilter.OLDER_CREATED)
        filter.setFilters({ order: 'created_at ASC', ...filters })
        break
      case constants.orderFilter.NEWLY_UPDATED:
        setOrderValue(constants.orderFilter.NEWLY_UPDATED)
        filter.setFilters({ order: 'updated_at DESC', ...filters })
        break
      case constants.orderFilter.ALPHABETICAL_A_TO_Z:
        setOrderValue(constants.orderFilter.ALPHABETICAL_A_TO_Z)
        filter.setFilters({
          order: `${alphabeticalFieldOrder} ASC`,
          ...filters,
        })
        break
      case constants.orderFilter.ALPHABETICAL_Z_TO_A:
        setOrderValue(constants.orderFilter.ALPHABETICAL_Z_TO_A)
        filter.setFilters({
          order: `${alphabeticalFieldOrder} DESC`,
          ...filters,
        })
        break
      default:
        setOrderValue(constants.orderFilter.OLDER_UPDATED)
        filter.setFilters({ order: 'updated_at ASC', ...filters })
        break
    }

    filter.setDrawerOpen(false)
  }

  const getMultipleSelectedOptions = (fieldName, options, compareByName) => {
    var selectedOptions = []

    if (!values[fieldName] || !options) return selectedOptions

    if (typeof values[fieldName] === 'object') {
      values[fieldName].map((selectedValue) => {
        return options.map((option) => {
          if (compareByName) {
            if (option.name === selectedValue) {
              return selectedOptions.push(option)
            }
          } else if (parseInt(option.id) === parseInt(selectedValue)) {
            return selectedOptions.push(option)
          }

          return null
        })
      })
    } else {
      options.map((option) => {
        if (parseInt(option.id) === parseInt(values[fieldName])) {
          return selectedOptions.push(option)
        }

        return null
      })
    }

    return selectedOptions
  }

  const handleChangeRNCEntityType = (e) => {
    if (
      e?.target?.value === constants.nonComplianceReports.FRAGILITY_FINALITY ||
      e?.target?.value === constants.nonComplianceReports.NECESSARY_FINALITY ||
      e?.target?.value ===
        constants.nonComplianceReports.FRAGILITY_AND_NECESSARY_FINALITY
    ) {
      setValues({
        ...values,
        entityType: constants.nonComplianceReports.ENTITY_DATA_PROCESS,
        [e.target.name]: e.target.value,
      })
    }

    if (e?.target.value === constants.nonComplianceReports.QUESTION_FINALITY) {
      setValues({
        ...values,
        entityType: constants.nonComplianceReports.ENTITY_QUESTION,
        [e.target.name]: e.target.value,
      })
    }

    if (e?.target?.value === constants.nonComplianceReports.INCIDENT_FINALITY) {
      setValues({
        ...values,
        entityType: constants.nonComplianceReports.ENTITY_INCIDENT,
        [e.target.name]: e.target.value,
      })
    }
  }

  const onSubmit = () => {
    filter.setFilters(values)
    filter.setDrawerOpen(false)
  }

  const clearFilters = () => {
    Children.toArray(children).map((field) => {
      if (field?.props?.multipleselectinput) {
        return setValues({ [field?.props?.name]: [] })
      }
      return setValues({ [field?.props?.name]: '' })
    })
    filter.setFilters('')
    filter.setDrawerOpen(false)
  }

  const setDateInterval = (date, props) => {
    const formattedProps = humps.camelizeKeys(props)
    const formattedDate = moment(date).format('YYYY-MM-DD')

    if (!formattedProps.dataMin && !formattedProps.dataMax) return

    if (formattedProps.dataMin) {
      return setMinDates({
        ...minDates,
        [formattedProps.dataTarget]: formattedDate,
      })
    }

    if (formattedProps.dataMax) {
      return setMaxDates({
        ...maxDates,
        [formattedProps.dataTarget]: formattedDate,
      })
    }
  }

  const getSelectedOption = (fieldName, options) => {
    return (
      options?.find(
        (option) => parseInt(option.id) === parseInt(values[fieldName]),
      ) || null
    )
  }

  const willClearField = (fieldName) => {
    const newValues = values
    delete newValues[fieldName]

    setValues(newValues)
  }

  return (
    <Drawer
      anchor="right"
      open={filter.drawerOpen}
      onClose={() => filter.setDrawerOpen(false)}
    >
      <Box px={2} py={4} className={classes.root}>
        <Typography variant="h4" gutterBottom>
          Filtros
        </Typography>
        <Box display="flex" alignItems="center">
          <Box>
            <Typography>Ordenar por</Typography>
          </Box>
          <FormControl>
            <Select
              id="order-select"
              value={orderValue}
              onChange={handleChangeOrder}
              input={<SorterInput />}
            >
              <MenuItem value={constants.orderFilter.NEWLY_CREATED}>
                <Typography variant="body1" color="secondary">
                  Criação recente
                </Typography>
              </MenuItem>
              <MenuItem value={constants.orderFilter.OLDER_CREATED}>
                <Typography variant="body1" color="secondary">
                  Criação antiga
                </Typography>
              </MenuItem>
              <MenuItem value={constants.orderFilter.NEWLY_UPDATED}>
                <Typography variant="body1" color="secondary">
                  Atualização recente
                </Typography>
              </MenuItem>
              <MenuItem value={constants.orderFilter.OLDER_UPDATED}>
                <Typography variant="body1" color="secondary">
                  Atualização antiga
                </Typography>
              </MenuItem>
              {alphabeticalOrder && (
                <MenuItem value={constants.orderFilter.ALPHABETICAL_A_TO_Z}>
                  <Typography variant="body1" color="secondary">
                    De A-Z
                  </Typography>
                </MenuItem>
              )}
              {alphabeticalOrder && (
                <MenuItem value={constants.orderFilter.ALPHABETICAL_Z_TO_A}>
                  <Typography variant="body1" color="secondary">
                    De Z-A
                  </Typography>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          {Children.toArray(children).map(
            (field, index) =>
              (field &&
                field.props.textfieldinput &&
                field.props.skip !== 'true' && (
                  <div key={index}>
                    <TextField
                      SelectProps={{ native: true }}
                      margin="dense"
                      className={classes.filterField}
                      fullWidth
                      disabled={field.props.disabled}
                      value={values[field.props.name] || ''}
                      select={field.type === 'select'}
                      {...field.props}
                      onChange={(event) => {
                        if (field.props.onChange) field.props.onChange(event)
                        if (field.props.willclearfield)
                          willClearField(field.props.willclearfield)
                        return handleChange(event)
                      }}
                      inputProps={{
                        ref: register,
                        ...field.props.inputProps,
                      }}
                      key={index}
                    ></TextField>
                  </div>
                )) ||
              (field && field.props.datepickerinput && (
                <div key={index}>
                  <KeyboardDatePicker
                    margin="dense"
                    className={classes.filterField}
                    fullWidth
                    format="DD/MM/yyyy"
                    minDate={minDates[field.props.name]}
                    minDateMessage="A data inicial não poser ser maior que a data final"
                    maxDate={maxDates[field.props.name]}
                    maxDateMessage="A data final não pode ser menor que a data inicial"
                    value={values[field.props.name] || null}
                    onChange={(e) => {
                      setDateInterval(e, field.props)
                      setValues({
                        ...values,
                        [field.props.name]: moment(e).utc(false).format(),
                      })
                    }}
                    {...field.props}
                    inputProps={{
                      ref: register,
                      ...field.props.inputProps,
                    }}
                    key={index}
                  ></KeyboardDatePicker>
                </div>
              )) ||
              (field?.props?.multipleselectinput && (
                <div key={index}>
                  <Autocomplete
                    options={field?.props?.optionvalues}
                    className={classes.filterSelectField}
                    value={getMultipleSelectedOptions(
                      field.props.name,
                      field.props.optionvalues,
                      field.props.compareByName,
                    )}
                    ChipProps={{
                      size: 'small',
                    }}
                    onChange={(event, newInputValue) => {
                      handleMultipleChange(newInputValue, field?.props?.name)
                    }}
                    multiple
                    inputprops={{
                      ref: register,
                      ...field.props.inputProps,
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={field?.props?.label}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              )) ||
              (field?.props?.autocompleteselectinput && (
                <div key={index}>
                  <Autocomplete
                    options={field?.props?.optionvalues}
                    className={classes.filterSelectField}
                    value={getSelectedOption(
                      field.props.name,
                      field.props.optionvalues,
                    )}
                    ChipProps={{
                      size: 'small',
                    }}
                    onChange={(event, newInputValue) => {
                      if (field?.props?.onChange)
                        field.props.onChange(newInputValue?.id)
                      setValues({
                        ...values,
                        [field?.props?.name]: newInputValue?.id,
                      })
                      return
                    }}
                    inputprops={{
                      ref: register,
                      ...field.props.inputProps,
                    }}
                    getOptionLabel={(option) => option.name}
                    autoSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={field?.props?.label}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              )),
          )}
          <Button
            className={classes.filterField}
            variant="outlined"
            color="default"
            fullWidth
            type="button"
            onClick={clearFilters}
            id={isDataProcess ? 'id-indexdataprocess-button-cleanfilter' : ''}
          >
            Limpar Filtros
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.filterField}
            fullWidth
            id={isDataProcess ? 'id-indexdataprocess-button-filter' : ''}
          >
            Filtrar
          </Button>
        </form>
      </Box>
    </Drawer>
  )
}

Filters.propTypes = {
  filter: PropTypes.object,
  defaultOrder: PropTypes.string,
  children: PropTypes.node,
  isDataProcess: PropTypes.bool,
  alphabeticalOrder: PropTypes.bool,
  alphabeticalFieldOrder: PropTypes.string,
}

Filters.defaultProps = {
  defaultOrder: constants.orderFilter.NEWLY_CREATED,
  isDataProcess: false,
  alphabeticalOrder: false,
  alphabeticalFieldOrder: 'name',
}

export default Filters
