import api from 'service/api'

const dponetAPI = api.create('cookies', false)

const get = async ({ bannerId, params }) =>
  await dponetAPI.get('/consents', { params: { ...params, bannerId } })

export default {
  get,
}
