import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import constants from 'constants/index'

const styles = ({ selectedTab }) =>
  makeStyles((theme) => {
    const isColorCustomization =
      constants.cookies.banner.COLOR_CUSTOMIZATION_CARD_ID
    const isLogoCustomization =
      constants.cookies.banner.LOGO_CUSTOMIZATION_CARD_ID
    const isDescriptionCustomization =
      constants.cookies.banner.DESCRIPTION_CUSTOMIZATION_CARD_ID
    const isPositionCustomization =
      constants.cookies.banner.POSITION_CUSTOMIZATION_CARD_ID

    return {
      colorCustomizationContainer: {
        display: selectedTab === isColorCustomization ? 'flex' : 'none',
        height: '100%',
        flexDirection: 'row',
      },
      logoUploadContainer: {
        display: selectedTab === isLogoCustomization ? 'flex' : 'none',
        height: '100%',
        flexDirection: 'row',
      },
      descriptionCustomizationContainer: {
        display: selectedTab === isDescriptionCustomization ? 'flex' : 'none',
        height: '100%',
        flexDirection: 'row',
      },
      positionCustomizationContainer: {
        display: selectedTab === isPositionCustomization ? 'flex' : 'none',
        height: '100%',
        justifyContent: 'space-between',
      },
      bannerPreviewContainer: {
        display:
          selectedTab === isColorCustomization ||
          selectedTab === isDescriptionCustomization
            ? 'block'
            : 'none',
        height: '100%',
      },
      logoPreviewContainer: {
        display: selectedTab === isLogoCustomization ? 'block' : 'none',
        height: '100%',
      },
      positionPreviewContainer: {
        display: selectedTab === isPositionCustomization ? 'block' : 'none',
        height: '100%',
      },
    }
  })

styles.propTypes = {
  selectedTab: PropTypes.number.isRequired,
}

export default styles
