import React from 'react'

import { Grid, Box, Typography, TextField } from '@material-ui/core'

import { Controller } from 'react-hook-form'

import helpers from 'helpers'

const Operator = ({ setValue, control }) => {
  return (
    <>
      <Grid item xs={12}>
        <Box p={2}>
          <Typography variant="h6">
            Caso o incidente tenha sido comunicado ao controlador por um
            operador, informe:
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Typography variant="h6">Dados do operador</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Controller
            as={
              <TextField
                label="Razão social / Nome"
                type="text"
                color="primary"
                variant="outlined"
                fullWidth
              />
            }
            control={control}
            name="name"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Controller
            as={
              <TextField
                label="CNPJ / CPF"
                type="text"
                color="primary"
                variant="outlined"
                fullWidth
              />
            }
            onChange={([text]) =>
              helpers.formatters.cnpjOrCpf(text.target.value)
            }
            control={control}
            name="document"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Controller
            as={
              <TextField
                label="E-mail"
                type="email"
                color="primary"
                variant="outlined"
                fullWidth
              />
            }
            control={control}
            name="email"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2} mt={2}>
          <Box p={2} style={{ backgroundColor: '#FFF7E0' }}>
            <Box mb={2}>
              <Typography variant="body1">
                Cabe ao controlador solicitar ao operador as informações
                necessárias à comunicação do incidente
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default Operator
