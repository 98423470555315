import React from 'react'
import PropTypes from 'prop-types'

const PolygonOutline = ({
  width = '65',
  height = '72',
  alt = 'Polígono não preenchido',
  className,
  fill = 'none',
  stroke = '#E3FFBA',
  strokeWidth = '3',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 72"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
      className={className}
    >
      <path
        d="M29.25 2.60844C31.2611 1.44733 33.7389 1.44733 35.75 2.60844L59.7929 16.4896C61.804 17.6508 63.0429 19.7966 63.0429 22.1188V49.8812C63.0429 52.2034 61.804 54.3493 59.7929 55.5104L35.75 69.3916C33.7389 70.5527 31.2611 70.5527 29.25 69.3916L5.20706 55.5104C3.19595 54.3492 1.95706 52.2034 1.95706 49.8812V22.1188C1.95706 19.7966 3.19595 17.6508 5.20706 16.4896L29.25 2.60844Z"
        stroke={stroke}
        stroke-width={strokeWidth}
      />
    </svg>
  )
}

PolygonOutline.propTypes = {
  width: PropTypes.string | PropTypes.number,
  height: PropTypes.string | PropTypes.number,
  alt: PropTypes.string,
  className: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
}

export default PolygonOutline
