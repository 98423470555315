import api from 'service/api'

const dponetAPI = api.create('dponet')

const showCheckout = async (token) => {
  return await dponetAPI.get(`payment_contracts/show_checkout/${token}`)
}

export default {
  showCheckout,
}
