import React from 'react'

import { FilterButton, Filters } from 'components'

const DocumentFilter = ({ filter, categories }) => {
  return (
    <>
      <FilterButton setDrawerOpen={filter.setDrawerOpen}></FilterButton>
      <Filters filter={filter} alphabeticalOrder alphabeticalFieldOrder="title">
        <input textfieldinput="true" label="Identificador" name="id" />
        <input textfieldinput="true" label="Título" name="title" />
        <select
          textfieldinput="true"
          label="Categoria"
          name="documentCategoryId"
        >
          <option value=""></option>
          {categories?.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </Filters>
    </>
  )
}

export default DocumentFilter
