import React from 'react'

import { Text } from '../'
import { Box } from '@material-ui/core'

import { RoundedButton } from 'components'

import useTrailStep from 'hooks/useTrailStep'
import useStyles from './styles'

const WebsiteConfirmation = () => {
  const classes = useStyles()
  const { jumpToDocuments, handleNext } = useTrailStep()

  return (
    <>
      <Text color="primary" fontSize="7vh" variant="h1">
        A organização tem um site?
      </Text>
      <Box my={4} className={classes.actionArea}>
        <RoundedButton variant="outlined" withPadding onClick={jumpToDocuments}>
          Não
        </RoundedButton>
        <Box ml={2}>
          <RoundedButton variant="primary" withPadding onClick={handleNext}>
            Sim
          </RoundedButton>
        </Box>
      </Box>
    </>
  )
}

export default WebsiteConfirmation
