import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

import useStyles from './styles'
import { CheckCircle as CheckCircleIcon } from 'react-feather'

const ForWhoSection = ({
  cover,
  coverText,
  sectionTitle,
  description,
  benefitsList,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.descriptionContainer}>
        <Typography className={classes.title}>
          Para quem é {sectionTitle}?
        </Typography>
        <Typography className={classes.description}>{description}</Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="baseline"
        >
          {benefitsList.map((benefit) => (
            <Box className={classes.benefitContainer}>
              <CheckCircleIcon color="green" />
              <Typography align="left" className={classes.description}>
                {benefit}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <img src={cover} alt={coverText} className={classes.image} />
    </Box>
  )
}

ForWhoSection.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  cover: PropTypes.string.isRequired,
  coverText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  benefitsList: PropTypes.array.isRequired,
}

export default ForWhoSection
