const KIND_TAG = {
  RIPD: 'ripd',
  ROPA: 'ropa',
  COMPLIANCE: 'compliance',
  MANAGEMENT: 'management',
  CONTROL: 'control',
  FRAGILITY: 'fragility',
  TRAINING: 'training',
}

const KIND = {
  RIPD: 0,
  ROPA: 1,
  COMPLIANCE: 2,
  MANAGEMENT: 3,
  CONTROL: 4,
  FRAGILITY: 5,
  TRAINING: 6,
  LIA: 7,
  CONSENT: 8,
  AUDIT: 9,
}

const DOWNLOAD_ONLY_ID_ACTION = 1
const DESTROY_VERSION_ID_ACTION = 2
const VERSION_EXISTS_ACTION_OPTIONS = [
  {
    id: DOWNLOAD_ONLY_ID_ACTION,
    name: 'Apenas baixar um novo relatório',
  },
  {
    id: DESTROY_VERSION_ID_ACTION,
    name: 'Excluir versão atual e gerar uma nova versão',
  },
]

const reportVersions = {
  DESTROY_VERSION_ID_ACTION,
  KIND_TAG,
  KIND,
  VERSION_EXISTS_ACTION_OPTIONS,
  DOWNLOAD_ONLY_ID_ACTION,
}

export default reportVersions
