const styles = (theme) => ({
  card: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    boxShadow:
      '0px 3px 10px -5px rgb(0 0 0 / 20%), 0px 2px 5px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    color: 'white',
  },
  determinate: {
    borderRadius: '5px',
    height: '6px',
  },
  barColorPrimary: {
    backgroundColor: '#4CAF50',
  },
})

export default styles
