import api from 'service/api'
import axios from 'axios'
import humps from 'humps'

import { getBaseURL } from 'service/env'

import * as service from 'service'

const baseURL = getBaseURL('dponet')

const dponetAPI = api.create('dponet')

const get = async ({ reportVersionId = '', ...params }) => {
  return await dponetAPI.get(`/report_versions/${reportVersionId}`, { params })
}

const consultVersion = async (kind) => {
  return await dponetAPI.get('/report_versions/consult_version', {
    params: { kind },
  })
}

const create = async ({ file, ...data }) => {
  var formData = new FormData()
  formData.append('report_version[file]', file)
  for (var [key, value] of Object.entries(data)) {
    formData.append(`report_version[${humps.decamelizeKeys(key)}]`, value)
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/report_versions`,
    data: formData,
    ...config,
  })
}

const destroy = async ({ reportVersionId }) => {
  return await dponetAPI.delete(`/report_versions/${reportVersionId}`)
}

export default {
  consultVersion,
  create,
  destroy,
  get,
}
