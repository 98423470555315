import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Check as CheckIcon } from 'react-feather'

import useSnackbar from 'hooks/useSnackbar'

import { ConfirmationDialog } from 'components'
import { ListItemCustom } from './components'

import helpers from 'helpers'
import constants from 'constants/index'
import styles from './styles'
import * as service from 'service'
const useStyles = makeStyles(styles)

const ActionContent = ({ dataProcess, refresh, isShow = false }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const [unnecessaryDataValues, setUnnecessaryDataValues] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const someUnnecessary = dataProcess?.unnecessaryDatas?.filter(
    (item) => item?.status === constants.unnecessaryData.UNNECESSARY_STATUS,
  )

  const isEditableByFinality = isShow ? false : !isEmpty(someUnnecessary)

  const isFragility = [
    constants.nonComplianceReports.SEVERE_FRAGILITY_STATUS,
    constants.nonComplianceReports.HIGH_FRAGILITY_STATUS,
  ].includes(dataProcess?.fragilityId)

  const unnecessaryCollectedFields =
    helpers.nonComplianceReports.filterUnnecessary(
      dataProcess?.unnecessaryDatas,
      'CollectedField',
      isShow,
    )

  const unnecessaryDataTreatments =
    helpers.nonComplianceReports.filterUnnecessary(
      dataProcess?.unnecessaryDatas,
      'DataTreatment',
      isShow,
    )

  const unnecessaryLifeCycles = helpers.nonComplianceReports.filterUnnecessary(
    dataProcess?.unnecessaryDatas,
    'LifeCycle',
    isShow,
  )

  const viewMessageSecurityMeasure = isFragility && isShow

  const handleSubmit = async () => {
    const unnecessaryDatas = []
    const ids = Object.keys(unnecessaryDataValues)
    for (let id of ids) {
      unnecessaryDatas.push({
        id: id,
        status: unnecessaryDataValues[id],
      })
    }

    setIsLoading(true)
    try {
      await service.dponet.dataProcesses.unnecessaryData({
        dataProcessId: dataProcess?.id,
        unnecessaryDatas,
      })
      snackbar.open({
        message: 'Reavaliação dos dados concluída com sucesso.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error, false) ||
          'Ocorreu um erro na reavaliação dos dados.',
        variant: 'error',
      })
    } finally {
      setOpenConfirmationDialog(false)
      setIsLoading(false)
      refresh()
    }
  }

  return (
    <>
      <Box>
        <Divider />
        <CardContent className={classes.cardContent}>
          <Box mb={isEditableByFinality ? 0 : 2}>
            <Box>
              <Typography gutterBottom>
                <b>
                  {isEditableByFinality
                    ? 'Reavaliação de necessidade e proporcionalidade'
                    : 'Resultado da reavaliação de necessidade e proporcionalidade'}
                </b>
              </Typography>
              <Typography gutterBottom>
                {isEditableByFinality
                  ? 'Escolha eliminar ou não eliminar o item indicado como desnecessário e/ou desproporcional clicando sobre a opção escolhida.'
                  : 'Todos os itens indicados como desnecessários/desproporcionais durante o mapeamento foram reavaliados. O resultado da reavaliação foi o seguinte:'}
              </Typography>
            </Box>
            {unnecessaryCollectedFields?.length > 0 && (
              <Box mt={2}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleActionContents}
                >
                  Dados tratados
                </Typography>
                <List className={classes.root}>
                  {unnecessaryCollectedFields?.map((unnecessaryData) => (
                    <ListItemCustom
                      classes={classes}
                      key={unnecessaryData?.dataId}
                      itemId={unnecessaryData?.id}
                      dataType="collectedField"
                      unnecessaryData={unnecessaryData}
                      setButtonIsDisabled={setIsButtonDisabled}
                      isEditableByFinality={isEditableByFinality}
                      unnecessaryDataValues={unnecessaryDataValues}
                      setUnnecessaryDataValues={setUnnecessaryDataValues}
                      countUnnecessary={someUnnecessary?.length || 0}
                    />
                  ))}
                </List>
              </Box>
            )}
            {unnecessaryDataTreatments.length > 0 && (
              <Box mt={2}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleActionContents}
                >
                  Compartilhamento
                </Typography>
                <List className={classes.root}>
                  {unnecessaryDataTreatments.map((unnecessaryData) => (
                    <ListItemCustom
                      classes={classes}
                      key={unnecessaryData?.dataId}
                      itemId={unnecessaryData?.id}
                      dataType="dataTreatment"
                      unnecessaryData={unnecessaryData}
                      setButtonIsDisabled={setIsButtonDisabled}
                      isEditableByFinality={isEditableByFinality}
                      unnecessaryDataValues={unnecessaryDataValues}
                      setUnnecessaryDataValues={setUnnecessaryDataValues}
                      countUnnecessary={someUnnecessary?.length || 0}
                    />
                  ))}
                </List>
              </Box>
            )}
            {unnecessaryLifeCycles.length > 0 && (
              <Box mt={2}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleActionContents}
                >
                  Forma de proteção
                </Typography>

                <List className={classes.root}>
                  {unnecessaryLifeCycles.map((unnecessaryData) => (
                    <ListItemCustom
                      classes={classes}
                      key={unnecessaryData?.dataId}
                      dataType="lifeCycle"
                      itemId={unnecessaryData?.id}
                      unnecessaryData={unnecessaryData}
                      setButtonIsDisabled={setIsButtonDisabled}
                      isEditableByFinality={isEditableByFinality}
                      unnecessaryDataValues={unnecessaryDataValues}
                      setUnnecessaryDataValues={setUnnecessaryDataValues}
                      countUnnecessary={someUnnecessary?.length || 0}
                    />
                  ))}
                </List>
              </Box>
            )}
          </Box>
          {viewMessageSecurityMeasure && (
            <Box mt={2} mb={2}>
              <Alert variant="filled" severity="info">
                <Typography>
                  Após a reavaliação de necessidade e proporcionalidade, o risco
                  calculado para o processo de origem é{' '}
                  {helpers.dataProcess.fragilityLabel(
                    dataProcess?.adoptedFragilityId,
                    true,
                  )}
                  . Assim, devem ser vinculadas medidas de segurança específicas
                  para a atividade de tratamento de dados pessoais.
                </Typography>
              </Alert>
            </Box>
          )}
        </CardContent>
        {isEditableByFinality && (
          <CardActions>
            <Box ml={1} m={2}>
              <Button
                disableElevation
                disabled={isButtonDisabled || isLoading}
                startIcon={
                  isLoading ? <CircularProgress size={14} /> : <CheckIcon />
                }
                variant="outlined"
                color="primary"
                onClick={() => setOpenConfirmationDialog(true)}
              >
                {isLoading ? 'Aguarde...' : 'Confirmar Reavaliação'}
              </Button>
            </Box>
          </CardActions>
        )}
      </Box>
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        actionAcceptButton={handleSubmit}
        text="Deseja confirmar a reavaliação?"
        textButton="Confirmar"
      />
    </>
  )
}

export default ActionContent
