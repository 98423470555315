import React from 'react'

import { Box, Grid, Typography, Tooltip, makeStyles } from '@material-ui/core'

import PropTypes from 'prop-types'
import clsx from 'clsx'

import styles from './styles'

const useStyles = makeStyles(styles)

const Label = ({
  children,
  ellipsis,
  title = '',
  description = '',
  className,
  titleClassName,
  titleVariant = 'subtitle2',
  marginTopChildren = 1,
  descriptionVariant = 'caption',
  paddingBoxTitle = 1,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Grid
      {...rest}
      className={clsx(
        {
          [classes.ellipsis]: ellipsis,
        },
        classes.root,
        className,
      )}
    >
      <Box py={paddingBoxTitle}>
        <Typography
          variant={titleVariant}
          className={clsx({ [titleClassName]: !!titleClassName })}
          color="textPrimary"
        >
          {title}
        </Typography>
      </Box>
      <Tooltip noWrap title={description || ''} placement="bottom-start">
        <Typography variant={descriptionVariant} color="textSecondary">
          {description}
        </Typography>
      </Tooltip>
      <Box mt={marginTopChildren}>{children}</Box>
    </Grid>
  )
}

Label.propTypes = {
  children: PropTypes.node,
  ellipsis: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  titleVariant: PropTypes.string,
  descriptionVariant: PropTypes.string,
  paddingBoxTitle: PropTypes.number,
  marginTopChildren: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleClassName: PropTypes.string,
}

export default Label
