import React from 'react'

import { Box, Card, makeStyles } from '@material-ui/core'

import { Alert, AlertTitle } from '@material-ui/lab'

import constants from 'constants/index'

import styles from '../../styles'

import NonComplianceReportsForm from '../NonComplianceReportsForm'
import NonComplianceReportsDetails from '../NonComplianceReportsDetails'

const useStyles = makeStyles(styles)

const NonComplianceEditContent = ({
  nonComplianceReport,
  nonComplianceReportId,
  loading,
  setDialogFinalize,
  title,
  onEvent = () => {},
}) => {
  const classes = useStyles()

  return (
    <>
      {nonComplianceReport?.status ===
        constants.nonComplianceReports.FROZEN_STATUS && (
        <Box pb={2}>
          <Alert severity="info" className={classes.frozenAlert}>
            <AlertTitle className={classes.alertTitle}>Atenção!</AlertTitle>
            Este RMC está momentaneamente congelado, pois durante a resolução
            optou-se pela exclusão de alguns dados do processo. Ele será
            desbloqueado após nossa equipe jurídica concluir a
            <b> reavaliação do enquadramento legal</b> do processo. Neste
            momento o processo está aguardando revisão.
          </Alert>
        </Box>
      )}
      <Card>
        <Box px={3} pb={3} mt={2}>
          <>
            <NonComplianceReportsDetails
              title={title}
              nonComplianceReport={nonComplianceReport}
            />
            <NonComplianceReportsForm
              nonComplianceReportId={nonComplianceReportId}
              nonComplianceReport={nonComplianceReport}
              setDialogFinalize={setDialogFinalize}
              isLoading={loading}
              onEvent={onEvent}
            />
          </>
        </Box>
      </Card>
    </>
  )
}

export default NonComplianceEditContent
