import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    border: 'none !important',
    background: theme.palette.subscription.primary.main,
    display: 'grid',
    gridTemplateColumns: '1.5fr 1fr !important',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  wrapper: {
    width: '100%',
    background: theme.palette.white,
    padding: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary,
    fontSize: 25,
    fontWeight: 900,
    fontFamily: 'Roboto',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  subtitle: {
    color: theme.palette.text.mainMenu,
    fontSize: 20,
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  subtitle2: {
    color: theme.palette.text.mainMenu,
    margin: theme.spacing(0, 0, 2, 0),
    fontSize: 20,
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  trailLabel: {
    paddingTop: theme.spacing(1),
    color: theme.palette.primary,
    fontSize: 18,
    fontFamily: 'Roboto',
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },

  labelRigth: {
    color: `${theme.palette.white} !important`,
    fontSize: 20,
    paddingTop: 20,
    fontFamily: 'Roboto',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  boxButton: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 10),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  button: {
    backgroundColor: theme.palette.greenPantone,
    color: theme.palette.white,
    fontWeight: 900,
    fontSize: 16,
    '&:hover': {
      backgroundColor: theme.palette.greenPantone,
      opacity: 0.8,
    },
  },
  BoxAlert: {
    margin: '16px 60px',
    [theme.breakpoints.down('xs')]: {
      margin: '16px 0',
    },
  },
  alert: {
    backgroundColor: theme.palette.black,
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'space-between',
  },

  number: {
    color: theme.palette.darkRed,
    fontSize: 45,
    fontWeight: 900,
  },
}))

export default styles
