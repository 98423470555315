import origin from './origin'
import status from './status'
import statusChipColor from './statusChipColor'
import getOriginName from './getOriginName'
import showActionPlanRecomendations from './showActionPlanRecomendations'
import getResponsableNames from './getResponsableNames'
import filterUnnecessary from './filterUnnecessary'
import eliminateNotEliminateTexts from './eliminateNotEliminateTexts'
import eliminateNotEliminateSubtitleMessages from './eliminateNotEliminateSubtitleMessages'
import getContinuedText from './getContinuedText'
import getFinalizedText from './getFinalizedText'
import statusesCheck from './statusesCheck'
import finalityCheck from './finalityCheck'
import getFragilityActionText from './getFragilityActionText'
import reavaliatedAllData from './reavaliatedAllData'

export default {
  origin,
  status,
  statusChipColor,
  getOriginName,
  showActionPlanRecomendations,
  getResponsableNames,
  filterUnnecessary,
  eliminateNotEliminateTexts,
  eliminateNotEliminateSubtitleMessages,
  getContinuedText,
  getFinalizedText,
  statusesCheck,
  finalityCheck,
  getFragilityActionText,
  reavaliatedAllData,
}
