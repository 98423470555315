import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import helpers from 'helpers'

const DialogChangeStatus = ({
  active,
  departmentId,
  open,
  setOpen = () => {},
  onEvent = () => {},
}) => {
  const snackbar = useSnackbar()

  const changeStatus = async (departmentId) => {
    try {
      await service.dponet.departments.changeStatus({ departmentId })
      snackbar.open({
        message: active
          ? 'Departamento inativado com sucesso!'
          : 'Departamento ativado com sucesso!',
        variant: 'success',
      })
      setOpen(false)
      onEvent()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error.response && error.response.data && error.response.data.error,
        ),
        variant: 'error',
      })
      setOpen(false)
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box>
          <Typography variant="h4">
            {active ? 'Inativar' : 'Ativar'} departamento
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box mb={1}>
        <DialogContent>
          <Box>
            <Typography>
              Tem certeza que deseja {active ? 'inativar' : 'ativar'} este
              departamento?
            </Typography>
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => changeStatus(departmentId)}
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogChangeStatus
