import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

const BannerHeadCard = ({ name, stage }) => {
  return (
    <Box p={2} display="flex" justifyContent="space-between">
      <Typography color="primary" variant="h5">
        {name}
      </Typography>
      <Typography color="primary" variant="h5">
        {stage}/04
      </Typography>
    </Box>
  )
}

BannerHeadCard.propTypes = {
  name: PropTypes.string.isRequired,
  stage: PropTypes.string.isRequired,
}

export default BannerHeadCard
