import constants from 'constants/index'

const storageModeType = (
  storage = null,
  value = null,
  volumetry = null,
  valueVolumetry = false,
) => {
  const {
    STORAGE_DEFINED_TYPE,
    STORAGE_UNDEFINED_TYPE,
    STORAGE_PERMANENT_TYPE,
  } = constants.dataProcess

  if (storage === STORAGE_PERMANENT_TYPE) return 'Permanente'

  if (storage === STORAGE_UNDEFINED_TYPE) return 'Indefinido'

  if (storage === STORAGE_DEFINED_TYPE && !valueVolumetry) return 'Indefinido'

  if (storage === STORAGE_DEFINED_TYPE && valueVolumetry) {
    return `${value} ${volumetry}`
  }
  return 'Indefinido'
}

export default storageModeType
