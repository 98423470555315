import React from 'react'
import { Box, Drawer, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'

import {
  RegistrationResume,
  SubscriptionAdvantages,
} from 'components/PreRegistration'

import useStyles from './styles'

const RegistrationResumeDrawer = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false)
  const classes = useStyles()

  return (
    <Drawer
      variant="temporary"
      open={open}
      anchor="right"
      classes={{ paper: classes.paper }}
    >
      <Box mx={4}>
        <Box width="100%" display="flex" justifyContent="end" mt={2}>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box mt={-3}>
          <SubscriptionAdvantages />
        </Box>
        <Box my={(2, 3)}>
          <RegistrationResume defaultExpanded />
        </Box>
      </Box>
    </Drawer>
  )
}

RegistrationResumeDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

export default RegistrationResumeDrawer
