import iconByStatus from './iconByStatus'
import label from './label'
import mountTabsWithQuestionnaires from './mountTabsWithQuestionnaires'
import statusChipColor from './statusChipColor'
import statusLogTitle from './statusLogTitle'
import advisorContent from './advisorContent'
import documentSend from './documentSend'
import statusDriverSteps from './statusDriverSteps'
import statusDriverId from './statusDriverId'

export default {
  iconByStatus,
  label,
  mountTabsWithQuestionnaires,
  statusChipColor,
  statusLogTitle,
  advisorContent,
  documentSend,
  statusDriverSteps,
  statusDriverId,
}
