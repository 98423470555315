const statusLabel = (statusId) => {
  const WAITING_REVIEW_TEXT = 'AGUARDANDO REVISÃO'
  switch (statusId) {
    case 1:
      return 'PENDENTE'
    case 2:
      return WAITING_REVIEW_TEXT
    case 3:
      return 'REPROVADO'
    case 4:
      return 'APROVADO'
    case 5:
      return WAITING_REVIEW_TEXT
    case 6:
      return 'INATIVO'
    // status 7 e 8 são defaults da plataforma web
    // Caso criado mais status no processamento, favor adequar este modulo e onde é chamado
    case 7:
      return 'SUGERIDO'
    case 8:
      return 'DESCARTADOS'
    case 9:
      return WAITING_REVIEW_TEXT
    case 10:
      return 'PENDENTE LIA'
    case 11:
      return 'REVISÃO LIA'
    case 12:
      return 'REVISÃO LIA'
    case 13:
      return 'REPROVADO LIA'
    default:
      return '-'
  }
}

export default statusLabel
