import React, { useState } from 'react'

import useSnackbar from 'hooks/useSnackbar'

import {
  Button,
  makeStyles,
  Box,
  Tooltip,
  Typography,
  TableContainer,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

import { MenuButton } from 'components'

import { DataLifeCycleModal } from '../DataLifeCycleModal'
import DialogRemoveDataLifeCycle from '../DialogRemoveDataLifeCycle'

import helpers from 'helpers'

import * as service from 'service'
import styles from './styles'

const useStyles = makeStyles(styles)

const TableDataLifeCycle = ({
  edit,
  loadDataLifeCycle,
  dataProcessId,
  dataLifeCycles,
  dataProcess,
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const [dialogRemoveDataLifeCycle, setDialogRemoveDataLifeCycle] =
    useState(false)
  const [removableDataLifeCycleId, setRemovableDataLifeCycleId] = useState()
  const [dataLifeCycleModal, setDataLifeCycleModal] = useState(false)
  const [editableDataLifeCycle, setEditableDataLifeCycle] = useState({})

  const handleDelete = async (dataProcessId, dataLifeCycleId) => {
    try {
      await service.dponet.dataLifeCycles.destroy({
        dataProcessId,
        dataLifeCycleId,
      })
      setDialogRemoveDataLifeCycle(false)

      snackbar.open({
        message: 'Salvaguarda e Ciclo de vida removido com sucesso!',
        variant: 'success',
      })
      loadDataLifeCycle()
    } catch (err) {
      snackbar.open({
        message:
          'Não foi possível remover a salvaguarda e ciclo de vida. Tente novamente!',
        variant: 'error',
      })
    }
  }

  const handleOpenRemoveDialog = (dataLifeCycle) => {
    setRemovableDataLifeCycleId(dataLifeCycle.id)
    setDialogRemoveDataLifeCycle(true)
  }

  const handleOpenEditionDataLifeCycle = (dataLifeCycle) => {
    setEditableDataLifeCycle(dataLifeCycle)
    setDataLifeCycleModal(true)
  }

  return (
    <>
      <TableContainer>
        <Table
          size="small"
          emptyMessage="Nenhum salvaguarda e ciclo de vida foi encontrado"
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell width={!edit ? '20%' : ''}>
                Ambiente de alocação
              </TableCell>
              <TableCell width={!edit ? '20%' : ''}>
                Onde os dados são armazenados
              </TableCell>
              <TableCell width={!edit ? '20%' : ''}>
                Por quanto tempo os dados serão armazenados
              </TableCell>
              <TableCell width={!edit ? '15%' : ''}>
                Justificativa de retenção
              </TableCell>
              <TableCell width={!edit ? '15%' : ''}>
                Como os dados são dispostos
              </TableCell>
              {edit && (
                <TableCell width="10%" align="right">
                  Ações
                </TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {dataLifeCycles.map((dataLifeCycleUnity) => (
              <TableRow key={dataLifeCycleUnity.id}>
                <TableCell>
                  <Tooltip
                    title={dataLifeCycleUnity?.internalAllocationMode?.name}
                  >
                    <Typography className={classes.ellipsisTable}>
                      {dataLifeCycleUnity?.internalAllocationMode?.name}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={dataLifeCycleUnity?.storageLocation?.name}>
                    <Typography className={classes.ellipsisTable}>
                      {dataLifeCycleUnity?.storageLocation?.name}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={helpers.dataProcess.storageModeType(
                      dataLifeCycleUnity?.storageType,
                      dataLifeCycleUnity?.value,
                      dataLifeCycleUnity?.volumetry,
                      !!dataLifeCycleUnity?.value &&
                        !!dataLifeCycleUnity?.volumetry,
                    )}
                  >
                    <Typography className={classes.ellipsisTable}>
                      {helpers.dataProcess.storageModeType(
                        dataLifeCycleUnity?.storageType,
                        dataLifeCycleUnity?.value,
                        dataLifeCycleUnity?.volumetry,
                        !!dataLifeCycleUnity?.value &&
                          !!dataLifeCycleUnity?.volumetry,
                      )}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title={dataLifeCycleUnity?.retentionFinality}>
                    <Typography className={classes.ellipsisTable}>
                      {dataLifeCycleUnity?.retentionFinality}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={helpers.dataProcess.discardMode(dataLifeCycleUnity)}
                  >
                    <Typography className={classes.ellipsisTable}>
                      {helpers.dataProcess.discardMode(dataLifeCycleUnity)}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Box display="flex" justifyContent="flex-end">
                    <MenuButton>
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() =>
                          handleOpenEditionDataLifeCycle(dataLifeCycleUnity)
                        }
                      >
                        Editar
                      </Button>
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() =>
                          handleOpenRemoveDialog(dataLifeCycleUnity)
                        }
                      >
                        Excluir
                      </Button>
                    </MenuButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogRemoveDataLifeCycle
        open={dialogRemoveDataLifeCycle}
        setOpen={setDialogRemoveDataLifeCycle}
        dataProcessId={dataProcessId}
        dataLifeCycleId={removableDataLifeCycleId}
        handleRemove={handleDelete}
      />
      <DataLifeCycleModal
        open={dataLifeCycleModal}
        setOpen={setDataLifeCycleModal}
        dataProcessId={dataProcessId}
        dataLifeCycle={editableDataLifeCycle}
        classes={classes}
        dataProcess={dataProcess}
        mode="edit"
      />
    </>
  )
}

export default TableDataLifeCycle
