import { makeStyles } from '@material-ui/core'
import background from 'images/background_primeiros_passos.png'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background:
      'transparent linear-gradient(180deg, #233154 0%, #1E3860 100%) 0% 0% no-repeat padding-box;',
    [theme.breakpoints.up('sm')]: {
      overflow: 'hidden',
      height: '100%',
    },
  },
  children: {
    backgroundImage: `url(${background})`,
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100%',
  },
}))

export default styles
