import { default as dataGridTexts } from './dataGridTexts'
import otherOption from './otherOption'
import displayedOther from './displayedOther'
import mountOption from './mountOption'
import mountDefault from './mountDefault'
import mountStepGeneralInformation from './mountStepGeneralInformation'
import mountStepOccurrence from './mountStepOccurrence'
import mountStepOpportunity from './mountStepOpportunity'
import mountStepImpact from './mountStepImpact'
import holderAffectedInformedStatus from './holderAffectedInformedStatus'
import optionImpactIncidentHolderStatus from './optionImpactIncidentHolderStatus'
import dataProtectedNotPossibleIdentifyHolderStatus from './dataProtectedNotPossibleIdentifyHolderStatus'
import driverId from './driverId'
import typeFormDriverSteps from './typeFormDriverSteps'

export default {
  dataGridTexts,
  otherOption,
  displayedOther,
  mountOption,
  mountDefault,
  mountStepGeneralInformation,
  mountStepOccurrence,
  mountStepOpportunity,
  mountStepImpact,
  holderAffectedInformedStatus,
  optionImpactIncidentHolderStatus,
  dataProtectedNotPossibleIdentifyHolderStatus,
  driverId,
  typeFormDriverSteps,
}
