import React from 'react'
import PropTypes from 'prop-types'

import { BarChart, DashboardChartPaper } from 'components'

import constants from 'constants/index'

const RmcStatusByDepartmentGraphic = ({ processStats, ...rest }) => {
  const departments = processStats?.departments
    ?.sort((a, b) => {
      const sumA =
        a.nonComplianceReports.pending +
        a.nonComplianceReports.onTime +
        a.nonComplianceReports.overdue +
        a.nonComplianceReports.finalized
      const sumB =
        b.nonComplianceReports.pending +
        b.nonComplianceReports.onTime +
        b.nonComplianceReports.overdue +
        b.nonComplianceReports.finalized
      return sumB - sumA
    })
    .slice(0, 4)

  const { PENDING_STATUS, ON_TIME_STATUS, OVERDUE_STATUS, FINALIZED_STATUS } =
    constants.nonComplianceReports

  const getDataByStatus = (department, status) =>
    department?.nonComplianceReports[status]

  const data = ['pending', 'onTime', 'overdue', 'finalized'].map((status) => ({
    [status]: departments?.map((department) =>
      getDataByStatus(department, [status]),
    ),
  }))

  const names = departments?.map((department) => department.name)

  return (
    <>
      <DashboardChartPaper
        title="Status dos RMCs por departamento"
        subtitle="Pendentes, no prazo, vencidos e finalizados"
        // redirect={routes.nonComplianceReport.all}
        redirectParams={{
          status: [
            PENDING_STATUS,
            ON_TIME_STATUS,
            OVERDUE_STATUS,
            FINALIZED_STATUS,
          ],
        }}
        {...rest}
      >
        <BarChart
          names={names}
          series={[
            { name: 'Pendente', data: data[0].pending || [] },
            { name: 'No prazo', data: data[1].onTime || [] },
            { name: 'Vencido', data: data[2].overdue || [] },
            { name: 'Finalizado', data: data[3].finalized || [] },
          ]}
          colors={['#087DF1', '#B8DAF7', '#DC3545', '#28A745']}
          heightValue={250}
          id={constants.myLgpd.GRAPH_IDS.pdf.statusRmcsByDepartment}
        />
      </DashboardChartPaper>
    </>
  )
}

RmcStatusByDepartmentGraphic.propTypes = {
  processStats: PropTypes.object.isRequired,
}

export default RmcStatusByDepartmentGraphic
