import * as yup from 'yup'

export const schema = yup.object().shape({
  responsibleName: yup.string().required(),
  responsibleEmail: yup.string().email().required(),
  responsiblePhone: yup
    .string()
    .required()
    .min(14, 'O numero digitado não é valido'),
})

export default schema
