import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import { IncidentCard, WelcomeCard } from './components'

import constants from 'constants/index'

const GeneralComponents = ({ generalStats }) => {
  const { general } = constants.myLgpd.GRAPH_IDS

  return (
    <>
      <Grid item md={8} xs={12}>
        <WelcomeCard generalStats={generalStats} id={general?.welcome} />
      </Grid>
      <Grid item md={4} xs={12}>
        <IncidentCard
          incidents={generalStats?.incidentRmcs}
          id={general?.incidentCard}
        />
      </Grid>
    </>
  )
}

GeneralComponents.propTypes = {
  generalStats: PropTypes.object.isRequired,
  exportingPdf: PropTypes.bool,
}

export default GeneralComponents
