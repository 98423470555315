import React, { Fragment } from 'react'
import { Box, Divider, Tooltip, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import useStyles from './styles'

const PaperHeader = ({ title, actionButton, ...rest }) => {
  const classes = useStyles()

  return (
    <Fragment>
      <Box className={classes.box} {...rest}>
        <Tooltip title={title}>
          <Typography className={classes.title} variant="h5" noWrap>
            {title}
          </Typography>
        </Tooltip>
        {actionButton && actionButton}
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
    </Fragment>
  )
}

PaperHeader.propTypes = {
  title: PropTypes.string,
  actionButton: PropTypes.node,
}

export default PaperHeader
