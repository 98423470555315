import React, { useState } from 'react'

import { AvaliationDpo, LegalOpinion } from './components'
import { Card, LoadingFeedback } from 'components'

import useIncidentManegement from 'hooks/useIncidentManegement'

const AvaliationStep = ({ type }) => {
  const [loading, setLoading] = useState(false)

  const { incident, isDpo } = useIncidentManegement()

  return (
    <Card title="Avaliação do Incidente">
      <LoadingFeedback open={loading} />
      {isDpo && !incident?.legalOpinion ? (
        <AvaliationDpo type={type} />
      ) : (
        <LegalOpinion type={type} setLoading={setLoading} />
      )}
    </Card>
  )
}

export default AvaliationStep
