import * as yup from 'yup'

export const schemaUser = () => {
  return yup.object().shape({
    profileId: yup.string().required(),
    fileToImport: yup.string(),
  })
}

export default schemaUser
