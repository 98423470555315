import { makeStyles } from '@material-ui/core'
const styles = makeStyles((theme) => ({
  activitiesTitle: {
    color: theme.palette.blueDark,
    fontWeight: 500,
  },
  activitieSubtitle: {
    color: theme.palette.blueDark,
    fontWeight: 400,
  },
}))

export default styles
