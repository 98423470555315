import { makeStyles, colors } from '@material-ui/core'

export default makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chipRoot: {
    marginLeft: theme.spacing(1),
    backgroundColor: colors.lightBlue[100],
    color: colors.blue[600],
    padding: '0 4px',
    fontSize: '0.6rem',
  },
  textInfo: {
    fontWeight: 400,
  },
  boxAction: {
    padding: theme.spacing(0, 1, 1),
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
}))
