const mountTemplateData = (consentTemplate) => {
  const components = JSON.parse(consentTemplate?.components)

  delete consentTemplate.id

  return {
    ...consentTemplate,
    components,
  }
}

export default mountTemplateData
