const formatData = (data) => {
  return {
    address: {
      postalCode: data?.postalCode,
      country: data?.country,
      state: data?.state,
      city: data?.city,
      neighborhood: data?.neighborhood,
      street: data?.street,
      number: data?.number,
      complement: data?.complement,
    },
    name: data?.name,
    description: data?.description,
    stateRegistration: data?.stateRegistration,
    municipalRegistration: data?.municipalRegistration,
    email: data?.email,
    phone: data?.phone,
    siteUrl: data?.siteUrl,
    twoFactorAuthentication: data?.twoFactorAuthentication === 'true',
    notifyDataProcessChanges: data?.notifyDataProcessChanges,
  }
}

export default formatData
