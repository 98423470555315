import React from 'react'

import { Card } from 'components'
import { Typography, Box, Chip } from '@material-ui/core'

import useStyles from './styles'

import helpers from 'helpers'
import constants from 'constants/index'

const CardType = ({ children, isLast, planAction }) => {
  const classes = useStyles()
  return (
    <Card
      title={
        <Box display="flex" flexDirection="row">
          <Box mr={2}>
            <Typography variant="h5">
              #{planAction?.id} Plano de ação
            </Typography>
          </Box>
          <Chip
            size="small"
            className={helpers.questionControls.statusChipColor(
              planAction?.status,
              classes,
            )}
            label={helpers.questionControls.status(planAction?.status)}
          />
        </Box>
      }
      dropdown={
        !isLast ||
        planAction?.status === constants.questionControl.CONCLUSION_PLAN_STATUS
      }
    >
      {children}
    </Card>
  )
}
export default CardType
