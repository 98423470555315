import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  contentHeaderBox: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
    },
  },
}))

export default styles
