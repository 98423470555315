import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { reverse } from 'named-urls'
import clsx from 'clsx'
import {
  Box,
  Button,
  Card,
  Tab,
  TablePagination,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import {
  Container,
  ContentHeader,
  DriveTour,
  LoadingFeedback,
  Page,
} from 'components'
import { AuditsTable } from './components'

import useFetch from 'hooks/useFetch'
import usePagination from 'hooks/usePagination'

import theme from 'theme'
import { routes } from 'Routes'
import useStyles from './styles'
import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

const AuditsMain = () => {
  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(null)
  const [openTour, setOpenTour] = useState(false)

  const classes = useStyles()
  const history = useHistory()

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.companyAudits.get,
    { perPage, page, status: currentTab },
    [perPage, page, currentTab],
  )

  const {
    response: auditConfigResponse,
    isLoading: auditConfigIsLoading,
    refresh: auditConfigRefresh,
  } = useFetch(service.dponet.auditConfigs.get, [])

  const companyAudits = response?.data?.companyAudits
  const auditConfigId = auditConfigResponse?.data?.auditConfigId

  const handleTabChange = (_, value) => setCurrentTab(value)
  const handleRedirectAutomation = () =>
    history.push(
      reverse(routes.audits.automation, {
        auditConfigId,
      }),
    )
  const handleRedirectNewAudit = () => history.push(routes.audits.new)

  const handleRefresh = () => {
    refresh()
    auditConfigRefresh()
  }

  const handleOpenTour = () => {
    const where = 'Auditorias de Processos'
    const title = 'Tour'

    const params = {
      action: 'tour-auditorias-processos',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Auditorias de processos">
      <LoadingFeedback open={loading || isLoading || auditConfigIsLoading} />
      <Container maxWidth={false}>
        <ContentHeader title="Auditoria de processos">
          <Box
            className={clsx(
              classes.contentHeaderBox,
              classes.contentHeaderBoxXs,
            )}
          >
            <Tooltip title="Iniciar o tour guiado">
              <Box className={classes.contentHeaderBox}>
                <Button
                  id={constants.audits.AUDITS_MAIN_DRIVER_STEP_0}
                  variant="contained"
                  color="primary"
                  startIcon={<HelpCircleIcon size={20} />}
                  onClick={handleOpenTour}
                  fullWidth
                >
                  Tutorial
                </Button>
              </Box>
            </Tooltip>
            <Button
              id={constants.audits.AUDITS_MAIN_DRIVER_STEP_1}
              variant="contained"
              color="primary"
              onClick={handleRedirectAutomation}
            >
              Configurar Automação
            </Button>
            <Button
              id={constants.audits.AUDITS_MAIN_DRIVER_STEP_2}
              variant="contained"
              color="primary"
              onClick={handleRedirectNewAudit}
            >
              Nova Auditoria
            </Button>
          </Box>
        </ContentHeader>
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
          <Box
            display="flex"
            gridGap={theme.spacing(2)}
            className={classes.dividerContainer}
          >
            <Typography variant="h6" color="primary">
              Periodicamente a plataforma reabre processos por amostragem. Esses
              processos são sempre os mais antigos e ficam reabertos durante
              todo o mês para que sejam revistos. O objetivo de reabrir alguns
              desses processos é sempre lembrar a empresa de que os processos
              mapeados precisam se manter atualizados.
            </Typography>
          </Box>
          <Box>
            <Tabs
              id={constants.audits.AUDITS_MAIN_DRIVER_STEP_3}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="primary"
              indicatorColor="primary"
              onChange={handleTabChange}
            >
              {constants.audits.TABS.map((tab) => (
                <Tab key={tab.value} label={tab.name} value={tab.value} />
              ))}
            </Tabs>
            <Card>
              <AuditsTable
                auditConfigId={auditConfigId}
                companyAudits={companyAudits}
                setLoading={setLoading}
                refresh={handleRefresh}
                isLoading={loading || isLoading || auditConfigIsLoading}
              />
              <Box px={2} display="flex" justifyContent="flex-end">
                <TablePagination
                  component="div"
                  count={response?.data?.meta?.totalCount || 0}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page - 1}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  labelRowsPerPage={isDesktop ? 'Por página' : ''}
                  nextIconButtonProps={{ size: 'small' }}
                  backIconButtonProps={{ size: 'small' }}
                />
              </Box>
            </Card>
          </Box>
        </Box>
      </Container>
      <DriveTour
        stepsMatrix={constants.audits.AUDITS_DRIVER_STEPS_OBJECT}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default AuditsMain
