import helpers from 'helpers'

const formatDataTomticketRequestPartner = (
  data,
  typeService,
  partnerName,
  categoryService,
) => {
  const description =
    process.env.NODE_ENV !== 'production'
      ? `[Teste feito em sandbox ou desenvolvimento] ${data?.description}`
      : data?.description
  return {
    tomticket: {
      description,
      partner_data: {
        typeService,
        partnerName,
        categoryService,
        phone: Number(helpers.formatters.onlyNumbers(data?.phone)),
      },
    },
  }
}

export default formatDataTomticketRequestPartner
