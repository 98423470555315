import helpers from 'helpers'
import constants from 'constants/index'
import partinersImage from 'images/partiners.png'
import partnersRmcImage from 'images/partner_rmc.png'
import partnerTrainingImage from 'images/partner_training.png'

const typeName = constants.partner.TYPE_CATEGORIES
const getImage = (name) => {
  const named = helpers.formatters.formattedConstantName(name)

  switch (named) {
    case typeName[0]:
      return partinersImage
    case typeName[1]:
      return partinersImage
    case typeName[2]:
      return partnersRmcImage
    case typeName[3]:
      return partnerTrainingImage
    default:
      return partinersImage
  }
}

export default getImage
