import React from 'react'

import PropTypes from 'prop-types'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'

import { Delete as DeleteIcon } from '@material-ui/icons'

import { IconButton, Card } from '@material-ui/core'

import useSelectCountry from 'hooks/useSelectCountry'

const TableCountries = ({
  countries,
  handleRemoveCountry,
  manyFields,
  fieldName,
  disabled,
}) => {
  const { removeCountry, removeCountryToManyFields } = useSelectCountry()

  const handleRemove = (country) => {
    if (manyFields) {
      return removeCountryToManyFields(country, fieldName, handleRemoveCountry)
    }
    removeCountry(country, handleRemoveCountry)
  }

  return (
    <Card>
      <Table emptyMessage="Nenhum país selecionado.">
        <TableHead>
          <TableRow>
            <TableCell>País(es) selecionado(s)</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {countries?.map((country) => (
            <TableRow key={country.id}>
              <TableCell>{country.country}</TableCell>
              <TableCell align="right">
                {!disabled && (
                  <IconButton
                    onClick={() => {
                      handleRemove(country)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  )
}

TableCountries.propTypes = {
  countries: PropTypes.arrayOf(Object),
  handleRemoveCountry: PropTypes.func,
  manyFields: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldName: PropTypes.string,
}

TableCountries.defaultProps = {
  countries: [],
  handleRemoveCountry: () => {},
  manyFields: false,
  disabled: false,
}

export default TableCountries
