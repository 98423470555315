import defineStatus from './defineStatus'
import defineSubtitle from './defineSubtitle'
import mountTrails from './mountTrails'

const trainningJourneys = {
  defineStatus,
  defineSubtitle,
  mountTrails,
}

export default trainningJourneys
