import * as yup from 'yup'

const schema = yup.object().shape({
  currentPassword: yup.string().required().min(6),
  newPassword: yup
    .string()
    .required()
    .min(8)
    .matches(
      //eslint-disable-next-line
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ `!*@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/,
      'Sua nova senha deve possuir letras minúsculas, maiúsculas, números e pelo menos um caractere especial',
    ),
  confirmPassword: yup
    .string()
    .required()
    .min(8)
    .oneOf([yup.ref('newPassword'), null], 'Senhas não coincidem.'),
})

export default schema
