import React from 'react'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

import { Check as CheckIcon } from '@material-ui/icons'
import { Table, TableBody, TableCell, TableRow } from 'components/Table'

const DialogNotApplicableQuestion = ({
  open,
  questionnaire,
  setOpen = () => {},
  handleConfirmation = () => {},
}) => {
  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-change"
      maxWidth="lg"
    >
      <DialogTitle>
        <Box>
          <Typography variant="h5">
            Deseja removê-la(s) de suas perguntas?
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h5" gutterBottom>
          Você selecionou essa(s) questão(ões) como 'Não se aplica'.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Se sim, confirme. Caso contrário, altere as resposta(s) da(s)
          pergunta(s)
        </Typography>
        <Box my={1}>
          <Table size="medium" aria-label="not applicable questions">
            <TableBody>
              {questionnaire?.map((question) => (
                <TableRow key={question.id}>
                  <TableCell width="10%">{question.index}</TableCell>
                  <TableCell>{question.theme}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" my={2} mr={2}>
        <Box pr={3}>
          <Button
            variant="outlined"
            type="button"
            onClick={handleCancel}
            disableElevation
          >
            Cancelar
          </Button>
        </Box>

        <Button
          startIcon={<CheckIcon />}
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleConfirmation}
        >
          Confirmar
        </Button>
      </Box>
    </Dialog>
  )
}

export default DialogNotApplicableQuestion
