const ACTIVED_STATUS = 'actived'
const PENDING_STATUS = 'pending'
const CANCELED_STATUS = 'canceled'
const REQUESTED_STATUS = 'requested'

const STATUSES_LABELS = {
  [REQUESTED_STATUS]: 'SOLICITADO',
  [PENDING_STATUS]: 'EM ANDAMENTO',
  [ACTIVED_STATUS]: 'ATIVO',
  [CANCELED_STATUS]: 'CANCELADO',
}

const status = {
  ACTIVED_STATUS,
  CANCELED_STATUS,
  REQUESTED_STATUS,
  PENDING_STATUS,
}

const ORDER_DRIVER_STEP_1 = 'order-driver-step-1'
const ORDER_DRIVER_STEP_2 = 'order-driver-step-2'
const ORDER_DRIVER_STEP_3 = 'order-driver-step-3'
const ORDER_DRIVER_STEP_4 = 'order-driver-step-4'
const ORDER_DRIVER_STEP_5 = 'order-driver-step-5'

const ORDER_DRIVER_STEP_OBJECT_1 = {
  element: '#' + ORDER_DRIVER_STEP_1,
  popover: {
    description:
      'No Marketplace, você pode acessar esta seção para acompanhar o andamento dos pedidos que fez. É aqui que você tem controle sobre os serviços que contratou, podendo verificar se foram aceitos, estão em andamento ou foram cancelados.',
  },
}

const ORDER_DRIVER_STEP_OBJECT_2 = {
  element: '#' + ORDER_DRIVER_STEP_2,
  popover: {
    title: STATUSES_LABELS[REQUESTED_STATUS],
    description:
      'Esse status indica que o pedido foi feito e está aguardando análise pelo provedor de serviço.',
  },
}

const ORDER_DRIVER_STEP_OBJECT_3 = {
  element: '#' + ORDER_DRIVER_STEP_3,
  popover: {
    title: STATUSES_LABELS[PENDING_STATUS],
    description: 'Esse status indica que a contratação está em andamento.',
  },
}

const ORDER_DRIVER_STEP_OBJECT_4 = {
  element: '#' + ORDER_DRIVER_STEP_4,
  popover: {
    title: STATUSES_LABELS[ACTIVED_STATUS],
    description:
      'Esse status mostra que a solicitação foi aceita e o serviço está ativo.',
  },
}

const ORDER_DRIVER_STEP_OBJECT_5 = {
  element: '#' + ORDER_DRIVER_STEP_5,
  popover: {
    title: STATUSES_LABELS[CANCELED_STATUS],
    description:
      'Esse status indica que o pedido foi cancelado, seja porque sua empresa optou pelo cancelamento ou porque a solicitação não foi aceita.',
  },
}

const ORDER_DRIVER_STATUS_ID = {
  [REQUESTED_STATUS]: ORDER_DRIVER_STEP_2,
  [PENDING_STATUS]: ORDER_DRIVER_STEP_3,
  [ACTIVED_STATUS]: ORDER_DRIVER_STEP_4,
  [CANCELED_STATUS]: ORDER_DRIVER_STEP_5,
}

const ORDER_DRIVER_SELECTED_STEPS = {
  [REQUESTED_STATUS]: ORDER_DRIVER_STEP_OBJECT_2,
  [PENDING_STATUS]: ORDER_DRIVER_STEP_OBJECT_3,
  [ACTIVED_STATUS]: ORDER_DRIVER_STEP_OBJECT_4,
  [CANCELED_STATUS]: ORDER_DRIVER_STEP_OBJECT_5,
}

const order = {
  status,
  STATUSES_LABELS,
  ORDER_DRIVER_STEP_1,
  ORDER_DRIVER_STEP_OBJECT_1,
  ORDER_DRIVER_STATUS_ID,
  ORDER_DRIVER_SELECTED_STEPS,
}

export default order
