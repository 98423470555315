import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ dataProcessId = '', ...params }) => {
  return await dponetAPI.get(`/data_processes/${dataProcessId}`, { params })
}

const getWithLiaAnswers = async ({ ...params }) => {
  return await dponetAPI.get(`/data_processes/list_with_lia_answers`, {
    params,
  })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/data_processes', data)
}

const put = async ({ dataProcessId = '', ...data }) => {
  return await dponetAPI.put(`/data_processes/${dataProcessId}`, data)
}

const getRelationOptions = async ({ ...params }) => {
  return await dponetAPI.get(`/data_processes/relations_options`, { params })
}

const changeStatus = async ({
  dataProcessId,
  statusId,
  adoptedFragilityId,
}) => {
  return await dponetAPI.put(
    `/data_processes/${dataProcessId}/change_status/${statusId}`,
    { dataProcess: { adoptedFragilityId } },
  )
}

const reopen = async ({ dataProcessId }, data) => {
  return await dponetAPI.post(`/data_processes/${dataProcessId}/reopen`, data)
}

const logs = async ({ dataProcessId }) => {
  return await dponetAPI.get(`/data_processes/${dataProcessId}/logs`)
}

const softDelete = async ({ dataProcessId, ...params }) => {
  return await dponetAPI.delete(
    `/data_processes/${dataProcessId}/soft_delete`,
    { params },
  )
}

const copy = async ({ dataProcessId, ...params }) => {
  return await dponetAPI.post(`/data_processes/${dataProcessId}/copy`, params)
}

const discard = async ({ dataProcessId, ...params }) => {
  return await dponetAPI.post(
    `/data_processes/${dataProcessId}/discard`,
    params,
  )
}

const suggested = async ({ ...params }) => {
  return await dponetAPI.get(`/data_processes/suggestions`, { params })
}

const totalSuggested = async ({ ...params }) => {
  return await dponetAPI.get(`/data_processes/total_suggestions`, { params })
}

const discarded = async ({ ...params }) => {
  return await dponetAPI.get(
    `/data_processes/suggestions?suggested_data_processes_status=3`,
    { params },
  )
}

const acceptSuggestion = async ({ dataProcessId, ...params }) => {
  return await dponetAPI.post(
    `/data_processes/${dataProcessId}/suggestions/accept`,
    params,
  )
}

const refuseSuggestion = async ({ dataProcessId, ...params }) => {
  return await dponetAPI.post(
    `/data_processes/${dataProcessId}/suggestions/refuse`,
    { params },
  )
}

const destroyDataProcessDataSource = async ({
  dataProcessId,
  dataProcessDataSourceId,
  ...params
}) => {
  return await dponetAPI.delete(
    `/data_processes/${dataProcessId}/data_process_data_sources/${dataProcessDataSourceId}`,
    { params },
  )
}

const unnecessaryData = async ({ dataProcessId, ...data }) => {
  return await dponetAPI.put(
    `/data_processes/${dataProcessId}/update_unnecessary_data`,
    data,
  )
}

const suggestionFragility = async ({ dataProcessId }) => {
  return await dponetAPI.patch(
    `/data_processes/${dataProcessId}/change_fragility`,
  )
}

export default {
  acceptSuggestion,
  changeStatus,
  copy,
  create,
  destroyDataProcessDataSource,
  discard,
  discarded,
  get,
  getRelationOptions,
  getWithLiaAnswers,
  logs,
  put,
  refuseSuggestion,
  reopen,
  softDelete,
  suggested,
  suggestionFragility,
  totalSuggested,
  unnecessaryData,
}
