import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useForm, Controller } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Divider,
  Box,
  Button,
} from '@material-ui/core'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'
import useStyles from './styles'
import * as service from 'service'

const DialogAddUser = ({
  availableUsers,
  availableUsersRefresh = () => {},
  departmentId,
  handleClose = () => {},
  open,
  refresh = () => {},
  setLoading = () => {},
}) => {
  const snackbar = useSnackbar()
  const classes = useStyles()

  const { handleSubmit, control, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      responsible: [],
    },
  })

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      await service.dponet.departments.linkUser({
        departmentId,
        ...data,
      })
      snackbar.open({
        message: 'Usuário vinculado ao departamento com sucesso!',
        variant: 'success',
      })
      refresh()
      availableUsersRefresh()
      handleClose()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="add-user"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        <Box>
          <Typography variant="h4">
            Adicionar usuário ao departamento
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box className={classes.dialogContent}>
          {isEmpty(availableUsers) ? (
            <Typography variant="h6">
              Não há nenhum usuário para ser adicionado ao departamento
            </Typography>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} id="add-user-form">
              <Controller
                control={control}
                name="responsible"
                mode="onChange"
                onChange={([, data]) => data}
                as={
                  <Autocomplete
                    id="combo-box-user"
                    options={availableUsers}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id || value.id === 0
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Usuário"
                        error={!!errors.responsible}
                        helperText={errors?.responsible?.message}
                      />
                    )}
                    fullWidth
                    multiple
                  />
                }
              />
            </form>
          )}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Button type="button" variant="outlined" onClick={handleClose}>
          {isEmpty(availableUsers) ? 'Fechar' : 'Cancelar'}
        </Button>
        {!isEmpty(availableUsers) && (
          <Button
            variant="contained"
            type="submit"
            disabled={!availableUsers.length}
            color="primary"
            form="add-user-form"
          >
            Salvar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

DialogAddUser.propTypes = {
  availableUsers: PropTypes.arrayOf(PropTypes.object),
  availableUsersRefresh: PropTypes.func.isRequired,
  departmentId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default DialogAddUser
