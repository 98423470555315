const styles = (theme) => ({
  billetNumberBox: {
    backgroundColor: theme.palette.subscription.primary.light,
    color: theme.palette.primary.main,
  },
  cardBillet: {
    width: '100%',
    marginRight: theme.spacing(2.5),
    cursor: 'pointer',
  },
  buttonPrimary: {
    backgroundColor: theme.palette.subscription.primary.main,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.subscription.primary.dark,
    },
  },
})

export default styles
