import React, { useState } from 'react'

import { Box, Container } from '@material-ui/core'
import { Text } from '..'
import { RoundedButton } from 'components'

import * as service from 'service'

const QuestionPage = ({ question, handleNext }) => {
  const [loading, setLoading] = useState()

  const handleAnswer = async (answer) => {
    setLoading(true)
    await service.dponet.trainningJourneys.answer({
      questionId: question?.id,
      trainningQuestion: { answer },
    })
    setLoading(false)

    handleNext()
  }

  return (
    <Container maxWidth="lg">
      <Text fontSize="3.5vh" variant="h4" gutterBottom>
        {question?.question}
      </Text>

      <Box display="flex" mt={2} width="100%" justifyContent="center">
        <Box mr={2}>
          <RoundedButton
            variant="outlined"
            onClick={() => handleAnswer('Falso')}
            withPadding
            disabled={loading}
          >
            Falso
          </RoundedButton>
        </Box>

        <RoundedButton
          variant="primary"
          onClick={() => handleAnswer('Verdadeiro')}
          withPadding
          disabled={loading}
        >
          Verdadeiro
        </RoundedButton>
      </Box>
    </Container>
  )
}

export default QuestionPage
