import React from 'react'
import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'

import {
  Stepper,
  GeneralInformation,
  OccurrenceScience,
  OpportunityCommunication,
  DescriptionImpact,
  RiskConsequence,
  SecurityMeasure,
} from './components'

import useIncident from 'hooks/useIncident'
import useIncidentManegement from 'hooks/useIncidentManegement'

import incidentConstants from 'constants/incidents'
import styles from './styles'

const useStyles = makeStyles(styles)

const Complete = ({ incident, refresh }) => {
  const {
    INCIDENT_INFORMATION_STEPS,
    GENERAL_INFORMATION_STEP,
    SCIENCE_OCCURRENCE_STEP,
    TIMPERTIVITY_COMMUNICATION_STEP,
    DESCRIPTION_IMPACT_STEP,
    RISKS_CONSEQUENCES_STEP,
    SECURITY_MEASURES_STEP,
  } = incidentConstants

  const classes = useStyles()
  const steps = INCIDENT_INFORMATION_STEPS.map((textStep) => textStep.step)

  const { lastActiveStep, activeStep, nextStep } = useIncident()
  const { handleNext: handleNextIncidentManegementStep } =
    useIncidentManegement()

  const handleNext = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })

    if (activeStep < 5) {
      refresh()
      nextStep()
    } else {
      handleNextIncidentManegementStep()
    }
  }

  return (
    <Grid container>
      <Grid item xl={9} lg={9} md={8} xs={12}>
        <Paper className={classes.fullCardWidth}>
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            fontWeight={500}
          >
            <Typography variant="subtitle1">
              <Box fontWeight="bold">
                {INCIDENT_INFORMATION_STEPS[activeStep].title}
              </Box>
            </Typography>
            <Typography variant="subtitle1">
              Etapa {activeStep + 1} de 6
            </Typography>
          </Box>
          <Divider />
          <Grid container>
            <Grid item xs={12}>
              <Box width="100%" mt={2}>
                {activeStep === GENERAL_INFORMATION_STEP && (
                  <GeneralInformation
                    incident={incident}
                    idForm={INCIDENT_INFORMATION_STEPS[activeStep].buttonId}
                    handleNext={handleNext}
                    stepName={INCIDENT_INFORMATION_STEPS[activeStep].title}
                  />
                )}
                {activeStep === SCIENCE_OCCURRENCE_STEP && (
                  <OccurrenceScience
                    incident={incident}
                    idForm={INCIDENT_INFORMATION_STEPS[activeStep].buttonId}
                    handleNext={handleNext}
                    stepName={INCIDENT_INFORMATION_STEPS[activeStep].title}
                  />
                )}
                {activeStep === TIMPERTIVITY_COMMUNICATION_STEP && (
                  <OpportunityCommunication
                    incident={incident}
                    idForm={INCIDENT_INFORMATION_STEPS[activeStep].buttonId}
                    handleNext={handleNext}
                    stepName={INCIDENT_INFORMATION_STEPS[activeStep].title}
                  />
                )}
                {activeStep === DESCRIPTION_IMPACT_STEP && (
                  <DescriptionImpact
                    incident={incident}
                    idForm={INCIDENT_INFORMATION_STEPS[activeStep].buttonId}
                    handleNext={handleNext}
                    stepName={INCIDENT_INFORMATION_STEPS[activeStep].title}
                  />
                )}
                {activeStep === RISKS_CONSEQUENCES_STEP && (
                  <RiskConsequence
                    incident={incident}
                    idForm={INCIDENT_INFORMATION_STEPS[activeStep].buttonId}
                    handleNext={handleNext}
                    stepName={INCIDENT_INFORMATION_STEPS[activeStep].title}
                  />
                )}
                {activeStep === SECURITY_MEASURES_STEP && (
                  <SecurityMeasure
                    incident={incident}
                    idForm={INCIDENT_INFORMATION_STEPS[activeStep].buttonId}
                    handleNext={handleNext}
                    stepName={INCIDENT_INFORMATION_STEPS[activeStep].title}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xl={3} lg={3} md={4} xs={12}>
        <Stepper lastActiveStep={lastActiveStep} steps={steps} />
      </Grid>
    </Grid>
  )
}

export default Complete
