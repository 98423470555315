import { jsPDF } from 'jspdf'
import moment from 'moment'
import { orderBy } from 'lodash'

import templates from '../templates'
import pages from './pages'

import * as service from 'service'
import constants from 'constants/index'

moment.locale('pt-br')
var dateNow = moment().format('MMM YYYY')

dateNow = dateNow.charAt(0).toUpperCase() + dateNow.slice(1)

const exportPdf = async (
  userCompany,
  customStyles,
  customLogo,
  dataProcesses = [],
  qrCode = '',
  snackbar = () => {},
  shouldCreateVersion = false,
  refresh = () => {},
  setExportingPdf = () => {},
) => {
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    userUnit: 300,
  })

  try {
    templates.firstCover(
      pdf,
      userCompany?.company?.name,
      userCompany?.company?.cpf || userCompany?.company?.cnpj,
      dateNow,
      customStyles,
      customLogo,
      'Relatório de Legítimo Interesse',
      qrCode,
    )

    const dataProcessOrderById = orderBy(dataProcesses, 'id')

    dataProcessOrderById.map((dataProcess) => {
      return pages.dataProcessLia(pdf, dateNow, dataProcess, customStyles)
    })

    templates.lastCover(pdf, customStyles, customLogo)

    const reportName = `Relatório_de_Legítimo_Interesse_${userCompany?.company?.name
      ?.split(' ')
      ?.join('_')
      ?.toLowerCase()}_${dateNow}.pdf`

    pdf.save(reportName)

    if (shouldCreateVersion) {
      const file = new File([pdf.output('blob')], reportName)
      await service.dponet.reportVersions.create({
        file,
        name: 'Relatório de Legítimo Interesse',
        kind: constants.reportVersions.KIND.LIA,
      })
      refresh()
    }

    snackbar.open({
      message: 'Relatório Legítimo Interesse gerado com sucesso',
      variant: 'success',
    })
  } catch (error) {
    console.error(error)
    snackbar.open({
      message:
        error?.response?.data?.message ||
        'Erro ao gerar o Relatório Legítimo Interesse!',
      variant: 'error',
    })
  }
  setExportingPdf(false)
}

export default exportPdf
