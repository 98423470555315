const mountStepImpact = (data) => {
  const incidentViolationTypes = data?.incidentViolationTypes?.map((item) => {
    return {
      id: item?.value,
    }
  })

  return {
    ...data,
    incidentViolationTypes,
  }
}

export default mountStepImpact
