const styles = (theme) => ({
  gapAnalysisImage: {
    maxWidth: '100%',
    maxHeight: '15vh',
  },
  recommendationHeader: {
    backgroundColor: theme.palette.white,
  },
  title: {
    fontWeight: '600',
  },
})

export default styles
