import React from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'

import { Box, Link, Typography, makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import styles from './styles'
import { reverse } from 'named-urls'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const SuggestionAlert = ({
  hidden,
  sectionName,
  sectionTab,
  dataProcessId,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const toSectionRedirect = () => {
    history.push({
      pathname: reverse(routes.dataProcess.viewSuggestions, {
        dataProcessId: dataProcessId,
      }),
      search: `?tab=${sectionTab}`,
    })
  }

  if (hidden) {
    return <></>
  }

  return (
    <Box mt={2}>
      <Alert variant="filled" severity="info" color="warning">
        <Typography>
          {sectionName} possui sugestões.{' '}
          <Link
            variant="body1"
            className={classes.linkInfo}
            onClick={toSectionRedirect}
          >
            Clique aqui
          </Link>
        </Typography>
      </Alert>
    </Box>
  )
}

SuggestionAlert.propTypes = {
  hidden: PropTypes.bool,
  sectionName: PropTypes.string,
  sectionTab: PropTypes.string,
  dataProcessId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}

SuggestionAlert.defaultValues = {
  hidden: true,
}

export default SuggestionAlert
