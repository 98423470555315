import { makeStyles } from '@material-ui/core'
import THEMES from 'constants/theme'

const styles = makeStyles((theme) => ({
  root: {
    position: 'relative',

    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.palette.subscription.primary.main,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
        }
      : {}),
    border: 0,
  },
  logo: {
    fontSize: 0,
    width: '130px',
    [theme.breakpoints.up('md')]: {
      width: '140px',
    },
  },
  subTopBar: {
    backgroundColor: theme.palette.primary.topBar,
  },
  itemsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  linksText: {
    color: theme.palette.white,
  },
  itemContainer: {
    margin: '10px',
  },
  menuButton: {
    color: theme.palette.white,
  },
  routerLink: {
    maxHeight: '44.7px',
    paddingBottom: '-3.2px',
  },
  menuResume: {
    maxHeight: '44.7px',
    maxWidth: '44.7px',
    backgroundColor: `${theme.palette.subscription.primary.light} !important`,
    color: `${theme.palette.subscription.primary.dark} !important`,
  },
}))

export default styles
