import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  urlInput: {
    '& .MuiOutlinedInput-root': {
      '& input': {
        color: theme.palette.common.white,
        fontSize: 16,
      },
      '& fieldset': {
        borderColor: theme.palette.common.white,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.common.white,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.white,
      },
    },
  },
}))

export default styles
