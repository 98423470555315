import { map, filter } from 'lodash'

const manageOptions = (existant = [], newState = []) => {
  const existantIds = map(existant, 'questionControlId')
  const newStateIds = map(newState, 'id')

  const toCreateIds = filter(newStateIds, (id) => !existantIds.includes(id))
  const toDestroyIds = filter(existantIds, (id) => !newStateIds.includes(id))

  return {
    toCreate: map(toCreateIds, (id) => ({ id })),
    toDestroy: map(toDestroyIds, (id) => ({ id })),
  }
}

export default manageOptions
