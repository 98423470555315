const styles = (theme) => ({
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 400,
  },
})

export default styles
