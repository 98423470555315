import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Button, Grid, Link, Paper, Typography, Box } from '@material-ui/core'

import { TitleComponent } from './components'

import { getWebsiteURL } from 'service/env'

import { ListAdequacy } from 'components'
import { routes } from 'Routes'

import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'
import useStyles from './styles'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'

const SupplierAdequacy = ({ status, nonCompliance }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { loadOut, company } = useAuth()

  const { mixpanel } = helpers

  const buttonLogout = () => {
    mixpanel.track('Jornada de Fornecedores', 'Adequação do Fornecedor', {
      action: 'Não_estou_interessado-Adequação_do_Fornecedor',
    })
    service.dponet.auth.logout()
    loadOut()
    window.location.replace(getWebsiteURL())
  }

  const handleFinish = () => {
    mixpanel.track('Jornada de Fornecedores', 'Adequação do Fornecedor', {
      action: 'Finalizar-Adequação_do_Fornecedor',
    })
  }

  const sendRd = async () => {
    try {
      await service.dponet.companies.notificationRdStation({
        companyId: company.id,
      })
      mixpanel.track('Jornada de Fornecedores', 'Adequação do Fornecedor', {
        action: 'Solicitar_contato_especialista-Adequação_do_Fornecedor',
      })
      snackbar.open({
        message:
          'Um e-mail de contato será enviado para você, confira sua caixa de entrada!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro ao solicitar o contato',
        variant: 'error',
      })
    }
  }

  return (
    <Grid container spacing={6} className={classes.root}>
      <Grid item xs={12} className={classes.text}>
        <TitleComponent
          status={status}
          classes={classes}
          nonCompliance={nonCompliance}
        />
      </Grid>
      {status === constants.companySuppliers.REFUSED_STATUS ? (
        <>
          <Grid item xs={12} lg={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.description} align="justify">
                  Adequar à LGPD pode ser crucial para&nbsp;
                  <Typography className={classes.descriptionUnderlined}>
                    evitar processos judiciais e multas
                  </Typography>
                  &nbsp; devido a violações de privacidade e proteger a&nbsp;
                  <Typography className={classes.descriptionUnderlined}>
                    reputação da sua empresa sem custar caro.
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ListAdequacy />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} className={classes.groupButton}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Paper
                onClick={sendRd}
                variant="outlined"
                component={Button}
                className={classes.button}
                fullWidth
              >
                SOLICITAR CONTATO GRATUITO DE ESPECIALISTA LGPD
              </Paper>
              <Paper
                onClick={buttonLogout}
                variant="outlined"
                component={Button}
                className={clsx(classes.button, classes.outlined)}
                fullWidth
              >
                NÃO ESTOU INTERESSADO
              </Paper>
            </Box>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} lg={6} className={classes.groupButton}>
          <Link href={routes.supplierStep} onClick={handleFinish}>
            <Paper
              variant="outlined"
              component={Button}
              className={clsx(classes.button, classes.outlined)}
              fullWidth
            >
              FINALIZAR
            </Paper>
          </Link>
        </Grid>
      )}
    </Grid>
  )
}

SupplierAdequacy.propTypes = {
  status: PropTypes.string,
  nonCompliance: PropTypes.number,
}

export default SupplierAdequacy
