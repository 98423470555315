const dataMultiOptions = (entities = []) => {
  const fragilityEntities = entities?.map((entity) => ({
    id: entity?.id ?? entity?.entityId,
    label: entity?.name ?? entity?.label,
    group: entity?.group ?? entity?.department?.name,
    entityType: entity?.entityType,
    departmentId: entity?.department?.id ?? entity?.departmentId,
  }))

  return fragilityEntities
}

export default dataMultiOptions
