import React, { useEffect } from 'react'
import { RHFInput } from 'react-hook-form-input'
import { Controller, useForm } from 'react-hook-form'
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'

import { Label } from 'components'

import useConsent from 'hooks/useConsent'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import schema from './schema'
import constants from 'constants/index'

import useStyles from './styles'

const GeneralInformation = () => {
  const {
    nextStep,
    toNextStep,
    setToNextStep,
    setDataConsentForm,
    dataConsentForm,
  } = useConsent()

  const snackbar = useSnackbar()

  const classes = useStyles()

  const { register, setValue, handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      title: dataConsentForm?.title ?? '',
      subtitle: dataConsentForm?.subtitle ?? '',
      legalFrameworkId: dataConsentForm?.legalFrameworkId?.toString() ?? '',
    },
  })

  useEffect(() => {
    if (toNextStep) {
      handleSubmit(onSubmit)()

      if (errors) setToNextStep(false)
    }
    // eslint-disable-next-line
  }, [toNextStep])

  const onSubmit = async (data) => {
    try {
      setDataConsentForm((oldData) => ({ ...oldData, ...data }))

      nextStep()
      setToNextStep(false)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error, false) ||
          error?.error?.message,
        variant: 'error',
      })
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id={constants.consent.NEW_CONSENT_FORM_DRIVER_STEP_0}
    >
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary">
            Preencha os campos de informações gerais do formulário de
            consentimento
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Label title="Título do formulário" item xs={12}>
              <RHFInput
                as={
                  <TextField
                    error={!!errors.title}
                    helperText={errors?.title?.message}
                    label="Insira o título do formulário"
                    fullWidth
                  />
                }
                register={register}
                setValue={setValue}
                name="title"
                mode="onChange"
              />
            </Label>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Label title="Subtítulo" item xs={12}>
              <RHFInput
                as={
                  <TextField
                    error={!!errors.subtitle}
                    helperText={errors?.subtitle?.message}
                    label="Descreva um subtítulo para o formulário"
                    fullWidth
                  />
                }
                register={register}
                setValue={setValue}
                name="subtitle"
                mode="onChange"
              />
            </Label>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Label
            title="Base legal para a coleta"
            description="Insira a finalidade para a base legal da coleta de dados"
            item
            xs={12}
          />
          <FormControl component="fieldset">
            <Controller
              control={control}
              name="legalFrameworkId"
              defaultValue=""
              as={
                <RadioGroup>
                  <FormControlLabel
                    value={constants.consent.LEGAL_BASIS_CONSENT_ID}
                    control={<Radio />}
                    className={classes.optionBox}
                    label={
                      <>
                        <Typography
                          variant="body1"
                          className={classes.optionTitle}
                        >
                          Consentimento
                        </Typography>
                        <Typography variant="caption">
                          Quando uma pessoa consente com o tratamento dos seus
                          dados, de forma livre, inequívoca e informada.
                        </Typography>
                      </>
                    }
                  />
                  <Divider />
                  <FormControlLabel
                    value={constants.consent.LEGAL_BASIS_LEGITIMATE_INTEREST_ID}
                    control={<Radio />}
                    className={classes.optionBox}
                    label={
                      <>
                        <Typography
                          variant="body1"
                          className={classes.optionTitle}
                        >
                          Legítimo interesse
                        </Typography>
                        <Typography variant="caption">
                          Quando a empresa possui um interesse legítimo para o
                          processamento e a utilização dos dados está dentro das
                          expectativas da pessoa, não sendo necessário obter
                          consentimento.
                        </Typography>
                      </>
                    }
                  />
                </RadioGroup>
              }
              mode="onChange"
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  )
}

export default GeneralInformation
