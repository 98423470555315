import React from 'react'
import PropTypes from 'prop-types'

import LinearProgressTable from 'components/LinearProgressTable/LinearProgressTable'
import helpers from 'helpers'

const ComplianceByQuestionnaire = ({ questionnaires, ...rest }) => {
  const rows = helpers.myLgpd.mountComplianceRows(questionnaires)

  return (
    <LinearProgressTable
      rows={rows}
      title="Conformidade por questionário"
      tableType="questionnaire"
      percentage
      {...rest}
    />
  )
}

ComplianceByQuestionnaire.propTypes = {
  questionnaires: PropTypes.array.isRequired,
}

export default ComplianceByQuestionnaire
