import { colors, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  optionsButton: {
    backgroundColor: theme.palette.primary.main,
    color: colors.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
  customPopover: {
    maxWidth: 500,
  },
  contentHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    gridGap: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  contentHeaderBox: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
    },
  },
}))

export default useStyles
