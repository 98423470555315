import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

import { routes } from 'Routes'
import { reverse } from 'named-urls'

import { ServiceCard } from '..'

import useStyles from './styles'

const ServicesSection = ({ services }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Typography className={classes.sectionTitle}>
        Empresas destaques
      </Typography>
      <Box className={classes.solutionsContainer}>
        {services?.map((service, idx) => (
          <ServiceCard
            key={idx}
            icon={service?.partner?.logo}
            title={service?.partner?.tradeName}
            description={service?.partner?.description}
            pathName={reverse(routes.marketplace.show, {
              partnerServiceId: service.id,
            })}
          />
        ))}
      </Box>
    </Box>
  )
}

ServicesSection.propTypes = {
  services: PropTypes.array,
}

ServicesSection.defaultProps = {
  services: [],
}

export default ServicesSection
