import { makeStyles } from '@material-ui/core'
import palette from 'theme/palette'

const styles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    transition: 'visibility 1s',
  },
  card: {
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(1.2)',
    },
  },
  iconBox: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: 20,
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%,${palette.azure} 100%) !important`,
  },
  icon: {
    color: palette.white,
  },
  infoBox: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: palette.primary.main,
  },
  body: {
    lineHeight: 'normal',
  },
  closeIcon: {
    padding: 10,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  transitionEnter: {
    opacity: 0,
    transform: 'scale(0.9)',
  },
  transitionEnterActive: {
    opacity: 1,
    transform: 'translateX(0)',
    transition: 'opacity 300ms, transform 300ms',
  },
  transitionExit: {
    opacity: 1,
  },
  transitionExitActive: {
    opacity: 0,
    transform: 'scale(0.9)',
    transition: 'opacity 300ms, transform 300ms',
  },
}))

export default styles
