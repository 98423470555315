const allowDocumentUpload = (files) => {
  const filesList = Array.from(files)
  var allowed = true
  const allowedExtensions = [
    'jpg',
    'jpeg',
    'png',
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'csv',
  ]

  const forbidedDocuments = []

  filesList.forEach((file) => {
    const name = file.name
    const fileExtension = name.split('.').pop().toLowerCase()

    if (!allowedExtensions.includes(fileExtension)) {
      allowed = false
      forbidedDocuments.push(name)
    }
  })

  return { allowed, forbidedDocuments }
}

export default allowDocumentUpload
