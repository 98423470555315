import React from 'react'
import PropTypes from 'prop-types'
import { TextField, Grid } from '@material-ui/core'

import { Controller, useForm } from 'react-hook-form'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'
import * as service from 'service'
import helpers from 'helpers'

const CompanyServiceForm = ({
  companyName,
  responsable,
  email,
  partnerServiceId,
  companyId,
  setIsLoading,
}) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const { mixpanel } = helpers

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      companyName,
      responsable,
      email,
    },
  })

  const onSubmit = async (data) => {
    setIsLoading(true)

    try {
      mixpanel.track('Marktplace', 'Modal de solicitação de serviço', {
        action: 'Clique botão solicitar',
      })

      setIsLoading(true)

      await service.marketPlace.orders.orderService({
        order: { ...data, partnerServiceId, externalId: companyId },
      })

      snackbar.open({
        message: 'Solicitação enviada com sucesso!',
        variant: 'success',
      })

      history.push(routes.marketplace.all)
    } catch (error) {
      const formattedErrorMessage =
        helpers.formatters.errorMessage(error?.response?.data?.error, false) ||
        'Ocorreu um erro! Tente novamente.'

      snackbar.open({
        message: formattedErrorMessage,
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form id="partner-service-form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Controller
            as={<TextField fullWidth label="Empresa contratante" disabled />}
            control={control}
            name="companyName"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={5}>
          <Controller
            as={<TextField fullWidth label="Responsável" disabled />}
            control={control}
            name="responsable"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={7}>
          <Controller
            as={<TextField label="E-mail" fullWidth disabled />}
            control={control}
            name="email"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={5}>
          <Controller
            as={
              <TextField
                fullWidth
                label="Telefone"
                error={!!errors.phone}
                helperText={errors?.phone?.message}
              />
            }
            control={control}
            name="phone"
            mode="onBlur"
          />
        </Grid>
      </Grid>
    </form>
  )
}

CompanyServiceForm.propTypes = {
  typeService: PropTypes.string,
  partnerName: PropTypes.string,
  categoryName: PropTypes.string,
  setIsLoading: PropTypes.func,
}

export default CompanyServiceForm
