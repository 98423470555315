const calculatePercentage = (total, count) => ((count / total) * 100).toFixed(2)

const mountQuestionControlStatsRows = (questionControlArray) => {
  const totalCount = questionControlArray?.reduce(
    (acc, category) => acc + category.count,
    0,
  )

  // Transforma os dados
  const questionControlsRows = questionControlArray?.map((category) => {
    return {
      name: category.kind,
      value: category.count,
      percentage: calculatePercentage(totalCount, category.count),
    }
  })

  return questionControlsRows
}

export default mountQuestionControlStatsRows
