import React from 'react'

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core'

import FormModal from './Form'

import styles from './styles'

const useStyles = makeStyles(styles)

const SpecificRisksModal = ({
  open,
  nonComplianceReportId,
  specificRisk,
  setOpen = () => {},
  onEvent = () => {},
}) => {
  const submitSuccess = async () => {
    await onEvent()
    setOpen(false)
  }

  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      fullWidth
      disableAutoFocus
    >
      <DialogTitle>
        <Box>
          <Typography variant="h4">
            {specificRisk ? 'Editar' : 'Adicionar'} risco específico
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent} dividers>
        <FormModal
          specificRisk={specificRisk}
          handleCloseModal={() => setOpen(false)}
          nonComplianceReportId={nonComplianceReportId}
          submitSuccess={submitSuccess}
        />
      </DialogContent>
    </Dialog>
  )
}

export default SpecificRisksModal
