import React from 'react'
import { Box, TextField, Typography } from '@material-ui/core'

import useStyles from './styles'
import { Controller } from 'react-hook-form'

const Questions = ({ control, errors, order, text, show = false }) => {
  const classes = useStyles()

  const name = `question${order}`

  return (
    <Box my={3}>
      <Typography className={classes.questionText}>
        {order} - {text}
      </Typography>
      <Controller
        name={name}
        control={control}
        as={
          <TextField
            disabled={show}
            fullWidth
            inputProps={{ maxLength: 1000 }}
            multiline
            minRows={4}
            error={!!errors[name]}
            helperText={errors[name]?.message}
          />
        }
      />
    </Box>
  )
}

export default Questions
