import constants from 'constants/index'
const statusChipColor = (status, classes) => {
  switch (status) {
    case constants.companySuppliers.APPROVED_STATUS:
      return classes.approved
    case constants.companySuppliers.REFUSED_STATUS:
      return classes.refused
    case constants.companySuppliers.INACTIVE_STATUS:
      return classes.inactive
    case constants.companySuppliers.DISAPPROVED_STATUS:
      return classes.refused
    default:
      return classes.indicated
  }
}

export default statusChipColor
