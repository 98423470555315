import palette from 'theme/palette'

import { ratio } from '../../helpers'

import {
  WIDTH as WIDTHPdf,
  HEIGHT as HEIGHTPdf,
  fontSize,
  PADDING,
  ROWGAP,
} from '../../constants'

const summary = (pdf) => {
  const WIDTH = WIDTHPdf(pdf)
  const HEIGHT = HEIGHTPdf(pdf)

  pdf.addPage()
  pdf.setFillColor(palette.white)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')

  pdf.setFontSize(fontSize.title)
  pdf.setLineHeightFactor(1.2)
  pdf.setFont('Raleway-Bold')

  pdf.setTextColor(palette.subscription.primary.main)
  pdf.text('Sumário', PADDING, PADDING + fontSize.subtitle, null, null, 'left')

  pdf.setFontSize(fontSize.subtitle)
  pdf.setFont('Roboto-Regular')

  pdf.text(
    '1. Introdução _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _   2',
    PADDING + ratio(10),
    ratio(630),
    null,
    null,
    'left',
  )

  pdf.text(
    '2. Visão Geral _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _    5',
    PADDING + ratio(10),
    ratio(630) + ROWGAP,
    null,
    null,
    'left',
  )

  pdf.text(
    '3. Processos _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _   10',
    PADDING + ratio(10),
    ratio(630) + ROWGAP * 2,
    null,
    null,
    'left',
  )

  pdf.text(
    '4. Diagnósticos _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _   15',
    PADDING + ratio(10),
    ratio(630) + ROWGAP * 3,
    null,
    null,
    'left',
  )

  pdf.text(
    '5. Incidentes _ _ _ _ _ _ _ _ _ _ _  _ _ _ _ _ __ _ _ _ _ _ _ _  21',
    PADDING + ratio(10),
    ratio(630) + ROWGAP * 4,
    null,
    null,
    'left',
  )

  pdf.text(
    '6. Fornecedores _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _  23',
    PADDING + ratio(10),
    ratio(630) + ROWGAP * 5,
    null,
    null,
    'left',
  )
}

export default summary
