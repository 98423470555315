import React from 'react'
import PropTypes from 'prop-types'

import { BarChart, DashboardChartPaper } from 'components'

import { routes } from 'Routes'
import constants from 'constants/index'

const RiskByDepartment = ({ processStats, ...rest }) => {
  const departments = processStats?.departments
    ?.sort((a, b) => {
      const sumA = a.risks.low + a.risks.medium + a.risks.high + a.risks.severe
      const sumB = b.risks.low + b.risks.medium + b.risks.high + b.risks.severe
      return sumB - sumA
    })
    .slice(0, 4)

  const {
    LOW_FRAGILITY_STATUS,
    MEDIUM_FRAGILITY_STATUS,
    HIGH_FRAGILITY_STATUS,
    SEVERE_FRAGILITY_STATUS,
  } = constants.nonComplianceReports

  const series = departments?.map((department) => {
    const { low, medium, high, severe } = department?.risks
    return { name: department.name, data: [low, medium, high, severe] }
  })

  return (
    <DashboardChartPaper
      title="Contribuição de cada departamento para o riscos"
      subtitle="Risco baixo, médio, alto e severo"
      redirect={routes.dataProcess.all}
      redirectParams={{
        fragilityId: [
          LOW_FRAGILITY_STATUS,
          MEDIUM_FRAGILITY_STATUS,
          HIGH_FRAGILITY_STATUS,
          SEVERE_FRAGILITY_STATUS,
        ],
      }}
      {...rest}
    >
      <BarChart
        colors={['#0763a0', '#16a63c', '#e83a49', '#fecc31']}
        names={['Baixo', 'Médio', 'Alto', 'Severo']}
        series={series}
        heightValue={250}
        id="contribute-by-risk-chart"
        showLabels
      />
    </DashboardChartPaper>
  )
}

RiskByDepartment.propTypes = {
  processStats: PropTypes.object.isRequired,
}

export default RiskByDepartment
