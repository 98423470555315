import React from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Controller } from 'react-hook-form'

import useDataProcessTemplateQuestion from 'hooks/useDataProcessTemplateQuestion'

const MultiSelect = ({ control, question, disabled = false }) => {
  const { errors } = useDataProcessTemplateQuestion()

  const name = `question-${question.id}`

  const options = question?.dataProcessTemplateQuestionOptions?.map(
    (option) => ({
      id: option.id,
      name: option.option,
    }),
  )

  return (
    <Controller
      as={
        <Autocomplete
          multiple
          options={options}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) =>
            option.id === value.id || value.id === 0
          }
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!errors[name]}
              helperText={errors[name]?.message}
              variant="outlined"
            />
          )}
        />
      }
      name={name}
      control={control}
      onChange={([, data]) => data}
      rules={{ required: question?.requiredField }}
    />
  )
}

export default MultiSelect
