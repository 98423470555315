import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  checks: {
    fontFamily: 'Roboto',
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(20),
    paddingLeft: 10,
  },
  listChecks: {
    padding: '15px 0px 0px 0px',
  },
  groupList: {
    paddingTop: 15,
  },
}))

export default styles
