const STEP_LABELS = ['DPONET', 'LGPD', 'SELO', 'CERTIFICADO', 'FINALIZAR']

const STEP_WELCOME_INDEX = [0, 0]
const STEP_DPO_INDEX = [0, 1]
const STEP_LGPD_INDEX = [1, 0]
const STEP_STAMP_INDEX = [2, 0]
const STEP_CERTIFICATION_INDEX = [3, 0]
const STEP_CHECKPOINT_INDEX = [4, 0]
const STEP_COOKIE_INDEX = [4, 1]
const STEP_COOKIE_PRODUCTS_INDEX = [4, 2]
const STEP_PRODUCT_CONFIRM_INDEX = [4, 3]
const STEP_CONCLUSION_INDEX = [5, 0]

const STEP_DPO_TAG = 'step_dponet'
const STEP_LGPD_TAG = 'step_lgpd'
const STEP_STAMP_TAG = 'step_stamp'
const STEP_CERTIFICATION_TAG = 'step_certification'
const STEP_COOKIE_TAG = 'step_cookie'
const STEP_CONCLUSION_TAG = 'step_conclusion'

const STEP_DPO = 1
const STEP_LGPD = 2
const STEP_STAMP = 3
const STEP_CERTIFICATION = 4
const STEP_COOKIE = 5
const STEP_CONCLUSION = 6

const COMPANY_WITHOUT_SITE_OBSERVATION =
  'Etapa marcada como concluída pois a empresa não possui um site.'

const DIALOG_INFOS = {
  DPONET: {
    title: 'O que é a DPOnet?',
    description:
      'Descubra como sua organização pode atingir a conformidade com a LGPD utilizando a DPOnet. Aprenda sobre os benefícios e processos envolvidos para colocar sua empresa em conformidade com a lei.',
  },
  LGPD: {
    title: 'O que é a LGPD?',
    description:
      'Obtenha conhecimento sobre a LGPD e aprenda a aplicar as melhores práticas na sua organização. Conheça os benefícios de estar em conformidade com a lei e os possíveis malefícios caso sua empresa não esteja em conformidade.',
  },
  STAMP: {
    title: 'Selo',
    description:
      'O selo de adequação à LGPD indica que uma organização está com as diretrizes mínimas sobre proteção de dados pessoais. Este selo é uma forma de demonstrar para clientes e parceiros que a organização leva a sério a proteção dos dados de seus usuários e está agindo de acordo com as recomendações mínimas estabelecidas pela LGPD.',
  },
  CERTIFICATION: {
    title: 'Certificado',
    description: `Parabéns! Você obteve o certificado de introdução à LGPD (Lei Geral de Proteção de Dados Pessoais). Este certificado evidencia que você está ciente das obrigações e dos princípios estabelecidos pela LGPD, e que entende a importância de proteger os dados pessoais dos usuários.\n\nContinue se dedicando nos outros treinamentos que oferecemos aqui na plataforma para adequar a sua empresa a LGPD`,
  },
  DOWNLOAD_CONFIRMATION: (isStampStep) => ({
    title: `Você baixou o ${isStampStep ? 'selo' : 'certificado'}?`,
    description: isStampStep
      ? 'Preparamos um selo especialmente para sua organização! Baixe o arquivo e siga para as próximas etapas!'
      : 'Preparamos um certificado de “Introdução à LGPD” especialmente para você! Baixe o arquivo e siga para as próximas etapas!',
  }),
  COOKIES: {
    title: 'Site na internet',
    description: `Um site na internet é uma coleção de páginas da web que contém informações sobre um determinado assunto. Essas páginas podem incluir texto, imagens, vídeos e links para outras páginas. Os sites são acessados ​​por meio de um navegador da web, como o Google Chrome ou o Firefox, e podem ser encontrados digitando o endereço do site na barra de endereço do navegador. Os sites podem ser usados para várias finalidades, como divulgação de informações, vendas de produtos ou serviços, entre outros. \n\nExemplo de site: `,
  },
}

const CONCLUSION_ITEMS = [
  'Mapeamento de processos',
  'Análise de riscos',
  'Canal de comunicação',
]

const userSteps = {
  COMPANY_WITHOUT_SITE_OBSERVATION,
  CONCLUSION_ITEMS,
  DIALOG_INFOS,
  STEP_CERTIFICATION_INDEX,
  STEP_CERTIFICATION_TAG,
  STEP_CERTIFICATION,
  STEP_CHECKPOINT_INDEX,
  STEP_CONCLUSION_INDEX,
  STEP_CONCLUSION_TAG,
  STEP_CONCLUSION,
  STEP_COOKIE_INDEX,
  STEP_COOKIE_PRODUCTS_INDEX,
  STEP_COOKIE_TAG,
  STEP_COOKIE,
  STEP_DPO_INDEX,
  STEP_DPO_TAG,
  STEP_DPO,
  STEP_LABELS,
  STEP_LGPD_INDEX,
  STEP_LGPD_TAG,
  STEP_LGPD,
  STEP_PRODUCT_CONFIRM_INDEX,
  STEP_STAMP_INDEX,
  STEP_STAMP_TAG,
  STEP_STAMP,
  STEP_WELCOME_INDEX,
}

export default userSteps
