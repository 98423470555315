import components from '../../components'

import palette from 'theme/palette'

import helpers from 'helpers'

import * as helpersRmc from '../../helpers'

const questionnaire = async (pdf, date, questionsRmcs, customThemeStatus) => {
  const questions = questionsRmcs.map((data) => {
    return [
      ['ID', data.id],
      ['Status', helpers.nonComplianceReports.status(data.status)],
      ['Origem', !!data.questionTitle ? data.questionTitle : 'Não informado'],
      [
        'Prazo',
        !!data.deadlineDate
          ? helpers.formatters.date(data.deadlineDate)
          : 'Não informado',
      ],
      [
        'Responsável',
        helpersRmc.responsableUserAction(data.nonComplianceReportActions),
      ],
      [
        'Riscos',
        data.specificRisks.length > 0
          ? helpersRmc.specificsRisksText(data.specificRisks)
          : 'Nenhum risco específico informado',
      ],

      [
        'Ação Corretiva',
        !!data.nonComplianceReportActions[0]?.correctiveAction
          ? data.nonComplianceReportActions[0]?.correctiveAction
          : 'Nenhuma ação registrada',
      ],
      [
        'Evidências',
        !!data.nonComplianceReportActions[0]?.correctionEvidence
          ? data.nonComplianceReportActions[0]?.correctionEvidence
          : 'Nenhuma evidência registrada',
      ],
    ]
  })

  pdf.addPage('a4', 'p')
  components.numberAndTitle(pdf, '3', 'Questionários')

  questions.forEach((data, index) => {
    components.lineWithDate(pdf, date)
    components.table(
      pdf,
      [],
      data,
      index === 0 ? 200 : pdf.lastAutoTable.finalY + 35,
      0.25,
    )
    components.numberPage(pdf, palette.black, customThemeStatus)
  })
}

export default questionnaire
