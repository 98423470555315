import permissions from '../permissions'

const TASK_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.TASKS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.TASKS.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.TASKS.UPDATE_OBSERVATIONS,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.TASKS.DESTROY,
  },
  {
    name: 'Finalizar',
    tag: permissions.TASKS.FINALIZE,
  },
]

export default TASK_LIST
