import { isNaN } from 'lodash'

const mountProgressRows = (questionnaires) => {
  return questionnaires?.map((questionnaire) => ({
    name: questionnaire.name,
    value: calculateProgressPercentage(questionnaire),
    percentage: calculateProgressPercentage(questionnaire),
  }))
}

const calculateProgressPercentage = (questionnaire) => {
  const progress =
    questionnaire?.asweredQuestions / questionnaire?.totalQuestions

  if (isNaN(progress)) {
    return 0
  }

  return parseFloat(progress * 100).toFixed(0)
}

export default mountProgressRows
