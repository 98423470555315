import antivirusCover from 'images/antivirus-cover.png'
import protecaoIcon from 'images/antivirus-protection.png'
import tempoRealIcon from 'images/antivirus-realtime.png'
import usabilidadeIcon from 'images/pentest-white-box.png'

import segurancaDadosIcon from 'images/antivirus-security.png'
import integridadeIcon from 'images/antivirus-mountain.png'
import conformidadeIcon from 'images/antivirus-law.png'

const antivirusIcons = [
  {
    title: 'Proteção Abrangente',
    altText: 'Proteção Abrangente',
    src: protecaoIcon,
  },
  {
    title: 'Segurança em Tempo Real',
    altText: 'Segurança em Tempo Real',
    src: tempoRealIcon,
  },
  {
    title: 'Usabilidade Intuitiva',
    altText: 'Usabilidade Intuitiva',
    src: usabilidadeIcon,
  },
]

const antivirus = {
  id: 26,
  sectionTitle: 'Antivírus e Antimalware',
  description:
    'Empresas devem ter antivírus para proteger seus dados e sistemas contra ameaças cibernéticas. Essa medida essencial minimiza riscos de ataques, preserva a integridade das informações e assegura a continuidade operacional, garantindo a confiança de clientes e parceiros.',
  whatIs: {
    primaryText: 'O que é o Antivírus e Antimalware?',
    text: 'Antivírus é um software que identifica, bloqueia e remove ameaças cibernéticas, como vírus e malware. Utiliza bancos de dados e algoritmos para analisar arquivos em busca de padrões suspeitos, protegendo assim os sistemas contra infecções e garantindo a segurança digital.',
    icons: antivirusIcons,
  },
  forWho: {
    text: 'Empresas de todos os portes e setores, desde pequenos negócios até grandes corporações, podem se beneficiar ao contratar um antivírus. A proteção contra ameaças cibernéticas é crucial para preservar dados confidenciais, manter a integridade operacional e salvaguardar a reputação da empresa. Setores financeiros, saúde e tecnologia, especialmente, são alvos frequentes, tornando essencial a adoção de soluções de segurança robustas para evitar prejuízos e garantir a confiança de clientes e parceiros.',
    cover: antivirusCover,
    coverText: 'Imagem de uma mão segurando uma lapa através de um escudo',
    benefitsList: [
      'Segurança de Dados',
      'Integridade Operacional',
      'Reputação e Confiança',
      'Conformidade e Regulamentação',
    ],
  },
  benefits: [
    {
      title: 'Segurança de Dados',
      altText: 'Segurança de Dados',
      description:
        'Elimine riscos associados à perda, esquecimento ou compartilhamento inadequado de senhas.',
      icon: segurancaDadosIcon,
    },
    {
      title: 'Integridade Operacional',
      altText: 'Integridade Operacional',
      description:
        'Funcionários não têm conhecimento das senhas, impedindo possíveis vazamentos.',
      icon: integridadeIcon,
    },
    {
      title: 'Conformidade e Regulamentação',
      altText: 'Conformidade e Regulamentação',
      description:
        'Ajuda a cumprir requisitos legais e regulamentações de segurança de dados.',
      icon: conformidadeIcon,
    },
  ],
}

export default antivirus
