import api from 'service/api'
import * as service from 'service'
import { getBaseURL } from 'service/env'
import axios from 'axios'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

const create = async (data) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/tickets/juridic`,
    data,
    ...config,
  })
}

const getAll = async (params) => {
  return await dponetAPI.get(`/tickets`, { params })
}

const get = async ({ ticketId = '', ...params }) => {
  return await dponetAPI.get(`/tickets/${ticketId}`, { params })
}

const createMessage = async ({ ticketId, ticketMessages }) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/tickets/${ticketId}/messages`,
    data: ticketMessages,
    ...config,
  })
}

const showMessages = async ({ ticketId = '', ...params }) => {
  return await dponetAPI.get(`/tickets/${ticketId}/messages`, { params })
}

const confirmTitularData = async ({ ticketId, ...params }) => {
  return await dponetAPI.post(
    `/tickets/${ticketId}/confirm_titular_data`,
    params,
  )
}

const sendTitularData = async ({ ticketId, titularData, ticketMessages }) => {
  var data = new FormData()

  data.append('titular_data', titularData)
  data.append('ticket_messages[content]', ticketMessages?.content || '')

  if (ticketMessages?.attachments) {
    for (const value of Object.entries(ticketMessages?.attachments)) {
      data.append(`ticket_messages[attachments][]`, value[1])
    }
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/tickets/${ticketId}/send_titular_data`,
    data,
    ...config,
  })
}

const createTicketFeedback = async ({ ticketId, ticketFeedback }) => {
  return await dponetAPI.post(`/tickets/${ticketId}/feedbacks`, {
    ticketFeedback,
  })
}

const reopenTicket = async ({ ticketId }) => {
  return await dponetAPI.put(`/tickets/juridic/${ticketId}/reopen`)
}

const closeTicket = async ({ ticketId }) => {
  return await dponetAPI.put(`/tickets/juridic/${ticketId}/close`)
}

const tickets = {
  closeTicket,
  confirmTitularData,
  create,
  createMessage,
  createTicketFeedback,
  get,
  getAll,
  reopenTicket,
  sendTitularData,
  showMessages,
}

export default tickets
