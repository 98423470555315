import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 50,
    backgroundColor: theme.palette.primary.topBar,
  },
  sectionTitle: {
    fontSize: 36,
    fontWeight: 700,
    fontFamily: 'Raleway',
    color: theme.palette.white,
  },
  solutionsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 24,
    justifyContent: 'center',
  },
}))

export default styles
