import healthImage from 'images/cyber-cover.png'
import custoImage from 'images/segment-custo.png'
import eficienciaImage from 'images/segment-efeciencia.png'
import reputacaoImage from 'images/segment-protecao-reputacao.png'

import prevencaoDadosImage from 'images/prevencao-perda-dados.png'
import seguroCyberImage from 'images/seguro-cyber.png'
import cofreSenhasImage from 'images/cofre-senhas.png'
import vunerabilidadesSociaisImage from 'images/vunerabilidades-sociais.png'
import backupCyberImage from 'images/backup-cyber.png'
import vunerabilidadesInfraImage from 'images/vunerabilidades-infra.png'

const healthIcons = [
  {
    title: 'Redução de custos',
    altText: 'Redução de custos',
    src: custoImage,
  },
  {
    title: 'Aumento da eficiência',
    altText: 'Aumento da eficiência',
    src: eficienciaImage,
  },
  {
    title: 'Proteção da reputação',
    altText: 'Proteção da reputação',
    src: reputacaoImage,
  },
]

const health = {
  id: 1,
  sectionTitle: 'Saúde',
  description:
    'O Marketplace DPOnet oferece o soluções que visa proteger empresas que aprofundam obrigações de transparência ativa e passiva e criam meios processuais para mobilizar a Administração Pública. A plataforma oferece produtos e serviços que fazem a diferença, ajudando a impulsionar a confiança dos seus clientes e solidificando sua reputação como líder em governança de dados responsável.',
  whatIs: {
    primaryText: 'Benefícios para empresas de Saúde',
    text: 'As empresas de saúde precisam estabelecer novas medidas para proteger os dados das pessoas envolvidas, especialmente dos pacientes, visto que esses dados podem ser considerados sensíveis por conterem informações relacionadas a doenças. A adequação à LGPD pode trazer benefícios para as empresas de saúde, como a melhora do relacionamento com o cliente, aumento da segurança jurídica, valorização do marketing, entre outros.',
    icons: healthIcons,
  },
  forWho: {
    text: 'A cobertura do Seguro health pode incluir, por exemplo, cobertura para processos cíveis ou criminais, procedimentos regulatório ou administrativo e processos de arbitragem; multas e penalidades cíveis e administrativas; restauração de reputação pessoal; responsabilidade trabalhista, tributária e previdenciária; saúde e segurança ocupacional; evento cibernético; indisponibilidade de bens e penhora online; gerenciamento de crise para o tomador; multas e penalidades; gerenciamento de crise; proteção de diretores e administradores aposentados ou por demissão voluntária; novas subsidiárias.\nO Seguro health é voltado para empresas de todos os portes e segmentos que desejam se proteger contra os riscos cibernéticos. O seguro é especialmente importante para empresas que lidam com informações confidenciais, como dados de clientes, informações financeiras e propriedade intelectual. ',
    cover: healthImage,
    coverText: 'Imagem de uma mão segurando uma lapa através de um escudo',
  },
  establishments: [
    {
      title: 'Hospitais',
      servicesAvailables: [
        {
          title: 'DLP (Prevenção em Perda de Dados)',
          description:
            'Ferramentas ou processos que, quando usados por um usuário, podem garantir que dados confidenciais não sejam acessados, usados indevidamente ou perdidos por usuários não autorizados.',
          pathName: '/marketplace/services/1',
          icon: prevencaoDadosImage,
        },
        {
          title: 'Seguro Cyber',
          description:
            'Amparar perdas financeiras decorrentes de ataques virtuais maliciosos, ou mesmo de incidentes decorrentes de erros ou negligências causados internamente na companhia, que resultem em vazamento de dados e danos ligados ao sigilo da informação.',
          pathName: '/marketplace/services/7',
          icon: seguroCyberImage,
        },
        {
          title: 'Cofre de senhas',
          description:
            'Um cofre de senhas é uma ferramenta online que armazena senhas com segurança, exigindo apenas uma senha mestra para acesso. Simplifica a gestão de senhas, promovendo a criação de senhas únicas para cada conta, melhorando a segurança online.',
          pathName: '/marketplace/services/3',
          icon: cofreSenhasImage,
        },
      ],
    },
    {
      title: 'Operadoras de Saúde',
      servicesAvailables: [
        {
          title: 'Vulnerabilidades Sociais',
          description:
            'Phishing e Ransomware são duas das vulnerabilidades sociais mais comuns que podem afetar a segurança de um sistema. Esses ataques podem ser evitados por meio de medidas de segurança.',
          pathName: '/marketplace/services/4',
          icon: vunerabilidadesSociaisImage,
        },
        {
          title: 'Backup e recuperação cyber',
          description:
            'O backup de recuperação cyber é uma técnica de segurança que envolve a cópia de dados importantes para um local seguro, a fim de protegê-los contra perda ou corrupção.',
          pathName: '/marketplace/services/5',
          icon: backupCyberImage,
        },
        {
          title: 'Vulnerabilidades Infra/Técnica',
          description:
            'As vulnerabilidades de segurança são as fraquezas/falhas de um sistema de segurança que poderiam ser potencialmente exploradas por uma ou mais ameaças e causar danos e prejuízos à organização.',
          pathName: '/marketplace/services/6',
          icon: vunerabilidadesInfraImage,
        },
      ],
    },
    {
      title: 'Clínicas',
      servicesAvailables: [
        {
          title: 'Vulnerabilidades Sociais',
          description:
            'Phishing e Ransomware são duas das vulnerabilidades sociais mais comuns que podem afetar a segurança de um sistema. Esses ataques podem ser evitados por meio de medidas de segurança.',
          pathName: '/marketplace/services/4',
          icon: vunerabilidadesSociaisImage,
        },
        {
          title: 'Backup e recuperação cyber',
          description:
            'O backup de recuperação cyber é uma técnica de segurança que envolve a cópia de dados importantes para um local seguro, a fim de protegê-los contra perda ou corrupção.',
          pathName: '/marketplace/services/5',
          icon: backupCyberImage,
        },
        {
          title: 'Vulnerabilidades Infra/Técnica',
          description:
            'As vulnerabilidades de segurança são as fraquezas/falhas de um sistema de segurança que poderiam ser potencialmente exploradas por uma ou mais ameaças e causar danos e prejuízos à organização.',
          pathName: '/marketplace/services/6',
          icon: vunerabilidadesInfraImage,
        },
      ],
    },
  ],
}

export default health
