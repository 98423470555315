import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  IconButton,
  Divider,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import NewForm from '../DataTreatmentModal/NewForm'

import useDataProcessOptions from 'hooks/useDataProcessOptions'

import DataProcessTemplateQuestionProvider from 'providers/DataProcessTemplateQuestionProvider'

const DataTreatmentShowModal = ({ open, setOpen, dataTreatment }) => {
  const { dataProcessTemplate } = useDataProcessOptions()

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Visualizar dados compartilhados</Typography>
          <IconButton
            onClick={() => setOpen(false)}
            color="primary"
            aria-label="close modal"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DataProcessTemplateQuestionProvider
          dataProcessTemplate={dataProcessTemplate}
          step="data_treatment"
          dataProcessTemplateAnswers={
            dataTreatment?.dataProcessTemplateAnswers || []
          }
        >
          <NewForm
            mode="show"
            dataTreatment={dataTreatment}
            handleCloseModal={() => setOpen(false)}
          />
        </DataProcessTemplateQuestionProvider>
      </DialogContent>

      <DialogActions />
    </Dialog>
  )
}

DataTreatmentShowModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  dataTreatment: PropTypes.object,
}

export default DataTreatmentShowModal
