import constants from 'constants/index'

const status = (status) => {
  switch (status) {
    case constants.auditProcess.WAITING_ACTION_STATUS:
      return constants.auditProcess.WAITING_ACTION_LABEL
    case constants.auditProcess.CONFIRMED_STATUS:
      return constants.auditProcess.CONFIRMED_LABEL
    case constants.auditProcess.UPDATED_STATUS:
      return constants.auditProcess.UPDATED_LABEL
    case constants.auditProcess.AUTOMATICALLY_APPROVED_STATUS:
      return constants.auditProcess.AUTOMATICALLY_APPROVED_LABEL
    case constants.nonComplianceReports.ON_TIME_STATUS_LABEL:
      return status
    case constants.nonComplianceReports.PENDING_STATUS_LABEL:
      return status
    case constants.nonComplianceReports.OVERDUE_STATUS_LABEL:
      return status
    case constants.nonComplianceReports.RISK_ACCEPTED_STATUS_LABEL:
      return status
    case constants.nonComplianceReports.FINALIZED_STATUS_LABEL:
      return status
    case constants.nonComplianceReports.SUBSTITUTED_STATUS_LABEL:
      return status
    case constants.nonComplianceReports.FROZEN_STATUS_LABEL:
      return status
    case constants.nonComplianceReports.CLOSED_STATUS_LABEL:
      return status
    case constants.nonComplianceReports.INACTIVE_STATUS_LABEL:
      return status
    case constants.nonComplianceReports.NECESSARY_FINALITY:
      return 'Necessario'
    case constants.nonComplianceReports.UNNECESSARY_FINALITY:
      return 'Aguardando avaliação'
    case constants.nonComplianceReports.ELIMINATED_FINALITY:
      return 'Não necessario'
    case constants.questionControl.ADOPTED_STATUS:
      return 'Adotado'
    case constants.questionControl.OVERDUE_STATUS:
      return 'Atrasado'
    case constants.questionControl.IN_PROGRESS_STATUS:
      return 'Em andamento'
    case constants.questionControl.PENDING_STATUS:
      return 'Pendente'
    case constants.questionControl.TREATED_STATUS:
      return 'Tratado'
    case constants.questionControl.RISK_ASSUMED_STATUS:
      return 'Risco assumido'
    default:
      return '-'
  }
}

export default status
