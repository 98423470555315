import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  boxContainer: {
    padding: 5,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  titleInfo: {
    color: theme.palette.primary.main,
    [theme.breakpoints.only('xs')]: {
      alignSelf: 'flex-start',
    },
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textAlign: 'justify',
  },
  listInfo: {
    marginLeft: 30,
  },
  headerBox: {
    marginBottom: 20,
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  button: {
    [theme.breakpoints.only('xs')]: {
      alignSelf: 'flex-end',
      marginBottom: 15,
    },
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    gridGap: 8,
  },
}))

export default styles
