import rmcs from './rmcs'
import process from './process'
import questionnaire from './questionnaire'
import incident from './incident'
import NotFound from './notFound'

const pages = {
  rmcs,
  process,
  questionnaire,
  incident,
  NotFound,
}

export default pages
