import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import qs from 'query-string'
import { Container } from '@material-ui/core'

import { ContentHeader, Page } from 'components'
import { Form } from './components'

import useIncidentManegement from 'hooks/useIncidentManegement'
import useConvertToFile from 'hooks/useConvertToFile'

const IncidentManagementCreate = () => {
  const location = useLocation()

  const query = qs.parse(location.search)

  const { incident, setIncident } = useIncidentManegement()
  const { fileList, loading } = useConvertToFile(incident?.detectionAttachments)

  useEffect(() => {
    if (!isEmpty(incident) && !loading)
      setIncident((prevIncident) => ({
        ...prevIncident,
        detectionAttachmentsList: fileList,
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, loading])

  return (
    <Page title="Novo incidente">
      <Container maxWidth={false}>
        <ContentHeader title="Novo Incidente" />

        <Form type="create" query={query} />
      </Container>
    </Page>
  )
}

export default IncidentManagementCreate
