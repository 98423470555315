import React, { useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Button,
  MenuItem,
  Typography,
  InputAdornment,
} from '@material-ui/core'
import { Copy as CopyIcon } from 'react-feather'
import { isNil } from 'lodash'

import { Select, TextEditor } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import * as service from 'service'
import { routes } from 'Routes'
import schema from './schema'
import helpers from 'helpers'
import useStyles from './styles'

const PrivacyPoliciesForm = (props) => {
  const [loading, setLoading] = useState(false)
  const [selectStatusOpen] = useState(false)
  const [political, setPoliticalStatus] = useState(false)

  const history = useHistory()
  const snackbar = useSnackbar()
  const classes = useStyles()
  const reference = useRef()

  const { errors, control, handleSubmit, reset, getValues } = useForm({
    validationSchema: schema,
    defaultValues: {
      category: props?.privacyPolicy?.category || '',
      title: props?.privacyPolicy?.title || '',
      content: props?.privacyPolicy?.content || '',
      status: props?.privacyPolicy?.status || '',
    },
  })

  const onSubmit = async (privacyPolicy) => {
    setLoading(true)

    if (political) privacyPolicy.status = constants.privacyPolicy.ACTIVE_STATUS
    try {
      if (props?.privacyPolicy?.id) {
        await service.dponet.privacyPolicies.put({
          privacyPolicyId: props?.privacyPolicy?.id,
          privacyPolicy,
        })
        snackbar.open({
          message: 'Política de privacidade editado com sucesso',
          variant: 'success',
        })
        setLoading(false)
        history.push(routes.privacyPolicy.all)
      } else {
        await service.dponet.privacyPolicies.create({ privacyPolicy })
        setLoading(false)
        history.push(routes.privacyPolicy.all)
        snackbar.open({
          message: 'Política de privacidade criada com sucesso',
          variant: 'success',
        })
      }
    } catch (error) {
      reset(getValues())
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setPoliticalStatus(false)
    }
  }

  const handleReturn = () => {
    history.push(routes.privacyPolicy.all)
  }

  const copyToClipboard = () => {
    try {
      window.navigator.clipboard.writeText(reference.current.value)
      snackbar.open({
        message: 'copiado com sucesso!',
        variant: 'success',
      })
    } catch (e) {
      console.error(e)
      snackbar.open({
        message: 'ocorreu um erro ao tentar copiar o link',
        variant: 'error',
      })
    }
  }

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} id="privacy-policy-form">
          <Grid container spacing={1}>
            <Grid
              item
              {...(props?.privacyPolicy ? { md: 6 } : { md: 12 })}
              sm={12}
              xs={12}
            >
              <Controller
                control={control}
                name="category"
                mode="onChange"
                as={
                  <Select
                    items={constants.privacyPolicy.CATEGORIES}
                    label="Categoria do documento"
                    error={!!errors.category}
                    helperText={errors.category?.message}
                  />
                }
              />
            </Grid>
            <Grid
              item
              {...(props?.privacyPolicy ? { md: 6 } : { md: 12 })}
              sm={12}
              xs={12}
            >
              <Controller
                as={
                  <TextField
                    label="Título"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.title}
                    helperText={errors.title?.message}
                    fullWidth
                  />
                }
                control={control}
                name="title"
                mode="onChange"
              />
            </Grid>
            {props?.privacyPolicy && (
              <>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Controller
                    as={
                      <TextField
                        open={selectStatusOpen}
                        select
                        label="Status"
                        variant="outlined"
                        error={!!errors.status}
                        helperText={errors.status?.message}
                        fullWidth
                      >
                        <MenuItem value="1">Rascunho</MenuItem>
                        <MenuItem value="2">Ativo</MenuItem>
                        <MenuItem value="3">Inativo</MenuItem>
                      </TextField>
                    }
                    control={control}
                    name="status"
                    mode="onChange"
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    className={classes.linkDocument}
                    variant="outlined"
                    defaultValue={
                      props?.privacyPolicy?.documentLink ||
                      'Não há um link disponível'
                    }
                    fullWidth
                    disabled
                    inputRef={reference}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Button
                            onClick={copyToClipboard}
                            disabled={isNil(props?.privacyPolicy?.documentLink)}
                          >
                            <CopyIcon />
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box p={1}>
                <Typography variant="subtitle2">
                  Elabore o conteúdo de sua política de privacidade
                </Typography>
              </Box>
              <Controller
                control={control}
                name="content"
                as={
                  <TextEditor
                    error={!!errors?.content}
                    helperText={errors?.content?.message}
                    configRest={{ height: 500 }}
                  />
                }
                mode="onBlur"
              />
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Box pr={1}>
              <Button
                type="button"
                variant="outlined"
                onClick={() => handleReturn()}
              >
                Voltar
              </Button>
            </Box>
            <Box pr={1}>
              <Button color="primary" variant="outlined" type="submit">
                Salvar rascunho
              </Button>
            </Box>
            <Box>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => setPoliticalStatus(true)}
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </>
  )
}

export default PrivacyPoliciesForm
