import permissions from '../permissions'

const TRAINING_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.VISUALIZE,
    tag: permissions.MOODLE_TRAININGS.VIEW,
  },
]

export default TRAINING_LIST
