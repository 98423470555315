import constants from 'constants/index'

const getTagByIndex = (step) => {
  const {
    STEP_DPO_TAG,
    STEP_LGPD_TAG,
    STEP_LGPD_INDEX,
    STEP_STAMP_TAG,
    STEP_STAMP_INDEX,
    STEP_CERTIFICATION_TAG,
    STEP_CERTIFICATION_INDEX,
    STEP_COOKIE_TAG,
    STEP_COOKIE_INDEX,
    STEP_CONCLUSION_TAG,
    STEP_CONCLUSION_INDEX,
  } = constants.userSteps

  switch (step) {
    case STEP_LGPD_INDEX[0]:
      return STEP_LGPD_TAG
    case STEP_STAMP_INDEX[0]:
      return STEP_STAMP_TAG
    case STEP_CERTIFICATION_INDEX[0]:
      return STEP_CERTIFICATION_TAG
    case STEP_COOKIE_INDEX[0]:
      return STEP_COOKIE_TAG
    case STEP_CONCLUSION_INDEX[0]:
      return STEP_CONCLUSION_TAG
    default:
      return STEP_DPO_TAG
  }
}

export default getTagByIndex
