import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Box, Grid, TextField, Typography } from '@material-ui/core'

import { Card, LoadingFeedback } from 'components'

import useIncidentManegement from 'hooks/useIncidentManegement'
import useSnackbar from 'hooks/useSnackbar'

import { routes } from 'Routes'
import * as service from 'service'
import formatters from 'helpers/formatters'
import schema from './schema'
import useStyles from './styles'

const LessonsLearnedStep = () => {
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const classes = useStyles()
  const snackbar = useSnackbar()

  const { wantsToAdvanceStep, resetAdvanceStep, incident } =
    useIncidentManegement()

  const { control, errors, getValues, triggerValidation } = useForm({
    defaultValues: {
      lessonLearned: incident?.lessonLearned || '',
    },
    validationSchema: schema,
  })

  const onSubmit = async () => {
    setLoading(true)

    try {
      const validForm = await triggerValidation()
      const data = getValues()

      if (!validForm) return

      await service.dponet.incidents.put({
        incidentId: incident.id,
        incident: {
          lessonLearned: data.lessonLearned,
          lessonLearnedForm: true,
        },
      })

      history.push(routes.incidents.all)
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      resetAdvanceStep()
    }
  }

  useEffect(() => {
    if (wantsToAdvanceStep) {
      onSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantsToAdvanceStep])

  return (
    <Grid container spacing={1}>
      <LoadingFeedback open={loading} />

      <Grid item xs={12}>
        <Card title="Lições Aprendidas">
          <Typography variant="body1">
            As lições aprendidas em um incidente de segurança com dados pessoais
            são cruciais para fortalecer as defesas e prevenir problemas
            futuros. Esta etapa visa fornecer conclusões e ideias sobre o
            incidente ocorrido, além de oferecer um campo para registro de
            melhorias e conclusões sobre o que você e sua equipe implementaram e
            aprenderam para mitigar riscos e danos.
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title="Pontos de Análise">
          <Box>
            <Typography
              variant="body1"
              color="primary"
              className={classes.topic}
              gutterBottom
            >
              O que motivou o incidente?
            </Typography>
            <Typography variant="body1" gutterBottom>
              Busque entender os fatores ou eventos que levaram ao ocorrido.
              Investigue as causas subjacentes do incidente, procurando
              identificar falhas em sistemas, processos ou comportamentos que
              contribuíram para o evento. Essa análise visa não apenas corrigir
              problemas imediatos, mas também prevenir futuros incidentes,
              abordando as raízes do problema.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography
              variant="body1"
              color="primary"
              className={classes.topic}
              gutterBottom
            >
              Avalie as medidas de segurança adotadas antes do incidente
            </Typography>
            <Typography variant="body1" gutterBottom>
              Essa análise envolve revisar políticas, procedimentos e
              ferramentas de segurança implementadas pela organização.
              Identificar falhas ou lacunas nessas medidas pode ajudar a
              fortalecer a postura de segurança, garantindo uma proteção mais
              eficaz contra ameaças futuras.
            </Typography>
          </Box>

          <Box mt={2}>
            <Typography
              variant="body1"
              color="primary"
              className={classes.topic}
            >
              Avalie as medidas que passaram a ser adotas após o incidente
            </Typography>
            <Typography variant="body1">
              Avaliar as medidas adotadas após o incidente é crucial para
              entender como a organização respondeu e se adaptou à situação.
              Isso envolve analisar as ações tomadas para fortalecer a segurança
              e prevenir futuros incidentes semelhantes. Identificar as novas
              políticas, procedimentos, tecnologias ou treinamentos
              implementados pode fornecer insights valiosos sobre o aprendizado
              da organização com o incidente e sua dedicação em melhorar sua
              postura de segurança.
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card title="Quais foram as lições aprendidas">
          <Controller
            as={
              <TextField
                fullWidth
                variant="outlined"
                multiline
                minRows={4}
                error={!!errors.lessonLearned}
                helperText={errors?.lessonLearned?.message}
              />
            }
            control={control}
            name="lessonLearned"
          />
        </Card>
      </Grid>
    </Grid>
  )
}

export default LessonsLearnedStep
