import React, { useEffect, useState } from 'react'

import {
  Box,
  TablePagination,
  useMediaQuery,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import usePagination from 'hooks/usePagination'
import useAuth from 'hooks/useAuth'

import { DataProcessCard } from 'components'
import useFilter from 'hooks/useFilter'
import constants from 'constants/index'

import * as service from 'service'

const DataProcessSuggested = ({ dataProcesses, setDataProcesses }) => {
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(12)

  const [isLoading, setLoading] = useState(true)

  const filter = useFilter()
  const theme = useTheme()
  const { setCountBadge } = useAuth()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  useEffect(() => {
    const loadDataProcess = async () => {
      const response = await service.dponet.dataProcesses.suggested({
        perPage,
        page,
        ...{ ...filter.filters },
      })
      setDataProcesses(response?.data)
      setCountBadge(response?.data?.meta?.totalCount)
      if (isLoading) {
        setLoading(false)
      }
    }
    loadDataProcess()
    //eslint-disable-next-line
  }, [isLoading, page, perPage, filter.filters])

  return (
    <>
      {isLoading ? (
        <Box
          id="loadBox"
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box display="flex" width="100%" mt={5}>
            <Grid container spacing={3}>
              <DataProcessCard
                xl={3}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                dataProcesses={dataProcesses?.dataProcesses}
                isLoading={isLoading}
                origin={'DataProcessSuggestion'}
                setIsLoading={setLoading}
                defaultStatus={constants.dataProcess.SUGGESTED}
              />
            </Grid>
          </Box>
          <TablePagination
            component="div"
            count={dataProcesses.meta.totalCount}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[4, 12, 36, 100]}
            labelRowsPerPage={isDesktop ? 'Por página' : ''}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Box>
      )}
    </>
  )
}

export default DataProcessSuggested
