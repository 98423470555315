const styles = (theme) => ({
  drawer: {
    width: 256,
    backgroundColor: theme.palette.layout.main,
  },
  standardBackground: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main} 80%, ${theme.palette.primary.hover} 100%)`,
  },
  customBackground: {
    backgroundColor: theme.palette.layout.main,
  },
  drawerFullScreen: {
    width: '100vw',
  },
})

export default styles
