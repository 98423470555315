import React, { useState } from 'react'

import { MenuButton, Permitted, DialogLogs } from 'components'
import { Button } from '@material-ui/core'

import isEmpty from 'helpers/functions/isEmpty'
import constants from 'constants/index'
import helpers from 'helpers'

import { DialogFinalize, DialogReopen, DialogResidualRisk } from '../'

import { reverse } from 'named-urls'

import { useHistory } from 'react-router-dom'

import { routes } from 'Routes'

import useRmc from 'hooks/useRmc'

import permission from 'constants/permissions'

const RmcActions = ({
  nonComplianceReport,
  responseLogWithStatus,
  onReload,
  setLoading,
  dialogFinalize,
  setDialogFinalize,
}) => {
  const [dialogResidualRisk, setDialogResidualRisk] = useState(false)
  const [dialogReopen, setDialogReopen] = useState(false)
  const [dialogLogs, setDialogLogs] = useState(false)

  const history = useHistory()
  const { oldScreen } = useRmc()

  return (
    <>
      <MenuButton>
        {oldScreen ? (
          <>
            {[
              constants.nonComplianceReports.FINALIZED_STATUS,
              constants.nonComplianceReports.RISK_ACCEPTED_STATUS,
            ].includes(nonComplianceReport.status) && (
              <Button
                color="secondary"
                fullWidth
                onClick={() => {
                  setDialogReopen(true)
                }}
              >
                Reabrir RMC
              </Button>
            )}
          </>
        ) : (
          <>
            {!helpers.nonComplianceReports.finalityCheck.necessary(
              nonComplianceReport,
            ) &&
              nonComplianceReport?.entity?.dataProcess?.adoptedFragilityId >
                constants.nonComplianceReports.MEDIUM_FRAGILITY_STATUS &&
              helpers.nonComplianceReports.statusesCheck.isFinalized(
                nonComplianceReport,
              ) &&
              nonComplianceReport.chosenAction !==
                constants.nonComplianceReports.FINALIZED_CHOSEN_ACTION && (
                <Button
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    setDialogReopen(true)
                  }}
                >
                  Reabrir RMC
                </Button>
              )}
          </>
        )}

        {(!constants.nonComplianceReports.NOT_ACCEPT_RISK_STATUSES.includes(
          nonComplianceReport.status,
        ) ||
          nonComplianceReport.residualRisk) &&
          oldScreen && (
            <Button
              color="secondary"
              fullWidth
              onClick={() => setDialogResidualRisk(true)}
            >
              {nonComplianceReport.residualRisk
                ? 'Exibir aceite de risco'
                : 'Aceitar Risco'}
            </Button>
          )}
        <Permitted
          someTags={[permission.INCIDENTS.LIST, permission.INCIDENTS.CREATE]}
        >
          {nonComplianceReport?.entityType ===
            constants.nonComplianceReports.ENTITY_INCIDENT && (
            <Button
              color="secondary"
              fullWidth
              onClick={() => {
                history.push(
                  reverse(routes.incidents.edit, {
                    incidentId: nonComplianceReport?.entity?.incident?.id,
                  }),
                )
              }}
            >
              Acesse o Incidente
            </Button>
          )}
        </Permitted>
        <Button
          color="secondary"
          fullWidth
          onClick={() => {
            setDialogLogs(true)
          }}
        >
          Histórico de Alterações
        </Button>
      </MenuButton>
      <DialogResidualRisk
        nonComplianceReportId={nonComplianceReport.id}
        open={dialogResidualRisk}
        setOpen={setDialogResidualRisk}
        residualRisk={nonComplianceReport?.residualRisk}
        onEvent={onReload}
        onLoading={setLoading}
      />
      <DialogReopen
        nonComplianceReport={nonComplianceReport}
        open={dialogReopen}
        setOpen={setDialogReopen}
        onEvent={onReload}
      />
      <DialogLogs
        logs={
          isEmpty(responseLogWithStatus) ? undefined : responseLogWithStatus
        }
        open={dialogLogs}
        setOpen={setDialogLogs}
        showRegisterSession={false}
      />
      <DialogFinalize
        nonComplianceReport={nonComplianceReport}
        open={dialogFinalize}
        setOpen={setDialogFinalize}
        onEvent={onReload}
      />
    </>
  )
}

export default RmcActions
