import React, { useState } from 'react'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { useHistory, useLocation, matchPath } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { reverse } from 'named-urls'
import { Box, Button, Tooltip } from '@material-ui/core'

import {
  LoadingFeedback,
  Page,
  Container,
  MenuButton,
  DialogLogs,
  Permitted,
  DriveTour,
} from 'components'
import { Header } from '../'
import { Info, Evidence, PlanAction } from './components'
import ReopenDialog from '../ReopenDialog'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'

import * as service from 'service'
import constants from 'constants/index'
import useStyles from './styles'
import { routes } from 'Routes'

const BaseView = ({
  questionControl,
  isEdit,
  logs,
  isLoading,
  refresh,
  refreshLogs,
}) => {
  const [loadingDialog, setLoadingDialog] = useState(false)
  const [dialogLogs, setDialogLogs] = useState(false)
  const [openTour, setOpenTour] = useState(false)
  const [reopenDialog, setReopenDialog] = useState({
    questionControlId: null,
    open: false,
  })

  const classes = useStyles()
  const snackbar = useSnackbar()
  const history = useHistory()
  const { pathname } = useLocation()

  const { ADOPTED_STATUS } = constants.questionControl

  const planEvidence = questionControl?.questionPlanActionEvidencies?.find(
    (evidence) => evidence?.kind === 'evidence',
  )
  const planActions = questionControl?.questionPlanActionEvidencies?.filter(
    (evidence) => evidence?.kind === 'plan_action',
  )

  const displayedEvidence =
    questionControl?.status === ADOPTED_STATUS ||
    (!isEmpty(planEvidence) && planEvidence?.status !== 'initial')

  const displayedAction =
    questionControl?.status !== ADOPTED_STATUS || !isEmpty(planActions)

  const page = isEdit ? 'Edição' : 'Visualização'

  const handleOpenTour = () => {
    const where = `Medidas de Segurança (${page})`
    const title = 'Tour'

    const params = {
      action: `tour-medidas-de-segurança-${page}-diagnostico`,
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  const validationSteps = () => {
    const stepsObject = []

    if (displayedAction)
      stepsObject.push(
        constants.questionControl.QUESTION_CONTROL_EDIT_DRIVER_STEP_OBJECT_1,
      )
    if (displayedEvidence)
      stepsObject.push(
        constants.questionControl.QUESTION_CONTROL_EDIT_DRIVER_STEP_OBJECT_2,
      )

    return stepsObject
  }

  const handleReopen = async () => {
    setLoadingDialog(true)
    try {
      await service.dponet.questionControls.enableWrite({
        questionControlId: questionControl?.id,
      })

      const isRefresh = matchPath(pathname, {
        path: routes.questionControls.edit,
        exact: true,
      })
      if (isRefresh) {
        refresh()
        refreshLogs()
      } else {
        history.push(
          reverse(routes.questionControls.edit, {
            questionControlId: questionControl?.id,
          }),
        )
      }
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Erro ao habilitar a edição da medida de segurança!',
        variant: 'error',
      })
    } finally {
      setLoadingDialog(false)
    }
  }

  return (
    <Page title={`${page} de medida de segurança`}>
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false} spacingXl={7}>
        <Box id={constants.questionControl.QUESTION_CONTROL_EDIT_DRIVER_STEP_1}>
          <Header
            title={questionControl?.title}
            specifNavText={questionControl?.title}
            navText="Medidas de segurança"
            withContainer
          >
            <Tooltip
              title={
                isEmpty(validationSteps())
                  ? 'O tour não esta disponivel para essa medida de segurança'
                  : 'Iniciar o tour guiado'
              }
            >
              <Box className={classes.contentHeaderBox}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<HelpCircleIcon size={20} />}
                  onClick={handleOpenTour}
                  disabled={isEmpty(validationSteps())}
                  fullWidth
                >
                  Tutorial
                </Button>
              </Box>
            </Tooltip>
            <MenuButton>
              <Button
                color="secondary"
                fullWidth
                onClick={() => {
                  setDialogLogs(true)
                }}
              >
                Histórico de Alterações
              </Button>
              {!questionControl?.openWrite && (
                <Permitted tag={constants.permissions.DIAGNOSTICS.OPEN_WRITE}>
                  <Button
                    color="secondary"
                    fullWidth
                    onClick={() =>
                      setReopenDialog({
                        questionControlId: questionControl?.id,
                        open: true,
                      })
                    }
                  >
                    Reabrir
                  </Button>
                </Permitted>
              )}
            </MenuButton>
          </Header>
          <Box my={(4, 2)}>
            <Info questionControl={questionControl} />
          </Box>
        </Box>
        {displayedEvidence && (
          <Box
            id={constants.questionControl.QUESTION_CONTROL_EDIT_DRIVER_STEP_2}
          >
            <Evidence
              planEvidence={planEvidence}
              questionControl={questionControl}
              isEdit={isEdit}
              refresh={refresh}
            />
          </Box>
        )}
        {displayedAction && (
          <PlanAction
            planActions={planActions}
            questionControl={questionControl}
            refresh={refresh}
            isEdit={isEdit}
          />
        )}
      </Container>
      {reopenDialog?.open && (
        <ReopenDialog
          changeDialog={reopenDialog}
          setChangeDialog={setReopenDialog}
          handleConfirmation={handleReopen}
          loading={loadingDialog}
        />
      )}
      {dialogLogs && (
        <DialogLogs
          logs={logs || undefined}
          open={dialogLogs}
          setOpen={setDialogLogs}
          showRegisterSession={false}
          refresh={refreshLogs}
        />
      )}

      <DriveTour
        stepsMatrix={validationSteps()}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default BaseView
