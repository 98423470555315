import { uniqBy, flattenDeep, filter } from 'lodash'

import constants from 'constants/index'

const dataToSubmit = (
  company,
  applyToCompany,
  selectedProcesses = [],
  allProcessesSelectedByGroup = () => {},
) => {
  if (applyToCompany) {
    return [
      {
        id: company?.id,
        entityType: constants.fragilityEntities.COMPANY_ENTITY_TYPE,
        group: company?.name,
      },
    ]
  }

  const entites = uniqBy(
    selectedProcesses?.map(({ group, id, departmentId }) => ({
      group,
      id,
      departmentId,
    })),
    'departmentId',
  )

  return flattenDeep(
    entites?.map((entity) => {
      if (allProcessesSelectedByGroup(entity?.group)) {
        return {
          ...entity,
          entityType: constants.fragilityEntities.DEPARTMENT_ENTITY_TYPE,
          id: entity?.departmentId,
          group: entity?.group,
          name: entity?.group,
        }
      }
      const processes = filter(selectedProcesses, [
        'departmentId',
        entity?.departmentId,
      ])

      return processes.map((process) => ({
        id: process?.id,
        entityType: constants.fragilityEntities.DATA_PROCESS_ENTITY_TYPE,
        group: entity?.group,
        name: process?.name ?? process?.label,
        departmentId: process.departmentId,
      }))
    }),
  )
}

export default dataToSubmit
