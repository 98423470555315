import PropTypes from 'prop-types'
import { colors, makeStyles } from '@material-ui/core'

import constants from 'constants/index'

const styles = (description, complaintMessage) => {
  const boxColor =
    constants.complaint.BOX_MESSAGE_COLOR[complaintMessage?.kind] ||
    colors.lightGreen['A100']

  return makeStyles((theme) => ({
    boxTitle: {
      backgroundColor: boxColor,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      display: 'flex',
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      width: '100%',
    },
    messageInfo: {
      fontWeight: 500,
      overflowWrap: 'break-word',
      whiteSpace: 'pre-line',
    },
    boxContent: {
      backgroundColor: theme.palette.white,
      color: theme.palette.text.secondary,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.body1.fontSize,
      overflowWrap: 'break-word',
      whiteSpace: 'pre-line',
      listStylePosition: 'inside',
      ...(description && { textAlign: 'justify' }),
      [theme.breakpoints.down('xs')]: {
        '& p > img': {
          width: '100%',
          height: 'auto',
        },
      },
    },
    contentContainer: {
      maxHeight: !!description ? 140 : 'fit-content',
      paddingRight: theme.spacing(2),
    },
  }))
}

styles.propTypes = {
  description: PropTypes.string,
}

export default styles
