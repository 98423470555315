import React from 'react'
import { useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { reverse } from 'named-urls'
import { ChevronRight as ChevronRightIcon } from 'react-feather'
import { Box, IconButton, TableRow } from '@material-ui/core'

import { CircularProgressWithLabel, DashboardChartPaper } from 'components'
import { Table, TableBody, TableCell, TableHead } from 'components/Table'

import { routes } from 'Routes'

import useStyles from './styles'

const SupplierComplianceTable = ({
  suppliers,
  title,
  subtitle,
  maxElements,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const handleViewSupplier = (supplierId) =>
    history.push(reverse(routes.mySuppliers.show, { supplierId }))

  return (
    <DashboardChartPaper
      title={title}
      subtitle={subtitle}
      redirect={routes.mySuppliers.all}
      heightContainer="fit-content"
      noMaxHeight
    >
      <Box minHeight={272}>
        <PerfectScrollbar
          options={{ wheelPropagation: true, useBothWheelAxes: true }}
        >
          <Table
            size="small"
            emptyMessage="Nenhum fornecedor encontrado"
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="20%">ID</TableCell>
                <TableCell width="20%">Conformidade(%)</TableCell>
                <TableCell width="50%">Empresa</TableCell>
                <TableCell width="10%">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suppliers?.slice(0, maxElements)?.map((supplier) => (
                <TableRow key={supplier.id}>
                  <TableCell>{supplier.id}</TableCell>
                  <TableCell align="center">
                    <Box
                      display="flex"
                      height="100%"
                      width="100%"
                      justifyContent="center"
                      alignItems="center"
                      py={1}
                    >
                      <CircularProgressWithLabel
                        className={classes.circularProgress}
                        size={30}
                        value={supplier?.compliance}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>{supplier.name}</TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleViewSupplier(supplier?.id)}
                    >
                      <ChevronRightIcon size={22} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
    </DashboardChartPaper>
  )
}

SupplierComplianceTable.propTypes = {
  suppliers: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  maxElements: PropTypes.number,
}

SupplierComplianceTable.defaultProps = {
  maxElements: 4,
}

export default SupplierComplianceTable
