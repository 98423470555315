const styles = (theme) => ({
  root: {},
  card: {
    minHeight: '100%',
    transition: theme.transitions.create(['box-shadow']),
    '&:hover': {
      borderRadius: 4,
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    },
  },
  cardHeader: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  cardHeaderContent: {
    maxWidth: '90%',
  },
  cardContent: {
    padding: '0px !important',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardLinkContent: {
    padding: '0px !important',
    cursor: 'pointer',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  statusSection: {
    textAlign: 'center',
  },
  textWhite: {
    color: theme.palette.white,
  },
  nameEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '18rem',
  },
  departmentEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '11rem',
  },
  pending: {
    backgroundColor: '#087DF1',
  },
  waitingReview: {
    backgroundColor: '#FF9800',
  },
  reviewDisapproved: {
    backgroundColor: '#E53555',
  },
  reviewApproved: {
    backgroundColor: '#4CAF50',
  },
  reviewing: {
    backgroundColor: '#FF9800',
  },
  inactive: {
    backgroundColor: '#999999',
  },
  suggested: {
    backgroundColor: '#006EA0',
  },
  discarded: {
    backgroundColor: '#DFDFDF',
  },
  textPending: {
    color: '#65C8FF',
    fontWeight: 700,
  },
  textWaitingReview: {
    color: '#FFDEAD',
    fontWeight: 700,
  },
  textReviewDisapproved: {
    color: '#FFADAD',
    fontWeight: 700,
  },
  textReviewApproved: {
    color: '#AFFFBC',
    fontWeight: 700,
  },
  textReviewing: {
    color: '#FFDEAD',
    fontWeight: 700,
  },
  textInactive: {
    color: '#CCCCCC',
    fontWeight: 700,
  },
  textSuggested: {
    color: '#74BFE1',
    fontWeight: 700,
  },
  textDiscarded: {
    color: '#535151',
    fontWeight: 700,
  },
  justifyFlexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fontBold: {
    fontWeight: 800,
  },
  lowFragility: {
    color: '#007bff',
    fontWeight: 700,
  },
  mediumFragility: {
    color: '#4CAF50',
    fontWeight: 700,
  },
  highFragility: {
    color: '#F4A460',
    fontWeight: 700,
  },
  severeFragility: {
    color: '#E53935',
    fontWeight: 700,
  },
})

export default styles
