import constants from 'constants/index'
import helpers from 'helpers'
import * as service from 'service'

import { saveAs } from 'file-saver'
import { routes } from 'Routes'

const homeTrails = (
  userSteps,
  setLoading,
  userCompanyId,
  impressionStamp,
  history,
) => {
  const getSubtitleByTag = (tag) => {
    const concludedAt = userSteps?.find((step) => step.tag === tag)?.concludedAt
    return helpers.formatters.dateLocaleFormat(
      concludedAt,
      'DD/MM/YYYY - HH:mm',
    )
  }

  const downloadCertification = async () => {
    try {
      setLoading(true)

      const response =
        await service.dponet.usersCompanies.consultOrCreateCertificate({
          id: userCompanyId,
          cert_type: 'INTRODUCTION_LGPD',
        })

      const certificationLink =
        response?.data?.userCompanyCertification?.certification
      const fileResponse = await service.dponet.companyTrails.getImage(
        certificationLink,
      )

      saveAs(fileResponse.data, 'certificado-de-introduçao-à-lgpd.png')
    } catch (error) {
      console.error('Erro ao baixar o certificado:', error)
    } finally {
      setLoading(false)
    }
  }

  const downloadStamp = async () => {
    try {
      setLoading(true)

      const fileResponse = await service.dponet.companyTrails.getImage(
        impressionStamp,
      )

      saveAs(fileResponse.data, 'carimbo-de-introduçao-à-lgpd.png')
    } catch (error) {
      console.error('Erro ao baixar o carimbo:', error)
    } finally {
      setLoading(false)
    }
  }

  const goToStep = (step) => {
    history.push(`${routes.userSteps}?step=${step}`)
  }

  const { CERTIFICATE, STAMP } = constants.trailsJourney

  return [
    {
      title: 'Benefícios da DPOnet',
      subtitle: getSubtitleByTag(constants.userSteps.STEP_DPO_TAG),
      status: constants.companyTrail.CONCLUDED_STATUS,
      concluded: true,
      action: {
        text: 'Assistir',
        method: () => goToStep(constants.userSteps.STEP_DPO_TAG),
      },
    },
    {
      title: 'O que é LGPD?',
      subtitle: getSubtitleByTag(constants.userSteps.STEP_LGPD_TAG),
      status: constants.companyTrail.CONCLUDED_STATUS,
      concluded: true,
      action: {
        text: 'Assistir',
        method: () => goToStep(constants.userSteps.STEP_LGPD_TAG),
      },
    },
    {
      title: STAMP,
      subtitle: getSubtitleByTag(constants.userSteps.STEP_STAMP_TAG),
      status: constants.companyTrail.CONCLUDED_STATUS,
      concluded: true,
      action: {
        text: 'Baixar',
        method: downloadStamp,
      },
    },
    {
      title: CERTIFICATE,
      subtitle: getSubtitleByTag(constants.userSteps.STEP_CERTIFICATION_TAG),
      status: constants.companyTrail.CONCLUDED_STATUS,
      concluded: true,
      action: {
        text: 'Baixar',
        method: downloadCertification,
      },
    },
    {
      title: 'Conclusão',
      subtitle: getSubtitleByTag(constants.userSteps.STEP_CONCLUSION_TAG),
      status: constants.companyTrail.CONCLUDED_STATUS,
      concluded: true,
    },
  ]
}

export default homeTrails
