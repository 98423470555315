import React from 'react'
import { Box, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import useStyles from './styles'

const SubscriptionAdvantages = () => {
  const classes = useStyles()

  const items = [
    'Adequação à LGPD ágil e com suporte técnico e jurídico especializado',
    'Plataforma completa para gerenciamento dos dados pessoais que você trata',
    'Sua empresa com um selo de adequação à LGPD que transmite segurança aos seus clientes',
    'Proteção contra multas e penalidades da LGPD',
  ]

  return (
    <>
      <Box className={classes.advantagesCard} p={2} mt={3}>
        <Box display="flex" flexDirection="column">
          {items.map((item, index) => (
            <Box key={index} display="flex">
              <CheckCircleIcon fontSize="small" className={classes.iconColor} />
              <Box mb={1} mt={0.15} ml={1}>
                <Typography variant="body2">{item}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  )
}
export default SubscriptionAdvantages
