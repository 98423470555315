import React, { useState } from 'react'
import {
  Box,
  Button,
  Container,
  Hidden,
  Paper,
  TablePagination,
  Tooltip,
} from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import { ContentHeader, DriveTour, LoadingFeedback, Page } from 'components'
import { ConsentsTable } from './components'

import useFetch from 'hooks/useFetch'
import usePagination from 'hooks/usePagination'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

import useStyles from './styles'

const ConsentList = () => {
  const { mixpanel } = helpers
  const {
    COOKIES_CONSENT_DRIVER_STEPS_OBJECT,
    COOKIES_CONSENT_MAIN_DRIVER_STEP,
  } = constants.cookies.consent

  const classes = useStyles()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const [openTour, setOpenTour] = useState(false)

  const { response, isLoading } = useFetch(
    service.cookies.consent.get,
    {
      page,
      perPage,
    },
    [page, perPage],
  )

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Cookies-Consentimentos', 'Tour', {
      action: 'tour-consentimentos-cookies',
    })
  }

  const consents = response?.data?.consents

  return (
    <Page title="Cookies - Consentimentos">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Consentimentos registrados (Logs)">
          <Tooltip title="Iniciar o tour guiado">
            <Box>
              <Button
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                fullWidth
                id={COOKIES_CONSENT_MAIN_DRIVER_STEP}
              >
                Tutorial
              </Button>
            </Box>
          </Tooltip>
        </ContentHeader>
        <Paper variant="outlined">
          <ConsentsTable consents={consents} loading={isLoading} />
          <TablePagination
            component="div"
            count={0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Paper>
      </Container>
      <DriveTour
        stepsMatrix={COOKIES_CONSENT_DRIVER_STEPS_OBJECT}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default ConsentList
