const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  wrapper: {
    justifyContent: 'center',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  imgBox: {
    height: '24px',
  },
})

export default styles
