import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required(),
  status: yup.string().required(),
  description: yup.string(),
  impactId: yup.number().required(),
  probabilityId: yup.number().required(),
})

export default schema
