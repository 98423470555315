import React from 'react'

import { Checkbox, FormControlLabel } from '@material-ui/core'

// import styles from './styles'

// const useStyles = makeStyles(styles)

const CategoryCheckbox = ({
  label,
  checked,
  onChange,
  id,
  name = 'childreen',
}) => {
  // const classes = useStyles()

  return (
    <FormControlLabel
      control={
        <Checkbox checked={checked} onChange={onChange} id={id} name={name} />
      }
      label={label}
    />
  )
}

export default CategoryCheckbox
