import React, { Fragment, useState } from 'react'
import { RefreshCw as RefreshCwIcon } from 'react-feather'

import { isEmpty } from 'lodash'

import { Box, Button, Typography } from '@material-ui/core'
import { LoadingFeedback } from 'components'

import { ChangePaymentMethod, PaymentBillet, PaymentCard } from './components'

import theme from 'theme'
import helpers from 'helpers'

const PaymentMethod = ({ contractsFetch }) => {
  const [paymentMethod, setPaymentMethod] = useState(false)
  const [contract, setContract] = useState(false)

  const handleChangePaymentMethod = (paymentContract) =>
    setPaymentMethod((prevState) => {
      if (prevState) {
        setContract()
      } else {
        setContract(paymentContract)
      }
      return !prevState
    })

  const { response, isLoading, refresh } = contractsFetch
  const paymentContracts = response?.data?.paymentContracts

  if (!isLoading && isEmpty(paymentContracts)) {
    return <Typography>Esta empresa não possui contratos ativos</Typography>
  }

  return (
    <Box p={2} display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
      <LoadingFeedback open={isLoading} />
      {!paymentMethod ? (
        <Fragment>
          {paymentContracts?.map((paymentContract) => {
            const price = helpers.formatters.numberToPrice(
              parseFloat(paymentContract?.price || 0),
            )

            return (
              <Fragment>
                <Typography variant="h6">
                  O contrato nº {paymentContract?.contractIdentifier} está
                  vinculado
                </Typography>
                {paymentContract?.methodPayment === 'card' ? (
                  <PaymentCard
                    creditCard={paymentContract?.creditCard}
                    price={price}
                    nextDateAdvance={paymentContract?.nextDateAdvance}
                  />
                ) : (
                  <PaymentBillet
                    price={price}
                    nextDateAdvance={paymentContract?.nextDateAdvance}
                  />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<RefreshCwIcon size={22} />}
                  size="large"
                  fullWidth
                  onClick={() => handleChangePaymentMethod(paymentContract)}
                >
                  Trocar forma de pagamento
                </Button>
              </Fragment>
            )
          })}
        </Fragment>
      ) : (
        <>
          <Typography variant="h6">
            Contrato nº {contract?.contractIdentifier}
          </Typography>
          <ChangePaymentMethod
            handleChangePaymentMethod={handleChangePaymentMethod}
            paymentContract={contract}
            setPaymentMethod={setPaymentMethod}
            refresh={refresh}
          />
        </>
      )}
    </Box>
  )
}

export default PaymentMethod
