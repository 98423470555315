import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
  },
}))

export default styles
