import permissionsConstants from 'constants/permissions'

const BLOCKED_MENU_OUTSOURCE_DPO = ['Home', 'Treinamentos']
const RESPONSIBLE_PROFILE_ID = 10_000

const USER_MAIN_DRIVER_STEP_0 = 'user-main-driver-step-0'
const USER_MAIN_DRIVER_STEP_1 = 'user-main-driver-step-1'
const USER_MAIN_DRIVER_STEP_2 = 'user-main-driver-step-2'
const USER_MAIN_DRIVER_STEP_3 = 'user-main-driver-step-3'

const USERS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + USER_MAIN_DRIVER_STEP_0,
    popover: {
      description:
        'É possível cadastrar novos usuários para assistência no preenchimento da plataforma. Ao vincular um perfil, é possível conceder acesso total ou parcial às funcionalidades, facilitando assim o fluxo de trabalho.',
    },
  },
  {
    element: '#' + USER_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Importe usuários, baixe o modelo da planilha sem alterar a formatação, faça o upload e defina o perfil de usuário e permissões desejados.',
    },
  },
  {
    element: '#' + USER_MAIN_DRIVER_STEP_2,
    popover: {
      description:
        'Crie um novo usuário, vincule-o a um perfil de usuário e defina as permissões.',
    },
  },
  {
    element: '#' + USER_MAIN_DRIVER_STEP_3,
    popover: {
      description: 'Realize a edição ou exclusão do usuário, caso necessário.',
    },
  },
]

const USERS_DRIVER_STEPS_WITH_PERMISSIONS = {
  ['#' + USER_MAIN_DRIVER_STEP_1]: permissionsConstants.USERS.CREATE,
  ['#' + USER_MAIN_DRIVER_STEP_2]: permissionsConstants.USERS.CREATE,
}

const NEW_USER_DRIVER_STEP_0 = 'new-user-driver-step-0'

const NEW_USERS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + NEW_USER_DRIVER_STEP_0,
    popover: {
      description:
        "Adicione um novo usuário à plataforma e vincule-o a um perfil apropriado para definir suas permissões. Lembre-se de que essas permissões podem ser modificadas posteriormente na aba 'Perfil'.",
    },
  },
]

export default {
  BLOCKED_MENU_OUTSOURCE_DPO,
  RESPONSIBLE_PROFILE_ID,
  USER_MAIN_DRIVER_STEP_0,
  USER_MAIN_DRIVER_STEP_1,
  USER_MAIN_DRIVER_STEP_2,
  USER_MAIN_DRIVER_STEP_3,
  USERS_DRIVER_STEPS_OBJECT,
  USERS_DRIVER_STEPS_WITH_PERMISSIONS,
  NEW_USER_DRIVER_STEP_0,
  NEW_USERS_DRIVER_STEPS_OBJECT,
}
