const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  whiteTypography: {
    color: theme.palette.layout.textPrimary,
  },
})

export default styles
