import React from 'react'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const LoadingTextComponent = ({ loading, children }) => {
  if (loading) {
    return (
      <Box
        height="24px"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Skeleton variant="rect" width={30} />
      </Box>
    )
  }
  return children
}

export default LoadingTextComponent
