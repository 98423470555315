const idsToDestroy = (selected = []) => {
  return {
    toDestroy: selected?.map(({ id, entityId, entityType }) => ({
      id: entityId ?? id,
      entityType,
    })),
  }
}

export default idsToDestroy
