import permissions from '../permissions'

const QUESTIONNAIRE_LIST = [
  {
    name: 'Gerenciamento usuários',
    tag: permissions.QUESTIONNAIRES.LINK_USERS,
  },
  {
    name: 'Responder todas as perguntas',
    tag: permissions.QUESTIONNAIRES.REPLY,
    description:
      'Se a permissão estiver desativada, apenas será possível responder aos questionários aos quais o usuário estiver vinculado; caso contrário, terá permissão para visualizar todos.',
  },
]

export default QUESTIONNAIRE_LIST
