import api from 'service/api'

const dpoAPI = api.create('dponet', false)

const get = async ({ ...params }) => {
  return await dpoAPI.get('/marketplace/list_orders', { params })
}

const orderService = async (data) => {
  return await dpoAPI.post('/marketplace/order_services', data)
}

const orders = {
  get,
  orderService,
}

export default orders
