import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { Text, RoundedBox, IconButton, InfoDialog } from '../'

import { Info } from 'react-feather'

import useFirstStep from 'hooks/useFirstStep'

import constants from 'constants/index'
import helpers from 'helpers'
import useStyles from './styles'

const DPOnetPage = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)

  const { activeStep, activeSubStep, setBackgroundKind, registerTrack } =
    useFirstStep()

  useEffect(
    () => {
      setBackgroundKind('topLeft')
      registerTrack(
        helpers.userSteps.defineMixpanelId(
          activeStep,
          activeSubStep,
          'play-video',
        ),
      )
    },
    //eslint-disable-next-line
  [])

  return (
    <>
      <Text color="primary" fontSize="5vh" variant="h1" gutterBottom>
        Conheça os benefícios que a DPOnet traz para você!
      </Text>
      <RoundedBox>
        <Box width="100%" display="flex" alignItems="flex-start">
          <iframe
            title="WelcomeVideo"
            src="https://player.vimeo.com/video/853127574?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            className={classes.iFrame}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
          />
          <Box className={classes.boxIcon}>
            <IconButton
              icon={<Info />}
              onClick={handleOpen}
              mixpanelId={helpers.userSteps.defineMixpanelId(
                activeStep,
                activeSubStep,
                'informação',
              )}
            />
          </Box>
        </Box>
      </RoundedBox>
      <InfoDialog
        open={open}
        setOpen={setOpen}
        {...constants.userSteps.DIALOG_INFOS.DPONET}
      />
    </>
  )
}

export default DPOnetPage
