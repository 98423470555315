import React, { useState } from 'react'
import {
  PlusCircle as PlusCircleIcon,
  HelpCircle as HelpCircleIcon,
} from 'react-feather'
import {
  Button,
  Container,
  TablePagination,
  Hidden,
  Paper,
  Tooltip,
  Box,
} from '@material-ui/core'

import {
  Page,
  ContentHeader,
  LoadingFeedback,
  DriveTour,
  ButtonAction,
} from 'components'
import { NewBannerModal, BannersTable } from './components'

import helpers from 'helpers'
import * as service from 'service'
import useFetch from 'hooks/useFetch'
import constants from 'constants/index'
import usePagination from 'hooks/usePagination'

import useStyles from './styles'

const CookiesMain = () => {
  const { mixpanel } = helpers
  const {
    COOKIES_BANNERS_DRIVER_STEPS_OBJECT,
    COOKIES_BANNERS_MAIN_DRIVER_STEP,
  } = constants.cookies.banner

  const [loading, setLoading] = useState(false)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [openTour, setOpenTour] = useState(false)

  const classes = useStyles()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const { response, isLoading, refresh } = useFetch(
    service.cookies.banner.get,
    {
      perPage,
      page,
    },
    [perPage, page],
  )

  const banners = response?.data?.banners

  const handleCreateModal = () => setOpenCreateModal((prevOpen) => !prevOpen)

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Cookies-Meus-Banners', 'Tour', {
      action: 'tour-meus-banners-cookies',
    })
  }

  return (
    <Page title="Cookies - Meus banners">
      <LoadingFeedback open={isLoading || loading} />
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Meus Banners">
          <Tooltip title="Iniciar o tour guiado">
            <Box mr={1}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                fullWidth
                id={COOKIES_BANNERS_MAIN_DRIVER_STEP}
              >
                Tutorial
              </Button>
            </Box>
          </Tooltip>

          <ButtonAction
            variant="contained"
            color="primary"
            startIcon={<PlusCircleIcon size={18} />}
            action={handleCreateModal}
            name="Adicionar Site"
          />
        </ContentHeader>
        <Paper variant="outlined">
          <BannersTable banners={banners} isLoading={loading} />
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Paper>
      </Container>
      <NewBannerModal
        handleCreateModal={handleCreateModal}
        loading={loading}
        open={openCreateModal}
        refresh={refresh}
        setLoading={setLoading}
        setOpen={setOpenCreateModal}
      />
      <DriveTour
        stepsMatrix={COOKIES_BANNERS_DRIVER_STEPS_OBJECT}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default CookiesMain
