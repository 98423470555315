import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get(`/audit_configs`, { params })
}

const show = async ({ auditConfigId = '' }) => {
  return await dponetAPI.get(`/audit_configs/${auditConfigId}`)
}

const update = async ({ auditConfigId = '', ...data }) => {
  return await dponetAPI.put(`/audit_configs/${auditConfigId}`, {
    auditConfig: data,
  })
}

export default { get, show, update }
