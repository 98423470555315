import React from 'react'

import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
} from '@material-ui/core'

import { reverse } from 'named-urls'
import { routes } from 'Routes'
import { useHistory } from 'react-router-dom'

import { QuestionBox } from './components'

import styles from './styles'

const useStyles = makeStyles(styles)

const QuestionnaireCard = ({ questionnaire }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleReply = () => {
    history.push(
      reverse(routes.questionnaire.reply, {
        questionnaireId: questionnaire.id,
      }),
    )
  }

  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <Card className={classes.cardMain}>
        <Box className={classes.cardHeader} p={2}>
          <Typography variant="h5" className={classes.whiteTypography}>
            {questionnaire.name}
          </Typography>
          <Box>
            <Box p={0.3} mt={3} display="flex" justifyContent="space-between">
              <Typography className={classes.whiteTypography}>
                Progresso
              </Typography>
              <Typography className={classes.whiteTypography}>
                {questionnaire.answerPercentage}%
              </Typography>
            </Box>
            <LinearProgress
              variant="determinate"
              value={questionnaire.answerPercentage}
              classes={{ barColorPrimary: classes.barColorPrimary }}
              className={classes.determinate}
            />
          </Box>
        </Box>
        <QuestionBox
          questionType="Questões"
          questionLength={questionnaire.totalQuestions}
        />
        <QuestionBox
          questionType="Respondidas"
          questionLength={
            questionnaire.totalQuestions - questionnaire.pendingQuestions
          }
        />
        <QuestionBox
          questionType="Pendentes"
          questionLength={questionnaire.pendingQuestions}
        />
        <Box p={2} width="100%">
          <Button className={classes.cardButton} onClick={handleReply}>
            Responder
          </Button>
        </Box>
      </Card>
    </Grid>
  )
}

QuestionnaireCard.propTypes = {
  questionnaire: PropTypes.object,
}

export default QuestionnaireCard
