import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Box, Button } from '@material-ui/core'
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
} from 'components/Table'
import { MenuButton } from 'components'

import auth from 'hooks/useAuth'

import constants from 'constants/index'
import helpers from 'helpers'

const ListAgents = ({ agents, setOpenDialog, setAgentEdition, setAgents }) => {
  const { TRANSLATED_TYPE } = constants.dataTreatmentAgents

  const { company } = auth()

  const handleEdit = (agent) => {
    if (agent.companySelf) {
      setAgentEdition({
        ...agent,
        name: company.name,
        document: company.document,
      })
    } else {
      setAgentEdition(agent)
    }
    setOpenDialog(true)
  }

  const handleRemove = (data) => {
    const agentsRemove = agents.filter((agent) => agent.name !== data.name)
    setAgents(agentsRemove)
  }

  return (
    <Box mt={2}>
      <Table size="small" noWrap>
        <TableHead>
          <TableRow>
            <TableCell width="40%">Organização</TableCell>
            <TableCell width="30%">CPF/CNPJ</TableCell>
            <TableCell width="20%">Tipo</TableCell>
            <TableCell width="10%">Ação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agents?.map((agent, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography>{agent?.name || company?.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {helpers.formatters.cnpjOrCpf(
                    agent?.name ? agent?.document : company?.document,
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>{TRANSLATED_TYPE[agent?.agentType]}</Typography>
              </TableCell>
              <TableCell align="right">
                <MenuButton>
                  <Button
                    color="secondary"
                    fullWidth
                    size="small"
                    onClick={() => handleEdit(agent)}
                  >
                    Editar
                  </Button>
                  <Button
                    color="secondary"
                    fullWidth
                    size="small"
                    onClick={() => handleRemove(agent)}
                  >
                    Remover
                  </Button>
                </MenuButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
ListAgents.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.ob),
  setOpenDialog: PropTypes.func,
  setAgentEdition: PropTypes.func,
  setAgents: PropTypes.func,
}

ListAgents.defaultProps = {
  setOpenDialog: () => {},
  setAgentEdition: () => {},
  setAgents: () => {},
}

export default ListAgents
