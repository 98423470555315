import React from 'react'

const Visa = ({
  alt = 'Bandeira Visa',
  height = 16,
  viewBox = '0 0 49 16', // Valor total da imagem
  width = 49,
  grayscale = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
    >
      {grayscale && (
        <filter id="grayscale">
          <feColorMatrix
            type="matrix"
            values="0.3333 0.3333 0.3333 0 0
                                           0.3333 0.3333 0.3333 0 0
                                           0.3333 0.3333 0.3333 0 0
                                           0      0      0      1 0"
          />
        </filter>
      )}
      <g filter={grayscale ? 'url(#grayscale)' : ''}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2505 15.5165H8.00987L4.82991 3.38475C4.67897 2.82669 4.3585 2.33333 3.88708 2.1008C2.71062 1.51646 1.41424 1.05141 0 0.81686V0.349787H6.83134C7.77417 0.349787 8.48129 1.05141 8.59914 1.86626L10.2491 10.6173L14.4877 0.349787H18.6104L12.2505 15.5165ZM20.9675 15.5165H16.9626L20.2604 0.349787H24.2653L20.9675 15.5165ZM29.4467 4.55143C29.5646 3.73456 30.2717 3.26748 31.0967 3.26748C32.3931 3.15021 33.8052 3.38476 34.9838 3.96708L35.6909 0.701619C34.5123 0.234547 33.216 0 32.0395 0C28.1524 0 25.3239 2.10081 25.3239 5.01648C25.3239 7.23457 27.3274 8.39921 28.7417 9.10083C30.2717 9.80043 30.861 10.2675 30.7431 10.9671C30.7431 12.0165 29.5646 12.4836 28.3881 12.4836C26.9739 12.4836 25.5596 12.1338 24.2653 11.5494L23.5582 14.8169C24.9724 15.3992 26.5025 15.6338 27.9167 15.6338C32.2752 15.749 34.9838 13.6502 34.9838 10.5C34.9838 6.53295 29.4467 6.30042 29.4467 4.55143V4.55143ZM49 15.5165L45.82 0.349787H42.4044C41.6972 0.349787 40.9901 0.81686 40.7544 1.51646L34.8659 15.5165H38.9887L39.8116 13.3004H44.8772L45.3486 15.5165H49ZM42.9936 4.43416L44.1701 10.1502H40.8723L42.9936 4.43416Z"
          fill="#172B85"
        />
      </g>
    </svg>
  )
}

export default Visa
