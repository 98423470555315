import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Dialog,
  Typography,
  Box,
  Divider,
  Button,
  IconButton,
} from '@material-ui/core'
import { BaseConfirmationDialog, LoadingFeedback } from 'components'
import { X as CloseIcon } from 'react-feather'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import { Link, useLocation } from 'react-router-dom'

import auth from 'service/modules/dponet/auth'
import * as service from 'service'
import { routes } from 'Routes'

import helpers from 'helpers'
import constants from 'constants/index'

import logo from 'images/dponet-logo-white.svg'
import useStyles from './styles'

const NoAccessFreeTrial = () => {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(true)
  const [rdMarketing, setRdMarketing] = useState(false)
  const [loading, setLoading] = useState(false)
  const { company } = useAuth()
  const { pathname } = useLocation()
  const snackbar = useSnackbar()

  const classes = useStyles()

  const publicRoutes = [routes.selectCompany]

  useEffect(() => {
    if (publicRoutes.includes(pathname)) {
      return setIsOpen(false)
    }
    setIsOpen(true)
    // eslint-disable-next-line
  }, [pathname])

  const closeDialog = () => {
    auth.logout()
  }

  const handleNotify = async () => {
    setLoading(true)
    try {
      const response = await service.dponet.companyFreeTrials.notify_hire()
      const success = response?.data?.success

      if (success) {
        setRdMarketing(true)
      } else {
        snackbar.open({
          message: response?.data?.message,
          variant: 'error',
        })
      }
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleRedirect = () => {
    const token =
      company?.preRegistrationToken || company?.preRegistration?.token

    if (token) {
      history.push({
        pathname: routes.subscription.default,
        search: `segment=${company?.segment?.name}&token=${token}`,
      })
    } else {
      handleNotify()
    }
  }

  return (
    <Dialog
      open={
        company?.statusId === constants.userCompanies.TRIAL_CANCEL_STATUS &&
        isOpen
      }
      maxWidth="lg"
      fullWidth
    >
      <LoadingFeedback open={loading} />
      <Box id="form-dialog-title">
        <Box component="main" className={classes.root}>
          <Box className={classes.wrapper}>
            <Box px={4} pt={6}>
              <Box mb={2}>
                <Typography className={classes.titleOver}>
                  Seu período teste acabou!
                </Typography>
                <Typography className={classes.subtitle}>
                  Mas a conformidade em proteção dos dados na sua empresa não
                  precisa acabar!
                </Typography>
                <Typography className={classes.titileContext}>
                  Contrate a plataforma DPOnet para:
                </Typography>
                <Typography className={classes.context}>
                  <strong>Funcionalidades avançadas:</strong> Ao fazer a
                  contratação, você terá acesso a recursos exclusivos que
                  otimizarão o gerenciamento dos dados pessoais e dados
                  sensiveis tratados na sua empresa.
                </Typography>
                <Typography className={classes.context}>
                  <strong>Conformidade garantida:</strong> Recuros adicionais
                  para garantir que sua empresa esteha totalmente linhada com a
                  LGPD, evitando riscos de penalidades e multas.
                </Typography>
                <Typography className={classes.context}>
                  <strong>Suporte personalizado:</strong> Você terá assistência
                  especializada dedicada, pronta para ajudá-lo em todas as
                  dúvidas e etapas do processo.
                </Typography>
              </Box>
              <Box mb={4}>
                <Divider />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                textAlign="center"
                className={classes.boxButton}
              >
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={handleRedirect}
                >
                  REALIZAR CONTRATAÇÃO
                </Button>
                <br />
                {/* os botões abaixo vão ficar desativado por enquanto */}
                <Button variant="outlined" disabled>
                  SOLICITAR CONTATO
                </Button>
                <Box mt={2}>
                  <Link className={classes.exclusion} disabled to="#">
                    Excluir minha conta
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box className={classes.rightBanner}>
              <Box
                width="100%"
                display="flex"
                justifyContent="flex-end"
                mr={-2}
              >
                <IconButton
                  size="small"
                  onClick={closeDialog}
                  aria-label="Fechar PopUp"
                >
                  <CloseIcon color="white" />
                </IconButton>
              </Box>
              <Box p={2} mt={10}>
                <Box display="flex" justifyContent="center">
                  <img src={logo} alt="Logo" width={260} />
                </Box>
                <Typography className={classes.labelRigth}>
                  +2.500 empresas confiam em nossa solução
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {rdMarketing && (
        <BaseConfirmationDialog
          displayedButtons={false}
          title="Solicitação de contratação da plataforma"
          text="Essa solicitação foi encaminhada à nossa equipe, e entraremos em contato para dar continuidade ao
                processo de contratação."
          open={rdMarketing}
          setOpen={setRdMarketing}
          variant="success"
          loading={loading}
        />
      )}
    </Dialog>
  )
}

export default NoAccessFreeTrial
