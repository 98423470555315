import React from 'react'
import PropTypes from 'prop-types'

import useSupplierInvite from 'hooks/useSupplierInvite'

import constants from 'constants/index'
import helpers from 'helpers'

const SupplierInviteStep = ({ inviter, companyToken }) => {
  const { currentStep } = useSupplierInvite()
  const StepComponents = helpers.supplierInvite.stepComponents({
    inviter,
    companyToken,
  })

  const StepComponent =
    StepComponents[currentStep] ||
    StepComponents[constants.supplierInvite.WELCOME_STEP]

  return <StepComponent />
}

SupplierInviteStep.propTypes = {
  inviter: PropTypes.object,
  companyToken: PropTypes.string,
}

export default SupplierInviteStep
