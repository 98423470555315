import * as yup from 'yup'

import constants from 'constants/index'

const schemaGeneralInformation = (volumetryType, company) => {
  const volumetryRequire =
    volumetryType === constants.dataProcess.STORY_MODE_DEFINED_TYPE

  const isRequired = company?.kind !== constants.userCompanies.KIND_TEMPLATE

  const dataProcessDataSourcesField = (yup) => {
    return isRequired
      ? {
          dataProcessDataSources: yup
            .array()
            .required()
            .min(1, 'Selecione ao menos uma fonte de origem dos dados')
            .typeError('Preencha as origens dos dados do processo'),
        }
      : {
          dataProcessDataSources: yup.array().nullable(),
        }
  }

  return yup.object().shape({
    departmentId: yup.string().required(),
    code: yup.string(),
    name: yup.string().required(),
    finality: yup.string().required(),
    ...(volumetryRequire && {
      deadlineJustification: yup.string().required(),
      value: yup.number().typeError('Precisa ser um número').required(),
      volumetry: yup.string().required(),
    }),
    ...dataProcessDataSourcesField(yup),
    sourceDescription: yup
      .number()
      .nullable()
      .transform((newValue) => {
        if (isNaN(parseInt(newValue))) {
          return null
        }

        return newValue
      }),
    sourceDescriptionAnother: yup.string().nullable(),
  })
}

export default schemaGeneralInformation
