import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Typography,
  CardContent,
  CardActionArea,
} from '@material-ui/core'

import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import useStyles from '../DialogContactTickets/styles'

import constants from 'constants/index'

const DialogMyTickets = ({ open = false, setOpen, goToDeskTickets }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleClose = () => setOpen(false)

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography color="primary" variant="h4">
          Ver meus tickets
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContentContainer}>
        <Box display="flex" flexDirection="column" width="100%">
          <Typography className={classes.subtitle} color="secondary">
            Selecione qual a categoria de ticket você deseja consultar
          </Typography>
          <Box className={classes.cardsContainer}>
            {constants.tickets
              .MY_TICKETS_CARDS_DATA(history, setOpen, goToDeskTickets)
              .map((card, index) => (
                <Card
                  className={classes.card}
                  onClick={card.actionButton}
                  key={index}
                >
                  <CardActionArea>
                    <CardContent className={classes.cardContentContainer}>
                      <Box className={classes.descriptionContainer}>
                        <Typography color="primary" variant="h5">
                          {card.title}
                        </Typography>
                        <Typography variant="body2" color="secondary">
                          {card.description}
                        </Typography>
                      </Box>
                      <ArrowForwardIcon />
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

DialogMyTickets.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  goToDeskTickets: PropTypes.func.isRequired,
}

export default DialogMyTickets
