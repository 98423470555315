import { makeStyles } from '@material-ui/core'

import greyPolygon from 'images/Polygon_grey.svg'
import greenPolygon from 'images/Polygon_green.svg'
import redPolygon from 'images/Polygon_red.svg'

import palette from 'theme/palette'
import theme from 'theme'

const styles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    backgroundColor: theme.palette.white,
  },
  cardContent: {
    color: theme.palette.subscription.primary.main,
  },
  title: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  text: {
    fontSize: 28,
    fontWeight: 600,
  },
  avatar: {
    marginTop: 4,
  },
  greyAvatar: {
    color: palette.dashboard.chart.primary.inactive,
  },
  greenAvatar: {
    color: palette.dashboard.icons.green,
  },
  redAvatar: {
    color: palette.dashboard.card.incident.main,
  },
  boxPolygon: {
    width: 50,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
  },
  greyPolygon: {
    backgroundImage: `url(${greyPolygon})`,
    backgroundSize: 50,
  },
  greenPolygon: {
    backgroundImage: `url(${greenPolygon})`,
    backgroundSize: 50,
  },
  redPolygon: {
    backgroundImage: `url(${redPolygon})`,
    backgroundSize: 50,
  },
}))

export default styles
