import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { range, isEmpty } from 'lodash'
import {
  Search as SearchIcon,
  Menu as MenuIcon,
  Grid as GridIcon,
} from 'react-feather'

import {
  ContentHeader,
  Container,
  DocumentCard,
  DocumentCardSkeleton,
  DocumentFilter,
  DocumentDialog,
  AdvisorCard,
  Page,
  DriveTour,
} from 'components'
import { DocumentTable } from './components'

import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'

import helpers from 'helpers'

import constants from 'constants/index'
import * as service from 'service'
import useStyles from './styles'

import InformationDocuments from 'images/information_documents.svg'

const DocumentsTemplateMain = () => {
  const classes = useStyles()
  const filter = useFilter()
  const { mixpanel } = helpers

  const [openDialog, setOpenDialog] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState({})
  const [action, setAction] = useState('show')
  const [listType, setListType] = useState('cards')
  const [searchInput, setSearchInput] = useState('')
  const [openTour, setOpenTour] = useState(false)

  const {
    response: companyDocumentsResponse,
    refresh,
    isLoading,
  } = useFetch(
    service.dponet.documentsTemplate.get,
    {
      perPage: 1000,
      ...filter.filters,
    },
    [filter.filters],
  )

  const { response: documentCategoriesResponse, refresh: refreshCategories } =
    useFetch(service.dponet.documentsTemplate.getCategories)

  const companyDocuments = companyDocumentsResponse?.data?.documents
  const documentCategories =
    documentCategoriesResponse?.data?.documentCategories

  const resetDocumentState = () => {
    setSelectedDocument({})
  }

  const handleOpenDialog = (action, document) => {
    setOpenDialog(true)
    setSelectedDocument(document)
    setAction(action)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const reloadDocuments = () => {
    handleCloseDialog()
    refresh()
    refreshCategories()
  }

  const searchItems = (searchValue) => {
    setSearchInput(searchValue)
  }

  const filteredCompanyDocuments = !isEmpty(searchInput)
    ? companyDocuments.filter((companyDocument) =>
        companyDocument.title.toLowerCase().includes(searchInput.toLowerCase()),
      )
    : companyDocuments

  useEffect(() => {
    if (!openDialog) resetDocumentState()
  }, [openDialog])

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Documentos Modelo', 'Tour', {
      action: 'tour-modelos-documentos',
    })
  }

  const filteredSteps =
    constants.documents.DOCUMENTS_MODEL_DRIVER_STEPS_OBJECT.filter((step) => {
      if (isEmpty(companyDocuments))
        return (
          step.element !==
          '#' + constants.documents.DOCUMENTS_MODEL_MAIN_DRIVER_STEP_3
        )
      return true
    })

  return (
    <Page title="Documentos modelo">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Documentos modelo">
          <Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
              fullWidth
              id={constants.documents.DOCUMENTS_MODEL_MAIN_DRIVER_STEP_1}
            >
              Tutorial
            </Button>
          </Box>
          <Box pl={1}>
            <DocumentFilter filter={filter} categories={documentCategories} />
          </Box>
        </ContentHeader>
        <Box mb={4} id={constants.documents.DOCUMENTS_MODEL_MAIN_DRIVER_STEP_2}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <IconButton onClick={() => setListType('table')}>
              <MenuIcon size={16} />
            </IconButton>
            <IconButton onClick={() => setListType('cards')}>
              <GridIcon size={16} />
            </IconButton>
          </Box>
          <TextField
            className={classes.faqDocBox}
            fullWidth
            value={searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon size={20} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            onChange={(e) => searchItems(e.target.value)}
          />
        </Box>

        {companyDocuments?.length === 0 && (
          <AdvisorCard
            imgSrc={InformationDocuments}
            title="Nenhum documento modelo foi encontrado."
            document={true}
          />
        )}

        {listType === 'cards' && (
          <Grid container spacing={3}>
            {isLoading
              ? range(8).map((i) => <DocumentCardSkeleton key={i} />)
              : filteredCompanyDocuments?.map((document) => (
                  <DocumentCard
                    key={document.id}
                    document={document}
                    handleOpenDialog={handleOpenDialog}
                  />
                ))}
          </Grid>
        )}

        {listType === 'table' &&
          (isLoading ? (
            range(8).map((i) => <DocumentCardSkeleton key={i} />)
          ) : (
            <DocumentTable
              companyDocuments={filteredCompanyDocuments}
              handleOpenDialog={handleOpenDialog}
            />
          ))}
      </Container>

      <DocumentDialog
        action={action}
        document={selectedDocument}
        onClose={handleCloseDialog}
        open={openDialog}
        categories={documentCategories || []}
        reloadDocuments={reloadDocuments}
      />
      <DriveTour
        stepsMatrix={filteredSteps}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default DocumentsTemplateMain
