const styles = (theme) => ({
  root: {
    nameEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '18rem',
    },
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  defaultColorContainer: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    backgroundColor: theme.palette.background.default,
  },
  whitePage: {
    backgroundColor: theme.palette.background.paper,
  },
  paperMargin: {
    marginBottom: theme.spacing(5),
    padding: theme.spacing(5),
  },
  iconMargin: {
    marginRight: theme.spacing(1),
  },
  editIconButton: {
    marginRight: theme.spacing(1),
  },
  paddingContentHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paddingDescription: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  fullCardWidth: {
    width: '100%',
  },
  disableButtonHover: {
    backgroundColor: 'transparent !important',
  },
  alertBox: {
    padding: theme.spacing(2.5, 0),
  },
  alertUndefinedProcesses: {
    margin: theme.spacing(5, 0),
    padding: theme.spacing(2),
    fontSize: 16,
    cursor: 'pointer',
  },
})

export default styles
