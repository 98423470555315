import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get('/company_sizes', { params })
}

const companySizes = {
  get,
}

export default companySizes
