import phoneDynamicMask from './phoneDynamicMask'

const phoneMaskBuilder = (phone) => {
  if (!phone || phone.length === 0) return '(99) 9999-9999'
  const maskedPhone = phoneDynamicMask(phone)
  return maskedPhone.length >= 6 && maskedPhone[5] === '9'
    ? '(99) 9 9999-9999'
    : '(99) 9999-9999'
}

export default phoneMaskBuilder
