import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  levelAlert: {
    backgroundColor: theme.palette.yellowWarningDark,
    color: theme.palette.primary.main,
    alignItems: 'center',
  },
  levelAlertTitle: {
    margin: '8px 0',
    fontWeight: 700,
    textDecoration: 'underline',
  },
  levelAlertSubtitle: {
    margin: '8px 0',
  },
}))

export default styles
