import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  lockImage: {
    maxWidth: '50vh',
    [theme.breakpoints.down('sm')]: {
      width: '60%',
      padding: theme.spacing(2),
    },
  },
  infoButton: {
    display: 'inline-flex',
    [theme.breakpoints.up('md')]: {
      verticalAlign: 'super',
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  buttons: {
    gap: `${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  actionArea: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
}))

export default styles
