import React, { useState, useEffect } from 'react'

import { Box, CircularProgress, Grid, Typography } from '@material-ui/core'

import positionSealImage from 'images/position_seal.png'
import * as service from 'service'

import { Seal } from './components'

const Form = () => {
  const [loading, setLoading] = useState(false)
  const [privacyStamp, setPrivacyStamp] = useState({})

  useEffect(() => {
    setLoading(true)
    const getPrivacyStamp = async () => {
      const response = await service.dponet.privacyStamps.get()
      setPrivacyStamp(response.data)
    }

    getPrivacyStamp().then(() => setLoading(false))
  }, [])

  return (
    <Box mt={2}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={1}>
          <Grid item md={5} xs={12}>
            <img
              src={positionSealImage}
              alt="identidade visual da dponet"
              width="100%"
            />
          </Grid>
          <Grid item md={7} xs={12}>
            <Box px={2} mt={1}>
              <Typography variant="h6" color="textPrimary">
                Canal de Comunicação
              </Typography>
            </Box>
            <Box px={2} mt={1}>
              <Typography variant="body1" color="textPrimary" align="justify">
                Copie o código abaixo e incorpore-o ao seu site para estabelecer
                um Canal de Comunicação com os titulares de dados e a ANPD
                (Autoridade Nacional de Proteção de Dados).
              </Typography>
            </Box>
            <Box px={2} mt={1}>
              <Typography variant="body1" color="textPrimary" align="justify">
                Através dele, direcionamos todos os interessados para o Portal
                da Privacidade, onde encontrarão informações como: o DPO
                (Encarregado de Proteção de Dados) nomeado em sua organização; o
                canal de atendimento aos titulares de dados; o canal de
                atendimento à ANPD; além de acesso aos termos, avisos e
                políticas que foram cadastrados na plataforma.
              </Typography>
            </Box>
          </Grid>

          <Seal title="Selo" htmlTag={privacyStamp.blue} color="blue" />
        </Grid>
      )}
    </Box>
  )
}

export default Form
