import React from 'react'
import PropTypes from 'prop-types'

import { Box, Card, Typography } from '@material-ui/core'
import { RowLog } from './'

import helpers from 'helpers'

const TableLogs = ({ dponetAudits, mailLogs, object, entityName }) => {
  const logs = helpers.logs.separateLogsByDate(dponetAudits, mailLogs)
  const days = Object.keys(logs)

  return days.map((day) => {
    const changes = logs[day]

    return (
      <Box key={day}>
        <Typography variant="h4">{day}</Typography>
        <Box pt={2} pb={4}>
          <Card>
            {changes.map((change) => (
              <RowLog
                key={change?.createdAt}
                change={change}
                entityName={entityName}
                object={object}
              />
            ))}
          </Card>
        </Box>
      </Box>
    )
  })
}

TableLogs.propTypes = {
  dponetAudits: PropTypes.array,
  mailLogs: PropTypes.array,
  object: PropTypes.object,
  entityName: PropTypes.string,
}

TableLogs.defaultProps = {
  dponetAudits: [],
  mailLogs: [],
  object: {},
}

export default TableLogs
