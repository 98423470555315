import React, { useState, useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'

import { useLocation, matchPath } from 'react-router-dom'

import SubscriptionContext from 'contexts/SubscriptionContext'

import { TOKEN_PRE_REGISTRATION } from 'service/constants'
import * as service from 'service'
import constants from 'constants/index'
import { routes } from 'Routes'

const SubscriptionProvider = ({ children }) => {
  const location = useLocation()
  const [data, setData] = useState()
  const [responsibleData, setResponsibleData] = useState({})
  const [disabledButton, setDisabledButton] = useState(true)
  const [plans, setPlans] = useState([])
  const [termsAccept, setTermsAccept] = useState(false)
  const [policyAccept, setPolicyAccept] = useState(false)
  const [demoTermsAccept, setDemoTermsAccept] = useState(false)
  const [recaptcha, setRecaptcha] = useState(false)
  const [coupon, setCoupon] = useState({})
  const [selectedPlan, setSelectedPlan] = useState()
  const [validForm, setValidForm] = useState(false)
  const [currentKind, setCurrentKind] = useState()
  const [nextStep, setNextStep] = useState(
    constants.preRegistrations.FIRST_STEP[0],
  )
  const [selectedMethod, setSelectedMethod] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [payloadPayment, setPayloadPayment] = useState({})
  const [activeSubStep, setActiveSubStep] = useState(
    constants.preRegistrations.FIRST_STEP[1],
  )
  const [finalPrice, setFinalPrice] = useState('')
  const [status, setStatus] = useState(null)
  const [paymentData, setPaymentData] = useState({})
  const [selectedSegment, setSelectedSegment] = useState('')
  const [selectedDateExpirationBillet, setSelectedDateExpirationBillet] =
    useState('')
  const [currentDocument, setCurrentDocument] = useState('')

  const recaptchaRef = useRef()

  const { pathname } = useLocation()

  const isDemoSubscription = matchPath(pathname, {
    path: routes.subscription.demo,
    exact: true,
  })

  const isTrialSubscription = matchPath(pathname, {
    path: routes.subscription.trial,
    exact: true,
  })

  const isDemoSubscriptionFinish = matchPath(pathname, {
    path: routes.subscription.demoFinish,
    exact: true,
  })

  const toNextStep = () => {
    setNextStep(nextStep + 1)
  }

  const toNextSubStep = () => {
    setActiveSubStep(activeSubStep + 1)
  }

  const resetRecaptcha = () => {
    if (!!recaptchaRef?.current) {
      recaptchaRef.current.reset()
      setRecaptcha(undefined)
    }
  }

  const loadDataByToken = async () => {
    setIsLoading(isEmpty(data))
    const response = await service.dponet.preRegistrations.get()
    const preRegistration = response?.data?.preRegistration
    setData(preRegistration)
    setCoupon(preRegistration?.coupon || preRegistration?.discountCoupon)
    setSelectedPlan(preRegistration?.plan)
    setCurrentKind(
      preRegistration?.kind || constants.preRegistrations.JURIDIC_KIND,
    )
    setResponsibleData(preRegistration?.responsibleUser)
    setIsLoading(false)
  }

  const loadDataBySupplier = async (token) => {
    if (localStorage.getItem(TOKEN_PRE_REGISTRATION)) {
      await loadDataByToken()
    }
    setIsLoading(true)
    const {
      data: { company },
    } = await service.dponet.companies.verifyDocument(token)
    if (!company?.cnpj) {
      setCurrentKind(constants.preRegistrations.PERSONAL_KIND)
    }
    setData((data) => ({ ...data, ...company }))
    setResponsibleData({
      name: data?.name || company?.name,
      email: data?.email || company?.email,
      phone: data?.phone || company?.phone,
    })
    setIsLoading(false)
  }

  useEffect(() => {
    const defaultValidations = termsAccept && policyAccept && recaptcha

    if (isDemoSubscription) {
      if (defaultValidations && demoTermsAccept) return setDisabledButton(false)
      setDisabledButton(true)
    }
    if (isTrialSubscription) {
      if (defaultValidations && demoTermsAccept) return setDisabledButton(false)
      setDisabledButton(true)
    } else {
      if (defaultValidations) return setDisabledButton(false)
      setDisabledButton(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termsAccept, policyAccept, demoTermsAccept, recaptcha])

  useEffect(() => {
    setIsLoading(true)
    const searchParams = new URLSearchParams(location.search)

    if (
      localStorage.getItem(TOKEN_PRE_REGISTRATION) &&
      !searchParams.get('supplierToken')
    ) {
      loadDataByToken()
    }
    setIsLoading(false)
    //eslint-disable-next-line
  }, [])

  return (
    <SubscriptionContext.Provider
      value={{
        activeSubStep,
        coupon,
        currentDocument,
        currentKind,
        data,
        demoTermsAccept,
        disabledButton,
        finalPrice,
        isDemoSubscription,
        isTrialSubscription,
        isLoading,
        loadDataBySupplier,
        loadDataByToken,
        nextStep,
        payloadPayment,
        paymentData,
        plans,
        policyAccept,
        recaptcha,
        recaptchaRef,
        resetRecaptcha,
        responsibleData,
        selectedDateExpirationBillet,
        selectedMethod,
        selectedPlan,
        selectedSegment,
        setActiveSubStep,
        setCoupon,
        setCurrentDocument,
        setCurrentKind,
        setData,
        setDemoTermsAccept,
        setDisabledButton,
        setFinalPrice,
        setIsLoading,
        setPayloadPayment,
        setPaymentData,
        setPlans,
        setPolicyAccept,
        setRecaptcha,
        setResponsibleData,
        setSelectedDateExpirationBillet,
        setSelectedMethod,
        setSelectedPlan,
        setSelectedSegment,
        setStatus,
        setTermsAccept,
        setValidForm,
        status,
        termsAccept,
        toNextStep,
        toNextSubStep,
        validForm,
        isDemoSubscriptionFinish,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}

export default SubscriptionProvider
