import palette from 'theme/palette'

import { logoBlue } from '../../assets/logoBlue64'

import { ratio } from '../../helpers'

import {
  WIDTH as WidthPdf,
  HEIGHT as HeightPdf,
  fontSize,
  PADDING,
} from '../../constants'

const disclaimer = (pdf, date) => {
  const WIDTH = WidthPdf(pdf)
  const HEIGHT = HeightPdf(pdf)

  pdf.addPage()
  pdf.setFillColor(palette.white)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')

  pdf.addImage(
    logoBlue,
    'png',
    WIDTH / 2 - PADDING * 1.25,
    PADDING,
    ratio(500),
    ratio(158),
  )
  pdf.setFontSize(fontSize.subtitle)
  pdf.setLineHeightFactor(1.2)
  pdf.setFont('Raleway-Bold')

  pdf.setTextColor(palette.subscription.primary.main)
  pdf.text(
    'Informação Importante',
    PADDING,
    HEIGHT / 2 - ratio(300),
    null,
    null,
    'left',
  )

  pdf.setFontSize(fontSize.paragraph)
  pdf.setFont('Raleway-Regular')
  var splited_infos = pdf.splitTextToSize(
    'Esse relatório fornece informações gerais sobre o progresso da adequação da organização, contudo, não se trata de um documento oficial, isto é, que comprova a adequação perante autoridades. O documento exigido pela LGPD é o Relatório de Impacto à Proteção de Dados (RIPD), um documento que atende a todos os requisitos técnicos exigidos pela Autoridade Nacional de Proteção de Dados (ANPD).\n\nDessa forma, a DPOnet alerta que este Relatório Gerencial não possui fins técnicos ou comprobatórios, mas somente gráficos que ajudam no gerenciamento de atividades. A DPOnet não se responsabiliza pelo uso indevido desse relatório como comprovação de adequação à LGPD, ou quaisquer outros fins.',
    WIDTH - PADDING * 2,
  )
  pdf.text(
    splited_infos,
    PADDING,
    HEIGHT / 2 - ratio(150),
    null,
    null,
    'justify',
  )

  pdf.text(date, WIDTH / 2, HEIGHT - PADDING, null, null, 'justify')
}

export default disclaimer
