import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TableContainer,
  Box,
  Button,
  Link,
  Tooltip,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton } from 'components'

const AttachmentTable = ({
  attachments = [],
  handleDelete = () => {},
  withLink = false,
  openWrite = false,
}) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Documentos</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attachments.map((attachment, index) => (
            <TableRow key={attachment?.name + index}>
              <TableCell>
                {withLink ? (
                  <Link href={attachment?.url} target="_blank">
                    {attachment?.name}
                  </Link>
                ) : (
                  attachment?.name
                )}
              </TableCell>
              <TableCell align="right">
                <Grid>
                  <Box display="flex" justifyContent="flex-end">
                    <MenuButton>
                      {openWrite ? (
                        <Button
                          color="secondary"
                          fullWidth
                          onClick={() => handleDelete(index)}
                        >
                          Remover
                        </Button>
                      ) : (
                        <Tooltip
                          title="Reabra a medida para realizar esta ação."
                          arrow
                        >
                          <span>
                            <Button
                              color="secondary"
                              fullWidth
                              onClick={() => handleDelete(index)}
                              disabled
                            >
                              Remover
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </MenuButton>
                  </Box>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

AttachmentTable.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object),
  handleDelete: PropTypes.func,
}

AttachmentTable.defaultProps = {}

export default AttachmentTable
