import api from 'service/api'

const dponetAPI = api.create('dponet')

const show = async ({ trainningJourneyId, ...params }) => {
  return await dponetAPI.get(`/trainning_journeys/${trainningJourneyId}`, {
    params,
  })
}

const showByUser = async ({ trainningJourneyId }) => {
  return await dponetAPI.get(
    `/trainning_journeys/${trainningJourneyId}/show_by_user`,
  )
}

const validateCertification = async ({ trainningJourneyId }) => {
  return await dponetAPI.post(
    `/trainning_journeys/${trainningJourneyId}/validate_certification`,
  )
}

const removeAnswers = async ({ trainningJourneyId }) => {
  return await dponetAPI.delete(
    `/trainning_journeys/${trainningJourneyId}/remove_answers`,
  )
}

const answer = async ({ questionId, ...data }) => {
  return await dponetAPI.post(`/trainning_questions/${questionId}/answer`, data)
}

const showUserCertifications = async () => {
  return await dponetAPI.get(`/trainning_journeys/show_user_certifications`)
}

const trainningJourneys = {
  show,
  showByUser,
  validateCertification,
  answer,
  removeAnswers,
  showUserCertifications,
}

export default trainningJourneys
