import React, { useEffect, useState } from 'react'

import { useLocation, matchPath } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Box,
  Container,
  useMediaQuery,
  Link,
} from '@material-ui/core'

import { Account, RedirectHelpButton } from './components'

import useAuth from 'hooks/useAuth'

import useStyles from './styles'
import logo from 'images/dponet-logo-white.svg'
import theme from 'theme'
import { routes } from 'Routes'

const TopBar = () => {
  const {
    user,
    homeRoute,
    documentations,
    redirectLinks,
    showHelpButton: showHelpButtonAuth,
  } = useAuth()
  const [showHelpButton, setShowHelpButton] = useState(showHelpButtonAuth)
  const [documentationId, setDocumentationId] = useState()

  const location = useLocation()
  const classes = useStyles()

  useEffect(() => {
    const verifyMatch = (uri, id) => {
      if (!matchPath(location.pathname, { path: uri, exact: true })) {
        return
      }

      setShowHelpButton(true)
      setDocumentationId(id)
    }

    const matchDocumentationLink = async () => {
      setShowHelpButton(false)
      documentations &&
        documentations.map((documentation) => {
          if (documentation.uri && documentation.id) {
            verifyMatch(documentation.uri, documentation.id)
          }

          return documentation
        })
    }

    if (showHelpButtonAuth) {
      matchDocumentationLink()
    }

    // eslint-disable-next-line
  }, [documentations, redirectLinks, location.key])

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  })

  return (
    <AppBar elevation={0} className={classes.appBar}>
      <Container maxWidth="xl" className={classes.container}>
        <Toolbar>
          <Box>
            <Link
              href={
                user?.allStepsConcluded
                  ? homeRoute || routes.dataProcess.resume
                  : routes.userSteps
              }
            >
              <img
                alt="Logo"
                src={logo}
                className={classes.logo}
                width="130px"
              />
            </Link>
          </Box>
          <Box ml={2} flexGrow={1} />
          <Box
            display="flex"
            justifyContent="flex-end"
            ml={1}
            mr={isMobile ? 1 : 4}
          >
            {showHelpButton && (
              <RedirectHelpButton
                documentationId={documentationId}
                classes={classes}
              />
            )}
          </Box>
          <Box ml={3}>
            <Account />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default TopBar
