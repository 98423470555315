import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Card,
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  makeStyles,
} from '@material-ui/core'

import { AlertCircle as AlertCircleIcon, Plus as PlusIcon } from 'react-feather'
import { useHistory } from 'react-router-dom'

import DialogQuestionRecommendation from '../DialogQuestionRecommendation'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import constants from 'constants/index'

import styles from './styles'

const useStyles = makeStyles(styles)

const RecommendedQuestionsCard = ({
  displayQuestions,
  questionnaireId,
  setCurrentTab,
  refresh = async () => {},
}) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const classes = useStyles()
  const [dialogRecommendation, setDialogRecommendation] = useState(false)
  const [recommendation, setRecommendation] = useState()

  const includeQuestion = async (questionId) => {
    try {
      await service.dponet.questionnaires.includeQuestion({
        questionnaireId,
        questionId,
      })
      snackbar.open({
        message: 'Questão incluída às suas questões com sucesso',
        variant: 'success',
      })
      await refresh()
      history.replace(`#${questionId}`)
      setCurrentTab(constants.questionnaire.USER_QUESTIONS_TAB_VALUE)
    } catch {
      snackbar.open({
        message: 'Ocorreu um erro ao incluir a questão recomendada',
        variant: 'error',
      })
    }
  }

  const handleOpen = (recommendation) => {
    setRecommendation(recommendation)
    setDialogRecommendation(true)
  }

  return (
    <Card>
      <Box>
        {displayQuestions?.length !== 0 ? (
          <>
            {displayQuestions.map((question, index) => (
              <Box key={question.id}>
                <Box mx={3} my={2} id={question.id}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="h5">
                            {index + 1}. {question.theme}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box width="100%" mt={2} mb={1} ml={2}>
                            <Typography variant="body1">
                              {question.question}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            width="100%"
                            display="flex"
                            alignItems="flex-end"
                            flexDirection="column"
                          >
                            {!isEmpty(question?.recomendations) && (
                              <Button
                                size="small"
                                onClick={() =>
                                  handleOpen(question?.recomendations)
                                }
                                startIcon={<AlertCircleIcon size={14} />}
                                className={classes.iconColor}
                              >
                                Recomendações
                              </Button>
                            )}

                            <Box mt={2}>
                              <Button
                                onClick={() => includeQuestion(question.id)}
                                startIcon={<PlusIcon size={12} />}
                                variant="outlined"
                                color="primary"
                                disableElevation
                              >
                                Incluir
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
              </Box>
            ))}
          </>
        ) : (
          <Box m={3}>
            <Typography variant="body1">
              Não existe nenhuma questão recomendada.
            </Typography>
          </Box>
        )}
      </Box>

      <DialogQuestionRecommendation
        open={dialogRecommendation}
        setOpen={setDialogRecommendation}
        recommendation={recommendation}
      />
    </Card>
  )
}

export default RecommendedQuestionsCard
