import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import { Text } from '../'

import { Box } from '@material-ui/core'

import TextEditorTrail from './TextEditorTrail'

import useTrailStep from 'hooks/useTrailStep'

import * as service from 'service'

const PrivacyPolicyForm = () => {
  const [loadingPrivacy, setLoadingPrivacy] = useState(false)

  const { privacyPolicy, setPrivacyPolicy } = useTrailStep()

  useEffect(() => {
    const loadPrivacyPolicy = async () => {
      setLoadingPrivacy(true)
      const response = await service.dponet.privacyPolicies.get({
        title: 'Aviso de Privacidade',
      })

      const privacyPolicyResponse =
        !!response?.data?.privacyPolicies && response?.data?.privacyPolicies[0]

      setPrivacyPolicy(privacyPolicyResponse)
      setLoadingPrivacy(false)
    }

    if (isEmpty(privacyPolicy)) loadPrivacyPolicy()

    //eslint-disable-next-line
  }, [])

  return (
    <>
      <Text color="primary" fontSize="7vh" variant="h1">
        Aviso de Privacidade
      </Text>
      <Box my={3}>
        <Text fontSize="3.2vh" variant="h4" gutterBottom>
          O Aviso de Privacidade explica como as informações dos usuários são
          coletadas e usadas por um serviço online, incluindo dados pessoais e
          compartilhamento com terceiros
        </Text>
      </Box>
      {!loadingPrivacy && <TextEditorTrail />}
    </>
  )
}

export default PrivacyPolicyForm
