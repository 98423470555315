import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { Box, TextField, Grid } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { BaseDialog, Select, Label } from 'components'

import useFetch from 'hooks/useFetch'
import { Controller, useForm } from 'react-hook-form'

import constants from 'constants/index'
import * as service from 'service'

const CreateAgentDialog = ({
  open,
  setOpen,
  setAgents,
  agents,
  agentEdition,
  setAgentEdition,
}) => {
  const { control, errors, setValue, handleSubmit } = useForm({
    defaultValues: {
      agentType: agentEdition?.agentType || '',
      name: agentEdition?.name || '',
      document: agentEdition?.document || '',
    },
  })

  const { response } = useFetch(
    service.dponet.dataTreatmentAgents.listOptions,
    {},
    [],
  )

  const onSubmit = (data) => {
    agents = agents.filter((agent) => agent.name !== data.name)

    setAgents([
      ...agents,
      {
        name: data?.name,
        document: data?.document,
        agentType: data?.agentType,
      },
    ])

    setAgentEdition(null)
    setOpen(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="agent-form">
      <BaseDialog
        dialogSize="sm"
        open={open}
        setOpen={setOpen}
        closeButtonText="Cancelar"
        actionButtonText="Cadastrar"
        formRef="agent-form"
        resetFilds={setAgentEdition}
        title={
          isEmpty(agentEdition) ? 'Nova organização' : 'Editar a organização'
        }
      >
        <Grid container spacing={2}>
          {isEmpty(agentEdition) && !isEmpty(response?.data) && (
            <Grid item xs={12}>
              <Label title="Buscar organizações">
                <Controller
                  control={control}
                  name="searchAgent"
                  onChange={([, data]) => {
                    setValue('document', data.document)
                    setValue('name', data.name)
                    return data
                  }}
                  mode="onChange"
                  as={
                    <Autocomplete
                      options={response?.data}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        option.name === value.name || value.name === 0
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  }
                />
              </Label>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box mt={isEmpty(agentEdition) ? 2 : 0}>
              <Label
                title={
                  isEmpty(agentEdition) ? 'Adicionar nova organização' : ''
                }
              >
                <Controller
                  as={
                    <TextField
                      label="Nome"
                      color="primary"
                      variant="outlined"
                      error={!!errors?.name}
                      helperText={errors?.name && 'Preencha este campo'}
                      fullWidth
                    />
                  }
                  control={control}
                  rules={{ required: true }}
                  name="name"
                  mode="onBlur"
                />
              </Label>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  inputProps={{
                    maxLength: 18,
                  }}
                  label="CPF/CNPJ"
                  color="primary"
                  variant="outlined"
                  error={!!errors?.document}
                  helperText={errors?.document && 'Preencha este campo'}
                  fullWidth
                />
              }
              control={control}
              name="document"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <Select
                  items={constants.dataTreatmentAgents.OPTIONS_TYPES}
                  label="Agente"
                  error={!!errors?.agentType}
                  helperText={errors?.agentType && 'Preencha este campo'}
                  fullWidth
                />
              }
              control={control}
              rules={{ required: true }}
              name="agentType"
              mode="onBlur"
            />
          </Grid>
        </Grid>
      </BaseDialog>
    </form>
  )
}

CreateAgentDialog.propTypes = {
  setAgents: PropTypes.func,
  agents: PropTypes.array,
  agentEdition: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

CreateAgentDialog.defaultProps = {
  setAgents: () => {},
  setOpen: () => {},
}

export default CreateAgentDialog
