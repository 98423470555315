import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  Grid,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'

import { SuggestionAlert } from 'components/RmcActionContent/components'
import { DataLifeCycleTable } from './components'

import constants from 'constants/index'
import styles from './styles'

const useStyles = makeStyles(styles)

const DataLifeCycle = ({ data, isLoading, hiddenSuggestionAlert }) => {
  const classes = useStyles()

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="h5" className={classes.titleContainer}>
            Salvaguarda e Ciclo de Vida
          </Typography>
          <SuggestionAlert
            hidden={hiddenSuggestionAlert}
            sectionName="Salvaguarda e ciclo de vida"
            dataProcessId={data.id}
            sectionTab={constants.suggestionChanges.TAB_VIEW_VALUE.LIFE_CYCLES}
          />
          {Array.isArray(data.lifeCycles) ? (
            <Grid xs={12} item>
              <DataLifeCycleTable
                lifeCycles={data.lifeCycles}
                dataProcess={data}
              />
            </Grid>
          ) : (
            <Grid container className={classes.root}>
              <Grid item xl={4} md={4} sm={6} xs={12}>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Onde os dados são armazenados?
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {data?.storageLocation?.name || ''}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Como os dados são dispostos?
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {data?.discardMode?.name || ''}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Recuperação
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {data?.recovery?.name || ''}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={4} md={4} sm={6} xs={12}>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Ambiente de alocação
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {data?.internalAllocationMode?.name || ''}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Tempo de Armazenamento
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {data?.storageTime?.name || ''}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Proteção
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {data?.protection?.name || ''}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={4} md={4} sm={6} xs={12}>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Justificativa de retenção
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {data?.retentionFinality || ''}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  )
}

DataLifeCycle.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  hiddenSuggestionAlert: PropTypes.bool,
}

export default DataLifeCycle
