const currencyInput = (valor) => {
  valor = formatValue(valor)
  valor = valor?.replace(/([0-9]{2})$/g, ',$1')

  if (valor === 'NaN') {
    return ''
  }

  if (valor?.length > 6) {
    valor = valor?.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2')
  }

  return valor
}

const formatValue = (value) => {
  value = value?.replace(/[.,\s]/g, '') + ''
  value = +value + ''
  value = value?.replace(/[\D]+/g, '') + ''
  value = value?.padStart(4, '0') + ''

  return value
}

export default currencyInput
