import { colors } from '@material-ui/core'

const DENIED_KIND = 'denied'
const FULL_KIND = 'full'
const PARTIAL_KIND = 'partial'

const READING_TYPE_TRANSLATION = {
  [DENIED_KIND]: 'Negado',
  [FULL_KIND]: 'Aceito',
  [PARTIAL_KIND]: 'Aceite parcial',
}

const STATUS_CHIP_COLOR = {
  [DENIED_KIND]: colors.red[100],
  [FULL_KIND]: colors.green[100],
  [PARTIAL_KIND]: colors.blue[100],
}

const STATUS_CHIP_FONT_COLOR = {
  [DENIED_KIND]: colors.red[800],
  [FULL_KIND]: colors.green[800],
  [PARTIAL_KIND]: colors.blue[800],
}

const CONSENT_DETAILS_DIALOG_ITEMS = [
  {
    title: 'Domínio do banner',
    value: (value) => value,
  },
  {
    title: 'ID do visitante',
    value: (value) => value,
  },
  {
    title: 'Tipo:',
    value: (value) => value,
  },
  {
    title: 'Data do consentimento:',
    value: (value) => value,
  },
  {
    title: 'Tags aceitas:',
    value: (value) => value,
  },
  {
    title: 'Tags recusadas',
    value: (value) => value,
  },
]

const COOKIES_CONSENT_MAIN_DRIVER_STEP = 'cookies-consent-main-driver-step'

const COOKIES_CONSENT_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + COOKIES_CONSENT_MAIN_DRIVER_STEP,
    popover: {
      description:
        'Local onde são exibidas as interações com o banner de cookies, indicando se os usuários aceitaram ou não. É possível visualizar quais tags foram aceitas e quais foram recusadas.',
    },
  },
]

export default {
  READING_TYPE_TRANSLATION,
  CONSENT_DETAILS_DIALOG_ITEMS,
  STATUS_CHIP_COLOR,
  STATUS_CHIP_FONT_COLOR,
  COOKIES_CONSENT_DRIVER_STEPS_OBJECT,
  COOKIES_CONSENT_MAIN_DRIVER_STEP,
}
