import { makeStyles } from '@material-ui/core'

const styles = ({
  attachmentsBgcolor,
  backgroundColor,
  color,
  isDragging,
  width,
  disabled,
  error,
}) =>
  makeStyles((theme) => ({
    formControl: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: disabled ? 'not-allowed' : 'pointer',
      minHeight: 150,
      gap: theme.spacing(1),
      padding: theme.spacing(2),
      border: `2px dashed ${color}`,
      borderRadius: 4,
      color: color,
      backgroundColor: backgroundColor,
      width: width,
    },
    title: {
      fontWeight: isDragging ? 500 : 400,
    },
    input: {
      display: 'none',
    },
    controlBar: {
      paddingInline: theme.spacing(2),
      paddingTop: theme.spacing(2),
      border: `1px solid ${theme.palette.textfield.border}`,
      borderRadius: 4,
      color: theme.palette.custom.dark,
      marginTop: theme.spacing(2),
      backgroundColor: backgroundColor,
    },
    attachmentItem: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: 4,
      backgroundColor: attachmentsBgcolor,
      cursor: 'pointer',
      width: 'fit-content',
    },
    previewButton: {
      ...(error && {
        borderColor: theme.palette.error.main,
        color: theme.palette.error.main,
        '&:hover': {
          borderColor: theme.palette.error.dark,
        },
      }),
    },
  }))

export default styles
