import constants from 'constants/index'

const statusButtonItems = (status) => {
  const { ALL_STATUS_ID, ALL_STATUS_LABEL } = constants.complaint

  return [
    {
      id: ALL_STATUS_ID.PENDING_STATUS_ID,
      name: ALL_STATUS_LABEL.PENDING_STATUS_LABEL,
      disabled: status === ALL_STATUS_ID.CONVERTED_INCIDENT_STATUS_ID,
    },
    {
      id: ALL_STATUS_ID.FINALIZED_STATUS_ID,
      name: ALL_STATUS_LABEL.FINALIZED_STATUS_LABEL,
      disabled: status === ALL_STATUS_ID.CONVERTED_INCIDENT_STATUS_ID,
    },
    {
      id: ALL_STATUS_ID.CONVERTED_INCIDENT_STATUS_ID,
      name: ALL_STATUS_LABEL.CONVERTED_INCIDENT_STATUS_LABEL,
      disabled:
        status === ALL_STATUS_ID.PENDING_STATUS_ID ||
        status === ALL_STATUS_ID.FINALIZED_STATUS_ID,
    },
  ]
}

export default statusButtonItems
