import chapterCover from '../../templates/chapterCover'
import diagnostics from './diagnostics'
import disclaimer from './disclaimer'
import generalInformations from './generalInformations'
import glossary from './glossary'
import incidents from './incidents'
import introduction from './introduction'
import lastCover from '../../templates/lastCover'
import processes from './processes'
import secondCover from './secondCover'
import summary from './summary'
import suppliers from './suppliers'

const pages = {
  chapterCover,
  diagnostics,
  disclaimer,
  generalInformations,
  glossary,
  incidents,
  introduction,
  lastCover,
  processes,
  secondCover,
  summary,
  suppliers,
}

export default pages
