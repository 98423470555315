import React from 'react'
import { Tooltip, Chip } from '@material-ui/core'
import { useLocation, matchPath } from 'react-router-dom'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import HelpStep from './components/HelpStep'

import useAuth from 'hooks/useAuth'

import { getTomTicketURL } from 'service/env'
import helpers from 'helpers'
import { routes } from 'Routes'

const RedirectHelpButton = ({ helpUrl, classes }) => {
  const auth = useAuth()
  const { pathname } = useLocation()

  const isUserSteps = matchPath(pathname, {
    path: routes.userSteps,
    exact: true,
  })

  const redirectRoute = () => {
    const formattedHelpUrl = helpUrl
      .replace('https://', '')
      .replace('http://', '')

    return helpers.functions.splitFirstOccurrence(formattedHelpUrl, '/')[1]
  }

  const redirectUrl = () => {
    const url = getTomTicketURL()

    return `${url}=${auth?.user?.tomticketToken}&redirect=/${redirectRoute()}`
  }

  const handleHelp = () => {
    window.open(redirectUrl(), '_blank')
  }

  return (
    <Tooltip title="Acesso a documentação">
      {!isUserSteps ? (
        <Chip
          className={classes.helpChip}
          onClick={handleHelp}
          icon={<HelpCircleIcon className={classes.iconChip} />}
          label="Ajuda"
          clickable={false}
        />
      ) : (
        <HelpStep redirectUrl={redirectUrl} classes={classes} />
      )}
    </Tooltip>
  )
}

export default RedirectHelpButton
