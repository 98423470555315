import React from 'react'

import { Grid, Box, Typography, makeStyles, Chip } from '@material-ui/core'

import DataProcessDetails from '../DataProcessDetails'

import helpers from 'helpers'
import constants from 'constants/index'
import styles from '../../styles'

const useStyles = makeStyles(styles)

const NonComplianceReportsDetails = ({ nonComplianceReport, title }) => {
  const classes = useStyles()

  return (
    <>
      {title && (
        <Box mb={3}>
          <Typography variant="h5">{title}</Typography>
        </Box>
      )}
      <Grid container>
        <Grid item xl={4} md={4} sm={6} xs={12}>
          <Box>
            <Typography variant="subtitle2" color="textSecondary">
              Identificador
            </Typography>
            <Typography color="textPrimary" variant="body1">
              {nonComplianceReport.id}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={4} md={4} sm={6} xs={12}>
          <Box mt={3}>
            <Typography variant="subtitle2" color="textSecondary">
              Status
            </Typography>
            <Chip
              className={helpers.nonComplianceReports.statusChipColor(
                nonComplianceReport.status,
                classes,
              )}
              align="center"
              size="small"
              label={helpers.nonComplianceReports.status(
                nonComplianceReport.status,
              )}
            />
          </Box>
        </Grid>
        <Grid item xl={4} md={4} sm={6} xs={12}>
          <Box mt={3}>
            <Typography variant="subtitle2" color="textSecondary">
              Criado em
            </Typography>
            <Typography color="textPrimary" variant="body1">
              {helpers.formatters.date(nonComplianceReport.createdAt)}
            </Typography>
          </Box>
        </Grid>
        {nonComplianceReport.entityType ===
          constants.nonComplianceReports.ENTITY_DATA_PROCESS && (
          <DataProcessDetails nonComplianceReport={nonComplianceReport} />
        )}
      </Grid>
    </>
  )
}

export default NonComplianceReportsDetails
