import React from 'react'
import PropTypes from 'prop-types'

import { useForm, Controller } from 'react-hook-form'

import { Grid, TextField } from '@material-ui/core'

import { Label } from 'components'

import schema from './schema'

const RetentionJustificationForm = ({ handleCreate }) => {
  const { control, errors, getValues, triggerValidation } = useForm({
    validationSchema: schema,
    defaultValues: {
      content: '',
    },
  })

  const handleSubmit = (data) => {
    handleCreate(data)
  }

  const handleValidSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    triggerValidation().then((valid) => {
      if (valid) {
        handleSubmit(getValues())
      }
    })
  }

  return (
    <form onSubmit={handleValidSubmit} id="process-ticket-form">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Label
            title="Motivo"
            description="Por favor, explique o(s) motivo(s) para o armazenamento permanente destes dados"
            xs={12}
            titleVariant="h5"
            item
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            as={
              <TextField
                label="Escreva seu(s) motivo(s)"
                type="text"
                color="primary"
                variant="outlined"
                multiline
                rows={6}
                error={!!errors.content}
                helperText={errors?.content?.message}
                fullWidth
              />
            }
            name="content"
            mode="onBlur"
          />
        </Grid>
      </Grid>
    </form>
  )
}

RetentionJustificationForm.propTypes = {
  handleCreate: PropTypes.func,
}

RetentionJustificationForm.defaultProps = {
  handleCreate: () => {},
}

export default RetentionJustificationForm
