import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  TextField,
  MenuItem,
  CircularProgress,
} from '@material-ui/core'

import { LoadingButton } from 'components'

import userCompaniesConstants from 'constants/userCompanies'
import useSnackbar from 'hooks/useSnackbar'
import * as service from 'service'

const ChangeStatusDialog = ({
  user,
  userCompanyId,
  userCompanyStatus,
  open,
  isLoading,
  profiles,
  setOpen = () => {},
  refresh = () => {},
}) => {
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)

  const { control, setError, getValues, errors } = useForm({
    defaultValues: {
      profileId: '',
    },
  })

  const noticeText = () => {
    if (!user) return

    if (
      user?.vinculedDepartmentNames !== '' &&
      userCompanyStatus === userCompaniesConstants.ACTIVE
    ) {
      return `Este usuário está vinculado ao(s) seguinte(s) departamento(s): "${user.vinculedDepartmentNames}". Ao excluir o usuário, o(s) departamento(s) e os processos não serão excluídos, mas o usuário será desvinculado deles. Certifique-se apenas de manter ao menos um responsável pelo(s) departamento(s).`
    }

    if (userCompanyStatus === userCompaniesConstants.ACTIVE) {
      return `Você tem certeza que deseja excluir o usuário ${user.name}?`
    }

    return `Você tem certeza que deseja reativar o usuário ${user.name}? Selecione um perfil para o usuário:`
  }

  const onSubmit = async (userCompanyStatus) => {
    try {
      if (
        userCompanyStatus === userCompaniesConstants.INACTIVE &&
        (getValues('profileId') === '' || !getValues('profileId'))
      ) {
        setError('profileId', {
          type: {
            notMatch: 'Perfil não pode ficar em branco.',
          },
        })
      } else {
        setLoading(true)
        await service.dponet.usersCompanies.softDelete({
          userCompanyId,
          profileId: getValues('profileId'),
        })
        setLoading(false)
        snackbar.open({
          message:
            userCompanyStatus === userCompaniesConstants.ACTIVE
              ? 'Usuário excluído com sucesso!'
              : 'Usuário reativado com sucesso!',
          variant: 'success',
        })
        setOpen(false)
        refresh()
      }
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : userCompanyStatus === userCompaniesConstants.ACTIVE
          ? 'Falha ao excluído usuário!'
          : 'Falha ao reativar usuário!',
        variant: 'error',
      })
      setOpen(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="change-user-status"
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h5">Excluir usuário</Typography>
      </DialogTitle>
      <Divider />
      <Box>
        <DialogContent>
          <Box pb={1}>
            <Typography>{noticeText()}</Typography>
            {userCompanyStatus === userCompaniesConstants.INACTIVE && (
              <Box mt={1}>
                {isLoading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress size={30} />
                  </Box>
                ) : (
                  <Controller
                    control={control}
                    name="profileId"
                    as={
                      <TextField
                        label="Perfil"
                        select
                        color="primary"
                        variant="outlined"
                        error={!!errors.profileId}
                        helperText={errors?.profileId?.types?.type?.notMatch}
                        fullWidth
                      >
                        {profiles?.map((profile) => (
                          <MenuItem key={profile.id} value={profile.id}>
                            <Typography>{profile.name}</Typography>
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                    mode="onBlur"
                  />
                )}
              </Box>
            )}
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <LoadingButton
          variant="contained"
          type="submit"
          color="primary"
          text="Confirmar"
          loading={loading}
          onClick={() => onSubmit(userCompanyStatus)}
        />
      </DialogActions>
    </Dialog>
  )
}

ChangeStatusDialog.propTypes = {
  user: PropTypes.object,
  userCompanyId: PropTypes.number,
  userCompanyStatus: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
}

ChangeStatusDialog.defaultProps = {
  setOpen: () => {},
  refresh: () => {},
}

export default ChangeStatusDialog
