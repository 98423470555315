import palette from 'theme/palette'

import { hexagonBorder } from '../../assets/hexagonBorder64'

import { ratio } from '../../helpers'

const hexagon = (pdf, positionX, positionY, label, data) => {
  pdf.addImage(
    hexagonBorder,
    'png',
    positionX,
    positionY,
    ratio(380),
    ratio(380),
  )
  pdf.setTextColor(palette.white)
  pdf.setFontSize(ratio(100))
  pdf.setFont('Raleway-Bold')
  pdf.text(
    data,
    positionX + ratio(190),
    positionY + ratio(180),
    null,
    null,
    'center',
  )
  pdf.setFontSize(ratio(70))
  pdf.text(
    label,
    positionX + ratio(190),
    positionY + ratio(250),
    null,
    null,
    'center',
  )
}

export default hexagon
