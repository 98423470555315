import React, { useState } from 'react'

import { Box, makeStyles, Button, Link } from '@material-ui/core'

import { ExternalLink as ExternalLinkIcon } from 'react-feather'

import {
  DialogContactTickets,
  DialogMyTickets,
  LoadingFeedback,
} from 'components'

import { getTomTicketURL } from 'service/env'
import { routes } from 'Routes'

import * as service from 'service'
import helpers from 'helpers'
import styles from './styles'

import { useHistory } from 'react-router-dom'
import useSnackbar from 'hooks/useSnackbar'
const useStyles = makeStyles(styles)

const HelpCentralBox = () => {
  const history = useHistory()
  const classes = useStyles()
  const url = getTomTicketURL()
  const snackbar = useSnackbar()

  const { mixpanel } = helpers

  const [dialogContactTickets, setDialogContactTickets] = useState(false)
  const [dialogMyTickets, setDialogMyTickets] = useState(false)
  const [loading, setLoading] = useState(false)

  const mixpanelTrack = () => {
    mixpanel.track('Fornecedores', 'Novo fornecedor', {
      action: 'cliente utilizou os botões de ajuda',
    })
  }

  const goToDeskTickets = async () => {
    try {
      setLoading(true)

      const response =
        await service.dponet.tomticketIntermediator.getTomticketToken()
      const tomticketToken = response?.data

      window.open(`${url}=${tomticketToken}`)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro, tente novamente mais tarde.',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const goToFaqsDocumentations = () => {
    if (window.document.title === 'Novo fornecedor') {
      mixpanelTrack()
    }

    history.push(routes.faqsDocumentations.all)
  }

  const handleOpenContactTickets = () => {
    if (window.document.title === 'Novo fornecedor') {
      mixpanelTrack()
    }
    setDialogContactTickets(true)
  }

  const handleOpenMyTickets = () => {
    if (window.document.title === 'Novo fornecedor') {
      mixpanelTrack()
    }
    setDialogMyTickets(true)
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <Box p={2} className={classes.root}>
        <Button
          className={classes.helpCentralButton}
          variant="contained"
          endIcon={
            <ExternalLinkIcon
              className={classes.helpCentralButtoIcon}
              size="16"
            />
          }
          onClick={goToFaqsDocumentations}
          size="small"
        >
          Central de Ajuda
        </Button>
        <Link
          className={classes.link}
          variant="body2"
          onClick={handleOpenMyTickets}
        >
          Ver meus Tickets
        </Link>
        <Link
          className={classes.link}
          variant="body2"
          onClick={handleOpenContactTickets}
        >
          Entre em contato
        </Link>

        <DialogContactTickets
          open={dialogContactTickets}
          setOpen={setDialogContactTickets}
        />

        <DialogMyTickets
          open={dialogMyTickets}
          setOpen={setDialogMyTickets}
          goToDeskTickets={goToDeskTickets}
        />
      </Box>
    </>
  )
}

export default HelpCentralBox
