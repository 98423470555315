const cleanConsentContent = (content) =>
  content
    .replace(/<p[^>]*>/g, '\n\n')
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(
      /&[^;]+;/g,
      (match) =>
        ({
          '&ccedil;': 'ç',
          '&atilde;': 'ã',
          '&ecirc;': 'ê',
          '&nbsp;': ' ',
          '&oacute;': 'ó',
          '&ocirc;': 'ô',
          '&uacute;': 'ú',
          '&eacute;': 'é',
          '&agrave;': 'à',
          '&iacute;': 'í',
          '&Atilde;': 'Ã',
          '&Ccedil;': 'Ç',
          '&Oacute;': 'Ó',
          '&Eacute;': 'É',
        }[match] || ' '),
    )
    .replace(/\s+/g, ' ')
    .trim()

export default cleanConsentContent
