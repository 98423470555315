import { makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.spacing(2),
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: grey[200],
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textBox: {
    minWidth: 0,
    flex: 1,
  },
  buttonLink: {
    padding: 0,
    color: theme.palette.primary.hover,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    textTransform: 'capitalize',
    height: 17,
  },
}))

export default styles
