import { isEmpty } from 'lodash'

const rangeAgeContent = (titularRangeAges) => {
  const ranges = titularRangeAges?.map((range) => range.name)
  if (isEmpty(ranges)) return 'Nenhuma faixa etária'

  return ranges.toString().replaceAll(',', ' - ')
}

export default rangeAgeContent
