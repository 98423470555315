import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import helpers from 'helpers'

import {
  Box,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons/'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import styles from './styles'
const useStyles = makeStyles(styles)

const RowChangesCollapse = ({ changes, registerType, action, open }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell width="33%">Informação</TableCell>
            <TableCell width="33%">Antes</TableCell>
            <TableCell width="33%">Depois</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {changes.map((change, index) => (
            <TableRow key={index}>
              <TableCell
                className={helpers.logs.tableCellColor(action, classes)}
              >
                {t(`${registerType}.${change[0]}`)}
              </TableCell>
              <TableCell
                className={helpers.logs.tableCellColor(action, classes)}
              >
                {helpers.logs.formattedValue(
                  change[0],
                  change[1][0],
                  registerType,
                )}
              </TableCell>
              <TableCell
                className={helpers.logs.tableCellColor(action, classes)}
              >
                {helpers.logs.formattedValue(
                  change[0],
                  change[1][1],
                  registerType,
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Collapse>
  )
}

const CardLog = ({ change, entityName, object }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState()

  const registerTranslated = t(
    `registerTypes.${change?.auditableType}`,
  ).toLowerCase()

  const hasChanges = !!change.changes

  return (
    <>
      <Box py={hasChanges ? 1.5 : 2} px={2}>
        <Grid container alignItems="center">
          <Grid item xs={2} md={1}>
            <Typography variant="body1">
              {helpers.formatters.dateLocaleFormat(change?.createdAt, 'LT')}
            </Typography>
          </Grid>
          <Grid item xs={hasChanges ? 8 : 10} md={10}>
            <Typography variant="body1">
              Autor: {change?.authorName || change?.author?.name} -{' '}
              {helpers.logs.logTitleByAction(
                change?.action,
                change?.changes || change,
                object,
                entityName,
                registerTranslated,
              )}
            </Typography>
          </Grid>
          {hasChanges && (
            <Grid item xs={2} md={1}>
              <Box display="flex" justifyContent="end">
                <IconButton size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      {hasChanges && (
        <RowChangesCollapse
          changes={change.changes}
          registerType={change.auditableType}
          action={change.action}
          open={open}
          showRegisterSession
        />
      )}
      <Divider />
    </>
  )
}

CardLog.propTypes = {
  change: PropTypes.object,
  entityName: PropTypes.string,
  object: PropTypes.object,
}

RowChangesCollapse.propTypes = {
  changes: PropTypes.array,
  registerType: PropTypes.string,
  action: PropTypes.string,
  open: PropTypes.bool,
}

export default CardLog
