import React from 'react'
import { Box, Card, Typography, useMediaQuery } from '@material-ui/core'
import { Receipt as ReceiptIcon } from '@material-ui/icons'

import formatters from 'helpers/formatters'
import theme from 'theme'

const PaymentBillet = ({ price, nextDateAdvance }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <Card
      component={Box}
      display="flex"
      {...(isMobile && { flexDirection: 'column', gap: 2 })}
      justifyContent="space-between"
      alignItems={isMobile ? 'start' : 'center'}
      p={2}
    >
      <Box display="flex" alignItems="center">
        <ReceiptIcon fontSize="large" />
        <Box pl={2}>
          <Typography>Boleto bancário</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        {...(!isMobile && { alignItems: 'end' })}
      >
        <Typography variant="caption" color="textSecondary">
          Próxima cobrança: {formatters.date(nextDateAdvance) ?? '-'}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {price}/mês
        </Typography>
      </Box>
    </Card>
  )
}

export default PaymentBillet
