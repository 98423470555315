import constants from 'constants/index'

const convertSourceKind = (source) => {
  switch (source) {
    case constants.dataProcess.DEPARTMENT_SOURCE:
      return constants.dataProcess.SOURCE_DEPARTMENT_KIND
    case constants.dataProcess.THIRD_PARTY_SOURCE:
      return constants.dataProcess.SOURCE_THIRD_PARTY_KIND
    default:
      return constants.dataProcess.SOURCE_PUBLIC_KIND
  }
}

export default convertSourceKind
