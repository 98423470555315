import { colors } from '@material-ui/core'

const styles = (theme) => ({
  blueBox: {
    backgroundColor: theme.palette.primary.main,
    marginLeft: '0.5px',
  },
  circularProgress: {
    color: colors.common.white,
  },
  splintUp: {
    background: `linear-gradient(330deg, ${theme.palette.primary.main} 25%, transparent 25%, transparent)`,
    backgroundSize: '70px 20px',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
  },
  splintDown: {
    background: `linear-gradient(214deg, ${theme.palette.primary.main} 25%, transparent 25%, transparent)`,
    backgroundSize: '70px 20px',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
  },
  whiteTypography: {
    color: colors.common.white,
  },
  negativeMarginProgress: {
    marginLeft: -theme.spacing(9.5),
  },
})

export default styles
