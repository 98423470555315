import React from 'react'
import PropTypes from 'prop-types'

import { Grid, TableContainer, Box, Button } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton } from 'components'

const DocumentTable = ({ dataDocument, handleDelete }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Documentos</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataDocument.map((document, idx) => (
            <TableRow key={document?.name + idx}>
              <TableCell>{document?.name}</TableCell>
              <TableCell align="right">
                <Grid>
                  <Box display="flex" justifyContent="flex-end">
                    <MenuButton>
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() => handleDelete(document?.name)}
                      >
                        Remover
                      </Button>
                    </MenuButton>
                  </Box>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

DocumentTable.propTypes = {
  dataDocument: PropTypes.arrayOf(PropTypes.object),
  handleDelete: PropTypes.func,
}

DocumentTable.defaultProps = {
  dataDocument: [],
  handleDelete: () => {},
}

export default DocumentTable
