import React from 'react'

import { Grid, Box, Typography, Chip, Tooltip } from '@material-ui/core'

const LawsLists = ({ laws }) => {
  return (
    <Grid item xs={12}>
      <Box mt={2}>
        <Typography variant="subtitle2" color="textSecondary">
          Motivo (lei que obriga fazer o armazenamento permanente)
        </Typography>
        {laws.map((law) => (
          <Tooltip title={law?.name}>
            <Chip component={Box} m={0.5} maxWidth="100%" label={law?.name} />
          </Tooltip>
        ))}
      </Box>
    </Grid>
  )
}

export default LawsLists
