import constants from 'constants/index'

const label = (status) => {
  switch (status) {
    case constants.companySuppliers.REFUSED_STATUS:
      return constants.companySuppliers.REFUSED_LABEL
    case constants.companySuppliers.APPROVED_STATUS:
      return constants.companySuppliers.APPROVED_LABEL
    case constants.companySuppliers.INDICATED_STATUS:
      return constants.companySuppliers.INDICATED_LABEL
    case constants.companySuppliers.INACTIVE_STATUS:
      return constants.companySuppliers.INACTIVE_LABEL
    case constants.companySuppliers.SUGGESTED_STATUS:
      return constants.companySuppliers.SUGGESTED_LABEL
    case constants.companySuppliers.DISAPPROVED_STATUS:
      return constants.companySuppliers.DISAPPROVED_LABEL
    default:
      return '-'
  }
}
export default label
