import api from 'service/api'

const dponetAPI = api.create('dponet')

const listingByCompany = async (params) => {
  return await dponetAPI.get(`payment_orders/company`, { params })
}

const billet = async ({ paymentOrderId }) => {
  return await dponetAPI.get(`payment_orders/${paymentOrderId}/billet`)
}

const nfe = async ({ paymentOrderId }) => {
  return await dponetAPI.get(`payment_orders/${paymentOrderId}/nfe`)
}

export default {
  listingByCompany,
  billet,
  nfe,
}
