import React, { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import useSnackbar from 'hooks/useSnackbar'
import usePermissions from 'hooks/usePermissions'
import useAuth from 'hooks/useAuth'

import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Link,
  makeStyles,
} from '@material-ui/core'
import { PlusCircle } from 'react-feather'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import NonComplianceReportsActionModal from '../NonComplianceReportsActionModal'
import { MenuButton, DialogLogs } from 'components'
import DialogRemoveNonComplianceReportAction from '../DialogRemoveNonComplianceReportAction'

import * as service from 'service'

import useRncAction from 'hooks/useRncAction'

import helpers from 'helpers'
import constants from 'constants/index'
import styles from './styles'

const useStyles = makeStyles(styles)

const TableRowNonComplianceReportsAction = ({
  nonComplianceReportAction,
  nonComplianceReport,
  isLoading,
  viewOnly,
  dataProcessLogs,
  setDialogOpen = () => {},
  setNonComplianceReportAction = () => {},
  refresh = () => {},
  handleOpenEditDialog = () => {},
  handleOpenRemoveDialog = () => {},
  handleNonComplianceReportsActionsPermmited,
  user,
  ...rest
}) => {
  const [dialogLogs, setDialogLogs] = useState(false)
  const classes = useStyles()

  const showProcessHistoryLink = () => {
    return (
      nonComplianceReport.status ===
        constants.nonComplianceReports.FINALIZED_STATUS &&
      nonComplianceReport.entityType ===
        constants.nonComplianceReports.ENTITY_DATA_PROCESS
    )
  }

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableRow {...rest}>
          <TableCell>{nonComplianceReportAction?.id}</TableCell>
          <TableCell>
            {nonComplianceReportAction?.responsableUser?.name || '-'}
          </TableCell>
          <TableCell>
            {nonComplianceReportAction?.correctiveAction || '-'}
          </TableCell>
          <TableCell>
            {nonComplianceReportAction?.correctionEvidence || '-'}
            {showProcessHistoryLink() && (
              <>
                {' '}
                <Link
                  href="#"
                  className={classes.link}
                  onClick={() => setDialogLogs(true)}
                >
                  Clique aqui
                </Link>{' '}
                para visualizar o histórico de alterações realizadas no
                processo.
              </>
            )}
          </TableCell>
          <TableCell>
            {helpers.formatters.date(nonComplianceReportAction?.deadlineDate)}
          </TableCell>
          <TableCell align="right">
            {!viewOnly &&
              (handleNonComplianceReportsActionsPermmited ||
                nonComplianceReportAction?.responsableUser?.id === user.id) && (
                <MenuButton>
                  <Button
                    color="secondary"
                    fullWidth
                    onClick={() =>
                      handleOpenEditDialog(nonComplianceReportAction)
                    }
                  >
                    Editar
                  </Button>
                  <Button
                    color="secondary"
                    fullWidth
                    onClick={() =>
                      handleOpenRemoveDialog(nonComplianceReportAction)
                    }
                  >
                    Excluir
                  </Button>
                </MenuButton>
              )}
          </TableCell>
        </TableRow>
      )}
      <DialogLogs
        open={dialogLogs}
        setOpen={setDialogLogs}
        logs={dataProcessLogs}
      />
    </>
  )
}

const NonComplianceReportsActionTable = ({
  nonComplianceReportId,
  nonComplianceReport,
  isLoading,
  viewOnly,
}) => {
  const [edit, setEdit] = useState(false)
  const [nonComplianceReportAction, setNonComplianceReportAction] = useState({})
  const [nonComplianceReportActionModal, setNonComplianceReportActionModal] =
    useState(false)
  const [
    nonComplianceReportActionRemoveDialog,
    setNonComplianceReportActionRemoveDialog,
  ] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataProcessLogs, setDataProcessLogs] = useState({})
  const snackbar = useSnackbar()
  const { permitted } = usePermissions()
  const { user } = useAuth()

  useEffect(() => {
    const loadDataProcessLogs = async () => {
      let logs = await service.dponet.dataProcesses.logs({
        dataProcessId: nonComplianceReport?.entity?.dataProcess?.id,
      })

      setDataProcessLogs(logs?.data?.dponetAudits)
    }

    if (
      nonComplianceReport.entityType ===
      constants.nonComplianceReports.ENTITY_DATA_PROCESS
    ) {
      loadDataProcessLogs()
    }
  }, [nonComplianceReport])

  const handleNonComplianceReportsActionsPermmited = permitted(
    'handle_non_compliance_reports_actions',
  )

  const { data: rncActions, loadData: loadRncActions } = useRncAction()

  const onRefresh = async () => {
    await loadRncActions(nonComplianceReportId)
  }

  const handleRemove = async (
    nonComplianceReportId,
    nonComplianceReportActionId,
  ) => {
    try {
      setLoading(true)
      await service.dponet.nonComplianceReportActions.destroy({
        nonComplianceReportId,
        nonComplianceReportActionId,
      })
      setNonComplianceReportActionRemoveDialog(false)
      setLoading(false)
      snackbar.open({
        message: 'Plano de ação removido com sucesso!',
        variant: 'success',
      })

      onRefresh()
    } catch (err) {
      setLoading(false)
      snackbar.open({
        message: 'Não foi possível remover o Plano de ação. Tente novamente!',
        variant: 'error',
      })
    }
  }

  const handleOpenCreateDialog = () => {
    setNonComplianceReportAction({})
    setNonComplianceReportActionModal(true)
    setEdit(false)
  }

  const handleOpenEditDialog = (nonComplianceReportAction) => {
    setNonComplianceReportAction(nonComplianceReportAction)
    setNonComplianceReportActionModal(true)
    setEdit(true)
  }

  const handleOpenRemoveDialog = (nonComplianceReportAction) => {
    setNonComplianceReportAction(nonComplianceReportAction)
    setNonComplianceReportActionRemoveDialog(true)
  }

  const isResponsable = () => {
    return rncActions
      .map((rncAction) => {
        return rncAction?.responsableUser?.id
      })
      .includes(user.id)
  }

  return (
    <>
      {isLoading || loading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          {!viewOnly && handleNonComplianceReportsActionsPermmited && (
            <Box mt={3} mb={2}>
              <Button
                variant="outlined"
                startIcon={<PlusCircle size={20} />}
                htmlFor="rnc_action"
                component="label"
                onClick={() => handleOpenCreateDialog()}
                id={constants.incidents.EDIT_INCIDENT_DRIVER_STEP_5}
              >
                <Box mr={1}>
                  <Typography>Adicionar plano de ação</Typography>
                </Box>
              </Button>
            </Box>
          )}
          <PerfectScrollbar
            options={{ wheelPropagation: true, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhum plano de ação deste registro de melhoria contínua foi encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="8%">Identificador</TableCell>
                  <TableCell width="15%">Responsável</TableCell>
                  <TableCell width={viewOnly ? '33%' : '30%'}>
                    Ação corretiva
                  </TableCell>
                  <TableCell width={viewOnly ? '33%' : '30%'}>
                    Evidência da implantação
                  </TableCell>
                  <TableCell width="11%">Prazo</TableCell>
                  {!viewOnly &&
                    (handleNonComplianceReportsActionsPermmited ||
                      isResponsable()) && (
                      <TableCell width="6%" align="right">
                        Ações
                      </TableCell>
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rncActions?.map((nonComplianceReportAction) => (
                  <TableRowNonComplianceReportsAction
                    key={nonComplianceReportAction.id}
                    isLoading={loading || isLoading}
                    nonComplianceReportAction={nonComplianceReportAction}
                    setNonComplianceReportAction={setNonComplianceReportAction}
                    refresh={onRefresh}
                    viewOnly={viewOnly}
                    nonComplianceReport={nonComplianceReport}
                    handleOpenEditDialog={handleOpenEditDialog}
                    handleOpenRemoveDialog={handleOpenRemoveDialog}
                    handleNonComplianceReportsActionsPermmited={
                      handleNonComplianceReportsActionsPermmited
                    }
                    user={user}
                    dataProcessLogs={dataProcessLogs}
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}

      <NonComplianceReportsActionModal
        nonComplianceReportId={nonComplianceReportId}
        open={nonComplianceReportActionModal}
        setOpen={setNonComplianceReportActionModal}
        nonComplianceReportAction={nonComplianceReportAction}
        onEvent={onRefresh}
        edit={edit}
      />
      <DialogRemoveNonComplianceReportAction
        open={nonComplianceReportActionRemoveDialog}
        setOpen={setNonComplianceReportActionRemoveDialog}
        handleRemove={handleRemove}
        nonComplianceReportId={nonComplianceReportId}
        nonComplianceReportActionId={nonComplianceReportAction?.id}
      />
    </>
  )
}

export default NonComplianceReportsActionTable
