import * as yup from 'yup'

export const schemaSupplier = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
  document: yup.string().required(),
  telephone: yup.string().required(),
})

export default schemaSupplier
