import React from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'

import usePermissions from 'hooks/usePermissions'
import useAuth from 'hooks/useAuth'

import styles from './styles'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const HiringBanner = ({ showHiring }) => {
  const { isSupplier } = usePermissions()
  const { company } = useAuth()
  const history = useHistory()

  const classes = useStyles()
  const goToSubscription = () => {
    history.push({
      pathname: routes.subscription,
      search: `segment=${company?.segment?.name}&supplierToken=${company?.token}`,
    })
  }

  if (showHiring && isSupplier() && !!company?.segment && !!company?.token) {
    return (
      <Box className={classes.root} pb={4} pt={8}>
        <Box className={classes.imageContainer}>
          <Box className={classes.boxAction}>
            <Typography variant="body1">
              O que é o DPOnet e como ele pode deixar sua empresa adequada?
            </Typography>
            <Button
              className={classes.hireButton}
              variant="contained"
              onClick={goToSubscription}
              size="small"
            >
              ASSINAR O DPONET
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }

  return <></>
}

HiringBanner.propTypes = {
  showHiring: PropTypes.bool,
}

HiringBanner.defaultProps = {
  showHiring: true,
}

export default HiringBanner
