import React from 'react'
import { useLocation, Router } from 'react-router-dom'
import { ThemeProvider, withStyles, StylesProvider } from '@material-ui/styles'
import { createGenerateClassName } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import JssProvider from 'react-jss/lib/JssProvider'
import { Helmet } from 'react-helmet'

import 'moment/locale/pt-br'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'configs/yup/locale'
import 'driver.js/dist/driver.css'

import theme from 'theme'
import history from 'service/history'
import Routes from './Routes'
import { GoogleAnalyticsGlobalTag } from 'components'
import AuthProvider from 'providers/AuthProvider'
import SnackbarProvider from 'providers/SnackbarProvider'
import SelectCountryProvider from 'providers/SelectCountryProvider'
import CustomThemeProvider from 'providers/CustomThemeProvider'
import ContentHeaderProvider from 'providers/ContentHeaderProvider'

import { getApplicationUrl } from 'service/env'
import constants from 'constants/index'

const defaultGenerateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'c',
})

const App = () => (
  <>
    <Helmet>
      <link rel="canonical" href={getApplicationUrl()} />
      {process.env.REACT_APP_API === 'production' && (
        <script>{constants.googleTagManager.SCRIPT}</script>
      )}
    </Helmet>
    <JssProvider generateClassName={defaultGenerateClassName}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
          <StylesProvider injectFirst>
            <SnackbarProvider>
              <Router history={history}>
                <ScrollToTop />
                <AuthProvider>
                  <CustomThemeProvider>
                    <SelectCountryProvider>
                      <ContentHeaderProvider>
                        <Routes />
                      </ContentHeaderProvider>
                    </SelectCountryProvider>
                  </CustomThemeProvider>
                </AuthProvider>
              </Router>
            </SnackbarProvider>
          </StylesProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </JssProvider>
    <GoogleAnalyticsGlobalTag />
  </>
)

const styles = {
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      height: '100%',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
    },
    a: {
      textDecoration: 'none',
    },
    '#root': {
      height: '100%',
    },
  },
}

function ScrollToTop() {
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default withStyles(styles)(App)
