import constants from 'constants/index'

const discountCouponValue = (discountType, discountValue, price) => {
  switch (discountType) {
    case constants.discountCoupon.VALUE_TYPE:
      if (parseFloat(price) - parseFloat(discountValue) <= 0) {
        return 0
      }
      return parseFloat(price) - parseFloat(discountValue)
    case constants.discountCoupon.FIXED_PRICE_TYPE:
      return parseFloat(discountValue)
    default:
      return (
        parseFloat(price) - parseFloat(price) * (parseInt(discountValue) / 100)
      )
  }
}
export default discountCouponValue
