import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

const DialogConfirmAddDocument = ({
  open,
  setOpen = () => {},
  onChangeCreate = () => {},
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Adicionar documentos</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box my={1}>
        <DialogContent>
          <Box>
            <Typography>
              Você confirma que não existe nenhum dado pessoal no
              modelo/template selecionado? Lembre-se de que a existência de
              qualquer informação no documento é de responsabilidade do usuário.
            </Typography>
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => onChangeCreate()}
        >
          Confirmo
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogConfirmAddDocument
