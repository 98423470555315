import React, { useState } from 'react'
import clsx from 'clsx'

import { Box, Button, Dialog, Typography, Paper } from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'

import * as service from 'service'
import constants from 'constants/index'
import { routes } from 'Routes'

import helpers from 'helpers'
import { LoadingFeedback } from 'components'
import { SupplierAdequacy, SupplierForm } from '../../../'

import useStyle from './styles'

const RefusedModal = ({
  open,
  setOpen,
  client,
  company,
  supplierId,
  setRenderedContent,
}) => {
  const classes = useStyle()
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { mixpanel } = helpers

  const handleClose = () => setOpen(false)

  const approvedLink = async () => {
    mixpanel.track(
      'Jornada de Fornecedores',
      'Modal: Tem certeza que não reconhece esta empresa?',
      {
        action:
          'Reconhece_a_empresa-Modal_tem_certeza_que_não_reconhece_esta_empresa?',
      },
    )
    try {
      setLoading(true)
      await service.dponet.suppliers.approveOrRefuse({
        supplierId,
        status: constants.companySuppliers.APPROVED_STATUS,
      })
      if (company.kind !== constants.preRegistrations.SUPPLIER_KIND) {
        history.push(routes.questionnaire.all)
      } else {
        setLoading(false)
        setRenderedContent(
          <SupplierForm setRenderedContent={setRenderedContent} />,
        )
      }
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro ao confirmar o seu vínculo com o cliente',
        variant: 'error',
      })
    }
  }

  const declineLink = async () => {
    mixpanel.track(
      'Jornada de Fornecedores',
      'Modal: Tem certeza que não reconhece esta empresa?',
      {
        action:
          'Não_reconhece_a_empresa-Modal_tem_certeza_que_não_reconhece_esta_empresa?',
      },
    )
    try {
      setLoading(true)
      await service.dponet.suppliers.approveOrRefuse({
        supplierId,
        status: constants.companySuppliers.REFUSED_STATUS,
      })
      setLoading(false)
      setRenderedContent(
        <SupplierAdequacy status={constants.companySuppliers.REFUSED_STATUS} />,
      )
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro ao rejeitar o seu vínculo com o cliente',
        variant: 'error',
      })
    }
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="md"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <LoadingFeedback open={loading} />
      <Box m={4}>
        <Box display="flex" flexDirection="column">
          <Typography className={classes.title}>
            Tem certeza que não reconhece esta empresa?
            <br />
            <span className={classes.description}>"{client?.name}"</span>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Paper
            onClick={approvedLink}
            variant="outlined"
            component={Button}
            className={classes.button}
            fullWidth
          >
            Reconheço esta empresa
          </Paper>
          <Paper
            onClick={declineLink}
            variant="outlined"
            component={Button}
            className={clsx(classes.button, classes.outlined)}
            fullWidth
          >
            Não reconheço esta empresa
          </Paper>
        </Box>
      </Box>
    </Dialog>
  )
}

export default RefusedModal
