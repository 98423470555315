const styles = (theme) => ({
  root: {
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.white,
    cursor: 'pointer',
  },
  gridScroll: {
    [theme.breakpoints.up('md')]: { overflow: 'hidden' },
    [theme.breakpoints.down('xs')]: { width: 'calc(100% + 40px)' },
    overflowX: 'auto',
    overflowY: 'hidden',
    paddingBottom: 4,
  },
  selected: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.hover,
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    boxShadow:
      '0px 2px 2px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 2px 3px 0px rgb(0 0 0 / 12%)',
  },
  title: {
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
  },
  subTitle: {
    fontSize: '14px',
  },
})

export default styles
