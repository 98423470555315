import { makeStyles } from '@material-ui/core'
import helpers from 'helpers'

const useStyles = (status) =>
  makeStyles((theme) => ({
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    title: {
      textTransform: 'uppercase',
      paddingRight: 10,
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'row',
      gridGap: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gridGap: theme.spacing(3),
      },
    },
    boxCreatedBy: {
      width: 200,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    chip: {
      fontWeight: 600,
      textTransform: 'uppercase',
      color: theme.palette.common.white,
      backgroundColor: helpers.validates.consents.consentFormChipColor(status),
    },
  }))

export default useStyles
