import permissions from '../permissions'

const PROFILE_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.PROFILES.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.PROFILES.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.PROFILES.EDIT,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.ACTIVATE_OR_INACTIVATE,
    tag: permissions.PROFILES.CHANGE_STATUS,
  },
]

export default PROFILE_LIST
