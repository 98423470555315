import * as yup from 'yup'

import helpers from 'helpers'

export const schema = yup.object().shape({
  content: yup
    .string()
    .required()
    .test(
      'hasContent',
      'Preencha esse campo',
      helpers.validates.jodit.hasContent,
    ),
})

export default schema
