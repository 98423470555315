import React, { useEffect, useState } from 'react'
import { Box, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Controller } from 'react-hook-form'
import { orderBy, size, uniqBy } from 'lodash'

import { LoadingFeedback } from 'components'
import useFetch from 'hooks/useFetch'
import * as service from 'service'

const ConsentPeopleForm = ({ control, watch, errors, setConsentForms }) => {
  const [filteredData, setFilteredData] = useState([])
  const [inputEmail, setInputEmail] = useState('')
  const [triggerFetch, setTriggerFetch] = useState(false)

  const { response: responseFormAnswers, isLoading } = useFetch(
    service.dponet.consent.listReceipts,
    { userEmail: inputEmail },
    [],
    triggerFetch,
  )

  const consentFormAnswers = responseFormAnswers?.data?.consentFormAnswers

  const consentFormAnswer = watch('email')
  const userEmail = consentFormAnswer?.userEmail

  useEffect(() => {
    if (size(inputEmail) >= 3) {
      setTriggerFetch(true)
      setFilteredData(consentFormAnswers)
    } else {
      setFilteredData([])
      setTriggerFetch(false)
    }
  }, [inputEmail, consentFormAnswers])

  const handleEmailInput = (e) => {
    setInputEmail(e.target.value)
  }

  const { response: responseForm } = useFetch(
    service.dponet.consent.getStats,
    { userEmail },
    [consentFormAnswer],
    !!consentFormAnswer,
  )

  useEffect(() => {
    setConsentForms([])
    if (responseForm?.data?.consentForms) {
      setConsentForms(responseForm.data.consentForms)
    }
  }, [responseForm, setConsentForms])

  return (
    <>
      <LoadingFeedback open={isLoading && inputEmail.length >= 3} />
      <Box display="flex" width="100%" gridGap={6} paddingBottom={1}>
        <Box display="flex" flexDirection="column" width="100%">
          <Controller
            control={control}
            name="email"
            onChange={([, data]) => data}
            mode="onChange"
            as={
              <Autocomplete
                options={
                  orderBy(uniqBy(filteredData, 'userEmail'), 'userEmail') || []
                }
                getOptionLabel={(option) => option.userEmail || ''}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Digite o e-mail"
                    variant="outlined"
                    onChange={handleEmailInput}
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                  />
                )}
              />
            }
          />
        </Box>
      </Box>
    </>
  )
}

export default ConsentPeopleForm
