import React from 'react'
import { Box, Link, Typography } from '@material-ui/core'
import { Paperclip as PaperclipIcon } from 'react-feather'

import palette from 'theme/palette'

const MessageAttachment = ({ attachment }) => {
  return (
    <Box
      display="flex"
      gap={1}
      component={Link}
      color={palette.secondary.main}
      href={attachment.url}
      target="_blank"
      rel="noopener"
      download
    >
      <PaperclipIcon size={18} />
      <Typography variant="body2" noWrap>
        {attachment.name}
      </Typography>
    </Box>
  )
}

export default MessageAttachment
