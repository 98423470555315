import React from 'react'
import PropTypes from 'prop-types'

import { BaseDialog } from 'components'
import { Box, Typography } from '@material-ui/core'

import useStyles from './styles'

const ConfirmationDialog = ({
  open,
  setOpen,
  title,
  actionButtonText,
  handleSave,
  description,
}) => {
  const classes = useStyles()

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title={title}
      closeButtonText="Voltar"
      actionButtonText={actionButtonText}
      dialogSize="md"
      actionButton={handleSave}
      justifyActions="flex-end"
      fullWidth
    >
      <Box py={2}>
        <Typography variant="subtitle1" className={classes.textDescription}>
          {description}
        </Typography>
      </Box>
    </BaseDialog>
  )
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  title: PropTypes.string,
  actionButtonText: PropTypes.string,
  handleSave: PropTypes.func,
  description: PropTypes.string,
}

export default ConfirmationDialog
