const LGPD_ID = 1
const INFORMATION_SECURITY_ID = 2

const ALL_STATUS = null
const ACTIVE_STATUS = 'ACTIVE'
const INACTIVE_STATUS = 'INACTIVE'
const DRAFT_STATUS = 'DRAFT'

const TICKET_SUBJECT = [
  {
    id: 'Compartilhamento de dados pessoais',
    name: 'Compartilhamento de dados pessoais',
  },
  {
    id: 'Tratamentos de dados pessoais de crianças e adolescentes',
    name: 'Tratamentos de dados pessoais de crianças e adolescentes',
  },
  {
    id: 'Tratamento de dados pessoais via WhatsApp/telefone',
    name: 'Tratamento de dados pessoais via WhatsApp/telefone',
  },
  {
    id: 'Retirada/recebimento de documentos por terceiros',
    name: 'Retirada/recebimento de documentos por terceiros',
  },
  {
    id: 'Modelos da plataforma',
    name: 'Modelos da plataforma',
  },
  {
    id: 'Consentimento',
    name: 'Consentimento',
  },
  {
    id: 'Câmeras de segurança',
    name: 'Câmeras de segurança',
  },
  {
    id: 'Política de Privacidade/Aviso de Privacidade',
    name: 'Política de Privacidade/Aviso de Privacidade',
  },
  {
    id: 'Usabilidade da plataforma (mapeamento, emissão de relatórios)',
    name: 'Usabilidade da plataforma (mapeamento, emissão de relatórios)',
  },
]

const TECHNICAL_FAQS_MAIN_DRIVER_STEP_1 = 'technical-faqs-main-step-1'

const TECHNICAL_FAQS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + TECHNICAL_FAQS_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Oferecemos esta base de conhecimento para entender e aplicar corretamente a LGPD (Lei Geral de Proteção de Dados). Cada card oferece insights valiosos sobre conformidade, direitos individuais e procedimentos necessários para proteger dados pessoais. Ao selecionar um card, você pode aprofundar seu conhecimento sobre as melhores práticas de privacidade de dados, garantindo assim o cumprimento das regulamentações e a proteção dos dados de seus clientes e usuários.',
    },
  },
]

const TECHNICAL_FAQS_SI_MAIN_DRIVER_STEP_1 = 'technical-faqs-si-main-step-1'

const TECHNICAL_FAQS_SI_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + TECHNICAL_FAQS_SI_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Oferecemos esta base de conhecimento para entender e explorar a Segurança da Informação. Cada card representa um aspecto crucial deste campo, desde práticas de proteção de dados até estratégias de prevenção de ataques cibernéticos.  Selecione um card, descubra novos insights e esclareça suas dúvidas, fortalecendo assim sua compreensão e práticas em Segurança da Informação.',
    },
  },
]

const TECHNICAL_FAQS_TICKETS_MAIN_DRIVER_STEP_1 =
  'technical-faqs-tickets-main-step-1'

const TECHNICAL_FAQS_TICKTES_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + TECHNICAL_FAQS_TICKETS_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Acesse facilmente todas as solicitações da Central de Privacidade. Utilize os filtros disponíveis para organizar por status e acompanhar o progresso de forma eficiente e organizada.',
    },
  },
]

const VISIBILITY_TYPE = [
  {
    id: 'private_type',
    name: 'Privado',
  },
  {
    id: 'organization',
    name: 'Organizacional',
  },
]

const technicalFaqs = {
  ACTIVE_STATUS,
  ALL_STATUS,
  DRAFT_STATUS,
  INACTIVE_STATUS,
  INFORMATION_SECURITY_ID,
  LGPD_ID,
  TICKET_SUBJECT,
  TECHNICAL_FAQS_DRIVER_STEPS_OBJECT,
  TECHNICAL_FAQS_MAIN_DRIVER_STEP_1,
  TECHNICAL_FAQS_SI_DRIVER_STEPS_OBJECT,
  TECHNICAL_FAQS_SI_MAIN_DRIVER_STEP_1,
  TECHNICAL_FAQS_TICKTES_DRIVER_STEPS_OBJECT,
  TECHNICAL_FAQS_TICKETS_MAIN_DRIVER_STEP_1,
  VISIBILITY_TYPE,
}

export default technicalFaqs
