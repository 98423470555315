import React, { useState, useEffect } from 'react'

import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'

import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'

import {
  Grid,
  TextField,
  Box,
  MenuItem,
  Typography,
  Button,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'

import { Label } from 'components'

import NonComplianceReportsActionSchema from './schemas'

import * as service from 'service'
import moment from 'moment'

import helpers from 'helpers'

const FormModal = ({
  nonComplianceReportId,
  nonComplianceReportAction,
  users,
  listUserPermitted,
  handleCloseModal = () => {},
  submitSuccess = () => {},
}) => {
  const snackbar = useSnackbar()
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const [minDate, setMinDate] = useState()

  const { handleSubmit, errors, control, reset, getValues } = useForm({
    validationSchema: NonComplianceReportsActionSchema,
    defaultValues: {
      nonComplianceReportId: nonComplianceReportId,
      deadlineDate: nonComplianceReportAction?.deadlineDate || '',
      responsableUserId: nonComplianceReportAction?.responsableUser
        ? nonComplianceReportAction?.responsableUser?.id
        : listUserPermitted
        ? ''
        : user?.id,
      correctiveAction: nonComplianceReportAction?.correctiveAction || '',
      correctionEvidence: nonComplianceReportAction?.correctionEvidence || '',
    },
  })

  useEffect(() => {
    if (nonComplianceReportAction?.deadlineDate) {
      setMinDate(
        moment(nonComplianceReportAction?.deadlineDate)
          .utc()
          .format('YYYY-MM-DD'),
      )
    } else {
      setMinDate(moment(new Date()).format('YYYY-MM-DD'))
    }

    // eslint-disable-next-line
  }, [])

  const onSubmit = async (data) => {
    if (nonComplianceReportAction?.id) {
      try {
        setLoading(true)
        if (data?.deadlineDate) {
          data.deadlineDate = helpers.formatters.date(data?.deadlineDate)
        }
        await service.dponet.nonComplianceReportActions.update({
          nonComplianceReportId,
          nonComplianceReportActionId: nonComplianceReportAction?.id,
          ...{ nonComplianceReportAction: data },
        })
        setLoading(false)
        snackbar.open({
          message: 'Plano de ação editado com sucesso',
          variant: 'success',
        })
        await submitSuccess()
      } catch (error) {
        setLoading(false)
        reset(getValues())
        snackbar.open({
          message: helpers.formatters.errorMessage(
            error?.response?.data?.error,
          ),
          variant: 'error',
        })
      }
    } else {
      try {
        setLoading(true)
        if (data?.deadlineDate) {
          data.deadlineDate = helpers.formatters.date(data?.deadlineDate)
        }
        await service.dponet.nonComplianceReportActions.create({
          nonComplianceReportId,
          ...{ nonComplianceReportAction: data },
        })
        setLoading(false)
        snackbar.open({
          message: 'Plano de ação criado com sucesso',
          variant: 'success',
        })
        await submitSuccess()
      } catch (error) {
        setLoading(false)
        reset(getValues())
        snackbar.open({
          message: helpers.formatters.errorMessage(
            error?.response?.data?.error,
          ),
          variant: 'error',
        })
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Controller
            as={
              <DatePicker
                fullWidth
                format="DD/MM/yyyy"
                label="Prazo"
                helperText={errors?.deadlineDate?.message}
                error={!!errors.deadlineDate}
                minDateMessage="O prazo não pode estar no passado"
                inputVariant="outlined"
                onChange={(newValue) => ({ value: newValue })}
                minDate={minDate}
              />
            }
            control={control}
            name="deadlineDate"
            mode="onChange"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Controller
            as={
              <TextField
                select
                color="primary"
                variant="outlined"
                error={!!errors.responsableUserId}
                helperText={errors?.responsableUserId?.message}
                label="Responsável"
                disabled={!listUserPermitted}
                fullWidth
              >
                {listUserPermitted ? (
                  users?.map((userRow) => (
                    <MenuItem key={userRow?.id} value={userRow?.id}>
                      <Typography>{userRow?.name}</Typography>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={user?.id} value={user?.id}>
                    <Typography>{user?.name}</Typography>
                  </MenuItem>
                )}
              </TextField>
            }
            name="responsableUserId"
            control={control}
            mode="onChange"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box mt={1}>
            <Label title="Ação corretiva" description="O que será feito" />
          </Box>
          <Controller
            as={
              <TextField
                fullWidth
                multiline
                error={!!errors.correctiveAction}
                helperText={errors?.correctiveAction?.message}
                rows={8}
              />
            }
            control={control}
            name="correctiveAction"
            mode="onBlur"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box mt={1}>
            <Label
              title="Evidência da implantação"
              description="O que foi feito"
            />
          </Box>
          <Controller
            as={
              <TextField
                fullWidth
                multiline
                error={!!errors.correctionEvidence}
                helperText={errors?.correctionEvidence?.message}
                rows={8}
              />
            }
            control={control}
            name="correctionEvidence"
            mode="onBlur"
          />
        </Grid>
        <Controller
          as={<></>}
          control={control}
          name="nonComplianceReportId"
          mode="onChange"
        />
      </Grid>
      <Box display="flex" my={2}>
        <Box pr={1}>
          <Button
            color="secondary"
            type="button"
            variant="outlined"
            onClick={() => handleCloseModal()}
          >
            Voltar
          </Button>
        </Box>
        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
        >
          {loading ? 'Aguarde...' : 'Salvar'}
        </Button>
      </Box>
    </form>
  )
}

export default FormModal
