import axios from 'axios'
import api from 'service/api'
import * as service from 'service'
import { getBaseURL } from '../../env'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

const get = async ({ supplierId = '', ...params }) => {
  return await dponetAPI.get(`/company_suppliers/${supplierId}`, { params })
}

const logs = async ({ supplierId = '', ...params }) => {
  return await dponetAPI.get(`/company_suppliers/${supplierId}/logs`, {
    params,
  })
}

const mailLogs = async ({ supplierId = '', ...params }) => {
  return await dponetAPI.get(`/mail_logs`, {
    params,
  })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/company_suppliers/', data)
}

const importSuppliers = async ({
  fileToImport,
  international,
  dateToSendEmails,
  questionnaireKinds,
  supplierInvite,
}) => {
  var formData = new FormData()

  formData.append('file_to_import', fileToImport)
  formData.append('international', international)
  formData.append('date_to_send_emails', dateToSendEmails)
  formData.append('questionnaire_kinds', questionnaireKinds)
  formData.append('supplier_invite', supplierInvite)

  const config = {
    headers: {
      'Content-Type': fileToImport.type,
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/company_suppliers/import_suppliers`,
    data: formData,
    ...config,
  })
}

const put = async ({ supplierId = '', ...params }) => {
  return await dponetAPI.put(`/company_suppliers/${supplierId}`, params)
}

const approveOrRefuse = async ({ supplierId = '', ...params }) => {
  return await dponetAPI.put(
    `/company_suppliers/${supplierId}/approve_or_refuse`,
    params,
  )
}

const supplierIndex = async ({ ...params }) => {
  return await dponetAPI.get('/company_suppliers/iam_supplier/', { params })
}

const clientIndex = async ({ ...params }) => {
  return await dponetAPI.get('/company_suppliers/iam_client/', { params })
}

const supplierQuestionnaires = async ({ supplierId }) => {
  return await dponetAPI.get(
    `/company_suppliers/${supplierId}/supplier_questionnaires`,
  )
}

const resendSolicitation = async ({ supplierId, ...data }) => {
  return await dponetAPI.post(
    `/company_suppliers/${supplierId}/resend_solicitation`,
    data,
  )
}

const inactivate = async ({ supplierId }) => {
  return await dponetAPI.post(`/company_suppliers/${supplierId}/inactivate`)
}

const softDelete = async ({ supplierId }) => {
  return await dponetAPI.delete(`/company_suppliers/${supplierId}`)
}

export default {
  approveOrRefuse,
  clientIndex,
  create,
  get,
  inactivate,
  logs,
  mailLogs,
  put,
  resendSolicitation,
  softDelete,
  supplierIndex,
  supplierQuestionnaires,
  importSuppliers,
}
