import React from 'react'

import { BaseDialog, Label } from 'components'
import { Grid } from '@material-ui/core'
import formatters from 'helpers/formatters'

const ViewContract = ({ contract, onClose }) => {
  return (
    <BaseDialog
      open={!!contract}
      setOpen={onClose}
      title={`Contrato nº ${contract?.contractIdentifier}`}
      closeButtonText="Voltar"
    >
      <Grid container>
        <Label
          item
          xs={12}
          title="Identificador"
          description={contract?.identifier}
        />
        <Label
          item
          xs={12}
          md={6}
          title="Situação do contrato"
          description={contract?.kind}
        />
        <Label
          item
          xs={12}
          md={6}
          title="Meio de pagamento"
          description={
            contract?.methodPayment === 'billet' ? 'Boleto' : 'Cartão'
          }
        />
        <Label
          item
          xs={12}
          md={6}
          title="Valor"
          description={
            formatters.numberToPrice(parseFloat(contract?.price ?? 0)) + ' /mẽs'
          }
        />
        <Label
          item
          xs={12}
          md={6}
          title="Pŕoxima cobrança"
          description={formatters.date(contract?.nextDateAdvance)}
        />
        {contract?.methodPayment === 'billet' && (
          <>
            <Label
              item
              xs={12}
              md={6}
              title="Vencimento da próxima cobrança"
              description={formatters.date(contract?.nextBillingDate)}
            />
            <Label
              item
              xs={12}
              title="E-mails de notificação de cobranças"
              description={contract?.billingEmails
                ?.map((billingEmail) => billingEmail?.email)
                .join(', ')}
            />
          </>
        )}
      </Grid>
    </BaseDialog>
  )
}

export default ViewContract
