import { lightBlue } from '@material-ui/core/colors'

const styles = (theme) => ({
  internationalTransferField: {
    backgroundColor: lightBlue[50],
    borderRadius: theme.spacing(0.5),
  },
  inputBackground: {
    backgroundColor: theme.palette.white,
  },
  removeButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
})

export default styles
