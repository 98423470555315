import regex from 'helpers/regex'

const creditCardNumber = (cardNumber) => {
  return regex
    .onlyNumbers(cardNumber)
    ?.replace(/(\d{4})(?=\d)/g, '$1 ')
    .slice(0, 23)
}

export default creditCardNumber
