import React from 'react'

import { BarChart, DashboardChartPaper } from 'components'

import { routes } from 'Routes'
import theme from 'theme'
import helpers from 'helpers'

const SecurityMeasuresStatusByCategory = ({
  questionControlStats,
  id,
  ...rest
}) => {
  const statusesByCategory = questionControlStats?.statusByCategory

  const kinds = statusesByCategory?.map((category) => category.kind)

  const adoptedValues = helpers.myLgpd.questionControlStatsByStatus(
    statusesByCategory,
    'adopted',
  )

  const treatedValues = helpers.myLgpd.questionControlStatsByStatus(
    statusesByCategory,
    'treated',
  )

  const inProgressValues = helpers.myLgpd.questionControlStatsByStatus(
    statusesByCategory,
    'inProgress',
  )

  const pendingValues = helpers.myLgpd.questionControlStatsByStatus(
    statusesByCategory,
    'pending',
  )

  const overdueValues = helpers.myLgpd.questionControlStatsByStatus(
    statusesByCategory,
    'overdue',
  )

  const riskAssumedValues = helpers.myLgpd.questionControlStatsByStatus(
    statusesByCategory,
    'riskAssumed',
  )

  return (
    <DashboardChartPaper
      title="Status de medidas por categoria"
      subtitle="Adotada, em andamento, pendente, atrasada, risco assumido"
      redirect={routes.questionControls.all}
      {...rest}
    >
      <BarChart
        id={id}
        names={kinds}
        series={[
          { name: 'Adotado', data: adoptedValues || [] },
          { name: 'Tratado', data: treatedValues || [] },
          { name: 'Em Andamento', data: inProgressValues || [] },
          { name: 'Pendente', data: pendingValues || [] },
          { name: 'Atrasado', data: overdueValues || [] },
          { name: 'Risco Assumido', data: riskAssumedValues || [] },
        ]}
        heightValue={250}
        colors={[
          theme.palette.dashboard.chart.measures.adopted,
          theme.palette.dashboard.chart.measures.treated,
          theme.palette.dashboard.chart.measures.inProgress,
          theme.palette.dashboard.chart.measures.pending,
          theme.palette.dashboard.chart.measures.overdue,
          theme.palette.dashboard.chart.measures.riskAssumed,
          theme.palette.dashboard.chart.measures.inactive,
        ]}
        showLabels
      />
    </DashboardChartPaper>
  )
}

export default SecurityMeasuresStatusByCategory
