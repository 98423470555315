import React from 'react'

import { Grid, Box } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@material-ui/pickers'

import { Label, Select } from 'components'

import useFetch from 'hooks/useFetch'
import * as service from 'service'

const ResponsibleAndDate = ({ control, errors, isDisabled }) => {
  const { response } = useFetch(service.dponet.users.get, {
    perPage: 10000,
    minimal: true,
  })

  return (
    <Grid item xs={12}>
      <Box m={(2, 2, 0, 2)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Label title="Responsável *" marginTopChildren={0}>
              <Controller
                as={
                  <Select
                    items={response?.data?.users || []}
                    error={!!errors.responsibleUserId}
                    helperText={errors?.responsibleUserId?.message}
                    fullWidth
                    disabled={isDisabled}
                  />
                }
                control={control}
                name="responsibleUserId"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={3}>
            <Label title="Início *" marginTopChildren={0}>
              <Controller
                as={
                  <DatePicker
                    fullWidth
                    format="DD/MM/yyyy"
                    helperText={errors?.startDate?.message}
                    error={!!errors.startDate}
                    inputVariant="outlined"
                    onChange={(newValue) => ({ value: newValue })}
                    disabled={isDisabled}
                  />
                }
                control={control}
                name="startDate"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={3}>
            <Label title="Prazo final *" marginTopChildren={0}>
              <Controller
                as={
                  <DatePicker
                    fullWidth
                    format="DD/MM/yyyy"
                    helperText={errors?.deadlineDate?.message}
                    error={!!errors?.deadlineDate}
                    inputVariant="outlined"
                    onChange={(newValue) => ({ value: newValue })}
                    disabled={isDisabled}
                  />
                }
                control={control}
                name="deadlineDate"
                mode="onBlur"
              />
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default ResponsibleAndDate
