import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

const DialogRemoveDataCollected = ({
  dataProcessId,
  dataCollectedId,
  open,
  setOpen = () => {},
  handleRemove = () => {},
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Remover dado tratado</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box mt={1} mb={1}>
        <DialogContent>
          <Box>
            <Typography>
              Tem certeza que deseja remover este dado tratado?
            </Typography>
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => handleRemove(dataProcessId, dataCollectedId)}
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogRemoveDataCollected
