import React from 'react'
import constants from 'constants/index'
import { isEmpty } from 'lodash'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { Trash as TrashIcon } from 'react-feather'

import helpers from 'helpers'
import styles from './styles'

const useStyles = makeStyles(styles)

const Icon = ({ type, ...rest }) => {
  const Icon = helpers.dataProcess.iconDataProcessSources(type)
  const isMaterial = type === constants.dataProcess.SOURCE_THIRD_PARTY_KIND

  return (
    <Icon
      {...(isMaterial && { color: 'secondary', fontSize: 'small' })}
      {...rest}
    />
  )
}

const DataProcessSourcesTable = ({
  dataProcessSources,
  removeOption,
  type,
}) => {
  const classes = useStyles()
  return (
    <>
      {!isEmpty(dataProcessSources) && (
        <List>
          {dataProcessSources.map((dataProcessSource) => (
            <Box
              width="100%"
              className={classes.listItemGutters}
              key={dataProcessSource?.entityId || dataProcessSource?.id}
            >
              <ListItem>
                <ListItemIcon>
                  <Box className={classes.iconBackground} ml={1} mr={3}>
                    <Icon type={type} size="18px" />
                  </Box>
                </ListItemIcon>
                <ListItemText
                  primary={
                    dataProcessSource?.entityName || dataProcessSource?.name
                  }
                />
                {removeOption && (
                  <ListItemIcon>
                    <IconButton onClick={() => removeOption(dataProcessSource)}>
                      <TrashIcon size={18} />
                    </IconButton>
                  </ListItemIcon>
                )}
              </ListItem>
            </Box>
          ))}
        </List>
      )}
    </>
  )
}

DataProcessSourcesTable.propTypes = {
  dataProcessSources: PropTypes.array.isRequired,
  removeOption: PropTypes.func,
  type: PropTypes.string,
}

export default DataProcessSourcesTable
