import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { Box, Paper, Typography } from '@material-ui/core'

import useStyles from './styles'

const DashboardTablePaper = ({ children, title, tableType, ...rest }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} component={Box} {...rest}>
      <Box className={classes.cardHeader}>
        <Typography variant="h5" className={classes.cardTitle}>
          {title}
        </Typography>
      </Box>
      <PerfectScrollbar
        className={classes.perfectScrollBar}
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Box className={classes.childrenBox}>{children}</Box>
      </PerfectScrollbar>
    </Paper>
  )
}

DashboardTablePaper.propTypes = {
  title: PropTypes.string,
  tableType: PropTypes.oneOf(['questionnaire', 'process', 'incident']),
}

export default DashboardTablePaper
