import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  rowText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0em',
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0em',
  },
  adopted: {
    borderRadius: '2px',
    backgroundColor: colors.green[100],
    color: colors.green[900],
    fontWeight: 400,
    height: '21px',
    width: 100,
  },
  pending: {
    borderRadius: '2px',
    backgroundColor: colors.orange[100],
    color: colors.orange[900],
    fontWeight: 400,
    height: '21px',
    width: 100,
  },
  inProgress: {
    borderRadius: '2px',
    backgroundColor: colors.blue[100],
    color: colors.blue[900],
    fontWeight: 400,
    height: '21px',
    width: 100,
  },
  overdue: {
    borderRadius: '2px',
    backgroundColor: colors.red[100],
    color: colors.red[900],
    fontWeight: 400,
    height: '21px',
    width: 100,
  },
  riskAssumed: {
    borderRadius: '2px',
    backgroundColor: colors.purple[100],
    color: colors.purple[900],
    fontWeight: 400,
    height: '21px',
    width: 100,
  },
  treated: {
    borderRadius: '2px',
    backgroundColor: colors.green[500],
    color: colors.common.white,
    fontWeight: 400,
    height: '21px',
    width: 100,
  },
}))

export default styles
