import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

import { ButtonAction } from 'components'

const ConfirmationDialog = ({
  open,
  setOpen = () => {},
  handleConfirm = () => {},
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box>
          <Typography variant="h4">
            Você confirma o cadastro do processamento de dados?
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box mt={1} mb={1}>
        <DialogContent>
          <Box>
            <Typography>
              Você confirma que revisou as informações de acordo com o processo
              de sua empresa? Após a confirmação, o processo será enviado para
              análise jurídica, e terá seu status alterado para "Aguardando
              revisão".
            </Typography>
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Voltar e revisar
          </Button>
        </Box>
        <ButtonAction
          variant="contained"
          type="submit"
          color="primary"
          action={handleConfirm}
          name="Confirmar"
        />
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
