import React, { useState, useEffect } from 'react'
import {
  TextField,
  Grid,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
} from '@material-ui/core'

import { useForm, Controller } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'

import { Label, OptionCheckIncident, LoadingFeedback } from 'components'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'

const RiskConsequence = ({ idForm, incident, handleNext, stepName }) => {
  const [loading, setLoading] = useState(false)
  const [selectedRipd, setSelectedRipd] = useState(
    incident?.incidentRiskDataHolder?.ripdPreparedIncident + '' ?? '',
  )
  const [selectedImpact, setSelectedImpact] = useState(
    incident?.incidentRiskDataHolder?.optionImpactIncidentHolder ?? '',
  )

  const snackbar = useSnackbar()

  const isCheckedRipd = (value) => selectedRipd === value
  const isCheckedImpact = (value) => selectedImpact === value

  const { handleSubmit, control, setValue, reset, getValues, watch } = useForm({
    defaultValues: {
      ripdPreparedIncident:
        incident?.incidentRiskDataHolder?.ripdPreparedIncident + '' ?? '',
      totalDataHolderTreated:
        incident?.incidentRiskDataHolder?.totalDataHolderTreated ?? '',
      totalHolderAffected:
        incident?.incidentRiskDataHolder?.totalHolderAffected ?? '',
      monorAffected: incident?.incidentRiskDataHolder?.monorAffected ?? '',
      otherVulnerableAffecte:
        incident?.incidentRiskDataHolder?.otherVulnerableAffecte ?? '',
      descriptionHolderCategoryAffected:
        incident?.incidentRiskDataHolder?.descriptionHolderCategoryAffected ??
        '',
      descriptionHolderCategoryAmountAffected:
        incident?.incidentRiskDataHolder
          ?.descriptionHolderCategoryAmountAffected ?? '',
      descriptionPossibleConsequenceGroupHolder:
        incident?.incidentRiskDataHolder
          ?.descriptionPossibleConsequenceGroupHolder ?? '',
      optionImpactIncidentHolder:
        incident?.incidentRiskDataHolder?.optionImpactIncidentHolder ?? '',
      measuresAdoptedMitigateIncidentHolder:
        incident?.incidentRiskDataHolder
          ?.measuresAdoptedMitigateIncidentHolder ?? '',
      probableConsequenceIncidentHolder:
        incident?.dataOptionIncidents?.probableConsequenceIncidentHolder || [],
      probableConsequenceIncidentHolderOther: helpers.incidents.otherOption(
        incident?.dataOptionIncidents?.probableConsequenceIncidentHolder,
      ),
      categoryHolderAffectedIncident:
        incident?.dataOptionIncidents?.categoryHolderAffectedIncident || [],
      categoryHolderAffectedIncidentOther: helpers.incidents.otherOption(
        incident?.dataOptionIncidents?.categoryHolderAffectedIncident,
      ),
    },
  })

  useEffect(() => {
    setValue('ripdPreparedIncident', selectedRipd)
  }, [selectedRipd, setValue])

  useEffect(() => {
    setValue('optionImpactIncidentHolder', selectedImpact)
  }, [selectedImpact, setValue])

  const { response, isLoading } = useFetch(
    service.dponet.incidents.options,
    {
      typeFields: [
        'probable_consequence_incident_holder',
        'category_holder_affected_incident',
      ],
    },
    [],
  )

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      await service.dponet.incidents.addStepRisk({
        incidentId: incident?.id,
        incident: data,
      })
      setLoading(false)
      handleNext()
      snackbar.open({
        message: `${stepName} atualizada com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: `Ocorreu um erro ao salvar a ${stepName}!`,
        variant: 'error',
      })
      setLoading(false)
      reset(getValues())
    }
  }

  return (
    <>
      <LoadingFeedback open={loading || isLoading} />
      <form onSubmit={handleSubmit(onSubmit)} id={idForm}>
        <Box px={2} pb={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box mt={2} px={1} pt={1} style={{ backgroundColor: '#F4F6F8' }}>
                <Typography variant="body1" style={{ fontWeight: '900' }}>
                  Foi elaborado um relatório de impacto à proteção de dados
                  pessoais (RIPD) das atividades de tratamento afetadas pelo
                  incidente?
                </Typography>
                <FormControl size="small" variant="outlined">
                  <FormGroup row>
                    {constants.incidents.COMMUNICATION_REQUIRED?.map(
                      (option) => {
                        return (
                          <FormControlLabel
                            control={
                              <Controller
                                name="ripdPreparedIncident"
                                onChange={() => setSelectedRipd(option?.id)}
                                as={
                                  <Radio
                                    size="medium"
                                    checked={isCheckedRipd(option?.id)}
                                  />
                                }
                                control={control}
                              />
                            }
                            label={option?.name}
                            key={option?.id}
                          />
                        )
                      },
                    )}
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Label title="Qual a quantidade total de titulares cujos dados são tratados nas atividades afetadas pelo inicidente?">
                <Controller
                  as={
                    <TextField
                      inputProps={{
                        min: 0,
                      }}
                      label="Total de titulares afetados"
                      type="number"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    />
                  }
                  control={control}
                  name="totalHolderAffected"
                  mode="onBlur"
                />
                <Box my={2}>
                  <Grid item xs={12}>
                    <Controller
                      as={
                        <TextField
                          inputProps={{
                            min: 0,
                          }}
                          label="Crianças e/ou adolescentes"
                          type="number"
                          color="primary"
                          variant="outlined"
                          fullWidth
                        />
                      }
                      control={control}
                      name="monorAffected"
                      mode="onBlur"
                    />
                  </Grid>
                </Box>
                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        inputProps={{
                          min: 0,
                        }}
                        label="Outros titualres"
                        type="number"
                        color="primary"
                        variant="outlined"
                        fullWidth
                      />
                    }
                    control={control}
                    name="otherVulnerableAffecte"
                    mode="onBlur"
                  />
                </Grid>
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Box mt={1}>
                <Label title="Se aplicável, descreva as categorias de titulares vulneráveis afetados:">
                  <Controller
                    as={
                      <TextField
                        type="text"
                        color="primary"
                        variant="outlined"
                        fullWidth
                      />
                    }
                    control={control}
                    name="descriptionHolderCategoryAffected"
                    mode="onBlur"
                  />
                </Label>
              </Box>
            </Grid>
            <OptionCheckIncident
              variantLabel="h6"
              label="Quais as categorias de titulares foram afetadas pelo incidente?"
              control={control}
              options={response?.data?.categoryHolderAffectedIncident}
              checkedOptions={
                incident?.dataOptionIncidents?.categoryHolderAffectedIncident
              }
              name="categoryHolderAffectedIncident"
              literaName="category_holder_affected_incident"
              setValue={setValue}
              watch={watch}
              other
            />
            <Grid item xs={12}>
              <Label title="Informe a quantidade de titulares afetados, por categoria:">
                <Controller
                  as={
                    <TextField
                      multiline
                      rows={5}
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    />
                  }
                  control={control}
                  name="descriptionHolderCategoryAmountAffected"
                  mode="onBlur"
                />
              </Label>
            </Grid>
            <OptionCheckIncident
              variantLabel="h6"
              label="Quais as prováveis consequências do incidete para os titulares?"
              control={control}
              options={response?.data?.probableConsequenceIncidentHolder}
              checkedOptions={
                incident?.dataOptionIncidents?.probableConsequenceIncidentHolder
              }
              name="probableConsequenceIncidentHolder"
              setValue={setValue}
              literaName="probable_consequence_incident_holder"
              watch={watch}
              other
            />
            <Grid item xs={12}>
              <Label title="Se cabível, descreva as prováveis consequências do incidente para cada grupo de titulares:">
                <Grid item xs={12}>
                  <Box
                    mb={2}
                    p={2}
                    style={{
                      backgroundColor: '#F4F6F8',
                      border: '1px solid #E8E6E6',
                    }}
                  >
                    <Typography variant="body1">
                      Titular afetado é aquele cujos dados podem ter tido a
                      confidencialidade, integridade ou disponibilidade violadas
                      e que ficará exposto a novos riscos relevantes em razão do
                      incidente.
                    </Typography>
                  </Box>
                </Grid>
                <Controller
                  as={
                    <TextField
                      multiline
                      rows={5}
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    />
                  }
                  control={control}
                  name="descriptionPossibleConsequenceGroupHolder"
                  mode="onBlur"
                />
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Box py={2}>
                <Label title="Qual o provável impacto do incidente sobre os titulares? (admite só uma marcação)">
                  <FormControl size="small" variant="outlined">
                    <FormGroup>
                      {constants.incidents.IMPACT_HOLDER.map((option) => {
                        return (
                          <FormControlLabel
                            control={
                              <Controller
                                name="optionImpactIncidentHolder"
                                onChange={() => setSelectedImpact(option?.id)}
                                as={
                                  <Radio
                                    size="medium"
                                    checked={isCheckedImpact(option?.id)}
                                  />
                                }
                                control={control}
                              />
                            }
                            label={option?.name}
                            key={option?.id}
                          />
                        )
                      })}
                    </FormGroup>
                  </FormControl>
                </Label>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Label title="Se cabível, quais medidas foram adotadas para mitigação dos riscos causados pelo incidente aos titulares?">
                <Controller
                  as={
                    <TextField
                      multiline
                      rows={5}
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    />
                  }
                  control={control}
                  name="measuresAdoptedMitigateIncidentHolder"
                  mode="onBlur"
                />
              </Label>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  )
}

export default RiskConsequence
