import React from 'react'
import PropTypes from 'prop-types'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { Box, Chip, CircularProgress, Typography } from '@material-ui/core'

import constants from 'constants/index'
import helpers from 'helpers'

import useStyles from './styles'

const TableRowOrder = ({ order, ...rest }) => {
  const classes = useStyles()

  return (
    <TableRow {...rest}>
      <TableCell>{order?.id}</TableCell>
      <TableCell>
        <Typography>{order?.partnerService?.companyService?.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{order?.partnerService?.partner?.name}</Typography>
      </TableCell>
      <TableCell>
        {helpers.formatters.dateLocaleFormat(
          order?.createdAt,
          'DD/MM/YYYY - HH:mm:ss',
        )}
      </TableCell>
      <TableCell>
        <Chip
          size="small"
          label={helpers.orders.statusLabel(order?.status)}
          className={helpers.orders.chipStatusColor(order?.status, classes)}
          id={constants.order.ORDER_DRIVER_STATUS_ID[order?.status]}
        />
      </TableCell>
    </TableRow>
  )
}

TableRowOrder.propTypes = {
  order: PropTypes.object,
}

const OrdersTable = ({ orders, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhum pedido encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="10%">Identificador</TableCell>
                  <TableCell width="30%">Serviço</TableCell>
                  <TableCell width="30%">Parceiro</TableCell>
                  <TableCell width="20%">Data</TableCell>
                  <TableCell width="10%">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders?.map((order) => (
                  <TableRowOrder order={order} key={order.id} hover />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}

OrdersTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
}

export default OrdersTable
