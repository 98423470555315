const DRAFT_STATUS = 1
const ACTIVE_STATUS = 2
const INACTIVE_STATUS = 3

const PRIVACY_POLICY_MAIN_DRIVER_STEP_1 = 'privacy-policy-main-driver-step-1'
const PRIVACY_POLICY_MAIN_DRIVER_STEP_2 = 'privacy-policy-main-driver-step-2'
const PRIVACY_POLICY_MAIN_DRIVER_STEP_3 = 'privacy-policy-main-driver-step-3'

const PRIVACY_POLICY_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + PRIVACY_POLICY_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'O recurso de políticas de privacidade na plataforma é crucial para estabelecer diretrizes internas de tratamento de dados pessoais. Ao acessar esta funcionalidade, os usuários podem criar e manter políticas que orientam os colaboradores sobre como lidar com informações sensíveis. Além disso, a funcionalidade permite a inclusão de avisos de privacidade direcionados aos titulares de dados, promovendo transparência e conformidade com as regulamentações de proteção de dados.',
    },
  },
  {
    element: '#' + PRIVACY_POLICY_MAIN_DRIVER_STEP_2,
    popover: {
      description:
        "Crie ou disponibilize suas políticas de privacidade. As Políticas Criadas serão Disponibilizadas para Acesso Direto em 'Privacidade & Você'",
    },
  },
  {
    element: '#' + PRIVACY_POLICY_MAIN_DRIVER_STEP_3,
    popover: {
      description:
        'Ajuste suas diretrizes de privacidade, remova políticas desnecessárias.',
    },
  },
]

const PRIVACY_POLICY = 'privacy_notice'
const COOKIE_POLICY = 'cookie_policy'
const TERMS_OF_USE = 'terms_of_use'

const CATEGORIES = [
  { id: 'privacy_notice', name: 'Aviso de Privacidade' },
  { id: 'cookie_policy', name: 'Política de Cookies' },
  { id: 'terms_of_use', name: 'Termos de Uso' },
  { id: 'other', name: 'Outras' },
]

const CATEGORIES_LIST = {
  PRIVACY_POLICY,
  COOKIE_POLICY,
  TERMS_OF_USE,
}

const TRANSLATED_CATEGORIES = {
  privacy_notice: 'Aviso de Privacidade',
  cookie_policy: 'Política de Cookies',
  terms_of_use: 'Termos de Uso',
  other: 'Outras',
}

export default {
  ACTIVE_STATUS,
  CATEGORIES_LIST,
  CATEGORIES,
  DRAFT_STATUS,
  INACTIVE_STATUS,
  PRIVACY_POLICY_DRIVER_STEPS_OBJECT,
  PRIVACY_POLICY_MAIN_DRIVER_STEP_1,
  PRIVACY_POLICY_MAIN_DRIVER_STEP_2,
  PRIVACY_POLICY_MAIN_DRIVER_STEP_3,
  TRANSLATED_CATEGORIES,
}
