const yearsAhead = (quantityOfYears) => {
  var currentYear = new Date().getFullYear()
  const endYear = currentYear + quantityOfYears
  var years = []

  while (currentYear <= endYear) {
    years.push(currentYear++)
  }

  return years
}

export default yearsAhead
