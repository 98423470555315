import constants from 'constants/index'

const eliminateNotEliminateSubtitleMessages = (type, chosen, status) => {
  const { ELIMINATED_STATUS, UNNECESSARY_STATUS } = constants.unnecessaryData
  const {
    ELIMINATE_LIFE_CYCLES_MESSAGES,
    NOT_ELIMINATE_LIFE_CYCLES_MESSAGES,
    ELIMINATE_COLLECTED_FIELD_MESSAGES,
    NOT_ELIMINATE_COLLECTED_FIELD_MESSAGES,
    ELIMINATE_DATA_TREATMENT_MESSAGES,
    NOT_ELIMINATE_DATA_TREATMENT_MESSAGES,
  } = constants.nonComplianceReports

  const message = (eliminatedMessage, notEliminatedMessage) => {
    if ([chosen, status].includes(ELIMINATED_STATUS)) return eliminatedMessage

    if (!chosen && status === UNNECESSARY_STATUS) return '-'

    return notEliminatedMessage
  }

  const unnecessaryData = {
    lifeCycle: message(
      ELIMINATE_LIFE_CYCLES_MESSAGES,
      NOT_ELIMINATE_LIFE_CYCLES_MESSAGES,
    ),
    collectedField: message(
      ELIMINATE_COLLECTED_FIELD_MESSAGES,
      NOT_ELIMINATE_COLLECTED_FIELD_MESSAGES,
    ),
    dataTreatment: message(
      ELIMINATE_DATA_TREATMENT_MESSAGES,
      NOT_ELIMINATE_DATA_TREATMENT_MESSAGES,
    ),
  }

  return unnecessaryData[type] || '-'
}

export default eliminateNotEliminateSubtitleMessages
