import React from 'react'
import clsx from 'clsx'

import { Box, Typography } from '@material-ui/core'
import {
  XCircle as XCircleIcon,
  CheckCircle as CheckCircleIcon,
} from 'react-feather'

import useStyles from './styles'

const PasswordValidator = ({
  passwordInput,
  justSubmitted,
  isSupplierStep = false,
}) => {
  const classes = useStyles()

  const eightCharacters = () => {
    return /^.{8,}$/.test(passwordInput)
  }

  const uppercaseLetter = () => {
    return /.*[A-Z].*/.test(passwordInput)
  }

  const lowercaseLetter = () => {
    return /.*[a-z].*/.test(passwordInput)
  }

  const specialCharacters = () => {
    //eslint-disable-next-line
    return /[ `*!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(passwordInput)
  }

  const numberCharacters = () => {
    return /.*[0-9].*/.test(passwordInput)
  }

  const identifyClass = (regexValidator) => {
    if (regexValidator()) return classes.correct
    if (justSubmitted) return classes.errorJustSubmitted
    return isSupplierStep ? classes.errorLight : classes.error
  }

  const identifyIcon = (regexValidator) => {
    if (regexValidator())
      return (
        <CheckCircleIcon
          size={24}
          className={clsx(classes.icon, classes.correct)}
        />
      )
    if (justSubmitted)
      return (
        <XCircleIcon
          size={24}
          className={clsx(classes.icon, classes.errorJustSubmitted)}
        />
      )
    return (
      <XCircleIcon
        size={24}
        className={clsx(
          classes.icon,
          isSupplierStep ? classes.errorLight : classes.error,
        )}
      />
    )
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={1}>
        {identifyIcon(eightCharacters)}
        <Typography className={identifyClass(eightCharacters)}>
          Ao menos 8 caracteres
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" mb={1}>
        {identifyIcon(lowercaseLetter)}
        <Typography className={identifyClass(lowercaseLetter)}>
          Letras minúsculas
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" mb={1}>
        {identifyIcon(uppercaseLetter)}
        <Typography className={identifyClass(uppercaseLetter)}>
          Letras maiúsculas
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" mb={1}>
        {identifyIcon(specialCharacters)}
        <Typography className={identifyClass(specialCharacters)}>
          Caractere especial (Ex: # $)
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        {identifyIcon(numberCharacters)}
        <Typography className={identifyClass(numberCharacters)}>
          Números
        </Typography>
      </Box>
    </Box>
  )
}

export default PasswordValidator
