import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import {
  Button,
  Box,
  Container,
  Card,
  Grid,
  TablePagination,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { Skeleton } from '@material-ui/lab'
import { useTheme } from '@material-ui/styles'

import {
  Page,
  ContentHeader,
  Permitted,
  Filters,
  FilterButton,
  DriveTour,
} from 'components'
import { PrivacyPoliciesTable } from './components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'

import useStyles from './styles'

const PrivacyPoliciesMain = () => {
  const filter = useFilter()
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()

  const [openTour, setOpenTour] = useState(false)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { mixpanel } = helpers
  const { privacyPolicy } = constants

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.privacyPolicies.get,
    {
      perPage,
      page,
      ...filter.filters,
    },
    [page, perPage, filter.filters],
  )

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Termos e Políticas', 'Tour', {
      action: 'tour-politicas-de-privacidade-privacidade-e-voce',
    })
  }

  const filteredSteps = privacyPolicy.PRIVACY_POLICY_DRIVER_STEPS_OBJECT.filter(
    (step) => {
      if (isEmpty(response?.data?.privacyPolicies))
        return (
          step.element !== '#' + privacyPolicy.PRIVACY_POLICY_MAIN_DRIVER_STEP_3
        )
      return true
    },
  )

  return (
    <Page title="Termos e Políticas">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Termos e Políticas">
          <Permitted tag="create_privacy_policy">
            <Box pr={1}>
              <Tooltip title="Iniciar o tour guiado">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<HelpCircleIcon size={20} />}
                  onClick={handleOpenTour}
                  fullWidth
                  id={privacyPolicy.PRIVACY_POLICY_MAIN_DRIVER_STEP_1}
                >
                  Tutorial
                </Button>
              </Tooltip>
            </Box>
            <Button
              variant="contained"
              color="primary"
              id={privacyPolicy.PRIVACY_POLICY_MAIN_DRIVER_STEP_2}
              onClick={() => history.push(routes.privacyPolicy.new)}
            >
              NOVO
            </Button>
          </Permitted>
          <Box pl={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen}></FilterButton>
          </Box>
        </ContentHeader>
        <Card>
          <Box display="flex" width="100%" mt={2}>
            <Grid container>
              <PrivacyPoliciesTable
                refresh={refresh}
                privacyPolicies={response?.data?.privacyPolicies ?? []}
              />
            </Grid>
          </Box>

          <Box px={2} display="flex" justifyContent="flex-end">
            {!isLoading && response ? (
              <TablePagination
                component="div"
                count={response.data.meta.totalCount}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            ) : (
              <Box py="11px">
                <Skeleton variant="rect" width={200} height={30} />
              </Box>
            )}
          </Box>
        </Card>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input textfieldinput="true" label="Título" name="title" />
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            <option value="1">Rascunho</option>
            <option value="2">Ativo</option>
            <option value="3">Inativo</option>
          </select>
        </Filters>
      </Container>
      <DriveTour
        stepsMatrix={filteredSteps}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default PrivacyPoliciesMain
