import React from 'react'
import PropTypes from 'prop-types'

import { DashboardChartPaper, DonutChart } from 'components'

import constants from 'constants/index'

const OverdueRmcByDepartmentGraphic = ({ processStats, ...rest }) => {
  const departments = processStats?.departments
    ?.sort((a, b) => {
      const sumA = a.nonComplianceReports.overdue
      const sumB = b.nonComplianceReports.overdue
      return sumB - sumA
    })
    .slice(0, 4)

  const getDataByStatus = (department, status) =>
    department?.nonComplianceReports[status]

  const names = departments?.map((department) => department.name)

  const dataOverdue = departments?.map((department) =>
    getDataByStatus(department, 'overdue'),
  )

  return (
    <DashboardChartPaper
      title="Departamentos que possuem mais RMCs vencidos"
      subtitle="RMCs vencidos"
      // redirect={routes.nonComplianceReport.all}
      {...rest}
    >
      <DonutChart
        legendPosition="right"
        names={names || []}
        values={dataOverdue || []}
        title="RMCs vencido"
        colors={['#376FB6', '#30DADA', '#AADC1F', '#16A63C']}
        heightValue={225}
        id={constants.myLgpd.GRAPH_IDS.pdf.departmentsRmcsOverdued}
      />
    </DashboardChartPaper>
  )
}

OverdueRmcByDepartmentGraphic.propTypes = {
  processStats: PropTypes.object.isRequired,
}

export default OverdueRmcByDepartmentGraphic
