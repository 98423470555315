import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { Grid, TextField } from '@material-ui/core'

import { Controller } from 'react-hook-form'

import * as service from 'service'

import useSubscription from 'hooks/useSubscription'
import useSnackbar from 'hooks/useSnackbar'

const FormCoupon = ({ formMethods, setLoading }) => {
  const { control, errors, setError, clearError, handleSubmit, setValue } =
    formMethods
  const { data, coupon, setCoupon, selectedPlan, currentDocument } =
    useSubscription()
  const snackbar = useSnackbar()

  const addErrorToCoupon = (errorMessage) => {
    setError('coupon', {
      type: {
        notMatch: errorMessage,
      },
    })
  }
  const defaultCoupon = data?.coupon || coupon || data?.discountCoupon

  useEffect(() => {
    if (!!defaultCoupon) {
      setValue('coupon', defaultCoupon?.code || '')
      setCoupon(defaultCoupon)
    }
    if (!selectedPlan) {
      addErrorToCoupon('Selecione o tamanho da sua empresa.')
    } else {
      clearError('coupon')
    }
    // eslint-disable-next-line
  }, [selectedPlan])

  const onSubmit = async ({ coupon }) => {
    if (coupon === '') {
      setCoupon(null)
      return clearError('coupon')
    }
    try {
      setLoading(true)
      const response = await service.dponet.coupons.validate({
        coupon,
        planId: selectedPlan?.id,
        document: currentDocument,
      })
      setCoupon({ ...response?.data?.discountCoupon, value: coupon })
      clearError('coupon')
      snackbar.open({
        message: 'Cupom aplicado.',
        variant: 'success',
      })
      setLoading(false)
    } catch (err) {
      setCoupon(null)
      addErrorToCoupon('Cupom inválido!')
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form-coupon">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.coupon}
                helperText={errors?.coupon?.types?.type?.notMatch}
                label="Código do cupom"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            }
            control={control}
            name="coupon"
          />
        </Grid>
      </Grid>
    </form>
  )
}

FormCoupon.propTypes = {
  formMethods: PropTypes.object.isRequired,
  setCouponCode: PropTypes.func,
  setLoading: PropTypes.func,
}

export default FormCoupon
