import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Box, Button, Typography } from '@material-ui/core'

import { routes } from 'Routes'
import useStyles from './styles'

const IncidentCard = ({ incidents = {}, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()
  const { finalized, onTime, overdue, pending, riskAccepted } = incidents
  const openIncidents = onTime + overdue + pending
  const resolvedIncidents = finalized + riskAccepted

  const goToIncidents = () => {
    history.replace(routes.incidents.all)
  }

  return (
    <Box
      className={clsx(classes.cardIncidents, {
        [classes.cardIncidentsBlue]: openIncidents === 0,
        [classes.cardIncidentsRed]: openIncidents > 0,
      })}
      {...rest}
    >
      <Box className={classes.incidentsValueBox}>
        <Typography className={classes.numberIncidents}>
          {openIncidents || '-'}
        </Typography>
        <Typography variant="subtitle1" color="inherit">
          Incidente{openIncidents > 1 && 's'} em análise
        </Typography>
      </Box>

      <Box className={classes.incidentsActionsBox}>
        <Box>
          <Button
            onClick={goToIncidents}
            variant="text"
            className={classes.seeMoreAction}
          >
            Ver mais
          </Button>
        </Box>
        <Button
          variant="text"
          disabled
          classes={{
            disabled: classes.resolvedIncidents,
          }}
        >
          {resolvedIncidents} resolvido{resolvedIncidents > 1 && 's'}
        </Button>
      </Box>
    </Box>
  )
}

IncidentCard.propTypes = {
  incidents: PropTypes.object.isRequired,
}

export default IncidentCard
