import React from 'react'
import clsx from 'clsx'

import constants from 'constants/index'
import { Typography } from '@material-ui/core'

const TitleComponent = ({ status, classes, nonCompliance }) => {
  const textRender = () => {
    if (status === constants.companySuppliers.REFUSED_STATUS)
      return (
        <Typography className={clsx(classes.title, classes.titleRefused)}>
          Você está adequado à LGPD?
        </Typography>
      )
    return (
      <>
        <Typography className={clsx(classes.title, classes.titlePending)}>
          Você está pendente de&nbsp;
          <span className={classes.titleApproved}>
            {nonCompliance > 1
              ? `${nonCompliance} medida(s)`
              : `${nonCompliance} medida`}
          </span>
          &nbsp; de controle {nonCompliance > 1 ? 'importantes' : 'importante'}
        </Typography>
        <Typography
          className={clsx(classes.title, classes.descriptionApproved)}
        >
          A LGPD é uma obrigação para todas as empresas, conte com o suporte de
          nossos especialistas para entender os pontos de melhoria para a sua
          organização.
        </Typography>
      </>
    )
  }

  return textRender()
}

export default TitleComponent
