import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  initial: {
    borderRadius: '2px',
    backgroundColor: colors.orange[100],
    color: colors.orange[900],
    fontWeight: 400,
    height: '21px',
  },
  headway: {
    borderRadius: '2px',
    backgroundColor: colors.blue[100],
    color: colors.blue[900],
    fontWeight: 400,
    height: '21px',
  },
  conclusion: {
    borderRadius: '2px',
    backgroundColor: colors.green[500],
    color: colors.common.white,
    fontWeight: 400,
    height: '21px',
  },
}))

export default styles
