import { makeStyles } from '@material-ui/core'

import theme from 'theme'

const dataProcessColors = theme.colors.dataProcess.backgroundFragilities

const styles = makeStyles(() => ({
  root: {
    borderRadius: '2px',
    fontWeight: 700,
    color: theme.palette.white,
  },
  lowFragility: {
    backgroundColor: dataProcessColors.low,
  },
  mediumFragility: {
    backgroundColor: dataProcessColors.medium,
  },
  highFragility: {
    backgroundColor: dataProcessColors.high,
  },
  severeFragility: {
    backgroundColor: dataProcessColors.severe,
  },
  notCalculated: {
    backgroundColor: dataProcessColors.notCalculated,
  },
}))

export default styles
