import React from 'react'

import { RHFInput } from 'react-hook-form-input'

import { Label, Select as SelectComponent } from 'components'
import { Grid, TextField, Box } from '@material-ui/core'

import helpers from 'helpers'

import useStyles from '../../styles'

const StorageTimeVolumetry = ({ setValue, register, errors }) => {
  const classes = useStyles()

  const volumetryOptions = helpers.dataProcess.selectVolumetryOptions()

  return (
    <Box className={classes.box}>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          <Label
            title="Tempo de armazenamento na organização"
            description="Por quanto tempo os dados ficam armazenados na organização?"
            item
            xs={12}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <RHFInput
                  as={
                    <TextField
                      type="number"
                      variant="outlined"
                      error={!!errors?.value}
                      helperText={errors?.value?.message}
                      fullWidth
                    />
                  }
                  register={register}
                  setValue={(name, value, trigger) =>
                    setValue(name, value < 0 ? '0' : value, trigger)
                  }
                  name="value"
                  mode="onChange"
                />
              </Grid>
              <Grid item xs={8}>
                <RHFInput
                  as={
                    <SelectComponent
                      items={volumetryOptions}
                      error={!!errors?.volumetry}
                      helperText={errors?.volumetry?.message}
                    />
                  }
                  register={register}
                  setValue={setValue}
                  name="volumetry"
                  mode="onChange"
                />
              </Grid>
            </Grid>
          </Label>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Label
            title="Justificativa do tempo de armazenamento"
            description="Por que os dados ficam armazenados na organização por este tempo?"
            item
            xs={12}
          >
            <RHFInput
              as={
                <TextField
                  variant="outlined"
                  error={!!errors?.deadlineJustification}
                  helperText={errors?.deadlineJustification?.message}
                  fullWidth
                />
              }
              register={register}
              setValue={setValue}
              name="deadlineJustification"
              mode="onChange"
            />
          </Label>
        </Grid>
      </Grid>
    </Box>
  )
}
export default StorageTimeVolumetry
