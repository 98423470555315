import React from 'react'
import { Alert } from '@material-ui/lab'
import { Box, Typography } from '@material-ui/core'

const MissingDpoAlert = () => {
  return (
    <Box mb={2}>
      <Alert variant="filled" severity="error">
        <Typography variant="body1">
          A organização não possui um DPO nomeado. É necessário nomear um DPO
          Responsável.
        </Typography>
      </Alert>
    </Box>
  )
}

export default MissingDpoAlert
