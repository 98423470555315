import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid } from '@material-ui/core'

import { DataProcessCard } from 'components'
import { DataProcessTable } from 'views/DataProcesses/components/'

import constants from 'constants/index'

const Main = ({
  dataProcesses,
  isLoading,
  setRefresh,
  listingType,
  departments,
  origin,
}) => {
  return (
    <Box display="flex" width="100%" mt={-2}>
      <Grid container spacing={3}>
        {listingType === constants.dataProcess.LIST_IN_CARD_TYPE && (
          <DataProcessCard
            md={4}
            sm={6}
            xs={12}
            departments={departments}
            dataProcesses={dataProcesses?.dataProcesses}
            isLoading={isLoading}
            onEvent={setRefresh}
            {...(origin && { origin })}
          />
        )}
        {listingType === constants.dataProcess.LIST_IN_TABLE_TYPE && (
          <DataProcessTable
            xl={3}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            dataProcesses={dataProcesses?.dataProcesses}
            isLoading={isLoading}
          />
        )}
      </Grid>
    </Box>
  )
}

Main.propTypes = {
  dataProcesses: PropTypes.object,
  listingType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired,
  departments: PropTypes.arrayOf(PropTypes.object),
}

Main.defaultProps = {
  departments: [],
  setRefresh: () => {},
}

export default Main
