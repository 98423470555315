import React, { useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ChromePicker } from 'react-color'
import PropTypes from 'prop-types'
import { truncate } from 'lodash'
import { Box, InputAdornment, TextField } from '@material-ui/core'

import helpers from 'helpers'

import useOutsideClick from 'hooks/useOutsideClick'

import styles from './styles'

const ColorInput = ({ controlName, label }) => {
  const [showPicker, setShowPicker] = useState(false)

  const { control, watch, setValue, errors } = useFormContext()

  const colorInputRef = useRef(null)
  const colorPicker = watch(controlName)

  const useStyles = styles({ colorPicker })
  const classes = useStyles()

  const handleShowPicker = () => setShowPicker(true)
  const handleClosePicker = () => setShowPicker(false)

  useOutsideClick({
    ref: colorInputRef,
    callback: handleClosePicker,
  })

  const hexadecimalValidation = (value) => {
    let newValue = helpers.regex.onlyLetters(value)
    newValue = helpers.regex.removeSpaces(newValue).toUpperCase()

    return truncate(newValue, {
      length: 6,
      omission: '',
    })
  }

  const handleColorChange = (event) =>
    '#' + hexadecimalValidation(event?.target?.value)

  const handlePickerColorChange = (newColor) =>
    setValue(controlName, '#' + hexadecimalValidation(newColor?.hex))

  return (
    <Box>
      <Controller
        as={
          <TextField
            error={!!errors?.[controlName]}
            helperText={<>{errors?.[controlName]?.message}</>}
            type="text"
            label={label}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box className={classes.colorPreviewBox} p={1.5} />
                </InputAdornment>
              ),
            }}
            ref={colorInputRef}
            onClick={handleShowPicker}
          />
        }
        control={control}
        name={controlName}
        onChange={([event]) => handleColorChange(event)}
        mode="onChange"
      />
      {showPicker && (
        <Box
          display="flex"
          justifyContent="end"
          ref={colorInputRef}
          position="relative"
        >
          <Box width="fit-content" position="absolute" zIndex={2}>
            <ChromePicker
              disableAlpha
              color={colorPicker}
              onChange={handlePickerColorChange}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

ColorInput.propTypes = {
  controlName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default ColorInput
