import onlyNumbers from '../formatters/onlyNumbers'

import constants from 'constants/index'

const mountCreate = (data, recaptcha) => {
  const {
    document,
    companyName,
    segmentId,
    responsibleName,
    responsibleEmail,
    phone,
    planId,
  } = data
  const documentNumber = onlyNumbers(document)
  const phoneNumber = onlyNumbers(phone)

  return {
    document: documentNumber,
    kind: documentNumber.length === 11 ? 'P' : 'C',
    name: companyName,
    fantasyName: companyName,
    email: responsibleEmail,
    phone: phoneNumber,
    planId,
    type: constants.preRegistrations.TRIAL_TYPE,
    segmentId,
    responsibleUser: {
      name: responsibleName,
      email: responsibleEmail,
      phone: phoneNumber,
    },
    membershipRegistration: {
      partnerCompany: constants.userCompanies.PARTNER_TRIAL,
    },
    recaptcha: recaptcha,
  }
}

export default mountCreate
