import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
  },
  title: {
    display: 'block',
    fontFamily: 'Raleway',
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(55),
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(30),
    padding: '15px 0px',
  },
  descriptionApproved: {
    fontSize: theme.typography.pxToRem(25),
    marginTop: 40,
  },
  titleApproved: {
    backgroundColor: theme.palette.subscription.warning.error,
  },
  titleRefused: {
    display: 'inline',
    backgroundColor: theme.palette.subscription.warning.success,
  },
  description: {
    display: 'inline',
    fontFamily: 'Raleway',
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(25),
    fontWeight: 'bold',
    marginBottom: 30,
  },
  descriptionUnderlined: {
    display: 'inline',
    fontFamily: 'Raleway',
    color: theme.palette.common.white,
    backgroundColor: '#4199D2',
    fontSize: theme.typography.pxToRem(25),
    fontWeight: 'bold',
    marginBottom: 30,
  },
  groupButton: {
    textAlign: 'center',
  },
  button: {
    width: '390px',
    borderRadius: 29,
    fontSize: theme.typography.pxToRem(18),
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    boxShadow: `0px 0px 12px ${theme.colors.firstStep.button.primary}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.colors.firstStep.button.primary,
    },
    backgroundColor: theme.colors.firstStep.button.primary,
    borderColor: theme.colors.firstStep.button.primary,
    color: theme.palette.common.white,
    textTransform: 'none',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  outlined: {
    boxShadow: 'none',
    border: '2px solid',
    borderColor: theme.palette.common.white,
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginTop: 10,
  },
  titlePending: {
    marginTop: 144,
  },
}))

export default styles
