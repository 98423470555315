import PropTypes from 'prop-types'
import { colors, makeStyles } from '@material-ui/core'

const styles = ({ errors, attachmentControlName }) =>
  makeStyles((theme) => ({
    styledButton: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      backgroundColor: colors.grey[100],
      paddingInline: theme.spacing(4),
      textTransform: 'lowercase',
      '&:hover': {
        backgroundColor: colors.grey[200],
      },
    },
    nameFile: {
      color: colors.pink[300],
      paddingRight: theme.spacing(1),
    },
    cancelIcon: {
      color: colors.grey[400],
      display: 'flex',
      paddingLeft: '2px',
      flexWrap: 'nowrap',
    },
    errorColor: {
      color: !!errors?.[attachmentControlName]
        ? theme.palette.error.main
        : 'primary',
    },
  }))

styles.propTypes = {
  errors: PropTypes.object,
  attachmentControlName: PropTypes.string.isRequired,
}

export default styles
