import React from 'react'
import { Box, LinearProgress, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import { DashboardTablePaper } from 'components'

import useStyles from './styles'

const LinearProgressTable = ({
  title,
  tableType,
  rows,
  percentage = false,
  withTotalRmcQuestions = false,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <DashboardTablePaper
      title={title}
      tableType={tableType}
      className={classes.root}
      {...rest}
    >
      {rows?.map((row) => (
        <Box px={2} key={row?.name}>
          <Box className={classes.lineTitle}>
            <Typography className={classes.rowName}>{row.name}</Typography>
            <Typography className={classes.rowValue}>
              {row.value}
              {percentage ? '%' : ''}
              {withTotalRmcQuestions ? `/${row.totalRmcQuestions}` : ''}
            </Typography>
          </Box>
          <Box>
            <LinearProgress
              className={classes.lineBar}
              variant="determinate"
              value={row.totalRmcQuestions === 0 ? 100 : row.percentage}
            />
          </Box>
        </Box>
      ))}
    </DashboardTablePaper>
  )
}

LinearProgressTable.propTypes = {
  title: PropTypes.string,
  tableType: PropTypes.string,
  rows: PropTypes.array,
  percentage: PropTypes.bool,
  withTotalRmcQuestions: PropTypes.bool,
}

export default LinearProgressTable
