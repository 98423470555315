import helpers from 'helpers'

import components from '../../components'

import { GAP } from '../../constants'

import palette from 'theme/palette'

const generalInformation = async (pdf, date, { incident }) => {
  const controllerData = incident?.incidentDataAgents?.controller?.[0]
  const commissionerData = incident?.incidentDataAgents?.commissioner?.[0]
  const representativeData = incident?.incidentDataAgents?.representative?.[0]

  pdf.addPage('a4', 'p')
  components.subtitle(
    pdf,
    'Formulário de Comunicação de Incidente \nde Segurança com Dados Pessoais - ANPD',
    GAP,
    palette.primary.hover,
  )

  components.subtitle(pdf, 'Informações gerais', GAP * 1.85)

  components.lineWithDate(pdf, date)

  components.paragraph(
    pdf,
    'Dados do Incidente',
    GAP * 2.25,
    palette.subscription.primary.main,
    'Roboto-Bold',
  )

  const incidentData = [
    ['Data do Incidente', helpers.formatters.date(incident?.incidentDatetime)],
    ['Hora do Incidente', helpers.formatters.hours(incident?.incidentDatetime)],
    ['Quantidade estimada de titulares afetados', incident?.affectedHolders],
  ]

  components.table(pdf, [], incidentData, GAP * 2.35, 0.35)

  components.paragraph(
    pdf,
    'Dados do Controlador',
    GAP * 3.85,
    palette.subscription.primary.main,
    'Roboto-Bold',
  )

  const controllerDataTable = [
    ['Nome da Empresa', controllerData?.name],
    ['CNPJ', controllerData?.document],
    ['CEP', controllerData?.cep],
    ['Cidade', controllerData?.city],
    ['Estado', controllerData?.state],
    ['Telefone', controllerData?.phone],
    ['E-mail', controllerData?.email],
    [
      'Declara ser Microempresa ou Empresa de Pequeno Porte?',
      helpers.formatters.convertBoolToString(
        controllerData?.declaresSmallCompany,
      ),
    ],
    [
      'Declara ser Agente de Tratamento de Pequeno Porte?',
      helpers.formatters.convertBoolToString(
        controllerData?.declaresSmallAgentTreatment,
      ),
    ],
    [
      'Número aproximado de titulares cujos dados são tratados por sua organização',
      controllerData?.treatmentHolder,
    ],
  ]

  components.table(pdf, [], controllerDataTable, GAP * 3.95, 0.35)

  components.paragraph(
    pdf,
    'Dados do Encarregado pelo tratamento de Dados Pessoais',
    GAP * 7.9,
    palette.subscription.primary.main,
    'Roboto-Bold',
  )

  const dpoData = [
    ['Nome do encarregado', commissionerData?.name],
    ['CPF/CNPJ', commissionerData?.document],
    ['Telefone', commissionerData?.phone],
    ['E-mail', commissionerData?.email],
  ]

  components.table(pdf, [], dpoData, GAP * 8, 0.35)

  pdf.addPage('a4', 'p')

  components.lineWithDate(pdf, date)

  components.paragraph(
    pdf,
    'Dados do Notificante/Representante Legal',
    GAP,
    palette.subscription.primary.main,
    'Roboto-Bold',
  )

  const notifierData = [
    ['Nome do Representante Legal', representativeData?.name],
    ['CPF/CNPJ', representativeData?.document],
    ['Telefone', representativeData?.phone],
    ['E-mail', representativeData?.email],
  ]

  components.table(pdf, [], notifierData, GAP * 1.1, 0.35)

  components.numberPage(pdf, palette.black, 2)
}

export default generalInformation
