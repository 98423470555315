import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { sanitize } from 'dompurify'
import { Box, Paper, Typography } from '@material-ui/core'

import helpers from 'helpers'

import theme from 'theme'
import useStyles from './styles'

const MessageBox = ({ consentForm }) => {
  const classes = useStyles()

  return (
    <Paper variant="outlined">
      <Box p={2} bgcolor={theme.palette.background.default}>
        <Typography variant="h6">Texto do Consentimento</Typography>
        <Typography color="secondary" variant="caption">
          {helpers.validates.consents.legalFrameworkLabel(
            consentForm?.legalFrameworkId,
          )}
        </Typography>
      </Box>
      <Box className={classes.messageContainer}>
        <PerfectScrollbar>
          <Box
            className={classes.messageBox}
            dangerouslySetInnerHTML={{
              __html: sanitize(consentForm?.content || ''),
            }}
          />
        </PerfectScrollbar>
      </Box>
    </Paper>
  )
}

MessageBox.propTypes = {
  consentForm: PropTypes.object,
}

export default MessageBox
