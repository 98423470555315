import React, { useState } from 'react'
import { Box, Button, Hidden, Typography } from '@material-ui/core'
import { ProfileCard } from 'components'
import { ChangeResponsible, CompanyForm } from './components'

import { Upload as UploadIcon } from 'react-feather'

import useAuth from 'hooks/useAuth'
import { getBaseURL } from 'service/env'

import * as service from 'service'

import { InviteAdvisor } from './components/Company'

import useStyles from './styles'
import useSnackbar from 'hooks/useSnackbar'
import helpers from 'helpers'

const Company = () => {
  const {
    user: { name },
    userCompany: { company, responsable },
  } = useAuth()
  const snackbar = useSnackbar()

  const logoPath =
    company?.logo?.url && `${getBaseURL('dponet')}${company?.logo?.url}`
  const [logo, setLogo] = useState(logoPath)

  const classes = useStyles()

  const submitLogo = async (e) => {
    try {
      const logo = e.target.files[0]
      setLogo(URL.createObjectURL(logo))
      await service.dponet.companies.updateSelfLogo({ logo })
      snackbar.open({
        variant: 'success',
        message: 'Logotipo da empresa alterado com sucesso!',
      })
    } catch (error) {
      setLogo(logoPath)
      snackbar.open({
        variant: 'error',
        message: helpers.formatters.errorMessage(error, false),
      })
    }
  }

  return (
    <>
      <ProfileCard
        title={company?.name}
        subtitle={company?.document}
        avatarImagePath={logo}
      >
        <Box mb={5}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<UploadIcon size={18} />}
            component="label"
          >
            <Hidden xsDown>
              <Typography style={{ fontWeight: 'bold' }}>
                Logomarca da Organização
              </Typography>
            </Hidden>
            <input
              type="file"
              id="logo"
              className={classes.input}
              onChange={submitLogo}
            />
          </Button>
        </Box>
      </ProfileCard>
      {responsable && <ChangeResponsible userName={name} />}

      <InviteAdvisor invite={company?.outsourced} isDpoInvite />
      <InviteAdvisor invite={company?.observer} />
      <CompanyForm company={company} />
    </>
  )
}

export default Company
