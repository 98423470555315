import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async () => {
  return await dponetAPI.get(`/data_collected_options`)
}

const create = async ({ dataProcessId, ...data }) => {
  return await dponetAPI.post(
    `/data_processes/${dataProcessId}/data_collecteds`,
    data,
  )
}

export default { get, create }
