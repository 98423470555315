const styles = (theme) => ({
  defaultTypography: {
    color: theme.palette.layout.textPrimary,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '200px',
  },
})

export default styles
