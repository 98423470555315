import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography, Grid, makeStyles } from '@material-ui/core'
import { SuggestionAlert } from 'components/RmcActionContent/components'

import TableDataTreatments from '../../TableDataTreatments'
import constants from 'constants/index'

import styles from './styles'
const useStyles = makeStyles(styles)

const DataTreatment = ({
  isLoading,
  dataTreatments,
  dataProcessId,
  hiddenSuggestionAlert,
}) => {
  const classes = useStyles()

  return (
    <>
      <Box mt={3} mb={2}>
        <Typography variant="h5">
          Com quem os dados são compartilhados?
        </Typography>
      </Box>
      <SuggestionAlert
        hidden={hiddenSuggestionAlert}
        sectionName="Dados compartilhados"
        dataProcessId={dataProcessId}
        sectionTab={constants.suggestionChanges.TAB_VIEW_VALUE.DATA_TREATMENTS}
      />
      <Grid container className={classes.dataCollectedContainer}>
        <Grid
          className={classes.dataCollectedTable}
          item
          xl={12}
          lg={12}
          md={12}
          xs={12}
        >
          {!isLoading && (
            <TableDataTreatments
              dataTreatments={dataTreatments}
              classes={classes}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

DataTreatment.propTypes = {
  isLoading: PropTypes.bool,
  dataTreatments: PropTypes.array,
  dataProcessId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hiddenSuggestionAlert: PropTypes.bool,
}

export default DataTreatment
