import React from 'react'
import PropTypes from 'prop-types'

import { BaseConfirmationDialog } from 'components'

const ConfirmationDialog = ({ open, setOpen, title, subtitle, formRef }) => {
  return (
    <BaseConfirmationDialog
      open={open}
      title={title}
      text={subtitle}
      actionButtonText="Salvar e continuar"
      variant="blueConfirmation"
      setOpen={setOpen}
      dialogSize="sm"
      formRef={formRef}
    />
  )
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  formRef: PropTypes.string.isRequired,
}

export default ConfirmationDialog
