import React from 'react'

import { Controller, useForm } from 'react-hook-form'
import { Button, Grid, TextField } from '@material-ui/core'

import { CardFlagsSection, Select } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'
import constants from 'constants/index'

const MONTHS = constants.date.MONTHS.map((month) => ({
  id: month,
  name: month,
}))
const YEARS = helpers.functions.yearsAhead(15).map((year) => ({
  id: year,
  name: year,
}))

const PaymentCard = ({ loading, submit }) => {
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: '',
      number: '',
      expiration: null,
      cvv: '',
    },
  })

  const cardNumber = watch('number')
  const cardId = helpers.validates.cardFlags.id(cardNumber)
  const handleOnChange = (value, type) => {
    let inputValue = value

    if (type === 'number')
      inputValue = helpers.formatters.cardFlag.number(value)
    else if (type === 'cvv')
      inputValue = helpers.formatters.onlyNumbers(value).slice(0, 4)

    return inputValue
  }

  const onSubmit = async (data) => {
    try {
      const brand = constants.cardFlag.CARD_BRAND_DEFINITION_BY_ID[cardId]
      const expiration = `${data?.month}/${data?.year}`
      delete data.month
      delete data.year
      await submit({
        card: { ...data, expiration, brand },
        methodPayment: 'card',
      })

      snackbar.open({
        message: 'Método de pagamento atualizado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }

  return (
    <Grid
      container
      spacing={2}
      component="form"
      id="payment-card-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <CardFlagsSection title="Bandeiras aceitas:" cardFlagId={cardId} />
      </Grid>
      {constants.settings.FINANCIAL_PAYMENT_CARD_CONTROLLER_ITEMS.map(
        (item, index) => (
          <Grid key={index} item xs={12} md={6}>
            <Controller
              control={control}
              name={item?.controlName}
              onChange={([event]) =>
                handleOnChange(event.target.value, item?.controlName)
              }
              as={
                <TextField
                  label={item?.label}
                  error={!!errors?.[item?.controlName]}
                  helperText={<>{errors?.[item?.controlName]?.message}</>}
                  inputProps={{
                    inputMode: item?.inputType,
                  }}
                  fullWidth
                />
              }
            />
          </Grid>
        ),
      )}
      <Grid item xs={6} md={3}>
        <Controller
          name="month"
          control={control}
          as={
            <Select
              items={MONTHS}
              error={!!errors.month}
              fullWidth
              helperText={errors.month?.message}
              label="Mês"
            />
          }
          mode="onBlur"
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Controller
          name="year"
          control={control}
          as={
            <Select
              items={YEARS}
              error={!!errors.year}
              fullWidth
              helperText={errors.year?.message}
              label="Ano"
            />
          }
          mode="onBlur"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name="cvv"
          onChange={([event]) => handleOnChange(event.target.value, 'cvv')}
          as={
            <TextField
              label="CVV"
              error={!!errors?.cvv}
              helperText={<>{errors?.cvv?.message}</>}
              inputProps={{
                inputMode: 'numeric',
              }}
              fullWidth
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          type="submit"
          form="payment-card-form"
          loading={loading}
        >
          Atualizar forma de pagamento
        </Button>
      </Grid>
    </Grid>
  )
}

export default PaymentCard
