import React from 'react'
import { Controller } from 'react-hook-form'

import { Select } from 'components'

import useDataProcessTemplateQuestion from 'hooks/useDataProcessTemplateQuestion'

const SingleSelect = ({ control, question, disabled = false }) => {
  const { errors } = useDataProcessTemplateQuestion()

  const name = `question-${question.id}`

  return (
    <Controller
      as={
        <Select
          items={question?.dataProcessTemplateQuestionOptions || []}
          fieldName="option"
          error={!!errors[name]}
          helperText={<>{errors[name]?.message}</>}
          disabled={disabled}
        />
      }
      name={name}
      control={control}
    />
  )
}

export default SingleSelect
