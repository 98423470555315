import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import { Button } from '@material-ui/core'

import useAuth from 'hooks/useAuth'

import constants from 'constants/index'
import { routes } from 'Routes'
import { isEmpty } from 'lodash'

const ButtonAction = ({
  id = '',
  variant = 'text',
  color = 'primary',
  action,
  name,
  startIcon,
  endIcon,
  size = 'medium',
  style,
  className,
  disabled,
  fullWidth = false,
  form,
  type = 'button',
}) => {
  const { company, setFreeTrialBlock } = useAuth()
  const { pathname } = useLocation()

  const { TRIAL_LIMITED_STATUS, TRIAL_COMPLETE_STATUS } =
    constants.userCompanies

  const blockRoutesTrialComplete = [
    routes.technicalFaqs.lgpdMain,
    routes.technicalFaqs.informationSecurityMain,
  ]
  const activeLimitedTrial = company?.statusId === TRIAL_LIMITED_STATUS

  const handleAction = () => {
    if (
      activeLimitedTrial ||
      (blockRoutesTrialComplete.includes(pathname) &&
        company?.statusId === TRIAL_COMPLETE_STATUS)
    ) {
      return setFreeTrialBlock(true)
    }

    action()
  }

  return (
    <Button
      {...(!activeLimitedTrial && !isEmpty(form) && { form: form })}
      id={id}
      className={className}
      style={style}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant || 'text'}
      color={color || 'primary'}
      size={size || 'medium'}
      onClick={handleAction}
      disabled={disabled}
      fullWidth={fullWidth}
      type={type}
    >
      {name}
    </Button>
  )
}

ButtonAction.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  color: PropTypes.oneOf(['primary', 'secondary', 'default', 'inherit']),
  action: PropTypes.func,
  name: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
  form: PropTypes.string,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
}

export default ButtonAction
