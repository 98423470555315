import React, { useState } from 'react'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { Box, Button, Container, Tooltip } from '@material-ui/core'

import { ContentHeader, DriveTour, Page } from 'components'
import UsersForm from './components/UsersForm'

import useFetch from 'hooks/useFetch'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'

import useStyles from './styles'

const UsersNew = () => {
  const classes = useStyles()

  const [openTour, setOpenTour] = useState(false)

  const { response: responseProfiles, isLoading: loadingProfiles } = useFetch(
    service.dponet.profiles.get,
    {
      status: true,
      profileType: constants.profile.PROFILE_CLIENT_TYPE,
      perPage: 1000,
    },
  )

  const handleOpenTour = () => {
    const where = 'Novo Usuário'
    const title = 'Tour'

    const params = {
      action: 'tour-novo-usuario-empresas',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Novo usuário">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Novo usuário">
          <Tooltip title="Iniciar o tour guiado">
            <Box className={classes.contentHeaderBox}>
              <Button
                id={constants.user.NEW_USER_DRIVER_STEP_0}
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                fullWidth
              >
                Tutorial
              </Button>
            </Box>
          </Tooltip>
        </ContentHeader>
        <UsersForm
          loadingProfiles={loadingProfiles}
          profiles={responseProfiles?.data?.profiles}
        />
      </Container>
      <DriveTour
        stepsMatrix={constants.user.NEW_USERS_DRIVER_STEPS_OBJECT}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default UsersNew
