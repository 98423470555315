import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  defaultPopover: {
    maxWidth: '500px !important',
    '& .driver-popover-footer button': {
      color: theme.palette.white,
      border: 'none',
      textShadow: 'none',
      textTransform: 'uppercase',
      fontWeight: 700,
      padding: '6px 14px',
      fontSize: theme.typography.body2.fontSize,
      letterSpacing: theme.typography.body2.letterSpacing,
      fontFamily: theme.typography.body2.fontFamily,
    },
    '& .driver-popover-prev-btn': {
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    '& .driver-popover-next-btn': {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
      },
    },
    '& .driver-popover-title': {
      color: theme.palette.primary.main,
      fontFamily: theme.typography.h1.fontFamily,
    },
    '& .driver-popover-description': {
      color: theme.palette.primary.main,
      fontFamily: theme.typography.body1.fontFamily,
      textAlign: 'justify',
    },
  },
}))

export default styles
