import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  multiSelect: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
  },
  accordionHeader: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryAccordionHeader: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordionSummaryColor: {
    backgroundColor: theme.palette.secondary.superLight,
  },
}))

export default styles
