import { makeStyles } from '@material-ui/core'
import hexagons from 'images/hexagonos_background.svg'

const styles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  content: {
    minHeight: '70vh',
    width: '100%',
  },
  penOrRocketBackground: {
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${hexagons})`,
    [theme.breakpoints.up('md')]: {
      backgroundPositionX: '10vh',
      backgroundSize: '50vh',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
    [theme.breakpoints.only('md')]: {
      backgroundSize: '35vw',
      backgroundPositionX: '8vw',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      backgroundPositionX: '34vw',
      marginTop: theme.spacing(4),
      width: '100%',
      backgroundSize: 'contain',
    },
  },
  rocketImage: {
    [theme.breakpoints.up('md')]: {
      width: '50vh',
    },
    [theme.breakpoints.only('md')]: {
      marginTop: theme.spacing(6),
      width: '37vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50vw',
      marginBottom: -theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '9vh',
    },
  },
  welcomeBackground: {
    backgroundSize: '35vh',
    minHeight: '40vh',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '30vh',
      minHeight: '35vh',
      backgroundPositionX: 'center',
    },
  },
}))

export default styles
