const AtLeastOneNecessary = (data, scope) => {
  let necessaryScope = []

  Object.keys(data).forEach((key) => {
    let splitKey = key.split('-')
    let scopeType = splitKey[0]

    if (scopeType === scope) necessaryScope.push(data[key])
  })

  return necessaryScope.includes(1)
}

export default AtLeastOneNecessary
