import React from 'react'

import PropTypes from 'prop-types'

import { Box, Divider, Typography } from '@material-ui/core'

const QuestionBox = ({ questionType, questionLength }) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        px={2}
        py={1}
        width="100%"
      >
        <Typography>{questionType}</Typography>
        <Typography>{questionLength}</Typography>
      </Box>
      <Divider />
    </>
  )
}

QuestionBox.propTypes = {
  questionType: PropTypes.string,
  questionLength: PropTypes.number,
}

export default QuestionBox
