const TABS = [
  { value: 'general', label: 'PARA SITES' },
  { value: 'emailSignature', label: 'PARA E-MAIL' },
  { value: 'socialMedia', label: 'PARA REDES SOCIAIS' },
  { value: 'print', label: 'PARA IMPRESSÃO' },
  { value: 'card', label: 'CARTÃO' },
]

const PRIVACY_STAMPS_MAIN_DRIVER_STEP_1 = 'privacy-stamps-main-driver-step-1'
const PRIVACY_STAMPS_MAIN_DRIVER_STEP_2 = 'privacy-stamps-main-driver-step-2'

const PRIVACY_STAMPS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + PRIVACY_STAMPS_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Este recurso é essencial para que empresas transmitam suas práticas de privacidade aos clientes de forma eficaz. Ao disponibilizar selos como "Para Sites", "Para E-mails", "Para Redes Sociais", "Para Impressão" e "Cartão de Visita", ele simplifica a incorporação das políticas de privacidade em diversas plataformas e materiais.',
    },
  },
  {
    element: '#' + PRIVACY_STAMPS_MAIN_DRIVER_STEP_2,
    popover: {
      description:
        'Escolha uma das opções para adaptar seus selos às diferentes plataformas e materiais, garantindo transparência e conformidade com as regulamentações de proteção de dados.',
    },
  },
]

const privacyStamps = {
  TABS,
  PRIVACY_STAMPS_DRIVER_STEPS_OBJECT,
  PRIVACY_STAMPS_MAIN_DRIVER_STEP_1,
  PRIVACY_STAMPS_MAIN_DRIVER_STEP_2,
}

export default privacyStamps
