import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

import useStyles from './styles'
import theme from 'theme'

const SolutionIcon = ({ icon, title, altText, imgHeight, titleColor }) => {
  const classes = useStyles(imgHeight, titleColor)()

  return (
    <Box className={classes.container}>
      <img className={classes.image} src={icon} alt={altText} />
      <Typography className={classes.title}>{title}</Typography>
    </Box>
  )
}

SolutionIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  imgHeight: PropTypes.number,
}

SolutionIcon.defaultProps = {
  imgHeight: 72,
  titleColor: theme.palette.black,
}

export default SolutionIcon
