import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import helpers from 'helpers'

const DialogShowDataCollected = ({
  dataCollected,
  open,
  setOpen = () => {},
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Visualizar dado tratado</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <PerfectScrollbar>
          <Table size="small" emptyMessage="Não há nenhum dado coletado">
            <TableHead>
              <TableRow>
                <TableCell width="50%">Dado</TableCell>
                <TableCell width="50%">Tipo de dado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataCollected &&
                dataCollected.collectedFields.map((value) => (
                  <TableRow key={value.id}>
                    <TableCell>
                      {value.dataCollectedOption &&
                        value.dataCollectedOption.name}
                    </TableCell>
                    <TableCell>
                      {helpers.dataCollected.dataTypeLabel(
                        value.dataCollectedOption &&
                          value.dataCollectedOption.dataType,
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </DialogContent>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            variant="outlined"
            type="submit"
            onClick={() => setOpen(false)}
          >
            Sair
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default DialogShowDataCollected
