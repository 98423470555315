import React from 'react'

import { Table, TableRow, TableCell, TableBody } from 'components/Table'

import { Box, Typography, IconButton, makeStyles } from '@material-ui/core'

import { ArrowRight as ArrowIcon } from 'react-feather'

import useAuth from 'hooks/useAuth'

import useSnackbar from 'hooks/useSnackbar'

import PerfectScrollbar from 'react-perfect-scrollbar'

import styles from './styles'

import helpers from 'helpers'

import * as service from 'service'

const useStyles = makeStyles(styles)

const TableRowCompany = ({ index, userCompany, ...rest }) => {
  const classes = useStyles()
  const auth = useAuth()
  const snackbar = useSnackbar()

  const companyDocument = helpers.company.documentType(userCompany?.company)

  const handleClick = async () => {
    try {
      const response = await service.dponet.usersCompanies.selectCompany(
        userCompany.id,
      )

      const authToken = response?.data?.authToken
      const cookieToken = response?.data?.cookieToken

      if (!authToken) {
        return snackbar.open({
          message:
            'Ocorreu um erro ao acessar a empresa! Verifique se você ainda pertence à essa empresa e tente novamente',
          variant: 'error',
        })
      }

      service.dponet.auth.setToken(authToken)
      await service.cookies.auth.login(cookieToken)

      auth.loadDataWithAnimation()
    } catch (error) {
      snackbar.open({
        message: 'Ocorreu um erro ao selecionar empresa! Tente novamente',
        variant: 'error',
      })
    }
  }

  return (
    <TableRow {...rest}>
      <TableCell>
        <Typography>{userCompany?.company?.name}</Typography>
        <Typography className={classes.document}>{companyDocument}</Typography>
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={handleClick}>
          <ArrowIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

const ListCompanies = ({ usersCompanies }) => {
  const classes = useStyles()

  const sortedCompanies = [...usersCompanies].sort((a, b) =>
    a?.company?.name.localeCompare(b?.company?.name),
  )

  return (
    <Box width="100%" maxHeight="82%">
      <PerfectScrollbar
        className={classes.perfectScrollbar}
        options={{ wheelPropagation: true, suppressScrollX: true }}
      >
        <Table noWrap width="100%">
          <TableBody>
            {sortedCompanies?.map((userCompany) => (
              <TableRowCompany userCompany={userCompany} key={userCompany.id} />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

export default ListCompanies
