import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { Box, Button, Card, CardHeader, Divider } from '@material-ui/core'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { SupplierForm, QuestionnaireSupplier } from './components'

import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import helpers from 'helpers'
import * as service from 'service'

import { routes } from 'Routes'

const ClientsEdit = ({ match }) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const { mixpanel } = helpers

  const {
    params: { supplierId },
  } = match
  const [company, setCompany] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [getValues, setGetValues] = useState(() => () => {})
  const [international, setInternational] = useState(null)
  const [questionnaires, setQuestionnaires] = useState([])
  const [questionnairesCompany, setQuestionnairesCompany] = useState()
  const [previousStatus, setPreviousStatus] = useState({})

  const submittedRef = useRef(submitted)

  const onSubmit = async (companySupplier) => {
    try {
      setSubmitted(true)
      if (!international) {
        if (companySupplier.document.length > 14) {
          companySupplier.cnpj = companySupplier.document
        } else {
          companySupplier.cpf = companySupplier.document
        }
        delete companySupplier.document
      }
      if (questionnaires) {
        companySupplier.questionnaireKinds = previousStatus
      }

      const data = company?.editionPermitted
        ? companySupplier
        : { questionnaireKinds: previousStatus }

      await service.dponet.suppliers.put({
        supplierId,
        companySupplier: data,
      })

      snackbar.open({
        message: 'Fornecedor editado com sucesso',
        variant: 'success',
      })
      handleBack()
    } catch (error) {
      setSubmitted(false)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro ao editar as informações do fornecedor.',
        variant: 'error',
      })
    }
  }

  const handleBack = () => {
    mixpanel.track('Fornecedores', 'Editar fornecedor', {
      action: !submittedRef.current
        ? 'clique no botão voltar'
        : 'fornecedor editado',
      name: getValues('name'),
      document: getValues('document'),
      email: getValues('email'),
    })
    return history.push(routes.mySuppliers.all)
  }

  useEffect(() => {
    const loadCompany = async () => {
      try {
        setLoading(true)
        const responseSuppliers = await service.dponet.suppliers.get({
          supplierId,
        })
        const { companySupplier } = responseSuppliers.data
        setCompany(companySupplier)
        setInternational(companySupplier?.international)
      } catch (e) {
        snackbar.open({
          variant: 'error',
          message:
            helpers.formatters.errorMessage(e) ||
            'Ocorreu um erro ao carregar os dados do fornecedor!',
        })
        history.push(routes.mySuppliers.all)
      } finally {
        setLoading(false)
      }
    }
    loadCompany()
    submittedRef.current = submitted
    //eslint-disable-next-line
  }, [submitted])

  useEffect(() => {
    if (company.questionnaireSupplier) {
      const kindsActive = company.questionnaireSupplier
        .filter((questionnaire) => questionnaire.status === 'active')
        .map((questionnaire) => questionnaire.kind)
      setQuestionnaires(kindsActive)
    }
  }, [company.questionnaireSupplier])

  useEffect(() => {
    if (questionnairesCompany) {
      const newPreviousStatus = questionnairesCompany
        .filter(
          (kind) =>
            kind.name !== constants.questionControl.KIND.BASIC_REQUIREMENT,
        )
        .map((kind) => ({
          name: kind.name,
          status: questionnaires.includes(kind.name) ? 'active' : 'inactive',
        }))
      setPreviousStatus(newPreviousStatus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaires])

  return (
    <Page title="Editar fornecedor">
      <Container spacingXl={8} spacingLg={12}>
        <LoadingFeedback open={loading} />
        <ContentHeader title="Editar fornecedor" />
        <Card>
          <CardHeader title="Dados básicos" />
          <Divider />
          <Box p={2}>
            {!loading && (
              <SupplierForm
                companySupplier={company}
                onSubmit={onSubmit}
                setGetValues={setGetValues}
                setInternational={setInternational}
                international={international}
              />
            )}
          </Box>
        </Card>

        <Box mt={2}>
          <QuestionnaireSupplier
            questionnaires={questionnaires}
            setQuestionnaires={setQuestionnaires}
            setQuestionnairesCompany={setQuestionnairesCompany}
          />
        </Box>

        <Box mt={4} display="flex">
          <Button variant="contained" onClick={handleBack}>
            Voltar
          </Button>

          <Box ml={4}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form="form-supplier"
              disabled={submitted}
            >
              Salvar
            </Button>
          </Box>
        </Box>
      </Container>
    </Page>
  )
}

export default ClientsEdit
