import constants from 'constants/index'

const chipStatusColor = (status, classes) => {
  switch (status) {
    case constants.questions.PENDING_STATUS:
      return classes.chipPending
    case constants.questions.ANSWERED_STATUS:
      return classes.chipAnswered
    case constants.questions.INACTIVE_STATUS:
      return classes.chipInactive
    default:
      return null
  }
}

export default chipStatusColor
