import { useContext } from 'react'

import DocumentContext from 'contexts/DocumentContext'

const useRncDocuments = () => {
  const context = useContext(DocumentContext)

  if (context === undefined) {
    throw new Error(
      'useDocuments must be used within a DocumentContext.Provider',
    )
  }

  return {
    data: context.data,
    loadData: context.loadData,
    setData: context.setData,
    loading: context.loading,
    setLoading: context.setLoading,
  }
}

export default useRncDocuments
