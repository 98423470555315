import { useContext } from 'react'

import SelectCountryContext from 'contexts/SelectCountryContext'

const useSelectCountry = () => {
  const context = useContext(SelectCountryContext)

  if (context === undefined) {
    throw new Error(
      'useDocuments must be used within a SelectCountryContext.Provider',
    )
  }

  return {
    countries: context.countries,
    setCountries: context.setCountries,
    loading: context.loading,
    setLoading: context.setLoading,
    loadCountries: context.loadCountries,
    selectedCountries: context.selectedCountries,
    setSelectedCountries: context.setSelectedCountries,
    selectedCountry: context.selectedCountry,
    setSelectedCountry: context.setSelectedCountry,
    addCountry: context.addCountry,
    removeCountry: context.removeCountry,
    manySelectedCountry: context.manySelectedCountry,
    setManySelectedCountry: context.setManySelectedCountry,
    manySelectedCountries: context.manySelectedCountries,
    setManySelectedCountries: context.setManySelectedCountries,
    addCountryToManyFields: context.addCountryToManyFields,
    removeCountryToManyFields: context.removeCountryToManyFields,
    sortNewNamesToObject: context.sortNewNamesToObject,
  }
}

export default useSelectCountry
