import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { routes } from 'Routes'

import { Box, Button, Typography } from '@material-ui/core'
import {
  ContentHeader,
  LoadingFeedback,
  Page,
  Container,
  TableLogs,
} from 'components'

import constants from 'constants/index'
import * as service from 'service'

const Logs = ({ match }) => {
  const location = useLocation()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [dponetAudits, setDponetAudits] = useState([])
  const [companySupplier, setCompanySupplier] = useState({})
  const [mailLogs, setMailLogs] = useState([])

  const isSupplierPath = !matchPath(location.pathname, {
    path: routes.mySuppliers.logs,
  })

  const { supplierId } = match.params

  useEffect(() => {
    const loadLogs = async () => {
      setLoading(true)
      const responseSupplier = await service.dponet.suppliers.get({
        supplierId,
      })
      const companySupplierData = responseSupplier?.data?.companySupplier

      const responseLogs = await service.dponet.suppliers.logs({
        supplierId,
      })

      const responseMailLogs = await service.dponet.suppliers.mailLogs({
        companySupplierId: supplierId,
        success: true,
        ...(!isSupplierPath && {
          userEmail: companySupplierData?.supplierResponsibleEmail,
        }),
      })

      setDponetAudits(responseLogs?.data?.dponetAudits)
      setMailLogs(responseMailLogs?.data?.mailLogs)
      setCompanySupplier(companySupplierData)
      setLoading(false)
    }

    loadLogs()
    //eslint-disable-next-line
  }, [])

  const handleBack = () => {
    history.goBack()
  }

  const emptyLogs = isEmpty(mailLogs) && isEmpty(dponetAudits)

  return (
    <Page title="Logs do fornecedor">
      <LoadingFeedback open={loading} />
      <Container maxWidth={false} spacingXl={12} spacingLg={12}>
        <ContentHeader title="Histórico do fornecedor" />
        {!loading && emptyLogs && (
          <Typography variant="body2">
            Não existe nenhum histórico de alterações para este registro.
          </Typography>
        )}
        <TableLogs
          dponetAudits={dponetAudits}
          mailLogs={mailLogs}
          object={companySupplier}
          entityName={constants.logs.HELPER_ENTITIES.companySupplier}
        />
        <Box mt={4}>
          <Button variant="contained" onClick={handleBack}>
            Voltar
          </Button>
        </Box>
      </Container>
    </Page>
  )
}

export default Logs
