import { makeStyles } from '@material-ui/core'

import hexagonCoplete from 'images/Polygon_2_Complete.svg'
import hexagonPending from 'images/Polygon_2_Pending.svg'

const styles = makeStyles(() => ({
  statusIcon: {
    backgroundImage: `url(${hexagonPending})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  completed: {
    backgroundImage: `url(${hexagonCoplete})`,
  },
}))

export default styles
