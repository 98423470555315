import { routes } from 'Routes'
import { reverse } from 'named-urls'
import { isEmpty } from 'lodash'
import { saveAs } from 'file-saver'

import * as service from 'service'

import defineStatus from './defineStatus'
import defineSubtitle from './defineSubtitle'

const mountTrails = (history, trainningJourney, userSteps, certification) => {
  const goToStep = (step) =>
    history.push(
      `${reverse(routes.trainningJourney, {
        trainningJourneyId: trainningJourney?.id,
      })}?step=${step}`,
    )

  const downloadCertification = async () => {
    try {
      const certificationLink = certification?.certification
      const fileResponse = await service.dponet.companyTrails.getImage(
        certificationLink,
      )

      saveAs(fileResponse.data, `${trainningJourney.name}.png`)
    } catch (error) {
      console.error('Erro ao baixar o certificado:', error)
    }
  }

  const trails = trainningJourney?.trainningSteps?.map((trail, index) => {
    const userStep = userSteps?.find(
      (userStep) => userStep?.trainningStepId === trail?.id,
    )

    return {
      title: trail.description,
      subtitle: defineSubtitle(userStep || trail),
      status: defineStatus(!!userStep?.concluded),
      concluded: !!userStep?.concluded,
      action: {
        text: 'Assistir',
        method: () => goToStep(index + 1),
      },
    }
  })

  const someUncompleted = trails?.filter((trail) => !trail.concluded)
  const unlockedCertification = !!certification && isEmpty(someUncompleted)

  return [
    ...(!!trails ? trails : []),
    {
      title: 'Certificado',
      concluded: unlockedCertification,
      status: defineStatus(unlockedCertification),
      subtitle: defineSubtitle(unlockedCertification ? certification : {}),
      action: {
        text: 'Baixar',
        method: downloadCertification,
      },
    },
  ]
}

export default mountTrails
