import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  makeStyles,
} from '@material-ui/core'
import { DestroyOrCreateActionInput, UpdateActionInput } from './components'

import pluralize from 'pluralize'
import humps from 'humps'
import constants from 'constants/index'

import styles from './styles'

const useStyles = makeStyles(styles)
const {
  UPDATE_ACTION,
  CREATE_ACTION,
  DESTROY_ACTION,
} = constants.suggestionChanges

const CardRow = ({ suggestedChanges, action, entityType, defaultExpanded }) => {
  if (action === CREATE_ACTION) {
    return (
      <DestroyOrCreateActionInput
        key={suggestedChanges.id}
        changeId={suggestedChanges.id}
        suggestion={suggestedChanges}
        entityType={entityType}
        defaultExpanded={defaultExpanded}
        isCreate
      />
    )
  }

  if (action === DESTROY_ACTION) {
    return (
      <DestroyOrCreateActionInput
        key={suggestedChanges.id}
        changeId={suggestedChanges.id}
        suggestion={suggestedChanges}
        defaultExpanded={defaultExpanded}
        entityType={entityType}
      />
    )
  }

  if (action === UPDATE_ACTION) {
    return suggestedChanges.map((suggestedChange) => {
      return Object.keys(suggestedChange.changes).map((registerType) => (
        <Box key={`${registerType}-${suggestedChange.id}`}>
          <Box p={2}>
            <UpdateActionInput
              changeId={suggestedChange.id}
              registerType={registerType}
              entityType={entityType}
              suggestion={suggestedChange.changes}
            />
          </Box>
          <Divider />
        </Box>
      ))
    })
  }

  return Object.keys(suggestedChanges).map((suggestedChangeKey) => {
    const entityType = humps.pascalize(pluralize.singular(action))
    if (suggestedChangeKey === UPDATE_ACTION) {
      const suggestedChange = suggestedChanges[
        suggestedChangeKey
      ].map((suggestionData) => ({ entityType, ...suggestionData }))

      return (
        <SuggestionCard
          key={`update-${suggestedChangeKey}`}
          tabData={suggestedChange}
          suggestions={suggestedChange}
          action={suggestedChangeKey}
        />
      )
    }

    return suggestedChanges[
      suggestedChangeKey
    ].map((suggestedChange, index) => (
      <CardRow
        key={`${suggestedChangeKey}-${suggestedChanges.id || index}`}
        suggestedChanges={suggestedChange}
        action={suggestedChangeKey}
        entityType={entityType}
        defaultExpanded
      />
    ))
  })
}

const SuggestionCard = ({ tabData, suggestions, action, ...rest }) => {
  const label = (suggestion) => {
    return `ID ${suggestion.entityId} - ${suggestion.name}`
  }

  const classes = useStyles()

  return suggestions?.map((suggestion, index) => {
    const keyChanges = suggestion?.suggestedChange
      ? 'suggestedChange'
      : 'suggestedChanges'

    const suggestionKeys = Object.keys(suggestion[keyChanges]).filter(
      (key) => key !== 'id',
    )

    return (
      <Box {...rest} key={suggestion.entityId || index}>
        <Card>
          {suggestion.action === UPDATE_ACTION && (
            <>
              <CardHeader title={label(suggestion)} />
              <Divider />
            </>
          )}
          <CardContent className={classes.cardContent}>
            {suggestionKeys.map((suggestedChangeKey) => {
              const isRootChange = suggestedChangeKey === 'changes'

              return (
                <CardRow
                  key={suggestedChangeKey + action}
                  entityType={suggestion.entityType}
                  suggestedChanges={
                    isRootChange
                      ? suggestion[keyChanges]
                      : suggestion[keyChanges][suggestedChangeKey]
                  }
                  action={isRootChange ? action : suggestedChangeKey}
                />
              )
            })}
          </CardContent>
        </Card>
      </Box>
    )
  })
}

CardRow.propTypes = {
  suggestedChanges: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  action: PropTypes.string,
  entityType: PropTypes.string,
  defaultExpanded: PropTypes.bool,
}

SuggestionCard.propTypes = {
  tabData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  suggestions: PropTypes.array,
  action: PropTypes.string,
}

CardRow.defaultValues = {
  defaultExpanded: false,
}

export default SuggestionCard
