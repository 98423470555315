import React from 'react'

import { Box } from '@material-ui/core'

const CardRow = ({ children, ...rest }) => (
  <Box display="flex" width="100%" alignContent="center" {...rest}>
    {children}
  </Box>
)

export default CardRow
