const styles = (theme) => ({
  root: {
    nameEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '18rem',
    },
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 20,
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    backgroundColor: theme.palette.subscription.primary.main,
  },
  title: {
    fontSize: 40,
    fontFamily: 'Raleway',
    fontWeight: 700,
    textAlign: 'center',
  },
  contentContainer: {
    padding: theme.spacing(2.5),
  },
  listContainer: {
    paddingBlock: theme.spacing(2.5),
  },
  description: {
    overflow: 'hidden',
    minHeight: 120,
    paddingBlock: theme.spacing(2.5),
  },
  partnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    padding: 20,
  },
  label: {
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    border: 'none',
    padding: 10,
    transition: '0.3s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      opacity: 0.9,
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.marketplace.card.hover,
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.secondary.superLight}`,
  },
  contentHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    gridGap: 8,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  contentHeaderBox: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
    },
  },
})

export default styles
