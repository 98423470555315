import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

import theme from 'theme'

const DialogRemoveUser = ({
  departmentId,
  userId,
  open,
  handleClose = () => {},
  unlinkUser = () => {},
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Remover usuário do departamento</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box py={1}>
          <Typography>
            Tem certeza que deseja remover o usuário deste departamento?
          </Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" gridGap={theme.spacing(1)} pl={2}>
          <Button type="button" variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={() => unlinkUser(departmentId, userId)}
          >
            Continuar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

DialogRemoveUser.propTypes = {
  departmentId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  userId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  unlinkUser: PropTypes.func.isRequired,
}

export default DialogRemoveUser
