const dynamicImageSize = (dimensions) => {
  // dimensios é um array
  // index 0: width base
  // index 1: height base
  // index 2: tipo(1,2,3)
  // todos os valores tem que ser enviado no tipo numerico ex: dimensions = [1011, 638, 1]
  // o retorno é um array atualizado com tres valores (width, height, 'titulo texto')

  if (dimensions[2] === 1) return [dimensions[0], dimensions[1], 'original']
  if (dimensions[2] === 2)
    return [
      dimensions[0] / dimensions[2],
      dimensions[1] / dimensions[2],
      'reduzido',
    ]

  return [
    dimensions[0] * (dimensions[2] - 1),
    dimensions[1] * (dimensions[2] - 1),
    'grande',
  ]
}

export default dynamicImageSize
