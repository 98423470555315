import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
  TextField,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { RHFInput } from 'react-hook-form-input'

import { Label } from 'components'
import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'
import { schema } from './schema'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

const DialogReopen = ({
  dataProcess,
  open,
  setOpen,
  setLoading,
  refresh,
  redirectTo,
}) => {
  const snackbar = useSnackbar()
  const { errors, handleSubmit, register, setValue } = useForm({
    validationSchema: schema,
    defaultValues: {
      description: '',
    },
  })
  const history = useHistory()

  const onSubmit = async (data) => {
    setLoading(true)

    const handleRedirectTo = (redirectTo) => {
      if (redirectTo) {
        return history.push(redirectTo)
      } else {
        refresh()
      }
    }
    try {
      await service.dponet.dataProcesses.reopen(
        { dataProcessId: dataProcess.id },
        data,
      )

      snackbar.open({
        message: 'Processamento de dados reaberto com sucesso',
        variant: 'success',
      })

      handleRedirectTo(redirectTo)
    } catch (error) {
      const { errors = null, message = null } = error.response.data.error

      snackbar.open({
        message: errors || message || 'Ocorreu um erro inesperado',
        variant: 'error',
      })
    }

    setLoading(false)
    setOpen(false)
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Reabrir processo</Typography>
        </Box>
      </DialogTitle>
      <Divider />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={1}>
          <DialogContent>
            <Label
              title="Motivo da reabertura"
              description="Descreva o motivo da reabertura"
            >
              <RHFInput
                as={
                  <TextField
                    color="primary"
                    multiline
                    rows={3}
                    variant="outlined"
                    error={!!errors.description}
                    helperText={
                      errors && errors.description && errors.description.message
                    }
                    fullWidth
                  />
                }
                register={register}
                setValue={setValue}
                name="description"
                mode="onBlur"
              />
            </Label>
          </DialogContent>
        </Box>
        <Divider />
        <DialogActions p={2}>
          <Box pl={2} pr={1}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
          </Box>
          <Button variant="contained" type="submit" color="primary">
            Reabrir
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

DialogReopen.propTypes = {
  dataProcess: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setLoading: PropTypes.func,
  refresh: PropTypes.func,
  redirectTo: PropTypes.node,
}

DialogReopen.defaultProps = {
  setOpen: () => {},
  setLoading: () => {},
  refresh: () => {},
}
export default DialogReopen
