import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import { Box, Divider, Typography, Collapse, Button } from '@material-ui/core'
import { MenuButton, Permitted, ToggleButton } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

import useStyles from './styles'

const FragilityItem = ({
  companyFragility,
  dataProcessId,
  fragilityItems,
  refresh,
  onlyShow,
}) => {
  const snackbar = useSnackbar()

  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()

  const handleHide = async () => {
    try {
      await service.dponet.companyFragilities.manageFragilityEntities({
        companyFragilityId: companyFragility?.id,
        toHide: [
          {
            id: dataProcessId,
          },
        ],
      })
      snackbar.open({
        message: 'Risco removido com sucesso',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        pl={2}
      >
        <Typography variant="subtitle2">{companyFragility?.name}</Typography>
        <Box display="flex">
          <ToggleButton
            size={18}
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          />
          <Permitted tag={constants.permissions.DATA_PROCESSES.CREATE}>
            {!companyFragility.isCompany && !onlyShow && (
              <MenuButton iconButtonFontSize={18}>
                <Button onClick={handleHide}>Excluir</Button>
              </MenuButton>
            )}
          </Permitted>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        {isEmpty(fragilityItems) && (
          <Box py={1} px={2}>
            <Typography variant="body1">
              Não foram encontradas medidas de seguranças para este risco
            </Typography>
          </Box>
        )}
        {fragilityItems?.map((item) => (
          <React.Fragment key={item?.id}>
            <Box py={1} px={2} width="100%" display="flex" alignItems="center">
              <Box mr={2}>
                <Typography variant="body1">
                  #{item?.questionControlId}
                </Typography>
              </Box>
              <Typography variant="body1">{item?.title}</Typography>
            </Box>
            <Divider />
          </React.Fragment>
        ))}
      </Collapse>
    </Box>
  )
}

FragilityItem.propTypes = {
  companyFragility: PropTypes.object,
  dataProcessId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fragilityItems: PropTypes.array,
  refresh: PropTypes.func,
  setLoading: PropTypes.func,
  onlyShow: PropTypes.bool,
}

export default FragilityItem
