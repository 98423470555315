import constants from 'constants/index'
import helpers from 'helpers'

const getStepId = (step, trailSteps) => {
  const {
    STEP_LOGO,
    STEP_SITE,
    STEP_COOKIE,
    STEP_CONFIG,
    STEP_DOCS,
    STEP_CONCLUSION,
    COMPANY_LOGO_TAG,
    COMPANY_SITE_TAG,
    COOKIE_BANNER_TAG,
    SITE_CONFIGURATION_TAG,
    COMPANY_DOCUMENTS_TAG,
    CONCLUSION_TAG,
  } = constants.userTrailSteps

  switch (step) {
    case STEP_LOGO:
      return helpers.userTrailSteps.getTrailIdByTag(
        COMPANY_LOGO_TAG,
        trailSteps,
      )
    case STEP_SITE:
      return helpers.userTrailSteps.getTrailIdByTag(
        COMPANY_SITE_TAG,
        trailSteps,
      )
    case STEP_COOKIE:
      return helpers.userTrailSteps.getTrailIdByTag(
        COOKIE_BANNER_TAG,
        trailSteps,
      )
    case STEP_CONFIG:
      return helpers.userTrailSteps.getTrailIdByTag(
        SITE_CONFIGURATION_TAG,
        trailSteps,
      )
    case STEP_DOCS:
      return helpers.userTrailSteps.getTrailIdByTag(
        COMPANY_DOCUMENTS_TAG,
        trailSteps,
      )
    case STEP_CONCLUSION:
      return helpers.userTrailSteps.getTrailIdByTag(CONCLUSION_TAG, trailSteps)
    default:
      return null
  }
}

export default getStepId
