import constants from 'constants/index'

const convertDataToApi = (data, kind, planId, couponValue = undefined) => {
  var kindData = {
    fantasyName: data?.fantasyName || data?.companyName || '',
    document: data?.cnpj,
  }

  if (kind === constants.preRegistrations.PERSONAL_KIND) {
    kindData = {
      description: data?.description || '',
      document: data?.cpf,
    }
  }

  return {
    preRegistration: {
      ...kindData,
      name: data?.name,
      discountCouponCode: couponValue,
      kind: kind,
      phone: data?.phone,
      email: data?.email,
      planId: planId,
      address: {
        postalCode: data?.postalCode,
        country: data?.country || 'Brasil',
        state: data?.state,
        city: data?.city,
        complement: data?.complement,
        neighborhood: data?.neighborhood,
        street: data?.street,
        number: data?.number,
      },
      responsibleUser: {
        name: data?.responsibleName,
        email: data?.responsibleEmail,
        phone: data?.responsiblePhone,
      },
    },
  }
}

export default convertDataToApi
