import { makeStyles } from '@material-ui/styles'

const styles = makeStyles(() => ({
  advantagesCard: {
    background: '#EFF9DF',
    borderRadius: '4px',
    border: '1px solid #E2E4E0',
    shadowColor: 'black',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  iconColor: {
    color: '#80B92B',
  },
}))

export default styles
