import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.firstStep.background.card,
    borderRadius: 20,
    height: 280,
    width: '100%',
    cursor: 'pointer',
  },
  active: {
    boxShadow: `0px 3px 6px ${theme.colors.firstStep.button.primary}29`,
    border: `4px solid ${theme.colors.firstStep.button.primary}`,
  },
  checkbox: {
    padding: 0,
    '& .MuiSvgIcon-root': {
      fontSize: '1.4em',
    },
  },
  primaryCheckbox: {
    color: `${theme.colors.firstStep.button.primary} !important`,
  },
  price: {
    gap: '1.32px',
  },
}))

export default styles
