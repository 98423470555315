import changeLogs from './changeLogs'
import formattedValue from './formattedValue'
import logTitleByAction from './logTitleByAction'
import separateLogsByDate from './separateLogsByDate'
import tableCellColor from './tableCellColor'
import kindType from './kindType'
import questionStatus from './questionStatus'

export default {
  changeLogs,
  formattedValue,
  logTitleByAction,
  separateLogsByDate,
  tableCellColor,
  kindType,
  questionStatus,
}
