import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import { Controller } from 'react-hook-form'

import { Select, LoadingFeedback } from 'components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'

const ConsentForm = ({ control, watch, errors, setConsentForms }) => {
  const { response, isLoading } = useFetch(service.dponet.consent.list, {}, [])

  const consentForms = response?.data?.consentForms

  const consentFormId = watch('consentFormId')

  const { response: responseForm } = useFetch(
    service.dponet.consent.getStats,
    {
      consentFormId,
    },
    [consentFormId],
    !!consentFormId,
  )

  useEffect(() => {
    setConsentForms([])
    if (responseForm?.data?.consentForms) {
      setConsentForms(responseForm?.data?.consentForms)
    }
  }, [responseForm, setConsentForms])

  return (
    <Box display="flex" width="100%" paddingBottom={1}>
      <LoadingFeedback open={isLoading} />
      {!isLoading && (
        <Box display="flex" flexDirection="column" width="100%">
          <Controller
            control={control}
            name="consentFormId"
            as={
              <Select
                items={consentForms || []}
                fieldName="title"
                error={!!errors.consentFormId}
                helperText={errors?.consentFormId?.message}
                label="Selecione o formulário"
              />
            }
            mode="onChange"
          />
        </Box>
      )}
    </Box>
  )
}

export default ConsentForm
