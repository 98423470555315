import React from 'react'
import PropTypes from 'prop-types'
import { TextField, Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import useConsent from 'hooks/useConsent'
import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'

const ConsentCustomButtonForm = ({ onCloseDialog }) => {
  const snackbar = useSnackbar()

  const { setDataConsentForm, dataConsentForm } = useConsent()

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      buttonText: dataConsentForm?.buttonText ?? 'EU AUTORIZO',
    },
  })

  const onSubmit = async (data) => {
    try {
      const mergedData = {
        ...dataConsentForm,
        ...data,
      }

      setDataConsentForm(mergedData)
    } catch (error) {
      snackbar.open({
        message: 'Ocorreu um erro! Tente novamente.',
        variant: 'error',
      })
    } finally {
      onCloseDialog()
    }
  }

  return (
    <form id="consent-form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Nome do botão de envio"
                fullWidth
                error={!!errors.buttonText}
                helperText={errors?.buttonText?.message}
              />
            }
            control={control}
            name="buttonText"
            mode="onBlur"
          />
        </Grid>
      </Grid>
    </form>
  )
}

ConsentCustomButtonForm.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
}

export default ConsentCustomButtonForm
