import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button } from '@material-ui/core'

import { ReplyTicketForm } from 'components'

import { routes } from 'Routes'

const ReplyTicket = ({ onSubmit = () => {} }) => {
  const history = useHistory()

  return (
    <>
      <ReplyTicketForm onSubmit={onSubmit} />
      <Box display="flex" justifyContent="end" mt={2}>
        <Button
          variant="contained"
          onClick={() => history.push(routes.technicalFaqs.tickets)}
        >
          Cancelar
        </Button>
        <Box ml={1}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="reply-ticket-form"
          >
            Enviar
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default ReplyTicket
