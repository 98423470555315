import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'

import { X as CloseIcon, AlertTriangle as Alert } from 'react-feather'

import styles from './styles'
const useStyles = makeStyles(styles)

const DialogUpdateDataQuestionnaires = ({
  open,
  setOpen,
  handleAction,
  title,
  subtitle,
}) => {
  const classes = useStyles()

  const closeDialog = () => {
    setOpen(false)
  }

  const handleupdate = () => {
    handleAction()
    closeDialog()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={closeDialog}
        maxWidth="sm"
        classes={{ paper: classes.dialogAlert }}
      >
        <Box width="100%" display="flex" justifyContent="flex-end" mr={-2}>
          <IconButton
            size="small"
            onClick={closeDialog}
            color="primary"
            aria-label="close modal"
          >
            <CloseIcon color="white" />
          </IconButton>
        </Box>
        <DialogTitle id="form-dialog-title">
          <Box display="flex" alignItems="center" flexDirection="column">
            <Alert color="white" size={60} />

            <Box mt={2}>
              <Typography variant="h2" className={classes.fontTitle}>
                {title}
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          <Typography variant="h5" className={classes.colorWhite}>
            {subtitle}
          </Typography>
        </Box>

        <Box display="flex">
          <Box display="flex" justifyContent="flex-start" mt={5} width="50%">
            <Button
              className={classes.button}
              variant="contained"
              onClick={closeDialog}
            >
              Voltar
            </Button>
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={5} width="50%">
            <Button
              className={classes.button}
              variant="contained"
              onClick={handleupdate}
            >
              Atualizar
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

DialogUpdateDataQuestionnaires.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default DialogUpdateDataQuestionnaires
