import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import { SVG } from 'components'

const BoxPolygon = ({ data, classes }) => {
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      className={classes.polygonContainer}
    >
      {data.map((values, index) => (
        <Box key={index} {...values.properties} className={classes.polygonBox}>
          {values?.type === 'outline' && (
            <SVG.PolygonOutline
              stroke={values?.polygonProps?.stroke}
              height={values?.polygonProps?.height}
              width={values?.polygonProps?.width}
            />
          )}
          {values?.type === 'contained' && (
            <SVG.PolygonContained
              stroke={values?.polygonProps?.stroke}
              height={values?.polygonProps?.height}
              width={values?.polygonProps?.width}
              fill={values?.polygonProps?.fill}
            />
          )}
          <Box className={classes.polygonValues}>
            <Typography
              variant="h4"
              className={clsx(classes.labelTypography, {
                [classes.specialLabelTypography]: values?.type === 'contained',
              })}
            >
              {values.value}
            </Typography>
            <Typography
              variant="body1"
              className={clsx(classes.labelTypography, {
                [classes.specialLabelTypography]: values?.type === 'contained',
              })}
            >
              {values.label}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

BoxPolygon.propTypes = {
  data: PropTypes.array,
}

export default BoxPolygon
