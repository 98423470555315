import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Tooltip } from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { range } from 'lodash'

import {
  Page,
  ContentHeader,
  Container,
  AdvisorCard,
  ConfirmationDialog,
  Permitted,
  DocumentCard,
  DocumentCardSkeleton,
  DocumentFilter,
  DocumentDialog,
  DriveTour,
} from 'components'

import helpers from 'helpers'

import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'

import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'
import documentAdvisorImage from 'images/document_advisor.png'

const DocumentsMain = () => {
  const classes = useStyles()
  const filter = useFilter()
  const { mixpanel } = helpers

  const localStorageDocumentAdvisor = localStorage.getItem(
    constants.documents.ADVISOR_CARD_DOCUMENT,
  )
  const advisorHasAlreadyBeenClosed =
    localStorageDocumentAdvisor !== constants.documents.ADVISOR_CARD_CLOSED

  const [openDialog, setOpenDialog] = useState(false)
  const [openDestroyDialog, setOpenDestroyDialog] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState({})
  const [action, setAction] = useState('show')
  const [showAdvisor, setShowAdvisor] = useState(advisorHasAlreadyBeenClosed)
  const [openTour, setOpenTour] = useState(false)

  const {
    response: companyDocumentsResponse,
    refresh,
    isLoading,
  } = useFetch(
    service.dponet.companyDocuments.get,
    {
      perPage: 1000,
      ...filter.filters,
    },
    [filter.filters],
  )

  const { response: documentCategoriesResponse, refresh: refreshCategories } =
    useFetch(service.dponet.companyDocuments.getCategories)

  const companyDocuments = companyDocumentsResponse?.data?.documents
  const documentCategories =
    documentCategoriesResponse?.data?.documentCategories

  const resetDocumentState = () => {
    setSelectedDocument({})
  }

  const handleCloseAdvisor = () => {
    localStorage.setItem(
      constants.documents.ADVISOR_CARD_DOCUMENT,
      constants.documents.ADVISOR_CARD_CLOSED,
    )
    setShowAdvisor(false)
  }

  const handleOpenDialog = (action, document) => {
    if (action === 'destroy') setOpenDestroyDialog(true)
    else setOpenDialog(true)

    setSelectedDocument(document)
    setAction(action)
  }

  const handleCloseDialog = () => {
    if (action === 'destroy') setOpenDestroyDialog(false)
    else setOpenDialog(false)
  }

  const reloadDocuments = () => {
    handleCloseDialog()
    refresh()
    refreshCategories()
  }

  const destroyAction = async () => {
    await service.dponet.companyDocuments.destroy({
      documentId: selectedDocument?.id,
    })
    reloadDocuments()
  }

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Documentos', 'Tour', {
      action: 'tour-meus-documentos-documentos',
    })
  }

  useEffect(() => {
    if (!openDialog) resetDocumentState()
  }, [openDialog])

  return (
    <Page title="Documentos">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Documentos">
          <Permitted tag={constants.permissions.DOCUMENT.CREATE}>
            <Tooltip title="Iniciar o tour guiado">
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<HelpCircleIcon size={20} />}
                  onClick={handleOpenTour}
                  fullWidth
                  id={constants.documents.DOCUMENTS_MAIN_DRIVER_STEP_1}
                >
                  Tutorial
                </Button>
              </Box>
            </Tooltip>
            <Box paddingX={1}>
              <Button
                color="primary"
                variant="contained"
                className={classes.headerActionButton}
                onClick={() => handleOpenDialog('create', {})}
                id={constants.documents.DOCUMENTS_MAIN_DRIVER_STEP_2}
              >
                Novo documento
              </Button>
            </Box>
          </Permitted>
          <DocumentFilter filter={filter} categories={documentCategories} />
        </ContentHeader>

        {showAdvisor && (
          <AdvisorCard
            imgSrc={documentAdvisorImage}
            handleClose={handleCloseAdvisor}
            title="Cadastre e armazene os documentos da sua empresa."
            subtitle="Gerencie os documentos de conformidade da sua empresa dentro da própria plataforma, através de links de redirecionamento para as suas pastas e arquivos na nuvem. Os seus documentos ficam organizados e seguros para facilitar a sua gestão"
          />
        )}

        <Grid container spacing={3}>
          {isLoading
            ? range(8).map((i) => <DocumentCardSkeleton key={i} />)
            : companyDocuments?.map((document) => (
                <DocumentCard
                  key={document.id}
                  document={document}
                  handleOpenDialog={handleOpenDialog}
                />
              ))}
        </Grid>
      </Container>

      <DocumentDialog
        action={action}
        document={selectedDocument}
        onClose={handleCloseDialog}
        open={openDialog}
        categories={documentCategories || []}
        reloadDocuments={reloadDocuments}
      />
      <ConfirmationDialog
        open={openDestroyDialog}
        setOpen={setOpenDestroyDialog}
        text="Você tem certeza que deseja excluir o documento?"
        textButton="Excluir"
        textButtonCancel="Cancelar"
        actionAcceptButton={destroyAction}
      />

      <DriveTour
        stepsMatrix={constants.documents.DOCUMENTS_DRIVER_STEPS_OBJECT}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default DocumentsMain
