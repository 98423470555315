import React, { useEffect } from 'react'
import { Controller, FormContext, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {
  Box,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { TextFieldMenu } from 'components'

import helpers from 'helpers'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'
import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'

const AuditsForm = ({
  auditConfigId,
  automationData,
  companyAudit,
  disabledInputs = false,
  formRef,
  handleBack,
  isAutomation,
  isNew = false,
  isShow = false,
  previewTooltip = '',
  refresh = () => {},
  setLoading,
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const { response, isLoading } = useFetch(
    service.dponet.departments.getAll,
    {
      perPage: 1000,
      active: true,
      order: 'departments.name ASC',
    },
    [],
  )

  const departments = response?.data?.departments
  const defaultDepartments = departments?.filter((department) =>
    companyAudit?.departmentIds?.includes(department?.id),
  )

  const { control, errors, handleSubmit, reset, setValue, watch, ...formRest } =
    useForm({
      validationSchema: schema(isAutomation),
      defaultValues: {
        applicationChoice: companyAudit
          ? isEmpty(companyAudit?.departmentIds)
            ? 'company'
            : 'departments'
          : '',
        departments: [],
        sampling:
          helpers.formatters.float.input(String(companyAudit?.sampling)) || '',
        startDate: companyAudit?.startDate || null,
        ...(isAutomation
          ? {
              periodType: automationData?.periodType || 'months',
              recurrence: String(automationData?.recurrence) || '',
            }
          : {
              endDate: companyAudit?.endDate || null,
            }),
      },
    })

  const applicationChoice = watch('applicationChoice')
  const departmentsControl = watch('departments')

  const getDepartmentIds = (departments) => {
    return departments ? departments?.map((department) => department?.id) : []
  }

  const isDepartment =
    applicationChoice === constants.audits.APPLICATION_CHOICE_DEPARTMENTS_ID

  const datePickerControls = isAutomation
    ? constants.audits.KEYBOARD_DATE_PICKER_CONTROL_FORM.slice(0, 1)
    : constants.audits.KEYBOARD_DATE_PICKER_CONTROL_FORM

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const departmentIds = getDepartmentIds(data?.departments)
      const sampling = helpers.formatters.float.convert(data?.sampling)
      const startDate = helpers.formatters.toISOStringVerify(data?.startDate)
      const endDate = helpers.formatters.toISOStringVerify(data?.endDate)

      let formData = {
        departmentIds,
        startDate,
        sampling,
      }

      if (isAutomation)
        formData = {
          ...formData,
          periodType: data?.periodType,
          recurrence: data?.recurrence,
        }
      else formData = { ...formData, endDate }

      if (isAutomation)
        await service.dponet.auditConfigs.update({
          auditConfigId,
          ...formData,
        })
      else if (isNew) await service.dponet.companyAudits.create({ ...formData })
      else
        await service.dponet.companyAudits.update({
          auditId: companyAudit?.id,
          ...formData,
        })

      snackbar.open({
        message: `Auditoria ${isNew ? 'criada' : 'editada'} com sucesso!`,
        variant: 'success',
      })
      reset()
      refresh()
      handleBack()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (applicationChoice === 'company') setValue('departments', [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationChoice])

  useEffect(() => {
    if (isEmpty(departmentsControl) && !isEmpty(defaultDepartments))
      setValue('departments', defaultDepartments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <FormContext
      control={control}
      errors={errors}
      handleSubmit={handleSubmit}
      reset={reset}
      setValue={setValue}
      watch={watch}
      {...formRest}
    >
      <form onSubmit={handleSubmit(onSubmit)} id={formRef}>
        <Paper className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Aplicar auditoria para:</Typography>
              <Tooltip title={previewTooltip}>
                <Box width="fit-content">
                  <Controller
                    control={control}
                    name="applicationChoice"
                    as={
                      <RadioGroup row>
                        {constants.audits.APPLICATION_CHOICE_FORM_CONTROL_LABEL.map(
                          (item, index) => (
                            <FormControlLabel
                              key={index}
                              value={item?.value}
                              disabled={isShow || disabledInputs}
                              control={
                                <Radio
                                  size="small"
                                  className={
                                    !!errors?.applicationChoice
                                      ? classes.error
                                      : ''
                                  }
                                />
                              }
                              label={item?.label}
                              className={
                                !!errors?.applicationChoice ? classes.error : ''
                              }
                            />
                          ),
                        )}
                      </RadioGroup>
                    }
                  />
                </Box>
              </Tooltip>
              {!!errors.applicationChoice && (
                <FormHelperText error={!!errors.applicationChoice}>
                  <>{errors.applicationChoice.message}</>
                </FormHelperText>
              )}
            </Grid>
            {isDepartment && (
              <Grid item xs={12}>
                <Tooltip title={previewTooltip}>
                  <Box>
                    <Controller
                      control={control}
                      name="departments"
                      mode="onChange"
                      onChange={([, data]) => data}
                      as={
                        <Autocomplete
                          multiple
                          options={departments ?? []}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) =>
                            option.id === value.id || value.id === 0
                          }
                          disabled={isShow || isLoading || disabledInputs}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Departamento"
                              error={!!errors?.departments}
                              helperText={<>{errors?.departments?.message}</>}
                              disabled={isShow || isLoading || disabledInputs}
                              InputProps={{
                                ...(isLoading
                                  ? {
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <CircularProgress size={22} />
                                        </InputAdornment>
                                      ),
                                    }
                                  : { ...params.InputProps }),
                              }}
                              fullWidth
                            />
                          )}
                        />
                      }
                    />
                  </Box>
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={4}>
              <Tooltip title={previewTooltip}>
                <Box>
                  <Controller
                    control={control}
                    name="sampling"
                    onChange={([event]) =>
                      helpers.formatters.float.input(event.target.value)
                    }
                    as={
                      <TextField
                        variant="outlined"
                        label="Amostragem"
                        error={!!errors?.sampling}
                        helperText={<>{errors?.sampling?.message}</>}
                        InputProps={{
                          endAdornment: '%',
                        }}
                        disabled={isShow || disabledInputs}
                        fullWidth
                      />
                    }
                  />
                </Box>
              </Tooltip>
            </Grid>
            {datePickerControls?.map((item, index) => (
              <Grid key={index} item xs={4}>
                <Tooltip title={previewTooltip}>
                  <Box>
                    <Controller
                      control={control}
                      name={item?.name}
                      mode="onChange"
                      as={
                        <KeyboardDatePicker
                          fullWidth
                          format="DD/MM/yyyy"
                          label={item?.label}
                          inputVariant="outlined"
                          onChange={(newValue) => ({
                            value: newValue,
                          })}
                          error={!!errors?.[item?.name]}
                          helperText={<>{errors?.[item?.name]?.message}</>}
                          disablePast
                          shouldDisableDate={(date) =>
                            date.isSame(new Date(), 'day')
                          }
                          disabled={isShow || disabledInputs}
                          clearable
                        />
                      }
                    />
                  </Box>
                </Tooltip>
              </Grid>
            ))}
            {isAutomation && (
              <Grid item xs={4}>
                <Tooltip title={previewTooltip}>
                  <Box>
                    <TextFieldMenu
                      controlNameValue="recurrence"
                      controlNameType="periodType"
                      formatter={helpers.formatters.onlyNumbers}
                      disabled={disabledInputs}
                    />
                  </Box>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Paper>
      </form>
    </FormContext>
  )
}

AuditsForm.propTypes = {
  auditConfigId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  automationData: PropTypes.object,
  companyAudit: PropTypes.object,
  formRef: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
  isAutomation: PropTypes.bool,
  isNew: PropTypes.bool,
  isPreview: PropTypes.bool,
  isShow: PropTypes.bool,
  previewTooltip: PropTypes.string.isRequired,
  refresh: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
}

export default AuditsForm
