import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ liaQuestionId = '', ...params }) => {
  return await dponetAPI.get(`/lia_questions/${liaQuestionId}`, { params })
}

const getWithAnswers = async ({ ...params }) => {
  return await dponetAPI.get('/lia_questions/list_questions_with_answers', {
    params,
  })
}

const liaQuestions = { get, getWithAnswers }

export default liaQuestions
