import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  cardBox: {
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: 250,
    flex: 1,
  },
  boxVideoPlayer: {
    width: 425,
    height: 250,
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
    },
    marginLeft: theme.spacing(1),
  },
  paperContainer: {
    padding: theme.spacing(1),
  },
  expandedCard: {
    flexGrow: 2,
  },
  videoPlayer: {
    width: '100%',
    borderRadius: 5,
    [theme.breakpoints.down('xs')]: {
      height: 270,
    },
    [theme.breakpoints.up('sm')]: {
      height: 255,
    },
    [theme.breakpoints.up('md')]: {
      height: 240,
    },
    [theme.breakpoints.up('lg')]: {
      height: 250,
    },
    [theme.breakpoints.up('xl')]: {
      height: 250,
    },
  },
}))

export default styles
