import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: 'none',
  },
  descriptionBox: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: 8,
    margin: '16px 0',
  },
}))

export default useStyles
