import api from 'service/api'

const dponetAPI = api.create('dponet')

const create = async ({ ...data }) => {
  return await dponetAPI.post(`/company_free_trials`, data)
}

const notify_hire = async () => {
  return await dponetAPI.get('/company_free_trials/notify_hire')
}

export default { create, notify_hire }
