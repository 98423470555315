import React from 'react'
import clsx from 'clsx'

import { Box, Dialog, Typography, Divider, Grid } from '@material-ui/core'

import constants from 'constants/index'
import useStyle from './styles'

const AboutModal = ({ open, setOpen, activeStep }) => {
  const classes = useStyle()
  const handleClose = () => setOpen(false)

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="md"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography className={clsx(classes.textFormat, classes.titleText)}>
            O que diz a LGPD?
          </Typography>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item xs={12} container className={classes.modalTextGrid}>
          <Grid item xs={12}>
            <Typography
              className={clsx(
                classes.textFormat,
                classes.bodyText,
                classes.textSpacing,
              )}
            >
              {activeStep &&
                activeStep <= 22 &&
                constants.questionnaire.HELPER_TEXT[activeStep][0]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Typography
                className={clsx(
                  classes.textFormat,
                  classes.titleText,
                  classes.dponetDivider,
                )}
              >
                O que a DPOnet pode fazer por você?
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={1} mb={3}>
              <Typography
                className={clsx(classes.textFormat, classes.bodyText)}
              >
                {activeStep &&
                  activeStep <= 22 &&
                  constants.questionnaire.HELPER_TEXT[activeStep][1]}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default AboutModal
