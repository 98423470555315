import { colors } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    border: `1px solid ${colors.grey[300]}`,
    borderRadius: '4px',
    paddingBottom: '0px',
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  titleActionContents: {
    fontSize: 18,
  },
  radioGroup: {
    flexWrap: 'noWrap',
  },
  avatarContainer: {
    backgroundColor: colors.grey[200],
    height: '40px',
    width: '40px',
    borderRadius: '40px',
  },
  iconStyle: {
    color: colors.grey[700],
  },
  radioGroupContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  checkedEliminate: {
    color: `${colors.red[500]} !important`,
  },
  checkedNotEliminate: {
    color: `${colors.green[500]} !important`,
  },
})

export default styles
