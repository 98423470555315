import * as yup from 'yup'

export const schema = yup.object().shape({
  name: yup.string().required(),
  number: yup.string().required(),
  cvv: yup.string().required(),
  month: yup.string().required(),
  year: yup.string().required(),
})

export default schema
