import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import useCustomTheme from 'hooks/useCustomTheme'

import helpers from 'helpers'

const ManagementPdf = ({
  exportingPdf,
  fragilityStats,
  gapStats,
  generalStats,
  processStats,
  questionnaires,
  questionControlStats,
  refresh,
  setExportingPdf,
  setLoading,
  shouldCreateVersion,
  supplierStats,
}) => {
  const snackbar = useSnackbar()
  const { customTheme, customLogo, customPalette } = useCustomTheme()
  const { userCompany } = useAuth()

  const customStyles = customTheme?.palette?.layout

  useEffect(() => {
    if (exportingPdf) {
      helpers.reports.dashboard.exportPdf({
        customLogo,
        customPalette,
        customStyles,
        fragilityStats,
        gapStats,
        generalStats,
        processStats,
        questionControlStats,
        questionnaires,
        refresh,
        setExportingPdf,
        setLoading,
        shouldCreateVersion,
        snackbar,
        supplierStats,
        userCompany,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportingPdf])

  return <></>
}

ManagementPdf.propTypes = {
  setExportingPdf: PropTypes.func,
  fragilities: PropTypes.array,
}

ManagementPdf.defaultProps = {
  setExportingPdf: () => {},
}

export default ManagementPdf
