import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.text.backgroundInputColor,
    padding: theme.spacing(2),
  },
  chip: {
    margin: '-5px',
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.dark,
    '&:hover': {
      opacity: 0.8,
    },
  },
}))

export default styles
