import React from 'react'

import { DisapprovedLia, PendingLia } from './components'
import { ApprovedLia, ReviewLia } from '../../components'

import constants from 'constants/index'

const Finality = ({
  dataProcess = {},
  handleNext = () => {},
  questionsPendingAnswers = [],
  questionsDisapprovedAnswers = [],
  questionsReviewDisapprovedAnswers = [],
  questionsApprovedAnswers = [],
  answersReviewAndApproved = [],
  hasBalancingStep = false,
  questions = [],
  show = false,
}) => {
  const {
    PENDING_LIA,
    DISAPPROVED_LIA,
    REVIEW_LIA_DPO,
    REVIEW_LIA_CLIENT,
    APPROVED_STATUS,
  } = constants.dataProcess
  const { STEP_LABELS } = constants.liaQuestions

  const statusPendingLia = dataProcess?.statusId === PENDING_LIA
  const statusDisapprovedLia = dataProcess?.statusId === DISAPPROVED_LIA
  const statusReviewLia = dataProcess?.statusId === REVIEW_LIA_CLIENT
  const statusApproved = dataProcess?.statusId === APPROVED_STATUS
  const statusReviewLiaDpo = dataProcess?.statusId === REVIEW_LIA_DPO

  return (
    <>
      {statusPendingLia && (
        <PendingLia
          dataProcess={dataProcess}
          handleNext={handleNext}
          questionsPendingAnswers={questionsPendingAnswers}
          questionsApprovedAnswers={questionsApprovedAnswers}
          questions={questions}
          show={show}
        />
      )}
      {statusDisapprovedLia && (
        <DisapprovedLia
          dataProcess={dataProcess}
          handleNext={handleNext}
          questionsReviewDisapprovedAnswers={questionsReviewDisapprovedAnswers}
          questionsDisapprovedAnswers={questionsDisapprovedAnswers}
          hasBalancingStep={hasBalancingStep}
        />
      )}
      {statusReviewLia && (
        <ReviewLia
          handleNext={handleNext}
          questionsPendingAnswers={questionsPendingAnswers}
          questionsReviewDisapprovedAnswers={questionsReviewDisapprovedAnswers}
          questionsApprovedAnswers={questionsApprovedAnswers}
          finality
        />
      )}
      {(statusApproved || statusReviewLiaDpo) && (
        <ApprovedLia
          handleNext={handleNext}
          answersReviewAndApproved={answersReviewAndApproved}
          questions={questions}
          stepLabel={STEP_LABELS.FINALITY_LABEL}
        />
      )}
    </>
  )
}

export default Finality
