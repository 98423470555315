const styles = (theme) => ({
  alertBox: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '4px',
    margin: theme.spacing(3, 0),
    width: '100%',
  },
  text: {
    padding: theme.spacing(2.5),
  },
  title: {
    color: theme.palette.background.default,
    paddingBottom: '10px',
  },
  description: {
    color: theme.palette.background.default,
    fontWeight: '500',
  },
  button: {
    marginTop: theme.spacing(2.5),
    color: theme.palette.background.default,
    borderColor: theme.palette.background.default,
    marginRight: theme.spacing(2.5),
  },
})

export default styles
