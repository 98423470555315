import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Drawer } from '@material-ui/core'

import styles from './styles'

import Content from '../Content'

import useDrawer from 'hooks/useDrawer'

const useStyles = makeStyles(styles)

const MiniDrawer = () => {
  const classes = useStyles()
  const { fixedDrawer } = useDrawer()

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: fixedDrawer,
        [classes.drawerClose]: !fixedDrawer,
      })}
      classes={{
        paper: clsx(classes.drawer, {
          [classes.drawerOpen]: fixedDrawer,
          [classes.drawerClose]: !fixedDrawer,
        }),
      }}
    >
      <Content />
    </Drawer>
  )
}

export default MiniDrawer
