const styles = (theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.menu,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.layout.secondary,
    },
  },
  buttonLeaf: {
    color: theme.palette.text.menu,
    padding: '8px 6px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.layout.secondary,
    },
  },
  selectedButton: {
    backgroundColor: theme.palette.layout.secondary,
  },
  iconButton: {
    color: theme.palette.text.menu,
    padding: '8px',
    width: '100%',
    textTransform: 'none',
    letterSpacing: 0,
    borderRadius: 12,
    '&:hover': {
      backgroundColor: theme.palette.layout.secondary, //
    },
  },
  buttonDisabled: {
    color: '#4D9ABC !important',
  },
  iconButtonIcon: {
    color: theme.palette.layout.textPrimary,
    '&:hover': {
      backgroundColor: theme.palette.layout.secondary,
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.layout.textPrimary,
    maxWidth: 20,
  },
  expandedIcon: {
    color: theme.palette.layout.textPrimary,
  },
  title: {
    marginRight: 'auto',
    color: theme.palette.layout.textPrimary,
    textWrap: 'wrap',
  },
  active: {
    color: theme.palette.layout.textPrimary,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.layout.textPrimary,
    },
  },
  badgeStyle: {
    paddingLeft: '6px',
    paddingRight: '6px',
    borderRadius: 10,
    background: theme.palette.navigator.selectedColor,
    fontSize: '10px',
    color: theme.palette.white,
  },
})

export default styles
