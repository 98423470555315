import React, { useState } from 'react'

import ContentHeaderContext from 'contexts/ContentHeaderContext'

const ContentHeaderProvider = ({ children }) => {
  const [historyContentHeader, setHistoryContentHeader] = useState([])
  const [lastScopeContentHeader, setLastScopeContentHeader] = useState('')
  const [isReloadAction, setIsReloadAction] = useState(false)

  window.addEventListener('load', function (event) {
    return setIsReloadAction(true)
  })

  // Apenas passar objetos que estão no arquivo helpers/formatters/ContentHeader.js
  const onChangePage = (currentPathObjectFormatted, currentListPath) => {
    const currentScope = currentListPath?.url.split('/')[1]
    const scopeIsTheSame = currentScope === lastScopeContentHeader

    const currentPathIsList =
      currentPathObjectFormatted?.url === currentListPath?.url

    let auxiliaryHistoryContentHeaderArray = historyContentHeader

    const isDuplicatedObject =
      historyContentHeader?.filter(
        (history) => history?.label === currentPathObjectFormatted.label,
      ).length > 0

    const localStorageArray = JSON.parse(
      localStorage.getItem('lastArrayContentHeader'),
    )
    setLastScopeContentHeader(currentScope)

    return rulesOfFuntionaty(
      currentPathIsList,
      scopeIsTheSame,
      currentPathObjectFormatted,
      currentScope,
      localStorageArray,
      isDuplicatedObject,
      auxiliaryHistoryContentHeaderArray,
    )
  }

  const setDefaultArray = (currentPathObjectFormatted) => {
    const defaultArray = [currentPathObjectFormatted]

    setHistoryContentHeader(defaultArray)
    localStorage.setItem(
      'lastArrayContentHeader',
      JSON.stringify(defaultArray || []),
    )

    return defaultArray
  }

  const setLocalStorageArrayAsDefault = (localStorageArray) => {
    setHistoryContentHeader(localStorageArray)
    localStorage.setItem(
      'lastArrayContentHeader',
      JSON.stringify(localStorageArray || []),
    )

    return localStorageArray
  }

  const addItemToDefaultArray = (
    auxiliaryHistoryContentHeaderArray,
    currentPathObjectFormatted,
  ) => {
    auxiliaryHistoryContentHeaderArray.push(currentPathObjectFormatted)

    localStorage.setItem(
      'lastArrayContentHeader',
      JSON.stringify(auxiliaryHistoryContentHeaderArray || []),
    )
    return auxiliaryHistoryContentHeaderArray
  }

  const rulesOfFuntionaty = (
    currentPathIsList,
    scopeIsTheSame,
    currentPathObjectFormatted,
    currentScope,
    localStorageArray,
    isDuplicatedObject,
    auxiliaryHistoryContentHeaderArray,
  ) => {
    const RmcTodataProcess =
      lastScopeContentHeader === 'non-compliance-reports' &&
      currentScope === 'data-processes'

    const dataProcessToRmc =
      lastScopeContentHeader === 'data-processes' &&
      currentScope === 'non-compliance-reports'

    if (isReloadAction) {
      setIsReloadAction(false)
      return setLocalStorageArrayAsDefault(localStorageArray)
    }

    if (!scopeIsTheSame) {
      if (!dataProcessToRmc && !RmcTodataProcess) {
        return setDefaultArray(currentPathObjectFormatted, currentScope)
      } else {
        if (!isDuplicatedObject) {
          return addItemToDefaultArray(
            auxiliaryHistoryContentHeaderArray,
            currentPathObjectFormatted,
          )
        }
      }

      return setLocalStorageArrayAsDefault(localStorageArray)
    } else {
      if (currentPathIsList) {
        return setDefaultArray(currentPathObjectFormatted, currentScope)
      } else {
        if (!isDuplicatedObject) {
          return addItemToDefaultArray(
            auxiliaryHistoryContentHeaderArray,
            currentPathObjectFormatted,
          )
        } else {
          if (localStorageArray?.length >= 2) {
            return setLocalStorageArrayAsDefault(localStorageArray)
          }
          return setLocalStorageArrayAsDefault(localStorageArray)
        }
      }
    }
  }

  return (
    <ContentHeaderContext.Provider
      value={{
        historyContentHeader,
        setHistoryContentHeader,
        lastScopeContentHeader,
        setLastScopeContentHeader,
        onChangePage,
      }}
    >
      {children}
    </ContentHeaderContext.Provider>
  )
}

export default ContentHeaderProvider
