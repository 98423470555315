const storageTimeValidation = (value, volumetry) => {
  switch (volumetry) {
    case 'Minutos':
      return value / (24 * 60)
    case 'Horas':
      return value / 24
    case 'Dias':
      return value
    case 'Meses':
      return value * 30
    case 'Anos':
      return value * 365
    default:
      return ''
  }
}
export default storageTimeValidation
