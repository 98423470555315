const formatLegalFrameworksFilterOptions = (legalFrameworks) => {
  const formattedLegalFrameworks = []

  legalFrameworks.map((legalFramework) => {
    let formattedLegalFramework = {
      id: legalFramework.id,
      name: `${legalFramework.dataType} - ${legalFramework.name}`,
    }

    return formattedLegalFrameworks.push(formattedLegalFramework)
  })

  return formattedLegalFrameworks
}

export default formatLegalFrameworksFilterOptions
