const displayedOther = (options, name) => {
  const snakeToCamel = (str) => {
    if (!str) return ''

    return str
      ?.toLowerCase()
      ?.replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase()?.replace('-', '')?.replace('_', ''),
      )
  }

  const isPresent = options?.find(
    (option) =>
      option?.keyName === 'other' && snakeToCamel(option?.typeField) === name,
  )

  if (isPresent) return true

  return false
}

export default displayedOther
