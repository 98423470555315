const totalProcess = (processStats) => {
  return processStats?.departments?.reduce(
    (value, department) =>
      value +
      department.risks?.severe +
      department.risks?.high +
      department.risks?.medium +
      department.risks?.low,
    0,
  )
}

export default totalProcess
