import React, { useEffect, useState } from 'react'
import { Grid, Container, Box, useMediaQuery, Hidden } from '@material-ui/core'
import { LoadingFeedback, Page } from 'components'
import { Text } from 'views/UserSteps/components'

import certificate from 'images/certificado_jornada.png'
import dponetLogo from 'images/dponet-logo-white.svg'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import useStyles from './styles'
import helpers from 'helpers'
import theme from 'theme'

const Consult = ({ match }) => {
  const [certificateData, setCertificateData] = useState({})
  const [loading, setLoading] = useState(false)
  const { token } = match.params
  const classes = useStyles()
  const snackbar = useSnackbar()

  useEffect(
    () => {
      const getCertificationData = async () => {
        try {
          setLoading(true)
          const response =
            await service.dponet.usersCompanies.consultCertificate(token)
          setCertificateData(response?.data?.userCompanyCertification)
        } catch (error) {
          snackbar.open({
            message:
              helpers.formatters.errorMessage(error?.response?.data?.error) ||
              'Ocorreu um erro, tente novamente mais tarde.',
            variant: 'error',
          })
        } finally {
          setLoading(false)
        }
      }

      getCertificationData()
    },
    //eslint-disable-next-line
  [])

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  return (
    <Page title="Consultar certificação" transparent noPadding>
      <Container maxWidth="lg" className={classes.container}>
        <LoadingFeedback open={loading} />
        <Hidden mdUp>
          <Box py={2} display="flex" justifyContent="center" width="100%">
            <img
              src={dponetLogo}
              alt="logo-dponet"
              width={isMobile ? '50%' : '35%'}
            />
          </Box>
        </Hidden>
        <Grid container className={classes.gridContainer} alignItems="stretch">
          <Grid item sm={12} md={6} lg={5}>
            <Box className={classes.imgBox}>
              <img
                src={certificate}
                alt="certificado"
                width={isMobile ? '50%' : '400px'}
                className={classes.certificateImg}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={7}>
            <Box className={classes.dataBox}>
              <Hidden smDown>
                <img
                  src={dponetLogo}
                  alt="logo-dponet"
                  width={isMobile ? '50%' : '35%'}
                />
              </Hidden>
              <Box className={classes.boxCertificate} mt={4}>
                <Text fontSize={35} variant="h2">
                  Este certificado é{' '}
                  {certificateData?.validCertificate
                    ? 'válido'
                    : !loading && 'inválido'}
                </Text>
                <Text fontSize={16} variant="subtitle1">
                  {certificateData?.userName}
                </Text>
                <Text fontSize={16} variant="subtitle1">
                  Certificado: {certificateData?.label}
                </Text>
                <Text fontSize={16} variant="subtitle1">
                  Emitido em:{' '}
                  {helpers.formatters.date(certificateData?.createdAt)}
                </Text>
                <Text fontSize={16} variant="subtitle1">
                  Renovado em:{' '}
                  {helpers.formatters.date(certificateData.renewDate)}
                </Text>
                <Text fontSize={16} variant="subtitle1">
                  Expira em:{' '}
                  {!!certificateData.expirationDate
                    ? helpers.formatters.date(certificateData.expirationDate)
                    : 'Não expira'}
                </Text>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default Consult
