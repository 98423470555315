import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  questionText: {
    color: theme.palette.text.mainMenu,
    marginBottom: theme.spacing(1),
  },
}))

export default styles
