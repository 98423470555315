import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import helpers from 'helpers'

const styles = (size) =>
  makeStyles((theme) => {
    const { watch } = useFormContext()

    const primaryColor = helpers.palette.validateColor(watch('primaryColor'))
    const secondaryColor = helpers.palette.validateColor(
      watch('secondaryColor'),
    )
    const calculatedSecondaryColor =
      helpers.palette.calculateColors(secondaryColor)
    const secondaryBGColor = helpers.palette.hexToRgba(secondaryColor, 0.06)

    const isFull = size === 'full'
    const isMedium = size === 'medium'

    return {
      paperContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'justify',
        maxWidth: isFull || !size ? undefined : isMedium ? 700 : 550,
        padding: theme.spacing(2),
        gridGap: theme.spacing(1.5),
        maxHeight: '100%',
      },
      title: {
        fontSize: size ? 16 : 24,
        fontWeight: 600,
        fontFamily: 'Arial',
        color: helpers.palette.validateColor(primaryColor),
      },
      globalTypography: {
        fontSize: size && 10,
        fontFamily: 'Arial',
        width: 'fit-content',
        color: helpers.palette.validateColor(primaryColor),
      },
      link: {
        color: helpers.palette.validateColor(primaryColor),
        width: 'fit-content',
      },
      button: {
        fontFamily: 'Arial',
        paddingInline: 0,
        fontSize: size && 10,
        '& > span': {
          fontWeight: 400,
        },
      },
      settingsButton: {
        textTransform: 'initial',
        color: helpers.palette.validateColor(secondaryColor),
        '&:hover': {
          backgroundColor: helpers.palette.validateColor(secondaryBGColor),
        },
      },
      rejectButton: {
        boxShadow: 'none',
        color: helpers.palette.validateColor(secondaryColor),
        borderColor: helpers.palette.validateColor(calculatedSecondaryColor)
          ?.light,
        '&:hover': {
          borderColor: helpers.palette.validateColor(
            calculatedSecondaryColor?.dark,
          ),
          backgroundColor: helpers.palette.validateColor(secondaryBGColor),
        },
      },
      acceptButton: {
        backgroundColor: helpers.palette.validateColor(secondaryColor),
        color: helpers.palette.validateColor(
          calculatedSecondaryColor?.contrastText,
        ),
        '&:hover': {
          backgroundColor: helpers.palette.validateColor(
            calculatedSecondaryColor?.dark,
          ),
        },
      },
      settingsGrid: {
        order: 1,
        [theme.breakpoints.down('sm')]: {
          order: isFull ? 3 : 1,
        },
        [theme.breakpoints.down('xs')]: {
          order: 3,
        },
      },
      rejectGrid: {
        order: 2,
      },
      acceptGrid: {
        order: 3,
        [theme.breakpoints.down('sm')]: {
          order: isFull ? 1 : 3,
        },
        [theme.breakpoints.down('xs')]: {
          order: 1,
        },
      },
    }
  })

styles.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  secondaryBGColor: PropTypes.string.isRequired,
  calculatedSecondaryColor: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['default', 'medium', 'full']),
}

export default styles
