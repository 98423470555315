import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import {
  ButtonGroup as ButtonGroupMaterial,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
  Typography,
  Button,
  Popper,
  FormHelperText,
  Box,
} from '@material-ui/core'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons'

import useStyles from './styles'

const ButtonGroup = ({
  options,
  title,
  type = 'button',
  fullWidth = false,
  buttonGroup,
  setButtonGroup,
  tooltipPlacement = 'bottom',
  controlName,
  disabled = false,
  ...rest
}) => {
  const [open, setOpen] = useState(false)

  const anchorRef = useRef(null)
  const classes = useStyles()
  const formContext = useFormContext()

  const setValue = controlName ? formContext.setValue : undefined
  const watch = controlName ? formContext.watch : undefined
  const errors = controlName ? formContext.errors : undefined

  const value = watch && watch(controlName)

  const [selectedId, setSelectedId] = useState(buttonGroup || value)

  const handleMenuItemClick = (optionId) => {
    if (controlName && setValue) setValue(controlName, optionId)
    else if (setButtonGroup) setButtonGroup(optionId)

    setSelectedId(optionId)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return

    setOpen(false)
  }

  useEffect(() => {
    if (
      (buttonGroup && buttonGroup !== selectedId) ||
      (value && value !== selectedId)
    )
      setSelectedId(buttonGroup)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonGroup, value])

  return (
    <Box>
      <ButtonGroupMaterial
        ref={anchorRef}
        aria-label="split button"
        fullWidth={fullWidth}
      >
        <Tooltip
          title={
            isNil(selectedId)
              ? options?.find((option) => option?.id === selectedId)?.name
              : ''
          }
          placement={tooltipPlacement}
        >
          <Button
            type={type}
            disabled={disabled}
            className={classes.buttonSubmit}
            {...rest}
          >
            {title ? (
              <Fragment>
                <Typography
                  className={classes.buttonTitle}
                  variant="button"
                  pr={1}
                >
                  {title}:&nbsp;
                </Typography>
                <Typography
                  variant="button"
                  className={classes.buttonName}
                  noWrap
                >
                  {!!selectedId
                    ? options?.find((option) => option?.id === selectedId)?.name
                    : ''}
                </Typography>
              </Fragment>
            ) : (
              <Typography
                variant="button"
                fontWeight={100}
                textOverflow="ellipsis"
                overflow="hidden"
                noWrap
              >
                {!!selectedId
                  ? options?.find((option) => option?.id === selectedId)?.name
                  : ''}
              </Typography>
            )}
          </Button>
        </Tooltip>
        <Button
          size="small"
          aria-controls={open ? 'split-ButtonOption-menu' : undefined}
          aria-expanded={open || null}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          className={classes.buttonOption}
        >
          <KeyboardArrowDownIcon />
        </Button>
      </ButtonGroupMaterial>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options
                    .slice()
                    .sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
                    .map((option) => (
                      <MenuItem
                        key={option?.id}
                        selected={option?.id === selectedId}
                        onClick={() =>
                          !!option?.id && handleMenuItemClick(option?.id)
                        }
                        disabled={option?.disabled}
                      >
                        <Tooltip title={option?.name ?? ''}>
                          <Typography
                            variant="body2"
                            maxWidth={400}
                            textOverflow="ellipsis"
                            overflow="hidden"
                            noWrap
                          >
                            {option?.name}
                          </Typography>
                        </Tooltip>
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {!!errors?.[controlName] && (
        <FormHelperText error={!!errors?.[controlName]}>
          {<>{errors?.[controlName]?.message}</>}
        </FormHelperText>
      )}
    </Box>
  )
}

ButtonGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  buttonGroup: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setButtonGroup: PropTypes.func,
  tooltipPlacement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
  controlName: PropTypes.string,
  disabled: PropTypes.bool,
}

export default ButtonGroup
