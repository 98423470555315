const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  transparent: {
    backgroundColor: 'transparent',
    paddingBottom: 0,
  },
  noPadding: {
    padding: 0,
  },
})

export default styles
