import React, { useEffect, useState } from 'react'

import { Box, Tooltip, Typography } from '@material-ui/core'
import { Upload as UploadIcon } from 'react-feather'
import { useDropzone } from 'react-dropzone'

import { LoadingFeedback, RoundedButton } from 'components'
import { Text } from '../'

import useStyles from './styles'
import useAuth from 'hooks/useAuth'
import useTrailStep from 'hooks/useTrailStep'

import theme from 'theme'
import * as service from 'service'
import { getBaseURL } from 'service/env'

const LogoUpload = () => {
  const classes = useStyles()
  const {
    userCompany: { company },
  } = useAuth()
  const { handleNext, setNextDisabled } = useTrailStep()

  const currentLogo =
    company?.logo?.url && `${getBaseURL('dponet')}${company?.logo?.url}`

  const [logo, setLogo] = useState(currentLogo)
  const [loading, setLoading] = useState(false)

  const onDrop = async (file) => {
    try {
      setLoading(true)
      await service.dponet.companies.updateSelfLogo({ logo: file[0] })
      setLogo(URL.createObjectURL(file[0]))
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const { ref, ...rootProps } = getRootProps()

  useEffect(() => {
    setNextDisabled(!logo)
    //eslint-disable-next-line
  }, [logo])

  return (
    <>
      <LoadingFeedback open={loading} />
      <Text color="primary" fontSize="7vh" variant="h1">
        Faça o logo da sua empresa
      </Text>
      <Box my={6}>
        <Text fontSize="3.5vh" variant="h4">
          A seguir, vamos personalizar a identidade visual do seu canal de
          comunicação. Para isso, anexe sua logomarca.
        </Text>
      </Box>
      <Tooltip title="Arraste e solte ou selecione o documento">
        <Box
          {...rootProps}
          className={classes.inputImageContainer}
          mb={3}
          rootref={ref}
        >
          <input {...getInputProps()} />
          {logo ? (
            <Box my={1}>
              <img src={logo} alt="logo-upload" className={classes.imgLogo} />
            </Box>
          ) : (
            <>
              <Box m={1} mt={2}>
                <UploadIcon color={theme.palette.primary.main} />
              </Box>
              <Box m={1} mb={2}>
                <Typography>
                  Arraste e solte ou selecione o documento
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Tooltip>
      <Box my={2}>
        <RoundedButton variant="outlined" onClick={() => handleNext()}>
          Não tenho logo
        </RoundedButton>
      </Box>
    </>
  )
}

export default LogoUpload
