import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
    height: '100%',
  },
  cardHeader: {
    background: theme.palette.primary.main,
    color: theme.palette.white,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 16px center',
    backgroundSize: 70,
  },
  cardTitle: {
    padding: 20,
  },
  lineTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
    fontWeight: 500,
  },
  lineBar: {
    height: 7,
    borderRadius: 5,
    backgroundColor: theme.palette.subscription.primary.light,
  },
  rowName: {
    fontWeight: 500,
  },
  rowValue: {
    fontWeight: 500,
  },
  perfectScrollBar: {
    maxHeight: 325,
    overflowY: 'auto',
    marginTop: 15,
  },
  childrenBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
}))

export default styles
