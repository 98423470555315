import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
  IconButton,
} from '@material-ui/core'
import { RefreshCw as RefreshIcon } from 'react-feather'

import LogsTable from './components/LogsTable'

const DialogLogs = ({
  logs,
  open,
  showRegisterSession = true,
  setOpen = () => {},
  refresh = () => {},
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4">Histórico de alterações</Typography>
          {refresh && (
            <Box>
              <IconButton onClick={refresh}>
                <RefreshIcon size={20} />
              </IconButton>
            </Box>
          )}
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {!logs ? (
          <Typography>
            Não existe nenhum histórico de alterações para este registro.
          </Typography>
        ) : (
          <LogsTable logs={logs} showRegisterSession={showRegisterSession} />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button type="button" variant="outlined" onClick={() => setOpen(false)}>
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogLogs
