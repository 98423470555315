import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Container, useMediaQuery } from '@material-ui/core'
import {
  ArrowRight as ArrowRightIcon,
  HelpCircle as HelpCircleIcon,
} from 'react-feather'
import { InfoDialog, Stepper } from '../'

import useTrailStep from 'hooks/useTrailStep'
// import useAuth from 'hooks/useAuth'

import theme from 'theme'

import useStyles from './styles'
import { RoundedButton } from 'components'

const ContentBase = ({
  children,
  nextButtonText,
  maxWidth,
  hideStepper,
  buttonAlign,
  ...rest
}) => {
  const [helpOpen, setHelpOpen] = useState(false)

  const classes = useStyles()
  const {
    formSubmitConfigs,
    jumpStep,
    isFinalStep,
    isFirstStep,
    handleBack,
    handleNext,
    helpObject,
    nextDisabled,
    showBackButton,
    showHelpButton,
    showJumpButton,
    showNextButton,
  } = useTrailStep()

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const handleOpenHelpDialog = () => setHelpOpen(true)

  return (
    <Box
      width="100%"
      mt={8}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Container className={classes.container} maxWidth={maxWidth}>
        {!hideStepper && (
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={isDesktop ? 4 : 3}
          >
            <Stepper />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          height="inherit"
          alignItems="center"
          justifyContent="center"
          {...(isDesktop
            ? {
                minHeight: '70vh',
              }
            : { mb: 14 })}
          {...rest}
        >
          {children}
          <Box
            display="flex"
            alignItems="center"
            {...(isDesktop && { justifyContent: buttonAlign })}
            width="100%"
            mt={2}
          >
            {showBackButton && (
              <Box
                mr={isDesktop ? 2 : 1}
                {...(!isDesktop && { width: '100%' })}
              >
                <RoundedButton
                  onClick={handleBack}
                  variant="outlined"
                  fullWidth={!isDesktop}
                >
                  Voltar
                </RoundedButton>
              </Box>
            )}
            {showJumpButton && (
              <Box
                mr={isDesktop ? 2 : 1}
                {...(!isDesktop && { width: '100%' })}
              >
                <RoundedButton
                  fullWidth={!isDesktop}
                  onClick={jumpStep}
                  variant="outlined"
                  withPadding
                >
                  Pular
                </RoundedButton>
              </Box>
            )}
            {showNextButton && (
              <RoundedButton
                {...(isFirstStep && { icon: <ArrowRightIcon /> })}
                variant="primary"
                withShadow
                withPadding
                fullWidth={!isDesktop}
                onClick={() => handleNext()}
                disabled={nextDisabled}
                {...formSubmitConfigs()}
              >
                {isFirstStep
                  ? 'Começar minha trilha'
                  : isFinalStep
                  ? 'Finalizar'
                  : 'Avançar'}
              </RoundedButton>
            )}
          </Box>
          {showHelpButton && (
            <Box mt={4}>
              <Button
                variant="text"
                className={classes.helpButton}
                classes={{ label: classes.helpButtonLabel }}
                endIcon={<HelpCircleIcon />}
                onClick={handleOpenHelpDialog}
              >
                {helpObject.textButton}
              </Button>
            </Box>
          )}
        </Box>
      </Container>
      <InfoDialog
        open={helpOpen}
        setOpen={setHelpOpen}
        title={helpObject.title}
        description={helpObject.description}
      />
    </Box>
  )
}

ContentBase.propTypes = {
  nextButtonText: PropTypes.string,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  buttonAlign: PropTypes.oneOf(['start', 'end', 'center']),
  hideStepper: PropTypes.bool,
}

ContentBase.defaultProps = {
  nextButtonText: 'Avançar',
  maxWidth: 'md',
  hideStepper: false,
  buttonAlign: 'end',
}

export default ContentBase
