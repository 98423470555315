import permissions from '../permissions'

const COMPANY_LIST = [
  {
    name: 'Atualizar dados cadastrais da empresa',
    tag: permissions.COMPANIES.UPDATE_SELF,
  },
]

export default COMPANY_LIST
