import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

import { MenuButton, Permitted, ButtonAction } from 'components'
import { reverse } from 'named-urls'

import permission from 'constants/permissions'
import constants from 'constants/index'

import useSnackbar from 'hooks/useSnackbar'
import usePermissions from 'hooks/usePermissions'

import helpers from 'helpers'
import * as service from 'service'

const SupplierActions = ({
  supplier,
  setOpenConfirmation,
  setConfirmationData,
  setCompanySupplier,
}) => {
  const isApproved =
    supplier.status === constants.companySuppliers.APPROVED_STATUS
  const isIndicated =
    supplier.status === constants.companySuppliers.INDICATED_STATUS

  const snackbar = useSnackbar()
  const { isSupplier } = usePermissions()
  const history = useHistory()

  const inactivateSubmit = async () => {
    try {
      await service.dponet.suppliers.inactivate({ supplierId: supplier.id })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Ocorreu um erro ao inativar o vínculo',
        variant: 'error',
      })

      throw error
    }
  }

  const approveOrRefuseSubmit = async (status) => {
    const isApprove = status === constants.companySuppliers.APPROVED_STATUS

    try {
      await service.dponet.suppliers.approveOrRefuse({
        supplierId: supplier.id,
        status,
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          `Ocorreu um erro ao ${isApprove ? 'aprovar' : 'rejeitar'} o vínculo`,
        variant: 'error',
      })

      throw error
    }
  }

  const handleInactivate = () => {
    setConfirmationData({
      actionType: constants.companySuppliers.INACTIVATE_ACTION,
      title: 'Inativar vínculo?',
      subtitle: 'Deseja confirmar a inativação de vínculo com o cliente?',
      handleConfirm: inactivateSubmit,
    })
    setOpenConfirmation(true)
  }

  const handleApprove = () => {
    const subtitle = constants.companySuppliers.APPROVE_BASE_TEXT
    setCompanySupplier(supplier)

    setConfirmationData({
      actionType: constants.companySuppliers.APPROVE_ACTION,
      title: 'Confirmação do vínculo',
      subtitle: isSupplier()
        ? subtitle
        : `${subtitle} 

      ${constants.companySuppliers.APPROVE_CLIENT_TEXT}`,
      handleConfirm: () =>
        approveOrRefuseSubmit(constants.companySuppliers.APPROVED_STATUS),
    })
    setOpenConfirmation(true)
  }

  const handleRefuse = () => {
    setConfirmationData({
      actionType: constants.companySuppliers.REFUSE_ACTION,
      title: 'Rejeitar vínculo?',
      subtitle: 'Deseja confirmar a rejeição de vínculo com o cliente?',
      handleConfirm: () =>
        approveOrRefuseSubmit(constants.companySuppliers.REFUSED_STATUS),
    })
    setOpenConfirmation(true)
  }

  if (!isApproved && !isIndicated) {
    return <></>
  }

  return (
    <MenuButton>
      <Permitted tag={permission.COMPANY_SUPPLIERS.SHOW}>
        {isApproved && (
          <ButtonAction
            color="secondary"
            fullWidth
            name="Visualizar"
            action={() =>
              history.push(
                reverse(routes.supplier.show, {
                  supplierId: supplier.id,
                }),
              )
            }
          />
        )}
      </Permitted>
      <Permitted tag={permission.COMPANY_SUPPLIERS.UPDATE}>
        {isIndicated && (
          <>
            <ButtonAction
              color="secondary"
              action={handleApprove}
              fullWidth
              name="Aprovar vínculo"
            />
            <ButtonAction
              color="secondary"
              fullWidth
              action={handleRefuse}
              name="Reprovar vínculo"
            />
          </>
        )}
        {isApproved && (
          <ButtonAction
            color="secondary"
            fullWidth
            action={handleInactivate}
            name="Inativar vínculo"
          />
        )}
      </Permitted>
    </MenuButton>
  )
}

SupplierActions.propTypes = {
  supplier: PropTypes.object,
  setOpenConfirmation: PropTypes.func,
  setConfirmationData: PropTypes.func,
  setCompanySupplier: PropTypes.func,
}

export default SupplierActions
