import React from 'react'

import PropTypes from 'prop-types'

import { Box, LinearProgress, Typography, makeStyles } from '@material-ui/core'

import styles from './styles'
const useStyles = makeStyles(styles)

const CardCompliance = ({ compliance }) => {
  const classes = useStyles()

  return (
    <Box width="100%" mt={1} mb={4} display="flex" justifyContent="center">
      <Box className={classes.card} p={2} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" px={0.5} pb={0.5}>
          <Typography variant="body1">Conformidade</Typography>
          <Typography variant="body1">
            {compliance?.toFixed(0) || 0}%
          </Typography>
        </Box>
        <Box>
          <LinearProgress
            variant="determinate"
            className={classes.determinate}
            classes={{ barColorPrimary: classes.barColorPrimary }}
            value={compliance}
          />
        </Box>
      </Box>
    </Box>
  )
}

CardCompliance.propTypes = {
  compliance: PropTypes.number,
}

export default CardCompliance
