import React, { useState } from 'react'
import {
  Button,
  Box,
  Card,
  CardHeader,
  Divider,
  Typography,
} from '@material-ui/core'
import { NewPasswordForm, UserSettingsForm } from './components/Account'

import helpers from 'helpers'

import useAuth from 'hooks/useAuth'

const Account = () => {
  const userFormId = 'user-create-form'
  const passwordFormId = 'password-form'
  const { user, loadData, userCompany } = useAuth()

  const [loading, setLoading] = useState()
  const [formIds, setFormIds] = useState([])
  const [hasPhone, setHasPhone] = useState(!!user?.phone)

  const isResponsible = userCompany?.responsable
  const passwordLastChange = `${helpers.formatters.date(
    user?.passwordUpdatedAt,
  )} às ${helpers.formatters.hours(user?.passwordUpdatedAt)}`

  const submit = async () => {
    for await (let form of formIds) {
      await form.dispatchEvent(new Event('submit'))
    }
  }

  return (
    <>
      <Card component={Box} mb={3}>
        <CardHeader title="Dados básicos" />
        <Divider />
        <Box mt={1}>
          <UserSettingsForm
            formId={userFormId}
            setHasPhone={setHasPhone}
            formIds={formIds}
            setLoading={setLoading}
            setFormIds={setFormIds}
            user={user}
            loadData={loadData}
          />
        </Box>
      </Card>
      <Card>
        <CardHeader title="Alterar senha" />
        <Divider />
        <Box mt={2}>
          {user?.passwordUpdatedAt && (
            <Box px={2}>
              <Typography variant="subtitle2">
                {`Sua senha foi alterada pela última vez em ${passwordLastChange}`}
              </Typography>
            </Box>
          )}
          <NewPasswordForm
            formId={passwordFormId}
            formIds={formIds}
            setFormIds={setFormIds}
            setLoading={setLoading}
            loadData={loadData}
            isResponsible={isResponsible}
            hasPhone={hasPhone}
          />
        </Box>
      </Card>
      <Box mt={4} mb={5} display="flex" justifyContent="flex-end">
        <Button
          disabled={loading}
          color="primary"
          onClick={submit}
          variant="contained"
        >
          Salvar
        </Button>
      </Box>
    </>
  )
}

export default Account
