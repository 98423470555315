import { useContext } from 'react'

import DataTreatmentModalContext from 'contexts/DataTreatmentModalContext'

const useDataTreatment = () => {
  const context = useContext(DataTreatmentModalContext)

  if (context === undefined) {
    throw new Error(
      'useDataTreatment  must be used within a DataTreatmentModalContext.Provider',
    )
  }

  return {
    data: context.data,
    loadData: context.loadData,
    setData: context.setData,
    isLoading: context.isLoading,
  }
}

export default useDataTreatment
