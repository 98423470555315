import helpers from 'helpers'

const defineSubtitle = (trail) => {
  if (trail?.concluded) {
    return helpers.formatters.dateLocaleFormat(
      trail.concludedAt,
      'DD/MM/YYYY - HH:mm',
    )
  }

  if (trail?.active) {
    return helpers.formatters.dateLocaleFormat(
      trail.createdAt,
      'DD/MM/YYYY - HH:mm',
    )
  }

  return '-'
}

export default defineSubtitle
