import React from 'react'
import PropTypes from 'prop-types'

import { DashboardChartPaper, DonutChart } from 'components'

import constants from 'constants/index'
import theme from 'theme'

const ConsentGraph = ({ consentForm }) => {
  return (
    <DashboardChartPaper
      title="Consentimentos coletados"
      subtitle="Autorizados e Revogados"
      heightContainer="fit-content"
      id={constants.consent.DETAILS_DRIVER_STEP_1}
    >
      <DonutChart
        title="Consentimentos"
        names={['Autorizados', 'Revogados']}
        values={[
          consentForm?.answersCollected?.authorized || 0,
          consentForm?.answersCollected?.revoked || 0,
        ]}
        colors={[theme.palette.success.main, theme.palette.error.main]}
        legendShow
        showName={false}
      />
    </DashboardChartPaper>
  )
}

ConsentGraph.propTypes = {
  consentForm: PropTypes.object,
}

export default ConsentGraph
