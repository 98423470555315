import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  expandOpen: {
    transform: 'rotate(180deg)',
    transition: '0.1s',
  },
  expandClose: {
    transition: '0.05s',
  },
}))

export default styles
