import React from 'react'
import { X as XIcon } from 'react-feather'
import clsx from 'clsx'
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  Paper,
} from '@material-ui/core'

import { ListAdequacy } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'
import useStyle from './styles'

const AdequacyModal = ({
  company,
  nonCompliance,
  open,
  handleClose,
  setOpenModal,
}) => {
  const classes = useStyle()
  const snackbar = useSnackbar()
  const { mixpanel } = helpers

  const sendRd = async () => {
    mixpanel.track(
      'Jornada de Forncedores',
      'Modal: Adequação pendências de medidas de controle importantes',
      {
        action: 'Solicitar_contato_especialista-Adequação',
      },
    )
    try {
      await service.dponet.companies.notificationRdStation({
        companyId: company.id,
      })
      setOpenModal(false)
      snackbar.open({
        message:
          'Um e-mail de contato será enviado para você, confira sua caixa de entrada!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro ao solicitar o contato',
        variant: 'error',
      })
    }
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="md"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <IconButton onClick={handleClose} className={classes.closeIcon}>
        <XIcon color="white" size={23} />
      </IconButton>
      <Box margin="5px 15px 20px">
        <Box>
          <Typography className={classes.title}>
            Você está pendente de&nbsp;
            <span className={classes.titleApproved}>
              {nonCompliance} medida(s)
            </span>
            &nbsp; de controle importantes.
          </Typography>
          <Typography className={clsx(classes.title, classes.subtitle)}>
            Podemos te ajudar!
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.description} align="justify">
            Adequar à LGPD pode ser crucial para&nbsp;
            <Typography className={classes.descriptionUnderlined}>
              evitar processos judiciais e multas
            </Typography>
            &nbsp; devido a violações de privacidade e proteger a&nbsp;
            <Typography className={classes.descriptionUnderlined}>
              reputação da sua empresa sem custar caro.
            </Typography>
          </Typography>
        </Box>
        <Box>
          <ListAdequacy />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Paper
            onClick={sendRd}
            variant="outlined"
            component={Button}
            className={classes.button}
            fullWidth
          >
            SOLICITAR CONTATO GRATUITO DE ESPECIALISTA LGPD
          </Paper>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AdequacyModal
