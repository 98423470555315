import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { RmcActionContent, Permitted } from 'components'

import { isEmpty } from 'lodash'

import constants from 'constants/index'

const ShowNecessityProportionality = ({ data, refresh }) => {
  const showStep = !isEmpty(data?.unnecessaryDatas || [])

  const someUnnecessary = !isEmpty(
    data?.unnecessaryDatas?.filter(
      (item) => item?.status === constants.unnecessaryData.UNNECESSARY_STATUS,
    ),
  )

  return (
    <Permitted tag={constants.permissions.DATA_PROCESSES.CREATE}>
      {showStep && (
        <>
          <Box my={2}>
            <Typography variant="h5">
              Análise da Necessidade e Proporcionalidade
            </Typography>
          </Box>
          {!someUnnecessary && <RmcActionContent dataProcess={data} isShow />}
          {someUnnecessary && (
            <RmcActionContent dataProcess={data} refresh={refresh} />
          )}
        </>
      )}
    </Permitted>
  )
}

export default ShowNecessityProportionality
