import palette from 'theme/palette'

import components from '../../components'

import { HEIGHT as HeightPdf, fontSize, PADDING } from '../../constants'

import { ratio } from '../../helpers'

const NotFound = async (
  pdf,
  date,
  chapter,
  chapterTitle,
  text,
  customThemeStatus,
) => {
  const HEIGHT = HeightPdf(pdf)

  pdf.addPage('a4', 'p')
  components.lineWithDate(pdf, date)
  components.numberAndTitle(pdf, chapter, chapterTitle)
  pdf.setFillColor(palette.white)

  pdf.setFontSize(fontSize.subtitle)
  pdf.setLineHeightFactor(1.2)
  pdf.setFont('Raleway-Bold')

  pdf.setTextColor(palette.subscription.primary.main)
  pdf.text(text, PADDING, HEIGHT / 2 - ratio(300), null, null, 'left')

  pdf.setFontSize(fontSize.paragraph)
  pdf.setFont('Raleway-Regular')

  components.numberPage(pdf, palette.black, customThemeStatus)
}

export default NotFound
