import React from 'react'
import { Container } from '@material-ui/core'

import { Page, ContentHeader } from 'components'
import { ListLastUpdates } from './components'

import useStyles from './styles'

const LastUpdates = () => {
  const classes = useStyles()

  return (
    <Page title="Últimas Atualizações">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Últimas Atualizações"></ContentHeader>

        <ListLastUpdates />
      </Container>
    </Page>
  )
}

export default LastUpdates
