import * as yup from 'yup'

const schema = (isRequired) => {
  return yup.object().shape({
    ...(isRequired
      ? { email: yup.string().required() }
      : { email: yup.string() }),
  })
}

export default schema
