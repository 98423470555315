import React, { useEffect, useState } from 'react'

import { isEmpty } from 'lodash'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
} from '@material-ui/core'

import { ChevronDown as ExpandMoreIcon } from 'react-feather'

import { CategoryCheckbox } from '../'

import styles from './styles'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const CategoryAccordion = ({ category, label, checked }) => {
  const [isChecked, setIsChecked] = useState(checked)

  const classes = useStyles()
  const { mixpanel } = helpers

  const hasCompanyServices = !isEmpty(category?.companyServices)

  const hasDependentsCompanyServices = !isEmpty(
    category?.dependentsCompanyServices,
  )

  const handleCheckboxChange = (serviceCategoryName) => {
    if (!isChecked) {
      mixpanel.track('Marktplace', 'Filtro por categoria', {
        action: `Marcado checkbox da categoria ${serviceCategoryName}`,
      })
    }
    setIsChecked(!isChecked)
  }

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  if (hasCompanyServices) {
    return (
      <Accordion
        classes={{
          root: classes.accordionContainer,
          expanded: classes.expanded,
        }}
        key={category?.id}
      >
        <AccordionSummary
          classes={{ expanded: classes.expanded, content: classes.expanded }}
          expandIcon={<ExpandMoreIcon />}
        >
          <CategoryCheckbox
            checked={isChecked}
            label={label}
            id={category?.id?.toString()}
            name="parent"
            onChange={() => handleCheckboxChange(label)}
          />
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.detailsContainer }}>
          {category?.companyServices.map((subCategory) => (
            <CategoryAccordion
              key={subCategory?.id}
              category={subCategory}
              checked={isChecked}
              label={subCategory.name}
              onChange={handleCheckboxChange}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    )
  }

  if (hasDependentsCompanyServices) {
    return (
      <Accordion
        classes={{
          root: classes.accordionContainer,
          expanded: classes.expanded,
        }}
        key={category?.id}
      >
        <AccordionSummary
          classes={{ expanded: classes.expanded, content: classes.expanded }}
          expandIcon={<ExpandMoreIcon />}
        >
          <CategoryCheckbox
            label={label}
            checked={isChecked}
            onChange={() => handleCheckboxChange(label)}
            id={category?.id?.toString()}
          />
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.detailsChildrenContainer }}>
          {category?.dependentsCompanyServices?.map((subCategory) => (
            <CategoryCheckbox
              key={subCategory?.id}
              checked={isChecked}
              label={subCategory?.name}
              onChange={() => handleCheckboxChange(label)}
              id={subCategory?.id?.toString()}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    )
  } else {
    return (
      <Box pl="inherit" padding={0}>
        <CategoryCheckbox
          checked={isChecked}
          label={label}
          onChange={() => handleCheckboxChange(label)}
          id={category?.id.toString()}
        />
      </Box>
    )
  }
}

export default CategoryAccordion
