import { colors } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  blueContainer: {
    backgroundColor: theme.palette.layout.main,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  buttonOptions: {
    backgroundColor: colors.green[500],
    color: colors.common.white,
    '&:hover': {
      backgroundColor: colors.green[500],
    },
  },
  avatar: {
    height: 90,
    width: 90,
    [theme.breakpoints.up('md')]: {
      marginTop: -60,
    },
    backgroundColor: theme.palette.primary.topBar,
    boxShadow:
      '0px 3px 10px -5px rgb(0 0 0 / 20%), 0px 2px 5px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    marginBottom: theme.spacing(3),
  },

  customPopover: {
    maxWidth: 500,
  },
}))

export default styles
