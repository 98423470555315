import React from 'react'

import { Text } from '../'
import { Box } from '@material-ui/core'
import { GreenHighlighted } from 'components'

const DocumentInfo = () => {
  return (
    <>
      <Text color="primary" fontSize="7vh" variant="h1">
        Documentos
      </Text>
      <Box my={3}>
        <Text fontSize="3.2vh" variant="h4" gutterBottom>
          Dentre os documentos exigidos pela lei, podemos destacar um deles:
        </Text>
      </Box>
      <Text fontSize="3.2vh" variant="h4" gutterBottom>
        <GreenHighlighted>Aviso de Privacidade</GreenHighlighted>
      </Text>
      <Box my={3}>
        <Text fontSize="3.2vh" variant="h4" gutterBottom>
          Para ajudá-lo a cumprir essas obrigações de forma simples e ágil,
          disponibilizamos a seguir o modelo desse documento. Você poderá
          alterá-lo se preferir!
        </Text>
      </Box>
    </>
  )
}

export default DocumentInfo
