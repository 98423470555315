import React from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  ThumbsUp as ThumbsUpIcon,
  ThumbsDown as ThumbsDownIcon,
  X as CloseIcon,
} from 'react-feather'

import useSnackbar from 'hooks/useSnackbar'
import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

import theme from 'theme'

const DialogQuestionInformation = ({
  open,
  question,
  setOpen,
  setLoading,
  questionnaireName,
  questionnaireId,
  refresh,
}) => {
  const snackbar = useSnackbar()

  const { BAD_RATING, GOOD_RATING } = constants.questions

  const handleClose = () => setOpen(false)

  const handleUpdate = async (rating) => {
    try {
      setLoading(true)
      await service.dponet.questionnaires.rateQuestion({
        questionnaireId: questionnaireId,
        questionId: question?.id,
        question: {
          rate: rating,
        },
      })

      handleClose()
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Box paddingY={1} paddingX={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h5">{questionnaireName}</Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon size={20} />
          </IconButton>
        </Box>
      </Box>
      <DialogContent dividers>
        <Typography variant="h5" gutterBottom>
          {question?.theme}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {question?.explanation}
        </Typography>
        <Box my={1}>
          <Typography variant="h5">Propósito</Typography>
        </Box>
        <Typography>{question?.purpose}</Typography>
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
        <Typography variant="h6">Isso foi útil?</Typography>
        <IconButton onClick={() => handleUpdate(GOOD_RATING)}>
          <ThumbsUpIcon
            size={20}
            color={
              question?.rate === GOOD_RATING
                ? theme.palette.greenPantone
                : theme.palette.primary.main
            }
          />
        </IconButton>
        <IconButton onClick={() => handleUpdate(BAD_RATING)}>
          <ThumbsDownIcon
            size={20}
            color={
              question?.rate === BAD_RATING
                ? theme.palette.redErrorDark
                : theme.palette.primary.main
            }
          />
        </IconButton>
      </Box>
    </Dialog>
  )
}

export default DialogQuestionInformation
