const NEWLY_CREATED = 'newly_created'
const OLDER_CREATED = 'older_created'
const NEWLY_UPDATED = 'newly_updated'
const OLDER_UPDATED = 'older_updated'
const ALPHABETICAL_A_TO_Z = 'alphabetical_a_to_z'
const ALPHABETICAL_Z_TO_A = 'alphabetical_z_to_a'

const orderFilter = {
  NEWLY_CREATED,
  OLDER_CREATED,
  NEWLY_UPDATED,
  OLDER_UPDATED,
  ALPHABETICAL_A_TO_Z,
  ALPHABETICAL_Z_TO_A,
}

export default orderFilter
