import constants from 'constants/index'

const statusLogTitle = (status) => {
  switch (status) {
    case constants.companySuppliers.REFUSED_STATUS:
      return constants.companySuppliers.REFUSED_LOG_TEXT
    case constants.companySuppliers.APPROVED_STATUS:
      return constants.companySuppliers.APPROVED_LOG_TEXT
    case constants.companySuppliers.INACTIVE_STATUS:
      return constants.companySuppliers.INACTIVE_LOG_TEXT
    default:
      return null
  }
}
export default statusLogTitle
