import React from 'react'
import useAuth from 'hooks/useAuth'
import { Avatar, makeStyles } from '@material-ui/core'
import styles from './styles'

const useStyles = makeStyles(styles)

const UserAvatar = () => {
  const classes = useStyles()
  const auth = useAuth()
  const avatarLetters = ((auth.user && auth.user.name) || 'Usuário')
    .split(' ')
    .map((word) => word[0])
    .join('')

  return (
    <Avatar className={classes.avatar}>
      {avatarLetters[0]}
      {avatarLetters[avatarLetters.length - 1]}
    </Avatar>
  )
}

export default UserAvatar
