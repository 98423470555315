import clsx from 'clsx'
import PropTypes from 'prop-types'
import { driver } from 'driver.js'

const useDriver = ({
  stepsMatrix,
  defaultStyle,
  customStyle,
  setOpen = () => {},
}) => {
  const handleDestroyTour = () => {
    setOpen(false)
    driverObj.destroy()
  }

  const driverObj = driver({
    showProgress: true,
    popoverClass: clsx(defaultStyle, customStyle),
    steps: stepsMatrix,
    progressText: `{{current}} de {{total}}`,
    stagePadding: 4,
    stageRadius: 4,
    disableActiveInteraction: true,
    prevBtnText: 'Anterior',
    nextBtnText: 'Próximo',
    doneBtnText: 'Concluir',
    onDestroyStarted: handleDestroyTour,
  })

  return driverObj
}

useDriver.propTypes = {
  stepsMatrix: PropTypes.arrayOf(PropTypes.object),
  defaultStyle: PropTypes.string,
  customStyle: PropTypes.string,
  setOpen: PropTypes.func,
}

export default useDriver
