import React from 'react'

import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Typography,
} from '@material-ui/core'

import { Check as CheckIcon } from 'react-feather'

const DialogRemoveSpecificRisk = ({
  nonComplianceReportId,
  specificRiskId,
  open = false,
  setOpen = () => {},
  handleRemove = () => {},
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
      <Box m={4}>
        <DialogContent>
          <Box>
            <Typography variant="h3">
              Tem certeza que deseja excluir o risco específico?
            </Typography>
          </Box>
        </DialogContent>
      </Box>
      <Box pb={2} display="flex" justifyContent="center">
        <Box pr={1}>
          <Button
            disableElevation
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>
        </Box>
        <Button
          startIcon={<CheckIcon />}
          variant="contained"
          type="submit"
          color="primary"
          disableElevation
          onClick={() => handleRemove(nonComplianceReportId, specificRiskId)}
        >
          Confirmar
        </Button>
      </Box>
    </Dialog>
  )
}

export default DialogRemoveSpecificRisk
