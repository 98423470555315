import { makeStyles } from '@material-ui/styles'

const styles = (backgroundImage) =>
  makeStyles((theme) => ({
    avatar: {
      height: 200,
      width: 200,
      marginRight: theme.spacing(1),
      boxShadow: `0px 0px 6px ${backgroundImage}`,
    },
    avatarBackground: {
      backgroundColor: theme.palette.background.default,
    },
    avatarImage: {
      width: '100%',
    },
    backgroundBanner: {
      height: '170px',
      width: '100%',
      objectFit: 'cover',
      backgroundColor: backgroundImage,
    },
  }))

export default styles
