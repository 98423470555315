import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumbs, Grid, Typography, Link, Box } from '@material-ui/core'
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

import { Container } from 'components'
import { routes } from 'Routes'
import useStyles from './styles'

const Header = ({
  children,
  title,
  navText,
  specifNavText,
  withContainer = false,
}) => {
  const history = useHistory()
  const classes = useStyles()

  const redirect = () => history.push(routes.questionControls.all)

  const commonContent = (
    <Grid
      className={classes.root}
      container
      justifyContent="space-between"
      spacing={3}
      alignItems="flex-start"
    >
      <Grid item xs={12} sm={7}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          classes={{
            li: classes.blackTypography,
            ol: classes.blackTypography,
          }}
          aria-label="breadcrumb"
        >
          <Typography variant="body1" color="inherit">
            Diagnóstico
          </Typography>
          {navText ? (
            <Link color="inherit" variant="body2" onClick={redirect}>
              {navText}
            </Link>
          ) : (
            <Typography variant="body1" color="inherit">
              {title}
            </Typography>
          )}
          {specifNavText && (
            <Typography variant="body1" color="inherit">
              {specifNavText}
            </Typography>
          )}
        </Breadcrumbs>
        <Typography variant="h3" className={classes.blackTypography}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Box className={classes.childrenContent}>{children}</Box>
      </Grid>
    </Grid>
  )

  return withContainer ? (
    <Container maxWidth="xl">{commonContent}</Container>
  ) : (
    commonContent
  )
}

Header.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  navText: PropTypes.string,
  specifNavText: PropTypes.string,
  withContainer: PropTypes.bool,
}

export default Header
