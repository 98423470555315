import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chip: {
    backgroundColor: 'transparent',
    border: `2px solid ${theme.colors.firstStep.info.primary}`,
    color: theme.colors.firstStep.info.primary,
    borderRadius: '20px',
    padding: theme.spacing(1),
    fontSize: 20,
    height: 40,
  },
  icon: {
    color: theme.colors.firstStep.info.primary,
    marginLeft: 10,
    marginRight: 1,
  },
}))

export default styles
