import * as Sentry from '@sentry/react'
import { jsPDF } from 'jspdf'

import moment from 'moment'

import templates from '../templates'

import '../fonts/Raleway-Bold-normal'
import '../fonts/Raleway-Regular-normal'
import '../fonts/Raleway-Thin-normal'
import '../fonts/Roboto-Bold-normal'
import '../fonts/Roboto-Regular-normal'

import pages from './pages'

moment.locale('pt-br')
var dateNow = moment().format('MMM YYYY')
dateNow = dateNow.charAt(0).toUpperCase() + dateNow.slice(1)

const completeIncidentReportPdf = async (
  company,
  userCompany,
  incident,
  customStyles,
  customLogo,
) => {
  const ReportCompleteIncidentPdf = async (company, userCompany) => {
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      userUnit: 300,
    })

    try {
      templates.firstCover(
        pdf,
        userCompany?.company?.name,
        userCompany?.company?.cpf || userCompany?.company?.cnpj,
        dateNow,
        customStyles,
        customLogo,
        'Formulário de Comunicação \nde Incidente de Segurança com Dados Pessoais \n- ANPD',
      )

      pages.generalInformation(pdf, `Incidente | ${dateNow}`, incident)

      pages.occurrenceScience(pdf, `Incidente | ${dateNow}`, incident)

      pages.timmingCommunication(pdf, `Incidente | ${dateNow}`, incident)

      pages.impactDescription(pdf, `Incidente | ${dateNow}`, incident)

      pages.riskConsequence(pdf, `Incidente | ${dateNow}`, incident)

      pages.securityMeasures(pdf, `Incidente | ${dateNow}`, incident)

      templates.lastCover(pdf, customStyles, customLogo)

      pdf.save(
        `Relatorio_de_Incidente_completo_${company?.name
          ?.split(' ')
          ?.join('_')
          ?.toLowerCase()}_${dateNow}.pdf`,
      )
    } catch (e) {
      Sentry.captureException(e)
    }
  }
  return ReportCompleteIncidentPdf(company, userCompany)
}

export default completeIncidentReportPdf
