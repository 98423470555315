import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  paperContainer: {
    width: 'fit-content',
    padding: theme.spacing(2),
  },
}))

export default styles
