import React, { useState, useEffect } from 'react'

import RmcContext from 'contexts/RmcContext'

import * as service from 'service'

import helpers from 'helpers'
import constants from 'constants/index'

const RmcProvider = ({ children }) => {
  const [data, setData] = useState({})
  const [oldScreen, setOldScreen] = useState()
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [isEditable, setIsEditable] = useState(true)
  const [riskStep, setRiskStep] = useState(false)
  const [securityMeasures, setSecurityMeasures] = useState([])
  const [initialSecurityMeasures, setInitialSecurityMeasures] = useState([])

  const loadData = async (nonComplianceReportId) => {
    setLoading(true)

    let response = await service.dponet.nonComplianceReports.get({
      nonComplianceReportId,
    })
    const rmc = response?.data?.nonComplianceReport
    const isNotEditable =
      constants.nonComplianceReports.NOT_EDITABLE_STATUSES.includes(rmc?.status)
    const riskStep =
      helpers.nonComplianceReports.finalityCheck.fragilityAndNecessary(rmc) &&
      rmc.unnecessaryDatas[0]?.status !==
        constants.unnecessaryData.UNNECESSARY_STATUS

    setIsEditable(!isNotEditable)
    setRiskStep(riskStep)

    setData(rmc)
    setInitialSecurityMeasures(
      rmc?.securityMeasures?.filter(
        (securityMeasure) =>
          securityMeasure?.measureType ===
          constants.nonComplianceReports.QUESTION_FINALITY,
      ),
    )

    const actionsWithResponsible = rmc?.nonComplianceReportActions?.filter(
      (nonComplianceReportAction) => {
        return nonComplianceReportAction?.responsableUser !== null
      },
    )

    const isOldScreen =
      rmc?.entityType !== constants.nonComplianceReports.ENTITY_DATA_PROCESS ||
      helpers.nonComplianceReports.statusesCheck.riskAccepted(rmc) ||
      actionsWithResponsible?.length > 0

    setOldScreen(isOldScreen)
    setLoading(false)
  }

  useEffect(() => {
    if (data.id) {
      loadData(data.id)
    }
    // eslint-disable-next-line
  }, [refresh])

  const isContinuedFinalized =
    data?.chosenAction ===
      constants.nonComplianceReports.CONTINUED_CHOSEN_ACTION &&
    helpers.nonComplianceReports.statusesCheck.isFinalized(data)

  const reload = () => {
    setSecurityMeasures([])
    setRefresh(refresh + 1)
  }

  return (
    <RmcContext.Provider
      value={{
        data,
        setData,
        loading,
        setLoading,
        loadData,
        reload,
        isEditable,
        setIsEditable,
        riskStep,
        setRiskStep,
        isContinuedFinalized,
        oldScreen,
        securityMeasures,
        setSecurityMeasures,
        initialSecurityMeasures,
        setInitialSecurityMeasures,
      }}
    >
      {children}
    </RmcContext.Provider>
  )
}

export default RmcProvider
