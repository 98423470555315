import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  infoPaper: {
    padding: theme.spacing(2),
    textAlign: 'justify',
  },
}))

export default styles
