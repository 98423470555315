import constants from 'constants/index'

const convertRisk = (text, ripd) => {
  const optionsFragilities = ripd
    ? constants?.ripdRisk?.RISK
    : constants?.ripdRisk?.RISK_ROPA

  return optionsFragilities.filter((fragility) => {
    const valueRisk = fragility?.name === text
    if (valueRisk) {
      if (fragility?.id === 5) {
        return false
      }

      return true
    }

    return false
  })
}

export default convertRisk
