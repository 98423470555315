import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import { BaseDialog } from 'components'

import helpers from 'helpers'

import theme from 'theme'
import constants from 'constants/index'

const ConsentDetailsDialog = ({ open, setOpen, consent }) => {
  const consentItems = [
    consent?.banner?.domain,
    consent?.userAgent,
    helpers.cookies.consent.readingTypeTranslation(consent?.kind),
    helpers.formatters.ptBrFormatDateTime(
      consent?.datetimeConsent,
      'Não encontrada',
    ),
    consent?.tags?.optIns.join('; ') || 'Não encontrada',
    consent?.tags?.optOuts.join('; ') || 'Não encontrada',
  ]

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Detalhes do Consentimento"
      fullWidth
      dialogSize="md"
      closeButtonText="Fechar"
    >
      <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
        <Typography>Algumas informações sobre esse consentimento</Typography>
        {constants.cookies.consent.CONSENT_DETAILS_DIALOG_ITEMS.map(
          (item, index) => (
            <Box key={index}>
              <Typography variant="h6">{item.title}</Typography>
              <Typography>{item?.value(consentItems[index])}</Typography>
            </Box>
          ),
        )}
      </Box>
    </BaseDialog>
  )
}

ConsentDetailsDialog.propTypes = {
  consent: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default ConsentDetailsDialog
