import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid, Typography } from '@material-ui/core'

import useStyles from './styles'

const SupplierStatsCards = ({ label, value }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6}>
      <Box
        display="flex"
        flexDirection="column"
        className={classes.supplierContainer}
      >
        <Box className={classes.labelBox} py={2}>
          <Typography variant="body1" className={classes.titleTypography}>
            {label?.toUpperCase()}
          </Typography>
        </Box>
        <Box className={classes.valueBox}>
          <Typography variant="h1" className={classes.valueTypography}>
            {value}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}

SupplierStatsCards.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default SupplierStatsCards
