import creditCardType from 'credit-card-type'

const getMethodIdByBrand = (number) => {
  number = number.split(' ').join('')
  const card = creditCardType(number).filter((card) => {
    return card.type
  })[0]
  const brand = card?.type
  switch (brand) {
    case 'mastercard':
      return 1
    case 'visa':
      return 2
    case 'american-express':
      return 3
    case 'elo':
      return 4
    case 'hipercard':
      return 5
    default:
      return null
  }
}

export default getMethodIdByBrand
