const styles = (theme) => ({
  perfectScrollbar: {
    maxHeight: '67vh',
  },
  document: {
    color: theme.palette.secondary.main,
  },
})

export default styles
