import constants from 'constants/index'

const entitiesByCategory = (fragilityEntities = []) => {
  return fragilityEntities?.map((category) => {
    if (
      category.entityType === constants.fragilityEntities.COMPANY_ENTITY_TYPE
    ) {
      return {
        entityType: category.entityType,
        name: category.group ?? category.name,
        id: category?.entityId ?? category?.id,
        fragilityEntities: [
          { ...category, name: 'Ocorre em toda a organização' },
        ],
      }
    }

    if (
      category.entityType ===
      constants.fragilityEntities.DATA_PROCESS_ENTITY_TYPE
    ) {
      return {
        entityType: category.entityType,
        name: category.group,
        id: category?.id,
        departmentId: category?.departmentId,
        fragilityEntities: fragilityEntities?.filter(
          (entity) => entity?.group === category?.group,
        ),
      }
    }

    return {
      entityType: category.entityType,
      name: category?.label ?? category?.name ?? category.group,
      id: category?.entityId ?? category?.id,
      fragilityEntities: [
        {
          name: 'Todos os processos',
        },
      ],
    }
  })
}

export default entitiesByCategory
