import SupplierShow from './SupplierShow'
import ClientShow from './ClientShow'
import SuppliersMain from './SuppliersMain'
import ClientsMain from './ClientsMain'
import ClientEdit from './ClientEdit'
import ClientNew from './ClientNew'
import Logs from './Logs'
import SuggestionMain from './SuggestionMain'
import SupplierInvites from './SupplierInvites'

const Suppliers = {
  SupplierShow,
  ClientShow,
  SuppliersMain,
  ClientsMain,
  ClientEdit,
  ClientNew,
  Logs,
  SuggestionMain,
  SupplierInvites,
}

export default Suppliers
