import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  chip: {
    borderRadius: 4,
    width: '100%',
  },
  lowFragility: {
    backgroundColor: '#087DF138',
  },
  mediumFragility: {
    backgroundColor: '#E8F5E9',
  },
  highFragility: {
    backgroundColor: '#FFF3E0',
  },
  severeFragility: {
    backgroundColor: '#FEEBEE',
  },
  undefinedFragility: {
    backgroundColor: '#FEEBEE',
  },
  textLowFragility: {
    color: '#007bff',
    fontWeight: 700,
  },
  textMediumFragility: {
    color: '#4CAF50',
    fontWeight: 700,
  },
  textHighFragility: {
    color: '#F4A460',
    fontWeight: 700,
  },
  textSevereFragility: {
    color: '#E53935',
    fontWeight: 700,
  },
  textUndefinedFragility: {
    color: '#A9A9A9',
    fontWeight: 700,
  },
}))

export default styles
