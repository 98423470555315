const defaultTab = (questions) => {
  const isPending = questions
    ?.map((question) => question?.answer === null)
    .includes(true)

  if (isPending) return 'pending'
  return 'all'
}

export default defaultTab
