import React, { useState } from 'react'
import clsx from 'clsx'

import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Link,
  Button,
} from '@material-ui/core'

import { getBaseURL } from 'service/env'

import useStyles from './styles'

import { FundamentalRequirements } from '../'
import helpers from 'helpers'

const AvaliationAndTerms = ({ setRenderedContent }) => {
  const { mixpanel } = helpers
  const classes = useStyles()
  const [termsAccept, setTermsAccept] = useState(false)

  const handleNextStep = () => {
    if (termsAccept) {
      mixpanel.track('Jornada de Fornecedores', 'Faça sua avaliação!', {
        action: 'Faça_seu_diagnóstico-Faça_sua_avaliação!',
      })
      setRenderedContent(
        <FundamentalRequirements setRenderedContent={setRenderedContent} />,
      )
    }
  }

  const redirectToTerms = () => {
    mixpanel.track('Jornada de Fornecedores', 'Faça sua avaliação!', {
      action: 'Termos_e_condições-Faça_sua_avaliação!',
    })
    window.open(`${getBaseURL('website')}/termos-e-avisos/`)
  }

  return (
    <Box mt={15} p={0}>
      <Grid container justify="center" className={classes.mainContainer}>
        <Grid item xs={12}>
          <Typography className={classes.textFormat}>
            Faça sua avaliação!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={clsx(classes.textFormat, classes.bodyText)}>
            Você <span>deve</span> estar adequado à LGDP para não perder
            contratos! <br />
            São 22 perguntas que irão medir sua conformidade em relação aos
            requisitos fundamentais
          </Typography>
        </Grid>
        <form onSubmit={handleNextStep}>
          <Grid item xs={12} className={classes.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={termsAccept}
                  required
                  onChange={(event) => {
                    setTermsAccept(event.target.checked)
                  }}
                  name="termsAccept"
                />
              }
            />
            <Typography
              className={clsx(classes.textFormat, classes.checkboxText)}
            >
              Li e aceito os{' '}
              <Link onClick={redirectToTerms} className={classes.pointer}>
                {' '}
                termos e condições.
              </Link>{' '}
              Declaro que responderei apenas a verdade e estou ciente de que
              podem ser exigidas evidencias da implementação.
            </Typography>
          </Grid>
          <Grid>
            <Button className={classes.roundedButton} type="submit" fullWidth>
              Faça seu diagnóstico
            </Button>
          </Grid>
        </form>
      </Grid>
    </Box>
  )
}

export default AvaliationAndTerms
