import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { filter, isEmpty, uniqBy } from 'lodash'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Box, Button, Paper, Typography } from '@material-ui/core'
import { MenuButton } from 'components'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { FragilityEntitiesDialog } from './components'
import { ConfirmationDialog } from 'views/CompanyFragilities/components'

import useCompanyFragility from 'hooks/useCompanyFragility'
import useSnackbar from 'hooks/useSnackbar'

import { Edit as EditIcon, PlusCircle as PlusCircleIcon } from 'react-feather'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

const FragilityEntities = ({ setLoading, loading }) => {
  const snackbar = useSnackbar()

  const [openFragilitySelection, setOpenFragilitySelection] = useState(false)
  const [departments, setDepartments] = useState([])
  const [destroyOpen, setDestroyOpen] = useState(false)
  const [fragilityEntity, setFragilityEntity] = useState()

  const {
    fragilitiesSelected,
    setFragilitiesSelected,
    setFragilityEntities,
    fragilityEntities,
    companyFragility,
  } = useCompanyFragility()

  const defaultApplyToCompany = useMemo(
    () =>
      fragilitiesSelected?.find(
        (entity) =>
          entity.entityType === constants.fragilityEntities.COMPANY_ENTITY_TYPE,
      ),
    [fragilitiesSelected],
  )

  const loadDepartaments = async () => {
    if (isEmpty(departments)) {
      setLoading(true)
      const response = await service.dponet.departments.get({
        perPage: 1000,
        active: true,
      })

      setDepartments(response?.data?.departments)
      setLoading(false)
    }
  }

  const destroyFragility = async (fragilityEntity) => {
    try {
      if (!!companyFragility?.id) {
        const fragilityEntityId = fragilitiesSelected?.find(
          (fragilitySelected) =>
            fragilitySelected.entityId === fragilityEntity?.id,
        )?.id

        if (fragilityEntityId) {
          await service.dponet.companyFragilities.destroyFragilityEntity({
            companyFragilityId: companyFragility.id,
            fragilityEntityId,
          })
        }

        setFragilityEntities(
          filter(
            fragilityEntities,
            (fragility) => fragility?.entityId !== fragilityEntity?.id,
          ),
        )
      }

      const keyEntity = !!companyFragility?.id ? 'entityId' : 'id'

      setFragilitiesSelected(
        filter(
          fragilitiesSelected,
          (fragility) => fragility[keyEntity] !== fragilityEntity?.id,
        ),
      )
    } catch (error) {
      throw error
    }
  }

  const manageEntities = async (data) => {
    if (!!companyFragility?.id) {
      try {
        const response =
          await service.dponet.companyFragilities.manageFragilityEntities({
            companyFragilityId: companyFragility.id,
            ...data,
          })

        const entites = response?.data?.fragilityEntities

        setFragilityEntities(entites)
        setFragilitiesSelected(entites)
      } catch (error) {
        throw error
      }
    }
  }

  const handleDestroy = async () => {
    try {
      setLoading(true)
      if (
        fragilityEntity?.entityType ===
        constants.fragilityEntities.DATA_PROCESS_ENTITY_TYPE
      ) {
        const data = helpers.fragilityEntities.idsToDestroy(
          fragilityEntity?.fragilityEntities,
        )

        await manageEntities(data)
        setFragilitiesSelected(
          filter(
            fragilitiesSelected,
            (fragility) =>
              fragility?.departmentId !== fragilityEntity?.departmentId,
          ),
        )
      } else {
        await destroyFragility(fragilityEntity)
      }
      setFragilityEntity()
      setDestroyOpen(false)

      snackbar.open({
        variant: 'success',
        message: 'Local de ocorrência excluida com sucesso',
      })
      setLoading(false)
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
      })
    }
  }

  const handleConfirmDestroy = (fragilityEntity) => {
    setDestroyOpen(true)
    setFragilityEntity(fragilityEntity)
  }

  const entitiesByCategory = uniqBy(
    helpers.fragilityEntities.entitiesByCategory(fragilitiesSelected),
    'name',
  )

  const isEntitiesEmpty = isEmpty(entitiesByCategory)

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={4}
        py={2}
      >
        <Typography variant="h4">Local de ocorrência</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={isEntitiesEmpty ? <PlusCircleIcon /> : <EditIcon />}
          onClick={() => setOpenFragilitySelection(true)}
        >
          {isEntitiesEmpty
            ? 'Adicionar local de ocorrência'
            : 'Editar locais de ocorrência'}
        </Button>
      </Box>
      {isEntitiesEmpty && (
        <Typography variant="overline">
          Nenhum local de ocorrência foi adicionado
        </Typography>
      )}

      {entitiesByCategory?.map((entity) => (
        <Paper key={entity?.name} variant="outlined" component={Box} mt={2}>
          <PerfectScrollbar options={{ useBothWheelAxes: true }}>
            <Table size="small" emptyMessage="Nenhum local encontrado">
              <TableHead>
                <TableRow>
                  <TableCell width="95%">{entity?.name}</TableCell>
                  <TableCell align="right">
                    <MenuButton>
                      <Button onClick={() => handleConfirmDestroy(entity)}>
                        Excluir
                      </Button>
                    </MenuButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entity?.fragilityEntities?.map((fragilityEntity) => (
                  <TableRow key={fragilityEntity?.id}>
                    <TableCell>
                      <Box my={1}>{fragilityEntity?.name}</Box>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Paper>
      ))}
      <FragilityEntitiesDialog
        manageEntities={manageEntities}
        defaultApplyToCompany={!!defaultApplyToCompany}
        defaultSelected={helpers.fragilityEntities.defaultDataMultiOptions(
          fragilitiesSelected,
        )}
        open={openFragilitySelection}
        setOpen={setOpenFragilitySelection}
        loadDepartaments={loadDepartaments}
        departments={departments}
        setLoading={setLoading}
        loading={loading}
      />
      <ConfirmationDialog
        open={destroyOpen}
        setOpen={setDestroyOpen}
        title="Você tem certeza que deseja excluir este local de ocorrência?"
        actionButtonText="Excluir"
        handleSave={handleDestroy}
        description={`Ao excluir um local de ocorrência, todos os processos vinculados a este departamanto também serão desvinculados do risco.
\nPara excluir apenas processos específicos, clique em editar locais de ocorrência.`}
      />
    </>
  )
}

FragilityEntities.propTypes = {
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
}

export default FragilityEntities
