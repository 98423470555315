import functions from 'helpers/functions'

import * as yup from 'yup'

const schema = yup.object({
  name: yup.string().min(4).max(150).required(),
  number: yup
    .string()
    .min(16)
    .max(23)
    .required()
    .test(
      'cardNumberIsValid',
      'Cartão inválido',
      (value) => !!functions.cardFlag(value),
    ),
  month: yup.string().required(),
  year: yup.number().required(),
  cvv: yup
    .string()
    .min(3, 'Código Inválido')
    .max(4, 'Código Inválido')
    .required(),
})
export default schema
