import cyberImage from 'images/cyber-cover.png'
import protecaoPerdasImage from 'images/cyber-protecao-perdas.png'
import coberturaAbrangenteImage from 'images/cyber-cobertura-abrangente.png'
import suporteEspecializadoImage from 'images/cyber-suporte-especializado.png'

import benefitProtecaoPerdasImage from 'images/cyber-benefit-protecao.png'
import benefitSuporteImage from 'images/cyber-benefit-suporte.png'
import benefitComplianceImage from 'images/cyber-benefit-compliance.png'
import benefitPreparacaoImage from 'images/cyber-benefit-preparacao.png'
import benefitProtecaoEmpresasImage from 'images/cyber-benefit-protecao-empresa.png'
import benefitGerenciamentoImage from 'images/cyber-benefit-gerenciamento.png'

const cyberIcons = [
  {
    title: 'Proteção contra perdas financeiras',
    altText: 'Proteção contra perdas financeiras',
    src: protecaoPerdasImage,
  },
  {
    title: 'Cobertura abrangente',
    altText: 'Cobertura abrangente',
    src: coberturaAbrangenteImage,
  },
  {
    title: 'Suporte especializado',
    altText: 'Suporte especializado',
    src: suporteEspecializadoImage,
  },
]

const cyber = {
  id: 38,
  sectionTitle: 'Seguro Cyber',
  description:
    'O Marketplace DPOnet oferece o Seguro Cyber que visa proteger empresas de possíveis perdas financeiras decorrentes de ataques virtuais maliciosos, erros ou negligências internas que resultem em vazamento de dados e outros danos ligados ao sigilo da informação. A tecnologia alterou a maneira como os negócios são feitos e, com ataques virtuais cada vez mais sofisticados, os riscos cibernéticos ou cyber risks são uma realidade nas companhias.',
  whatIs: {
    primaryText: 'O que é o Seguro Cyber?',
    text: 'O seguro cibernético é uma proteção vital para nossos dias digitais. Imagine-o como um guarda-costas para seus dados online. Ele cobre danos causados por ataques virtuais, como hackers, vírus ou até mesmo um descuido acidental. Se sua informação pessoal ou empresarial for comprometida, o seguro entra em ação, cobrindo prejuízos financeiros e ajudando na recuperação. É como um escudo para seu mundo digital, garantindo tranquilidade em um universo cada vez mais conectado.',
    icons: cyberIcons,
  },
  forWho: {
    text: 'Empresas de todos os tamanhos e setores estão cada vez mais vulneráveis a ameaças cibernéticas, tornando o seguro cibernético essencial. Grandes corporações, instituições financeiras e setores de saúde, que lidam com dados sensíveis, são clientes naturais. Pequenas e médias empresas também devem considerar, pois muitas vezes têm menos proteção e são alvos fáceis.',
    cover: cyberImage,
    coverText: 'Imagem de uma mão segurando uma lapa através de um escudo',
    benefitsList: [
      'Minimização de riscos financeiros',
      'Rápida recuperação em caso de ataque',
      'Conformidade Legal e Normativa',
      'Confiança do cliente reforçada',
    ],
  },
  benefits: [
    {
      title: 'Proteção contra perdas financeiras',
      altText: 'Proteção contra perdas financeiras',
      description:
        'Visa proteger empresas de possíveis perdas financeiras decorrentes de ataques virtuais maliciosos, erros ou negligências internas que resultem em vazamento de dados e outros danos ligados ao sigilo da informação.',
      icon: benefitProtecaoPerdasImage,
    },
    {
      title: 'Suporte especializado',
      altText: 'Suporte especializado',
      description:
        'Oferece suporte técnico de consultoria especializada e equipe especializada em sinistros disponível para prestar todo o suporte ao segurado quando necessário.',
      icon: benefitSuporteImage,
    },
    {
      title: 'Compliance com a LGPD',
      altText:
        'Ajuda as empresas a estarem em compliance com a Lei Geral de Proteção de Dados (LGPD).',
      description:
        'Ajuda as empresas a estarem em compliance com a Lei Geral de Proteção de Dados (LGPD).',
      icon: benefitComplianceImage,
    },
    {
      title: 'Preparação para ataques cibernéticos',
      altText: 'Preparação para ataques cibernéticos',
      description:
        'Ajuda as empresas a se prepararem para possíveis ataques cibernéticos, com suporte técnico especializado e cobertura abrangente.',
      icon: benefitPreparacaoImage,
    },
    {
      title: 'Proteção para empresas de todos os portes',
      altText: 'Proteção para empresas de todos os portes',
      description:
        'Voltado para empresas de todos os portes e segmentos que desejam se proteger contra os riscos cibernéticos',
      icon: benefitProtecaoEmpresasImage,
    },
    {
      title: 'Gerenciamento de crise',
      altText: 'Gerenciamento de crise',
      description:
        'Oferece gerenciamento de crise para o tomador, incluindo suporte técnico especializado e cobertura abrangente.',
      icon: benefitGerenciamentoImage,
    },
  ],
}

export default cyber
