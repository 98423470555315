import React from 'react'
import useStyles from './styles'

const GreenHighlighted = ({ children }) => {
  const classes = useStyles()

  return <span className={classes.greenHighlighted}>{children}</span>
}

export default GreenHighlighted
