const MAIL_ACTION = 'mail'
const CREATE_ACTION = 'create'
const DESTROY_ACTION = 'destroy'

const HELPER_ENTITIES = {
  companySupplier: 'companySuppliers',
}

export default {
  MAIL_ACTION,
  CREATE_ACTION,
  DESTROY_ACTION,
  HELPER_ENTITIES,
}
