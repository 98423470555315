import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.subscription.primary.main,
    color: theme.palette.white,
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
  },
  tableHeader: {
    background: theme.palette.primary.main,
    color: theme.palette.white,
  },
  tableTitle: {
    color: 'white',
    fontSize: 18,
  },
  boxValue: {
    display: 'flex',
    alignItems: 'center',
    height: 36,
    borderRadius: 17,
  },
  percentageTypo: {
    paddingBottom: theme.spacing(1.5),
    fontWeight: 700,
  },
  complianceValue: {
    fontSize: 70,
    fontWeight: 700,
  },
  button: {
    color: theme.palette.white,
  },
}))

export default styles
