import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Link,
} from '@material-ui/core'

import { Permitted, Select as SelectComponent, Card } from 'components'
import { LoadingFeedback, ProfileSection } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import { routes } from 'Routes'
import helpers from 'helpers'
import constants from 'constants/index'

import schemaUser from './schema'
import useStyles from './styles'

const UsersForm = ({
  userCompanyId,
  isEdit,
  user,
  userCompany,
  profiles,
  loadingProfiles,
}) => {
  const responsibleProfile =
    userCompany?.profile?.id === constants.user.RESPONSIBLE_PROFILE_ID

  const history = useHistory()
  const snackbar = useSnackbar()
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [profile, setProfile] = useState({})

  const { setValue, handleSubmit, errors, reset, getValues, watch, control } =
    useForm({
      validationSchema: schemaUser(responsibleProfile),
      defaultValues: {
        ...user,
        profileId: userCompany?.profile?.id || '',
        phone: helpers.formatters.phoneDynamicMask(user?.phone || ''),
      },
    })

  const handleReturn = () => {
    history.push(routes.user.all)
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      if (isEdit) {
        await service.dponet.usersCompanies.edit({
          userCompanyId,
          profileId: data.profileId,
        })

        await service.dponet.users.edit({ userId: user.id, ...data })

        Object.keys(user).map((key) => setValue(key, user[key]))

        history.push(routes.user.all)
        snackbar.open({
          message: 'Usuário atualizado com sucesso',
          variant: 'success',
        })
      } else {
        await service.dponet.users.create({ user: data })

        history.push(routes.user.all)
        snackbar.open({
          message: 'Usuário criado com sucesso',
          variant: 'success',
        })
      }
    } catch (error) {
      reset(getValues())
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const selectedProfile = () => {
    if (responsibleProfile) {
      return setProfile(userCompany?.profile)
    }

    const profileId = watch('profileId')
    if (!!profileId && !!profiles) {
      const profile = profiles.find((profile) => profile.id === profileId)

      if (!!profile) {
        return setProfile(profile)
      }
    }

    return setProfile({})
  }

  const navigateToCreateProfile = () => {
    history.push(routes.profiles.new)
  }

  useEffect(() => {
    selectedProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('profileId')])

  return (
    <>
      <LoadingFeedback open={loading || loadingProfiles} />
      <form onSubmit={handleSubmit(onSubmit)} id="user-create-form">
        <Card title="Preencha os dados do usuário">
          <Grid container spacing={1}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Controller
                control={control}
                as={
                  <TextField
                    label="Nome"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    fullWidth
                  />
                }
                name="name"
                mode="onChange"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Controller
                control={control}
                as={
                  <TextField
                    label="Email"
                    type="email"
                    color="primary"
                    variant="outlined"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    fullWidth
                  />
                }
                name="email"
                mode="onBlur"
              />
            </Grid>
            {responsibleProfile && (
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Controller
                  control={control}
                  as={
                    <TextField
                      label="Telefone"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.phone}
                      helperText={errors?.phone?.message}
                      fullWidth
                    />
                  }
                  onChange={([e]) => {
                    return helpers.formatters.phoneDynamicMask(e.target.value)
                  }}
                  name="phone"
                  mode="onChange"
                />
              </Grid>
            )}
          </Grid>
        </Card>

        <Box mt={3}>
          <Typography variant="h4">Perfil de usuário e permissão</Typography>
          <Box mt={1}>
            {!responsibleProfile && (
              <>
                <Box className={classes.selectProfileBox}>
                  <Controller
                    control={control}
                    name="profileId"
                    mode="onBlur"
                    as={
                      <SelectComponent
                        label="Selecione o perfil de usuário"
                        items={profiles || []}
                        error={!!errors.profileId}
                        helperText={errors?.profileId?.message}
                      />
                    }
                  />
                </Box>
                <Permitted tag={constants.permissions.PROFILES.CREATE}>
                  <Box pt={1}>
                    <Typography variant="body2" color="textPrimary">
                      Não encontrou a opção? Clique{' '}
                      <Link
                        onClick={navigateToCreateProfile}
                        className={classes.link}
                      >
                        aqui
                      </Link>{' '}
                      para adicionar
                    </Typography>
                  </Box>
                </Permitted>
              </>
            )}
          </Box>
          {!isEmpty(profile) && (
            <Box mt={2}>
              <ProfileSection
                simplifiedProfile={profile}
                profileId={profile.id}
              />
            </Box>
          )}
        </Box>

        <Box mt={2} display="flex" justifyContent="flex-start">
          <Box pr={1}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => handleReturn()}
            >
              Voltar
            </Button>
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              form="user-create-form"
            >
              Salvar
            </Button>
          </Box>
        </Box>
      </form>
    </>
  )
}

UsersForm.propTypes = {
  userCompanyId: PropTypes.number,
  isEdit: PropTypes.bool,
  user: PropTypes.object,
  userCompany: PropTypes.object,
}

UsersForm.defaultProps = {
  isEdit: false,
}

export default UsersForm
