import { colors } from '@material-ui/core'

export default {
  head: {
    color: '#263238',
    fontWeight: 700,
    borderColor: colors.grey[300],
  },
  root: {
    borderBottom: `1px solid ${colors.grey[300]}`,
  },
  stickyHeader: {
    zIndex: 0
  }
}
