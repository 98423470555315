import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'

import { Input } from './components'

import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'

import useStyles from './styles'
import * as service from 'service'

const Form = ({ accessToken, setLoading }) => {
  const [inputValues, setInputValues] = useState(Array(5).fill(''))
  const [selectedInput, setSelectedInput] = useState(0)

  const [disableInputs, setDisableInputs] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()
  const auth = useAuth()

  const normalizeIndex = (index) => {
    if (index > 4) return 4
    if (index < 0) return 0

    return index
  }

  const handleInput = (index, value) => {
    setInputValues([
      ...inputValues.slice(0, index),
      value,
      ...inputValues.slice(index + 1),
    ])

    if (value !== '' && index !== 4) handleForward(index)
  }

  const handleForward = (index) => {
    setSelectedInput(normalizeIndex(index + 1))
  }

  const handleBack = () => {
    if (inputValues[selectedInput] !== '') return

    setSelectedInput((selectedInput) => normalizeIndex(selectedInput - 1))
  }

  const handlePaste = (pastedValue) => {
    setInputValues((inputValues) =>
      inputValues.map((_, index) => pastedValue[index] || ''),
    )
  }

  const handleFocus = (e) => {
    const inputIndex = e.target.id

    if (selectedInput !== inputIndex) setSelectedInput(inputIndex)
  }

  const defineErrorMessage = (error) => {
    const errorMessage = error?.response.data?.error?.message

    if (errorMessage === 'não autorizado')
      return 'O código de autenticação é inválido ou expirou. Verifique-o e tente novamente ou solicite o reenvio.'

    return errorMessage || 'Ocorreu algum erro! Tente novamente!'
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setDisableInputs(true)
    setLoading(true)

    try {
      await service.dponet.twoFactorAuthentication.validateCode({
        accessToken,
        code: inputValues.join(''),
      })

      await auth.loadDataWithAnimation()
    } catch (error) {
      snackbar.open({
        message: defineErrorMessage(error),
        variant: 'error',
      })
    }

    setLoading(false)
    setDisableInputs(false)
  }

  useEffect(() => {
    document.getElementById(normalizeIndex(selectedInput)).focus()
  }, [selectedInput])

  return (
    <Box
      className={classes.container}
      component="form"
      id="2fa-form"
      my={4}
      onSubmit={handleSubmit}
    >
      {inputValues.map((value, index) => (
        <Input
          disabled={disableInputs}
          index={index}
          key={index}
          onBackspace={handleBack}
          onChange={handleInput}
          onFocus={handleFocus}
          onPaste={handlePaste}
          value={value}
        />
      ))}
    </Box>
  )
}

Form.propTypes = {
  accessToken: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default Form
