import { isEmpty } from 'lodash'

import helpers from 'helpers'
import * as helpersRmc from '../helpers'

const actionText = (data) => {
  const necessary = helpers.nonComplianceReports.finalityCheck.necessary(data)
  const unnecessaryDatas = data?.unnecessaryDatas

  if (necessary) {
    if (unnecessaryDatas && isEmpty(unnecessaryDatas)) {
      return 'Dados tratados não informados'
    }
    return `Dados tratados:\n${helpersRmc.specificsRisksText(
      data?.unnecessaryDatas,
      'dataName',
    )}
      `
  }

  if (unnecessaryDatas && isEmpty(unnecessaryDatas)) {
    return `Dados tratados:\n${helpersRmc.specificsRisksText(
      unnecessaryDatas,
      'dataName',
    )}`
  } else {
    return 'Nenhuma ação registrada'
  }
}

export default actionText
