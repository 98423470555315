import React from 'react'
import { Box } from '@material-ui/core'

import { Page, Container, LoadingFeedback } from 'components'
import { SupplierInviteStep } from './components'

import useStyles from './styles'
import useFetch from 'hooks/useFetch'
import useSupplierInvite from 'hooks/useSupplierInvite'

import * as service from 'service'

const SupplierInvite = ({ match }) => {
  const { currentStep } = useSupplierInvite()
  const { companyToken } = match?.params
  const classes = useStyles({ currentStep })

  const { response, isLoading } = useFetch(
    service.dponet.supplierInvite.inviterInfos,
    companyToken,
  )

  const inviter = response?.data?.company

  return (
    <Page title="Convite de fornecedores" className={classes.root}>
      <LoadingFeedback open={isLoading} />
      <Container>
        <Box display="flex" alignItems="center" flexDirection="column">
          <SupplierInviteStep inviter={inviter} companyToken={companyToken} />
        </Box>
      </Container>
    </Page>
  )
}

export default SupplierInvite
