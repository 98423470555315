import React from 'react'
import { Paper } from '@material-ui/core'

import {
  Container,
  ContentHeader,
  LoadingFeedback,
  Page,
  PaperHeader,
} from 'components'
import { PersonalizedServiceForm } from '../components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'
import constants from 'constants/index'

const PersonalizedService = () => {
  const { response, isLoading } = useFetch(
    service.dponet.technicalFaqs.getSubcategory,
    {
      categoryId: constants.technicalFaqs.KNOWLEDGE_BASE_ID,
      minimal: true,
      status: constants.technicalFaqs.ACTIVE_STATUS,
    },
  )

  const faqSubcategories = response?.data?.knowledgeBaseSubcategories

  return (
    <Page title="Solicitar Atendimento Especializado">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false}>
        <ContentHeader title="Solicitar Atendimento Especializado"></ContentHeader>
        <Paper>
          <PaperHeader title="Atendimento especializado" />
          {!isLoading && (
            <PersonalizedServiceForm subcategories={faqSubcategories} />
          )}
        </Paper>
      </Container>
    </Page>
  )
}

export default PersonalizedService
