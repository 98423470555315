import constants from 'constants/index'

const numberGrid = (storageMode) => {
  const { STORAGE_PERMANENT_TYPE } = constants.dataProcess

  if (storageMode === STORAGE_PERMANENT_TYPE) {
    return 12
  } else {
    return 6
  }
}

export default numberGrid
