import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { isEmpty } from 'lodash'

import {
  Box,
  Button,
  Card,
  Container,
  Hidden,
  Tab,
  TablePagination,
  Tabs,
  Tooltip,
} from '@material-ui/core'
import {
  Page,
  ContentHeader,
  FilterButton,
  Permitted,
  DriveTour,
} from 'components'
import { CompanyFragilitiesTable, CompanyFragilityFilters } from './components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import usePermissions from 'hooks/usePermissions'

import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'

import useStyles from './styles'

const CompanyFragilityMain = () => {
  const [openTour, setOpenTour] = useState(false)
  const [statusTab, setStatusTab] = useState('all')

  const filter = useFilter()
  const classes = useStyles()
  const history = useHistory()
  const permissions = usePermissions()

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.companyFragilities.get,
    {
      perPage,
      page,
      order: ['status ASC', 'id DESC'],
      status: statusTab === 'all' ? undefined : statusTab,
      ...filter.filters,
    },
    [page, perPage, filter.filters, statusTab],
  )

  const companyFragilities = response?.data?.companyFragilities

  const hasEditPermission = permissions.permitted(
    constants.permissions.COMPANY_FRAGILITIES.MANAGE_COMPANY_FRAGILITIES,
  )

  const handleNew = () => history.push(routes.companyFragilities.new)

  const handleOpenTour = () => {
    const where = 'Ameaças'
    const title = 'Tour'

    const params = {
      action: 'tour-ameaças-diagnostico',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  const filteredSteps =
    constants.fragilities.FRAGILITIES_MAIN_DRIVER_STEPS_OBJECT.filter(
      (step) => {
        if (isEmpty(companyFragilities))
          return (
            step.element !==
            '#' + constants.fragilities.FRAGILITY_MAIN_DRIVER_STEP_3
          )
        else return true
      },
    )

  return (
    <Page title="Ameaças">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Ameaças">
          <Box className={classes.contentHeader}>
            <Tooltip
              title={
                hasEditPermission
                  ? 'Iniciar o tour guiado'
                  : 'O tour guiado é destinado aos usuários com permissões sobre edição de ameaças'
              }
              placement="top"
            >
              <Box className={classes.contentHeaderBox}>
                <Button
                  id={constants.fragilities.FRAGILITY_MAIN_DRIVER_STEP_1}
                  variant="contained"
                  color="primary"
                  startIcon={<HelpCircleIcon size={20} />}
                  onClick={handleOpenTour}
                  disabled={!hasEditPermission}
                  fullWidth
                >
                  Tutorial
                </Button>
              </Box>
            </Tooltip>
            <Permitted
              tag={
                constants.permissions.COMPANY_FRAGILITIES
                  .MANAGE_COMPANY_FRAGILITIES
              }
            >
              <Box className={classes.contentHeaderBox}>
                <Button
                  id={constants.fragilities.FRAGILITY_MAIN_DRIVER_STEP_2}
                  variant="contained"
                  color="primary"
                  onClick={handleNew}
                  fullWidth
                >
                  Nova Ameaça
                </Button>
              </Box>
            </Permitted>
            <Box className={classes.contentHeaderBox}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} fullWidth />
            </Box>
          </Box>
        </ContentHeader>
        <Box width="100%" mt={1}>
          <Box mb={4}>
            <Tabs
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
              value={statusTab}
              variant="scrollable"
            >
              {constants.fragilities.FRAGILITY_TABS.map((tab) => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                  onClick={() => setStatusTab(tab.value)}
                  wrapped
                />
              ))}
            </Tabs>
          </Box>
          <Card>
            <CompanyFragilitiesTable
              companyFragilities={companyFragilities}
              refresh={refresh}
              isLoading={isLoading}
            />
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Card>
        </Box>
      </Container>

      <CompanyFragilityFilters filter={filter} />
      <DriveTour
        stepsMatrix={filteredSteps}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default CompanyFragilityMain
