const mountGapTabs = (statsGap) => {
  return (
    statsGap?.questionnaires?.map((questionnaire, index) => ({
      value: index + 1,
      label: questionnaire.name,
    })) || []
  )
}

export default mountGapTabs
