import buttonTextByStatus from './buttonTextByStatus'
import dateExpirationBillet from './dateExpirationBillet'
import discountCouponValue from './discountCouponValue'
import helpLinkByStatus from './helpLinkByStatus'
import paymentFormatObject from './paymentFormatObject'
import resumeCompanyData from './resumeCompanyData'
import stylesByStatus from './stylesByStatus'
import textByStatus from './textByStatus'
import convertApiData from './convertApiData'
import convertDataToApi from './convertDataToApi'

const preRegistration = {
  buttonTextByStatus,
  dateExpirationBillet,
  discountCouponValue,
  helpLinkByStatus,
  paymentFormatObject,
  resumeCompanyData,
  stylesByStatus,
  textByStatus,
  convertApiData,
  convertDataToApi,
}

export default preRegistration
