import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    padding: 52,
  },
  title: {
    fontSize: 40,
    fontFamily: 'Raleway',
    fontWeight: 700,
    textAlign: 'center',
  },
  description: {
    fontSize: 21,
    fontWeight: 400,
    fontFamily: 'Roboto',
    textAlign: 'justify',
    color: theme.palette.text.mainMenu,
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 50,
  },
  card: {
    width: 400,
    height: 320,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default styles
