import permissions from '../permissions'

const CONSENT_RECEIPT_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.CONSENT_RECEIPTS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.VISUALIZE,
    tag: permissions.CONSENT_RECEIPTS.SHOW,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.CONSENT_RECEIPTS.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.CONSENT_RECEIPTS.CHANGE_STATUS,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.SOFT_DELETE,
    tag: permissions.CONSENT_RECEIPTS.DESTROY,
  },
]

export default CONSENT_RECEIPT_LIST
