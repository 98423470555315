import React from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Button,
  DialogTitle,
  Typography,
} from '@material-ui/core'

const DialogConfirmChangeAnswer = ({
  open,
  setOpen,
  changeReply,
  setValue,
}) => {
  const confirmChanges = () => {
    setValue(changeReply.name, changeReply.value)
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h5">ATENÇÃO!</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent dividers>
        <Typography variant="body1" gutterBottom>
          Ao alterar a resposta desta questão você está afirmando que já adotava
          esta medida. Portanto, isso resultará na exclusão do RMC e de todas as
          informações relacionadas a ele.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Você tem certeza disso? Se a sua intenção é dizer que executou uma
          melhoria e passou a adotar esta medida, isso deve ser registrado no
          RMC, não sendo correto alterar a resposta.
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={confirmChanges}
        >
          Confirmar - alterar resposta e excluir RMC
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogConfirmChangeAnswer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  changeReply: PropTypes.object,
}

export default DialogConfirmChangeAnswer
