import constants from 'constants/index'

const mountTabsWithQuestionnaires = (questionnaires) => [
  {
    value: constants.companySuppliers.GENERAL_INFORMATION_VALUE,
    label: constants.companySuppliers.GENERAL_INFORMATION_LABEL,
  },
  ...questionnaires?.map((questionnaire) => ({
    value: questionnaire.id,
    label: questionnaire.name,
  })),
]

export default mountTabsWithQuestionnaires
