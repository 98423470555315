import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Chip,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core'

import { Label } from 'components'

import useDataProcess from 'hooks/useDataProcess'
import { isEmpty } from 'lodash'

import useStyles from '../../styles'
import { DialogPermanentStorageAlert } from 'views/DataProcesses/components'

const Laws = ({ laws, setLaws }) => {
  const classes = useStyles()
  const { laws: lawsOptions } = useDataProcess()

  const [openSuccess, setOpenSuccess] = useState(false)

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setLaws(typeof value === 'string' ? value.split(',') : value)
  }

  const handleCloseConfirm = () => {
    setOpenSuccess(false)
  }

  const OpenDialog = () => {
    setOpenSuccess(true)
  }

  const findLawers = (value) => {
    return lawsOptions.find((law) => value === law.id)?.name
  }

  return (
    <>
      <Box className={classes.box}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Label
              title="Motivo"
              description="Qual lei que obriga fazer o armazenamento permanente?"
              item
              xs={12}
            >
              <Select
                fullWidth
                multiple
                value={laws}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => (
                  <Box className={classes.chip}>
                    {selected.map((value, index) => (
                      <>
                        <Chip key={index} label={findLawers(value)} />{' '}
                      </>
                    ))}
                  </Box>
                )}
                error={isEmpty(laws)}
              >
                {lawsOptions.map((option, index) => (
                  <MenuItem key={index} value={option?.id}>
                    {option?.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="caption" color="secondary">
                Não encontrou a justificativa adequada?{' '}
                <span onClick={OpenDialog} className={classes.link}>
                  Clique aqui
                </span>{' '}
                e contate nosso especialista!
              </Typography>
            </Label>
          </Grid>
        </Grid>
      </Box>
      <DialogPermanentStorageAlert
        open={openSuccess}
        setOpen={setOpenSuccess}
        title="Atenção!"
        subtitle="Você tem certeza da necessidade de armazenamento permanente destes dados? Uma das boas práticas em privacidade e proteção de dados é que os dados pessoais sejam eliminados após o término de seu tratamento"
        reload={handleCloseConfirm}
      />
    </>
  )
}

Laws.propTypes = {
  laws: PropTypes.object.isRequired,
  setLaws: PropTypes.isRequired,
}

export default Laws
