import * as html2canvas from 'html2canvas'

const convertObjectToCanvas = async (objectId) => {
  const input = document.getElementById(objectId)
  if (input) {
    const canvas = await html2canvas(input, {
      scale: 2,
    })

    return canvas
  }
}

export default convertObjectToCanvas
