import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import { AppBar, Toolbar, Box, makeStyles } from '@material-ui/core'

import { Account } from './components'

import styles from './styles'
import logo from 'images/dponet-logo-white.svg'

const useStyles = makeStyles(styles)

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()

  return (
    <AppBar elevation={3} className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Box mb={1}>
          <img alt="Logo" src={logo} className={classes.logo} width="100%" />
        </Box>
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
}

export default TopBar
