import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      margin: `0px ${theme.spacing(10)}px`,
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
  },
}))
