const getCardStyle = (classes, expanded, index) => {
  if (index === expanded) {
    return classes.levelTwoCard
  }
  if (index < expanded) {
    return classes.levelOneCard
  }
  return classes.levelThreeCard
}

export default getCardStyle
