import { useContext } from 'react'

import ContentHeaderContext from 'contexts/ContentHeaderContext'

const useContentHeader = () => {
  const context = useContext(ContentHeaderContext)

  if (context === undefined) {
    throw new Error(
      'useContentHeader must be used within a ContentHeaderContext.Provider',
    )
  }

  return {
    historyContentHeader: context.historyContentHeader,
    setHistoryContentHeader: context.setHistoryContentHeader,
    lastScopeContentHeader: context.lastScopeContentHeader,
    setLastScopeContentHeader: context.setLastScopeContentHeader,
    onChangePage: context.onChangePage,
  }
}

export default useContentHeader
