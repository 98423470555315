import React from 'react'

import { Box, Hidden, Typography, Tooltip } from '@material-ui/core'

const LabelIconStart = ({ icon, label, variant = 'h6' }) => (
  <>
    <Hidden xsDown>
      <Tooltip title={label} placement="left">
        <Box display="flex" width="100%">
          <Box px={1}>{icon}</Box>
          <Typography variant={variant}>{label}</Typography>
        </Box>
      </Tooltip>
    </Hidden>
    <Hidden smUp>
      <Tooltip title={label} placement="left">
        <Box px={1}>{icon}</Box>
      </Tooltip>
    </Hidden>
  </>
)

export default LabelIconStart
