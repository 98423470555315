import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'

import {
  TextField,
  Grid,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Divider,
} from '@material-ui/core'

import { Controller } from 'react-hook-form'

import helpers from 'helpers'

const DataController = ({
  errors,
  control,
  SetDataRepresentative,
  dataRepresentative,
  watch,
  setValue,
  representative,
}) => {
  const HandleCommisionerTreatment = (e) => {
    SetDataRepresentative(e.target.value)
  }

  const commissionerFieldValid =
    isEmpty(watch('nameCommissioner')) ||
    isEmpty(watch('emailCommissioner')) ||
    isEmpty(watch('documentCommissioner')) ||
    isEmpty(watch('phoneCommissioner'))

  useEffect(() => {
    if (dataRepresentative === 'true') {
      setValue('nameRepresentative', watch('nameCommissioner'))
      setValue('emailRepresentative', watch('emailCommissioner'))
      setValue(
        'documentRepresentative',
        helpers.formatters.cnpjOrCpf(watch('documentCommissioner')),
      )
      setValue(
        'phoneRepresentative',
        helpers.formatters.phoneWithoutCountryCode(watch('phoneCommissioner')),
      )
    } else {
      setValue('nameRepresentative', representative?.name || '')
      setValue('emailRepresentative', representative?.email || '')
      setValue('documentRepresentative', representative?.document || '')
      setValue('phoneRepresentative', representative?.phone || '')
    }

    if (commissionerFieldValid) {
      SetDataRepresentative('false')
    }
    // eslint-disable-next-line
  }, [dataRepresentative, commissionerFieldValid])

  return (
    <>
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography variant="h6">
            Dados do notificante / Representante legal
          </Typography>
        </Box>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box mt={2} style={{ backgroundColor: '#F4F6F8', padding: '5px' }}>
          <RadioGroup
            value={dataRepresentative}
            row
            onChange={HandleCommisionerTreatment}
          >
            <FormControlLabel
              disabled={commissionerFieldValid}
              value="true"
              control={
                <Radio size="medium" checked={dataRepresentative === 'true'} />
              }
              label="O própio encarregado pela proteção de dados."
            />
            <FormControlLabel
              value="false"
              control={
                <Radio size="medium" checked={dataRepresentative === 'false'} />
              }
              label="Outros (especifique)"
            />
          </RadioGroup>
        </Box>
      </Grid>
      <>
        <Grid item md={6} xs={12}>
          <Box mt={2}>
            <Controller
              as={
                <TextField
                  label="Nome da Empresa"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.nameRepresentative}
                  helperText={errors?.nameRepresentative?.message}
                  fullWidth
                  disabled={dataRepresentative === 'true'}
                />
              }
              control={control}
              name="nameRepresentative"
              mode="onBlur"
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box mt={2}>
            <Controller
              as={
                <TextField
                  label="CNPJ / CPF"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.documentRepresentative}
                  helperText={errors?.documentRepresentative?.message}
                  fullWidth
                  disabled={dataRepresentative === 'true'}
                />
              }
              onChange={([text]) =>
                helpers.formatters.cnpjOrCpf(text.target.value)
              }
              control={control}
              name="documentRepresentative"
              mode="onBlur"
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box mt={2}>
            <Controller
              as={
                <TextField
                  label="Telefone"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.phoneRepresentative}
                  helperText={errors?.phoneRepresentative?.message}
                  fullWidth
                  disabled={dataRepresentative === 'true'}
                />
              }
              onChange={([text]) =>
                helpers.formatters.phoneWithoutCountryCode(text.target.value)
              }
              control={control}
              name="phoneRepresentative"
              mode="onBlur"
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box mt={2}>
            <Controller
              as={
                <TextField
                  label="E-mail"
                  type="email"
                  color="primary"
                  variant="outlined"
                  error={!!errors.emailRepresentative}
                  helperText={errors?.emailRepresentative?.message}
                  fullWidth
                  disabled={dataRepresentative === 'true'}
                />
              }
              control={control}
              name="emailRepresentative"
              mode="onBlur"
            />
          </Box>
        </Grid>
      </>
      <Grid item xs={12}>
        <Box p={2} mt={2} style={{ backgroundColor: '#FFF7E0' }}>
          <Box mb={2}>
            <Typography variant="body1">Exemplos:</Typography>
          </Box>
          <Typography variant="body1">Exemplos:</Typography>
          <Box ml={4}>
            <ul>
              <li>
                <Typography variant="body2">
                  <strong>Encarregado:</strong> ato de
                  designação/nomeação/procuração.
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  <strong>Representante:</strong> ato constitutivo
                  (contrato/estatuto social) e procuração, se cabível.
                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box p={2} mt={2} style={{ backgroundColor: '#F4F6F8' }}>
          <Typography variant="body1">
            Nos termos do REGULAMENTO DE APLICAÇÃO DA LEI Nº 13.709, DE 14 DE
            AGOSTO DE 2018, aprovado pela RESOLUÇÃO CD/ANPD Nº 2, DE 27 DE
            JANEIRO DE 2022.
          </Typography>
          <a
            rel="Governo do brasil - resolução ANPD"
            href="https://www.in.gov.br/en/web/dou/-/resolucao-cd/anpd-n-2-de-27-de-janeiro-de-2022-376562019"
          >
            <Typography
              variant="body1"
              style={{ textDecoration: 'underline', color: 'black ' }}
            >
              (https://www.in.gov.br/en/web/dou/-/resolucao-cd/anpd-n-2-de-27-de-janeiro-de-2022-376562019)
            </Typography>
          </a>
        </Box>
      </Grid>
    </>
  )
}

export default DataController
