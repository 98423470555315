import React from 'react'
import { Box, Card, TablePagination, useMediaQuery } from '@material-ui/core'

import { InvoiceTable } from './components'

import theme from 'theme'

const Invoices = ({
  paymentOrderFetch,
  page,
  perPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleViewContract,
}) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = paymentOrderFetch

  const paymentOrders = response?.data?.paymentOrders

  return (
    <Card component={Box} mt={2}>
      <InvoiceTable
        isLoading={isLoading}
        paymentOrders={paymentOrders}
        refresh={refresh}
        handleViewContract={handleViewContract}
      />
      <Box px={2} display="flex" justifyContent="flex-end">
        <TablePagination
          component="div"
          count={response?.data?.meta?.totalCount || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[5, 10, 25, 100]}
          labelRowsPerPage={isDesktop ? 'Por página' : ''}
          nextIconButtonProps={{ size: 'small' }}
          backIconButtonProps={{ size: 'small' }}
        />
      </Box>
    </Card>
  )
}

export default Invoices
