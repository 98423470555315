import * as yup from 'yup'
import constants from 'constants/index'

const schema = (requiredField) => {
  return yup.object().shape({
    departments: yup.array(),
    legalFrameworks: yup.array(),
    fragilities: yup.string(),
    comment: yup.string().max(constants.reports.CHARACTER_LIMIT),
    ...(requiredField && {
      justificationReport: yup.string().required(),
      anotherJustificationReport: yup.string().when('justificationReport', {
        is: 'another',
        then: yup.string().required().max(constants.reports.CHARACTER_LIMIT),
      }),
    }),
  })
}

export default schema
