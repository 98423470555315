import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import ReCAPTCHA from 'react-google-recaptcha'

import { Page, Container, LoadingFeedback } from 'components'

import { ShoppingBag as ShoppingBagIcon } from 'react-feather'

import useSubscription from 'hooks/useSubscription'
import useSnackbar from 'hooks/useSnackbar'

import { FormCreditCard } from 'components/PreRegistration/Payment/components'
import { HorizontalStepper } from 'components/PreRegistration/Stepper/components'
import { PaymentFeedbackScreen, ResumeCard } from 'components/PreRegistration'

import constants from 'constants/index'

import { getGoogleRecaptchaToken, getPaymentAuthKey } from 'service/env'
import * as service from 'service'

import helpers from 'helpers'
import theme from 'theme'

import useStyles from './styles'

const CheckoutPayment = ({ match }) => {
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  const snackbar = useSnackbar()
  const {
    isLoading,
    recaptcha,
    recaptchaRef,
    resetRecaptcha,
    setHideTopbarResume,
    setIsLoading,
    setRecaptcha,
    setStatus,
    status,
  } = useSubscription()
  const [checkoutData, setCheckoutData] = useState(false)
  const paymentContractToken = match?.params?.contractToken
  const preRegistrationToken = checkoutData?.data?.id

  const classes = useStyles()

  const onRecaptchaExpired = () => {
    setRecaptcha(undefined)
  }
  const onRecaptchaChange = (token) => {
    setRecaptcha(token)
  }

  const submitPayment = async (data) => {
    try {
      setIsLoading(true)
      if (!recaptcha) {
        return snackbar.open({
          message: 'Preencha o recaptcha',
          variant: 'error',
        })
      }
      const brand = constants.cardFlag.CARD_BRAND_DEFINITION_BY_ID[data.method]
      const response = await service.dponet.payments.create({
        authKey: getPaymentAuthKey(),
        paymentOrder: {
          paymentContractToken,
          preRegistrationToken,
          card: {
            brand,
            ...(data.card ?? {}),
          },
        },
      })

      if (response.status === 207) {
        return setStatus(constants.preRegistrations.INTERNAL_ERROR_STATUS)
      }

      setStatus(
        response?.data?.order?.status ??
          constants.preRegistrations.FAILURE_STATUS,
      )
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const showCheckout = async () => {
      try {
        setIsLoading(true)
        const response = await service.dponet.paymentContracts.showCheckout(
          match?.params?.contractToken,
        )

        setCheckoutData(response?.data?.paymentContract)
        resetRecaptcha()
      } catch (error) {
        snackbar.open({
          message: helpers.formatters.errorMessage(error.response.data.error),
          variant: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    }

    setHideTopbarResume(true)
    showCheckout()
    //eslint-disable-next-line
  }, [])

  const steps = [
    { label: 'Sobre você', index: constants.preRegistrations.FIRST_STEP[0] },
    {
      label: 'Sobre a empresa',
      index: constants.preRegistrations.ABOUT_COMPANY_STEP[0],
    },
    { label: 'Pagamento', index: constants.preRegistrations.PAYMENT_STEP[0] },
  ]

  const price = helpers.formatters.numberToPrice(
    parseFloat(checkoutData?.price ?? 0),
  )

  if (!!status) {
    return <PaymentFeedbackScreen />
  }

  return (
    <Page title="Efetuar pagamento" showHiring={false}>
      <LoadingFeedback open={isLoading} />
      <Box display="flex" justifyContent="center">
        <Container
          maxWidth="xl"
          spacingXl={25}
          spacingLg={15}
          spacingMd={10}
          spacingSm={8}
          spacingXs={5}
        >
          <Grid
            container
            spacing={2}
            direction={smallScreen ? 'column-reverse' : 'row'}
          >
            <Hidden mdDown>
              <Grid item xs={12}>
                <Box width="100%" display="flex" justifyContent="center">
                  <HorizontalStepper
                    activeStep={2}
                    setActiveStep={() => {}}
                    greatherStep={2}
                    steps={steps}
                    isFinalized
                  />
                </Box>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={7} lg={8} xl={9}>
              <Paper component={Box} p={2}>
                <FormCreditCard
                  submitPayment={submitPayment}
                  showBrands={false}
                />
              </Paper>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={smallScreen ? 'column' : 'row'}
                mt={2}
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  theme="light"
                  sitekey={getGoogleRecaptchaToken()}
                  onChange={onRecaptchaChange}
                  onExpired={onRecaptchaExpired}
                />
                <Box mt={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    form="form-submit-main"
                    color="primary"
                    disabled={!preRegistrationToken}
                  >
                    Efetuar pagamento
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} lg={4} xl={3}>
              <ResumeCard
                icon={ShoppingBagIcon}
                title="RESUMO DO PEDIDO"
                defaultExpanded
              >
                <Box p={2}>
                  <Box>
                    <Typography variant="body2">Faturar para</Typography>
                    <Typography variant="subtitle1">
                      {checkoutData?.data?.name ?? '-'}
                    </Typography>
                  </Box>
                  <Box my={2}>
                    <Typography variant="body2">Documento</Typography>
                    <Typography variant="subtitle1">
                      {helpers.formatters.cnpjOrCpf(
                        checkoutData?.data?.document,
                      ) ?? '-'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">Mensalidade</Typography>
                    <Typography variant="subtitle1">{price}/mês</Typography>
                  </Box>
                </Box>
                <Divider />
                <Box ml={2.8} my={2} className={classes.gridStart}>
                  <Typography variant="h6">Total</Typography>
                  <Typography variant="h5">{price}</Typography>
                </Box>
              </ResumeCard>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  )
}

export default CheckoutPayment
