const styles = (theme) => ({
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  expanded: {
    margin: '0 !important',
  },
  detailsChildrenContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  accordionContainer: {
    border: 0,
    padding: 0,
    '&:before': {
      display: 'none',
    },
  },
})

export default styles
