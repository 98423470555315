import React from 'react'
import { Box, Typography } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './styles'
import helpers from 'helpers'

const DescriptionLegendColor = ({ title, description, levelId }) => {
  const classes = useStyles()

  return (
    <Box display="flex">
      <Typography
        variant="caption"
        className={clsx(
          classes.textLevel,
          helpers.dataProcess.fragilityColor(levelId, classes),
        )}
      >
        {title}
      </Typography>
      <Typography variant="caption" style={{ display: 'flex' }}>
        {description}
      </Typography>
    </Box>
  )
}

export default DescriptionLegendColor
