import { colors } from '@material-ui/core'

const rncsColors = {
  pending: {
    backgroundColor: colors.cyan[100],
    color: colors.cyan[800],
  },
  onTime: {
    backgroundColor: colors.blue[100],
    color: colors.blue[800],
  },
  substituted: {
    backgroundColor: colors.grey[200],
    color: colors.grey[800],
  },
  finalized: {
    backgroundColor: colors.green[100],
    color: colors.green[800],
  },
  overdue: {
    backgroundColor: colors.red[100],
    color: colors.red[800],
  },
  frozen: {
    backgroundColor: colors.teal[100],
    color: colors.teal[800],
  },
  inactive: {
    backgroundColor: colors.grey[200],
    color: colors.grey[800],
  },
}

export default rncsColors
