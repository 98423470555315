const ANSWER_NO = `Se não houve um incidente de dados pessoais que tenha afetado a confidencialidade, integridade ou disponibilidade de dados pessoais, não é necessário adotar nenhuma medida de comunicação à Autoridade Nacional de Proteção de Dados, nem aos titulares de dados ou registrar a ocorrência do fato.`

const ANSWER_DONT_KNOW = `Um incidente de dados deve ser reportado se existir certeza razoável de que ele ocorreu. Se ainda existem dúvidas sobre a ocorrência de um incidente de dados, o atendimento do DPOnet deve ser acionado para que o possível incidente seja discutido com mais detalhes e seja confirmada a violação.`

const ANSWER_YES = `Nós entendemos. Realmente aconteceu um incidente de dados pessoais! O DPOnet irá apoiar a sua organização para realizar as notificações e esclarecimentos necessários. Agora, será necessário registrar o incidente no DPOnet com todos os detalhes.
                    Após o registro do incidente, será gerado automaticamente um Registro de Melhoria Contínua (RMC) para tratamento.`

export default {
  ANSWER_NO,
  ANSWER_DONT_KNOW,
  ANSWER_YES,
}
