import palette from 'theme/palette'

import { WIDTH as WIDTHPdf, fontSize, PADDING } from '../../constants'

import { ratio } from '../../helpers'

const progressBar = (pdf, positionY, progress, label, isPercentage = true) => {
  const RADIUS = ratio(15)
  const WIDTH = WIDTHPdf(pdf) - PADDING * 2

  pdf.setFillColor(palette.subscription.primary.light)
  pdf.roundedRect(PADDING, positionY, WIDTH, ratio(30), RADIUS, RADIUS, 'F')

  pdf.setFillColor(palette.subscription.primary.main)
  pdf.roundedRect(
    PADDING,
    positionY,
    (WIDTH * progress) / 100,
    ratio(30),
    RADIUS,
    RADIUS,
    'F',
  )
  pdf.setFontSize(fontSize.progressLabel)
  pdf.setTextColor(palette.primary.main)
  pdf.setFont('Raleway-Bold')

  var stringProgress = progress.toString()

  if (isPercentage) {
    stringProgress = stringProgress + '%'
  }

  pdf.text(label, PADDING + ratio(20), positionY - ratio(15))
  pdf.text(stringProgress, WIDTH + PADDING - ratio(70), positionY - ratio(15))
}

export default progressBar
