import components from '../../components'
import palette from 'theme/palette'

import { GAP, PADDING } from '../../constants'
import { cleanConsentContent, ratio } from '../../helpers'

import constants from 'constants/index'
import helpers from 'helpers'

const receipts = (pdf, dateNow, receipts = {}, customStyles) => {
  const { STATUS_LABEL_RECEIPT } = constants.consent

  const answers = receipts?.answers && JSON.parse(receipts?.answers)

  const firstTableColsStyle = {
    0: {
      halign: 'left',
    },
  }

  pdf.addPage()
  components.numberPage(pdf, palette.primary.main, true)
  components.lineWithDate(pdf, `Consentimentos | ${dateNow}`)
  pdf.setFont('Raleway-Regular')
  pdf.setFontSize(ratio(44))

  components.subtitle(pdf, 'Recibo', GAP, palette.primary.hover)

  pdf.setTextColor(palette.text.mainMenu)

  const generatePlanRows = () => [
    ['Formulário', receipts?.consentForm?.title || 'N/A'],
    ['Status', STATUS_LABEL_RECEIPT[receipts?.status] || 'N/A'],
    ['Consentido em', helpers.formatters.date(receipts?.createdAt) || 'N/A'],
    ['Nome', answers?.['Nome completo'] || 'N/A'],
    ['E-mail', receipts.userEmail || 'N/A'],
  ]

  components.table(
    pdf,
    null,
    generatePlanRows(),
    GAP * 1.25,
    0.25,
    PADDING,
    true,
  )

  components.table(
    pdf,
    null,
    [[cleanConsentContent(receipts?.consentForm?.content)]],
    pdf.lastAutoTable.finalY,
    0.25,
    PADDING,
    true,
    firstTableColsStyle,
  )

  components.numberPage(pdf, palette.black)
}

export default receipts
