import { uniqBy } from 'lodash'

const controlByCategory = (questionControls = []) => {
  const categories = uniqBy(questionControls, 'group')

  return categories?.map((category) => {
    return {
      name: category?.categoryId,
      id: category?.categoryId,
      questionControls: questionControls?.filter(
        (questionControl) => questionControl?.group === category?.group,
      ),
    }
  })
}
export default controlByCategory
