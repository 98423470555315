const styles = (theme) => ({
  button: {
    padding: '0px',
    margin: '0px',
    minWidth: theme.spacing(4),
  },
  buttonLabel: {
    textTransform: 'capitalize',
  },
  iconMargin: {
    margin: theme.spacing(1),
  },
  buttonTitle: {
    paddingRight: theme.spacing(1),
  },
})

export default styles
