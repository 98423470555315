import { lightGreen, lightBlue, red } from '@material-ui/core/colors'

const styles = () => ({
  destroyTableCell: {
    backgroundColor: red[50],
  },

  createTableCell: {
    backgroundColor: lightGreen[50],
  },
  updateTableCell: {
    backgroundColor: lightBlue[50],
  },
})

export default styles
