import React from 'react'
import { Box } from '@material-ui/core'

import useStyles from './styles'
import { TopBar } from './components'

const JourneyStepsWithoutUserBox = ({ children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <TopBar />
      <Box className={classes.children}>{children}</Box>
    </Box>
  )
}

export default JourneyStepsWithoutUserBox
