import React, { useState, useRef } from 'react'
import { Box, Button, Link, TextField } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { reverse } from 'named-urls'

import { PasswordInputField } from 'components'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import { getGoogleRecaptchaToken } from 'service/env'
import * as service from 'service'
import schemas from '../../schemas'
import { routes } from 'Routes'
import useStyles from './styles'

const Form = () => {
  const [loading, setLoading] = useState(false)
  const [recaptcha, setRecaptcha] = useState(undefined)

  const recaptchaRef = useRef(null)

  const production = process.env.REACT_APP_API === 'production'
  const disableSubmitButton = production ? !recaptcha || loading : false

  const auth = useAuth()
  const snackbar = useSnackbar()
  const classes = useStyles()
  const history = useHistory()

  const { handleSubmit, errors, control } = useForm({
    validationSchema: schemas.login,
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async (data) => {
    if (disableSubmitButton) return

    try {
      setLoading(true)
      const response = await service.dponet.auth.login({ ...data, recaptcha })

      if (response.status === 202) {
        const accessToken = response.data.accessToken

        history.push({
          pathname: reverse(routes.twoFactorAuthentication, { accessToken }),
          state: {
            email: data.email,
          },
        })
        return
      }

      await auth.loadDataWithAnimation()
    } catch (error) {
      if (production) resetRecaptcha()
      snackbar.open({
        message: defineErrorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const resetRecaptcha = () => {
    recaptchaRef.current.reset()
    setRecaptcha(undefined)
  }

  const defineErrorMessage = (error) => {
    const response = error?.response
    const status = response?.status
    const errorMessage = response.data?.error?.message

    if ((status === 401 || status === 422) && errorMessage) return errorMessage

    return 'Ocorreu algum erro! Tente novamente!'
  }

  const handleAcceptedRecaptcha = (recaptchaToken) => {
    setRecaptcha(recaptchaToken)
  }
  const disableRecaptcha = () => setRecaptcha(undefined)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={
          <TextField
            label="Email"
            type="email"
            color="primary"
            variant="outlined"
            error={!!errors.email}
            helperText={errors?.email?.message}
            fullWidth
          />
        }
        control={control}
        name="email"
        mode="onBlur"
      />
      <Controller
        as={
          <PasswordInputField
            label="Senha"
            type="password"
            variant="outlined"
            margin="normal"
            error={!!errors.password}
            helperText={errors?.password?.message}
            fullWidth
          />
        }
        control={control}
        name="password"
        mode="onBlur"
      />
      {production && (
        <Box mt={1} display="flex" alignItems="center" justifyContent="center">
          <ReCAPTCHA
            ref={recaptchaRef}
            render="explicit"
            sitekey={getGoogleRecaptchaToken()}
            onChange={handleAcceptedRecaptcha}
            onExpired={disableRecaptcha}
          />
        </Box>
      )}
      <Box mt={2} className={classes.actionBox}>
        <Button
          type="submit"
          variant="contained"
          className={classes.buttonPrimary}
          disabled={disableSubmitButton}
        >
          {loading ? 'Carregando...' : 'Acessar'}
        </Button>
        <Link
          component={RouterLink}
          to={routes.forgotPassword}
          variant="body2"
          color="textSecondary"
          className={classes.forgotPasswordLink}
        >
          Esqueceu sua senha?
        </Link>
      </Box>
    </form>
  )
}

export default Form
