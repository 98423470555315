import React from 'react'
import PropTypes from 'prop-types'

import { FormPolicy, FormTerms, FormDemoTerms } from './components'

const TermsToPolicy = ({
  setPolicyAccept,
  setTermsAccept,
  setDemoTermsAccept,
}) => {
  return (
    <>
      <FormPolicy setPolicyAccept={setPolicyAccept} />
      <FormTerms setTermsAccept={setTermsAccept} />
      {!!setDemoTermsAccept && (
        <FormDemoTerms setDemoTermsAccept={setDemoTermsAccept} />
      )}
    </>
  )
}

TermsToPolicy.propTypes = {
  setPolicyAccept: PropTypes.func,
  setTermsAccept: PropTypes.func,
  setDemoTermsAccept: PropTypes.func,
}

TermsToPolicy.defaultProps = {
  setPolicyAccept: () => {},
  setTermsAccept: () => {},
}

export default TermsToPolicy
