import { colors } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const white = '#FFFFFF'

const styles = makeStyles((theme) => ({
  appBar: {
    border: 0,
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.colors.firstStep.secondary,
    boxShadow: 'none',
  },
  container: {
    [theme.breakpoints.up('xl')]: {
      paddingRight: theme.spacing(24),
      paddingLeft: theme.spacing(24),
    },
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  logo: {
    fontSize: 0,
    width: '130px',
    [theme.breakpoints.up('md')]: {
      width: '130px',
    },
  },
  helpChip: {
    backgroundColor: theme.colors.firstStep.button.primary,
    color: white,
    fontSize: 14,
    fontWeight: 500,
    minWidth: 85,
    height: 39,
    borderRadius: 100,
    '&:hover': {
      backgroundColor: colors.green[600],
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      width: 45,
      '& .MuiChip-label': {
        display: 'none',
      },
      '& .MuiChip-icon': {
        margin: 0,
      },
    },
  },
  iconChip: {
    color: white,
    fontSize: 24,
    height: 21,
    width: 21,
  },
}))

export default styles
