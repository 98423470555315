import calculateColors from './calculateColors'
import hexToRgba from './hexToRgba'
import validateColor from './validateColor'

const palette = {
  calculateColors,
  hexToRgba,
  validateColor,
}

export default palette
