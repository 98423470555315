import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  logo: {
    width: '164px',
    height: '50px !important',
    marginBottom: theme.spacing(3),
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 10,
  },
}))

export default styles
