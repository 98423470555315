import autoTable from 'jspdf-autotable'
import palette from 'theme/palette'
import {
  WIDTH as WIDTHPdf,
  PADDING,
  defaultColumStyles,
  defaultHeaderStyles,
} from '../constants/index'

const table = (
  pdf,
  head,
  body,
  startY = 10,
  cellWidth = 0.65,
  margin = PADDING,
  isBold = true,
  columnStyles = defaultColumStyles(cellWidth, pdf),
  headStyles = defaultHeaderStyles,
  fontSize = 10,
  functionToDidDrawCell = () => {},
) => {
  const WIDTH = WIDTHPdf(pdf) - margin * 2

  autoTable(pdf, {
    head: head,
    body: body,
    startY: startY,
    tableWidth: WIDTH,
    margin: margin,
    willDrawCell:
      !isBold &&
      function (data) {
        data.row.index === 0
          ? pdf.setFont('Roboto-Bold')
          : pdf.setFont('Roboto-Regular')
      },
    didDrawCell: functionToDidDrawCell(),
    columnStyles: columnStyles,
    headStyles: headStyles,
    styles: {
      cellWidth: 'auto',
      textColor: palette.primary.main,
      fontSize: fontSize,
    },
    bodyStyles: {
      font: 'Roboto-Regular',
    },
    theme: 'grid',
    showHead: 'firstPage',
  })
}

export default table
