import React from 'react'

import {
  Card,
  Box,
  Grid,
  Typography,
  Divider,
  makeStyles,
} from '@material-ui/core'
import styles from './styles'

const useStyles = makeStyles(styles)

const InactiveQuestionsCard = ({ displayQuestions }) => {
  const classes = useStyles()

  return (
    <>
      <Card>
        <Box>
          {displayQuestions?.length !== 0 ? (
            <>
              {displayQuestions.map((question, index) => (
                <Box key={question.id}>
                  <Box mx={3} my={2} id={question.id}>
                    <Grid container>
                      <Grid item xs={10}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="h5">
                              {index + 1}. {question.theme}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box width="100%" mt={2} mb={1} ml={2}>
                              <Typography variant="body1">
                                {question.question}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid xs={12}>
                        <Box
                          width="100%"
                          mt={1}
                          p={1}
                          className={classes.inactivateReasonBox}
                        >
                          <Box ml={1}>
                            <Typography variant="h6">
                              Motivo da inativação
                            </Typography>
                          </Box>
                          <Box mt={1} ml={1}>
                            <Typography variant="body2">
                              {question?.reason}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider />
                </Box>
              ))}
            </>
          ) : (
            <Box m={3}>
              <Typography variant="body1">
                Não existe nenhuma questão inativa.
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    </>
  )
}

export default InactiveQuestionsCard
