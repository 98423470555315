import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'

import {
  ComplianceByQuestionnaire,
  MyComplianceCard,
  ProgressByQuestionnaire,
} from './components'

import {
  DepartmentsByThreats,
  DistributionRisksDegreeSeverity,
  FragilitiesWithLinkedMeasures,
  SecurityMeasuresByCategory,
  SecurityMeasuresStatus,
  SecurityMeasuresStatusByCategory,
} from 'components/DashboardDiagnostic'

import myLgpdHelpers from 'helpers/myLgpd'

import constants from 'constants/index'

const DashboardDiagnostic = ({
  questionnaires,
  gapStats,
  fragilityStats,
  questionControlStats,
}) => {
  const { gap: gapIds } = constants.myLgpd.GRAPH_IDS

  const departmentsLowestOfThreats = myLgpdHelpers.threatsByDepartment(
    fragilityStats?.departments,
    ['stats.totalCount'],
    ['asc'],
  )

  const departmentsHighestOfThreats = myLgpdHelpers.threatsByDepartment(
    fragilityStats?.departments,
    ['stats.totalCount'],
    ['desc'],
  )

  const departmentsWithLowerSeverity = myLgpdHelpers.threatsByDepartment(
    fragilityStats?.departments,
    ['stats.severe', 'stats.high', 'stats.medium', 'stats.low'],
    ['asc', 'asc', 'asc', 'asc'],
  )

  const departmentsWithGreaterSeverity = myLgpdHelpers.threatsByDepartment(
    fragilityStats?.departments,
    ['stats.severe', 'stats.high', 'stats.medium', 'stats.low'],
    ['desc', 'desc', 'desc', 'desc'],
  )

  return (
    <Fragment>
      <Grid item xs={12}>
        <Typography variant="h4" color="primary">
          Diagnóstico
        </Typography>
      </Grid>
      <Grid item md={4} xs={12}>
        <MyComplianceCard gapStats={gapStats} id={gapIds.complianceCard} />
      </Grid>
      <Grid item md={4} xs={12}>
        <ProgressByQuestionnaire
          questionnaires={questionnaires}
          id={gapIds.progress}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ComplianceByQuestionnaire
          questionnaires={questionnaires}
          id={gapIds.compliance}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <SecurityMeasuresStatus questionControlStats={questionControlStats} />
      </Grid>
      <Grid item md={8} xs={12}>
        <SecurityMeasuresStatusByCategory
          questionControlStats={questionControlStats}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <SecurityMeasuresByCategory
          questionControlStats={questionControlStats}
        />
      </Grid>
      {fragilityStats && (
        <Grid item md={4} xs={12}>
          <DistributionRisksDegreeSeverity fragilityStats={fragilityStats} />
        </Grid>
      )}
      {fragilityStats && (
        <Grid item md={4} xs={12}>
          <FragilitiesWithLinkedMeasures fragilityStats={fragilityStats} />
        </Grid>
      )}
      <Grid item md={6} xs={12}>
        <DepartmentsByThreats
          title="Departamentos por menor quantidade de ameaças"
          subtitle="Ranking dos quatro departamentos com menor quantidade"
          values={departmentsLowestOfThreats}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <DepartmentsByThreats
          title="Departamentos por maior quantidade de ameaças"
          subtitle="Ranking dos quatro departamentos com maior quantidade"
          values={departmentsHighestOfThreats}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <DepartmentsByThreats
          title="Departamentos por menor severidade de ameaças"
          subtitle="Ranking dos quatro departamentos com menor severidade"
          values={departmentsWithLowerSeverity}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <DepartmentsByThreats
          title="Departamentos por maior severidade de ameaças"
          subtitle="Ranking dos quatro departamentos com maior severidade"
          values={departmentsWithGreaterSeverity}
        />
      </Grid>
    </Fragment>
  )
}

DashboardDiagnostic.propTypes = {
  questionnaires: PropTypes.array,
  gapStats: PropTypes.object.isRequired,
  fragilityStats: PropTypes.object.isRequired,
}

export default DashboardDiagnostic
