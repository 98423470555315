import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import helpers from 'helpers'

const styles = ({ colorPicker }) =>
  makeStyles((theme) => ({
    colorPreviewBox: {
      border: '1px solid',
      borderColor: theme.palette.custom.border,
      borderRadius: theme.spacing(0.5),
      cursor: 'pointer',
      backgroundColor: helpers.palette.validateColor(colorPicker),
    },
  }))

styles.propTypes = {
  colorPicker: PropTypes.string.isRequired,
}

export default styles
