import React from 'react'

import { Container, Card, Box } from '@material-ui/core'

import { ContentHeader, Page } from 'components'
import PrivacyPoliciesForm from './components/PrivacyPoliciesForm'

import useStyles from './styles'

const PrivacyPoliciesNew = () => {
  const classes = useStyles()

  return (
    <Page title="Nova política de privacidade">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Nova política de privacidade" />
        <Card>
          <Box px={3} pb={3}>
            <Box mt={3}>
              <PrivacyPoliciesForm />
            </Box>
          </Box>
        </Card>
      </Container>
    </Page>
  )
}

export default PrivacyPoliciesNew
