const CONFIRMED_CHOOSE = 'CONFIRMED'
const WAITING_UPDATE_CHOOSE = 'WAITING_UPDATE'

const WAITING_ACTION_STATUS = 'waiting_action'
const WAITING_UPDATE_STATUS = 'waiting_update'
const CONFIRMED_STATUS = 'confirmed'
const UPDATED_STATUS = 'updated'
const AUTOMATICALLY_APPROVED_STATUS = 'automatically_approved'

const WAITING_ACTION_LABEL = 'AGUARDANDO ESCOLHA'
const CONFIRMED_LABEL = 'PROCESSO CONFIRMADO COMO CORRETO'
const UPDATED_LABEL = 'PROCESSO ATUALIZADO'
const AUTOMATICALLY_APPROVED_LABEL = 'APROVADO AUTOMATICAMENTE'

const WAITING_ACTION_REPORT_LABEL = 'PENDENTE'
const CONFIRMED_REPORT_LABEL = 'CONFIRMADO'
const UPDATED_REPORT_LABEL = 'ATUALIZADO'

const auditProcess = {
  AUTOMATICALLY_APPROVED_LABEL,
  AUTOMATICALLY_APPROVED_STATUS,
  CONFIRMED_CHOOSE,
  CONFIRMED_LABEL,
  CONFIRMED_REPORT_LABEL,
  CONFIRMED_STATUS,
  UPDATED_LABEL,
  UPDATED_REPORT_LABEL,
  UPDATED_STATUS,
  WAITING_ACTION_LABEL,
  WAITING_ACTION_REPORT_LABEL,
  WAITING_ACTION_STATUS,
  WAITING_UPDATE_CHOOSE,
  WAITING_UPDATE_STATUS,
}

export default auditProcess
