import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ faqsDocumentationId = '', ...params }) => {
  return await dponetAPI.get(`/faqs_documentations/${faqsDocumentationId}`, {
    params,
  })
}

const faqs = {
  get,
}

export default faqs
