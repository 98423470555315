import audits from './audits'
import cardFlags from './cardFlags'
import consents from './consents'
import file from './file'
import jodit from './jodit'
import lodash from './lodash'
import settings from './settings'

export default {
  audits,
  cardFlags,
  consents,
  file,
  jodit,
  lodash,
  settings,
}
