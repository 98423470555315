import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  cardScroll: {
    [theme.breakpoints.up('md')]: { overflow: 'hidden' },
    [theme.breakpoints.down('xs')]: { width: 'calc(100% + 40px)' },
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  cardWidth: {
    '& p': {
      minWidth: 150,
    },
    minHeight: 122,
    [theme.breakpoints.up('md')]: { minWidth: '33%' },
    [theme.breakpoints.up('xl')]: { minWidth: 'auto' },
    minWidth: 250,
    overflow: 'hidden',
  },
  container: {
    maxWidth: 'inherit',
  },
}))

export default styles
