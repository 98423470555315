const statusColor = (statusId, classes) => {
  switch (statusId) {
    case 1:
      return classes.pending
    case 2:
      return classes.waitingReview
    case 3:
      return classes.reviewDisapproved
    case 4:
      return classes.reviewApproved
    case 5:
      return classes.reviewing
    case 6:
      return classes.inactive
    // status 7 e 8 são defaults da plataforma web
    // Caso criado mais status no processamento, favor adequar este modulo e onde é chamado
    case 7:
      return classes.suggested
    case 8:
      return classes.discarded
    case 9:
      return classes.waitingReview
    case 10:
      return classes.pending
    case 11:
      return classes.waitingReview
    case 12:
      return classes.waitingReview
    case 13:
      return classes.reviewDisapproved
    default:
      return ''
  }
}

export default statusColor
