const mountComplianceRows = (questionnaires) => {
  return questionnaires?.map((questionnaire) => ({
    name: questionnaire.name,
    value: formatPercentage(questionnaire.complianceGap),
    percentage: formatPercentage(questionnaire.complianceGap),
  }))
}

const formatPercentage = (compliance) => {
  return parseFloat(compliance).toFixed(0)
}

export default mountComplianceRows
