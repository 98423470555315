import React from 'react'

import {
  ConfirmDocumentForm,
  PasswordForm,
  SupplierForm,
  SupplierInvitedWelcome,
  SupplierNotInvited,
} from '../../views/SupplierInvite/components'

import constants from 'constants/index'

const stepComponents = ({ inviter, companyToken }) => ({
  [constants.supplierInvite.WELCOME_STEP]: () => (
    <SupplierInvitedWelcome inviter={inviter} />
  ),
  [constants.supplierInvite.VALIDATE_DOCUMENT_STEP]: () => (
    <ConfirmDocumentForm inviter={inviter} companyToken={companyToken} />
  ),
  [constants.supplierInvite.DATA_CONFIRMATION_STEP]: () => (
    <SupplierForm companyToken={companyToken} />
  ),
  [constants.supplierInvite.LOGIN_STEP]: () => (
    <PasswordForm isNewUser={false} companyToken={companyToken} />
  ),
  [constants.supplierInvite.CREATE_PASSWORD_STEP]: () => (
    <PasswordForm isNewUser companyToken={companyToken} />
  ),
  [constants.supplierInvite.NOT_LINKED_STEP]: () => (
    <SupplierNotInvited isNotLinked inviter={inviter} />
  ),
  [constants.supplierInvite.NOT_PERMITED_STEP]: () => (
    <SupplierNotInvited isNotLinked={false} />
  ),
})

export default stepComponents
