import React from 'react'

import { Container } from '@material-ui/core'

import { Page } from 'components'

import PaperShowDataProcess from './components/PaperShowDataProcess'
import useStyles from './styles'

import DataTreatmentModalProvider from 'providers/DataTreatmentModalProvider'

const Details = ({ match }) => {
  const classes = useStyles()

  return (
    <>
      <Page className={classes.root} title="Detalhes do processo">
        <Container maxWidth={false} className={classes.container}>
          <DataTreatmentModalProvider>
            <PaperShowDataProcess match={match} />
          </DataTreatmentModalProvider>
        </Container>
      </Page>
    </>
  )
}

export default Details
