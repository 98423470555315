import * as yup from 'yup'

export const schemaUser = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .required()
    .matches(/\((\d{2}\))( \d{5})-(\d{4}).*/, {
      message: 'Digite um número de telefone válido',
      excludeEmptyString: true,
    }),
})

export default schemaUser
