import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  dataCollectedContainer: {
    padding: theme.spacing(1),
  },
  dataCollectedTable: {
    marginBottom: theme.spacing(2),
  },
  button: {
    padding: '0px',
  },
  buttonLabel: {
    textTransform: 'capitalize',
  },
  buttonTitle: {
    paddingLeft: '5px',
  },
  textTableRight: {
    textAlign: 'center',
  },
  actionHeader: {
    paddingRight: '70px',
  },
  iconMargin: {
    marginRight: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperModal: {
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
    margin: theme.spacing(1),
    padding: theme.spacing(2, 2, 3),
    outline: 'none',
  },
  tableContainerModal: {
    marginTop: theme.spacing(2),
    maxHeight: 'auto',
  },
}))

export default styles
