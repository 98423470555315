import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  textLevel: {
    minWidth: 70,
    paddingInline: 1,
    marginRight: 5,
    marginBottom: 1,
    borderRadius: 5,
    textAlign: 'center',
  },
  lowFragility: {
    backgroundColor: '#4CAF50',
  },
  mediumFragility: {
    backgroundColor: '#ACCA2E',
  },
  highFragility: {
    backgroundColor: '#F4A460',
  },
  severeFragility: {
    backgroundColor: '#E53935',
  },
}))

export default styles
