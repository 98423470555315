import React, { useState } from 'react'

import { DialogLayout, LoadingFeedback } from 'components'

import { Controller, useForm } from 'react-hook-form'

import {
  Box,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  makeStyles,
} from '@material-ui/core'

import { Autocomplete } from '@material-ui/lab'
import styles from './styles'
import schema from './schema'

import constants from 'constants/index'
import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

const useStyles = makeStyles(styles)

const DialogAcceptSuggested = ({
  open,
  setOpen = () => {},
  dataProcess,
  departments,
  setIsLoading = () => {},
}) => {
  const history = useHistory()
  const [modalLoading, setModalLoading] = useState(false)
  const { control, handleSubmit, errors, setError } = useForm({
    defaultValues: {
      acceptType: '',
      department: null,
    },
    validationSchema: schema,
  })
  const snackbar = useSnackbar()
  const classes = useStyles()
  const formatDepartments = () => {
    return [
      { value: '', label: '' },
      ...departments.map((department) => {
        return { value: department.id, label: department.name }
      }),
    ]
  }
  const onSubmit = async (data) => {
    if (!data.department && !dataProcess.haveProcessDepartment) {
      setError('department', {
        type: {
          required: 'Escolha um departamento',
        },
      })
      return
    }

    setModalLoading(true)

    try {
      await service.dponet.dataProcesses.acceptSuggestion({
        dataProcessId: dataProcess.id,
        suggested_data_processes: {
          status: parseInt(data.acceptType),
          department_id: data?.department?.value || '',
        },
      })
      snackbar.open({
        message: 'Processamento de dados aceito com sucesso!',
        variant: 'success',
      })
      setModalLoading(false)
      setOpen(false)
      setIsLoading(true)
      history.push(
        reverse('/data-processes/suggested?tab=dataProcessesSuggested'),
      )
    } catch (error) {
      snackbar.open({
        message: 'Erro ao aceitar processamento de dados',
        variant: 'error',
      })
      setModalLoading(false)
      setOpen(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={modalLoading} />
      <DialogLayout
        open={open}
        setOpen={setOpen}
        title="Aceitar processamento sugerido"
        confirmTextButton="Aceitar"
        handleSubmit={handleSubmit(onSubmit)}
      >
        <Box display="flex" flexDirection="column">
          <Typography>
            Deseja aceitar o processamento de dados com qual status?
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="row" mt={1}>
              <Controller
                control={control}
                name="acceptType"
                as={
                  <RadioGroup row>
                    <FormControlLabel
                      value={`${constants.dataProcess.SUGGESTED_APPROVING_STATUS}`}
                      control={<Radio color="primary" />}
                      label="Aprovado"
                    />
                    <FormControlLabel
                      value={`${constants.dataProcess.SUGGESTED_PENDING_STATUS}`}
                      control={<Radio color="primary" />}
                      label="Pendente"
                    />
                  </RadioGroup>
                }
              />
            </Box>
            {errors?.acceptType && (
              <Box mb={2} className={classes.errorMessage}>
                <Typography variant="body2">
                  {errors?.acceptType?.message}
                </Typography>
              </Box>
            )}
            {!dataProcess.haveProcessDepartment && (
              <>
                <Controller
                  control={control}
                  name="department"
                  rules={{ required: true }}
                  onChange={([, data]) => data}
                  mode="onChange"
                  as={
                    <Autocomplete
                      defaultValue={0}
                      options={formatDepartments()}
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={errors?.department?.message}
                          error={!!errors?.department}
                          label="Departamentos"
                          variant="outlined"
                        />
                      )}
                    />
                  }
                />
                {errors?.department && (
                  <Box mb={2} className={classes.errorMessage}>
                    <Typography variant="body2">
                      {errors?.department?.types?.type?.required}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </form>
        </Box>
      </DialogLayout>
    </>
  )
}

export default DialogAcceptSuggested
