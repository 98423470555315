import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chip: {
    borderRadius: 3,
    fontWeight: 600,
  },
}))

export default styles
