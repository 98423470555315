import React from 'react'
import { isNil } from 'lodash'
import { Link as RouterLink } from 'react-router-dom'
import { useLocation, matchPath } from 'react-router'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { Box, Hidden, List, makeStyles } from '@material-ui/core'

import { PermittedBySupplier } from '../../components'

import NavItem from '../NavItem'
import { routes } from 'Routes'
import useAuth from 'hooks/useAuth'
import usePermissions from 'hooks/usePermissions'

import styles from './styles'

import useDrawer from 'hooks/useDrawer'
import useCustomTheme from 'hooks/useCustomTheme'

import { HelpCentralBox, OpenOrCloseDrawer, UserBox } from './components'

import constants from 'constants/index'

const useStyles = makeStyles(styles)

const renderNavItems = ({ items, ...rest }) => {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        [],
      )}
    </List>
  )
}

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  menuDisabled,
  depth = 0,
  stepScope,
  permissions,
  showEntireMenu,
}) => {
  const key = (item.id || item.title) + depth

  const isDisabled = item.alwaysUnlocked ? false : menuDisabled

  const isMenuDisabled = () => {
    if (permissions?.isSupplier()) {
      if (item.showBlocked) {
        return true
      }

      if (item.unlockedToSupplier) {
        return false
      }

      if (item.somePermissions && permissions?.some(item.somePermissions)) {
        return false
      }

      if (item.permission && permissions?.permitted(item.permission)) {
        return false
      }

      return true
    }

    if (item.items) {
      return isDisabled
    }

    if (!item.alwaysUnlocked && stepScope) {
      return !item.alwaysUnlocked
    }

    return isDisabled
  }

  if (
    permissions?.isDpo() &&
    constants.user.BLOCKED_MENU_OUTSOURCE_DPO.includes(item.title)
  ) {
    return <></>
  }

  if (item.items && showEntireMenu()) {
    const open = matchPath(pathname, { path: item.href, exact: false })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        somePermissions={item.somePermissions}
        permission={item.permission}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        show={item.show}
        menuDisabled={isMenuDisabled()}
        main={item.main}
        isDirectAction={item.isDirectAction}
        handleDirectAction={item.handleDirectAction}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          menuDisabled: isMenuDisabled() || isDisabled,
          stepScope,
          permissions,
        })}
      </NavItem>,
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        somePermissions={item.somePermissions}
        permission={item.permission}
        info={item.info}
        show={item.show}
        title={item.title}
        menuDisabled={isMenuDisabled()}
        countToBadge={item.countToBadge}
        main={item.main}
        isDirectAction={item.isDirectAction}
        handleDirectAction={item.handleDirectAction}
      />,
    )
  }

  return acc
}

const Content = () => {
  const auth = useAuth()
  const location = useLocation()
  const classes = useStyles()
  const permissions = usePermissions()
  const { showEntireMenu } = useDrawer()
  const { customLogo, customPalette } = useCustomTheme()

  const themeStatus = customPalette?.status

  const allStepsConcluded = auth?.user?.allStepsConcluded
  const stepScope =
    allStepsConcluded === false && !isNil(auth?.company?.templateCompany?.id)

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={classes.root}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {!!customLogo && (
          <Hidden lgUp>
            <Box p={3} pb={0} display="flex" justifyContent="center">
              <RouterLink
                to={auth.homeRoute || routes.dataProcess.resume}
                className={classes.logoLink}
              >
                <img
                  src={customLogo}
                  alt="Logo"
                  className={classes.logo}
                  style={{ display: themeStatus && 'none' }}
                />
              </RouterLink>
            </Box>
          </Hidden>
        )}

        <Hidden mdUp>
          <UserBox />
        </Hidden>

        <Box p={2}>
          {auth?.menuItems?.map((item) => {
            const isDisabled = item.alwaysUnlocked ? false : stepScope

            return (
              <PermittedBySupplier
                key={item?.items[0]?.title}
                permission={item.permission}
                somePermissions={item.somePermissions}
                title={item?.items[0]?.title}
                show={item.show}
              >
                {renderNavItems({
                  items: item.items,
                  pathname: location.pathname,
                  menuDisabled: isDisabled,
                  stepScope: stepScope,
                  permissions: permissions,
                  showEntireMenu,
                })}
              </PermittedBySupplier>
            )
          })}
        </Box>

        {showEntireMenu() && <HelpCentralBox />}
      </PerfectScrollbar>
      <OpenOrCloseDrawer />
    </Box>
  )
}

export default Content
