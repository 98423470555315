import React from 'react'

import { Link, Box, Tooltip } from '@material-ui/core'

import useStyles from './styles'
import useSupplierInvite from 'hooks/useSupplierInvite'

import logo from 'images/dponet-logo-white.svg'
import constants from 'constants/index'

const TopBar = () => {
  const classes = useStyles()
  const { setCurrentStep } = useSupplierInvite()

  const handleGoWelcomeStep = () =>
    setCurrentStep(constants.supplierInvite.WELCOME_STEP)

  const redirectToTerms = () => {
    window.open('https://www.dponet.com.br/termos-de-uso/')
  }

  const redirectToPrivacy = () => {
    window.open('https://www.dponet.com.br/politica-de-privacidade/')
  }

  return (
    <Box className={classes.container}>
      <Box onClick={handleGoWelcomeStep} className={classes.logoContainer}>
        <Tooltip title="Ir para início da jornada">
          <img alt="Logo" src={logo} className={classes.logo} width="130px" />
        </Tooltip>
      </Box>
      <Box className={classes.linksContainer}>
        <Link className={classes.pointer} onClick={redirectToTerms}>
          Termos e condições de uso
        </Link>
        <Link className={classes.pointer} onClick={redirectToPrivacy}>
          Aviso de privacidade
        </Link>
      </Box>
    </Box>
  )
}

export default TopBar
