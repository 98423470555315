const KINDS = {
  NEW: 'N',
  IMPROVEMENT: 'I',
}

const KINDS_LABELS = {
  N: 'Novidade',
  I: 'Melhoria',
}

const SELECT_LAST_UPDATE_TYPE = [
  { id: KINDS.NEW, name: KINDS_LABELS.N },
  { id: KINDS.IMPROVEMENT, name: KINDS_LABELS.I },
]

const lastUpdates = {
  KINDS,
  KINDS_LABELS,
  SELECT_LAST_UPDATE_TYPE,
}

export default lastUpdates
