import { useContext } from 'react'

import AuthContext from 'contexts/AuthContext'

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthContext.Provider')
  }

  return {
    actualStep: context.actualStep,
    companies: context.companies,
    company: context.company,
    companyTrails: context.companyTrails,
    countBadge: context.countBadge,
    documentations: context.documentations,
    faqsDocumentations: context.faqsDocumentations,
    freeTrialBlock: context.freeTrialBlock,
    handleSelectCompany: context.handleSelectCompany,
    homeRoute: context.homeRoute,
    isLoading: context.isLoading,
    loadCompany: context.loadCompany,
    loadCompanyTrails: context.loadCompanyTrails,
    loadData: context.loadData,
    loadDataWithAnimation: context.loadDataWithAnimation,
    loaded: context.loaded,
    loadOut: context.loadOut,
    loadUser: context.loadUser,
    loadUserCompany: context.loadUserCompany,
    menuItems: context.menuItems,
    mixpanelBrowser: context.mixpanelBrowser,
    permissions: context.permissions,
    redirectLinks: context.redirectLinks,
    setActualStep: context.setActualStep,
    setCompanies: context.setCompanies,
    setCompany: context.setCompany,
    setCompanyTrails: context.setCompanyTrails,
    setCountBadge: context.setCountBadge,
    setFreeTrialBlock: context.setFreeTrialBlock,
    setIsLoading: context.setIsLoading,
    setLoaded: context.setLoaded,
    setShowHelpButton: context.setShowHelpButton,
    setUser: context.setUser,
    setUserCompany: context.setUserCompany,
    showHelpButton: context.showHelpButton,
    temporaryPassword: context.temporaryPassword,
    user: context.user,
    userCompany: context.userCompany,
  }
}

export default useAuth
