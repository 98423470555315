import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import { BaseDialog } from 'components'

const DepartmentListDialog = ({ open, setOpen, departmentList }) => {
  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Detalhes do local"
      fullWidth
      dialogSize="md"
      closeButtonText="Fechar"
    >
      <Box py={2}>
        <Typography variant="h5" gutterBottom>
          Departamentos vinculados a essa auditoria:
        </Typography>
        {departmentList?.map((department, index) => (
          <Typography key={index}>{department}</Typography>
        ))}
      </Box>
    </BaseDialog>
  )
}

DepartmentListDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  departmentList: PropTypes.arrayOf(PropTypes.string),
}

export default DepartmentListDialog
