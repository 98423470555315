const convertStringToCpf = (cpf) => {
  if (cpf === null || cpf === undefined) return ''

  let l = cpf.length
  if (l <= 3) return cpf

  if (l > 14) return cpf.slice(0, 14)

  cpf = cpf.split('-').join('').split('.').join('')

  l = cpf.length

  if (l <= 6) return cpf.replace(/^(\d{3})(\d{1,3})*/, '$1.$2')
  if (l <= 9) return cpf.replace(/^(\d{3})(\d{3})(\d{1,3}).*/, '$1.$2.$3')
  if (l < 11)
    return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{1}).*/, '$1.$2.$3-$4')

  return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')
}

export default convertStringToCpf
