import constants from 'constants/index'

const status = (status) => {
  switch (status) {
    case constants.auditProcess.WAITING_ACTION_STATUS:
      return constants.auditProcess.WAITING_ACTION_REPORT_LABEL
    case constants.auditProcess.CONFIRMED_STATUS:
      return constants.auditProcess.CONFIRMED_REPORT_LABEL
    case constants.auditProcess.UPDATED_STATUS:
      return constants.auditProcess.UPDATED_REPORT_LABEL
    case constants.auditProcess.AUTOMATICALLY_APPROVED_STATUS:
      return constants.auditProcess.AUTOMATICALLY_APPROVED_LABEL
    default:
      return '-'
  }
}

export default status
