import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gridGap: theme.spacing(1.5),
    },
  },
  buttonBox: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 300,
    },
  },
}))

export default styles
