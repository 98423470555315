import * as yup from 'yup'

export const schema = (isDemoSubscription) =>
  yup.object().shape({
    name: yup.string().required(),
    cpf: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    street: yup.string().required(),
    number: yup.string().required(),
    neighborhood: yup.string().required(),
    complement: yup.string(),
    postalCode: yup.string().required(),
    city: yup.string().required(),
    country: yup.string().required(),
    state: yup.string().required(),
    ...(isDemoSubscription && {
      responsibleName: yup.string().required(),
      responsibleEmail: yup.string().email().required(),
      responsiblePhone: yup.string().required(),
    }),
  })

export default schema
