import React from 'react'
import { Box } from '@material-ui/core'
import { RoundedBox, RoundedButton, Text } from '..'

import useFirstStep from 'hooks/useFirstStep'
import theme from 'theme'

const ProductConfirmationPage = () => {
  const { handleNext, nextDisabled } = useFirstStep()

  return (
    <Box display="flex" justifyContent="center">
      <RoundedBox noOpacity maxWidth={theme.breakpoints.values.sm}>
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text fontSize={24} variant="h3">
            Enviaremos para você um e-mail com mais informações sobre como usar
            o produto sugerido
          </Text>
          <Box mt={2}>
            <RoundedButton
              variant="primary"
              withShadow
              withPadding
              onClick={handleNext}
              mixpanelId="confirmar-recebimento-confirmação_cookie"
              disabled={nextDisabled}
            >
              Confirmar
            </RoundedButton>
          </Box>
        </Box>
      </RoundedBox>
    </Box>
  )
}

export default ProductConfirmationPage
