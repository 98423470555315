import constants from 'constants/index'

const convertSourceDescriptionToText = (source) => {
  switch (source) {
    case constants.dataProcess.TITULAR_DATA_SOURCE:
      return constants.dataProcess.TITULAR_DESCRIPTION
    case constants.dataProcess.DEPARTMENT_SOURCE:
      return constants.dataProcess.DEPARTMENT_DESCRIPTION
    case constants.dataProcess.THIRD_PARTY_SOURCE:
      return constants.dataProcess.THIRD_PARTY_SOURCE_DESCRIPTION
    case constants.dataProcess.PUBLIC_SOURCE:
      return constants.dataProcess.PUBLIC_SOURCE_DESCRIPTION
    default:
      return source
  }
}

export default convertSourceDescriptionToText
