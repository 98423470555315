import React from 'react'
import { TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'

import useDataProcessTemplateQuestion from 'hooks/useDataProcessTemplateQuestion'

const InputText = ({ question, control, disabled = false }) => {
  const { errors } = useDataProcessTemplateQuestion()

  const name = `question-${question.id}`

  return (
    <Controller
      as={
        <TextField
          multiline
          fullWidth
          error={!!errors[name]}
          helperText={errors[name]?.message}
          disabled={disabled}
        />
      }
      name={name}
      control={control}
    />
  )
}

export default InputText
