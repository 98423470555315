import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    gap: 32,
  },
  videoPlayer: {
    height: 599,
    width: 1065,
    border: 0,
  },
  text: {
    fontSize: 40,
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    lineHeight: 1.2,
    color: theme.palette.common.white,
  },
  roundedButton: {
    borderRadius: 29,
    marginTop: 20,
    fontSize: 16,
    letterSpacing: 1.78,
    width: 350,
    fontWeight: '500 !important',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
    boxShadow: `0px 0px 12px ${theme.colors.firstStep.button.primary}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.colors.firstStep.button.primary,
    },
    backgroundColor: theme.colors.firstStep.button.primary,
    color: theme.palette.common.white,
  },
}))

export default styles
