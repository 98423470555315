import React from 'react'
import {
  Grid,
  Typography,
  makeStyles,
  Box,
  CircularProgress,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const LoadingTextComponent = ({ loading, children }) => {
  if (loading) {
    return <CircularProgress color="inherit" size={24} disableShrink />
  }
  return children
}

const Overview = ({
  loading,
  processesApproved,
  processesRncs,
  gapRncs,
  incidentsRncs,
}) => {
  const classes = useStyles()

  return (
    <Grid alignItems="center" container justify="space-between">
      <Grid className={classes.item} item md={3} sm={6} xs={12}>
        <Typography
          gutterBottom
          variant="body2"
          className={classes.whiteTypography}
        >
          PROCESSOS APROVADOS
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.whiteTypography}
        >
          <LoadingTextComponent loading={loading}>
            <Typography variant="h4" color="inherit">
              {processesApproved || '-'}
            </Typography>
          </LoadingTextComponent>
        </Box>
      </Grid>
      <Grid className={classes.item} item md={3} sm={6} xs={12}>
        <Typography
          gutterBottom
          variant="body2"
          className={classes.whiteTypography}
        >
          RMCS DE PROCESSOS EM ABERTO
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.whiteTypography}
        >
          <LoadingTextComponent loading={loading}>
            <Typography variant="h4" color="inherit">
              {processesRncs || '-'}
            </Typography>
          </LoadingTextComponent>
        </Box>
      </Grid>
      <Grid className={classes.item} item md={3} sm={6} xs={12}>
        <Typography
          gutterBottom
          variant="body2"
          className={classes.whiteTypography}
        >
          RMCS DE QUESTIONÁRIOS EM ABERTO
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.whiteTypography}
        >
          <LoadingTextComponent loading={loading}>
            <Typography variant="h4" color="inherit">
              {gapRncs || '-'}
            </Typography>
          </LoadingTextComponent>
        </Box>
      </Grid>
      <Grid className={classes.item} item md={3} sm={6} xs={12}>
        <Typography
          gutterBottom
          variant="body2"
          className={classes.whiteTypography}
        >
          RMCS DE INCIDENTES EM ABERTO
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.whiteTypography}
        >
          <LoadingTextComponent loading={loading}>
            <Typography variant="h4" color="inherit">
              {incidentsRncs || '-'}
            </Typography>
          </LoadingTextComponent>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Overview
