import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import { reverse } from 'named-urls'

import {
  Box,
  Button,
  Container,
  Grid,
  Tabs,
  Tab,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  LoadingFeedback,
  MenuButton,
  Page,
  Permitted,
  RoundedBorder,
} from 'components'
import { GeneralInformation, Header, SupplierAvatar } from './components/Show'
import { ConfirmationDialog, SuccessDialog } from './components'

import constants from 'constants/index'
import helpers from 'helpers'
import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'
import usePermissions from 'hooks/usePermissions'
import useAuth from 'hooks/useAuth'

import styles from './styles'

const useStyles = makeStyles(styles)

const SupplierShow = ({ match }) => {
  const {
    params: { supplierId },
  } = match

  const history = useHistory()
  const { loadData } = useAuth()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { isSupplier } = usePermissions()

  const [loading, setLoading] = useState(true)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [successData, setSuccessData] = useState({})
  const [confirmationData, setConfirmationData] = useState({})
  const [company, setCompany] = useState({})
  const [currentTab] = useState(
    constants.companySuppliers.GENERAL_INFORMATION_VALUE,
  )

  const confirmationCallback = async (data) => {
    if (!!data.isInactive) {
      snackbar.open({
        message: `Você inativou seu vínculo com a empresa ${company?.name}`,
        variant: 'success',
      })
      await loadData()
      return handleBack()
    }

    setSuccessData(data)
    setOpenSuccess(true)
  }

  const approveOrRefuseSubmit = async (status) => {
    const isApprove = status === constants.companySuppliers.APPROVED_STATUS

    try {
      await service.dponet.suppliers.approveOrRefuse({
        supplierId: supplierId,
        status,
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          `Ocorreu um erro ao ${isApprove ? 'aprovar' : 'rejeitar'} o vínculo`,
        variant: 'error',
      })

      throw error
    }
  }

  const inactivateSubmit = async () => {
    try {
      await service.dponet.suppliers.inactivate({ supplierId })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Ocorreu um erro ao inativar o fornecedor',
        variant: 'error',
      })

      throw error
    }
  }

  const handleInactivate = () => {
    setConfirmationData({
      actionType: constants.companySuppliers.INACTIVATE_ACTION,
      title: 'Inativar vínculo?',
      subtitle: 'Deseja confirmar a inativação de vínculo com o cliente?',
      handleConfirm: inactivateSubmit,
      isInactive: true,
    })
    setOpenConfirmation(true)
  }

  const handleApprove = () => {
    const subtitle = constants.companySuppliers.APPROVE_BASE_TEXT
    setConfirmationData({
      actionType: constants.companySuppliers.APPROVE_ACTION,
      title: 'Confirmação do vínculo',
      subtitle: isSupplier()
        ? subtitle
        : `${subtitle} 

      ${constants.companySuppliers.APPROVE_CLIENT_TEXT}`,
      handleConfirm: () =>
        approveOrRefuseSubmit(constants.companySuppliers.APPROVED_STATUS),
    })
    setOpenConfirmation(true)
  }

  const handleRefuse = () => {
    setConfirmationData({
      actionType: constants.companySuppliers.REFUSE_ACTION,
      title: 'Rejeitar vínculo?',
      subtitle: 'Deseja confirmar a rejeição de vínculo com o cliente?',
      handleConfirm: () =>
        approveOrRefuseSubmit(constants.companySuppliers.REFUSED_STATUS),
    })
    setOpenConfirmation(true)
  }

  const loadCompany = async () => {
    const responseSuppliers = await service.dponet.suppliers.get({
      supplierId,
    })
    const {
      companySupplier: { status, companyResponsibleEmail, company },
    } = responseSuppliers.data
    setCompany({
      status,
      companyResponsibleEmail,
      ...company,
    })
  }

  useEffect(() => {
    const loadData = async () => {
      await loadCompany()
      setLoading(false)
    }
    loadData()
    //eslint-disable-next-line
  }, [])

  const tabs = [
    {
      value: constants.companySuppliers.GENERAL_INFORMATION_VALUE,
      label: constants.companySuppliers.GENERAL_INFORMATION_LABEL,
    },
  ]

  const handleBack = () => {
    history.push(routes.supplier.all)
  }

  const handleLogs = () => {
    history.push(reverse(routes.supplier.logs, { supplierId }))
  }

  const isInactive =
    company?.status === constants.companySuppliers.INACTIVE_STATUS
  const isIndicated =
    company.status === constants.companySuppliers.INDICATED_STATUS

  const avatarLetters = (company?.name || 'Empresa Cliente')
    .split(' ')
    .map((word) => word[0])
    .join('')

  return (
    <Page title="Visualizar fornecedor">
      <LoadingFeedback open={loading} />
      <Container
        maxWidth={false}
        className={clsx(classes.blueContainer, classes.containerSpacing)}
      >
        <Header>
          <Grid item xs={2} sm={6}>
            <Box width="100%" display="flex" justifyContent="flex-end">
              <MenuButton
                anchorElement={Button}
                anchorOptions={{
                  className: classes.buttonOptions,
                  variant: 'contained',
                }}
                anchorChildren="Opções"
              >
                <Button
                  color="secondary"
                  onClick={handleLogs}
                  fullWidth
                  size="small"
                >
                  Histórico
                </Button>
                <Permitted tag={constants.permissions.COMPANY_SUPPLIERS.UPDATE}>
                  {isIndicated && (
                    <>
                      <Button
                        color="secondary"
                        onClick={handleApprove}
                        fullWidth
                      >
                        Aprovar vínculo
                      </Button>
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={handleRefuse}
                      >
                        Reprovar vínculo
                      </Button>
                    </>
                  )}
                  {!isInactive && (
                    <Button
                      color="secondary"
                      fullWidth
                      onClick={handleInactivate}
                    >
                      Inativar
                    </Button>
                  )}
                </Permitted>
              </MenuButton>
            </Box>
          </Grid>
          <SupplierAvatar company={company} avatarLetters={avatarLetters} />
        </Header>
      </Container>
      <Box position="relative">
        <RoundedBorder showRoundedWhite showRoundedGray={false} />
      </Box>
      <Container
        maxWidth={false}
        className={clsx(classes.whiteContainer, classes.containerSpacing)}
      >
        <Box
          pt={14}
          pb={4}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h3">
            <b>{company?.name}</b>
          </Typography>
        </Box>
        <Tabs
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </Container>
      <Container maxWidth={false} className={classes.containerSpacing}>
        <GeneralInformation
          questionnaires={[]}
          company={{
            ...company,
            supplierResponsibleEmail: company?.companyResponsibleEmail,
          }}
        />
        <Box mt={4}>
          <Button variant="contained" onClick={handleBack}>
            Voltar
          </Button>
        </Box>
      </Container>
      <ConfirmationDialog
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        callback={confirmationCallback}
        supplierName={company?.name}
        isSupplier={false}
        {...confirmationData}
      />
      <SuccessDialog
        open={openSuccess}
        setOpen={setOpenSuccess}
        reload={loadCompany}
        isSupplier={false}
        {...successData}
      />
    </Page>
  )
}

export default SupplierShow
