import React from 'react'
import { Info as InfoIcon } from 'react-feather'
import { Box, Typography } from '@material-ui/core'

import useStyles from './styles'

const InfoCard = ({ title, bgcolor, endActions, ...rest }) => {
  const classes = useStyles(bgcolor)()

  return (
    <Box className={classes.container} {...rest}>
      <Box className={classes.mainBox}>
        <InfoIcon size={22} />
        <Typography className={classes.typography}>{title}</Typography>
      </Box>
      <Box minWidth="fit-content">{endActions}</Box>
    </Box>
  )
}

export default InfoCard
