const mountStepOccurrence = (data) => {
  const {
    assessmentTypeRisk,
    decriptionOccurrenceIncidentWasKnown,
    justificationRiskAssessment,
    typeCommunication,
    name,
    email,
    document,
    howDidIncidentBecomeKnown,
  } = data
  const operator = {
    typeField: 'operator',
    name,
    email,
    document,
  }

  return {
    assessmentTypeRisk,
    decriptionOccurrenceIncidentWasKnown,
    justificationRiskAssessment,
    typeCommunication,
    operator,
    howDidIncidentBecomeKnown,
  }
}

export default mountStepOccurrence
