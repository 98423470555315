import React from 'react'
import { Box } from '@material-ui/core'

import { ScreenLoading } from 'layouts/Main/components'

import useAuth from 'hooks/useAuth'

import logoDponetWhite from 'images/dponet-logo-white.svg'
import useStyles from './styles'

const NewAuth = ({ children }) => {
  const classes = useStyles()
  const auth = useAuth()

  return (
    <ScreenLoading loaded={auth.loaded}>
      <Box component="main" className={classes.root}>
        <Box className={classes.wrapper}>
          <Box height="100%">{children}</Box>
        </Box>
        <Box>
          <Box className={classes.rightBanner}>
            <img
              src={logoDponetWhite}
              alt="Logo DPOnet Branca"
              className={classes.dponetLogo}
            />
          </Box>
        </Box>
      </Box>
    </ScreenLoading>
  )
}

export default NewAuth
