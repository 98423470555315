import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  spanImage: {
    fontSize: '80%',
    verticalAlign: 'top',
  },
}))

export default styles
