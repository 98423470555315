import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonTitle: {
    minWidth: 'fit-content',
    fontWeight: 400,
  },
  buttonName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  buttonSubmit: {
    backgroundColor: colors.grey[100],
    color: theme.palette.primary.main,
    border: 'none',
    fontWeight: 'normal',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      border: 'none',
      backgroundColor: colors.grey[300],
    },
    justifyContent: 'start',
  },
  buttonOption: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    border: 'none',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      border: 'none',
      backgroundColor: colors.grey[100],
    },
    width: '20%',
  },
  popper: {
    zIndex: 1,
  },
}))

export default styles
