import InformationSecurityMain from './InformationSecurity/InformationSecurityMain'
import InformationSecurityShow from './InformationSecurity/InformationSecurityShow'
import LGPDMain from './LGPD/LGPDMain'
import LGPDShow from './LGPD/LGPDShow'
import PersonalizedService from './LGPD/PersonalizedService'
import Ticket from './Tickets/Tickets'
import TicketDetails from './Tickets/TicketDetails'

export default {
  InformationSecurityMain,
  InformationSecurityShow,
  LGPDMain,
  LGPDShow,
  PersonalizedService,
  Ticket,
  TicketDetails,
}
