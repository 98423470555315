import { useContext } from 'react'

import CustomPaletteContext from 'contexts/CustomPaletteContext'

const useCustomTheme = () => {
  const context = useContext(CustomPaletteContext)

  if (context === undefined) {
    throw new Error(
      'useCustomTheme must be used within a CustomPaletteContext.Provider',
    )
  }

  return {
    customTheme: context.customTheme,
    customPalette: context.customPalette,
    customLogo: context.customLogo,
    loading: context.loading,
    setCustomLogo: context.setCustomLogo,
  }
}

export default useCustomTheme
