import React, { useRef, useState } from 'react'

import ReCAPTCHA from 'react-google-recaptcha'

import { Box, Button, Container, Typography } from '@material-ui/core'
import { Auth } from 'layouts'
import { NewPasswordForm } from 'views/Settings/components'

import { getGoogleRecaptchaToken } from 'service/env'

import useStyles from './styles'

const ResetTemporaryPassword = () => {
  const classes = useStyles()
  const recaptchaRef = useRef(null)
  const [recaptcha, setRecaptcha] = useState()

  const resetRecaptcha = () => {
    if (recaptchaRef.current) recaptchaRef.current.reset()
    setRecaptcha(undefined)
  }

  const handleAcceptedRecaptcha = (recaptchaToken) => {
    setRecaptcha(recaptchaToken)
  }
  const disableRecaptcha = () => setRecaptcha(undefined)

  const [loading, setLoading] = useState(false)

  const production = process.env.REACT_APP_API === 'production'
  const disableSubmitButton = production ? !recaptcha || loading : false

  return (
    <Auth>
      <Box className={classes.root}>
        <Container maxWidth="sm" fullWidth>
          <Box px={3}>
            <Typography variant="h4" color="textPrimary">
              Cadastre sua nova senha!
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              Você utilizou uma senha temporária, cadastre uma senha permanente
              para continuar.
            </Typography>
          </Box>
          <NewPasswordForm
            isResponsible={false}
            formId="reset-temporary-form"
            setLoading={setLoading}
            formIds={[]}
            setFormIds={() => {}}
            recaptcha={recaptcha}
            resetRecaptcha={resetRecaptcha}
          />
          {production && (
            <Box
              mt={1}
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                render="explicit"
                sitekey={getGoogleRecaptchaToken()}
                onChange={handleAcceptedRecaptcha}
                onExpired={disableRecaptcha}
              />
            </Box>
          )}
          <Box px={3} display="flex" justifyContent="flex-end">
            <Button
              disabled={loading || disableSubmitButton}
              type="submit"
              form="reset-temporary-form"
              variant="contained"
              color="primary"
            >
              Cadastrar senha
            </Button>
          </Box>
        </Container>
      </Box>
    </Auth>
  )
}

export default ResetTemporaryPassword
