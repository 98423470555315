import React from 'react'

import { Box, Typography, makeStyles } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const NoData = ({ message }) => {
  const classes = useStyles()

  return (
    <Box>
      <Typography variant="h4" color="secondary">
        {message}
      </Typography>
      <Box mt={5} display="flex" justifyContent="center" width="100%">
        <img
          alt="Nenhum dado encontrado"
          className={classes.image}
          src="/images/no_data.svg"
        />
      </Box>
    </Box>
  )
}

export default NoData
