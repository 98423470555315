import { useContext } from 'react'

import SuggestedChangeContext from 'contexts/SuggestedChangeContext'

const useSuggestedChange = () => {
  const context = useContext(SuggestedChangeContext)

  if (context === undefined) {
    throw new Error(
      'useSuggestedChange must be used within a SuggestedChangeContext.Provider',
    )
  }

  return {
    data: context.data,
    setData: context.setData,
    loading: context.loading,
    setLoading: context.setLoading,
    submit: context.submit,
    clearData: context.clearData,
    handleSelect: context.handleSelect,
    suggestionsQuantity: context.suggestionsQuantity,
    incrementSuggestionsQuantity: context.incrementSuggestionsQuantity,
    departments: context.departments,
    setDepartments: context.setDepartments,
    dataProcess: context.dataProcess,
    loadDataProcess: context.loadDataProcess,
  }
}

export default useSuggestedChange
