import palette from 'theme/palette'

import { supplierBox } from '../../assets/supplierBox64'

const suppliersBox = (pdf, positionX, positionY, data, label, ratio) => {
  pdf.addImage(supplierBox, 'png', positionX, positionY, ratio(680), ratio(380))
  pdf.setFontSize(ratio(160))
  pdf.setFont('Raleway-Bold')
  pdf.setTextColor(palette.white)
  pdf.text(
    data,
    positionX + ratio(340),
    positionY + ratio(170),
    null,
    null,
    'center',
  )
  pdf.setFontSize(ratio(40))
  pdf.setFont('Raleway-Regular')
  pdf.setTextColor(palette.text.mainMenu)
  pdf.text(
    label,
    positionX + ratio(350),
    positionY + ratio(330),
    null,
    null,
    'center',
  )
}

export default suppliersBox
