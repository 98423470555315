import React, { useState } from 'react'
import { Box, Button, Hidden, Typography } from '@material-ui/core'

import { Card } from 'components'
import { DialogInviteAdvisor } from './components'

const InviteAdvisor = ({ invite, isDpoInvite = false }) => {
  const [openDialog, setOpenDialog] = useState(false)

  const inviteTypeText = isDpoInvite ? 'DPO responsável' : 'Observador'

  const inviteText = () => {
    if (!invite)
      return (
        <>
          Essa empresa não possui um <b>{inviteTypeText}</b> registrado
        </>
      )

    const statusesMessages = {
      PENDING: (
        <>
          está com o convite pendente para se tornar <b>{inviteTypeText}</b>.
        </>
      ),
      REFUSED: (
        <>
          recusou o convite para se tornar <b>{inviteTypeText}</b>.
        </>
      ),
      CANCELED: 'está com o vinculo cancelado.',
      ACTIVE: (
        <>
          está registrado como o <b>{inviteTypeText}</b> dessa empresa.
        </>
      ),
    }

    return (
      <>
        <strong>{invite?.name || invite?.email}</strong>{' '}
        {statusesMessages[invite.status]}
      </>
    )
  }

  return (
    <Box mt={4}>
      <Card title={inviteTypeText} paddingBottom={0}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" color="textPrimary">
            {inviteText()}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            CONFIGURAÇÕES <Hidden xsDown>AVANÇADAS</Hidden>
          </Button>
        </Box>
      </Card>
      <DialogInviteAdvisor
        open={openDialog}
        setOpen={setOpenDialog}
        invite={invite}
        isDpoInvite={isDpoInvite}
        inviteTypeText={inviteTypeText}
      />
    </Box>
  )
}

export default InviteAdvisor
