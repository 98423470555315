const styles = (theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerSegment: {
    minWidth: '35%',
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
      padding: 0,
    },
    padding: theme.spacing(2.5),
    border: 'none',
    color: theme.palette.subscription.primary.main,
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  pointer: {
    cursor: 'pointer !important',
  },
  stepBackground: {
    backgroundColor: theme.palette.background.default,
  },
  stepIcon: {
    color: `${theme.palette.subscription.primary.light} !important`,
  },
  activeStepIcon: {
    color: `${theme.palette.subscription.primary.main} !important`,
    '& text': {
      fill: `${theme.palette.white} !important`,
    },
  },
  stepIconText: {
    fill: `${theme.palette.text.primary} !important`,
  },
  stepLabel: {
    color: theme.palette.black,
  },
  stepLine: {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.categoryCard.mainColor,
    },
  },
})

export default styles
