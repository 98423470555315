import { jsPDF } from 'jspdf'

import moment from 'moment'

import pages from './pages'
import templates from '../templates'

import constants from 'constants/index'
import * as service from 'service'

import '../fonts/Raleway-Bold-normal'
import '../fonts/Raleway-Thin-normal'
import '../fonts/Roboto-Regular-normal'
import '../fonts/Roboto-Bold-normal'

moment.locale('pt-br')
var dateNow = moment().format('MMM YYYY')

//Jul 2023
dateNow = dateNow.charAt(0).toUpperCase() + dateNow.slice(1)

const exportPdf = async (
  userCompany,
  customStyles,
  customLogo,
  gapStats,
  questionControls,
  isComplete,
  setExportingPdf,
  snackbar,
  shouldCreateVersion,
  refresh,
  notSave,
  updateQuestionnaire = false,
  refreshQuestionnaireLevel,
) => {
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    userUnit: 300,
  })

  const risksNull = questionControls.every(
    (questionControl) => questionControl.takeRisk === null,
  )

  try {
    templates.firstCover(
      pdf,
      userCompany?.company?.name,
      userCompany?.company?.cpf || userCompany?.company?.cnpj,
      dateNow,
      customStyles,
      customLogo,
      'Relatório de\nMedidas de\nSegurança',
    )

    pages.generalInformations(
      pdf,
      `Medidas de Segurança | ${dateNow}`,
      gapStats,
      customStyles,
      isComplete,
      questionControls,
    )

    pages.questionControls(
      pdf,
      `Medidas de Segurança | ${dateNow}`,
      customStyles,
      questionControls,
    )

    if (isComplete) {
      pages.actionPlans(pdf, `Planos de Ação | ${dateNow}`, questionControls)
    }

    if (isComplete && !risksNull) {
      pages.takeRisks(pdf, `Riscos Assumidos | ${dateNow}`, questionControls)
    }

    templates.lastCover(pdf, customStyles, customLogo)

    const reportName = `Relatório_Medidas_de_Segurança_${userCompany?.company?.name
      ?.split(' ')
      ?.join('_')
      ?.toLowerCase()}${
      isComplete ? '_Completo_' : '_Simplificado_'
    }_${dateNow}.pdf`

    if (!notSave) {
      pdf.save(reportName)
    }

    if (shouldCreateVersion) {
      const file = new File([pdf.output('blob')], reportName)
      await service.dponet.reportVersions.create({
        file,
        name: 'Relatório de Medidas de Segurança',
        kind: constants.reportVersions.KIND.CONTROL,
        skip: updateQuestionnaire ? true : false,
      })

      if (!updateQuestionnaire) {
        refresh()
      }
    }

    if (updateQuestionnaire) {
      await service.dponet.questionnaires.updateNewVersion()
    }

    snackbar.open({
      variant: 'success',
      message: 'Relatório gerado com sucesso, aguarde o download começar',
    })
  } catch (error) {
    console.error(error)
    snackbar.open({
      variant: 'error',
      message: 'Ocorreu um erro ao gerar o relatório',
    })
  } finally {
    setExportingPdf(false)

    if (updateQuestionnaire) {
      window.location.reload()
    }
  }
}

export default exportPdf
