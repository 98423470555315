import trimChartLegend from './trimChartLegend'
import getSupplierByCompliance from './getSupplierByCompliance'
import mountQuestionControlStatsRows from './mountQuestionControlStatsRows'
import questionControlStatsByStatus from './questionControlStatsByStatus'
import threatsByDepartment from './threatsByDepartment'

const dashboard = {
  trimChartLegend,
  getSupplierByCompliance,
  mountQuestionControlStatsRows,
  questionControlStatsByStatus,
  threatsByDepartment,
}

export default dashboard
