import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Typography, Button } from '@material-ui/core'

import { QuestionChart } from './components'

import { routes } from 'Routes'
import useStyles from './styles'
import constants from 'constants/index'

const MyComplianceCard = ({ gapStats, ...rest }) => {
  const { gap: gapIds } = constants.myLgpd.GRAPH_IDS

  const classes = useStyles()
  const history = useHistory()

  return (
    <Box className={classes.root} {...rest}>
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="flex-end">
            <Typography variant="h1" className={classes.complianceValue}>
              {gapStats.companyCompliance?.toFixed(0)}
            </Typography>
            <Typography variant="h4" className={classes.percentageTypo}>
              %
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-end">
            <Typography variant="body2">MINHA CONFORMIDADE</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="flex-end">
          <Button
            variant="text"
            className={classes.button}
            onClick={() => history.replace(routes.questionnaire.all)}
          >
            Ver mais
          </Button>
        </Box>
      </Box>
      <Box>
        <QuestionChart gapStats={gapStats} id={gapIds.questionCard} />
      </Box>
    </Box>
  )
}

MyComplianceCard.propTypes = {
  gapStats: PropTypes.object.isRequired,
}

export default MyComplianceCard
