import React from 'react'

import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
} from '@material-ui/core'

import { Label } from 'components'

import { StorageTimeVolumetry, Laws } from './components'

import constants from 'constants/index'

const Volumetry = ({
  setValue,
  register,
  errors,
  volumetryType,
  setVolumetryType,
  laws,
  setLaws,
  data,
}) => {
  const handleChange = (e) => {
    setVolumetryType(e.target.value)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <FormControl>
            <Label
              title="Tempo de armazenamento na organização"
              description="Por quanto tempo os dados ficam armazenados na organização?"
              item
              xs={12}
            >
              <RadioGroup
                onChange={handleChange}
                defaultValue={
                  data?.storageMode ||
                  constants.dataProcess.STORY_MODE_UNDEFINED_TYPE
                }
              >
                <FormControlLabel
                  value={constants.dataProcess.STORY_MODE_DEFINED_TYPE}
                  control={<Radio />}
                  label="Definido"
                />
                <FormControlLabel
                  style={{}}
                  value={constants.dataProcess.STORY_MODE_UNDEFINED_TYPE}
                  control={<Radio />}
                  label="Indefinido"
                />
                <FormControlLabel
                  value={constants.dataProcess.STORY_MODE_PERMANENT_TYPE}
                  control={<Radio />}
                  label="Permanente"
                />
              </RadioGroup>
            </Label>
          </FormControl>
        </Grid>
      </Grid>
      {volumetryType === constants.dataProcess.STORY_MODE_DEFINED_TYPE && (
        <StorageTimeVolumetry
          setValue={setValue}
          register={register}
          errors={errors}
        />
      )}
      {volumetryType === constants.dataProcess.STORY_MODE_PERMANENT_TYPE && (
        <Laws laws={laws} setLaws={setLaws} volumetryType={volumetryType} />
      )}
    </>
  )
}
export default Volumetry
