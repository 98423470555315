import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

const DialogRemovePrivacyPolicies = ({
  privacyPolicyId,
  open,
  setOpen = () => { },
  handleRemove = () => { },
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Typography variant="h4">Remover política de privacidade</Typography>
      </DialogTitle>
      <Divider />
      <Box mt={1} mb={1}>
        <DialogContent>
          <Typography>Tem certeza que deseja remover a política de privacidade?</Typography>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          onClick={() => handleRemove(privacyPolicyId)}
          color="primary"
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogRemovePrivacyPolicies