import { makeStyles } from '@material-ui/core'
const styles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    borderRadius: '0 4px 4px 0',
  },
  typographyDescription: {
    fontWeight: 500,
    fontSize: '2.3vh',
  },
  noWrap: {
    [theme.breakpoints.up('lg')]: {
      whiteSpace: 'nowrap',
      alignSelf: 'center',
    },
  },
  titleCard: {
    fontSize: '3vh',
    fontWeight: 600,
  },
}))

export default styles
