import constants from 'constants/index'

const discardMode = (dataLifeCycle) => {
  return dataLifeCycle?.storageType ===
    constants.dataProcess.STORAGE_PERMANENT_TYPE
    ? 'Arquivo permanente'
    : dataLifeCycle?.discardMode?.name || ''
}

export default discardMode
