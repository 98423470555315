import { isEmpty, filter, orderBy } from 'lodash'

const transformComponents = (components) => {
  if (isEmpty(components)) return

  const componentsFields = Object.keys(components).map((key, index) => ({
    name: key,
    description: components[key].subtitle,
    required: components[key].required,
    order: components[key].order ?? index,
    selected: true,
  }))

  return orderBy(
    filter(
      componentsFields,
      (field) => field.name !== 'Email' && field.name !== 'E-mail',
    ),
    'order',
  )
}

export default transformComponents
