import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 1.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.grey['200'],
  },
}))

export default styles
