import socialMediaHd from 'images/social_media_HD.png'

const styles = () => ({
  socialMediaImageBackground: {
    backgroundImage: `url(${socialMediaHd})`,
    height: '480px',
    width: '480px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: 'white',
    fontFamily: 'Roboto, sans-serif',
  },
  socialMediaImageContainer: {
    width: '100%',
    textAlign: 'center',
    maxWidth: '1080px',
    top: '30px',
    boxSizing: 'border-box',
    paddingTop: '140px',
  },
  bodyDocument: {
    fontSize: '18px',
  },
})

export default styles
