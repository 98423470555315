const overdueRmcs = (stats, process = true) => {
  if (process) {
    return stats?.departments?.reduce(
      (value, department) => value + department.nonComplianceReports?.overdue,
      0,
    )
  }

  return stats?.questionnaires?.reduce(
    (value, questionnaire) =>
      value + questionnaire.nonComplianceReports?.overdue,
    0,
  )
}

export default overdueRmcs
