import React from 'react'
import { Box, Button, Link, Typography } from '@material-ui/core'

import useAuth from 'hooks/useAuth'

import useStyles from './styles'
import greenCheck from 'images/green-check.png'
import greenDash from 'images/green-dash.png'
import blueX from 'images/blue-x.png'
import lock from 'images/lock.png'
import constants from 'constants/index'

const TrailsCardItem = ({
  title,
  subtitle,
  status,
  action,
  cardFatherTitle,
  journey,
  ...trail
}) => {
  const {
    company: { statusId },
  } = useAuth()
  const classes = useStyles()
  const trailStatus = status
    ? status
    : trail?.jumped
    ? constants.companyTrail.DOING_STATUS
    : trail?.completed
    ? constants.companyTrail.CONCLUDED_STATUS
    : constants.companyTrail.NOT_INITIATED_STATUS

  const images = {
    [constants.companyTrail.CONCLUDED_STATUS]: greenCheck,
    [constants.companyTrail.DOING_STATUS]: greenDash,
    [constants.companyTrail.NOT_INITIATED_STATUS]: blueX,
  }

  const { CERTIFICATE, STAMP } = constants.trailsJourney
  const { USER_STEP_JOURNEY, COMMUNICATION_JOURNEY, TRAINNING_JOURNEY } =
    constants.companyTrail.JOURNEYS

  const isTrial = statusId === constants.userCompanies.TRIAL_LIMITED_STATUS
  const isStempOrCertificate = title === STAMP || title === CERTIFICATE
  const disableTrial = isTrial && isStempOrCertificate

  const userSteps = journey === USER_STEP_JOURNEY && isTrial
  const communication = journey === COMMUNICATION_JOURNEY && isTrial
  const trainning = journey === TRAINNING_JOURNEY && isTrial

  return (
    <Box className={classes.root}>
      {(disableTrial && userSteps) || communication || trainning ? (
        <img src={lock} alt="progress" height={40} width={40} />
      ) : (
        <img src={images[trailStatus]} alt="progress" height={40} width={40} />
      )}

      <Box ml={2} className={classes.textBox}>
        <Typography variant="body1" className={classes.title} noWrap>
          {title}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{subtitle}</Typography>
          {!!action && (
            <Link
              component={Button}
              className={classes.buttonLink}
              onClick={action?.method}
              disabled={(!trail?.concluded && !trail.jumped) || disableTrial}
            >
              {action?.text}
            </Link>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default TrailsCardItem
