const showActionPlanRecomendations = (nonComplianceReport) => {
  const highFragilityId = 3

  if (nonComplianceReport.finality === 'Fragility') {
    let dataProcess = nonComplianceReport?.entity?.dataProcess

    if (!dataProcess || !dataProcess?.adoptedFragilityId) return false
    if (dataProcess?.adoptedFragilityId < highFragilityId) return false
    return true
  } else {
    return false
  }
}

export default showActionPlanRecomendations
