import React, { useState } from 'react'
import SupplierInviteContext from 'contexts/SupplierInviteContext'

import constants from 'constants/index'

const SupplierInviteProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)

  const [currentStep, setCurrentStep] = useState(
    constants.supplierInvite.WELCOME_STEP,
  )
  const [supplierData, setSupplierData] = useState({
    companyName: '',
    email: '',
    phone: '',
    document: '',
    password: '',
    confirmationPassword: '',
  })

  return (
    <SupplierInviteContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        supplierData,
        setSupplierData,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </SupplierInviteContext.Provider>
  )
}

export default SupplierInviteProvider
