const dataMultiOptions = (questionControls = []) => {
  const measures = questionControls?.map((questionControl) => ({
    id: questionControl?.questionControlId ?? questionControl?.id,
    label: questionControl?.title,
    group: questionControl?.questionnaireKind,
    categoryId: questionControl?.questionnaireKind,
    status: questionControl?.status,
  }))

  return measures
}
export default dataMultiOptions
