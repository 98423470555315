import * as yup from 'yup'

import helpers from 'helpers'
import constants from 'constants/index'

const { FILE_TYPES_DOCS, SUPPORTED_DOCUMENT_FILE_TYPES } = constants.validations

const fileTypeErrorMessage = `Somente arquivos ${helpers.formatters.file.allowedTypes(
  FILE_TYPES_DOCS,
)} são permitidos`

const schema = yup
  .object({
    legalOpinion: yup.string().required().max(1000),
    confirmed: yup.string().required(),
    shouldNotify: yup.string(),
    notificationOpinion: yup.string().when('shouldNotify', {
      is: 'true',
      then: yup.string().required(),
    }),
    opinionFile: yup
      .mixed()
      .required()
      .test('fileSize', 'O arquivo deve ter no máximo 5 MB', (value) =>
        helpers.validates.file.size('MB', 5, value),
      )
      .test('fileType', fileTypeErrorMessage, (value) =>
        helpers.validates.file.type(SUPPORTED_DOCUMENT_FILE_TYPES, value),
      ),
    notificationOpinionFile: yup
      .mixed()
      .nullable()
      .when('shouldNotify', {
        is: 'true',
        then: yup
          .mixed()
          .required()
          .test('fileSize', 'O arquivo deve ter no máximo 5 MB', (value) =>
            helpers.validates.file.size('MB', 5, value),
          )
          .test('fileType', fileTypeErrorMessage, (value) =>
            helpers.validates.file.type(SUPPORTED_DOCUMENT_FILE_TYPES, value),
          ),
      }),
  })
  .required()

export default schema
