import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    height: '100%',
  },
  messageContainer: {
    paddingBlock: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    height: 327,
  },
  messageBox: {
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    textAlign: 'justify',
    whiteSpace: 'pre-line',
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(2),
    height: '100%',
    paddingRight: theme.spacing(2),
  },
}))

export default useStyles
