import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  backgroundIcon: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: '25%',
    borderRadius: '47%',
    backgroundColor: `${theme.palette.subscription.primary.light} !important`,
    color: `${theme.palette.subscription.primary.dark} !important`,
  },
  collapse: {
    paddingRight: theme.spacing(1),
  },
}))

export default styles
