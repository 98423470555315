import React from 'react'

import { TextField, Grid, Box, Typography, Divider } from '@material-ui/core'

import { Controller } from 'react-hook-form'

import helpers from 'helpers'

const Commissioner = ({ errors, control }) => {
  return (
    <>
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography variant="h6">
            Dados do encarregado pelo tratamento de dados pessoais
          </Typography>
        </Box>
        <Divider />
      </Grid>
      <Grid item md={6} xs={12}>
        <Box mt={2}>
          <Controller
            as={
              <TextField
                label="Nome da Empresa"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.nameCommissioner}
                helperText={errors?.nameCommissioner?.message}
                fullWidth
              />
            }
            control={control}
            name="nameCommissioner"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box mt={2}>
          <Controller
            as={
              <TextField
                label="CNPJ / CPF"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.documentCommissioner}
                helperText={errors?.documentCommissioner?.message}
                fullWidth
              />
            }
            onChange={([text]) =>
              helpers.formatters.cnpjOrCpf(text.target.value)
            }
            control={control}
            name="documentCommissioner"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box mt={2}>
          <Controller
            as={
              <TextField
                label="Telefone"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.phoneCommissioner}
                helperText={errors?.phoneCommissioner?.message}
                fullWidth
              />
            }
            onChange={([text]) =>
              helpers.formatters.phoneWithoutCountryCode(text.target.value)
            }
            control={control}
            name="phoneCommissioner"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box mt={2}>
          <Controller
            as={
              <TextField
                label="E-mail"
                type="email"
                color="primary"
                variant="outlined"
                error={!!errors.emailCommissioner}
                helperText={errors?.emailCommissioner?.message}
                fullWidth
              />
            }
            control={control}
            name="emailCommissioner"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box py={2}>
          <Divider />
        </Box>
      </Grid>
    </>
  )
}

export default Commissioner
