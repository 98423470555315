import { lightBlue } from '@material-ui/core/colors'

const styles = (theme) => ({
  root: {
    margin: 'auto',
    overflowY: 'auto',
    maxHeight: '70vh',
  },
  internationalTransferField: {
    backgroundColor: lightBlue[50],
    borderRadius: theme.spacing(0.5),
  },
  inputBackground: {
    backgroundColor: theme.palette.white,
  },
})

export default styles
