const Origin = (finality) => {
  switch (finality) {
    case 'Fragility':
      return 'Risco'
    case 'Necessary':
      return 'Necessidade e proporcionalidade'
    case 'FragilityAndNecessary':
      return 'Risco e necessidade e proporcionalidade'
    case 'Question':
      return 'Questão'
    case 'Incident':
      return 'Incidente'
    default:
      return '-'
  }
}

export default Origin
