import constants from 'constants/index'

import { getPaymentAuthKey } from 'service/env'
import { TOKEN_PRE_REGISTRATION } from 'service/constants'
import helpers from 'helpers'

const paymentFormatObject = (
  selectedMethod,
  payloadPayment,
  selectedDateExpirationBillet,
) => {
  const methodId = () => {
    if (selectedMethod.id !== constants.preRegistrations.BILLET_METHOD) {
      return helpers.functions.cardFlag(payloadPayment.card.number)
    }

    return selectedMethod.id
  }

  const paymentNode = () => {
    if (selectedMethod.id === constants.preRegistrations.BILLET_METHOD) {
      return {
        billet: {
          expiration: selectedDateExpirationBillet,
        },
      }
    }

    return {
      card: {
        name: payloadPayment?.card?.name,
        number: payloadPayment?.card?.number.split(' ').join(''),
        cvv: payloadPayment?.card?.cvv,
        expiration: payloadPayment?.card?.expiration,
      },
    }
  }

  const payment = {
    authKey: getPaymentAuthKey(),
    paymentOrder: {
      method: methodId(),
      ...paymentNode(),
      preRegistrationToken: localStorage.getItem(TOKEN_PRE_REGISTRATION),
    },
  }
  return payment
}
export default paymentFormatObject
