import helpers from 'helpers'

import components from '../../components'

import { GAP } from '../../constants'

import palette from 'theme/palette'

const securityMeasures = async (pdf, date, { incident }) => {
  const incidentSecurityMeasures =
    incident?.incidentTechnicalAdministrativeSecury

  pdf.addPage('a4', 'p')

  components.subtitle(pdf, 'Medidas de Segurança', GAP)

  components.lineWithDate(pdf, date)

  components.paragraph(
    pdf,
    'Medidas de Segurança, Técnicas e Administrativas, para a Proteção dos Dados Pessoais',
    GAP * 1.5,
    palette.subscription.primary.main,
    'Roboto-Bold',
  )

  const incidentData = [
    [
      'Os dados violados estavam protegidos de forma a impossibilitar a identificação de seus titulares?',
      helpers.incidents.dataProtectedNotPossibleIdentifyHolderStatus(
        incidentSecurityMeasures?.dataProtectedNotPossibleIdentifyHolder,
      ) || 'N/A',
    ],
    [
      'Descreva os meios utilizados para proteger a identidade dos titulares, e a quais tipos de dados foram aplicados:',
      incidentSecurityMeasures?.descriptionProtectDataHolderType || 'N/A',
    ],
    [
      'Antes do incidente, quais das seguintes medidas de segurança eram adotadas?',
      incident?.dataOptionIncidents?.whichSecurityMeasuresAdopted
        ? incident?.dataOptionIncidents?.whichSecurityMeasuresAdopted
            .map(
              (securityMeasuresAdopted) => securityMeasuresAdopted.name + '\n',
            )
            .join('')
        : 'N/A',
    ],
    [
      'Descreva as demais medidas de segurança técnicas e administrativas adotadas antes do incidente:',
      incident?.descriptionOtherTechnicalAdministrativeSecurityMeasure || 'N/A',
    ],
    [
      'Após o incidente, foi adotada alguma nova medida de segurança?',
      incident?.dataOptionIncidents?.afterWhichSecurityMeasuresAdopted
        ? incident?.dataOptionIncidents?.afterWhichSecurityMeasuresAdopted
            .map((securityMeasure) => securityMeasure.name + '\n')
            .join('')
        : 'N/A',
    ],
    [
      'Se cabível, descreva as medidas de segurança adicionais adotadas após o incidente:',
      incidentSecurityMeasures?.descriptionSecurityMeasuresAdoptedAfterIncident ||
        'N/A',
    ],
    [
      'As atividades de tratamento de dados afetadas estão submetidas a regulamentações de segurança setoriais?',
      helpers.formatters.convertBoolToString(
        incidentSecurityMeasures?.affectedDataProcessingSectoralSecurityRegulation,
      ) || 'N/A',
    ],
    [
      'Se cabível, indique as regulamentações setoriais de segurança aplicáveis às atividades de tratamento de dados afetadas pelo incidente',
      incidentSecurityMeasures?.descriptionSectoralSecurityRegulationAffected ||
        'N/A',
    ],
  ]

  components.table(pdf, [], incidentData, GAP * 1.6, 0.4)

  components.numberPage(pdf, palette.black, 2)
}

export default securityMeasures
