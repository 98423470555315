const isArrayOf = (array, type) => {
  var isArrayOfType = true
  for (let index in array) {
    if (typeof array[index] !== type) {
      isArrayOfType = false
    }
  }

  return isArrayOfType
}

export default isArrayOf
