import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Box } from '@material-ui/core'

import { GeneralComponents } from './components'
import { DashboardProcess, DashboardSupplier, DashboardDiagnostic } from '..'

const DashboardMain = ({
  gapStats,
  processStats,
  generalStats,
  supplierStats,
  fragilityStats,
  questionnaires,
  questionControlStats,
}) => {
  return (
    <>
      <GeneralComponents generalStats={generalStats} />
      <Box mt={3} width="100%" />
      <DashboardProcess processStats={processStats} />
      <Box mt={3} width="100%" />
      {!isEmpty(fragilityStats) && (
        <DashboardDiagnostic
          gapStats={gapStats}
          questionnaires={questionnaires}
          fragilityStats={fragilityStats}
          questionControlStats={questionControlStats}
        />
      )}
      <Box mt={3} width="100%" />
      <DashboardSupplier supplierStats={supplierStats} />
    </>
  )
}

DashboardMain.propTypes = {
  gapStats: PropTypes.object.isRequired,
  processStats: PropTypes.object.isRequired,
  generalStats: PropTypes.object.isRequired,
  supplierStats: PropTypes.object.isRequired,
  fragilityStats: PropTypes.object.isRequired,
  questionnaires: PropTypes.array.isRequired,
}

export default DashboardMain
