import React from 'react'

import { useForm, Controller } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Paper,
} from '@material-ui/core'

import helpers from 'helpers'
import * as service from 'service'
import schema from './schema'
import styles from './styles'

const useStyles = makeStyles(styles)

const DialogResidualRisk = ({
  nonComplianceReportId,
  open,
  setOpen,
  onEvent = () => {},
  onLoading = () => {},
  ...props
}) => {
  const snackbar = useSnackbar()
  const classes = useStyles()
  const onlyShow = props.residualRisk ? true : false

  const { handleSubmit, control, errors } = useForm({
    defaultValues: props.residualRisk || {},
    validationSchema: schema,
  })

  const onSubmit = async (residualRisk) => {
    try {
      onLoading(true)
      await service.dponet.residualRisks.create({
        nonComplianceReportId,
        residualRisk,
      })
      onLoading(false)
      snackbar.open({
        message: 'Risco residual aceito com sucesso!',
        variant: 'success',
      })
      setOpen(false)
      onEvent()
    } catch (error) {
      onLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error.response && error.response.data && error.response.data.error,
        ),
        variant: 'error',
      })
      setOpen(false)
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Aceite de risco</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box mt={1}>
        <DialogContent p={1}>
          {onlyShow && (
            <Box mb={3}>
              <Grid container spacing={1} justify="space-between">
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Paper
                    variant="outlined"
                    className={classes.informationPaper}
                  >
                    <Box p={1}>
                      <Box display="flex" justifyContent="center">
                        <Typography variant="h5">Risco aceito por</Typography>
                      </Box>
                      <Box display="flex" justifyContent="center">
                        <Typography variant="body2">
                          {props.residualRisk.responsableName}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Paper
                    variant="outlined"
                    className={classes.informationPaper}
                  >
                    <Box p={1}>
                      <Box display="flex" justifyContent="center">
                        <Typography variant="h5">Risco aceito em</Typography>
                      </Box>
                      <Box display="flex" justifyContent="center">
                        <Typography variant="body2">
                          {helpers.formatters.date(
                            props.residualRisk.createdAt,
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box>
            <form onSubmit={handleSubmit(onSubmit)} id="residual-risk-form">
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        multiline
                        disabled={onlyShow}
                        minRows={8}
                        label="Justificativa"
                        error={!!errors.justification}
                        helperText={
                          errors &&
                          errors.justification &&
                          errors.justification.message
                        }
                      />
                    }
                    control={control}
                    defaultValue={
                      (props.residualRisk &&
                        props.residualRisk.justification) ||
                      ''
                    }
                    name="justification"
                    mode="onChange"
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              setOpen(false)
            }}
          >
            {onlyShow ? 'Voltar' : 'Cancelar'}
          </Button>
        </Box>
        {!onlyShow && (
          <Button
            variant="contained"
            type="submit"
            color="primary"
            form="residual-risk-form"
          >
            Salvar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default DialogResidualRisk
