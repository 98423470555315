import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  root: {
    color: '#52af77',
    height: theme.spacing(2),
  },
  thumb: {
    height: 24,
    width: 24,
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: -4,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  valueLabel: {
    left: 'calc(-50% + 5px)',
    top: 'calc(100% + 5px)',
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  mark: {
    backgroundColor: 'inherit',
  },
  markActive: {
    backgroundColor: 'inherit',
  },
  track: {
    height: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: 'transparent',
  },
  rail: {
    opacity: 1,
    border: `1px solid ${theme.palette.primary.main}`,
    height: theme.spacing(2),
    borderRadius: theme.spacing(1),
    background: `linear-gradient(270deg, ${theme.palette.fragility.severe} 5%, ${theme.palette.fragility.high} 35%, ${theme.palette.fragility.medium} 50%, ${theme.palette.fragility.low} 75%, ${theme.palette.fragility.undefined} 95%)`,
  },
  thumbUndefinedFragility: {
    backgroundColor: theme.palette.fragility.undefined,
  },
  thumbLowFragility: {
    backgroundColor: theme.palette.fragility.low,
  },
  thumbMediumFragility: {
    backgroundColor: theme.palette.fragility.medium,
  },
  thumbHighFragility: {
    backgroundColor: theme.palette.fragility.high,
  },
  thumbSevereFragility: {
    backgroundColor: theme.palette.fragility.severe,
    left: '98% !important',
  },
  iconButton: {
    padding: 0,
  },
}))

export default styles
