import React from 'react'
import { Box, Typography } from '@material-ui/core'

import BaseStatus from '../BaseStatus'

const InternalError = () => {
  return (
    <BaseStatus status="disapproved" description="Ocorreu um erro interno!">
      <Box>
        <Typography gutterBottom variant="body1" color="textPrimary">
          Por favor, entre em contato com nossa equipe de atendimento para
          entender melhor o que aconteceu. Caso você tenha sido cobrado pelo
          pagamento, não tente realizá-lo novamente.
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Entre em contato através do e-mail financeiro@dponet.com.br
        </Typography>
      </Box>
    </BaseStatus>
  )
}

export default InternalError
