import palette from 'theme/palette'

import { complianceBox as complianceBox64 } from '../../assets/complianceBox64'

import { fontSize } from '../../constants'

import { ratio } from '../../helpers'

const complianceBox = (pdf, positionX, positionY, data, label) => {
  pdf.addImage(
    complianceBox64,
    'png',
    positionX,
    positionY,
    ratio(640),
    ratio(320),
  )
  pdf.setFontSize(fontSize.chapterTitle)
  pdf.setFont('Raleway-Bold')
  pdf.setTextColor(palette.white)
  pdf.text(
    data,
    positionX + ratio(40),
    positionY + ratio(140),
    null,
    null,
    'left',
  )

  pdf.setFontSize(ratio(40))
  pdf.setFont('Raleway-Regular')
  pdf.text(
    label,
    positionX + ratio(40),
    positionY + ratio(190),
    null,
    null,
    'left',
  )
}

export default complianceBox
