import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBlock: theme.spacing(2),
    paddingInline: theme.spacing(3),
  },
  title: {
    fontWeight: 500,
    padding: 1,
  },
}))

export default styles
