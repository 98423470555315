import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundColor: theme.colors.firstStep.secondary,
    border: 'none',
    textAlign: 'center',
    width: 500,
    maxHeight: '90vh',
  },
  closeIcon: {
    justifyContent: 'end',
    paddingBottom: 0,
  },
  title: {
    fontFamily: 'Raleway',
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(22),
    fontWeight: 'bold',
  },
  titleApproved: {
    backgroundColor: theme.palette.subscription.warning.error,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(27),
    padding: theme.spacing(2, 0),
  },
  description: {
    display: 'inline',
    fontFamily: 'Raleway',
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
    marginBottom: 30,
  },
  descriptionUnderlined: {
    display: 'inline',
    fontFamily: 'Raleway',
    color: theme.palette.common.white,
    backgroundColor: '#4199D2',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'bold',
    marginBottom: 30,
  },
  button: {
    borderRadius: '29px',
    fontSize: theme.typography.pxToRem(16),
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    boxShadow: `0px 0px 12px ${theme.colors.firstStep.button.primary}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.colors.firstStep.button.primary,
    },
    backgroundColor: theme.colors.firstStep.button.primary,
    borderColor: theme.colors.firstStep.button.primary,
    color: theme.palette.common.white,
    textTransform: 'none',
    justifyContent: 'center',
    marginTop: 30,
  },
}))

export default styles
