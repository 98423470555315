const styles = (theme) => ({
  cardContent: {
    padding: '0px !important',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
})

export default styles
