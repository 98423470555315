import { colors } from '@material-ui/core'

const white = '#FFFFFF'

const styles = (theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  textPending: {
    color: '#007bff',
    fontWeight: 700,
  },
  textWaitingReview: {
    color: '#FF9800',
    fontWeight: 700,
  },
  textReviewDisapproved: {
    color: '#E53935',
    fontWeight: 700,
  },
  textReviewApproved: {
    color: '#4CAF50',
    fontWeight: 700,
  },
  textReviewing: {
    color: '#FF9800',
    fontWeight: 700,
  },
  textInactive: {
    color: white,
    fontWeight: 700,
  },
  textSuggested: {
    color: '#74BFE1',
    fontWeight: 700,
  },
  textDiscarded: {
    color: '#535151',
    fontWeight: 700,
  },
  inactive: {
    backgroundColor: colors.grey[500],
  },
  pending: {
    backgroundColor: '#087DF138',
  },
  waitingReview: {
    backgroundColor: '#FFF3E0',
  },
  reviewDisapproved: {
    backgroundColor: '#FEEBEE',
  },
  reviewApproved: {
    backgroundColor: '#E8F5E9',
  },
  reviewing: {
    backgroundColor: '#FFF3E0',
  },
  suggested: {
    backgroundColor: '#006EA0',
  },
  discarded: {
    backgroundColor: '#DFDFDF',
  },
  lowFragility: {
    backgroundColor: '#087DF138',
  },
  mediumFragility: {
    backgroundColor: '#E8F5E9',
  },
  highFragility: {
    backgroundColor: '#FFF3E0',
  },
  severeFragility: {
    backgroundColor: '#FEEBEE',
  },
  textLowFragility: {
    color: '#007bff',
    fontWeight: 700,
  },
  textMediumFragility: {
    color: '#4CAF50',
    fontWeight: 700,
  },
  textHighFragility: {
    color: '#F4A460',
    fontWeight: 700,
  },
  textSevereFragility: {
    color: '#E53935',
    fontWeight: 700,
  },
})

export default styles
