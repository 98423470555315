import { makeStyles } from '@material-ui/core'
const styles = makeStyles((theme) => ({
  iFrame: {
    backgroundColor: theme.palette.common.black,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: '40vh',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '55vw',
    },
  },
  boxIcon: {
    marginTop: theme.spacing(1),
    marginLeft: -theme.spacing(6),
  },
}))

export default styles
