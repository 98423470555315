import React from 'react'
import { Box, IconButton, Paper, Tooltip, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { ChevronRight as ChevronRightIcon } from 'react-feather'
import PropTypes from 'prop-types'

import useStyles from './styles'

const PendingActivity = ({ loading, title, subtitle, handleClick, icon }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paperBox} onClick={handleClick}>
      {loading ? (
        <Skeleton
          variant="circle"
          width={37}
          height={37}
          className={classes.avatarIcon}
        ></Skeleton>
      ) : (
        <Box className={classes.avatarBox}>
          <Box className={classes.avatarIcon}>{icon}</Box>
        </Box>
      )}

      <Box className={classes.boxInfos}>
        {loading ? (
          <>
            <Skeleton width="45%" height="25px" />
            <Skeleton width="90%" />
          </>
        ) : (
          <>
            <Typography className={classes.lineTitle} gutterBottom>
              {title}
            </Typography>

            <Tooltip title={subtitle}>
              <Typography className={classes.lineDesc}>{subtitle}</Typography>
            </Tooltip>
          </>
        )}
      </Box>
      <IconButton className={classes.arrowIcon}>
        <ChevronRightIcon />
      </IconButton>
    </Paper>
  )
}

PendingActivity.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  handleClick: PropTypes.func,
  icon: PropTypes.object,
}

PendingActivity.defaultProps = {
  loading: false,
}

export default PendingActivity
