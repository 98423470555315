import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    color: theme.palette.white,
    paddingTop: theme.spacing(0),
    padding: theme.spacing(2.5),
  },
  alignCenterBox: {
    display: 'flex',
    justifyContent: 'center',
    AlignItems: 'center',
    textAlign: 'center',
  },
  title: {
    fontWeight: 700,
  },
  subtitle: {
    whiteSpace: 'break-spaces',
    color: theme.palette.white,
  },
  default: {
    background: `linear-gradient(135deg, ${theme.palette.blueLight} 0%,${theme.palette.azure} 100%) !important`,
  },
  warning: {
    background: `linear-gradient(135deg, ${theme.palette.yellowWarningLight} 0%,${theme.palette.yellowWarningDark} 100%) !important`,
  },
  error: {
    background: `linear-gradient(135deg, ${theme.palette.redErrorLight} 0%,${theme.palette.redErrorDark} 100%) !important`,
  },
  success: {
    background: `linear-gradient(135deg, ${theme.palette.greenLight} 0%,${theme.palette.greenDark} 100%) !important`,
  },
  buttonTextColor: {
    color: 'white',
  },
}))

export default styles
