import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundColor: theme.colors.firstStep.secondary,
    border: 'none',
    borderRadius: 20,
    [theme.breakpoints.up('md')]: {
      width: `calc(${theme.breakpoints.values.sm}px + 17vh)`,
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
  closeLabel: {
    color: theme.palette.common.white,
  },
  iconButton: {
    padding: 0,
    maxHeight: 30,
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-end',
    },
  },
  title: {
    color: theme.palette.common.white,
  },
  description: {
    color: theme.palette.common.white,
    whiteSpace: 'break-spaces',
  },
}))

export default styles
