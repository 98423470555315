import getIndexByTag from './getIndexByTag'
import getTagByIndex from './getTagByIndex'
import getTrailId from './getTrailId'
import getTrailIdByTag from './getTrailIdByTag'
import mountHelpObject from './mountHelpObject'
import validRedirectionStep from './validRedirectionStep'

const userTailSteps = {
  getIndexByTag,
  getTagByIndex,
  getTrailId,
  getTrailIdByTag,
  mountHelpObject,
  validRedirectionStep,
}

export default userTailSteps
