import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { ShoppingBag as ShoppingBagIcon } from 'react-feather'
import { AppBar, Toolbar, Box, IconButton, Hidden } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Container } from 'components'
import { RegistrationResumeDrawer } from './components'
import { routes } from 'Routes'

import useStyles from './styles'
import useSubscription from 'hooks/useSubscription'
import logo from 'images/dponet-logo-white.svg'

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles()
  const [drawer, setDrawer] = useState(false)
  const { isDemoSubscription, isDemoSubscriptionFinish, hideTopbarResume } =
    useSubscription()
  const openDrawer = () => {
    setDrawer(true)
  }

  const showResume =
    !hideTopbarResume && !isDemoSubscription && !isDemoSubscriptionFinish

  return (
    <>
      <AppBar elevation={1} className={clsx(classes.root, className)} {...rest}>
        <Toolbar disableGutters>
          <Box width="100%">
            <Container maxWidth={false} spacingXl={7}>
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <RouterLink
                  className={classes.routerLink}
                  to={routes.subscription}
                >
                  <img alt="Logo" src={logo} className={classes.logo} />
                </RouterLink>
                {showResume && (
                  <Hidden lgUp>
                    <IconButton
                      size="medium"
                      className={classes.menuResume}
                      onClick={openDrawer}
                    >
                      <ShoppingBagIcon />
                    </IconButton>
                  </Hidden>
                )}
              </Box>
            </Container>
          </Box>
        </Toolbar>
      </AppBar>
      <RegistrationResumeDrawer open={drawer} setOpen={setDrawer} />
    </>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
}

export default TopBar
