const dataCollectedLabelOptions = {
  0: 'Outros',
  1: 'Dados sensíveis',
  2: 'Dados pessoais',
  3: 'Dados financeiros',
  4: 'Dados comportamentais',
  5: 'Outros dados pessoais',
  6: 'Outros dados financeiros',
  7: 'Outros dados comportamentais',
}

const mountLabelsList = (data, disabled = false) => {
  return data.map((val) => ({
    id: val.id,
    label: val.name,
    group:
      dataCollectedLabelOptions[
        (val.dataType && val.dataType.toString()) || '0'
      ],
    disabled,
  }))
}

export default mountLabelsList
