import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { ArrowRight as ArrowRightIcon } from 'react-feather'
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'

import { LoadingFeedback } from 'components'
import { RefusedModal } from './components'
import { SupplierForm } from '../../components'

import { getBaseURL } from 'service/env'
import { routes } from 'Routes'

import { useHistory } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import useStyles from './styles'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'

const SupplierWelcome = ({ client, supplierId, setRenderedContent }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const { company } = useAuth()
  const history = useHistory()

  const { mixpanel } = helpers

  const clientLogo = `${getBaseURL('dponet')}/${client?.logo?.url}`

  const handleConfirmation = () => setOpenConfirmation(true)

  const handleNextOrConfirmation = () => {
    mixpanel.track('Jornada de Fornecedores', 'Bem vindo(a) à DPOnet', {
      action: 'Não_reconhe_esta_empresa-Bem_vindo',
    })
    return handleConfirmation()
  }

  const handleNextDialog = () => {
    setOpenConfirmation(false)
  }

  const approvedLink = async () => {
    try {
      setLoading(true)
      await service.dponet.suppliers.approveOrRefuse({
        supplierId,
        status: constants.companySuppliers.APPROVED_STATUS,
      })
      mixpanel.track('Jornada de Fornecedores', 'Bem vindo(a) à DPOnet', {
        action: 'Reconhe_esta_empresa-Bem_vindo',
      })

      setLoading(false)
      if (company.kind !== constants.preRegistrations.SUPPLIER_KIND) {
        history.push(routes.questionnaire.all)
      } else {
        setRenderedContent(
          <SupplierForm setRenderedContent={setRenderedContent} />,
        )
      }
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro ao confirmar o seu vínculo com o cliente',
        variant: 'error',
      })
    }
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <LoadingFeedback open={loading} />
      <Box className={classes.groupTypography}>
        <Box className={classes.logoContainer}>
          <img
            src={clientLogo}
            alt="Logo da empresa"
            className={classes.logo}
          />
        </Box>
        <Typography className={classes.text}>
          A empresa <span className={classes.highlight}>{client?.name}</span> te
          indicou como fornecedor para avaliar sua conformidade à LGPD!
        </Typography>
        <Typography className={clsx(classes.text, classes.textQuestion)}>
          Você reconhece esta empresa?
        </Typography>
      </Box>
      <Box className={classes.groupButton}>
        <Paper
          onClick={approvedLink}
          variant="outlined"
          component={Button}
          endIcon={<ArrowRightIcon size={31} />}
          className={classes.button}
          fullWidth
        >
          Sim, reconheço esta empresa
        </Paper>
        <Paper
          onClick={handleNextOrConfirmation}
          variant="outlined"
          component={Button}
          endIcon={<ArrowRightIcon size={31} />}
          className={clsx(classes.button, classes.outlined)}
          fullWidth
        >
          Não reconheço esta empresa
        </Paper>
        <RefusedModal
          client={client}
          supplierId={supplierId}
          company={company}
          open={openConfirmation}
          setRenderedContent={setRenderedContent}
          setOpen={setOpenConfirmation}
          actionAcceptButton={handleNextDialog}
          textButton="Sim"
        />
      </Box>
    </Grid>
  )
}

SupplierWelcome.propTypes = {
  supplierId: PropTypes.number,
  client: PropTypes.object,
}

export default SupplierWelcome
