const styles = (theme) => ({
  legalFrameworkContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  legalFrameworkTitle: {
    marginBottom: theme.spacing(4),
  },
  inputContainer: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  topicContainer: {
    paddingBottom: theme.spacing(2),
  },
})

export default styles
