const SERVICE_COMPONENT = 'Service'
const ADDRESS_COMPONENT = 'Address'
const CONTACT_COMPONENT = 'Contact'

const TYPE_CATEGORY_MAP_PROCESS = 'MAPEAMENTO DE PROCESSOS'
const TYPE_CATEGORY_RESOLUTION_QUESTIONNARIES = 'RESOLUCAO DE QUESTIONARIOS'
const TYPE_CATEGORY_RESOLUTION_RMC = 'RESOLUCAO DE RMC'
const TYPE_CATEGORY_TRAINING = 'TREINAMENTO'

const DESCRIPTION_MAP_PROCESS =
  'Este serviço te auxiliará no mapeamento e/ou revisão das atividades que são realizadas diaramente, identificando todas aquelas que contêm dados pessoais envolvidos.'
const DESCRIPTION_RESOLUTION_QUESTIONNARIES =
  'O objetivo da resolução de questionários é identificar quais exigências jurídicas e medidas de controle (governança e cultura, infraestrutura e tecnologia) sua organização adota.'
const DESCRIPTION_RESOLUTION_RMC =
  'Com base em suas respostas no questionário e de seus processos mapeados, o DPOnet te recomendará algumas melhorias a serem adotadas (RMCs) e um instrutor poderá te auxiliar a implementá-las.'
const DESCRIPTION_TRAINING =
  'Estar em conformidade com LGPD não é uma ação pontual, é preciso  estar sempre se atualizando. Os serviços de treinamento é um dos meios pelos quais os colaboradores podem adquirir conhecimento sobre a LGPD.'

const OPTIONS_MODE_SERVICES = [
  { id: 1, name: 'Presencial' },
  { id: 2, name: 'Remoto' },
  { id: 3, name: 'Híbrido' },
]

const TYPE_CATEGORIES = [
  TYPE_CATEGORY_MAP_PROCESS,
  TYPE_CATEGORY_RESOLUTION_QUESTIONNARIES,
  TYPE_CATEGORY_RESOLUTION_RMC,
  TYPE_CATEGORY_TRAINING,
]

const DESCRITION_CATEGORIES = [
  DESCRIPTION_MAP_PROCESS,
  DESCRIPTION_RESOLUTION_QUESTIONNARIES,
  DESCRIPTION_RESOLUTION_RMC,
  DESCRIPTION_TRAINING,
]

const partner = {
  SERVICE_COMPONENT,
  ADDRESS_COMPONENT,
  CONTACT_COMPONENT,
  TYPE_CATEGORIES,
  DESCRITION_CATEGORIES,
  OPTIONS_MODE_SERVICES,
}

export default partner
