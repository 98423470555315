import dataProcess from './dataProcess'
import firstStep from './firstStep'
import companyTrails from './companyTrails'
import rncs from './rmcs'

const colors = {
  dataProcess,
  companyTrails,
  firstStep,
  rncs,
}

export default colors
