import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import helpers from 'helpers'

import theme from 'theme'
import styles from './styles'

const ScannerStatus = ({ status, ...rest }) => {
  const useStyles = styles(status)
  const classes = useStyles()

  const StatusIcon = helpers.cookies.banner.scannerStatusIcon(status)
  const statusColor = helpers.cookies.banner.scannerStatusColor(status)
  const statusLabel = helpers.cookies.banner.scannerStatusLabel(status)

  return (
    <Box
      display="flex"
      gridGap={theme.spacing(1)}
      alignItems="center"
      color={statusColor}
      {...rest}
    >
      <Box display="flex" pb={0.25} className={classes.iconBox}>
        <StatusIcon size={18} />
      </Box>
      <Typography variant="h6" className={classes?.label}>
        {statusLabel}
      </Typography>
    </Box>
  )
}

ScannerStatus.propTypes = {
  cookies: PropTypes.string,
}

export default ScannerStatus
