import palette from 'theme/palette'

import { fontSize, PADDING } from '../constants'

import { ratio } from '../helpers'

const numberAndTitle = (pdf, number, title) => {
  pdf.setFontSize(fontSize.chapterNumber)
  pdf.setTextColor(palette.grapePurple)
  pdf.text(number, PADDING, ratio(430), null, null, 'left')
  pdf.setFontSize(fontSize.chapterTitle)
  pdf.setTextColor(palette.primary.main)
  pdf.setFont('Raleway-Bold')
  pdf.text(title, PADDING, ratio(650), null, null, 'left')
}

export default numberAndTitle
