import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@material-ui/core'

import useSubscription from 'hooks/useSubscription'

import useStyles from './styles'

const Header = () => {
  const classes = useStyles()

  const { isDemoSubscription } = useSubscription()

  return (
    <Box className={classes.root}>
      <Typography variant="h3">
        {isDemoSubscription ? 'Cadastro' : 'Contratação'}
      </Typography>
    </Box>
  )
}

Header.propTypes = {
  segment: PropTypes.string,
}

Header.defaultProps = {
  segment: 'Não identificado',
}

export default Header
