import backgroundBlue from 'images/Polygon_2.svg'
import backgroundGreen from 'images/Polygon_2_Complete_light_green.svg'
const styles = (theme) => ({
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    fontWeight: 800,
    '&:hover': {
      backgroundColor: '#546E7A',
      color: 'white',
    },
  },
  stepper: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
  },
  step: {
    padding: theme.spacing(2),
  },
  stepConnector: {
    display: 'none',
  },
  stepIcon: {
    height: 45,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepIconActive: {
    backgroundImage: `url(${backgroundBlue})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  stepIconCompleted: {
    color: '#090',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${backgroundGreen}) !important`,
  },
  stepLabelText: {
    color: '#263238',
  },
  stepLabelTextOptional: {
    color: '#546E7A',
  },
})
export default styles
