import React, { useState, useEffect } from 'react'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import PropTypes from 'prop-types'
import qs from 'query-string'

import { DialogExportRipds, Permitted } from 'components'
import { ContentMain, ScreenLoading } from './components'

import DrawerProvider from 'providers/DrawerProvider'

import useAuth from 'hooks/useAuth'
import useCustomTheme from 'hooks/useCustomTheme'
import useSnackbar from 'hooks/useSnackbar'

import { RIPD_INCITE_EXPORTED } from 'service/constants'
import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'
import theme from 'theme'

const Main = ({
  permission,
  somePermissions,
  admin,
  children,
  showBackToFirstSteps,
  reduceMargin,
}) => {
  const auth = useAuth()
  const history = useHistory()
  const location = useLocation()
  const snackbar = useSnackbar()
  const { customTheme } = useCustomTheme()

  const layoutColors = customTheme?.palette?.layout

  const customThemeIsActive = () => {
    let customThemeIsActive = false
    if (layoutColors?.main !== theme.palette.primaryMain) {
      customThemeIsActive = true
    }
    return customThemeIsActive
  }

  const showInciteRipd =
    auth.userCompany?.showInciteRipd &&
    !localStorage.getItem(RIPD_INCITE_EXPORTED + auth?.userCompany?.id)

  const search = qs.parse(location.search)

  const [dialogExportRipds, setDialogExportRipds] = useState(
    search.exportRipds === 'true',
  )
  const [ripdInciteOpen, setRipdInciteOpen] = useState(false)
  const [dialogTomcicket, setDialogTomcicket] = useState(false)

  const redirectToMoodle = async () => {
    if (
      auth?.company?.statusId === constants.userCompanies.TRIAL_LIMITED_STATUS
    ) {
      history.push(routes.myLgpd)
      return auth.setFreeTrialBlock(true)
    }
    try {
      const response = await service.dponet.moodleLotus.generateKey()
      window.open(response?.data?.loginurl, '_blank')
    } catch {
      snackbar.open({
        message:
          'Não foi possível redirecionar para a plataforma, entre em contato conosco para verificar se você possui uma conta de acesso',
        variant: 'error',
      })
    } finally {
      history.replace()
    }
  }

  const redirectToMoodleDocuments = async () => {
    if (
      auth?.company?.statusId === constants.userCompanies.TRIAL_LIMITED_STATUS
    ) {
      history.push(routes.myLgpd)
      return auth.setFreeTrialBlock(true)
    }
    const wantsUrl = 'https://educacao.dponet.com.br/mod/folder/view.php?id=189'
    try {
      const response = await service.dponet.moodleLotus.generateKey()
      window.open(`${response?.data?.loginurl}&wantsurl=${wantsUrl}`, '_blank')
    } catch {
      snackbar.open({
        message:
          'Não foi possível redirecionar para a plataforma, entre em contato conosco para verificar se você possui uma conta de acesso',
        variant: 'error',
      })
    } finally {
      history.replace()
    }
  }

  const handleOpenTomticketDialog = () => setDialogTomcicket(true)

  useEffect(() => {
    if (search.redirectToMoodle === 'true') redirectToMoodle()
    //eslint-disable-next-line
  }, [search.redirectToMoodle])

  useEffect(() => {
    if (search.redirectToMoodleDocuments === 'true') redirectToMoodleDocuments()
    //eslint-disable-next-line
  }, [search.redirectToMoodleDocuments])

  useEffect(() => {
    setDialogExportRipds(search.exportRipds === 'true')

    // eslint-disable-next-line
  }, [search.exportRipds])

  useEffect(() => {
    setRipdInciteOpen(showInciteRipd)

    // eslint-disable-next-line
  }, [showInciteRipd])

  return (
    <DrawerProvider>
      <ThemeProvider theme={customTheme}>
        <ScreenLoading loaded={auth.loaded}>
          <Permitted
            someTags={somePermissions}
            tag={permission}
            admin={admin}
            fallback={<Redirect to={routes.forbidden} />}
          >
            <ContentMain
              children={children}
              showBackToFirstSteps={showBackToFirstSteps}
              reduceMargin={reduceMargin}
              company={auth?.userCompany?.company}
              handleOpenTomticketDialog={handleOpenTomticketDialog}
              dialogTomcicket={dialogTomcicket}
              setDialogTomcicket={setDialogTomcicket}
              customThemeIsActive={customThemeIsActive}
            />
          </Permitted>
          <DialogExportRipds
            open={dialogExportRipds}
            setOpen={setDialogExportRipds}
          />
          <DialogExportRipds
            open={ripdInciteOpen}
            setOpen={setRipdInciteOpen}
            ripdIncite
          />
        </ScreenLoading>
      </ThemeProvider>
    </DrawerProvider>
  )
}

Main.propTypes = {
  permission: PropTypes.string,
  showBackToFirstSteps: PropTypes.bool,
  reduceMargin: PropTypes.bool,
}

export default Main
