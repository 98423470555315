import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get('/supplier_suggestions', { params })
}

const acceptedOrDeclined = async ({ supplierSuggestionId, supplier }) => {
  return await dponetAPI.put(
    `/supplier_suggestions/${supplierSuggestionId}/accepted_or_declined`,
    {
      supplier,
    },
  )
}

const suggestedSuppliers = {
  get,
  acceptedOrDeclined,
}

export default suggestedSuppliers
