const styles = (theme) => ({
  button: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    transition: '0.3s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.success.main,
      opacity: 0.9,
    },
  },
})

export default styles
