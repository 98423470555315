import React from 'react'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
} from '@material-ui/core'

const DialogQuestionRecommendation = ({ open, setOpen, recommendation }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-change"
      maxWidth="lg"
    >
      <DialogTitle>
        <Box>
          <Typography variant="h4">Recomendação da questão</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box
        minHeight="60px"
        display="flex"
        alignItems="center"
        paddingBottom="12px"
      >
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {recommendation}
          </Typography>
        </DialogContent>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="flex-end" my={2} mr={2}>
        <Box pr={1}>
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={() => setOpen(false)}
            disableElevation
          >
            Ok
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default DialogQuestionRecommendation
