import React, { useState } from 'react'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { isEmpty } from 'lodash'
import clsx from 'clsx'

import {
  Box,
  Container,
  Tabs,
  Divider,
  Tab,
  Tooltip,
  Button,
} from '@material-ui/core'

import { Page, ContentHeader, DriveTour } from 'components'
import { DataProcessesSuggested, DataProcessesDiscarded } from './components'

import helpers from 'helpers'
import constants from 'constants/index'
import DataProcessProvider from 'providers/DataProcessProvider'

import useStyles from './styles'

const DataProcessesSuggestedMain = () => {
  const classes = useStyles()

  const [currentTab, setCurrentTab] = useState('dataProcessesSuggested')
  const [dataProcesses, setDataProcesses] = useState([])
  const [openTour, setOpenTour] = useState(false)

  const tabs = [
    {
      value: 'dataProcessesSuggested',
      label: 'Processos Sugeridos',
      tour: constants.dataProcess.DATA_PROCESS_SUGGESTED_DRIVER_STEP_2,
    },
    {
      value: 'dataProcessesDiscarded',
      label: 'Processos Descartados',
      tour: constants.dataProcess.DATA_PROCESS_SUGGESTED_DRIVER_STEP_3,
    },
  ]

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  const validationSteps = () => {
    let steps = [
      ...constants.dataProcess.DATA_PROCESS_SUGGESTED_DRIVER_STEPS_OBJECT_1,
    ]

    if (!isEmpty(dataProcesses?.dataProcesses)) {
      if (currentTab === 'dataProcessesSuggested') {
        steps = [
          ...steps,
          constants.dataProcess.DATA_PROCESS_SUGGESTED_DRIVER_STEPS_OBJECT_2,
        ]
      } else {
        steps = [
          ...steps,
          constants.dataProcess.DATA_PROCESS_SUGGESTED_DRIVER_STEPS_OBJECT_3,
        ]
      }
    }

    return steps
  }

  const handleOpenTour = () => {
    const where = 'Processos sugeridos'
    const title = 'Tour'

    const params = {
      action: 'tour-novos-sugeridos-processos',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Processos sugeridos">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Processos sugeridos">
          <Tooltip title={'Iniciar o tour guiado'}>
            <Box
              className={clsx(
                classes.contentHeaderBox,
                classes.contentHeaderBoxSm,
              )}
            >
              <Button
                id={constants.dataProcess.DATA_PROCESS_SUGGESTED_DRIVER_STEP_1}
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                fullWidth
              >
                Tutorial
              </Button>
            </Box>
          </Tooltip>
        </ContentHeader>
        <Box display="flex" width="100%" mt={5}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            {tabs.map((tab) => (
              <Tab
                id={tab.tour}
                key={tab.value}
                label={tab.label}
                value={tab.value}
                onClick={(e) => handleTabsChange(e, tab.value)}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <DataProcessProvider>
          <Box mt={3}>
            {currentTab === 'dataProcessesSuggested' && (
              <DataProcessesSuggested
                dataProcesses={dataProcesses}
                setDataProcesses={setDataProcesses}
              />
            )}

            {currentTab === 'dataProcessesDiscarded' && (
              <DataProcessesDiscarded
                dataProcesses={dataProcesses}
                setDataProcesses={setDataProcesses}
              />
            )}
          </Box>
        </DataProcessProvider>
      </Container>
      <DriveTour
        stepsMatrix={validationSteps()}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default DataProcessesSuggestedMain
