import { jsPDF } from 'jspdf'
import * as Sentry from '@sentry/react'

import moment from 'moment'

import pages from './pages'
import templates from '../templates'

import '../fonts/Raleway-Bold-normal'
import '../fonts/Raleway-Regular-normal'
import '../fonts/Raleway-Thin-normal'
import '../fonts/Roboto-Bold-normal'
import '../fonts/Roboto-Regular-normal'

import * as service from 'service'

import constants from 'constants/index'

moment.locale('pt-br')
var dateNow = moment().format('MMM YYYY')
dateNow = dateNow.charAt(0).toUpperCase() + dateNow.slice(1)

const exportRMCsReport = async (
  company,
  userCompany,
  gapStats,
  processStats,
  generalStats,
  customStyles,
  customLogo,
  customThemeStatus,
) => {
  const ReportRmcPdf = async (company, userCompany) => {
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      userUnit: 300,
    })

    const response = await service.dponet.nonComplianceReports.get({
      nonComplianceReportId: '',
      perPage: 9999,
    })

    const questionsRmcs = response?.data?.nonComplianceReports.filter(
      (report) =>
        report.entityType === constants.nonComplianceReports.ENTITY_QUESTION,
    )

    const incidentsRmcs = response?.data?.nonComplianceReports.filter(
      (report) =>
        report.entityType === constants.nonComplianceReports.ENTITY_INCIDENT,
    )

    const dataProcessesRmcs = response?.data?.nonComplianceReports.filter(
      (report) =>
        report.entityType ===
        constants.nonComplianceReports.ENTITY_DATA_PROCESS,
    )

    try {
      templates.firstCover(
        pdf,
        userCompany?.company?.name,
        userCompany?.company?.cpf || userCompany?.company?.cnpj,
        dateNow,
        customStyles,
        customLogo,
        'Relatório dos Registros de Melhorias Contínuas \n(RMC)',
      )

      templates.chapterCover(
        pdf,
        'VISÃO GERAL',
        'Síntese das principais informações e métricas apresentadas na plataforma.',
        customStyles,
      )

      await pages.rmcs(
        pdf,
        `RMC | ${dateNow}`,
        processStats,
        gapStats,
        generalStats,
        customThemeStatus,
      )

      templates.chapterCover(
        pdf,
        'PROCESSOS',
        'Síntese das principais informações e atividades sobre RMCs de processos.',
        customStyles,
      )
      dataProcessesRmcs.length === 0
        ? pages.NotFound(
            pdf,
            `Processos | ${dateNow}`,
            '2',
            'Processos',
            'Não foram encontrados RMCs de processos',
            customThemeStatus,
          )
        : pages.process(
            pdf,
            `Processos | ${dateNow}`,
            dataProcessesRmcs,
            customThemeStatus,
          )

      templates.chapterCover(
        pdf,
        'QUESTIONÁRIOS',
        'Síntese das principais informações e atividades sobre RMCs de questionários.',
        customStyles,
      )

      questionsRmcs.length === 0
        ? pages.NotFound(
            pdf,
            `Questionários | ${dateNow}`,
            '3',
            'Questionários',
            'Não foram encontrados RMCs de questionários',
            customThemeStatus,
          )
        : pages.questionnaire(
            pdf,
            `Questionários | ${dateNow}`,
            questionsRmcs,
            customThemeStatus,
          )

      templates.chapterCover(
        pdf,
        'INCIDENTES',
        'Síntese das principais informações e atividades sobre RMCs de incidentes.',
        customStyles,
      )

      incidentsRmcs.length === 0
        ? pages.NotFound(
            pdf,
            `Incidentes | ${dateNow}`,
            '4',
            'Incidentes',
            'Não foram encontrados RMCs de incidentes',
            customThemeStatus,
          )
        : pages.incident(
            pdf,
            `Incidentes | ${dateNow}`,
            incidentsRmcs,
            customThemeStatus,
          )

      templates.lastCover(pdf, customStyles, customLogo)

      pdf.save(
        `Relatorio_RMCs_${company?.name
          ?.split(' ')
          ?.join('_')
          ?.toLowerCase()}_${dateNow}.pdf`,
      )
    } catch (e) {
      Sentry.captureException(e)
      console.error(e)
    }
  }
  return ReportRmcPdf(company, userCompany)
}

export default exportRMCsReport
