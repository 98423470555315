import React, { useState } from 'react'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { AuditsPageStructure } from './components'

const AuditsNew = () => {
  const [loading, setLoading] = useState(false)

  return (
    <Page title="Nova - Auditoria de processos">
      <LoadingFeedback open={loading} />
      <Container maxWidth={false}>
        <ContentHeader title="Nova auditoria" />
        <AuditsPageStructure
          formRef="new-audits-info-section-form"
          formType="new"
          setLoading={setLoading}
        />
      </Container>
    </Page>
  )
}

export default AuditsNew
