import React, { useState } from 'react'
import useSnackbar from 'hooks/useSnackbar'
import { RHFInput } from 'react-hook-form-input'
import { useForm } from 'react-hook-form'

import {
  Grid,
  TableContainer,
  Box,
  CircularProgress,
  Button,
  Typography,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton } from 'components'
import DialogRemoveDocument from '../DialogRemoveDocument'

import { PlusCircle } from 'react-feather'

import useRncDocuments from 'hooks/useRncDocuments'
import usePermission from 'hooks/usePermissions'
import helpers from 'helpers'
import * as service from 'service'
import { getBaseURL } from 'service/env'
import constants from 'constants/index'

const TableDocuments = ({
  description,
  nonComplianceReportId,
  viewOnly,
  onEvent = () => {},
}) => {
  const { isDpo } = usePermission()
  const snackbar = useSnackbar()
  const [dialogRemoveDocument, setDialogRemoveDocument] = useState(false)
  const [removableDocument, setRemovableDocument] = useState(false)

  const { register, setValue } = useForm()

  const { data, loadData, loading, setLoading } = useRncDocuments()

  const handleRemove = (document) => {
    setRemovableDocument(document)
    setDialogRemoveDocument(true)
  }

  const onClickDeleteDocument = async (document) => {
    const documentName = helpers.functions.documentName(document.url)

    try {
      await service.dponet.documents.deleteRncDocument({
        nonComplianceReportId: nonComplianceReportId,
        documentName: documentName,
      })

      snackbar.open({
        message: 'Documento removido com sucesso!',
        variant: 'success',
      })
      setDialogRemoveDocument(false)
    } catch {
      snackbar.open({
        message: `Ocorreu algum erro ao remover ${documentName}!\n`,
        variant: 'error',
      })
      setDialogRemoveDocument(false)
    }
  }

  const onChangeCreate = async () => {
    const documentInput = document.getElementById('documents')
    const files = documentInput.files

    if (files.length === 0) return

    try {
      setLoading(true)
      await service.dponet.documents.addDocumentRnc({
        nonComplianceReportId: nonComplianceReportId,
        files: files,
      })

      await loadData(nonComplianceReportId)
      setLoading(false)
      snackbar.open({
        message: 'Documento(s) adicionado(s) com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro ao adicionar o(s) documento(s)',
        variant: 'error',
      })
    }
  }

  return (
    <>
      {!viewOnly && (
        <Box mt={3} mb={2}>
          {!isDpo() && (
            <form id="documents-form">
              <RHFInput
                as={
                  <Button
                    variant="outlined"
                    startIcon={<PlusCircle size={20} />}
                    htmlFor="documents"
                    component="label"
                    id={constants.incidents.EDIT_INCIDENT_DRIVER_STEP_6}
                  >
                    <Box mr={1}>
                      <Typography>Selecionar documento(s)</Typography>
                    </Box>
                    <input
                      type="file"
                      multiple
                      id="documents"
                      style={{ display: 'none' }}
                    />
                  </Button>
                }
                setValue={setValue}
                register={register}
                onChange={() => onChangeCreate()}
                name="documents"
              />
            </form>
          )}
          {!isDpo && (
            <Box mt={1}>
              <Typography variant="caption">
                Arquivos suportados: jpg, jpeg, png, pdf, docx, doc.
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer mt={10}>
          <Table size="small" emptyMessage="Nenhum documento encontrado" noWrap>
            <TableHead>
              <TableRow>
                <TableCell>Documentos</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((document, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {helpers.functions.documentName(document.url)}
                  </TableCell>
                  <TableCell align="right">
                    <Grid container item justify="flex-end">
                      <Box display="flex" justifyContent="flex-end">
                        <MenuButton>
                          <Button
                            color="secondary"
                            fullWidth
                            onClick={() =>
                              window.open(
                                `${getBaseURL('dponet')}${document.url}`,
                                '_blank',
                              )
                            }
                          >
                            Visualizar
                          </Button>
                          {!viewOnly && !isDpo() && (
                            <Button
                              color="secondary"
                              fullWidth
                              onClick={() => handleRemove(document, index)}
                            >
                              Remover
                            </Button>
                          )}
                        </MenuButton>
                      </Box>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <DialogRemoveDocument
        open={dialogRemoveDocument}
        setOpen={setDialogRemoveDocument}
        handleRemove={onClickDeleteDocument}
        document={removableDocument}
        refresh={() => loadData(nonComplianceReportId)}
      />
    </>
  )
}

export default TableDocuments
