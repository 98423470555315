import api from 'service/api'

const dponetAPI = api.create('cookies', false)

const update = async ({ bannerId, cookies }) =>
  await dponetAPI.patch(`/banners/${bannerId}/cookies`, { cookies })

export default {
  update,
}
