import React from 'react'

import { Text } from '../'
import { Box } from '@material-ui/core'

import { RoundedButton } from 'components'

import useTrailStep from 'hooks/useTrailStep'
import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'
import constants from 'constants/index'
import * as service from 'service'

const CookieConfirmation = () => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { user } = useAuth()

  const { handleNext, setHasBanner, setNextDisabled, nextDisabled } =
    useTrailStep()

  const handleHasBanner = () => {
    setHasBanner(true)
    handleNext(constants.userTrailSteps.HAS_BANNER_OBS)
  }

  const handleDoesntHasBanner = async () => {
    setHasBanner(false)
    try {
      setNextDisabled(true)
      await service.dponet.users.solicitationBannerCookies({
        userId: user.id,
      })
    } catch (error) {
      snackbar.open({
        message:
          'Houve um erro ao enviar sua solicitação, por favor, entre em contato através do e-mail atendimento@dponet.com.br',
        variant: 'error',
      })
    } finally {
      setNextDisabled(false)
      handleNext('')
    }
  }

  return (
    <>
      <Text color="primary" fontSize="7vh" variant="h1">
        Deseja adicionar banner de cookies em seu site?
      </Text>
      <Box my={4} className={classes.actionArea}>
        <RoundedButton
          disabled={nextDisabled}
          variant="outlined"
          withPadding
          onClick={handleHasBanner}
        >
          Já possuo um banner
        </RoundedButton>
        <Box ml={2}>
          <RoundedButton
            disabled={nextDisabled}
            variant="primary"
            withPadding
            onClick={handleDoesntHasBanner}
          >
            Sim
          </RoundedButton>
        </Box>
      </Box>
    </>
  )
}

export default CookieConfirmation
