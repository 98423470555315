import { isNil } from 'lodash'
const url = (url) => {
  if (isNil(url)) return

  try {
    const newUrl = new URL(url)
    return newUrl.host
  } catch {
    return url
  }
}
export default url
