const STAMP = 'Selo'
const CERTIFICATE = 'Certificado'

const CERTIFICATES = {
  FUNDAMENTAL_CONCEPTS: {
    label: 'Conceitos fundamentais da LGPD',
    status: 'Pendente',
  },
  INTRODUCTION_LGPD: {
    label: 'Introdução à LGPD',
    status: 'Pendente',
  },
}

const PENDING_STATUS = 'Pendente'
const IN_PROGRESS_STATUS = 'Em andamento'
const COMPLETED_STATUS = 'Concluído'

const trailsJourney = {
  STAMP,
  CERTIFICATES,
  CERTIFICATE,
  PENDING_STATUS,
  IN_PROGRESS_STATUS,
  COMPLETED_STATUS,
}

export default trailsJourney
