import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  Box,
  makeStyles,
  Button,
} from '@material-ui/core'

import {
  X as CloseIcon,
  CheckCircle as CheckIcon,
  AlertTriangle as Alert,
} from 'react-feather'

import useAuth from 'hooks/useAuth'

import styles from './styles'
const useStyles = makeStyles(styles)

const SuccessDialog = ({
  open,
  setOpen,
  isSupplier,
  isRegistrationInProgress,
  reload,
  title,
  subtitle,
}) => {
  const classes = useStyles()
  const { loadData } = useAuth()
  const [loading, setLoading] = useState(false)

  const closeDialog = async () => {
    setLoading(true)
    if (isSupplier) {
      await loadData()
    }
    await reload()

    setLoading(false)

    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      maxWidth="sm"
      fullWidth
      classes={
        isRegistrationInProgress
          ? { paper: classes.dialogAlert }
          : { paper: classes.dialog }
      }
    >
      <Box width="100%" display="flex" justifyContent="flex-end" mr={-2}>
        <IconButton
          size="small"
          onClick={closeDialog}
          color="primary"
          aria-label="close modal"
        >
          <CloseIcon color={isRegistrationInProgress ? 'black' : 'white'} />
        </IconButton>
      </Box>
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center" flexDirection="column">
          {isRegistrationInProgress ? (
            <Alert className={classes.colorBlack} size={40} />
          ) : (
            <CheckIcon className={classes.colorWhite} size={40} />
          )}
          <Box mt={2}>
            <Typography
              variant="h3"
              className={
                isRegistrationInProgress ? classes.h3Black : classes.h3
              }
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        <Typography
          variant="subtitle1"
          className={
            isRegistrationInProgress ? classes.colorBlack : classes.colorWhite
          }
        >
          {subtitle}
        </Typography>
      </Box>
      {isRegistrationInProgress ? (
        <Box display="flex" justifyContent="flex-start" mt={2}>
          <Button
            className={classes.backButton}
            classes={{ label: classes.buttonLabel }}
            onClick={closeDialog}
            disabled={loading}
          >
            Voltar
          </Button>
        </Box>
      ) : (
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            className={classes.button}
            onClick={closeDialog}
            variant="contained"
            disabled={loading}
          >
            Ok
          </Button>
        </Box>
      )}
    </Dialog>
  )
}

SuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  reload: PropTypes.func,
  isSupplier: PropTypes.bool,
  isRegistrationInProgress: PropTypes.bool,
}

export default SuccessDialog
