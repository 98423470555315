import constants from 'constants/index'

const statusId = (status) => {
  return constants.dataProcess.QUESTION_DRIVER_STATUS_ID[status] || ''
}

const statusSteps = (status) => {
  return constants.dataProcess.DATA_PROCESS_DRIVER_SELECTED_STEPS[status]
}

const chooseStepsObject = (step) => {
  return constants.dataProcess.CHOOSE_WHICH_NEW_DRIVER_STEP_OBJECT[step]
}

const tourId = (value) => {
  return constants.dataProcess.SUGGESTED_OR_MAIN_DRIVER_ID[value].tourId
}

const tabId = (value) => {
  return constants.dataProcess.SUGGESTED_OR_MAIN_DRIVER_ID[value].tabId
}

const driverJs = {
  statusId,
  statusSteps,
  chooseStepsObject,
  tourId,
  tabId,
}

export default driverJs
