import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))

export default styles
