import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Dialog,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { RoundedButton } from 'components'
import { X as XIcon } from 'react-feather'

import useStyles from './styles'
import theme from 'theme'

const InfoDialog = ({ open, setOpen, title, description }) => {
  const handleClose = () => setOpen(false)

  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.dialogPaper,
      }}
      disableBackdropClick
    >
      <Box m={4}>
        <Box
          display="flex"
          {...(isMobile && { flexDirection: 'column-reverse' })}
          justifyContent="space-between"
        >
          <Box mt={isMobile ? 4 : 1}>
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
          </Box>
          <IconButton
            className={classes.iconButton}
            classes={{ label: classes.closeLabel }}
            onClick={handleClose}
          >
            <XIcon size={30} />
          </IconButton>
        </Box>
        <Box display="flex" mt={2}>
          <Typography variant="h4" paragraph className={classes.description}>
            {description}
          </Typography>
        </Box>
        <Box mt={2} display="flex" alignItems="center" justifyContent="end">
          <RoundedButton variant="text" onClick={handleClose}>
            Voltar
          </RoundedButton>
        </Box>
      </Box>
    </Dialog>
  )
}

InfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  actionAcceptButton: PropTypes.func,
  description: PropTypes.string.isRequired,
  textButton: PropTypes.string,
  showWebSite: PropTypes.bool,
}

InfoDialog.defaultProps = {
  showWebSite: false,
}

export default InfoDialog
