import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'

import useAuth from 'hooks/useAuth'
import helpers from 'helpers'

import styles from './styles'

const useStyles = makeStyles(styles)

const UserBox = () => {
  const auth = useAuth()
  const classes = useStyles()

  return (
    <Box p={2} className={classes.root}>
      <Typography variant="h6" className={classes.defaultTypography}>
        {auth?.user?.name}
      </Typography>
      <Box mt={1}>
        <Typography variant="body2" className={classes.defaultTypography}>
          {auth?.company?.name ?? 'Administrador'}
        </Typography>
        <Typography variant="body2" className={classes.defaultTypography}>
          {helpers.company.documentType(auth?.company)}
        </Typography>
      </Box>
    </Box>
  )
}

export default UserBox
