import defaultTab from './defaultTab'
import getCardStyle from './getCardStyle'
import status from './status'
import statusChipColor from './statusChipColor'

export default {
  defaultTab,
  getCardStyle,
  status,
  statusChipColor,
}
