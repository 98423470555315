import lineWithDate from './lineWithDate'
import numberAndTitle from './numberAndTitle'
import numberPage from './numberPage'
import paragraph from './paragraph'
import rectangle from './rectangle'
import rowTwoColumns from './rowTwoColumns'
import subtitle from './subtitle'
import table from './table'
import text from './text'

const components = {
  lineWithDate,
  numberAndTitle,
  numberPage,
  paragraph,
  rectangle,
  rowTwoColumns,
  subtitle,
  table,
  text,
}

export default components
