import constants from 'constants/index'

const {
  COMPANY_LOGO_TAG,
  COMPANY_SITE_TAG,
  COOKIE_BANNER_TAG,
  SITE_CONFIGURATION_TAG,
  COMPANY_DOCUMENTS_TAG,
} = constants.userTrailSteps

const validRedirectionStep = (step_tag) =>
  [
    COMPANY_LOGO_TAG,
    COMPANY_SITE_TAG,
    COOKIE_BANNER_TAG,
    SITE_CONFIGURATION_TAG,
    COMPANY_DOCUMENTS_TAG,
  ].includes(step_tag)

export default validRedirectionStep
