import constants from 'constants/index'

const statusName = (status) => {
  switch (status) {
    case constants.questions.PENDING_STATUS:
      return 'Pendente'
    case constants.questions.ANSWERED_STATUS:
      return 'Respondida'
    case constants.questions.INACTIVE_STATUS:
      return 'Inativa'
    default:
      return '-'
  }
}

export default statusName
