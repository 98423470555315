import React, { useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import { isEmpty, uniqBy, map, filter } from 'lodash'

import { Switch, FormControlLabel, Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { BaseDialog, Label } from 'components'
import { MultiSelectItem } from './components'

import MultiSelect from '@kenshooui/react-multi-select'

import useCompanyFragility from 'hooks/useCompanyFragility'
import useAuth from 'hooks/useAuth'

import helpers from 'helpers'
import constants from 'constants/index'

import useStyles from './styles'

const FragilityEntitiesDialog = ({
  defaultApplyToCompany,
  open,
  setOpen,
  defaultSelected,
  loadDepartaments,
  departments,
  loading,
  setLoading,
  manageEntities,
}) => {
  const { company } = useAuth()
  const classes = useStyles()

  const defaultDepartments = useMemo(
    () =>
      uniqBy(
        helpers.fragilityEntities.loadDefaultDepartments(defaultSelected),
        'id',
      ),
    //eslint-disable-next-line
    [defaultSelected],
  )

  const defaultProcessesSelected = defaultSelected?.filter(
    (entity) =>
      entity?.entityType ===
      constants.fragilityEntities.DATA_PROCESS_ENTITY_TYPE,
  )

  const {
    companyFragility,
    fragilityEntities,
    loadProcessesOptions,
    setFragilitiesSelected,
  } = useCompanyFragility()

  const [applyToCompany, setApplyToCompany] = useState(defaultApplyToCompany)
  const [selectedDepartments, setSelectedDepartments] = useState([])

  const [processesOptions, setProcessesOptions] = useState([])
  const [allProcessesOptionsLoaded, setProcessesOptionsLoaded] = useState([])
  const [selectedProcesses, setSelectedProcesses] = useState(
    defaultProcessesSelected ?? [],
  )

  const departmentOptions =
    helpers.fragilityEntities.dataMultiOptions(departments)

  const changeDepartments = (_, value) => {
    setSelectedDepartments(value)
  }

  const multiSelectSearch = (value) => (item) => {
    return (
      String(item.label).toLowerCase().includes(value.toLowerCase()) ||
      String(item.group).toLowerCase().includes(value.toLowerCase())
    )
  }

  useEffect(() => {
    if (open) {
      loadDepartaments()
    } else {
      setSelectedDepartments(defaultDepartments)
    }
    //eslint-disable-next-line
  }, [open])

  useEffect(() => {
    if (open && !loading && !isEmpty(processesOptions)) {
      const loadDefaultProcess = () => {
        const departments = map(
          filter(defaultDepartments, [
            'entityType',
            constants.fragilityEntities.DEPARTMENT_ENTITY_TYPE,
          ]),
          'name',
        )
        const processesToSelect = filter(processesOptions, (process) =>
          departments.includes(process.group),
        )
        setSelectedProcesses(
          uniqBy([...selectedProcesses, ...processesToSelect], 'id'),
        )
      }

      loadDefaultProcess()
    }
    //eslint-disable-next-line
  }, [open, loading, processesOptions])

  useEffect(() => {
    if (open) {
      const loadProcesses = async () => {
        setLoading(true)
        if (!isEmpty(selectedDepartments)) {
          const processes = await loadProcessesOptions(
            map(selectedDepartments, 'id'),
          )

          if (!isEmpty(processes)) {
            setProcessesOptions(processes)
            setProcessesOptionsLoaded(
              uniqBy([...allProcessesOptionsLoaded, ...processes], 'id'),
            )
          }
        } else {
          setProcessesOptions(selectedProcesses)
        }

        setLoading(false)
      }

      loadProcesses()
    }
    //eslint-disable-next-line
  }, [selectedDepartments, open])

  const allProcessesSelectedByGroup = (group) => {
    const selected = filter(selectedProcesses, ['group', group])

    const options = filter(allProcessesOptionsLoaded, ['group', group])

    return selected?.length === options?.length
  }

  const selectAllByGroup = (group) => {
    if (allProcessesSelectedByGroup(group)) {
      return setSelectedProcesses(
        filter(selectedProcesses, (process) => process.group !== group),
      )
    }

    setSelectedProcesses(
      uniqBy(
        [...selectedProcesses, ...filter(processesOptions, ['group', group])],
        'id',
      ),
    )
  }

  const handleSave = async () => {
    setLoading(true)
    const selection = helpers.fragilityEntities.dataToSubmit(
      company,
      applyToCompany,
      selectedProcesses,
      allProcessesSelectedByGroup,
    )

    if (!!companyFragility.id) {
      const data = helpers.fragilityEntities.manageOptions(
        fragilityEntities,
        selection,
      )

      await manageEntities(data)
    } else {
      setFragilitiesSelected(selection)
    }
    setLoading(false)

    setOpen(false)
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title={
        companyFragility?.id
          ? 'Editar locais de ocorrência'
          : 'Adicionar local de ocorrência'
      }
      closeButtonText="Voltar"
      actionButtonText="Salvar"
      dialogSize="lg"
      actionButton={handleSave}
      fullWidth
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            onChange={(event, checked) => {
              setApplyToCompany(checked)
              return event
            }}
            label="Aplicar à toda a organização"
            labelPlacement="start"
            control={
              <Switch
                color="primary"
                defaultChecked={defaultApplyToCompany}
                checked={applyToCompany}
              />
            }
            className={classes.switchCompany}
          />
        </Grid>
        {!applyToCompany && (
          <Label
            titleClassName={classes.labelTitle}
            titleVariant="subtitle1"
            title="Selecione o departamento"
            item
            xs={12}
          >
            <Autocomplete
              getOptionLabel={(value) => value.name}
              getOptionSelected={(option, value) =>
                !value || option.id === value.id || value.id === 0
              }
              defaultValue={defaultDepartments}
              options={departments}
              onChange={changeDepartments}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              multiple
            />
          </Label>
        )}
        <Label
          titleClassName={classes.labelTitle}
          titleVariant="subtitle1"
          title={applyToCompany ? 'Departamentos' : 'Processos'}
          item
          xs={12}
        >
          <MultiSelect
            isLocked={() => applyToCompany}
            messages={helpers.dataCollected.messagesMultiSelect()}
            selectAllRenderer={({
              selectAllMessage: label,
              isAllSelected: checked,
              onClick,
            }) => (
              <MultiSelectItem
                isForeign
                applyToCompany={applyToCompany}
                item={{ id: label, label }}
                onClick={onClick}
                checked={checked}
              />
            )}
            itemRenderer={(data) => (
              <MultiSelectItem
                applyToCompany={applyToCompany}
                selectAllByGroup={selectAllByGroup}
                allProcessesSelectedByGroup={allProcessesSelectedByGroup}
                {...data}
              />
            )}
            items={applyToCompany ? departmentOptions : processesOptions}
            withGrouping={!applyToCompany}
            selectedItems={
              applyToCompany ? departmentOptions : selectedProcesses
            }
            onChange={(selected) => {
              if (!applyToCompany) {
                setSelectedProcesses(selected)
              }

              return selected
            }}
            filterFunction={multiSelectSearch}
            wrapperClassName={classes.multiSelect}
          />
        </Label>
      </Grid>
    </BaseDialog>
  )
}

FragilityEntitiesDialog.propTypes = {
  defaultApplyToCompany: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  defaultSelected: PropTypes.array,
  loadDepartaments: PropTypes.func,
  departments: PropTypes.array,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  manageEntities: PropTypes.func,
}

export default FragilityEntitiesDialog
