import * as yup from 'yup'

export const schemaUser = (phoneRequired) => {
  return yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    ...(phoneRequired && { phone: yup.string().required() }),
  })
}

export default schemaUser
