const errorMessage = (errorObject, includeKey = true) => {
  if (!errorObject) return

  var errorGeneralMessage = errorObject.message || ''
  const errors =
    errorObject.errors || errorObject?.response?.data?.error?.message

  if (typeof errors === 'string') return errors

  for (let key in errors) {
    let errorMessage = ` ${includeKey ? key + ' ' : ''}${errors[key]}`
    errorGeneralMessage = errorGeneralMessage.concat(errorMessage)
  }

  return errorGeneralMessage
}
export default errorMessage
