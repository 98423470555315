const styles = (theme) => ({
  logo: {
    width: '50%',
    height: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  logoLink: {
    display: 'flex',
    justifyContent: 'center',
  },
})

export default styles
