import React, { useState, useEffect } from 'react'
import {
  Grid,
  Box,
  Radio,
  FormControlLabel,
  Typography,
  Divider,
  FormControl,
  FormGroup,
  TextField,
} from '@material-ui/core'

import { Label, OptionCheckIncident } from 'components'

import { Controller } from 'react-hook-form'
import useFetch from 'hooks/useFetch'

import constants from 'constants/index'

import * as service from 'service'

const CommunicationHolder = ({
  control,
  setValue,
  checkedValue,
  incident,
  watch,
  setLoading,
}) => {
  const [selected, setSelected] = useState('')

  const { COMMUNICATION_HOLDER_OPTIONS } = constants.incidents

  useEffect(() => {
    if (checkedValue) {
      setSelected(checkedValue)
    }
    // eslint-disable-next-line
  }, [])

  const isChecked = (value) => selected === value

  useEffect(() => {
    setValue('holderAffectedInformed', selected)
  }, [selected, setValue])

  const { response, isLoading } = useFetch(
    service.dponet.incidents.options,
    {
      typeFields: 'how_was_incident_reported_owner',
    },
    [],
  )

  useEffect(() => {
    setLoading(isLoading)
    // eslint-disable-next-line
  }, [isLoading])

  return (
    <>
      <Grid item xs={12}>
        <Box px={2}>
          <Typography variant="h6">
            Da comunicação do incidente aos titulares dos dados
          </Typography>
        </Box>
        <Box pt={2}>
          <Divider />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Label title="Os titulares dos dados afetados foram comunicados sobre o incidente?">
            <FormControl size="small" variant="outlined">
              <FormGroup>
                {COMMUNICATION_HOLDER_OPTIONS?.map((option) => {
                  return (
                    <FormControlLabel
                      control={
                        <Controller
                          name="holderAffectedInformed"
                          onChange={() => setSelected(option?.id)}
                          as={
                            <Radio
                              size="medium"
                              checked={isChecked(option?.id)}
                            />
                          }
                          control={control}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          dangerouslySetInnerHTML={{
                            __html: option?.name,
                          }}
                        />
                      }
                      key={option?.id}
                    />
                  )
                })}
              </FormGroup>
            </FormControl>
          </Label>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Label title="Se cabível, quando os titulares serão comunicados sobre o incidente?">
            <Controller
              as={
                <TextField
                  type="text"
                  color="primary"
                  variant="outlined"
                  fullWidth
                />
              }
              control={control}
              name="holderWillCommunicateIn"
              mode="onBlur"
            />
          </Label>
        </Box>
      </Grid>
      <OptionCheckIncident
        label="De que forma a ocorrência do incidente foi comunicada aos titulares?"
        control={control}
        options={response?.data?.howWasIncidentReportedOwner}
        checkedOptions={
          incident?.dataOptionIncidents?.howWasIncidentReportedOwner
        }
        name="howWasIncidentReportedOwner"
        setValue={setValue}
        watch={watch}
        literaName="how_was_incident_reported_owner"
        other
        paddingX
      />
    </>
  )
}

export default CommunicationHolder
