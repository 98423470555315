import React, { useEffect, useState } from 'react'
import { Controller, FormContext, useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { Box, Paper, Typography } from '@material-ui/core'

import { BaseDialog, ButtonGroup } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import schema from './schema'
import * as service from 'service'

const ResponsibleForm = ({
  complaint,
  complaintId,
  refresh,
  setLoading,
  userCompanies,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [options, setOptions] = useState([])

  const snackbar = useSnackbar()
  const formMethods = useForm({
    validationSchema: schema,
    defaultValues: { responsibleId: complaint?.responsibleId || null },
  })

  const { control, handleSubmit, triggerValidation, watch } = formMethods

  const responsibleId = watch('responsibleId')

  const handleOpenModal = async () => {
    const response = await triggerValidation('responsibleId')
    if (response) setOpenModal((prevState) => !prevState)
  }

  const chooseResponsible = (id) =>
    options?.find((option) => option?.id === id)?.name

  const mountOptions = userCompanies?.map((item) => ({
    id: item?.user?.id,
    name: item?.user?.name,
  }))

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      await service.dponet.complaint.registerResponsible({
        complaintId,
        responsibleId: data?.responsibleId,
      })
      snackbar.open({
        message: 'Responsável registrado com sucesso!',
        variant: 'success',
      })
      refresh()
      setOpenModal(false)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setOptions(mountOptions || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCompanies])

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} id="responsible-form">
        <Paper
          component={Box}
          py={2}
          px={3}
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(1)}
        >
          <Typography variant="subtitle1" color="primary">
            Denúncia: {complaint?.id}
          </Typography>
          {!isEmpty(options) && (
            <Controller
              as={
                <ButtonGroup
                  options={options}
                  title="ATRIBUIR PARA"
                  controlName="responsibleId"
                  tooltipPlacement="left"
                  fullWidth
                  onClick={handleOpenModal}
                />
              }
              control={control}
              name="responsibleId"
            />
          )}
        </Paper>
        <BaseDialog
          open={openModal}
          setOpen={setOpenModal}
          title="Atribuir responsável pela denúncia"
          actionButtonText="Confirmar"
          closeButtonText="Cancelar"
          formRef="responsible-form"
        >
          <Box p={1}>
            <Typography variant="body1" color="primary">
              Tem certeza de que deseja confirmar
              <strong> {chooseResponsible(responsibleId)} </strong>
              como o responsável pela denúncia?
            </Typography>
          </Box>
        </BaseDialog>
      </form>
    </FormContext>
  )
}

ResponsibleForm.propTypes = {
  complaint: PropTypes.object,
  complaintId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  refresh: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  userCompanies: PropTypes.arrayOf(PropTypes.object),
}

export default ResponsibleForm
