import React, { useState } from 'react'
import { Button, Typography, makeStyles, Fade } from '@material-ui/core'
import styles from './styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

const IconButton = ({
  Icon,
  text,
  onClick,
  buttonDisabled = false,
  ...rest
}) => {
  const [isHovered, setHovered] = useState(false)

  const classes = useStyles()

  const handleMouseHover = () => {
    setHovered(!isHovered)
  }

  return (
    <Button
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseHover}
      className={classes.button}
      classes={{
        label: classes.buttonLabel,
      }}
      onClick={onClick}
      disableRipple
      variant="outlined"
      disabled={buttonDisabled}
    >
      {!isHovered ? (
        <Icon {...rest} className={classes.iconMargin} />
      ) : (
        <>
          <Fade in={isHovered}>
            <Icon {...rest} className={classes.iconMargin} />
          </Fade>
          <Fade in={isHovered}>
            <Typography
              variant="h6"
              color="textSecondary"
              className={classes.buttonTitle}
            >
              {text}
            </Typography>
          </Fade>
        </>
      )}
    </Button>
  )
}

IconButton.propTypes = {
  Icon: PropTypes.any,
  text: PropTypes.string,
  buttonDisabled: PropTypes.bool,
}

export default IconButton
