import { makeStyles } from '@material-ui/core'

import hexagons from 'images/hexagonos-certificado.png'

const styles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  gridContainer: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },
  },
  imgBox: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    backgroundImage: `url(${hexagons})`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      backgroundSize: '400px',
      minHeight: '70vh',
      backgroundPositionX: '-8%',
      backgroundPositionY: 'bottom',
    },
    [theme.breakpoints.only('md')]: {
      backgroundPositionX: 'left',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      backgroundPositionX: '33%',
      justifyContent: 'center',
      backgroundSize: 'contain',
    },
  },
  dataBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    [theme.breakpoints.up('md')]: {
      minHeight: '52vh',
    },
  },
  boxCertificate: {
    borderLeft: '4px solid white',
    paddingLeft: theme.spacing(1),
  },
  certificateImg: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginBottom: -45,
      paddingLeft: 2,
    },
    [theme.breakpoints.only('md')]: {
      marginLeft: 65,
    },
    [theme.breakpoints.down('sm')]: {
      objectPosition: '0 5vw',
    },
  },
}))

export default styles
