import { useContext } from 'react'

import RncActionContext from 'contexts/RncActionContext'

const useRncAction = () => {
  const context = useContext(RncActionContext)

  if (context === undefined) {
    throw new Error(
      'useRncAction must be used within a RncActionContext.Provider',
    )
  }

  return {
    data: context.data,
    loadData: context.loadData,
    setData: context.setData,
    loading: context.loading,
    setLoading: context.setLoading,
  }
}

export default useRncAction
