const mountListOfAnotherOptions = (anotherOptions) => {
  let anotherOptionsList = anotherOptions.split(',').map((val) => {
    var match = /[^\s*].*[^\s*]/g.exec(val)

    return match ? match[0] : val
  })
  return anotherOptionsList
    .filter((val) => val !== '')
    .map((val) => ({ name: val }))
}

export default mountListOfAnotherOptions
