import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import { Typography, Chip } from '@material-ui/core'

import { TableRow, TableCell } from 'components/Table'
import {
  MenuButton,
  Permitted,
  ButtonAction,
  ConfirmationDialog,
} from 'components'

import helpers from 'helpers'
import constants from 'constants/index'
import { routes } from 'Routes'
import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'

import useStyles from '../../styles'

const TableRowProfile = ({ profile, refresh, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const [openInactivateDialog, setOpenInactivateDialog] = useState(false)

  const labelToChangeStatus = profile?.status ? 'inativar' : 'ativar '

  const changeProfileStatus = async () => {
    try {
      handleActionDialog()
      await service.dponet.profiles.changeStatus(profile?.id)
      refresh()

      snackbar.open({
        message: 'Status do perfil alterado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }

  const handleActionDialog = () => {
    setOpenInactivateDialog((openInactivateDialog) => !openInactivateDialog)
  }

  return (
    <>
      <TableRow {...rest}>
        <TableCell>{profile?.id}</TableCell>
        <TableCell>
          <Typography variant="body2">{profile?.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{profile?.description}</Typography>
        </TableCell>
        <TableCell>
          <Chip
            size="small"
            className={helpers.departments.statusChipColor(
              profile?.status,
              classes,
            )}
            label={
              profile?.status === constants.profile.ACTIVE ? 'ATIVO' : 'INATIVO'
            }
          />
        </TableCell>
        <TableCell align="right">
          <Permitted
            someTags={[
              constants.permissions.PROFILES.EDIT,
              constants.permissions.PROFILES.CHANGE_STATUS,
            ]}
          >
            <MenuButton id={constants.profile.PROFILE_MAIN_DRIVER_STEP_2}>
              <Permitted tag={constants.permissions.PROFILES.EDIT}>
                <ButtonAction
                  color="secondary"
                  fullWidth
                  size="small"
                  action={() =>
                    history.push(
                      reverse(routes.profiles.edit, {
                        profileId: profile?.id,
                      }),
                    )
                  }
                  name="Editar"
                />
              </Permitted>
              <Permitted tag={constants.permissions.PROFILES.CHANGE_STATUS}>
                <ButtonAction
                  color="secondary"
                  fullWidth
                  size="small"
                  action={handleActionDialog}
                  name={labelToChangeStatus}
                />
              </Permitted>
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        open={openInactivateDialog}
        setOpen={setOpenInactivateDialog}
        text={`Você tem certeza que deseja ${labelToChangeStatus} o perfil?`}
        textButtonCancel="Cancelar"
        textButton="Confirmar"
        actionAcceptButton={changeProfileStatus}
      />
    </>
  )
}

TableRowProfile.propTypes = {
  profile: PropTypes.object,
  refresh: PropTypes.func,
}

TableRowProfile.defaultProps = {
  refresh: () => {},
}

export default TableRowProfile
