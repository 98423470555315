import React from 'react'

import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Filter as FilterProducts,
  CompaniesServiceList,
  Carousel,
  MonthProductsList,
} from './components'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import helpers from 'helpers'
import styles from './styles'
import useFilter from 'hooks/useFilter'

const useStyles = makeStyles(styles)

const ProductsAndServicesMain = () => {
  const filter = useFilter()
  const { mixpanel } = helpers

  const handleFilterChange = (category_id) => {
    filter.setFilters({ category_ids: category_id })
  }

  const filterByCategory = () => {
    const { checkedValues } = helpers.marketplace.checkedBoxes()

    mixpanel.track('Marktplace', 'Filtro', {
      action: 'Click no botão de filtrar',
    })

    filter.setFilters({
      ...filter.filters,
      category_ids: checkedValues,
    })
  }

  const classes = useStyles()

  return (
    <Page title="Marketplace - Produtos e Serviços">
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={2}>
          <Grid xs={12} item>
            <Carousel handleFilterChange={handleFilterChange} />
          </Grid>
          <Grid xs={12} item>
            <Paper className={classes.contentContainer}>
              <Box display="flex" flexDirection="column" mb={2}>
                <Box pb={2}>
                  <Typography variant="h3">Destaques do mês</Typography>
                </Box>
                <MonthProductsList handleFilterChange={handleFilterChange} />
              </Box>
              <Grid container spacing={4}>
                <Grid item xs={12} md={5} lg={4} xl={3}>
                  <Typography variant="h4" gutterBottom>
                    Pesquisar
                  </Typography>
                  <FilterProducts
                    filterByCategory={filterByCategory}
                    filter={filter}
                  />
                </Grid>
                <Grid item xs={12} md={7} lg={8} xl={9}>
                  <Typography variant="h4" gutterBottom>
                    Resultados dos Serviços
                  </Typography>
                  <CompaniesServiceList filter={filter} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default ProductsAndServicesMain
