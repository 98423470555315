import React, { useState } from 'react'

import propTypes from 'prop-types'

import {
  Box,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  RadioGroup,
  Radio,
} from '@material-ui/core'

import { Database as DatabaseIcon } from 'react-feather'

import { ConfirmationDialog } from 'components'

import helpers from 'helpers'

const ListItemCustom = ({
  itemId,
  unnecessaryData,
  classes,
  dataType,
  isEditableByFinality,
  unnecessaryDataValues,
  setUnnecessaryDataValues,
  setButtonIsDisabled,
  countUnnecessary,
}) => {
  const [dialogOpened, setDialogOpened] = useState(false)
  const [radioValue, setRadioValue] = useState()
  const [dataText, setDataText] = useState({})

  const handleChange = (e) => {
    setRadioValue(e.target.value)
    setDataText(
      helpers.nonComplianceReports.eliminateNotEliminateTexts(
        dataType,
        e.target.value,
      ),
    )
    setDialogOpened(true)
  }

  const submit = () => {
    const newData = unnecessaryDataValues
    newData[itemId.toString()] = radioValue
    setUnnecessaryDataValues(newData)
    setButtonIsDisabled(countUnnecessary !== Object.values(newData).length || 0)
    setDialogOpened(false)
  }

  return (
    <ListItem divider>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={isEditableByFinality ? 8 : 12}
          lg={isEditableByFinality ? 8 : 12}
        >
          <Box display="flex">
            <ListItemAvatar>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.avatarContainer}
              >
                <DatabaseIcon className={classes.iconStyle} />
              </Box>
            </ListItemAvatar>
            <Box>
              <ListItemText primary={unnecessaryData?.dataName} />
              <ListItemText
                primary={helpers.nonComplianceReports.eliminateNotEliminateSubtitleMessages(
                  dataType,
                  unnecessaryDataValues[itemId],
                  unnecessaryData?.status,
                )}
                primaryTypographyProps={{
                  color: 'secondary',
                }}
              />
            </Box>
          </Box>
        </Grid>
        {isEditableByFinality && (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className={classes.radioGroupContainer}
          >
            <RadioGroup
              row
              aria-label="position"
              name="position"
              className={classes.radioGroup}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Necessary"
                control={
                  <Radio
                    checked={unnecessaryDataValues[itemId] === 'Necessary'}
                    classes={{ checked: classes.checkedNotEliminate }}
                  />
                }
                label="Não Eliminar"
                labelPlacement="end"
                {...(unnecessaryDataValues[itemId] === 'Necessary' && {
                  classes: { label: classes.checkedNotEliminate },
                })}
              />
              <FormControlLabel
                value="Eliminated"
                control={
                  <Radio
                    checked={unnecessaryDataValues[itemId] === 'Eliminated'}
                    classes={{ checked: classes.checkedEliminate }}
                  />
                }
                label="Eliminar"
                labelPlacement="end"
                {...(unnecessaryDataValues[itemId] === 'Eliminated' && {
                  classes: { label: classes.checkedEliminate },
                })}
              />
            </RadioGroup>
          </Grid>
        )}
      </Grid>
      {isEditableByFinality && (
        <ConfirmationDialog
          open={dialogOpened}
          setOpen={setDialogOpened}
          actionAcceptButton={submit}
          text={dataText?.text}
          textButton={dataText?.textButton}
        />
      )}
    </ListItem>
  )
}

ListItemCustom.propTypes = {
  dataType: propTypes.oneOf(['collectedField', 'dataTreatment', 'lifeCycle']),
}

export default ListItemCustom
