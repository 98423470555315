import React from 'react'
import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'
import useDataProcess from 'hooks/useDataProcess'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

import Form from './components/Form'

import helpers from 'helpers'
import * as service from 'service'

const DialogDataCollected = ({
  dataProcessId,
  open,
  setLoading = () => {},
  setOpen = () => {},
  onEvent = () => {},
  ...props
}) => {
  const { reloadOptions } = useDataProcess()
  const snackbar = useSnackbar()

  const edition = props.dataCollected && props.dataCollected.id ? true : false

  const onSubmit = (dataCollecteds, template) => {
    if (dataCollecteds.dataCollectedMultiSelect) {
      dataCollecteds.dataCollectedOptionsIds =
        helpers.dataCollected.mountDataCollectedOptionsIDs(
          dataCollecteds.dataCollectedMultiSelect,
        )
    }

    if (dataCollecteds.anotherPersonalNames) {
      dataCollecteds.anotherPersonalNames =
        helpers.dataCollected.mountListOfAnotherOptions(
          dataCollecteds.anotherPersonalNames,
        )
    }

    if (dataCollecteds.anotherFinancialNames) {
      dataCollecteds.anotherFinancialNames =
        helpers.dataCollected.mountListOfAnotherOptions(
          dataCollecteds.anotherFinancialNames,
        )
    }

    if (dataCollecteds.anotherComportamentalNames) {
      dataCollecteds.anotherComportamentalNames =
        helpers.dataCollected.mountListOfAnotherOptions(
          dataCollecteds.anotherComportamentalNames,
        )
    }

    dataCollecteds.possibleMinor = dataCollecteds.possibleMinor === 'true'

    if (dataCollectedsIsBlank(dataCollecteds)) {
      snackbar.open({
        message:
          'Por favor selecione ao menos um dado tratado para o tratamento.',
        variant: 'error',
      })
      return
    }

    if (props.dataCollected && props.dataCollected.id) {
      handleEdit(dataCollecteds, template)
    } else {
      handleCreate(dataCollecteds, template)
    }
  }

  const dataCollectedsIsBlank = (dataCollecteds) => {
    return (
      !dataCollecteds.anotherComportamentalNames.length &&
      !dataCollecteds.anotherPersonalNames.length &&
      !dataCollecteds.anotherFinancialNames.length &&
      !dataCollecteds.dataCollectedOptionsIds.length
    )
  }

  const handleCreate = async (dataCollecteds, template) => {
    try {
      setLoading(true)
      setOpen(false)
      await service.dponet.dataCollecteds.create({
        dataProcessId: dataProcessId,
        dataCollecteds: {
          ...dataCollecteds,
          dataProcessTemplateQuestions: template,
        },
      })
      onEvent()
      reloadOptions()
      setLoading(false)
      snackbar.open({
        message: 'Dado tratado criado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      setOpen(false)
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error.response && error.response.data && error.response.data.error,
        ),
        variant: 'error',
      })
    }
  }

  const handleEdit = async (dataCollecteds, template) => {
    try {
      setLoading(true)
      setOpen(false)
      await service.dponet.dataCollecteds.put({
        dataProcessId: dataProcessId,
        dataCollectedId: props?.dataCollected?.id,
        dataCollecteds: {
          ...dataCollecteds,
          dataProcessTemplateQuestions: template,
        },
      })
      onEvent()
      reloadOptions()
      setLoading(false)
      snackbar.open({
        message: 'Dado tratado atualizado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      setOpen(false)
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error.response && error.response.data && error.response.data.error,
        ),
        variant: 'error',
      })
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">
            {edition ? 'Editar' : 'Adicionar'} dado(s) tratado(s)
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Form onSubmit={onSubmit} dataCollected={props.dataCollected} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          form="data-collected-form"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogDataCollected.propTypes = {
  dataProcessId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
  onEvent: PropTypes.func,
}

export default DialogDataCollected
