import { inRange, orderBy } from 'lodash'

const getSupplierByCompliance = (
  supplierStats,
  complianceMin,
  complianceMax,
  order = 'desc',
) => {
  const suppliers = supplierStats?.suppliers?.filter((supplier) =>
    inRange(supplier?.compliance, complianceMin, complianceMax),
  )
  return orderBy(suppliers, ['compliance'], [order])
}

export default getSupplierByCompliance
