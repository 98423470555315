import React from 'react'

import { Box, Typography, makeStyles } from '@material-ui/core'

import { AlertCircle as AlertCircleIcon } from 'react-feather'

import styles from './styles'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const CompanyAuditAlert = (companyAuditEndDate) => {
  const classes = useStyles()

  const endDate = new Date(companyAuditEndDate.companyAuditEndDate)

  return (
    <Box className={classes.alertBox}>
      <Box className={classes.items}>
        <AlertCircleIcon className={classes.icon} />
        <Typography variant="subtitle2" className={classes.text}>
          Faltam apenas {helpers.functions.daysTillOtherDate(endDate)} dias para
          realizar a auditoria de processos
        </Typography>
      </Box>
    </Box>
  )
}

export default CompanyAuditAlert
