import React from 'react'

import { DialogLayout } from 'components'

import { Box, Typography } from '@material-ui/core'

import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

const DialogRefuseSuggested = ({
  refuseDialog,
  dataProcess,
  redirectTo,
  setRefuseDialog = () => {},
  setIsLoading = () => {},
  onEvent = () => {},
}) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const refuseType = refuseDialog?.type === 'refuse'

  const setOpen = (open) => {
    setRefuseDialog({ ...refuseDialog, open })
  }

  const onSubmit = async () => {
    const submitAction = refuseType
      ? service.dponet.dataProcesses.refuseSuggestion
      : service.dponet.dataProcesses.discard

    try {
      await submitAction({
        dataProcessId: dataProcess.id,
      })
      snackbar.open({
        message: `Processo ${
          refuseType ? 'recusado' : 'excluído'
        } com sucesso!`,
        variant: 'success',
      })
      if (refuseType) {
        setIsLoading(true)
        return history.push(
          reverse('/data-processes/suggested?tab=dataProcessesDiscarded'),
        )
      }
      if (redirectTo) {
        return history.push(redirectTo)
      }
      onEvent()
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message: `Erro ao ${
          refuseType ? 'recusar sugestão de' : 'excluir o'
        } processamento de dados`,
        variant: 'error',
      })
      setOpen(false)
    }
  }

  return (
    <DialogLayout
      open={refuseDialog?.open}
      setOpen={setOpen}
      title={refuseType ? 'Recusar processo sugerido' : 'Excluir processo'}
      confirmTextButton="Confirmar"
      handleSubmit={onSubmit}
    >
      <Box display="flex" flexDirection="column">
        <Typography>{refuseDialog?.message}</Typography>
      </Box>
    </DialogLayout>
  )
}

export default DialogRefuseSuggested
