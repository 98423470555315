import React, { useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Switch,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { MenuButton } from 'components'

import { ChevronDown, ChevronUp } from 'react-feather'
import { Controller } from 'react-hook-form'

import styles from '../../styles'

const useStyles = makeStyles(styles)

const SelectedItem = ({
  item,
  order,
  control,
  watch,
  handleSwitchChange,
  handleUp,
  handleDown,
  handleOpenDialog,
  selectedItems,
  handleFieldDestroy,
}) => {
  const classes = useStyles()
  const [selected, setSelected] = useState(!!item?.required)

  const handleSelect = (e) => {
    setSelected(e.target.checked)
    return handleSwitchChange(e, item, order)
  }

  return (
    <Box
      margin={1}
      padding={1}
      className={classes.attributeBox}
      key={item?.name}
    >
      <Box>
        <Typography variant="subtitle2">{item?.name}</Typography>
        <Typography variant="caption">{item?.description}</Typography>
        <Box>
          <Controller
            control={control}
            name={item?.name}
            defaultValue={{
              subtitle: item?.description,
              required: !!item?.required,
              order,
            }}
            as={
              <Box display="flex" alignItems="center">
                <Switch
                  key={item.name}
                  color="primary"
                  checked={selected ?? watch(item?.name)?.required}
                />
                <Typography>Obrigatório</Typography>
              </Box>
            }
            onChange={(event) => handleSelect(event[0])}
            mode="onChange"
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" pr={1.5}>
        <Box
          width="50%"
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <IconButton
            disabled={order === 0}
            size="small"
            onClick={() => handleUp(order, selectedItems)}
          >
            <ChevronUp size={16} />
          </IconButton>
          <IconButton
            disabled={order === selectedItems.length - 1}
            size="small"
            onClick={() => handleDown(order, selectedItems)}
          >
            <ChevronDown size={16} />
          </IconButton>
        </Box>
        <Box width="50%">
          <MenuButton>
            <Button
              color="secondary"
              fullWidth
              size="small"
              onClick={() => handleOpenDialog(item)}
            >
              Editar
            </Button>
            <Button
              color="secondary"
              fullWidth
              size="small"
              onClick={() => handleFieldDestroy(item)}
            >
              Excluir
            </Button>
          </MenuButton>
        </Box>
      </Box>
    </Box>
  )
}

export default SelectedItem
