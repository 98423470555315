import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import clsx from 'clsx'

import useFetch from 'hooks/useFetch'
import useAuth from 'hooks/useAuth'
import usePermissions from 'hooks/usePermissions'

import { Box, Container, Button, Tooltip } from '@material-ui/core'
import { Page, ContentHeader, Permitted, DriveTour } from 'components'
import ResumeCard from './components/ResumeCard'

import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'
import { routes } from 'Routes'
import useStyles from './styles'

const DataProcessesResume = () => {
  const classes = useStyles()
  const history = useHistory()
  const { company } = useAuth()
  const permissions = usePermissions()

  const [openTour, setOpenTour] = useState(false)

  const { response, isLoading } = useFetch(service.dponet.resume.departments, {
    companyId: company && company.id,
  })

  const navigateTo = (route) => {
    history.push(route)
  }

  const hasCreatePermission = permissions.permitted(
    constants.permissions.DATA_PROCESSES.CREATE,
  )

  const validationSteps =
    constants.dataProcess.DATA_PROCESS_RESUME_DRIVER_STEPS_OBJECT

  const handleOpenTour = () => {
    const where = 'Resumo de processos'
    const title = 'Tour'

    const params = {
      action: 'tour-resumo-processos',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Resumo de processos">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Resumo">
          <Box
            className={clsx(
              classes.contentHeader,
              classes.contentHeaderAdjustment,
            )}
          >
            <Tooltip
              title={
                hasCreatePermission
                  ? 'Iniciar o tour guiado'
                  : 'O tour guiado é destinado aos usuários com permissão para gerenciar processos'
              }
            >
              <Box
                className={clsx(
                  classes.contentHeaderBox,
                  classes.contentHeaderBoxSm,
                )}
              >
                <Button
                  id={constants.dataProcess.DATA_PROCESS_RESUME_DRIVER_STEP_1}
                  variant="contained"
                  color="primary"
                  startIcon={<HelpCircleIcon size={20} />}
                  onClick={handleOpenTour}
                  disabled={!hasCreatePermission}
                  fullWidth
                >
                  Tutorial
                </Button>
              </Box>
            </Tooltip>
            <Permitted tag="create_data_process">
              <Box
                className={clsx(
                  classes.contentHeaderBox,
                  classes.contentHeaderBoxSm,
                )}
              >
                <Button
                  id={constants.dataProcess.DATA_PROCESS_RESUME_DRIVER_STEP_2}
                  variant="contained"
                  color="primary"
                  onClick={() => navigateTo(routes.dataProcess.new)}
                  fullWidth
                >
                  NOVO PROCESSO
                </Button>
              </Box>
            </Permitted>
          </Box>
        </ContentHeader>
        <Box display="flex" width="100%" mt={5}>
          <ResumeCard
            isLoading={isLoading}
            resumes={response?.data?.resumeDepartament}
          />
        </Box>
      </Container>
      <DriveTour
        stepsMatrix={validationSteps}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default DataProcessesResume
