import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { BaseConfirmationDialog } from 'components'

import constants from 'constants/index'

const ConfirmationDialog = ({
  open,
  setOpen,
  title,
  subtitle,
  handleConfirm,
  callback,
  actionType,
  isSupplier,
  supplier,
  isInactive,
}) => {
  const [loading, setLoading] = useState(false)
  const isBlueBox = [
    constants.companySuppliers.APPROVE_ACTION,
    constants.companySuppliers.CREATE_ACTION,
  ].includes(actionType)

  const mountTitle = () => {
    switch (actionType) {
      case constants.companySuppliers.APPROVE_ACTION:
        return 'Vinculado!'
      default:
        return 'Salvo'
    }
  }

  const mountSubtitle = () => {
    switch (actionType) {
      case constants.companySuppliers.APPROVE_ACTION:
        return `Você vinculou sua empresa com a empresa ${supplier?.company?.name}. Responda o questionário caso você não tenha respondido`
      case constants.companySuppliers.REFUSE_ACTION:
        return `Você rejeitou o vínculo com a empresa!`
      case constants.companySuppliers.DESTROY_ACTION:
        return `Você removeu o vínculo com a empresa!`
      default:
        return `Você inativou o vínculo com ${
          isSupplier ? 'a empresa' : 'o fornecedor'
        }!`
    }
  }

  const closeDialog = async () => {
    setLoading(false)
    setOpen(false)
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await handleConfirm()

      callback({
        title: mountTitle(),
        subtitle: mountSubtitle(),
        isInactive,
      })
      closeDialog()
    } catch {
      closeDialog()
    }
  }

  return (
    <BaseConfirmationDialog
      open={open}
      title={title}
      text={subtitle}
      actionButtonText={isBlueBox ? 'Salvar e continuar' : 'Confirmar'}
      variant={isBlueBox ? 'blueConfirmation' : 'warning'}
      actionButton={handleSubmit}
      setOpen={setOpen}
      dialogSize="sm"
      loading={loading}
      actionSupplier={true}
      supplierStatus={supplier?.status}
    />
  )
}

ConfirmationDialog.defaultProps = {
  actionType: constants.companySuppliers.INACTIVATE_ACTION,
  callback: () => {},
  isSupplier: false,
  isInactive: false,
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  supplier: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  handleConfirm: PropTypes.func,
  callback: PropTypes.func,
  isSupplier: PropTypes.bool,
  actionType: PropTypes.oneOf([
    constants.companySuppliers.CREATE_ACTION,
    constants.companySuppliers.INACTIVATE_ACTION,
    constants.companySuppliers.APPROVE_ACTION,
    constants.companySuppliers.REFUSE_ACTION,
    constants.companySuppliers.DESTROY_ACTION,
  ]),
  isInactive: PropTypes.bool,
}

export default ConfirmationDialog
