import dataAnonimazationCover from 'images/data-anonimizacao-cover.png'
import protecaoIcon from 'images/antivirus-protection.png'
import reducaoIcon from 'images/pentest-web-app.png'
import privacidadeIcon from 'images/safepassword-lock.png'
import benefitProtecaoEmpresasImage from 'images/cyber-benefit-protecao-empresa.png'
import conformidadeIcon from 'images/antivirus-law.png'
import ecoIcon from 'images/eco.png'

const antivirusIcons = [
  {
    title: 'Redução de Riscos',
    altText: 'Redução de Riscos',
    src: reducaoIcon,
  },
  {
    title: 'Descarte seguro',
    altText: 'Descarte seguro',
    src: protecaoIcon,
  },
  {
    title: 'Privacidade Preservada',
    altText: 'Privacidade Preservada',
    src: privacidadeIcon,
  },
]

const eletronicsDisposal = {
  id: 26,
  sectionTitle: 'Descarte de equipamentos eletrônicos',
  description:
    'Empresas especializadas oferecem serviços de destruição segura de dados e reciclagem responsável de equipamentos eletrônicos, garantindo conformidade com regulamentações de proteção de dados e minimizando riscos de vazamento de informações sensíveis. Essa prática essencial não só protege a privacidade dos clientes e funcionários, mas também demonstra responsabilidade ambiental, contribuindo para um ciclo sustentável de consumo e descarte de tecnologia.',
  whatIs: {
    primaryText: 'O que é o descarte de equipamentos eletrônicos?',
    text: 'O serviço de descarte consciente de equipamentos eletrônicos para a LGPD garante a destruição segura de dados pessoais, em conformidade com a legislação, protegendo a privacidade e evitando vazamentos.',
    icons: antivirusIcons,
  },
  forWho: {
    text: 'Empresas de todos os setores, especialmente aquelas que lidam com dados sensíveis de clientes e funcionários, como bancos, hospitais, escritórios de advocacia e empresas de tecnologia, podem se interessar pelo serviço de descarte consciente de equipamentos eletrônicos. Contratar esse serviço é crucial para garantir a conformidade com regulamentações como a LGPD, evitando multas e danos à reputação. Além disso, proporciona segurança na proteção de dados, reduzindo o risco de vazamentos e violações de privacidade. A reciclagem responsável também promove a sustentabilidade ambiental, demonstrando responsabilidade corporativa.',
    cover: dataAnonimazationCover,
    coverText: 'Imagem de uma mão segurando uma lapa através de um escudo',
    benefitsList: [
      ' Conformidade legal',
      'Segurança de dados',
      'Sustentabilidade ambiental',
    ],
  },
  benefits: [
    {
      title: 'Conformidade legal',
      altText: 'Conformidade e Regulamentação',
      description:
        'Evite multas e penalidades se mantendo dentro da conformidade legal',
      icon: conformidadeIcon,
    },
    {
      title: 'Segurança de dados',
      altText: 'Proteção para empresas de todos os portes',
      description:
        'Protege informações confidenciais contra vazamentos e uso indevido',
      icon: benefitProtecaoEmpresasImage,
    },
    {
      title: 'Sustentabilidade ambiental',
      altText: 'Sustentabilidade ambiental',
      description: 'Contribui para a redução do impacto ambiental.',
      icon: ecoIcon,
    },
  ],
}

export default eletronicsDisposal
