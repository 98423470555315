import api from 'service/api'

const marketPlaceAPI = api.create('marketPlace', false)

const get = async () => {
  return await marketPlaceAPI.get('/showcases/redirect_links')
}

const redirectLinks = {
  get,
}

export default redirectLinks
