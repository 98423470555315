import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  DialogActions,
  Button,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const DialogUpdateDpoAlert = ({
  open = false,
  handleClose = () => {},
  handleSubmit = () => {},
}) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle>
        <Box>
          <Typography variant="h4">
            Tem certeza que deseja alterar o DPO responsável?
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Alert severity="warning">
          <Typography variant="body1">
            Essa ação irá alterar o DPO responsável pela organização.
          </Typography>
        </Alert>
        <br />
        <Typography variant="body1">
          A nomeação de um novo DPO está atrelada à requisitos legais, afetando
          a responsabilidade perante à LGPD e a ANPD, o relacionamento com
          diretoria e líderes, as avaliações de riscos de dados e a revisão de
          políticas de privacidade.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" width="100%" gridGap={6}>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={handleClose}
          >
            CANCELAR
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleSubmit}
          >
            CONFIRMAR
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default DialogUpdateDpoAlert
