import moment from 'moment'

const mountStepOpportunity = (data) => {
  data.incidentAware = data.incidentAware
    ? moment(data?.incidentAware).format('DD/MM/YYYY')
    : ''
  data.occurred = data.occurred
    ? moment(data?.occurred).format('DD/MM/YYYY')
    : ''

  data.anpdNotificationDate = data.anpdNotificationDate
    ? moment(data?.anpdNotificationDate).format('DD/MM/YYYY')
    : ''

  data.informedHolder = data.informedHolder
    ? moment(data?.informedHolder).format('DD/MM/YYYY')
    : ''

  return {
    ...data,
  }
}

export default mountStepOpportunity
