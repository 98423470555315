import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  horizontalStep: {
    padding: 0,
  },
  stepperLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
    color: `${theme.colors.firstStep.text.secondary} !important`,
  },
}))

export default styles
