import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
} from '@material-ui/core'

import { NotificationsTable } from './components'

const DialogHistoryNotifications = ({
  notifications,
  open,
  setOpen = () => {},
}) => {
  const handleClose = () => setOpen(false)

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h4">Histórico de notificações</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {!notifications ? (
          <Typography>
            Não existe nenhum histórico de notificações para este registro.
          </Typography>
        ) : (
          <NotificationsTable notifications={notifications} />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button type="button" variant="outlined" onClick={handleClose}>
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogHistoryNotifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}

export default DialogHistoryNotifications
