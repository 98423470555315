import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'

import { StatusesByProcessGraphic } from 'components'

import { ProcessCards, ProcessRiskTable } from './components'

import constants from 'constants/index'
import useStyles from './styles'

const DashboardProcess = ({ processStats }) => {
  const classes = useStyles()
  const { process: processIds } = constants.myLgpd.GRAPH_IDS

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" color="primary">
          Processos
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} className={classes.processItems}>
            <Grid
              container
              alignItems="stretch"
              className={classes.processContainer}
            >
              <Grid item xs={12}>
                <ProcessCards
                  processStats={processStats}
                  id={processIds.processCards}
                />
              </Grid>
              <Grid item xs={12} className={classes.processRiskTable}>
                <ProcessRiskTable
                  processStats={processStats}
                  id={processIds.riskTable}
                  width="100%"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <StatusesByProcessGraphic
              processStats={processStats}
              id={processIds.statuses}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

DashboardProcess.propTypes = {
  processStats: PropTypes.object.isRequired,
}

export default DashboardProcess
