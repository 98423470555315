import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'

import logo from 'images/dponet-logo-blue.png'

import * as html2canvas from 'html2canvas'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import constants from 'constants/index'

const ExportPdf = ({ setExportingPdf, ...rest }) => {
  const { general, process, nonComplianceReports, gap, suppliers } =
    constants.myLgpd.GRAPH_IDS

  const auth = useAuth()
  const snackbar = useSnackbar()

  const exportPdf = async () => {
    const yDefault = 25

    var page = 1

    const dateNow = helpers.formatters.date(Date.now())
    const spacing = 8
    const pdf = new jsPDF({
      orientation: 'portrait',
      format: 'A4',
    })
    const width = pdf.internal.pageSize.width
    const height = pdf.internal.pageSize.height
    const centerX = width / 2
    const centerY = height / 2
    const imageScale = 1.8

    try {
      pdf.setFontSize(12)
      addCenterText(page.toString(), height - spacing / 2)

      pdf.addImage(logo, 'png', centerX - spacing * 2.6, spacing, 45, 14)

      pdf.setFont(undefined, 'bold')
      pdf.setFontSize(16)

      addCenterText(auth.company?.name, centerY - spacing * 12)
      addCenterText('Metadados', centerY - spacing * 9)

      pdf.setFontSize(14)
      addCenterText('ID', centerY - spacing * 7)
      pdf.setFont(undefined, 'normal')
      pdf.setFontSize(12)
      addCenterText(Date.now().toString(), centerY - spacing * 6)

      pdf.setFont(undefined, 'bold')
      pdf.setFontSize(14)
      addCenterText('Título', centerY - spacing * 4)
      pdf.setFont(undefined, 'normal')
      pdf.setFontSize(12)
      addCenterText('Minha LGPD Resumo', centerY - spacing * 3)

      pdf.setFont(undefined, 'bold')
      pdf.setFontSize(14)
      addCenterText('Autor', centerY - spacing * 1)
      pdf.setFont(undefined, 'normal')
      pdf.setFontSize(12)
      addCenterText('DPOnet', centerY)

      pdf.setFont(undefined, 'bold')
      pdf.setFontSize(14)
      addCenterText('Local da Digitalização e Data', centerY - spacing * -2)
      pdf.setFont(undefined, 'normal')
      pdf.setFontSize(12)
      addCenterText(`Pompéia - ${dateNow} `, centerY - spacing * -3)

      pdf.setFont(undefined, 'bold')
      pdf.setFontSize(14)
      addCenterText('Tipo Documental', centerY - spacing * -5)
      pdf.setFont(undefined, 'normal')
      pdf.setFontSize(12)
      addCenterText('Relatório', centerY - spacing * -6)

      let toExport = [
        {
          ids: Object.values(general),
        },
        {
          label: 'Processos',
          ids: Object.values(process),
        },
        {
          label: 'Registro de melhoria contínua (RMC)',
          ids: Object.values(nonComplianceReports),
        },
        {
          label: 'Fornecedores',
          ids: Object.values(suppliers),
        },
        {
          label: 'Questionários',
          ids: Object.values(gap),
        },
      ]

      addPage()
      var y = yDefault

      for (let chart of toExport) {
        if (!!chart.label) {
          y += spacing * 2
          addCenterText(chart?.label, y)
        }

        y += spacing
        for (let chartId of chart.ids) {
          const imgData = await convertObjectToImage(chartId)
          if (imgData) {
            const imgProps = pdf.getImageProperties(imgData)
            const imageHeight =
              (imgProps.height * width) / imgProps.width / imageScale
            if (y > yDefault && y + imageHeight > height - spacing) {
              addPage()
              y = yDefault
            }
            addImage(imgData, '', y)

            y += imageHeight + spacing
          }
        }
      }

      pdf.save(
        `resumo_minha_lgpd_${auth.company?.name
          ?.split(' ')
          ?.join('_')
          ?.toLowerCase()}_${dateNow}.pdf`,
      )

      setExportingPdf(false)

      snackbar.open({
        variant: 'success',
        message: 'Relatório gerado com sucesso, aguarde o download começar',
      })
    } catch {
      setExportingPdf(false)
      snackbar.open({
        variant: 'error',
        message: 'Ocorreu um erro ao gerar o relatório',
      })
    }

    function addPage() {
      pdf.addPage()
      page += 1
      pdf.setFontSize(12)
      pdf.text(page.toString(), centerX, height - spacing / 2)
      pdf.setFontSize(16)
      pdf.addImage(logo, 'png', width - 30, height - spacing * 1.4, 27, 9)
    }

    function addImage(imgData, title, positionY) {
      const imgProps = pdf.getImageProperties(imgData)
      const pdfHeight = (imgProps.height * width) / imgProps.width
      const imageWidth = width / imageScale
      const imageHeight = pdfHeight / imageScale

      if (title) {
        var titleSplitted = pdf.splitTextToSize(title, width)
        addCenterText(titleSplitted, positionY - spacing / 2)
      }

      const marginX = (width - imageWidth) / 2
      pdf.addImage(imgData, 'JPEG', marginX, positionY, imageWidth, imageHeight)
    }

    function addCenterText(text, positionY) {
      pdf.text(text, centerX, positionY, null, null, 'center')
    }

    async function convertObjectToImage(objectId) {
      const input = document.getElementById(objectId)
      if (input) {
        const canvas = await html2canvas(input, 1.0)
        return canvas.toDataURL('image/png')
      }
    }
  }

  const exportTimeOut = () => {
    setExportingPdf(true)
    setTimeout(exportPdf, 1000)
  }

  return (
    <Button onClick={exportTimeOut} {...rest}>
      Exportar Dashboard
    </Button>
  )
}

ExportPdf.propTypes = {
  setExportingPdf: PropTypes.func,
}

ExportPdf.defaultProps = {
  setExportingPdf: () => {},
}

export default ExportPdf
