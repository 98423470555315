import React, { useEffect } from 'react'

import { Box } from '@material-ui/core'
import { Text } from '..'
import { StatusIcon } from './components'

import useFirstStep from 'hooks/useFirstStep'

import constants from 'constants/index'
import useStyles from './styles'

const ConclusionPage = () => {
  const classes = useStyles()
  const { setBackgroundKind, setNextDisabled } = useFirstStep()

  useEffect(() => {
    setBackgroundKind('topLeft')
    setNextDisabled(true)
    const timeoutId = setTimeout(
      () => setNextDisabled(false),
      [constants.userSteps.CONCLUSION_ITEMS.length * 1050],
    )

    return () => {
      setBackgroundKind(null)
      clearTimeout(timeoutId)
    }
    //eslint-disable-next-line
  }, [])

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box className={classes.textBox}>
        <Text color="primary" fontSize="7vh" variant="h1" gutterBottom>
          ESTAMOS CONFIGURANDO TUDO PARA VOCÊ
        </Text>
      </Box>
      <Box className={classes.itemsBox}>
        {constants.userSteps.CONCLUSION_ITEMS.map((item, index) => (
          <StatusIcon label={item} key={item} time={(index + 1) * 1000} />
        ))}
      </Box>
    </Box>
  )
}

export default ConclusionPage
