import React from 'react'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { CheckCircle as CheckCircleIcon } from 'react-feather'

import { Box, Typography, Hidden } from '@material-ui/core'
import { ScreenLoading } from 'layouts/Main/components'

import useAuth from 'hooks/useAuth'
import useSubscription from 'hooks/useSubscription'

import logoDponetWhite from 'images/dponet-logo-white.svg'
import useStyles from './styles'

const Checkout = ({ children }) => {
  const { isTrialSubscription } = useSubscription()

  const classes = useStyles()
  const auth = useAuth()

  const trialTexts = [
    'Ficar em dia com conformidade da LGPD;',
    'Gerar documentos e relatórios;',
    'Ter um canal de comunicação com titulares e ANPD;',
    'Ter suporte especializado;',
    'Evitar multas e sanções.',
  ]

  return (
    <ScreenLoading loaded={auth.loaded}>
      <Box component="main" className={classes.root}>
        <Box className={classes.wrapper}>
          <PerfectScrollbar>{children}</PerfectScrollbar>
        </Box>
        <Box>
          <Box className={classes.rightBanner}>
            <Box className={classes.image}>
              <img
                src={logoDponetWhite}
                alt="Logo DPOnet Branca"
                className={classes.dponetLogo}
              />
            </Box>
            {isTrialSubscription && (
              <Hidden smDown>
                <Box pl={15} mt={5}>
                  {trialTexts.map((text, index) => (
                    <Box display="flex" alignItems="center">
                      <Box color="white" mb={-2.5} mr={1}>
                        <CheckCircleIcon size={24} />
                      </Box>
                      <Typography key={index} className={classes.trailLabel}>
                        {text}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Hidden>
            )}
          </Box>
        </Box>
      </Box>
    </ScreenLoading>
  )
}

export default Checkout
