import { isEmpty } from 'lodash'

import components from '../../components'

import palette from 'theme/palette'

import { WIDTH as WIDTHPdf, GAP, PADDING } from '../../constants'

const takeRisks = (pdf, date, securityMeasuresData) => {
  const WIDTH = WIDTHPdf(pdf) - PADDING * 2

  const columStyles = {
    0: {
      halign: 'left',
    },
  }

  const plansColumStyles = {
    0: {
      cellWidth: WIDTH * 0.3,
      halign: 'left',
      font: 'Roboto-Bold',
    },
  }

  const mountedSecurityMeasures = {}

  securityMeasuresData.forEach((securityMeasure) => {
    const { questionnaireKind } = securityMeasure
    if (!mountedSecurityMeasures[questionnaireKind]) {
      mountedSecurityMeasures[questionnaireKind] = []
    }
    mountedSecurityMeasures[questionnaireKind].push(securityMeasure)
  })

  const generateTakeRiskRows = (risk) => [
    ['Status', 'Risco Assumido'],
    ['Responsável', risk.responsibleName || 'N/A'],
    ['Data', risk.acceptDate || 'N/A'],
    ['Justificativa', risk.description || 'N/A'],
  ]

  const securityMeasureKeys = Object.keys(mountedSecurityMeasures)

  pdf.addPage()
  components.numberPage(pdf, palette.black)
  components.lineWithDate(pdf, date)
  components.subtitle(pdf, 'Riscos Assumidos', GAP)

  securityMeasureKeys.forEach((securityMeasureKind, index) => {
    const measures = mountedSecurityMeasures[securityMeasureKind]

    let startY = GAP * 1.25

    measures.forEach((securityMeasure) => {
      const takeRisk = securityMeasure.takeRisk

      if (!isEmpty(takeRisk)) {
        components.table(
          pdf,
          [[securityMeasure.title]],
          [[securityMeasure.subtitle]],
          startY,
          0.5,
          PADDING,
          true,
          columStyles,
        )

        components.table(
          pdf,
          null,
          generateTakeRiskRows(takeRisk),
          pdf.lastAutoTable.finalY,
          0.5,
          PADDING,
          true,
          plansColumStyles,
        )

        startY = pdf.lastAutoTable.finalY + 16
        components.numberPage(pdf, palette.black)
      }
    })
  })
}

export default takeRisks
