import constants from 'constants/index'

const stylesByStatus = (status, classes) => {
  switch (status) {
    case constants.preRegistrations.WAITING_PAYMENT_STATUS:
      return classes.awaitingPayment
    case constants.preRegistrations.FAILURE_STATUS:
      return classes.paymentFailed
    case constants.preRegistrations.PAID_STATUS:
      return classes.paymentApproved
    default:
      return ''
  }
}
export default stylesByStatus
