const orderById = (collection) => {
  return collection.sort((x, y) => {
    if (x.id < y.id) {
      return -1
    } else {
      return null
    }
  })
}

export default orderById
