import { colors } from '@material-ui/core'
import HiringBanner from 'images/hiring_banner.jpg'

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    width: '85%',
    height: '124px',
    color: theme.palette.white,
    [theme.breakpoints.up('md')]: {
      width: '60%',
      backgroundImage: `url(${HiringBanner})`,
      backgroundPosition: 'right',
    },
    backgroundColor: theme.palette.pinkMagenta,
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px 4px 0px 0px',
  },
  hireButton: {
    backgroundColor: colors.green[500],
    color: colors.grey[50],
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: colors.green[500],
      opacity: 0.8,
    },
  },
  boxAction: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: '124px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'baseline',
    [theme.breakpoints.up('md')]: {
      maxWidth: '65%',
    },
  },
})

export default styles
