import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ activityId = '', ...params }) => {
  return await dponetAPI.get(`/tasks/${activityId}`, { params })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/tasks', { task: data })
}

const edit = async ({ taskId, ...data }) => {
  return await dponetAPI.put(`/tasks/${taskId}`, { task: data })
}

const destroy = async ({ taskId }) => {
  return await dponetAPI.delete(`/tasks/${taskId}`)
}

const editObservations = async ({ taskId, ...data }) => {
  return await dponetAPI.put(`/tasks/${taskId}/update_observation`, {
    task: data,
  })
}

const finalize = async ({ taskId }) => {
  return await dponetAPI.put(`/tasks/${taskId}/finalize`)
}

const reopen = async ({ taskId }) => {
  return await dponetAPI.put(`/tasks/${taskId}/reopen`)
}

export default {
  get,
  create,
  destroy,
  edit,
  editObservations,
  finalize,
  reopen,
}
