import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'

import styles from './styles'
import TopBar from '../TopBar'
import NavBar from '../NavBar'
import { DialogUseLimitationNotice, NoAccessFreeTrial } from 'components'
import FloatingButtons from '../FloatingButtons'

import clsx from 'clsx'

const useStyles = makeStyles(styles)

const ContentMain = ({
  children,
  showBackToFirstSteps,
  reduceMargin,
  company,
  handleOpenTomticketDialog,
  dialogTomticket,
  setDialogTomticket,
  customThemeIsActive,
}) => {
  const classes = useStyles()

  return (
    <Box
      className={clsx(
        classes.root,
        customThemeIsActive()
          ? classes.customBackground
          : classes.standardBackground,
      )}
    >
      <TopBar />
      <NavBar customThemeIsActive={customThemeIsActive} />

      <Box className={classes.wrapper}>
        <Box
          className={clsx(classes.contentContainer, {
            [classes.contentContainerMarginReduce]: reduceMargin,
          })}
        >
          <Box className={classes.content}>
            {children}

            <NoAccessFreeTrial />
            <FloatingButtons
              company={company}
              showBackToFirstSteps={showBackToFirstSteps}
              handleOpenTomticketDialog={handleOpenTomticketDialog}
              dialogTomticket={dialogTomticket}
              setDialogTomticket={setDialogTomticket}
            />
            <DialogUseLimitationNotice />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ContentMain

ContentMain.propTypes = {
  customThemeIsActive: PropTypes.func,
  children: PropTypes.any,
  showBackToFirstSteps: PropTypes.bool,
  reduceMargin: PropTypes.bool,
  company: PropTypes.object,
  handleOpenTomticketDialog: PropTypes.func,
  dialogTomticket: PropTypes.bool,
  setDialogTomticket: PropTypes.bool,
}

ContentMain.defaultProps = {
  showBackToFirstSteps: false,
  reduceMargin: false,
}
