import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  customPopover: {
    maxWidth: 500,
  },
  contentHeaderBox: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
    },
  },
}))

export default styles
