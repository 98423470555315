const fragilityLabel = (fragilityId, maleText = true) => {
  switch (fragilityId) {
    case 1:
      return maleText ? 'BAIXO' : 'BAIXA'
    case 2:
      return maleText ? 'MÉDIO' : 'MÉDIA'
    case 3:
      return maleText ? 'ALTO' : 'ALTA'
    case 4:
      return maleText ? 'SEVERO' : 'SEVERA'
    default:
      return maleText ? 'NÃO CALCULADO' : 'NÃO CALCULADA'
  }
}

export default fragilityLabel
