import React from 'react'

import { Box, Typography, CircularProgress } from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

const DataProcessIncidentTable = ({ dataProcesses, isLoading }) => {
  return (
    <>
      <Box>
        <Typography variant="h5">Processamento de dados vinculados</Typography>
      </Box>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhum processo encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Identificador</TableCell>
                  <TableCell width="40%">Processamento de dados</TableCell>
                  <TableCell width="40%">Departamento</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataProcesses?.map((dataProcess) => (
                  <TableRow key={dataProcess.id}>
                    <TableCell>{dataProcess.id}</TableCell>
                    <TableCell>{dataProcess.name}</TableCell>
                    <TableCell>{dataProcess.department.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}

export default DataProcessIncidentTable
