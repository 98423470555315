import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  cardHeaderContent: {
    minWidth: 0,
  },
  cardHeaderTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  linearProgress: {
    height: theme.spacing(1),
    borderRadius: theme.spacing(1),
    width: '100%',
    background: '#FFF',
    marginRight: theme.spacing(1),
    border: '1px #FFF solid',
  },
  cardContent: {
    padding: 0,
    margin: 0,
    '&:last-child': {
      padding: 0,
      margin: 0,
    },
  },
  linearProgressBar: {
    background: '#16A63C !important',
  },
}))

export default styles
