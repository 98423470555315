import React from 'react'
import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'
import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core'
import { File as FileIcon } from 'react-feather'

import styles from './styles'
const useStyles = makeStyles(styles)

const CardsCompliance = ({ questionnaires }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Typography variant="h4">Conformidade por questionário</Typography>
      <Paper elevation={3} component={Box} my={2}>
        {isEmpty(questionnaires) && (
          <Box py={3} px={2}>
            <Typography variant="body1">
              O fornecedor ainda não possui questionários
            </Typography>
          </Box>
        )}
        <Grid container spacing={1}>
          {questionnaires.map((questionnaire) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={questionnaire.id}>
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                p={2}
                height="100%"
              >
                <Box px={2}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={classes.boxIcon}
                  >
                    <FileIcon color="white" size={18} />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box display="flex" flexDirection="column" width="100%">
                    <Typography color="textSecondary" variant="body2">
                      {questionnaire?.name?.toUpperCase()}
                    </Typography>
                    <Typography color="textPrimary" variant="body1">
                      {questionnaire?.compliance?.toFixed(0)}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  )
}

CardsCompliance.propTypes = {
  questionnaires: PropTypes.array,
}

export default CardsCompliance
