const COMPANY_ENTITY_TYPE = 'Company'
const DEPARTMENT_ENTITY_TYPE = 'Department'
const DATA_PROCESS_ENTITY_TYPE = 'DataProcess'

const fragilityEntities = {
  COMPANY_ENTITY_TYPE,
  DEPARTMENT_ENTITY_TYPE,
  DATA_PROCESS_ENTITY_TYPE,
}

export default fragilityEntities
