import api from 'service/api'

const dponetAPI = api.create('dponet')

const dponetAPIWithoutError = api.create('dponet', false)

const create = async ({ ...data }) => {
  return await dponetAPI.post('/cookie_consents', data)
}

const list = async () => {
  return await dponetAPIWithoutError.get('/cookie_consents')
}

const show = async ({ cookieConsentId }) => {
  return await dponetAPI.get(`/cookie_consents/${cookieConsentId}`)
}

const update = async ({ cookieConsentId, ...data }) => {
  return await dponetAPI.put(`/cookie_consents/${cookieConsentId}`, data)
}

const checkInstallation = async ({ cookieConsentId }) => {
  return await dponetAPI.post(
    `/cookie_consents/${cookieConsentId}/check_installation`,
  )
}

const confirmInstallation = async ({ cookieConsentId }) => {
  return await dponetAPI.put(
    `/cookie_consents/${cookieConsentId}/confirm_installation`,
  )
}

const confirmPublication = async ({ cookieConsentId }) => {
  return await dponetAPI.put(
    `/cookie_consents/${cookieConsentId}/confirm_publication`,
  )
}

const installCode = async ({ cookieConsentId }) => {
  return await dponetAPI.get(`/cookie_consents/${cookieConsentId}/install_code`)
}

const listTags = async ({ cookieConsentId }) => {
  return await dponetAPI.get(`/cookie_consents/${cookieConsentId}/list_tags`)
}

const tagById = async ({ cookieConsentId, ...data }) => {
  return await dponetAPI.post(
    `/cookie_consents/${cookieConsentId}/tag_by_id`,
    data,
  )
}

const monthlyMetrics = async ({ cookieConsentId }) => {
  return await dponetAPIWithoutError.get(
    `/cookie_consents/${cookieConsentId}/monthly_metrics`,
  )
}

export default {
  checkInstallation,
  confirmInstallation,
  confirmPublication,
  create,
  installCode,
  list,
  listTags,
  monthlyMetrics,
  show,
  tagById,
  update,
}
