import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    height: 'inherit',
  },
  helpButton: {
    color: theme.palette.common.white,
  },
  helpButtonLabel: {
    fontWeight: `500 !important`,
  },
}))

export default styles
