import * as yup from 'yup'
import constants from 'constants/index'

const { STORAGE_DEFINED_TYPE, STORAGE_UNDEFINED_TYPE } = constants.dataProcess

const dataLifeCycleSchema = () => {
  return yup.object().shape({
    dataLifeCycleId: yup.string(),
    retentionFinality: yup.string().required(),
    internalAllocationModeId: yup.string().required(),
    storageLocationId: yup.string().required(),
    protectionId: yup.string().required(),
    recoveryId: yup.string().required(),
    storageType: yup.number().typeError('Preencha esse campo').required(),
    discardModeId: yup.string().when(['storageType'], {
      is: (storageType) =>
        storageType === STORAGE_DEFINED_TYPE ||
        storageType === STORAGE_UNDEFINED_TYPE,
      then: yup.string().required(),
    }),
    value: yup.number().when('storageType', {
      is: STORAGE_DEFINED_TYPE,
      then: yup.number().typeError('Precisa ser um número').required(),
    }),
    volumetry: yup.string().when('storageType', {
      is: STORAGE_DEFINED_TYPE,
      then: yup.string().required(),
    }),
    anotherStorageLocation: yup.string().when('storageLocationId', {
      is: 'another',
      then: yup.string().required(),
    }),
    anotherDiscardMode: yup.string().when('discardModeId', {
      is: 'another',
      then: yup.string().required(),
    }),
    anotherRecovery: yup.string().when('recoveryId', {
      is: 'another',
      then: yup.string().required(),
    }),
  })
}

export default dataLifeCycleSchema
