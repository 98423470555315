import constants from 'constants/index'

const statusLabel = (status) => {
  if (constants.order.STATUSES_LABELS[status]) {
    return constants.order.STATUSES_LABELS[status]
  } else {
    return 'N/A'
  }
}

export default statusLabel
