import React from 'react'
import { useHistory } from 'react-router-dom'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { Box, Grid, Typography, Button, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'

import { Permitted } from 'components'
import {
  // CollapseAnotherTrail,
  TrailsCard,
  TrainningJourneyTrail,
} from './components'

import useAuth from 'hooks/useAuth'
import usePermissions from 'hooks/usePermissions'

import helpers from 'helpers'

import { routes } from 'Routes'
import constants from 'constants/index'

const CompanyTrails = ({ setLoading, handleOpenTour }) => {
  const {
    companyTrails,
    user,
    userCompany,
    company: { impressionStamp },
  } = useAuth()

  const history = useHistory()
  const permissions = usePermissions()
  const communicationTrailsPermission = permissions.permitted(
    constants.permissions.HOME.SHOW_COMMUNICATION_TRAIL,
  )
  const { USER_STEP_JOURNEY, COMMUNICATION_JOURNEY, TRAINNING_JOURNEY } =
    constants.companyTrail.JOURNEYS
  const { USER_STEP_JOURNEY_CARD_TITLE, COMMUNICATION_JOURNEY_CARD_TITLE } =
    constants.companyTrail.CARD_TITLES

  const userStepsJourney = {
    title: USER_STEP_JOURNEY_CARD_TITLE,
    progress: 100,
    status: constants.companyTrail.CONCLUDED_STATUS,
    trails: helpers.userSteps.homeTrails(
      user?.userSteps,
      setLoading,
      userCompany?.id,
      impressionStamp,
      history,
    ),
    url: `${routes.userSteps}?step=${constants.userSteps.STEP_DPO_TAG}`,
  }

  const communicationTrails = helpers.companyTrails.mountTrails(
    companyTrails,
    history,
  )
  const communicationProgress =
    helpers.companyTrails.defineProgress(communicationTrails)
  const communicationJourney = {
    title: COMMUNICATION_JOURNEY_CARD_TITLE,
    progress: communicationProgress,
    status: helpers.companyTrails.defineCardStatus(communicationProgress),
    trails: communicationTrails,
    url: routes.userTrailSteps,
  }

  // const anotherCompanyTrails = companyTrails.filter(
  //   (companyTrail) => !companyTrail.tag,
  // )

  return (
    <>
      <Box my={4} display="flex" justifyContent="space-between">
        <Typography color="textPrimary" variant="h3">
          Trilha LGPD
        </Typography>
        <Tooltip title="Iniciar o tour guiado" placement="top">
          <Button
            variant="contained"
            color="primary"
            startIcon={<HelpCircleIcon size={20} />}
            id={constants.myLgpd.HOME_DRIVER_STEP_1}
            onClick={handleOpenTour}
          >
            Tutorial
          </Button>
        </Tooltip>
      </Box>
      <Box mt={4}>
        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={12} md={6} lg={4}>
            <Box id={constants.myLgpd.HOME_DRIVER_STEP_4}>
              <TrailsCard {...userStepsJourney} journey={USER_STEP_JOURNEY} />
            </Box>
          </Grid>
          <Permitted tag={constants.permissions.HOME.SHOW_COMMUNICATION_TRAIL}>
            <Grid item xs={12} md={6} lg={4}>
              <Box id={constants.myLgpd.HOME_DRIVER_STEP_5}>
                <TrailsCard
                  {...communicationJourney}
                  journey={COMMUNICATION_JOURNEY}
                />
              </Box>
            </Grid>
          </Permitted>
          <Grid item xs={12} md={6} lg={4}>
            <Box id={constants.myLgpd.HOME_DRIVER_STEP_6}>
              <TrainningJourneyTrail
                step={communicationTrailsPermission ? 3 : 2}
                user={user}
                disabled={
                  communicationTrailsPermission && communicationProgress !== 100
                }
                journey={TRAINNING_JOURNEY}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* 
      Será implementado na atividade de gestão de tarefas
      <Box my={4}>
        <Box my={2}>
          <Typography variant="h4">Atividades</Typography>
        </Box>
        {anotherCompanyTrails?.map((anotherCompanyTrail) => (
          <Box my={1} key={anotherCompanyTrail?.id}>
            <CollapseAnotherTrail
              companyTrail={anotherCompanyTrail}
            ></CollapseAnotherTrail>
          </Box>
        ))}
      </Box> */}
    </>
  )
}

CompanyTrails.propTypes = {
  setLoading: PropTypes.func,
  handleOpenTour: PropTypes.func,
}

export default CompanyTrails
