import * as yup from 'yup'

const schema = yup.object().shape({
  companyName: yup.string().required(),
  responsibleName: yup.string().required(),
  responsibleEmail: yup.string().email('Adicione um e-mail válido').required(),
  phone: yup.string().required(),
  segmentId: yup.string().required('Selecione pelo menos uma opção'),
  document: yup.string().required(),
  planId: yup.string().required('Selecione uma opção'),
})

export default schema
