import api from 'service/api'

const marketPlaceAPI = api.create('marketPlace', false)

const get = async ({ ...params }) => {
  return await marketPlaceAPI.get('/showcases/service_categories', { params })
}

const categories = {
  get,
}

export default categories
