import React, { Fragment } from 'react'
import { Image as ImageIcon } from 'react-feather'
import { Box, Divider, Typography, useMediaQuery } from '@material-ui/core'

import { FileUploadInput } from 'components'

import theme from 'theme'
import constants from 'constants/index'

const LogoUpload = () => {
  const mdUp = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" width="100%">
        <Typography variant="h6" gutterBottom>
          Logo
        </Typography>
        <Typography gutterBottom>
          Essa logo também será utilizada no seu canal de atendimento ao titular
          de dados.
        </Typography>
        <FileUploadInput
          title="Faça o upload da sua logo aqui"
          subCaption="Proporções recomendadas de 500x500 pixels"
          accept={constants.cookies.banner.SUPPORTED_IMAGE_FILE_INPUT_TYPES}
          controlName="logo"
          StartIcon={ImageIcon}
          EndIcon={ImageIcon}
        />
      </Box>
      {mdUp && (
        <Box>
          <Divider orientation="vertical" variant="middle" />
        </Box>
      )}
    </Fragment>
  )
}

export default LogoUpload
