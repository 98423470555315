import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  consentTitleLine: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.greenSnowFlurry,
    fontWeight: 'bold',
  },
}))

export default styles
