import React, { useState } from 'react'
import {
  Box,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'

import { Container, Page, RoundedBorder } from 'components'
import {
  Header,
  QuestionControlPdf,
  ReportVersionExistsDialog,
  ReportVersionTable,
} from './components'

import * as service from 'service'
import constants from 'constants/index'

import useStyles from './styles'
import useFetch from 'hooks/useFetch'

const ControlReport = () => {
  const [exportingPdf, setExportingPdf] = useState(false)
  const [isComplete, setIsComplete] = useState(true)
  const [refresher, setRefresher] = useState(false)
  const [reportVersionExistsOpen, setReportVersionExistsOpen] = useState(false)
  const [currentReportVersion, setCurrentReportVersion] = useState()

  const classes = useStyles()

  const { response, isLoading } = useFetch(
    service.dponet.questionControls.list,
    { withActionPlans: isComplete, perPage: 999 },
    [],
    !!exportingPdf,
  )

  const { response: gapStatsResponse, isLoading: gapIsLoading } = useFetch(
    service.dponet.myLgpd.getGapStats,
    {},
    [],
    !!exportingPdf,
  )

  const questionControls = response?.data?.questionControls
  const gapStats = gapStatsResponse?.data?.stats

  const buttonAction = () => {
    if (!!currentReportVersion?.id) {
      return setReportVersionExistsOpen(true)
    }
    setExportingPdf(true)
  }

  const handleChange = (event) => {
    setIsComplete(event.target.value === 'complete')
  }

  return (
    <Page className={classes.root} title="Relatório de Medidas de Segurança">
      <Box position="relative">
        <RoundedBorder />
      </Box>
      <Container maxWidth={false} spacingXl={15} spacingLg={10} spacingMd={5}>
        <Box py={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Header
                type="control"
                reportTour="medidas-de-seguranca"
                isLoading={gapIsLoading || isLoading}
                buttonAction={buttonAction}
                disabled={!!exportingPdf}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                Escolha a versão do relatório:
              </InputLabel>
              <RadioGroup
                value={isComplete ? 'complete' : 'simple'}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="complete"
                  control={<Radio classes={{ checked: classes.checked }} />}
                  className={classes.radioLabel}
                  label="Relatório Completo (Controles e planos de ação)"
                />
                <FormControlLabel
                  value="simple"
                  className={classes.radioLabel}
                  control={<Radio classes={{ checked: classes.checked }} />}
                  label="Relatório Simplificado (Apenas controles)"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <ReportVersionTable
                currentReportVersion={currentReportVersion}
                kind={constants.reportVersions.KIND.CONTROL}
                refresher={refresher}
                setCurrentReportVersion={setCurrentReportVersion}
              />
            </Grid>
          </Grid>
          <QuestionControlPdf
            exportingPdf={exportingPdf}
            setExportingPdf={setExportingPdf}
            questionControls={questionControls}
            isComplete={isComplete}
            gapStats={gapStats}
            refresh={() => setRefresher(!refresher)}
            shouldCreateVersion={!currentReportVersion?.id}
          />
          <ReportVersionExistsDialog
            currentReportVersion={currentReportVersion}
            exportAction={() => setExportingPdf(true)}
            open={reportVersionExistsOpen}
            setCurrentReportVersion={setCurrentReportVersion}
            setOpen={setReportVersionExistsOpen}
          />
        </Box>
      </Container>
    </Page>
  )
}

export default ControlReport
