import React from 'react'
import { useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { reverse } from 'named-urls'
import { Box, Button, Chip, Link } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton } from 'components'

import helpers from 'helpers'

import useStyles from './styles'
import { routes } from 'Routes'

const BannersTable = ({ banners, isLoading }) => {
  const classes = useStyles()
  const history = useHistory()

  const navigateToSettings = (id) => {
    history.push(
      reverse(routes.cookies.settings, {
        id: id,
      }),
    )
  }

  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table
          size="small"
          emptyMessage="Nenhum banner cadastrado"
          isLoading={isLoading}
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>URL</TableCell>
              <TableCell align="center">Acessos</TableCell>
              <TableCell align="center">Planos</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {banners?.map((banner) => (
              <TableRow key={banner?.id}>
                <TableCell>{banner?.id}</TableCell>
                <TableCell>{banner?.name}</TableCell>
                <TableCell>
                  <Link href={banner?.url}>{banner?.url}</Link>
                </TableCell>
                <TableCell align="center">
                  {banner?.requestsCount || '-'}
                </TableCell>
                <TableCell align="center">
                  {banner?.optOut || 'Leavening'}
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={helpers.cookies.banner.chipStatusLabel(
                      banner?.status,
                    )}
                    style={{
                      backgroundColor: helpers.cookies.banner.chipStatusColor(
                        banner?.status,
                      ),
                      color: helpers.cookies.banner.chipFontStatusColor(
                        banner?.status,
                      ),
                    }}
                    size="small"
                    className={classes.chip}
                  />
                </TableCell>
                <TableCell align="right">
                  <MenuButton>
                    <Button
                      color="secondary"
                      onClick={() => navigateToSettings(banner?.id)}
                      fullWidth
                      size="small"
                    >
                      Configurar
                    </Button>
                  </MenuButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

BannersTable.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
}

export default BannersTable
