import React, { useEffect } from 'react'

import { LoadingFeedback } from 'components'
import Page from 'components/Page'
import * as service from 'service'
import useStyles from './styles'

import useAuth from 'hooks/useAuth'

const IntegrationLogin = ({ match }) => {
  const classes = useStyles()

  const auth = useAuth()

  useEffect(() => {
    const authenticate = async () => {
      const token = match?.params?.authToken
      const companyId = match?.params?.companyId
      const cookieToken = match?.params?.cookieToken

      service.dponet.auth.setToken(token)
      if (companyId) service.dponet.auth.setCompany(companyId)
      if (cookieToken) service.cookies.auth.login(cookieToken)

      await auth.loadDataWithAnimation()
    }

    authenticate()
    // eslint-disable-next-line
  }, [match?.params])

  return (
    <Page
      className={classes.root}
      title="App DPOnet - Acesse a plataforma - LGPD e gestão de dados"
    >
      <LoadingFeedback open />
    </Page>
  )
}

export default IntegrationLogin
