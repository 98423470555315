import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core'

import { LoadingFeedback } from 'components'
import { Complete } from 'views/Incidents/components/IncidentsForm/components'

import useIncidentManegement from 'hooks/useIncidentManegement'
import useIncident from 'hooks/useIncident'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import formatters from 'helpers/formatters'
import incidentConstants from 'constants/incidents'

const DetalingStep = ({ refresh }) => {
  const { INCIDENT_INFORMATION_STEPS } = incidentConstants

  const { wantsToAdvanceStep, resetAdvanceStep, incident, handleNext } =
    useIncidentManegement()
  const { activeStep: completeFormActiveStep } = useIncident()
  const snackbar = useSnackbar()

  const [showCompleteForm, setShowCompleteForm] = useState(
    incident?.shouldNotify || !incident?.simplifiedForm,
  )
  const [loading, setLoading] = useState(false)

  const handleChangeShowCompleteForm = () => {
    setShowCompleteForm((showCompleteForm) => !showCompleteForm)
  }

  const submitForm = () => {
    const form = document.getElementById(
      INCIDENT_INFORMATION_STEPS[completeFormActiveStep].buttonId,
    )

    if (form) form.dispatchEvent(new Event('submit'))

    resetAdvanceStep()
  }

  const concludeStep = async () => {
    try {
      setLoading(true)

      await service.dponet.incidents.concludeStep({
        incidentId: incident.id,
        step: 'detaling',
      })

      resetAdvanceStep()
      handleNext()
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      resetAdvanceStep()
    }
  }

  useEffect(() => {
    if (wantsToAdvanceStep) {
      if (showCompleteForm) {
        submitForm()
      } else {
        concludeStep()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantsToAdvanceStep])

  return (
    <Box paddingBottom={2}>
      <Grid container spacing={3}>
        <LoadingFeedback open={loading} />

        {!incident?.shouldNotify && (
          <Grid item xs={12}>
            <Paper component={Box} p={2}>
              <Typography variant="body1" gutterBottom>
                O DPO responsável concluiu que não será necessário notificar os
                titulares de dados e a ANPD pois não houve dano e/ou risco
                relevante, portanto, esta etapa não será exigida. No entanto, se
                desejar detalhar como parte da documentação, basta ativá-la no
                botão abaixo.
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={showCompleteForm}
                    color="primary"
                    onChange={handleChangeShowCompleteForm}
                    value={showCompleteForm}
                    disabled={!incident?.simplifiedForm}
                  />
                }
                label="Ativar relatório detalhado"
                labelPlacement="end"
              />
            </Paper>
          </Grid>
        )}

        {showCompleteForm && (
          <Grid item xs={12}>
            <Complete incident={incident} refresh={refresh} />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default DetalingStep
