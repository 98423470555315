import { isEmpty } from 'lodash'

const getResponsableNames = (nonComplianceReport, type = true) => {
  let responsableNames = ''

  if (type) {
    if (!nonComplianceReport.nonComplianceReportActions) return '-'

    nonComplianceReport.nonComplianceReportActions.map(
      (nonComplianceReportAction) => {
        return (responsableNames =
          responsableNames +
          (nonComplianceReportAction?.responsableUser?.name || '-') +
          '/ ')
      },
    )
  } else {
    if (isEmpty(nonComplianceReport)) return '-'

    nonComplianceReport.map((nonComplianceReportAction) => {
      return (responsableNames =
        responsableNames + (nonComplianceReportAction?.name || '-') + '/ ')
    })
  }

  return responsableNames.slice(0, -2)
}

export default getResponsableNames
