import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  Paper,
  IconButton as IconButtonMaterial,
  Dialog,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import FormModal from './Form'

import DataProcessTemplateQuestionProvider from 'providers/DataProcessTemplateQuestionProvider'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import useDataProcessOptions from 'hooks/useDataProcessOptions'
import useDataCollected from 'hooks/useDataCollected'
import { ActionButton } from './components'

const DataCollectedModal = ({
  dataCollected,
  classes,
  toSelectItems,
  selectedFields,
  mode,
  dataProcessId,
  children,
  refresh,
}) => {
  const [open, setOpen] = useState(false)

  const edit = mode === 'edit'
  const show = mode === 'show'

  const textMode = edit ? 'Editar' : show ? 'Visualizar' : 'Adicionar'

  const snackbar = useSnackbar()
  const { company } = useAuth()
  const dataProcessOptions = useDataProcessOptions()
  const dataCollectedHook = useDataCollected()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const submitSuccess = () => {
    snackbar.open({
      message: `Dados tratados ${edit ? 'atualizados' : 'criado'} com sucesso`,
      variant: 'success',
    })
    handleClose()
    dataProcessOptions.loadData(company.id)
    dataCollectedHook.loadData(dataProcessId, '')
  }

  return (
    <>
      <ActionButton mode={mode} onClick={handleOpen}>
        {children}
      </ActionButton>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.modal}
        disableAutoFocus
        maxWidth="md"
        fullWidth
      >
        <Paper className={classes.paperModal} elevation="0">
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h4">{textMode} dados tratados</Typography>
            <IconButtonMaterial
              onClick={handleClose}
              color="primary"
              aria-label="close modal"
            >
              <CloseIcon />
            </IconButtonMaterial>
          </Grid>
          <DataProcessTemplateQuestionProvider
            dataProcessTemplate={dataProcessOptions.dataProcessTemplate}
            step="data_collected"
            dataProcessTemplateAnswers={
              dataCollected?.dataProcessTemplateAnswers || []
            }
          >
            <FormModal
              dataCollected={dataCollected}
              toSelectItems={toSelectItems}
              selectedFields={edit || show ? selectedFields : []}
              handleCloseModal={handleClose}
              mode={mode}
              dataProcessId={dataProcessId}
              submitSuccess={submitSuccess}
              refresh={refresh}
            />
          </DataProcessTemplateQuestionProvider>
        </Paper>
      </Dialog>
    </>
  )
}

DataCollectedModal.propTypes = {
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
}
export default DataCollectedModal
