import React from 'react'

import useFetch from 'hooks/useFetch'
import { Controller, useForm } from 'react-hook-form'

import { Box, Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { LoadingFeedback } from 'components'

import * as service from 'service'
import schema from './schema'
import helpers from 'helpers'

const RegisteredUsersTab = ({ onSubmit, setDisabled = () => {} }) => {
  const { response, isLoading } = useFetch(
    service.dponet.usersCompanies.list,
    {
      listByCompany: true,
      perPage: 9999999,
    },
    [],
  )

  const { handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: {
      userCompany: { id: 0, name: '' },
      phone: '',
    },
  })

  const options = response?.data?.userCompanies.map((userCompany) => {
    return {
      id: userCompany.id,
      name: `${userCompany?.user?.name} - (${userCompany?.user?.email})`,
    }
  })

  const formatData = (data) => {
    const formattedData = {
      userCompanyId: data?.userCompany?.id,
      phone: data?.phone,
    }

    onSubmit(formattedData)
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <Box minHeight="26.3vh" width="100%" mt={2}>
        <form onSubmit={handleSubmit(formatData)} id="newResponsableForm">
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Controller
                control={control}
                name="userCompany"
                mode="onChange"
                onChange={([, data]) => {
                  return data
                }}
                as={
                  <Autocomplete
                    options={options || []}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option, value) =>
                      !value || option.id === value.id || value.id === 0
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.userCompany}
                        helperText={
                          errors?.userCompany ? 'Selecione um usuário' : ''
                        }
                        label="Selecione o usuário que deseja tornar representante"
                        variant="outlined"
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller
                control={control}
                name="phone"
                onChange={([event]) => {
                  return helpers.formatters.phoneDynamicMask(event.target.value)
                }}
                mode="onBlur"
                as={
                  <TextField
                    label="Telefone do usuário"
                    type="text"
                    color="primary"
                    variant="outlined"
                    placeholder="(99) 9999-9999"
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    fullWidth
                  />
                }
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  )
}

export default RegisteredUsersTab
