import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography } from '@material-ui/core'
import { BaseDialog, Select } from 'components'

import { Controller, useForm } from 'react-hook-form'

import schema from './schema'

import constants from 'constants/index'
import * as service from 'service'

const ReportVersionExistsDialog = ({
  currentReportVersion,
  exportAction,
  open,
  setCurrentReportVersion,
  setOpen,
  formRef,
  setRenewReportVersion,
}) => {
  const [loading, setLoading] = useState(false)
  const { handleSubmit, control, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      dataAction: constants.reportVersions.DOWNLOAD_ONLY_ID_ACTION,
    },
  })

  const onSubmit = async (data) => {
    setLoading(true)
    const destroyAction =
      data?.dataAction === constants.reportVersions.DESTROY_VERSION_ID_ACTION

    if (destroyAction) {
      await service.dponet.reportVersions.destroy({
        reportVersionId: currentReportVersion?.id,
      })
      setCurrentReportVersion()
    }

    await exportAction(destroyAction)

    setLoading(false)
    setOpen(false)
  }

  const onChangeSelect = ([data]) => {
    const value = data?.target?.value
    if (!!setRenewReportVersion) {
      const destroyAction =
        value === constants.reportVersions.DESTROY_VERSION_ID_ACTION
      setRenewReportVersion(destroyAction)
    }

    return data
  }

  return (
    <form id="report-version-exists" onSubmit={handleSubmit(onSubmit)}>
      <BaseDialog
        actionButtonText="Confirmar"
        closeButtonText="Cancelar"
        formRef={formRef ?? 'report-version-exists'}
        title="Já existe uma versão mensal desse relatório"
        loading={loading}
        open={open}
        setOpen={setOpen}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">
              Selecione uma opção para prosseguir.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <Select
                  disabled={loading}
                  error={!!errors?.dataAction}
                  helperText={errors?.dataAction?.message}
                  items={constants.reportVersions.VERSION_EXISTS_ACTION_OPTIONS}
                  fullWidth
                />
              }
              onChange={onChangeSelect}
              control={control}
              mode="onBlur"
              name="dataAction"
            />
          </Grid>
        </Grid>
      </BaseDialog>
    </form>
  )
}

ReportVersionExistsDialog.propTypes = {
  open: PropTypes.bool,
  formRef: PropTypes.string,
  setOpen: PropTypes.func,
  currentReportVersion: PropTypes.object,
  exportAction: PropTypes.func,
  setCurrentReportVersion: PropTypes.func,
  setRenewReportVersion: PropTypes.func,
}

ReportVersionExistsDialog.defaultProps = {
  setOpen: () => {},
  exportAction: () => {},
  setCurrentReportVersion: () => {},
}

export default ReportVersionExistsDialog
