import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { Info as InfoIcon, PlusCircle as PlusCircleIcon } from 'react-feather'
import { Box, Typography, Grid, Tooltip, Button } from '@material-ui/core'

import { TableDataTreatmentAgents } from '../../'
import DialogAdd from '../../TableDataTreatmentAgents/components/DialogAdd/DialogAdd'

import useFetch from 'hooks/useFetch'

import constants from 'constants/index'
import * as service from 'service'
import useStyles from './styles'

import helpers from 'helpers'

const DataTreatmentAgent = ({ dataProcessId }) => {
  const [dialogAdd, setDiaglogAdd] = useState(false)
  const classes = useStyles()

  const { response, isLoading, refresh } = useFetch(
    service.dponet.dataTreatmentAgents.index,
    { dataProcessId: dataProcessId, perPage: 100_000 },
    [],
  )

  const isCompanySelfPresent = () => {
    const agent = helpers.dataTreatmentAgent.agentTypeOfCompanySelf(
      response?.data?.dataTreatmentAgents,
    )

    if (isEmpty(agent)) return false

    return true
  }

  const agentsDocuments = response?.data?.dataTreatmentAgents.map(
    (agents) => agents.document,
  )

  return (
    <>
      <Box id={constants.dataProcess.DATA_PROCESS_EDIT_DRIVER_STEP_6}>
        <Grid container justifyContent="space-between">
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <Typography variant="h5">Agentes de tratamento</Typography>
            {!isLoading && isEmpty(response?.data?.dataTreatmentAgents) && (
              <Tooltip
                title={constants.dataTreatmentAgents.REQUIREDS}
                placement="bottom-start"
              >
                <Box ml={1} display="flex">
                  <InfoIcon size={18} color="red" />
                </Box>
              </Tooltip>
            )}
          </Box>
          <Box mt={2}>
            <Button
              onClick={() => setDiaglogAdd(true)}
              variant="outlined"
              startIcon={<PlusCircleIcon size={20} />}
              size="small"
            >
              <Typography>Adicionar Agente de tratamento</Typography>
            </Button>
          </Box>
        </Grid>

        <Grid container className={classes.dataCollectedContainer}>
          <Grid className={classes.dataCollectedTable} item xs={12}>
            {!isLoading && (
              <TableDataTreatmentAgents
                dataTreatmentAgents={response?.data?.dataTreatmentAgents}
                dataProcessId={dataProcessId}
                refresh={refresh}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      {!isLoading && dialogAdd && (
        <DialogAdd
          open={dialogAdd}
          setOpen={setDiaglogAdd}
          isCompanySelfPresent={isCompanySelfPresent}
          refresh={refresh}
          dataProcessId={dataProcessId}
          agentsDocuments={agentsDocuments}
        />
      )}
    </>
  )
}

export default DataTreatmentAgent
