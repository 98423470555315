import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  logo: {
    fontSize: 0,
    width: '130px',
    [theme.breakpoints.up('md')]: {
      width: '130px',
    },
  },
  logoContainer: {
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '20px 40px',
    gap: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  linksContainer: {
    display: 'flex',
    gap: 14,
    textAlign: 'center',
  },
  pointer: {
    color: theme.palette.white,
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    fontSize: '18',
    cursor: 'pointer',
  },
}))

export default styles
