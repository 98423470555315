import React from 'react'
import PropTypes from 'prop-types'
import {
  Stepper,
  Step,
  StepButton,
  StepLabel,
  makeStyles,
} from '@material-ui/core'

import useSubscription from 'hooks/useSubscription'

import constants from 'constants/index'
import styles from './styles'

const useStyles = makeStyles(styles)

const HorizontalStepper = ({
  activeStep,
  setActiveStep,
  greatherStep,
  steps,
  isFinalized = false,
}) => {
  const {
    activeSubStep,
    setActiveSubStep,
    status,
    setPolicyAccept,
    setTermsAccept,
  } = useSubscription()
  const classes = useStyles()

  const activeStepOnStepper = () => {
    return activeStep?.index === constants.preRegistrations.PAYMENT_STEP[0] &&
      activeSubStep?.index ===
        constants.preRegistrations.FINALIZED_STATUS_STEP[0] &&
      status === constants.preRegistrations.PAID_STATUS
      ? constants.preRegistrations.FINALIZED_STATUS_STEP[0]
      : activeStep
  }

  const ableNavigateTo = (stepIndex) => {
    return greatherStep >= stepIndex
  }

  const navigateToStep = (stepIndex) => {
    if (isFinalized) return null
    if (ableNavigateTo(stepIndex)) {
      setActiveSubStep(0)
      setActiveStep(stepIndex)

      if (stepIndex === constants.preRegistrations.FIRST_STEP[0]) {
        setTermsAccept(false)
        setPolicyAccept(false)
      }
    }
  }

  return (
    <Stepper
      activeStep={activeStepOnStepper()}
      alternativeLabel
      className={classes.containerSegment}
      classes={{ root: classes.stepBackground }}
    >
      {steps.map((step) => (
        <Step
          key={step.label}
          className={ableNavigateTo(step.index) ? classes.pointer : ''}
          onClick={() => navigateToStep(step.index)}
          classes={{ root: classes.stepLine }}
        >
          <StepButton>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.stepIcon,
                  active: classes.activeStepIcon,
                  completed: classes.activeStepIcon,
                  text: classes.stepIconText,
                },
              }}
              classes={{ label: classes.stepLabel }}
            >
              {step.label}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  )
}

HorizontalStepper.propTypes = {
  greatherStep: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
}

export default HorizontalStepper
