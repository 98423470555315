import React from 'react'

import {
  Grid,
  Tooltip,
  Typography,
  makeStyles,
  Hidden,
  Box,
  CircularProgress,
} from '@material-ui/core'

import logo from 'images/dponet-logo-blue.png'

import styles from './styles'

import helpers from 'helpers'

const useStyles = makeStyles(styles)

const Header = ({ data, isLoading, ...rest }) => {
  const classes = useStyles()
  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid
          className={classes.root}
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Hidden smUp>
            <Grid item sm={12} xs={12}>
              <img alt="Logo" src={logo} width="75%" />
            </Grid>
          </Hidden>
          <Grid xl={10} lg={9} md={8} sm={8} xs={12} item>
            <Tooltip
              title={helpers.functions.dig(data.department, 'name')}
              placement="bottom-start"
            >
              <Typography variant="h4" className={classes.ellipsis}>
                {helpers.functions.dig(data.department, 'name')}
              </Typography>
            </Tooltip>
          </Grid>
          <Hidden smDown>
            <Grid item xl={2} lg={3} md={4} sm={4} xs={12}>
              <img alt="Logo" src={logo} width="75%" />
            </Grid>
          </Hidden>
        </Grid>
      )}
    </>
  )
}

export default Header
