import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Controller } from 'react-hook-form'

import { Grid, TextField } from '@material-ui/core'

import helpers from 'helpers'

import * as service from 'service'

import useSubscription from 'hooks/useSubscription'
import useStyles from './styles'

import clsx from 'clsx'

const AddressForm = ({ formMethods }) => {
  const [postalCodeEmpty, setPostalCodeEmpty] = useState(true)
  const classes = useStyles()

  const { control, errors, getValues, setValue, watch } = formMethods

  const { data, isDemoSubscription } = useSubscription()

  const setAddressValues = (dataResponse) => {
    if (dataResponse) {
      setValue([
        { country: 'Brasil' },
        { city: dataResponse.localidade },
        { state: dataResponse.uf },
        { neighborhood: dataResponse.bairro },
        { street: dataResponse.logradouro },
      ])
      setPostalCodeEmpty(false)
    } else if (data?.address && !dataResponse) {
      setValue([
        { country: data?.address?.contry },
        { city: data?.address?.city },
        { state: data?.address?.state },
        { neighborhood: data?.address?.neighborhood },
        { street: data?.address?.street },
      ])
      setPostalCodeEmpty(false)
    } else {
      setValue([
        { country: '' },
        { city: '' },
        { state: '' },
        { neighborhood: '' },
        { street: '' },
      ])
    }
  }

  useEffect(() => {
    const getAddressByPostalCode = async (cep) => {
      const response = await service.externalAPIs.viaCEP.get(cep)

      if (response?.data?.erro) {
        setPostalCodeEmpty(false)
        return setAddressValues(false)
      }
      setAddressValues(response?.data)
    }

    const postalCode = getValues('postalCode')

    if (postalCode.length === 9) {
      getAddressByPostalCode(postalCode)
    } else {
      setPostalCodeEmpty(true)
      setAddressValues(false)
    }
    // eslint-disable-next-line
  }, [watch('postalCode')])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          as={
            <TextField
              fullWidth
              error={!!errors.postalCode}
              helperText={errors?.postalCode?.message}
              label="Código Postal (CEP)"
              required
            />
          }
          onChange={([text]) => helpers.formatters.cep(text.target.value)}
          control={control}
          name="postalCode"
          mode="onChange"
        />
      </Grid>
      <Grid
        className={clsx(classes.gridAddress, {
          [classes.gridAddressHidden]: !isDemoSubscription && postalCodeEmpty,
        })}
        spacing={2}
        container
      >
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.country}
                helperText={errors?.country?.message}
                label="País"
                hidden={postalCodeEmpty}
                required
              />
            }
            control={control}
            name="country"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                inputProps={{
                  maxLength: 2,
                }}
                error={!!errors.state}
                helperText={errors?.state?.message}
                label="Estado, ex: SP"
                required
              />
            }
            control={control}
            name="state"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.city}
                helperText={errors?.city?.message}
                label="Cidade"
                required
              />
            }
            control={control}
            name="city"
            mode="onChange"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.neighborhood}
                helperText={errors?.neighborhood?.message}
                label="Bairro"
                required
              />
            }
            control={control}
            name="neighborhood"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.street}
                helperText={errors?.street?.message}
                label="Rua/Logradouro"
                required
              />
            }
            control={control}
            name="street"
            mode="onChange"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.number}
                helperText={errors?.number?.message}
                label="Número"
                required
              />
            }
            control={control}
            name="number"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.complement}
                helperText={errors?.complement?.message}
                label="Complemento"
              />
            }
            control={control}
            name="complement"
            mode="onChange"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

AddressForm.propTypes = {
  formMethods: PropTypes.object.isRequired,
}

export default AddressForm
