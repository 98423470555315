import React, { isValidElement } from 'react'
import { isArray, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import {
  Box,
  colors,
  Divider,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'

import theme from 'theme'

const CommonTypography = ({ value }) => {
  if (isValidElement(value)) return value

  const validationValue = isEmpty(value) ? 'Não informado' : value

  return (
    <Tooltip title={validationValue} placement="bottom-start">
      <Typography variant="body2" color="textSecondary">
        {validationValue}
      </Typography>
    </Tooltip>
  )
}

const Infos = ({ items }) => {
  return (
    <Paper variant="outlined">
      <Box px={3} py={2}>
        <Typography variant="subtitle1" color="primary">
          Informações
        </Typography>
      </Box>
      {items?.map((option, index) => {
        const highlight = option?.highlight
          ? colors.lightGreen['A100']
          : theme.palette.common.white

        return (
          <Box key={index}>
            <Divider />
            <Box px={3} py={2} bgcolor={highlight}>
              {option?.label && (
                <Tooltip title={option?.label ?? ''} placement="bottom-start">
                  <Typography variant="subtitle2" color="primary">
                    {option.label}
                  </Typography>
                </Tooltip>
              )}
              {isArray(option?.value) && !isEmpty(option?.value) ? (
                option?.value?.map((value, subIndex) => (
                  <CommonTypography value={value} key={subIndex} />
                ))
              ) : (
                <CommonTypography value={option?.value} />
              )}
            </Box>
          </Box>
        )
      })}
    </Paper>
  )
}

Infos.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

CommonTypography.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Infos
