import { makeStyles } from '@material-ui/core'

const styles = (withoutDivider) =>
  makeStyles((theme) => ({
    container: {
      maxWidth: 443,
      maxHeight: 425,
      borderRadius: 8,
    },
    contentContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%',
      gap: 10,
      padding: 40,
    },
    description: {
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: 16,
      height: 170,
      color: theme.palette.text.mainMenu,
    },
    button: {
      width: 165,
      height: 40,
      alignSelf: 'center',
      color: theme.palette.primary.hover,
      border: `1px solid ${theme.palette.marketplace.card.border}`,
      transition: 'all 200ms ease-in',
      '&:hover': {
        color: theme.palette.white,
        border: theme.palette.white,
        backgroundColor: theme.palette.marketplace.card.border,
      },
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      height: 160,
    },
    listItem: {
      fontFamily: 'Roboto',
      fontSize: 18,
      color: theme.palette.text.mainMenu,
    },
  }))

export default styles
