import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Container, Typography } from '@material-ui/core'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { Form as LoginForm, GoogleSignInButton } from './components'
import { Page, Divider } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'
import { getGoogleAuthenticationToken } from 'service/env'

const Login = () => {
  const classes = useStyles()
  const location = useLocation()
  const snackbar = useSnackbar()
  const locationState = location.state
  const message = locationState
    ? locationState.error || locationState.success
    : ''

  useEffect(() => {
    if (locationState) {
      if (locationState.error) {
        snackbar.open({ message: message, variant: 'error' })
      } else {
        snackbar.open({ message: message, variant: 'success' })
      }
    }
    // eslint-disable-next-line
  }, [message, locationState])

  return (
    <Page
      className={classes.root}
      title="App DPOnet - Acesse a plataforma - LGPD e gestão de dados"
    >
      <Container maxWidth="xs">
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.welcomeText}
        >
          Bem-vindo(a)!
        </Typography>
        <Box mt={3}>
          <LoginForm />
        </Box>
        <Box mt={3}>
          <Divider>
            <Typography variant="caption" color="textSecondary">
              ou
            </Typography>
          </Divider>
        </Box>
        <Box mt={3} display="flex" justifyContent="center">
          <GoogleOAuthProvider clientId={getGoogleAuthenticationToken()}>
            <GoogleSignInButton />
          </GoogleOAuthProvider>
        </Box>
      </Container>
    </Page>
  )
}

export default Login
