import palette from 'theme/palette'

import templates from '../../templates'
import components from '../../components'

import { ratio } from '../../helpers'

import { PADDING, GAP } from '../../constants'

const generalInformations = async (pdf, date, questionnaires, customStyles) => {
  const calculateAverage = (prop) => {
    const total = questionnaires.reduce(
      (acc, questionnaire) => acc + questionnaire[prop],
      0,
    )
    const average = total / questionnaires.length
    return Math.round(average) + '%'
  }

  const compliance = calculateAverage('compliance')
  const progressPercentage = calculateAverage('progress')

  templates.chapterCover(
    pdf,
    'VISÃO GERAL',
    'Síntese das principais informações e métricas apresentadas no painel de controle.',
    customStyles,
  )

  pdf.addPage('a4', 'p')
  components.lineWithDate(pdf, date)
  components.subtitle(pdf, 'Visao Geral', GAP)

  components.rectangle(
    pdf,
    PADDING,
    GAP * 1.25,
    ratio(660),
    palette.primary.main,
    ratio,
    'Progresso',
    progressPercentage,
  )

  components.rectangle(
    pdf,
    PADDING * 5,
    GAP * 1.25,
    ratio(660),
    palette.primary.main,
    ratio,
    'Conformidade',
    compliance,
  )

  var mountQuestionnaireRow = (questionnaire) => [
    ['Progresso de resolução do questionário ', questionnaire?.progress + '%'],
    ['Conformidade do questionário', questionnaire?.compliance + '%'],
  ]

  questionnaires.forEach((questionnaire, index) => {
    let startY = index === 0 ? GAP * 3 : pdf.autoTable.previous.finalY + 16

    components.table(
      pdf,
      [[questionnaire.name, 'Total (%)']],
      mountQuestionnaireRow(questionnaire),
      startY,
      0.5,
      PADDING,
      true,
    )
  })
}

export default generalInformations
