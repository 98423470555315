const statusLabel = (status) => {
  switch (status) {
    case 'partially_answered':
      return 'Parcialmente Respondido'
    case 'answered':
      return 'Respondido'
    default:
      return 'Aguardando Resposta'
  }
}

export default statusLabel
