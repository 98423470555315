const specificsRisksText = (data, specificKey = 'description') => {
  let specificText = ''

  for (const obj of data) {
    if (obj.hasOwnProperty(specificKey)) {
      specificText += obj[specificKey] + 'lineBreak'
    }
  }
  return specificText.replace(/lineBreak/g, '\n')
}

export default specificsRisksText
