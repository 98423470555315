import permissions from '../permissions'

const DEPARTMENT_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.VISUALIZE,
    tag: permissions.DEPARTMENTS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.DEPARTMENTS.CREATE,
  },
  {
    name: 'Vincular Responsável',
    tag: permissions.DEPARTMENTS.LINK_USERS,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.ACTIVATE_OR_INACTIVATE,
    tag: permissions.DEPARTMENTS.ACTIVATE_OR_INACTIVATE,
  },
]

export default DEPARTMENT_LIST
