import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'

import styles from './styles'

import Banner from './components/Banner'

const useStyles = makeStyles(styles)

const Carousel = ({ banners }) => {
  const classes = useStyles()

  return (
    <ReactCarousel
      interval={6000}
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      autoPlay
      className={classes.container}
    >
      {banners.map((banner, idx) => (
        <Banner
          action={banner.action}
          altImgText={banner.altText}
          idx={idx}
          srcImg={banner.srcImg}
          title={banner.title}
          bgColor={banner.bgColor}
          key={idx}
        />
      ))}
    </ReactCarousel>
  )
}

Carousel.propTypes = {
  banners: PropTypes.array.isRequired,
}

export default Carousel
