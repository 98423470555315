import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  borderedBox: {
    border: `solid ${theme.spacing(1)}px ${
      theme.colors.firstStep.background.secondary
    }`,
  },
  typographyLegend: {
    fontWeight: 500,
    fontSize: '2.3vh',
  },
  boxCode: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.code.background,
    overflow: 'auto',
  },
}))

export default styles
