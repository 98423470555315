import React, { useEffect, useState } from 'react'

import IncidentManegementContext from 'contexts/IncidentManegementContext'

import IncidentProvider from './IncidentProvider'

import useAuth from 'hooks/useAuth'

import { history } from 'service'
import { routes } from 'Routes'

const IncidentManegementProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [lastActiveStep, setLastActiveStep] = useState(0)
  const [wantsToAdvanceStep, setWantsToAdvanceStep] = useState(false)
  const [wantsToReturnStep, setWantsToReturnStep] = useState(false)

  const [activeButton, setActiveButton] = useState(true)
  const [showButtons, setShowButtons] = useState(true)

  const [incident, setIncident] = useState({})

  const { company, user } = useAuth()
  const outsourced = company?.outsourced
  const isDpo =
    outsourced?.status === 'ACTIVE' && outsourced?.email === user?.email

  const handleAdvanceStep = () => {
    setWantsToAdvanceStep(true)
  }

  const handleReturnStep = () => {
    setWantsToReturnStep(true)
  }

  const resetAdvanceStep = () => {
    setWantsToAdvanceStep(false)
  }

  const resetReturnStep = () => {
    setWantsToReturnStep(false)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    if (activeStep === 0) return history.push(routes.incidents.all)

    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step) => {
    if (step > lastActiveStep) return

    setActiveStep(step)
  }

  const isCompleted = (step) => {
    return lastActiveStep > step
  }

  useEffect(() => {
    if (activeStep > lastActiveStep) {
      setLastActiveStep(activeStep)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  return (
    <IncidentManegementContext.Provider
      value={{
        activeStep,
        setActiveStep,
        handleAdvanceStep,
        handleReturnStep,
        resetAdvanceStep,
        resetReturnStep,
        handleBack,
        handleNext,
        handleStep,
        isCompleted,
        activeButton,
        setActiveButton,
        showButtons,
        setShowButtons,
        incident,
        setIncident,
        wantsToAdvanceStep,
        wantsToReturnStep,
        isDpo,
      }}
    >
      <IncidentProvider>{children}</IncidentProvider>
    </IncidentManegementContext.Provider>
  )
}

export default IncidentManegementProvider
