import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridGap: theme.spacing(1),
  },
}))

export default styles
