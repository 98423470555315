import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  boxTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: theme.spacing(2),
  },
  boxTitleTwo: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: theme.spacing(97),
  },
  muiDialogTitleOverride: {
    padding: '14px 23px 14px 23px',
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
    },
  },
  dialogRoot: {
    minWidth: theme.breakpoints.values.sm,
    maxHeight: '85%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.breakpoints.values.sm,
      minWidth: theme.breakpoints.values.xs,
    },
  },
  new: {
    backgroundColor: colors.lightBlue[800],
    borderRadius: '44px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 600,
    height: '26px',
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  improvement: {
    backgroundColor: colors.lightGreen[500],
    borderRadius: '44px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 600,
    height: '26px',
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  dialogContentRoot: {
    padding: '10px 35px',
  },
}))

export default styles
