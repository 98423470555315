import permissions from '../permissions'

const PRIVACY_POLICY_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.VISUALIZE,
    tag: permissions.PRIVACY_POLICIES.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.PRIVACY_POLICIES.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.PRIVACY_POLICIES.DESTROY,
  },
]

export default PRIVACY_POLICY_LIST
