import { makeStyles } from '@material-ui/core'

import rectangleBlue from 'images/rectangle_blue.svg'
import rectangleRed from 'images/rectangle_red.svg'

const styles = makeStyles((theme) => ({
  cardIncidents: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(4.5),
    width: 'auto',
    height: 250,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionY: 'bottom',
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      height: 'fit-content',
    },
  },
  cardIncidentsBlue: {
    backgroundImage: `url(${rectangleBlue})`,
    backgroundColor: theme.palette.trailWarning,
  },
  cardIncidentsRed: {
    backgroundImage: `url(${rectangleRed})`,
    backgroundColor: '#c00d19',
  },
  numberIncidents: {
    fontSize: 81,
    fontWeight: 800,
    marginBottom: -26,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  seeMoreAction: {
    color: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  resolvedIncidents: {
    color: `${theme.palette.common.white} !important`,
    letterSpacing: 1,
  },
  incidentsAlertImg: {
    maxWidth: 150,
    width: '10vw',
    height: 'fit-content',
  },
  incidentsActionsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  incidentsValueBox: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}))

export default styles
