import React, { useState } from 'react'
import { Box, Grid } from '@material-ui/core'

import { Container, Page, RoundedBorder } from 'components'
import {
  CompanyFragilitiesPdf,
  Header,
  ReportVersionExistsDialog,
  ReportVersionTable,
} from './components'
import {
  DepartmentsByThreats,
  DistributionRisksDegreeSeverity,
} from 'components/DashboardDiagnostic'

import useFetch from 'hooks/useFetch'

import myLgpdHelpers from 'helpers/myLgpd'

import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'

const FragilityReport = () => {
  const [exportingPdf, setExportingPdf] = useState(false)
  const [refresher, setRefresher] = useState(false)
  const [reportVersionExistsOpen, setReportVersionExistsOpen] = useState(false)
  const [currentReportVersion, setCurrentReportVersion] = useState()

  const classes = useStyles()

  const { response: fragilitiesResponse, isLoading: fragilitiesIsLoading } =
    useFetch(
      service.dponet.companyFragilities.get,
      { withSecurityMeasures: true },
      [],
      exportingPdf,
    )

  const {
    response: fragilityStatsResponse,
    isLoading: fragilityStatsIsLoading,
  } = useFetch(service.dponet.myLgpd.getFragilityStats, {}, [])

  const companyFragilities = fragilitiesResponse?.data?.companyFragilities

  const fragilityStats = fragilityStatsResponse?.data?.stats

  const buttonAction = () => {
    if (!!currentReportVersion?.id) {
      return setReportVersionExistsOpen(true)
    }
    setExportingPdf(true)
  }

  const departmentsHighestOfThreats = myLgpdHelpers.threatsByDepartment(
    fragilityStats?.departments,
    ['stats.totalCount'],
    ['desc'],
  )

  const departmentsWithLowerSeverity = myLgpdHelpers.threatsByDepartment(
    fragilityStats?.departments,
    ['stats.severe', 'stats.high', 'stats.medium', 'stats.low'],
    ['asc', 'asc', 'asc', 'asc'],
  )

  const departmentsWithGreaterSeverity = myLgpdHelpers.threatsByDepartment(
    fragilityStats?.departments,
    ['stats.severe', 'stats.high', 'stats.medium', 'stats.low'],
    ['desc', 'desc', 'desc', 'desc'],
  )

  return (
    <Page className={classes.root} title="Relatório de Ameaças">
      <Box position="relative">
        <RoundedBorder />
      </Box>
      <Container maxWidth={false} spacingXl={15} spacingLg={10} spacingMd={5}>
        <Box py={5}>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={12}>
              <Header
                type="fragility"
                reportTour="ameacas"
                isLoading={fragilitiesIsLoading || fragilityStatsIsLoading}
                buttonAction={buttonAction}
                disabled={exportingPdf}
              />
            </Grid>
            {!fragilityStatsIsLoading && (
              <Grid item md={6} xs={12}>
                <DistributionRisksDegreeSeverity
                  id="distribution-of-threats-by-severity"
                  fragilityStats={fragilityStats}
                />
              </Grid>
            )}
            <Grid item md={6} xs={12}>
              <DepartmentsByThreats
                id="departments-with-the-highest-number-of-threats"
                title="Departamentos por maior quantidade de ameaças"
                subtitle="Ranking dos quatro departamentos com maior quantidade"
                values={departmentsHighestOfThreats}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DepartmentsByThreats
                id="departments-to-lower-threat-severity"
                title="Departamentos por menor severidade de ameaças"
                subtitle="Ranking dos quatro departamentos com menor severidade"
                values={departmentsWithLowerSeverity}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DepartmentsByThreats
                id="departments-to-greater-threat-severity"
                title="Departamentos por maior severidade de ameaças"
                subtitle="Ranking dos quatro departamentos com maior severidade"
                values={departmentsWithGreaterSeverity}
              />
            </Grid>
            <Grid item xs={12}>
              <ReportVersionTable
                currentReportVersion={currentReportVersion}
                kind={constants.reportVersions.KIND.FRAGILITY}
                refresher={refresher}
                setCurrentReportVersion={setCurrentReportVersion}
              />
            </Grid>
          </Grid>
          {companyFragilities && (
            <CompanyFragilitiesPdf
              exportingPdf={exportingPdf}
              setExportingPdf={setExportingPdf}
              refresh={() => setRefresher(!refresher)}
              shouldCreateVersion={!currentReportVersion?.id}
              fragilities={companyFragilities}
            />
          )}
          <ReportVersionExistsDialog
            currentReportVersion={currentReportVersion}
            exportAction={() => setExportingPdf(true)}
            open={reportVersionExistsOpen}
            setCurrentReportVersion={setCurrentReportVersion}
            setOpen={setReportVersionExistsOpen}
          />
        </Box>
      </Container>
    </Page>
  )
}

export default FragilityReport
