import { makeStyles } from '@material-ui/core'
import hexagons from 'images/hexagonos_background.svg'

const styles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    backgroundImage: `url(${hexagons})`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      borderRadius: 20,
      height: '44vh',
      backgroundPositionX: theme.spacing(4),
      backgroundPositionY: 'center',
      backgroundSize: '35vh',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      flexDirection: 'column',
      backgroundPositionY: theme.spacing(2),
      backgroundPositionX: 'center',
      backgroundSize: '60%',
      borderRadius: 4,
    },
  },
  typographyDescription: {
    fontWeight: 500,
    fontSize: '2.3vh',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
  noWrap: {
    [theme.breakpoints.up('lg')]: {
      whiteSpace: 'nowrap',
      alignSelf: 'center',
    },
  },
  imgCertificate: {
    [theme.breakpoints.up('md')]: {
      objectPosition: '15px',
    },
  },
  spanImage: {
    fontSize: '80%',
    verticalAlign: 'top',
  },
  titleCard: {
    fontSize: '3vh',
    fontWeight: 600,
  },
}))

export default styles
