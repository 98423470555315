import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import qs from 'query-string'
import { Box, Button, Paper, Typography } from '@material-ui/core'

import useStyles from './styles'

const DashboardChartPaper = ({
  id,
  children,
  title,
  subtitle,
  heightContainer,
  redirect,
  redirectParams,
  noMaxHeight = false,
  ...rest
}) => {
  const classes = useStyles(heightContainer)()
  const history = useHistory()

  const handleRedirect = () => {
    history.replace(redirect + '?' + qs.stringify(redirectParams))
  }

  return (
    <Paper
      className={clsx(classes.root, { [classes.paperContent]: noMaxHeight })}
      {...rest}
    >
      <Box
        id={id}
        className={classes.columnBox}
        justifyContent={noMaxHeight ? 'start !important' : 'undefined'}
      >
        <Box className={classes.cardHeader}>
          <Typography variant="h5" className={classes.cardTitle}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body2" color="secondary">
              {subtitle}
            </Typography>
          )}
        </Box>
        <Box className={classes.cardContent}>{children}</Box>
        {!!redirect && (
          <Box p={1}>
            <Button variant="text" onClick={handleRedirect}>
              Ver mais
            </Button>
          </Box>
        )}
      </Box>
    </Paper>
  )
}

DashboardChartPaper.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  heightContainer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  redirect: PropTypes.string,
  redirectParams: PropTypes.object,
  noMaxHeight: PropTypes.bool,
}

export default DashboardChartPaper
