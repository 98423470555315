import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  menuItem: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  error: {
    color: theme.palette.error.main,
  },
}))

export default styles
