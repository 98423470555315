import React, { useState } from 'react'

import { Box } from '@material-ui/core'
import { CardProduct, RoundedBox, RoundedButton, Text } from '..'
import { LoadingFeedback } from 'components'

import useFirstStep from 'hooks/useFirstStep'
import useAuth from 'hooks/useAuth'

import adoptImage from 'images/adopt.svg'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'

import theme from 'theme'

const ProductPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { activeStep, activeSubStep, handleNext, jumpToFinalize } =
    useFirstStep()
  const { user } = useAuth()
  const snackbar = useSnackbar()

  const requestProduct = async () => {
    try {
      setIsLoading(true)
      await service.dponet.users.solicitationBannerCookies({
        userId: user.id,
      })
    } catch (error) {
      snackbar.open({
        message:
          'Houve um erro ao enviar sua solicitação, por favor, entre em contato através do e-mail atendimento@dponet.com.br',
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
      handleNext()
    }
  }

  const handleJump = () =>
    jumpToFinalize(
      'O cliente possui site porém não solicitou o produto sugerido',
    )

  return (
    <RoundedBox noOpacity maxWidth={theme.breakpoints.values.lg}>
      <LoadingFeedback open={isLoading} />
      <Box width="100%" display="flex" justifyContent="center">
        <Box
          width="100%"
          p={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxWidth={theme.breakpoints.values.md}
        >
          <Text fontSize="4vh" variant="h2">
            Com base na sua resposta, indicamos esse produto para a sua
            segurança
          </Text>
          <Box mt={2}>
            <CardProduct active description="Banner de cookies para o seu site">
              <Box mt={1} width="70%">
                <img src={adoptImage} alt="adopt" width="100%" />
              </Box>
            </CardProduct>
          </Box>
          <Box display="flex" alignItems="center" my={4}>
            <RoundedButton
              variant="primary"
              withShadow
              withPadding
              onClick={requestProduct}
              disabled={isLoading}
              mixpanelId={helpers.userSteps.defineMixpanelId(
                activeStep,
                activeSubStep,
                'solitar-produto',
              )}
            >
              Solicitar
            </RoundedButton>
            <Box ml={2}>
              <RoundedButton
                withPadding
                onClick={handleJump}
                variant="text"
                disabled={isLoading}
                mixpanelId={helpers.userSteps.defineMixpanelId(
                  activeStep,
                  activeSubStep,
                  'pular',
                )}
              >
                Pular
              </RoundedButton>
            </Box>
          </Box>
          <Text fontSize={16} fontHeight={400} variant="body1" gutterBottom>
            Entraremos em contato por e-mail com as instruções
          </Text>
        </Box>
      </Box>
    </RoundedBox>
  )
}

export default ProductPage
