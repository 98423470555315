import constants from 'constants/index'

const statusChipColor = (status, classes) => {
  switch (status) {
    case constants.activities.ONGOING_STATUS:
      return classes.onGoing
    case constants.activities.PENDING_STATUS:
      return classes.pending
    case constants.activities.OVERDUE_STATUS:
      return classes.overdue
    case constants.activities.FINALIZED_STATUS:
      return classes.finalized
    default:
      return ''
  }
}

export default statusChipColor
