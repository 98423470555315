const JURIDIC_KIND = 'C'
const PERSONAL_KIND = 'P'
const TEMPLATE_KIND = 'T'
const SUPPLIER_KIND = 'S'

const company = {
  JURIDIC_KIND,
  PERSONAL_KIND,
  TEMPLATE_KIND,
  SUPPLIER_KIND,
}

export default company
