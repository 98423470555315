import React, { useState } from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'
import theme from 'theme'
import * as service from 'service'
import helpers from 'helpers'

import badColorful from 'images/ratings/bad-colorful.png'
import veryBadColorful from 'images/ratings/very-bad-colorful.png'
import neutralColorful from 'images/ratings/neutral-colorful.png'
import goodColorful from 'images/ratings/good-colorful.png'
import veryGoodColorful from 'images/ratings/very-good-colorful.png'

const TicketFeedback = ({ ticketId, ticketFeedback, refresh }) => {
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()

  const ratings = [
    { name: 'very_bad', image: veryBadColorful },
    { name: 'bad', image: badColorful },
    { name: 'neutral', image: neutralColorful },
    { name: 'good', image: goodColorful },
    { name: 'very_good', image: veryGoodColorful },
  ]

  const handleSelectFeedback = async (ratingName) => {
    try {
      if (!!ticketFeedback) return

      setLoading(true)

      await service.dponet.tickets.createTicketFeedback({
        ticketId: ticketId,
        ticketFeedback: {
          rating: ratingName,
        },
      })

      setLoading(false)

      refresh()

      snackbar.open({
        message: 'Obrigado pelo seu feedback!',
        variant: 'success',
      })
    } catch (error) {
      setLoading(false)

      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }

  return (
    <Paper component={Box} elevation={2} p={3}>
      <LoadingFeedback loading={loading} />

      <Typography variant="h5">Como você avalia nosso suporte?</Typography>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        gridGap={theme.spacing(2)}
      >
        {ratings.map((rating) => (
          <Box
            key={rating.name}
            onClick={() => handleSelectFeedback(rating.name)}
          >
            <img
              src={rating.image}
              alt={rating.name}
              className={clsx(classes.image, {
                [classes.selectedImage]: ticketFeedback?.rating === rating.name,
                [classes.unselectedImage]:
                  ticketFeedback?.rating !== rating.name,
                [classes.enabled]: !ticketFeedback,
              })}
            />
          </Box>
        ))}
      </Box>
    </Paper>
  )
}

export default TicketFeedback
