import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.hover,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  inputFile: {
    display: 'none',
  },
}))

export default styles
