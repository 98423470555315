import constants from 'constants/index'

const defineCardStatus = (progress) => {
  if (parseInt(progress) === 100) {
    return constants.companyTrail.CONCLUDED_STATUS
  }

  if (parseInt(progress) === 0) {
    return constants.companyTrail.NOT_INITIATED_STATUS
  }

  return constants.companyTrail.DOING_STATUS
}

export default defineCardStatus
