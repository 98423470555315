import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  headerTitle: {
    fontWeight: 600,
  },
}))

export default styles
