const companyTrailByItem = ({ item, companyTrails }) => {
  const companyTrail = companyTrails?.filter((companyTrail) => {
    if (item.title === 'Faça seu Diagnóstico') {
      return companyTrail.name === 'Gap Analysis'
    }

    if (item.title === 'Mapeamento de Processos') {
      return companyTrail.name === 'Data Mapping'
    }

    return companyTrail.name === item.title
  })
  return companyTrail[0]
}

export default companyTrailByItem
