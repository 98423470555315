const JUSTIFICATION = [
  {
    name: 'Alto risco',
    description:
      'O RIPD foi gerado visando descrever e gerir os processos de tratamento de dados pessoais que podem gerar altos riscos às liberdades civis e aos direitos fundamentais, bem como medidas, salvaguardas e mecanismos de mitigação de risco, nos termos do arts. 5º, inciso XVII e 6º, inciso X, da LGPD.',
  },
  {
    name: 'Legítimo Interesse',
    description:
      'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou ao controlador a emissão do RIPD, em decorrência da presença do fundamento do Legítimo Interesse no presente processo, observados os segredos comercial e industrial, nos termos do art. 10, § 3º, da LGPD.',
  },
  {
    name: 'Poder Público',
    description:
      'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou aos agentes do Poder Público a publicação do RIPD, nos termos do art. 32 da LGPD.',
  },
  {
    name: 'Dados pessoais sensíveis',
    description:
      'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou ao controlador a emissão do RIPD, em decorrência da presença de dados pessoais sensíveis neste processo, nos termos do art. 38, da LGPD.',
  },
  {
    name: 'Princípio da segurança e da prevenção',
    description:
      'O controlador gerou o presente RIPD, visando o cumprimento dos princípios da segurança e da prevenção, nos termos do art. 6º, VII e VIII, da LGPD, a fim de implementar programa de governança em privacidade que, entre outros itens, deve estabelecer políticas e salvaguardas adequadas com base em processo de avaliação sistemática de impactos e riscos à privacidade, conforme determinação do art. 50, § 2º, I, d,d a LGPD.',
  },
  {
    name: 'Operações de tratamento efetuadas para fins exclusivos de segurança pública, defesa nacional, segurança do Estado ou atividades de investigação e repressão de infrações penais',
    description:
      'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou aos responsáveis por operações de tratamento para fins de segurança pública, defesa nacional, segurança do Estado ou atividades de investigação e repressão de infrações penais, a emissão do RIPD, nos termos do art. 4º,  § 3º, da LGPD.',
  },
]

const JUSTIFICATION_LABEL = {
  'Alto risco':
    'O RIPD foi gerado visando descrever e gerir os processos de tratamento de dados pessoais que podem gerar altos riscos às liberdades civis e aos direitos fundamentais, bem como medidas, salvaguardas e mecanismos de mitigação de risco, nos termos do arts. 5º, inciso XVII e 6º, inciso X, da LGPD.',
  'Legítimo Interesse':
    'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou ao controlador a emissão do RIPD, em decorrência da presença do fundamento do Legítimo Interesse no presente processo, observados os segredos comercial e industrial, nos termos do art. 10, § 3º, da LGPD.',

  'Poder Público':
    'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou aos agentes do Poder Público a publicação do RIPD, nos termos do art. 32 da LGPD.',

  'Dados pessoais sensíveis':
    'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou ao controlador a emissão do RIPD, em decorrência da presença de dados pessoais sensíveis neste processo, nos termos do art. 38, da LGPD.',
  'Princípio da segurança e da prevenção':
    'O controlador gerou o presente RIPD, visando o cumprimento dos princípios da segurança e da prevenção, nos termos do art. 6º, VII e VIII, da LGPD, a fim de implementar programa de governança em privacidade que, entre outros itens, deve estabelecer políticas e salvaguardas adequadas com base em processo de avaliação sistemática de impactos e riscos à privacidade, conforme determinação do art. 50, § 2º, I, d,d a LGPD.',
  'Operações de tratamento efetuadas para fins exclusivos de segurança pública, defesa nacional, segurança do Estado ou atividades de investigação e repressão de infrações penais':
    'A ANPD (Autoridade Nacional de Proteção de Dados) solicitou aos responsáveis por operações de tratamento para fins de segurança pública, defesa nacional, segurança do Estado ou atividades de investigação e repressão de infrações penais, a emissão do RIPD, nos termos do art. 4º,  § 3º, da LGPD.',
}

const RISK = [
  { id: 3, name: 'Alto' },
  { id: 4, name: 'Severo' },
  { id: 5, name: 'Alto e Severo' },
]

const RISK_ROPA = [
  { id: 1, name: 'Baixo' },
  { id: 2, name: 'Médio' },
  { id: 3, name: 'Alto' },
  { id: 4, name: 'Severo' },
  { id: 5, name: 'Alto e Severo' },
]

const DESCRIPTION_RIPD = {
  title: 'Relatório de Impacto à Proteção de Dados Pessoais ',
  content:
    'Em geral, o Relatório de Impacto à Proteção de Dados Pessoais (RIPD) é ' +
    'um documento que estabelece a gestão de riscos da sua organização. Ele ' +
    'contém a descrição detalhada dos seus processos de tratamento de dados ' +
    'pessoais que possuem risco alto ou severo e quais medidas são adotadas ' +
    'para mitigação dos riscos. Pode haver exceções, como:',
  options: [
    'Hipótese de Legítimo Interesse;',
    'Outras obrigações legais;',
    'Segurança Pública;',
    'Entre outros.',
  ],
}

const DESCRIPTION_ROPA = {
  title: 'Registro das Atividades de Tratamento (RoPA)',
  content:
    'O Registro das Atividades de Tratamento (traduzido da sigla em inglês, ' +
    'Record of Processing Activities, RoPA, é um documento essencial do ' +
    'Regulamento Geral de Proteção de Dados (GDPR) da União Europeia. É ' +
    'um documento que detalha todas as atividades de processamento de dados ' +
    'pessoais realizadas por uma organização. O RoPA inclui informações ' +
    'sobre a finalidade do processamento, as categorias de dados pessoais ' +
    'envolvidos, os enquadramentos legais, os destinatários dos dados, os ' +
    'prazos de retenção dos dados e as medidas de segurança implementadas, ' +
    'dentre outras informações. O RoPA desempenha um papel fundamental na ' +
    'conformidade com o GDPR, ajudando as organizações a demonstrar ' +
    'transparência e responsabilidade no tratamento de dados pessoais.',
  options: [],
}

const DESCRIPTION = {
  true: DESCRIPTION_ROPA,
  false: DESCRIPTION_RIPD,
}

const CHARACTER_LIMIT = 500

export default {
  CHARACTER_LIMIT,
  DESCRIPTION,
  JUSTIFICATION_LABEL,
  JUSTIFICATION,
  RISK_ROPA,
  RISK,
}
