import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Grid, Box } from '@material-ui/core'

import { useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'
import usePermissions from 'hooks/usePermissions'
import useAuth from 'hooks/useAuth'

import { LoadingFeedback } from 'components'
import { DataIncident } from '../../../Simplified/components'
import { DataController, Commissioner, Representative } from './components'

import * as service from 'service'
import constants from 'constants/index'
import schema from './schema'

import helpers from 'helpers'

const GeneralInformation = ({ incident, idForm, handleNext, stepName }) => {
  const snackbar = useSnackbar()
  const permissions = usePermissions()

  const {
    userCompany: { company },
  } = useAuth()

  const { controller, commissioner, representative } =
    incident?.incidentDataAgents || {}

  const [dataRepresentative, SetDataRepresentative] = useState(
    representative && representative[0]?.carriedProcessedData
      ? 'true'
      : 'false',
  )

  const [smallCompany, setSmallCompany] = useState(
    (controller && controller[0])?.declaresSmallCompany?.toString() || 'false',
  )
  const [smallAgentTreatment, setSmallAgentTreatment] = useState(
    (controller && controller[0])?.declaresSmallAgentTreatment?.toString() ||
      'false',
  )

  const [loading, setLoading] = useState(false)
  const hasEditPermission =
    !incident || permissions.permitted(constants.permissions.INCIDENTS.CREATE)

  const RepresentativeDefaultValue = (attribute) => {
    if (
      representative &&
      representative[0]?.carriedProcessedData &&
      commissioner &&
      commissioner[0]?.name
    ) {
      return commissioner[0][attribute]
    }

    if (representative && !representative[0]?.carriedProcessedData) {
      return representative[0][attribute]
    }

    return ''
  }

  const { handleSubmit, errors, control, watch, reset, getValues, setValue } =
    useForm({
      validationSchema: schema,
      defaultValues: {
        incidentDate: incident?.incidentDatetime
          ? moment(incident?.incidentDatetime).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        incidentHour: incident?.incidentDatetime
          ? moment(incident?.incidentDatetime)
          : moment(),
        affectedHolders: incident?.affectedHolders ?? '',
        nameController: (controller && controller[0]?.name) ?? company?.name,
        documentController:
          (controller &&
            helpers.formatters.cnpjOrCpf(controller[0]?.document)) ??
          helpers.formatters.cnpjOrCpf(company?.document),
        emailController: (controller && controller[0]?.email) ?? '',
        phoneController:
          (controller &&
            helpers.formatters.phoneWithoutCountryCode(controller[0]?.phone)) ??
          '',
        city:
          (controller && controller[0]?.city) ?? (company?.address?.city || ''),
        state:
          (controller && controller[0]?.state) ??
          (company?.address?.state || ''),
        cep:
          (controller && helpers.formatters.cep(controller[0]?.cep)) ??
          (company.address?.postalCode || ''),
        treatmentHolder: (controller && controller[0]?.treatmentHolder) ?? '',
        nameCommissioner:
          (commissioner && commissioner[0]?.name) ??
          'DPOnet Desenvolvimento de Sistemas e Consultoria em Segurança da Informação Ltda',
        documentCommissioner:
          (commissioner &&
            helpers.formatters.cnpjOrCpf(commissioner[0]?.document)) ??
          '36.487.128/0001-79',
        emailCommissioner:
          (commissioner && commissioner[0]?.email) ??
          'atendimento@dponet.com.br',
        phoneCommissioner:
          (commissioner &&
            helpers.formatters.phoneWithoutCountryCode(
              commissioner[0]?.phone,
            )) ??
          '(14) 3333-1970',
        nameRepresentative: RepresentativeDefaultValue('name'),
        documentRepresentative: helpers.formatters.cnpjOrCpf(
          RepresentativeDefaultValue('document'),
        ),
        emailRepresentative: RepresentativeDefaultValue('email'),
        phoneRepresentative: helpers.formatters.phoneWithoutCountryCode(
          RepresentativeDefaultValue('phone'),
        ),
        legalOpinion: incident?.legalOpinion ?? '',
      },
    })

  const cnpjCpf = (field) => {
    return helpers.functions.cnpjCpf(field, 'isValid')
  }

  const onSubmit = async (incidentData) => {
    if (incidentData?.incidentHour) {
      incidentData.incidentHour = moment(
        incidentData.incidentHour,
        'HH:mm:ss',
      ).format('HH:mm')
    }

    if (incidentData?.incidentDate) {
      incidentData.incidentDate = moment(incidentData.incidentDate).format(
        'DD/MM/YYYY',
      )
    }

    try {
      setLoading(true)

      const data = helpers.incidents.mountStepGeneralInformation(
        incidentData,
        dataRepresentative,
        smallCompany,
        smallAgentTreatment,
      )

      if (watch('documentController')) {
        const docController = cnpjCpf(watch('documentController'))
        const docommissioner = cnpjCpf(watch('documentCommissioner'))
        const docRepresentative = cnpjCpf(watch('documentRepresentative'))

        if (!docController || !docommissioner || !docRepresentative) {
          snackbar.open({
            message: 'O CNPJ/CPF informado não é válido.',
            variant: 'error',
          })
          setLoading(false)
          return
        }
      }

      await service.dponet.incidents.put({
        incidentId: incident?.id,
        incident: {
          ...helpers.incidents.mountDefault(incident),
          ...data,
          simplifiedForm: false,
        },
      })

      setLoading(false)
      handleNext()

      snackbar.open({
        message: `${stepName} atualizada com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: `Ocorreu um erro ao salvar a ${stepName}!`,
        variant: 'error',
      })
      setLoading(false)
      reset(getValues())
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <form
        id={idForm}
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit(onSubmit)()
        }}
      >
        <Box px={3} pb={3} mt={3} mb={1}>
          <Grid container spacing={1}>
            <DataIncident
              label="Dados do incidente"
              errors={errors}
              control={control}
              hasEditPermission={hasEditPermission}
            />
            <DataController
              errors={errors}
              control={control}
              smallCompany={smallCompany}
              setSmallCompany={setSmallCompany}
              smallAgentTreatment={smallAgentTreatment}
              setSmallAgentTreatment={setSmallAgentTreatment}
              constroller={controller && controller[0]}
              companyPostalCode={company.address?.postalCode}
              watch={watch}
              setValue={setValue}
            />
            <Commissioner errors={errors} control={control} />
            <Representative
              errors={errors}
              control={control}
              dataRepresentative={dataRepresentative}
              SetDataRepresentative={SetDataRepresentative}
              representative={representative && representative[0]}
              watch={watch}
              setValue={setValue}
            />
          </Grid>
        </Box>
      </form>
    </>
  )
}

GeneralInformation.propTypes = {
  idForm: PropTypes.string,
  handleNext: PropTypes.func,
  incident: PropTypes.object,
}

GeneralInformation.defaultProps = {
  handleNext: () => {},
}

export default GeneralInformation
