import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { X as CloseIcon } from 'react-feather'

import constants from 'constants/index'
import useStyles from './styles'

const BaseDialog = ({
  open,
  title,
  closeButtonText,
  actionButtonText,
  children,
  actionButton,
  setOpen,
  dialogSize,
  formRef,
  loading,
  mixpanelTrack,
  companySupplier,
  resetFields,
  closeIconButton,
  withoutBottomDivider,
  tag,
  fullWidth,
  justifyActions,
}) => {
  const classes = useStyles()
  const newTag = tag === constants.lastUpdates.KINDS_LABELS.N

  const handleClose = () => {
    if (companySupplier?.canResendSolicitation === true) {
      mixpanelTrack()
    }
    if (resetFields) {
      resetFields(null)
    }
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={dialogSize}
      fullWidth={!!fullWidth}
      PaperProps={{
        className: classes.dialogRoot,
      }}
    >
      <DialogTitle
        disableTypography
        classes={{ root: classes.muiDialogTitleOverride }}
      >
        <Box className={classes.boxTitle}>
          <Box className={classes.boxTitleTwo}>
            {tag && (
              <Box>
                <Chip
                  className={newTag ? classes.new : classes.improvement}
                  label={tag}
                />
              </Box>
            )}
            <Box>
              <Tooltip
                title={title}
                placement="top"
                disableHoverListener={!tag}
              >
                <Typography className={classes.title} variant="h4">
                  {title}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
          {closeIconButton && (
            <IconButton onClick={actionButton}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ padding: tag && '10px 40px' }}>
        {children}
      </DialogContent>
      {!withoutBottomDivider && (
        <>
          <Divider />
          <DialogActions>
            <Box
              display="flex"
              my={1}
              justifyContent={justifyActions}
              width="100%"
            >
              <Box pl={2} pr={1}>
                <Button type="button" variant="outlined" onClick={handleClose}>
                  {closeButtonText}
                </Button>
              </Box>
              {actionButtonText && (
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={actionButton}
                  disabled={loading}
                  form={formRef}
                >
                  {actionButtonText}
                </Button>
              )}
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

BaseDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  closeButtonText: PropTypes.string,
  actionButtonText: PropTypes.string,
  actionButton: PropTypes.func,
  setOpen: PropTypes.func,
  dialogSize: PropTypes.string,
  formRef: PropTypes.string,
  loading: PropTypes.bool,
  closeIconButton: PropTypes.bool,
  withoutBottomDivider: PropTypes.bool,
  tag: PropTypes.string,
  fullWidth: PropTypes.bool,
  justifyActions: PropTypes.string,
}

BaseDialog.defaultProps = {
  title: '',
  closeButtonText: '',
  actionButtonText: '',
  actionButton: () => {},
  setOpen: () => {},
  dialogSize: 'sm',
  loading: false,
  closeIconButton: false,
  withoutBottomDivider: false,
  tag: '',
  justifyActions: 'flex-start',
}

export default BaseDialog
