import React from 'react'
import { Box, Typography } from '@material-ui/core'

import BaseStatus from '../BaseStatus'

const Processing = () => {
  return (
    <BaseStatus
      status="processing"
      description="Pagamento está sendo processado!"
    >
      <Box>
        <Typography variant="h4" color="textPrimary">
          Olá
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Realize o pagamento do DPOnet. Caso tenha realizado, aguarde a
          efetivação do pagamento.
        </Typography>
      </Box>
    </BaseStatus>
  )
}

export default Processing
