import api from 'service/api'

const dponetAPI = api.create('dponet')

const destroy = async ({
  dataProcessId,
  dataTreatmentId,
  sharedSoftwareId,
}) => {
  return await dponetAPI.delete(
    `/data_processes/${dataProcessId}/data_treatments/${dataTreatmentId}/shared_softwares/${sharedSoftwareId}`,
  )
}

const create = async ({ dataProcessId, dataTreatmentId, ...params }) => {
  return await dponetAPI.post(
    `/data_processes/${dataProcessId}/data_treatments/${dataTreatmentId}/shared_softwares`,
    params,
  )
}

const put = async ({
  dataProcessId,
  dataTreatmentId,
  sharedSoftwareId,
  ...params
}) => {
  return await dponetAPI.put(
    `/data_processes/${dataProcessId}/data_treatments/${dataTreatmentId}/shared_softwares/${sharedSoftwareId}`,
    params,
  )
}

const internationalTransferDestroy = async ({
  dataProcessId,
  dataTreatmentId,
  sharedSoftwareId,
  internationalTransferId,
}) => {
  return await dponetAPI.delete(
    `data_processes/${dataProcessId}/data_treatments/${dataTreatmentId}/shared_softwares/${sharedSoftwareId}/international_transfers/${internationalTransferId}`,
  )
}

export default { destroy, create, put, internationalTransferDestroy }
