import categories from './categories'
import orders from './orders'
import partners from './partners'
import redirectLinks from './redirectLinks'
import serviceCompanies from './serviceCompanies'

const marketPlace = {
  categories,
  orders,
  partners,
  redirectLinks,
  serviceCompanies,
}

export default marketPlace
