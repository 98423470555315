import { setLocale } from 'yup'

setLocale({
  mixed: {
    required: 'Preencha esse campo',
    default: 'Preencha esse campo de forma válida',
  },
  number: {
    typeError: 'Precisa ser um número',
    integer: 'Digite um número inteiro',
    positive: 'Digite um número positivo',
    min: (params) => `Digite um número maior que ${params.min}`,
    max: (params) => `Digite um número menor que ${params.max}`,
    lessThan: (params) => `Digite um número menor que ${params.less}`,
    moreThan: (params) => `Digite um número maior que ${params.more}`,
    notEqual: (params) => `Digite um número diferente de ${params.notEqual}`,
  },
  date: {
    min: (params) => `A data deve ser maior que ${params.min}`,
    max: (params) => `A data deve ser menor que ${params.min}`,
  },
  string: {
    length: (params) => `Deve ter exatamente ${params.length} caracteres`,
    min: (params) => `Deve ter pelo menos ${params.min} caracteres`,
    max: (params) => `Deve ter no máximo ${params.max} caracteres`,
    email: 'Digite um e-mail válido',
    url: 'Digite uma URL válida com HTTP ou HTTPS. Ex: https://www.dponet.com.br',
    trim: 'Retirar os espaços do início ou fim',
    lowercase: 'Deve estar em minúsculo',
    uppercase: 'Deve estar em maiúsculo',
  },
  array: {
    min: (params) => `Selecione pelo menos ${params.min} opção`,
  },
})
