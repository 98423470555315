import React, { useState } from 'react'

import * as service from 'service'
import DataProcessOptionsContext from 'contexts/DataProcessOptionsContext'

const DataProcessOptionsProvider = ({ children }) => {
  const [data, setData] = useState(null)
  const [dataProcesses, setDataProcesses] = useState([])
  const [reload, setReload] = useState(false)
  const [sourceOptions, setSourceOptions] = useState([])
  const [dataProcessTemplate, setDataProcessTemplate] = useState()

  const loadData = async () => {
    const [responseOptions, responseDataProcessTemplate] = await Promise.all([
      service.dponet.dataProcesses.getRelationOptions({
        companyId: service.dponet.auth.getCompany(),
      }),
      service.dponet.dataProcessTemplates.get(),
    ])

    setData(responseOptions.data)
    setDataProcessTemplate(
      responseDataProcessTemplate?.data?.dataProcessTemplate,
    )
  }

  return (
    <DataProcessOptionsContext.Provider
      value={{
        data,
        loadData,
        dataProcesses,
        setDataProcesses,
        reload,
        setReload,
        sourceOptions,
        setSourceOptions,
        dataProcessTemplate,
      }}
    >
      {children}
    </DataProcessOptionsContext.Provider>
  )
}

export default DataProcessOptionsProvider
