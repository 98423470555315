const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  customTypography: {
    color: '#ADE5FF',
  },
  whiteTypography: {
    color: theme.palette.common.white,
  },
})

export default styles
