const OVERDUE_STATUS = 'overdue'
const IN_PROGRESS_STATUS = 'in_progress'
const ADOPTED_STATUS = 'adopted'
const PENDING_STATUS = 'pending'
const TREATED_STATUS = 'treated'
const RISK_ASSUMED_STATUS = 'risk_assumed'
const INACTIVE_STATUS = 'inactive'

const INITIAL_PLAN_STATUS = 'initial'
const HEADWAY_PLAN_STATUS = 'headway'
const CONCLUSION_PLAN_STATUS = 'conclusion'

const PLAN_ACTION_KIND = 'plan_action'
const EVIDENCE_KIND = 'evidence'

const KIND = {
  ALL: 'all',
  BASIC_REQUIREMENT: 'Requisitos Fundamentais',
  GOVERNANCE_CULTURE: 'Governança e Cultura',
  INFRASTRUCTURE_TECHNOLOGY: 'Infraestrutura e Tecnologia',
  JURIDIC: 'Jurídico',
}

const KIND_OPTIONS = [
  { id: KIND.BASIC_REQUIREMENT, name: 'Requisitos Fundamentais' },
  { id: KIND.GOVERNANCE_CULTURE, name: 'Governança e Cultura' },
  {
    id: KIND.INFRASTRUCTURE_TECHNOLOGY,
    name: 'Infraestrutura e Tecnologia',
  },
  { id: KIND.JURIDIC, name: 'Jurídico' },
]

const STATUSES = [
  { id: 'overdue', name: 'Atrasado' },
  { id: 'in_progress', name: 'Em andamento' },
  { id: 'adopted', name: 'Adotado' },
  { id: 'pending', name: 'Pendente' },
  { id: 'treated', name: 'Tratado' },
  { id: 'risk_assumed', name: 'Risco assumido' },
]

const ENABLE_ALERT = [ADOPTED_STATUS, IN_PROGRESS_STATUS, TREATED_STATUS]
const FINALIZED_ACTION = [IN_PROGRESS_STATUS, TREATED_STATUS, OVERDUE_STATUS]
const RISK_ACTION = [
  RISK_ASSUMED_STATUS,
  OVERDUE_STATUS,
  IN_PROGRESS_STATUS,
  PENDING_STATUS,
]
const ADOPTED_MEASURES = [ADOPTED_STATUS, TREATED_STATUS]

const QUESTION_CONTROL_DRIVER_STEP_1 = 'question-control-driver-step-1'
const QUESTION_CONTROL_DRIVER_STEP_2 = 'question-control-driver-step-2'
const QUESTION_CONTROL_DRIVER_STEP_3 = 'question-control-driver-step-3'
const QUESTION_CONTROL_DRIVER_STEP_4 = 'question-control-driver-step-4'
const QUESTION_CONTROL_DRIVER_STEP_5 = 'question-control-driver-step-5'
const QUESTION_CONTROL_DRIVER_STEP_6 = 'question-control-driver-step-6'
const QUESTION_CONTROL_DRIVER_STEP_7 = 'question-control-driver-step-7'

const QUESTION_CONTROL_DRIVER_STEP_OBJECT_4 = {
  element: '#' + QUESTION_CONTROL_DRIVER_STEP_4,
  popover: {
    title: 'Risco Assumido',
    description:
      'A empresa aceita o risco, fornecendo uma justificativa para a ação, visível em "informações". É possível ver o horário e o usuário que aceitou o risco. É possível dar continuidade com o tratamento do risco em "reabrir".',
  },
}

const QUESTION_CONTROL_DRIVER_STEP_OBJECT_5 = {
  element: '#' + QUESTION_CONTROL_DRIVER_STEP_5,
  popover: {
    title: 'Tratado',
    description: 'Ao finalizar um controle esse é o status definido.',
  },
}

const QUESTION_CONTROL_DRIVER_STEP_OBJECT_6 = {
  element: '#' + QUESTION_CONTROL_DRIVER_STEP_6,
  popover: {
    title: 'Adotado',
    description:
      'Aparece quando uma questão é respondida com <strong>SIM</strong>, com isso você pode "visualizar" ou "reabrir" adicionando ou editando evidências da implantação.',
  },
}

const QUESTION_CONTROL_DRIVER_STEP_OBJECT_7 = {
  element: '#' + QUESTION_CONTROL_DRIVER_STEP_7,
  popover: {
    title: 'Pendente',
    description:
      'Aparece para questões com respostas <strong>NÃO</strong> e <strong>PARCIAL</strong>, é possível "visualizar", "editar" ou "assumir o risco".',
  },
}

const QUESTION_CONTROL_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + QUESTION_CONTROL_DRIVER_STEP_1,
    popover: {
      description:
        'As Medidas de Segurança são associadas aos questionários respondidos como, Sim, Parcial e Não.',
    },
  },
  {
    element: '#' + QUESTION_CONTROL_DRIVER_STEP_2,
    popover: {
      description:
        'Também é possível cadastrar uma nova medida de forma livre, como por exemplo: Firewall IDS/IPS, Câmeras de Segurança.',
    },
  },
  {
    element: '#' + QUESTION_CONTROL_DRIVER_STEP_3,
    popover: {
      description:
        'Edite a medida de segurança, atribua responsáveis e um plano de ação, inclua evidências e mais informações. O campo de evidências será disponibilizado ao adicionar um plano de ação.',
    },
  },
]

const QUESTION_DRIVER_STATUS_ID = {
  [RISK_ASSUMED_STATUS]: QUESTION_CONTROL_DRIVER_STEP_4,
  [TREATED_STATUS]: QUESTION_CONTROL_DRIVER_STEP_5,
  [ADOPTED_STATUS]: QUESTION_CONTROL_DRIVER_STEP_6,
  [PENDING_STATUS]: QUESTION_CONTROL_DRIVER_STEP_7,
}

const QUESTION_DRIVER_SELECTED_STEPS = {
  [RISK_ASSUMED_STATUS]: QUESTION_CONTROL_DRIVER_STEP_OBJECT_4,
  [TREATED_STATUS]: QUESTION_CONTROL_DRIVER_STEP_OBJECT_5,
  [ADOPTED_STATUS]: QUESTION_CONTROL_DRIVER_STEP_OBJECT_6,
  [PENDING_STATUS]: QUESTION_CONTROL_DRIVER_STEP_OBJECT_7,
}

const QUESTION_CONTROL_EDIT_DRIVER_STEP_1 =
  'question-control-edit-driver-step-1'
const QUESTION_CONTROL_EDIT_DRIVER_STEP_2 =
  'question-control-edit-driver-step-2'

const QUESTION_CONTROL_EDIT_DRIVER_STEP_OBJECT_1 = {
  element: '#' + QUESTION_CONTROL_EDIT_DRIVER_STEP_1,
  popover: {
    description:
      'Edite uma medida de segurança <strong>Pendente</strong> ou <strong>Em Andamento</strong>, adicione um plano de ação, atribua um responsável e defina um prazo. Após salvar, o campo de evidências será habilitado para armazenar as provas da implementação. Concluindo o processo de evidências, clique em "Finalizar" para encerrar o controle.',
  },
}

const QUESTION_CONTROL_EDIT_DRIVER_STEP_OBJECT_2 = {
  element: '#' + QUESTION_CONTROL_EDIT_DRIVER_STEP_2,
  popover: {
    description:
      'Reabra uma medida de segurança <strong>Adotado</strong> ou Finalizado, para editar as evidências.',
  },
}

const questionControl = {
  ADOPTED_MEASURES,
  ADOPTED_STATUS,
  CONCLUSION_PLAN_STATUS,
  ENABLE_ALERT,
  EVIDENCE_KIND,
  FINALIZED_ACTION,
  HEADWAY_PLAN_STATUS,
  IN_PROGRESS_STATUS,
  INACTIVE_STATUS,
  INITIAL_PLAN_STATUS,
  KIND_OPTIONS,
  KIND,
  OVERDUE_STATUS,
  PENDING_STATUS,
  PLAN_ACTION_KIND,
  QUESTION_CONTROL_DRIVER_STEP_1,
  QUESTION_CONTROL_DRIVER_STEP_2,
  QUESTION_CONTROL_DRIVER_STEP_3,
  QUESTION_CONTROL_DRIVER_STEPS_OBJECT,
  QUESTION_CONTROL_EDIT_DRIVER_STEP_1,
  QUESTION_CONTROL_EDIT_DRIVER_STEP_2,
  QUESTION_CONTROL_EDIT_DRIVER_STEP_OBJECT_1,
  QUESTION_CONTROL_EDIT_DRIVER_STEP_OBJECT_2,
  QUESTION_DRIVER_SELECTED_STEPS,
  QUESTION_DRIVER_STATUS_ID,
  RISK_ACTION,
  RISK_ASSUMED_STATUS,
  STATUSES,
  TREATED_STATUS,
}

export default questionControl
