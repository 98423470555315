const questionStatus = (registerType, status) => {
  if (registerType === 'QuestionControl') {
    const statused = {
      0: 'Pendente',
      1: 'Em progresso',
      2: 'Adotado',
      3: 'Vencido',
      4: 'Risco assumido',
      5: 'Tratado',
    }

    return statused[status]
  }

  if (registerType === 'Question') {
    const statused = {
      1: 'Pendente',
      2: 'Respondido',
      3: 'Inativo',
    }

    return statused[status]
  }

  const statused = {
    0: 'Rascunho', // initial
    1: 'Preenchido', // headway
    2: 'Finalizado', // conclusion
  }

  return statused[status]
}

export default questionStatus
