import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  alert: {
    backgroundColor: 'rgba(220, 53, 64,0.2)',
    border: '0.5px solid rgba(220, 53, 64,0.4)',
    borderRadius: 3,
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
  },
  button: {
    backgroundColor: theme.palette.greenPantone,
    color: theme.palette.white,
    fontWeight: 900,
    fontSize: 14,
    padding: theme.spacing(0, 6),
    '&:hover': {
      backgroundColor: theme.palette.greenPantone,
      opacity: 0.8,
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 1),
      fontSize: 12,
    },
  },
  restDays: {
    margin: theme.spacing(0, 4),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2, 0, 0),
    },
  },
  number: {
    color: theme.palette.darkRed,
    fontSize: 30,
    fontWeight: 900,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
}))

export default styles
