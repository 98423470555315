import constants from 'constants/index'

const labelSource = (source) => {
  const DEPARTMENT = 'Qual o departamento onde vêm os dados?'
  const THIRD_PARTY =
    'Qual é o nome completo do terceiro (pessoa jurídica ou física) que compartilha os dados com a organização?'
  const PUBLIC = 'Onde são disponibilizados os dados?'

  switch (source) {
    case constants.dataProcess.DEPARTMENT_SOURCE:
      return DEPARTMENT
    case constants.dataProcess.THIRD_PARTY_SOURCE:
      return THIRD_PARTY
    case constants.dataProcess.DEPARTMENT_DESCRIPTION:
      return DEPARTMENT
    case constants.dataProcess.THIRD_PARTY_SOURCE_DESCRIPTION:
      return THIRD_PARTY
    default:
      return PUBLIC
  }
}

export default labelSource
