import React, { useState } from 'react'
import { Box, useMediaQuery } from '@material-ui/core'

import { DialogTomticket } from 'components'

import useFirstStep from 'hooks/useFirstStep'
import useStyles from './styles'

import helpers from 'helpers'
import theme from 'theme'

const TicketButton = () => {
  const [open, setOpen] = useState(false)
  const { activeStep, activeSubStep, registerTrack } = useFirstStep()
  const classes = useStyles()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const handleTicket = () => {
    setOpen(!open)
    registerTrack(
      helpers.userSteps.defineMixpanelId(
        activeStep,
        activeSubStep,
        'clique-no-botao-de-ticket',
      ),
    )
  }

  return (
    <Box display="flex" alignItems="end" className={classes.root}>
      <DialogTomticket open={open} setOpen={setOpen} />
      {(isMdUp || (!isMdUp && !open)) && (
        <Box ml={1} className={classes.ticketButton} onClick={handleTicket} />
      )}
    </Box>
  )
}

export default TicketButton
