import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import { uniqBy } from 'lodash'
import { FieldMenu, SelectedItem } from './components'
import { DialogConsentField, MenuButton } from 'components'

import useConsent from 'hooks/useConsent'
import useSnackbar from 'hooks/useSnackbar'
import helpers from 'helpers'

import constants from 'constants/index'
import styles from './styles'

const useStyles = makeStyles(styles)

const Fields = () => {
  const {
    nextStep,
    toNextStep,
    setToNextStep,
    dataConsentForm,
    setDataConsentForm,
  } = useConsent()
  const snackbar = useSnackbar()
  const classes = useStyles()

  const [openDialog, setOpenDialog] = useState(false)
  const [selectedField, setSelectedField] = useState({})
  const [customFields, setCustomFields] = useState([
    ...constants.consent.STANDARD_FIELDS_CONSENT_FORM,
  ])
  const [selectedItems, setSelectedItems] = useState(
    helpers.consent.transformComponents(dataConsentForm?.components) || [],
  )
  const [submitButtonEdit, setSubmitButtonEdit] = useState(false)

  const { handleSubmit, control, errors, watch, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (toNextStep) {
      handleSubmit(onSubmit)()

      if (errors) setToNextStep(false)
    }
    // eslint-disable-next-line
  }, [toNextStep])

  const handleSwitchChange = (event, item, index) => {
    const required = event.target.checked
    setSelectedItems((selecteds) =>
      selecteds.map((selected) => {
        if (selected.name === item.name) {
          return { ...selected, required }
        }

        return selected
      }),
    )

    return {
      subtitle: item.description,
      required,
      order: index,
    }
  }

  const handleFieldDestroy = (itemToRemove) => {
    setSelectedItems(selectedItems.filter((item) => item !== itemToRemove))
  }

  const handleOpenDialog = (item) => {
    setSubmitButtonEdit(false)
    setOpenDialog(!openDialog)
    setSelectedField(item)
  }

  const handleOpenDialogButton = () => {
    setOpenDialog(!openDialog)
    setSubmitButtonEdit(!submitButtonEdit)
  }

  const onSubmit = async (data) => {
    try {
      const mergedData = {
        ...dataConsentForm,

        components: {
          ...data,
        },
      }

      setDataConsentForm(mergedData)

      nextStep()
      setToNextStep(false)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error, false) ||
          error?.error?.message,
        variant: 'error',
      })
    }
  }

  const handleUp = (index, items) => {
    const item = items[index]
    const itemToDown = items[index - 1]
    items[index - 1] = item
    items[index] = itemToDown
    setSelectedItems(items.map((item, order) => ({ ...item, order })))
  }

  const handleDown = (index, items) => {
    const item = items[index]
    const itemToUp = items[index + 1]
    items[index + 1] = item
    items[index] = itemToUp
    setSelectedItems(items.map((item, order) => ({ ...item, order })))
  }

  return (
    <Grid
      container
      justifyContent="center"
      id={constants.consent.NEW_CONSENT_FORM_DRIVER_STEP_2}
    >
      <Grid item xs={12} md={6} lg={4}>
        <Box padding={3}>
          <Typography variant="subtitle1">Componentes</Typography>
          <Typography variant="caption">
            Lembre-se de coletar somente os dados estritamente necessários dos
            titulares.
          </Typography>
        </Box>
        <FieldMenu
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
          customFields={uniqBy(customFields, 'name')}
          setCustomFields={setCustomFields}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={8} className={classes.consentBox}>
        <Box className={classes.headerBox}>
          <Box p={2}>
            <Typography variant="subtitle2">{dataConsentForm.title}</Typography>
            <Typography variant="caption">
              {dataConsentForm.subtitle}
            </Typography>
          </Box>
        </Box>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box margin={1} padding={1} className={classes.attributeBox}>
              <Box>
                <Typography variant="subtitle2">E-mail</Typography>
                <Typography variant="caption">
                  Insira um endereço de e-mail válido
                </Typography>
                <Box>
                  <Controller
                    control={control}
                    name="Email"
                    defaultValue={{
                      subtitle: 'Insira um endereço de e-mail válido.',
                      required: true,
                      order: 0,
                    }}
                    as={
                      <Box display="flex" alignItems="center">
                        <Switch
                          color="primary"
                          defaultChecked={true}
                          disabled
                        />
                        <Typography>Obrigatório</Typography>
                      </Box>
                    }
                    mode="onChange"
                  />
                </Box>
              </Box>
            </Box>
            {selectedItems.map((item, index) => (
              <SelectedItem
                key={item?.name + index?.toString()}
                item={item}
                order={index}
                control={control}
                watch={watch}
                handleDown={handleDown}
                handleUp={handleUp}
                handleSwitchChange={handleSwitchChange}
                handleOpenDialog={handleOpenDialog}
                selectedItems={selectedItems}
                handleFieldDestroy={handleFieldDestroy}
              />
            ))}
            <Box margin={1} padding={1} className={classes.attributeBox}>
              <Box>
                <Typography variant="subtitle2">
                  Botão enviar formulário
                </Typography>
                <Typography variant="caption">Enviar formulário</Typography>
                <Box marginTop={2}>
                  <Button
                    className={classes.helpCentralButton}
                    variant="contained"
                    size="small"
                  >
                    {dataConsentForm?.buttonText ?? 'EU AUTORIZO'}
                  </Button>
                </Box>
              </Box>
              <MenuButton>
                <Button
                  color="secondary"
                  fullWidth
                  size="small"
                  onClick={handleOpenDialogButton}
                >
                  Editar
                </Button>
              </MenuButton>
            </Box>
          </form>
        </Box>
      </Grid>
      <DialogConsentField
        open={openDialog}
        onClose={handleOpenDialog}
        action="edit"
        selectedField={selectedField}
        customFields={customFields}
        setCustomFields={setCustomFields}
        selectedItems={selectedItems}
        setValue={setValue}
        setSelectedItems={setSelectedItems}
        submitButtonEdit={submitButtonEdit}
      />
    </Grid>
  )
}

export default Fields
