import biggerSupplierBox from './biggerSupplierBox'
import box from './box'
import complianceBox from './complianceBox'
import complianceHexagons from './complianceHexagons'
import hexagon from './hexagon'
import progressBar from './progressBar'
import rectangleTwoLabels from './rectangleTwoLabels'
import suppliersBox from './suppliersBox'

const components = {
  biggerSupplierBox,
  box,
  complianceBox,
  complianceHexagons,
  hexagon,
  progressBar,
  rectangleTwoLabels,
  suppliersBox,
}

export default components
