import permissions from '../permissions'

const DIAGNOSTICS_LIST = [
  {
    name: 'Visualizar todas as medidas de segurança',
    tag: permissions.DIAGNOSTICS.LIST,
  },
  {
    name: 'Gerenciar todas as medidas de segurança',
    tag: permissions.DIAGNOSTICS.UPDATE,
    description:
      'Ao permitir, o usuário poderá criar, editar e excluir medidas de segurança',
  },
  {
    name: 'Assumir risco de medidas de segurança',
    tag: permissions.DIAGNOSTICS.TAKE_RISK,
  },
  {
    name: 'Reabrir medida de segurança para edição',
    tag: permissions.DIAGNOSTICS.OPEN_WRITE,
  },
]

export default DIAGNOSTICS_LIST
