import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  labelTitle: {
    fontWeight: 400,
  },
  multiSelect: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
  },
  categoriesText: {
    maxWidth: 350,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export default styles
