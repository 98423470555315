import React, { useState, useEffect, useRef } from 'react'

import {
  Box,
  Button,
  Paper,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import useSnackbar from 'hooks/useSnackbar'
import useSubscription from 'hooks/useSubscription'

import styles from './styles'
import theme from 'theme'

import helpers from 'helpers'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const BilletStep = ({ billet }) => {
  const { data, paymentData } = useSubscription()

  localStorage.removeItem('@dponet-pre-registration')

  const classes = useStyles()
  const reference = useRef()
  const snackbar = useSnackbar()
  const [copySuccess, setCopySuccess] = useState(false)

  const [component, setComponent] = useState(reference.current)

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  useEffect(() => {
    if (!component) {
      return setComponent(reference.current)
    }
    //eslint-disable-next-line
  }, [reference])

  const copyToClipboard = () => {
    try {
      window.navigator.clipboard.writeText(reference.current.innerText)
      setCopySuccess(true)
      snackbar.open({
        message: 'Boleto copiado com sucesso!',
        variant: 'success',
      })
    } catch {
      snackbar.open({
        message: 'Ocorreu um erro ao copiar boleto',
        variant: 'error',
      })
    }
  }

  if (data) {
    return (
      <Box display="flex" justifyContent="center" width="100%" mt={2.5} mb={5}>
        <Box component={Paper} variant="elevation" elevation={0} minWidth="70%">
          <Box p={2.5}>
            {billet && (
              <Box mb={2}>
                <Alert
                  severity={
                    constants.preRegistrations.PAYMENT_STATUSES_ALERT_COLOR[
                      billet?.status
                    ]
                  }
                >
                  <AlertTitle>
                    Esse boleto está{' '}
                    {
                      constants.preRegistrations.PAYMENT_STATUSES[
                        billet?.status
                      ]
                    }
                  </AlertTitle>
                </Alert>
              </Box>
            )}

            <Typography variant="h4">
              {data?.responsibleUser?.name}, seu boleto foi gerado!
            </Typography>
            <Box mt={2.5}>
              <Typography variant="body1">
                Seu boleto foi enviado para o e-mail{' '}
                <strong>{data?.email}</strong>
              </Typography>
              <Typography variant="body1">
                Copie os números para pagar seu boleto
              </Typography>
            </Box>
            <Box
              my={2.5}
              display={isDesktop ? 'flex' : ''}
              justifyContent="space-between"
              alignItems="center"
            >
              <Tooltip title="Copiar">
                <Paper
                  onClick={copyToClipboard}
                  className={classes.cardBillet}
                  variant="elevation"
                  elevation={2}
                >
                  <Box p={2.5} className={classes.billetNumberBox}>
                    <Typography ref={reference} variant="h5">
                      {paymentData?.billet?.digitableLine ||
                        '01010.01010 01010.010101 01010.010101 1 01010101010101'}
                    </Typography>
                  </Box>
                </Paper>
              </Tooltip>
              <Box mr={8} mt={isDesktop ? 0 : 2.5}>
                <Button variant="contained" onClick={copyToClipboard}>
                  {copySuccess ? 'Copiado' : 'Copiar'}
                </Button>
              </Box>
            </Box>
            <Box mt={4}>
              <Typography variant="body1">
                Pague até{' '}
                {helpers.formatters.date(paymentData?.billet?.expirationDate)}
              </Typography>
            </Box>
            <Box
              mt={isDesktop ? 2 : 1.5}
              display="flex"
              {...(!isDesktop && {
                flexDirection: 'column',
                alignItems: 'baseline',
              })}
              pb={2}
            >
              <Box mr={2.5} {...(!isDesktop && { pb: 1.5 })}>
                <Button
                  className={classes.buttonPrimary}
                  variant="contained"
                  onClick={() =>
                    window.open(
                      `https://drive.google.com/viewerng/viewer?url=${paymentData?.billet?.url}`,
                    )
                  }
                >
                  Ver boleto
                </Button>
              </Box>
              <Button
                className={classes.buttonPrimary}
                variant="contained"
                onClick={() => window.open(paymentData?.billet?.url)}
              >
                Salvar boleto em PDF
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
  return <></>
}

export default BilletStep
