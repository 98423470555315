import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  actionArea: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
}))

export default styles
