const expirationDateCard = (value) => {
  if (value === null || value === undefined) return ''

  value = value.split('/').join('')

  var l = value.length

  if (l <= 2) return value.replace(/^(\d((0)[0-9])|((1)[0-2]))*/, '$1')

  if (value[0] === '0') {
    return value.replace(/^(\d{2})(\d{3})*/, '$1/$2')
  }

  return value.replace(/^(\d((0)[0-9])|((1)[0-2]))(\d{3})*/, '$1/$2')
}

export default expirationDateCard
