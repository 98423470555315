import React, { useState } from 'react'

import { Box } from '@material-ui/core'
import { RoundedButton, IconButton, InfoDialog, Text } from '..'

import { Info as InfoIcon } from 'react-feather'

import useFirstStep from 'hooks/useFirstStep'
import useAuth from 'hooks/useAuth'

import constants from 'constants/index'
import helpers from 'helpers'

import useStyles from './styles'
import cookiesLock from 'images/cadeado-cookies.png'

const CookiePage = () => {
  const [open, setOpen] = useState(false)
  const {
    activeStep,
    activeSubStep,
    handleNext,
    jumpToFinalize,
    nextDisabled,
  } = useFirstStep()
  const { company } = useAuth()

  const classes = useStyles()

  const handleOpen = () => setOpen(true)

  const goToFinalize = async (observations) => jumpToFinalize(observations)
  const mixpanelInfoId = helpers.userSteps.defineMixpanelId(
    activeStep,
    activeSubStep,
    'informação',
  )

  const handleProduct = () => {
    if (company?.statusId === constants.userCompanies.TRIAL_LIMITED_STATUS) {
      return goToFinalize(
        'O cliente não possui banner de cookies, mas não tem direto ao mesmo.',
      )
    }

    return handleNext()
  }

  return (
    <>
      <Box display="flex" justifyContent="center" mb={1}>
        <img
          src={cookiesLock}
          alt="cookies-cadeado"
          className={classes.lockImage}
        />
      </Box>
      <Text color="primary" fontSize="5vh" variant="h1">
        A organização tem um banner de cookies no site?
        <Box className={classes.infoButton} p={1}>
          <IconButton
            icon={<InfoIcon />}
            paddingButton={2}
            onClick={handleOpen}
            mixpanelId={mixpanelInfoId}
          />
        </Box>
      </Text>
      <Box
        my={2}
        display="flex"
        justifyContent="center"
        className={classes.buttons}
      >
        <Box className={classes.actionArea}>
          <RoundedButton
            variant="secondary"
            disabled={nextDisabled}
            withPadding
            onClick={() =>
              goToFinalize('O cliente já possui banner de cookies')
            }
            mixpanelId={helpers.userSteps.defineMixpanelId(
              activeStep,
              activeSubStep,
              'sim',
            )}
          >
            Sim
          </RoundedButton>
          <Box ml={2}>
            <RoundedButton
              variant="secondary"
              withPadding
              onClick={handleProduct}
              mixpanelId={helpers.userSteps.defineMixpanelId(
                activeStep,
                activeSubStep,
                'não',
              )}
            >
              Não
            </RoundedButton>
          </Box>
        </Box>
        <Box>
          <RoundedButton
            variant="outlined"
            disabled={nextDisabled}
            withPadding
            onClick={() => goToFinalize('O cliente não possui site')}
            mixpanelId={helpers.userSteps.defineMixpanelId(
              activeStep,
              activeSubStep,
              'não-tenho-site',
            )}
            fullWidth
          >
            Não tenho site
          </RoundedButton>
        </Box>
      </Box>
      <InfoDialog
        open={open}
        setOpen={setOpen}
        {...constants.userSteps.DIALOG_INFOS.COOKIES}
        showWebSite
      />
    </>
  )
}

export default CookiePage
