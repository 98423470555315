import findAnswer from './findAnswer'
import mountComplaintInfos from './mountComplaintInfos'
import statusButtonItems from './statusButtonItems'
import statusColor from './statusColor'
import statusLabel from './statusLabel'

export default {
  findAnswer,
  mountComplaintInfos,
  statusButtonItems,
  statusColor,
  statusLabel,
}
