import permissions from '../permissions'

const RIPD_LIST = [
  {
    name: 'Exportar',
    tag: permissions.RIPD.EXPORT,
  },
]

export default RIPD_LIST
