import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  link: {
    fontWeight: 500,
    cursor: 'pointer',
  },
  amexBox: {
    backgroundColor: theme.palette.cardFlags.amex.primary,
    borderRadius: 4,
    paddingInline: 6,
  },
}))

export default styles
