import calculateRisk from './calculateRisk'
import convertFragilityValue from './convertFragilityValue'
import fragilityEntitiesLabel from './fragilityEntitiesLabel'
import idToLabel from './idToLabel'

const fragilities = {
  calculateRisk,
  convertFragilityValue,
  fragilityEntitiesLabel,
  idToLabel,
}

export default fragilities
