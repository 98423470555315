import api from 'service/api'
import { TOKEN_PRE_REGISTRATION } from 'service/constants'

const dponetAPI = api.create('dponet', false)

const validate = async ({
  coupon,
  planId = '',
  document = '',
  segmentId = '',
}) => {
  const params = {
    planId,
    document,
    segmentId,
    pre_registration_token: localStorage.getItem(TOKEN_PRE_REGISTRATION) || '',
  }

  return await dponetAPI.get(`/discount_coupons/${coupon}/validate`, {
    params,
  })
}

export default { validate }
