const statusChipColor = (statusId, classes) => {
  switch (statusId) {
    case 'awaiting_response':
      return classes.awaitingResponse
    case 'partially_answered':
      return classes.partiallyAnswered
    case 'answered':
      return classes.answered
    default:
      return ''
  }
}

export default statusChipColor
