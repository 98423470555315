import api from 'service/api'

const dponetAPI = api.create('dponet')

const getGapStats = async () => {
  return await dponetAPI.get('/dashboard/gap_stats')
}

const getGeneralStats = async () => {
  return await dponetAPI.get('/dashboard/general_stats')
}

const getSupplierStats = async () => {
  return await dponetAPI.get('/dashboard/supplier_stats')
}

const getProcessStats = async () => {
  return await dponetAPI.get('/dashboard/data_process_stats')
}

const getFragilityStats = async () => {
  return await dponetAPI.get('/dashboard/fragility_stats')
}

const getQuestionControlStats = async () => {
  return await dponetAPI.get('/dashboard/question_control_stats')
}

export default {
  getGapStats,
  getGeneralStats,
  getSupplierStats,
  getProcessStats,
  getFragilityStats,
  getQuestionControlStats,
}
