import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.secondary.superLight}`,
  },
  customPopover: {
    maxWidth: 500,
  },
  contentHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    gridGap: 8,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  contentHeaderBox: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
    },
  },
  categoriesText: {
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export default styles
