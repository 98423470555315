import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumbs, Grid, Typography, makeStyles } from '@material-ui/core'
import { Container } from 'components'
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons'

import styles from './styles'

const useStyles = makeStyles(styles)

const Header = ({ children }) => {
  const classes = useStyles()

  return (
    <Container maxWidth="xl">
      <Grid
        className={classes.root}
        container
        justify="space-between"
        spacing={3}
        alignItems="flex-start"
      >
        <Grid item sm={8} xs={10}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            classes={{
              li: classes.whiteTypography,
              ol: classes.whiteTypography,
            }}
            aria-label="breadcrumb"
          >
            <Typography variant="body1" color="inherit">
              Diagnóstico
            </Typography>
            <Typography variant="body1" color="inherit">
              Todos os questionários
            </Typography>
          </Breadcrumbs>
          <Typography variant="h3" className={classes.whiteTypography}>
            Questionários
          </Typography>
        </Grid>
        {children}
      </Grid>
    </Container>
  )
}

Header.propTypes = {
  children: PropTypes.node,
}

export default Header
