import { makeStyles } from '@material-ui/core'

import polygonOutlined from 'images/polygon-outline.svg'

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.subscription.primary.main,
    color: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
      height: 'fit-content',
    },
  },
  textWithPolygon: {
    backgroundImage: `url(${polygonOutlined})`,
    height: 92,
    width: 83,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelTypography: {
    fontWeight: 700,
  },
  typographyCompliance80: {
    lineHeight: 0.951,
  },
  typographyLabelCompliance80: {
    fontWeight: 500,
  },
  polygonBox: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  polygonValues: {
    position: 'absolute',
    textAlign: 'center',
  },
  specialLabelTypography: {
    fontSize: 24,
    maxWidth: 102,
  },
  specialValueTypographyResponse: {
    fontSize: 48,
    maxWidth: 102,
  },
  polygonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },

  polygonContainerResponse: {
    display: 'flex',
    flexDirection: 'column',
  },
  polygonBoxResponse: {
    display: 'flex',
  },
}))

export default styles
