import React from 'react'
import Main from './DataProcessesMain'

const SuggestionChangesMain = () => {
  return (
    <Main
      defaultFilters={{ suggestedChanges: true }}
      origin="SuggestedChanges"
      title="Alterações sugeridas"
    />
  )
}

export default SuggestionChangesMain
