import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import clsx from 'clsx'
import { ChevronDown as ChevronDownIcon } from 'react-feather'

import useStyles from './styles'

const ToggleButton = ({ size, expanded, onClick }) => {
  const classes = useStyles()
  return (
    <IconButton
      className={clsx({
        [classes.expandOpen]: expanded,
        [classes.expandClose]: !expanded,
      })}
      onClick={onClick}
    >
      <ChevronDownIcon size={size} />
    </IconButton>
  )
}

ToggleButton.prototype = {
  size: PropTypes.number,
  expanded: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

ToggleButton.defaultProps = {
  size: 18,
  expanded: false,
}

export default ToggleButton
