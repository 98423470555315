import { makeStyles } from '@material-ui/core'

const styles = (heightContainer) =>
  makeStyles((theme) => ({
    root: {
      minWidth: 250,
      height: heightContainer || '100%',
      minHeight: 404,
    },
    columnBox: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'start',
    },
    cardHeader: {
      padding: theme.spacing(2),
    },
    cardTitle: {},
    cardContent: {
      minHeight: 'fit-content',
      width: '100%',
    },
    paperContent: {
      minHeight: 'fit-content',
    },
  }))

export default styles
