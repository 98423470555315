const styles = () => ({
  dialog: {
    backgroundImage:
      'linear-gradient(to left top, #ffb03d, #ffb434, #ffb82a, #ffbc1d, #ffc107)',
  },
  colorWhite: {
    color: 'white',
  },
})

export default styles
