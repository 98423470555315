const textStatusColor = (statusId, classes) => {
  switch (statusId) {
    case 1:
      return classes.textPending
    case 2:
      return classes.textWaitingReview
    case 3:
      return classes.textReviewDisapproved
    case 4:
      return classes.textReviewApproved
    case 5:
      return classes.textReviewing
    case 6:
      return classes.textInactive
    // status 7 e 8 são defaults da plataforma web
    // Caso criado mais status no processamento, favor adequar este modulo e onde é chamado
    case 7:
      return classes.textSuggested
    case 8:
      return classes.textDiscarded
    case 9:
      return classes.textWaitingReview
    case 10:
      return classes.textPending
    case 11:
      return classes.textWaitingReview
    case 12:
      return classes.textWaitingReview
    case 13:
      return classes.textReviewDisapproved
    default:
      return '-'
  }
}

export default textStatusColor
