import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    fontFamily: 'Roboto',
    color: theme.palette.primary.main,
  },
  children: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      justifyContent: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 14,
    marginBottom: theme.spacing(1),
  },
  span: {
    fontSize: 12,
    color: theme.palette.text.mainMenu,
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  primaryButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  whiteButton: {
    backgroundColor: theme.palette.background.default,

    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  radioCheck: {
    '& .MuiIconButton-label': {
      color: theme.palette.primary.main,
    },
  },
  radioControl: {
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },
  grayBox: {
    display: 'flex',
    marginBlock: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    backgroundColor: theme.palette.background.default,
  },
}))
