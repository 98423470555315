import * as yup from 'yup'

const schemaFinality = yup.object().shape({
  question1: yup.string().required(),
  question2: yup.string().required(),
  question3: yup.string().required(),
  question4: yup.string().required(),
})

export default schemaFinality
