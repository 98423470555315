import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles, Grid, Typography, Box, Button } from '@material-ui/core'

import styles from './styles'
import useAuth from 'hooks/useAuth'

const useStyles = makeStyles(styles)

const NotFound = () => {
  const classes = useStyles()
  const history = useHistory()
  const auth = useAuth()

  return (
    <Box className={classes.root}>
      <Grid container justify="center">
        <Grid item md={8} xs={12}>
          <Box className={classes.content}>
            <img
              alt="Em desenvolvimento"
              className={classes.image}
              src="/images/404.svg"
            />
            <Box my={5}>
              <Typography variant="h2">404: Página não encontrada</Typography>
            </Box>
            <Box className={classes.subtitle}>
              <Typography variant="subtitle2">
                Você tentou algum caminho desconhecido ou veio aqui por engano.
                Seja qual for, tente usar a navegação.
              </Typography>
            </Box>
            <Box mt={6}>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  history.push(auth.homeRoute || '/data-processes/resume')
                }
              >
                Voltar ao início
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default NotFound
