import React from 'react'

import { Step, StepLabel } from '@material-ui/core'

import { StepIcon, Stepper as StepperComponent } from 'components'

import useTrailStep from 'hooks/useTrailStep'

import constants from 'constants/index'
import useStyles from './styles'

const Stepper = () => {
  const classes = useStyles()
  const { activeStep } = useTrailStep()

  return (
    <StepperComponent activeStep={activeStep}>
      {constants.userTrailSteps.STEP_LABELS.map((label) => (
        <Step
          key={label}
          classes={{
            horizontal: classes.horizontalStep,
          }}
        >
          <StepLabel
            StepIconComponent={StepIcon}
            classes={{ label: classes.stepperLabel }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </StepperComponent>
  )
}

export default Stepper
