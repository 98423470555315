import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { saveAs } from 'file-saver'

import { Button } from '@material-ui/core'

import { TableRow, TableCell } from 'components/Table'
import { MenuButton } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'

import * as service from 'service'

const ReportVersionRow = ({
  reportVersion,
  setReportVersion,
  setOpenDestroy,
}) => {
  const [loading, setLoading] = useState(false)
  const snackbar = useSnackbar()

  const [url, setUrl] = useState('')

  const getUrl = async () => {
    if (!!url) {
      return url
    }

    setLoading(true)
    try {
      const response = await service.dponet.reportVersions.get({
        reportVersionId: reportVersion?.id,
      })

      const reportUrl = response?.data?.reportVersion?.url

      const responseReport = await fetch(reportUrl)
      const blob = await responseReport.blob()

      setUrl(blob)
      return reportUrl
    } catch {
      snackbar.open({
        variant: 'error',
        message: 'Ocorreu um erro ao carregar o relatório',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleView = async () => {
    setLoading(true)

    const reportUrl = await getUrl()

    setLoading(false)
    return window.open(reportUrl)
  }

  const handleDownload = async () => {
    setLoading(true)

    const reportUrl = await getUrl()

    saveAs(
      reportUrl,
      `${reportVersion?.name} - versão ${reportVersion?.version}.pdf`,
    )
    setLoading(false)
  }

  const handleDestroy = () => {
    setReportVersion(reportVersion)
    setOpenDestroy(true)
  }

  return (
    <TableRow>
      <TableCell>{reportVersion?.id}</TableCell>
      <TableCell align="center">{reportVersion?.authorName}</TableCell>
      <TableCell align="center">{reportVersion?.version}</TableCell>
      <TableCell align="center">
        {helpers.formatters.dateLocaleFormat(
          reportVersion?.createdAt,
          'DD/MM/yy',
        )}
      </TableCell>
      <TableCell align="right">
        <MenuButton iconButtonSize="small">
          <Button disabled={loading} onClick={handleView}>
            Visualizar
          </Button>
          <Button disabled={loading} onClick={handleDownload}>
            Baixar
          </Button>
          <Button disabled={loading} onClick={handleDestroy}>
            Excluir
          </Button>
        </MenuButton>
      </TableCell>
    </TableRow>
  )
}

ReportVersionRow.propTypes = {
  reportVersion: PropTypes.object,
  setReportVersion: PropTypes.func,
  setOpenDestroy: PropTypes.func,
}

export default ReportVersionRow
