import { makeStyles } from '@material-ui/core'

const styles = (props) =>
  makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 80,
      backgroundColor: props.bgColor,
      paddingBottom: 20,
    },
    sectionTitle: {
      paddingInline: 52,
      fontSize: 36,
      fontWeight: 700,
      fontFamily: 'Raleway',
      color: props.primaryColor,
    },
  }))

export default styles
