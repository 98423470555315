import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  button: {
    borderRadius: '29px',
    fontSize: 16,
    letterSpacing: 1.78,
    [theme.breakpoints.up('sm')]: {
      minWidth: 197,
    },
  },
  label: {
    fontWeight: '500 !important',
  },
  withPadding: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
  },
  withShadow: {
    boxShadow: `0px 0px 12px ${theme.colors.firstStep.button.primary}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.colors.firstStep.button.primary,
    },
  },
  endIcon: {
    marginBottom: 2,
  },
  primary: {
    backgroundColor: theme.colors.firstStep.button.primary,
    color: theme.palette.common.white,
  },
  primaryHover: {
    '&:hover': {
      border: `1px solid ${theme.palette.common.white}`,
    },
  },
  secondary: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  outlined: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  text: {
    color: theme.palette.common.white,
  },
}))

export default styles
