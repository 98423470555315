import React from 'react'

import { Step, StepLabel } from '@material-ui/core'

import { StepIcon, Stepper as StepperComponent } from 'components'

import useFirstStep from 'hooks/useFirstStep'
import useAuth from 'hooks/useAuth'

import constants from 'constants/index'
import useStyles from './styles'

const Stepper = () => {
  const classes = useStyles()
  const { activeStep } = useFirstStep()
  const { user } = useAuth()

  return (
    <StepperComponent activeStep={activeStep}>
      {constants.userSteps.STEP_LABELS.map((label, index) => (
        <Step
          key={label}
          classes={{
            horizontal: classes.horizontalStep,
          }}
          {...(user?.allStepsConcluded && {
            completed: true,
          })}
        >
          <StepLabel
            StepIconComponent={StepIcon}
            classes={{ label: classes.stepperLabel }}
            StepIconProps={{ step: index }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </StepperComponent>
  )
}

export default Stepper
