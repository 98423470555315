import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 10,
  },
  highlightedText: {
    color: theme.palette.grapePurple,
  },
  buttonPrimary: {
    backgroundColor: theme.palette.subscription.primary.main,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.subscription.primary.dark,
    },
  },
}))

export default styles
