import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  greenHighlighted: {
    backgroundColor: theme.colors.companyTrails.button.primary,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
}))

export default styles
