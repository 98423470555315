import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  inputImageContainer: {
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px ${theme.palette.primary.main} dashed`,
    borderRadius: '5px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    flexDirection: 'column',
  },
  imgLogo: {
    maxHeight: '75px',
  },
}))

export default styles
