import savePasswordCover from 'images/safepassword-cover.png'
import acessoSimplificadoImage from 'images/pentest-white-box.png'
import criptografiaImage from 'images/safepassword-criptografia-avancada.png'
import armazenamentoSeguroImage from 'images/safepassword-armazenamento-seguro.png'

import segurancaIcon from 'images/safepassword-security.png'
import cadeadoIcon from 'images/safepassword-lock.png'
import produtividadeIcon from 'images/safepassword-produtividade.png'

const safePasswordIcons = [
  {
    title: 'Acesso Simplificado e Controle de Segurança',
    altText: 'Acesso Simplificado e Controle de Segurança',
    src: acessoSimplificadoImage,
  },
  {
    title: 'Criptografia Avançada',
    altText: 'Criptografia Avançada',
    src: criptografiaImage,
  },
  {
    title: 'Armazenamento Seguro',
    altText: 'Armazenamento Seguro',
    src: armazenamentoSeguroImage,
  },
]

const safePassword = {
  id: 36,
  sectionTitle: 'Cofre de Senhas',
  description:
    'Cofre de Senhas é uma solução avançada para a gestão segura e eficiente de acessos em sua empresa.',
  whatIs: {
    primaryText: 'O que é o Cofre de Senhas?',
    text: 'Cofre de senhas é um sistema composto por um portal web e software exclusivo, especializado no transporte de senhas criptografadas, eliminando as vulnerabilidades associadas ao gerenciamento tradicional de credenciais.',
    icons: safePasswordIcons,
  },
  forWho: {
    text: 'O Cofre de Senhas é ideal para empresas que buscam aprimorar a segurança e eficiência no gerenciamento de acessos. Nosso público-alvo são organizações que valorizam a proteção de dados, buscam eliminar riscos de invasões e desejam uma solução intuitiva para distribuição segura de credenciais. Transforme a gestão de senhas na sua empresa com a inovação e confiabilidade de um cofre de senhas.',
    cover: savePasswordCover,
    coverText: 'Imagem de uma mão segurando uma lapa através de um escudo',
    benefitsList: [
      'Segurança Reforçada',
      'Gestão Eficiente',
      'Confidencialidade Aprimorada',
      'Produtividade Elevada',
    ],
  },
  benefits: [
    {
      title: 'Segurança Reforçada',
      altText: 'Segurança Reforçada',
      description:
        'Elimine riscos associados à perda, esquecimento ou compartilhamento inadequado de senhas.',
      icon: segurancaIcon,
    },
    {
      title: 'Confidencialidade Aprimorada',
      altText: 'Confidencialidade Aprimorada',
      description:
        'Funcionários não têm conhecimento das senhas, impedindo possíveis vazamentos.',
      icon: cadeadoIcon,
    },
    {
      title: 'Produtividade Elevada',
      altText: 'Produtividade Elevada',
      description:
        'Reduza o tempo gasto em recuperação de senhas e resolva problemas de acesso de forma ágil.',
      icon: produtividadeIcon,
    },
  ],
}

export default safePassword
