import React, { Fragment, useEffect, useState } from 'react'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
  FormGroup,
} from '@material-ui/core'

import { Controller } from 'react-hook-form'

import constants from 'constants/index'
import usePermission from 'hooks/usePermissions'

const IncidentViolationType = ({ name, control, setValue, checkedOptions }) => {
  const { isDpo } = usePermission()
  const [selectedItems, setSelectedItems] = useState([])

  const handleSelect = (option) => {
    const isPresent = selectedItems.indexOf(option)

    if (selectedItems.map((item) => item?.value).includes(option?.value)) {
      const removeItem = selectedItems.filter(
        (item) => item?.value !== option?.value,
      )
      setSelectedItems(removeItem)
    } else {
      if (isPresent !== -1) {
        const remaining = selectedItems.filter((item) => item !== option)
        setSelectedItems(remaining)
      } else {
        setSelectedItems((prevItems) => [...prevItems, option])
      }
    }
  }

  useEffect(() => {
    if (checkedOptions) {
      setSelectedItems(checkedOptions)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setValue('incidentViolationTypes', selectedItems)
  }, [name, selectedItems, setValue])

  const isChecked = (option) => {
    return selectedItems.map((item) => item?.value).includes(option?.value)
  }
  return (
    <>
      <Grid xs={12} item>
        {constants.incidents.INCIDENT_VIOLATION_TYPES && (
          <FormControl size="small" variant="outlined">
            <Typography variant="h5">
              De quais formas o incidente afetou os dados pessoais:
            </Typography>
            <FormGroup>
              {constants.incidents.INCIDENT_VIOLATION_TYPES.map((option) => {
                return (
                  <Fragment key={option?.value}>
                    <FormControlLabel
                      control={
                        <Controller
                          name="incidentViolationTypes"
                          onChange={() => handleSelect(option)}
                          as={<Checkbox checked={isChecked(option)} />}
                          control={control}
                          disabled={isDpo()}
                        />
                      }
                      label={option?.name}
                    />
                    <Typography variant="body2">
                      {option?.description}
                    </Typography>
                  </Fragment>
                )
              })}
            </FormGroup>
          </FormControl>
        )}
      </Grid>
    </>
  )
}

export default IncidentViolationType
