import helpers from 'helpers'
import constants from 'constants/index'

const resumeCompanyData = (data) => {
  const isCompany =
    data?.kind === constants.preRegistrations.JURIDIC_KIND || !!data?.cnpj

  return [
    {
      label: isCompany ? 'Razão social' : 'Nome',
      value: data?.name,
    },
    isCompany && {
      label: 'Nome fantasia',
      value: data?.fantasyName,
    },
    {
      label: isCompany ? 'CNPJ' : 'CPF',
      value: isCompany
        ? helpers.formatters.cnpj(data?.document)
        : helpers.formatters.cpf(data?.document),
    },
    {
      label: 'E-mail de cobrança',
      value: data?.email,
    },
    {
      label: 'Telefone',
      value: data?.phone,
    },
    {
      label: 'CEP',
      value: data?.address?.postalCode,
    },
    {
      label: 'País',
      value: data?.address?.country,
    },
    {
      label: 'Estado',
      value: data?.address?.state,
    },
    {
      label: 'Cidade',
      value: data?.address?.city,
    },
    {
      label: 'Bairro',
      value: data?.address?.neighborhood,
    },
    {
      label: 'Rua',
      value: data?.address?.street,
    },
    {
      label: 'Número',
      value: data?.address?.number,
    },
    {
      label: 'Complemento',
      value: data?.address?.complement,
    },
  ].filter((field) => !!field)
}

export default resumeCompanyData
