import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.colors.firstStep.button.light,
    color: theme.colors.firstStep.primary,
    letterSpacing: 1.25,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.colors.firstStep.button.light,
    },
  },
}))

export default styles
