const mountDefault = (
  incidentData,
  dataRepresentative,
  smallCompany,
  smallAgentTreatment,
) => {
  return {
    ...incidentData,
    controller: {
      typeField: 'controller',
      cep: incidentData?.cep,
      city: incidentData?.city,
      document: incidentData?.documentController,
      treatmentHolder: incidentData?.treatmentHolder,
      email: incidentData?.emailController,
      name: incidentData?.nameController,
      phone: incidentData?.phoneController,
      state: incidentData?.state,
      declaresSmallAgentTreatment: smallAgentTreatment === 'true',
      declaresSmallCompany: smallCompany === 'true',
    },
    commissioner: {
      typeField: 'commissioner',
      name: incidentData?.nameCommissioner,
      email: incidentData?.emailCommissioner,
      document: incidentData?.documentCommissioner,
      phone: incidentData?.phoneCommissioner,
    },
    representative: {
      typeField: 'representative',
      carriedProcessedData: dataRepresentative === 'true',
      name: incidentData?.nameRepresentative,
      email: incidentData?.emailRepresentative,
      document: incidentData?.documentRepresentative,
      phone: incidentData?.phoneRepresentative,
    },
  }
}

export default mountDefault
