import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ token }) => {
  return await dponetAPI.get(`/reports/validate/${token}`)
}

const generateQrcode = async ({ ...params }) => {
  return await dponetAPI.get(`/reports/generate_qrcode`, { params })
}

const reportStats = async ({ ...params }) => {
  return await dponetAPI.get(`/reports/stats`, { params })
}

export default {
  get,
  generateQrcode,
  reportStats,
}
