import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import DescriptionPaper from '../../DescriptionPaper'
import Fragilities from '../../Fragilities'

import useDataProcess from 'hooks/useDataProcess'

const FragilityEntities = ({ description }) => {
  const { data, toNextStep, setToNextStep, nextStep } = useDataProcess()
  const { dataProcess } = data

  useEffect(() => {
    if (toNextStep) {
      nextStep()
      setToNextStep(false)
    }
    // eslint-disable-next-line
  }, [toNextStep])

  return (
    <>
      <DescriptionPaper description={description} />
      <Box px={2} pb={2} width="100%">
        <Fragilities dataProcessId={dataProcess?.id} />
      </Box>
    </>
  )
}

FragilityEntities.propTypes = {
  description: PropTypes.string,
}

export default FragilityEntities
