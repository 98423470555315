import pentestCover from 'images/pentest-cover.png'
import whiteBoxImage from 'images/pentest-white-box.png'
import networkServiceImage from 'images/pentest-network-service.png'
import webAppImage from 'images/pentest-web-app.png'

import vunerabilidadesImage from 'images/pentest-descoberta-vunerabilidades.png'
import falhasImage from 'images/pentest-reducao-falhas.png'
import avaliacaoImage from 'images/pentest-avaliacao-critica.png'
import visaoImage from 'images/pentest-visao-externa.png'
import prevencaoImage from 'images/pentest-prevencao-prejuizos.png'
import protecaoImage from 'images/pentest-protecao-imagem.png'

const pentestIcons = [
  {
    title: 'Teste de caixa branca (White Box)',
    altText: 'Intermediação de serviços',
    src: whiteBoxImage,
  },
  {
    title: 'Teste de serviços de rede',
    altText: 'Teste de serviços de rede',
    src: networkServiceImage,
  },
  {
    title: 'Teste de aplicações web',
    altText: 'Teste de aplicações web',
    src: webAppImage,
  },
]

const pentest = {
  id: 22,
  sectionTitle: 'Pentest',
  description:
    'O Marketplace DPOnet oferece o Pentest que visa proteger empresas de possíveis perdas financeiras decorrentes de ataques virtuais maliciosos, erros ou negligências internas que resultem em vazamento de dados e outros danos ligados ao sigilo da informação. A tecnologia alterou a maneira como os negócios são feitos e, com ataques virtuais cada vez mais sofisticados, os riscos cibernéticos ou cyber risks são uma realidade nas companhias.',
  whatIs: {
    primaryText: 'O que é Pentest?',
    text: 'O pentest, ou teste de intrusão, é uma espécie de "simulação de ataque" realizado por especialistas em segurança cibernética. Eles buscam identificar e corrigir possíveis brechas em sistemas digitais, atuando como uma espécie de defesa prévia contra hackers mal-intencionados. É como um check-up de segurança para garantir que sites, aplicativos e redes estejam bem protegidos, proporcionando mais confiança no ambiente online.',
    icons: pentestIcons,
  },
  forWho: {
    text: 'Empresas de todos os tamanhos e setores estão cada vez mais vulneráveis a ameaças cibernéticas, tornando o seguro cibernético essencial. Grandes corporações, instituições financeiras e setores de saúde, que lidam com dados sensíveis, são clientes naturais. Pequenas e médias empresas também devem considerar, pois muitas vezes têm menos proteção e são alvos fáceis.',
    cover: pentestCover,
    coverText: 'Imagem de uma mão segurando uma lupa através de um escudo',
    benefitsList: [
      'Identificação de Vulnerabilidades',
      'Melhoria da Segurança',
      'Conformidade com Normas e Regulamentações',
      'Prevenção de Ataques Futuros',
    ],
  },
  benefits: [
    {
      title: 'Descoberta de vulnerabilidades',
      altText: 'Descoberta de vulnerabilidades',
      description:
        'Identifica vulnerabilidades em um sistema antes que um cibercriminoso possa explorá-las.',
      icon: vunerabilidadesImage,
    },
    {
      title: 'Redução de falhas',
      altText: 'Redução de falhas',
      description:
        'Pode ajudar a reduzir as falhas durante o processo de desenvolvimento de software.',
      icon: falhasImage,
    },
    {
      title: 'Avaliação crítica',
      altText: 'Avaliação crítica',
      description:
        'Fornece uma avaliação crítica da capacidade de segurança cibernética de uma organização.',
      icon: avaliacaoImage,
    },
    {
      title: 'Visão externa especializada',
      altText: 'Visão externa especializada',
      description:
        'Oferece uma visão externa especializada da segurança de uma organização.',
      icon: visaoImage,
    },
    {
      title: 'Prevenção de prejuízos financeiros',
      altText: 'Prevenção de prejuízos financeiros',
      description:
        'Prevenir prejuízos financeiros decorrentes de violações de segurança.',
      icon: prevencaoImage,
    },
    {
      title: 'Proteção da imagem e reputação da empresa',
      altText: 'Proteção da imagem e reputação da empresa',
      description:
        'Pode ajudar a proteger a imagem e a reputação de uma empresa, demonstrando que ela leva a segurança a sério.',
      icon: protecaoImage,
    },
  ],
}

export default pentest
