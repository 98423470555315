import React, { useState } from 'react'
import { Box, Grid, TextField, Divider, Typography } from '@material-ui/core'

import { useForm, Controller } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'

import {
  Label,
  OptionCheckIncident,
  IncidentViolationType,
  LoadingFeedback,
} from 'components'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'

const OpportunityCommunication = ({
  idForm,
  incident,
  handleNext,
  stepName,
}) => {
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const verifyDescription = (value) => {
    return constants.incidents.INCIDENT_VIOLATION_TYPES.find(
      (item) => item?.value === value,
    ).description
  }

  const mountDefaultViolationTypes = () => {
    return (
      incident?.incidentViolationTypes?.map((violationTypes) => ({
        value: violationTypes?.value,
        name: violationTypes?.name,
        description: verifyDescription(violationTypes?.value),
      })) || []
    )
  }

  const { handleSubmit, control, setValue, reset, getValues, watch } = useForm({
    defaultValues: {
      whatTypeIncident: incident?.dataOptionIncidents?.whatTypeIncident || [],
      whatTypeIncidentOther: helpers.incidents.otherOption(
        incident?.dataOptionIncidents?.whatTypeIncident,
      ),
      incidentViolationTypes: mountDefaultViolationTypes(),
      typesSensitivePersonalDataBreached:
        incident?.dataOptionIncidents?.typesSensitivePersonalDataBreached || [],
      typesSensitivePersonalDataBreachedOther: helpers.incidents.otherOption(
        incident?.dataOptionIncidents?.typesSensitivePersonalDataBreached,
      ),
      otherTypePersonalDataViolatedOther: helpers.incidents.otherOption(
        incident?.dataOptionIncidents?.otherTypePersonalDataViolated,
      ),
      otherTypePersonalDataViolated:
        incident?.dataOptionIncidents?.otherTypePersonalDataViolated || [],
      description: incident?.description || '',
      descriptionRootIncident: incident?.descriptionRootIncident || '',
      descriptionMeasures: incident?.descriptionMeasures || '',
      descriptionTypePersonalBreached:
        incident?.descriptionTypePersonalBreached || '',
      nonSensitiveViolationDescription:
        incident?.nonSensitiveViolationDescription || '',
    },
  })

  const { response, isLoading } = useFetch(
    service.dponet.incidents.options,
    {
      typeFields: [
        'what_type_incident',
        'types_sensitive_personal_data_breached',
        'other_type_personal_data_violated',
      ],
    },
    [],
  )

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      await service.dponet.incidents.put({
        incidentId: incident?.id,
        incident: {
          ...helpers.incidents.mountDefault(incident),
          ...helpers.incidents.mountStepImpact(data),
          simplifiedForm: false,
        },
      })
      setLoading(false)
      handleNext()
      snackbar.open({
        message: `${stepName} atualizada com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: `Ocorreu um erro ao salvar a ${stepName}!`,
        variant: 'error',
      })
      setLoading(false)
      reset(getValues())
    }
  }

  return (
    <>
      <LoadingFeedback open={loading || isLoading} />
      <form onSubmit={handleSubmit(onSubmit)} id={idForm}>
        <Box px={2} pb={2}>
          <Grid container spacing={1}>
            <OptionCheckIncident
              label="Qual o tipo de incidente? (informe o tipo mais específico)"
              control={control}
              options={response?.data?.whatTypeIncident}
              checkedOptions={incident?.dataOptionIncidents?.whatTypeIncident}
              name="whatTypeIncident"
              literaName="what_type_incident"
              setValue={setValue}
              watch={watch}
            />
            <Grid item xs={12}>
              <Label title="Descreva, resumidamente, como ocorreu o incidente">
                <Controller
                  as={
                    <TextField
                      multiline
                      rows={5}
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    />
                  }
                  control={control}
                  name="description"
                  mode="onBlur"
                />
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label title="Explique, resumidamente, por que o incidente ocorreu (identifique a causa raiz, se conhecida):">
                <Controller
                  as={
                    <TextField
                      multiline
                      rows={5}
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    />
                  }
                  control={control}
                  name="descriptionRootIncident"
                  mode="onBlur"
                />
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label title="Quais medidas foram adotadas para corrigir as causas do incidente?">
                <Controller
                  as={
                    <TextField
                      multiline
                      rows={5}
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    />
                  }
                  control={control}
                  name="descriptionMeasures"
                  mode="onBlur"
                />
              </Label>
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12}>
          <Box py={2}>
            <Divider />
            <Box my={2} px={2}>
              <Typography variant="h6">
                Impactos do incidente sobre os dados pessoais
              </Typography>
            </Box>
            <Divider />
          </Box>
        </Grid>
        <Box px={2} pb={2}>
          <Grid container spacing={1}>
            <IncidentViolationType
              control={control}
              setValue={setValue}
              checkedOptions={mountDefaultViolationTypes()}
            />

            <OptionCheckIncident
              label="Se aplicável, quais os tipos de dados pessoais sensiveis foram violados?"
              control={control}
              options={response?.data?.typesSensitivePersonalDataBreached}
              checkedOptions={
                incident?.dataOptionIncidents
                  ?.typesSensitivePersonalDataBreached
              }
              name="typesSensitivePersonalDataBreached"
              setValue={setValue}
              watch={watch}
              literaName="types_sensitive_personal_data_breached"
            />
            <Grid item xs={12}>
              <Label title="Se aplicável, descreva os tipos de dados pessoais sensíveis violados:">
                <Controller
                  as={
                    <TextField
                      multiline
                      rows={5}
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    />
                  }
                  control={control}
                  name="descriptionTypePersonalBreached"
                  mode="onBlur"
                />
              </Label>
            </Grid>
            <OptionCheckIncident
              label="Quais os demais tipos de dados pessoais violados?"
              control={control}
              options={response?.data?.otherTypePersonalDataViolated}
              checkedOptions={
                incident?.dataOptionIncidents?.otherTypePersonalDataViolated
              }
              other
              name="otherTypePersonalDataViolated"
              setValue={setValue}
              watch={watch}
              literaName="other_type_personal_data_violated"
            />
            <Grid item xs={12}>
              <Label title="Descrever os tipos de dados pessoais não sensíveis violados, se cabível:">
                <Controller
                  as={
                    <TextField
                      type="text"
                      rows={5}
                      multiline
                      color="primary"
                      variant="outlined"
                      fullWidth
                    />
                  }
                  control={control}
                  name="nonSensitiveViolationDescription"
                  mode="onBlur"
                />
              </Label>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  )
}

export default OpportunityCommunication
