import palette from 'theme/palette'
import components from '.'

import { hexagonFilled } from '../../assets/hexagonFilled64'

import { WIDTH as WIDTHPdf, PADDING, GAP } from '../../constants'

import { ratio } from '../../helpers'

const complianceHexagons = (
  pdf,
  under20,
  under40,
  under60,
  under80,
  over80,
) => {
  const WIDTH = WIDTHPdf(pdf)

  pdf.setTextColor(palette.white)

  pdf.setFontSize(ratio(100))

  pdf.setFont('Raleway-Bold')

  components.hexagon(pdf, PADDING * 2, GAP * 3.5, '0 - 20%', under20)

  components.hexagon(pdf, WIDTH - PADDING * 4, GAP * 3.5, '20% - 40%', under40)

  components.hexagon(pdf, PADDING * 2, GAP * 6.5, '40 - 60%', under60)

  components.hexagon(pdf, WIDTH - PADDING * 4, GAP * 6.5, '60% - 80%', under80)

  pdf.addImage(
    hexagonFilled,
    'png',
    WIDTH - PADDING * 6,
    GAP * 4.75,
    ratio(500),
    ratio(500),
  )

  pdf.setTextColor(palette.white)
  pdf.setFontSize(ratio(200))
  pdf.setFont('Raleway-Bold')
  pdf.text(
    over80,
    WIDTH - PADDING * 6 + ratio(250),
    GAP * 5.75,
    null,
    null,
    'center',
  )
  pdf.setFontSize(ratio(70))
  pdf.text(
    ['Acima', 'de 80%'],
    WIDTH - PADDING * 6 + ratio(250),
    GAP * 6,
    null,
    null,
    'center',
  )
}

export default complianceHexagons
