const styles = (theme) => ({
  iconBackground: {
    width: '35px',
    height: '35px',
    backgroundColor: theme.palette.grey[100],
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItemGutters: {
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.divider}`,
  },
})

export default styles
