import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ securityMeasureId = '', ...params }) => {
  return await dponetAPI.get(`/security_measures/${securityMeasureId}`, {
    params,
  })
}

const create = async (data) => {
  return await dponetAPI.post(`/security_measures`, data)
}

const put = async ({ securityMeasureId, ...data }) => {
  return await dponetAPI.put(`/security_measures/${securityMeasureId}`, data)
}

const destroy = async ({ securityMeasureId }) => {
  return await dponetAPI.delete(`/security_measures/${securityMeasureId}`)
}

export default { get, create, put, destroy }
