import Main from './MarketplaceMain'
import Orders from './OrdersMain'
import ProductsAndServices from './ProductsAndServicesMain'
import Segments from './SegmentsMain'
import Services from './ServicesMain'
import Show from './CompanyServiceMain'

const Marketplace = {
  Main,
  Orders,
  ProductsAndServices,
  Segments,
  Services,
  Show,
}

export default Marketplace
