import React from 'react'

import {
  User as UserIcon,
  Monitor as MonitorIcon,
  Globe as GlobeIcon,
} from 'react-feather'

const getIconthus = (type) => {
  switch (type) {
    case 'Presencial':
      return <UserIcon />
    case 'Remoto':
      return <MonitorIcon />
    case 'Híbrido':
      return <GlobeIcon />
    default:
      return ''
  }
}

export default getIconthus
