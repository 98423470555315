import React from 'react'

import { Box } from '@material-ui/core'
import { GapAnalysisHeader } from 'components'
import { RecommendedQuestionsCard } from './components'

const RecommendedQuestions = ({
  displayQuestions,
  questionnaireId,
  setCurrentTab,
  refresh = () => {},
}) => {
  return (
    <>
      <GapAnalysisHeader title='Aqui em "Outras Questões" estão disponíveis as perguntas que normalmente não se aplicam à sua organização. Mas essas perguntas ficarão disponíveis caso você entenda que se enquadra na situação da sua empresa. É só clicar em "Incluir" e a pergunta será transferida para "Minhas Questões".' />
      <Box mt={6}>
        <RecommendedQuestionsCard
          displayQuestions={displayQuestions}
          questionnaireId={questionnaireId}
          setCurrentTab={setCurrentTab}
          refresh={refresh}
        />
      </Box>
    </>
  )
}

export default RecommendedQuestions
