import firstCover from './firstCover'
import lastCover from './lastCover'
import chapterCover from './chapterCover'

const components = {
  firstCover,
  lastCover,
  chapterCover,
}

export default components
