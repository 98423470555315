import React, { useState, useEffect } from 'react'
import { RHFInput } from 'react-hook-form-input'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Grid, TextField, makeStyles } from '@material-ui/core'

import { Label, Select as SelectComponent } from 'components'
import {
  DescriptionPaper,
  ContextDataProcessSource,
  Volumetry,
  DataProcessTemplateForm,
} from '../../'

import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'
import useDataProcess from 'hooks/useDataProcess'
import useDataProcessTemplateQuestion from 'hooks/useDataProcessTemplateQuestion'

import helpers from 'helpers'

import schemaGeneralInformation from './schema'
import constants from 'constants/index'
import * as service from 'service'
import styles from '../styles'

const useStyles = makeStyles(styles)

const GeneralInformation = ({ description }) => {
  const {
    code,
    setCode,
    setData,
    data,
    loadOptions,
    nextStep,
    toNextStep,
    setToNextStep,
    departments,
    sourceOptions,
    setSourceOptions,
  } = useDataProcess()
  const { company } = useAuth()

  const [selectDepartmentOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [allSources, setAllSources] = useState([])
  const [volumetryType, setVolumetryType] = useState(
    data?.dataProcess?.storageMode ||
      constants.dataProcess.STORY_MODE_UNDEFINED_TYPE,
  )
  const dataLaws = data?.dataProcess?.laws?.map((law) => law?.id)
  const [laws, setLaws] = useState(dataLaws || [])

  const blockSubmit =
    volumetryType === constants.dataProcess.STORY_MODE_PERMANENT_TYPE &&
    isEmpty(laws)

  const snackbar = useSnackbar()

  const classes = useStyles()

  const {
    register,
    setValue,
    getValues,
    watch,
    errors,
    control,
    triggerValidation,
  } = useForm({
    validationSchema: schemaGeneralInformation(volumetryType, company),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...data.dataProcess,
      name: data?.dataProcess?.name ?? '',
      value: data?.dataProcess?.value ?? '',
      deadlineJustification: data?.dataProcess?.deadlineJustification ?? '',
      volumetry: data?.dataProcess?.volumetry ?? '',
      code: code ?? '',
      departmentId: data?.dataProcess?.department?.id ?? '',
      companyId: data?.dataProcess?.company?.id ?? '',
      sourceDescription: '',
      sourceDescriptionAnother: '',
      dataProcessDataSources: data?.dataProcess?.dataProcessDataSources
        ? helpers.dataProcess.covertDataSourcesToOptions(
            data?.dataProcess?.dataProcessDataSources,
          )
        : [],
    },
  })

  const watchSourceDescription = watch(
    constants.dataProcess.SOURCE_DESCRIPTION_FIELD,
  )

  const isEmptyLawsAndPermanentIsBlockSubmit = () => {
    snackbar.open({
      message:
        'Verifique se os campos do tempo de armazenamento estão devidamente preenchidos!',
      variant: 'error',
    })
  }

  const {
    triggerValidation: triggerTemplateValidation,
    getValues: getTemplateValues,
  } = useDataProcessTemplateQuestion()

  const onSubmit = async (dataProcess, template) => {
    try {
      if (blockSubmit) return isEmptyLawsAndPermanentIsBlockSubmit()
      let response = undefined
      let dataProcessId = data.dataProcess && data.dataProcess.id
      delete dataProcess['sourceDescription']
      if (dataProcessId) {
        response = await service.dponet.dataProcesses.put({
          dataProcessId,
          dataProcess: {
            ...dataProcess,
            storageMode: volumetryType,
            laws: laws.map((law) => ({
              id: law,
            })),
            dataProcessTemplateQuestions: template,
          },
        })
      } else {
        response = await service.dponet.dataProcesses.create({
          dataProcess: {
            ...dataProcess,
            storageMode: volumetryType,
            laws: laws.map((law) => ({
              id: law,
            })),
            dataProcessTemplateQuestions: template,
          },
        })
      }

      await setData((data) => ({
        ...data,
        dataProcess: { ...data.dataProcess, ...response.data.dataProcess },
      }))
      await loadOptions(service.dponet.auth.getCompany())

      setToNextStep(false)
      nextStep()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error, false) ||
          error?.error?.message,
        variant: 'error',
      })
    }
  }

  const setCodeByDepartment = async (departmentId) => {
    const response = await service.dponet.departments.getCode({ departmentId })
    setCode(response.data.code)
    setValue('code', response.data.code)
    setValue('dataProcessDataSources', '')
    setAllSources([])
  }

  const handleToNextStep = async () => {
    const templateValidation = await triggerTemplateValidation()
    const generalInformationValidation = await triggerValidation()

    if (templateValidation && generalInformationValidation) {
      onSubmit(getValues(), getTemplateValues())
    }
    setToNextStep(false)
  }

  useEffect(() => {
    if (toNextStep) handleToNextStep()
    // eslint-disable-next-line
  }, [toNextStep])

  useEffect(() => {
    if (data?.dataProcess?.dataProcessDataSources) {
      setAllSources(
        helpers.dataProcess.covertDataSourcesToOptions(
          data.dataProcess.dataProcessDataSources,
        ),
      )
      setIsEdit(true)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <form id="general-information">
      <DescriptionPaper description={description} />
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Grid
            id={constants.dataProcess.DATA_PROCESS_NEW_DRIVER_STEP_1}
            container
            spacing={2}
          >
            <Label
              title="Departamentos"
              description="Selecione o departamento em que os dados serão armazenados."
              xs={12}
              item
            >
              <RHFInput
                as={
                  <SelectComponent
                    items={departments}
                    open={selectDepartmentOpen}
                    error={!!errors.departmentId}
                    helperText={errors?.departmentId?.message}
                  />
                }
                onChange={async (event) => {
                  setCodeByDepartment(event.target.value)
                }}
                register={register}
                setValue={setValue}
                name="departmentId"
                mode="onChange"
              />
            </Label>
            <Label
              title="Código"
              description="Identificador único do registro em sua empresa"
              lg={6}
              item
              xs={12}
            >
              <RHFInput
                as={
                  <TextField
                    error={!!errors.code}
                    helperText={errors?.code?.message}
                    fullWidth
                  />
                }
                register={register}
                setValue={setValue}
                name="code"
                mode="onChange"
              />
            </Label>
            <Label
              title="Versão"
              description="Versão do novo processamento de dados."
              lg={6}
              item
              xs={12}
            >
              <TextField value="0" disabled />
            </Label>
            <Label
              title="Nome do processo"
              description="Descreva qual o nome do processo que vamos mapear, ou seja, aqui é onde damos início ao mapeamento"
              item
              xs={12}
            >
              <RHFInput
                as={
                  <TextField
                    variant="outlined"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    fullWidth
                  />
                }
                register={register}
                setValue={setValue}
                name="name"
                mode="onChange"
              />
            </Label>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            id={constants.dataProcess.DATA_PROCESS_NEW_DRIVER_STEP_2}
            container
            spacing={2}
          >
            <ContextDataProcessSource
              errors={errors}
              control={control}
              watchSourceDescription={watchSourceDescription}
              classes={classes}
              setValue={setValue}
              watch={watch}
              sourceOptions={sourceOptions}
              setSourceOptions={setSourceOptions}
              getValues={getValues}
              allSources={allSources}
              setAllSources={setAllSources}
              isEdit={isEdit}
            />
            <Label
              title="Por que o dado é tratado?"
              description="Para que? Por qual motivo a empresa trata esses dados? O objetivo é descobrir a finalidade"
              item
              xs={12}
            >
              <RHFInput
                as={
                  <TextField
                    multiline
                    minRows={3}
                    error={!!errors.finality}
                    helperText={errors?.finality?.message}
                    fullWidth
                  />
                }
                register={register}
                setValue={setValue}
                name="finality"
                mode="onChange"
              />
            </Label>
          </Grid>
        </Grid>
        <Grid
          id={constants.dataProcess.DATA_PROCESS_NEW_DRIVER_STEP_3}
          item
          xs={12}
        >
          <Volumetry
            register={register}
            setValue={setValue}
            errors={errors}
            setVolumetryType={setVolumetryType}
            volumetryType={volumetryType}
            laws={laws}
            setLaws={setLaws}
            data={data?.dataProcess}
          />
        </Grid>
        <Grid item xs={12}>
          <DataProcessTemplateForm step="general_information" />
        </Grid>
      </Grid>
    </form>
  )
}

GeneralInformation.propTypes = {
  description: PropTypes.string.isRequired,
}

export default GeneralInformation
