import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { TextField } from '@material-ui/core'

import { BaseDialog } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import * as service from 'service'

const TitleUpdateModal = ({ consentFormId, open, setOpen, refresh }) => {
  const [inputValue, setInputValue] = useState(null)

  const snackbar = useSnackbar()

  const handleChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleUpdate = async () => {
    try {
      await service.dponet.consent.update({
        consentFormId: consentFormId,
        title: inputValue,
      })

      snackbar.open({
        message: `Título atualizado com sucesso!`,
        variant: 'success',
      })

      setOpen(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro ao tentar atualizar o título',
        variant: 'error',
      })
    }
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Alterar título do formulário"
      closeButtonText="Cancelar"
      actionButtonText="Salvar"
      justifyActions="flex-end"
      actionButton={() => handleUpdate()}
    >
      <TextField
        label="Título"
        color="primary"
        variant="outlined"
        fullWidth
        onChange={handleChange}
      />
    </BaseDialog>
  )
}

TitleUpdateModal.propTypes = {
  consentFormId: PropTypes.number,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
}

export default TitleUpdateModal
