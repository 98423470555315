import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'

import { ConsentCustomButtonForm, ConsentCustomFieldForm } from './components'

import useStyles from './styles'

const DialogConsentField = ({
  open,
  onClose,
  selectedField,
  action,
  setCustomFields,
  customFields,
  selectedItems,
  setSelectedItems,
  setValue = () => {},
  submitButtonEdit = false,
}) => {
  const titleByAction = {
    create: 'Criar campo personalizado',
    edit: 'Editar campo personalizado',
  }

  const buttonTextByAction = {
    create: 'Criar',
    edit: 'Salvar',
  }

  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">{titleByAction[action]}</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box my={1}>
        <DialogContent>
          {submitButtonEdit ? (
            <ConsentCustomButtonForm onCloseDialog={onClose} />
          ) : (
            <ConsentCustomFieldForm
              selectedField={selectedField}
              action={action}
              setCustomFields={setCustomFields}
              customFields={customFields}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              onCloseDialog={onClose}
              setValue={setValue}
            />
          )}
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2} className={classes.boxButton}>
        <Box>
          <Button type="button" variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
        </Box>
        <Box pr={2}>
          <Button
            type="submit"
            form="consent-form"
            color="primary"
            variant="contained"
          >
            {buttonTextByAction[action]}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

DialogConsentField.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedField: PropTypes.object,
  action: PropTypes.string.isRequired,
}

DialogConsentField.defaultProps = {
  selectedField: {},
}

export default DialogConsentField
