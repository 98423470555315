import helpers from 'helpers'

const numberToPrice = (number, discountPercentage, splitsNumber = 1) => {
  if (splitsNumber > 1) {
    var splitedPrice = number / splitsNumber

    if (discountPercentage) {
      var finalPrice =
        parseFloat(splitedPrice) -
        parseFloat(splitedPrice) * (parseInt(discountPercentage) / 100)

      return `De ${splitsNumber}x de R$ ${helpers.formatters.formatToCurrencyBrl(
        splitedPrice,
      )} por ${splitsNumber}x de R$ ${helpers.formatters.formatToCurrencyBrl(
        finalPrice,
      )}`
    }

    return `${splitsNumber}x de R$ ${helpers.formatters.formatToCurrencyBrl(
      splitedPrice,
    )}`
  }

  if (discountPercentage) {
    var recurringPrice =
      parseFloat(number) -
      parseFloat(number) * (parseInt(discountPercentage) / 100)

    return `De R$ ${helpers.formatters.formatToCurrencyBrl(
      number,
    )} por R$ ${helpers.formatters.formatToCurrencyBrl(recurringPrice)}`
  }

  return `R$ ${helpers.formatters.formatToCurrencyBrl(number)}`
}

export default numberToPrice
