import dlpCover from 'images/dlp-cover.png'
import protecaoIcon from 'images/antivirus-protection.png'
import tempoRealIcon from 'images/antivirus-realtime.png'
import usabilidadeIcon from 'images/pentest-white-box.png'

import segurancaDadosIcon from 'images/antivirus-security.png'
import protecaoReputacaoIcon from 'images/dlp-brain.png'
import conformidadeIcon from 'images/antivirus-law.png'

const dlpIcons = [
  {
    title: 'Proteção Abrangente',
    altText: 'Proteção Abrangente',
    src: protecaoIcon,
  },
  {
    title: 'Segurança em Tempo Real',
    altText: 'Segurança em Tempo Real',
    src: tempoRealIcon,
  },
  {
    title: 'Usabilidade Intuitiva',
    altText: 'Usabilidade Intuitiva',
    src: usabilidadeIcon,
  },
]

const dlp = {
  id: 37,
  sectionTitle: 'Prevenção de perda de dados (DLP)',
  description:
    'A implementação eficaz do DLP é crucial para empresas que lidam com informações sensíveis, como dados financeiros, informações pessoais dos clientes, propriedade intelectual e outros ativos valiosos. A combinação de tecnologias de segurança, políticas claras e conscientização dos usuários é fundamental para garantir o sucesso de uma estratégia de prevenção contra perda de dados.',
  whatIs: {
    primaryText: 'O que é o Prevenção de perda de dados (DLP)?',
    text: 'DLP (Data Loss Prevention), em português Prevenção contra Perda de Dados, refere-se a um conjunto de técnicas e soluções projetadas para proteger informações confidenciais, sensíveis ou críticas contra vazamentos não autorizados, extravio ou acesso indevido. O objetivo principal do DLP é evitar que dados valiosos deixem a organização de maneira não autorizada.',
    icons: dlpIcons,
  },
  forWho: {
    text: 'Empresas de diversos setores e tamanhos podem se beneficiar da implementação de soluções de DLP, especialmente aquelas que lidam com informações sensíveis ou confidenciais. Alguns setores e tipos de empresas que frequentemente têm interesse em DLP são bancos, empresas ligada a área da saúde, setor de tecnologia e pesquisa, entre outros segmentos.',
    cover: dlpCover,
    coverText: 'Imagem de uma mão segurando uma lapa através de um escudo',
    benefitsList: [
      'Proteção contra Vazamento de Dados',
      'Conformidade com Regulamentações',
      'Proteção da Reputação e Propriedade Intelectual',
    ],
  },
  benefits: [
    {
      title: 'Segurança de Dados',
      altText: 'Segurança de Dados',
      description:
        'Evita vazamentos, monitora e impede transferências não autorizadas.',
      icon: segurancaDadosIcon,
    },
    {
      title: 'Conformidade com Regulamentações',
      altText: 'Conformidade com Regulamentações',
      description:
        'Garante alinhamento com regulamentações de segurança de dados.',
      icon: conformidadeIcon,
    },
    {
      title: 'Proteção da Reputação e Propriedade Intelectual',
      altText: 'Proteção da Reputação e Propriedade Intelectual',
      description:
        'Previne danos financeiros, mantém confiança e protege propriedade intelectual.',
      icon: protecaoReputacaoIcon,
    },
  ],
}

export default dlp
