import React, { useState } from 'react'
import DOMPurify from 'dompurify'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { PlusCircle } from 'react-feather'

import { BaseDialog, LoadingBox } from 'components'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'
import constants from 'constants/index'
import * as service from 'service'
import useStyles from './styles'
import helpers from 'helpers'

const ListLastUpdates = ({ mt }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const [lastUpdate, setLastUpdate] = useState({})
  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const { response, isLoading } = useFetch(service.dponet.lastUpdates.get, {
    draft: false,
  })

  const seeMoreButton = async (lastUpdateId) => {
    try {
      setLoading(true)
      const response = await service.dponet.lastUpdates.get({
        lastUpdateId: lastUpdateId,
      })
      setLastUpdate(response?.data?.lastUpdate)
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.message ||
          'Não foi possível visualizar a atualização',
        variant: 'error',
      })
    }
    setLoading(false)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const lastUpdates = response?.data?.lastUpdates

  const descriptionSanitizer = DOMPurify.sanitize(lastUpdate?.description, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  })

  return (
    <Box display="flex" width="100%" mt={mt}>
      {loading && isLoading && <LoadingBox />}
      <Grid container>
        {!isLoading && lastUpdate ? (
          lastUpdates?.map((item) => (
            <Grid item xs={12} key={item.id}>
              <Card>
                <CardContent className={classes.cardContent}>
                  <Box className={classes.boxTitle}>
                    <Box className={classes.boxTitleTwo}>
                      <Box>
                        <Chip
                          className={
                            item?.tag === constants.lastUpdates.KINDS_LABELS.N
                              ? classes.new
                              : classes.improvement
                          }
                          label={item?.tag}
                        />
                        <Typography className={classes.updatedAt}>
                          {helpers.formatters.date(item?.updatedAt)}
                        </Typography>
                      </Box>
                      <Box>
                        <Tooltip title={item?.title} placement="top">
                          <Typography className={classes.title} variant="h4">
                            {item?.title}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Box>{' '}
                    <Button
                      className={classes.buttonSeeMore}
                      onClick={() => seeMoreButton(item?.id)}
                    >
                      <PlusCircle />
                      <Typography className={classes.seeMoreText}>
                        VER MAIS
                      </Typography>
                    </Button>
                  </Box>
                  <Typography
                    className={classes.descriptionText}
                    variant="subtitle1"
                  >
                    {item?.subtitle}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Box py="11px">
            <Skeleton variant="rect" width={1000} height={150} />
          </Box>
        )}
      </Grid>

      {!loading && (
        <BaseDialog
          open={openDialog}
          setOpen={setOpenDialog}
          title={lastUpdate?.title}
          tag={lastUpdate?.tag}
          actionButton={handleCloseDialog}
          withoutBottomDivider={true}
          closeIconButton={true}
          dialogSize="lg"
        >
          <Box
            fontFamily="Roboto"
            dangerouslySetInnerHTML={{
              __html: descriptionSanitizer,
            }}
          />
        </BaseDialog>
      )}
    </Box>
  )
}

ListLastUpdates.propTypes = {
  mt: PropTypes.number,
}

ListLastUpdates.defaultProps = {
  mt: 3,
}

export default ListLastUpdates
