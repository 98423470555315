import React, { useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core'

import helpers from 'helpers'

import constants from 'constants/index'
import useStyles from './styles'

const TextFieldMenu = ({
  label = 'Recorrência',
  controlNameValue = 'recurrenceValue',
  controlNameType = 'recurrenceType',
  menuList = constants.recurrence.RECURRENCE_BUTTON_MENU_ITEMS,
  helperTranslation = helpers.recurrence.labelTranslation,
  formatter,
  disabled = false,
}) => {
  const anchorRef = useRef(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const classes = useStyles()
  const { control, errors, watch, setValue } = useFormContext()

  const valueType = watch(controlNameType)

  const handleOpenMenu = () => setMenuOpen(true)
  const handleCloseMenu = () => setMenuOpen(false)

  const handleChangeValue = (value) => {
    setValue(controlNameType, value)
    handleCloseMenu()
  }

  return (
    <Controller
      control={control}
      name={controlNameValue}
      onChange={([event]) => {
        const value = event.target.value
        return formatter ? formatter(value) : value
      }}
      as={
        <TextField
          variant="outlined"
          label={label}
          error={!!errors?.[controlNameValue] || !!errors?.[controlNameType]}
          helperText={
            <>
              {errors?.[controlNameValue]?.message ||
                errors?.[controlNameType]?.message}
            </>
          }
          inputProps={{ inputMode: 'decimal' }}
          InputProps={{
            endAdornment: (
              <Controller
                control={control}
                name={controlNameType}
                mode="onChange"
                as={
                  <InputAdornment position="end">
                    <Button
                      ref={anchorRef}
                      onClick={handleOpenMenu}
                      className={
                        !!errors?.[controlNameType] ? classes.error : ''
                      }
                      disabled={disabled}
                    >
                      {helperTranslation
                        ? helperTranslation(valueType)
                        : valueType}
                    </Button>
                    <Menu
                      anchorEl={anchorRef.current}
                      open={menuOpen}
                      onClose={handleCloseMenu}
                    >
                      {menuList?.map((item, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => handleChangeValue(item?.value)}
                          className={classes.menuItem}
                        >
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </InputAdornment>
                }
              />
            ),
          }}
          fullWidth
          disabled={disabled}
        />
      }
    />
  )
}

TextFieldMenu.propTypes = {
  controlNameType: PropTypes.string,
  controlNameValue: PropTypes.string,
  menuList: PropTypes.arrayOf(PropTypes.object),
  helperTranslation: PropTypes.func,
  formatter: PropTypes.func,
  disabled: PropTypes.bool,
}

export default TextFieldMenu
