import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  input: {
    aspectRatio: '4 / 3',
    width: '100%',
    maxHeight: '100%',
    border: `1px solid ${colors.grey[300]}`,
    borderRadius: theme.spacing(0.5),
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'Ubuntu',
  },
}))

export default styles
