import React, { useState } from 'react'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'
import usePermissions from 'hooks/usePermissions'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, CircularProgress, Typography, Button } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { Permitted, MenuButton } from 'components'
import DialogRemovePrivacyPolicies from '../DialogRemovePrivacyPolicies'

import { routes } from 'Routes'
import helpers from 'helpers'

import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'
import permission from 'constants/permissions'
import constants from 'constants/index'

const TableRowPrivacyPolicies = ({
  removePrivacyPolicy,
  privacyPolicy,
  ...rest
}) => {
  const history = useHistory()
  const snackbar = useSnackbar()

  const copyToClipboard = (link) => {
    try {
      window.navigator.clipboard.writeText(link)
      snackbar.open({
        message: 'copiado com sucesso!',
        variant: 'success',
      })
    } catch (e) {
      console.error(e)
      snackbar.open({
        message: 'ocorreu um erro ao tentar copiar o link',
        variant: 'error',
      })
    }
  }
  return (
    <TableRow {...rest}>
      <TableCell>{privacyPolicy.id}</TableCell>
      <TableCell>
        {helpers.privacyPolicies.translateCategory(privacyPolicy?.category)}
      </TableCell>
      <TableCell>
        <Typography variant="h6">{privacyPolicy?.title}</Typography>
      </TableCell>
      <TableCell>
        {helpers.privacyPolicies.status(privacyPolicy?.status)}
      </TableCell>
      <Permitted
        someTags={[
          permission.PRIVACY_POLICIES.CREATE,
          permission.PRIVACY_POLICIES.DESTROY,
        ]}
      >
        <TableCell align="right">
          <MenuButton
            id={constants.privacyPolicy.PRIVACY_POLICY_MAIN_DRIVER_STEP_3}
          >
            <Permitted tag={permission.PRIVACY_POLICIES.CREATE}>
              <Button
                color="secondary"
                onClick={() =>
                  history.push(
                    reverse(routes.privacyPolicy.edit, {
                      privacyPolicyId: privacyPolicy.id,
                    }),
                  )
                }
                fullWidth
                size="small"
              >
                Editar
              </Button>
            </Permitted>
            <Button
              color="secondary"
              onClick={() => copyToClipboard(privacyPolicy?.documentLink)}
              fullWidth
              size="small"
            >
              Copiar link
            </Button>
            <Permitted tag={permission.PRIVACY_POLICIES.DESTROY}>
              <Button
                color="secondary"
                fullWidth
                size="small"
                onClick={() => removePrivacyPolicy(privacyPolicy.id)}
              >
                Excluir
              </Button>
            </Permitted>
          </MenuButton>
        </TableCell>
      </Permitted>
    </TableRow>
  )
}

const PrivacyPoliciesTable = ({ refresh, privacyPolicies, isLoading }) => {
  const snackbar = useSnackbar()
  const [dialogRemovePrivacyPolicies, setDialogPrivacyPolicies] =
    useState(false)
  const [privacyPolicyId, setPrivacyPolicyId] = useState()

  const removePrivacyPolicy = (privacyPolicyId) => {
    setPrivacyPolicyId(privacyPolicyId)
    setDialogPrivacyPolicies(true)
  }

  const { permitted } = usePermissions()
  const widthPercentage =
    permitted(permission.PRIVACY_POLICIES.CREATE) ||
    permitted(permission.PRIVACY_POLICIES.DESTROY)
      ? ['20%', '40%', '20%', '20%']
      : ['20%', '40%', '40%', '0%']

  const handleRemove = async (privacyPolicyId) => {
    try {
      await service.dponet.privacyPolicies.destroy({ privacyPolicyId })
      snackbar.open({
        message: 'Política de privacidade removida com sucesso!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          'Não foi possível remover a política de privacidade. Tente novamente!',
        variant: 'error',
      })
    }
    setDialogPrivacyPolicies(false)
  }

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          <PerfectScrollbar>
            <Table
              size="medium"
              emptyMessage="Nenhuma política de privacidade encontrada"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width={widthPercentage[0]}>
                    Identificador
                  </TableCell>
                  <TableCell width={widthPercentage[1]}>Categoria</TableCell>
                  <TableCell width={widthPercentage[2]}>Titulo</TableCell>
                  <TableCell width={widthPercentage[3]}>Status</TableCell>
                  <Permitted
                    someTags={[
                      permission.PRIVACY_POLICIES.CREATE,
                      permission.PRIVACY_POLICIES.DESTROY,
                    ]}
                  >
                    <TableCell width={widthPercentage[4]} align="right">
                      Ações
                    </TableCell>
                  </Permitted>
                </TableRow>
              </TableHead>

              <TableBody>
                {privacyPolicies.map((privacyPolicy) => (
                  <TableRowPrivacyPolicies
                    privacyPolicy={privacyPolicy}
                    key={privacyPolicy.id}
                    removePrivacyPolicy={removePrivacyPolicy}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}

      <DialogRemovePrivacyPolicies
        open={dialogRemovePrivacyPolicies}
        setOpen={setDialogPrivacyPolicies}
        privacyPolicyId={privacyPolicyId}
        handleRemove={handleRemove}
      />
    </>
  )
}

export default PrivacyPoliciesTable
