import { makeStyles } from '@material-ui/core'

import cityBannerImage from 'images/city.png'

export default makeStyles((theme) => ({
  root: {
    background: theme.palette.subscription.primary.main,
    display: 'grid',
    width: '100%',
    minHeight: '100vh',
    overflow: 'auto',
    gridTemplateColumns: '1fr 1fr !important',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: '8fr 4fr !important',
    },
  },
  wrapper: {
    width: '100%',
    background: theme.palette.white,
    [theme.breakpoints.down('sm')]: {
      height: '85vh',
      borderRadius: theme.spacing(2, 2, 0, 0),
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: theme.spacing(0, 2, 2, 0),
    },
  },
  rightBanner: {
    placeItems: 'center',
    height: '100%',
    backgroundImage: `url(${cityBannerImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover, 50%',
    backgroundPosition: 'bottom',
    [theme.breakpoints.down('sm')]: {
      height: '15vh',
      display: 'grid',
    },
  },
  right: {
    placeItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '15vh',
      display: 'grid',
    },
  },
  labelRigth: {
    color: `${theme.palette.white} !important`,
    fontSize: 30,
    paddingTop: 20,
    fontFamily: 'Roboto',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  image: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  dponetLogo: {
    paddingTop: theme.spacing(12),
    width: '65%',
    maxHeight: '100%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '55%',
      maxWidth: '240px',
      paddingTop: 0,
    },
  },
  trailLabel: {
    color: `${theme.palette.white} !important`,
    fontSize: 25,
    paddingTop: 20,
    fontFamily: 'Roboto',
  },
}))
