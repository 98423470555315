import permissionsConstants from 'constants/permissions'

const ADMIN_ID = 1
const AGENT_ID = 3
const OUTSOURCED_DPO_PROFILE_ID = 500001
const PMO_ID = 2
const RESPONSABLE_USER = 10000
const RESPONSABLE_USER_ID = 10000
const SUPPLIER_ID = 200000

const PROFILE_CLIENT_TYPE = 'Client'

const ACTIVE = true
const INACTIVE = false

const PROFILE_MAIN_DRIVER_STEP_0 = 'profile-main-driver-step-0'
const PROFILE_MAIN_DRIVER_STEP_1 = 'profile-main-driver-step-1'
const PROFILE_MAIN_DRIVER_STEP_2 = 'profile-main-driver-step-2'

const PROFILES_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + PROFILE_MAIN_DRIVER_STEP_0,
    popover: {
      description:
        'Além dos três perfis predefinidos (Diretoria, Líderes de Departamento e Co-responsável da Empresa), oferecemos a flexibilidade de criar perfis personalizados na plataforma, permitindo a adaptação das permissões de acesso conforme suas necessidades específicas.',
    },
  },
  {
    element: '#' + PROFILE_MAIN_DRIVER_STEP_1,
    popover: {
      description: 'Crie um perfil de usuário e defina suas permissões.',
    },
  },
  {
    element: '#' + PROFILE_MAIN_DRIVER_STEP_2,
    popover: {
      description: 'Edite as informações do perfil e suas permissões.',
    },
  },
]

const PROFILES_DRIVER_STEPS_WITH_PERMISSIONS = {
  ['#' + PROFILE_MAIN_DRIVER_STEP_1]: permissionsConstants.PROFILES.CREATE,
  ['#' + PROFILE_MAIN_DRIVER_STEP_2]: permissionsConstants.PROFILES.EDIT,
}

const RESPONSIBLE_PROFILE_OPTION = {
  id: RESPONSABLE_USER_ID,
  name: 'Representante',
}

const NEW_PROFILE_DRIVER_STEP_0 = 'new-profile-driver-step-0'

const NEW_PROFILES_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + NEW_PROFILE_DRIVER_STEP_0,
    popover: {
      description:
        'Ao criar um novo perfil, você pode vinculá-lo a um novo usuário. Não esqueça de editar as permissões para definir o que o usuário poderá editar, visualizar ou criar na sua organização.',
    },
  },
]

export default {
  ADMIN_ID,
  AGENT_ID,
  OUTSOURCED_DPO_PROFILE_ID,
  PMO_ID,
  PROFILE_CLIENT_TYPE,
  RESPONSABLE_USER_ID,
  RESPONSABLE_USER,
  SUPPLIER_ID,
  ACTIVE,
  INACTIVE,
  RESPONSIBLE_PROFILE_OPTION,
  PROFILE_MAIN_DRIVER_STEP_0,
  PROFILE_MAIN_DRIVER_STEP_1,
  PROFILE_MAIN_DRIVER_STEP_2,
  PROFILES_DRIVER_STEPS_OBJECT,
  PROFILES_DRIVER_STEPS_WITH_PERMISSIONS,
  NEW_PROFILE_DRIVER_STEP_0,
  NEW_PROFILES_DRIVER_STEPS_OBJECT,
}
