import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Tooltip } from '@material-ui/core'

import { MenuButton } from 'components'
import { AuditsFinalizeModal, AuditsInactivationModal } from '..'

import constants from 'constants/index'

const AuditsActions = ({ companyAudit, setLoading, refresh }) => {
  const [open, setOpen] = useState(false)
  const [openInactivationModal, setOpenInactivationModal] = useState(false)

  const handleOpenModal = () => setOpen(true)
  const handleOpenInactivationModal = () => setOpenInactivationModal(true)

  const isPreview = companyAudit?.status === constants.audits.PREVIEW_STATUS_ID
  const isInactive =
    companyAudit?.status === constants.audits.INACTIVE_STATUS_ID

  return (
    <Box>
      <MenuButton>
        <Fragment>
          <Button onClick={handleOpenInactivationModal}>
            {isInactive ? 'Ativar' : 'Inativar'}
          </Button>
          <Tooltip
            title={
              isPreview || isInactive
                ? `Não é possível finalizar uma auditoria ${
                    isPreview ? 'prevista' : 'inativada'
                  }`
                : ''
            }
          >
            <Box>
              <Button
                onClick={handleOpenModal}
                disabled={isPreview || isInactive}
              >
                Finalizar
              </Button>
            </Box>
          </Tooltip>
        </Fragment>
      </MenuButton>
      <AuditsFinalizeModal
        companyAuditId={companyAudit?.id}
        open={open}
        setLoading={setLoading}
        setOpen={setOpen}
        refresh={refresh}
      />
      <AuditsInactivationModal
        companyAuditId={companyAudit?.id}
        isInactive={isInactive}
        open={openInactivationModal}
        setLoading={setLoading}
        setOpen={setOpenInactivationModal}
        refresh={refresh}
      />
    </Box>
  )
}

AuditsActions.propTypes = {
  companyAudit: PropTypes.object,
  refresh: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
}

export default AuditsActions
