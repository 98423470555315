const styles = (theme) => ({
  root: {
    width: '100%',
  },
  tabContainer: {
    display: 'flex',
    overflowX: 'auto',
  },
  rootTab: {
    width: 'auto',
    backgroundColor: theme.palette.white,
  },
  tabSelected: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.hover,
  },
  tabLeft: {
    borderRadius: '4px 0px 0px 0px',
  },
  tabRight: {
    borderRadius: '0px 4px 0px 0px',
  },
})

export default styles
