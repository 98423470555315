import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import constants from 'constants/index'

const styles = (variant, severity) =>
  makeStyles((theme) => ({
    dialogPaper: {
      background:
        constants.alert.CHOOSE_DIALOG_BACKGROUND_COLOR[variant]?.[severity],
      border: `2px solid ${
        variant === constants.alert.OUTLINED_VARIANT_ID
          ? constants.alert.CHOOSE_MAIN_COLOR_OF_STANDARD[severity]
          : theme.palette.paper.border
      }`,
    },
    dialogTitle: {
      color:
        variant === constants.alert.FILLED_VARIANT_ID
          ? theme.palette.common.white
          : constants.alert.CHOOSE_MAIN_COLOR_OF_STANDARD[severity],
    },
    dialogContentText: {
      color:
        variant === constants.alert.FILLED_VARIANT_ID
          ? theme.palette.common.white
          : constants.alert.CHOOSE_DARK_COLOR_OF_STANDARD[severity],
    },
  }))

styles.propTypes = {
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
}

export default styles
