import React, { useState } from 'react'
import { Edit as EditIcon } from 'react-feather'
import PropTypes from 'prop-types'

import {
  Box,
  IconButton,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { ConsentGraph, MessageBox, TitleUpdateModal } from './components'

import helpers from 'helpers'

import styles from './styles'
import theme from 'theme'

const ConsentDetailsInfo = ({ consentForm, refresh }) => {
  const [openModal, setOpenModal] = useState(false)

  const useStyles = styles(consentForm?.status)
  const classes = useStyles()

  const handleOpenModal = () => setOpenModal((prevOpen) => !prevOpen)

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(3)}>
      <Box>
        <Box className={classes.titleContainer}>
          <Typography variant="h4" className={classes.title}>
            {consentForm?.title}
          </Typography>
          <IconButton color="primary" size="small" onClick={handleOpenModal}>
            <EditIcon size={20} />
          </IconButton>
        </Box>
        <Typography variant="caption">{consentForm?.subtitle}</Typography>
      </Box>
      <Box className={classes.infoContainer}>
        <Box>
          <Typography variant="h6">ID</Typography>
          <Typography color="secondary">{consentForm?.id}</Typography>
        </Box>
        <Box>
          <Typography variant="h6">Última atualização</Typography>
          <Typography color="secondary">
            {helpers.formatters.ptBrFormatDateTime(consentForm?.updatedAt)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6">Status</Typography>
          <Typography color="secondary">
            <Chip
              label={helpers.validates.consents.consentFormStatusLabel(
                consentForm?.status,
              )}
              size="small"
              className={classes.chip}
            />
          </Typography>
        </Box>
        <Box className={classes.boxCreatedBy}>
          <Typography variant="h6">Criado por</Typography>
          <Tooltip title="teste" placement="bottom-start">
            <Typography color="secondary" noWrap>
              {consentForm?.author?.name}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={8}>
          <MessageBox consentForm={consentForm} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ConsentGraph consentForm={consentForm} />
        </Grid>
      </Grid>
      <TitleUpdateModal
        consentFormId={consentForm?.id}
        open={openModal}
        setOpen={setOpenModal}
        refresh={refresh}
      />
    </Box>
  )
}

ConsentDetailsInfo.propTypes = {
  consentForm: PropTypes.object,
}

export default ConsentDetailsInfo
