import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ lastUpdateId = '', ...params }) => {
  return await dponetAPI.get(`/last_updates/${lastUpdateId}`, { params })
}

const lastUpdates = { get }

export default lastUpdates
