const riskAcceptedRmcs = (stats, process = true) => {
  if (process) {
    return stats?.departments?.reduce(
      (value, department) =>
        value + department.nonComplianceReports?.riskAccepted,
      0,
    )
  }

  return stats?.questionnaires?.reduce(
    (value, questionnaire) =>
      value + questionnaire.nonComplianceReports?.riskAccepted,
    0,
  )
}

export default riskAcceptedRmcs
