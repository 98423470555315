import React from 'react'
import PropTypes from 'prop-types'

import { Grid, makeStyles } from '@material-ui/core'
import { ContentHeader } from 'components'

import styles from './styles'

const useStyles = makeStyles(styles)

const Header = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid
      className={classes.root}
      container
      justify="space-between"
      alignItems="center"
      spacing={1}
    >
      <Grid item sm={6} xs={10}>
        <ContentHeader lightColor />
      </Grid>
      {children}
    </Grid>
  )
}

Header.propTypes = {
  isSupplier: PropTypes.bool,
}

Header.defaultProps = {
  isSupplier: false,
}

export default Header
