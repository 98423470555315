import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  image: {
    maxHeight: 100,
    width: 'auto',
  },
  unselectedImage: {
    filter: 'grayscale(1) brightness(10) contrast(0.5)',
    transition: 'filter 0.2s ease',
  },
  selectedImage: {
    filter: 'grayscale(0)',
  },
  enabled: {
    cursor: 'pointer',

    '&:hover': {
      filter: 'grayscale(0)',
    },
  },
}))

export default styles
