import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
  },
  permissionsResume: {
    whiteSpace: 'break-spaces',
  },
}))

export default styles
