import permissions from '../permissions'

const INCIDENT_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.VISUALIZE,
    tag: permissions.INCIDENTS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.INCIDENTS.CREATE,
  },
]

export default INCIDENT_LIST
