import React from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import constants from 'constants/index'

import useStyles from './styles'

const DashboardTabs = ({ handleTabsChange, currentTab, tabs }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Box
        pb={1}
        className={classes.rootBox}
        id={constants.myLgpd.DASHBOARD_DRIVER_STEP_2}
      >
        {tabs.map((tab) => (
          <Box
            key={tab.value}
            px={1}
            className={clsx(classes.rootTab, {
              [classes.selectedTab]: currentTab === tab.value,
            })}
            onClick={(e) => handleTabsChange(e, tab.value)}
          >
            <Box mr={1}>{tab.icon}</Box>
            <Box display="flex" height="100%" alignItems="center">
              <Typography className={classes.label} variant="h6">
                {tab.label}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Grid>
  )
}

DashboardTabs.propTypes = {
  handleTabsChange: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
}

export default DashboardTabs
