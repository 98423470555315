const splitCategories = (categories, itemsPerArray) => {
  const numberOfArrays = Math.ceil(categories?.length / itemsPerArray)
  var aux = [...categories]
  var categoriesList = []

  for (var i = 0; i < numberOfArrays; i++) {
    if (i === numberOfArrays - 1) {
      categoriesList.push(aux)
    } else {
      categoriesList.push(aux.splice(-itemsPerArray))
    }
  }

  return categoriesList
}

export default splitCategories
