import React, { useEffect, useState } from 'react'
import { Box, Paper, Typography, useMediaQuery } from '@material-ui/core'

import NextLevelModal from '../NextLevelModal'

import constants from 'constants/index'

import useStyle from './styles'
import { LevelCard } from './components'

const ComplianceLevelBox = ({
  companyId,
  questionnaireLevelInfo,
  refresh,
  refreshQuestionnaireLevel,
}) => {
  const classes = useStyle()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { COMPLIANCE_LEVEL_NOT_STARTED, COMPLIANCE_LEVEL_THREE, LEVEL_CARDS } =
    constants.questionnaire

  const conformityLevel = questionnaireLevelInfo?.conformityLevel
  const conformityLevelCompleted =
    questionnaireLevelInfo?.conformityLevelCompleted

  const [loading, setLoading] = useState(false)
  const [openNextLevelModal, setOpenNextLevelModal] = useState(false)
  const [unlockLevel, setUnlockLevel] = useState(false)
  const [expanded, setExpanded] = useState(
    conformityLevel === 0 ? 0 : conformityLevel - 1,
  )

  useEffect(() => {
    if (
      conformityLevelCompleted &&
      conformityLevel !== COMPLIANCE_LEVEL_THREE &&
      conformityLevel !== COMPLIANCE_LEVEL_NOT_STARTED
    ) {
      setUnlockLevel(false)
      setOpenNextLevelModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conformityLevel, conformityLevelCompleted])

  return (
    <Paper className={classes.paperContainer}>
      <Box p={1}>
        <Typography variant="h5">Níveis de conformidade</Typography>
      </Box>
      <Box display="flex" width={'100%'} className={classes.container}>
        <Box className={classes.boxVideoPlayer}>
          <iframe
            className={classes.videoPlayer}
            src="https://player.vimeo.com/video/1002706100?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="fullscreen; picture-in-picture; clipboard-write"
            title="Tickets"
          />
        </Box>
        <Box className={isMobile ? classes.containerMobile : classes.cardBox}>
          {LEVEL_CARDS.map((statsCard, index) => (
            <LevelCard
              key={index}
              statsCard={statsCard}
              conformityLevel={conformityLevel}
              conformityLevelCompleted={conformityLevelCompleted}
              expanded={expanded}
              setExpanded={setExpanded}
              setOpenNextLevelModal={setOpenNextLevelModal}
              setUnlockLevel={setUnlockLevel}
              index={index}
            />
          ))}
        </Box>
      </Box>
      <NextLevelModal
        companyId={companyId}
        unlockLevel={unlockLevel}
        level={expanded + 1}
        open={openNextLevelModal}
        setOpen={setOpenNextLevelModal}
        loading={loading}
        setLoading={setLoading}
        refresh={refresh}
        refreshQuestionnaireLevel={refreshQuestionnaireLevel}
      />
    </Paper>
  )
}

export default ComplianceLevelBox
