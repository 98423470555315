const reavaliatedAllData = (rmc) => {
  if (!rmc?.unnecessaryDatas.length) {
    return false
  }
  let notReavaliated = rmc.unnecessaryDatas.filter(
    (unnecessaryData) => unnecessaryData.status === 'Unnecessary',
  )

  if (!notReavaliated.length) return true

  return false
}

export default reavaliatedAllData
