import React from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import {
  Grid,
  TextField,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@material-ui/core'

import { Label, Select as SelectComponent } from 'components'
import DataProcessTemplateForm from 'views/DataProcesses/components/DataProcessTemplateForm'

import useDataProcess from 'hooks/useDataProcess'
import useDataProcessTemplateQuestion from 'hooks/useDataProcessTemplateQuestion'

import dataLifeCycleSchema from '../schema'
import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'

const Form = ({ onSubmit = () => {}, dataLifeCycle = {}, dataProcess }) => {
  const volumetryOptions = helpers.dataProcess.selectVolumetryOptions()

  const {
    storageLocations,
    internalAllocationModes,
    discardModes,
    recoveries,
    protections,
  } = useDataProcess()
  const {
    triggerValidation: triggerTemplateValidation,
    getValues: getTemplateValues,
  } = useDataProcessTemplateQuestion()
  const classes = useStyles()

  const storageTypeFormated = dataLifeCycle?.storageType

  const {
    errors,
    watch,
    control,
    setError,
    triggerValidation: triggerFormValidation,
    getValues,
  } = useForm({
    validationSchema: dataLifeCycleSchema,
    defaultValues: {
      storageLocationId: dataLifeCycle?.storageLocation?.id || '',
      internalAllocationModeId: dataLifeCycle?.internalAllocationMode?.id || '',
      storageType: `${storageTypeFormated}` ?? '',
      value: dataLifeCycle?.value || '',
      volumetry: dataLifeCycle?.volumetry || '',
      retentionFinality: dataLifeCycle?.retentionFinality || '',
      discardModeId: dataLifeCycle?.discardMode?.id || '',
      recoveryId: dataLifeCycle?.recovery?.id || '',
      protectionId: dataLifeCycle?.protection?.id || '',
      anotherStorageLocation: dataLifeCycle?.storageLocation?.id || '',
      anotherDiscardMode: dataLifeCycle?.discardMode?.id || '',
      anotherRecovery: dataLifeCycle?.recovery?.id || '',
    },
  })

  const {
    STORY_MODE_PERMANENT_TYPE,
    STORAGE_DEFINED_TYPE,
    STORAGE_UNDEFINED_TYPE,
    STORAGE_PERMANENT_TYPE,
    STORY_MODE_DEFINED_TYPE,
  } = constants.dataProcess

  const handleSubmit = async (e) => {
    e.preventDefault()

    const templateValidation = await triggerTemplateValidation()
    const dataCollectedValidation = await triggerValidation()

    if (templateValidation && dataCollectedValidation) {
      onSubmit(getValues(), getTemplateValues())
    }
  }

  const triggerValidation = async () => {
    const valueDataProcess = dataProcess?.value
    const volumetryDataProcess = dataProcess?.volumetry
    const storageMode = dataProcess?.storageMode

    const dataCollectedValidation = await triggerFormValidation()

    if (storageMode === STORY_MODE_DEFINED_TYPE) {
      let formattedStorageTimeDataProcess =
        helpers.dataProcess.storageTimeValidation(
          valueDataProcess,
          volumetryDataProcess,
        )

      let formattedStorageTimeLifeCycle =
        helpers.dataProcess.storageTimeValidation(
          getValues('value'),
          getValues('volumetry'),
        )

      if (formattedStorageTimeDataProcess < formattedStorageTimeLifeCycle) {
        setError('value', {
          type: {
            invalid:
              'Insira uma data menor do que a informada nas informações gerais do processo',
          },
        })

        setError('volumetry', {
          type: {
            invalid:
              'Insira uma data menor do que a informada nas informações gerais do processo',
          },
        })
        return false
      }
    }

    return dataCollectedValidation
  }

  return (
    <form onSubmit={handleSubmit} id="data-life-cycle-form">
      <Grid
        container
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <Controller control={control} name="dataLifeCycleId" as={<></>} />
          <Label
            title="Onde os dados são armazenados?"
            description="Seleciona o local em que os dados são armazenados."
            item
          >
            <Controller
              control={control}
              name="storageLocationId"
              as={
                <SelectComponent
                  items={storageLocations}
                  anotherOption={true}
                  error={!!errors?.storageLocationId}
                  helperText={errors?.storageLocationId?.message}
                  className={classes.placeholder}
                />
              }
              mode="onBlur"
            />
          </Label>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <Label
            title="Ambiente de alocação"
            description="A informação é alocada em que ambiente?"
            item
          >
            <Controller
              name="internalAllocationModeId"
              control={control}
              as={
                <SelectComponent
                  items={internalAllocationModes}
                  error={!!errors.internalAllocationModeId}
                  helperText={errors?.internalAllocationModeId?.message}
                />
              }
              mode="onBlur"
            />
          </Label>
        </Grid>
        {watch('storageLocationId') === 'another' && (
          <Grid item xl={12}>
            <Grid item xl={6}>
              <Box mr={0}>
                <Label description="Outro: onde os dados são armazenados?" item>
                  <Controller
                    name="anotherStorageLocation"
                    control={control}
                    as={
                      <TextField
                        color="primary"
                        variant="outlined"
                        error={!!errors.anotherStorageLocation}
                        helperText={errors?.anotherStorageLocation?.message}
                        fullWidth
                      />
                    }
                    mode="onBlur"
                  />
                </Label>
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid item lg={12} xs={12}>
          <Box mt={1}>
            <Label
              title="Tempo de armazenamento"
              description="Por quanto tempo esses dados serão armazenados"
              item
            >
              <Controller
                control={control}
                name="storageType"
                as={
                  <RadioGroup>
                    <FormControlLabel
                      key={`${STORAGE_DEFINED_TYPE}`}
                      value={`${STORAGE_DEFINED_TYPE}`}
                      control={<Radio />}
                      label="Definido"
                    />
                    <FormControlLabel
                      key={`${STORAGE_UNDEFINED_TYPE}`}
                      value={`${STORAGE_UNDEFINED_TYPE}`}
                      control={<Radio />}
                      label="Indefinido"
                    />
                    {dataProcess?.storageMode === STORY_MODE_PERMANENT_TYPE && (
                      <FormControlLabel
                        key={`${STORAGE_PERMANENT_TYPE}`}
                        value={`${STORAGE_PERMANENT_TYPE}`}
                        control={<Radio />}
                        label="Permanente"
                      />
                    )}
                  </RadioGroup>
                }
                mode="onChange"
              />
            </Label>
            {watch('storageType') === `${STORAGE_DEFINED_TYPE}` && (
              <>
                <Grid container spacing={1}>
                  <Grid item lg={6} xs={12}>
                    <Label
                      title="Valor"
                      description="Qual é a quantidade do tempo?"
                      item
                    >
                      <Controller
                        name="value"
                        control={control}
                        as={
                          <TextField
                            type="number"
                            variant="outlined"
                            error={!!errors.value}
                            fullWidth
                            helperText={
                              errors?.value?.message ||
                              errors?.value?.types?.type?.invalid
                            }
                          />
                        }
                        mode="onBlur"
                        onChange={([e]) =>
                          e.target.value < 0 ? '0' : e.target.value
                        }
                      />
                    </Label>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <Label
                      title="Unidade de tempo"
                      description="Qual é o formato do tempo?"
                      item
                    >
                      <Controller
                        name="volumetry"
                        control={control}
                        as={
                          <SelectComponent
                            items={volumetryOptions}
                            error={!!errors.volumetry}
                            fullWidth
                            helperText={
                              errors.volumetry?.message ||
                              errors?.value?.types?.type?.invalid
                            }
                          />
                        }
                        mode="onBlur"
                      />
                    </Label>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      *Atenção: o tempo informado deve ser menor ou igual ao das
                      informações gerais do processo
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Label
            title="Justificativa de retenção"
            description="Justifique porque o registro é retido no departamento pelo tempo informado."
            item
          >
            <Controller
              name="retentionFinality"
              control={control}
              as={
                <TextField
                  multiline
                  rows={3}
                  color="primary"
                  variant="outlined"
                  error={!!errors.retentionFinality}
                  helperText={errors?.retentionFinality?.message}
                />
              }
              mode="onChange"
              fullWidth
            />
          </Label>
        </Grid>
        {watch('storageType') !== `${STORAGE_PERMANENT_TYPE}` && (
          <Grid item xs={12}>
            <Label
              title="Como os dados são dispostos?"
              description="Modo que os dados são dispostos após a utilização."
              item
            >
              <Controller
                name="discardModeId"
                control={control}
                as={
                  <SelectComponent
                    items={discardModes}
                    color="primary"
                    anotherOption={true}
                    error={!!errors.discardModeId}
                    helperText={errors?.discardModeId?.message}
                    fullWidth
                  />
                }
                mode="onBlur"
              />
            </Label>
            {watch('discardModeId') === 'another' && (
              <Box mt={1}>
                <Label description="Outro: como os dados são dispostos?" item>
                  <Controller
                    name="anotherDiscardMode"
                    control={control}
                    as={
                      <TextField
                        color="primary"
                        variant="outlined"
                        error={!!errors.anotherDiscardMode}
                        helperText={errors?.anotherDiscardMode?.message}
                        fullWidth
                      />
                    }
                  />
                </Label>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <Label
            title="Forma de recuperação"
            description="Qual dado é utilizado para localizar e acessar/recuperar o registro?"
            item
          >
            <Controller
              name="recoveryId"
              control={control}
              as={
                <SelectComponent
                  items={recoveries}
                  color="primary"
                  anotherOption={true}
                  error={!!errors.recoveryId}
                  helperText={errors?.recoveryId?.message}
                />
              }
              mode="onBlur"
            />
          </Label>
        </Grid>
        <Grid item md={6} xs={12}>
          <Label
            title="Forma de proteção"
            description="A informação é protegida fisicamente(impressa) ou eletronicamente (sistemas, e-mail, servidor,etc)?"
            item
          >
            <Controller
              name="protectionId"
              control={control}
              as={
                <SelectComponent
                  items={protections}
                  color="primary"
                  error={!!errors.protectionId}
                  helperText={errors?.protectionId?.message}
                  name="protectionId"
                />
              }
              mode="onBlur"
            />
          </Label>
        </Grid>
        {watch('recoveryId') === 'another' && (
          <Grid item xl={6}>
            <Box mr={0}>
              <Label description="Outro: forma de recuperação" item>
                <Controller
                  name="anotherRecovery"
                  control={control}
                  as={
                    <TextField
                      color="primary"
                      variant="outlined"
                      error={!!errors.anotherRecovery}
                      helperText={errors.anotherRecovery?.message}
                    />
                  }
                />
              </Label>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataProcessTemplateForm step="data_collected" />
        </Grid>
      </Grid>
    </form>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  dataLifeCycle: PropTypes.object,
  storageMode: PropTypes.string.isRequired,
}

export default Form
