import React from 'react'
import { Text } from '../'
import { InfoChip } from 'components'
import { Box, useMediaQuery } from '@material-ui/core'

import useAuth from 'hooks/useAuth'

import theme from 'theme'
import useStyles from './styles'

const WelcomePage = () => {
  const classes = useStyles()
  const { user } = useAuth()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  return (
    <>
      <Text color="primary" fontSize="7vh" variant="h1" gutterBottom>
        {!isMobile && (
          <span
            role="img"
            aria-label="Congratulations"
            className={classes.spanImage}
          >
            🥳{' '}
          </span>
        )}
        {user?.name}, bem vindo(a)
      </Text>
      <Text color="secondary" fontSize="3.5vh" variant="h4">
        Aprimore o seu conhecimento sobre a LGPD com a DPOnet, a maior
        plataforma de adequação e gestão da Lei Geral de Proteção de Dados no
        Brasil
      </Text>
      <Box width="100%" display="flex" justifyContent="center" my={2}>
        <InfoChip label="Apenas 10 minutos" />
      </Box>
    </>
  )
}

export default WelcomePage
