import { makeStyles } from '@material-ui/core'

const styles = (bgcolor) =>
  makeStyles((theme) => ({
    container: {
      alignItems: 'center',
      backgroundColor: bgcolor || theme.palette.statuses.disapproved,
      borderRadius: 4,
      color: theme.palette.common.white,
      display: 'flex',
      gridGap: theme.spacing(2),
      justifyContent: 'space-between',
      paddingBlock: theme.spacing(2),
      paddingInline: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    mainBox: {
      display: 'flex',
      gridGap: theme.spacing(2),
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    typography: {
      fontWeight: 500,
    },
  }))

export default styles
