import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ nonComplianceReportId = '', ...params }) => {
  return await dponetAPI.get(
    `/non_compliance_reports/${nonComplianceReportId}`,
    { params },
  )
}

const put = async ({ nonComplianceReportId, ...data }) => {
  return await dponetAPI.put(
    `/non_compliance_reports/${nonComplianceReportId}`,
    data,
  )
}

const reopen = async ({ nonComplianceReportId }) => {
  return await dponetAPI.post(
    `/non_compliance_reports/${nonComplianceReportId}/reopen`,
  )
}

const logs = async ({ nonComplianceReportId }) => {
  return await dponetAPI.get(
    `/non_compliance_reports/${nonComplianceReportId}/logs`,
  )
}

const finalize = async ({ nonComplianceReportId }) => {
  return await dponetAPI.put(
    `/non_compliance_reports/${nonComplianceReportId}/finalize`,
  )
}

const unnecessaryData = async ({ nonComplianceReportId, ...data }) => {
  return await dponetAPI.put(
    `/non_compliance_reports/${nonComplianceReportId}/update_unnecessary_data`,
    data,
  )
}

const finalizeAndInactiveDataProcess = async ({ nonComplianceReportId }) => {
  return await dponetAPI.put(
    `/non_compliance_reports/${nonComplianceReportId}/finalize_and_inactivate_data_process`,
  )
}

const availableMeasures = async ({ nonComplianceReportId }) => {
  return await dponetAPI.get(
    `/non_compliance_reports/${nonComplianceReportId}/security_measures`,
  )
}

export default {
  get,
  put,
  reopen,
  finalize,
  logs,
  unnecessaryData,
  finalizeAndInactiveDataProcess,
  availableMeasures,
}
