import palette from 'theme/palette'
import { colors } from '@material-ui/core'

const styles = (theme) => ({
  detachedBox: {
    backgroundColor: palette.primary.light,
    padding: '9px',
    borderRadius: '4px',
  },
  inactiveBox: {
    backgroundColor: palette.secondary.superLight,
    padding: '9px',
    borderRadius: '4px',
  },
  chipAnswered: {
    backgroundColor: colors.green[100],
    borderRadius: '4px',
    color: colors.green[800],
    fontWeight: 700,
    height: '21px',
  },
  chipPending: {
    backgroundColor: colors.blue[100],
    borderRadius: '4px',
    color: colors.blue[800],
    fontWeight: 700,
    height: '21px',
  },
  chipInactive: {
    backgroundColor: colors.grey[100],
    borderRadius: '4px',
    color: colors.grey[800],
    fontWeight: 700,
    height: '21px',
  },
  linkButton: {
    color: colors.lightBlue[500],
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  linkText: {
    color: colors.lightBlue[500],
    fontWeight: 700,
  },
  endFormControl: {
    marginRight: 0,
  },
  CardTechnologyCompany: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  levelAlert: {
    backgroundColor: theme.palette.subscription.primary.light,
    color: theme.palette.primary.main,
    alignItems: 'center',
  },
  levelAlertTitle: {
    margin: '8px 0',
    fontWeight: 700,
    textDecoration: 'underline',
  },
  boxButtons: {
    flexDirection: 'row',
  },
})

export default styles
