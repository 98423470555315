import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Grid, makeStyles, Box } from '@material-ui/core'

import useDataProcess from 'hooks/useDataProcess'
import useSnackbar from 'hooks/useSnackbar'

import { DescriptionPaper } from '../../'
import PaperShowDataProcess from '../../PaperShowDataProcess'
import { ConfirmationDialog } from '../'
import { ConfirmationRiskDialog } from 'components'

import DataProcessOptionsProvider from 'providers/DataProcessOptionsProvider'
import RmcProvider from 'providers/RmcProvider'

import { routes } from 'Routes'
import * as service from 'service'
import styles from '../styles'

const useStyles = makeStyles(styles)

export default function Revision({ description }) {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const history = useHistory()
  const { data, nextStep, toNextStep, setToNextStep } = useDataProcess()
  const { dataProcess } = data
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [confirmationRiskDialog, setConfirmationRiskDialog] = useState(false)
  const [adoptedFragilityId, setAdoptedFragilityId] = useState(null)
  const [defaultFragilityId, setDefaultFragilityId] = useState(null)

  useEffect(() => {
    if (toNextStep && (!confirmationDialog || !confirmationRiskDialog)) {
      setToNextStep(false)

      if (dataProcess?.acceptProcessFragility) {
        setConfirmationRiskDialog(true)
      } else {
        setConfirmationDialog(true)
      }
    }
    // eslint-disable-next-line
  }, [nextStep])

  const handleFinalize = async () => {
    try {
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: dataProcess?.id,
        statusId: 2,
        adoptedFragilityId: adoptedFragilityId || defaultFragilityId,
      })
      snackbar.open({
        message:
          'Processamento de dados criado e enviado para revisão com sucesso!',
        variant: 'success',
      })
      history.push(routes.dataProcess.all)
    } catch (error) {
      snackbar.open({
        message: 'Falha ao enviar o processamento de dados para revisão!',
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Box>
        <DescriptionPaper description={description} />
        <Grid container spacing={2} className={classes.root}>
          <DataProcessOptionsProvider>
            <Container maxWidth={false}>
              <RmcProvider>
                <PaperShowDataProcess processId={dataProcess.id} onlyShow />
              </RmcProvider>
            </Container>
          </DataProcessOptionsProvider>
        </Grid>
      </Box>
      {confirmationDialog && (
        <ConfirmationDialog
          open={confirmationDialog}
          setOpen={setConfirmationDialog}
          handleConfirm={handleFinalize}
        />
      )}
      {confirmationRiskDialog && (
        <ConfirmationRiskDialog
          open={confirmationRiskDialog}
          setOpen={setConfirmationRiskDialog}
          handleConfirm={handleFinalize}
          dataProcessId={dataProcess?.id}
          setAdoptedFragilityId={setAdoptedFragilityId}
          setDefaultFragilityId={setDefaultFragilityId}
        />
      )}
    </>
  )
}
