const reduceRoutesToArray = (routes) => {
  if (typeof routes === 'string') return [routes]
  if (!routes) return []

  return Object.values(routes)
    .filter((route) => typeof route === 'string')
    .map((route) => {
      if (route.slice(-1) === '/') {
        return [route.slice(0, -1), route]
      }

      return route
    })
    .flat(Infinity)
}

export default reduceRoutesToArray
