import React from 'react'

import PropTypes from 'prop-types'

import { Box, Typography, Grid } from '@material-ui/core'
import { LawsLists } from '../'
import constants from 'constants/index'

const Volumetry = ({ dataProcess }) => {
  const { storageMode, value, volumetry, deadlineJustification, laws } =
    dataProcess

  const storageTime = () => {
    switch (storageMode) {
      case constants.dataProcess.STORY_MODE_DEFINED_TYPE:
        return `${value} ${volumetry}`
      case constants.dataProcess.STORY_MODE_UNDEFINED_TYPE:
        return 'Indefinido'
      default:
        return 'Permanente'
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Box mt={3}>
          <Typography variant="subtitle2" color="textSecondary">
            Tempo de armazenamento da organização
          </Typography>
          <Typography color="textPrimary" variant="body2">
            {storageTime()}
          </Typography>
        </Box>
      </Grid>

      {storageMode === constants.dataProcess.STORY_MODE_PERMANENT_TYPE && (
        <LawsLists laws={laws} />
      )}

      {storageMode === constants.dataProcess.STORY_MODE_DEFINED_TYPE && (
        <Grid item xs={12}>
          <Box mt={2}>
            <Typography variant="subtitle2" color="textSecondary">
              Justificativa do tempo de Armazenamento
            </Typography>
            <Typography align="justify" color="textPrimary" variant="body2">
              {deadlineJustification}
            </Typography>
          </Box>
        </Grid>
      )}
    </>
  )
}

Volumetry.propTypes = {
  dataProcess: PropTypes.object,
}

export default Volumetry
