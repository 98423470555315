const tableCellColor = (action, classes) => {
  switch (action) {
    case 'create':
      return classes.createTableCell
    case 'destroy':
      return classes.destroyTableCell
    default:
      return classes.updateTableCell
  }
}

export default tableCellColor
