import React from 'react'
import PropTypes from 'prop-types'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { Box, makeStyles } from '@material-ui/core'

import { MonthProductCard } from '../'

import styles from './styles'
import helpers from 'helpers'
import constants from 'constants/index'

import treinamentosImage from 'images/treinamentos.png'
import servicosImage from 'images/servicos.png'
import mapeamentoImage from 'images/mapeamento.png'
import questionariosImage from 'images/questionarios.png'

const useStyles = makeStyles(styles)

const MonthProductsList = ({ handleFilterChange }) => {
  const classes = useStyles()
  const { mixpanel } = helpers

  const monthProducts = [
    {
      srcMock: treinamentosImage,
      title: 'Buscar treinamentos',
      action: () => {
        handleFilterChange(constants.marketplace.CATEGORIES_IDS.training)
        mixpanel.track('Marktplace', 'Destaques do mês', {
          action: 'Clique no card de categoria Treinamentos',
        })
      },
    },
    {
      srcMock: servicosImage,
      title: 'Buscar serviços',
      action: () => {
        handleFilterChange(constants.marketplace.CATEGORIES_IDS.service)
        mixpanel.track('Marktplace', 'Destaques do mês', {
          action: 'Clique no card de categoria Serviços',
        })
      },
    },
    {
      srcMock: mapeamentoImage,
      title: 'Buscar mapeamento',
      action: () => {
        handleFilterChange(constants.marketplace.CATEGORIES_IDS.mapping)
        mixpanel.track('Marktplace', 'Destaques do mês', {
          action: 'Clique no card de categoria Mapeamento',
        })
      },
    },
    {
      srcMock: questionariosImage,
      title: 'Buscar questionários',
      action: () => {
        handleFilterChange(constants.marketplace.CATEGORIES_IDS.journeyHelp)
        mixpanel.track('Marktplace', 'Destaques do mês', {
          action: 'Clique no card de categoria Questionários',
        })
      },
    },
  ]

  return (
    <PerfectScrollbar>
      <Box className={classes.container} pb={2}>
        {monthProducts.map((service, idx) => (
          <MonthProductCard
            key={idx}
            srcImg={service.srcMock}
            title={service.title}
            action={service.action}
          />
        ))}
      </Box>
    </PerfectScrollbar>
  )
}

MonthProductsList.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
}

export default MonthProductsList
