import { colors } from '@material-ui/core'

const styles = (theme) => ({
  containerSpacing: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12),
    },
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  blueContainer: {
    backgroundColor: theme.palette.primary.main,
  },
  whiteContainer: {
    backgroundColor: colors.common.white,
  },
  chipRoot: {
    textTransform: 'uppercase',
    borderRadius: '2px',
    fontWeight: 700,
    color: theme.palette.white,
  },
  indicated: {
    backgroundColor: theme.palette.trailWarning,
  },
  approved: {
    backgroundColor: colors.green[500],
  },
  refused: {
    backgroundColor: colors.red[500],
  },
  inactive: {
    backgroundColor: theme.palette.statuses.inactive,
  },
  buttonOptions: {
    backgroundColor: colors.green[500],
    color: colors.common.white,
    '&:hover': {
      backgroundColor: colors.green[500],
    },
  },
  optionsButton: {
    backgroundColor: theme.palette.primary.main,
    color: colors.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
  menuItem: {
    margin: '4.2rem 0rem 0rem -8rem',
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})

export default styles
