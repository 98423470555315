import constants from 'constants/index'

const defaultDataMultiOptions = (entities) => {
  if (
    entities[0]?.entityType === constants.fragilityEntities.COMPANY_ENTITY_TYPE
  )
    return

  const fragilityEntities = entities?.map((entity) => {
    if (
      entity?.entityType ===
      constants.fragilityEntities.DATA_PROCESS_ENTITY_TYPE
    ) {
      return {
        id: entity?.entityId ?? entity?.id,
        label: entity?.name ?? entity?.label,
        group: entity?.group,
        entityType: entity?.entityType,
        departmentId: entity?.departmentId,
      }
    }

    return {
      name: entity?.name ?? entity?.label,
      label: entity?.name ?? entity?.label,
      group: entity?.name ?? entity?.label,
      id: entity?.departmentId ?? entity?.entityId,
      entityType: constants.fragilityEntities.DEPARTMENT_ENTITY_TYPE,
    }
  })

  return fragilityEntities ?? []
}

export default defaultDataMultiOptions
