import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  TextField,
  Grid,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  Checkbox,
} from '@material-ui/core'

import { Label, OptionCheckIncident, LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'

const SecurityMeasure = ({ idForm, incident, handleNext, stepName }) => {
  const [loading, setLoading] = useState(false)

  const [selectedProtected, setSelectedProtectd] = useState(
    incident?.incidentTechnicalAdministrativeSecury
      ?.dataProtectedNotPossibleIdentifyHolder || '',
  )
  const [selectedSecurity, setSelectedSecurity] = useState(
    incident?.incidentTechnicalAdministrativeSecury
      ?.affectedDataProcessingSectoralSecurityRegulation + '' || '',
  )

  const [selectedSignature, setSelectedSignature] = useState(
    incident?.incidentTechnicalAdministrativeSecury?.signature || '',
  )

  const snackbar = useSnackbar()

  const isCheckedProtected = (value) => selectedProtected === value
  const isCheckedSecurity = (value) => selectedSecurity === value

  const { handleSubmit, control, setValue, reset, getValues, watch } = useForm({
    defaultValues: {
      dataProtectedNotPossibleIdentifyHolder:
        incident?.incidentTechnicalAdministrativeSecury
          ?.dataProtectedNotPossibleIdentifyHolder ?? '',
      descriptionProtectDataHolderType:
        incident?.incidentTechnicalAdministrativeSecury
          ?.descriptionProtectDataHolderType ?? '',
      descriptionSecurityMeasuresAdoptedAfterIncident:
        incident?.incidentTechnicalAdministrativeSecury
          ?.descriptionSecurityMeasuresAdoptedAfterIncident ?? '',
      affectedDataProcessingSectoralSecurityRegulation:
        incident?.incidentTechnicalAdministrativeSecury
          ?.affectedDataProcessingSectoralSecurityRegulation + '' ?? '',
      descriptionSectoralSecurityRegulationAffected:
        incident?.incidentTechnicalAdministrativeSecury
          ?.descriptionSectoralSecurityRegulationAffected ?? '',
      signature:
        incident?.incidentTechnicalAdministrativeSecury?.signature ?? '',
      descriptionOtherTechnicalAdministrativeSecurityMeasure:
        incident?.descriptionOtherTechnicalAdministrativeSecurityMeasure ?? '',
      whichSecurityMeasuresAdopted:
        incident?.dataOptionIncidents?.whichSecurityMeasuresAdopted ?? [],
      whichSecurityMeasuresAdoptedOther: helpers.incidents.otherOption(
        incident?.dataOptionIncidents?.whichSecurityMeasuresAdopted,
      ),
      afterWhichSecurityMeasuresAdopted:
        incident?.dataOptionIncidents?.afterWhichSecurityMeasuresAdopted ?? [],
      afterWhichSecurityMeasuresAdoptedOther: helpers.incidents.otherOption(
        incident?.dataOptionIncidents?.afterWhichSecurityMeasuresAdopted,
      ),
    },
  })

  useEffect(() => {
    setValue('dataProtectedNotPossibleIdentifyHolder', selectedProtected)
  }, [selectedProtected, setValue])

  useEffect(() => {
    setValue(
      'affectedDataProcessingSectoralSecurityRegulation',
      selectedSecurity,
    )
  }, [selectedSecurity, setValue])

  const { response, isLoading } = useFetch(
    service.dponet.incidents.options,
    {
      typeFields: [
        'which_security_measures_adopted',
        'after_which_security_measures_adopted',
      ],
    },
    [],
  )

  const onSubmit = async (data) => {
    try {
      setLoading(true)

      await service.dponet.incidents.addStepSecurity({
        incidentId: incident?.id,
        incident: {
          ...data,
          signature: selectedSignature,
          concludedLastStep: true,
        },
      })

      setLoading(false)
      handleNext()

      snackbar.open({
        message: `${stepName} atualizada com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: `Ocorreu um erro ao salvar a ${stepName}!`,
        variant: 'error',
      })

      setLoading(false)
      reset(getValues())
    }
  }

  return (
    <>
      <LoadingFeedback open={loading || isLoading} />
      <form onSubmit={handleSubmit(onSubmit)} id={idForm}>
        <Box px={2} pb={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box mt={2} px={1} pt={1} style={{ backgroundColor: '#F4F6F8' }}>
                <Typography variant="body1" style={{ fontWeight: '900' }}>
                  Os dados violados estavam protegidos de forma a impossibilitar
                  a identificação de seus titulares?
                </Typography>
                <FormControl size="small" variant="outlined">
                  <FormGroup>
                    {constants.incidents.SECURITY_OPTIONS?.map((option) => {
                      return (
                        <FormControlLabel
                          control={
                            <Controller
                              name="dataProtectedNotPossibleIdentifyHolder"
                              onChange={() => setSelectedProtectd(option?.id)}
                              as={
                                <Radio
                                  size="medium"
                                  checked={isCheckedProtected(option?.id)}
                                />
                              }
                              control={control}
                            />
                          }
                          label={
                            <Box
                              dangerouslySetInnerHTML={{
                                __html: option?.name,
                              }}
                            />
                          }
                          key={option?.id}
                        />
                      )
                    })}
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Label title="Descreva os meios utilizados para proteger a identidade dos titulares, e a quais tipos de dados foram aplicados:">
                <Controller
                  as={
                    <TextField
                      multiline
                      minRows={5}
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    />
                  }
                  control={control}
                  name="descriptionProtectDataHolderType"
                  mode="onBlur"
                />
              </Label>
            </Grid>
            <OptionCheckIncident
              variantLabel="h6"
              label="Antes do incidente, quais das seguintes medidas de segurança eram adotadas?"
              control={control}
              options={response?.data?.whichSecurityMeasuresAdopted}
              checkedOptions={
                incident?.dataOptionIncidents?.whichSecurityMeasuresAdopted
              }
              name="whichSecurityMeasuresAdopted"
              setValue={setValue}
              watch={watch}
              literaName="which_security_measures_adopted"
              other
            />
            <Grid item xs={12}>
              <Label title="Descreva as demais medidas de segurança técnicas e administrativas antes do incidente:">
                <Controller
                  as={
                    <TextField
                      multiline
                      minRows={5}
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    />
                  }
                  control={control}
                  name="descriptionOtherTechnicalAdministrativeSecurityMeasure"
                  mode="onBlur"
                />
              </Label>
            </Grid>
            <OptionCheckIncident
              variantLabel="h6"
              label="Após o incidente, foi adotada alguma nova medida de segurança"
              control={control}
              options={response?.data?.afterWhichSecurityMeasuresAdopted}
              checkedOptions={
                incident?.dataOptionIncidents?.afterWhichSecurityMeasuresAdopted
              }
              name="afterWhichSecurityMeasuresAdopted"
              setValue={setValue}
              watch={watch}
              literaName="after_which_security_measures_adopted"
              other
            />
            <Grid item xs={12}>
              <Label title="Se cabível, descreva as medidas de segurança adicionais adotadas após o incidente:">
                <Controller
                  as={
                    <TextField
                      multiline
                      minRows={5}
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                    />
                  }
                  control={control}
                  name="descriptionSecurityMeasuresAdoptedAfterIncident"
                  mode="onBlur"
                />
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2} px={1} pt={1} style={{ backgroundColor: '#F4F6F8' }}>
                <Typography variant="body1" style={{ fontWeight: '900' }}>
                  As atividades de tratamento de dados afetadas estão submetidas
                  a regulamentações de segurança setoriais?
                </Typography>
                <FormControl size="small" variant="outlined">
                  <FormGroup row>
                    {constants.incidents.COMMUNICATION_REQUIRED?.map(
                      (option) => {
                        return (
                          <FormControlLabel
                            control={
                              <Controller
                                name="affectedDataProcessingSectoralSecurityRegulation"
                                onChange={() => setSelectedSecurity(option?.id)}
                                as={
                                  <Radio
                                    size="medium"
                                    checked={isCheckedSecurity(option?.id)}
                                  />
                                }
                                control={control}
                              />
                            }
                            label={option?.name}
                            key={option?.id}
                          />
                        )
                      },
                    )}
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box my={2}>
                <Label title="Se cabível, indique as regulamentações setoriais de segurança aplicáveis às atividades de tratamento de dados afetadas pelo incidente:">
                  <Controller
                    as={
                      <TextField
                        multiline
                        minRows={5}
                        type="text"
                        color="primary"
                        variant="outlined"
                        fullWidth
                      />
                    }
                    control={control}
                    name="descriptionSectoralSecurityRegulationAffected"
                    mode="onBlur"
                  />
                </Label>
              </Box>
            </Grid>
            {incident?.legal_opinion && (
              <Grid item xs={12}>
                <Label title="Parecer jurídico">
                  <Controller
                    as={
                      <TextField
                        multiline
                        minRows={5}
                        type="text"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    }
                    control={control}
                    name="4"
                    mode="onBlur"
                  />
                </Label>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box p={2} style={{ backgroundColor: '#FFF7E0' }}>
                <Typography variant="body1">
                  <strong>
                    Cabe ao agente regulado solicitar à ANPD o sigilo de
                    informações protocoladas nos processos
                  </strong>{' '}
                  relativas à sua atividade empresarial, como dados e
                  informações técnicas, econômico-financeiras, contábeis,
                  operacionais, cuja divulgação possa representar violação a
                  segredo comercial ou a industrial, nos termos do §2º Art. 5º
                  do Regulamento do Processo de Fiscalização e do Processo
                  Administrativo Sancionador.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                mt={2}
                px={1}
                pt={1}
                style={{ backgroundColor: '#F4F6F8', border: '' }}
              >
                <FormControl size="small" variant="outlined">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setSelectedSignature(e.target.checked)}
                        size="medium"
                        checked={selectedSignature === true}
                      />
                    }
                    label="Declaro, sob as penas da lei, serem verdadeiras as informações prestadas acima."
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  )
}

export default SecurityMeasure
