import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  boxProvideHolder: {
    backgroundColor: theme.palette.greenSnowFlurry,
    padding: 15,
    marginTop: 10,
    borderRadius: 4,
  },
  alert: {
    border: 'none',
  },
  link: {
    cursor: 'pointer',
  },
}))

export default styles
