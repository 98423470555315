import api from 'service/api'
import axios from 'axios'
import { getBaseURL } from '../../env'

import * as service from 'service'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

const createTicket = async ({ ...data }) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/tomticket_intermediator`,
    data,
    ...config,
  })
}

const request_partner = async ({ ...data }) => {
  return await dponetAPI.post('/tomticket_intermediator/request_partner', data)
}

const solicitation = async ({ ...data }) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/tomticket_intermediator/solicitation`,
    data,
    ...config,
  })
}

const getTomticketToken = async () => {
  return await dponetAPI.get(`/tomticket_intermediator/tomticket_token`)
}

export default {
  createTicket,
  solicitation,
  request_partner,
  getTomticketToken,
}
