import api from 'service/api'

const dponetAPI = api.create('dponet')
const dponetAPINotTreatError = api.create('dponet', false)

const selectCompany = async (userCompanyId) => {
  return await dponetAPI.post(`/user_companies/${userCompanyId}/select`)
}

const list = async ({ ...params }) => {
  return await dponetAPI.get('/user_companies', { params })
}

const identify = async () => {
  return await dponetAPI.get('/user_companies/identify')
}

const changeStatus = async ({ userCompanyId, profileId = undefined }) => {
  return await dponetAPI.put(`/user_companies/${userCompanyId}/change_status`, {
    profileId,
  })
}

const edit = async ({ userCompanyId = '', ...data }) => {
  return await dponetAPI.put(`/user_companies/${userCompanyId}`, data)
}

const qrCode = async () => {
  return await dponetAPI.get(`/user_companies/cnpj_consult_qrcode`)
}

const softDelete = async ({ userCompanyId }) => {
  return await dponetAPI.delete(`user_companies/${userCompanyId}/soft_delete`)
}

const consultCertificate = async (token) => {
  return await dponetAPINotTreatError.get(`/certificate/${token}`)
}

const consultOrCreateCertificate = async ({ id, cert_type }) => {
  return await dponetAPI.get(`/user_companies/${id}/certification/${cert_type}`)
}

export default {
  consultCertificate,
  consultOrCreateCertificate,
  selectCompany,
  list,
  identify,
  changeStatus,
  edit,
  qrCode,
  softDelete,
}
