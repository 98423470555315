import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import // PopUpRedirectMarketplace,
// BackToSteps,
'./components'

import useStyles from './styles'

import helpers from 'helpers'

const FloatingButtons = ({
  // showBackToFirstSteps,
  company,
  //handleOpenTomticketDialog,
  // dialogTomticket,
  // setDialogTomticket,
}) => {
  const classes = useStyles()

  if (helpers.company.isSupplier(company)) {
    return <></>
  }

  return (
    <Box className={classes.floatingButtons}>
      {/* <BackToSteps showBackToFirstSteps={showBackToFirstSteps} /> */}

      {/* <PopUpRedirectMarketplace
           handleOpenTomticketDialog={handleOpenTomticketDialog}
           dialogTomticket={dialogTomticket}
           setDialogTomticket={setDialogTomticket}
         /> */}
    </Box>
  )
}

FloatingButtons.propTypes = {
  showBackToFirstSteps: PropTypes.bool.isRequired,
}

export default FloatingButtons
