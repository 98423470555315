import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { remove } from 'lodash'
import { useForm, Controller } from 'react-hook-form'

import { CardContent, Grid, TextField } from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'

import * as service from 'service'

import schemaUser from './schema'

import helpers from 'helpers'

const UsersForm = ({
  user,
  setLoading,
  loadData,
  formId,
  formIds,
  setFormIds,
  setHasPhone,
}) => {
  const auth = useAuth()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, reset, getValues, formState } =
    useForm({
      validationSchema: schemaUser(auth?.userCompany?.responsable),
      defaultValues: {
        name: user?.name ?? '',
        email: user?.email ?? '',
        phone: helpers.formatters.phoneDynamicMask(user?.phone ?? ''),
      },
    })
  const handleChangePhone = ([event]) => {
    const phone = helpers.formatters.onlyNumbers(event.target.value)
    setHasPhone(!!phone)
    return helpers.formatters.phoneDynamicMask(phone)
  }

  const onSubmit = async (userFormData) => {
    try {
      setLoading(true)
      await service.dponet.users.editSelf({ ...userFormData })
      if (formIds.length === 1) {
        await loadData()
        snackbar.open({
          message: 'Seus dados foram atualizado com sucesso',
          variant: 'success',
        })
      }
    } catch (error) {
      reset(getValues())
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (formState.dirty) {
      setFormIds([formId, ...formIds])
    } else {
      remove(formIds, (item) => item?.id === formId?.id)
      setFormIds([...formIds])
    }

    //eslint-disable-next-line
  }, [formState.dirty])

  return (
    <CardContent>
      <form
        ref={(form) => [(formId = form)]}
        onSubmit={handleSubmit(onSubmit)}
        id={formId}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  label="Nome"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  fullWidth
                />
              }
              control={control}
              name="name"
              mode="onChange"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  label="Email"
                  type="email"
                  color="primary"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  fullWidth
                />
              }
              control={control}
              name="email"
              mode="onBlur"
            />
          </Grid>
          {auth?.userCompany?.responsable && (
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Telefone"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    fullWidth
                  />
                }
                onChange={handleChangePhone}
                control={control}
                name="phone"
                mode="onChange"
              />
            </Grid>
          )}
        </Grid>
      </form>
    </CardContent>
  )
}

UsersForm.propTypes = {
  user: PropTypes.object,
  setLoading: PropTypes.func,
  loadData: PropTypes.func,
  formId: PropTypes.string,
  formIds: PropTypes.array,
  setFormIds: PropTypes.func,
}

export default UsersForm
