const JURIDIC_KIND = 'C'
const PERSONAL_KIND = 'P'
const TEMPLATE_KIND = 'T'
const SUPPLIER_KIND = 'S'

const INITIATED_STATUS = 'INITIATED'
const PAYMENT_FAILED_STATUS = 'PAYMENT_FAILED'
const FINALIZED_STATUS = 'FINALIZED'

const WAITING_PAYMENT_STATUS = 'WAITING_PAYMENT'
const BILLET_NOT_PAID_STATUS = 'NOT_PAID'
const PAID_STATUS = 'PAID'
const FAILURE_STATUS = 'FAILURE'
const CANCELED_STATUS = 'CANCELED'
const PRE_CAPTURED_STATUS = 'PRE_CAPTURED'
const INTERNAL_ERROR_STATUS = 'INTERNAL_ERROR'

// Steps separados da seguinte maneira:  [step, subStep]

const FIRST_STEP = [0, 0]
const ABOUT_COMPANY_STEP = [1, 0]
const PAYMENT_STEP = [2, 0]
const PAYMENT_STATUS_STEP = [2, 1]
const BILLET_STEP = [2, 2]
const FINALIZED_STATUS_STEP = [3, 0]

const PAYMENT_STATUSES = {
  WAITING_PAYMENT: 'Aguardando pagamento',
  PAID: 'Pago',
  FAILURE: 'Falha',
  CANCELED: 'Cancelado',
  OVERDUE: 'Vencido',
}

const PAYMENT_STATUSES_ALERT_COLOR = {
  WAITING_PAYMENT: 'warning',
  PAID: 'success',
  FAILURE: 'error',
  CANCELED: 'error',
}

const REAL_TYPE = 'REAL'
const TRIAL_TYPE = 'TRIAL'

const CARD_METHOD = 1
const BILLET_METHOD = 6

const DATE_EXPIRATION_BILLET = [
  { id: 5, name: 5 },
  { id: 10, name: 10 },
  { id: 15, name: 15 },
  { id: 20, name: 20 },
  { id: 25, name: 25 },
]

const preRegistrations = {
  JURIDIC_KIND,
  PERSONAL_KIND,
  INITIATED_STATUS,
  PAYMENT_FAILED_STATUS,
  FINALIZED_STATUS,
  WAITING_PAYMENT_STATUS,
  BILLET_NOT_PAID_STATUS,
  PAID_STATUS,
  FAILURE_STATUS,
  CANCELED_STATUS,
  FIRST_STEP,
  PAYMENT_STEP,
  BILLET_STEP,
  ABOUT_COMPANY_STEP,
  PAYMENT_STATUS_STEP,
  FINALIZED_STATUS_STEP,
  INTERNAL_ERROR_STATUS,
  CARD_METHOD,
  BILLET_METHOD,
  TEMPLATE_KIND,
  SUPPLIER_KIND,
  DATE_EXPIRATION_BILLET,
  PAYMENT_STATUSES,
  PAYMENT_STATUSES_ALERT_COLOR,
  REAL_TYPE,
  TRIAL_TYPE,
  PRE_CAPTURED_STATUS,
}

export default preRegistrations
