const lightBlue = '#B8DAF7'

const colors = {
  primary: '#076EA0',
  secondary: '#233154',
  background: {
    secondary: '#23215A',
    card: '#151E07',
    cardBlue: '#233154',
  },
  button: {
    primary: '#80B92B',
    light: lightBlue,
    green: '#98CF45',
    darkGreen: '#16A63C',
  },
  info: {
    primary: '#D4E9FB',
  },
  text: {
    primary: '#546E7A',
    secondary: lightBlue,
  },
  icon: {
    green: '#16A63C',
  },
}

export default colors
