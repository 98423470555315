import DigitalPrint from 'images/digital_print.jpg'

const styles = (theme) => ({
  footer: {
    width: '100%',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    backgroundColor: theme.palette.white,
  },
  root: {
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    margin: 0,
  },
  container: {
    maxWidth: '386px',
    backgroundImage: `url(${DigitalPrint})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '4px',
    margin: '20px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
  },
  text: {
    color: theme.palette.white,
  },
  visualizeButton: {
    color: theme.palette.white,
    border: `1px solid ${theme.palette.white}`,
  },
  privacyImage: {
    width: '125px',
    height: '40px',
  },
})

export default styles
