import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography, makeStyles } from '@material-ui/core'
import { LoadingTextComponent } from 'components'
import { Layers as LayersIcon } from 'react-feather'

import styles from './styles'

const useStyles = makeStyles(styles)

const CardProcessOrigin = ({ handleBack, dataProcessName, isLoading }) => {
  const classes = useStyles()

  return (
    <Box
      className={classes.root}
      onClick={handleBack}
      display="flex"
      alignItems="center"
      width="100%"
      p={2}
      mb={4}
    >
      <Box px={2}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.boxAlertIncidents}
        >
          <LayersIcon color="white" size={18} />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Typography color="textSecondary" variant="body2">
            REGISTRO DE ORIGEM
          </Typography>
          <LoadingTextComponent loading={isLoading}>
            <Typography color="textPrimary" variant="body1">
              {dataProcessName}
            </Typography>
          </LoadingTextComponent>
        </Box>
      </Box>
    </Box>
  )
}

CardProcessOrigin.propTypes = {
  handleBack: PropTypes.func,
  dataProcessName: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default CardProcessOrigin
