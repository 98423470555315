import React from 'react'
import { PendingActivity } from './components'
import {
  Layers as LayersIcon,
  TrendingUp as TrendingUpIcon,
} from 'react-feather'
import { Box, Typography } from '@material-ui/core'

import useStyles from './styles'

const mockData = [
  {
    title: 'RMCS DE QUESTIONÁRIO',
    subtitle:
      'Representante e Líderes devem analisar e implementar as ações recomendadas nos RMCs de questionários (jurídico, governança...',
    icon: <TrendingUpIcon />,
    // Implementar na atividade #(não definida)
    handleClick: () => alert('clicou'),
  },

  {
    title: 'RMCS DE PROCESSOS',
    subtitle:
      'Representantes e Líderes validam os processos que foram recebidos e, caso necessário, fazem adaptações e/ou adicionem processo...',
    icon: <TrendingUpIcon />,
    // Implementar na atividade #(não definida)
    handleClick: () => alert('clicou'),
  },

  {
    title: 'RMCS DE INCIDENTES',
    subtitle:
      'Ao informar um incidente na plataforma um RMC é gerado para que um plano de ação seja estabelecido, visando mitigar impactos.',
    icon: <TrendingUpIcon />,
    // Implementar na atividade #(não definida)
    handleClick: () => alert('clicou'),
  },

  {
    title: 'AUDITORIA DE PROCESSOS',
    subtitle:
      'Alguns processos foram reabertos para auditoria. Representante e Líderes devem revisá-lo para garantir que estão atualizados...',
    icon: <LayersIcon />,
    // Implementar na atividade #(não definida)
    handleClick: () => alert('clicou'),
  },

  {
    title: 'SUGESTÃO DE NOVOS PROCESSOS',
    subtitle:
      'Para auxiliar na manutenção do mapeamento, o DPOnet sugeriu novos processo que podem existir na rotina da sua organização',
    icon: <LayersIcon />,
    // Implementar na atividade #(não definida)
    handleClick: () => alert('clicou'),
  },
  {
    title: 'SUGESTÃO DE ALTERAÇÕES DE PROCESSOS',
    subtitle:
      'Para auxiliar na manutenção do mapeamento, o DPOnet sugeriu algumas alterações para seus processos. Você pode optar por...',
    icon: <LayersIcon />,
    // Implementar na atividade #(não definida)
    handleClick: () => alert('clicou'),
  },
]

const ImplementationTrail = () => {
  const classes = useStyles()
  return (
    <>
      <Box marginBottom={2}>
        <Typography variant="h4" className={classes.activitiesTitle}>
          Atividades Pendentes
        </Typography>
        <Typography className={classes.activitieSubtitle}>
          {' '}
          Você tem atividades pendentes, indicadas pelo DPOnet.
        </Typography>
      </Box>

      {mockData.map((data, index) => (
        <PendingActivity
          key={index}
          title={data.title}
          subtitle={data.subtitle}
          icon={data.icon}
          handleClick={data.handleClick}
        />
      ))}
    </>
  )
}

export default ImplementationTrail
