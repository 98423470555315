import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { Box, Typography, Grid, makeStyles, Tooltip } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'
import { Info as InfoIcon } from 'react-feather'

import constants from 'constants/index'
import helpers from 'helpers'

import styles from './styles'
const useStyles = makeStyles(styles)

const DataTreatmentAgent = ({ data }) => {
  const classes = useStyles()

  const { TRANSLATED_TYPE } = constants.dataTreatmentAgents

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        className={classes.titleContainer}
      >
        <Typography variant="h5">Agentes de tratamento</Typography>
        {isEmpty(data?.dataTreatmentAgents) && (
          <Tooltip
            title={constants.dataTreatmentAgents.REQUIREDS}
            placement="bottom-start"
          >
            <Box ml={1} display="flex">
              <InfoIcon size={18} color="red" />
            </Box>
          </Tooltip>
        )}
      </Box>
      <Grid xs={12} item>
        <Box maxHeight="82%">
          <Table
            emptyMessage="NENHUM AGENTE DE TRATAMENTO FOI ENCONTRADO"
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="40%">Organização</TableCell>
                <TableCell width="30%">CNPJ/CPF</TableCell>
                <TableCell width="30%">Tipo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.dataTreatmentAgents?.map((dataTreatmentAgent) => (
                <TableRow key={dataTreatmentAgent?.id}>
                  <TableCell>
                    <Typography className={classes.ellipsisTable}>
                      {dataTreatmentAgent?.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.ellipsisTable}>
                      {helpers.formatters.cnpjOrCpf(
                        dataTreatmentAgent?.document,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.ellipsisTable}>
                      {TRANSLATED_TYPE[dataTreatmentAgent?.agentType]}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Grid>
    </>
  )
}

DataTreatmentAgent.propTypes = {
  data: PropTypes.object,
}

export default DataTreatmentAgent
