import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'

import mixpanel from 'mixpanel-browser'
import * as Sentry from '@sentry/react'

import { getMixPanelAuthKey } from 'service/env'
import { routes } from 'Routes'
import * as service from 'service'
import AuthContext from 'contexts/AuthContext'
import constants from 'constants/index'
import helpers from 'helpers'

const AuthProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [companies, setCompanies] = useState(null)
  const [company, setCompany] = useState(null)
  const [permissions, setPermissions] = useState(null)
  const [menuItems, setMenuItems] = useState([])
  const [showHelpButton, setShowHelpButton] = useState(true)
  const [temporaryPassword, setTemporaryPassword] = useState(false)
  const [actualStep, setActualStep] = useState(null)
  const [countBadge, setCountBadge] = useState()
  const [homeRoute, setHomeRoute] = useState()
  const [userCompany, setUserCompany] = useState()
  const [companyTrails, setCompanyTrails] = useState([])
  const [faqsDocumentations, setFaqsDocumentations] = useState([])
  const [freeTrialBlock, setFreeTrialBlock] = useState(false)

  const { pathname } = useLocation()

  const documentRaw = helpers.formatters.onlyNumbers(company?.document || '')

  const isReportOrCertificateValidation =
    pathname.startsWith('/reports/validate') ||
    pathname.startsWith('/certificate')

  useEffect(() => {
    if (
      service.dponet.auth.isAuthenticated() &&
      !isReportOrCertificateValidation
    ) {
      loadDataWithAnimation()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isEmpty(permissions) && user && homeRoute) {
      loadMenuItems()
    }
    // eslint-disable-next-line
  }, [
    countBadge,
    permissions,
    homeRoute,
    company,
    userCompany,
    documentRaw,
    user,
  ])

  const permitted = (tag, loadedPermissions) => {
    const permission = loadedPermissions.find(
      (permission) => permission.tag === tag,
    )

    if (!permission) {
      return true
    }

    return permission.status
  }

  const loadMenuItems = () => {
    const isSupplier = helpers.company.isSupplier(company)

    const menuItems = helpers.auth.mountMenuItems(countBadge, isSupplier, user)
    setMenuItems(menuItems)
  }

  const loadOut = () => {
    setLoaded(true)
    setIsLoading(false)
    setUser(null)
    setCompany(null)
    setCompanies(null)
    setPermissions(null)
    setHomeRoute()
    setMenuItems([])
    setCompanyTrails([])
    service.dponet.auth.removeCompanyToken()
  }

  const mixpanelBrowser = () => {
    mixpanel.init(getMixPanelAuthKey(), {
      debug: false, // TRUE somente em um ambiente de desenvolvimento
      ignore_dnt: true,
      secure_cookie: true,
      cross_site_cookie: false,
      api_transport: 'XHR',
      persistence: 'cookie',
      batch_requests: false,
    })

    if (!!userCompany) {
      mixpanel.register_once({
        userProfile: userCompany?.profile?.name,
        companyDocument: userCompany?.company?.document,
      })
    }

    setTimeout(() => {
      mixpanel.track(window.document.title, {
        userId: user?.id,
        companyId: company?.id,
        current_path: pathname,
      })
    }, 2000)
  }

  useEffect(() => {
    if (window.document.title !== '') {
      mixpanelBrowser()
    }
    // eslint-disable-next-line
  }, [window.document.title])

  const loadSuggestionBadge = async (permissions) => {
    if (
      !isEmpty(permissions) &&
      permitted('list_and_manage_suggested_data_processes', permissions)
    ) {
      const responseDataProcessesSuggestions =
        await service.dponet.dataProcesses.totalSuggested({})
      return responseDataProcessesSuggestions?.data?.totalSuggested
    }
  }

  const getByState = async (func, stateData, dataKey, params = {}) => {
    if (isEmpty(stateData)) {
      return await func(params)
    }

    return { data: { [dataKey]: stateData } }
  }

  const loadData = async () => {
    let responseUser = await service.dponet.users.me()
    const user = responseUser.data.user
    setTemporaryPassword(user?.temporaryPassword)
    Sentry.setUser(user)

    const responsePermissions = await service.dponet.users.permissions()
    const newPermissions = responsePermissions?.data?.profilePermissions || []

    await loadCompanyTrails()

    await Promise.all([
      service.dponet.usersCompanies.identify(),
      getByState(
        service.dponet.faqsDocumentations.get,
        faqsDocumentations,
        'faqsDocumentations',
        {
          kind: constants.faqsDocumentations.KINDS.DOCUMENTATION,
          perPage: 10000000,
          onlyCollaborators: false,
          draft: false,
          active: true,
          minimal: true,
        },
      ),
      loadSuggestionBadge(newPermissions),
    ]).then(
      async ([
        responseUsersCompaniesIdentify,
        responseFaqsDocumentations,
        responseBadge,
      ]) => {
        const identifiedCompany =
          responseUsersCompaniesIdentify.data?.userCompany?.company
        const isSupplier = helpers.company.isSupplier(identifiedCompany)
        const identifiedUserCompany =
          responseUsersCompaniesIdentify.data?.userCompany
        if (identifiedCompany) {
          setCountBadge(responseBadge)
          service.dponet.auth.setCompany(identifiedCompany?.id)
          setUserCompany(identifiedUserCompany)

          if (identifiedCompany?.token) {
            service.dponet.auth.setCompanyToken(identifiedCompany?.token)
          }
        }

        if (isSupplier) {
          setHomeRoute(routes.questionnaire.all)
        } else {
          setHomeRoute(routes.myLgpd.home)
        }

        setFaqsDocumentations(
          responseFaqsDocumentations.data?.faqsDocumentations ?? [],
        )
        setUser(responseUser.data.user)
        setCompany(identifiedCompany ?? null)
        setCompanies(responseUser.data.user.companies)
        setPermissions(newPermissions)
        if (identifiedUserCompany) {
          if (mixpanel.get_distinct_id() !== identifiedUserCompany?.id) {
            mixpanel.identify(identifiedUserCompany?.id)
          }
        }
      },
    )
  }

  const loadUser = async () => {
    const responseUser = await service.dponet.users.me()
    const user = responseUser?.data?.user
    setTemporaryPassword(user?.temporaryPassword)
    Sentry.setUser(user)
    setUser(user)
    return user
  }

  const loadCompany = async () => {
    const userCompanyResponse = await service.dponet.usersCompanies.identify()
    setCompany(userCompanyResponse?.data?.userCompany?.company)
    return userCompanyResponse?.data?.userCompany?.company
  }

  const loadUserCompany = async () => {
    const userCompanyResponse = await service.dponet.usersCompanies.identify()
    setCompany(userCompanyResponse?.data?.userCompany)
    return userCompanyResponse?.data?.userCompany
  }

  const loadCompanyTrails = async () => {
    const responseCompanyTrails = await service.dponet.companyTrails.get()
    setCompanyTrails(responseCompanyTrails.data.companyTrails)
    return responseCompanyTrails.data.companyTrails
  }

  const loadDataWithAnimation = async () => {
    setIsLoading(true)
    setLoaded(false)

    try {
      await loadData()
    } finally {
      setLoaded(true)
      setIsLoading(false)
    }
  }

  const handleSelectCompany = () => {
    setCompany({})
    setPermissions([])
    setHomeRoute(null)
    setMenuItems([])
    setCompanyTrails([])
    service.dponet.auth.removeCompany()
    service.dponet.auth.clearStorage()
    mixpanel.reset()
    service.history.push(routes.selectCompany)
  }

  return (
    <AuthContext.Provider
      value={{
        actualStep,
        companies,
        company,
        companyTrails,
        countBadge,
        faqsDocumentations,
        freeTrialBlock,
        handleSelectCompany,
        homeRoute,
        isLoading,
        loadCompany,
        loadCompanyTrails,
        loadData,
        loadDataWithAnimation,
        temporaryPassword,
        loaded,
        loadOut,
        loadUser,
        loadUserCompany,
        menuItems,
        mixpanelBrowser,
        permissions,
        setActualStep,
        setCompanies,
        setCompany,
        setCompanyTrails,
        setCountBadge,
        setFreeTrialBlock,
        setIsLoading,
        setLoaded,
        setShowHelpButton,
        setUser,
        setUserCompany,
        showHelpButton,
        user,
        userCompany,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
