const processCharts = (
  statsProcess,
  permitted,
  department = false,
  setDrawerOpen = () => {},
) => {
  return {
    title: department ? `Processos de ${department}` : 'Processos',
    type: 'process',

    visible: permitted('view_dashboard_process'),
    menuItems: [
      {
        name: 'Filtros',
        action: () => setDrawerOpen(true),
        visible: permitted('list_departments'),
      },
    ],
    tabs: [
      {
        value: 0,
        label: 'Status',
      },
      {
        value: 1,
        label: 'Risco',
      },
    ],
    tabsPanels: [
      {
        value: 0,
        id: 'process-0',
        data: [
          statsProcess?.inactive,
          statsProcess?.waitingReview,
          statsProcess?.disapproved,
          statsProcess?.approved,
          statsProcess?.pending,
        ],
        labels: [
          'Inativos',
          'Em revisão',
          'Reprovados',
          'Aprovados',
          'Pendentes',
        ],
        items: [
          {
            title: 'PENDENTES',
            subtitle: statsProcess?.pending,
          },
          {
            title: 'APROVADOS',
            subtitle: statsProcess?.approved,
          },
          {
            title: 'REPROVADOS',
            subtitle: statsProcess?.disapproved,
          },
          {
            title: 'EM REVISÃO',
            subtitle: statsProcess?.waitingReview,
          },
          {
            title: 'INATIVOS',
            subtitle: statsProcess?.inactive,
          },
        ],
      },
      {
        value: 1,
        id: 'process-1',
        data: [
          statsProcess?.severeFragility,
          statsProcess?.highFragility,
          statsProcess?.mediumFragility,
          statsProcess?.lowFragility,
        ],
        labels: ['Severo', 'Alto', 'Médio', 'Baixo'],
        items: [
          {
            title: 'BAIXO',
            subtitle: statsProcess?.lowFragility,
          },
          {
            title: 'MÉDIO',
            subtitle: statsProcess?.mediumFragility,
          },
          {
            title: 'ALTO',
            subtitle: statsProcess?.highFragility,
          },
          {
            title: 'SEVERO',
            subtitle: statsProcess?.severeFragility,
          },
        ],
      },
    ],
  }
}

export default processCharts
