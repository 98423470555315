import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const checkInvite = async ({ ...supplierParams }, companyToken) =>
  await dponetAPI.post(
    `/supplier_invites/${companyToken}/check_invite`,
    supplierParams,
  )

const checkSupplierRelations = async ({ ...supplierParams }, companyToken) =>
  await dponetAPI.post(
    `/supplier_invites/${companyToken}/check_supplier_relations`,
    supplierParams,
  )

const create = async ({ ...params }) =>
  await dponetAPI.post('/supplier_invites', params)

const createSupplierRelations = async ({ ...supplierParams }, companyToken) =>
  await dponetAPI.post(
    `/supplier_invites/${companyToken}/create_supplier_relations`,
    supplierParams,
  )

const inviterInfos = async (companyToken) =>
  await dponetAPI.get(`/supplier_invites/${companyToken}/company_infos`)

const listInvites = async ({ ...params }) =>
  await dponetAPI.get('/supplier_invites/list_invites', { params })

const supplierInvite = {
  checkInvite,
  checkSupplierRelations,
  create,
  createSupplierRelations,
  inviterInfos,
  listInvites,
}

export default supplierInvite
