import React from 'react'

import { makeStyles } from '@material-ui/core'

import { Page, Container } from 'components'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import constants from 'constants/index'
import styles from './styles'

import {
  CardsListSection,
  FaqsSection,
  Section,
  WhatIsSection,
} from './components'
import theme from 'theme'

const useStyles = makeStyles(styles)

const MarketplaceMain = () => {
  const { home } = constants.marketplace.pages

  const classes = useStyles()
  return (
    <Page title="Marketplace - Início">
      <Container maxWidth={false} className={classes.container}>
        <Section
          title="Marketplace"
          subtitle="O Marketplace é um HUB de produtos e serviços que trazem uma variedade de ferramentas que aumentam a segurança da sua empresa em relação a proteção de dados. Aqui garantimos facilidade em contratar serviços pré-selecionados fornecidos por parceiros confiáveis, ajudando a impulsionar a a segurança dos seus clientes e solidificando sua reputação como líder em governança de dados responsável."
        />
        <CardsListSection
          title="Soluções mais solicitadas pelos nossos clientes"
          listItems={home.solutions}
          bgColor={theme.palette.black}
          id="services-section"
        />
        <WhatIsSection
          icons={home.icons}
          sectionTitle="O que é o Marketplace?"
        />
        <FaqsSection faqs={home.faqs} />
      </Container>
    </Page>
  )
}

export default MarketplaceMain
