import React from 'react'
import { X as XIcon } from 'react-feather'
import PropTypes from 'prop-types'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core'

import styles from './styles'

import constants from 'constants/index'

const AlertDialog = ({
  open,
  onClose,
  content,
  Icon,
  variant = 'standard',
  severity = 'success',
  dialogContentProps,
  dialogActions,
  ...rest
}) => {
  const useStyles = styles(variant, severity)
  const classes = useStyles()
  const AlertIcon = Icon || constants.alert.CHOOSE_DIALOG_ICON[severity]

  return (
    <Dialog
      open={open}
      onClose={onClose}
      {...rest}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle align="center" className={classes.dialogTitle}>
        <Box display="flex" justifyContent="end">
          <IconButton onClick={onClose}>
            <XIcon size={20} className={classes.dialogContentText} />
          </IconButton>
        </Box>
        <AlertIcon size={82} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          className={classes.dialogContentText}
          variant="h5"
          align="center"
          {...dialogContentProps}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box pb={4}>{dialogActions}</Box>
      </DialogActions>
    </Dialog>
  )
}

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  dialogContentProps: PropTypes.object,
  dialogActions: PropTypes.node,
}

export default AlertDialog
