import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { Download as DownloadIcon } from 'react-feather'

import useFirstStep from 'hooks/useFirstStep'

import useStyles from './styles'

const DownloadButton = ({ label, mixpanelId, ...rest }) => {
  const classes = useStyles()
  const { registerTrack } = useFirstStep()

  const handleClick = () => {
    if (!!rest?.onClick) {
      rest.onClick()
    }
    registerTrack(mixpanelId)
  }

  return (
    <Button
      className={classes.button}
      {...rest}
      endIcon={<DownloadIcon size={16} />}
      onClick={handleClick}
    >
      {label}
    </Button>
  )
}

DownloadButton.propTypes = {
  label: PropTypes.string.isRequired,
  mixpanelId: PropTypes.string,
}

export default DownloadButton
