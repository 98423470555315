import { makeStyles } from '@material-ui/core'
import hexagonBackground from 'images/hexagonos_background.svg'

const styles = makeStyles((theme) => ({
  content: {
    zIndex: 1,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minHeight: '70vh',
    },
  },
  container: {
    height: 'inherit',
  },
  centeredContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  hexagonBackground: {
    backgroundImage: `url(${hexagonBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '5S0vh',
  },
  topLeft: {
    backgroundPositionX: 'left',
    backgroundPositionY: theme.spacing(6),
  },
  topRight: {
    backgroundPositionX: 'right',
    backgroundPositionY: theme.spacing(6),
  },
  fullHeight: {
    [theme.breakpoints.down('sm')]: {
      height: '87vh',
    },
  },
  widthContent: {
    width: `calc(${theme.breakpoints.values.sm}px + 18vh)`,
  },
}))

export default styles
