import * as yup from 'yup'

const combinedSchema = (reportSelected) => {
  return yup.object().shape({
    consentFormId: yup.string().when('reportSelected', {
      is: 'reportByForm',
      then: yup.string().required('Consent Form ID é obrigatório'),
    }),
    email: yup.string().when('reportSelected', {
      is: 'reportByPeople',
      then: yup
        .string()
        .required('Email é obrigatório')
        .email('Email inválido')
        .max(254),
    }),
  })
}

export default combinedSchema
