import React from 'react'
import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'
import { Tabs, Tab, Box } from '@material-ui/core'

import useStyles from './styles'
import constants from 'constants/index'

const TabsSuggestions = ({ currentTab, handleTabsChange, suggestions }) => {
  const classes = useStyles()

  return (
    <>
      <Box width="100%">
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          textColor="primary"
        >
          {constants.suggestionChanges.TABS_SUGGESTIONS.map(
            (tab) =>
              !isEmpty(suggestions[tab.value]) && (
                <Tab
                  className={classes.root}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  onClick={(event) => handleTabsChange(event, tab.value)}
                />
              ),
          )}
        </Tabs>
      </Box>
    </>
  )
}

TabsSuggestions.propTypes = {
  currentTab: PropTypes.string,
  handleTabsChange: PropTypes.func,
  suggestions: PropTypes.object,
}

export default TabsSuggestions
