import * as yup from 'yup'

export const schema = () => {
  return yup.object().shape({
    attachments: yup.mixed(),
    description: yup.string().required(),
  })
}

export default schema
