const styles = (theme) => ({
  dataCollectedContainer: {
    padding: theme.spacing(1),
  },
  dataCollectedTable: {
    marginBottom: theme.spacing(2),
  },
  button: {
    padding: '0px',
  },
  buttonLabel: {
    textTransform: 'capitalize',
  },
  buttonTitle: {
    paddingLeft: '5px',
  },
})

export default styles
