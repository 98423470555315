import React, { useState, useEffect } from 'react'

import {
  Box,
  Card,
  CardHeader,
  Collapse,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons/'

import constants from 'constants/index'
import * as service from 'service'

import useFetch from 'hooks/useFetch'
import useStyles from './styles'

const QuestionnaireSupplier = ({
  questionnaires,
  setQuestionnaires,
  setQuestionnairesCompany,
}) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const { response, isLoading } = useFetch(
    service.dponet.questionnaires.get,
    {},
    [],
  )

  const basicRequirement = constants.questionControl.KIND.BASIC_REQUIREMENT

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target
    const updatedQuestionnaires = checked
      ? [...questionnaires, value]
      : questionnaires.filter((id) => id !== value)

    setQuestionnaires(updatedQuestionnaires)
  }

  const questionnairesResponse =
    response?.data?.questionnaires.sort((a, b) => {
      if (a.name === basicRequirement) return -1
      if (b.name === basicRequirement) return 1
      return 0
    }) || []

  useEffect(() => {
    if (!isLoading && setQuestionnairesCompany) {
      setQuestionnairesCompany(questionnairesResponse)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, response])

  return (
    <>
      <Box className={classes.root}>
        <IconButton
          className={classes.collapse}
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Typography className={classes.collapse}>
          Personalizar Diagnóstico
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box mt={1}>
          <Card>
            <CardHeader title="Questionários" />
            <Divider />
            <Box m={2}>
              {!isLoading && (
                <FormControl size="small" variant="outlined">
                  <Typography variant="body1">
                    Selecione quais questionários o fornecedor deverá responder
                  </Typography>
                  <FormGroup>
                    {questionnairesResponse?.map((kind) => {
                      return (
                        <FormControlLabel
                          key={kind.name}
                          control={<Checkbox />}
                          label={
                            kind.name +
                            (kind.name === basicRequirement
                              ? ' (obrigatório)'
                              : '')
                          }
                          value={kind.name}
                          checked={
                            kind.name === basicRequirement ||
                            questionnaires.includes(kind.name)
                          }
                          onChange={handleCheckboxChange}
                        />
                      )
                    })}
                  </FormGroup>
                </FormControl>
              )}
            </Box>
          </Card>
        </Box>
      </Collapse>
    </>
  )
}

export default QuestionnaireSupplier
