import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ dataProcessId, dataCollectedId = '', ...params }) => {
  return await dponetAPI.get(
    `data_processes/${dataProcessId}/data_collecteds/${dataCollectedId}`,
    { params },
  )
}

const create = async ({ dataProcessId, ...dataCollecteds }) => {
  return await dponetAPI.post(
    `data_processes/${dataProcessId}/data_collecteds`,
    dataCollecteds,
  )
}

const put = async ({ dataProcessId, dataCollectedId, ...dataCollecteds }) => {
  return await dponetAPI.put(
    `data_processes/${dataProcessId}/data_collecteds/${dataCollectedId}`,
    dataCollecteds,
  )
}

const destroy = async ({ dataCollectedId = '', dataProcessId = '' }) => {
  return await dponetAPI.delete(
    `/data_processes/${dataProcessId}/data_collecteds/${dataCollectedId}`,
  )
}

const list = async ({ dataProcessId = '' }) => {
  return await dponetAPI.get(`data_processes/${dataProcessId}/data_collecteds`)
}

export default { get, destroy, create, put, list }
