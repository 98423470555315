import constants from 'constants/index'

const dataProtectedNotPossibleIdentifyHolder = (id) => {
  switch (id) {
    case constants.incidents.SECURITY_OPTIONS[0].id:
      return constants.incidents.SECURITY_OPTIONS[0].status
    case constants.incidents.SECURITY_OPTIONS[1].id:
      return constants.incidents.SECURITY_OPTIONS[1].status
    case constants.incidents.SECURITY_OPTIONS[2].id:
      return constants.incidents.SECURITY_OPTIONS[2].status
    default:
      return 'N/A'
  }
}

export default dataProtectedNotPossibleIdentifyHolder
