import React, { useState } from 'react'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { Container, Box, Tooltip, Button } from '@material-ui/core'

import { ProfilesForm } from './components'
import { Page, ContentHeader, DriveTour } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'

import useStyles from './styles'

const ProfilesNew = () => {
  const classes = useStyles()

  const [openTour, setOpenTour] = useState(false)

  const handleOpenTour = () => {
    const where = 'Novo Perfil'
    const title = 'Tour'

    const params = {
      action: 'tour-novo-perfil-empresas',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Novo perfil">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Novo perfil">
          <Tooltip title="Iniciar o tour guiado">
            <Box className={classes.contentHeaderBox}>
              <Button
                id={constants.profile.NEW_PROFILE_DRIVER_STEP_0}
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                fullWidth
              >
                Tutorial
              </Button>
            </Box>
          </Tooltip>
        </ContentHeader>
        <Box width="100%" mt={2}>
          <ProfilesForm isEdit={false} />
        </Box>
      </Container>
      <DriveTour
        stepsMatrix={constants.profile.NEW_PROFILES_DRIVER_STEPS_OBJECT}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}
export default ProfilesNew
