import React from 'react'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import {
  Grid,
  Box,
  Typography,
  makeStyles,
  Chip,
  Link,
} from '@material-ui/core'

import usePermissions from 'hooks/usePermissions'
import permission from 'constants/permissions'

import helpers from 'helpers'
import { routes } from 'Routes'
import styles from '../../styles'

const useStyles = makeStyles(styles)

const DataProcessDetails = ({ nonComplianceReport = {} }) => {
  const classes = useStyles()
  const history = useHistory()
  const permissions = usePermissions()

  const handleToDataProcess = (dataProcessId, e) => {
    if (!permissions.permitted(permission.DATA_PROCESSES.LIST)) {
      return e.preventDefault()
    }

    history.push(
      reverse(routes.dataProcess.view, {
        dataProcessId: dataProcessId,
      }),
    )
  }

  return (
    <>
      <Grid item xl={4} md={4} sm={6} xs={12}>
        <Box mt={3}>
          <Typography variant="subtitle2" color="textSecondary">
            Departamento
          </Typography>
          <Typography color="textPrimary" variant="body1">
            {nonComplianceReport.department?.name}
          </Typography>
        </Box>
      </Grid>
      <Grid item xl={4} md={4} sm={6} xs={12}>
        <Box mt={3}>
          <Typography variant="subtitle2" color="textSecondary">
            Risco
          </Typography>
          <Chip
            className={helpers.dataProcess.fragilityColor(
              nonComplianceReport?.dataProcessFragility,
              classes,
            )}
            align="center"
            size="small"
            label={helpers.dataProcess.fragilityLabel(
              nonComplianceReport?.dataProcessFragility,
            )}
          />
        </Box>
      </Grid>
      <Grid item xl={4} md={4} sm={6} xs={12}>
        <Box mt={3}>
          <Typography variant="subtitle2" color="textSecondary">
            Origem
          </Typography>
          <Typography color="textPrimary" variant="body1">
            {helpers.nonComplianceReports.origin(nonComplianceReport.finality)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={3}>
          <Typography variant="subtitle2" color="textSecondary">
            Registro de origem
          </Typography>
          <Link
            color="secondary"
            href={`#${nonComplianceReport.entity.dataProcess.id}`}
            onClick={(e) =>
              handleToDataProcess(nonComplianceReport.entity.dataProcess.id, e)
            }
          >
            <Typography color="textSecondary" variant="body1">
              {nonComplianceReport.entity?.dataProcess?.name}
            </Typography>
          </Link>
        </Box>
      </Grid>
    </>
  )
}

export default DataProcessDetails
