const DATA_PROCESS_ENTITY_TYPE = 'DataProcess'
const DATA_COLLECTED_ENTITY_TYPE = 'DataCollected'
const LIFE_CYCLE_ENTITY_TYPE = 'LifeCycle'
const DATA_TREATMENT_ENTITY_TYPE = 'DataTreatment'
const DATA_SOURCE_ENTITY_TYPE = 'DataProcessDataSource'
const DEPARTMENT_ENTITY_TYPE = 'Department'
const SOURCE_ENTITY_TYPE = 'DataProcessSource'

const CREATE_ACTION = 'create'
const UPDATE_ACTION = 'update'
const DESTROY_ACTION = 'destroy'

const TAB_VIEW_VALUE = {
  GENERAL: 'general',
  DATA_COLLECTEDS: 'dataCollecteds',
  LIFE_CYCLES: 'lifeCycles',
  DATA_TREATMENTS: 'dataTreatments',
  DATA_TREATMENT_AGENTS: 'dataTreatmentAgents',
}

const TAB_VIEW_LABEL = {
  GENERAL: 'Informações gerais',
  DATA_COLLECTEDS: 'Dados tratados',
  LIFE_CYCLES: 'Salvaguarda e ciclo de vida',
  DATA_TREATMENTS: 'Dados compartilhados',
  DATA_TREATMENT_AGENTS: 'Agentes de tratamento',
}

const TABS_SUGGESTIONS = [
  { value: TAB_VIEW_VALUE.GENERAL, label: TAB_VIEW_LABEL.GENERAL },
  {
    value: TAB_VIEW_VALUE.DATA_COLLECTEDS,
    label: TAB_VIEW_LABEL.DATA_COLLECTEDS,
  },
  { value: TAB_VIEW_VALUE.LIFE_CYCLES, label: TAB_VIEW_LABEL.LIFE_CYCLES },
  {
    value: TAB_VIEW_VALUE.DATA_TREATMENTS,
    label: TAB_VIEW_LABEL.DATA_TREATMENTS,
  },
]

export default {
  DATA_PROCESS_ENTITY_TYPE,
  DATA_COLLECTED_ENTITY_TYPE,
  LIFE_CYCLE_ENTITY_TYPE,
  DATA_SOURCE_ENTITY_TYPE,
  DATA_TREATMENT_ENTITY_TYPE,
  TABS_SUGGESTIONS,
  TAB_VIEW_VALUE,
  CREATE_ACTION,
  UPDATE_ACTION,
  DESTROY_ACTION,
  DEPARTMENT_ENTITY_TYPE,
  SOURCE_ENTITY_TYPE,
}
