import * as yup from 'yup'
import helpers from 'helpers'
import constants from 'constants/index'

const allowedTypes = constants.consent.SUPPORTED_DOCUMENT_FILE_INPUT_TYPES

const schema = yup.object({
  document: yup
    .mixed()
    .test('fileSize', 'O documento deve ter no máximo 10 MB', (value) =>
      helpers.validates.file.size('MB', 10, value),
    )
    .test(
      'fileType',
      `Somente arquivos ${helpers.formatters.file.allowedTypes(
        allowedTypes,
      )} são permitidos`,
      (value) =>
        helpers.validates.file.type(
          constants.consent.SUPPORTED_DOCUMENT_FILE_SCHEMA_TYPES,
          value,
        ),
    )
    .required(),
})

export default schema
