const documentSend = (international, document) => {
  let documentSend = {}

  if (!international) {
    documentSend = { cpf: document }
    if (document.length > 14) {
      documentSend = { cnpj: document }
    }
  } else {
    documentSend = { internationalDocument: document }
  }

  return documentSend
}

export default documentSend
