import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  CircularProgress,
  Grid,
  Hidden,
  Paper,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core'

import clsx from 'clsx'
import theme from 'theme'

import supplierBoxImage from 'images/supplier_box_image.jpg'

import styles from './styles'
const useStyles = makeStyles(styles)

const CompanyCompliance = ({ companyCompliance }) => {
  const classes = useStyles()

  const isXs = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  })

  const isMaxCompliance = companyCompliance === 100

  return (
    <Grid item xs={12} sm={6} lg={8}>
      <Hidden smUp>
        <Box my={2}>
          <Typography variant="h4" gutterBottom>
            Conformidade da empresa
          </Typography>
        </Box>
      </Hidden>
      <Paper
        component={Box}
        elevation={2}
        {...(!isXs && { minHeight: '100%' })}
        width="100%"
        display="flex"
      >
        <Hidden mdDown>
          <Box width="100%" display="flex" alignItems="center">
            <img src={supplierBoxImage} alt="Logo" width="85%" />
            <Box
              display="flex"
              flexDirection="column"
              height="50px"
              width="15%"
            >
              <Box height="20px" className={classes.splintUp} />
              <Box height="20px" className={classes.splintDown} />
            </Box>
          </Box>
        </Hidden>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.blueBox}
          p={4}
        >
          <CircularProgress
            variant="static"
            value={companyCompliance}
            size={100}
            thickness={2.5}
            className={classes.circularProgress}
          />
          <Box display="flex" alignItems="baseline">
            <Typography
              variant={isMaxCompliance ? 'h3' : 'h1'}
              className={clsx(
                classes.whiteTypography,
                classes.negativeMarginProgress,
              )}
            >
              {companyCompliance?.toFixed(0) || 0}
            </Typography>
            <Typography
              variant={isMaxCompliance ? 'h6' : 'h4'}
              className={classes.whiteTypography}
            >
              %
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}

CompanyCompliance.propTypes = {
  companyCompliance: PropTypes.number,
}

export default CompanyCompliance
