import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Link } from '@material-ui/core'

import {
  Container,
  Carousel,
  LoadingFeedback,
  Page,
  Permitted,
  DriveTour,
  InfoCard,
} from 'components'
import { CompanyTrails, Activities } from './components'

import qs from 'query-string'

import usePermissions from 'hooks/usePermissions'
import useAuth from 'hooks/useAuth'

import helpers from 'helpers'

import theme from 'theme'
import useStyles from './styles'
import constants from 'constants/index'

import { routes } from 'Routes'

import vejaNovidadesImage from 'images/veja-novidades.png'
import visiteMarketplaceImage from 'images/visite-marketplace.png'
import allPrivacyImage from 'images/banner_allprivacy.png'

const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const [openTour, setOpenTour] = useState(false)
  const [haveTasks, setHaveTasks] = useState(false)

  const { company } = useAuth()

  const classes = useStyles()
  const history = useHistory()
  const { mixpanel } = helpers
  const { isDpo, permitted } = usePermissions()

  const isOutSourcedDpo = isDpo()

  const filtersMatrixSteps = (elementId, permission) => {
    const requiredPermission =
      constants.myLgpd.DRIVER_STEPS_OBJECT_WITH_PERMISSIONS[elementId]

    if (
      !!requiredPermission &&
      elementId === '#' + constants.myLgpd.HOME_DRIVER_STEP_9 &&
      !haveTasks
    )
      return false

    return requiredPermission ? permission(requiredPermission) : true
  }

  const filteredSteps = constants.myLgpd.DRIVER_STEPS_OBJECT.filter((step) => {
    return filtersMatrixSteps(step.element, permitted)
  })

  const handleGoToAllPrivacy = () =>
    window.open(constants.myLgpd.ALL_PRIVACY_URL, '_blank')

  const handleGoToMarketplace = () => history.push(routes.marketplace.all)

  const handleGoToLastUpdates = () => history.push(routes.lastUpdates.all)

  const handleSettingsRedirection = () =>
    history.push({
      pathname: routes.settings,
      search: qs.stringify({
        status: constants.settings.FINANCIAL_OVERDUE_STATUS,
      }),
      state: { tab: 'financial' },
    })

  const handleOpenTour = () => {
    const where = 'Home'
    const title = 'Tour'

    const params = {
      action: 'tour-home',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  const banners = [
    {
      altText: 'Banner de redirecionamento para a AllPrivacy Community',
      title: 'Junte-se à comunidade da Privacidade!',
      srcImg: allPrivacyImage,
      action: () => {
        mixpanel.track('MyLgpd', 'Home Carrousel', {
          action: 'Clique banner junte-se comunidade privacidade!',
        })
        handleGoToAllPrivacy()
      },
      bgColor: theme.palette.common.white,
    },
    {
      altText: 'banner de redirecionamento para últimas atualizações',
      title: 'Conhecer novidades',
      srcImg: vejaNovidadesImage,
      action: () => {
        mixpanel.track('MyLgpd', 'Home Carrousel', {
          action: 'Clique banner veja novidades!',
        })
        handleGoToLastUpdates()
      },
    },
    {
      altText: 'banner de direcionamento para marketplace',
      title: 'Conhecer marketplace',
      srcImg: visiteMarketplaceImage,
      action: () => {
        mixpanel.track('MyLgpd', 'Home Carrousel', {
          action: 'Clique banner visitar marketplace!',
        })
        handleGoToMarketplace()
      },
    },
  ]

  useEffect(() => {
    if (isOutSourcedDpo) {
      history.push(routes.myLgpd.dashboard)
    }

    //eslint-disable-next-line
  }, [isOutSourcedDpo])

  if (isOutSourcedDpo) {
    return <></>
  }

  return (
    <Page className={classes.root} title="DPOnet - Início">
      <Container
        maxWidth={false}
        spacingXl={8}
        spacingLg={8}
        spacingMd={5}
        spacingSm={5}
      >
        <Box my={6} className={classes.bannerContainer}>
          <Carousel banners={banners} />
        </Box>
        {company?.haveOverdueOrders && (
          <InfoCard
            title="Detectamos um problema com o pagamento da sua fatura. Por favor,
        regularize-o para evitar a interrupção do seu acesso."
            endActions={
              <Link
                variant="body1"
                color="initial"
                underline="always"
                className={classes.link}
                onClick={handleSettingsRedirection}
              >
                Pagar agora
              </Link>
            }
          />
        )}
        <CompanyTrails
          setLoading={setLoading}
          handleOpenTour={handleOpenTour}
        />
        <Permitted tag={constants.permissions.TASKS.LIST}>
          <Activities setHaveTasks={setHaveTasks} />
        </Permitted>
      </Container>

      <LoadingFeedback open={loading} />
      <DriveTour
        stepsMatrix={filteredSteps}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default Dashboard
