import React from 'react'
import { Chip } from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import useFirstStep from 'hooks/useFirstStep'

import helpers from 'helpers'

const HelpStep = ({ redirectUrl, classes }) => {
  const { activeStep, activeSubStep, registerTrack } = useFirstStep()

  const handleHelp = () => {
    registerTrack(
      helpers.userSteps.defineMixpanelId(activeStep, activeSubStep, 'ajuda'),
    )

    window.open(redirectUrl(), '_blank')
  }

  return (
    <Chip
      className={classes.helpChip}
      onClick={handleHelp}
      icon={<HelpCircleIcon className={classes.iconChip} />}
      label="Ajuda"
      clickable={false}
    />
  )
}

export default HelpStep
