import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { Box, Button, Chip } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton } from 'components'
import { ConsentDetailsDialog } from './components'

import helpers from 'helpers'

import useStyles from './styles'

const ConsentsTable = ({ consents, isLoading }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [consent, setConsent] = useState()

  const classes = useStyles()

  const handleOpenDialog = (item) => {
    setOpenDialog(true)
    setConsent(item)
  }

  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table
          size="small"
          emptyMessage="Nenhum consentimento encontrado"
          isLoading={isLoading}
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>ID do visitante</TableCell>
              <TableCell align="center">Data do consentimento</TableCell>
              <TableCell align="center">Consentimento</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {consents?.map((consent) => (
              <TableRow key={consent?.id}>
                <TableCell>{consent?.id}</TableCell>
                <TableCell applyNoWrap disableTooltip={false}>
                  {consent?.token}
                </TableCell>
                <TableCell align="center">
                  {helpers.formatters.ptBrFormatDateTime(
                    consent?.datetimeConsent,
                  )}
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={helpers.cookies.consent
                      .readingTypeTranslation(consent?.kind)
                      .toUpperCase()}
                    style={{
                      backgroundColor: helpers.cookies.consent.chipStatusColor(
                        consent?.kind,
                      ),
                      color: helpers.cookies.consent.chipFontStatusColor(
                        consent?.kind,
                      ),
                    }}
                    size="small"
                    className={classes.chip}
                  />
                </TableCell>
                <TableCell align="right">
                  <MenuButton>
                    <Button
                      color="secondary"
                      fullWidth
                      size="small"
                      onClick={() => handleOpenDialog(consent)}
                    >
                      Visualizar
                    </Button>
                  </MenuButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <ConsentDetailsDialog
        open={openDialog}
        setOpen={setOpenDialog}
        consent={consent}
      />
    </Box>
  )
}

ConsentsTable.propTypes = {
  consents: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
}

export default ConsentsTable
