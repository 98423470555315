import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  pending: {
    backgroundColor: colors.cyan[100],
    color: colors.cyan[800],
    fontWeight: 700,
    borderRadius: 4,
    height: '21px',
  },
  onTime: {
    backgroundColor: colors.blue[100],
    color: colors.blue[800],
    fontWeight: 700,
    borderRadius: 4,
    height: '21px',
  },
  substituted: {
    backgroundColor: colors.grey[200],
    color: colors.grey[800],
    fontWeight: 700,
    borderRadius: 4,
    height: '21px',
  },
  finalized: {
    backgroundColor: colors.green[100],
    color: colors.green[800],
    fontWeight: 700,
    borderRadius: 4,
    height: '21px',
  },
  overdue: {
    backgroundColor: colors.red[100],
    color: colors.red[800],
    fontWeight: 700,
    borderRadius: 4,
    height: '21px',
  },
  frozen: {
    backgroundColor: colors.teal[100],
    color: colors.teal[800],
    fontWeight: 700,
    borderRadius: 4,
    height: '21px',
  },
  inactive: {
    backgroundColor: colors.grey[200],
    color: colors.grey[800],
    fontWeight: 700,
    borderRadius: 4,
    height: '21px',
  },
  rowText: {
    fontSize: 13,
    width: 100,
  },
}))

export default styles
