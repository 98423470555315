import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Box } from '@material-ui/core'

import styles from './styles'

const Banner = ({ action, title, srcImg, altImgText, bgColor, idx }) => {
  const useStyles = styles(bgColor)
  const classes = useStyles()

  return (
    <Box onClick={action} title={title} className={classes.itemContainer}>
      <img
        src={srcImg}
        alt={altImgText}
        className={clsx(classes.imageContainer, classes.banner)}
      />
    </Box>
  )
}

Banner.propTypes = {
  action: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  srcImg: PropTypes.string.isRequired,
  altImgText: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  bgColor: PropTypes.string,
}

export default Banner
