import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  congratulation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '80vh',
    margin: 'auto',
    padding: theme.spacing(0, 2),
  },
  title: {
    fontWeight: '900',
    fontSize: 50,
  },
  description: {
    marginTop: theme.spacing(8),
    fontWeight: '400',
    fontSize: 30,
  },
  titleOver: {
    fontSize: 35,
    color: theme.palette.black,
    fontWeight: 500,
    margin: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  subtitle: {
    fontSize: 30,
    color: 'rgb(35,49,84,0.7)',
    fontWeight: 500,
    margin: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  titileContext: {
    fontSize: 25,
    color: 'rgb(84,110,122,0.7)',
    fontWeight: 500,
    margin: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  context: {
    fontSize: 20,
    color: theme.palette.text.mainMenu,
    margin: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  button: {
    backgroundColor: theme.palette.greenPantone,
    color: theme.palette.white,
    fontWeight: 900,
    fontSize: 16,
    marginBottom: theme.spacing(2),
    textShadow: 'rgb(0,0,0,0.4) 2px 3px 2px;',
    '&:hover': {
      backgroundColor: theme.palette.greenPantone,
      opacity: 0.8,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  exclusion: {
    color: 'rgb(84,110,122,0.7) !important',
    fontWeight: 400,
  },
  boxButton: {
    padding: theme.spacing(0, 15),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
    },
  },
}))
