import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  requesterBoxContainer: {
    border: `1px solid ${theme.palette.divider}`,
    marginBlock: theme.spacing(2),
  },
  requesterHeader: {
    display: 'flex',
    backgroundColor: '#EEE',
    paddingBlock: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  contentRequesterLine: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    listStylePosition: 'inside',
    padding: theme.spacing(2),
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('xs')]: {
      '& p > img': {
        width: '100%',
        height: 'auto',
      },
    },
    '& ol, & ul': {
      paddingLeft: 16,
      marginTop: 8,
      marginBottom: 8,
    },
    '& li': {
      marginBottom: 4,
    },
  },
  responsibleHeader: {
    width: '100%',
    display: 'flex',
    backgroundColor: theme.palette.subscription.primary.light,
    paddingBlock: theme.spacing(1),
    alignItems: 'center',
  },
  userIconBox: {
    height: 39,
    width: 39,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    paddingInline: theme.spacing(1),
    marginInline: theme.spacing(1),
    color: theme.palette.white,
  },
}))

export default styles
