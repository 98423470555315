import documentType from './documentType'
import formatPortalUrl from './formatPortalUrl'
import isTemplate from './isTemplate'
import isSupplier from './isSupplier'
import formatData from './formatData'

export default {
  documentType,
  formatPortalUrl,
  isTemplate,
  formatData,
  isSupplier,
}
