import React, { useState, useEffect } from 'react'
import { remove } from 'lodash'
import PropTypes from 'prop-types'

import { Controller, useForm } from 'react-hook-form'
import { Grid, Box, CardContent } from '@material-ui/core'

import { PasswordValidator, PasswordInputField } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import * as service from 'service'
import helpers from 'helpers'
import schema from './schema'
import useAuth from 'hooks/useAuth'

const PasswordForm = ({
  formId,
  formIds,
  setFormIds,
  setLoading,
  isResponsible,
  hasPhone,
  recaptcha,
  resetRecaptcha,
}) => {
  const [passwordInput, setPasswordInput] = useState('')
  const [justSubmitted, setJustSubmitted] = useState(false)
  const [showPasswordValidator, setShowPasswordValidator] = useState(false)

  const { temporaryPassword, user, loadUser } = useAuth()

  const snackbar = useSnackbar()
  const { handleSubmit, errors, control, reset, formState } = useForm({
    validationSchema: schema,
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  })

  const loginUser = async (data) => {
    try {
      await service.dponet.auth.login({
        email: user.email,
        password: data.newPassword,
        recaptcha,
      })
      resetRecaptcha()
      await loadUser()
      snackbar.open({
        message: 'Sua senha foi atualizada com sucesso!',
        variant: 'success',
      })
    } catch {
      service.dponet.auth.logout()
    }
  }

  const onSubmit = async (data) => {
    if (isResponsible && !hasPhone) return
    setLoading(true)
    try {
      await service.dponet.users.updatePassword(data)
      if (temporaryPassword) {
        return loginUser(data)
      } else {
        service.dponet.auth.logout()
      }
      setLoading(false)

      snackbar.open({
        message:
          'Sua senha foi atualizada com sucesso faça o login na plataforma utilizando a nova senha!',
        variant: 'success',
      })
      reset()
    } catch (err) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(err?.response?.data?.error, false) ||
          'Ocorreu um erro ao atualizar sua senha',
        variant: 'error',
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    if (formState.dirty) {
      setFormIds([...formIds, formId])
    } else {
      remove(formIds, (item) => item === formId)
      setFormIds([...formIds])
    }

    //eslint-disable-next-line
  }, [formState.dirty])

  const handleSubmitForm = (event) => {
    setJustSubmitted(true)
    handleSubmit(onSubmit)(event)
  }

  const handleChangePasswordInput = ([e]) => {
    setPasswordInput(e.target.value)
    return e.target.value
  }

  const handleShowPasswordValidator = () => {
    setShowPasswordValidator(true)
  }

  return (
    <CardContent>
      {showPasswordValidator && (
        <Box px={2} mb={3}>
          <PasswordValidator
            passwordInput={passwordInput}
            justSubmitted={justSubmitted}
          />
        </Box>
      )}
      <form
        ref={(form) => [(formId = form)]}
        onSubmit={handleSubmitForm}
        id={formId}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              as={
                <PasswordInputField
                  color="primary"
                  variant="outlined"
                  error={!!errors.currentPassword}
                  label="Senha atual"
                  fullWidth
                />
              }
              control={control}
              name="currentPassword"
              mode="onChange"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <PasswordInputField
                  color="primary"
                  variant="outlined"
                  error={!!errors.newPassword}
                  helperText={errors?.newPassword?.message}
                  label="Nova senha"
                  fullWidth
                />
              }
              control={control}
              name="newPassword"
              mode="onChange"
              onChange={handleChangePasswordInput}
              onClick={handleShowPasswordValidator}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <PasswordInputField
                  color="primary"
                  variant="outlined"
                  error={!!errors.confirmPassword}
                  helperText={errors?.confirmPassword?.message}
                  label="Confirmar nova senha"
                  fullWidth
                />
              }
              name="confirmPassword"
              control={control}
              mode="onChange"
            />
          </Grid>
        </Grid>
      </form>
    </CardContent>
  )
}

PasswordForm.propTypes = {
  user: PropTypes.object,
  setLoading: PropTypes.func,
  formId: PropTypes.string,
  formIds: PropTypes.array,
  setFormIds: PropTypes.func,
  hasPhone: PropTypes.bool,
  recaptcha: PropTypes.string,
  setRecaptcha: PropTypes.func,
}

export default PasswordForm
