import constants from 'constants/index'
const cardFlag = (cardNumber) => {
  const { CARD_FLAG_PATTERN_STANDARD_VALIDATION } = constants.cardFlag

  /**
   * cardFlag
   * Return id of card flag by number
   * 1 - MASTERCARD
   * 2 - VISA
   * 3 - AMEX
   * 4 - ELO
   * 5 - HIPERCARD
   *
   *
   * @param {string} cardNumber
   */
  let cardNumberDiff = cardNumber.replace(/[^0-9]+/g, '')

  for (var flagId in CARD_FLAG_PATTERN_STANDARD_VALIDATION) {
    if (CARD_FLAG_PATTERN_STANDARD_VALIDATION[flagId].test(cardNumberDiff)) {
      return parseInt(flagId)
    }
  }

  return null
}

export default cardFlag
