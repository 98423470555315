const styles = (theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  fullCardWidth: {
    width: '100%',
  },
  contentHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    gridGap: 8,
  },
})

export default styles
