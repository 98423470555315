import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'

import styles from './styles'

const BaseStatus = ({ children, description, status }) => {
  const classes = makeStyles(styles)()

  const styleByStus = () => {
    switch (status) {
      case 'approved':
        return classes.approved
      case 'disapproved':
        return classes.disapproved
      case 'processing':
        return classes.processing
      default:
        break
    }
  }
  return (
    <Box display="flex" flexDirection="column">
      <Box
        py={4.5}
        className={styleByStus()}
        display="flex"
        justifyContent="center"
      >
        <Typography variant="h4">{description}</Typography>
      </Box>
      <Box p={2} className={classes.childrenContainer}>
        {children}
      </Box>
    </Box>
  )
}

export default BaseStatus
