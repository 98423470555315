import api from 'service/api'

const dponetAPI = api.create('dponet')

const show = async ({ outsourcedId = '' }) => {
  return await dponetAPI.get(`/outsourced_dpos/${outsourcedId}`)
}

const create = async ({ data }) => {
  return await dponetAPI.post('/outsourced_dpos', {
    outsourcedDpo: data,
  })
}

const update = async ({ outsourcedId = '', data }) => {
  return await dponetAPI.put(`/outsourced_dpos/${outsourcedId}`, {
    outsourcedDpo: data,
  })
}

const destroy = async ({ outsourcedId = '' }) => {
  return await dponetAPI.delete(`/outsourced_dpos/${outsourcedId}`)
}

export default { show, create, update, destroy }
