import React from 'react'
import PropTypes from 'prop-types'

import { BarChart, DashboardChartPaper } from 'components'

import { routes } from 'Routes'

const RiskByDepartmentGraphic = ({ processStats, ...rest }) => {
  const departments = processStats?.departments
    ?.sort((a, b) => {
      const sumA = a.risks.low + a.risks.medium + a.risks.high + a.risks.severe
      const sumB = b.risks.low + b.risks.medium + b.risks.high + b.risks.severe
      return sumB - sumA
    })
    .slice(0, 8)

  const getDataByRisk = (department, risk) => department?.risks[risk]
  const data = ['low', 'medium', 'high', 'severe'].map((risk) => ({
    [risk]: departments?.map((department) => getDataByRisk(department, [risk])),
  }))
  const names = departments?.map((department) => department.name)

  return (
    <DashboardChartPaper
      title="Ameaças dos processos por departamentos"
      subtitle="Ameaças da organização por departamento"
      redirect={routes.dataProcess.all}
      {...rest}
    >
      <BarChart
        names={names}
        series={[
          { name: 'Baixo', data: data[0].low || [] },
          { name: 'Médio', data: data[1].medium || [] },
          { name: 'Alto', data: data[2].high || [] },
          { name: 'Severo', data: data[3].severe || [] },
        ]}
        heightValue={250}
        colors={['#16a63c', '#fecc31', '#ff7a00', '#e83a49']}
        showLabels
      />
    </DashboardChartPaper>
  )
}

RiskByDepartmentGraphic.propTypes = {
  processStats: PropTypes.object.isRequired,
}

export default RiskByDepartmentGraphic
