import constants from 'constants/index'

const getIndexByTag = (tag) => {
  const {
    STEP_DPO_INDEX,
    STEP_LGPD_TAG,
    STEP_LGPD_INDEX,
    STEP_STAMP_TAG,
    STEP_STAMP_INDEX,
    STEP_CERTIFICATION_TAG,
    STEP_CERTIFICATION_INDEX,
    STEP_COOKIE_TAG,
    STEP_COOKIE_INDEX,
    STEP_CONCLUSION_TAG,
    STEP_CONCLUSION_INDEX,
  } = constants.userSteps

  switch (tag) {
    case STEP_LGPD_TAG:
      return STEP_LGPD_INDEX[0]
    case STEP_STAMP_TAG:
      return STEP_STAMP_INDEX[0]
    case STEP_CERTIFICATION_TAG:
      return STEP_CERTIFICATION_INDEX[0]
    case STEP_COOKIE_TAG:
      return STEP_COOKIE_INDEX[0]
    case STEP_CONCLUSION_TAG:
      return STEP_CONCLUSION_INDEX[0]
    default:
      return STEP_DPO_INDEX[0]
  }
}

export default getIndexByTag
