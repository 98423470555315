import React from 'react'
import PropTypes from 'prop-types'

const PolygonContained = ({
  width = '149',
  height = '166',
  alt = 'Polígono preenchido',
  className,
  fill = '#80B92B',
  stroke = '#80B92B',
  strokeWidth = '3',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 149 166"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
      className={className}
    >
      <path
        d="M68.25 3.34049C72.1175 1.10758 76.8825 1.10758 80.75 3.34049L140.362 37.7576C144.23 39.9905 146.612 44.1171 146.612 48.5829V117.417C146.612 121.883 144.23 126.01 140.362 128.242L80.75 162.66C76.8825 164.892 72.1175 164.892 68.25 162.66L8.63784 128.242C4.77032 126.01 2.38784 121.883 2.38784 117.417V48.5829C2.38784 44.1171 4.77033 39.9905 8.63784 37.7576L68.25 3.34049Z"
        fill={fill}
        stroke={stroke}
        stroke-width={strokeWidth}
      />
    </svg>
  )
}

PolygonContained.propTypes = {
  width: PropTypes.string | PropTypes.number,
  height: PropTypes.string | PropTypes.number,
  alt: PropTypes.string,
  className: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
}

export default PolygonContained
