import constants from 'constants/index'

const {
  STEP_LOGO,
  STEP_SITE,
  STEP_COOKIE,
  STEP_CONFIG,
  STEP_DOCS,
  STEP_CONCLUSION,
  COMPANY_LOGO_TAG,
  COMPANY_SITE_TAG,
  COOKIE_BANNER_TAG,
  SITE_CONFIGURATION_TAG,
  COMPANY_DOCUMENTS_TAG,
  CONCLUSION_TAG,
} = constants.userTrailSteps

const tagByIndex = (index) => {
  switch (index) {
    case STEP_LOGO:
      return COMPANY_LOGO_TAG
    case STEP_SITE:
      return COMPANY_SITE_TAG
    case STEP_COOKIE:
      return COOKIE_BANNER_TAG
    case STEP_CONFIG:
      return SITE_CONFIGURATION_TAG
    case STEP_DOCS:
      return COMPANY_DOCUMENTS_TAG
    case STEP_CONCLUSION:
      return CONCLUSION_TAG
    default:
      return COMPANY_LOGO_TAG
  }
}

export default tagByIndex
