import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { isArray, isEmpty, isNil, uniq } from 'lodash'
import {
  Box,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'

import { ToggleButton } from 'components'
import { DialogSelectDepartment } from 'views/DataProcesses/components/SuggestionChanges'

import { useTranslation } from 'react-i18next'

import useSuggestedChange from 'hooks/useSuggestedChange'

import helpers from 'helpers'
import humps from 'humps'
import constants from 'constants/index'
import pluralize from 'pluralize'

const ActionColumn = ({
  registerKey,
  entityType,
  suggestedChange,
  changeEntityType,
}) => {
  const { t } = useTranslation()

  if (isArray(suggestedChange)) {
    return (
      <>
        <Grid item xs={12}>
          <Box pt={1}>
            <Divider />
            <Box pt={2}>
              <Typography variant="subtitle1">
                {t(
                  `${changeEntityType}.${humps
                    .decamelize(registerKey)
                    .replace('_id', '')}`,
                )}
              </Typography>
            </Box>
          </Box>
        </Grid>
        {suggestedChange.map((change, indexChange) =>
          Object.keys(change).map((registerKey, index) => {
            const suggestedChange = change[registerKey]

            return (
              <>
                {indexChange !== 0 && index === 0 && (
                  <Box width="100%" my={1}>
                    <Divider />
                  </Box>
                )}
                <ActionColumn
                  key={registerKey}
                  registerKey={registerKey}
                  entityType={
                    isArray(suggestedChange)
                      ? humps.pascalize(pluralize.singular(registerKey))
                      : entityType
                  }
                  changeEntityType={entityType}
                  suggestedChange={suggestedChange}
                />
              </>
            )
          }),
        )}
      </>
    )
  }
  const unbreakableLines = [
    'sourceDescription',
    'retentionFinality',
    'description',
    'controllMechanism',
    'discardModeId',
  ]

  return (
    <Grid
      item
      xs={12}
      {...(!unbreakableLines.includes(registerKey) && { md: 6 })}
    >
      <Box py={0.5}>
        <Typography variant="h6">
          {t(
            `${entityType}.${humps.decamelize(registerKey).replace('_id', '')}`,
          )}
        </Typography>
        <Typography>
          {helpers.suggestedChanges.formattedValue(
            suggestedChange.oldValue || suggestedChange.newValue,
            registerKey,
            t,
          )}
        </Typography>
      </Box>
    </Grid>
  )
}

const DestroyOrCreateActionInput = ({
  changeId,
  suggestion,
  isCreate,
  entityType,
  defaultExpanded,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [params, setParams] = useState({})
  const [notFoundDepartments, setNotFoundDepartments] = useState([])
  const [foundDepartments, setFoundDepartments] = useState([])

  const refuse = 'refuse'
  const accept = 'accept'

  const {
    handleSelect,
    incrementSuggestionsQuantity,
    data,
    departments,
    dataProcess,
  } = useSuggestedChange()

  const {
    DEPARTMENT_ENTITY_TYPE,
    SOURCE_ENTITY_TYPE,
    DATA_SOURCE_ENTITY_TYPE,
  } = constants.suggestionChanges

  const departmentNames = useMemo(
    () => departments.map((department) => department?.name),
    [departments],
  )

  const departmentsToSelect = (departmentList) =>
    departmentList?.filter(
      (department) => !foundDepartments?.includes(department?.id || department),
    )

  useEffect(() => {
    incrementSuggestionsQuantity(changeId)
    //eslint-disable-next-line
    }, [])

  useEffect(() => {
    const verifyIfSuggestionsIsSource = () => {
      const entity = suggestion?.changes?.entityType
      const entityData = suggestion?.changes?.entityId
      const mainEntity = entity?.newValue ?? entity?.oldValue
      const mainEntityData = entityData?.newValue ?? entityData?.oldValue

      if (
        mainEntity === DEPARTMENT_ENTITY_TYPE &&
        entityType === SOURCE_ENTITY_TYPE
      ) {
        if (!departmentNames.includes(mainEntityData?.name)) {
          if (!notFoundDepartments.includes(mainEntityData)) {
            notFoundDepartments.push(mainEntityData)
            return setNotFoundDepartments(notFoundDepartments)
          }
        } else {
          return setFoundDepartments([
            departments.find(
              (department) => department.name === mainEntityData.name,
            )?.id,
          ])
        }
      }

      if (entityType === DATA_SOURCE_ENTITY_TYPE) {
        const sources = suggestion.changes.dataProcessSources

        if (isEmpty(sources)) return

        const entityValue =
          sources[0].entityType.newValue ?? sources[0].entityType.oldValue

        if (entityValue !== DEPARTMENT_ENTITY_TYPE) return

        sources.forEach((source) => {
          const entity =
            source?.entityId?.newValue ?? source?.entityId?.oldValue
          if (!departmentNames.includes(entity?.name)) {
            if (!notFoundDepartments.includes(entity)) {
              notFoundDepartments.push(entity)
              setNotFoundDepartments(notFoundDepartments)
            }
          } else {
            foundDepartments.push(
              departments.find((department) => department.name === entity.name)
                ?.id,
            )
            setFoundDepartments(foundDepartments)
          }
        })
        return setParams({ departments: [] })
      }
    }

    if (!isEmpty(departmentNames) && !params.departments) {
      verifyIfSuggestionsIsSource()
    }

    //eslint-disable-next-line
  }, [departmentNames])

  const [expanded, setExpanded] = useState(defaultExpanded)
  const [value, setValue] = useState(
    data.find((item) => changeId === item.id)?.answer || '',
  )

  const changeValue = (val, secondaryParams) => {
    const finalParams = secondaryParams ? secondaryParams : params

    setValue(val)
    handleSelect({ value: val, changeId, ...finalParams })
  }

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const handleDepartment = () => {
    if (isNil(params.departments)) {
      return
    }

    if (isArray(params?.departments)) {
      if (
        departmentsToSelect(params?.departments)?.length !==
        notFoundDepartments.length
      ) {
        return
      }
      setParams({
        departments: uniq([...params.departments, ...foundDepartments]),
      })
    }

    changeValue(accept)
    closeDialog()
  }

  const handleChange = (e) => {
    const val = e.target.value
    if (val === accept) {
      if (!isEmpty(notFoundDepartments)) {
        return openDialog()
      }

      if (!isEmpty(foundDepartments)) {
        return changeValue(val, {
          departments: foundDepartments,
        })
      }
    }

    changeValue(val)
  }

  const defineDefaultValue = () => {
    if (isArray(params.departments)) {
      if (notFoundDepartments.length > 1)
        return departmentsToSelect(departments).filter((department) =>
          params.departments.includes(department?.id),
        )

      return (
        departmentsToSelect(departments).find(
          (department) => department.id === params.departments[0],
        ) || null
      )
    }

    return (
      departmentsToSelect(departments).find(
        (department) => department?.id === params?.departments,
      ) || null
    )
  }

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        mx={2}
        my={1}
      >
        <Typography>{t(`registerTypes.${entityType}`)}</Typography>
        <Box mr={4} display="flex" justifyContent="flex-end">
          <RadioGroup
            name={changeId.toString()}
            row
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value={accept}
              control={<Radio size="medium" />}
              label={isCreate ? 'Adicionar' : 'Excluir'}
            />
            <FormControlLabel
              value={refuse}
              control={<Radio size="medium" />}
              label={isCreate ? 'Não adicionar' : 'Não excluir'}
            />
          </RadioGroup>
          <ToggleButton
            size={18}
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          />
        </Box>
      </Box>
      <Divider />
      <Collapse in={expanded}>
        <Box p={2}>
          <Grid container>
            {Object.keys(suggestion.changes).map((registerKey) => {
              const suggestedChange = suggestion.changes[registerKey]

              return (
                <ActionColumn
                  key={registerKey}
                  registerKey={registerKey}
                  entityType={
                    isArray(suggestedChange)
                      ? humps.pascalize(pluralize.singular(registerKey))
                      : entityType
                  }
                  changeEntityType={entityType}
                  suggestedChange={suggestedChange}
                />
              )
            })}
          </Grid>
        </Box>
        <Divider />
      </Collapse>
      <DialogSelectDepartment
        departments={departmentsToSelect(departments)}
        defaultValue={defineDefaultValue()}
        open={open}
        closeDialog={closeDialog}
        handleConfirm={handleDepartment}
        setParams={setParams}
        error={
          isArray(params?.departments)
            ? departmentsToSelect(params?.departments)?.length !==
              notFoundDepartments.length
            : isNil(params?.departments)
        }
        notFoundDepartments={notFoundDepartments}
        dataProcessName={dataProcess.name}
        params={params}
      />
    </Box>
  )
}

ActionColumn.propTypes = {
  registerKey: PropTypes.string,
  entityType: PropTypes.string,
  suggestedChange: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  changeEntityType: PropTypes.string,
}

DestroyOrCreateActionInput.propTypes = {
  changeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suggestion: PropTypes.object,
  isCreate: PropTypes.bool,
  entityType: PropTypes.string,
  defaultExpanded: PropTypes.bool,
}

DestroyOrCreateActionInput.defaultProps = {
  defaultExpanded: false,
}

export default DestroyOrCreateActionInput
