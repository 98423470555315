import React from 'react'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Box, Grow, IconButton } from '@material-ui/core'

import { SVG } from 'components'
import { PrimaryLevelBanner } from './components'

import helpers from 'helpers'

import styles from './styles'
import constants from 'constants/index'

const BannerPreview = ({ position, iconProps, openBanner, setOpenBanner }) => {
  const { watch } = useFormContext()

  const secondaryColor = helpers.palette.validateColor(watch('secondaryColor'))

  const useStyles = styles({ secondaryColor })
  const classes = useStyles()

  const isCenter = position === constants.cookies.banner.BANNER_CENTER_POSITION
  const isFixed = position === constants.cookies.banner.BANNER_FIXED_POSITION
  const isRight = position === constants.cookies.banner.BANNER_RIGHT_POSITION

  const handleOpenBanner = () => setOpenBanner((prevState) => !prevState)

  const bannerSize = () => {
    if (isCenter) return 'medium'
    if (isFixed) return 'full'
    if (position) return 'default'
    return undefined
  }

  return (
    <Box height="100%" key={position}>
      {openBanner && (
        <Grow in={openBanner}>
          <Box
            height="100%"
            display="flex"
            alignItems="end"
            justifyContent={isRight ? 'end' : isCenter ? 'center' : 'start'}
          >
            <PrimaryLevelBanner
              size={bannerSize()}
              handleOpenBanner={handleOpenBanner}
            />
          </Box>
        </Grow>
      )}
      {!openBanner && (
        <Grow in={!openBanner}>
          <Box
            height="100%"
            display="flex"
            alignItems="end"
            justifyContent={isRight ? 'end' : 'start'}
            {...iconProps}
          >
            <IconButton
              onClick={handleOpenBanner}
              aria-label="Abrir o gerenciador de cookies"
            >
              <SVG.CookieIcon className={classes.cookieIcon} />
            </IconButton>
          </Box>
        </Grow>
      )}
    </Box>
  )
}

BannerPreview.propTypes = {
  size: PropTypes.oneOf(['center', 'fixed', 'left', 'right']),
  iconProps: PropTypes.string,
  openBanner: PropTypes.bool.isRequired,
  setOpenBanner: PropTypes.func.isRequired,
}

export default BannerPreview
