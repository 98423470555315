import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'

import { TicketMessage } from './components'

const TicketMessages = ({ ticketMessages, isLoadingMessage }) => {
  const [showAllMessages, setShowAllMessages] = useState(false)

  const ticketMessagesCount =
    ticketMessages?.length > 0 ? ticketMessages?.length - 1 : 0 || 0

  const handleClick = () => {
    setShowAllMessages((showAllMessages) => !showAllMessages)
  }

  const ticketMessagesSliced = ticketMessages?.slice(1, -1) || []

  return (
    <Box>
      {isLoadingMessage ? (
        <Skeleton variant="rect" height={200} />
      ) : (
        <TicketMessage message={ticketMessages?.[ticketMessages?.length - 1]} />
      )}

      <Box>
        <Button
          variant="text"
          startIcon={showAllMessages ? <ExpandLess /> : <ExpandMore />}
          onClick={handleClick}
          color="primary"
          type="button"
        >
          Todas as interações ({ticketMessagesCount})
        </Button>
      </Box>

      {ticketMessages?.length > 1 && (
        <TicketMessage message={ticketMessages[0]} />
      )}

      {showAllMessages &&
        ticketMessagesSliced.map((message) => (
          <TicketMessage key={message.id} message={message} />
        ))}
    </Box>
  )
}

export default TicketMessages
