import { useContext } from 'react'

import dataLifeCycleContext from 'contexts/DataLifeCycleContext'

const useDataLifeCycle = () => {
  const context = useContext(dataLifeCycleContext)

  if (context === undefined) {
    throw new Error(
      'useDataLifeCycle must be used within a dataLifeCycleContext.Provider',
    )
  }

  return {
    dataLifeCycle: context.dataLifeCycle,
    loadDataLifeCycle: context.loadDataLifeCycle,
    setDataLifeCycle: context.setDataLifeCycle,
    isLoading: context.isLoading,
    dataProcessId: context.dataProcessId,
  }
}

export default useDataLifeCycle
