import { map } from 'lodash'

const manageOptions = (existant = [], newState = []) => {
  const toCreate = newState.filter(
    (entity) =>
      !existant.find(
        (existantEntity) =>
          existantEntity.entityId === entity.id &&
          existantEntity.entityType === entity.entityType,
      ),
  )

  const toDestroy = existant.filter(
    (existantEntity) =>
      !newState.find(
        (entity) =>
          entity.id === existantEntity.entityId &&
          entity.entityType === existantEntity.entityType,
      ),
  )

  return {
    toCreate: map(toCreate, ({ id, entityType }) => ({ id, entityType })),
    toDestroy: map(toDestroy, ({ entityId, entityType }) => ({
      id: entityId,
      entityType,
    })),
  }
}

export default manageOptions
