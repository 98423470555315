import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'

import { TrailsCard } from '..'

import helpers from 'helpers'
import * as service from 'service'

const TrainningJourneyTrail = ({ step, disabled, user, journey }) => {
  const history = useHistory()

  const [loading, setLoading] = useState()
  const [conceptTrainningJourney, setConceptTrainningJourney] = useState()
  const [userConceptTrainningAnswers, setUserConceptTrainningAnswers] =
    useState()
  const certification = user?.userCompanyCertifications?.find(
    (certification) =>
      conceptTrainningJourney?.certificationKind === certification.certType,
  )

  useEffect(
    () => {
      const loadTrainningJourney = async () => {
        const response = await service.dponet.trainningJourneys.show({
          trainningJourneyId: 1,
          minimal: true,
        })

        setConceptTrainningJourney(response?.data?.trainningJourney)
      }

      const loadUserTrainningAnswers = async () => {
        const response = await service.dponet.trainningJourneys.showByUser({
          trainningJourneyId: 1,
        })

        setUserConceptTrainningAnswers(response?.data?.userTrainningSteps)
      }

      const loadData = async () => {
        setLoading(true)
        await loadTrainningJourney()
        await loadUserTrainningAnswers()
        setLoading(false)
      }

      loadData()
    },
    //eslint-disable-next-line
    [],
  )

  const trails = helpers.trainningJourneys.mountTrails(
    history,
    conceptTrainningJourney,
    userConceptTrainningAnswers,
    certification,
  )

  const actualStep = trails.map((a) => a.concluded).indexOf(false)

  const progress = helpers.companyTrails.defineProgress(trails)
  const lgpdConceptsTrainningJourney = {
    title: `${step} - ${conceptTrainningJourney?.name}`,
    progress,
    status: helpers.companyTrails.defineCardStatus(progress),
    trails,
    url: `/trainning/${conceptTrainningJourney?.id}?step=${actualStep + 1}`,
  }

  return (
    <TrailsCard
      loading={loading}
      {...lgpdConceptsTrainningJourney}
      disabled={disabled && progress < 100}
      journey={journey}
    />
  )
}

TrainningJourneyTrail.propTypes = {
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  user: PropTypes.object,
}

TrainningJourneyTrail.defaultProps = {
  step: 1,
}

export default TrainningJourneyTrail
