import React from 'react'

import LinearProgressTable from 'components/LinearProgressTable/LinearProgressTable'
import helpers from 'helpers'

const SecurityMeasuresByCategory = ({ questionControlStats, ...rest }) => {
  const questionControlsByCategory = questionControlStats?.byCategory

  const rows = helpers.myLgpd.mountQuestionControlStatsRows(
    questionControlsByCategory,
  )

  return (
    <LinearProgressTable
      rows={rows}
      title="Medida de segurança por categoria"
      tableType="questionnaire"
      {...rest}
    />
  )
}

export default SecurityMeasuresByCategory
