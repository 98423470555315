import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundColor: theme.colors.firstStep.secondary,
    border: 'none',
    borderRadius: 20,
    textAlign: 'center',
    width: '830px',
  },
  title: {
    fontFamily: 'Raleway',
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(33),
    fontWeight: 'bold',
  },
  description: {
    fontFamily: 'Raleway',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.subscription.warning.success,
    fontSize: theme.typography.pxToRem(25),
    fontWeight: 'bold',
    lineHeight: '90px',
  },
  button: {
    width: '325px',
    borderRadius: '29px',
    fontSize: theme.typography.pxToRem(18),
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    boxShadow: `0px 0px 12px ${theme.colors.firstStep.button.primary}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.colors.firstStep.button.primary,
    },
    backgroundColor: theme.colors.firstStep.button.primary,
    borderColor: theme.colors.firstStep.button.primary,
    color: theme.palette.common.white,
    textTransform: 'none',
    justifyContent: 'center',
    marginTop: '30px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  outlined: {
    boxShadow: 'none',
    border: '2px solid',
    borderColor: theme.palette.common.white,
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginTop: '10px',
  },
}))

export default styles
