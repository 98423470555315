import { routes } from 'Routes'

const NEW_STATUS_ID = 1
const PENDING_STATUS_ID = 2
const COMPLETED_STATUS_ID = 3
const CANCELED_STATUS_ID = 4
const WAITING_STATUS_ID = 5
const AWAITING_CONFIRMATION_POSSIBILITY_STATUS_ID = 6
const AWAITING_CONFIRMATION_IDENTITY_STATUS_ID = 7
const AWAITING_COMPANY_REPLY_STATUS_ID = 8
const AWAITING_REPLY_VALIDATION_STATUS_ID = 9
const REPLIED_STATUS_ID = 10
const REOPEN_STATUS_ID = 11

const PENDINGS_STATUSES = [
  PENDING_STATUS_ID,
  AWAITING_CONFIRMATION_IDENTITY_STATUS_ID,
  AWAITING_COMPANY_REPLY_STATUS_ID,
  AWAITING_REPLY_VALIDATION_STATUS_ID,
  REPLIED_STATUS_ID,
]

const ALL_STATUSES_TITULAR_SOLICITATION = [
  PENDING_STATUS_ID,
  AWAITING_CONFIRMATION_IDENTITY_STATUS_ID,
  AWAITING_COMPANY_REPLY_STATUS_ID,
  AWAITING_REPLY_VALIDATION_STATUS_ID,
  REPLIED_STATUS_ID,
  COMPLETED_STATUS_ID,
]

const SOLICITATION_TICKETS_TABS = [
  { value: 'todas', label: 'TODAS' },
  { value: PENDING_STATUS_ID, label: 'PENDENTES' },
  { value: COMPLETED_STATUS_ID, label: 'FINALIZADAS' },
]

const FORMATTED_STATUS = {
  [COMPLETED_STATUS_ID]: 'FINALIZADO',
  [CANCELED_STATUS_ID]: 'CANCELADO',
}

const JURIDIC_TICKET_TYPE = 'juridic'
const SECURITY_TICKET_TYPE = 'security'
const TITULAR_TICKET_TYPE = 'titular'

const TICKET_TYPE = { JURIDIC_TICKET_TYPE, SECURITY_TICKET_TYPE }

const TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_1 =
  'titular-solicitations-main-driver-step-1'
const TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_2 =
  'titular-solicitations-main-driver-step-2'
const TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_3 =
  'titular-solicitations-main-driver-step-3'

const TITULAR_SOLICITATIONS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'O recurso de solicitação de titulares de dados permite ao representante da empresa confirmar a existência dos dados pessoais do solicitante e tomar medidas adequadas, como fornecer informações, corrigir dados ou responder a pedidos específicos. A colaboração com o DPO é essencial para garantir respostas satisfatórias e o cumprimento das regulamentações de proteção de dados, assegurando a segurança das informações dentro da plataforma.',
    },
  },
  {
    element: '#' + TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_2,
    popover: {
      description:
        'Confirme a existência dos dados do titular da solicitação em sua organização.',
    },
  },
  {
    element: '#' + TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_3,
    popover: {
      description:
        'Responda à solicitação do titular de dados. Em caso de dúvidas, recomendamos que solicite ajuda ao DPO da sua organização.',
    },
  },
]

const CONTACT_CARDS_DATA = (history, setOpen) => [
  {
    title: 'LGPD',
    description:
      'Você será redirecionado para a tela “Central da Privacidade” na guia “LGPD”, caso você não encontre respostas para as suas dúvidas, clique no botão “NÃO ENCONTREI O QUE EU DESEJAVA” e então você será redirecionado para a tela de contato.',
    actionButton: () => {
      setOpen(false)
      history.push(routes.technicalFaqs.lgpdMain)
    },
  },
  {
    title: 'Segurança da Informação',
    description:
      'Você será redirecionado para a tela “Central da Privacidade” na guia “Segurança da Informação”, caso você não encontre respostas para as suas dúvidas, clique no botão “NÃO ENCONTREI O QUE EU DESEJAVA” e então você será redirecionado para a tela de contato.',
    actionButton: () => {
      setOpen(false)
      history.push(routes.technicalFaqs.informationSecurityMain)
    },
  },
  {
    title: 'Suporte técnico',
    description:
      'Dúvidas relacionadas ao financeiro, suporte ou outros assuntos. Você será redirecionado para a tela “Central de ajuda”, caso você não encontre respostas para as suas dúvidas, clique no botão “NÃO ENCONTREI O QUE EU DESEJAVA” e então você será redirecionado para a tela de contato.',
    actionButton: () => {
      setOpen(false)
      history.push(routes.faqsDocumentations.all)
    },
  },
]

const MY_TICKETS_CARDS_DATA = (history, setOpen, goToDeskTicketsFunction) => [
  {
    title: 'LGPD ou Segurança da Informação',
    description:
      'Você será redirecionado para a tela “Central da Privacidade” na guia “Minhas solicitações”.',
    actionButton: () => {
      setOpen(false)
      history.push(routes.technicalFaqs.tickets)
    },
  },
  {
    title: 'Plataforma',
    description:
      'Você será redirecionado para a plataforma de acompanhamento de tickets.',
    actionButton: () => {
      setOpen(false)
      goToDeskTicketsFunction()
    },
  },
]

const tickets = {
  ALL_STATUSES_TITULAR_SOLICITATION,
  AWAITING_COMPANY_REPLY_STATUS_ID,
  AWAITING_CONFIRMATION_IDENTITY_STATUS_ID,
  AWAITING_CONFIRMATION_POSSIBILITY_STATUS_ID,
  AWAITING_REPLY_VALIDATION_STATUS_ID,
  CANCELED_STATUS_ID,
  COMPLETED_STATUS_ID,
  CONTACT_CARDS_DATA,
  FORMATTED_STATUS,
  MY_TICKETS_CARDS_DATA,
  NEW_STATUS_ID,
  PENDING_STATUS_ID,
  PENDINGS_STATUSES,
  REOPEN_STATUS_ID,
  REPLIED_STATUS_ID,
  SOLICITATION_TICKETS_TABS,
  TICKET_TYPE,
  TITULAR_SOLICITATIONS_DRIVER_STEPS_OBJECT,
  TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_1,
  TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_2,
  TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_3,
  TITULAR_TICKET_TYPE,
  WAITING_STATUS_ID,
}

export default tickets
