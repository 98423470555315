import { makeStyles } from '@material-ui/core'
import background from 'images/ticket_botão.svg'

const styles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    zIndex: 999,
    right: 0,
    bottom: '5%',
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
    },
  },
  ticketButton: {
    height: 100,
    width: 100,
    backgroundColor: 'transparent',
    backgroundImage: `url(${background})`,
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.9,
    },
  },
}))

export default styles
