import constants from 'constants/index'

const textByStatus = (status) => {
  switch (status) {
    case constants.preRegistrations.WAITING_PAYMENT_STATUS:
      return [
        'Pagamento está sendo processado!',
        'Realize o pagamento do DPOnet. Caso tenha realizado, aguarde a efetivação do pagamento.',
      ]
    case constants.preRegistrations.FAILURE_STATUS:
      return [
        'Pagamento foi reprovado!',
        'Seu pagamento foi reprovado. Realize o pagamento novamente para efetivar sua compra.',
      ]
    case constants.preRegistrations.PAID_STATUS:
      return ['Pagamento foi aprovado!']
    default:
      return ''
  }
}
export default textByStatus
