import React from 'react'
import { Box } from '@material-ui/core'

import useStyles from './styles'

const Divider = ({ children }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Box className={classes.line} />
      <Box className={classes.text}>{children}</Box>
      <Box className={classes.line} />
    </Box>
  )
}

export default Divider
