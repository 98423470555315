import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    padding: 52,
    marginBottom: 20,
  },
  title: {
    fontSize: 36,
    fontFamily: 'Raleway',
    fontWeight: 700,
    textAlign: 'center',
  },
  button: {
    width: 420,
    fontSize: 18,
    height: 60,
    alignSelf: 'center',
    color: theme.palette.primary.hover,
    border: `1px solid ${theme.palette.marketplace.card.border}`,
    transition: 'all 200ms ease-in',
    '&:hover': {
      color: theme.palette.white,
      border: theme.palette.white,
      backgroundColor: theme.palette.marketplace.card.border,
    },
  },
}))

export default styles
