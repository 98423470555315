import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { BaseDialog } from 'components'
import ActivitiesForm from './ActivitiesForm/ActivitiesForm'

const ActivitiesFormDialog = ({ open, setOpen, refresh, task }) => {
  const [loading, setLoading] = useState(false)
  const [userCompanies, setUserCompanies] = useState([])

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      loading={loading}
      closeButtonText="Cancelar"
      actionButtonText="Salvar"
      formRef="activities-form"
      title={!!task?.id ? 'Editar' : 'Criar'}
    >
      {open && (
        <ActivitiesForm
          open={open}
          setOpen={setOpen}
          refresh={refresh}
          task={task}
          setLoading={setLoading}
          userCompanies={userCompanies}
          setUserCompanies={setUserCompanies}
        />
      )}
    </BaseDialog>
  )
}

ActivitiesFormDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
  task: PropTypes.object,
}

export default ActivitiesFormDialog
