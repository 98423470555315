import React, { useState } from 'react'
import { isEmpty } from 'lodash'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { AuditsPageStructure, AuditsActions } from './components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'

const AuditsEdit = ({ match }) => {
  const [loading, setLoading] = useState(false)
  const { auditId } = match?.params

  const { response, isLoading, refresh } = useFetch(
    service.dponet.companyAudits.show,
    {
      auditId,
    },
  )

  const companyAudit = response?.data?.companyAudit

  return (
    <Page title="Editar - Auditoria de processos">
      <LoadingFeedback open={isLoading || loading} />
      <Container maxWidth={false}>
        <ContentHeader title="Editar auditoria">
          <AuditsActions
            companyAudit={companyAudit}
            setLoading={setLoading}
            refresh={refresh}
          />
        </ContentHeader>
        {!isLoading && !isEmpty(companyAudit) && (
          <AuditsPageStructure
            companyAudit={companyAudit}
            formRef="edit-audits-info-section-form"
            formType="edit"
            setLoading={setLoading}
            refresh={refresh}
          />
        )}
      </Container>
    </Page>
  )
}

export default AuditsEdit
