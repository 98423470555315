import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  TablePagination,
  Tooltip,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useTheme } from '@material-ui/styles'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import {
  ContentHeader,
  DriveTour,
  FilterButton,
  Filters,
  Page,
} from 'components'
import { AdvisorCard, SuppliersTable } from './components'

import usePagination from 'hooks/usePagination'
import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'

import styles from './styles'

const useStyles = makeStyles(styles)

const SuppliersMain = () => {
  const filter = useFilter()
  const classes = useStyles()
  const theme = useTheme()

  const { mixpanel } = helpers

  const [companyOptions, setCompanyOptions] = useState([])
  const [segments, setSegments] = useState([])
  const [openTour, setOpenTour] = useState(false)
  const [showAdvisor, setShowAdvisor] = useState(
    localStorage.getItem(constants.companySuppliers.ADVISOR_CARD_SUPPLIER) !==
      constants.companySuppliers.ADVISOR_CARD_CLOSED,
  )

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.suppliers.supplierIndex,
    {
      perPage,
      page,
      ...{ ...filter.filters },
    },
    [page, perPage, filter.filters],
  )

  useEffect(() => {
    const loadOptions = async () => {
      if (isEmpty(segments)) {
        const segmentsResponse = await service.dponet.segments.get({})
        setSegments(segmentsResponse?.data?.segments)
      }

      if (isEmpty(companyOptions)) {
        const suppliersResponse = await service.dponet.suppliers.supplierIndex(
          {},
        )
        setCompanyOptions(
          suppliersResponse?.data?.companySuppliers.map((supplier) => ({
            id: supplier.company.id,
            name: `${supplier.company.name} - ${supplier.company.document}`,
          })),
        )
      }
    }

    loadOptions()
    //eslint-disable-next-line
  }, [])

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Sou fornecedor', 'Tour', {
      action: `tour-sou-fornecedor-fornecedores`,
    })
  }

  return (
    <Page title="Sou fornecedor">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Sou fornecedor">
          <Tooltip title="Iniciar o tour guiado">
            <Button
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
              id={constants.companySuppliers.SUPPLIERS_MAIN_DRIVER_STEP_1}
            >
              Tutorial
            </Button>
          </Tooltip>
          <Box pl={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen}></FilterButton>
          </Box>
        </ContentHeader>
        {showAdvisor && (
          <AdvisorCard
            setShowAdvisor={setShowAdvisor}
            isSupplier
            isSuggestion={false}
          />
        )}
        <Card>
          <Box display="flex" width="100%" mt={2}>
            <Grid container>
              <SuppliersTable
                companySuppliers={response?.data?.companySuppliers}
                isLoading={isLoading}
                refresh={refresh}
              />
            </Grid>
          </Box>
          <Box px={2} display="flex" justifyContent="flex-end">
            {!isLoading && response ? (
              <TablePagination
                component="div"
                count={response.data.meta.totalCount}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            ) : (
              <Box py="11px">
                <Skeleton variant="rect" width={200} height={30} />
              </Box>
            )}
          </Box>
        </Card>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <select
            autocompleteselectinput="true"
            optionvalues={companyOptions}
            label="Empresa"
            name="company_id"
          />
          <select
            autocompleteselectinput="true"
            optionvalues={segments}
            label="Segmento"
            name="company_segment_id"
          />
          <input
            textfieldinput="true"
            label="Documento"
            name="company_document"
          />
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            {constants.companySuppliers.STATUSES_LABEL.map((status) => (
              <option key={status.key} value={status.key}>
                {status.label}
              </option>
            ))}
          </select>
        </Filters>
      </Container>
      <DriveTour
        stepsMatrix={constants.companySuppliers.SUPPLIERS_DRIVER_STEPS_OBJECT}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default SuppliersMain
