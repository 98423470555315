import components from '../../components'
import palette from 'theme/palette'

import { WIDTH as WIDTHPdf, GAP, PADDING, fontSize } from '../../constants'

import { createSubTableReportLia, ratio } from '../../helpers'
import constant from 'constants/index'

const dataProcessLia = (pdf, dateNow, dataProcess = {}, customStyles) => {
  const WIDTH = WIDTHPdf(pdf)
  pdf.addPage()
  components.numberPage(pdf, palette.primary.main, true)
  components.lineWithDate(pdf, `Visão Geral | ${dateNow}`)
  pdf.setFont('Raleway-Regular')
  pdf.setFontSize(ratio(44))
  pdf.setTextColor(palette.text.mainMenu)

  const dataProcessName = dataProcess.name
  const dataProcessId = dataProcess.id
  const dataProcessDepartmentName = dataProcess.departmentName
  const dataProcessFinality = dataProcess.finality

  pdf.setFontSize(fontSize.chapterTitle)
  pdf.setFont('Raleway-Bold')
  pdf.setTextColor(palette.primary.main)
  const textLines = pdf.splitTextToSize(
    `#${dataProcessId} - ${dataProcessName}`,
    WIDTH - 100,
  )
  const numberOfLines = textLines.length
  pdf.text(textLines, PADDING, GAP)
  const textHeight = numberOfLines * 23
  const positionEndText = GAP + textHeight

  const {
    NATURE_OF_PERSONAL_DATA,
    DATA_CHILDREN_TEENAGERS,
    LEGITIMATE_INTEREST_FINALITY,
    CONCRETE_SITUATION,
    TREATMENT_INTENDED_PURPOSE,
    MINIMIZATION,
    LEGITIMATE_EXPECTATION,
    RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS,
    SAFEGUARDS_AND_MECHANISMS,
    CONCLUSION_LIA_DPO,
  } = constant.liaQuestions.KINDS

  const { TABLE, SUBTABLE, FIRST_QUESTION } =
    constant.liaQuestions.REPORT_LIA_LABELS

  const filterQuestionByKind = (kind) => {
    return dataProcess?.liaAnswers?.filter((liaAnswer) => {
      return liaAnswer?.questionKind === kind
    })
  }

  const questionsNaturePersonalData = filterQuestionByKind(
    NATURE_OF_PERSONAL_DATA,
  )
  const questionsDataChildrenTeenagers = filterQuestionByKind(
    DATA_CHILDREN_TEENAGERS,
  )
  const questionsLegitimateInterestFinality = filterQuestionByKind(
    LEGITIMATE_INTEREST_FINALITY,
  )
  const questionsConcreteSituation = filterQuestionByKind(CONCRETE_SITUATION)
  const questionsTreatmentIntendedPurpose = filterQuestionByKind(
    TREATMENT_INTENDED_PURPOSE,
  )
  const questionsMinimization = filterQuestionByKind(MINIMIZATION)
  const questionLegitimateExpectation = filterQuestionByKind(
    LEGITIMATE_EXPECTATION,
  )
  const questionRisksImpactsFundamentalRightsFreedoms = filterQuestionByKind(
    RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS,
  )
  const questionSafeguardsMechanisms = filterQuestionByKind(
    SAFEGUARDS_AND_MECHANISMS,
  )
  const questionConclusionLia = filterQuestionByKind(CONCLUSION_LIA_DPO)

  const firstTableColsStyle = {
    0: {
      cellWidth: 0.3 * (WIDTH - PADDING * 2),
      halign: 'left',
    },
    1: {
      cellWidth: 0.7 * (WIDTH - PADDING * 2),
      halign: 'left',
    },
  }

  const columnQuestionAnswerStyle = {
    0: {
      cellWidth: 0.5 * (WIDTH - PADDING * 2),
      halign: 'left',
      cellPadding: 8,
      font: 'Roboto-Bold',
    },
    1: {
      cellWidth: 0.5 * (WIDTH - PADDING * 2),
      halign: 'left',
      cellPadding: 8,
    },
  }

  const subtableHeadStyle = {
    textColor: palette.white,
    font: 'Roboto-Bold',
    fillColor: palette.secondary.main,
    cellPadding: 6,
  }

  components.table(
    pdf,
    null,
    [['Departamento', dataProcessDepartmentName]],
    positionEndText,
    0.5,
    PADDING,
    true,
    firstTableColsStyle,
  )

  components.table(
    pdf,
    [[TABLE.FINALITY]],
    null,
    pdf.autoTable.previous.finalY + ratio(50),
    0.5,
    PADDING,
    true,
    undefined,
    undefined,
    15,
  )

  components.table(
    pdf,
    null,
    [[FIRST_QUESTION, dataProcessFinality]],
    pdf.autoTable.previous.finalY,
    0.5,
    PADDING,
    true,
    columnQuestionAnswerStyle,
  )

  components.table(
    pdf,
    [[SUBTABLE.NATURE_OF_PERSONAL_DATA]],
    null,
    pdf.autoTable.previous.finalY,
    0.5,
    PADDING,
    true,
    undefined,
    subtableHeadStyle,
    11,
  )

  questionsNaturePersonalData.map((lia_answer) => {
    return createSubTableReportLia(
      pdf,
      lia_answer,
      PADDING,
      columnQuestionAnswerStyle,
      undefined,
      dateNow,
    )
  })

  components.table(
    pdf,
    [[SUBTABLE.DATA_CHILDREN_TEENAGERS]],
    null,
    pdf.autoTable.previous.finalY,
    0.5,
    PADDING,
    true,
    undefined,
    subtableHeadStyle,
    11,
  )

  questionsDataChildrenTeenagers.map((lia_answer) => {
    return createSubTableReportLia(
      pdf,
      lia_answer,
      PADDING,
      columnQuestionAnswerStyle,
      undefined,
      dateNow,
    )
  })

  components.table(
    pdf,
    [[SUBTABLE.LEGITIMATE_INTEREST_FINALITY]],
    null,
    pdf.autoTable.previous.finalY,
    0.5,
    PADDING,
    true,
    undefined,
    subtableHeadStyle,
    11,
  )

  questionsLegitimateInterestFinality.map((lia_answer) => {
    return createSubTableReportLia(
      pdf,
      lia_answer,
      PADDING,
      columnQuestionAnswerStyle,
      undefined,
      dateNow,
    )
  })

  components.table(
    pdf,
    [[SUBTABLE.CONCRETE_SITUATION]],
    null,
    pdf.autoTable.previous.finalY,
    0.5,
    PADDING,
    true,
    undefined,
    subtableHeadStyle,
    11,
  )

  questionsConcreteSituation.map((lia_answer) => {
    return createSubTableReportLia(
      pdf,
      lia_answer,
      PADDING,
      columnQuestionAnswerStyle,
      undefined,
      dateNow,
    )
  })

  components.table(
    pdf,
    [[TABLE.NECESSECITY]],
    null,
    pdf.autoTable.previous.finalY + ratio(50),
    0.5,
    PADDING,
    true,
    undefined,
    undefined,
    15,
  )

  components.table(
    pdf,
    [[SUBTABLE.TREATMENT_INTENDED_PURPOSE]],
    null,
    pdf.autoTable.previous.finalY,
    0.5,
    PADDING,
    true,
    undefined,
    subtableHeadStyle,
    11,
  )

  questionsTreatmentIntendedPurpose.map((lia_answer) => {
    return createSubTableReportLia(
      pdf,
      lia_answer,
      PADDING,
      columnQuestionAnswerStyle,
      undefined,
      dateNow,
    )
  })

  components.table(
    pdf,
    [[SUBTABLE.MINIMIZATION]],
    null,
    pdf.autoTable.previous.finalY,
    0.5,
    PADDING,
    true,
    undefined,
    subtableHeadStyle,
    11,
  )

  questionsMinimization.map((lia_answer) => {
    return createSubTableReportLia(
      pdf,
      lia_answer,
      PADDING,
      columnQuestionAnswerStyle,
      undefined,
      dateNow,
    )
  })

  components.table(
    pdf,
    [[TABLE.BALANCING_SAFEGUARDIN]],
    null,
    pdf.autoTable.previous.finalY + ratio(50),
    0.5,
    PADDING,
    true,
    undefined,
    undefined,
    15,
  )

  components.table(
    pdf,
    [[SUBTABLE.LEGITIMATE_EXPECTATION]],
    null,
    pdf.autoTable.previous.finalY,
    0.5,
    PADDING,
    true,
    undefined,
    subtableHeadStyle,
    11,
  )

  questionLegitimateExpectation.map((lia_answer) => {
    return createSubTableReportLia(
      pdf,
      lia_answer,
      PADDING,
      columnQuestionAnswerStyle,
      undefined,
      dateNow,
    )
  })

  components.table(
    pdf,
    [[SUBTABLE.RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS]],
    null,
    pdf.autoTable.previous.finalY,
    0.5,
    PADDING,
    true,
    undefined,
    subtableHeadStyle,
    11,
  )

  questionRisksImpactsFundamentalRightsFreedoms.map((lia_answer) => {
    return createSubTableReportLia(
      pdf,
      lia_answer,
      PADDING,
      columnQuestionAnswerStyle,
      undefined,
      dateNow,
    )
  })

  components.table(
    pdf,
    [[SUBTABLE.SAFEGUARDS_AND_MECHANISMS]],
    null,
    pdf.autoTable.previous.finalY,
    0.5,
    PADDING,
    true,
    undefined,
    subtableHeadStyle,
    11,
  )

  questionSafeguardsMechanisms.map((lia_answer) => {
    return createSubTableReportLia(
      pdf,
      lia_answer,
      PADDING,
      columnQuestionAnswerStyle,
      undefined,
      dateNow,
    )
  })

  components.table(
    pdf,
    [[TABLE.CONCLUSION]],
    null,
    pdf.autoTable.previous.finalY + ratio(50),
    0.5,
    PADDING,
    true,
    undefined,
    undefined,
    15,
  )

  questionConclusionLia.map((lia_answer) => {
    return createSubTableReportLia(
      pdf,
      lia_answer,
      PADDING,
      columnQuestionAnswerStyle,
      true,
      dateNow,
    )
  })
}

export default dataProcessLia
