import constants from 'constants/index'

const statusColor = (status, classes) => {
  const { ALL_STATUS_ID } = constants.complaint

  const statusColorStyle = {
    [ALL_STATUS_ID.PENDING_STATUS_ID]: classes.pendingStatus,
    [ALL_STATUS_ID.FINALIZED_STATUS_ID]: classes.finalizedStatus,
    [ALL_STATUS_ID.CONVERTED_INCIDENT_STATUS_ID]:
      classes.convertedIncidentStatus,
  }

  return statusColorStyle[status]
}

export default statusColor
