import api from 'service/api'

const dponetAPI = api.create('dponet')
const dponetAPINotTreatError = api.create('dponet', false)

const get = async ({ ...params }) => {
  return await dponetAPI.get(`/client_portal_theme`, { params })
}

const getStatusTheme = async ({ ...params }) => {
  return await dponetAPINotTreatError.get(`/client_portal_theme_company`, {
    params,
  })
}

export default { get, getStatusTheme }
