import React from 'react'
import PropTypes from 'prop-types'
import { TextField, Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import { orderBy } from 'lodash'
import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'

const ConsentCustomFieldForm = ({
  selectedField,
  action,
  setCustomFields,
  customFields = [],
  onCloseDialog,
  selectedItems,
  setSelectedItems,
  setValue,
}) => {
  const snackbar = useSnackbar()

  const formattedDocumentToDefaultValues = {
    name: selectedField?.name,
    description: selectedField?.description,
  }
  const defaultFieldsValues = {
    name: '',
    description: '',
  }
  const currentDocument = {
    create: defaultFieldsValues,
    edit: formattedDocumentToDefaultValues,
  }

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: { ...currentDocument[action] },
  })

  const newCustomItems = [...customFields]

  const onSubmit = async (customFieldData) => {
    try {
      if (action === 'create') createAction(customFieldData)
      else updateAction(customFieldData)
    } catch (error) {
      snackbar.open({
        message: 'Ocorreu um erro! Tente novamente.',
        variant: 'error',
      })
    } finally {
      onCloseDialog()
    }
  }

  const updateAction = (customFieldData) => {
    const filteredSelected = selectedItems.filter(
      (item) => item.name !== selectedField.name,
    )

    const filteredCustom = customFields.filter(
      (item) => item.name !== selectedField.name,
    )

    const newField = { ...selectedField, ...customFieldData }
    const newFieldsSelected = orderBy([...filteredSelected, newField], 'order')
    setSelectedItems(newFieldsSelected)
    setCustomFields([...filteredCustom, customFieldData])
    setValue(selectedField.name, {
      subtitle: newField.description,
      required: newField.required,
      order: newField.order,
    })
  }

  const createAction = (customFieldData) => {
    newCustomItems.push(customFieldData)
    setCustomFields(newCustomItems)
  }

  return (
    <form id="consent-form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Nome do campo"
                fullWidth
                error={!!errors.name}
                helperText={errors?.name?.message}
              />
            }
            control={control}
            name="name"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Descrição do campo"
                fullWidth
                error={!!errors.description}
                helperText={errors?.description?.message}
              />
            }
            onChange={([event]) => event.target.value}
            control={control}
            name="description"
            mode="onBlur"
          />
        </Grid>
      </Grid>
    </form>
  )
}

ConsentCustomFieldForm.propTypes = {
  selectedField: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
}

export default ConsentCustomFieldForm
