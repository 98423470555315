import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import { BaseDialog } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import useStyles from './styles'
import { routes } from 'Routes'
import theme from 'theme'

const DeleteFormModal = ({
  consentForm,
  loading,
  open,
  setLoading,
  setOpen,
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const history = useHistory()

  const formRef = 'delete-consent-form'

  const onSubmit = async (event) => {
    try {
      event.preventDefault()
      setLoading(true)

      await service.dponet.consent.destroy({ id: consentForm?.id })

      snackbar.open({
        message: 'Formulário excluído com sucesso!',
        variant: 'success',
      })
      history.push(routes.consent.all)
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseDialog
      actionButtonText="Excluir"
      closeButtonText="Cancelar"
      dialogSize="md"
      formRef={formRef}
      fullWidth
      justifyActions='end'
      loading={loading}
      open={open}
      setOpen={setOpen}
      title="Excluir Formulário"
    >
      <Box
        py={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
        component="form"
        id={formRef}
        onSubmit={onSubmit}
      >
        <Box
          bgcolor={theme.palette.greenSnowFlurry}
          borderRadius={theme.spacing(1)}
          px={2}
          py={1}
        >
          <Typography className={classes.title} variant="h4">
            {consentForm?.title}
          </Typography>
        </Box>
        <Typography color="error" variant="h6">
          Total de consentimentos: {consentForm?.answersCollected?.total || 0}
        </Typography>
        <Box>
          <Typography color="secondary" variant="h6">
            Ao excluir esse formulário, todos os consentimentos vinculados serão
            revogados.{<br />}Essa ação não poderá ser desfeita. Deseja
            continuar?
          </Typography>
        </Box>
      </Box>
    </BaseDialog>
  )
}

DeleteFormModal.propTypes = {
  consentForm: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default DeleteFormModal
