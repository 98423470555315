import components from '../../components'

import palette from 'theme/palette'

import helpers from 'helpers'

import * as helpersRmc from '../../helpers'

const incident = async (pdf, date, dataIncidents, customThemeStatus) => {
  const dataIncident = dataIncidents.map((data) => [
    ['ID', data.id],
    ['Status', helpers.nonComplianceReports.status(data.status)],
    ['Descrição (Causa)', !!data.cause ? data.cause : 'Não informado'],
    [
      'Riscos',
      data.specificRisks.length > 0
        ? helpersRmc.specificsRisksText(data.specificRisks)
        : 'Nenhum risco específico informado',
    ],
    [
      'Prazo',
      !!data.deadlineDate
        ? helpers.formatters.date(data.deadlineDate)
        : 'Não informado',
    ],
    [
      'Responsável',
      !!data.responsableUser?.name
        ? data.responsableUser?.name
        : 'Nenhum responsável vinculado',
    ],
    [
      'Ação Corretiva',
      !!data.nonComplianceReportActions[0]?.correctiveAction
        ? data.nonComplianceReportActions[0]?.correctiveAction
        : 'Nenhuma ação registrada',
    ],
    [
      'Evidências',
      !!data.nonComplianceReportActions[0]?.correctionEvidence
        ? data.nonComplianceReportActions[0]?.correctionEvidence
        : 'Nenhuma evidência registrada',
    ],
  ])

  pdf.addPage('a4', 'p')
  components.numberAndTitle(pdf, '4', 'Incidentes')

  dataIncident.forEach((data, index) => {
    components.lineWithDate(pdf, date)
    components.table(
      pdf,
      [],
      data,
      index === 0 ? 200 : pdf.lastAutoTable.finalY + 35,
      0.25,
    )
    components.numberPage(pdf, palette.black, customThemeStatus)
  })
}

export default incident
