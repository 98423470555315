import { useContext, useMemo } from 'react'
import constants from 'constants/index'

import firstStepContext from 'contexts/FirstStepContext'

const useFirstStep = () => {
  const { STEP_WELCOME_INDEX, STEP_STAMP_INDEX, STEP_CERTIFICATION_INDEX } =
    constants.userSteps

  const {
    activeStep,
    activeSubStep,
    backgroundKind,
    isCheckpointStep,
    isCheckpointSubStep,
    isProductStep,
    isFinalStep,
    ...context
  } = useContext(firstStepContext)

  if (context === undefined) {
    throw new Error(
      'useFirstStep must be used within a FirstStepContext.Provider',
    )
  }

  const [isWelcomeStep, isStampStep, isStampOrCertificationStep] = useMemo(
    () => [
      // Retorna se etapa é de Boas Vindas
      activeStep === STEP_WELCOME_INDEX[0] &&
        activeSubStep === STEP_WELCOME_INDEX[1],
      STEP_STAMP_INDEX[0] === activeStep,
      // Retorna se etapa é de Selo ou Certificação
      [STEP_STAMP_INDEX[0], STEP_CERTIFICATION_INDEX[0]].includes(activeStep),
    ],
    //eslint-disable-next-line
    [activeStep, activeSubStep],
  )

  const isCenteredScreen = useMemo(
    () =>
      isWelcomeStep || isCheckpointStep || isCheckpointSubStep || isFinalStep,
    //eslint-disable-next-line
    [activeStep, activeSubStep],
  )

  const buttonText = useMemo(() => {
    if (isWelcomeStep) {
      return 'Começar minha jornada'
    }

    if (isCheckpointStep) {
      return 'Vamos lá'
    }

    if (isFinalStep) {
      return 'Finalizar'
    }

    return 'Avançar'
    //eslint-disable-next-line
  }, [activeStep, activeSubStep])

  const pageConfigs = useMemo(
    () => ({
      backgroundKind,
      textAlign: 'center',
      nextButtonText: buttonText,
      maxWidth:
        isWelcomeStep || isCheckpointStep || isProductStep ? 'lg' : 'md',
      buttonAlign: isCenteredScreen ? 'center' : 'end',
      hideStepper: !isFinalStep && isCenteredScreen,
    }),
    //eslint-disable-next-line
    [isCenteredScreen, backgroundKind, buttonText],
  )

  const defineActiveStepToExport = () => {
    if (activeStep < context.pagesLength) {
      return activeStep
    }

    return activeStep - 1
  }

  return {
    activeStep: defineActiveStepToExport(),
    activeSubStep,
    backgroundKind,
    certificationLink: context.certificationLink,
    concludeStep: context.concludeStep,
    getActivePage: context.getActivePage,
    handleBack: context.handleBack,
    handleNext: context.handleNext,
    isCenteredScreen,
    isCheckpointStep,
    isCheckpointSubStep,
    isStampOrCertificationStep,
    isStampStep,
    isWelcomeStep,
    jumpToFinalize: context.jumpToFinalize,
    loaded: context.loaded,
    loadingStep: context.loadingStep,
    nextDisabled: context.nextDisabled,
    pageConfigs,
    registerTrack: context.registerTrack,
    setActiveStep: context.setActiveStep,
    setActiveSubStep: context.setActiveSubStep,
    setBackgroundKind: context.setBackgroundKind,
    setCertificationLink: context.setCertificationLink,
    setLoaded: context.setLoaded,
    setNextDisabled: context.setNextDisabled,
    setShowBackButton: context.setShowBackButton,
    setShowNextButton: context.setShowNextButton,
    showBackButton: context.showBackButton,
    showNextButton: context.showNextButton,
  }
}

export default useFirstStep
