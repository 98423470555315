import React, { useEffect, useRef } from 'react'

import { Box, makeStyles } from '@material-ui/core'

import { Page, Container } from 'components'

import theme from 'theme'
import helpers from 'helpers'
import styles from './styles'

import { CardsListSection, Section, WhatIsSection } from './components'

const useStyles = makeStyles(styles)

const SegmentsMain = ({ match }) => {
  const classes = useStyles()
  const ref = useRef()

  const { id } = match.params
  const { description, sectionTitle, whatIs, establishments } =
    helpers.marketplace.mountSegmentData(id)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
      }
    }, 400)

    return () => clearTimeout(timer)
  }, [])

  return (
    <Page title="Listagem" ref={ref}>
      <Container maxWidth={false} className={classes.container}>
        <Box bgcolor="white">
          <Section title={sectionTitle} subtitle={description} />
          <WhatIsSection
            description={whatIs.text}
            sectionTitle={whatIs.primaryText}
            icons={whatIs.icons}
          />
          {establishments.map((establishment, idx) => (
            <CardsListSection
              title={establishment.title}
              listItems={establishment.servicesAvailables}
              bgColor="transparent"
              primaryColor={theme.palette.black}
            />
          ))}
        </Box>
      </Container>
    </Page>
  )
}

export default SegmentsMain
