import React from 'react'

import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  Error as ErrorIcon,
} from '@material-ui/icons'

import {
  AlertTriangle as AlertTriangleIcon,
  Info as InfoIcon,
} from 'react-feather'

const getIconByVariant = (variant) => {
  switch (variant) {
    case 'success':
      return <CheckCircleOutlineIcon fontSize="large" />
    case 'warning':
      return <AlertTriangleIcon size={40} />
    case 'error':
      return <ErrorIcon fontSize="large" />
    case 'blueConfirmation':
      return <InfoIcon size={40} />
    default:
      return <></>
  }
}

export default getIconByVariant
