import React from 'react'
import moment from 'moment'
import {
  Grid,
  Paper,
  Box,
  Button,
  Typography,
  Chip,
  Tooltip,
} from '@material-ui/core'
import {
  File as FileIcon,
  Eye as EyeIcon,
  Edit as EditIcon,
  Trash as TrashIcon,
} from 'react-feather'
import PropTypes from 'prop-types'

import { MenuButton, Permitted } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'
import useStyles from './styles'

const DocumentCard = ({ document, handleOpenDialog }) => {
  const { id, title, link, documentCategory, createdAt, updatedAt } = document

  const classes = useStyles()

  const menuOptions = [
    {
      icon: EyeIcon,
      title: 'Visualizar',
      permission: constants.permissions.DOCUMENT.LIST,
      handleClick: () => handleOpenDialog('show', document),
    },
    {
      icon: EditIcon,
      title: 'Editar',
      permission: constants.permissions.DOCUMENT.CREATE,
      handleClick: () => handleOpenDialog('edit', document),
    },
    {
      icon: TrashIcon,
      title: 'Excluir',
      permission: constants.permissions.DOCUMENT.DESTROY,
      handleClick: () => handleOpenDialog('destroy', document),
    },
  ]

  const redirectToDocument = () => {
    window.open(helpers.formatters.website(link), '_blank')
  }

  const currentDate = moment(new Date())
  const updatedAtDate = moment(updatedAt)
  const lastUpdatedAt = currentDate.from(updatedAtDate)
  const models = window.document.title !== 'Documentos modelo'

  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Paper component={Box} className={classes.cards}>
        <Box p={2}>
          <Box className={classes.header}>
            <Box display="flex" alignItems="center">
              <FileIcon />
              <Chip
                label={documentCategory.name.toUpperCase()}
                size="small"
                className={classes.chipRoot}
              />
            </Box>
            {models && (
              <Box>
                <MenuButton iconButtonFontSize={16}>
                  {menuOptions.map((menuOption) => (
                    <Permitted
                      tag={menuOption.permission}
                      key={menuOption.title}
                    >
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={menuOption.handleClick}
                      >
                        {menuOption.title}
                      </Button>
                    </Permitted>
                  ))}
                </MenuButton>
              </Box>
            )}
          </Box>
          <Box>
            <Tooltip title={title}>
              {models ? (
                <Typography variant="h5" gutterBottom className={classes.title}>
                  {title}
                </Typography>
              ) : (
                <Typography variant="h5" gutterBottom className={classes.title}>
                  #{id} {title}
                </Typography>
              )}
            </Tooltip>
            {models && (
              <Box>
                <Typography className={classes.textInfo} variant="subtitle2">
                  Identificador: {id}
                </Typography>
                <Typography className={classes.textInfo} variant="subtitle2">
                  Criado em {helpers.formatters.date(createdAt)}
                </Typography>
                <Typography className={classes.textInfo} variant="subtitle2">
                  Última atualização: Feita {lastUpdatedAt}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Box className={classes.boxAction}>
          <Button
            color="primary"
            onClick={redirectToDocument}
            id={constants.documents.DOCUMENTS_MODEL_MAIN_DRIVER_STEP_3}
          >
            ABRIR DOCUMENTO
          </Button>
        </Box>
      </Paper>
    </Grid>
  )
}

DocumentCard.propTypes = {
  document: PropTypes.object.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
}

export default DocumentCard
