import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  categoriesText: {
    maxWidth: 350,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export default styles
