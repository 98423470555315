import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Typography } from '@material-ui/core'
import { SolicitationDialog } from './components'

import useStyles from './styles'

const FooterSection = ({ categoryService }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>
        Quer aumentar a segurança de sua empresa e ficou com dúvidas?
      </Typography>
      <Button className={classes.button} onClick={() => setOpen(true)}>
        Fale com os nossos especialistas
      </Button>
      <SolicitationDialog
        open={open}
        setOpen={setOpen}
        categoryService={categoryService}
      />
    </Box>
  )
}

FooterSection.propTypes = {
  categoryService: PropTypes.string.isRequired,
}

export default FooterSection
