import moment from 'moment'

const formatData = (data) => {
  return {
    name: data?.name,
    description: data?.description,
    observations: data?.observations,
    responsibleId: data?.responsibleId?.id,
    startDate: moment(data?.startDate).format('DD/MM/YYYY'),
    dueDate: moment(data?.dueDate).format('DD/MM/YYYY'),
  }
}

export default formatData
