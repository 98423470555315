import React from 'react'
import clsx from 'clsx'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Layers } from 'react-feather'

import useStyles from './styles'
import helpers from 'helpers'

const ProcessCards = ({ processStats, ...rest }) => {
  const classes = useStyles()

  const cardValues = [
    {
      title: 'ATIVOS',
      value: helpers.myLgpd.activeProcess(processStats),
      color: 'green',
    },
    {
      title: 'INATIVOS',
      value: helpers.myLgpd.inactiveProcess(processStats),
      color: 'grey',
    },
  ]

  return (
    <Grid container spacing={3} {...rest}>
      {cardValues.map((cardValue, index) => (
        <Grid key={index} item xs={12} sm={6}>
          <Box p={2} className={classes.root} component={Paper}>
            <Box
              className={clsx(classes.boxPolygon, {
                [classes.greyPolygon]: cardValue.color === 'grey',
                [classes.greenPolygon]: cardValue.color === 'green',
              })}
            >
              <Box
                className={clsx(classes.avatar, {
                  [classes.greyAvatar]: cardValue.color === 'grey',
                  [classes.greenAvatar]: cardValue.color === 'green',
                })}
              >
                <Layers size={28} />
              </Box>
            </Box>

            <Box className={classes.cardContent}>
              <Typography
                component={Box}
                textOverflow="ellipsis"
                className={classes.text}
              >
                {cardValue.value}
              </Typography>

              <Typography
                component={Box}
                textOverflow="ellipsis"
                color="secondary"
                className={classes.title}
              >
                {cardValue.title}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

ProcessCards.propTypes = {
  processStats: PropTypes.object.isRequired,
}

export default ProcessCards
