import * as yup from 'yup'

const NonComplianceReportsActionSchema = yup.object().shape({
  nonComplianceReportId: yup.string().required(),
  deadlineDate: yup.date().required().typeError('Preencha o prazo'),
  responsableUserId: yup.string().required(),
  correctiveAction: yup.string(),
  correctionEvidence: yup.string(),
})

export default NonComplianceReportsActionSchema
