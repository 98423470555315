import React from 'react'
import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'
import { Box, Typography } from '@material-ui/core'
import { SuggestionCard } from './components'

import constants from 'constants/index'

const TabSections = ({ currentTabData, suggestions }) => {
  const label = currentTabData.label.toLowerCase()
  const {
    UPDATE_ACTION,
    CREATE_ACTION,
    DESTROY_ACTION,
  } = constants.suggestionChanges

  const filterByAction = (action) =>
    suggestions.filter((item) => item.action === action)

  const createSuggestions = filterByAction(CREATE_ACTION)
  const updateSuggestions = filterByAction(UPDATE_ACTION)
  const destroySuggestions = filterByAction(DESTROY_ACTION)

  return (
    <>
      {!isEmpty(updateSuggestions) && (
        <>
          <Box mb={2}>
            <Typography variant="h4" gutterBottom color="textPrimary">
              Atualizar {label}
            </Typography>
          </Box>

          <SuggestionCard
            mb={4}
            mt={2}
            tabData={currentTabData}
            suggestions={updateSuggestions}
            action={UPDATE_ACTION}
          />
        </>
      )}
      {!isEmpty(createSuggestions) && (
        <>
          <Box mb={2}>
            <Typography variant="h4" gutterBottom color="textPrimary">
              Criar {label}
            </Typography>
          </Box>

          <SuggestionCard
            mb={4}
            mt={2}
            tabData={currentTabData}
            suggestions={createSuggestions}
            action={CREATE_ACTION}
          />
        </>
      )}
      {!isEmpty(destroySuggestions) && (
        <>
          <Box mb={2}>
            <Typography variant="h4" gutterBottom color="textPrimary">
              Excluir {label}
            </Typography>
          </Box>

          <SuggestionCard
            mb={4}
            mt={2}
            tabData={currentTabData}
            suggestions={destroySuggestions}
            action={DESTROY_ACTION}
          />
        </>
      )}
    </>
  )
}

TabSections.propTypes = {
  currentTabData: PropTypes.object,
  suggestions: PropTypes.arrayOf(PropTypes.object),
}

export default TabSections
