import api from 'service/api'

const dponetAPI = api.create('dponet')

const logs = async () => {
  return await dponetAPI.get('cookie_consent_companies/logs')
}

const create = async () => await dponetAPI.post('/cookie_consent_companies')

export default { logs, create }
