import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, orderBy } from 'lodash'
import { Box, Button, Typography } from '@material-ui/core'
import { PlusCircle as PlusIcon } from 'react-feather'

import { LoadingBox, Permitted } from 'components'
import { FragilityItem, FragilitiesDialog } from './components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'
import constants from 'constants/index'

const Fragilities = ({ dataProcessId, onlyShow }) => {
  const [openNewFragility, setOpenNewFragility] = useState(false)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.companyFragilities.getByDataProcess,
    { dataProcessId },
    [],
  )

  const fragilities = response?.data?.companyFragilities

  return (
    <Box id={constants.dataProcess.DATA_PROCESS_EDIT_DRIVER_STEP_8} mt={2}>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Ameaças</Typography>
        <Permitted tag={constants.permissions.DATA_PROCESSES.CREATE}>
          {!onlyShow && (
            <Button
              id={constants.dataProcess.DATA_PROCESS_NEW_DRIVER_STEP_8}
              onClick={() => setOpenNewFragility(true)}
              startIcon={<PlusIcon />}
              variant="contained"
              color="primary"
            >
              Adicionar ameaça
            </Button>
          )}
        </Permitted>
      </Box>

      {isLoading && <LoadingBox minHeight={200} />}
      {!isLoading && isEmpty(fragilities) && (
        <Box pb={1}>
          <Typography variant="overline">
            Não foram encontradas ameaças para este processo
          </Typography>
        </Box>
      )}
      {orderBy(fragilities, 'isCompany').map((fragility) => (
        <FragilityItem
          key={fragility?.id}
          companyFragility={fragility}
          dataProcessId={dataProcessId}
          fragilityItems={fragility?.companyFragilityMeasures}
          refresh={refresh}
          onlyShow={onlyShow}
        />
      ))}

      {!isLoading && (
        <FragilitiesDialog
          dataProcessId={dataProcessId}
          defaultSelectedItems={fragilities}
          refresh={refresh}
          open={openNewFragility}
          setOpen={setOpenNewFragility}
        />
      )}
    </Box>
  )
}

Fragilities.propTypes = {
  dataProcessId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onlyShow: PropTypes.bool,
}

export default Fragilities
