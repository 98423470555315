import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chipStyle: {
    margin: '5px',
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export default styles
