const styles = (theme) => ({
  root: {
    marginTop: -theme.spacing(2),
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: -theme.spacing(2),
    },
  },
})

export default styles
