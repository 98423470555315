const finalizedRmcs = (stats, process = true) => {
  if (process) {
    return stats?.departments?.reduce(
      (value, department) => value + department.nonComplianceReports?.finalized,
      0,
    )
  }

  return stats?.questionnaires?.reduce(
    (value, questionnaire) =>
      value + questionnaire.nonComplianceReports?.finalized,
    0,
  )
}

export default finalizedRmcs
