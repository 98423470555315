import constants from 'constants/index'
const filterUnnecessary = (unnecessaryDatas, entity, isShow) => {
  if (!unnecessaryDatas) return []

  if (isShow) {
    return unnecessaryDatas?.filter(
      (unnecessaryData) =>
        unnecessaryData?.dataType === entity &&
        unnecessaryData?.status !==
          constants.nonComplianceReports.UNNECESSARY_FINALITY,
    )
  }

  return unnecessaryDatas?.filter(
    (unnecessaryData) =>
      unnecessaryData?.dataType === entity &&
      unnecessaryData?.status ===
        constants.nonComplianceReports.UNNECESSARY_FINALITY,
  )
}

export default filterUnnecessary
