import { colors } from '@material-ui/core'

const styles = (theme) => ({
  headerBox: {
    backgroundColor: theme.palette.primary.light,
  },
  consentBox: {
    border: `1px solid ${theme.palette.divider}`,
    justifyContent: 'flex-start',
    borderRadius: 5,
  },
  attributeBox: {
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  menuListBox: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
  },
  helpCentralButton: {
    backgroundColor: colors.green[500],
    color: colors.grey[50],
    width: 150,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: colors.green[500],
      opacity: 0.8,
    },
  },
  buttonField: {
    textTransform: 'capitalize',
    padding: 12,
  },
  menuButton: {
    display: 'contents',
  },
})

export default styles
