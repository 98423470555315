import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Link, Checkbox } from '@material-ui/core'

import useStyles from './styles'

const FormPolicy = ({ setDemoTermsAccept }) => {
  const classes = useStyles()

  const redirectToDemoTerms = () => {
    window.open(
      'https://drive.google.com/file/d/1d0yP2H846RNMa_dR7tGzWv5xmE3rEqew/view?usp=sharing',
    )
  }

  return (
    <Box display="flex" alignItems="center">
      <Checkbox
        color="primary"
        inputProps={{ 'aria-label': 'tertiary checkbox' }}
        onChange={(event) => setDemoTermsAccept(event.target.checked)}
      />
      <Typography>
        Li e concordo com o{' '}
        <Link className={classes.pointer} onClick={redirectToDemoTerms}>
          termo de licença de cortesia
        </Link>
      </Typography>
    </Box>
  )
}

FormPolicy.propTypes = {
  setDemoTermsAccept: PropTypes.func,
}

FormPolicy.defaultProps = {
  setDemoTermsAccept: () => {},
}

export default FormPolicy
