import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Box, useMediaQuery } from '@material-ui/core'

import useStyles from './styles'
import theme from 'theme'

const RoundedBox = ({ children, noOpacity, maxWidth }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  if (isMobile) {
    return <Box mt={1}>{children}</Box>
  }

  return (
    <Box
      maxWidth={maxWidth}
      className={clsx(classes.root, {
        [classes.noOpacityBackground]: noOpacity,
      })}
    >
      {children}
    </Box>
  )
}
RoundedBox.propTypes = {
  noOpacity: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

RoundedBox.defaultProps = {
  noOpacity: false,
  maxWidth: theme.breakpoints.values.md,
}

export default RoundedBox
