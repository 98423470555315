import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async () => {
  return await dponetAPI.get(`/privacy_portal/stamps`)
}

const consult = async (data) => {
  return await dponetAPI.post(`/privacy_portal/consult`, data)
}

export default {
  get,
  consult,
}
