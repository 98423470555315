import React from 'react'
import PropTypes from 'prop-types'

import LinearProgressTable from 'components/LinearProgressTable/LinearProgressTable'
import helpers from 'helpers'

const ProcessRiskTable = ({ processStats, ...rest }) => {
  const totalProcess = helpers.myLgpd.totalProcess(processStats)
  const lowRiskCount = helpers.myLgpd.lowRiskProcess(processStats)
  const mediumRiskCount = helpers.myLgpd.mediumRiskProcess(processStats)
  const highRiskCount = helpers.myLgpd.highRiskProcess(processStats)
  const severeRiskCount = helpers.myLgpd.severeRiskProcess(processStats)

  const percentage = (count) => (count / totalProcess) * 100

  const rows = [
    {
      name: 'Risco baixo',
      value: lowRiskCount,
      percentage: percentage(lowRiskCount),
    },
    {
      name: 'Risco médio',
      value: mediumRiskCount,
      percentage: percentage(mediumRiskCount),
    },
    {
      name: 'Risco alto',
      value: highRiskCount,
      percentage: percentage(highRiskCount),
    },
    {
      name: 'Risco severo',
      value: severeRiskCount,
      percentage: percentage(severeRiskCount),
    },
  ]

  return (
    <>
      <LinearProgressTable
        rows={rows}
        title="Processos por risco"
        tableType="process"
        {...rest}
      />
    </>
  )
}

ProcessRiskTable.propTypes = {
  processStats: PropTypes.object.isRequired,
}

export default ProcessRiskTable
