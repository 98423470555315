import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  collapse: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
}))

export default styles
