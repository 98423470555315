const mountUnnecessaryFields = (dataCollecteds = []) => {
  return dataCollecteds
    .map((dataCollected) =>
      dataCollected.collectedFields.map((collectedField) => ({
        ...collectedField,
        possibleMinor: dataCollected.possibleMinor,
        titularCategory: dataCollected.titularCategory,
        holderNumber: dataCollected?.holderNumber,
      })),
    )
    .flat(Infinity)
    .filter((collectedField) => collectedField.necessary === false)
}

export default mountUnnecessaryFields
