import api from 'service/api'

const marketPlaceAPIWithRedirect = api.create('marketPlace')
const marketPlaceAPI = api.create('marketPlace', false)

const getAll = async ({ ...params }) => {
  return marketPlaceAPI.get('showcases/partners_services', { params })
}

const get = async ({ partnerServiceId, ...params }) => {
  return marketPlaceAPIWithRedirect.get(
    `showcases/partners_services/${partnerServiceId}`,
    {
      params,
    },
  )
}

const partners = {
  getAll,
  get,
}

export default partners
