import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { isEmpty, uniqBy } from 'lodash'

import { routes } from 'Routes'
import { useHistory } from 'react-router-dom'

import { Controller, useForm } from 'react-hook-form'

import { Autocomplete } from '@material-ui/lab'
import { Box, Button, Grid, Paper, TextField } from '@material-ui/core'
import { LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'

import schema from './schema'
import useStyles from './styles'

const QuestionControlForm = ({ securityMeasure }) => {
  const history = useHistory()
  const snackbar = useSnackbar()
  const classes = useStyles()

  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)

  const { handleSubmit, control, errors, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      title: '',
      subtitle: '',
      formKind: null,
      status: null,
    },
  })

  const handleGoIndex = () => history.push(routes.questionControls.all)

  useEffect(() => {
    const loadCategories = async () => {
      if (isEmpty(categories)) {
        setLoading(true)
        const response = await service.dponet.questionControls.listCustomKinds()

        setCategories(response?.data?.questionControls)
        setLoading(false)
      }
    }
    loadCategories()

    //eslint-disable-next-line
  }, [])

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      data.formKind = data?.categoryName || data?.formKind?.name
      data.status = data?.status?.id
      delete data.categoryName

      await service.dponet.questionControls.create(data)
      snackbar.open({
        variant: 'success',
        message: `Medida de segurança ${
          !!securityMeasure?.id ? 'editada' : 'criada'
        } com sucesso`,
      })
      handleGoIndex()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      snackbar.open({
        variant: 'error',
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error,
          false,
        ),
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingFeedback open={loading} />
      <Paper variant="outlined" component={Box} px={2} pt={4} pb={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  label="Medida"
                  fullWidth
                />
              }
              mode="onChange"
              control={control}
              name="title"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <Autocomplete
                  options={uniqBy(
                    helpers.questionControls.categoryOptions([
                      ...constants.questionControl.KIND_OPTIONS,
                      ...(categories ?? []),
                    ]),
                    'id',
                  )}
                  getOptionLabel={(option) => option?.name || ''}
                  getOptionSelected={(option, value) =>
                    option?.id === value?.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.formKind}
                      helperText={errors?.formKind?.message}
                      label="Categoria"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  renderOption={(option) => (
                    <Box className={classes.categoriesText}>{option.name}</Box>
                  )}
                />
              }
              onChange={([, data]) => data}
              mode="onChange"
              control={control}
              name="formKind"
            />
          </Grid>
          {watch('formKind')?.id === 'another' && (
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    error={!!errors.categoryName}
                    helperText={errors?.categoryName?.message}
                    label="Outro: nova categoria"
                    variant="outlined"
                    fullWidth
                  />
                }
                mode="onChange"
                control={control}
                name="categoryName"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  minRows={3}
                  multiline
                  label="Descrição"
                  fullWidth
                  name="subtitle"
                />
              }
              mode="onChange"
              control={control}
              name="subtitle"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <Autocomplete
                  options={constants.questionControl.STATUSES.filter((status) =>
                    ['adopted', 'pending'].includes(status.id),
                  )}
                  getOptionLabel={(option) => option?.name || ''}
                  getOptionSelected={(option, value) =>
                    option?.id === value?.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.status}
                      helperText={errors?.status?.message}
                      label="Status"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              }
              onChange={([, data]) => data}
              mode="onChange"
              control={control}
              name="status"
            />
          </Grid>
        </Grid>
        <Box display="flex" mt={4} width="100%" justifyContent="flex-end">
          <Button
            disabled={loading}
            variant="contained"
            onClick={handleGoIndex}
          >
            Voltar
          </Button>
          <Box ml={2}>
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </Box>
        </Box>
      </Paper>
    </form>
  )
}

QuestionControlForm.propTypes = {
  securityMeasure: PropTypes.object,
}

export default QuestionControlForm
