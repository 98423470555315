import { makeStyles } from '@material-ui/core'
const styles = makeStyles((theme) => ({
  noticeBillet: {
    backgroundColor: '#B8DAF7',
    padding: theme.spacing(2.5),
    marginTop: theme.spacing(2.5),
  },
  noticeText: {
    color: '#076EA0',
  },
  selectfield: {
    width: theme.spacing(17.5),
  },
  styleText: {
    [theme.breakpoints.down('xs')]: { fontSize: 17 },
    fontWeight: 500,
  },
}))

export default styles
