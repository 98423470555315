const DETECTION_STEP = 0
const INVESTIGATION_STEP = 1
const AVALIATION_STEP = 2
const DETALING_STEP = 3
const TREATMENT_STEP = 4
const COMMUNICATION_STEP = 5
const LESSONS_LEARNED_STEP = 6

const STEPS = [
  DETECTION_STEP,
  INVESTIGATION_STEP,
  AVALIATION_STEP,
  DETALING_STEP,
  TREATMENT_STEP,
  COMMUNICATION_STEP,
  LESSONS_LEARNED_STEP,
]

const DETECTION_ENUM = 'detection'
const INVESTIGATION_ENUM = 'investigation'
const AVALIATION_ENUM = 'avaliation'
const DETALING_ENUM = 'detaling'
const TREATMENT_ENUM = 'treatment'
const COMMUNICATION_ENUM = 'communication'
const LESSONS_LEARNED_ENUM = 'lessons_learned'

const ENUMS_BY_STEP = {
  [DETECTION_STEP]: DETECTION_ENUM,
  [INVESTIGATION_STEP]: INVESTIGATION_ENUM,
  [AVALIATION_STEP]: AVALIATION_ENUM,
  [DETALING_STEP]: DETALING_ENUM,
  [TREATMENT_STEP]: TREATMENT_ENUM,
  [COMMUNICATION_STEP]: COMMUNICATION_ENUM,
  [LESSONS_LEARNED_STEP]: LESSONS_LEARNED_ENUM,
}

const STEPS_BY_ENUM = {
  [DETECTION_ENUM]: DETECTION_STEP,
  [INVESTIGATION_ENUM]: INVESTIGATION_STEP,
  [AVALIATION_ENUM]: AVALIATION_STEP,
  [DETALING_ENUM]: DETALING_STEP,
  [TREATMENT_ENUM]: TREATMENT_STEP,
  [COMMUNICATION_ENUM]: COMMUNICATION_STEP,
  [LESSONS_LEARNED_ENUM]: LESSONS_LEARNED_STEP,
}

const DETECTION_LABEL = 'Detecção'
const INVESTIGATION_LABEL = 'Investigação'
const AVALIATION_LABEL = 'Avaliação'
const DETALING_LABEL = 'Detalhamento'
const TREATMENT_LABEL = 'Tratamento'
const COMMUNICATION_LABEL = 'Comunicação'
const LESSONS_LEARNED_LABEL = 'Lições Aprendidas'

const LABELS = {
  [DETECTION_ENUM]: DETECTION_LABEL,
  [INVESTIGATION_ENUM]: INVESTIGATION_LABEL,
  [AVALIATION_ENUM]: AVALIATION_LABEL,
  [DETALING_ENUM]: DETALING_LABEL,
  [TREATMENT_ENUM]: TREATMENT_LABEL,
  [COMMUNICATION_ENUM]: COMMUNICATION_LABEL,
  [LESSONS_LEARNED_ENUM]: LESSONS_LEARNED_LABEL,
}

export default {
  ENUMS_BY_STEP,
  LABELS,
  STEPS,
  STEPS_BY_ENUM,
  DETECTION_ENUM,
  INVESTIGATION_ENUM,
  AVALIATION_ENUM,
  DETALING_ENUM,
  TREATMENT_ENUM,
  COMMUNICATION_ENUM,
  LESSONS_LEARNED_ENUM,
}
