import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'

import { Card, LoadingFeedback, MenuButton } from 'components'
import { NonComplianceForm } from './components'
import {
  DialogReopen,
  DialogResidualRisk,
} from 'views/NonComplianceReports/components'

import useIncidentManegement from 'hooks/useIncidentManegement'
import useSnackbar from 'hooks/useSnackbar'
import useRmc from 'hooks/useRmc'

import * as service from 'service'
import formatters from 'helpers/formatters'
import constants from 'constants/index'

const TreatmentStep = ({ refresh }) => {
  const [loading, setLoading] = useState(false)
  const [openResidualRiskDialog, setOpenResidualRiskDialog] = useState(false)
  const [openReopenDialog, setOpenReopenDialog] = useState(false)

  const { wantsToAdvanceStep, handleNext, resetAdvanceStep, incident } =
    useIncidentManegement()
  const { data: nonComplianceReport } = useRmc()
  const snackbar = useSnackbar()

  const onSubmit = async () => {
    try {
      setLoading(true)

      await service.dponet.incidents.concludeStep({
        incidentId: incident.id,
        step: 'treatment',
      })

      resetAdvanceStep()
      handleNext()
    } catch (error) {
      resetAdvanceStep()
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (wantsToAdvanceStep) {
      onSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantsToAdvanceStep])

  const riskAcceptedStatus = [
    constants.nonComplianceReports.FINALIZED_STATUS,
    constants.nonComplianceReports.RISK_ACCEPTED_STATUS,
  ]
  const permitReopenNonComplianceReport = riskAcceptedStatus.includes(
    nonComplianceReport?.status,
  )

  const permitAcceptRisk =
    !constants.nonComplianceReports.NOT_ACCEPT_RISK_INCIDENT_STATUSES.includes(
      nonComplianceReport?.status,
    )

  return (
    <Card
      title="Tratamento do Incidente"
      actionButton={
        <>
          {(permitAcceptRisk || permitReopenNonComplianceReport) && (
            <MenuButton>
              {permitReopenNonComplianceReport && (
                <Button
                  color="secondary"
                  fullWidth
                  onClick={() => setOpenReopenDialog(true)}
                >
                  Reabrir o Tratamento
                </Button>
              )}

              {permitAcceptRisk && (
                <Button
                  color="secondary"
                  fullWidth
                  onClick={() => setOpenResidualRiskDialog(true)}
                >
                  Aceitar Risco
                </Button>
              )}
            </MenuButton>
          )}
        </>
      }
    >
      <LoadingFeedback open={loading} />
      <NonComplianceForm
        refresh={refresh}
        openResidualRiskDialog={openResidualRiskDialog}
        setOpenResidualRiskDialog={setOpenResidualRiskDialog}
      />

      {openResidualRiskDialog && (
        <DialogResidualRisk
          nonComplianceReportId={nonComplianceReport.id}
          open={openResidualRiskDialog}
          setOpen={setOpenResidualRiskDialog}
          residualRisk={nonComplianceReport?.residualRisk}
          onEvent={refresh}
        />
      )}

      {openReopenDialog && (
        <DialogReopen
          nonComplianceReport={nonComplianceReport}
          open={openReopenDialog}
          setOpen={setOpenReopenDialog}
          onEvent={refresh}
        />
      )}
    </Card>
  )
}

export default TreatmentStep
