import { Grid, Typography } from '@material-ui/core'
import React from 'react'

const ShowDataProcessTemplateForm = ({ questions, renderAnswer }) => {
  return (
    <>
      {questions.map((question) => (
        <Grid item xs={12} key={question.id}>
          <Typography variant="subtitle2" color="textSecondary">
            {question.title}
          </Typography>
          {renderAnswer(question)}
        </Grid>
      ))}
    </>
  )
}

export default ShowDataProcessTemplateForm
