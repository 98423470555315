import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Typography } from '@material-ui/core'
import { SupplierWelcome, SupplierAdequacy, AvaliationAndTerms } from '../'

import { LoadingFeedback } from 'components'

import { useHistory } from 'react-router-dom'
import useStyles from './styles'
import useFetch from 'hooks/useFetch'

import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'

const VideoPlayer = ({
  setRenderedContent,
  supplierId,
  skipConfirmation,
  client,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const { response, isLoading } = useFetch(
    service.dponet.questionnaires.companyCompliance,
    {},
    [],
    skipConfirmation,
  )
  const companyCompliance = response?.data

  const handleNext = () => {
    if (skipConfirmation || !client) {
      if (!companyCompliance?.answered) {
        return setRenderedContent(
          <AvaliationAndTerms setRenderedContent={setRenderedContent} />,
        )
      }
      if (
        companyCompliance?.answered &&
        companyCompliance?.negativeQuestions === 0
      ) {
        return history.push(routes.questionnaire.all)
      }

      if (
        companyCompliance?.answered &&
        companyCompliance?.negativeQuestions > 0
      ) {
        return setRenderedContent(
          <SupplierAdequacy
            nonCompliance={companyCompliance?.negativeQuestions}
          />,
        )
      }
    } else {
      return setRenderedContent(
        <SupplierWelcome
          client={client}
          supplierId={supplierId}
          setRenderedContent={setRenderedContent}
        />,
      )
    }
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <Box className={classes.videoContainer}>
        <Typography className={classes.text}>
          Entenda o que é a LGPD!
        </Typography>
        <iframe
          className={classes.videoPlayer}
          title="Conceitos - O que é LGPD"
          src={constants.supplierInvite.VIDEO_URL}
          allowFullScreen
          allow={'picture-in-picture; fullscreen'}
        />
        <Button onClick={handleNext} className={classes.roundedButton}>
          AVANÇAR
        </Button>
      </Box>
    </>
  )
}

VideoPlayer.propTypes = {
  setRenderedContent: PropTypes.func,
  supplierId: PropTypes.number,
  skipConfirmation: PropTypes.bool,
  client: PropTypes.object,
}

export default VideoPlayer
