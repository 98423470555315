import React, { useState, useRef, useEffect } from 'react'

import PropTypes from 'prop-types'

import { Button, CircularProgress } from '@material-ui/core'

const LoadingButton = ({
  loading,
  text,
  disabledButton,
  startIcon = <></>,
  ...rest
}) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })
  const targetRef = useRef()

  useEffect(() => {
    if (targetRef.current.offsetWidth >= dimensions.width) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      })
    }
    //eslint-disable-next-line
  }, [loading])

  return (
    <Button
      color="primary"
      type="submit"
      variant="contained"
      ref={targetRef}
      style={{ width: !loading ? 'auto' : dimensions.width }}
      startIcon={!loading && startIcon}
      disabled={loading || disabledButton}
      {...rest}
    >
      {loading ? <CircularProgress color="inherit" size={25} /> : `${text}`}
    </Button>
  )
}

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.string,
  disabledButton: PropTypes.bool,
  startIcon: PropTypes.node,
}

LoadingButton.defaultProps = {
  startIcon: <></>,
}

export default LoadingButton
