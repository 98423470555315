import generalInformation from './generalInformation'
import incident from './incident'
import occurrenceScience from './occurrenceScience'
import timmingCommunication from './timingCommunication'
import impactDescription from './impactDescription'
import riskConsequence from './risksConsequence'
import securityMeasures from './securityMeasures'

const pages = {
  generalInformation,
  incident,
  occurrenceScience,
  timmingCommunication,
  impactDescription,
  riskConsequence,
  securityMeasures,
}

export default pages
