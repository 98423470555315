import constants from 'constants/index'

const convertSourceDescriptionToSource = (source) => {
  switch (source) {
    case constants.dataProcess.TITULAR_DESCRIPTION:
      return constants.dataProcess.TITULAR_DATA_SOURCE
    case constants.dataProcess.DEPARTMENT_DESCRIPTION:
      return constants.dataProcess.DEPARTMENT_SOURCE
    case constants.dataProcess.THIRD_PARTY_SOURCE_DESCRIPTION:
      return constants.dataProcess.THIRD_PARTY_SOURCE
    case constants.dataProcess.PUBLIC_SOURCE_DESCRIPTION:
      return constants.dataProcess.PUBLIC_SOURCE
    default:
      return constants.dataProcess.ANOTHER_SOURCE
  }
}

export default convertSourceDescriptionToSource
