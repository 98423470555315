const convertStringToPhone = (phone, withoutCountry = false) => {
  if (phone === null || phone === undefined) return '-'
  phone = phone.replace(/\D/g, '')

  let l = phone.length

  l = phone.length

  if (withoutCountry) {
    if (l <= 2) return phone.replace(/^(\d{1,2}).*/, '($1')

    if (l < 7 && l >= 2)
      return phone.replace(/^(\d{1,2})(\d{1,5}).*/, '($1) $2')

    if (l >= 11)
      return phone.replace(
        /^(\d{1,2})(\d{1,1})(\d{1,4})(\d{1,4}).*/,
        '($1) $2 $3-$4',
      )
    return phone.replace(/^(\d{1,2})(\d{1,4})(\d{1,4}).*/, '($1) $2-$3')
  } else {
    if (l <= 2) return phone.replace(/^(\d{1,2}).*/, '+$1')

    if (l < 5 && l >= 2)
      return phone.replace(/^(\d{1,2})(\d{1,2}).*/, '+$1 ($2')

    if (l <= 9 && l >= 5)
      return phone.replace(/^(\d{1,2})(\d{1,2})(\d{1,5}).*/, '+$1 ($2) $3')

    return phone.replace(
      /^(\d{1,2})(\d{1,2})(\d{1,5})(\d{1,4}).*/,
      '+$1 ($2) $3-$4',
    )
  }
}

export default convertStringToPhone
