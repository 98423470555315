import { isEmpty } from 'lodash'

import helpers from 'helpers'
import components from '../../components'

import { GAP } from '../../constants'

import palette from 'theme/palette'

const incident = async (pdf, date, { incident }) => {
  pdf.addPage('a4', 'p')
  components.subtitle(pdf, 'Incidentes', GAP)
  components.lineWithDate(pdf, date)

  const tableData = [
    ['ID', incident?.id],
    ['Data do Incidente', helpers.formatters.date(incident?.incidentDatetime)],
    ['Hora do Incidente', helpers.formatters.hours(incident?.incidentDatetime)],
    [
      'Quantidade estimada de titulares afetados',
      incident?.affectedHolders || 'N/A',
    ],
    ['Como ocorreu o incidente', incident?.description || 'N/A'],
    ['Por que o incidente ocorreu', incident?.descriptionRootIncident || 'N/A'],
    [
      'Medidas adotadas para corrigir as causas do incidente',
      incident?.descriptionMeasures || 'N/A',
    ],
    [
      'Medidas de segurança adotadas antes do incidente',
      incident?.dataOptionIncidents?.whichSecurityMeasuresAdopted
        ? incident.dataOptionIncidents.whichSecurityMeasuresAdopted
            .map((measureAdopted) => measureAdopted.name + '\n')
            .join('')
        : 'N/A',
    ],
    [
      'Medidas de segurança técnicas e administrativas adotadas antes do incidente',
      incident?.descriptionOtherTechnicalAdministrativeSecurityMeasure || 'N/A',
    ],
    [
      'Formas que o incidente afetou os dados pessoais',
      !isEmpty(incident?.incidentViolationTypes)
        ? incident.incidentViolationTypes
            .map((incidentViolation) => incidentViolation?.name + '\n')
            .join('')
        : 'N/A',
    ],
    [
      'Tipos de dados pessoais sensíveis violados',
      incident?.dataOptionIncidents?.typesSensitivePersonalDataBreached
        ? incident.dataOptionIncidents.typesSensitivePersonalDataBreached
            .map((dataType) => dataType.name + '\n')
            .join('')
        : 'N/A',
    ],
    [
      'Demais tipos de dados pessoais violados',
      incident?.dataOptionIncidents?.otherTypePersonalDataViolated
        ? incident.dataOptionIncidents.otherTypePersonalDataViolated
            .map((dataType) => dataType.name + '\n')
            .join('')
        : 'N/A',
    ],
    [
      'Tipos de dados pessoais não sensíveis violados',
      incident?.nonSensitiveViolationDescription || 'N/A',
    ],
  ]

  components.table(pdf, [], tableData, GAP * 1.5, 0.25)
  components.numberPage(pdf, palette.black)
}

export default incident
