import React from 'react'
import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import { Grid } from '@material-ui/core'
import { PersonOutline as UserIcon } from '@material-ui/icons'
import {
  ShoppingBag as ShoppingBagIcon,
  Briefcase as CompanyIcon,
} from 'react-feather'

import { ResumeCompany, ResumePrice, ResumeResponsible } from './components'
import { ResumeCard } from 'components/PreRegistration'

import useSubscription from 'hooks/useSubscription'

const RegistrationResume = ({ defaultExpanded }) => {
  const { data, responsibleData } = useSubscription()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ResumeCard
          icon={ShoppingBagIcon}
          title="RESUMO DO PEDIDO"
          defaultExpanded
        >
          <ResumePrice />
        </ResumeCard>
      </Grid>
      {!isEmpty(responsibleData) && (
        <Grid item xs={12}>
          <ResumeCard
            icon={UserIcon}
            title="SOBRE VOCÊ"
            collapsible={!defaultExpanded}
            defaultExpanded={defaultExpanded}
          >
            <ResumeResponsible responsibleData={responsibleData} />
          </ResumeCard>
        </Grid>
      )}
      {!isEmpty(data) && (
        <Grid item xs={12}>
          <ResumeCard
            icon={CompanyIcon}
            title="SOBRE A EMPRESA"
            collapsible={!defaultExpanded}
            defaultExpanded={defaultExpanded}
          >
            <ResumeCompany data={data} />
          </ResumeCard>
        </Grid>
      )}
    </Grid>
  )
}

RegistrationResume.propTypes = {
  defaultExpanded: PropTypes.bool,
}

RegistrationResume.defaultProps = {
  defaultExpanded: false,
}

export default RegistrationResume
