import React from 'react'

import { Card } from 'components'
import { Box, Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { Controller } from 'react-hook-form'

const NotificationPreferences = ({ control, loading }) => {
  return (
    <Box mt={4}>
      <Card title="Configurações de notificação" paddingBottom={0}>
        <Box display="flex" alignItems="center">
          <FormControlLabel
            control={
              <Controller
                as={<Checkbox color="primary" />}
                name="notifyDataProcessChanges"
                control={control}
              />
            }
            labelPlacement="end"
            label="Notificar os responsáveis de um departamento sobre mudanças nos processos"
            onChange={([_, data]) => data}
          />
        </Box>
      </Card>
      <Box mt={4} display="flex" justifyContent="end" width="100%">
        <Button
          disabled={loading}
          color="primary"
          variant="contained"
          type="submit"
        >
          Salvar
        </Button>
      </Box>
    </Box>
  )
}

export default NotificationPreferences
