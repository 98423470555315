import React from 'react'
import DOMPurify from 'dompurify'
import {
  Box,
  Chip,
  Container,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core'

import { Page, ContentHeader, LoadingBox } from 'components'

import useFetch from 'hooks/useFetch'
import constants from 'constants/index'
import * as service from 'service'
import styles from './styles'

const useStyles = makeStyles(styles)

const FaqsDocumentationShow = ({ match }) => {
  const classes = useStyles()
  const faqsDocumentationId = match.params.faqsDocumentationId
  const { response, isLoading } = useFetch(
    service.dponet.faqsDocumentations.get,
    {
      faqsDocumentationId,
    },
  )

  const faqsDocumentation = response?.data?.faqsDocumentation
  const kind =
    faqsDocumentation?.kind === constants.faqsDocumentations.KINDS.FAQ
      ? 'FAQ'
      : 'DOCUMENTAÇÃO'

  const descriptionSanitizer = DOMPurify.sanitize(
    faqsDocumentation?.description,
    {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
    },
  )

  return (
    <Page title={kind}>
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title={kind}></ContentHeader>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <Paper className={classes.paper} width="100%" mt={8}>
            <Box width="100%" m={2}>
              <Chip
                className={
                  faqsDocumentation?.kind ===
                  constants.faqsDocumentations.KINDS.DOCUMENTATION
                    ? classes.faqShow
                    : classes.documentationShow
                }
                label={faqsDocumentation?.faqsDocumentationsCategory?.name}
              />
            </Box>
            <Box className={classes.boxTitle} mt={1}>
              <Box>
                <Typography variant="h3">{faqsDocumentation?.title}</Typography>
              </Box>
            </Box>
            <Box mb={1}>
              <Typography variant="h4">
                {faqsDocumentation?.subtitle}
              </Typography>
            </Box>
            <Box
              m={5}
              className={classes.boxDescription}
              textOverflow="hidden"
              fontFamily="Roboto"
              dangerouslySetInnerHTML={{
                __html: descriptionSanitizer,
              }}
            />
          </Paper>
        )}
      </Container>
    </Page>
  )
}

export default FaqsDocumentationShow
