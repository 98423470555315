import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Box, Grid, TextField, Typography } from '@material-ui/core'

import { BaseDialog } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import schema from './schema'
import * as service from 'service'
import constants from 'constants/index'

const NewBannerModal = ({
  handleCreateModal,
  loading,
  open,
  refresh,
  setLoading,
  setOpen,
}) => {
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, reset } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: '',
      url: '',
    },
  })

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const banner = {
        ...data,
        status: constants.cookies.banner.INCOMPLETE_STATUS_ID,
      }

      await service.cookies.banner.create({ banner })

      snackbar.open({
        message: 'Banner adicionado com sucesso!',
        variant: 'success',
      })
      reset()
      refresh()
      handleCreateModal()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Novo Banner de Cookies"
      dialogSize="md"
      fullWidth
      closeButtonText="Cancelar"
      actionButtonText="Adicionar"
      formRef="create-cookie-form"
      loading={loading}
    >
      <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
        <Box bgcolor={theme.palette.primary.light} padding={2}>
          <Typography variant="h5" gutterBottom>
            Informação
          </Typography>
          <Typography>
            Um banner de cookies é um aviso exibido nos sites para informar e
            obter o consentimento dos usuários antes de rastrear e armazenar
            seus dados de navegação.
          </Typography>
        </Box>
        <form id="create-cookie-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {constants.cookies.banner.NEW_BANNER_MODAL_CONTROL_ITEMS.map(
              (item, index) => (
                <Grid key={index} item xs={12}>
                  <Controller
                    as={
                      <TextField
                        label={item?.label}
                        type="text"
                        color="primary"
                        variant="outlined"
                        error={!!errors?.[item?.controlName]}
                        helperText={errors?.[item?.controlName]?.message}
                        fullWidth
                      />
                    }
                    name={item?.controlName}
                    mode="onChange"
                    control={control}
                  />
                </Grid>
              ),
            )}
          </Grid>
        </form>
      </Box>
    </BaseDialog>
  )
}

NewBannerModal.propTypes = {
  handleCreateModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default NewBannerModal
