import { makeStyles } from '@material-ui/core'

const styles = (bgColor) =>
  makeStyles((theme) => ({
    container: {
      marginTop: -25,
    },
    itemContainer: {
      cursor: 'pointer',
      backgroundColor: bgColor || theme.palette.marketplace.banner.cookies,
      border: !!bgColor && `2px solid ${theme.palette.primary.main}`,
      borderRadius: 5,
    },
    imageContainer: {
      height: 170,
      objectPosition: 'center',
    },
    banner: {
      objectFit: 'scale-down',
    },
  }))

export default styles
