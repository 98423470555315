import api from 'service/api'
import axios from 'axios'
import { getBaseURL } from '../../env'

import * as service from 'service'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

const deleteDocument = async ({ dataProcessesId, documentName, ...params }) => {
  return await dponetAPI.delete(
    `data_processes/${dataProcessesId}/destroy_document?document_name=${documentName}`,
    { params },
  )
}

const deleteRncDocument = async ({
  nonComplianceReportId,
  documentName,
  ...params
}) => {
  return await dponetAPI.delete(
    `non_compliance_reports/${nonComplianceReportId}/destroy_document?document_name=${documentName}`,
    { params },
  )
}

const addDocument = async ({ dataProcessId, files }) => {
  var documentFormData = new FormData()

  for (var [key, value] of Object.entries(files)) {
    documentFormData.append(`document-${key}`, value)
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/data_processes/${dataProcessId}/add_document`,
    data: documentFormData,
    ...config,
  })
}

const addDocumentRnc = async ({ nonComplianceReportId, files }) => {
  var documentFormData = new FormData()

  for (var [key, value] of Object.entries(files)) {
    documentFormData.append(`document-${key}`, value)
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/non_compliance_reports/${nonComplianceReportId}/add_document`,
    data: documentFormData,
    ...config,
  })
}

const addQuestionPlanActionEvidency = async ({
  questionPlanActionEvidencyId,
  files,
}) => {
  var documentFormData = new FormData()
  for (var [key, value] of Object.entries(files)) {
    documentFormData.append(`document-${key}`, value)
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/question_plan_action_evidencies/${questionPlanActionEvidencyId}/documents`,
    data: documentFormData,
    ...config,
  })
}

const listQuestionPlanActionEvidency = async ({
  questionPlanActionEvidencyId,
}) => {
  return await dponetAPI.get(
    `question_plan_action_evidencies/${questionPlanActionEvidencyId}/documents`,
  )
}

const deleteQuestionPlanActionEvidency = async ({
  questionPlanActionEvidencyId,
  documentId,
}) => {
  return await dponetAPI.delete(
    `question_plan_action_evidencies/${questionPlanActionEvidencyId}/documents/${documentId}`,
  )
}

const deleteQuestionPlanActionEvidencyLegacy = async ({
  questionPlanActionEvidencyId,
  documentName,
}) => {
  return await dponetAPI.delete(
    `question_plan_action_evidencies/${questionPlanActionEvidencyId}/documents_legacy?document_name=${documentName}`,
  )
}

export default {
  deleteDocument,
  deleteRncDocument,
  addDocument,
  addDocumentRnc,
  addQuestionPlanActionEvidency,
  listQuestionPlanActionEvidency,
  deleteQuestionPlanActionEvidency,
  deleteQuestionPlanActionEvidencyLegacy,
}
