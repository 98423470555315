import { isEmpty } from 'lodash'
const documentType = (company, clean = false) => {
  if (isEmpty(company?.document)) return ''

  if (clean) {
    return company?.document?.match(/\d+/g)?.join('')
  }

  return (
    company?.kind !== 'T' &&
    (company?.kind === 'P'
      ? `CPF: ${company?.document}`
      : `CNPJ: ${company?.document}`)
  )
}

export default documentType
