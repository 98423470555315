import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { Box } from '@material-ui/core'

import { Label, LoadingFeedback, Select } from 'components'

import useFetch from 'hooks/useFetch'
import helpers from 'helpers'

import * as service from 'service'

const AuditForm = ({ control, watch, errors, setCompanyAudts }) => {
  const { response, isLoading } = useFetch(
    service.dponet.companyAudits.get,
    {},
    [],
  )

  const companyAudits = response?.data?.companyAudits

  const companyAuditId = watch('companyAuditId')
  const companyAuditWasSelected = !!watch('companyAuditId')

  const { response: auditsResponse, isLoading: auditsLoading } = useFetch(
    service.dponet.companyAudits.getStats,
    {
      companyAuditId,
    },
    [companyAuditId],
    !!companyAuditId,
  )

  useEffect(() => {
    setCompanyAudts([])

    if (auditsResponse?.data?.companyAudits) {
      setCompanyAudts(auditsResponse?.data?.companyAudits)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditsResponse, setCompanyAudts])

  const formattedAudits =
    companyAudits?.map((item) => ({
      id: item?.id,
      startDate: `Auditoria - ${helpers.formatters.date(item?.startDate)}`,
    })) || []

  return (
    <form>
      <Box display="flex" width="100%" gridGap={6}>
        <LoadingFeedback open={isLoading || auditsLoading} />
        {!isLoading && (
          <Box
            display="flex"
            flexDirection="column"
            width={companyAuditWasSelected ? '60%' : '50%'}
          >
            <Label title="Auditoria" titleVariant="subtitle1">
              <Controller
                control={control}
                name="companyAuditId"
                as={
                  <Select
                    items={formattedAudits || []}
                    fieldName="startDate"
                    error={!!errors.consentFormId}
                    helperText={errors?.consentFormId?.message}
                    label="Selecione o formulário"
                  />
                }
                mode="onChange"
              />
            </Label>
          </Box>
        )}
      </Box>
    </form>
  )
}

export default AuditForm
