import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Box,
  Grid,
  Typography,
  Link,
} from '@material-ui/core'

import { useForm, Controller } from 'react-hook-form'
import { LoadingButton, Label, AttachmentTable } from 'components'

import * as service from 'service'
import * as yup from 'yup'

import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'
import useStyles from './styles'

import helpers from 'helpers'
import constants from 'constants/index'
import { isEmpty } from 'lodash'

const ReopenDialog = ({ setChangeDialog, changeDialog, refresh, riskData }) => {
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState(riskData?.riskEvidencies || [])
  const { user } = useAuth()

  const classes = useStyles()
  const snackbar = useSnackbar()

  const handleReturn = () => setChangeDialog(false)

  const handleChangeInputFile = (event) => {
    const eventFiles = event.target.files
    const attachments = [...files, ...eventFiles]
    setFiles(attachments)
  }

  const handleRemoveFile = async (index) => {
    const attachments = files.filter((_, i) => i !== index)
    const riskEvidencyId = files[index].id

    try {
      setLoading(true)
      await service.dponet.questionControls.destroyRiskEvidency(
        riskData?.id,
        riskEvidencyId,
      )
      setFiles(attachments)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Erro ao excluir arquivo de evidência!',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const isView =
    riskData?.status === constants.questionControl.RISK_ASSUMED_STATUS

  const onSubmit = async (data) => {
    setLoading(true)
    const newFiles = files.filter((file) => !file.id)

    try {
      const formData = helpers.questionControls.mountFormData({
        description: data.description,
        responsibleName: user?.name,
        riskEvidencies: newFiles,
      })

      await service.dponet.questionControls.takeRisk(riskData?.id, formData)

      handleReturn()
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Erro ao habilitar a edição da medida de segurança!',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const { handleSubmit, control, errors } = useForm({
    validationSchema: yup.object().shape({
      description: yup.string().required(),
    }),
    defaultValues: {
      description: riskData?.takeRisk?.description || '',
    },
  })

  return (
    <Dialog fullWidth maxWidth="md" open={changeDialog} onClose={handleReturn}>
      <DialogTitle>
        {riskData?.takeRisk?.accept
          ? 'Detalhe sobre risco assumido'
          : 'Você tem certeza que deseja assumir o risco'}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id="risk-question">
          <Grid container>
            {riskData?.takeRisk?.accept && (
              <>
                <Grid item xs={12}>
                  <Label description="Responsável">
                    <Typography variant="body2">
                      {riskData?.takeRisk?.responsibleName}
                    </Typography>
                  </Label>
                </Grid>
                <Grid item xs={12}>
                  <Label description="Data do aceite">
                    <Typography variant="body2">
                      {' '}
                      {riskData?.takeRisk?.acceptDate}
                    </Typography>
                  </Label>
                </Grid>
              </>
            )}
          </Grid>
          <Label description="Justificativa">
            <Controller
              as={
                <TextField
                  fullWidth
                  rows={6}
                  multiline
                  label=""
                  type="text"
                  helperText={errors?.description?.message}
                  error={errors?.description}
                  disabled={isView}
                />
              }
              name="description"
              control={control}
              mode="onChange"
            />
          </Label>
          <Box pt={3}>
            <Typography variant="body1">
              Para anexar arquivos,&nbsp;
              <Link
                href="#"
                htmlFor="input-file"
                component="label"
                className={classes.link}
              >
                Clique aqui.
              </Link>
            </Typography>
            <Typography variant="caption">
              Arquivos suportados: xlsx, xls, imagens, doc, docx, ppt, pptx,
              txt, pdf
            </Typography>
            <input
              multiple
              type="file"
              id="input-file"
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              className={classes.inputFile}
              onChange={handleChangeInputFile}
            />
          </Box>
          {!isEmpty(files) && (
            <Grid item xs={12}>
              <AttachmentTable
                attachments={files}
                handleDelete={handleRemoveFile}
                openWrite={riskData?.openWrite}
                withLink
              />
            </Grid>
          )}
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <Button autoFocus onClick={handleReturn}>
            Voltar
          </Button>
          {!isView && (
            <LoadingButton
              loading={loading}
              text="Confirmar"
              autoFocus
              type="submit"
              form="risk-question"
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ReopenDialog
