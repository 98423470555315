import React, { useState } from 'react'
import { Users as UsersIcon } from 'react-feather'
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

import {
  DepartmentsForm,
  DepartmentUsersTable,
  DialogAddUser,
} from './components'
import { ContentHeader, LoadingFeedback, Page, Permitted } from 'components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'
import useStyles from './styles'
import theme from 'theme'

const DepartmentsEdit = ({ match }) => {
  const { departmentId } = match.params
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const classes = useStyles()

  const { response, isLoading, refresh } = useFetch(
    service.dponet.departments.get,
    { departmentId },
    [departmentId],
  )

  const {
    response: usersResponse,
    isLoading: userIsLoading,
    refresh: availableUsersRefresh,
  } = useFetch(
    service.dponet.departments.availableUsers,
    {
      departmentId,
    },
    [departmentId],
  )

  const department = response?.data?.department
  const availableUsers = usersResponse?.data?.users

  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  return (
    <Page title="Editar departamento">
      <LoadingFeedback open={loading || isLoading || userIsLoading} />
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader
          menu="Gerenciamento"
          title="Departamentos"
          subtitle="Editar Departamento"
        />
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(3)}>
          {!isLoading && (
            <DepartmentsForm
              department={department}
              setLoading={setLoading}
              refresh={refresh}
              formType="edit"
            />
          )}
          <Box>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-users-content"
                id="panel-users-header"
              >
                <Box
                  display="flex"
                  gridGap={theme.spacing(2)}
                  alignItems="center"
                >
                  <UsersIcon />
                  <Typography variant="h4">Responsáveis</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Grid container>
                  {!isLoading && (
                    <DepartmentUsersTable
                      departmentId={departmentId}
                      loading={loading}
                      refresh={refresh}
                      availableUsersRefresh={availableUsersRefresh}
                      setLoading={setLoading}
                      users={department?.users}
                    />
                  )}
                </Grid>
                <Permitted tag="link_users_to_department">
                  <Grid container display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenModal}
                    >
                      Adicionar
                    </Button>
                  </Grid>
                </Permitted>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        {!userIsLoading && (
          <DialogAddUser
            availableUsers={availableUsers}
            availableUsersRefresh={availableUsersRefresh}
            departmentId={departmentId}
            open={openModal}
            refresh={refresh}
            setLoading={setLoading}
            handleClose={handleCloseModal}
          />
        )}
      </Container>
    </Page>
  )
}

export default DepartmentsEdit
