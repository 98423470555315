import palette from '../palette'

const colors = {
  backgroundStatuses: {
    pending: palette.azure,
    waitingReview: palette.yellowOrange,
    reproved: palette.darkRed,
    approved: palette.greenPantone,
    inactive: palette.spanishGray,
    autoReview: palette.teal,
    reviewing: palette.blueViolet,
    total: palette.veryDarkGrayishBlue,
  },
  backgroundFragilities: {
    low: palette.azure,
    medium: palette.greenPantone,
    high: palette.yellowOrange,
    severe: palette.darkRed,
    notCalculated: palette.spanishGray,
  },
}

export default colors
