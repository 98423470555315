import { colors } from '@material-ui/core'

const PENDING_STATUS_ID = 'pending'
const FINALIZED_STATUS_ID = 'finalized'
const CONVERTED_INCIDENT_STATUS_ID = 'converted_incident'

const PENDING_STATUS_LABEL = 'Pendente'
const FINALIZED_STATUS_LABEL = 'Finalizado'
const CONVERTED_INCIDENT_STATUS_LABEL = 'Convertido em incidente'

const COMPANY_RELATION_ANSWER_KIND = 'company_relation'
const REPORT_CLASSIFICATION_ANSWER_KIND = 'report_classification'
const DESCRIPTION_ANSWER_KIND = 'description'

const DPO_MESSAGE_KIND = 'dpo'
const AUTOMATIC_MESSAGE_KIND = 'automatic'

const ALL_STATUS_ID = {
  PENDING_STATUS_ID,
  FINALIZED_STATUS_ID,
  CONVERTED_INCIDENT_STATUS_ID,
}

const ALL_STATUS_LABEL = {
  PENDING_STATUS_LABEL,
  FINALIZED_STATUS_LABEL,
  CONVERTED_INCIDENT_STATUS_LABEL,
}

const ALL_MESSAGE_KINDS = {
  DPO_MESSAGE_KIND,
  AUTOMATIC_MESSAGE_KIND,
}

const STATUS_LABEL = {
  [PENDING_STATUS_ID]: PENDING_STATUS_LABEL,
  [FINALIZED_STATUS_ID]: FINALIZED_STATUS_LABEL,
  [CONVERTED_INCIDENT_STATUS_ID]: CONVERTED_INCIDENT_STATUS_LABEL,
}

const BOX_MESSAGE_COLOR = {
  [DPO_MESSAGE_KIND]: colors.blue[50],
  [AUTOMATIC_MESSAGE_KIND]: colors.grey[200],
}

const LIST_OF_CATEGORIES = [
  'Comportamento Ético',
  'Condições de Trabalho',
  'Desrespeito a Normas e Regulamentos',
  'Desvios de Conduta',
  'Direitos do Consumidor',
  'Discriminação e Assédio',
  'Privacidade e Proteção de Dados',
  'Saúde e Meio Ambiente',
  'Violação de Direitos Humanos',
  'Violência e Segurança',
  'Elogio',
  'Sugestões',
  'Outros',
]

export default {
  STATUS_LABEL,
  ALL_STATUS_ID,
  ALL_STATUS_LABEL,
  COMPANY_RELATION_ANSWER_KIND,
  REPORT_CLASSIFICATION_ANSWER_KIND,
  DESCRIPTION_ANSWER_KIND,
  LIST_OF_CATEGORIES,
  DPO_MESSAGE_KIND,
  AUTOMATIC_MESSAGE_KIND,
  ALL_MESSAGE_KINDS,
  BOX_MESSAGE_COLOR,
}
