import React, { useState, useRef } from 'react'

import SubscriptionContext from 'contexts/SubscriptionContext'

import constants from 'constants/index'

const CheckoutPaymentProvider = ({ children }) => {
  const [recaptcha, setRecaptcha] = useState(false)

  const [hideTopbarResume, setHideTopbarResume] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(false)
  const [payloadPayment, setPayloadPayment] = useState({})
  const [activeSubStep, setActiveSubStep] = useState(
    constants.preRegistrations.FIRST_STEP[1],
  )
  const [paymentData, setPaymentData] = useState({})
  const recaptchaRef = useRef()

  const toNextSubStep = () => {
    setActiveSubStep(activeSubStep + 1)
  }

  const resetRecaptcha = () => {
    if (!!recaptchaRef?.current) {
      recaptchaRef.current.reset()
      setRecaptcha(undefined)
    }
  }

  return (
    <SubscriptionContext.Provider
      value={{
        activeSubStep,
        isLoading,
        payloadPayment,
        paymentData,
        recaptcha,
        recaptchaRef,
        hideTopbarResume,
        status,
        setStatus,
        resetRecaptcha,
        setHideTopbarResume,
        setActiveSubStep,
        setIsLoading,
        setPayloadPayment,
        setPaymentData,
        setRecaptcha,
        toNextSubStep,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}

export default CheckoutPaymentProvider
