import React from 'react'

import { Box } from '@material-ui/core'

import { Text } from '../'
import { InfoChip } from 'components'

const FirstPage = () => {
  return (
    <>
      <Text color="primary" fontSize="7vh" variant="h1">
        Crie seu canal de comunicação
      </Text>
      <Box my={6}>
        <Text fontSize="3.5vh" variant="h4">
          Nesta jornada, vamos orientá-lo na configuração do seu canal de
          comunicação, que é o meio pelo qual a Autoridade Nacional de Proteção
          de Dados (ANPD) e os titulares de dados podem fazer solicitações.
        </Text>
      </Box>
      <Box width="100%" display="flex" justifyContent="center" my={2}>
        <InfoChip label="Apenas 10 minutos" />
      </Box>
    </>
  )
}

export default FirstPage
