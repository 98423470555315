import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  contentHeaderBox: {
    display: 'flex',
    alignItems: 'center',
    gridGap: theme.spacing(1),
  },
  contentHeaderBoxSm: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
    },
  },
  contentHeaderBoxXs: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
    },
  },
  dividerContainer: {
    paddingBlock: 5,
    paddingInline: 10,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    textAlign: 'justify',
  },
  customPopover: {
    maxWidth: 500,
  },
}))

export default styles
