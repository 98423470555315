import React, { useRef, useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { size } from 'lodash'

import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'

import theme from 'theme'
import helpers from 'helpers'

const DonutChart = ({
  names,
  values,
  colors,
  title,
  legendShow = true,
  legendPosition = 'bottom',
  heightValue = 360,
  showLabels = true,
  id = '6wAcn',
  fixedTitle = false,
  scale = 0.8,
  trimChartLegend = true,
  legendColor = theme.palette.dashboard.chart.label,
  valueColor = theme.palette.common.black,
  showName = true,
}) => {
  const ref = useRef(null)
  const [maxLegendCharacters, setMaxLegendCharacters] = useState(350)

  useEffect(() => {
    setMaxLegendCharacters(ref.current?.offsetWidth)
    //eslint-disable-next-line
  }, [ref.current])

  const options = {
    chart: {
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
        xaxis: {
          borderWidth: 2,
          borderColor: theme.palette.common.black,
        },
      },
      foreColor: legendColor,
      fontFamily: 'Roboto',
      id: id,
      toolbar: {
        show: false,
      },
      type: 'donut',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          background: 'transparent',
        },
        dataLabels: {
          textAnchor: 'center',
          name: {},
          value: {},
          total: {},
        },
      },
      pie: {
        customScale: scale,
        donut: {
          size: showLabels ? 80 : 88,
          labels: {
            show: true,
            name: {
              show: showName,
              hover: {
                color: theme.palette.white,
              },
              color: legendColor,
              fontSize: 12,
              formatter: function (val) {
                return fixedTitle ? title : val
              },
            },
            value: {
              fontSize: 22,
              fontWeight: 900,
              color: valueColor,
              offsetY: 10,
            },
            total: {
              show: true,
              fontSize: 16,
              fontWeight: 500,
              color: theme.palette.mainMenu,
              label: trimChartLegend
                ? helpers.myLgpd.trimChartLegend(title, maxLegendCharacters)
                : title,
              formatter: function (w) {
                return w.globals.seriesTotals
                  .reduce((a, b) => a + b, 0)
                  .toFixed(0)
              },
            },
          },
        },
      },
    },
    colors: colors,
    dataLabels: {
      enabled: showLabels,
      style: {
        fontSize: 15,
        fontWeight: 500,
      },
      formatter: (_, opts) => {
        return opts.w.config.series[opts.seriesIndex]
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      padding: {
        right: 0,
        bottom: 12,
        left: 10,
      },
    },
    labels: names,
    legend: {
      show: legendShow,
      position: maxLegendCharacters < 300 ? 'bottom' : legendPosition,
      fontSize: 11,
      fontWeight: 500,
      showForZeroSeries: true,
      offsetY:
        legendPosition !== 'bottom' && maxLegendCharacters >= 300
          ? (heightValue * 0.5) / size(values)
          : -6,
      itemMargin: {
        vertical: 4,
      },
      formatter: (value) =>
        trimChartLegend
          ? helpers.myLgpd.trimChartLegend(value, maxLegendCharacters)
          : value,
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
    },
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'round',
      colors: undefined,
      width: 1,
      dashArray: 1,
    },
    xaxis: {
      labels: {
        trim: true,
        style: {},
      },
      title: {
        style: {
          fontWeight: 700,
        },
      },
    },
    yaxis: {
      labels: {
        style: {},
      },
      title: {
        style: {
          fontWeight: 700,
        },
      },
    },
  }

  const series = values

  return (
    <Box width="100%" ref={ref}>
      <Chart
        ref={ref}
        options={options}
        series={series}
        type="donut"
        height={heightValue}
      />
    </Box>
  )
}

DonutChart.propTypes = {
  names: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  colors: PropTypes.array,
  title: PropTypes.string.isRequired,
  legendShow: PropTypes.bool,
  heightValue: PropTypes.number,
  legendPosition: PropTypes.string,
  showLabels: PropTypes.bool,
  id: PropTypes.string,
  fixedTitle: PropTypes.bool,
  scale: PropTypes.number,
  trimChartLegend: PropTypes.bool,
  legendColor: PropTypes.string,
  valueColor: PropTypes.string,
}

export default DonutChart
