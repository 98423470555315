import React from 'react'

import { BaseView } from './components'

import useFetch from 'hooks/useFetch'
import * as service from 'service'

const QuestionControlsEdit = ({ match }) => {
  const {
    params: { questionControlId },
  } = match

  const { response, isLoading, refresh } = useFetch(
    service.dponet.questionControls.show,
    { questionControlId },
    [],
  )

  const { response: responseLogs, refresh: refreshLogs } = useFetch(
    service.dponet.questionControls.logs,
    { questionControlId },
    [],
  )

  return (
    <BaseView
      isEdit
      questionControl={response?.data?.questionControl}
      isLoading={isLoading}
      refresh={refresh}
      logs={responseLogs?.data?.dponetAudits}
      refreshLogs={refreshLogs}
    />
  )
}

export default QuestionControlsEdit
