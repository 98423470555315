import React, { useMemo } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'

import DataProcessTemplateQuestionContext from 'contexts/DataProcessTemplateQuestionContext'

const DataProcessTemplateQuestionProvider = ({
  dataProcessTemplate,
  dataProcessTemplateAnswers = [],
  step,
  children,
}) => {
  const questions = useMemo(() => {
    return (
      dataProcessTemplate?.dataProcessTemplateQuestions?.filter((question) => {
        return question.step === step
      }) || []
    )
  }, [dataProcessTemplate?.dataProcessTemplateQuestions, step])

  const mountDefaultValues = (questions = []) => {
    const defaultValues = {}

    questions.forEach((question) => {
      const dataProcessTemplateAnswer = findDataProcessTemplateAnswer(
        question.id,
      )

      var value = null

      if (question.fieldType === 'multi_select') {
        value = !isEmpty(dataProcessTemplateAnswer)
          ? dataProcessTemplateAnswer?.answer?.map((answer) => ({
              id: answer.id,
              name: answer.option,
            }))
          : []
      }
      if (question.fieldType === 'single_select') {
        value = !isEmpty(dataProcessTemplateAnswer)
          ? dataProcessTemplateAnswer?.answer?.id
          : ''
      }
      if (question.fieldType === 'text') {
        value = !isEmpty(dataProcessTemplateAnswer)
          ? dataProcessTemplateAnswer?.answer
          : ''
      }

      defaultValues[`question-${question.id}`] = value
    })

    return defaultValues
  }

  const findDataProcessTemplateAnswer = (questionId) => {
    return dataProcessTemplateAnswers.find(
      (dataProcessTemplateAnswer) =>
        dataProcessTemplateAnswer.dataProcessTemplateQuestionId === questionId,
    )
  }

  const schema = (questions) => {
    const schemaObject = {}

    questions.forEach((question) => {
      const questionName = `question-${question.id}`

      if (question.fieldType === 'multi_select') {
        if (question.requiredField) {
          return (schemaObject[questionName] = yup.array().min(1).required())
        }

        return (schemaObject[questionName] = yup.array().nullable())
      }

      if (question.requiredField) {
        return (schemaObject[questionName] = yup.string().required())
      }

      schemaObject[questionName] = yup.string()
    })

    return yup.object().shape(schemaObject)
  }

  const {
    control,
    watch,
    errors,
    triggerValidation,
    getValues: getFormValues,
  } = useForm({
    defaultValues: mountDefaultValues(questions),
    validationSchema: schema(questions),
  })

  const getValues = () => {
    const values = getFormValues()

    const keys = Object.keys(values)

    return keys.map((key) => {
      const questionId = key.replace('question-', '')

      return {
        id: questionId,
        answer: values[key],
      }
    })
  }

  return (
    <DataProcessTemplateQuestionContext.Provider
      value={{
        dataProcessTemplateAnswers,
        control,
        watch,
        errors,
        step,
        questions,
        triggerValidation,
        getValues,
        getFormValues,
      }}
    >
      {children}
    </DataProcessTemplateQuestionContext.Provider>
  )
}

export default DataProcessTemplateQuestionProvider
