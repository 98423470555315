import React from 'react'
import clsx from 'clsx'
import { sum } from 'lodash'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import { DonutChart } from 'components'

import palette from 'theme/palette'
import useStyles from './styles'

import constants from 'constants/index'
import theme from 'theme'

const QuestionChart = ({ gapStats }) => {
  const { gap } = constants.myLgpd.GRAPH_IDS

  const classes = useStyles()

  const totalAnswered = sum(
    gapStats?.questionnaires?.map(
      (questionnaire) => questionnaire?.asweredQuestions || 0,
    ),
  )
  const totalQuestion = sum(
    gapStats?.questionnaires?.map(
      (questionnaire) => questionnaire?.totalQuestions || 0,
    ),
  )

  const pendingInquiries = totalQuestion - totalAnswered

  return (
    <Box className={clsx(classes.pieChartBox, 'borderless-donut-box')}>
      <DonutChart
        id={gap.pieChart}
        names={['Respondidas', 'Não respondidas']}
        values={[totalAnswered, pendingInquiries]}
        colors={[
          palette.dashboard.chart.primary.approved,
          palette.dashboard.chart.primary.disapproved,
        ]}
        title="Questões"
        heightValue={320}
        scale={0.9}
        trimChartLegend={false}
        legendColor={theme.palette.common.white}
        valueColor={theme.palette.common.white}
      />
    </Box>
  )
}

QuestionChart.propTypes = {
  gapStats: PropTypes.object.isRequired,
}

export default QuestionChart
