import React from 'react'
import { Step, StepLabel, Stepper, useMediaQuery } from '@material-ui/core'

import useIncidentManegement from 'hooks/useIncidentManegement'

import useStyles from './styles'
import theme from 'theme'

const IncidentStepper = ({ type }) => {
  const { activeStep, handleStep, isCompleted, incident } =
    useIncidentManegement()
  const classes = useStyles()
  const matches = useMediaQuery(theme.breakpoints.up('lg'))

  const steps = () => {
    const defaultSteps = [
      'Detecção',
      'Investigação',
      'Avaliação',
      'Lições Aprendidas',
    ]

    if (type === 'edit' && incident?.confirmed) {
      defaultSteps.splice(3, 0, 'Detalhamento', 'Tratamento', 'Comunicação')
    }

    return defaultSteps
  }

  return (
    <Stepper
      activeStep={activeStep}
      orientation={matches ? 'horizontal' : 'vertical'}
    >
      {steps().map((step, index) => (
        <Step
          key={step}
          onClick={() => handleStep(index)}
          completed={isCompleted(index)}
          className={classes.stepper}
        >
          <StepLabel>{step}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default IncidentStepper
