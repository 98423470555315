import React, { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { PlusCircle } from 'react-feather'
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  Button,
  Tooltip,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'
import { MenuButton, LoadingBox } from 'components'
import DialogDataTreatment from '../../DialogDataTreatment'
import DialogRemoveDataTreatment from '../../DialogRemoveDataTreatment'
import DialogConfirmDataTreatmentNul from '../../DialogConfirmDataTreatmentNul'
import { DescriptionPaper } from '../../'

import DataProcessTemplateQuestionProvider from 'providers/DataProcessTemplateQuestionProvider'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'
import useDataProcess from 'hooks/useDataProcess'
import useSelectCountry from 'hooks/useSelectCountry'

import styles from '../styles'
import * as service from 'service'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const DataTreatments = ({ description }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { data, nextStep, toNextStep, setToNextStep, dataProcessTemplate } =
    useDataProcess()
  const { dataProcess } = data
  const [dialogDataTreatment, setDialogDataTreatment] = useState(false)
  const [dialogRemoveDataTreatment, setDialogRemoveDataTreatment] =
    useState(false)
  const [editionDataTreatment, setEditionDataTreatment] = useState(false)
  const [removableDataTreatmentId, setRemovableDataTreatmentId] = useState()
  const [loading, setLoading] = useState(false)

  const { setSelectedCountries, setManySelectedCountries } = useSelectCountry()

  const { isLoading, refresh, response } = useFetch(
    service.dponet.dataTreatments.get,
    {
      dataProcessId: dataProcess.id,
    },
  )

  const [
    openDialogConfirmDataTreatmentNul,
    setOpenDialogConfirmDataTreatmentNul,
  ] = useState(false)

  const dialogConfirmNextStep = () => {
    setOpenDialogConfirmDataTreatmentNul(true)
  }

  const verifyDataTreatment = () => {
    return response?.data?.dataTreatments.length > 0
  }

  const handleNextStep = () => {
    nextStep()
    setToNextStep()
  }

  useEffect(() => {
    if (toNextStep) {
      if (!isLoading) {
        if (verifyDataTreatment()) {
          nextStep()
          setToNextStep(false)
        } else {
          dialogConfirmNextStep()
        }
        setToNextStep(false)
      }
    }
    // eslint-disable-next-line
  }, [nextStep])

  const handleCreate = () => {
    setSelectedCountries([])
    setManySelectedCountries({})
    setEditionDataTreatment(null)
    setDialogDataTreatment(true)
  }

  const handleEdit = (dataTreatment) => {
    setEditionDataTreatment(dataTreatment)
    setSelectedCountries(dataTreatment?.internationalTransfers)
    setDialogDataTreatment(true)
  }

  const handleDelete = async (dataProcessId, dataTreatmentId) => {
    try {
      setLoading(true)

      await service.dponet.dataTreatments.destroy({
        dataProcessId,
        dataTreatmentId,
      })
      setSelectedCountries([])
      setManySelectedCountries({})
      setDialogRemoveDataTreatment(false)
      setLoading(false)
      snackbar.open({
        message: 'Compartilhamento de dados removido com sucesso!',
        variant: 'success',
      })

      refresh()
    } catch (err) {
      setLoading(false)
      snackbar.open({
        message:
          'Não foi possível remover o compartilhamento de dados. Tente novamente!',
        variant: 'error',
      })
    }
  }

  const handleOpenRemoveDialog = (dataTreatment) => {
    setRemovableDataTreatmentId(dataTreatment.id)
    setDialogRemoveDataTreatment(true)
  }

  return (
    <>
      {isLoading || loading ? (
        <LoadingBox />
      ) : (
        <Box id={constants.dataProcess.DATA_PROCESS_NEW_DRIVER_STEP_6}>
          <DescriptionPaper description={description}>
            <Button
              variant="outlined"
              startIcon={<PlusCircle size={16} />}
              onClick={() => handleCreate()}
            >
              Adicionar
            </Button>
          </DescriptionPaper>
          <Grid container className={classes.root}>
            <Grid xs={12} item>
              <Box maxHeight="82%">
                <PerfectScrollbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="20%">
                          Com quem é compartilhado
                        </TableCell>
                        <TableCell width="25%">
                          Porque é compartilhado
                        </TableCell>
                        <TableCell width="25%">Como é compartilhado</TableCell>
                        <TableCell width="20%">
                          Tipo de compartilhamento
                        </TableCell>
                        <TableCell width="10%" align="right">
                          Ações
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {response?.data?.dataTreatments?.map((dataTreatment) => (
                        <TableRow key={dataTreatment?.id}>
                          <TableCell>
                            <Tooltip title={dataTreatment?.shareProcess?.name}>
                              <Typography className={classes.ellipsisTable}>
                                {dataTreatment?.shareProcess?.name}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip title={dataTreatment.description}>
                              <Typography className={classes.ellipsisTable}>
                                {dataTreatment?.description}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={dataTreatment?.controllMechanismView}
                            >
                              <Typography className={classes.ellipsisTable}>
                                {dataTreatment?.controllMechanismView}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip title={dataTreatment?.shareType?.name}>
                              <Typography className={classes.ellipsisTable}>
                                {dataTreatment?.shareType?.name}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" justifyContent="flex-end">
                              <MenuButton>
                                <Button
                                  color="secondary"
                                  fullWidth
                                  onClick={() => handleEdit(dataTreatment)}
                                >
                                  Editar
                                </Button>
                                <Button
                                  color="secondary"
                                  fullWidth
                                  onClick={() =>
                                    handleOpenRemoveDialog(dataTreatment)
                                  }
                                >
                                  Excluir
                                </Button>
                              </MenuButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </PerfectScrollbar>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {dialogDataTreatment && (
        <DataProcessTemplateQuestionProvider
          dataProcessTemplate={dataProcessTemplate}
          step="data_treatment"
          dataProcessTemplateAnswers={
            editionDataTreatment?.dataProcessTemplateAnswers
          }
        >
          <DialogDataTreatment
            dataProcessId={dataProcess.id}
            open={dialogDataTreatment}
            setOpen={setDialogDataTreatment}
            setLoading={setLoading}
            onEvent={refresh}
            dataTreatment={editionDataTreatment}
          />
        </DataProcessTemplateQuestionProvider>
      )}
      <DialogRemoveDataTreatment
        open={dialogRemoveDataTreatment}
        setOpen={setDialogRemoveDataTreatment}
        dataProcessId={dataProcess.id}
        dataTreatmentId={removableDataTreatmentId}
        handleRemove={handleDelete}
      />
      <DialogConfirmDataTreatmentNul
        open={openDialogConfirmDataTreatmentNul}
        setOpen={setOpenDialogConfirmDataTreatmentNul}
        handleNextStep={handleNextStep}
      />
    </>
  )
}

export default DataTreatments
