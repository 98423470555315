import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { Box, Grid, TextField, Typography } from '@material-ui/core'

import { LoadingFeedback, TermsToPolicy } from 'components'
import { BaseAccessData } from './components'

import useSubscription from 'hooks/useSubscription'

import schema from './schema'
import helpers from 'helpers'
import useStyles from './styles'

import { TOKEN_PRE_REGISTRATION } from 'service/constants'

const FormAccessData = ({ isDemoSubscription }) => {
  const classes = useStyles()

  const {
    data,
    plans,
    responsibleData,
    setCoupon,
    setPolicyAccept,
    setResponsibleData,
    setSelectedPlan,
    setTermsAccept,
    setValidForm,
    toNextStep,
  } = useSubscription()

  const [isLoading, setIsLoading] = useState(false)

  const formDefaultValues = {
    responsibleName: responsibleData?.name || data?.responsibleUser?.name || '',
    responsibleEmail:
      responsibleData?.email || data?.responsibleUser?.email || '',
    responsiblePhone:
      responsibleData?.phone || data?.responsibleUser?.phone || '',
  }

  const formMethods = useForm({
    reValidateMode: 'onChange',
    validationSchema: schema,
    defaultValues: { ...formDefaultValues },
  })

  const { handleSubmit, errors, control, setValue } = formMethods

  const handleSubmitAction = (values) => {
    setResponsibleData({
      name: values?.responsibleName,
      phone: values?.responsiblePhone,
      email: values?.responsibleEmail,
    })
    setValidForm(true)
    toNextStep()
  }

  useEffect(() => {
    if (isEmpty(plans)) {
      setSelectedPlan(data?.plan)
    } else {
      setSelectedPlan(data?.plan || plans[0])
    }

    setCoupon(data?.coupon || data?.discountCoupon)
    setIsLoading(!!localStorage.getItem(TOKEN_PRE_REGISTRATION) && !data)
    setValue(
      'responsibleName',
      responsibleData?.name || data?.responsibleUser?.name || '',
    )
    setValue(
      'responsibleEmail',
      responsibleData?.email || data?.responsibleUser?.email || '',
    )
    setValue(
      'responsiblePhone',
      helpers.formatters.phone(
        responsibleData?.phone || data?.responsibleUser?.phone || '',
        true,
      ),
    )
    // eslint-disable-next-line
  }, [data])

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <BaseAccessData
        onSubmit={handleSubmit(handleSubmitAction)}
        isDemoSubscription={isDemoSubscription}
      >
        <Box mb={2.5}>
          <Typography variant="h5">Dados do representante</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              as={
                <TextField
                  required
                  fullWidth
                  error={!!errors.responsibleName}
                  helperText={errors?.responsibleName?.message}
                  label="Nome do Representante"
                />
              }
              control={control}
              name="responsibleName"
              mode="onChange"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={
                <TextField
                  required
                  fullWidth
                  error={!!errors.responsibleEmail}
                  helperText={errors?.responsibleEmail?.message}
                  FormHelperTextProps={{
                    classes: { root: classes.helperTextFontSize },
                  }}
                  label="E-mail do Representante"
                />
              }
              control={control}
              name="responsibleEmail"
              mode="onChange"
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.telephoneGridItem}>
            <Controller
              as={
                <TextField
                  required
                  fullWidth
                  error={!!errors.responsiblePhone}
                  helperText={errors?.responsiblePhone?.message}
                  label="Telefone do Representante"
                />
              }
              onChange={([event]) => {
                return helpers.formatters.phone(event.target.value, true)
              }}
              control={control}
              name="responsiblePhone"
              mode="onChange"
            />
          </Grid>
          {!isDemoSubscription && (
            <Grid item xs={12} className={classes.termsToPolicy}>
              <TermsToPolicy
                setPolicyAccept={setPolicyAccept}
                setTermsAccept={setTermsAccept}
              />
            </Grid>
          )}
        </Grid>
      </BaseAccessData>
    </>
  )
}

FormAccessData.propTypes = {
  isDemoSubscription: PropTypes.bool,
  demoFormMethods: PropTypes.any,
}

FormAccessData.defaultProps = {
  isDemoSubscription: null,
  demoFormMethods: {},
}

export default FormAccessData
