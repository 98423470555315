import React from 'react'
import { Grid, Container, Box, useMediaQuery, Hidden } from '@material-ui/core'
import { LoadingFeedback, Page } from 'components'
import { Text } from 'views/UserSteps/components'

import hexagonos from 'images/hexagonos-certificado.png'
import dponetLogo from 'images/dponet-logo-white.svg'

import * as service from 'service'

import useStyles from './styles'
import helpers from 'helpers'
import theme from 'theme'
import useFetch from 'hooks/useFetch'

const ValidateReport = ({ match }) => {
  const { token } = match.params
  const classes = useStyles()

  const { response, isLoading } = useFetch(service.dponet.reports.get, {
    token: token,
  })

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  const report = response?.data?.logReport

  return (
    <Page title="Consultar Relatório" transparent noPadding>
      <Container maxWidth="lg" className={classes.container}>
        <LoadingFeedback open={isLoading} />
        <Hidden mdUp>
          <Box py={2} display="flex" justifyContent="center" width="100%">
            <img
              src={dponetLogo}
              alt="logo-dponet"
              width={isMobile ? '50%' : '35%'}
            />
          </Box>
        </Hidden>
        <Grid container className={classes.gridContainer} alignItems="stretch">
          <Grid item sm={12} md={6} lg={5}>
            <Box className={classes.imgBox}>
              <img
                src={hexagonos}
                alt="hexagonos"
                width={isMobile ? '50%' : '400px'}
                className={classes.hexagonosImg}
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={7}>
            <Box className={classes.dataBox}>
              <Hidden smDown>
                <img
                  src={dponetLogo}
                  alt="logo-dponet"
                  width={isMobile ? '50%' : '35%'}
                />
              </Hidden>
              <Box className={classes.boxReportData} mt={4}>
                <Text fontSize={35} variant="h2">
                  Este relatório é válido
                </Text>
                <Text fontSize={16} variant="subtitle1">
                  Empresa {report?.companyName}
                </Text>
                <Text fontSize={16} variant="subtitle1">
                  Documento: {report?.companyDocument}
                </Text>
                <Text fontSize={16} variant="subtitle1">
                  Emitido em: {helpers.formatters.date(report?.emissionDate)}
                </Text>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default ValidateReport
