import React from 'react'
import { List, ListItem, ListItemText } from '@material-ui/core'

import icon from 'images/check-circle.svg'
import useStyle from './styles'

const ListAdequacy = () => {
  const classes = useStyle()

  const listMap = [
    'Você sabe o que fazer em um incidente de segurança de dados?',
    'Seus colaboradores estão conscientes sobre privacidade de dados?',
    'Seus contratos contêm cláusulas de responsabilidade de proteção de dados pessoais?',
    'Está realizando adequação utilizando planilhas e está sem tempo para atividades estratégicas?',
    'Comprovada por milhares de empresas, a DPOnet pode ser a sua parceira ideal.',
  ]

  return (
    <List className={classes.groupList}>
      {listMap.map((item) => (
        <ListItem className={classes.listChecks}>
          <img
            src={icon}
            width="20px"
            alt="bulletpoint da lista"
            aria-hidden="true"
          />
          <ListItemText primary={item} className={classes.checks} />
        </ListItem>
      ))}
    </List>
  )
}

export default ListAdequacy
