import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
  },
}))

export default styles
