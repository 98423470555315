import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  floatingButtons: {
    padding: '0px 20px 20px 0px',
    bottom: 0,
    right: 0,
    position: 'fixed',
  },
}))

export default styles
