import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Box,
  Typography,
  FormGroup,
  TextField,
} from '@material-ui/core'

import helpers from 'helpers'

import { Controller } from 'react-hook-form'
import usePermissions from 'hooks/usePermissions'

const OptionCheckIncident = ({
  name,
  control,
  setValue,
  label,
  options,
  other,
  checkedOptions,
  watch,
  paddingX,
  variantLabel,
  literaName,
}) => {
  const { isDpo } = usePermissions()
  const displayedOther = helpers.incidents.displayedOther(checkedOptions, name)
  const nameOther = `${name}Other`

  const [selectedItems, setSelectedItems] = useState([])
  const [displayFieldOther, setDisplayFieldOther] = useState(displayedOther)

  const handleSelect = (option) => {
    if (selectedItems.map((item) => item?.keyName).includes(option?.keyName)) {
      const removeItem = selectedItems.filter(
        (item) => item?.keyName !== option?.keyName,
      )
      setSelectedItems(removeItem)
    } else {
      setSelectedItems((prevItems) => [...prevItems, option])
    }
  }

  useEffect(() => {
    if (checkedOptions) {
      setSelectedItems(checkedOptions)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setValue(name, selectedItems)
    // eslint-disable-next-line
  }, [name, selectedItems, setValue])

  useEffect(() => {
    if (!displayFieldOther) {
      if (isEmpty(selectedItems)) return

      const removeItem = selectedItems.filter(
        (item) => item?.keyName !== 'other',
      )

      setValue(name, removeItem)
    }

    if (displayFieldOther) {
      const optioWithOther = helpers.incidents.mountOption(
        selectedItems,
        watch(nameOther),
        literaName,
      )
      setValue(name, optioWithOther)
    }
    // eslint-disable-next-line
  }, [displayFieldOther, watch(nameOther)])

  const isChecked = (option) => {
    return selectedItems.map((item) => item?.keyName).includes(option?.keyName)
  }
  return (
    <>
      <Grid xs={12} item>
        {options && (
          <Box mt={2.5}>
            <FormControl size="small" variant="outlined">
              <Box px={paddingX ? 2 : 0}>
                <Typography variant={variantLabel || 'h5'}>{label}</Typography>
              </Box>
              <FormGroup>
                {options?.map((option) => {
                  return (
                    <Box px={paddingX ? 2 : 0} key={option?.keyName}>
                      <FormControlLabel
                        control={
                          <Controller
                            name={name}
                            onChange={() => handleSelect(option)}
                            as={<Checkbox checked={isChecked(option)} />}
                            control={control}
                            disabled={isDpo()}
                          />
                        }
                        label={option?.name}
                      />
                    </Box>
                  )
                })}
                {other && (
                  <Box px={paddingX ? 2 : 0}>
                    <FormControlLabel
                      label="Outras"
                      control={
                        <Checkbox
                          name="other"
                          disabled={isDpo()}
                          checked={displayFieldOther === true}
                          onChange={(e) =>
                            setDisplayFieldOther(e.target.checked)
                          }
                        />
                      }
                    />
                  </Box>
                )}
              </FormGroup>
            </FormControl>
          </Box>
        )}
      </Grid>
      {other && displayFieldOther && (
        <Grid item xs={12}>
          <Box px={paddingX ? 2 : 0}>
            <Box px={2} py={2} style={{ backgroundColor: '#EEE' }}>
              <Controller
                as={
                  <TextField
                    label="Outras"
                    type="text"
                    rows={5}
                    multiline
                    color="primary"
                    variant="outlined"
                    fullWidth
                  />
                }
                disabled={isDpo()}
                control={control}
                name={nameOther}
                mode="onBlur"
              />
            </Box>
          </Box>
        </Grid>
      )}
    </>
  )
}

export default OptionCheckIncident
