import React, { useState } from 'react'
import { Box, Button, Link, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { BaseDialog } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import * as service from 'service'

import useStyles from './styles'

const ConfirmLinkWithTitular = ({ open, setOpen, ticket = {}, refresh }) => {
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()
  const classes = useStyles()

  const confirmIfHasTitularData = async (response) => {
    try {
      setLoading(true)
      await service.dponet.tickets.confirmTitularData({
        ticketId: ticket.id,
        hasTitularData: response,
      })
      snackbar.open({
        message: `Confirmado com sucesso que a empresa ${
          response ? 'possui' : 'não possui'
        } os dados do titular `,
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setOpen(false)
      setLoading(false)
    }
  }

  const questionDemand = ticket?.info?.questionDemand
  const specificField = questionDemand?.answer?.specificField
  const subOption = questionDemand?.answer?.subOption

  const redirectToFaqs = () => {
    window.open('https://app.dponet.com.br/faqs-documentations/show/101')
  }

  return (
    <BaseDialog
      open={open}
      title="Confirme o vínculo com o titular"
      setOpen={setOpen}
      withoutBottomDivider
    >
      <Box>
        <Typography>
          É necessário confirmar que a sua organização trata dados do titular
          abaixo. Para isso,&nbsp;
          <strong>
            confirme se o titular de dados consta na sua organização.
          </strong>
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h4">Informações do titular</Typography>
        {helpers.tickets.ticketInfo(ticket).map((info, index) => (
          <Box key={index} mt={index === 0 ? 1 : 0}>
            <Typography>
              <strong>{info.label}</strong>&nbsp;
              {info.label === 'Solicitante:' ? (
                <strong>{info.value}</strong>
              ) : (
                info.value
              )}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box className={classes.boxProvideHolder}>
        <Typography variant="h6">{questionDemand?.title}</Typography>
        <Typography
          variant="body1"
          gutterBottom={!!specificField || !!subOption}
        >
          {questionDemand?.answer?.title}
        </Typography>

        {!!specificField && (
          <>
            <Typography variant="h6">{specificField?.title}</Typography>
            <Typography variant="body1">{specificField?.response}</Typography>
          </>
        )}

        {!!subOption && (
          <>
            <Typography variant="h6">{subOption?.title}</Typography>
            <Typography variant="body1">{subOption?.subAnswer}</Typography>
          </>
        )}
      </Box>
      <Box mt={2}>
        <Alert severity="info" className={classes.alert}>
          <AlertTitle>Verificação de dados</AlertTitle>
          Entre em contato com o responsável pela base de dados e confirme se
          essas informações são compatíveis com a base de dados dentro da sua
          organização. Caso as informações não correspondam, clique em “Não”.
          Caso você tenha informações desse titular, clique em “Sim”.
          <br /> <br />
          Para mais informações, consulte a nossa&nbsp;
          <Link onClick={redirectToFaqs} className={classes.link}>
            <strong>Central de Ajuda</strong>
          </Link>
          .
        </Alert>
      </Box>
      <Box mt={4}>
        <Typography>A sua empresa possui os dados deste titular?</Typography>
        <Box display="flex" gridGap={10} mt={2}>
          <Button
            variant="outlined"
            onClick={() => confirmIfHasTitularData(false)}
            size="small"
            disabled={loading}
          >
            NÃO
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => confirmIfHasTitularData(true)}
            size="small"
            disabled={loading}
          >
            SIM
          </Button>
        </Box>
      </Box>
    </BaseDialog>
  )
}

export default ConfirmLinkWithTitular
