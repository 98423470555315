import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  formTag: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFormat: {
    fontFamily: 'Raleway',
    color: 'white',
    fontSize: theme.typography.pxToRem(54),
    fontWeight: 700,
    '& span': {
      backgroundColor: theme.palette.subscription.warning.error,
    },
  },
  stepper: {
    width: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    '& div': {
      borderRadius: 20,
      height: 30,
      width: '100%',
      backgroundColor: 'white',
      '& div': {
        backgroundColor: theme.palette.subscription.warning.success,
      },
    },
  },
  checkboxText: {
    fontSize: theme.typography.pxToRem(12),
    display: 'inline-block',
    width: 400,
    textAlign: 'justify',
  },
  bodyText: {
    fontSize: theme.typography.pxToRem(32),
    marginTop: theme.spacing(10),
    lineHeight: 1.2,
  },
  activeStepText: {
    fontSize: 25,
  },
  aboutButton: {
    fontSize: 11,
    fontWeight: 500,
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  label: {
    fontSize: theme.typography.pxToRem(19),
    color: theme.palette.subscription.primary.light,
    fontWeight: 700,
  },
  input: {
    backgroundColor: 'white',
    border: 'none',
    borderRadius: 5,
    '& input': {
      color: theme.palette.subscription.primary.main,
      fontSize: theme.typography.pxToRem(19),
      padding: 10,
      fontWeight: 700,
    },
  },
  checkbox: {
    '& path': {
      fill: 'white',
    },
  },
  pointer: {
    color: 'white',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  checkboxContainer: {
    marginTop: theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
  },
  roundedButton: {
    borderRadius: 29,
    marginTop: 20,
    fontSize: 16,
    letterSpacing: 1.78,
    width: 350,
    fontWeight: '500 !important',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
    boxShadow: `0px 0px 12px ${theme.colors.firstStep.button.primary}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.colors.firstStep.button.primary,
    },
    backgroundColor: theme.colors.firstStep.button.primary,
    color: theme.palette.common.white,
  },
  questionnaireItem: {
    margin: 0,
    minHeight: '300px',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(28),
  },
  outlined: {
    border: '2px solid',
    borderColor: theme.palette.common.white,
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginTop: 10,
  },
  noButton: {
    marginTop: 20,
  },
  mainContainer: {
    textAlign: 'center',
  },
  arrowButtonContainer: {
    marginBlock: '30px',
  },
  disabledButton: {
    stroke: 'grey',
  },
  skeleton: {
    backgroundColor: 'rgb(254 254 254 / 25%)',
  },
}))

export default styles
