import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

import useStyles from './styles'

const RoundedButton = ({
  children,
  variant,
  icon,
  withPadding,
  withShadow,
  onClick,
  ...rest
}) => {
  const classes = useStyles()
  const isPrimary = variant === 'primary'
  const isSecondary = variant === 'secondary'
  const isOutlined = variant === 'outlined'
  const isText = variant === 'text'

  return (
    <Button
      className={clsx(classes.button, {
        [classes.primary]: isPrimary,
        [classes.primaryHover]: isPrimary && !withShadow,
        [classes.secondary]: isSecondary,
        [classes.outlined]: isOutlined,
        [classes.text]: isText,
        [classes.withPadding]: withPadding,
        [classes.withShadow]: withShadow,
      })}
      classes={{
        label: classes.label,
        endIcon: classes.endIcon,
      }}
      {...(isOutlined && { variant: 'outlined' })}
      {...(isText && { variant: 'text' })}
      {...(!!icon && {
        endIcon: icon,
      })}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  )
}

RoundedButton.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'outlined', 'text']),
  icon: PropTypes.any,
  withPadding: PropTypes.bool,
  withShadow: PropTypes.bool,
}

RoundedButton.defaultProps = {
  withPadding: false,
  withShadow: false,
}

export default RoundedButton
