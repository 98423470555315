import React from 'react'

import { Box, Container } from '@material-ui/core'

import { Text } from '../'
import { InfoChip } from 'components'

const FirstPage = ({ trainningJourney }) => {
  return (
    <Container maxWidth="lg">
      <Text color="primary" fontSize="7vh" variant="h1">
        {trainningJourney?.name}
      </Text>
      <Box my={8}>
        <Text fontSize="3.5vh" variant="h4">
          {trainningJourney?.description}
        </Text>
      </Box>
      <Box width="100%" display="flex" justifyContent="center" mb={1}>
        <InfoChip label="Apenas 20 minutos" />
      </Box>
    </Container>
  )
}

export default FirstPage
