const style = (theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  departmentNameText: {
    fontWeight: 700,
  },
  alignTextEnd: {
    textAlign: 'end',
  },
  ellipsis: {
    whiteSpace: 'noWrap',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '60vw',
  },
})

export default style
