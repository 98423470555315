import React from 'react'
import { Box } from '@material-ui/core'
import { TopBar } from './components'

import useAuth from 'hooks/useAuth'

import useStyles from './styles'

const JourneyStepsAuth = ({ children, classes }) => {
  const { loaded } = useAuth()

  return (
    <>
      {loaded && (
        <>
          <TopBar />
          <Box className={classes.children}>{children}</Box>
        </>
      )}
    </>
  )
}

const JourneySteps = ({ children, auth }) => {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.root}>
        {auth ? (
          <JourneyStepsAuth classes={classes}>{children}</JourneyStepsAuth>
        ) : (
          <Box className={classes.children}>{children}</Box>
        )}
      </Box>
    </>
  )
}

export default JourneySteps
