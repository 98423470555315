import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import Content from '../Content'

import useDrawer from 'hooks/useDrawer'

import styles from './styles'

const useStyles = makeStyles(styles)

const SuspendedDrawer = ({ customThemeIsActive }) => {
  const classes = useStyles()
  const { handleCloseDrawer, openedDrawer, fullDrawer } = useDrawer()

  return (
    <Drawer
      anchor="left"
      classes={{
        paper: clsx(
          classes.drawer,
          {
            [classes.drawerFullScreen]: fullDrawer,
          },
          customThemeIsActive()
            ? classes.customBackground
            : classes.standardBackground,
        ),
      }}
      onClose={handleCloseDrawer}
      open={openedDrawer}
      variant="temporary"
    >
      <Content />
    </Drawer>
  )
}

SuspendedDrawer.propTypes = {
  customThemeIsActive: PropTypes.func,
}

export default SuspendedDrawer
