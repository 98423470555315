import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonBox: {
    border: 'solid',
    borderRadius: '5px',
    borderColor: colors.grey[300],
    color: theme.palette.black,
    cursor: 'pointer',
    backgroundColor: theme.palette.white,
    '&:hover': {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
}))

export default styles
