import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, Divider, Paper, TextField, Typography } from '@material-ui/core'

import BannerHeadCard from '../BannerHeadCard'

import theme from 'theme'
import constants from 'constants/index'

const BannerConfigurationCard = () => {
  const { control, errors } = useFormContext()

  return (
    <Paper
      variant="outlined"
      id={constants.cookies.banner.COOKIES_BANNER_SETTING_DRIVER_STEP_2}
    >
      <BannerHeadCard name="Configuração do Banner" stage="01" />
      <Divider />
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(3)}
      >
        <Box
          p={2}
          bgcolor={theme.palette.custom.light}
          borderRadius={1}
          textAlign="justify"
        >
          <Typography color="primary" variant="h6" gutterBottom>
            Informação
          </Typography>
          <Typography color="primary">
            Um banner de cookies é um aviso exibido nos sites para informar e
            obter o consentimento dos usuários antes de rastrear e armazenar
            seus dados de navegação.
          </Typography>
        </Box>
        {constants.cookies.banner.COOKIE_CREATION_CARD_ITEMS.map(
          (item, index) => (
            <Controller
              key={index}
              as={
                <TextField
                  error={!!errors?.[item.controlName]}
                  helperText={errors?.[item.controlName]?.message}
                  label={item.label}
                  type="text"
                  color="primary"
                  variant="outlined"
                  fullWidth
                />
              }
              control={control}
              name={item.controlName}
            />
          ),
        )}
      </Box>
    </Paper>
  )
}

export default BannerConfigurationCard
