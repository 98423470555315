import React, { useState } from 'react'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import {
  Box,
  Typography,
  Grid,
  makeStyles,
  CircularProgress,
  Button,
} from '@material-ui/core'

import TableDataLifeCycle from '../../TableDataLifeCycle'
import { DataLifeCycleModal } from '../../DataLifeCycleModal'

import useDataLifeCycle from 'hooks/useDataLifeCycle'

import constants from 'constants/index'
import styles from './styles'

const useStyles = makeStyles(styles)

const DataLifeCycle = ({ data, isLoading, departments, ...rest }) => {
  const classes = useStyles()
  const [dataLifeCycleModal, setDataLifeCycleModal] = useState(false)

  const { dataProcessId, dataLifeCycle, loadDataLifeCycle } = useDataLifeCycle()

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="flex-end"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box id={constants.dataProcess.DATA_PROCESS_EDIT_DRIVER_STEP_7}>
            <Grid container justifyContent="space-between">
              <Box>
                <Typography variant="h5" className={classes.titleContainer}>
                  Salvaguarda e Ciclo de Vida
                </Typography>
              </Box>
              <Button
                onClick={() => setDataLifeCycleModal(true)}
                variant="outlined"
                startIcon={<PlusCircleIcon size={20} />}
                size="small"
                className={classes.nameLifeCycle}
              >
                <Typography>Adicionar salvaguarda e ciclo de vida</Typography>
              </Button>
            </Grid>
            <Grid container className={classes.root}>
              <TableDataLifeCycle
                loadDataLifeCycle={loadDataLifeCycle}
                dataProcessId={dataProcessId}
                dataLifeCycles={dataLifeCycle}
                edit
                dataProcess={data}
                classes={classes}
              />
            </Grid>
          </Box>
          <DataLifeCycleModal
            open={dataLifeCycleModal}
            setOpen={setDataLifeCycleModal}
            dataProcessId={dataProcessId}
            dataProcess={data}
            classes={classes}
            mode="create"
          />
        </>
      )}
    </>
  )
}

export default DataLifeCycle
