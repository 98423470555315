import api from 'service/api'

const dponetAPI = api.create('dponet')

const index = async ({ ...params }) => {
  return await dponetAPI.get('data_treatment_agents/', { params })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('data_treatment_agents', {
    data_treatment_agent: data,
  })
}

const updateAll = async ({ dataProcessId, ...data }) => {
  return await dponetAPI.put(`/data_treatment_agents/${dataProcessId}`, {
    data_treatment_agent: data,
  })
}

const destroy = async ({ dataTreatmentAgentId, dataProcessId }) => {
  return await dponetAPI.delete(
    `/data_treatment_agents/${dataTreatmentAgentId}/data_process/${dataProcessId}`,
  )
}

const changeType = async ({ ...data }) => {
  return await dponetAPI.put('/data_treatment_agents_change_type', {
    data_treatment_agent: data,
  })
}

const listOptions = async ({ dataProcessId }) => {
  const query = dataProcessId ? `?data_process_id=${dataProcessId}` : ''

  return await dponetAPI.get(`data_treatment_list_agents${query}`)
}

export default {
  updateAll,
  create,
  listOptions,
  index,
  destroy,
  changeType,
}
