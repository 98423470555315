import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import { Text } from '../'
import { Box, Typography, useMediaQuery } from '@material-ui/core'
import { LoadingFeedback } from 'components'

import socialMediaHD from 'images/social_media_HD 1.png'
import * as service from 'service'

import theme from 'theme'
import useStyles from './styles'

import useTrailStep from 'hooks/useTrailStep'

const CookieConfiguration = () => {
  const [loading, setLoading] = useState(false)

  const { hasBanner, privacyStamp, setPrivacyStamp } = useTrailStep()
  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  useEffect(() => {
    const getPrivacyStamp = async () => {
      const response = await service.dponet.privacyStamps.get()
      setPrivacyStamp(response.data)
    }

    if (isEmpty(privacyStamp)) {
      getPrivacyStamp()
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const getPrivacyStamp = async () => {
      setLoading(true)

      const response = await service.dponet.privacyStamps.get()
      setPrivacyStamp(response.data)
      setLoading(false)
    }

    if (isEmpty(privacyStamp)) {
      getPrivacyStamp()
    }

    //eslint-disable-next-line
  }, [])

  // Implementar quando definir se vai utilizar Adopt

  // useEffect(() => {
  //   const reloadCookieBannerTag = async () => {
  //     if (!cookieBannerTag && !!cookieBannerId) {
  //       setLoading(true)
  //       await loadCookieBannerTag()
  //       setLoading(false)
  //     }
  //   }

  //   reloadCookieBannerTag()
  //   //eslint-disable-next-line
  // }, [cookieBannerId])

  return (
    <>
      <LoadingFeedback open={loading} />
      <Text color="primary" fontSize="7vh" variant="h1">
        Configure seu site!
      </Text>
      <Box my={4}>
        <Text fontSize="3.5vh" variant="h4">
          Preparamos um e-mail com todas as instruções de configuração do selo e
          do banner de cookies. Encaminhe para a equipe responsável pelo site!
        </Text>
      </Box>
      <Box
        maxWidth={theme.breakpoints.values.md}
        width="100%"
        display="flex"
        maxHeight="42vh"
        className={classes.borderedBox}
      >
        {!isMobile && (
          <Box minWidth="25%">
            <img
              src={socialMediaHD}
              alt="media-img"
              width="100%"
              height="100%"
            />
          </Box>
        )}
        <Box
          maxWidth="100%"
          style={{ backgroundColor: 'white', overflow: 'auto' }}
          height="inherit"
        >
          <Box px={2} py={4}>
            <Typography
              variant={isMobile ? 'subtitle1' : 'h4'}
              paragraph
              className={classes.typographyLegend}
            >
              Para o canal de comunicação
            </Typography>
            <Box className={classes.boxCode}>
              <Typography variant="caption">{privacyStamp?.blue}</Typography>
            </Box>
          </Box>
          {!hasBanner && (
            <Box px={2} pb={2}>
              <Typography
                variant={isMobile ? 'subtitle1' : 'h4'}
                paragraph
                className={classes.typographyLegend}
              >
                Para o banner
              </Typography>
              <Box className={classes.boxCode}>
                <Typography variant="caption">
                  Uma solicitação de banner de cookies foi enviado para a nossa
                  equipe. Aguarde a resposta via e-mail
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default CookieConfiguration
