import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ dataProcessId }) => {
  return await dponetAPI.get(
    `/data_processes/${dataProcessId}/suggested_changes`,
  )
}

const accept = async ({ dataProcessId, changeId, ...params }) => {
  return await dponetAPI.post(
    `/data_processes/${dataProcessId}/suggested_changes/${changeId}/accept`,
    params,
  )
}

const refuse = async ({ dataProcessId, changeId }) => {
  return await dponetAPI.post(
    `/data_processes/${dataProcessId}/suggested_changes/${changeId}/refuse`,
  )
}

export default { get, accept, refuse }
