import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import useCustomTheme from 'hooks/useCustomTheme'
import useFetch from 'hooks/useFetch'

import helper from 'helpers'
import * as service from 'service'
import constants from 'constants/index'

const AuditReportPdf = ({
  exportingPdf = false,
  companyAudits = [],
  shouldCreateVersion = false,
  refresh = () => {},
  setExportingPdf = () => {},
}) => {
  const snackbar = useSnackbar()
  const { customTheme, customLogo } = useCustomTheme()
  const { userCompany } = useAuth()

  const customStyles = customTheme?.palette?.layout

  const { response, isLoading } = useFetch(
    service.dponet.reports.generateQrcode,
    { report: constants.reports.KIND.AUDIT },
  )

  const pathQrCode = response?.data

  useEffect(() => {
    if (exportingPdf && !isLoading) {
      if (!isEmpty(companyAudits)) {
        helper.reports.audit.exportPdf(
          userCompany,
          customStyles,
          customLogo,
          companyAudits,
          pathQrCode,
          snackbar,
          shouldCreateVersion,
          refresh,
          setExportingPdf,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportingPdf])
  return <></>
}

export default AuditReportPdf
