import React from 'react'
import PropTypes from 'prop-types'

import { DashboardChartPaper, DonutChart } from 'components'

import constants from 'constants/index'

const FinalizedRmcByDepartmentGraphic = ({ processStats, ...rest }) => {
  const departments = processStats?.departments
    ?.sort((a, b) => {
      const sumA =
        a.nonComplianceReports.finalized + a.nonComplianceReports.riskAccepted
      const sumB =
        b.nonComplianceReports.finalized + a.nonComplianceReports.riskAccepted
      return sumB - sumA
    })
    .slice(0, 4)

  const getDataByStatus = (department, status) =>
    department?.nonComplianceReports[status]

  const names = departments?.map((department) => department.name)

  const dataFinalized = departments?.map(
    (department) =>
      getDataByStatus(department, 'finalized') +
      getDataByStatus(department, 'riskAccepted'),
  )

  return (
    <>
      <DashboardChartPaper
        title="Departamentos que possuem mais RMCs resolvidos"
        subtitle="RMCs risco assumido e finalizados"
        // redirect={routes.nonComplianceReport.all}
        redirectParams={{
          status: constants.nonComplianceReports.FINALIZED_STATUS,
        }}
        {...rest}
      >
        <DonutChart
          legendPosition="right"
          names={names || []}
          values={dataFinalized || []}
          title="RMCs risco assumido e finalizados"
          colors={['#376FB6', '#FF2EF8', '#4899FF', '#06A12F']}
          id={constants.myLgpd.GRAPH_IDS.pdf.departmentsRmcFinalizeds}
        />
      </DashboardChartPaper>
    </>
  )
}

FinalizedRmcByDepartmentGraphic.propTypes = {
  processStats: PropTypes.object.isRequired,
}

export default FinalizedRmcByDepartmentGraphic
