import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Box, Checkbox } from '@material-ui/core'
import { Text } from '..'

import useStyles from './styles'

const CardProduct = ({
  children,
  active,
  onChange,
  action,
  description,
  value,
}) => {
  const [checked, setChecked] = useState(active)
  const classes = useStyles()

  const handleChange = () => {
    onChange()
    setChecked(!checked)
  }

  return (
    <Box width={240}>
      <Box
        className={clsx(classes.root, { [classes.active]: checked })}
        onClick={handleChange}
        mb={2}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={checked ? 1.5 : 2}
          pt={checked ? 1.5 : 2}
        >
          <Checkbox
            checked={checked}
            color="primary"
            className={classes.checkbox}
            classes={{
              colorPrimary: classes.primaryCheckbox,
            }}
          />
          {action}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="150px"
        >
          {children}
        </Box>
      </Box>
      <Box textAlign="justify">
        <Text fontSize={16} fontHeight={400} variant="body1" gutterBottom>
          {description}
        </Text>
        <Box
          display="flex"
          alignItems="center"
          mt={2}
          className={classes.price}
        >
          <Text fontSize={14} fontHeight={400} variant="body1">
            R$
          </Text>
          <Text fontSize={23} fontHeight={500} variant="h3">
            {value}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

CardProduct.propTypes = {
  active: PropTypes.bool,
  onChange: PropTypes.func,
  action: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  description: PropTypes.string,
  value: PropTypes.string,
}

CardProduct.defaultProps = {
  active: false,
  onChange: () => {},
  value: 'Gratuíto',
}

export default CardProduct
