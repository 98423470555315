import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    width: 440,
    height: 660,
    borderRadius: 8,
  },
  contentContainer: {
    display: 'flex',
    padding: 40,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
    height: '100%',
  },
  imageContainer: {
    height: 132,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 180,
  },
  image: {
    borderRadius: 10,
    objectFit: 'contain',
    maxHeight: '100%',
    maxWidth: '100%',
  },

  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Raleway',
    fontWeight: 700,
    fontSize: 20,
    color: theme.palette.black,
    minHeight: 60,
  },
  description: {
    textAlign: 'justify',
    fontFamily: 'Roboto',
    fontSize: 18,
    color: theme.palette.text.mainMenu,
    alignSelf: 'start',
  },
  descriptionContainer: {
    minHeight: 300,
    maxHeight: 500,
  },
  button: {
    width: 165,
    height: 40,
    alignSelf: 'center',
    justifySelf: 'end',
    color: theme.palette.primary.hover,
    border: `1px solid ${theme.palette.marketplace.card.border}`,
    transition: 'all 200ms ease-in',
    '&:hover': {
      color: theme.palette.white,
      border: theme.palette.white,
      backgroundColor: theme.palette.marketplace.card.border,
    },
  },
}))

export default styles
