import React from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Zoom,
} from '@material-ui/core'

import helpers from 'helpers'

import useStyles from './styles'

const ServiceCard = ({ title, description, pathName, icon }) => {
  const classes = useStyles()
  const history = useHistory()
  const { mixpanel } = helpers

  const handleButtonClick = () => {
    if (pathName) history.push(pathName)

    mixpanel.track('Marktplace', 'Tela de serviços', {
      action: `Marketplace_Contratar_Parceiro_${title}`,
    })
  }

  return (
    <Zoom in timeout={300} mountOnEnter unmountOnExit>
      <Card className={classes.container}>
        <CardContent className={classes.contentContainer}>
          <Box className={classes.iconContainer}>
            <Box className={classes.imageContainer}>
              <img src={icon} alt={'logo ' + title} className={classes.image} />
            </Box>
            <Typography className={classes.title}>{title}</Typography>
          </Box>
          <Box className={classes.descriptionContainer}>
            <PerfectScrollbar>
              <Typography className={classes.description}>
                {description}
              </Typography>
            </PerfectScrollbar>
          </Box>
          <Button className={classes.button} onClick={handleButtonClick}>
            Contratar
          </Button>
        </CardContent>
      </Card>
    </Zoom>
  )
}

ServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  pathName: PropTypes.string,
}

ServiceCard.defaultProps = {
  pathName: '',
}

export default ServiceCard
