const questionControlsWithCompanyFragilties = (companyFragilities) => {
  const companyFragilitiesTotal = companyFragilities.length
  const withCompanyFragilitiesSecuresTotal = companyFragilities.filter(
    (companyFragility) =>
      companyFragility.companyFragilityMeasures &&
      companyFragility.companyFragilityMeasures.length > 0,
  ).length
  return { companyFragilitiesTotal, withCompanyFragilitiesSecuresTotal }
}

export default questionControlsWithCompanyFragilties
