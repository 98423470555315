import PolygonContained from './PolygonContained'
import PolygonOutline from './PolygonOutline'
import CookieIcon from './CookieIcon'
import DPOEasy from './DPOEasy'
import Mastercard from './Mastercard'
import Visa from './Visa'
import Elo from './Elo'
import Amex from './Amex'
import Hipercard from './Hipercard'

const SVG = {
  PolygonContained,
  PolygonOutline,
  CookieIcon,
  DPOEasy,
  Mastercard,
  Visa,
  Elo,
  Amex,
  Hipercard,
}

export default SVG
