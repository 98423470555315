import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import DOMPurify from 'dompurify'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { orderBy } from 'lodash'
import { Label } from 'components'

import useConsent from 'hooks/useConsent'
import useAuth from 'hooks/useAuth'

import helpers from 'helpers'
import constants from 'constants/index'
import { getBaseURL } from 'service/env'

import styles from './styles'

const useStyles = makeStyles(styles)

const Preview = () => {
  const { nextStep, toNextStep, setToNextStep, dataConsentForm } = useConsent()
  const { company } = useAuth()

  const classes = useStyles()

  const logoPath =
    company?.logo?.url && `${getBaseURL('dponet')}${company?.logo?.url}`

  const avatarLetters = (company) =>
    (company?.name || 'Empresa cliente')
      .split(' ')
      .map((word) => word[0])
      .join('')

  const { handleSubmit, errors } = useForm()

  useEffect(() => {
    if (toNextStep) {
      handleSubmit(onSubmit)()
      if (errors) setToNextStep(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toNextStep])

  const onSubmit = () => {
    nextStep()
    setToNextStep(false)
  }

  const components = orderBy(
    Object.entries(dataConsentForm.components),
    ([_, item]) => item.order,
  )

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id={constants.consent.NEW_CONSENT_FORM_DRIVER_STEP_3}
    >
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Box className={classes.headerBox}>
            <Typography variant="h3" align="center">
              {dataConsentForm?.title}
            </Typography>
            <Typography variant="h4" align="center">
              {dataConsentForm?.subtitle}
            </Typography>
          </Box>
        </Grid>
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          width="100%"
          marginLeft={2}
        >
          <Box marginRight={3}>
            <Avatar
              className={classes.avatar}
              src={logoPath}
              classes={{ img: classes.img }}
              variant="rounded"
            >
              {avatarLetters(company)[0]}
              {avatarLetters(company)[avatarLetters(company).length - 1]}
            </Avatar>
          </Box>
          <Box>
            <Typography variant="h5">{company?.name}</Typography>
            <Typography variant="h5">{company?.companyName}</Typography>
            <Box display="flex">
              <Typography variant="h5">
                {company?.cnpj ? 'CNPJ:' : 'CPF:'}
              </Typography>
              <Typography variant="h5" className={classes.document}>
                {helpers.formatters.cnpjOrCpf(company?.cnpj || company?.cpf)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Grid item xs={12}>
          <Box className={classes.descriptionBox}>
            <PerfectScrollbar
              options={{ wheelPropagation: false, useBothWheelAxes: true }}
            >
              <Box
                fontFamily="Roboto"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(dataConsentForm?.content),
                }}
              ></Box>
            </PerfectScrollbar>
          </Box>
        </Grid>

        {components.map(([key, value]) => (
          <Grid item xs={12} key={key}>
            <Grid container spacing={2}>
              <Label
                title={`${value?.required ? key + ' *' : key}`}
                description={value?.subtitle}
                item
                xs={12}
              >
                <TextField disabled fullWidth />
              </Label>
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button
            className={classes.authorizeButton}
            variant="contained"
            fullWidth
          >
            {dataConsentForm?.buttonText ?? 'EU AUTORIZO'}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default Preview
