import { isEmpty } from 'lodash'

const mountOption = (options, other, literaName) => {
  const newOptionOther = {
    keyName: 'other',
    name: other,
    typeField: literaName,
  }

  if (other) {
    if (isEmpty(options)) {
      return [].concat(newOptionOther)
    }

    const newOptions = options.filter((option) => option.keyName !== 'other')
    return newOptions.concat(newOptionOther)
  }

  return options
}

export default mountOption
