const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  stepper: {
    margin: theme.spacing(1),
    padding: 0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#E0E0E0',
    borderRadius: 4,
  },
  step: {
    padding: theme.spacing(2),
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#E0E0E0',
  },
  stepConnector: {
    display: 'none',
  },
  stepIcon: {
    borderRadius: '50%',
    height: 40,
    width: 40,
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepIconActive: {
    backgroundColor: '#087DF138',
    color: '#007BFF',
  },
  stepIconCompleted: {
    backgroundColor: '#E8F5E9',
    color: '#4CAF50',
  },
  stepLabelText: {
    color: '#263238',
  },
  stepLabelTextOptional: {
    color: '#546E7A',
  },
})
export default styles
