import formatData from './formatData'
import status from './status'
import statusChipColor from './statusChipColor'

const activities = {
  formatData,
  status,
  statusChipColor,
}

export default activities
