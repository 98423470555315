import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  boxTitle: {
    backgroundColor: theme.palette.primary.light,
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  boxSnackbar: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.snackbar,
    border: `1px solid ${theme.palette.custom.border}`,
  },
}))

export default useStyles
