import { colors } from '@material-ui/core'

const styles = (theme) => ({
  container: {
    paddingTop: theme.spacing(3.125),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  faq: {
    backgroundColor: colors.lightBlue[800],
    borderRadius: '44px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 600,
    height: '26px',
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  documentation: {
    backgroundColor: colors.lightGreen[500],
    borderRadius: '44px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 600,
    height: '26px',
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  boxTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  boxTitleTwo: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: theme.spacing(97),
    [theme.breakpoints.down('lg')]: {
      maxWidth: theme.spacing(75),
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.spacing(32.5),
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.spacing(12.5),
      whiteSpace: 'wrap',
    },
  },
  buttonSeeMore: {
    border: '1px solid',
    whiteSpace: 'nowrap',
    borderRadius: theme.spacing(2.5),
    width: theme.spacing(15.6),
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('xs')]: {
      border: 'none',
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: '50%',
    },
  },
  card: {
    marginTop: theme.spacing(1.5),
  },
  cardContent: {
    minHeight: theme.spacing(18.75),
    maxHeight: theme.spacing(25),
    overflow: 'hidden',
  },
  updatedAt: {
    padding: theme.spacing(0.5),
  },
  seeMoreText: {
    fontWeight: theme.spacing(75),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  descriptionText: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  descriptionDialog: {
    fontFamily: 'Roboto',
    fontSize: theme.spacing(2.5),
    fontWeight: theme.spacing(50),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  baseDialog: {
    minWidth: theme.spacing(62.5),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  boxTitleAndChip: {
    display: 'flex',
    width: '100%',
  },
  faqShow: {
    backgroundColor: colors.lightBlue[800],
    borderRadius: '44px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 600,
    height: '26px',
    marginLeft: theme.spacing(1),
    fontSize: '12px',
  },
  documentationShow: {
    backgroundColor: colors.lightGreen[500],
    borderRadius: '44px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 600,
    height: '26px',
    marginLeft: theme.spacing(1),
    fontSize: '12px',
  },
  boxDescription: {
    width: '-webkit-fill-available',
    [theme.breakpoints.down('xs')]: {
      '& p > img': {
        width: '100%',
        height: 'auto',
      },
    },
  },
  boxFlex: {
    display: 'flex',
    width: '100%',
  },
  boxHead: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      width: '100%%',
    },
  },
  boxSearch: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  faqDocBox: {
    marginTop: theme.spacing(1.5),
  },
})

export default styles
