import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  paperBox: {
    height: '233px',
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(2.5),

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5),
  },

  titleLine: {
    fontSize: 22,
    fontWeight: 'bold',
  },

  lineInfos: {
    fontSize: 14,
    fontWeight: 400,
  },

  dateLine: {
    marginTop: '3px',
    backgroundColor: 'red',
  },
}))

export default styles
