import React, { useEffect, useState } from 'react'
import { Button, Container } from '@material-ui/core'
import { isEmpty } from 'lodash'

import {
  ContentHeader,
  DialogLogs,
  LoadingFeedback,
  MenuButton,
  Page,
} from 'components'
import { Form } from './components'

import useFetch from 'hooks/useFetch'
import useIncidentManegement from 'hooks/useIncidentManegement'
import useConvertToFile from 'hooks/useConvertToFile'

import * as service from 'service'
import incidentManegementConstants from 'constants/incidentManegement'
import helpers from 'helpers'

const IncidentManagementEdit = ({ match }) => {
  const [openLogDialog, setOpenLogDialog] = useState(false)
  const [responseLogWithStatus, setResponseLogWithStatus] = useState([])
  const [loadingLogs, setLoadingLogs] = useState(false)

  const { incident, setIncident, setActiveStep } = useIncidentManegement()
  const { fileList, loading } = useConvertToFile(incident?.detectionAttachments)

  const { incidentId } = match.params

  const { response, isLoading, refresh } = useFetch(
    service.dponet.incidents.get,
    {
      incidentId,
    },
  )

  const identifyCurrentStep = (incidentManegementSteps) => {
    const stepsByEnum = incidentManegementConstants.STEPS_BY_ENUM

    if (isEmpty(incidentManegementSteps)) return

    incidentManegementSteps.forEach((incidentManegementStep) => {
      incidentManegementStep.order = stepsByEnum[incidentManegementStep.step]
    })

    incidentManegementSteps = incidentManegementSteps.sort(
      (a, b) => a.order - b.order,
    )

    const firstUnconcludedStep = incidentManegementSteps.find(
      (incidentManegementStep) => !incidentManegementStep.concluded,
    )

    if (isEmpty(firstUnconcludedStep))
      return setActiveStep(stepsByEnum['lessons_learned'])

    setActiveStep(firstUnconcludedStep.order)
  }

  useEffect(() => {
    if (response?.data?.incident) {
      if (isEmpty(incident)) {
        identifyCurrentStep(response.data.incident?.incidentManegementSteps)
      }

      setIncident(response.data.incident)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response?.data?.incident])

  useEffect(() => {
    const getLogs = async () =>
      await service.dponet.incidents.logs({
        incidentId,
      })

    if (isEmpty(responseLogWithStatus) && openLogDialog) {
      setLoadingLogs(true)
      getLogs().then((response) => {
        const updated_logs = helpers.logs.changeLogs(
          response?.data?.dponetAudits || [],
        )
        setResponseLogWithStatus(updated_logs)
        setLoadingLogs(false)
      })
    }

    // eslint-disable-next-line
  }, [openLogDialog])

  useEffect(() => {
    if (!isEmpty(incident) && !loading)
      setIncident((prevIncident) => ({
        ...prevIncident,
        detectionAttachmentsList: fileList,
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, loading])

  return (
    <Page title="Editar incidente">
      <Container maxWidth={false}>
        <ContentHeader title="Editar Incidente">
          <MenuButton>
            <Button
              color="secondary"
              fullWidth
              onClick={() => setOpenLogDialog(true)}
            >
              Histórico de alterações
            </Button>
          </MenuButton>
        </ContentHeader>

        <LoadingFeedback open={isLoading || loadingLogs} />

        {((!isLoading && !loading) || !isEmpty(incident)) && (
          <Form type="edit" refresh={refresh} />
        )}
      </Container>

      {!loadingLogs && (
        <DialogLogs
          logs={
            helpers.functions.isEmpty(responseLogWithStatus)
              ? undefined
              : responseLogWithStatus
          }
          open={openLogDialog}
          setOpen={setOpenLogDialog}
          showRegisterSession={false}
        />
      )}
    </Page>
  )
}

export default IncidentManagementEdit
