import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Typography,
} from '@material-ui/core'

import { TrailsCardItem } from './components'

import useStyles from './styles'
import constants from 'constants/index'
import { LoadingFeedback } from 'components'
import helpers from 'helpers'

const TrailsCard = ({
  title,
  progress,
  trails,
  status,
  url,
  loading = false,
  onClick,
  disabled,
  journey,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const buttonLabels = {
    [constants.companyTrail.CONCLUDED_STATUS]: 'Refazer Jornada',
    [constants.companyTrail.DOING_STATUS]: 'Retomar Jornada',
    [constants.companyTrail.NOT_INITIATED_STATUS]: 'Iniciar Jornada',
  }

  const handleRedirect = async () => {
    if (!!onClick) {
      onClick()
    }
    helpers.mixpanel.track('Home', 'Treinamento', {
      action: 'treinamento-home',
    })

    history.push(url)
  }

  return (
    <Fragment>
      <LoadingFeedback open={loading} />
      {!loading && (
        <Card>
          <CardHeader
            title={title}
            className={classes.cardHeader}
            classes={{
              content: classes.cardHeaderContent,
              title: classes.cardHeaderTitle,
            }}
            subheader={
              <Box display="flex" alignItems="center" mt={1}>
                <LinearProgress
                  classes={{
                    barColorPrimary: classes.linearProgressBar,
                  }}
                  className={classes.linearProgress}
                  variant="determinate"
                  value={progress || 0}
                />
                <Typography>{progress}%</Typography>
              </Box>
            }
          />
          <CardContent className={classes.cardContent}>
            {trails.map((trail) => (
              <TrailsCardItem
                key={trail.title}
                {...trail}
                cardFatherTitle={title}
                journey={journey}
              />
            ))}
            <Box p={2} width="100%">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleRedirect}
                disabled={disabled}
                fullWidth
              >
                {buttonLabels[status]}
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}
    </Fragment>
  )
}

export default TrailsCard
