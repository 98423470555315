import React from 'react'
import PropTypes from 'prop-types'
import { Filters } from 'components'

const FilterFaqsDocumentations = ({ filter, categories }) => {
  return (
    <Filters filter={filter}>
      <select
        multipleselectinput="true"
        label="Categorias"
        name="categories"
        optionvalues={categories}
      />
    </Filters>
  )
}

FilterFaqsDocumentations.propTypes = {
  filter: PropTypes.object,
  kind: PropTypes.string,
}

export default FilterFaqsDocumentations
