import React from 'react'
import PropTypes from 'prop-types'

import { BarChart, DashboardChartPaper } from 'components'

import { routes } from 'Routes'
import theme from 'theme'

const DepartmentsByThreats = ({
  id,
  title,
  subtitle,
  redirect = routes.dataProcess.all,
  values = {},
  ...rest
}) => {
  return (
    <DashboardChartPaper
      title={title}
      subtitle={subtitle}
      redirect={redirect}
      {...rest}
    >
      <BarChart
        id={id}
        names={values?.names}
        series={[
          { name: 'Baixo', data: values?.lowValues },
          { name: 'Médio', data: values?.mediumValues },
          { name: 'Alto', data: values?.highValues },
          { name: 'Severo', data: values?.severeValues },
        ]}
        heightValue={250}
        colors={[
          theme.palette.dashboard.chart.measures.adopted,
          theme.palette.dashboard.chart.measures.riskAssumed,
          theme.palette.dashboard.chart.measures.pending,
          theme.palette.dashboard.chart.measures.overdue,
        ]}
        showLabels
      />
    </DashboardChartPaper>
  )
}

DepartmentsByThreats.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  redirect: PropTypes.string,
  values: PropTypes.object,
}

export default DepartmentsByThreats
