import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'

import { FormControlLabel, Checkbox } from '@material-ui/core'

import useStyles from './styles'

const MultiSelectItem = ({
  item,
  applyToCompany,
  selectAllByGroup,
  allProcessesSelectedByGroup,
  isForeign,
  checked,
  onClick,
  height,
}) => {
  const classes = useStyles()
  const isGroup = !!item?.isGroup
  const handleClick = (e) => {
    if (applyToCompany) {
      return
    }

    if (isGroup) {
      return selectAllByGroup(item?.id)
    }

    if (!!onClick) {
      onClick(e)
    }
  }

  return (
    <FormControlLabel
      onClick={handleClick}
      classes={{
        root: clsx({
          [classes.formControlLabelRoot]: !isGroup && !isForeign,
          [classes.formControlLabelRootGroup]: isGroup || isForeign,
          [classes.checked]: checked,
        }),
        label: clsx({
          [classes.formControlLabelGroup]: !!isGroup,
        }),
      }}
      label={item?.label}
      labelPlacement="end"
      disabled={applyToCompany}
      control={
        <Checkbox
          color="primary"
          height={height}
          disabled={applyToCompany}
          checked={isGroup ? allProcessesSelectedByGroup(item?.id) : checked}
        />
      }
    />
  )
}

MultiSelectItem.propTypes = {
  item: PropTypes.object,
  applyToCompany: PropTypes.bool,
  selectAllByGroup: PropTypes.func,
  allProcessesSelectedByGroup: PropTypes.func,
  isForeign: PropTypes.bool,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default MultiSelectItem
