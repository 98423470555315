import React, { useEffect, useState } from 'react'
import { useForm, Controller, FormContext } from 'react-hook-form'
import { isEmpty } from 'lodash'
import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import humps from 'humps'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import { FileUploadInput, Label, LoadingFeedback } from 'components'

import useIncidentManegement from 'hooks/useIncidentManegement'
import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'
import * as service from 'service'
import schema from './schema'
import formatters from 'helpers/formatters'
import constants from 'constants/index'
import { routes } from 'Routes'

const AvaliationDpo = ({ type }) => {
  const [loading, setLoading] = useState(false)

  const {
    wantsToAdvanceStep,
    handleNext,
    resetAdvanceStep,
    incident,
    setIncident,
  } = useIncidentManegement()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const history = useHistory()

  const formMethods = useForm({
    validationSchema: schema,
    defaultValues: {
      legalOpinion: incident?.legalOpinion || '',
      confirmed: String(incident?.confirmed || false),
      shouldNotify: String(incident?.shouldNotify || false),
      notificationOpinion: incident?.notificationOpinion || '',
      opinionFile: incident?.attachments,
      notificationOpinionFile: incident?.notificationOpinionAttachment,
    },
  })

  const { control, errors, triggerValidation, watch, getValues, setValue } =
    formMethods

  const mountAvaliationParams = () => {
    const data = getValues()

    return {
      legalOpinion: data?.legalOpinion,
      confirmed: data?.confirmed,
      shouldNotify: data?.confirmed === 'true' ? data?.shouldNotify : undefined,
      notificationOpinion:
        data?.shouldNotify === 'true' ? data?.notificationOpinion : '',
      attachments: data?.opinionFile,
      notificationOpinionAttachment:
        data?.shouldNotify === 'true'
          ? data?.notificationOpinionFile
          : undefined,
    }
  }

  const alreadyAnswered = !isEmpty(incident?.legalOpinion)

  const onSubmit = async () => {
    if (alreadyAnswered) return handleNext()

    const valid = await triggerValidation()

    resetAdvanceStep()

    if (!valid) {
      resetAdvanceStep()
      return
    }

    try {
      setLoading(true)

      const response = await service.dponet.incidents.putAvaliationForm({
        incidentId: incident?.id,
        legalOpinionData: mountAvaliationParams(),
      })

      const incidentResponse = response.data?.incident

      setIncident(humps.camelizeKeys(incidentResponse))

      await service.dponet.incidents.concludeStep({
        incidentId: incident.id,
        step: 'avaliation',
      })

      snackbar.open({
        message: 'Parecer atualizado com sucesso',
        variant: 'success',
      })

      if (type === 'create') {
        navigateToEdit()
      }

      handleNext()
      resetAdvanceStep()
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const navigateToEdit = () => {
    history.push(
      reverse(routes.incidents.edit, {
        incidentId: incident?.id,
      }),
    )
  }

  useEffect(() => {
    if (watch('confirmed') === 'false') {
      setValue('shouldNotify', 'false')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('confirmed')])

  useEffect(() => {
    if (wantsToAdvanceStep) {
      onSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantsToAdvanceStep])

  return (
    <FormContext {...formMethods}>
      <Grid container spacing={4}>
        <LoadingFeedback open={loading} />
        <Grid item xs={12}>
          <Card title="Avaliação do Incidente" className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Label title="Essa notificação é realmente um incidente?">
                  <Controller
                    control={control}
                    name="confirmed"
                    as={
                      <RadioGroup>
                        <FormControlLabel
                          disabled={alreadyAnswered}
                          value="false"
                          control={<Radio />}
                          label="Não"
                        />
                        <FormControlLabel
                          disabled={alreadyAnswered}
                          value="true"
                          control={<Radio />}
                          label="Sim"
                        />
                      </RadioGroup>
                    }
                  />
                </Label>
              </Grid>
              <Grid item xs={12}>
                <Label title="Parecer" paddingBoxTitle={0}>
                  <Controller
                    as={
                      <TextField
                        type="text"
                        minRows={5}
                        multiline
                        error={!!errors.legalOpinion}
                        helperText={errors?.legalOpinion?.message}
                        fullWidth
                        disabled={alreadyAnswered}
                      />
                    }
                    control={control}
                    name="legalOpinion"
                  />
                </Label>

                <Box mt={2}>
                  <FileUploadInput
                    controlName="opinionFile"
                    title="Arraste e solte ou selecione o documento a ser anexado"
                    accept={constants.validations.FILE_TYPES_DOCS}
                  />
                </Box>
              </Grid>
              {watch('confirmed') === 'true' && (
                <>
                  <Grid item xs={12}>
                    <Label title="Houve dano e/ou risco relevante para que seja necessário comunicar a ANPD e os titulares de dados?">
                      <Controller
                        control={control}
                        name="shouldNotify"
                        as={
                          <RadioGroup>
                            <FormControlLabel
                              disabled={alreadyAnswered}
                              value="false"
                              control={<Radio />}
                              label="Não"
                            />
                            <FormControlLabel
                              disabled={alreadyAnswered}
                              value="true"
                              control={<Radio />}
                              label="Sim"
                            />
                          </RadioGroup>
                        }
                      />
                    </Label>
                  </Grid>
                  {watch('shouldNotify') === 'true' && (
                    <Grid item xs={12}>
                      <Label
                        title="Comentário sobre a comunicação à titulares de dados"
                        paddingBoxTitle={0}
                      >
                        <Controller
                          as={
                            <TextField
                              type="text"
                              minRows={5}
                              multiline
                              error={!!errors.notificationOpinion}
                              helperText={errors?.notificationOpinion?.message}
                              fullWidth
                              disabled={alreadyAnswered}
                            />
                          }
                          control={control}
                          name="notificationOpinion"
                        />
                      </Label>

                      <Box mt={2}>
                        <FileUploadInput
                          controlName="notificationOpinionFile"
                          title="Arraste e solte ou selecione o documento a ser anexado"
                          accept={constants.validations.FILE_TYPES_DOCS}
                        />
                      </Box>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </FormContext>
  )
}

export default AvaliationDpo
