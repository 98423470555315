const styles = (theme) => ({
  dialog: {
    background: `linear-gradient(135deg, ${theme.palette.greenLight} 0%,${theme.palette.greenDark} 100%) !important`,
    padding: theme.spacing(2.5),
  },
  dialogAlert: {
    background: `linear-gradient(135deg, ${theme.palette.yellowWarningLight} 0%,${theme.palette.yellowWarningDark} 100%) !important`,
    padding: theme.spacing(2.5),
  },
  colorWhite: {
    color: 'white',
  },
  h3: {
    color: 'white',
    fontWeight: 700,
  },
  colorBlack: {
    color: 'black',
    fontWeight: '400 !important',
  },
  h3Black: {
    color: 'black',
    fontWeight: 700,
  },
  button: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  buttonLabel: {
    fontWeight: '500 !important',
  },
})

export default styles
