import React, { useState } from 'react'

import { TableContainer, Button, IconButton } from '@material-ui/core'

import { VisibilityOutlined as VisibilityOutlinedIcon } from '@material-ui/icons'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { Permitted } from 'components'

import { DataTreatmentModal } from '../DataTreatmentModal'
import DialogRemoveDataTreatment from '../DialogRemoveDataTreatment'

import { DataTreatmentShowModal } from 'views/DataProcesses/components'
import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'

const TableDataTreatments = ({
  dataProcessId,
  dataTreatments,
  edit,
  classes,
  loadData,
}) => {
  const snackbar = useSnackbar()
  const [dialogRemoveDataTreatments, setDialogRemoveDataTreatments] =
    useState(false)
  const [removeDataTreatment, setRemoveDataTreatment] = useState()
  const [dataTreatmentShow, setDataTreatmentShow] = useState()
  const [openShow, setOpenShow] = useState(false)

  const handleRemove = (dataTreatmentId) => {
    setRemoveDataTreatment(dataTreatmentId)
    setDialogRemoveDataTreatments(true)
  }

  const handleOpen = (dataTreatment) => {
    setDataTreatmentShow(dataTreatment)
    setOpenShow(true)
  }

  const deleteDataTreatment = async (dataProcessId, dataTreatmentId) => {
    try {
      await service.dponet.dataTreatments.destroy({
        dataProcessId,
        dataTreatmentId,
      })

      setRemoveDataTreatment(null)
      setDialogRemoveDataTreatments(false)

      loadData()

      snackbar.open({
        message: `Dado compartilhado removido com sucesso`,
        variant: 'success',
      })
    } catch (error) {
      setRemoveDataTreatment(null)
      setDialogRemoveDataTreatments(true)
      snackbar.open({
        message: `Falha ao remover dado compartilhado`,
        variant: 'error',
      })
    }
  }

  return (
    <>
      <TableContainer>
        <Table
          size="small"
          emptyMessage="Nenhum compartilhamento de dados foi encontrado"
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell width="20%">
                Com quem o dado é compartilhado?
              </TableCell>
              <TableCell width="20%">Porquê o dado é compartilhado?</TableCell>
              <TableCell width="20%">Como o dado é compartilhado</TableCell>
              <TableCell width="20%">Tipo de compartilhamento</TableCell>
              <TableCell width="20%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTreatments.map((dataTreatment) => (
              <TableRow key={dataTreatment.id}>
                <TableCell>{dataTreatment?.shareProcess?.name}</TableCell>
                <TableCell>{dataTreatment.description}</TableCell>
                <TableCell>{dataTreatment.controllMechanismView}</TableCell>
                <TableCell>{dataTreatment?.shareType?.name}</TableCell>
                {edit ? (
                  <TableCell align="right">
                    <DataTreatmentModal
                      dataTreatment={dataTreatment}
                      classes={classes}
                      mode="edit"
                    >
                      <Permitted tag="create_data_process">
                        <Button
                          color="secondary"
                          fullWidth
                          onClick={() => handleRemove(dataTreatment.id)}
                        >
                          Excluir
                        </Button>
                      </Permitted>
                    </DataTreatmentModal>
                  </TableCell>
                ) : (
                  <TableCell align="right">
                    <IconButton onClick={() => handleOpen(dataTreatment)}>
                      <VisibilityOutlinedIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DataTreatmentShowModal
        dataTreatment={dataTreatmentShow}
        setOpen={setOpenShow}
        open={openShow}
        mode="show"
      />
      <DialogRemoveDataTreatment
        open={dialogRemoveDataTreatments}
        setOpen={setDialogRemoveDataTreatments}
        handleRemove={deleteDataTreatment}
        dataProcessId={dataProcessId}
        dataTreatmentId={removeDataTreatment}
      />
    </>
  )
}

export default TableDataTreatments
