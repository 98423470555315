import { makeStyles } from '@material-ui/core'

import theme from 'theme'

const dataProcessColors = theme.colors.dataProcess.backgroundStatuses

const styles = makeStyles(() => ({
  root: {
    borderRadius: '2px',
    fontWeight: 700,
    color: theme.palette.white,
  },
  inactive: {
    backgroundColor: dataProcessColors.inactive,
  },
  pending: {
    backgroundColor: dataProcessColors.pending,
  },
  waitingReview: {
    backgroundColor: dataProcessColors.waitingReview,
  },
  reviewDisapproved: {
    backgroundColor: dataProcessColors.reproved,
  },
  reviewApproved: {
    backgroundColor: dataProcessColors.approved,
  },
  reviewing: {
    backgroundColor: dataProcessColors.reviewing,
  },
  autoReview: {
    backgroundColor: dataProcessColors.autoReview,
  },
}))

export default styles
