import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  gridAddress: {
    padding: theme.spacing(1),
  },
  gridAddressHidden: {
    display: 'none !important',
  },
}))

export default styles
