import permissions from '../permissions'

const CONSENT_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.CONSENTS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.VISUALIZE,
    tag: permissions.CONSENTS.SHOW,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.CONSENTS.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.CONSENTS.CHANGE_RESPONSIBLE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.ACTIVATE_OR_INACTIVATE,
    tag: permissions.CONSENTS.CHANGE_FORM_STATUS,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.CONSENTS.DESTROY,
  },
]

export default CONSENT_LIST
