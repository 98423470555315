import moment from 'moment'

const dateExpirationBillet = (day) => {
  const date = new Date()

  if (date.getDate() >= day) {
    return moment(
      `${date.getUTCFullYear()}-${date.getUTCMonth() + 2}-${day}`,
    ).format('DD/MM/YYYY')
  }

  return moment(
    `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${day}`,
  ).format('DD/MM/YYYY')
}

export default dateExpirationBillet
