import constants from 'constants/index'

const labelDataProcessSourcesTable = (source) => {
  switch (source[0]) {
    case constants.dataProcess.SOURCE_DEPARTMENT_KIND:
      return 'Departamento(s) selecionado(s)'
    case constants.dataProcess.SOURCE_THIRD_PARTY_KIND:
      return 'Organização(es) selecionada(s)'
    case constants.dataProcess.SOURCE_PUBLIC_KIND:
      return 'Fonte(s) pública(s) selecionada(s)'
    default:
      return ''
  }
}

export default labelDataProcessSourcesTable
