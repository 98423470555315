const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: 'none',
  },
  select: {
    width: '100%',
  },
  table: {
    width: '100%',
    overflowX: 'scroll',
  },
  tableCellFlex: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  tableButton: {
    color: '#263238',
    fontSize: '12px',
  },
  ellipsisTable: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '9rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperModal: {
    backgroundColor: '#fff',
    borderWidth: 0,
    outline: 'none',
  },
  paperDescription: {
    backgroundColor: '#F1F1F1',
    padding: theme.spacing(2),
  },
  gridModal: {
    padding: theme.spacing(2),
  },
  modalButton: {
    padding: theme.spacing(1),
  },
  selectAction: {},
})

export default styles
