import React from 'react'
import { Paper, IconButton, Typography } from '@material-ui/core'
import { Download as DownloadIcon } from 'react-feather'

import useStyles from './styles'

const AttachmentBox = ({ attachment }) => {
  const classes = useStyles()

  const handleDownload = () => {
    window.open(attachment?.url, '_blank')
  }

  return (
    <Paper className={classes.root}>
      <Typography>{attachment?.name}</Typography>

      <IconButton onClick={handleDownload}>
        <DownloadIcon size={20} />
      </IconButton>
    </Paper>
  )
}

export default AttachmentBox
