const LEGITIMATE_INTEREST_FINALITY = 'legitimate_interest_finality'
const CONCRETE_SITUATION = 'concrete_situation'
const LEGITIMATE_EXPECTATION = 'legitimate_expectation'
const SAFEGUARDS_AND_MECHANISMS = 'safeguards_and_mechanisms'
const NATURE_OF_PERSONAL_DATA = 'nature_of_personal_data'
const DATA_CHILDREN_TEENAGERS = 'data_children_teenagers'
const TREATMENT_INTENDED_PURPOSE = 'treatment_intended_purpose'
const MINIMIZATION = 'minimization'
const RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS =
  'risks_impacts_fundamental_rights_freedoms'
const CONCLUSION_LIA_DPO = 'conclusion_lia'

const FINALITY_STEPS = [LEGITIMATE_INTEREST_FINALITY, CONCRETE_SITUATION]
const BALANCING_SAFEGUARING_STEPS = [
  LEGITIMATE_EXPECTATION,
  SAFEGUARDS_AND_MECHANISMS,
]

const FINALITY_STEPS_DPO = [
  NATURE_OF_PERSONAL_DATA,
  DATA_CHILDREN_TEENAGERS,
  LEGITIMATE_INTEREST_FINALITY,
  CONCRETE_SITUATION,
]
const NECESSITY_STEPS_DPO = [TREATMENT_INTENDED_PURPOSE, MINIMIZATION]
const BALANCING_SAFEGUARING_STEPS_DPO = [
  LEGITIMATE_EXPECTATION,
  RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS,
  SAFEGUARDS_AND_MECHANISMS,
]

const DPO_STEPS = {
  FINALITY_STEPS_DPO,
  NECESSITY_STEPS_DPO,
  BALANCING_SAFEGUARING_STEPS_DPO,
}

const FINALITY_LABEL = 'finality'
const NECESSITY_LABEL = 'necessity'

const STEP_LABELS = {
  FINALITY_LABEL,
  NECESSITY_LABEL,
}

const NATURE_OF_PERSONAL_DATA_LABEL = 'Natureza dos dados pessoais'
const DATA_CHILDREN_TEENAGERS_LABEL = 'Dados de crianças e adolescentes'
const LEGITIMATE_INTEREST_FINALITY_LABEL = 'Interesse e finalidades legítimas'
const CONCRETE_SITUATION_LABEL = 'Situação concreta'
const TREATMENT_INTENDED_PURPOSE_LABEL = 'Tratamento e finalidade pretendida'
const MINIMIZATION_LABEL = 'Minimização'
const LEGITIMATE_EXPECTATION_LABEL = 'Legítima expectativa'
const RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS_LABEL =
  'Riscos e impactos aos direitos e liberdades fundamentais'
const SAFEGUARDS_AND_MECHANISMS_LABEL =
  'Salvaguardas e mecanismos de opt-out e de oposição'

const KIND_LABELS = {
  [LEGITIMATE_INTEREST_FINALITY]: LEGITIMATE_INTEREST_FINALITY_LABEL,
  [NATURE_OF_PERSONAL_DATA]: NATURE_OF_PERSONAL_DATA_LABEL,
  [DATA_CHILDREN_TEENAGERS]: DATA_CHILDREN_TEENAGERS_LABEL,
  [CONCRETE_SITUATION]: CONCRETE_SITUATION_LABEL,
  [TREATMENT_INTENDED_PURPOSE]: TREATMENT_INTENDED_PURPOSE_LABEL,
  [MINIMIZATION]: MINIMIZATION_LABEL,
  [LEGITIMATE_EXPECTATION]: LEGITIMATE_EXPECTATION_LABEL,
  [RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS]:
    RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS_LABEL,
  [SAFEGUARDS_AND_MECHANISMS]: SAFEGUARDS_AND_MECHANISMS_LABEL,
}

const CLIENT = 'client'
const DPO = 'dpo'

const ROLES = { CLIENT, DPO }

const KINDS = {
  CONCRETE_SITUATION,
  DATA_CHILDREN_TEENAGERS,
  LEGITIMATE_EXPECTATION,
  LEGITIMATE_INTEREST_FINALITY,
  MINIMIZATION,
  NATURE_OF_PERSONAL_DATA,
  RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS,
  SAFEGUARDS_AND_MECHANISMS,
  TREATMENT_INTENDED_PURPOSE,
  CONCLUSION_LIA_DPO,
}

const KINDS_ARRAY = [
  CONCRETE_SITUATION,
  DATA_CHILDREN_TEENAGERS,
  LEGITIMATE_EXPECTATION,
  LEGITIMATE_INTEREST_FINALITY,
  MINIMIZATION,
  NATURE_OF_PERSONAL_DATA,
  RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS,
  SAFEGUARDS_AND_MECHANISMS,
  TREATMENT_INTENDED_PURPOSE,
  CONCLUSION_LIA_DPO,
]

const REPORT_LIA_LABELS = {
  TABLE: {
    FINALITY: 'Finalidade',
    NECESSECITY: 'Necessidade',
    BALANCING_SAFEGUARDIN: 'Balanceamento e Salvarguarda',
    CONCLUSION: 'Conclusão',
  },
  SUBTABLE: {
    NATURE_OF_PERSONAL_DATA: NATURE_OF_PERSONAL_DATA_LABEL,
    DATA_CHILDREN_TEENAGERS: DATA_CHILDREN_TEENAGERS_LABEL,
    LEGITIMATE_INTEREST_FINALITY: LEGITIMATE_INTEREST_FINALITY_LABEL,
    CONCRETE_SITUATION: CONCRETE_SITUATION_LABEL,
    TREATMENT_INTENDED_PURPOSE: TREATMENT_INTENDED_PURPOSE_LABEL,
    MINIMIZATION: MINIMIZATION_LABEL,
    LEGITIMATE_EXPECTATION: LEGITIMATE_EXPECTATION_LABEL,
    RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS:
      RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS_LABEL,
    SAFEGUARDS_AND_MECHANISMS: SAFEGUARDS_AND_MECHANISMS_LABEL,
  },
  FIRST_QUESTION: '1 - Qual a finalidade do tratamento?',
}

const QUESTION_LEGITIMATE_INTEREST = 37
const QUESTION_PREVENT_FRAUD = 38
const QUESTION_COMMENT = 39
const QUESTION_DATE = 40
const QUESTIONS_POSSIBLE_USE_DATA_TREATMENT = [
  QUESTION_LEGITIMATE_INTEREST,
  QUESTION_PREVENT_FRAUD,
]

const QUESTIONS_ORDERS = {
  QUESTION_COMMENT,
  QUESTION_DATE,
  QUESTION_LEGITIMATE_INTEREST,
  QUESTION_PREVENT_FRAUD,
  QUESTIONS_POSSIBLE_USE_DATA_TREATMENT,
}

const liaQuestions = {
  BALANCING_SAFEGUARING_STEPS,
  CONCRETE_SITUATION,
  DPO_STEPS,
  FINALITY_STEPS,
  KIND_LABELS,
  KINDS,
  KINDS_ARRAY,
  LEGITIMATE_EXPECTATION,
  LEGITIMATE_INTEREST_FINALITY,
  QUESTIONS_ORDERS,
  REPORT_LIA_LABELS,
  ROLES,
  SAFEGUARDS_AND_MECHANISMS,
  STEP_LABELS,
}

export default liaQuestions
