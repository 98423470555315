import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    background: `${theme.colors.firstStep.background.secondary}80 0% 0% no-repeat padding-box`,
    borderRadius: 20,
    padding: theme.spacing(2.5),
  },
  noOpacityBackground: {
    background: `${theme.colors.firstStep.secondary} 0% 0% no-repeat padding-box`,
  },
}))

export default styles
