import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Box, makeStyles } from '@material-ui/core'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'

import styles from './styles'
import helpers from 'helpers'
import constants from 'constants/index'

import gestaoDeCookiesImage from 'images/gestaodecookies.png'
import melhoriaContinuaImage from 'images/melhoriacontinua.png'

const useStyles = makeStyles(styles)

const Carousel = ({ handleFilterChange }) => {
  const classes = useStyles()

  const { mixpanel } = helpers

  const banners = [
    {
      altText: 'banner de boas vindas ao marketplace DPO',
      title: 'Buscar gestão de cookies',
      srcImg: gestaoDeCookiesImage,
      action: () => {
        handleFilterChange(constants.marketplace.CATEGORIES_IDS.cookie)
        mixpanel.track('Marktplace', 'Home Carrousel', {
          action: 'Clique banner Gestão de Cookies',
        })
      },
    },
    {
      altText: 'banner de melhoria contínua',
      title: 'Buscar melhoria contínua',
      srcImg: melhoriaContinuaImage,
      action: () => {
        handleFilterChange(constants.marketplace.CATEGORIES_IDS.rmc)
        mixpanel.track('Marktplace', 'Home Carrousel', {
          action: 'Clique banner Gestão de Cookies',
        })
      },
    },
  ]

  return (
    <ReactCarousel
      interval={6000}
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      autoPlay
      className={classes.container}
    >
      {banners.map((banner, idx) => (
        <Box
          onClick={banner.action}
          key={idx}
          title={banner.title}
          className={classes.itemContainer}
        >
          <img
            src={banner.srcImg}
            alt={banner.alt}
            className={clsx(classes.imageContainer, classes.banner)}
          />
        </Box>
      ))}
    </ReactCarousel>
  )
}

Carousel.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
}

export default Carousel
