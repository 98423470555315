import constants from 'constants/index'

const typeControllerIsPresent = (agents) => {
  const isPresent = agents?.map((agent) => {
    if (agent?.agentType === constants.dataTreatmentAgents.CONTROLLER_TYPE) {
      return true
    }

    return false
  })

  return isPresent.includes(true)
}

export default typeControllerIsPresent
