import React from 'react'

import { DashboardChartPaper, DonutChart } from 'components'

import theme from 'theme'

const SecurityMeasuresStatus = ({ questionControlStats, ...rest }) => {
  const measuresOnAdoptedStatus = questionControlStats?.status?.adopted || 0
  const measuresOnPendingStatus = questionControlStats?.status?.pending || 0
  const measuresOnOverdueStatus = questionControlStats?.status?.overdue || 0
  const measuresOnRiskAssumedStatus =
    questionControlStats?.status?.riskAssumed || 0
  const measuresOnTreatedStatus = questionControlStats?.status?.treated || 0
  const measuresOnInProgressStatus =
    questionControlStats?.status?.inProgress || 0

  return (
    <DashboardChartPaper
      title="Status de Medidas"
      subtitle="Pendente, em revisão, reprovadas, aprovadas, inativas"
      heightContainer="fit-content"
      {...rest}
    >
      <DonutChart
        legendShow
        names={[
          'Adotado',
          'Pendente',
          'Atrasado',
          'Risco Assumido',
          'Tratado',
          'Em Andamento',
        ]}
        values={[
          measuresOnAdoptedStatus,
          measuresOnPendingStatus,
          measuresOnOverdueStatus,
          measuresOnRiskAssumedStatus,
          measuresOnTreatedStatus,
          measuresOnInProgressStatus,
        ]}
        title="Medidas"
        colors={[
          theme.palette.dashboard.chart.measures.adopted,
          theme.palette.dashboard.chart.measures.pending,
          theme.palette.dashboard.chart.measures.overdue,
          theme.palette.dashboard.chart.measures.riskAssumed,
          theme.palette.dashboard.chart.measures.treated,
          theme.palette.dashboard.chart.measures.inProgress,
          theme.palette.dashboard.chart.measures.inactive,
        ]}
        showName={false}
      />
    </DashboardChartPaper>
  )
}

export default SecurityMeasuresStatus
