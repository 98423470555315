import helpers from 'helpers'
import constants from 'constants/index'
import { orderBy, uniq } from 'lodash'

const separateLogsByDate = (dponetAudits, mailLogs) => {
  const { dateLocaleFormat } = helpers.formatters

  const allLogs = [
    ...dponetAudits,
    ...mailLogs.map((mailLog) => ({
      ...mailLog,
      action: constants.logs.MAIL_ACTION,
    })),
  ]
  const orderedDays = allLogs
    .map((audit) => new Date(audit.createdAt).getTime())
    .sort()
    .reverse()

  const days = uniq(orderedDays.map((day) => dateLocaleFormat(day, 'LL')))

  const logsByDay = {}
  days.forEach((day) => {
    logsByDay[day] = orderBy(
      allLogs.filter((log) => dateLocaleFormat(log.createdAt, 'LL') === day),
      ['createdAt'],
      ['desc'],
    )
  })

  return logsByDay
}

export default separateLogsByDate
