import React from 'react'
import { CircularProgress } from '@material-ui/core'

const LoadingIcon = ({ icon: Icon, isLoading, ...rest }) => {
  if (isLoading) {
    return <CircularProgress color="inherit" {...rest} />
  }

  return <Icon {...rest} />
}

export default LoadingIcon
