import api from 'service/api'

const dponetAPI = api.create('dponet')

const create = async ({ nonComplianceReportId, ...data }) => {
  return await dponetAPI.post(
    `/non_compliance_reports/${nonComplianceReportId}/non_compliance_report_actions`,
    data,
  )
}

const update = async ({ nonComplianceReportId, nonComplianceReportActionId, ...data }) => {
  return await dponetAPI.put(
    `/non_compliance_reports/${nonComplianceReportId}/non_compliance_report_actions/${nonComplianceReportActionId}`,
    data,
  )
}

const destroy = async ({ nonComplianceReportId, nonComplianceReportActionId, ...data }) => {
  return await dponetAPI.delete(
    `/non_compliance_reports/${nonComplianceReportId}/non_compliance_report_actions/${nonComplianceReportActionId}`,
    data,
  )
}

export default { create, update, destroy }
