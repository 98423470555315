import helpers from 'helpers'
import constants from 'constants/index'

const logTitleByAction = (
  action,
  changes,
  object,
  entity,
  registerTranslated = 'registro',
) => {
  switch (action) {
    case constants.logs.MAIL_ACTION:
      if (entity === constants.logs.HELPER_ENTITIES.companySupplier) {
        if (object?.supplierResponsibleEmail === changes?.userEmail) {
          return `Envio de e-mail ao ${registerTranslated}`
        }
        return 'Envio de e-mail ao responsável da empresa cliente'
      }
      return `Envio de e-mail para ${object?.userEmail}`
    case constants.logs.CREATE_ACTION:
      return `O ${registerTranslated} foi cadastrado`
    case constants.logs.DESTROY_ACTION:
      return `O ${registerTranslated} foi removido`
    default:
      if (changes[0][0] === 'status') {
        const translatedStatus = helpers[entity]?.statusLogTitle(
          changes[0][1][1],
        )
        if (!!translatedStatus) {
          return translatedStatus
        }
      }
      return `O ${registerTranslated} foi alterado`
  }
}

export default logTitleByAction
