import React from 'react'
import { Box, Grid, Hidden, Typography, makeStyles } from '@material-ui/core'

import styles from './styles'

import gapAnalysisImage from 'images/gap_analysis_first_step.svg'
const useStyles = makeStyles(styles)

const GapAnalysisHeader = ({ title, children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.recommendationHeader}>
      <Grid container>
        <Hidden smDown>
          <Grid item md={4}>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={4}
            >
              <img
                alt="GapAnalysisImage"
                src={gapAnalysisImage}
                className={classes.gapAnalysisImage}
              />
            </Box>
          </Grid>
        </Hidden>
        <Grid item md={8} sm={12}>
          <Box
            display="flex"
            alignItems="center"
            height="100%"
            pt={2}
            pl={4}
            pr={7}
          >
            <Typography
              className={classes.title}
              color="primary"
              variant="h4"
              align="justify"
            >
              {title}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box {...(children && { p: 4 })}>{children}</Box>
    </Box>
  )
}

export default GapAnalysisHeader
