const MAX_FILE_SIZE = 10 * 1024 * 1024
const FILE_TYPES_IMAGE = '.jpg,.gif,.png,.jpeg,.svg,.webp'
const FILE_TYPES_DOCS = '.doc,.docx,.ppt,.pptx,.txt,.pdf,.csv'
const ALL_TYPES = FILE_TYPES_IMAGE + FILE_TYPES_DOCS

export default {
  ALL_TYPES,
  FILE_TYPES_DOCS,
  FILE_TYPES_IMAGE,
  MAX_FILE_SIZE,
}
