import constants from 'constants/index'

const chipStatusColor = (status, classes) => {
  switch (status) {
    case constants.order.status.REQUESTED_STATUS:
      return classes.chipRequested
    case constants.order.status.PENDING_STATUS:
      return classes.chipPending
    case constants.order.status.ACTIVED_STATUS:
      return classes.chipActived
    case constants.order.status.CANCELED_STATUS:
      return classes.chipCanceled
    default:
      return null
  }
}

export default chipStatusColor
