const FIXED_PRICE_TYPE = 'FIXED_PRICE'
const VALUE_TYPE = 'VALUE'
const PERCENTAGE_TYPE = 'PERCENTAGE'

const FIXED_PRICE_LABEL = 'Preço Fixo'
const VALUE_LABEL = 'Desconto em reais'
const PERCENTAGE_LABEL = 'Desconto em porcentagem'

const discountCoupon = {
  FIXED_PRICE_TYPE,
  VALUE_TYPE,
  PERCENTAGE_TYPE,
  FIXED_PRICE_LABEL,
  VALUE_LABEL,
  PERCENTAGE_LABEL,
}

export default discountCoupon
