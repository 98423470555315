import * as yup from 'yup'

const schema = ({ isNew }) =>
  yup.object().shape({
    name: yup.string().max(255).required(),
    description: yup.string().max(3000),
    ...(isNew && {
      responsible: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.number().required(),
            name: yup.string().required(),
          }),
        )
        .required()
        .typeError('Preencha esse campo'),
    }),
  })

export default schema
