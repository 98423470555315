import React from 'react'
import PropTypes from 'prop-types'

import { DashboardChartPaper, DonutChart } from 'components'

import theme from 'theme'

const DistributionRisksDegreeSeverity = ({ id, fragilityStats, ...rest }) => {
  const lowValue = fragilityStats?.fragility?.low || 0
  const mediumValue = fragilityStats?.fragility?.medium || 0
  const highValue = fragilityStats?.fragility?.high || 0
  const severeValue = fragilityStats?.fragility?.severe || 0

  return (
    <DashboardChartPaper
      title="Distribuição de ameaças por grau de severidade"
      subtitle="Severa, alta, média, baixa"
      heightContainer="fit-content"
      {...rest}
    >
      <DonutChart
        id={id}
        legendShow
        names={['Baixo', 'Médio', 'Alto', 'Severo']}
        values={[lowValue, mediumValue, highValue, severeValue]}
        title="Ameaças"
        colors={[
          theme.palette.dashboard.chart.measures.adopted,
          theme.palette.dashboard.chart.measures.riskAssumed,
          theme.palette.dashboard.chart.measures.pending,
          theme.palette.dashboard.chart.measures.overdue,
        ]}
        showName={false}
      />
    </DashboardChartPaper>
  )
}

DistributionRisksDegreeSeverity.propTypes = {
  id: PropTypes.string,
  fragilityStats: PropTypes.object.isRequired,
}

export default DistributionRisksDegreeSeverity
