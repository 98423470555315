import helpers from 'helpers'
import constants from 'constants/index'

const mountDataProcessSources = (
  dataProcessSources,
  departments,
  watchSourceDescription,
  isDefault = false,
) => {
  var items = isDefault ? dataProcessSources : []

  if (!isDefault) {
    if (watchSourceDescription === constants.dataProcess.DEPARTMENT_SOURCE) {
      items = departments
    } else {
      const formattedSource = helpers.dataProcess.convertSourceKind(
        watchSourceDescription,
      )
      items = dataProcessSources?.filter(
        (dataProcessSource) => dataProcessSource.kind === formattedSource,
      )
    }
  }

  return items.map((dpSource) => ({
    entityId: isDefault ? dpSource.entityId : dpSource.id,
    entityName: dpSource.name,
  }))
}

export default mountDataProcessSources
