import React, { useState, useEffect } from 'react'

import {
  Grid,
  Box,
  Radio,
  FormControlLabel,
  Typography,
  Divider,
  FormControl,
  FormGroup,
  TextField,
} from '@material-ui/core'

import { Controller } from 'react-hook-form'

import constants from 'constants/index'

const AvailableRisk = ({ setValue, control, checkedValue }) => {
  const [selected, setSelected] = useState('')

  const { AVAILABLE_RISK_OPTIONS } = constants.incidents

  useEffect(() => {
    if (checkedValue) {
      setSelected(checkedValue)
    }
    // eslint-disable-next-line
  }, [])

  const isChecked = (value) => selected === value

  useEffect(() => {
    setValue('assessmentTypeRisk', selected)
  }, [selected, setValue])

  return (
    <>
      <Grid item xs={12}>
        <Box mb={2} px={2}>
          <Typography variant="h6">Avaliação do risco</Typography>
        </Box>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <FormControl size="small" variant="outlined">
            <FormGroup>
              {AVAILABLE_RISK_OPTIONS?.map((option) => {
                return (
                  <FormControlLabel
                    control={
                      <Controller
                        name="assessmentTypeRisk"
                        onChange={() => setSelected(option?.id)}
                        as={
                          <Radio
                            size="medium"
                            checked={isChecked(option?.id)}
                          />
                        }
                        control={control}
                      />
                    }
                    label={
                      <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{
                          __html: option?.name,
                        }}
                      />
                    }
                    key={option?.id}
                  />
                )
              })}
            </FormGroup>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Controller
            as={
              <TextField
                multiline
                rows={5}
                label="Justifique, se cabível, avaliação do risco do incidente:"
                type="text"
                color="primary"
                variant="outlined"
                fullWidth
              />
            }
            control={control}
            name="justificationRiskAssessment"
            mode="onBlur"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box py={2}>
          <Divider />
        </Box>
      </Grid>
    </>
  )
}

export default AvailableRisk
