import React from 'react'
import { useFormContext } from 'react-hook-form'
import PerfectScrollbar from 'react-perfect-scrollbar'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import styles from './styles'
import theme from 'theme'

const PrimaryLevelBanner = ({ size, handleOpenBanner }) => {
  const { watch } = useFormContext()

  const smOnly = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  })

  const useStyles = styles(size)
  const classes = useStyles()

  const description = watch('description')

  const isFull = size === 'full'

  return (
    <Paper variant="outlined" className={classes.paperContainer}>
      <Grid container spacing={isFull && smOnly ? 4 : 2} alignItems="center">
        <Grid
          item
          xs={12}
          sm={isFull ? 7 : 12}
          component={Box}
          display="flex"
          flexDirection="column"
          textAlign="justify"
          gridGap={theme.spacing(1.5)}
        >
          <Typography className={classes.title}>
            Controle sua privacidade
          </Typography>
          <PerfectScrollbar>
            <Box maxHeight={100} width="100%">
              <Typography className={classes.globalTypography}>
                {description}
              </Typography>
            </Box>
          </PerfectScrollbar>
          <Link
            href="#"
            variant="body1"
            underline="always"
            className={classes.globalTypography}
            noWrap
          >
            Política de Cookies
          </Link>
        </Grid>
        <Grid item xs={12} sm={isFull ? 5 : 12}>
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid
              item
              xs={12}
              sm={isFull ? 12 : 3}
              md={4}
              xl={isFull ? 4 : 3}
              className={classes.settingsGrid}
            >
              <Button
                className={clsx(classes.button, classes.settingsButton)}
                variant="text"
                fullWidth
              >
                Gerenciar cookies
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={isFull ? 12 : 3}
              md={isFull ? 4 : 3}
              xl={isFull ? 4 : 3}
              className={classes.rejectGrid}
            >
              <Button
                className={clsx(classes.button, classes.rejectButton)}
                variant="outlined"
                fullWidth
                onClick={handleOpenBanner}
              >
                Rejeitar
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={isFull ? 12 : 3}
              md={4}
              xl={isFull ? 4 : 3}
              className={classes.acceptGrid}
            >
              <Button
                className={clsx(classes.button, classes.acceptButton)}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleOpenBanner}
              >
                Aceitar Todos
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

PrimaryLevelBanner.propTypes = {
  size: PropTypes.oneOf(['default', 'medium', 'full']),
  handleOpenBanner: PropTypes.func.isRequired,
}

export default PrimaryLevelBanner
