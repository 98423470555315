import React, { useEffect, useState } from 'react'

import { Filters } from 'components'

import useFetch from 'hooks/useFetch'
import usePermissions from 'hooks/usePermissions'
import constants from 'constants/index'

import * as service from 'service'

const CompanyFragilityFilters = ({ filter }) => {
  const permissions = usePermissions()

  const measuresPermitted = permissions.permitted(
    constants.permissions.DIAGNOSTICS.LIST,
  )

  const [shouldLoadMeasures, setShouldLoadMeasures] = useState(true)
  const [shouldLoadDeparments, setShouldLoadDeparments] = useState(true)
  const [departmentSelection, setDepartmentSelection] = useState(false)

  useEffect(() => {
    if (filter.drawerOpen) {
      if (shouldLoadMeasures) {
        setShouldLoadMeasures(false)
      }

      if (departmentSelection && shouldLoadDeparments) {
        setShouldLoadDeparments(false)
      }
    }
    //eslint-disable-next-line
  }, [filter.drawerOpen, departmentSelection])

  const { response: responseDepartments } = useFetch(
    service.dponet.departments.get,
    {
      perPage: 99999,
    },
    [shouldLoadDeparments],
    filter.drawerOpen && departmentSelection && shouldLoadDeparments,
  )

  const { response: responseControls } = useFetch(
    service.dponet.questionControls.list,
    { perPage: 9999 },
    [shouldLoadMeasures],
    filter.drawerOpen && shouldLoadMeasures && measuresPermitted,
  )

  const setEntityFilterType = ({ target: { value } }) => {
    setDepartmentSelection(
      !!value && value !== constants.fragilityEntities.COMPANY_ENTITY_TYPE,
    )
  }

  return (
    <Filters filter={filter}>
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Ameaças" name="name" />
      <select
        textfieldinput="true"
        label="Medidas Vinculadas"
        name="vinculated_measures"
      >
        <option value=""></option>
        <option value="true">Sim</option>
        <option value="false">Não</option>
      </select>
      <select
        multipleselectinput="true"
        label="Filtrar por medida"
        name="measure_ids"
        optionvalues={
          responseControls?.data?.questionControls?.map((control) => ({
            id: control?.id,
            label: control?.title,
            name: control?.title,
          })) || []
        }
      />
      <select
        textfieldinput="true"
        label="Local de ocorrência"
        onChange={setEntityFilterType}
        name="entity_type"
      >
        <option value=""></option>
        <option value="Company">Toda a organização</option>
        <option value="Department">Departamento</option>
        <option value="DataProcess">Processo</option>
      </select>
      {departmentSelection && (
        <select
          multipleselectinput="true"
          label="Selecionar departamentos"
          name="entity_ids"
          optionvalues={responseDepartments?.data?.departments || []}
        />
      )}
      <select textfieldinput="true" label="Impacto" name="impact_id">
        <option value=""></option>
        {Object.values(constants.fragilities.FRAGILITIES).map((fragility) => (
          <option key={fragility.id} value={fragility.id}>
            {fragility.name}
          </option>
        ))}
      </select>
      <select textfieldinput="true" label="Probabilidade" name="probability_id">
        <option value=""></option>
        {Object.values(constants.fragilities.FRAGILITIES).map((fragility) => (
          <option key={fragility.id} value={fragility.id}>
            {fragility.name}
          </option>
        ))}
      </select>
      <select textfieldinput="true" label="Nível" name="fragility_id">
        <option value=""></option>
        {Object.values(constants.fragilities.FRAGILITIES).map((fragility) => (
          <option key={fragility.id} value={fragility.id}>
            {fragility.name}
          </option>
        ))}
      </select>
    </Filters>
  )
}

export default CompanyFragilityFilters
