import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useSnackbar from 'hooks/useSnackbar'
import { Controller, useForm } from 'react-hook-form'
import {
  Grid,
  TextField,
  Box,
  MenuItem,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'

import { Label, Select as SelectComponent } from 'components'
import DataProcessTemplateForm from '../DataProcessTemplateForm'

import useDataProcessOptions from 'hooks/useDataProcessOptions'
import useDataProcessTemplateQuestion from 'hooks/useDataProcessTemplateQuestion'

import { dataLifeCycleSchema } from './schemas'
import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'
import styles from './styles'

const useStyles = makeStyles(styles)

const FormModal = ({
  dataProcessId,
  handleCloseModal = () => {},
  submitSuccess = () => {},
  dataLifeCycle,
  dataProcess,
  mode,
}) => {
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()

  const show = mode === 'show'
  const volumetryOptions = helpers.dataProcess.selectVolumetryOptions()
  const storageTypeFormated = dataLifeCycle?.storageType

  const {
    triggerValidation: triggerTemplateValidation,
    getValues: getTemplateValues,
  } = useDataProcessTemplateQuestion()

  const {
    errors,
    watch,
    control,
    setError,
    getValues,
    triggerValidation: triggerFormValidation,
  } = useForm({
    validationSchema: dataLifeCycleSchema(),
    defaultValues: {
      dataProcessId: dataProcessId ?? '',
      dataLifeCycleId: dataLifeCycle?.id ?? '',
      storageLocationId: dataLifeCycle?.storageLocation?.id ?? '',
      internalAllocationModeId: dataLifeCycle?.internalAllocationMode?.id ?? '',
      storageType: `${storageTypeFormated}` ?? '',
      value: dataLifeCycle?.value ?? '',
      volumetry: dataLifeCycle?.volumetry ?? '',
      retentionFinality: dataLifeCycle?.retentionFinality ?? '',
      discardModeId: dataLifeCycle?.discardMode?.id ?? '',
      recoveryId: dataLifeCycle?.recovery?.id ?? '',
      protectionId: dataLifeCycle?.protection?.id ?? '',
      anotherStorageLocation: dataLifeCycle?.anotherStorage?.name ?? '',
      anotherDiscardMode: dataLifeCycle?.anotherDiscard?.name ?? '',
      anotherRecovery: dataLifeCycle?.anotherRecovery?.name ?? '',
    },
  })
  const {
    storageLocations,
    internalAllocationModes,
    discardModes,
    recoveries,
    protections,
  } = useDataProcessOptions().data.options

  const {
    STORY_MODE_PERMANENT_TYPE,
    STORY_MODE_DEFINED_TYPE,
    STORAGE_DEFINED_TYPE,
    STORAGE_UNDEFINED_TYPE,
    STORAGE_PERMANENT_TYPE,
  } = constants.dataProcess

  const onSubmit = async (data, template) => {
    setLoading(true)
    try {
      if (dataLifeCycle) {
        await service.dponet.dataLifeCycles.put({
          dataProcessId,
          dataLifeCycleId: dataLifeCycle.id,
          lifeCycle: { ...data, dataProcessTemplateQuestions: template },
        })
      } else {
        await service.dponet.dataLifeCycles.create({
          dataProcessId,
          lifeCycle: { ...data, dataProcessTemplateQuestions: template },
        })
      }
      submitSuccess()
    } catch (error) {
      snackbar.open({
        message: 'Ocorreu algum erro! Tente novamente!',
        variant: 'error',
      })
      setLoading(false)
    }
  }

  const triggerValidation = async () => {
    const valueDataProcess = dataProcess?.value
    const volumetryDataProcess = dataProcess?.volumetry
    const storageMode = dataProcess?.storageMode

    const dataCollectedValidation = await triggerFormValidation()

    if (storageMode === STORY_MODE_DEFINED_TYPE) {
      let formattedStorageTimeDataProcess =
        helpers.dataProcess.storageTimeValidation(
          valueDataProcess,
          volumetryDataProcess,
        )

      let formattedStorageTimeLifeCycle =
        helpers.dataProcess.storageTimeValidation(
          getValues('value'),
          getValues('volumetry'),
        )

      if (formattedStorageTimeDataProcess < formattedStorageTimeLifeCycle) {
        setError('value', {
          type: {
            invalid:
              'Insira uma data menor do que a informada nas informações gerais do processo',
          },
        })

        setError('volumetry', {
          type: {
            invalid:
              'Insira uma data menor do que a informada nas informações gerais do processo',
          },
        })
        return false
      }
    }

    return dataCollectedValidation
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const templateValidation = await triggerTemplateValidation()
    const generalInformationValidation = await triggerValidation()

    if (templateValidation && generalInformationValidation) {
      onSubmit(getValues(), getTemplateValues())
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
        className={classes.root}
      >
        <Grid item lg={6} xs={12}>
          <Box mt={1}>
            <Controller
              control={control}
              name="storageLocationId"
              as={
                <TextField
                  label="Onde os dados são armazenados?"
                  select
                  color="primary"
                  variant="outlined"
                  error={!!errors.storageLocationId}
                  helperText={errors?.storageLocationId?.message}
                  disabled={show}
                  fullWidth
                >
                  {storageLocations?.map((storageLocation) => (
                    <MenuItem
                      key={storageLocation.id}
                      value={storageLocation.id}
                    >
                      <Typography>{storageLocation.name}</Typography>
                    </MenuItem>
                  ))}
                  <MenuItem key={'another'} value={'another'}>
                    <Typography>Outro</Typography>
                  </MenuItem>
                </TextField>
              }
              mode="onBlur"
            />
            {watch('storageLocationId') === 'another' && (
              <Box mr={0} mt={1}>
                <Controller
                  control={control}
                  name="anotherStorageLocation"
                  as={
                    <TextField
                      label="Outro: onde os dados são armazenados?"
                      color="primary"
                      variant="outlined"
                      error={!!errors.anotherStorageLocation}
                      helperText={errors?.anotherStorageLocation?.message}
                      disabled={show}
                      fullWidth
                    />
                  }
                  mode="onBlur"
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Box mt={1}>
            <Controller
              control={control}
              name="internalAllocationModeId"
              as={
                <TextField
                  label="Ambiente de alocação"
                  select
                  color="primary"
                  variant="outlined"
                  error={!!errors.internalAllocationModeId}
                  helperText={errors?.internalAllocationModeId?.message}
                  disabled={show}
                  fullWidth
                >
                  {internalAllocationModes?.map((internalAllocationMode) => (
                    <MenuItem
                      key={internalAllocationMode.id}
                      value={internalAllocationMode.id}
                    >
                      <Typography>{internalAllocationMode.name}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              }
              mode="onBlur"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1}>
            <Label
              title="Tempo de armazenamento"
              description="Por quanto tempo esses dados serão armazenados"
              item
            >
              <Controller
                control={control}
                name="storageType"
                as={
                  <RadioGroup>
                    <FormControlLabel
                      key={`${STORAGE_DEFINED_TYPE}`}
                      value={`${STORAGE_DEFINED_TYPE}`}
                      control={<Radio disabled={show} />}
                      label="Definido"
                      disabled={show}
                    />
                    <FormControlLabel
                      key={`${STORAGE_UNDEFINED_TYPE}`}
                      value={`${STORAGE_UNDEFINED_TYPE}`}
                      control={<Radio disabled={show} />}
                      label="Indefinido"
                      disabled={show}
                    />
                    {dataProcess?.storageMode === STORY_MODE_PERMANENT_TYPE && (
                      <FormControlLabel
                        key={`${STORAGE_PERMANENT_TYPE}`}
                        value={`${STORAGE_PERMANENT_TYPE}`}
                        control={<Radio disabled={show} />}
                        label="Permanente"
                        disabled={show}
                      />
                    )}
                  </RadioGroup>
                }
                mode="onChange"
              />
            </Label>
            {watch('storageType') === `${STORAGE_DEFINED_TYPE}` && (
              <>
                <Grid container spacing={1}>
                  <Grid item lg={6} xs={12}>
                    <Box mt={1}>
                      <Controller
                        name="value"
                        control={control}
                        as={
                          <TextField
                            label="Valor"
                            type="number"
                            variant="outlined"
                            error={!!errors.value}
                            disabled={show}
                            fullWidth
                            helperText={
                              (errors?.value?.message &&
                                'Qual é a quantidade do tempo?') ||
                              errors?.value?.types?.type?.invalid
                            }
                          />
                        }
                        mode="onBlur"
                        onChange={([e]) =>
                          e.target.value < 0 ? '0' : e.target.value
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Box mt={1}>
                      <Controller
                        name="volumetry"
                        control={control}
                        as={
                          <SelectComponent
                            items={volumetryOptions}
                            label="Unidade de tempo"
                            error={!!errors.volumetry}
                            disabled={show}
                            fullWidth
                            helperText={
                              (errors?.volumetry?.message &&
                                'Qual é o formato do tempo?') ||
                              errors?.value?.types?.type?.invalid
                            }
                          />
                        }
                        mode="onBlur"
                      />
                    </Box>
                  </Grid>
                  {!show && (
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        *Atenção: o tempo informado deve ser menor ou igual ao
                        das informações gerais do processo
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Box>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Box mt={1}>
            <Controller
              control={control}
              name="retentionFinality"
              as={
                <TextField
                  label="Justificativa de retenção"
                  multiline
                  minRows={3}
                  color="primary"
                  variant="outlined"
                  error={!!errors.retentionFinality}
                  helperText={errors?.retentionFinality?.message}
                  disabled={show}
                  fullWidth
                />
              }
              mode="onChange"
            />
          </Box>
        </Grid>
        {watch('storageType') !== `${STORAGE_PERMANENT_TYPE}` && (
          <Grid item xs={12}>
            <Box mt={1}>
              <Controller
                control={control}
                name="discardModeId"
                as={
                  <TextField
                    label="Como os dados são dispostos?"
                    select
                    color="primary"
                    variant="outlined"
                    error={!!errors.discardModeId}
                    helperText={errors?.discardModeId?.message}
                    disabled={show}
                    fullWidth
                  >
                    {discardModes?.map((discardMode) => (
                      <MenuItem key={discardMode.id} value={discardMode.id}>
                        <Typography>{discardMode.name}</Typography>
                      </MenuItem>
                    ))}
                    <MenuItem key={'another'} value={'another'}>
                      <Typography>Outro</Typography>
                    </MenuItem>
                  </TextField>
                }
                mode="onBlur"
              />
              {watch('discardModeId') === 'another' && (
                <Box mt={1}>
                  <Controller
                    control={control}
                    name="anotherDiscardMode"
                    as={
                      <TextField
                        label="Outro: como os dados são dispostos?"
                        color="primary"
                        variant="outlined"
                        error={!!errors.anotherDiscardMode}
                        helperText={errors?.anotherDiscardMode?.message}
                        disabled={show}
                        fullWidth
                      />
                    }
                  />
                </Box>
              )}
            </Box>
          </Grid>
        )}
        <Grid item lg={6} xs={12}>
          <Box mt={1}>
            <Controller
              control={control}
              name="recoveryId"
              as={
                <TextField
                  label="Forma de recuperação"
                  select
                  color="primary"
                  variant="outlined"
                  error={!!errors.recoveryId}
                  helperText={errors?.recoveryId?.message}
                  disabled={show}
                  fullWidth
                >
                  {recoveries?.map((recovery) => (
                    <MenuItem key={recovery.id} value={recovery.id}>
                      <Typography>{recovery.name}</Typography>
                    </MenuItem>
                  ))}
                  <MenuItem key={'another'} value={'another'}>
                    <Typography>Outro</Typography>
                  </MenuItem>
                </TextField>
              }
              mode="onBlur"
            />
            {watch('recoveryId') === 'another' && (
              <Box mt={1}>
                <Controller
                  control={control}
                  name="anotherRecovery"
                  as={
                    <TextField
                      label="Outro: forma de recuperação"
                      color="primary"
                      variant="outlined"
                      error={!!errors.anotherRecovery}
                      helperText={errors?.anotherRecovery?.message}
                      disabled={show}
                      fullWidth
                    />
                  }
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Box mt={1}>
            <Controller
              control={control}
              name="protectionId"
              as={
                <TextField
                  label="Forma de proteção"
                  select
                  color="primary"
                  variant="outlined"
                  error={!!errors.protectionId}
                  helperText={errors?.protectionId?.message}
                  disabled={show}
                  fullWidth
                >
                  {protections?.map((protection) => (
                    <MenuItem key={protection.id} value={protection.id}>
                      <Typography>{protection.name}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              }
              mode="onBlur"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DataProcessTemplateForm step="life_cycle" disabled={show} />
        </Grid>
      </Grid>
      {!show && (
        <Box display="flex" justifyContent="flex-start" my={2} mr={2}>
          <Box pl={2} pr={1}>
            <Button
              color="secondary"
              type="button"
              variant="outlined"
              onClick={() => handleCloseModal()}
            >
              Voltar
            </Button>
          </Box>
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
          >
            {loading ? 'Aguarde...' : 'Salvar'}
          </Button>
        </Box>
      )}
    </form>
  )
}

export default FormModal
