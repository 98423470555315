import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  video: {
    height: '50vh',
    [theme.breakpoints.down('sm')]: {
      height: '250px',
    },
  },
}))

export default styles
