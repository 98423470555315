import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'

import MultiSelect from '@kenshooui/react-multi-select'

import helpers from 'helpers'
import useStyles from './styles'
import * as service from 'service'

import { Label, LoadingFeedback } from 'components'
import schema from './schema'
import PropTypes from 'prop-types'

const DialogCopy = ({ dataProcess, open, redirectTo, setOpen, action }) => {
  const [departments, setDepartments] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const snackbar = useSnackbar()
  const classes = useStyles()

  const {
    userCompany: { company },
    companies,
  } = useAuth()

  const { errors, handleSubmit, control, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      companyId: company?.id,
    },
  })

  useEffect(() => {
    if (open) {
      const findDeparments = async () => {
        try {
          const response = await service.dponet.departments.get({
            companyId: watch('companyId'),
            minimal: true,
            perPage: 200,
          })
          setDepartments(response)
        } catch (e) {}
      }
      findDeparments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('companyId'), open])

  const companiesFilterActualCompany = companies?.filter(
    (companyFilter) => companyFilter?.id !== company?.id,
  )

  const departmentItems = departments?.data?.departments
    ?.map((value) => {
      if (value?.id === dataProcess?.department.id) {
        return {
          id: value?.id,
          company_id: value?.company?.id,
          label: value?.name,
          disabled: true,
        }
      } else {
        return {
          id: value?.id,
          company_id: value?.company?.id,
          label: value?.name,
        }
      }
    })
    .filter((department) => !department.disabled)

  const handleCopy = async (data) => {
    if (data) {
      setIsLoading(true)
      try {
        await Promise.all(
          data.departmentsMultiSelect.map((departments) =>
            service.dponet.dataProcesses.copy({
              dataProcessId: dataProcess?.id,
              departmentId: departments?.id,
              companyId: data?.companyId,
            }),
          ),
        ).then(() => {
          snackbar.open({
            message: 'Processamento de dados copiado com sucesso!',
            variant: 'success',
          })
          setOpen(false)
          setTimeout(action, 500)
        })
        setIsLoading(false)
      } catch (error) {
        snackbar.open({
          message: 'Erro ao copiar processamento de dados.',
          variant: 'error',
        })
        setOpen(false)
      }
    }
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          <Box>
            <Typography variant="h5">Copiar Processo</Typography>
          </Box>
        </DialogTitle>
        <Divider />

        <form onSubmit={handleSubmit(handleCopy)}>
          <Box mb={1} className={classes.root}>
            <DialogContent>
              <Controller
                as={
                  <TextField
                    select
                    variant="outlined"
                    fullWidth
                    error={!!errors.companyId}
                    helperText={
                      errors && errors.companyId && errors.companyId.message
                    }
                    className={classes.textField}
                  >
                    <MenuItem value={company?.id}>
                      {`${company?.name} - (CNPJ ${company?.document})`}
                    </MenuItem>
                    {companiesFilterActualCompany.map((company) => (
                      <MenuItem value={company.id} key={company?.id}>
                        {`${company?.name} - (CNPJ ${company?.document})`}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                control={control}
                name="companyId"
                mode="onChange"
              />
              <Label title="Departamentos">
                <Box className={classes.boxMultiSelect}>
                  <Controller
                    name="departmentsMultiSelect"
                    control={control}
                    as={
                      <MultiSelect
                        messages={helpers.dataProcess.messagesCopyDepartments()}
                        items={departmentItems}
                        wrapperClassName={classes.multiSelect}
                      />
                    }
                  />
                </Box>
              </Label>
            </DialogContent>
          </Box>
          <DialogActions
            p={2}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <Button
              type="button"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
            <Button variant="contained" type="submit" color="primary">
              Copiar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

DialogCopy.propTypes = {
  dataProcess: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  redirectTo: PropTypes.node,
  action: PropTypes.func,
}

DialogCopy.defaultProps = {
  setOpen: () => {},
  action: () => {},
}

export default DialogCopy
