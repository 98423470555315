import React, { useState } from 'react'
import clsx from 'clsx'
import qs from 'query-string'
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom'
import {
  Button,
  Collapse,
  IconButton,
  ListItem,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons'
import PropTypes from 'prop-types'

import styles from './styles'
import { PermittedBySupplier } from '../../components'

import { Badge } from './components'

import useDrawer from 'hooks/useDrawer'

const useStyles = makeStyles(styles)

const NavItem = ({
  title,
  href,
  depth,
  permission,
  children,
  somePermissions,
  show,
  menuDisabled,
  countToBadge,
  isDirectAction,
  handleDirectAction,
  icon: Icon,
  open: isOpen,
  info: Info,
  main,
  highlight,
  ...rest
}) => {
  const classes = useStyles()
  const { pathname, search } = useLocation()
  const { showEntireMenu } = useDrawer()
  const filters = qs.parse(search)

  const [open, setOpen] = useState(isOpen)

  const handleToggle = () => setOpen((prevOpen) => !prevOpen)

  let paddingLeft = 8

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  const btnStyle = { paddingLeft }

  const selectedPath = (uri) => {
    if (pathname === '/non-compliance-reports/' && filters?.entityType && uri) {
      if (uri.includes(`entityType=${filters?.entityType}`)) {
        return true
      }

      return false
    }

    return !!matchPath(pathname, { path: uri, exact: true })
  }

  if (!main && !showEntireMenu()) return <></>

  if (children && showEntireMenu()) {
    return (
      <PermittedBySupplier
        permission={permission}
        somePermissions={somePermissions}
        title={title}
        show={show}
      >
        <ListItem className={classes.item} disableGutters key={title} {...rest}>
          <Button
            className={clsx(classes.button, {
              [classes.selectedButton]: selectedPath(href),
              [classes.buttonDisabled]: menuDisabled,
            })}
            onClick={handleToggle}
            style={btnStyle}
            fullWidth
            disableElevation={true}
            disabled={menuDisabled}
          >
            {Icon && (
              <Icon
                className={clsx(classes.icon, {
                  [classes.buttonDisabled]: menuDisabled,
                })}
                size="20"
              />
            )}
            <Typography
              variant="body1"
              className={clsx(classes.title, {
                [classes.buttonDisabled]: menuDisabled,
              })}
            >
              {title}
            </Typography>
            {open ? (
              <ExpandLessIcon size="small" className={classes.expandedIcon} />
            ) : (
              <ExpandMoreIcon
                size="small"
                className={clsx(classes.expandedIcon, {
                  [classes.buttonDisabled]: menuDisabled,
                })}
              />
            )}
          </Button>
          <Collapse in={open}>{children}</Collapse>
        </ListItem>
      </PermittedBySupplier>
    )
  }

  return (
    <PermittedBySupplier
      permission={permission}
      somePermissions={somePermissions}
      title={title}
      show={show}
    >
      <ListItem
        className={main ? classes.item : classes.itemLeaf}
        disableGutters
        key={title}
        {...rest}
      >
        {showEntireMenu() ? (
          <Button
            className={clsx({
              [classes.button]: main,
              [classes.buttonLeaf]: !main,
              [classes.selectedButton]: selectedPath(href),
              [classes.buttonDisabled]: menuDisabled,
            })}
            {...(!isDirectAction
              ? {
                  component: RouterLink,
                  to: href,
                  exact: true,
                  activeClassName: classes.active,
                }
              : { onClick: handleDirectAction })}
            style={btnStyle}
            fullWidth
            disabled={menuDisabled}
          >
            {Icon && (
              <Icon
                className={clsx(classes.icon, {
                  [classes.buttonDisabled]: menuDisabled,
                })}
                size="20"
              />
            )}
            {main ? (
              <Typography
                variant="body1"
                className={clsx(classes.title, {
                  [classes.buttonDisabled]: menuDisabled,
                })}
              >
                {title}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                className={clsx(classes.title, {
                  [classes.buttonDisabled]: menuDisabled,
                })}
              >
                {title}
              </Typography>
            )}
            {countToBadge > 0 && (
              <Badge number={countToBadge} styles={classes.badgeStyle} />
            )}
            {Info && <Info className={classes.info} />}
          </Button>
        ) : (
          <Tooltip title={title} placement="right">
            <IconButton
              activeClassName={classes.active}
              className={clsx(classes.iconButton, {
                [classes.selectedButton]: selectedPath(href),
                [classes.buttonDisabled]: menuDisabled,
              })}
              component={RouterLink}
              exact
              to={href}
              disabled={menuDisabled}
            >
              {Icon && (
                <Icon
                  className={clsx(classes.iconButtonIcon, {
                    [classes.buttonDisabled]: menuDisabled,
                  })}
                  size="20"
                />
              )}
            </IconButton>
          </Tooltip>
        )}
      </ListItem>
    </PermittedBySupplier>
  )
}

NavItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

NavItem.defaultProps = {
  open: false,
  menuDisabled: false,
}

export default NavItem
