import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from '@material-ui/core'

import { Page, DialogUseLimitationNotice } from 'components'
import { ContentBase } from './components'

import qs from 'query-string'
import { isEmpty } from 'lodash'

import useTrailStep from 'hooks/useTrailStep'
import useAuth from 'hooks/useAuth'

import helpers from 'helpers'
import constants from 'constants/index'
import { routes } from 'Routes'

const UserTrailStepsMain = () => {
  const history = useHistory()
  const search = qs.parse(history?.location?.search)
  const { step: viewerStep } = search

  const { getActivePage, hideStepper, setActiveStep, setActiveSubStep } =
    useTrailStep()
  const { companyTrails } = useAuth()
  const trails = companyTrails.filter((trail) => !!trail.tag)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!isEmpty(trails) && loading) {
      setLoading(false)
      const actualStep = trails.find(
        (trail) => !trail.completed && !trail.jumped,
      )
      if (!!actualStep) {
        return setActiveStep(
          helpers.userTrailSteps.getIndexByTag(actualStep?.tag),
        )
      }

      return
    }

    if (
      !!viewerStep &&
      helpers.userTrailSteps.validRedirectionStep(viewerStep)
    ) {
      setLoading(false)
      setActiveStep(helpers.userTrailSteps.getIndexByTag(viewerStep))
      history.replace(routes.userTrailSteps)
      if (viewerStep === constants.userTrailSteps.SITE_CONFIGURATION_TAG) {
        return setActiveSubStep(
          constants.userTrailSteps.STEP_COOKIE_CONFIG_INDEX[1],
        )
      }
      setActiveSubStep(1)
    }
    //eslint-disable-next-line
  }, [trails, viewerStep])

  return (
    <Page title="Canal de comunicação" transparent>
      <Container maxWidth="lg">
        <ContentBase
          buttonAlign="center"
          maxWidth="lg"
          nextButtonText="Avançar"
          hideStepper={hideStepper}
        >
          {!loading && getActivePage()}
        </ContentBase>
      </Container>
      <DialogUseLimitationNotice />
    </Page>
  )
}

export default UserTrailStepsMain
