import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { Switch, Typography, Box } from '@material-ui/core'

const SwitchController = ({
  control,
  name = 'status',
  labelOption = { left: 'Inativo', right: 'Ativo' },
  option = { left: 'false', right: 'true' },
  dynamicChecked,
  staticChecked,
}) => {
  const onChecked = (checked) => {
    return checked ? option.right : option.left
  }
  const isChecked = dynamicChecked === staticChecked

  return (
    <Controller
      control={control}
      name={name}
      as={
        <Box display="flex" alignItems="center">
          <Typography>{labelOption?.left}</Typography>
          <Switch color="primary" defaultChecked={isChecked} />
          <Typography>{labelOption?.right}</Typography>
        </Box>
      }
      onChange={([event]) => onChecked(event.target.checked)}
      mode="onChange"
    />
  )
}

SwitchController.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string,
  isChecked: PropTypes.bool,
  option: PropTypes.object.isRequired,
  labelOption: PropTypes.object,
  dynamicChecked: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  staticChecked: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
}

export default SwitchController
