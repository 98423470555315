import palette from 'theme/palette'

import { WIDTH as WIDTHPdf, fontSize, PADDING } from '../constants'

const paragraph = (
  pdf,
  text,
  positionY,
  textColor = palette.primary.main,
  font = 'Roboto-Regular',
) => {
  const WIDTH = WIDTHPdf(pdf)

  pdf.setFont(font)
  pdf.setFontSize(fontSize.paragraph)
  pdf.setTextColor(textColor)

  var splited_paragraphs = pdf.splitTextToSize(text, WIDTH - PADDING * 2)

  pdf.text(splited_paragraphs, PADDING, positionY, null, null, 'justify')
}

export default paragraph
