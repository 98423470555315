import React from 'react'

const Mastercard = ({
  alt = 'Bandeira Mastercard',
  height = 28,
  viewBox = '0 0 45 28', // Valor total da imagem
  width = 45,
  grayscale = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
    >
      {grayscale && (
        <filter id="grayscale">
          <feColorMatrix
            type="matrix"
            values="0.3333 0.3333 0.3333 0 0
                                           0.3333 0.3333 0.3333 0 0
                                           0.3333 0.3333 0.3333 0 0
                                           0      0      0      1 0"
          />
        </filter>
      )}
      <g filter={grayscale ? 'url(#grayscale)' : ''}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5 24.3139C20.1169 26.3704 17.0255 27.6119 13.6475 27.6119C6.11021 27.6119 0 21.4308 0 13.806C0 6.18114 6.11021 0 13.6475 0C17.0255 0 20.1169 1.2415 22.5 3.29806C24.8831 1.2415 27.9745 0 31.3525 0C38.8898 0 45 6.18114 45 13.806C45 21.4308 38.8898 27.6119 31.3525 27.6119C27.9745 27.6119 24.8831 26.3704 22.5 24.3139Z"
          fill="#ED0006"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5 24.3139C25.4344 21.7816 27.2951 18.0136 27.2951 13.806C27.2951 9.59833 25.4344 5.83033 22.5 3.29806C24.8831 1.2415 27.9745 0 31.3525 0C38.8898 0 45 6.18114 45 13.806C45 21.4308 38.8898 27.6119 31.3525 27.6119C27.9745 27.6119 24.8831 26.3704 22.5 24.3139Z"
          fill="#F9A000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5 3.29813C25.4344 5.8304 27.2951 9.59838 27.2951 13.806C27.2951 18.0136 25.4344 21.7816 22.5 24.3138C19.5657 21.7816 17.705 18.0136 17.705 13.806C17.705 9.59838 19.5657 5.8304 22.5 3.29813Z"
          fill="#FF5E00"
        />
      </g>
    </svg>
  )
}

export default Mastercard
