import helpers from 'helpers/index'

const mountUserRow = (user) => [
  [
    'Introdução à LGPD',
    user?.introductionLgpdData?.createdAt
      ? `Completo em: ${helpers.formatters.date(
          user?.introductionLgpdData?.createdAt,
        )}`
      : 'Pendente',
  ],
  [
    'Conceitos fundamentais da LGPD',
    user?.fundamentalConceptsData?.createdAt
      ? `Completo em: ${helpers.formatters.date(
          user?.fundamentalConceptsData?.createdAt,
        )}`
      : 'Pendente',
  ],
]

export default mountUserRow
