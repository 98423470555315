import React, { useRef, useState } from 'react'
import { Upload as UploadIcon, X as XIcon } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Box,
  Button,
  TextField,
  Typography,
  colors,
  Link,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { BaseDialog } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'
import schema from './schema'
import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

const ProvideHolderData = ({ open, setOpen, ticket, refresh }) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()

  const fileInputRef = useRef(null)

  const questionDemand = ticket?.info?.questionDemand
  const specificField = questionDemand?.answer?.specificField
  const subOption = questionDemand?.answer?.subOption

  const { errors, control, handleSubmit, register } = useForm({
    validationSchema: schema(),
    mode: 'onChange',
  })

  const handleFileChange = (event) => {
    if (setSelectedFiles && selectedFiles) {
      const newFiles = Array.from(event?.target?.files || [])
      setSelectedFiles([...selectedFiles, ...newFiles])

      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  const handleRemoveFile = (fileName) => {
    if (setSelectedFiles && selectedFiles) {
      const updatedFiles = selectedFiles?.filter(
        (file) => file?.name !== fileName,
      )
      setSelectedFiles(updatedFiles)
    }
  }

  const sendHolderData = async (data) => {
    try {
      setLoading(true)
      await service.dponet.tickets.sendTitularData({
        ticketId: ticket?.id,
        titularData: true,
        ticketMessages: {
          content: data.description,
          attachments: selectedFiles,
        },
      })
      snackbar.open({
        message: 'Dados do titular enviado com sucesso',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  const redirectToFaqs = () => {
    window.open('https://app.dponet.com.br/faqs-documentations/show/101')
  }

  return (
    <form id="provide-holder-data" onSubmit={handleSubmit(sendHolderData)}>
      <BaseDialog
        open={open}
        title="Forneneça os dados do titular"
        setOpen={setOpen}
        actionButtonText="ENVIAR"
        closeButtonText="CANCELAR"
        formRef="provide-holder-data"
        loading={loading}
        justifyActions="end"
      >
        <Box>
          <Box>
            <Alert severity="info" className={classes.alert}>
              <AlertTitle>Analise o tipo de pedido</AlertTitle>
              Forneça o máximo de informações sobre o titular, para que o DPO
              possa responder à solicitação. Confira o tipo de pedido para
              fornecer informações compatíveis com os dados que existem na sua
              organização.
              <br /> <br />
              Para mais informações, consulte a nossa&nbsp;
              <Link onClick={redirectToFaqs} className={classes.link}>
                <strong>Central de Ajuda</strong>
              </Link>
              .
            </Alert>
          </Box>
          <Box className={classes.boxProvideHolder}>
            <Typography variant="h6">{questionDemand?.title}</Typography>
            <Typography
              variant="body1"
              gutterBottom={!!specificField || !!subOption}
            >
              {questionDemand?.answer?.title}
            </Typography>

            {!!specificField && (
              <>
                <Typography variant="h6">{specificField?.title}</Typography>
                <Typography variant="body1">
                  {specificField?.response}
                </Typography>
              </>
            )}

            {!!subOption && (
              <>
                <Typography variant="h6">{subOption?.title}</Typography>
                <Typography variant="body1">{subOption?.subAnswer}</Typography>
              </>
            )}
          </Box>
          <Box mt={2}>
            <Typography variant="h4">Informações do titular</Typography>
            {helpers.tickets.ticketInfo(ticket).map((info, index) => (
              <Box key={index} mt={index === 0 ? 1 : 0}>
                <Typography>
                  <strong>{info.label}</strong> {info.value}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box mt={2}>
            <Controller
              control={control}
              name="description"
              mode="onChange"
              error={!!errors.description}
              as={
                <TextField
                  label="Informações sobre o pedido do titular de dados"
                  multiline
                  minRows={3}
                  fullWidth
                />
              }
            />
          </Box>
          <Box className={classes.boxFileImport} mt={2} component="label">
            <Box display="none">
              <input
                {...register('attachments')}
                ref={fileInputRef}
                id="attachments"
                name="attachments"
                type="file"
                multiple
                onChange={handleFileChange}
                accept={constants.documentsValidations.ALL_TYPES}
              />
            </Box>
            <Box m={1} mt={2}>
              <UploadIcon />
            </Box>
            <Box m={1} mb={2}>
              <Typography>Anexe os documentos aqui (opcional)</Typography>
            </Box>
          </Box>
        </Box>
        <PerfectScrollbar>
          <Box display="flex" flexDirection="row">
            {selectedFiles &&
              selectedFiles?.map((file) => (
                <Box key={file?.name} m={2} p={3}>
                  <Button
                    type="button"
                    endIcon={
                      <XIcon
                        size={24}
                        onClick={() => handleRemoveFile(file?.name)}
                      />
                    }
                  >
                    {file?.name}
                    <Typography
                      color={colors.grey[400]}
                      display="flex"
                      pl={2}
                      noWrap
                    ></Typography>
                  </Button>
                </Box>
              ))}
          </Box>
        </PerfectScrollbar>
      </BaseDialog>
    </form>
  )
}

export default ProvideHolderData
