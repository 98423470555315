import { useContext } from 'react'

import DataProcessTemplateQuestionContext from 'contexts/DataProcessTemplateQuestionContext'

const useDataProcessTemplateQuestion = () => {
  const context = useContext(DataProcessTemplateQuestionContext)

  if (context === undefined) {
    throw new Error(
      'useDataProcessTemplateQuestion must be used within a DataProcessTemplateQuestionContext.Provider',
    )
  }

  return {
    dataProcessTemplateAnswers: context.dataProcessTemplateAnswers,
    control: context.control,
    watch: context.watch,
    errors: context.errors,
    step: context.step,
    questions: context.questions,
    triggerValidation: context.triggerValidation,
    getValues: context.getValues,
  }
}

export default useDataProcessTemplateQuestion
