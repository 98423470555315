import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
  },
  container: {
    minHeight: '100vh',
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  titleInfo: {
    color: theme.palette.primary.main,
    [theme.breakpoints.only('xs')]: {
      alignSelf: 'flex-start',
    },
  },
  gridContainer: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },
  },
  imgBox: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      backgroundSize: '400px',
      minHeight: '70vh',
      backgroundPositionX: '-8%',
      backgroundPositionY: 'bottom',
    },
    [theme.breakpoints.only('md')]: {
      backgroundPositionX: 'left',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      backgroundPositionX: '33%',
      justifyContent: 'center',
      backgroundSize: 'contain',
    },
  },
  dataBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    [theme.breakpoints.up('md')]: {
      minHeight: '52vh',
    },
  },
  boxReportData: {
    borderLeft: '4px solid white',
    paddingLeft: theme.spacing(1),
  },
  hexagonosImg: {
    [theme.breakpoints.between('md', 'xl')]: {
      marginBottom: -45,
      paddingLeft: 2,
    },
    [theme.breakpoints.only('md')]: {
      marginLeft: 65,
    },
    [theme.breakpoints.down('sm')]: {
      objectPosition: '0 5vw',
    },
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: 10,
  },
  radioLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  checked: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
}))

export default styles
