import { makeStyles } from '@material-ui/core'

const styles = (errors) =>
  makeStyles((theme) => ({
    buttonColor: {
      backgroundColor: errors?.email && theme.palette.error.main,
      '&:hover': {
        backgroundColor: errors?.email && theme.palette.error.dark,
      },
    },
    button: {
      marginLeft: 20,
    },
  }))

export default styles
