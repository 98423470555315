import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Grid, makeStyles } from '@material-ui/core'

import { Label, LoadingFeedback, Select } from 'components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'

import styles from './styles'

const useStyles = makeStyles(styles)

const ConsentSelectionForm = ({ setConsentForm }) => {
  const classes = useStyles()

  const { response, isLoading } = useFetch(service.dponet.consent.list, {
    perPage: 1000,
  })

  const { errors, control, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const consentFormId = watch('consentFormId')

  const { response: responseForm, isLoading: loadingForm } = useFetch(
    service.dponet.consent.get,
    {
      consentFormId,
    },
    [consentFormId],
    !!consentFormId,
  )

  setConsentForm(responseForm?.data?.consentForm)

  return (
    <>
      <LoadingFeedback isLoading={isLoading || loadingForm} />
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12}>
          <Label
            title="Formulário"
            description="Selecione qual formulário deseja registrar o consentimento"
          >
            <Controller
              as={
                <Select
                  items={response?.data?.consentForms || []}
                  fieldName="title"
                  error={!!errors.consentFormId}
                  helperText={errors?.consentFormId?.message}
                  fullWidth
                />
              }
              control={control}
              name="consentFormId"
              mode="onBlur"
            />
          </Label>
        </Grid>
      </Grid>
    </>
  )
}

export default ConsentSelectionForm
