import * as yup from 'yup'

const schema = (isJuridic) =>
  yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    ...(isJuridic && {
      stateRegistration: yup.string(),
      municipalRegistration: yup.string(),
    }),
    email: yup.string().required(),
    phone: yup.string().required(),
    notifyDataProcessChanges: yup.boolean(),
    siteUrl: yup.string(),
    postalCode: yup.string().required(),
    country: yup.string().required(),
    state: yup.string().required(),
    city: yup.string().required(),
    neighborhood: yup.string().required(),
    street: yup.string().required(),
    number: yup.string().required(),
    complement: yup.string(),
  })

export default schema
