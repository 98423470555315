const WELCOME_STEP = 1
const VALIDATE_DOCUMENT_STEP = 2
const DATA_CONFIRMATION_STEP = 3
const LOGIN_STEP = 4
const NOT_LINKED_STEP = 5
const CREATE_PASSWORD_STEP = 6
const NOT_PERMITED_STEP = 7

const VIDEO_URL =
  'https://player.vimeo.com/video/660687255?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'

const supplierInvite = {
  WELCOME_STEP,
  VALIDATE_DOCUMENT_STEP,
  DATA_CONFIRMATION_STEP,
  LOGIN_STEP,
  NOT_LINKED_STEP,
  CREATE_PASSWORD_STEP,
  NOT_PERMITED_STEP,
  VIDEO_URL,
}

export default supplierInvite
