import React, { useState } from 'react'
import { Plus as PlusIcon } from 'react-feather'
import PropTypes from 'prop-types'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import { ButtonAction, Permitted } from 'components'
import { ActivitiesTable } from './components'

import helpers from 'helpers'
import constants from 'constants/index'
import theme from 'theme'

const Activities = ({ setHaveTasks }) => {
  const [myActivities, setMyActivites] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  })

  const [openDialog, setOpenDialog] = useState(false)
  const [task, setTask] = useState()

  const handleCreate = () => {
    helpers.mixpanel.track('Home', 'Criar Atividade', {
      action: 'criar_atividade-home',
    })

    setTask()
    setOpenDialog(!openDialog)
  }

  return (
    <>
      <Box
        mt={4}
        mb={2}
        display="flex"
        alignItems={isMobile ? 'start' : 'center'}
        justifyContent="space-between"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Box {...(isMobile && { ml: 2, mb: 2 })}>
          <Typography color="textPrimary" variant="h3">
            Atividades
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
        >
          <Permitted tag={constants.permissions.TASKS.CREATE}>
            <Box mx={2}>
              <FormControlLabel
                onChange={(e) => setMyActivites(e?.target?.checked)}
                control={<Checkbox color="primary" />}
                label="Exibir apenas minhas atividades"
                labelPlacement="end"
              />
            </Box>
          </Permitted>
          <Permitted tag={constants.permissions.TASKS.CREATE}>
            <Box id={constants.myLgpd.HOME_DRIVER_STEP_8}>
              <ButtonAction
                variant="outlined"
                color="primary"
                action={handleCreate}
                startIcon={<PlusIcon width="18" />}
                name="CRIAR"
              />
            </Box>
          </Permitted>
        </Box>
      </Box>
      <Box mb={4}>
        <ActivitiesTable
          onlyMyActivities={myActivities}
          task={task}
          setTask={setTask}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          setHaveTasks={setHaveTasks}
        />
      </Box>
    </>
  )
}

Activities.propTypes = {
  setHaveTasks: PropTypes.func,
}

export default Activities
