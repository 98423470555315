import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Typography } from '@material-ui/core'

import { LoadingFeedback } from 'components'

import helpers from 'helpers'

const ListInviteAdvisor = ({
  setDisabled,
  invite,
  loading,
  inviteTypeText,
}) => {
  useEffect(() => {
    setDisabled(true)
    //eslint-disable-next-line
  }, [invite])

  return (
    <>
      <LoadingFeedback open={loading} />
      <Grid container spacing={3}>
        {invite ? (
          <Box width="100%" m={3}>
            <Grid item xs={12}>
              {invite?.name && (
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Nome
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {invite?.name}
                  </Typography>
                </Box>
              )}
              <Box mt={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Email
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {invite?.email}
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Status
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {helpers.inviteAdvisor.statusLabel(invite?.status)}
                </Typography>
              </Box>
            </Grid>
          </Box>
        ) : (
          <Box m={3} width="100%">
            <Typography color="textPrimary" variant="body1">
              Não existe nenhum {inviteTypeText} vinculado à empresa.
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  )
}

ListInviteAdvisor.propTypes = {
  setDisabled: PropTypes.func,
  invite: PropTypes.object,
  loading: PropTypes.bool,
  inviteTypeText: PropTypes.string,
}

ListInviteAdvisor.defaultProps = {
  setDisabled: () => {},
  invite: {},
}

export default ListInviteAdvisor
