import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const Header = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid
      className={classes.root}
      container
      justify="space-between"
      alignItems="center"
      spacing={3}
    >
      <Grid item>
        <Typography variant="h3" className={classes.customTypography}>
          Minha LGPD
        </Typography>
      </Grid>
      {children}
    </Grid>
  )
}

export default Header
