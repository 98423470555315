const pathname = 'portal-de-privacidade/'

const formatPortalUrl = (url) => {
  if (!url) {
    return '#!'
  }

  try {
    const newUrl = new URL(url)
    newUrl.pathname = pathname
    return newUrl
  } catch {
    return url
  }
}

export default formatPortalUrl
