import constants from 'constants/index'

const optionImpactIncidentHolderStatus = (id) => {
  const result = constants.incidents.IMPACT_HOLDER.find(
    (element) => element.id === id,
  )

  return result?.name || 'N/A'
}

export default optionImpactIncidentHolderStatus
