import React, { useEffect } from 'react'
import { Box, TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'
import { orderBy, isEmpty } from 'lodash'

import { Select, Label, LoadingFeedback } from 'components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'

const LiaForm = ({ control, watch, errors, clearError, setDataProcesses }) => {
  const { response, isLoading } = useFetch(
    service.dponet.departments.get,
    {},
    [],
  )

  const departments = response?.data?.departments

  const departmentWasSelected = !!watch('departmentId')

  const { response: dataProcessesResponse, isLoading: loadingDataProcess } =
    useFetch(
      service.dponet.dataProcesses.getWithLiaAnswers,
      {
        departmentId: watch('departmentId'),
      },
      [watch('departmentId')],
      !!watch('departmentId'),
    )

  const allDataProcessesDepartment = dataProcessesResponse?.data?.dataProcesses

  const noDataProcessWasFound =
    !loadingDataProcess && isEmpty(allDataProcessesDepartment)

  useEffect(() => {
    if (!!watch('departmentId')) {
      clearError('departmentId')
    }

    if (!!watch('dataProcess')) {
      clearError('dataProcess')
      if (watch('dataProcess').id === 0) {
        setDataProcesses(allDataProcessesDepartment)
      } else {
        setDataProcesses([watch('dataProcess')])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('departmentId'), watch('dataProcess')])

  const allDataProcessOption = [
    {
      id: 0,
      name: 'Todos',
    },
  ]

  const dataProcessesOptions = !!allDataProcessesDepartment && [
    ...allDataProcessOption,
    ...allDataProcessesDepartment,
  ]

  return (
    <form>
      <Box display="flex" width="100%" gridGap={6}>
        <LoadingFeedback open={isLoading} />
        {!isLoading && (
          <Box
            display="flex"
            flexDirection="column"
            width={departmentWasSelected ? '60%' : '50%'}
          >
            <Label title="Departamento" titleVariant="subtitle1">
              <Controller
                control={control}
                name="departmentId"
                as={
                  <Select
                    items={departments || []}
                    error={!!errors.departmentId}
                    helperText={errors?.departmentId?.message}
                  />
                }
                mode="onChange"
              />
            </Label>
          </Box>
        )}
        {!noDataProcessWasFound && (
          <Box display="flex" flexDirection="column" width="100%">
            <Label title="Processos" titleVariant="subtitle1">
              <Controller
                control={control}
                name="dataProcess"
                onChange={([, data]) => data}
                mode="onChange"
                as={
                  <Autocomplete
                    options={orderBy(dataProcessesOptions, 'id') || []}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Digite o nome do processo"
                        variant="outlined"
                        error={!!errors?.dataProcess}
                        helperText={errors?.dataProcess?.message}
                      />
                    )}
                  />
                }
              />
            </Label>
          </Box>
        )}
        {noDataProcessWasFound && !!watch('departmentId') && (
          <Box display="flex" width="100%" alignItems="flex-end">
            <TextField
              label="Nenhum processo encontrado para este departamento"
              disabled
              fullWidth
            />
          </Box>
        )}
      </Box>
    </form>
  )
}

export default LiaForm
