import React from 'react'
import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
  Grid,
  TextField,
} from '@material-ui/core'

import { Select } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'

const DialogEdition = ({
  open,
  setOpen,
  handleChange,
  agent,
  setType,
  type,
  setDocument,
  document,
}) => {
  const handleValue =
    document === null
      ? helpers.formatters.cnpjOrCpf(agent?.document)
      : helpers.formatters.cnpjOrCpf(document)

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Editar o agente de tratamento</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box mt={1} mb={1}>
        <DialogContent>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled
                  value={agent?.name}
                  label="Nome"
                  color="primary"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setDocument(e.target.value)}
                  inputProps={{
                    maxLength: 18,
                  }}
                  disabled={agent?.companySelf}
                  value={handleValue}
                  label="CPF/CNPJ"
                  color="primary"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  value={isEmpty(type) ? agent?.agentType : type}
                  fullWidth
                  items={constants.dataTreatmentAgents.OPTIONS_TYPES}
                  label="Tipo"
                  onChange={(event) => setType(event.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          disabled={isEmpty(type) && isEmpty(document)}
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => handleChange(false)}
        >
          Confirmar Edição
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogEdition.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleChange: PropTypes.func,
  agent: PropTypes.object,
  setType: PropTypes.func,
  type: PropTypes.string,
}

DialogEdition.defaultProps = {
  setOpen: () => {},
  handleChange: () => {},
  setType: () => {},
}

export default DialogEdition
