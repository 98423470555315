import activities from './activities'
import auditProcess from './auditProcess'
import auth from './auth'
import avatarLetters from './avatarLetters'
import company from './company'
import companyFreeTrials from './companyFreeTrials'
import companySuppliers from './companySuppliers'
import companyTrails from './companyTrails'
import complaint from './complaint'
import consent from './consent'
import contentHeader from './contentHeader'
import cookies from './cookies'
import dataCollected from './dataCollected'
import dataLifeCycle from './dataLifeCycle'
import dataProcess from './dataProcesses'
import dataTreatment from './dataTreatment'
import dataTreatmentAgent from './dataTreatmentAgent'
import departments from './departments'
import driverJs from './driverJs'
import formatters from './formatters'
import fragilities from './fragilities'
import fragilityEntities from './fragilityEntities'
import functions from './functions'
import incidents from './incidents'
import inviteAdvisor from './inviteAdvisor'
import logs from './logs'
import marketplace from './marketplace'
import mixpanel from './mixpanel'
import myLgpd from './myLgpd'
import necessityAndProportionality from './necessityAndProportionality'
import nonComplianceReports from './nonComplianceReports'
import orders from './orders'
import palette from './palette'
import partners from './partners'
import plans from './plans'
import preRegistration from './preRegistrations'
import privacyPolicies from './privacyPolicies'
import questionControls from './questionControls'
import questionnaires from './questionnaires'
import questions from './questions'
import recurrence from './recurrence'
import regex from './regex'
import reports from './reports'
import ripds from './ripds'
import suggestedChanges from './suggestedChanges'
import supplierInvite from './supplierInvite'
import tickets from './tickets'
import trainningJourneys from './trainningJourneys'
import userSteps from './userSteps'
import userTrailSteps from './userTrailSteps'
import validates from './validates'

const helpers = {
  activities,
  auditProcess,
  auth,
  avatarLetters,
  company,
  companyFreeTrials,
  companySuppliers,
  companyTrails,
  complaint,
  consent,
  contentHeader,
  cookies,
  dataCollected,
  dataLifeCycle,
  dataProcess,
  dataTreatment,
  dataTreatmentAgent,
  departments,
  driverJs,
  formatters,
  fragilities,
  fragilityEntities,
  functions,
  incidents,
  inviteAdvisor,
  logs,
  marketplace,
  mixpanel,
  myLgpd,
  necessityAndProportionality,
  nonComplianceReports,
  orders,
  palette,
  partners,
  plans,
  preRegistration,
  privacyPolicies,
  questionControls,
  questionnaires,
  questions,
  recurrence,
  regex,
  reports,
  ripds,
  suggestedChanges,
  supplierInvite,
  tickets,
  trainningJourneys,
  userSteps,
  userTrailSteps,
  validates,
}

export default helpers
