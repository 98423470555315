import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Typography } from '@material-ui/core'

const ResumeResponsible = ({ responsibleData }) => {
  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2">Nome do representante</Typography>
          <Typography variant="subtitle1">
            {responsibleData?.name || '-'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">E-mail do representante</Typography>
          <Typography variant="subtitle1">
            {responsibleData?.email || '-'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">Telefone do representante</Typography>
          <Typography variant="subtitle1">
            {responsibleData?.phone || '-'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

ResumeResponsible.propTypes = {
  responsibleData: PropTypes.object,
}

export default ResumeResponsible
