import React, { useEffect } from 'react'

import { Box } from '@material-ui/core'
import { Text } from '../'
import { InfoChip } from 'components'

import useFirstStep from 'hooks/useFirstStep'

const Checkpoint = () => {
  const { setBackgroundKind } = useFirstStep()
  useEffect(
    () => setBackgroundKind(null),
    //eslint-disable-next-line
  [])

  return (
    <Box p={2}>
      <Text color="primary" fontSize="7vh" variant="h1">
        Vamos para a próxima etapa!
      </Text>
      <Text color="secondary" fontSize="3.5vh" variant="h4">
        Queremos saber um pouco mais sobre sua organização
      </Text>
      <Box width="100%" display="flex" justifyContent="center" my={2}>
        <InfoChip label="Apenas 2 minutos" />
      </Box>
    </Box>
  )
}

export default Checkpoint
