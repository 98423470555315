import constants from 'constants/index'

const status = (status) => {
  switch (status) {
    case constants.activities.ONGOING_STATUS:
      return 'Em andamento'
    case constants.activities.PENDING_STATUS:
      return 'Pendente'
    case constants.activities.OVERDUE_STATUS:
      return 'Atrasada'
    case constants.activities.FINALIZED_STATUS:
      return 'Finalizada'
    default:
      return ''
  }
}

export default status
