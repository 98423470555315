import React from 'react'
import { Controller } from 'react-hook-form'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import { TextField, Grid, Typography, Box } from '@material-ui/core'

import helpers from 'helpers'

const DataIncident = ({ errors, control, hasEditPermission, label }) => {
  return (
    <>
      <Grid item xs={12}>
        {label && (
          <Box pb={2}>
            <Typography variant="h6">{label}</Typography>
          </Box>
        )}
      </Grid>
      <Grid item md={4} xs={12}>
        <Controller
          as={
            <DatePicker
              fullWidth
              format="DD/MM/yyyy"
              label="Data do incidente"
              helperText={errors?.incidentDate?.message}
              error={!!errors.incidentDate}
              inputVariant="outlined"
              onChange={(newValue) => ({ value: newValue })}
              disableFuture
              disabled={!hasEditPermission}
            />
          }
          control={control}
          name="incidentDate"
          mode="onChange"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Controller
          as={
            <TimePicker
              format="HH:mm"
              fullWidth
              label="Hora do incidente"
              helperText={errors?.incidentHour?.message}
              error={!!errors.incidentHour}
              inputVariant="outlined"
              ampm={false}
              disabled={!hasEditPermission}
            />
          }
          control={control}
          name="incidentHour"
          mode="onChange"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <Controller
          as={
            <TextField
              label="Quantidade estimada de titulares afetados"
              color="primary"
              variant="outlined"
              error={!!errors.affectedHolders}
              helperText={errors?.affectedHolders?.message}
              fullWidth
              disabled={!hasEditPermission}
            />
          }
          control={control}
          name="affectedHolders"
          mode="onChange"
          onChange={([e]) => helpers.formatters.onlyNumbers(e.target.value)}
        />
      </Grid>
    </>
  )
}

export default DataIncident
