import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  groupTypography: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  button: {
    width: '325px',
    borderRadius: '29px',
    fontSize: 18,
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
    backgroundColor: 'transparent',
    textTransform: 'none',
    marginTop: 48,
  },
  text: {
    fontSize: 40,
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    lineHeight: 1.2,
  },
  textWelcome: {
    fontSize: 50,
    paddingBottom: '36px',
  },
  highlight: {
    backgroundColor: theme.palette.subscription.warning.success,
  },
}))

export default styles
