const mountGapRmcRows = (gapStats) => {
  const totalRmcs = (questionnaire) =>
    questionnaire?.nonComplianceReports?.finalized +
    questionnaire?.nonComplianceReports?.pending +
    questionnaire?.nonComplianceReports?.riskAccepted +
    questionnaire?.nonComplianceReports?.onTime +
    questionnaire?.nonComplianceReports?.overdue

  return gapStats?.questionnaires?.map((questionnaire) => ({
    name: questionnaire?.name,
    value:
      questionnaire?.nonComplianceReports?.finalized +
      questionnaire?.nonComplianceReports?.riskAccepted,
    percentage:
      totalRmcs(questionnaire) === 0
        ? 0
        : ((questionnaire?.nonComplianceReports?.finalized +
            questionnaire?.nonComplianceReports?.riskAccepted) /
            totalRmcs(questionnaire)) *
          100,
    totalRmcQuestions: totalRmcs(questionnaire),
  }))
}

export default mountGapRmcRows
