import { makeStyles } from '@material-ui/core'
import background from 'images/background_primeiros_passos.png'

const styles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    background:
      'transparent linear-gradient(180deg, #233154 0%, #1E3860 100%) 0% 0% no-repeat padding-box;',
  },
  children: {
    backgroundImage: `url(${background})`,
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    [theme.breakpoints.down('md')]: {
      backgroundSize: 'auto 40%',
    },
  },
}))

export default styles
