import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonPrimary: {
    backgroundColor: theme.palette.subscription.primary.main,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.subscription.primary.dark,
    },
  },
}))

export default styles
