import constants from 'constants/index'

const defineStatus = (concluded) => {
  if (concluded) {
    return constants.companyTrail.CONCLUDED_STATUS
  }

  return constants.companyTrail.NOT_INITIATED_STATUS
}

export default defineStatus
