const CHILD_ID = 1
const ADOLESCENT_ID = 2
const ADULT_ID = 3
const ELDERLY_ID = 4

const CHILD_LABEL = 'Criança (0 - 11 anos)'
const ADOLESCENT_LABEL = 'Adolescente (12 - 17)'
const ADULT_LABEL = 'Adulto (18 - 59)'
const ELDERLY_LABEL = 'Idoso (60+)'

const OPTION_AGE_RANGE = [
  { id: CHILD_ID, name: CHILD_LABEL },
  { id: ADOLESCENT_ID, name: ADOLESCENT_LABEL },
  { id: ADULT_ID, name: ADULT_LABEL },
  { id: ELDERLY_ID, name: ELDERLY_LABEL },
]

const CONVERT_NAME = {
  1: CHILD_LABEL,
  2: ADOLESCENT_LABEL,
  3: ADULT_LABEL,
  4: ELDERLY_LABEL,
}

export default { OPTION_AGE_RANGE, CONVERT_NAME }
