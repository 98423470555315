const mountFormData = (data) => {
  let formData = new FormData()

  formData.append(
    'question_control[take_risk[responsible_name]]',
    data.responsibleName,
  )
  formData.append('question_control[take_risk[description]]', data.description)

  if (Array.isArray(data.riskEvidencies)) {
    data.riskEvidencies.forEach((evidency) => {
      formData.append(
        'question_control[take_risk[risk_evidencies]][]',
        evidency,
      )
    })
  }

  return formData
}

export default mountFormData
