import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const AlertSaveInfo = () => {
  return (
    <Box py={6}>
      <Alert variant="filled" severity="info">
        <Typography variant="h6">
          Você precisa responder todas as sugestões para salvar!
        </Typography>
      </Alert>
    </Box>
  )
}

export default AlertSaveInfo
