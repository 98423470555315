import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  labelTitle: {
    fontWeight: 400,
  },
  switchCompany: {
    marginTop: theme.spacing(1),
    marginLeft: 0,
    fontSize: 16,
  },
  multiSelect: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
  },
}))

export default styles
