import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import helpers from 'helpers'
import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'

const DialogTechnologyCompany = ({
  questionnaireId,
  open,
  setOpen,
  setLoading,
  refresh,
}) => {
  const snackbar = useSnackbar()
  const classes = useStyles()

  const { handleSubmit, control } = useForm()

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await service.dponet.questionnaires.handleDynamicQestions({
        questionnaireId: questionnaireId,
        hasDynamicQuestions: data?.questionOutsourceSoftware,
      })
      setOpen(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box paddingY={2} paddingX={3}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Atenção!</Typography>
          </Box>
        </Box>
        <DialogContent dividers>
          <Typography variant="body1" gutterBottom>
            A sua empresa faz ou terceiriza o desenvolvimento de software?
          </Typography>

          <Box py={1}>
            <Controller
              name="questionOutsourceSoftware"
              control={control}
              as={
                <RadioGroup className={classes.boxButtons}>
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
              }
            />
          </Box>
        </DialogContent>
        <Box display="flex" justifyContent="flex-end" p={1}>
          <Button variant="contained" color="primary" type="submit">
            Próximo
          </Button>
        </Box>
      </form>
    </Dialog>
  )
}

export default DialogTechnologyCompany
