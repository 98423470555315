import MainAuditReport from './AuditReport'
import MainAccountability from './Accountability'
import MainConsent from './ConsentReport'
import MainControlReport from './ControlReport'
import MainFragilityReport from './FragilityReport'
import MainLia from './LiaReport'
import MainManagement from './Management'
import MainRipd from './ImpactReport'
import MainRmcReport from './ReportRmc'
import MainRopa from './RopaReport'
import TrainingReport from './TrainingReport'
import ValidateReport from './ValidateReport'

export default {
  MainAuditReport,
  MainAccountability,
  MainConsent,
  MainControlReport,
  MainFragilityReport,
  MainLia,
  MainManagement,
  MainRipd,
  MainRmcReport,
  MainRopa,
  TrainingReport,
  ValidateReport,
}
