import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Typography } from '@material-ui/core'

import helpers from 'helpers'

const ResumeCompany = ({ data }) => {
  const fields = helpers.preRegistration.resumeCompanyData(data)

  return (
    <Box m={2}>
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid item xs={12} key={field?.label}>
            <Typography variant="body2">{field?.label}</Typography>
            <Typography variant="subtitle1">{field?.value || '-'}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

ResumeCompany.propTypes = {
  data: PropTypes.object,
}

export default ResumeCompany
