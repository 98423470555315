import components from '../../components'

import palette from 'theme/palette'

import helpers from 'helpers'

import { WIDTH as WIDTHPdf, PADDING, GAP } from '../../constants'

import { ratio } from '../../helpers'

const rmc = async (
  pdf,
  date,
  processStats,
  gapStats,
  generalStats,
  customThemeStatus,
) => {
  const WIDTH = WIDTHPdf(pdf)

  pdf.addPage('a4', 'p')

  components.lineWithDate(pdf, date)

  components.numberAndTitle(pdf, '1', 'Melhoria contínua')

  components.paragraph(
    pdf,
    '     No contexto da LGPD, a melhoria contínua implica que as organizações devem sempre buscar aprimorar seus processos, políticas e medidas de segurança para garantir conformidade com a lei e proteção adequada dos dados pessoais. Isso envolve revisões regulares de políticas, implementação de medidas de segurança adicionais, atualização de procedimentos, treinamentos periódicos e adoção de tecnologias avançadas.\n\n     Essa abordagem visa garantir a proteção dos direitos dos titulares de dados e fortalecer a confiança no gerenciamento de informações pessoais.',
    GAP * 3.25,
    palette.subscription.primary.main,
  )

  components.subtitle(pdf, 'Status dos RMCs', GAP * 5)

  components.paragraph(
    pdf,
    '     O RMC (Registro de Melhoria Contínua) é explicado juntamente com os diferentes status associados a ele: pendente, no prazo, vencido, risco assumido e finalizado. Cada status descreve o estágio em que a melhoria contínua se encontra, desde a fase de planejamento até a implementação e conclusão bem-sucedida das ações propostas. Esses status auxiliam no monitoramento e na gestão das melhorias contínuas, garantindo que elas sejam realizadas de acordo com o cronograma e com os objetivos estabelecidos.',
    GAP * 5.25,
    palette.subscription.primary.main,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 6.25,
    WIDTH - PADDING * 2,
    palette.dashboard.chart.secondary.pending,
    ratio,
    'Pendente',
    'Refere-se às melhorias contínuas que estão sendo executadas dentro do prazo estabelecido. Nessa fase, as ações planejadas estão em andamento, e é importante monitorar o progresso para garantir que sejam concluídas conforme o cronograma.',
    GAP * 6.25 + ratio(60),
    48,
    false,
    palette.dashboard.report.text.secondary,
    palette.primary.hover,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 7.75,
    WIDTH - PADDING * 2,
    palette.dashboard.chart.secondary.pending,
    ratio,
    'No prazo',
    'Refere-se às melhorias contínuas que estão sendo executadas dentro do prazo estabelecido. Nessa fase, as ações planejadas estão em andamento, e é importante monitorar o progresso para garantir que sejam concluídas conforme o cronograma.',
    GAP * 7.75 + ratio(60),
    48,
    false,
    palette.dashboard.report.text.secondary,
    palette.primary.hover,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 9.25,
    WIDTH - PADDING * 2,
    palette.dashboard.chart.primary.disapproved,
    ratio,
    'Vencido',
    'Indica que o prazo estabelecido para a implementação das melhorias contínuas foi ultrapassado. Nesse caso, é necessário investigar os motivos do atraso e tomar medidas corretivas para retomar o progresso e evitar que as melhorias sejam abandonadas.',
    GAP * 9.25 + ratio(60),
    48,
    false,
  )

  components.numberPage(pdf, palette.black, customThemeStatus)

  pdf.addPage('a4', 'p')

  components.lineWithDate(pdf, date)

  components.rectangle(
    pdf,
    PADDING,
    GAP,
    WIDTH - PADDING * 2,
    palette.dashboard.chart.secondary.approved,
    ratio,
    'Risco assumido',
    'Quando o status é classificado como "Risco Assumido", significa que a decisão foi tomada conscientemente de não prosseguir com a implementação das melhorias contínuas devido a razões específicas ou à aceitação dos riscos envolvidos.',
    GAP + ratio(60),
    48,
    false,
    palette.dashboard.chart.primary.approved,
    palette.dashboard.chart.primary.approved,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 2.5,
    WIDTH - PADDING * 2,
    palette.dashboard.chart.primary.approved,
    ratio,
    'Finalizado',
    'Indica que as ações de melhoria contínua foram concluídas com sucesso. Nessa etapa, as ações planejadas foram implementadas, e os resultados e benefícios esperados foram alcançados, fortalecendo as práticas de conformidade com a LGPD.',
    GAP * 2.5 + ratio(60),
    48,
    false,
  )

  components.subtitle(pdf, 'RMC de processo', GAP * 4.5)

  const processesRmc = [
    {
      value: helpers.myLgpd.pendingRmcs(processStats),
      label: 'Pendente',
      bgColor: palette.dashboard.chart.secondary.pending,
      fontColor: palette.dashboard.report.text.secondary,
    },
    {
      value: helpers.myLgpd.onTimeRmcs(processStats),
      label: 'No prazo',
      bgColor: palette.dashboard.chart.secondary.pending,
      fontColor: palette.dashboard.report.text.secondary,
    },
    {
      value: helpers.myLgpd.overdueRmcs(processStats),
      label: 'Vencido',
      bgColor: palette.dashboard.chart.primary.disapproved,
      fontColor: palette.white,
    },
    {
      value: helpers.myLgpd.riskAcceptedRmcs(processStats),
      label: 'Risco assumido',
      bgColor: palette.dashboard.chart.secondary.approved,
      fontColor: palette.dashboard.chart.primary.approved,
    },
    {
      value: helpers.myLgpd.finalizedRmcs(processStats),
      label: 'Finalizado',
      bgColor: palette.dashboard.chart.primary.approved,
      fontColor: palette.white,
    },
  ]

  processesRmc.forEach((rmc, index) => {
    components.rowTwoColumns(
      pdf,
      GAP * 5 + 20 * index,
      rmc.label,
      rmc?.value?.toString(),
      rmc.bgColor,
      rmc.fontColor,
    )
  })

  components.subtitle(pdf, 'RMC de questionário', GAP * 7)

  const questionnairesRmc = [
    {
      value: helpers.myLgpd.pendingRmcs(gapStats, false),
      label: 'Pendente',
      bgColor: palette.dashboard.chart.secondary.pending,
      fontColor: palette.dashboard.report.text.secondary,
    },
    {
      value: helpers.myLgpd.onTimeRmcs(gapStats, false),
      label: 'No prazo',
      bgColor: palette.dashboard.chart.secondary.pending,
      fontColor: palette.dashboard.report.text.secondary,
    },
    {
      value: helpers.myLgpd.overdueRmcs(gapStats, false),
      label: 'Vencido',
      bgColor: palette.dashboard.chart.primary.disapproved,
      fontColor: palette.white,
    },
    {
      value: helpers.myLgpd.riskAcceptedRmcs(gapStats, false),
      label: 'Risco assumido',
      bgColor: palette.dashboard.chart.secondary.approved,
      fontColor: palette.dashboard.chart.primary.approved,
    },
    {
      value: helpers.myLgpd.finalizedRmcs(gapStats, false),
      label: 'Finalizado',
      bgColor: palette.dashboard.chart.primary.approved,
      fontColor: palette.white,
    },
  ]

  questionnairesRmc.forEach((rmc, index) => {
    components.rowTwoColumns(
      pdf,
      GAP * 7.5 + 20 * index,
      rmc.label,
      rmc.value.toString(),
      rmc.bgColor,
      rmc.fontColor,
    )
  })
  components.numberPage(pdf, palette.black, customThemeStatus)

  pdf.addPage('a4', 'p')

  components.lineWithDate(pdf, date)

  components.subtitle(pdf, 'RMC de incidente', GAP, PADDING, ratio)

  const incidentsRmc = [
    {
      value: generalStats?.incidentRmcs?.pending,
      label: 'Pendente',
      bgColor: palette.dashboard.chart.secondary.pending,
      fontColor: palette.dashboard.report.text.secondary,
    },
    {
      value: generalStats?.incidentRmcs?.onTime,
      label: 'No prazo',
      bgColor: palette.dashboard.chart.secondary.pending,
      fontColor: palette.dashboard.report.text.secondary,
    },
    {
      value: generalStats?.incidentRmcs?.overdue,
      label: 'Vencido',
      bgColor: palette.dashboard.chart.primary.disapproved,
      fontColor: palette.white,
    },
    {
      value: generalStats?.incidentRmcs?.riskAccepted,
      label: 'Risco assumido',
      bgColor: palette.dashboard.chart.secondary.approved,
      fontColor: palette.dashboard.chart.primary.approved,
    },
    {
      value: generalStats?.incidentRmcs?.finalized,
      label: 'Finalizado',
      bgColor: palette.dashboard.chart.primary.approved,
      fontColor: palette.white,
    },
  ]

  incidentsRmc.forEach((rmc, index) => {
    components.rowTwoColumns(
      pdf,
      GAP * 1.5 + 20 * index,
      rmc.label,
      rmc.value.toString(),
      rmc.bgColor,
      rmc.fontColor,
    )
  })

  components.numberPage(pdf, palette.black, customThemeStatus)
}

export default rmc
