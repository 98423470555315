import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Box, Link, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import { AttachmentTable, TextEditor } from 'components'

import schema from './schema'
import useStyle from './styles'

const ReplyTicketForm = ({ onSubmit: onSubmitCustom }) => {
  const [files, setFiles] = useState([])

  const classes = useStyle()
  const { control, handleSubmit, reset, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      content: '',
    },
  })

  const onSubmit = async (data) => {
    let formData = new FormData()

    data.attachments = files
    Object.keys(data).forEach((key) => {
      if (key === 'attachments') {
        for (const value of data[key]) {
          formData.append(`ticket_messages[attachments][]`, value)
        }
      } else {
        formData.append(`ticket_messages[${key}]`, data[key])
      }
    })

    const success = await onSubmitCustom(formData)

    if (success) {
      reset()
      setFiles([])
    }
  }

  const handleChangeInputFile = (event) => {
    const eventFiles = event.target.files
    const attachments = [...files, ...eventFiles]
    setFiles(attachments)
  }

  const handleRemoveFile = (index) => {
    const attachments = files.filter((_, i) => i !== index)
    setFiles(attachments)
  }

  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit(onSubmit)} id="reply-ticket-form">
        <Controller
          control={control}
          name="content"
          as={
            <TextEditor
              error={!!errors?.content}
              helperText={errors?.content?.message}
            />
          }
          mode="onBlur"
        />
        <Box pt={3}>
          <Typography variant="body1">
            Para anexar arquivos,&nbsp;
            <Link
              href="#"
              htmlFor="input-file"
              component="label"
              className={classes.link}
            >
              Clique aqui.
            </Link>
          </Typography>
          <Typography variant="caption">
            Arquivos suportados: xlsx, xls, imagens, doc, docx, ppt, pptx, txt,
            pdf
          </Typography>
          <input
            multiple
            type="file"
            id="input-file"
            accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            className={classes.inputFile}
            onChange={handleChangeInputFile}
          />
        </Box>
        {files?.length > 0 && (
          <AttachmentTable
            attachments={files}
            handleDelete={handleRemoveFile}
          />
        )}
      </form>
    </Box>
  )
}

ReplyTicketForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ReplyTicketForm
