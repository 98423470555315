import THEMES from 'constants/theme'
import { colors } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const white = '#FFFFFF'

const styles = makeStyles((theme) => ({
  root: {
    border: 0,
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.palette.layout.main,
    ...(theme.name === THEMES.LIGHT
      ? {
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    boxShadow: 'none',
  },
  logo: {
    fontSize: 0,
    width: '130px',
    [theme.breakpoints.up('md')]: {
      width: '130px',
    },
  },
  helpChip: {
    backgroundColor: colors.green[500],
    color: white,
    fontSize: 14,
    fontWeight: 500,
    minWidth: 85,
    height: 39,
    borderRadius: 100,
    '&:hover': {
      backgroundColor: colors.green[600],
      cursor: 'pointer',
    },
  },
  iconChip: {
    color: white,
    fontSize: 24,
    height: 21,
    width: 21,
  },
  mktpButton: {
    color: white,
    textTransform: 'none',
    borderRadius: '15px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  mktpIconButtonIcon: {
    color: white,
  },
}))

export default styles
