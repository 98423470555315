const status = (statusId) => {
  switch (statusId) {
    case 1:
      return 'No prazo'
    case 2:
      return 'Vencido'
    case 3:
      return 'Finalizado'
    case 4:
      return 'Pendente'
    case 6:
      return 'Risco assumido'
    case 7:
      return 'Substituído'
    case 8:
      return 'Congelado'
    case 9:
      return 'Inativo'
    default:
      return '-'
  }
}

export default status
