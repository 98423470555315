import React from 'react'
import { Alert } from '@material-ui/lab'
import { Box, Typography } from '@material-ui/core'

const MissingDpoAlert = () => {
  return (
    <Box mb={2}>
      <Alert variant="filled" severity="warning">
        <Typography variant="body1">
          O DPO nomeado pela sua organização deverá aceitar o vínculo (Status
          atual: Pendente). Entre em contato com o DPO nomeado ou envie um novo
          convite.
        </Typography>
      </Alert>
    </Box>
  )
}

export default MissingDpoAlert
