import statusLabelColor from './statusLabelColor'
import formattedStatus from './formatterStatus'
import ticketInfo from './ticketInfo'
import mount from './mount'

const tickets = {
  formattedStatus,
  statusLabelColor,
  ticketInfo,
  mount,
}

export default tickets
