import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  Box,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import theme from 'theme'
import constants from 'constants/index'

const ScannerCard = ({ cookies }) => {
  const { control, errors, watch } = useFormContext()

  const smDown = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  return (
    <Grid container spacing={2}>
      {cookies?.map((cookie, index) => {
        const disableInput =
          cookie?.category?.id === constants.cookies.category.NECESSARY_ID

        return (
          <Grid item xs={12} key={index}>
            <Box
              bgcolor={theme.palette.background.default}
              border={1}
              borderColor={theme.palette.primary.main}
              borderRadius={4}
            >
              <Grid container spacing={smDown ? 3 : 8} component={Box} p={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <Typography color="primary" variant="h6" gutterBottom>
                    Plataforma de origem:
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gridGap={theme.spacing(1)}
                  >
                    <Controller
                      as={
                        <Tooltip
                          title={
                            disableInput
                              ? 'Cookies necessários não podem ser modificados'
                              : ''
                          }
                        >
                          <TextField
                            placeholder="Origem"
                            error={!!errors?.cookies?.[index]?.platform}
                            helperText={
                              <>{errors?.cookies?.[index]?.platform?.message}</>
                            }
                            type="text"
                            size="small"
                            disabled={disableInput}
                            fullWidth
                          />
                        </Tooltip>
                      }
                      control={control}
                      name={`cookies.${index}.platform`}
                    />
                  </Box>
                </Grid>
                <Grid md={3} sm={6} item xs={12}>
                  <Typography color="primary" variant="h6" gutterBottom>
                    Cookie:
                  </Typography>
                  <Tooltip title={cookie?.name} placement="bottom-start">
                    <TextField
                      type="text"
                      size="small"
                      defaultValue={cookie?.name}
                      fullWidth
                    />
                  </Tooltip>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Typography color="primary" variant="h6" gutterBottom>
                    ANPD:
                  </Typography>
                  <Controller
                    as={
                      <Tooltip
                        title={
                          disableInput
                            ? 'Cookies necessários não podem ser modificados'
                            : ''
                        }
                      >
                        <Select
                          fullWidth
                          error={!!errors?.cookies?.[index]?.categoryId}
                          color="primary"
                          variant="outlined"
                          placeholder="Selecionar"
                          disabled={disableInput}
                          SelectDisplayProps={{ style: { paddingBlock: 10.5 } }}
                        >
                          {constants.cookies.category.MENU_ITEMS.map(
                            (cookieCategory) => (
                              <MenuItem
                                key={cookieCategory?.id}
                                value={cookieCategory?.id}
                                disabled={cookieCategory?.disabled}
                              >
                                {cookieCategory.name}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </Tooltip>
                    }
                    control={control}
                    name={`cookies.${index}.categoryId`}
                  />
                  {!!errors?.cookies?.[index] && (
                    <FormHelperText error={!!errors?.cookies?.[index]}>
                      {<>{errors?.cookies?.[index]?.categoryId?.message}</>}
                    </FormHelperText>
                  )}
                  <Box display="none">
                    <Controller
                      as={<Select value={cookie?.id} />}
                      control={control}
                      name={`cookies.${index}.id`}
                    />
                  </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Typography color="primary" variant="h6" gutterBottom>
                    Tag Manager:
                  </Typography>
                  <TextField
                    type="text"
                    size="small"
                    value={
                      constants.cookies.category.STORAGE_TYPES[
                        watch(`cookies.${index}.categoryId`)
                      ] || ''
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}

ScannerCard.propTypes = {
  cookies: PropTypes.arrayOf(PropTypes.object),
}

export default ScannerCard
