import React from 'react'

import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

import { Grid, TextField } from '@material-ui/core'

import helpers from 'helpers'

const MainForm = ({ formMethods, verifyDocument }) => {
  const { errors, control } = formMethods

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          as={
            <TextField
              fullWidth
              error={!!errors.name}
              helperText={errors?.name?.message}
              label="Razão social"
              required
            />
          }
          control={control}
          name="name"
          mode="onChange"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          as={
            <TextField
              fullWidth
              error={!!errors.fantasyName}
              helperText={errors?.fantasyName?.message}
              label="Nome fantasia"
              required
            />
          }
          control={control}
          name="fantasyName"
          mode="onChange"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          as={
            <TextField
              fullWidth
              inputProps={{
                maxLength: 18,
              }}
              error={!!errors.cnpj}
              helperText={errors?.cnpj?.types?.type?.notMatch}
              label="CNPJ"
              required
            />
          }
          onChange={([event]) => {
            verifyDocument(event.target.value)
            return helpers.formatters.cnpj(event.target.value)
          }}
          control={control}
          name="cnpj"
          mode="onChange"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          as={
            <TextField
              fullWidth
              error={!!errors.email}
              helperText={errors?.email?.message}
              label="E-mail de cobrança"
              required
            />
          }
          control={control}
          name="email"
          mode="onChange"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          as={
            <TextField
              fullWidth
              inputProps={{
                maxLength: 18,
                minLength: 14,
              }}
              error={!!errors.phone}
              helperText={errors?.phone?.message}
              label="Telefone"
              required
            />
          }
          onChange={([event]) => {
            return helpers.formatters.phone(event.target.value, true)
          }}
          control={control}
          name="phone"
          mode="onChange"
        />
      </Grid>
    </Grid>
  )
}

MainForm.prototype = {
  formMethods: PropTypes.object.isRequired,
}

export default MainForm
