import { useContext } from 'react'

import SupplierInviteContext from 'contexts/SupplierInviteContext'

const useSupplierInvite = () => {
  const context = useContext(SupplierInviteContext)

  if (context === undefined) {
    throw new Error(
      'useSupplierInvite must be used within a SuggestedChangeContext.Provider',
    )
  }

  return {
    currentStep: context.currentStep,
    setCurrentStep: context.setCurrentStep,
    supplierData: context.supplierData,
    setSupplierData: context.setSupplierData,
    isLoading: context.isLoading,
    setIsLoading: context.setIsLoading,
  }
}

export default useSupplierInvite
