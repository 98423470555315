const DAY_ID = 'days'
const MONTH_ID = 'months'
const YEAR_ID = 'years'

const DAY_LABEL = 'Dias'
const MONTH_LABEL = 'Meses'
const YEAR_LABEL = 'Anos'

const DATE_TRANSLATION_LABEL = {
  [DAY_ID]: DAY_LABEL,
  [MONTH_ID]: MONTH_LABEL,
  [YEAR_ID]: YEAR_LABEL,
}

const RECURRENCE_BUTTON_MENU_ITEMS = [
  { label: DAY_LABEL, value: DAY_ID },
  { label: MONTH_LABEL, value: MONTH_ID },
  { label: YEAR_LABEL, value: YEAR_ID },
]

export default {
  DATE_TRANSLATION_LABEL,
  RECURRENCE_BUTTON_MENU_ITEMS,
}
