import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { isEmpty } from 'lodash'
import { Skeleton } from '@material-ui/lab'
import {
  Container,
  Button,
  Box,
  Grid,
  TablePagination,
  useMediaQuery,
  Card,
  Tooltip,
} from '@material-ui/core'

import {
  Page,
  ContentHeader,
  Permitted,
  FilterButton,
  Filters,
  DriveTour,
} from 'components'
import DepartmentTable from './components/DepartmentsTable'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import usePermissions from 'hooks/usePermissions'

import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'
import useStyles from './styles'
import theme from 'theme'
import helpers from 'helpers'

const DepartmentsMain = () => {
  const [openTour, setOpenTour] = useState(false)

  const filter = useFilter()
  const classes = useStyles()
  const history = useHistory()
  const permissions = usePermissions()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const hasEditPermission = permissions.permitted(
    constants.permissions.DEPARTMENTS.CREATE,
  )

  const hasActiveOrInactivePermission = permissions.permitted(
    constants.permissions.DEPARTMENTS.ACTIVATE_OR_INACTIVATE,
  )

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.departments.get,
    {
      perPage,
      page,
      ...{ ...filter.filters },
    },
    [page, perPage, filter.filters],
  )

  const departments = response?.data?.departments

  const { response: userResponse } = useFetch(service.dponet.users.get, {
    perPage: 10000,
    minimal: true,
  })

  const navigateTo = (route) => {
    history.push(route)
  }

  const handleOpenTour = () => {
    const where = 'Departamentos'
    const title = 'Tour'

    const params = {
      action: 'tour-departamento-empresas',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  const filtersMatrixSteps = (elementId, permission) => {
    const requiredPermission =
      constants.departments.DEPARTMENTS_DRIVER_STEPS_WITH_PERMISSIONS[elementId]

    if (
      (isEmpty(departments) ||
        (!hasEditPermission && !hasActiveOrInactivePermission)) &&
      elementId === '#' + constants.departments.DEPARTMENT_MAIN_DRIVER_STEP_2
    )
      return false

    return requiredPermission ? permission(requiredPermission) : true
  }

  const filteredSteps =
    constants.departments.DEPARTMENTS_DRIVER_STEPS_OBJECT.filter((step) => {
      return filtersMatrixSteps(step.element, permissions.permitted)
    })

  const disableTour =
    !hasEditPermission &&
    (!hasActiveOrInactivePermission || isEmpty(departments))

  return (
    <Page title="Todos os departamentos">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Departamentos">
          <Box className={classes.contentHeader}>
            <Tooltip
              title={
                disableTour
                  ? "Para iniciar este tour guiado, é necessário que você possua as permissões para 'gerenciar' ou 'ativar e inativar' com algum departamento definido."
                  : 'Iniciar o tour guiado'
              }
            >
              <Box className={classes.contentHeaderBox}>
                <Button
                  id={constants.departments.DEPARTMENT_MAIN_DRIVER_STEP_0}
                  variant="contained"
                  color="primary"
                  startIcon={<HelpCircleIcon size={20} />}
                  onClick={handleOpenTour}
                  disabled={disableTour}
                  fullWidth
                >
                  Tutorial
                </Button>
              </Box>
            </Tooltip>
            <Permitted tag={constants.permissions.DEPARTMENTS.CREATE}>
              <Box className={classes.contentHeaderBox}>
                <Button
                  id={constants.departments.DEPARTMENT_MAIN_DRIVER_STEP_1}
                  variant="contained"
                  color="primary"
                  onClick={() => navigateTo(routes.department.new)}
                  fullWidth
                >
                  NOVO DEPARTAMENTO
                </Button>
              </Box>
            </Permitted>
            <Box className={classes.contentHeaderBox}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} fullWidth />
            </Box>
          </Box>
        </ContentHeader>
        <Card>
          <Box display="flex" width="100%" mt={2}>
            <Grid container>
              <DepartmentTable
                departments={response && departments}
                isLoading={isLoading}
                onEvent={refresh}
              />
            </Grid>
          </Box>

          <Box px={2} display="flex" justifyContent="flex-end">
            {!isLoading && response ? (
              <TablePagination
                component="div"
                count={response.data.meta.totalCount}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            ) : (
              <Box py="11px">
                <Skeleton variant="rect" width={200} height={30} />
              </Box>
            )}
          </Box>
        </Card>
        <Filters filter={filter} alphabeticalOrder>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input textfieldinput="true" label="Departamento" name="name" />
          <select textfieldinput="true" label="Usuário" name="userId">
            <option value=""></option>
            {userResponse?.data?.users?.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name}
              </option>
            ))}
          </select>
          <select textfieldinput="true" label="Status" name="active">
            <option value=""></option>
            <option value="true">Ativo</option>
            <option value="false">Inativo</option>
          </select>
        </Filters>
      </Container>

      <DriveTour
        stepsMatrix={filteredSteps}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default DepartmentsMain
