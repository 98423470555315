import React from 'react'

import { Stepper as StepperMaterial, StepConnector } from '@material-ui/core'

import useStyles from './styles'

const Stepper = ({ children, activeStep }) => {
  const classes = useStyles()

  return (
    <StepperMaterial
      alternativeLabel
      activeStep={activeStep}
      className={classes.stepper}
      connector={
        <StepConnector
          classes={{ alternativeLabel: classes.alternativeLabel }}
        />
      }
    >
      {children}
    </StepperMaterial>
  )
}

export default Stepper
