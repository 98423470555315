const styles = (theme) => ({
  root: {
    outline: 'none',
  },
  descriptionBox: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: 8,
  },

  lineDescription: {
    marginTop: theme.spacing(1),
  },
})

export default styles
