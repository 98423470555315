import api from 'service/api'
import humps from 'humps'

import * as service from 'service'
import {
  AUTH_LOGGED_COMPANY,
  AUTH_TOKEN,
  TOKEN_LOGGED_COMPANY,
  TOKEN_PRE_REGISTRATION,
  TOKEN_SUPPLIER_INVITE,
} from 'service/constants'
import mixpanel from 'mixpanel-browser'
import * as Sentry from '@sentry/react'

const dponetAPI = api.create('dponet', false)

const login = async (data) => {
  const loginResponse = await dponetAPI.post('/authentication', data)

  if (loginResponse.status === 200) {
    const token = loginResponse.data.authToken
    const cookieToken = loginResponse.data.cookieToken ?? ''

    setToken(token)
    await service.cookies.auth.login(cookieToken)

    const identifyResponse = await service.dponet.usersCompanies.identify()
    if (identifyResponse.data?.company)
      setCompany(identifyResponse.data.company)
  }

  return loginResponse
}

const loginTransparentIntegration = async (data) => {
  return await dponetAPI.post('/public/v1/login_user', data)
}

const forgotPassword = async (data) => {
  const response = await dponetAPI.post('/users/password', {
    api_user: data,
  })

  return response
}

const checkTerm = async ({ token_raw }) => {
  return await dponetAPI.get(`/users/${token_raw}/check_term`)
}

const loginSocialProvider = async ({ socialProvider, ...data }) => {
  const loginResponse = await dponetAPI.post(
    `/authentication/${socialProvider}`,
    data,
  )

  if (loginResponse.status === 200) {
    const token = loginResponse.data.authToken
    const cookieToken = loginResponse.data.cookieToken ?? ''

    setToken(token)
    await service.cookies.auth.login(cookieToken)

    const identifyResponse = await service.dponet.usersCompanies.identify()
    if (identifyResponse.data?.company)
      setCompany(identifyResponse.data.company)
  }

  return loginResponse
}

const setCompany = (company) => {
  sessionStorage.setItem(AUTH_LOGGED_COMPANY, company)
}

const removeCompany = () => {
  sessionStorage.removeItem(AUTH_LOGGED_COMPANY)
}

const getCompany = () => {
  return sessionStorage.getItem(AUTH_LOGGED_COMPANY)
}

const setCompanyToken = (token) => {
  sessionStorage.setItem(TOKEN_LOGGED_COMPANY, token)
}

const removeCompanyToken = () => {
  sessionStorage.removeItem(TOKEN_LOGGED_COMPANY)
}

const getCompanyToken = () => {
  return sessionStorage.getItem(TOKEN_LOGGED_COMPANY)
}

const setSupplierInviteToken = (token) => {
  sessionStorage.setItem(TOKEN_SUPPLIER_INVITE, token)
}

const removeSupplierInviteToken = () => {
  sessionStorage.removeItem(TOKEN_SUPPLIER_INVITE)
}

const getSupplierInviteToken = () => {
  return sessionStorage.getItem(TOKEN_SUPPLIER_INVITE)
}

const setToken = (token) => {
  sessionStorage.setItem(AUTH_TOKEN, token)
}

const removeToken = () => {
  sessionStorage.removeItem(AUTH_TOKEN)
}

const clearStorage = () => {
  localStorage.removeItem('lastArrayContentHeader')
  localStorage.removeItem(TOKEN_PRE_REGISTRATION)
  localStorage.removeItem('ProcessFilters')
}

const getToken = () => {
  return sessionStorage.getItem(AUTH_TOKEN)
}

const logout = () => {
  if (getToken()) {
    mixpanel.reset()
    removeToken()
  }
  service.cookies.auth.logout()
  Sentry.setUser(null)
  removeCompany()
  service.history.push('/')
}

const getUserIdFromToken = () => {
  const token = getToken()
  const payload = humps.camelizeKeys(JSON.parse(atob(token.split('.')[1])))

  return payload.userId
}

const isAuthenticated = () => {
  const token = getToken()
  return !!token
}

export default {
  checkTerm,
  clearStorage,
  forgotPassword,
  getCompany,
  getCompanyToken,
  getToken,
  getUserIdFromToken,
  isAuthenticated,
  login,
  loginSocialProvider,
  loginTransparentIntegration,
  logout,
  removeCompany,
  removeCompanyToken,
  removeToken,
  setCompany,
  setCompanyToken,
  setSupplierInviteToken,
  setToken,
  getSupplierInviteToken,
  removeSupplierInviteToken,
}
