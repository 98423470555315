import dataAnonimazationCover from 'images/data-anonimizacao-cover.png'
import transformacaoIcon from 'images/pentest-network-service.png'
import reducaoIcon from 'images/pentest-web-app.png'

import conformidadeIcon from 'images/data-anonimization-law.png'
import segurancaIcon from 'images/safepassword-security.png'
import privacidadeIcon from 'images/safepassword-lock.png'

const dataDiscoveryIcons = [
  {
    title: 'Privacidade Preservada',
    altText: 'Privacidade Preservada',
    src: privacidadeIcon,
  },
  {
    title: 'Transformação Segura',
    altText: 'Transformação Segura',
    src: transformacaoIcon,
  },
  {
    title: 'Redução de Riscos',
    altText: 'Redução de Riscos',
    src: reducaoIcon,
  },
]

const dataAnonymization = {
  id: 32,
  sectionTitle: 'Anonimização de dados',
  description:
    'A anonimização de dados é um processo utilizado para proteger a privacidade e a segurança das informações pessoais. Em termos simples, é uma técnica que transforma dados de forma a remover ou ocultar qualquer elemento que possa identificar uma pessoa específica. O objetivo principal é garantir que os dados não possam ser facilmente vinculados a um indivíduo específico, mantendo, ao mesmo tempo, a utilidade desses dados para análises e pesquisas.',
  whatIs: {
    primaryText: 'O que é a Anonimização de dados?',
    text: 'O mascaramento de dados é realizado substituindo informações sensíveis por dados fictícios ou ofuscados, preservando a privacidade. Pode incluir técnicas como troca de caracteres ou substituição por valores similares, garantindo a utilidade dos dados para análises sem expor detalhes pessoais.',
    icons: dataDiscoveryIcons,
  },
  forWho: {
    text: 'Empresas de todos os tamanhos e setores estão cada vez mais vulneráveis a ameaças cibernéticas, tornando o seguro cibernético essencial. Grandes corporações, instituições financeiras e setores de saúde, que lidam com dados sensíveis, são clientes naturais. Pequenas e médias empresas também devem considerar, pois muitas vezes têm menos proteção e são alvos fáceis.',
    cover: dataAnonimazationCover,
    coverText: 'Imagem de uma mão segurando uma lapa através de um escudo',
    benefitsList: [
      'Conformidade Legal;',
      'Privacidade Aprimorada;',
      'Segurança Reforçada;',
    ],
  },
  benefits: [
    {
      title: 'Conformidade Legal',
      altText: 'Conformidade Legal',
      description:
        'Elimine riscos associados à perda, esquecimento ou compartilhamento inadequado de senhas.',
      icon: conformidadeIcon,
    },
    {
      title: 'Privacidade Aprimorada',
      altText: 'Privacidade Aprimorada',
      description:
        'Funcionários não têm conhecimento das senhas, impedindo possíveis vazamentos.',
      icon: privacidadeIcon,
    },
    {
      title: 'Segurança Reforçada',
      altText: 'Segurança Reforçada',
      description:
        'Reduza o tempo gasto em recuperação de senhas e resolva problemas de acesso de forma ágil.',
      icon: segurancaIcon,
    },
  ],
}

export default dataAnonymization
