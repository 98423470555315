import onlyLetters from './onlyLetters'
import onlyNumbers from './onlyNumbers'
import removeSpaces from './removeSpaces'

const regex = {
  onlyLetters,
  onlyNumbers,
  removeSpaces,
}

export default regex
