import constants from 'constants/index'
import { Briefcase as BriefcaseIcon, Unlock as UnlockIcon } from 'react-feather'
import { Apartment as ApartmentIcon } from '@material-ui/icons'

const iconDataProcessSources = (source) => {
  switch (source) {
    case constants.dataProcess.SOURCE_DEPARTMENT_KIND:
      return BriefcaseIcon
    case constants.dataProcess.SOURCE_THIRD_PARTY_KIND:
      return ApartmentIcon
    default:
      return UnlockIcon
  }
}

export default iconDataProcessSources
