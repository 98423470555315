const checkedBoxes = () => {
  const checkboxes = document.querySelectorAll(
    'input[type="checkbox"][name="childreen"]:checked ',
  )

  const checkedValues = Array.from(checkboxes).map((checkbox) => checkbox.id)

  return { checkedValues }
}

export default checkedBoxes
