import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  cardContainer: {
    transition: '0.3s ease-in-out',
  },
  logoContainer: {
    borderRadius: 10,
    width: 170,
    height: 150,
    objectFit: 'scale-down',
    [theme.breakpoints.down('md')]: {
      width: 150,
      height: 130,
    },
    [theme.breakpoints.down('sm')]: {
      width: 130,
      height: 110,
    },
  },
  logoLayout: {
    [theme.breakpoints.down('lg')]: {
      marginBlock: theme.spacing(8),
    },
  },
  storeIcon: {
    fontSize: 92,
  },
  description: {
    paddingRight: theme.spacing(2),
  },
}))

export default styles
