import React from 'react'
import constants from 'constants/index'
import {
  FinalizedAlert,
  ContinuedAlert,
} from 'components/RmcActionContent/components'

const FinalizedOrContinuedAlert = ({
  nonComplianceReport,
  unnecessaryDatas,
  oldScreen,
  isRisk = false,
}) => {
  const rmcFinalized =
    nonComplianceReport.status ===
    constants.nonComplianceReports.FINALIZED_STATUS

  const finalized = () => {
    if (isRisk || oldScreen) {
      return rmcFinalized
    }

    return (
      unnecessaryDatas?.filter(
        (unnecessaryData) =>
          unnecessaryData.status ===
          constants.unnecessaryData.UNNECESSARY_STATUS,
      )?.length === 0
    )
  }

  if (finalized()) {
    return (
      <FinalizedAlert
        nonComplianceReport={nonComplianceReport}
        isRisk={isRisk}
        finalized
      />
    )
  }
  return (
    <ContinuedAlert
      nonComplianceReport={nonComplianceReport}
      continued={
        nonComplianceReport.chosenAction ===
        constants.nonComplianceReports.CONTINUED_CHOSEN_ACTION
      }
      isRisk={isRisk}
      finalized={false}
    />
  )
}

export default FinalizedOrContinuedAlert
