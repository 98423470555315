import React from 'react'

import { Box } from '@material-ui/core'
import { GapAnalysisHeader } from 'components'
import { InactiveQuestionsCard } from './components'

const InactiveQuestions = ({ displayQuestions }) => {
  return (
    <>
      <GapAnalysisHeader title='O DPOnet acompanha no dia a dia as exigências que podem ser feitas para a sua organização. Algumas exigências podem deixar de fazer sentido, e então é possível ter "Questões Inativas". Se alguma questão for inativada para a sua organização, será enviado um e-mail informando o Responsável sobre essa alteração.' />
      <Box mt={6}>
        <InactiveQuestionsCard displayQuestions={displayQuestions} />
      </Box>
    </>
  )
}

export default InactiveQuestions
