import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonPrimary: {
    backgroundColor: theme.palette.subscription.primary.main,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.subscription.primary.dark,
    },
  },
  actionBox: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  forgotPasswordLink: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
}))

export default styles
