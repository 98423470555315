import React from 'react'
import Helmet from 'react-helmet'

import { getGoogleAnalyticsTag } from 'service/env'

const GoogleAnalyticsGlobalTag = () => {
  const googleAnalyticsURL = `https://www.googletagmanager.com/gtag/js?id=${getGoogleAnalyticsTag()}`

  const injectGA = () => {
    window.dataLayer = window.dataLayer || []

    function gtag() {
      window.dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', getGoogleAnalyticsTag())
  }

  return (
    <>
      {process.env.NODE_ENV !== 'development' && (
        <>
          <Helmet
            script={[
              {
                src: googleAnalyticsURL,
                async: true,
              },
            ]}
          />
          <script>{injectGA()}</script>
        </>
      )}
    </>
  )
}

export default GoogleAnalyticsGlobalTag
