import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'

import {
  Container,
  Page,
  RoundedBorder,
  StatusesByProcessGraphic,
} from 'components'
import {
  Header,
  ManagementPdf,
  ReportVersionExistsDialog,
  ReportVersionTable,
} from './components'
import {
  DepartmentsByThreats,
  DistributionRisksDegreeSeverity,
  FragilitiesWithLinkedMeasures,
  SecurityMeasuresStatus,
  SecurityMeasuresStatusByCategory,
} from 'components/DashboardDiagnostic'

import * as service from 'service'

import constants from 'constants/index'

import useStyles from './styles'
import helpers from 'helpers'

const Management = () => {
  const classes = useStyles()

  const [gapStats, setGapStats] = useState({})
  const [processStats, setProcessStats] = useState({})
  const [generalStats, setGeneralStats] = useState({})
  const [supplierStats, setSupplierStats] = useState({})
  const [fragilityStats, setFragilityStats] = useState({})
  const [questionControlStats, setQuestionControlStats] = useState({})
  const [loading, setLoading] = useState()
  const [exportingPdf, setExportingPdf] = useState(false)
  const [refresher, setRefresher] = useState(false)
  const [reportVersionExistsOpen, setReportVersionExistsOpen] = useState(false)
  const [currentReportVersion, setCurrentReportVersion] = useState()

  const {
    process: processIds,
    securityMeasures: securityMeasuresIds,
    fragilities: fragilitiesIds,
  } = constants.myLgpd.GRAPH_IDS

  useEffect(() => {
    const loadStats = async () => {
      setLoading(true)
      const [
        gapStatsResponse,
        processStatsResponse,
        generalStatsResponse,
        supplierStatsResponse,
        fragilityStatsResponse,
        questionControlResponse,
      ] = await Promise.all([
        service.dponet.myLgpd.getGapStats(),
        service.dponet.myLgpd.getProcessStats(),
        service.dponet.myLgpd.getGeneralStats(),
        service.dponet.myLgpd.getSupplierStats(),
        service.dponet.myLgpd.getFragilityStats(),
        service.dponet.myLgpd.getQuestionControlStats(),
      ])
      setGapStats(gapStatsResponse.data.stats)
      setProcessStats(processStatsResponse.data.stats)
      setGeneralStats(generalStatsResponse.data.stats)
      setSupplierStats(supplierStatsResponse.data.stats)
      setFragilityStats(fragilityStatsResponse.data.stats)
      setQuestionControlStats(questionControlResponse.data.stats)
      setLoading(false)
    }

    loadStats()
    //eslint-disable-next-line
  }, [])

  const departmentsLowestOfThreats = helpers.myLgpd.threatsByDepartment(
    fragilityStats?.departments,
    ['stats.totalCount'],
    ['asc'],
  )

  const departmentsHighestOfThreats = helpers.myLgpd.threatsByDepartment(
    fragilityStats?.departments,
    ['stats.totalCount'],
    ['desc'],
  )

  const departmentsWithLowerSeverity = helpers.myLgpd.threatsByDepartment(
    fragilityStats?.departments,
    ['stats.severe', 'stats.high', 'stats.medium', 'stats.low'],
    ['asc', 'asc', 'asc', 'asc'],
  )

  const departmentsWithGreaterSeverity = helpers.myLgpd.threatsByDepartment(
    fragilityStats?.departments,
    ['stats.severe', 'stats.high', 'stats.medium', 'stats.low'],
    ['desc', 'desc', 'desc', 'desc'],
  )

  const buttonAction = () => {
    if (!!currentReportVersion?.id) {
      return setReportVersionExistsOpen(true)
    }
    setExportingPdf(true)
  }

  return (
    <Page className={classes.root} title="Relatório Gerencial">
      <Box position="relative">
        <RoundedBorder />
      </Box>
      <Container maxWidth={false} spacingXl={15} spacingLg={10} spacingMd={5}>
        <Box py={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Header
                type="management"
                reportTour="gerencial"
                isLoading={loading}
                buttonAction={buttonAction}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.titleInfo}>
                Resumo do relatório gerencial em gráficos
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <StatusesByProcessGraphic
                processStats={processStats}
                id={processIds?.statuses}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SecurityMeasuresStatus
                questionControlStats={questionControlStats}
                id={securityMeasuresIds.statuses}
              />
            </Grid>
            {fragilityStats && (
              <Grid item md={4} xs={12}>
                <DistributionRisksDegreeSeverity
                  fragilityStats={fragilityStats}
                  id={fragilitiesIds.bySeverities}
                />
              </Grid>
            )}
            {fragilityStats && (
              <Grid item md={4} xs={12}>
                <FragilitiesWithLinkedMeasures
                  fragilityStats={fragilityStats}
                  id={fragilitiesIds.withSecurityMeasures}
                />
              </Grid>
            )}
            <Grid item md={8} xs={12}>
              <SecurityMeasuresStatusByCategory
                questionControlStats={questionControlStats}
                id={securityMeasuresIds.statusesByCategories}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DepartmentsByThreats
                title="Departamentos por menor quantidade de ameaças"
                subtitle="Ranking dos quatro departamentos com menor quantidade"
                values={departmentsLowestOfThreats}
                id={fragilitiesIds.departmentsWithLowestFragilities}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DepartmentsByThreats
                title="Departamentos por maior quantidade de ameaças"
                subtitle="Ranking dos quatro departamentos com maior quantidade"
                values={departmentsHighestOfThreats}
                id={fragilitiesIds.departmentsWithHighestFragilities}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DepartmentsByThreats
                title="Departamentos por menor severidade de ameaças"
                subtitle="Ranking dos quatro departamentos com menor severidade"
                values={departmentsWithLowerSeverity}
                id={fragilitiesIds.departmentsWithLowestSeverities}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DepartmentsByThreats
                title="Departamentos por maior severidade de ameaças"
                subtitle="Ranking dos quatro departamentos com maior severidade"
                values={departmentsWithGreaterSeverity}
                id={fragilitiesIds.departmentsWithHighestSeverities}
              />
            </Grid>
            <Grid item xs={12}>
              <ReportVersionTable
                currentReportVersion={currentReportVersion}
                kind={constants.reportVersions.KIND.MANAGEMENT}
                refresher={refresher}
                setCurrentReportVersion={setCurrentReportVersion}
              />
            </Grid>
          </Grid>
          <ManagementPdf
            exportingPdf={exportingPdf}
            setExportingPdf={setExportingPdf}
            generalStats={generalStats}
            gapStats={gapStats}
            processStats={processStats}
            supplierStats={supplierStats}
            fragilityStats={fragilityStats}
            questionControlStats={questionControlStats}
            questionnaires={gapStats?.questionnaires}
            setLoading={setLoading}
            refresh={() => setRefresher(!refresher)}
            shouldCreateVersion={!currentReportVersion?.id}
          />
          <ReportVersionExistsDialog
            currentReportVersion={currentReportVersion}
            exportAction={() => setExportingPdf(true)}
            open={reportVersionExistsOpen}
            setCurrentReportVersion={setCurrentReportVersion}
            setOpen={setReportVersionExistsOpen}
          />
        </Box>
      </Container>
    </Page>
  )
}

export default Management
