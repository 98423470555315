import React, { useState } from 'react'
import { isEmpty, filter } from 'lodash'

import PropTypes from 'prop-types'

import MultiSelect from '@kenshooui/react-multi-select'
import { BaseDialog } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'

import useStyles from './styles'

const FragilitiesDialog = ({
  dataProcessId,
  defaultSelectedItems,
  open,
  setOpen,
  refresh,
}) => {
  const snackbar = useSnackbar()

  const [selectedItems, setSelectedItems] = useState(
    filter(defaultSelectedItems, ['isCompany', false]).map((fragility) => ({
      id: fragility?.id,
      label: fragility?.name,
    })),
  )

  const classes = useStyles()

  const { response } = useFetch(
    service.dponet.companyFragilities.get,
    {
      perPage: 9999,
      hideIsCompany: true,
      status: constants.fragilities.ACTIVE,
    },
    [open],
    !!open,
  )

  const companyFragilities =
    response?.data?.companyFragilities?.map((fragility) => ({
      id: fragility?.id,
      label: fragility?.name,
    })) || []

  const multiSelectSearch = (value) => (item) => {
    return (
      String(item.label).toLowerCase().includes(value.toLowerCase()) ||
      String(item.group).toLowerCase().includes(value.toLowerCase())
    )
  }

  const handleChange = (data) => {
    setSelectedItems(data)
  }

  const handleSave = async () => {
    try {
      const data = helpers.fragilityEntities.manageFragilities(
        defaultSelectedItems,
        selectedItems,
      )

      if (isEmpty(data.toCreate) && data.toDestroy) return setOpen(false)

      await service.dponet.companyFragilities.manageDataProcess({
        dataProcessId,
        ...data,
      })
      snackbar.open({
        message: 'Riscos atualizados com sucesso',
        variant: 'success',
      })
      setOpen(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Adicionar ameaça"
      closeButtonText="Voltar"
      actionButtonText="Salvar"
      dialogSize="lg"
      actionButton={handleSave}
      fullWidth
    >
      <MultiSelect
        messages={helpers.dataCollected.messagesMultiSelect()}
        items={companyFragilities}
        selectedItems={selectedItems}
        filterFunction={multiSelectSearch}
        onChange={handleChange}
        wrapperClassName={classes.multiSelect}
      />
    </BaseDialog>
  )
}

FragilitiesDialog.propTypes = {
  dataProcessId: PropTypes.number,
  defaultSelectedItems: PropTypes.array,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
}

export default FragilitiesDialog
