import { makeStyles } from '@material-ui/core'

const styles = (buttonPadding) =>
  makeStyles((theme) => ({
    iconButton: {
      backgroundColor: theme.colors.firstStep.button.light,
      color: theme.colors.firstStep.primary,
      padding: buttonPadding,
      '&:hover': {
        backgroundColor: theme.colors.firstStep.button.light,
      },
    },
  }))

export default styles
