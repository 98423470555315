import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Card as CardMaterial,
  Typography,
  Collapse,
} from '@material-ui/core'
import { ToggleButton } from 'components'

import useStyles from './styles'

const Card = ({
  title,
  dropdown,
  children,
  actionButton,
  expandedOption,
  paddingBottom,
  childrenStyle,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(expandedOption)

  useEffect(() => {
    setExpanded(expandedOption)
  }, [expandedOption])

  return (
    <Box pb={paddingBottom}>
      <CardMaterial>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.borderBottom}
          pl={2}
          py={2}
        >
          <Typography variant="h5">{title}</Typography>
          <Box display="flex" justifyContent="center" alignItems="center">
            {actionButton && <Box pr={1}>{actionButton}</Box>}
            {dropdown && (
              <Box pr={1}>
                <ToggleButton
                  size={18}
                  expanded={expanded}
                  onClick={() => setExpanded(!expanded)}
                />
              </Box>
            )}
          </Box>
        </Box>
        {children && !dropdown ? (
          <Box style={childrenStyle || { padding: 16 }}>{children}</Box>
        ) : (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {children}
          </Collapse>
        )}
      </CardMaterial>
    </Box>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  dropdown: PropTypes.bool,
  children: PropTypes.node,
  actionButton: PropTypes.node,
  expandedOption: PropTypes.bool,
  paddingBottom: PropTypes.number,
  childrenStyle: PropTypes.object,
}

Card.defaultProps = {
  dropdown: false,
  actionButton: null,
  expandedOption: false,
  paddingBottom: 2,
  childrenStyle: null,
}

export default Card
