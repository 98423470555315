import cardImageBase from 'images/CartaoSeloMaturidade.png'

const styles = (theme) => ({
  cardBackground: {
    backgroundImage: `url(${cardImageBase})`,
    height: '400px',
    width: '636px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: 'white',
    fontFamily: 'Roboto, sans-serif',
    position: 'relative',
  },
  cardContainer: {
    width: '100%',
    textAlign: 'center',
    maxWidth: '1080px',
    top: '30px',
    boxSizing: 'border-box',
    paddingTop: '110px',
  },
  BoxQrcode: {
    position: 'absolute',
    bottom: '20px',
    left: '110px',
  },
  qrcode: {
    width: '90px',
  },
  boxCompany: {
    display: 'flex',
    justifyContent: 'center',
  },
  companyName: {
    fontWeight: 700,
    fontSize: 20,
    letterSpacing: '-0.06px',
  },
  companyNameLong: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px',
  },
  contextCompany: {
    width: '55%',
    padding: theme.spacing(0, 2),
  },
  dateContainer: {
    display: 'flex',
    justifyContent: ' center',
    alignItems: 'center',
    width: '50%',
    paddingLeft: 8,
    paddingTop: 20,
  },
  date: {
    fontFamily: 'Raleway',
    fontSize: 22,
  },
})

export default styles
