import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    overflowY: 'hidden',
  },
  textField: {
    marginBottom: '20px',
  },
  boxMultiSelect: {
    maxHeight: '200px',
    overflowY: 'auto',
  },
  multiSelect: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
    fontSize: '90%',
    height: '15vh',
  },
}))

export default styles
