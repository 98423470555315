import * as yup from 'yup'

import helpers from 'helpers'

import constants from 'constants/index'

const schema = (consentForm) => {
  const validationFields = {}

  const allowedTypes = constants.consent.SUPPORTED_DOCUMENT_FILE_INPUT_TYPES

  Object.entries(JSON.parse(consentForm?.components) || {}).forEach(
    ([key, value]) => {
      if (value?.required) {
        validationFields[key] = yup.string().required()
      } else {
        validationFields[key] = yup.string()
      }
    },
  )

  validationFields['document'] = yup
    .mixed()
    .test('fileSize', 'O documento deve ter no máximo 10 MB', (value) =>
      helpers.validates.file.size('MB', 10, value),
    )
    .test(
      'fileType',
      `Somente arquivos ${helpers.formatters.file.allowedTypes(
        allowedTypes,
      )} são permitidos`,
      (value) =>
        helpers.validates.file.type(
          constants.consent.SUPPORTED_DOCUMENT_FILE_SCHEMA_TYPES,
          value,
        ),
    )
    .required()

  return yup.object().shape(validationFields)
}

export default schema
