import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import useCustomTheme from 'hooks/useCustomTheme'

import styles from './styles'

const useStyles = makeStyles(styles)

const ScreenLoading = ({ loaded, children }) => {
  const classes = useStyles()
  const { customLogo } = useCustomTheme()

  return (
    <>
      <div className={`${classes.root} ${loaded && classes.rootLoaded}`}>
        <div className={`${classes.bg} ${loaded && classes.bgLoaded}`} />
        <figure className={`${classes.logo} ${loaded && classes.logoLoaded}`}>
          <img src={customLogo} width="100%" alt="logo-loading" />
        </figure>
      </div>

      {loaded && children}
    </>
  )
}

ScreenLoading.propTypes = {
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.any,
}

export default ScreenLoading
