import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Box,
  Hidden,
  Paper,
  TablePagination,
  Typography,
} from '@material-ui/core'
import {
  ConfirmationDialog,
  FilterButton,
  Filters,
  LoadingFeedback,
} from 'components'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { ReportVersionRow } from './components'

import useSnackbar from 'hooks/useSnackbar'
import usePagination from 'hooks/usePagination'
import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'

import * as service from 'service'
import helpers from 'helpers'

const ReportVersionTable = ({
  currentReportVersion,
  kind,
  refresher,
  setCurrentReportVersion,
}) => {
  const snackbar = useSnackbar()
  const [openDestroy, setOpenDestroy] = useState(false)
  const [reportVersion, setReportVersion] = useState()

  useEffect(() => {
    const loadCurrentVersionOfKind = async () => {
      const response = await service.dponet.reportVersions.consultVersion(kind)

      setCurrentReportVersion(response?.data?.reportVersion)
    }

    if (!currentReportVersion) loadCurrentVersionOfKind()

    // eslint-disable-next-line
  }, [currentReportVersion, refresher])

  const filter = useFilter()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.reportVersions.get,
    { kind, ...{ ...filter.filters }, page, perPage },
    [refresher, page, perPage, filter.filters],
  )

  const { response: responseUsers, isLoading: isLoadingUsers } = useFetch(
    service.dponet.users.get,
    {
      perPage: 10000,
      minimal: true,
    },
    [filter.alreadyOpen],
    !filter.alreadyOpen && filter.drawerOpen,
  )

  const handleDestroy = async () => {
    try {
      await service.dponet.reportVersions.destroy({
        reportVersionId: reportVersion?.id,
      })
      await refresh()
      setReportVersion()
      if (reportVersion?.id === currentReportVersion?.id) {
        setCurrentReportVersion()
      }
      setOpenDestroy(false)
      snackbar.open({
        message: 'Relatório excluído com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error) ||
          'Ocorreu um erro ao tentar excluir o relatório!',
        variant: 'error',
      })
    }
  }

  return (
    <Box>
      <LoadingFeedback open={filter.drawerOpen && isLoadingUsers} />
      <Box
        pb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="subtitle1">Histórico de Versões</Typography>
        <FilterButton setDrawerOpen={filter.setDrawerOpen} />
      </Box>
      <Paper variant="outlined">
        <PerfectScrollbar
          options={{ wheelPropagation: false, useBothWheelAxes: true }}
        >
          <Table
            size="small"
            emptyMessage="Nenhum relatório encontrado"
            isLoading={isLoading}
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="20%">ID</TableCell>
                <TableCell width="30%" align="center">
                  Responsável
                </TableCell>
                <TableCell width="20%" align="center">
                  Versão
                </TableCell>
                <TableCell width="20%" align="center">
                  Data
                </TableCell>
                <TableCell width="10%" align="right">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response?.data?.reportVersions?.map((reportVersion) => (
                <ReportVersionRow
                  key={reportVersion?.id}
                  reportVersion={reportVersion}
                  setOpenDestroy={setOpenDestroy}
                  setReportVersion={setReportVersion}
                  setCurrentReportVersion={setCurrentReportVersion}
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
        <Box px={2} display="flex" justifyContent="flex-end">
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 15, 20]}
            labelRowsPerPage={<Hidden smDown>Por página </Hidden>}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Box>
      </Paper>
      <ConfirmationDialog
        open={openDestroy}
        setOpen={setOpenDestroy}
        text="Tem certeza que deseja excluir essa versão do relatório? Essa ação não poderá ser desfeita."
        textButton="Excluir"
        textButtonCancel="Cancelar"
        actionAcceptButton={handleDestroy}
      />
      <Filters filter={filter}>
        <input textfieldinput="true" label="Identificador" name="id" />
        <input textfieldinput="true" label="Versão" name="version" />
        <select
          autocompleteselectinput="true"
          optionvalues={responseUsers?.data?.users ?? []}
          label="Responsável"
          name="authorId"
        />
        <input
          datepickerinput="true"
          label="Data de criação inicial"
          name="createdAtGt"
          data-target="createdAtLt"
          data-min
        />
        <input
          datepickerinput="true"
          label="Data de criação final"
          name="createdAtLt"
          data-target="createdAtGt"
          data-max
        />
      </Filters>
    </Box>
  )
}

ReportVersionTable.propTypes = {
  currentReportVersion: PropTypes.object,
  kind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refresher: PropTypes.bool,
  setCurrentReportVersion: PropTypes.func,
}

export default ReportVersionTable
