const styles = (theme) => ({
  titleContainer: {
    paddingTop: theme.spacing(3),
  },
  root: {
    paddingBottom: theme.spacing(3),
  },
})

export default styles
