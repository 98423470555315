import antivirus from './antivirus'
import cyber from './cyber'
import dataAnonymization from './dataAnonymization'
import dataDiscovery from './dataDiscovery'
import dlp from './dlp'
import pentest from './pentest'
import safePassword from './safePassword'
import eletronicsDisposal from './electronicsDisposal.js'

const services = {
  [antivirus.id]: antivirus,
  [cyber.id]: cyber,
  [dataAnonymization.id]: dataAnonymization,
  [dataDiscovery.id]: dataDiscovery,
  [dlp.id]: dlp,
  [pentest.id]: pentest,
  [safePassword.id]: safePassword,
  [eletronicsDisposal.id]: eletronicsDisposal,
}

export default services
