const styles = (theme) => ({
  boxIcon: {
    backgroundColor: theme.palette.primary.main,
    height: '41px',
    width: '41px',
    borderRadius: '40px',
  },
})

export default styles
