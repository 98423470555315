import palette from 'theme/palette'

const rectangleTwoLabels = (
  pdf,
  positionX,
  positionY,
  width,
  color,
  ratio,
  label1,
  data1,
  label2,
  data2,
) => {
  const RADIUS = ratio(20)

  pdf.setFillColor(color)
  pdf.roundedRect(positionX, positionY, width, ratio(300), RADIUS, RADIUS, 'F')
  pdf.setFont('Raleway-Bold')
  pdf.setFontSize(ratio(48))
  pdf.setTextColor(palette.subscription.warning.success)
  pdf.text(label1, width / 2 - 15, positionY + ratio(150), null, null, 'left')
  pdf.setFontSize(ratio(80))

  pdf.text(
    data1,
    width / 2 - 10,
    positionY + ratio(150) + 12,
    null,
    null,
    'left',
  )

  pdf.setFontSize(ratio(48))
  pdf.setTextColor(palette.text.mainMenu)
  pdf.text(label2, width / 2 + 70, positionY + ratio(150), null, null, 'left')
  pdf.setFontSize(ratio(80))

  pdf.text(
    data2,
    width / 2 + 80,
    positionY + ratio(150) + 12,
    null,
    null,
    'left',
  )
}

export default rectangleTwoLabels
