import React from 'react'
import PropTypes from 'prop-types'
import { Permitted } from 'components'
import usePermissions from 'hooks/usePermissions'

const PermittedBySupplier = ({
  permission,
  somePermissions,
  title,
  children,
  show,
}) => {
  const permissions = usePermissions()

  if (show && permissions.isSupplier()) {
    return children
  }

  return (
    <Permitted
      tag={permission}
      someTags={somePermissions}
      key={title}
      show={show}
    >
      {children}
    </Permitted>
  )
}

PermittedBySupplier.propTypes = {
  permission: PropTypes.any,
  somePermissions: PropTypes.array,
  title: PropTypes.any,
  children: PropTypes.any,
  show: PropTypes.bool,
}

PermittedBySupplier.defaultProps = {
  show: true,
}

export default PermittedBySupplier
