import Edit from './AuditsEdit'
import Main from './AuditsMain'
import New from './AuditsNew'
import Show from './AuditsShow'
import Automation from './Automation'

export default {
  Edit,
  Main,
  New,
  Show,
  Automation,
}
