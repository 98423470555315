import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

import { DocumentForm } from './components'
import { LoadingButton } from 'components'

const DocumentDialog = ({
  open,
  onClose,
  document,
  action,
  categories,
  reloadDocuments,
}) => {
  const [loading, setLoading] = useState(false)

  const titleByAction = {
    create: 'Novo documento',
    show: 'Visualizar documento',
    edit: 'Editar documento',
    destroy: 'Exluir documento',
  }
  const actionButtonTextByAction = {
    create: 'ADICIONAR',
    edit: 'EDITAR',
    destroy: 'EXCLUIR',
  }
  const shoudShowActionButton = action !== 'show'

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">{titleByAction[action]}</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box my={1}>
        <DialogContent>
          <DocumentForm
            document={document}
            action={action}
            categories={categories}
            reloadDocuments={reloadDocuments}
            setLoading={setLoading}
          />
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button type="button" variant="outlined" onClick={onClose}>
            Voltar
          </Button>
        </Box>
        {shoudShowActionButton && (
          <LoadingButton
            variant="contained"
            type="submit"
            color="primary"
            form="document-form"
            text={actionButtonTextByAction[action]}
            loading={loading}
          />
        )}
      </DialogActions>
    </Dialog>
  )
}

DocumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  reloadDocuments: PropTypes.func.isRequired,
}

export default DocumentDialog
