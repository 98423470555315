import components from '../../components'

import palette from 'theme/palette'

import helpers from 'helpers'

import * as helpersRmc from '../../helpers'

const process = async (pdf, date, dataProcessesRmcs, customThemeStatus) => {
  const dataProcesses = dataProcessesRmcs?.map((data) => {
    return [
      ['ID', data?.id],
      ['Status', helpers.nonComplianceReports.status(data?.status)],
      ['Origem', helpers.nonComplianceReports.origin(data?.finality)],
      [
        'Riscos',
        helpers.dataProcess.fragilityLabel(data?.dataProcessFragility),
      ],
      ['Processo', data?.entity?.name],
      [
        'Prazo',
        !!data?.deadlineDate
          ? helpers.formatters.date(data?.deadlineDate)
          : 'Não informado',
      ],
      [
        'Responsável',
        !!data?.responsableUser?.name
          ? data?.responsableUser?.name
          : 'Nenhum responsável vinculado',
      ],
      ['Ações', helpersRmc.actionText(data)],
    ]
  })

  pdf.addPage('a4', 'p')
  components.numberAndTitle(pdf, '2', 'Processos')

  dataProcesses.forEach((data, index) => {
    components.lineWithDate(pdf, date)
    components.table(
      pdf,
      [],
      data,
      index === 0 ? 200 : pdf.lastAutoTable.finalY + 35,
      0.25,
    )
    components.numberPage(pdf, palette.black, customThemeStatus)
  })
}

export default process
