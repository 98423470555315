import React from 'react'
import PropTypes from 'prop-types'
import { IconButton as IconButtonMaterial } from '@material-ui/core'

import useFirstStep from 'hooks/useFirstStep'

import styles from './styles'

const IconButton = ({ icon, paddingButton, mixpanelId, ...rest }) => {
  const { registerTrack } = useFirstStep()

  const useStyles = styles(paddingButton)
  const classes = useStyles()

  const handleClick = () => {
    if (!!rest?.onClick) {
      rest.onClick()
    }
    registerTrack(mixpanelId)
  }

  return (
    <IconButtonMaterial
      className={classes.iconButton}
      {...rest}
      onClick={handleClick}
    >
      {icon}
    </IconButtonMaterial>
  )
}

IconButton.propTypes = {
  icon: PropTypes.any,
  paddingButton: PropTypes.number,
  mixpanelId: PropTypes.string,
}

IconButton.defaultProps = {
  paddingButton: 8,
}

export default IconButton
