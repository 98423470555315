import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '0px 0px 4px 4px',
    color: theme.palette.white,
    backgroundColor: theme.palette.categoryCard.selectedColor,
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    boxShadow:
      '0px 2px 2px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 2px 3px 0px rgb(0 0 0 / 12%)',
  },
  gridCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2.5),
    },
  },
  gridStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      marginTop: theme.spacing(2.5),
    },
  },
  linkStyle: {
    cursor: 'pointer',
  },
  colorOBS: {
    color: theme.palette.secondary.light,
  },
  saveButton: {
    backgroundColor: theme.palette.subscription.primary.main,
    marginLeft: theme.spacing(2),
    maxWidth: theme.spacing(6),
    borderRadius: 5,
    maxHeight: 53.6,
    '&:hover': {
      backgroundColor: theme.palette.subscription.primary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  labelSaveButton: {
    padding: 0,
    width: 30,
    height: 30,
  },
}))

export default styles
