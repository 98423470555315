import helpers from '..'

const getFragilityActionText = (rmc) => {
  if (
    helpers.nonComplianceReports.statusesCheck.isSubstituted(rmc) &&
    !rmc.chosenAction
  ) {
    return {
      title: 'RMC substituído devido a alteração do processo',
      body: `O processo que gerou o RMC foi alterado pela organização, de
      forma que o processo tem agora uma nova versão. Assim, este RMC
      foi substituído e um novo RMC foi gerado para a nova versão do
      processo. Para visualizar o novo RMC gerado,`,
    }
  }

  if (
    helpers.nonComplianceReports.statusesCheck.isFinalized(rmc) &&
    !rmc.chosenAction
  ) {
    return {
      title: 'Risco reduzido após reanálise de necessidade e proporcionalidade',
      body: `A eliminação dos itens indicados acima também causou a diminuição do risco para ${helpers.dataProcess.fragilityLabel(
        rmc?.entity?.dataProcess?.adoptedFragilityId,
        true,
      )}. Por esse motivo, não é necessário vincular medidas de segurança específicas para a atividade de tratamento de dados pessoais e o RMC foi finalizado.`,
    }
  }

  return {
    title: 'Ação - Inativar o processo que gerou o RMC de risco',
    body: `Escolha inativar o processo, opção em que a empresa não irá mais
    realizar esse processo com dados pessoais, ou continuar com o
    processo, situação em que a empresa irá continuar realizando
    este processo com dados pessoais.`,
  }
}

export default getFragilityActionText
