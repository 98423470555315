import api from 'service/api'

const dponetAPI = api.create('dponet')

const create = async ({ nonComplianceReportId, ...data }) => {
  return await dponetAPI.post(
    `/non_compliance_reports/${nonComplianceReportId}/specific_risks`,
    data,
  )
}

const update = async ({ nonComplianceReportId, specificRiskId, ...data }) => {
  return await dponetAPI.put(
    `/non_compliance_reports/${nonComplianceReportId}/specific_risks/${specificRiskId}`,
    data,
  )
}

const destroy = async ({ nonComplianceReportId, specificRiskId, ...data }) => {
  return await dponetAPI.delete(
    `/non_compliance_reports/${nonComplianceReportId}/specific_risks/${specificRiskId}`,
    data,
  )
}

export default { create, update, destroy }
