const removeDuplicates = (array, prop) => {
  const seen = new Set()

  return array.filter((item) => {
    const value = prop ? item[prop] : item
    if (seen.has(value)) {
      return false
    } else {
      seen.add(value)
      return true
    }
  })
}

export default removeDuplicates
