import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ privacyPolicyId = '', ...params }) => {
  return await dponetAPI.get(`/privacy_policies/${privacyPolicyId}`, { params })
}

const create = async ({ ...privacyPolicy }) => {
  return await dponetAPI.post('/privacy_policies', privacyPolicy)
}

const put = async ({ privacyPolicyId = '', ...privacyPolicy }) => {
  return await dponetAPI.put(`/privacy_policies/${privacyPolicyId}`, privacyPolicy)
}

const destroy = async ({ privacyPolicyId = '' }) => {
  return await dponetAPI.delete(`/privacy_policies/${privacyPolicyId}`)
}

export default {
  get,
  create,
  put,
  destroy,
}
