import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonBackToSteps: {
    width: '100%',
    backgroundColor: colors.green[500],
    color: colors.grey[50],
    '&:hover': {
      backgroundColor: colors.green[400],
    },
  },
  transitionEnter: {
    opacity: 0,
    transform: 'scale(0.9)',
  },
  transitionEnterActive: {
    opacity: 1,
    transform: 'translateX(0)',
    transition: 'opacity 300ms, transform 300ms',
  },
  transitionExit: {
    opacity: 1,
  },
  transitionExitActive: {
    opacity: 0,
    transform: 'scale(0.9)',
    transition: 'opacity 300ms, transform 300ms',
  },
}))

export default styles
