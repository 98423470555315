import constants from 'constants/index'

const helpLinkByStatus = (status) => {
  switch (status) {
    case constants.preRegistrations.WAITING_PAYMENT_STATUS:
      return 'https://immunizesystem.desk360.com.br/ajuda/article/meu-pagamento-esta-em-processo-e-agora'
    case constants.preRegistrations.PAYMENT_FAILED_STATUS:
      return 'https://immunizesystem.desk360.com.br/ajuda/article/meu-pagamento-foi-reprovado-e-agora'
    case constants.preRegistrations.PAID_STATUS:
      return 'https://immunizesystem.desk360.com.br/ajuda/article/meu-pagamento-foi-aprovado-e-agora'
    default:
      return ''
  }
}
export default helpLinkByStatus
