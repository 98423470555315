import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import useStyles from './styles'
import { MainCard } from '..'

const CardsList = ({
  listItems,
  cardWithoutAction,
  cardTitleColor,
  iconsSize,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.solutionsContainer}>
      {listItems.map((item) => (
        <MainCard
          icon={item.icon}
          iconSize={iconsSize}
          pathName={item.pathName}
          title={item.title}
          description={item.description}
          id={item.id}
          cardTitleColor={cardTitleColor}
          withoutDivider={cardWithoutAction}
          displayButton={!cardWithoutAction}
        />
      ))}
    </Box>
  )
}

CardsList.propTypes = {
  listItems: PropTypes.array.isRequired,
  cardTitleColor: PropTypes.string,
  cardWithoutAction: PropTypes.bool,
  iconsSize: PropTypes.number,
}

CardsList.defaultProps = {
  cardWithoutAction: false,
  iconsSize: 72,
}

export default CardsList
