import React, { forwardRef } from 'react'
import clsx from 'clsx'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import { HiringBanner } from 'components'

import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'

import styles from './styles'

const useStyles = makeStyles(styles)

const Page = forwardRef(
  ({ title, children, transparent, noPadding, showHiring, ...rest }, ref) => {
    const classes = useStyles()

    return (
      <Box
        className={clsx(classes.root, {
          [classes.transparent]: transparent,
          [classes.noPadding]: noPadding,
        })}
        ref={ref}
        {...rest}
      >
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
        <HiringBanner showHiring={showHiring} />
      </Box>
    )
  },
)

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  showHiring: PropTypes.bool,
}

Page.defaultProps = {
  showHiring: true,
  noPadding: false,
}

export default Page
