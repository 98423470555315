import React from 'react'
import { Box, Chip, Grid, Typography } from '@material-ui/core'

import { Label } from 'components'
import {
  InputText,
  SingleSelect,
  MultiSelect,
  ShowDataProcessTemplateForm,
} from './components'

import useDataProcessTemplateQuestion from 'hooks/useDataProcessTemplateQuestion'

import useStyles from './styles'

const DataProcessTemplateForm = ({
  spacing = 2,
  show = false,
  dataProcessTemplateAnswers = [],
  disabled = false,
}) => {
  const { control, questions } = useDataProcessTemplateQuestion()
  const classes = useStyles()

  const filterAnswer = (questionId, questionType) => {
    const answerObject = dataProcessTemplateAnswers.filter(
      (answer) => answer.dataProcessTemplateQuestionId === questionId,
    )

    if (questionType === 'text') {
      return (
        <Typography align="justify" color="textPrimary" variant="body2">
          {answerObject[0]?.answer || '-'}
        </Typography>
      )
    }

    if (questionType === 'multi_select') {
      const options = answerObject[0]?.answer?.map((item) => {
        return item.option
      })

      return options?.map((option) => {
        return (
          <Chip
            variant="outlined"
            label={option}
            className={classes.chipStyle}
          />
        )
      })
    }

    return (
      <Typography align="justify" color="textPrimary" variant="body2">
        {answerObject[0]?.answer?.option || '-'}
      </Typography>
    )
  }

  const renderQuestion = (question) => {
    switch (question.fieldType) {
      case 'text':
        switch (show) {
          case true:
            return (
              <Typography align="justify" color="textPrimary" variant="body2">
                {filterAnswer(question.id) || '-'}
              </Typography>
            )
          default:
            return (
              <InputText
                question={question}
                control={control}
                disabled={disabled}
              />
            )
        }
      case 'single_select':
        return (
          <SingleSelect
            question={question}
            control={control}
            disabled={disabled || show}
          />
        )
      case 'multi_select':
        return (
          <MultiSelect
            question={question}
            control={control}
            disabled={disabled || show}
          />
        )
      default:
        return <></>
    }
  }

  const renderAnswer = (question) => {
    return (
      <Box display="flex" flexWrap="wrap">
        {filterAnswer(question.id, question.fieldType) || '-'}
      </Box>
    )
  }

  return (
    <Grid container spacing={spacing}>
      {show ? (
        <ShowDataProcessTemplateForm
          questions={questions}
          renderAnswer={renderAnswer}
        />
      ) : (
        <>
          {questions?.map((question) => (
            <Label
              key={question.id}
              title={question.title}
              description={question.description}
              item
              xs={12}
            >
              {renderQuestion(question)}
            </Label>
          ))}
        </>
      )}
    </Grid>
  )
}

export default DataProcessTemplateForm
