import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid, Typography } from '@material-ui/core'

import { BaseDialog } from 'components'

import helpers from 'helpers'

const AttributeRow = ({ title, description, ...props }) => {
  return (
    <Grid item {...props}>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="body1">{description || '-'}</Typography>
    </Grid>
  )
}

const ActivityShowDialog = ({ open, setOpen, task }) => {
  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      closeIconButton
      actionButton={() => setOpen(false)}
      formRef="activities-form"
      withoutBottomDivider
      title="Visualizar tarefa"
      dialogSize="sm"
    >
      <Box py={2}>
        <Grid container spacing={3}>
          <AttributeRow
            item
            xs={12}
            title="Título da atividade"
            description={task?.name}
          />
          <AttributeRow
            item
            xs={6}
            title="Responsável"
            description={task?.responsible?.name}
          />
          <AttributeRow
            item
            xs={6}
            title="Status"
            description={helpers.activities.status(task?.status)}
          />
          <AttributeRow
            item
            xs={6}
            title="Data de inicio"
            description={helpers.formatters.date(task?.startDate)}
          />
          <AttributeRow
            item
            xs={6}
            title="Prazo"
            description={helpers.formatters.date(task?.dueDate)}
          />
          <AttributeRow
            item
            xs={12}
            title="Descrição"
            description={task?.description}
          />
          <AttributeRow
            item
            xs={12}
            title="Observações"
            description={task?.observations}
          />
        </Grid>
      </Box>
    </BaseDialog>
  )
}

AttributeRow.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

ActivityShowDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
  task: PropTypes.object,
}

export default ActivityShowDialog
