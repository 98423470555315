import React from 'react'
import PropTypes from 'prop-types'

import { Chip } from '@material-ui/core'

import useStyles from './styles'

import helpers from 'helpers'

import clsx from 'clsx'

const DataProcessStatusChip = ({ title, statusId }) => {
  const classes = useStyles()
  return (
    <Chip
      className={clsx(
        classes.root,
        helpers.dataProcess.statusColor(statusId, classes),
      )}
      align="center"
      size="small"
      label={title || '-'}
    />
  )
}

DataProcessStatusChip.propTypes = {
  title: PropTypes.string.isRequired,
  statusId: PropTypes.number.isRequired,
}

export default DataProcessStatusChip
