import React, { useEffect } from 'react'

import { Grid, Box, Typography, Divider, TextField } from '@material-ui/core'

import { Controller } from 'react-hook-form'
import useFetch from 'hooks/useFetch'

import { OptionCheckIncident } from 'components'

import * as service from 'service'

const Occurrence = ({ setValue, control, incident, setLoading, watch }) => {
  const { response, isLoading } = useFetch(
    service.dponet.incidents.options,
    {
      typeFields: 'how_did_incident_become_known',
    },
    [],
  )

  useEffect(() => {
    setLoading(isLoading)
    // eslint-disable-next-line
  }, [isLoading])

  return (
    <>
      <Grid item xs={12}>
        <Box mb={2} px={2}>
          <Typography variant="h6">
            Da ciência da ocorrência do incidente
          </Typography>
        </Box>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <OptionCheckIncident
            label="Por qual meio se tomou conhecimento do incidente?"
            control={control}
            options={response?.data?.howDidIncidentBecomeKnown}
            checkedOptions={
              incident?.dataOptionIncidents?.howDidIncidentBecomeKnown
            }
            name="howDidIncidentBecomeKnown"
            setValue={setValue}
            watch={watch}
            literaName="how_did_incident_become_known"
            other
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Controller
            as={
              <TextField
                multiline
                rows={5}
                label="Descreva, resumidamente, de que forma a ocorrência do incidente foi conhecida"
                type="text"
                color="primary"
                variant="outlined"
                fullWidth
              />
            }
            control={control}
            name="decriptionOccurrenceIncidentWasKnown"
            mode="onBlur"
          />
        </Box>
      </Grid>
    </>
  )
}

export default Occurrence
