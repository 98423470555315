import React from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Button,
  DialogTitle,
  Typography,
} from '@material-ui/core'

const DialogConfirmDataTreatmentNul = ({ open, setOpen, handleNextStep }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h5">ATENÇÃO!</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent dividers>
        <Typography variant="body1" gutterBottom>
          Se houver compartilhamento de dados, é fundamental registrar cada um
          deles. Você tem certeza e confirma que não é feito nenhum
          compartilhamento dos dados deste processo?
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={handleNextStep}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogConfirmDataTreatmentNul.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
}

export default DialogConfirmDataTreatmentNul
