import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  dialogContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    gap: 8,
    padding: 24,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  subtitle: {
    fontSize: 16,
    marginBottom: 16,
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
    gap: 16,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 8,
  },
  card: {
    display: 'flex',
    gap: 8,
    width: '100%',
  },
  cardContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8,
  },
  descriptionContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 8,
  },
  videoPlayerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '80%',
    },
  },
  videoPlayer: {
    width: '100%',
    height: 320,
    [theme.breakpoints.down('xs')]: {
      height: 200,
    },
  },
}))

export default styles
