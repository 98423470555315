import React from 'react'

import { Chip } from '@material-ui/core'
import { Eye as EyeIcon } from 'react-feather'

import useStyles from './styles'

const InfoChip = ({ label }) => {
  const classes = useStyles()

  return (
    <Chip
      className={classes.chip}
      classes={{ icon: classes.icon }}
      icon={<EyeIcon size={20} />}
      label={label}
      clickable={false}
    />
  )
}

export default InfoChip
