import constants from 'constants/index'

const idToLabel = (impactId = 1, probabilityId = 1) => {
  const value = impactId + probabilityId

  if (impactId === 5 || probabilityId === 5) {
    return constants.fragilities.FRAGILITIES.undefined
  }

  if (value < 4) {
    return constants.fragilities.FRAGILITIES.low
  }

  if (value < 6) {
    return constants.fragilities.FRAGILITIES.medium
  }

  if (value === 6) {
    return constants.fragilities.FRAGILITIES.high
  }

  return constants.fragilities.FRAGILITIES.severe
}

export default idToLabel
