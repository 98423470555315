import React from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import { orderBy } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { QuestionsReview } from '../../components'

import constants from 'constants/index'
import { routes } from 'Routes'

const ApprovedLia = ({
  handleNext = () => {},
  answersReviewAndApproved = [],
  stepLabel = '',
  questions = [],
}) => {
  const history = useHistory()

  const { DPO_STEPS, KIND_LABELS, STEP_LABELS } = constants.liaQuestions
  const {
    FINALITY_STEPS_DPO,
    NECESSITY_STEPS_DPO,
    BALANCING_SAFEGUARING_STEPS_DPO,
  } = DPO_STEPS
  const { FINALITY_LABEL, NECESSITY_LABEL } = STEP_LABELS

  const findAnswer = (textToCompare) => {
    return answersReviewAndApproved.find(
      (answer) => answer.liaQuestion.text === textToCompare,
    )
  }

  const filterQuestionByKind = (kind) => {
    return questions.filter((question) => {
      return question.kind === kind
    })
  }

  const kindsByStep = () => {
    if (stepLabel === FINALITY_LABEL) {
      return FINALITY_STEPS_DPO
    }

    if (stepLabel === NECESSITY_LABEL) {
      return NECESSITY_STEPS_DPO
    }

    return BALANCING_SAFEGUARING_STEPS_DPO
  }

  const { handleSubmit } = useForm()

  const onSubmit = () => {
    if ([FINALITY_LABEL, NECESSITY_LABEL].includes(stepLabel)) {
      return handleNext()
    }
    return history.push(routes.dataProcess.all)
  }

  return (
    <Box mt={2}>
      <form form id="lia_form" onSubmit={handleSubmit(onSubmit)}>
        {kindsByStep().map((kind) => (
          <Paper key={kind} component={Box} padding={2} mt={2}>
            <Box mb={2}>
              <Typography variant="h5">{KIND_LABELS[kind]}</Typography>
            </Box>
            {orderBy(filterQuestionByKind(kind), 'order').map((question) => {
              const answer = findAnswer(question.text)
              return (
                <QuestionsReview
                  key={question.id}
                  order={question.order}
                  text={question.text}
                  answer={answer}
                />
              )
            })}
          </Paper>
        ))}
      </form>
    </Box>
  )
}

export default ApprovedLia
