const styles = (theme) => ({
  root: {
    overflowY: 'auto',
    maxHeight: '70vh',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  multiSelect: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
  },
  paperModal: {
    [theme.breakpoints.up('sm')]: {
      width: '70%',
    },
    margin: theme.spacing(1),
    padding: theme.spacing(2, 2, 3),
    marginRight: theme.spacing(4),
    outline: 'none',
  },
  tableContainerModal: {
    marginTop: theme.spacing(2),
    maxHeight: 'auto',
  },
})

export default styles
