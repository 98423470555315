import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles, Grid, Typography, Box, Button } from '@material-ui/core'

import styles from './styles'
import useAuth from 'hooks/useAuth'

const useStyles = makeStyles(styles)

const Forbidden = () => {
  const classes = useStyles()
  const history = useHistory()
  const auth = useAuth()

  return (
    <Box className={classes.root}>
      <Grid container justify="center">
        <Grid item md={8} xs={12}>
          <Box className={classes.content}>
            <img
              alt="Em desenvolvimento"
              className={classes.image}
              src="/images/forbidden.svg"
            />
            <Box my={5}>
              <Typography variant="h2">403: Acesso negado!</Typography>
            </Box>
            <Box className={classes.subtitle}>
              <Typography variant="subtitle2">
                Informamos que você não possui a permissão necessária para
                acessar esta página. Isto não é um erro, apenas a informação de
                que o seu perfil não pode visualizar esta página. Contate a
                pessoa responsável da sua empresa para solicitar que ela altere
                sua permissão ou seu Perfil de Usuário, se for o caso.
              </Typography>
            </Box>
            <Box mt={4}>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  history.push(auth.homeRoute || '/data-processes/resume')
                }
              >
                Voltar ao início
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Forbidden
