import React, { useEffect } from 'react'
import { Alert } from '@material-ui/lab'
import { Grid, TextField, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { Label } from 'components'
import AttachmentBox from '../AttachmentBox'

import useIncidentManegement from 'hooks/useIncidentManegement'
import useSnackbar from 'hooks/useSnackbar'

import { routes } from 'Routes'
import * as service from 'service'
import formatters from 'helpers/formatters'

const LegalOpinion = ({ type, setLoading }) => {
  const { wantsToAdvanceStep, handleNext, resetAdvanceStep, incident } =
    useIncidentManegement()
  const history = useHistory()
  const snackbar = useSnackbar()

  const onSubmit = async () => {
    if (type === 'create') return history.push(routes.incidents.all)

    try {
      setLoading(true)

      await service.dponet.incidents.concludeStep({
        incidentId: incident.id,
        step: 'avaliation',
      })

      resetAdvanceStep()
      handleNext()
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      resetAdvanceStep()
    }
  }

  useEffect(() => {
    if (wantsToAdvanceStep) {
      onSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantsToAdvanceStep])

  return (
    <Grid container spacing={2}>
      {incident?.legalOpinion ? (
        <>
          <Grid item xs={12}>
            <Label title="Parecer" xs={12} item>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                minRows={4}
                value={incident?.legalOpinion}
                disabled
              />
            </Label>
          </Grid>

          {incident?.attachments && (
            <Grid item xs={12}>
              <AttachmentBox attachment={incident?.attachments} />
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={12}>
          <Alert severity="info">
            <Typography>
              Por favor, aguarde! O DPO foi notificado e está avaliando seu
              incidente.
            </Typography>
          </Alert>
        </Grid>
      )}
    </Grid>
  )
}

export default LegalOpinion
