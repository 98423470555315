/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react'
import { Box, Link, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'
import * as service from 'service'
import { routes } from 'Routes'

const ResendEmail = ({ accessToken }) => {
  const [enableResendButton, setEnableResendButton] = useState(false)
  const [counter, setCounter] = useState(30)
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()
  const history = useHistory()

  const defineErrorMessage = (error) => {
    const errorMessage = error?.response.data?.error?.message

    return errorMessage || 'Ocorreu algum erro! Tente novamente!'
  }

  const resendEmail = async () => {
    setLoading(true)
    try {
      await service.dponet.twoFactorAuthentication.resendCode({
        accessToken,
      })
      setEnableResendButton(false)

      snackbar.open({
        message: 'O código foi reenviado, fique atento(a) ao seu e-mail.',
        variant: 'success',
      })
    } catch (error) {
      if (error.response.status === 403) return history.push(routes.root)

      snackbar.open({
        message: defineErrorMessage(error),
        variant: 'error',
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    // Quantidade de segundos para aparecer o botão
    if (!enableResendButton) setEnableResendButton(counter === 0)

    const timer =
      !enableResendButton && setInterval(() => setCounter(counter - 1), 1000)

    return () => clearInterval(timer)
  }, [counter])

  useEffect(() => {
    if (!enableResendButton && counter !== 1) setCounter(30)
  }, [enableResendButton])

  return (
    <Box>
      <Typography variant="subtitle1" paragraph>
        Não recebeu seu código?{' '}
        {loading ? (
          <strong>Carregando...</strong>
        ) : enableResendButton ? (
          <Link
            className={classes.link}
            onClick={resendEmail}
            aria-disabled={loading}
          >
            Reenvie
          </Link>
        ) : (
          <strong>Reenvie em {counter} segundos</strong>
        )}
      </Typography>
    </Box>
  )
}

ResendEmail.propTypes = {
  accessToken: PropTypes.string.isRequired,
}

export default memo(ResendEmail)
