import { colors } from '@material-ui/core'

import blueHexagon from 'images/Polygon_2.svg'
import greenHexagon from 'images/Polygon_2_Complete_light_green.svg'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  stepper: {
    margin: theme.spacing(1),
    padding: 0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.grey[300],
    borderRadius: 4,
  },
  step: {
    padding: theme.spacing(2),
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.grey[300],
  },
  stepConnector: {
    display: 'none',
  },
  stepIcon: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: 40,
    width: 40,
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepIconActive: {
    backgroundImage: `url(${blueHexagon})`,
    color: theme.palette.azure,
  },
  stepIconCompleted: {
    backgroundImage: `url(${greenHexagon})`,
    color: theme.palette.greenLight,
  },
  stepLabelText: {
    color: theme.palette.icon,
  },
  stepLabelTextOptional: {
    color: theme.palette.text.mainMenu,
  },
})
export default styles
