import category from './category'
import banner from './banner'
import consent from './consent'

const cookies = {
  category,
  banner,
  consent,
}

export default cookies
