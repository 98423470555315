import React from 'react'

import PropTypes from 'prop-types'

import { Box, Button, Dialog, Typography } from '@material-ui/core'

import { Check as CheckIcon } from 'react-feather'

const ConfirmationDialog = ({
  open,
  setOpen,
  text,
  textButton,
  textButtonCancel,
  actionAcceptButton,
}) => {
  const onClose = (e, reason) => {
    if (reason === 'backdropClick') return 
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box m={4} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h3" align="center">
          {text}
        </Typography>
      </Box>
      <Box mx={3} mb={2} display="flex" justifyContent="center">
        <Box pr={1}>
          <Button
            disableElevation
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            {textButtonCancel}
          </Button>
        </Box>
        <Button
          disableElevation
          startIcon={<CheckIcon />}
          variant="contained"
          color="primary"
          onClick={actionAcceptButton}
        >
          {textButton}
        </Button>
      </Box>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  actionAcceptButton: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  textButton: PropTypes.string,
  textButtonCancel: PropTypes.string,
}

ConfirmationDialog.defaultProps = {
  textButton: 'Confirmo',
  textButtonCancel: 'Voltar',
}

export default ConfirmationDialog
