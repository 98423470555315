import React from 'react'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
} from '@material-ui/core'

import { Check as CheckIcon } from '@material-ui/icons'

const DialogChangeResponsableConfirmation = ({
  open,
  setOpen = () => {},
  handleConfirmation = () => {},
}) => {
  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="confirm-change"
      maxWidth="sm"
    >
      <Box mx={1} my={4}>
        <DialogContent>
          <Typography align="center" variant="h3">
            Você deseja atribuir este papel de Representante a outro usuário em
            sua organização? Lembre-se que este usuário será o principal, quem
            irá conduzir as atividades no DPOnet e terá acesso total às
            configurações da sua organização.
          </Typography>
          <Box my={2}>
            <Typography align="center" variant="h3">
              Atenção!
            </Typography>
          </Box>
          <Typography align="center" variant="h3">
            Sua conta será excluída e você perderá o acesso ao DPOnet até que o
            novo representante faça o seu cadastro.
          </Typography>
        </DialogContent>
      </Box>
      <Box display="flex" justifyContent="center" pb={4}>
        <Box pr={3}>
          <Button
            variant="outlined"
            type="button"
            onClick={handleCancel}
            disableElevation
          >
            Voltar
          </Button>
        </Box>

        <Button
          startIcon={<CheckIcon />}
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleConfirmation}
        >
          SIM
        </Button>
      </Box>
    </Dialog>
  )
}

export default DialogChangeResponsableConfirmation
