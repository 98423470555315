import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'
import { LoadingFeedback } from 'components'

import PropTypes from 'prop-types'

const DialogRemoveDataLifeCycle = ({
  dataProcessId,
  dataLifeCycleId,
  open,
  loading,
  setOpen,
  handleRemove,
}) => {
  return (
    <>
      <LoadingFeedback open={loading} />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Box>
            <Typography variant="h4">
              Remover salvaguarda e ciclo de vida
            </Typography>
          </Box>
        </DialogTitle>
        <Divider />
        <Box mt={1} mb={1}>
          <DialogContent>
            <Box>
              <Typography>
                Tem certeza que deseja remover este salvaguarda e ciclo de vida?
              </Typography>
            </Box>
          </DialogContent>
        </Box>
        <Divider />
        <DialogActions p={2}>
          <Box pl={2} pr={1}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
          </Box>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={() => handleRemove(dataProcessId, dataLifeCycleId)}
          >
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DialogRemoveDataLifeCycle.propTypes = {
  dataProcessId: PropTypes.number.isRequired,
  dataLifeCycleId: PropTypes.number,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  setOpen: PropTypes.func,
  handleRemove: PropTypes.func,
}

DialogRemoveDataLifeCycle.defaultProps = {
  loading: false,
  setOpen: () => {},
  handleRemove: () => {},
}

export default DialogRemoveDataLifeCycle
