import antivirusService from '../services/antivirus'
import dataDiscoveryService from '../services/dataDiscovery'
import dataAnonymizationService from '../services/dataAnonymization'
import cyberService from '../services/cyber'
import pentestService from '../services/pentest'
import safePasswordService from '../services/safePassword'
import dlpService from '../services/dlp'
import eletronicsDisposal from '../services/electronicsDisposal.js'

import custoImage from 'images/custo-beneficio-icon.png'
import servicosImage from 'images/intermediacao-servicos.png'
import maturidadeImage from 'images/maturidade-lgpd.png'

import prevencaoDadosImage from 'images/prevencao-perda-dados.png'
import seguroCyberImage from 'images/seguro-cyber.png'
import cofreSenhasImage from 'images/cofre-senhas.png'
import vunerabilidadesSociaisImage from 'images/vunerabilidades-sociais.png'
import backupCyberImage from 'images/backup-cyber.png'
import vunerabilidadesInfraImage from 'images/vunerabilidades-infra.png'
import recyclingImage from 'images/recyclingImage.png'

import saudeImage from 'images/segmento-saude.png'
import condominioImage from 'images/segmento-condominio.png'
import contabilidadeImage from 'images/segmento-contabilidade.png'
import comercioImage from 'images/segmento-condominio.png'
import concessionariaImage from 'images/segmento-concessionaria.png'
import desenvolvimentoImage from 'images/segmento-desenvolvimento.png'

const segments = [
  {
    title: 'Saúde',
    description: ['Clínica médica', 'Hospital', 'Operadora de saúde'],
    icon: saudeImage,
    pathName: '/marketplace/segments/1',
  },
  {
    title: 'Condomínio',
    description: [
      'Pequenas empresas',
      'Empresas de médio porte',
      'Grandes empresas',
    ],
    icon: condominioImage,
    pathName: '/marketplace/segments/2',
  },
  {
    title: 'Contabilidade',
    description: [
      'Pequenas empresas',
      'Empresas de médio porte',
      'Grandes empresas',
    ],
    icon: contabilidadeImage,
    pathName: '/marketplace/segments/3',
  },
  {
    title: 'Comércio',
    description: ['Bares', 'Supermercados', 'Restaurantes'],
    icon: comercioImage,
    pathName: '/marketplace/segments/4',
  },
  {
    title: 'Concessionária',
    description: ['Automóveis', 'Motocicletas', 'Caminhões'],
    icon: concessionariaImage,
    pathName: '/marketplace/segments/5',
  },
  {
    title: 'Desenvolvimento de software',
    description: [
      'Pequenas empresas',
      'Empresas de médio porte',
      'Grandes empresas',
    ],
    icon: desenvolvimentoImage,
    pathName: '/marketplace/segments/6',
  },
]

const solutions = [
  {
    title: 'Data Discovery',
    description:
      'Ferramentas ou processos que, quando usados por um usuário, podem garantir que dados confidenciais não sejam acessados, usados indevidamente ou perdidos por usuários não autorizados.',
    pathName: `/marketplace/services/${dataDiscoveryService.id}`,
    icon: prevencaoDadosImage,
  },
  {
    title: 'Seguro Cyber',
    description:
      'Amparar perdas financeiras decorrentes de ataques virtuais maliciosos, ou mesmo de incidentes decorrentes de erros ou negligências causados internamente, que resultem em vazamento de dados e danos ligados ao sigilo da informação.',
    pathName: `/marketplace/services/${cyberService.id}`,
    icon: seguroCyberImage,
  },
  {
    title: 'Cofre de senhas',
    description:
      'Um cofre de senhas é uma ferramenta online que armazena senhas com segurança, exigindo apenas uma senha mestra para acesso. Simplifica a gestão, promovendo a criação de senhas únicas para cada conta, melhorando a segurança.',
    pathName: `/marketplace/services/${safePasswordService.id}`,
    icon: cofreSenhasImage,
  },
  {
    title: 'Pentest',
    description:
      'O pentest, ou teste de intrusão, simula ataques para encontrar e corrigir falhas em sistemas, protegendo contra hackers e aumentando a confiança na segurança online.',
    pathName: `/marketplace/services/${pentestService.id}`,
    icon: vunerabilidadesSociaisImage,
  },
  {
    title: 'Anonimização de dados',
    description:
      'A anonimização de dados protege a privacidade ao remover elementos de identificação pessoal, mantendo a utilidade dos dados para análises.',
    pathName: `/marketplace/services/${dataAnonymizationService.id}`,
    icon: backupCyberImage,
  },
  {
    title: 'Antivirus e antimalware',
    description:
      'As vulnerabilidades de segurança são as fraquezas/falhas de um sistema de segurança que poderiam ser potencialmente exploradas por uma ou mais ameaças e causar danos e prejuízos à organização.',
    pathName: `/marketplace/services/${antivirusService.id}`,
    icon: vunerabilidadesInfraImage,
  },
  {
    title: 'Prevenção de perda de dados (DLP)',
    description:
      'As ferramentas de DLP monitoram e evitam vazamentos de informações sensíveis, aplicando políticas, analisando atividades de usuários e garantindo conformidade com normas de segurança.',
    pathName: `/marketplace/services/${dlpService.id}`,
    icon: prevencaoDadosImage,
  },
  {
    title: ' Descarte de equipamentos eletrônicos',
    description:
      'Serviços de destruição segura de dados e reciclagem responsável de equipamentos eletrônicos, garantindo conformidade com regulamentações de proteção de dados e minimizando riscos de vazamento de informações sensíveis.',
    pathName: `/marketplace/services/${eletronicsDisposal.id}`,
    icon: recyclingImage,
  },
]

const whatIsIcons = [
  {
    title: 'Melhor custo-benefício',
    altText: 'Melhor custo-benefício',
    src: custoImage,
  },
  {
    title: 'Intermediação de serviços',
    altText: 'Intermediação de serviços',
    src: servicosImage,
  },
  {
    title: 'Maturidade à LGPD',
    altText: 'Maturidade à LGPD',
    src: maturidadeImage,
  },
]

const faqs = [
  {
    title: 'Como o Marketplace contribui para a conformidade regulatória?',
    answer:
      'No nosso marketplace ajudamos empresas de todos os portes a melhorar sua segurança e boas práticas através de produtos e serviços ofertados por parceiros certificados.',
  },
  {
    title: 'Quais são os riscos que o Marketplace ajuda a mitigar?',
    answer:
      'Ofertando uma ampla variedade de serviços, temos desde treinamentos para sua equipe até uma simulação de invasão para demonstrar a fragilidade da sua empresa referente à proteção de dados pessoais.',
  },
  {
    title: 'Como é feita a contratação de um serviço no Marketplace?',
    answer:
      'Ao finalizar seu pedido, o fornecedor receberá uma notificação da sua solicitação e entrará em contato em até 48 horas.',
  },
]

const home = {
  solutions: solutions,
  segments: segments,
  icons: whatIsIcons,
  faqs: faqs,
}

export default home
