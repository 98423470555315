import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Hidden,
  Typography,
} from '@material-ui/core'

import {
  DialogChangeResponsable,
  DialogChangeResponsableConfirmation,
} from 'components'

const ChangeResponsible = ({ userName }) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)

  const [
    openChangeResponsableDialog,
    setOpenChangeResponsableDialog,
  ] = useState(false)

  const handleChangeResponsable = () => {
    setOpenConfirmationDialog(false)
    setOpenChangeResponsableDialog(true)
  }

  return (
    <Box mt={3}>
      <Card>
        <CardHeader title="Representante" />
        <Divider />
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body1" color="textPrimary">
              <strong>{userName}</strong>, você é o representante dessa empresa
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenConfirmationDialog(true)}
            >
              ALTERAR <Hidden xsDown>REPRESENTANTE</Hidden>
            </Button>
          </Box>
        </CardContent>
      </Card>
      <DialogChangeResponsableConfirmation
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        handleConfirmation={handleChangeResponsable}
      />
      <DialogChangeResponsable
        open={openChangeResponsableDialog}
        setOpen={setOpenChangeResponsableDialog}
      />
    </Box>
  )
}

ChangeResponsible.propTypes = {
  userName: PropTypes.string,
}

export default ChangeResponsible
