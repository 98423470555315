import permissions from '../permissions'

const PAYMENT_LIST = [
  {
    name: 'Acesso ao painel financeiro',
    tag: permissions.PAYMENT_ORDERS.LIST,
  },
]

export default PAYMENT_LIST
