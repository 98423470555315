import constants from 'constants/index'

const buttonTextByStatus = (status) => {
  switch (status) {
    case constants.preRegistrations.WAITING_PAYMENT_STATUS:
      return ''
    case constants.preRegistrations.FAILURE_STATUS:
      return 'Realizar pagamento'
    case constants.preRegistrations.PAID_STATUS:
      return ''
    default:
      return ''
  }
}
export default buttonTextByStatus
