import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import useSpecificRisks from 'hooks/useSpecificRisks'
import useSnackbar from 'hooks/useSnackbar'

import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { PlusCircle } from 'react-feather'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton } from 'components'
import {
  SpecificRisksModal,
  DialogRemoveSpecificRisk,
} from '../../../../components'

import * as service from 'service'
import usePermissions from 'hooks/usePermissions'
import constants from 'constants/index'

const TableRowSpecificRisks = ({
  specificRisk,
  nonComplianceReportId,
  isLoading,
  viewOnly,
  permittedHandleSpecificRisks,
  setDialogOpen = () => {},
  handleRemoveDialog = () => {},
  handleEditDialog = () => {},
  setSpecificRisk = () => {},
  refresh = () => {},
  ...rest
}) => {
  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableRow {...rest}>
          <TableCell>{specificRisk?.id}</TableCell>
          <TableCell>{specificRisk?.description || '-'}</TableCell>
          {!viewOnly && permittedHandleSpecificRisks && (
            <TableCell align="right">
              <MenuButton>
                <Button
                  color="secondary"
                  fullWidth
                  onClick={() => handleEditDialog(specificRisk)}
                >
                  Editar
                </Button>
                <Button
                  color="secondary"
                  fullWidth
                  onClick={() => handleRemoveDialog(specificRisk)}
                >
                  Excluir
                </Button>
              </MenuButton>
            </TableCell>
          )}
        </TableRow>
      )}
    </>
  )
}

const SpecificRisksTable = ({ nonComplianceReportId, viewOnly, isLoading }) => {
  const [loading, setLoading] = useState(false)
  const { data, loadData: loadingSpecificRisks } = useSpecificRisks()
  const [specificRisk, setSpecificRisk] = useState()
  const [specificRiskModal, setSpecificRiskModal] = useState(false)
  const [specificRiskRemove, setSpecificRiskRemove] = useState(false)
  const { permitted } = usePermissions()
  const snackbar = useSnackbar()

  const permittedHandleSpecificRisks = permitted('handle_specific_risks')

  const onRefresh = async () => {
    await loadingSpecificRisks(nonComplianceReportId)
  }

  const handleRemove = async (nonComplianceReportId, specificRiskId) => {
    try {
      setLoading(true)
      await service.dponet.specificRisks.destroy({
        nonComplianceReportId,
        specificRiskId,
      })
      setSpecificRiskRemove(false)
      setLoading(false)
      snackbar.open({
        message: 'Risco específico removido com sucesso!',
        variant: 'success',
      })

      onRefresh()
    } catch {
      setLoading(false)
      snackbar.open({
        message:
          'Não foi possível remover o risco específico. Tente novamente!',
        variant: 'error',
      })
    }
  }

  const handleEditDialog = (specificRisk) => {
    setSpecificRisk(specificRisk)
    setSpecificRiskModal(true)
  }

  const handleRemoveDialog = (specificRisk) => {
    setSpecificRisk(specificRisk)
    setSpecificRiskRemove(true)
  }

  const handleNew = () => {
    setSpecificRisk()
    setSpecificRiskModal(true)
  }

  return (
    <>
      {isLoading || loading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          {!viewOnly && permittedHandleSpecificRisks && (
            <Box mt={3} mb={2}>
              <Button
                variant="outlined"
                startIcon={<PlusCircle size={20} />}
                component="label"
                htmlFor="modal-specific-risks"
                onClick={handleNew}
                id={constants.incidents.EDIT_INCIDENT_DRIVER_STEP_4}
              >
                <Box mr={1}>
                  <Typography>Adicionar riscos específicos</Typography>
                </Box>
              </Button>
            </Box>
          )}
          <PerfectScrollbar
            options={{ wheelPropagation: true, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Não foram mapeados riscos específicos para a empresa."
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="10%">Identificador</TableCell>
                  <TableCell width="80%">Risco</TableCell>
                  {!viewOnly && permittedHandleSpecificRisks && (
                    <TableCell width="10%" align="right">
                      Ações
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((specificRisk) => (
                  <TableRowSpecificRisks
                    key={specificRisk.id}
                    nonComplianceReportId={nonComplianceReportId}
                    isLoading={loading || isLoading}
                    specificRisk={specificRisk}
                    permittedHandleSpecificRisks={permittedHandleSpecificRisks}
                    viewOnly={viewOnly}
                    handleRemoveDialog={handleRemoveDialog}
                    handleEditDialog={handleEditDialog}
                    refresh={onRefresh}
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <SpecificRisksModal
            nonComplianceReportId={nonComplianceReportId}
            open={specificRiskModal}
            setOpen={setSpecificRiskModal}
            specificRisk={specificRisk}
            onEvent={onRefresh}
          />
          <DialogRemoveSpecificRisk
            open={specificRiskRemove}
            setOpen={setSpecificRiskRemove}
            handleRemove={handleRemove}
            nonComplianceReportId={nonComplianceReportId}
            specificRiskId={specificRisk?.id}
          />
        </Box>
      )}
    </>
  )
}

export default SpecificRisksTable
