import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { isArray } from 'lodash'

import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Zoom,
} from '@material-ui/core'

import helpers from 'helpers'

import useStyles from './styles'

import { SolutionIcon } from '..'

const MainCard = ({
  title,
  description,
  pathName,
  icon,
  iconSize,
  buttonText,
  withoutDivider,
  displayButton,
  cardTitleColor,
  id,
}) => {
  const classes = useStyles(withoutDivider)()
  const history = useHistory()

  const { mixpanel } = helpers

  const handleButtonClick = () => {
    if (pathName) {
      history.push(pathName)

      mixpanel.track('Marktplace', 'Tela de Início', {
        action: `Marketplace_Saiba_Mais_${title}`,
      })
    }
  }

  return (
    <Zoom in timeout={300} mountOnEnter unmountOnExit key={id}>
      <Card className={classes.container}>
        <CardContent className={classes.contentContainer}>
          <SolutionIcon
            title={title}
            titleColor={cardTitleColor}
            altText={title}
            icon={icon}
            imgHeight={iconSize}
            withoutDivider={withoutDivider}
          />
          {isArray(description) ? (
            <Box className={classes.list}>
              {description.map((description, idx) => (
                <Typography className={classes.listItem} key={idx}>
                  {description}
                </Typography>
              ))}
            </Box>
          ) : (
            <Typography className={classes.description}>
              {description}
            </Typography>
          )}

          {displayButton && (
            <Button className={classes.button} onClick={handleButtonClick}>
              {buttonText}
            </Button>
          )}
        </CardContent>
      </Card>
    </Zoom>
  )
}

MainCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  id: PropTypes.number,
  description: PropTypes.string.isRequired,
  pathName: PropTypes.string,
  buttonText: PropTypes.string,
  cardTitleColor: PropTypes.string,
  withoutDivider: PropTypes.bool,
  displayButton: PropTypes.bool,
}

MainCard.defaultProps = {
  pathName: '',
  buttonText: 'Saiba mais',
  withoutDivider: false,
  displayButton: false,
  iconSize: 72,
}

export default MainCard
