const JURIDIC_EMAIL = 'juridico@dponet.com.br'

const ACTIVE_STATUS = 'ACTIVE'
const PENDING_STATUS = 'PENDING'
const REFUSED_STATUS = 'REFUSED'
const CANCELED_STATUS = 'CANCELED'

export default {
  JURIDIC_EMAIL,
  ACTIVE_STATUS,
  PENDING_STATUS,
  REFUSED_STATUS,
  CANCELED_STATUS,
}
