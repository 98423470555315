import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  requesterBoxContainer: {
    border: `1px solid ${theme.palette.divider}`,
    marginBlock: theme.spacing(2),
  },
  requesterHeader: {
    display: 'flex',
    backgroundColor: '#EEE',
    paddingBlock: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  userAvatar: {
    marginLeft: theme.spacing(1),
  },
  contentRequesterLine: {
    padding: theme.spacing(1),
  },
  responsibleHeader: {
    width: '100%',
    display: 'flex',
    backgroundColor: theme.palette.subscription.primary.light,
    paddingBlock: theme.spacing(1),
    alignItems: 'center',
  },
  userIconBox: {
    height: 39,
    width: 39,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    paddingInline: theme.spacing(1),
    marginInline: theme.spacing(1),
    color: theme.palette.white,
  },
  boxId: {
    minWidth: 115,
    textAlign: 'center',
    paddingInline: theme.spacing(1),
    color: theme.palette.white,
    backgroundColor: theme.palette.text.mainMenu,
    borderRadius: 5,
  },
  boxStatus: {
    minWidth: 115,
    textAlign: 'center',
    paddingInline: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderRadius: 5,
  },
  newTicket: {
    backgroundColor: theme.palette.dashboard.chart.secondary.pending,
    color: theme.palette.dashboard.chart.primary.pending,
  },
  completedTicket: {
    backgroundColor: theme.palette.dashboard.chart.secondary.approved,
    color: theme.palette.dashboard.chart.primary.approved,
  },
  canceledTicket: {
    backgroundColor: theme.palette.dashboard.chart.secondary.disapproved,
    color: theme.palette.dashboard.chart.primary.disapproved,
  },
}))

export default styles
