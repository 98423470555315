import {
  AlertCircle as AlertCircleIcon,
  AlertTriangle as AlertTriangleIcon,
  CheckCircle as CheckCircleIcon,
  Info as InfoIcon,
} from 'react-feather'

// SEVERITY
const ERROR_SEVERITY_ID = 'error'
const INFO_SEVERITY_ID = 'info'
const SUCCESS_SEVERITY_ID = 'success'
const WARNING_SEVERITY_ID = 'warning'

// VARIANTS
const STANDARD_VARIANT_ID = 'standard'
const FILLED_VARIANT_ID = 'filled'
const OUTLINED_VARIANT_ID = 'outlined'

// FILLED COLORS
const MAIN_COLOR_OF_FILLED_ERROR = '#E53936'
const MAIN_COLOR_OF_FILLED_INFO = '#3288E5'
const MAIN_COLOR_OF_FILLED_SUCCESS = '#43A047'
const MAIN_COLOR_OF_FILLED_WARNING = '#F68C04'

// STANDARD COLORS
const LIGHT_COLOR_OF_STANDARD_ERROR = '#FCEBEA'
const LIGHT_COLOR_OF_STANDARD_INFO = '#E8F3FC'
const LIGHT_COLOR_OF_STANDARD_SUCCESS = '#ECF5EC'
const LIGHT_COLOR_OF_STANDARD_WARNING = '#FEF3E5'

const MAIN_COLOR_OF_STANDARD_ERROR = '#E74A46'
const MAIN_COLOR_OF_STANDARD_INFO = '#3592E7'
const MAIN_COLOR_OF_STANDARD_SUCCESS = '#54A858'
const MAIN_COLOR_OF_STANDARD_WARNING = '#F69617'

const DARK_COLOR_OF_STANDARD_ERROR = '#5B1614'
const DARK_COLOR_OF_STANDARD_INFO = '#0F365B'
const DARK_COLOR_OF_STANDARD_SUCCESS = '#1A401D'
const DARK_COLOR_OF_STANDARD_WARNING = '#643800'

const CHOOSE_MAIN_COLOR_OF_FILLED = {
  [ERROR_SEVERITY_ID]: MAIN_COLOR_OF_FILLED_ERROR,
  [INFO_SEVERITY_ID]: MAIN_COLOR_OF_FILLED_INFO,
  [SUCCESS_SEVERITY_ID]: MAIN_COLOR_OF_FILLED_SUCCESS,
  [WARNING_SEVERITY_ID]: MAIN_COLOR_OF_FILLED_WARNING,
}

const CHOOSE_LIGHT_COLOR_OF_STANDARD = {
  [ERROR_SEVERITY_ID]: LIGHT_COLOR_OF_STANDARD_ERROR,
  [INFO_SEVERITY_ID]: LIGHT_COLOR_OF_STANDARD_INFO,
  [SUCCESS_SEVERITY_ID]: LIGHT_COLOR_OF_STANDARD_SUCCESS,
  [WARNING_SEVERITY_ID]: LIGHT_COLOR_OF_STANDARD_WARNING,
}

const CHOOSE_MAIN_COLOR_OF_STANDARD = {
  [ERROR_SEVERITY_ID]: MAIN_COLOR_OF_STANDARD_ERROR,
  [INFO_SEVERITY_ID]: MAIN_COLOR_OF_STANDARD_INFO,
  [SUCCESS_SEVERITY_ID]: MAIN_COLOR_OF_STANDARD_SUCCESS,
  [WARNING_SEVERITY_ID]: MAIN_COLOR_OF_STANDARD_WARNING,
}

const CHOOSE_DARK_COLOR_OF_STANDARD = {
  [ERROR_SEVERITY_ID]: DARK_COLOR_OF_STANDARD_ERROR,
  [INFO_SEVERITY_ID]: DARK_COLOR_OF_STANDARD_INFO,
  [SUCCESS_SEVERITY_ID]: DARK_COLOR_OF_STANDARD_SUCCESS,
  [WARNING_SEVERITY_ID]: DARK_COLOR_OF_STANDARD_WARNING,
}

// DIALOG
const CHOOSE_DIALOG_BACKGROUND_COLOR = {
  [STANDARD_VARIANT_ID]: CHOOSE_LIGHT_COLOR_OF_STANDARD,
  [FILLED_VARIANT_ID]: CHOOSE_MAIN_COLOR_OF_FILLED,
  [OUTLINED_VARIANT_ID]: {},
}

const CHOOSE_DIALOG_ICON = {
  [ERROR_SEVERITY_ID]: AlertCircleIcon,
  [INFO_SEVERITY_ID]: InfoIcon,
  [SUCCESS_SEVERITY_ID]: CheckCircleIcon,
  [WARNING_SEVERITY_ID]: AlertTriangleIcon,
}

const alert = {
  CHOOSE_LIGHT_COLOR_OF_STANDARD,
  CHOOSE_MAIN_COLOR_OF_STANDARD,
  CHOOSE_DARK_COLOR_OF_STANDARD,
  CHOOSE_MAIN_COLOR_OF_FILLED,
  STANDARD_VARIANT_ID,
  FILLED_VARIANT_ID,
  OUTLINED_VARIANT_ID,
  CHOOSE_DIALOG_BACKGROUND_COLOR,
  CHOOSE_DIALOG_ICON,
}

export default alert
