import * as yup from 'yup'

const schema = (isRequired) => {
  return yup.object().shape({
    titularAgeRanges: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().required(),
          name: yup.string().required(),
        }),
      )
      .min(1),
    ...(isRequired
      ? {
          holderNumber: yup
            .number()
            .typeError('Preencha esse campo')
            .required(),
        }
      : {
          holderNumber: yup
            .number()
            .typeError('Preencha esse campo')
            .nullable(),
        }),
    titularCategoryId: yup.string().required(),
    anotherPersonalNames: yup.string(),
    anotherComportamentalNames: yup.string(),
    anotherFinancialNames: yup.string(),
  })
}

export default schema
