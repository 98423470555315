import React, { useState } from 'react'

import {
  Box,
  Button,
  Container,
  makeStyles,
  Tab,
  Tabs,
  Tooltip,
} from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import { ContentHeader, DriveTour, Page } from 'components'
import {
  Form,
  SocialMedia,
  Print,
  EmailSignature,
  PocketCard,
} from './components'

import helpers from 'helpers'
import constants from 'constants/index'

import styles from './styles'

const useStyles = makeStyles(styles)

const PrivacyStamp = () => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState('general')
  const [openTour, setOpenTour] = useState(false)

  const { mixpanel } = helpers

  const tabs = constants.privacyStamps.TABS

  const handleTabsChange = (_, value) => {
    const { label } = tabs.find((tab) => tab.value === value)
    helpers.mixpanel.track('Canal de Comunicação', label, {
      action: `${label.toLowerCase().replace(/ /g, '_')}-canal-comunicação`,
    })

    setCurrentTab(value)
  }

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Canal de Comunicação', 'Tour', {
      action: 'tour-canal-de-comunicacao-privacidade-e-voce',
    })
  }

  return (
    <Page title="Canal de Comunicação">
      <Container spacingXl={15} spacingLg={15}>
        <ContentHeader>
          <Box>
            <Tooltip title="Iniciar o tour guiado">
              <Button
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                fullWidth
                id={constants.privacyStamps.PRIVACY_STAMPS_MAIN_DRIVER_STEP_1}
              >
                Tutorial
              </Button>
            </Tooltip>
          </Box>
        </ContentHeader>
        <Box>
          <Box mt={3}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="primary"
              id={constants.privacyStamps.PRIVACY_STAMPS_MAIN_DRIVER_STEP_2}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  className={classes.tab}
                  onClick={(e) => handleTabsChange(e, tab.value)}
                />
              ))}
            </Tabs>
          </Box>
        </Box>

        {currentTab === tabs[0].value && <Form />}
        {currentTab === tabs[1].value && <EmailSignature />}
        {currentTab === tabs[2].value && <SocialMedia />}
        {currentTab === tabs[3].value && <Print />}
        {currentTab === tabs[4].value && <PocketCard />}
      </Container>

      <DriveTour
        stepsMatrix={constants.privacyStamps.PRIVACY_STAMPS_DRIVER_STEPS_OBJECT}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default PrivacyStamp
