import { COOKIE_AUTH_TOKEN } from 'service/constants'

const login = async (cookieToken) => {
  setToken(cookieToken)
}

const logout = () => {
  removeToken()
}

const setToken = (token) => {
  sessionStorage.setItem(COOKIE_AUTH_TOKEN, token)
}

const getToken = () => {
  const token = sessionStorage.getItem(COOKIE_AUTH_TOKEN)
  if (token === 'null') return false
  return token
}

const removeToken = () => {
  sessionStorage.removeItem(COOKIE_AUTH_TOKEN)
}

export default {
  getToken,
  login,
  logout,
}
