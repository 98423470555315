import React from 'react'

const Amex = ({
  alt = 'Bandeira American Express',
  height = 15,
  viewBox = '0 0 59 15', // Valor total da imagem
  width = 59,
  grayscale = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
    >
      {grayscale && (
        <filter id="grayscale">
          <feColorMatrix
            type="matrix"
            values="0.3333 0.3333 0.3333 0 0
                                           0.3333 0.3333 0.3333 0 0
                                           0.3333 0.3333 0.3333 0 0
                                           0      0      0      1 0"
          />
        </filter>
      )}
      <g filter={grayscale ? 'url(#grayscale)' : ''}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.54935 0L0 14.4935H7.84049L8.81248 12.1826H11.0342L12.0062 14.4935H20.6364V12.7298L21.4054 14.4935H25.8696L26.6386 12.6925V14.4935H44.587L46.7695 12.2426L48.813 14.4935L58.0317 14.5121L51.4617 7.2872L58.0317 0H48.956L46.8315 2.20926L44.8523 0H25.3268L23.6501 3.74089L21.9341 0H14.11V1.70371L13.2396 0H6.54935ZM33.3516 2.0581H43.6584L46.8108 5.46325L50.0648 2.0581H53.2172L48.4275 7.28517L53.2172 12.452H49.9218L46.7695 9.00729L43.4989 12.452H33.3516V2.0581ZM35.8968 6.10993V4.21141V4.2096H42.328L45.1342 7.24582L42.2036 10.2986H35.8968V8.22599H41.5197V6.10993H35.8968ZM8.06644 2.0581H11.8883L16.2324 11.8862V2.0581H20.4191L23.7745 9.10481L26.8668 2.0581H31.0326V12.4581H28.4978L28.4771 4.30873L24.7817 12.4581H22.5142L18.7981 4.30873V12.4581H13.5836L12.595 10.1266H7.25402L6.26748 12.4561H3.47358L8.06644 2.0581ZM8.16599 7.97119L9.92562 3.81773L11.6832 7.97119H8.16599Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default Amex
