import React from 'react'
import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import { Box, Grid, Hidden, Typography } from '@material-ui/core'
import { CardsCompliance, SupplierDetails, CompanyCompliance } from '..'

const GeneralInformation = ({ questionnaires, company }) => {
  const showCompliance = !isEmpty(questionnaires)

  return (
    <Box mt={4}>
      <Grid container spacing={2}>
        {showCompliance && <CardsCompliance questionnaires={questionnaires} />}
        <Grid item xs={12} {...(showCompliance && { sm: 6, lg: 4 })}>
          <Typography variant="h4" gutterBottom>
            Dados empresariais
          </Typography>
        </Grid>
        {showCompliance && (
          <Hidden xsDown>
            <Grid item xs={12} sm={6} lg={6}>
              <Typography variant="h4" gutterBottom>
                Conformidade da empresa
              </Typography>
            </Grid>
          </Hidden>
        )}
        <SupplierDetails company={company} showCompliance={showCompliance} />
        {showCompliance && (
          <CompanyCompliance companyCompliance={company?.companyCompliance} />
        )}
      </Grid>
    </Box>
  )
}

GeneralInformation.propTypes = {
  questionnaires: PropTypes.array,
  company: PropTypes.object,
}

export default GeneralInformation
