import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  divCodeTypography: {
    wordBreak: 'break-all',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  scriptCodeTypography: {
    wordBreak: 'break-all',
    paddingBottom: theme.spacing(2),
    paddingInline: theme.spacing(2),
  },
  iconButton: {
    padding: 8,
  },
}))

export default styles
