import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNil } from 'lodash'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
  Grid,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { ButtonAction, LoadingBox, Select, Label } from 'components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'
import constants from 'constants/index'

const ConfirmationRiskDialog = ({
  open,
  setOpen,
  handleConfirm,
  dataProcessId,
  setAdoptedFragilityId,
  setDefaultFragilityId,
}) => {
  const { response, isLoading } = useFetch(
    service.dponet.dataProcesses.suggestionFragility,
    { dataProcessId },
    [],
  )

  const fragilityId = isLoading ? '' : String(response?.data?.fragility?.id)
  const notReview = isNil(response)

  useEffect(() => {
    if (fragilityId) return setDefaultFragilityId(fragilityId)
    //eslint-disable-next-line
  }, [fragilityId])

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Análise de Risco</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box mt={1} mb={1}>
        <DialogContent>
          {isEmpty(fragilityId) ? (
            <>
              <Typography variant="body2">
                Calculando o risco do processo
              </Typography>
              <LoadingBox minHeight={0} />
            </>
          ) : (
            <>
              <Typography variant="body1">
                O Risco sugerido é calculado e recomendado pela plataforma. Se
                deseja, você pode alterar o risco adotado nesse processo
              </Typography>
              {notReview && (
                <Box mt={2}>
                  <Alert severity="warning">
                    <AlertTitle>Preencha os dados faltantes.</AlertTitle>
                  </Alert>
                </Box>
              )}
              <Box my={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Label title="Risco sugerido">
                      <Select
                        value={fragilityId}
                        disabled
                        items={constants.dataProcess.FRAGILITY_FILTER_OPTIONS}
                      />
                    </Label>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Label title="Risco adotado">
                      <Select
                        disabled={notReview}
                        defaultValue={fragilityId}
                        items={constants.dataProcess.FRAGILITY_FILTER_OPTIONS}
                        onChange={(event) =>
                          setAdoptedFragilityId(event.target.value)
                        }
                      />
                    </Label>
                  </Grid>
                </Grid>
              </Box>
              <Typography variant="body1">
                Após a confirmação, o processo será enviado para análise
                jurídica, e terá seu status alterado para "Aguardando revisão".
              </Typography>
            </>
          )}
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>
        </Box>
        <ButtonAction
          variant="contained"
          type="submit"
          color="primary"
          action={handleConfirm}
          name="Confirmar"
          disabled={notReview}
        />
      </DialogActions>
    </Dialog>
  )
}

ConfirmationRiskDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleConfirm: PropTypes.func,
  dataProcessId: PropTypes.number,
}

ConfirmationRiskDialog.defaultProps = {
  open: false,
  setOpen: () => {},
  handleConfirm: () => {},
}

export default ConfirmationRiskDialog
