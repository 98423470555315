import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import { ResetTemporaryPassword } from 'components'
import { TopBar, CardCompanies } from './components'
import { ScreenLoading } from 'layouts/Main/components'

import { Box } from '@material-ui/core'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'

import * as service from 'service'

import helpers from 'helpers'
import constants from 'constants/index'

const SelectCompany = () => {
  const auth = useAuth()

  const [usersCompanies, setUsersCompanies] = useState([])
  const snackbar = useSnackbar()

  const noCompaniesSnackbar = () => {
    snackbar.open({
      message:
        'Você não possui empresas ativas ou vínculos pendentes ou aprovados com clientes DPOnet',
      variant: 'error',
    })
    auth.loadOut(true)
    service.dponet.auth.logout()
  }

  const userCompanyFilter = (userCompany, filterDpo = false) => {
    if (
      filterDpo &&
      userCompany?.profile?.id === constants.profile.OUTSOURCED_DPO_PROFILE_ID
    ) {
      return false
    }

    if (
      helpers.company.isSupplier(userCompany.company) &&
      !userCompany.hasActiveReceptors
    ) {
      return false
    }

    if (userCompany?.company === null) {
      return false
    }

    return true
  }

  const { response, isLoading } = useFetch(
    service.dponet.usersCompanies.list,
    {
      status: constants.userCompanies.ACTIVE,
      perPage: 9999999,
      minimal: true,
    },
    [],
  )

  const userCompanies = response?.data?.userCompanies

  useEffect(() => {
    const loadData = async () => {
      if (!isEmpty(userCompanies)) {
        const userCompaniesFiltered = userCompanies.filter(userCompanyFilter)
        if (isEmpty(userCompaniesFiltered)) {
          noCompaniesSnackbar()
        }
        const companyWithValidProfiles = userCompaniesFiltered.filter(
          (userCompany) => userCompanyFilter(userCompany, true),
        )
        setUsersCompanies(companyWithValidProfiles)
      }
    }
    loadData()
    // eslint-disable-next-line
  }, [userCompanies])

  if (auth.temporaryPassword) {
    return <ResetTemporaryPassword />
  }

  return (
    <ScreenLoading loaded={auth.loaded && !isLoading}>
      <TopBar />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Box mx={2} mt={5}>
          <CardCompanies
            usersCompanies={usersCompanies}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </ScreenLoading>
  )
}

export default SelectCompany
