import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import { BaseDialog } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import * as service from 'service'
import constants from 'constants/index'

const AuditsInactivationModal = ({
  companyAuditId,
  isInactive,
  open,
  refresh = () => {},
  setLoading,
  setOpen,
}) => {
  const snackbar = useSnackbar()

  const onSubmit = async (event) => {
    event.preventDefault()
    try {
      setLoading(true)

      await service.dponet.companyAudits.changeStatus({
        companyAuditId,
        status: isInactive
          ? constants.audits.ACTIVE_STATUS_ID
          : constants.audits.INACTIVE_STATUS_ID,
      })

      snackbar.open({
        message: `Auditoria ${
          isInactive ? 'ativada' : 'inativada'
        } com sucesso!`,
        variant: 'success',
      })
      setOpen(false)
      refresh()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title={`${isInactive ? 'Ativar' : 'Inativar'} auditoria`}
      fullWidth
      dialogSize="md"
      actionButtonText={`${isInactive ? 'Ativar' : 'Inativar'}`}
      closeButtonText="Cancelar"
      formRef="audits-inactivation-modal-form"
    >
      <Box
        component="form"
        id="audits-inactivation-modal-form"
        onSubmit={onSubmit}
        display="flex"
        flexDirection="column"
        textAlign="justify"
        gridGap={theme.spacing(2)}
        py={1}
      >
        <Typography color="secondary" gutterBottom>
          Ao inativar a auditoria, os processos que não foram revisados e ainda
          estão marcados como 'Pendentes' retornarão ao status 'Aprovado'. No
          entanto, ao reativar a auditoria, esses processos voltarão a ficar com
          o status 'Pendente'.
        </Typography>
        <Typography variant="h6">
          Deseja realmente {isInactive ? 'ativar' : 'inativar'} esta auditoria?
        </Typography>
      </Box>
    </BaseDialog>
  )
}

AuditsInactivationModal.propTypes = {
  companyAuditId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isInactive: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  refresh: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default AuditsInactivationModal
