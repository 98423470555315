import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ documentId = '', ...params }) => {
  return await dponetAPI.get(`/documents_template/${documentId}`, { params })
}

const getCategories = async () => {
  return await dponetAPI.get('/document_categories')
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/documents_template', { document: data })
}

const put = async ({ documentId = '', ...data }) => {
  return await dponetAPI.put(`/documents_template/${documentId}`, data)
}

const destroy = async ({ documentId = '', ...data }) => {
  return await dponetAPI.delete(`/documents_template/${documentId}`, data)
}

export default {
  get,
  getCategories,
  create,
  put,
  destroy,
}
