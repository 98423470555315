import React from 'react'
import PropTypes from 'prop-types'
import { Box, colors, Typography } from '@material-ui/core'

import helpers from 'helpers'

import theme from 'theme'

const Notification = ({ interaction }) => {
  return (
    <Box
      py="2px"
      px={2}
      borderRadius={theme.spacing(2)}
      display="flex"
      bgcolor={colors.blueGrey['A400']}
      color="white"
      width="fit-content"
    >
      <Typography>
        {`${interaction?.content} - `}
        {helpers.formatters.ptBrFormatDateTime(interaction?.createdAt)}
      </Typography>
    </Box>
  )
}

Notification.propTypes = {
  interaction: PropTypes.object.isRequired,
}

export default Notification
