import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { BaseConfirmationDialog, BaseDialog } from 'components'
import { Box } from '@material-ui/core'
import { SupplierForm } from '../'

import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import helpers from 'helpers'
import * as service from 'service'

const ResendSolicitationDialog = ({
  companySupplier,
  open,
  setOpen,
  refresh,
}) => {
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [getValues, setGetValues] = useState(() => () => {})
  const [mixpanelCall, setMixpanelCall] = useState(false)
  const { mixpanel } = helpers
  const submittedRef = useRef(mixpanelCall)
  submittedRef.current = mixpanelCall

  const mixpanelTrack = () => {
    mixpanel.track(
      'Fornecedores',
      companySupplier.status === constants.companySuppliers.INACTIVE_STATUS
        ? 'Reativar vínculo'
        : 'Reenviar solicitação',
      {
        action: !submittedRef.current
          ? 'clique no botão cancelar'
          : 'solicitação reenviada',
        name: getValues('name'),
        document: getValues('document'),
        email: getValues('email'),
      },
    )
  }

  const handleResendSolicitation = async (supplier) => {
    setLoading(true)
    try {
      if (supplier?.document) {
        if (supplier.document.length > 14) {
          supplier.cnpj = supplier.document
        } else {
          supplier.cpf = supplier.document
        }
        delete supplier.document
      }

      await service.dponet.suppliers.resendSolicitation({
        supplierId: companySupplier.id,
        companySupplier: {
          supplier,
        },
      })

      snackbar.open({
        message: 'Solicitação reenviada com sucesso.',
        variant: 'success',
      })
      refresh()
      setLoading(false)
      setOpen(false)
      setMixpanelCall(true)
      mixpanelTrack()
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro ao reenviar a solicitação de vínculo.',
        variant: 'error',
      })
    }
  }

  if (companySupplier?.editionPermitted) {
    return (
      <BaseDialog
        loading={loading}
        open={open}
        title="Confirme os dados do fornecedor antes de reenviar a solicitação"
        closeButtonText="Cancelar"
        actionButtonText="Reenviar"
        setOpen={setOpen}
        dialogSize="lg"
        formRef="form-supplier"
        mixpanelTrack={mixpanelTrack}
        companySupplier={companySupplier}
      >
        <Box py={1}>
          <SupplierForm
            companySupplier={companySupplier}
            onSubmit={handleResendSolicitation}
            setGetValues={setGetValues}
          />
        </Box>
      </BaseDialog>
    )
  }

  return (
    <BaseConfirmationDialog
      open={open}
      setOpen={setOpen}
      title="Reenviar solicitação"
      text="Deseja confirmar o reenvio de solicitação de vínculo com o fornecedor?"
      actionButtonText="Confirmar"
      variant="warning"
      actionButton={() => handleResendSolicitation({})}
      dialogSize="sm"
      loading={loading}
      actionSupplier={true}
    />
  )
}

ResendSolicitationDialog.propTypes = {
  companySupplier: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
}

export default ResendSolicitationDialog
