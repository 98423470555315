import { colors } from '@material-ui/core'

import constants from 'constants/index'

const chipStatusLabel = (status) => {
  return (
    (status && constants.settings.FINANCIAL_CHIP_STATUS_LABEL[status]) || 'N/A'
  )
}

const chipStatusColor = (status) => {
  return (
    (status && constants.settings.FINANCIAL_CHIP_STATUS_COLOR[status]) ||
    colors.blueGrey[800]
  )
}

const chipStatusBgColor = (status) => {
  return (
    (status &&
      constants.settings.FINANCIAL_CHIP_STATUS_BACKGROUND_COLOR[status]) ||
    colors.blueGrey[100]
  )
}

export default {
  chipStatusLabel,
  chipStatusColor,
  chipStatusBgColor,
}
