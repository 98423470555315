import React, { useRef, useState, useCallback, useEffect } from 'react'

import { getBaseURL } from 'service/env'
import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'

import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
  MenuItem,
  Select,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Download as DownloadIcon } from 'react-feather'
import { PocketImage } from 'components'
import { toPng } from 'html-to-image'

import helpers from 'helpers'

const PocketCard = () => {
  const [qrcode, setQrcode] = useState(null)
  const [qualityImage, setQualityImage] = useState(1)
  const snackbar = useSnackbar()
  const auth = useAuth()

  const findQrcode = async () => {
    const companyQrCode = await service.dponet.companies.consultQrCode({
      companyId: auth?.company?.id,
    })
    const qrcode = `${getBaseURL('dponet')}/${companyQrCode?.data?.object}`
    setQrcode(qrcode)
  }

  useEffect(() => {
    if (qrcode === null) {
      findQrcode()
    }
    // eslint-disable-next-line
  }, [qrcode])

  const sizeCanvasDownload = helpers.formatters.dynamicImageSize([
    1011,
    638,
    qualityImage,
  ])

  const ref = useRef(null)

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return
    }

    toPng(ref.current, {
      cacheBust: true,
      canvasHeight: sizeCanvasDownload[1],
      canvasWidth: sizeCanvasDownload[0],
    })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = `imagem-cartao-visita-${sizeCanvasDownload[2]}.png`
        link.href = dataUrl
        link.click()
      })
      .catch(() => {
        snackbar.open({
          message: 'Não foi possível gerar a imagem do cartão.',
          variant: 'error',
        })
      })
    //eslint-disable-next-line
  }, [ref, qualityImage])

  return (
    <Card>
      <Box mt={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box px={2} mb={2}>
              <Typography variant="h6" color="textPrimary">
                Cartão
              </Typography>
            </Box>
            <Divider />
          </Grid>
          <Grid>
            <Box px={2}>
              {qrcode ? (
                <PocketImage
                  reference={ref}
                  qrcode={qrcode}
                  company={auth?.company}
                />
              ) : (
                <Skeleton variant="rectangular" width="50%" height={400} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Divider />
            </Box>
            <Box px={2} my={2} display="flex" justifyContent="space-between">
              <Select
                value={qualityImage}
                onChange={(e) => setQualityImage(e.target.value)}
              >
                <MenuItem value={1}>Tamanho original</MenuItem>
                <MenuItem value={2}>Tamanho reduzido</MenuItem>
                <MenuItem value={3}>Tamanho grande</MenuItem>
              </Select>

              <Box>
                <Button
                  disabled={!qrcode}
                  color="primary"
                  variant="contained"
                  onClick={onButtonClick}
                  startIcon={<DownloadIcon width="18" />}
                  size="large"
                >
                  DOWNLOAD
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default PocketCard
