import React from 'react'
import PropTypes from 'prop-types'
import { Box, Paper, Typography, IconButton, Hidden } from '@material-ui/core'
import { X as CloseIcon } from 'react-feather'

const AdvisorCard = ({ imgSrc, title, subtitle, handleClose, document }) => {
  return (
    <Paper
      component={Box}
      elevation={2}
      mb={4}
      px={2}
      py={1}
      display="flex"
      justifyContent="space-between"
    >
      <Box>
        <Box py={1} display="flex" alignItems="center">
          <Hidden smDown>
            <Box m={1} ml={7}>
              <img src={imgSrc} alt="Logo" width={100} />
            </Box>
          </Hidden>
          <Box ml={5} maxWidth={800}>
            <Typography variant="h4" component="h2">
              {title}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </Box>
      {!document && (
        <Box ml={2}>
          <IconButton onClick={handleClose}>
            <CloseIcon size={20} />
          </IconButton>
        </Box>
      )}
    </Paper>
  )
}

AdvisorCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default AdvisorCard
