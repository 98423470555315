import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    height: 'fit-content',
    paddingBottom: theme.spacing(2),
  },
  cardHeader: {
    background: theme.palette.primary.main,
    color: theme.palette.white,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '90%',
    backgroundSize: 70,
    [theme.breakpoints.down('md')]: {
      height: 'fit-content',
    },
  },
  cardTitle: {
    padding: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      padding: theme.spacing(2),
      fontWeight: 700,
    },
  },
  lineTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,
  },
  lineBar: {
    height: 7,
    borderRadius: 5,
    backgroundColor: theme.palette.subscription.primary.light,
  },
  cardContent: {
    minHeight: 100,
  },
  rowName: {
    fontWeight: 500,
  },
  rowValue: {
    fontWeight: 500,
  },
}))

export default styles
