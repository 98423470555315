import React from 'react'
import ReactDOM from 'react-dom'
import './i18n'
import App from './App'
import * as Sentry from '@sentry/react'

import { getSentryDsn, getBaseURL } from 'service/env'

const isProd = process.env.REACT_APP_ENV === 'production'

if (isProd) {
  Sentry.init({
    dsn: getSentryDsn(),
    release: `${process.env.REACT_APP_PROJECT_NAME}@${process.env.REACT_APP_GIT_VERSION}`,
    environment: process.env.REACT_APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [getBaseURL('website'), getBaseURL('dponet')],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: isProd ? 1.0 : 0,
    replaysSessionSampleRate: isProd ? 0.1 : 0,
    replaysOnErrorSampleRate: isProd ? 1.0 : 0,
  })
}

ReactDOM.render(<App />, document.getElementById('root'))
