/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { Download as DownloadIcon } from 'react-feather'

import { Card, LoadingFeedback } from 'components'

import useIncidentManegement from 'hooks/useIncidentManegement'
import useCustomTheme from 'hooks/useCustomTheme'
import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import completeIncidentReportPdf from 'helpers/reports/completeIncident/completeIncidentReportPdf'
import incidentReportPdf from 'helpers/reports/simplifiedIncident/incidentReportPdf'

import * as service from 'service'
import formatters from 'helpers/formatters'

const CommunicationStep = () => {
  const [loading, setLoading] = useState(false)

  const { wantsToAdvanceStep, handleNext, resetAdvanceStep, incident } =
    useIncidentManegement()
  const { customTheme, customLogo } = useCustomTheme()
  const { userCompany, company } = useAuth()
  const snackbar = useSnackbar()

  const customStyles = customTheme?.palette?.layout

  const handleGenerateIncidentReport = async (report) => {
    try {
      await report(
        company,
        userCompany,
        { incident: incident },
        customStyles,
        customLogo,
      )
    } catch (error) {
      snackbar.open({
        message: 'Ocorreu um erro ao gerar o relatório',
        variant: 'error',
      })
    }
  }

  const handleOpenNotificationDocument = async () => {
    window.open(incident?.notificationOpinionAttachment?.url, '_blank')
  }

  const onSubmit = async () => {
    setLoading(true)

    try {
      await service.dponet.incidents.concludeStep({
        incidentId: incident.id,
        step: 'communication',
      })

      resetAdvanceStep()
      handleNext()
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      resetAdvanceStep()
    }
  }

  useEffect(() => {
    if (wantsToAdvanceStep) {
      onSubmit()
    }
  }, [wantsToAdvanceStep])

  return (
    <Grid container spacing={1}>
      <LoadingFeedback open={loading} />

      <Grid item xs={12}>
        <Card title="Relatório Simplificado">
          <Typography variant="body1" gutterBottom>
            É necessário fazer o download do relatório completo para que possa
            anexar à comunicação dos titulares de dados e a ANPD.
          </Typography>

          <Button
            startIcon={<DownloadIcon size={16} />}
            variant="outlined"
            onClick={() => handleGenerateIncidentReport(incidentReportPdf)}
          >
            Relatório Simplificado
          </Button>
        </Card>
      </Grid>
      {!incident?.simplifiedForm && (
        <Grid item xs={12}>
          <Card title="Relatório Completo">
            <Typography variant="body1" gutterBottom>
              É necessário fazer o download do relatório completo para que possa
              anexar à comunicação dos titulares de dados e a ANPD
            </Typography>

            <Button
              startIcon={<DownloadIcon size={16} />}
              variant="outlined"
              onClick={() =>
                handleGenerateIncidentReport(completeIncidentReportPdf)
              }
            >
              Relatório Completo
            </Button>
          </Card>
        </Grid>
      )}
      {incident?.notificationOpinionAttachment?.url && (
        <Grid item xs={12}>
          <Card title="Documento para comunicação de titulares de dados">
            <Typography variant="body1" gutterBottom>
              É necessário fazer o download do documento para que possa enviar
              aos titulares de dados.
            </Typography>

            <Button
              startIcon={<DownloadIcon size={16} />}
              variant="outlined"
              onClick={handleOpenNotificationDocument}
            >
              Documento
            </Button>
          </Card>
        </Grid>
      )}
    </Grid>
  )
}

export default CommunicationStep
