import { routes } from 'Routes'

import constants from 'constants/index'

const mount = (data, files = [], pathname) => {
  data.title = data.title === 'another' ? 'Outra' : data.title
  data.ticket_type = ticketType(pathname)
  data.attachments = files
  data.visibility_type = data.visibilityType

  let formData = new FormData()
  Object.keys(data).forEach((key) => {
    if (key === 'attachments') {
      for (const value of data[key]) {
        formData.append(`ticket[attachments][]`, value)
      }
    } else {
      formData.append(`ticket[${key}]`, data[key])
    }
  })

  return formData
}

const ticketType = (pathname) => {
  if (pathname === routes.technicalFaqs.informationSecurityTicket)
    return constants.tickets.TICKET_TYPE.SECURITY_TICKET_TYPE

  return constants.tickets.TICKET_TYPE.JURIDIC_TICKET_TYPE
}

export default mount
