import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Box, TextField } from '@material-ui/core'
import { Text } from '../'
import { LoadingFeedback } from 'components'

import schema from './schema'

import useAuth from 'hooks/useAuth'
import useTrailStep from 'hooks/useTrailStep'

import * as service from 'service'

import useStyles from './styles'

const WebsiteForm = () => {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const { setNextDisabled, handleNext } = useTrailStep()

  const {
    userCompany: { company },
    loadCompany,
  } = useAuth()

  const { handleSubmit, control, errors, watch } = useForm({
    mode: 'onChange',
    validationSchema: schema,
    validateCriteriaMode: 'all',
    defaultValues: {
      url: company.siteUrl || 'https://',
    },
  })

  const onSubmit = async (data) => {
    if (data?.url !== company.siteUrl) {
      setLoading(true)
      await service.dponet.companies.updateSelf({
        company: { siteUrl: data?.url },
      })
      await loadCompany()
      setLoading(false)
    }
    handleNext()
  }

  const urlField = watch('url')
  useEffect(
    () => {
      setNextDisabled(!urlField || !!errors?.url)
    },
    //eslint-disable-next-line
    [urlField, errors?.url],
  )

  return (
    <>
      <LoadingFeedback open={loading} />
      <Box>
        <Text color="primary" fontSize="7vh" variant="h1">
          Informe a URL do seu site
        </Text>
        <form id="site-form" onSubmit={handleSubmit(onSubmit)}>
          <Box width="100%" my={4}>
            <Controller
              as={
                <TextField
                  fullWidth
                  variant="outlined"
                  error={!!errors?.url}
                  helperText={errors?.url?.message}
                  className={classes.urlInput}
                />
              }
              control={control}
              mode="onChange"
              name="url"
            />
          </Box>
        </form>
      </Box>
    </>
  )
}

export default WebsiteForm
