import status from './status'
import statusColor from './statusColor'
import textStatusColor from './textStatusColor'
import fragilityColor from './fragilityColor'
import textFragilityColor from './textFragilityColor'
import possibleMinor from './possibleMinor'
import fragilityLabel from './fragilityLabel'
import formatLegalFrameworksFilterOptions from './formatLegalFrameworksFilterOptions'
import mountTitle from './mountTitle'
import selectVolumetryOptions from './selectVolumetryOptions'
import statusesCheck from './statusesCheck'
import selectSourceOptions from './selectSourceOptions'
import convertSourceKind from './convertSourceKind'
import labelSource from './labelSource'
import mountDataProcessSources from './mountDataProcessSources'
import messagesCopyDepartments from './messagesCopyDepartments'
import convertSourceDescription from './convertSourceDescription'
import iconDataProcessSources from './iconDataProcessSources'
import labelDataProcessSourcesTable from './labelDataProcessSourcesTable'
import getSourceFilterLabel from './getSourceFilterLabel'
import covertDataSourcesToOptions from './covertDataSourcesToOptions'
import convertSourceDescriptionToText from './convertSourceDescriptionToText'
import convertSourceDescriptionToSource from './convertSourceDescriptionToSource'
import formatByNameInFilter from './formatByNameInFilter'
import storageModeType from './storageModeType'
import numberGrid from './numberGrid'
import retentionFinality from './retentionFinality'
import discardMode from './discardMode'
import storageTimeValidation from './storageTimeValidation'
import allowDocumentUpload from './allowDocumentUpload'
import driverJs from './driverJs'

export default {
  allowDocumentUpload,
  status,
  statusColor,
  textStatusColor,
  fragilityColor,
  fragilityLabel,
  textFragilityColor,
  possibleMinor,
  formatLegalFrameworksFilterOptions,
  mountTitle,
  selectVolumetryOptions,
  statusesCheck,
  selectSourceOptions,
  convertSourceKind,
  labelSource,
  mountDataProcessSources,
  messagesCopyDepartments,
  convertSourceDescription,
  iconDataProcessSources,
  labelDataProcessSourcesTable,
  getSourceFilterLabel,
  covertDataSourcesToOptions,
  convertSourceDescriptionToText,
  convertSourceDescriptionToSource,
  formatByNameInFilter,
  storageModeType,
  numberGrid,
  retentionFinality,
  discardMode,
  storageTimeValidation,
  driverJs,
}
