import React, { useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import * as Sentry from '@sentry/react'

import { Container, Page, RoundedBorder } from 'components'
import { Header } from './components'

import exportRMCsReport from 'helpers/reports/rmcs/exportReportRmc'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import useCustomTheme from 'hooks/useCustomTheme'

import * as service from 'service'

import useStyles from './styles'

const RmcReport = () => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { company, userCompany } = useAuth()
  const { customTheme, customLogo, customPalette } = useCustomTheme()

  const [isLoadingDashboardStats, setIsLoadingDashboardStats] = useState(false)

  const customStyles = customTheme?.palette?.layout
  const customThemeStatus = customPalette?.status

  const generateRmcReport = async () => {
    try {
      setIsLoadingDashboardStats(true)
      const [
        { data: gapStats },
        { data: processStats },
        { data: generalStats },
      ] = await Promise.all([
        service.dponet.myLgpd.getGapStats(),
        service.dponet.myLgpd.getProcessStats(),
        service.dponet.myLgpd.getGeneralStats(),
      ])

      await exportRMCsReport(
        company,
        userCompany,
        gapStats.stats,
        processStats.stats,
        generalStats.stats,
        customStyles,
        customLogo,
        customThemeStatus,
      )
    } catch (error) {
      console.error(error)
      snackbar.open({
        message:
          error?.response?.data?.message || 'Erro ao gerar o Relatório de RMCs',
        variant: 'error',
      })
      Sentry.captureException(error)
    } finally {
      setIsLoadingDashboardStats(false)
    }
  }

  return (
    <Page className={classes.root} title={'Relatório de RMCs'}>
      <Box position="relative">
        <RoundedBorder />
      </Box>
      <Container maxWidth={false} spacingXl={15} spacingLg={10} spacingMd={5}>
        <Box py={5}>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Header
                type="rmc"
                buttonAction={generateRmcReport}
                isLoading={isLoadingDashboardStats}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default RmcReport
