import React from 'react'

import { Box, makeStyles, useMediaQuery } from '@material-ui/core'

import { ScreenLoading } from 'layouts/Main/components'

import { TopBar, Footer } from './components'

import useAuth from 'hooks/useAuth'
import theme from 'theme'

import styles from './styles'
const useStyles = makeStyles(styles)

const Portal = ({ children, reduceMargin }) => {
  const classes = useStyles()
  const auth = useAuth()

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <ScreenLoading loaded={auth.loaded}>
      <Box>
        <div className={classes.root}>
          <TopBar />
        </div>
      </Box>
      <main className={classes.wrapper}>
        <Box mt={5} minHeight={isDesktop && !reduceMargin ? '100vh' : 'auto'}>
          {children}
        </Box>
      </main>
      {isDesktop && !reduceMargin && <Footer />}
    </ScreenLoading>
  )
}

export default Portal
