import api from 'service/api'

const dponetAPI = api.create('dponet')

const create = async ({ nonComplianceReportId, ...data }) => {
  return await dponetAPI.post('/residual_risks', {
    ...data,
    nonComplianceReportId,
  })
}

export default { create }
