import constants from 'constants/index'

const convertSourceDescription = (description, sources) => {
  if (sources[0]) {
    switch (sources[0].type) {
      case constants.dataProcess.SOURCE_DEPARTMENT_KIND:
        return constants.dataProcess.DEPARTMENT_SOURCE
      case constants.dataProcess.SOURCE_THIRD_PARTY_KIND:
        return constants.dataProcess.THIRD_PARTY_SOURCE
      default:
        return constants.dataProcess.PUBLIC_SOURCE
    }
  }

  if (description === constants.dataProcess.TITULAR_DESCRIPTION) {
    return constants.dataProcess.TITULAR_DATA_SOURCE
  }

  if (description === constants.dataProcess.DEPARTMENT_DESCRIPTION) {
    return constants.dataProcess.DEPARTMENT_SOURCE
  }

  return constants.dataProcess.ANOTHER_SOURCE
}

export default convertSourceDescription
