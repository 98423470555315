import helpers from 'helpers'
import constants from 'constants/index'

const convertApiData = (data) => {
  var kindData = {
    fantasyName: data?.fantasyName || data?.companyName || '',
    cnpj: helpers.formatters.cnpj(data?.document || data?.cnpj) || '',
  }

  if (data?.kind === constants.preRegistrations.PERSONAL_KIND) {
    kindData = {
      description: data?.description || '',
      cpf: helpers.formatters.cpf(data?.document || data?.cpf) || '',
    }
  }

  if (data?.kind === constants.preRegistrations.SUPPLIER_KIND && !data?.cnpj) {
    kindData = {
      description: data?.description || '',
      cpf: helpers.formatters.cpf(data?.document || data?.cpf) || '',
    }
  }

  return {
    ...kindData,
    name: data?.name || '',
    street: data?.address?.street || '',
    number: data?.address?.number || '',
    neighborhood: data?.address?.neighborhood || '',
    complement: data?.address?.complement || '',
    postalCode: data?.address?.postalCode || '',
    city: data?.address?.city || '',
    country: data?.address?.country || 'Brasil',
    state: data?.address?.state || '',
    email: data?.email || '',
    responsibleName: data?.responsibleUser?.name || '',
    responsibleEmail: data?.responsibleUser?.email || '',
    responsiblePhone: data?.responsibleUser?.phone || '',
    coupon: data?.coupon?.code || '',
    phone: data?.phone ? helpers.formatters.phone(data?.phone, true) : '',
  }
}

export default convertApiData
