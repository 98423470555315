const ADVISOR_CARD_DOCUMENT = 'advisor-company-document'
const ADVISOR_CARD_CLOSED = 'closed'

const DOCUMENTS_MAIN_DRIVER_STEP_1 = 'documents-main-step-1'
const DOCUMENTS_MAIN_DRIVER_STEP_2 = 'documents-main-step-2'

const DOCUMENTS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DOCUMENTS_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Essa funcionalidade tem como objetivo centralizar todos os documentos importantes da sua organização, proporcionando uma gestão mais eficiente e acessível da informação.',
    },
  },
  {
    element: '#' + DOCUMENTS_MAIN_DRIVER_STEP_2,
    popover: {
      description:
        "Ao clicar neste botão, um formulário para ser preenchido será exibido. Selecione o link do arquivo que deseja cadastrar. Oferecemos opções para documentos públicos e privados. Ao escolher 'Documento privado', não se esqueça de verificar as permissões dos usuários que podem visualizá-lo. É recomendável não conceder acesso a todos os usuários.",
    },
  },
]

const DOCUMENTS_MODEL_MAIN_DRIVER_STEP_1 = 'documents-model-main-driver-step-1'
const DOCUMENTS_MODEL_MAIN_DRIVER_STEP_2 = 'documents-model-main-driver-step-2'
const DOCUMENTS_MODEL_MAIN_DRIVER_STEP_3 = 'documents-model-main-driver-step-3'

const DOCUMENTS_MODEL_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DOCUMENTS_MODEL_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'A plataforma apresenta diversos modelos, simplificando sua jornada de trabalho ao procurar por documentos cruciais para sua empresa, especialmente na conformidade com a LGPD (Lei Geral de Proteção de Dados).',
    },
  },
  {
    element: '#' + DOCUMENTS_MODEL_MAIN_DRIVER_STEP_2,
    popover: {
      description:
        'Procurando por um documento específico? Utilize a barra de pesquisa.',
    },
  },
  {
    element: '#' + DOCUMENTS_MODEL_MAIN_DRIVER_STEP_3,
    popover: {
      description:
        'Ao abrir o documento, você poderá visualizá-lo e também ter a opção de fazer uma cópia ou realizar o download do mesmo.',
    },
  },
]

const documents = {
  ADVISOR_CARD_DOCUMENT,
  ADVISOR_CARD_CLOSED,
  DOCUMENTS_DRIVER_STEPS_OBJECT,
  DOCUMENTS_MAIN_DRIVER_STEP_1,
  DOCUMENTS_MAIN_DRIVER_STEP_2,
  DOCUMENTS_MODEL_DRIVER_STEPS_OBJECT,
  DOCUMENTS_MODEL_MAIN_DRIVER_STEP_1,
  DOCUMENTS_MODEL_MAIN_DRIVER_STEP_2,
  DOCUMENTS_MODEL_MAIN_DRIVER_STEP_3,
}

export default documents
