import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  InputLabel,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import { AvaliationAndTerms } from '../../components'

import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'

import helpers from 'helpers'
import * as service from 'service'

import schemaSupplier from './schema'
import useStyles from './styles'

const SupplierForm = ({ setRenderedContent }) => {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)
  const snackbar = useSnackbar()
  const {
    userCompany: { company },
  } = useAuth()
  const { mixpanel } = helpers

  const { control, handleSubmit, errors, setValue } = useForm({
    validationSchema: schemaSupplier,
  })

  useEffect(() => {
    setValue('name', company?.name)
    setValue('email', company?.email)
    setValue('document', documentFormater(company?.document))
    company?.phone &&
      setValue(
        'telephone',
        helpers.formatters.phoneWithoutCountryCode(company?.phone),
      )
    // eslint-disable-next-line
  }, [company])

  const onSubmit = async (supplier) => {
    setSubmitting(true)
    const documentSend = helpers.companySuppliers.documentSend(
      company.international,
      supplier.document,
    )

    mixpanel.track('Jornada de Fornecedores', 'Confirme seus dados', {
      action: 'Avançar-Confirme_seus_dados',
    })
    try {
      await service.dponet.companies.put({
        ...documentSend,
        companyId: company.id,
        name: supplier.name,
        email: supplier.email,
        document: supplier.document,
        phone: supplier.telephone,
      })
      setSubmitting(false)
      snackbar.open({
        message: 'Dados alterados com sucesso',
        variant: 'success',
      })
      setRenderedContent(
        <AvaliationAndTerms setRenderedContent={setRenderedContent} />,
      )
    } catch (error) {
      setSubmitting(false)

      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro ao confirmar o seu vínculo com o cliente',
        variant: 'error',
      })
    }
  }

  const documentFormater = (document) => {
    if (company.international) {
      return document
    }

    const clearedDocument = helpers.formatters.onlyNumbers(document)

    if (clearedDocument.length > 11) {
      return helpers.formatters.cnpj(clearedDocument)
    }

    return helpers.formatters.cpf(clearedDocument)
  }

  return (
    <Box mt={15} width="47.5%" padding="0">
      <Box>
        <Typography className={classes.confirmDataHeading}>
          Confirme seus dados
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formTag}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box mt={4} mb={1}>
              <InputLabel className={classes.label}>Razão Social</InputLabel>
            </Box>
            <Controller
              as={
                <TextField
                  className={classes.input}
                  type="text"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  fullWidth
                />
              }
              name="name"
              mode="onChange"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={1}>
              <Box mb={1}>
                <InputLabel className={classes.label}>E-mail</InputLabel>
              </Box>
              <Controller
                as={
                  <TextField
                    className={classes.input}
                    type="text"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    fullWidth
                  />
                }
                name="email"
                mode="onChange"
                control={control}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={1}>
              <Box mb={1}>
                <InputLabel className={classes.label}>Documento</InputLabel>
              </Box>
              <Controller
                as={
                  <TextField
                    className={classes.input}
                    type="text"
                    error={!!errors.document}
                    helperText={errors?.document?.message}
                    fullWidth
                  />
                }
                name="document"
                onChange={([e]) => {
                  return documentFormater(e.target.value)
                }}
                mode="onChange"
                control={control}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={1}>
              <Box mb={1}>
                <InputLabel className={classes.label}>Telefone</InputLabel>
              </Box>
              <Controller
                as={
                  <TextField
                    className={classes.input}
                    type="text"
                    error={!!errors.telephone}
                    helperText={errors?.telephone?.message}
                    fullWidth
                  />
                }
                name="telephone"
                onChange={([e]) => {
                  return helpers.formatters.phoneWithoutCountryCode(
                    e.target.value,
                  )
                }}
                mode="onChange"
                control={control}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mt={4} mb={2}>
          <Button
            className={classes.roundedButton}
            type="submit"
            disabled={submitting}
            fullWidth
          >
            Avançar
          </Button>
        </Box>
      </form>
    </Box>
  )
}

SupplierForm.propTypes = {
  supplierCompany: PropTypes.object,
  supplierId: PropTypes.number,
  clientName: PropTypes.string,
  setLoading: PropTypes.func,
}

export default SupplierForm
