import * as yup from 'yup'

export const schema = yup.object().shape({
  incidentDate: yup
    .date()
    .required('Preencha a data do incidente')
    .typeError('Preencha a data do incidente'),
  incidentHour: yup
    .date()
    .required('Preencha a hora do incidente')
    .typeError('Preencha a hora do incidente'),
  affectedHolders: yup
    .number()
    .required()
    .typeError('Preencha esse campo corretamente'),
  description: yup.string().required(),
  descriptionMeasures: yup.string(),
  descriptionRootIncident: yup.string(),
  technicalAdministrativeMeasure: yup.string(),
  nonSensitiveViolationDescription: yup.string(),
  descriptionOtherTechnicalAdministrativeSecurityMeasure: yup.string(),
})

export default schema
