const getOriginName = (nonComplianceReport) => {
  if (nonComplianceReport?.entityType === 'DataProcess') {
    if (nonComplianceReport?.entity?.name) {
      return {
        name: nonComplianceReport?.entity?.name,
        id: nonComplianceReport?.entity?.id,
      }
    }
    return {
      name: nonComplianceReport?.entity?.dataProcess?.name,
      id: nonComplianceReport?.entity?.dataProcess?.id,
    }
  }

  if (nonComplianceReport?.entityType === 'Question') {
    return { name: nonComplianceReport?.questionTitle }
  }

  if (nonComplianceReport?.entityType === 'Incident') {
    if (nonComplianceReport?.entity?.description) {
      return { name: nonComplianceReport.entity.description }
    }
    return { name: nonComplianceReport?.entity?.incident?.description }
  }
  return ''
}

export default getOriginName
