import React from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { PlusCircle as PlusCircleIcon } from 'react-feather'

import helpers from 'helpers'
import constants from 'constants/index'

const FaqsDocumentationTab = ({
  loading,
  searchInput,
  valueInput,
  faqsDocumentations,
  filteredResults,
  classes,
  seeMoreButton,
}) => {
  if (!loading && searchInput.length <= 0 && valueInput === '') {
    return faqsDocumentations?.map((item) => (
      <Grid item xs={12} key={item?.id}>
        <Card key={item?.id} className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Box className={classes.boxTitle}>
              <Box className={classes.boxTitleTwo}>
                <Box>
                  <Chip
                    className={
                      item?.kind ===
                      constants.faqsDocumentations.KINDS.DOCUMENTATION
                        ? classes.faq
                        : classes.documentation
                    }
                    label={item?.categoryName}
                  />
                  <Typography className={classes.updatedAt}>
                    {helpers.formatters.date(item?.updatedAt)}
                  </Typography>
                </Box>
                <Box>
                  <Tooltip title={item?.title} placement="top">
                    <Typography className={classes.title} variant="h4">
                      {item?.title}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>{' '}
              <Button
                className={classes.buttonSeeMore}
                onClick={() => seeMoreButton(item?.id)}
              >
                <PlusCircleIcon />
                <Typography className={classes.seeMoreText}>
                  VER MAIS
                </Typography>
              </Button>
            </Box>
            <Typography className={classes.descriptionText} variant="subtitle1">
              {item?.subtitle}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))
  }

  return filteredResults?.map((item) => (
    <Grid item xs={12} key={item?.id}>
      <Card key={item?.id} className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Box className={classes.boxTitle}>
            <Box className={classes.boxTitleTwo}>
              <Box>
                <Chip
                  className={
                    item?.kind ===
                    constants.faqsDocumentations.KINDS.DOCUMENTATION
                      ? classes.faq
                      : classes.documentation
                  }
                  label={item?.categoryName}
                />
                <Typography className={classes.updatedAt}>
                  {helpers.formatters.date(item?.updatedAt)}
                </Typography>
              </Box>
              <Box>
                <Tooltip title={item?.title} placement="top">
                  <Typography className={classes.title} variant="h4">
                    {item?.title}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>{' '}
            <Button
              className={classes.buttonSeeMore}
              onClick={() => seeMoreButton(item?.id)}
            >
              <PlusCircleIcon />
              <Typography className={classes.seeMoreText}>VER MAIS</Typography>
            </Button>
          </Box>
          <Typography className={classes.descriptionText} variant="subtitle1">
            {item?.subtitle}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ))
}

export default FaqsDocumentationTab
