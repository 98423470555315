import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import { LoadingButton } from 'components'

const ReopenDialog = ({
  setChangeDialog,
  changeDialog,
  handleConfirmation,
  loading,
}) => {
  const handleAction = (action) => {
    if (action === 'agree') handleConfirmation(changeDialog?.questionControlId)

    setChangeDialog({ open: false, questionControlId: null })
  }

  return (
    <Dialog open={changeDialog?.open} onClose={() => handleAction('close')}>
      <DialogTitle>Reabrir a questão</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tem certeza que deseja reabrir a edição da medida de segurança?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleAction('close')}>
          Voltar
        </Button>
        <LoadingButton
          loading={loading}
          text="Confirmar"
          onClick={() => handleAction('agree')}
          autoFocus
        />
      </DialogActions>
    </Dialog>
  )
}

export default ReopenDialog
