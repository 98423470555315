import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Box, Button, Dialog, Typography, Paper } from '@material-ui/core'

import helpers from 'helpers'
import constants from 'constants/index'

import useStyle from './styles'
import useSupplierInvite from 'hooks/useSupplierInvite'
import { getWebsiteURL } from 'service/env'

const RefusedModal = ({ open, setOpen, inviter }) => {
  const classes = useStyle()
  const { setCurrentStep } = useSupplierInvite()

  const { mixpanel } = helpers

  const handleClose = () => setOpen(false)

  const approvedLink = async () => {
    mixpanel.track(
      'Convite de Fornecedores',
      'Modal: Tem certeza que não reconhece esta empresa?',
      {
        action:
          'Reconhece_a_empresa-Modal_tem_certeza_que_não_reconhece_esta_empresa?',
      },
    )
    setCurrentStep(constants.supplierInvite.VALIDATE_DOCUMENT_STEP)
  }

  const declineLink = async () => {
    mixpanel.track(
      'Convite de Fornecedores',
      'Modal: Tem certeza que não reconhece esta empresa?',
      {
        action:
          'Não_reconhece_a_empresa-Modal_tem_certeza_que_não_reconhece_esta_empresa?',
      },
    )

    return window.open(getWebsiteURL(), '_self')
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="md"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <Box m={4}>
        <Box display="flex" flexDirection="column">
          <Typography className={classes.title}>
            Tem certeza que não reconhece esta empresa?
            <br />
            <span className={classes.description}>{inviter?.name}</span>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Paper
            onClick={approvedLink}
            variant="outlined"
            component={Button}
            className={classes.button}
            fullWidth
          >
            Reconheço esta empresa
          </Paper>
          <Paper
            onClick={declineLink}
            variant="outlined"
            component={Button}
            className={clsx(classes.button, classes.outlined)}
            fullWidth
          >
            Não reconheço esta empresa
          </Paper>
        </Box>
      </Box>
    </Dialog>
  )
}

RefusedModal.propTypes = {
  inviter: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

export default RefusedModal
