import React from 'react'

import { Text } from '../'

import { Box } from '@material-ui/core'
import { GreenHighlighted } from 'components'

const CookieInfo = () => {
  return (
    <>
      <Text color="primary" fontSize="7vh" variant="h1">
        Banner de Cookies
      </Text>
      <Box my={3}>
        <Text fontSize="3.2vh" variant="h4" gutterBottom>
          Um banner de cookies é um aviso exibido nos sites para informar e
          obter o consentimento dos usuários antes de rastrear e armazenar seus
          dados de navegação.
        </Text>
      </Box>

      <Text fontSize="3.2vh" variant="h4" gutterBottom>
        A lei exige que todos os sites possuam esta funcionalidade. Para atender
        essa exigência, a DPOnet conta com um serviço de banner de cookies{' '}
        <GreenHighlighted>gratuito</GreenHighlighted> para seus clientes!
      </Text>
      <Box my={3}>
        <Text variant="subtitle1" gutterBottom>
          *Para 1 site, limitado a 10 mil acessos mensais
        </Text>
      </Box>
    </>
  )
}

export default CookieInfo
