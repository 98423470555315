import React, { useState } from 'react'
import { Box, Grid } from '@material-ui/core'

import { Container, Page, RoundedBorder } from 'components'
import {
  Header,
  ReportVersionExistsDialog,
  ReportVersionTable,
  TrainingPdf,
} from './components'

import useFetch from 'hooks/useFetch'
import useStyles from './styles'

import * as service from 'service'
import constants from 'constants/index'

const TrainingReport = () => {
  const [exportingPdf, setExportingPdf] = useState(false)
  const [refresher, setRefresher] = useState(false)
  const [reportVersionExistsOpen, setReportVersionExistsOpen] = useState(false)
  const [currentReportVersion, setCurrentReportVersion] = useState()

  const classes = useStyles()

  const { response, isLoading } = useFetch(
    service.dponet.trainningJourneys.showUserCertifications,
    {},
    [],
    exportingPdf,
  )

  const users = response?.data?.users

  const buttonAction = () => {
    if (!!currentReportVersion?.id) {
      return setReportVersionExistsOpen(true)
    }
    setExportingPdf(true)
  }

  return (
    <Page className={classes.root} title="Relatório de Treinamentos">
      <Box position="relative">
        <RoundedBorder />
      </Box>
      <Container maxWidth={false} spacingXl={15} spacingLg={10} spacingMd={5}>
        <Box py={5}>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={12}>
              <Header
                type="training"
                isLoading={isLoading}
                buttonAction={buttonAction}
                disabled={exportingPdf}
                reportTour="treinamentos"
              />
            </Grid>

            <Grid item xs={12}>
              <ReportVersionTable
                currentReportVersion={currentReportVersion}
                kind={constants.reportVersions.KIND.TRAINING}
                refresher={refresher}
                setCurrentReportVersion={setCurrentReportVersion}
              />
            </Grid>
          </Grid>
          {users && (
            <TrainingPdf
              exportingPdf={exportingPdf}
              setExportingPdf={setExportingPdf}
              refresh={() => setRefresher(!refresher)}
              shouldCreateVersion={!currentReportVersion?.id}
              users={users}
            />
          )}
          <ReportVersionExistsDialog
            currentReportVersion={currentReportVersion}
            exportAction={() => setExportingPdf(true)}
            open={reportVersionExistsOpen}
            setCurrentReportVersion={setCurrentReportVersion}
            setOpen={setReportVersionExistsOpen}
          />
        </Box>
      </Container>
    </Page>
  )
}

export default TrainingReport
