import React from 'react'

import { Box, Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

import styles from './styles'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const PocketImage = ({ reference, qrcode, company }) => {
  const classes = useStyles()

  const companyNameSize = company?.name.length >= 100
  const currentYear = new Date().getFullYear()

  return (
    <div ref={reference}>
      <Box className={classes.cardBackground}>
        <Box className={classes.cardContainer}>
          <Box className={classes.BoxQrcode}>
            <img className={classes.qrcode} alt="qrcode" src={qrcode} />
          </Box>
          <Box className={classes.boxCompany}>
            <Box className={classes.dateContainer}>
              <Typography className={classes.date}>{currentYear}</Typography>
            </Box>
            <Box className={classes.contextCompany}>
              <Box mb={5}>
                <Typography
                  variant="h4"
                  className={clsx(classes.companyName, {
                    [classes.companyNameLong]: companyNameSize,
                  })}
                >
                  {company?.name.toUpperCase()}
                </Typography>
              </Box>
              <Typography variant="body2">
                {helpers.company.documentType(company)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default PocketImage
