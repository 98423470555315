const styles = (theme) => ({
  root: {},
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  whiteTypography: {
    color: theme.palette.common.white,
  },
})

export default styles
