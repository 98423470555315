import React, { useState } from 'react'
import { Grid, Box } from '@material-ui/core'

import { LoadingFeedback } from 'components'
import {
  // TypeCommunication,  // somente vai ser utilizado quando for criado a atividade de relátorio de incidente
  AvailableRisk,
  Occurrence,
  Operator,
} from './components'

import { useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import * as service from 'service'

const OccurrenceScience = ({ incident, idForm, handleNext, stepName }) => {
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const { operator } = incident?.incidentDataAgents

  const { handleSubmit, control, setValue, reset, getValues, watch } = useForm({
    defaultValues: {
      typeCommunication: incident?.typeCommunication ?? '',
      assessmentTypeRisk: incident?.assessmentTypeRisk ?? '',
      justificationRiskAssessment: incident?.justificationRiskAssessment ?? '',
      decriptionOccurrenceIncidentWasKnown:
        incident?.decriptionOccurrenceIncidentWasKnown ?? '',
      howDidIncidentBecomeKnown:
        incident?.dataOptionIncidents?.howDidIncidentBecomeKnown || [],
      howDidIncidentBecomeKnownOther: helpers.incidents.otherOption(
        incident?.dataOptionIncidents?.howDidIncidentBecomeKnown,
      ),
      name: (operator && operator[0])?.name,
      email: (operator && operator[0])?.email,
      document:
        (operator && helpers.formatters.cnpjOrCpf(operator[0]?.document)) || '',
    },
  })

  const onSubmit = async (data) => {
    try {
      const newData = helpers.incidents.mountStepOccurrence(data)
      setLoading(true)
      await service.dponet.incidents.put({
        incidentId: incident?.id,
        incident: {
          ...helpers.incidents.mountDefault(incident),
          ...newData,
          simplifiedForm: false,
        },
      })
      setLoading(false)
      handleNext()
      snackbar.open({
        message: `${stepName} atualizada com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: `Ocorreu um erro ao salvar a ${stepName}!`,
        variant: 'error',
      })
      setLoading(false)
      reset(getValues())
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <form onSubmit={handleSubmit(onSubmit)} id={idForm}>
        <Box pb={3} mt={3} mb={1}>
          <Grid container spacing={1}>
            {/* só deve ser descomentada quando for desenvolvida o relatorio de incidente */}

            {/* <TypeCommunication
              control={control}
              setValue={setValue}
              checkedValue={incident?.typeCommunication || ''}
            /> */}
            <AvailableRisk
              control={control}
              setValue={setValue}
              checkedValue={incident?.assessmentTypeRisk || ''}
            />
            <Occurrence
              control={control}
              setValue={setValue}
              setLoading={setLoading}
              incident={incident}
              watch={watch}
            />
            <Operator
              control={control}
              setValue={setValue}
              setLoading={setLoading}
              incident={incident}
            />
          </Grid>
        </Box>
      </form>
    </>
  )
}

export default OccurrenceScience
