import React from 'react'

import { Filters } from 'components'

import constants from 'constants/index'

const FinancialFilters = ({ filter }) => {
  return (
    <Filters filter={filter}>
      <input
        textfieldinput="true"
        label="Número do contrato"
        name="contract_identifier"
      />
      <select textfieldinput="true" label="Status" name="status">
        <option value=""></option>
        {Object.entries(constants.preRegistrations.PAYMENT_STATUSES).map(
          ([id, name]) => (
            <option key={id} value={id}>
              {name}
            </option>
          ),
        )}
      </select>
      <select
        textfieldinput="true"
        label="Mês de referência"
        name="order_reference_month"
      >
        <option value=""></option>
        {constants.date.MONTHS_WITH_LABEL.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </select>
    </Filters>
  )
}

export default FinancialFilters
