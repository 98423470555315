import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { isEmpty } from 'lodash'
import { Box, Grid, makeStyles, CircularProgress } from '@material-ui/core'

import TableNecPropDataCollected from '../../TableNecPropDataCollected'
import TableNecPropDataTreatments from '../../TableNecPropDataTreatments'
import TableNecPropDataLifeCycle from '../../TableNecPropDataLifeCycle'
import DescriptionPaper from '../../DescriptionPaper'
import { DialogInformation } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'
import useDataProcess from 'hooks/useDataProcess'

import helpers from 'helpers'

import * as service from 'service'
import styles from '../styles'

const useStyles = makeStyles(styles)

const NecessityProportionality = ({ description }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogMessage, setDialogMessage] = useState('')

  const classes = useStyles()
  const snackbar = useSnackbar()
  const { data, nextStep, toNextStep, setToNextStep } = useDataProcess()
  const formMethods = useForm()
  const { response, isLoading } = useFetch(service.dponet.dataProcesses.get, {
    dataProcessId: data?.dataProcess?.id,
  })

  const { handleSubmit, errors } = formMethods

  const dataProcess = response?.data?.dataProcess

  useEffect(() => {
    if (toNextStep) {
      handleSubmit(onSubmit)()

      if (errors) setToNextStep(false)
    }
    // eslint-disable-next-line
  }, [toNextStep])

  const handleOpenDialog = (message) => {
    setDialogMessage(message)
    setOpenDialog(true)
    return true
  }

  const onSubmit = async (data) => {
    try {
      if (
        helpers.necessityAndProportionality.verifyUnnecessaryScopes(
          data,
          handleOpenDialog,
        )
      )
        return

      if (dataProcess?.dataCollecteds) {
        dataProcess.dataCollecteds.map(async (dataCollected) => {
          let dataCollecteds =
            helpers.dataCollected.mountNecessityAndProportionalityParams(
              data,
              dataCollected,
            )

          if (!isEmpty(dataCollecteds?.collectedFields)) {
            await service.dponet.dataCollecteds.put({
              dataProcessId: dataProcess.id,
              dataCollectedId: dataCollected.id,
              dataCollecteds,
            })
          }
        })
      }

      if (dataProcess?.dataTreatments) {
        dataProcess.dataTreatments.map(async (dataTreat) => {
          let dataTreatment =
            helpers.dataTreatment.mountNecessityAndProportionalityParams(
              data,
              dataTreat.id,
            )

          if (dataTreatment !== {}) {
            await service.dponet.dataTreatments.put({
              dataProcessId: dataProcess.id,
              dataTreatmentId: dataTreat.id,
              dataTreatment,
            })
          }
        })
      }

      if (dataProcess?.lifeCycles) {
        dataProcess.lifeCycles.map(async (dataLife) => {
          let dataLifeCycle =
            helpers.dataLifeCycle.mountNecessityAndProportionalityParams(
              data,
              dataLife.id,
            )

          if (dataLifeCycle !== {}) {
            await service.dponet.dataLifeCycles.put({
              dataProcessId: dataProcess.id,
              dataLifeCycleId: dataLife.id,
              lifeCycle: {
                retentionFinality: dataLife.retentionFinality,
                storageLocationId: dataLife.storageLocation.id,
                discardModeId: dataLife.discardMode.id,
                internalAllocationModeId: dataLife.internalAllocationMode.id,
                protectionId: dataLife.protection.id,
                recoveryId: dataLife.recovery.id,
                value: dataLife.value,
                volumetry: dataLife.volumetry,
                ...dataLifeCycle,
              },
            })
          }
        })
      }

      nextStep()
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message:
          'Ocorreu um erro ao definir a necessidade e proporcionalidade do processo',
      })
    } finally {
      setToNextStep(false)
    }
  }

  return (
    <>
      <DescriptionPaper description={description} />
      <Grid container className={classes.root}>
        {isLoading ? (
          <Box
            id="loadBox"
            display="flex"
            width="100%"
            minHeight="700px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box width="100%">
              <Box width="100%" mb={3}>
                <FormContext className={classes.fullHeight} {...formMethods}>
                  <PerfectScrollbar>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <TableNecPropDataCollected
                        dataCollecteds={dataProcess?.dataCollecteds}
                      />
                    </form>
                  </PerfectScrollbar>
                </FormContext>
              </Box>
              {!isEmpty(dataProcess?.dataTreatments) && (
                <Box width="100%" mb={3}>
                  <FormContext {...formMethods}>
                    <PerfectScrollbar>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <TableNecPropDataTreatments
                          dataTreatments={dataProcess?.dataTreatments}
                        />
                      </form>
                    </PerfectScrollbar>
                  </FormContext>
                </Box>
              )}
              <Box width="100%" mb={3}>
                <FormContext {...formMethods}>
                  <PerfectScrollbar>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <TableNecPropDataLifeCycle
                        dataLifeCycles={dataProcess?.lifeCycles || []}
                      />
                    </form>
                  </PerfectScrollbar>
                </FormContext>
              </Box>
            </Box>
          </>
        )}
      </Grid>

      <DialogInformation
        open={openDialog}
        setOpen={setOpenDialog}
        title="Necessidade e proporcionalidade"
        description={dialogMessage}
      />
    </>
  )
}

export default NecessityProportionality
