import { useContext } from 'react'

import IncidentContext from 'contexts/IncidentContext'

const useIncident = () => {
  const context = useContext(IncidentContext)

  if (context === undefined) {
    throw new Error(
      'useIncident must be used within a IncidentContext.Provider',
    )
  }

  const toStep = (step, activeStep) => {
    if (step < activeStep) {
      context.setActiveStep(step)
    }
  }

  const nextStep = () => {
    context.setActiveStep((prevActiveStep) => prevActiveStep + 1)
    if (context.lastActiveStep === context.activeStep) {
      context.setLastActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const backStep = () => {
    context.setActiveStep((prevActiveStep) => prevActiveStep - 1)
    context.setLastActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return {
    activeStep: context.activeStep,
    lastActiveStep: context.lastActiveStep,
    setLastActiveStep: context.setLastActiveStep,
    setActiveStep: context.setActiveStep,
    toNextStep: context.toNextStep,
    setToNextStep: context.setToNextStep,
    reloadOptions: context.reloadOptions,
    counter: context.counter,
    toStep,
    nextStep,
    backStep,
  }
}

export default useIncident
