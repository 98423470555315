import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  containerBox: {
    margin: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  mainButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  searchButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.hover,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  searchIcon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.hover,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  cardDescription: {
    padding: theme.spacing(3),
    height: '55vh',
    '& p': {
      marginBlock: theme.spacing(3),
    },
    '& ul': {
      marginInline: theme.spacing(4),
    },
  },
  typographyDescription: {
    fontSize: 32,
  },
}))

export default styles
