import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { isEmpty } from 'lodash'

import { Page, Container } from 'components'
import { Stepper } from './components'

import useSubscription from 'hooks/useSubscription'

import * as service from 'service'
import { TOKEN_PRE_REGISTRATION, TOKEN_LOGGED_COMPANY } from 'service/constants'

import helpers from 'helpers'
import constants from 'constants/index'

import useStyles from './styles'

const Subscription = ({ location }) => {
  const classes = useStyles()
  const [greatherStep, setGreatherStep] = useState(
    constants.preRegistrations.FIRST_STEP[0],
  )
  const [loading, setLoading] = useState(true)
  const searchParams = new URLSearchParams(location.search)
  const segment = searchParams.get('segment') || 'Não identificado'
  const token = searchParams.get('token')
  const supplierToken = searchParams.get('supplierToken')
  const segmentQuery = helpers.functions.capitalizeText(segment)

  const {
    setSelectedSegment,
    setPlans,
    selectedSegment,
    setData,
    loadDataBySupplier,
    setIsLoading,
  } = useSubscription()

  useEffect(() => {
    const getDataByToken = async () => {
      if (token) {
        localStorage.setItem(TOKEN_PRE_REGISTRATION, token)
        const response = await service.dponet.preRegistrations.get()
        setData(response?.data?.preRegistration)
      }
    }

    const getPlansBySegment = async () => {
      setLoading(true)
      setSelectedSegment(segment)
      try {
        const response = await service.dponet.plans.get({
          segment: segmentQuery,
          status: true,
          order: 'name DESC',
        })

        if (!isEmpty(response?.data?.plans)) {
          setPlans(response?.data?.plans)
        }
      } catch {}
      setLoading(false)
    }

    getDataByToken()
    getPlansBySegment()
    // eslint-disable-next-line
  }, [supplierToken])

  useEffect(() => {
    const loadSupplierToken = async () => {
      sessionStorage.setItem(TOKEN_LOGGED_COMPANY, supplierToken)
      try {
        await loadDataBySupplier(supplierToken)
      } catch {
        setLoading(false)
        setIsLoading(false)
      }
    }

    if (supplierToken && !!selectedSegment) {
      loadSupplierToken()
    }
    // eslint-disable-next-line
  }, [supplierToken, selectedSegment])

  return (
    <Page className={classes.root} title="Assinatura" showHiring={false}>
      <Box display="flex" justifyContent="center">
        <Container
          maxWidth="xl"
          spacingXl={25}
          spacingLg={15}
          spacingMd={10}
          spacingSm={8}
          spacingXs={5}
        >
          {!loading && (
            <Stepper
              greatherStep={greatherStep}
              setGreatherStep={setGreatherStep}
            />
          )}
        </Container>
      </Box>
    </Page>
  )
}

export default Subscription
