import React, { useState } from 'react'

import { Grid, Button } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import useDocuments from 'hooks/useDocuments'

import helpers from 'helpers'

import * as service from 'service'

import { getBaseURL } from 'service/env'

import { IconButton } from 'components'

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import MenuButton from 'components/MenuButton'

import useSnackbar from 'hooks/useSnackbar'

const TableDocuments = ({ classes, dataProcessId, edit = false }) => {
  const [loading, setLoading] = useState(false)

  const { setData, data: documents, loading: loadingDocuments } = useDocuments()

  const snackbar = useSnackbar()

  const onClickDeleteDocument = async (document, index) => {
    setLoading(true)
    const documentName = helpers.functions.documentName(document.url)
    try {
      await service.dponet.documents.deleteDocument({
        dataProcessesId: dataProcessId,
        documentName: documentName,
      })
      delete documents[index]
      setData(documents)
      setLoading(false)
    } catch {
      snackbar.open({
        message: `Ocorreu algum erro ao deletar ${documentName}!\n`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <Table size="small" emptyMessage="Nenhum documento encontrado" noWrap>
      <TableHead>
        <TableRow>
          <TableCell>Documentos</TableCell>
          <TableCell align="right" className={classes.actionHeader}>
            Ações
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!loadingDocuments &&
          documents &&
          documents.map((document, index) => (
            <TableRow key={index}>
              <TableCell>
                {helpers.functions.documentName(document.url)}
              </TableCell>
              <TableCell align="right">
                <Grid container item justify="flex-end">
                  {edit && (
                    <MenuButton>
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() =>
                          window.open(
                            `${getBaseURL('dponet')}${document.url}`,
                            '_blank',
                          )
                        }
                      >
                        Visualizar
                      </Button>
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() => onClickDeleteDocument(document, index)}
                        disabled={loading}
                      >
                        {loading ? 'Aguarde...' : 'Remover'}
                      </Button>
                    </MenuButton>
                  )}
                  {!edit && (
                    <IconButton
                      size="small"
                      Icon={VisibilityOutlinedIcon}
                      text={'Visualizar'}
                      onClick={() =>
                        window.open(
                          `${getBaseURL('dponet')}${document.url}`,
                          '_blank',
                        )
                      }
                    />
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

export default TableDocuments
