import incidentManegementConstants from 'constants/incidentManegement'

const incidentManegementSteps = (incident, type) => {
  const steps = [
    incidentManegementConstants.DETECTION_ENUM,
    incidentManegementConstants.INVESTIGATION_ENUM,
    incidentManegementConstants.AVALIATION_ENUM,
    incidentManegementConstants.LESSONS_LEARNED_ENUM,
  ]

  if (type === 'edit' && incident?.confirmed) {
    steps.splice(
      3,
      0,
      incidentManegementConstants.DETALING_ENUM,
      incidentManegementConstants.TREATMENT_ENUM,
      incidentManegementConstants.COMMUNICATION_ENUM,
    )
  }

  return steps
}

export default incidentManegementSteps
