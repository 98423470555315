import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
} from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'

import { AddInviteAdvisor, ListInviteAdvisor } from '..'
import { LoadingFeedback } from 'components'
import { DialogUpdateDpoAlert } from './components'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'
import DialogCancelDpoAlert from './components/DialogCancelDpoAlert'

const DialogInviteAdvisor = ({
  open,
  setOpen,
  invite,
  isDpoInvite,
  inviteTypeText,
}) => {
  const labelTab = invite?.id ? 'Alterar' : 'Adicionar'
  const tabDefault = invite?.id
    ? constants.inviteAdvisor.TAB_DISPLAY
    : constants.inviteAdvisor.TAB_NEW

  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [currentTab, setCurrentTab] = useState(tabDefault)
  const [openUpdateConfirmationDialog, setOpenUpdateConfirmationDialog] =
    useState(false)
  const [openCancelConfirmationDialog, setOpenCancelConfirmationDialog] =
    useState(false)
  const [formData, setFormData] = useState({})

  const snackbar = useSnackbar()

  const handleCancel = () => {
    setOpen(false)
  }

  const tabs = [
    {
      value: constants.inviteAdvisor.TAB_NEW,
      label: `${labelTab} ${inviteTypeText}`,
    },
    {
      value: constants.inviteAdvisor.TAB_DISPLAY,
      label: 'Visualizar dados',
    },
  ]

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  const handleUpdateLink = (data) => {
    if (
      isDpoInvite &&
      invite?.status !== constants.outsourcedDpo.CANCELED_STATUS
    ) {
      setOpenUpdateConfirmationDialog(true)
      setFormData(data)
    } else {
      updateLink(data)
    }
  }

  const updateLink = async (data) => {
    setLoading(true)
    setOpenUpdateConfirmationDialog(false)
    try {
      if (isDpoInvite) {
        await service.dponet.outsourcedDpo.create({ data })
      } else {
        await service.dponet.observerInvite.create({ data })
      }

      document.location.reload(true)
      snackbar.open({
        message: `${inviteTypeText} vinculado à sua empresa!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const handleCancelLink = (data) => {
    if (
      isDpoInvite &&
      invite?.status !== constants.outsourcedDpo.CANCELED_STATUS
    ) {
      setOpenCancelConfirmationDialog(true)
    } else {
      cancelLink(data)
    }
  }

  const cancelLink = async () => {
    setLoading(true)
    setOpenCancelConfirmationDialog(false)
    try {
      if (isDpoInvite) {
        await service.dponet.outsourcedDpo.destroy({
          outsourcedId: invite.id,
        })
      } else {
        await service.dponet.observerInvite.destroy({
          observerInviteId: invite.id,
        })
      }

      setOpen(false)
      setOpenCancelConfirmationDialog(false)
      document.location.reload(true)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
    setLoading(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-change"
      maxWidth="md"
      fullWidth
    >
      <LoadingFeedback open={loading} />
      <DialogTitle>
        <Box>
          <Typography variant="h4">{inviteTypeText}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={(e) => handleTabsChange(e, tab.value)}
            />
          ))}
        </Tabs>

        {currentTab === constants.inviteAdvisor.TAB_NEW && (
          <AddInviteAdvisor
            disabled={disabled}
            setDisabled={setDisabled}
            onSubmit={handleUpdateLink}
            invite={invite}
          />
        )}

        {currentTab === constants.inviteAdvisor.TAB_DISPLAY && (
          <ListInviteAdvisor
            setDisabled={setDisabled}
            invite={invite}
            loading={loading}
            inviteTypeText={inviteTypeText}
          />
        )}
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" my={2} mr={2}>
        <Button
          variant="outlined"
          type="button"
          onClick={handleCancel}
          disableElevation
        >
          CANCELAR
        </Button>

        {!disabled && (
          <Box pl={3}>
            <Button
              startIcon={<CheckIcon />}
              variant="contained"
              color="primary"
              disableElevation
              type="submit"
              form="inviteAdvisorForm"
              disabled={loading}
            >
              Confirmar
            </Button>
          </Box>
        )}

        {disabled && invite && (
          <Box pl={3}>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              disabled={loading}
              onClick={handleCancelLink}
            >
              CANCELAR VÍNCULO
            </Button>
          </Box>
        )}
      </Box>

      <DialogUpdateDpoAlert
        open={openUpdateConfirmationDialog}
        handleClose={() => setOpenUpdateConfirmationDialog(false)}
        handleSubmit={() => updateLink(formData)}
      />
      <DialogCancelDpoAlert
        open={openCancelConfirmationDialog}
        handleClose={() => setOpenCancelConfirmationDialog(false)}
        handleSubmit={cancelLink}
      />
    </Dialog>
  )
}

DialogInviteAdvisor.proTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  invite: PropTypes.object,
  isDpoInvite: PropTypes.bool,
  inviteTypeText: PropTypes.string,
}

DialogInviteAdvisor.defaultProps = {
  setOpen: () => {},
  invite: {},
}

export default DialogInviteAdvisor
