import * as yup from 'yup'

import helpers from 'helpers'
import constants from 'constants/index'

const { ALL_FILE_TYPES, ALL_FILE_MIMES_TYPES } = constants.validations

export const schema = yup.object().shape({
  discoveryDescription: yup.string().required(),
  detectionAttachments: yup
    .mixed()
    .nullable()
    .test('fileSize', 'O arquivo deve ter no máximo 5 MB', (value) =>
      helpers.validates.file.size('MB', 5, value),
    )
    .test(
      'fileType',
      `Somente arquivos ${helpers.formatters.file.allowedTypes(
        ALL_FILE_TYPES,
      )} são permitidos`,
      (value) => helpers.validates.file.type(ALL_FILE_MIMES_TYPES, value),
    )
    .test(
      'uniqueFilenames',
      'Não é permitido enviar arquivos com o mesmo nome',
      (value) => helpers.validates.file.uniqueFile(value),
    ),
})

export default schema
