const getColorByVariant = (classes, variant) => {
  switch (variant) {
    case 'success':
      return classes.success
    case 'warning':
      return classes.warning
    case 'error':
      return classes.error
    default:
      return classes.default
  }
}

export default getColorByVariant
