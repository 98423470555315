import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import TrailStepContext from 'contexts/TrailStepContext'

import useAuth from 'hooks/useAuth'

import * as service from 'service'
import helpers from 'helpers'
import {
  CookieConfiguration,
  Conclusion,
  CookieConfirmation,
  CookieInfo,
  DocumentInfo,
  FirstPage,
  LogoUpload,
  PrivacyPolicyForm,
  WebsiteConfirmation,
  WebsiteForm,
} from 'views/UserTrailSteps/components'

import constants from 'constants/index'

const {
  LOGO_FIRST_STEP_INDEX,
  COOKIE_BANNER_TAG,
  HAS_BANNER_OBS,
  LOGO_STEP_INDEX,
  SITE_CONFIRM_STEP_INDEX,
  SITE_FORM_INDEX,
  STEP_CONCLUSION_INDEX,
  STEP_CONCLUSION,
  STEP_CONFIG,
  STEP_COOKIE_CONFIG_INDEX,
  STEP_COOKIE_CONFIRM_INDEX,
  STEP_COOKIE_INFO_INDEX,
  STEP_COOKIE,
  STEP_DOCS_FORM_INDEX,
  STEP_DOCS_INDEX,
  STEP_SITE,
} = constants.userTrailSteps

const TrailStepProvider = ({ children }) => {
  const { companyTrails, loadDataWithAnimation, homeRoute } = useAuth()
  const trailSteps = companyTrails?.filter((trail) => !!trail.tag)
  const bannerStep = trailSteps.find((step) => step.tag === COOKIE_BANNER_TAG)

  const [hasBanner, setHasBanner] = useState(false)
  const [privacyStamp, setPrivacyStamp] = useState({})
  const history = useHistory()

  const [activeStep, setActiveStep] = useState(0)
  const [activeSubStep, setActiveSubStep] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [nextDisabled, setNextDisabled] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState({})

  const isStep = (step_index) =>
    activeStep === step_index[0] && activeSubStep === step_index[1]

  const { arrayIndexSize } = helpers.functions

  const [
    isInitial,
    isLogoStep,
    isSiteConfirmStep,
    isSiteFormStep,
    isCookieInfo,
    isCookieConfirmStep,
    isCookieConfigStep,
    isDocInfoConfigStep,
    isDocFormStep,
    isFinalStep,
  ] = useMemo(
    () => [
      isStep(LOGO_FIRST_STEP_INDEX),
      isStep(LOGO_STEP_INDEX),
      isStep(SITE_CONFIRM_STEP_INDEX),
      isStep(SITE_FORM_INDEX),
      isStep(STEP_COOKIE_INFO_INDEX),
      isStep(STEP_COOKIE_CONFIRM_INDEX),
      isStep(STEP_COOKIE_CONFIG_INDEX),
      isStep(STEP_DOCS_INDEX),
      isStep(STEP_DOCS_FORM_INDEX),
      isStep(STEP_CONCLUSION_INDEX),
    ],
    //eslint-disable-next-line
    [activeStep, activeSubStep],
  )

  const showNextButton =
    !isSiteConfirmStep && !isCookieConfirmStep && !isDocFormStep
  const showJumpButton = isLogoStep
  const showBackButton = isSiteFormStep || isCookieConfigStep
  const hideStepper = isInitial || isCookieInfo || isDocInfoConfigStep
  const showHelpButton =
    isLogoStep || isSiteConfirmStep || isSiteFormStep || isCookieConfirmStep
  const helpObject = helpers.userTrailSteps.mountHelpObject(isStep)

  const pages = [
    [<FirstPage />, <LogoUpload />], // Etapas de logo
    [<WebsiteConfirmation />, <WebsiteForm />], // Etapas do Site
    [<CookieInfo />, <CookieConfirmation />], // Etapas do Cookie
    [<CookieConfiguration />], // Etapa de configuração do cookie
    [<DocumentInfo />, <PrivacyPolicyForm />], // Etapas de documentos
    [<Conclusion />],
  ]

  useEffect(() => {
    setHasBanner(bannerStep?.observations === HAS_BANNER_OBS)
  }, [bannerStep])

  const getActivePage = useCallback(() => {
    return pages[activeStep][activeSubStep]
    //eslint-disable-next-line
  }, [activeStep, activeSubStep])

  const canGoTo = useCallback(
    (step) =>
      !!trailSteps?.find(
        (trailStep) =>
          trailStep?.tag === helpers.userTrailSteps.getTagByIndex(step),
      ),
    [trailSteps],
  )

  const goToStep = (step) => {
    if (canGoTo(step)) {
      setActiveStep(step)
      setActiveSubStep(0)
    } else {
      if (step < arrayIndexSize(pages)) {
        goToStep(step + 1)
      }
    }
  }

  const handleNext = async (observations = null) => {
    if (activeStep < arrayIndexSize(pages)) {
      const activePage = pages[activeStep]
      if (activeSubStep === arrayIndexSize(activePage)) {
        setNextDisabled(true)
        await concludeStep(
          helpers.userTrailSteps.getTrailId(activeStep, trailSteps),
          observations,
        )
        goToStep(activeStep + 1)
        setNextDisabled(false)
      } else {
        setActiveSubStep(activeSubStep + 1)
      }
    } else {
      setNextDisabled(true)
      await concludeStep(
        helpers.userTrailSteps.getTrailId(STEP_CONCLUSION, trailSteps),
        observations,
      )
      setNextDisabled(false)
      history.push(homeRoute)
      loadDataWithAnimation()
    }
  }

  const jumpStep = async () => {
    goToStep(activeStep + 1)
  }

  const jumpToDocuments = async () => {
    setNextDisabled(true)
    await concludeStep(helpers.userTrailSteps.getTrailId(STEP_SITE, trailSteps))
    await concludeStep(
      helpers.userTrailSteps.getTrailId(STEP_COOKIE, trailSteps),
    )
    await concludeStep(
      helpers.userTrailSteps.getTrailId(STEP_CONFIG, trailSteps),
    )
    setActiveStep(STEP_DOCS_INDEX[0])
    setActiveSubStep(STEP_DOCS_INDEX[1])
    setNextDisabled(false)
  }

  const handleBack = () => {
    const backSubStep = () => setActiveSubStep(activeSubStep - 1)
    if (activeStep > 0) {
      if (activeSubStep > 0) {
        return backSubStep()
      }
      const subStep = arrayIndexSize(pages[activeStep - 1])
      setActiveSubStep(subStep)
      return setActiveStep(activeStep - 1)
    }
    if (activeSubStep > 0) {
      return backSubStep()
    }
  }

  // Implementar quando definir se vai utilizar Adopt

  // const loadCookieBanner = async () => {
  //   const response = await service.dponet.cookieConsents.list()
  //   const cookieBanner = response?.data?.cookieConsent
  //   setCookieBannerId(cookieBanner.id)
  // }

  // const loadCookieBannerTag = async () => {
  //   if (cookieBannerId) {
  //     const response = await service.dponet.cookieConsents.installCode({
  //       cookieConsentId: cookieBannerId,
  //     })

  //     setCookieBannerTag(response?.data?.code)
  //   }
  // }

  const concludeStep = async (trailId, observations) => {
    const response = await service.dponet.companyTrails.concludedStep({
      companyTrailId: trailId,
      observations,
    })

    return response.data
  }

  return (
    <TrailStepContext.Provider
      value={{
        activeStep,
        activeSubStep,
        concludeStep,
        getActivePage,
        handleBack,
        handleNext,
        hasBanner,
        helpObject,
        hideStepper,
        isFinalStep,
        isLogoStep,
        isSiteFormStep,
        jumpStep,
        jumpToDocuments,
        loaded,
        nextDisabled,
        pagesLength: arrayIndexSize(pages),
        privacyPolicy,
        privacyStamp,
        setActiveStep,
        setActiveSubStep,
        setHasBanner,
        setLoaded,
        setNextDisabled,
        setPrivacyPolicy,
        setPrivacyStamp,
        showBackButton,
        showHelpButton,
        showJumpButton,
        showNextButton,
      }}
    >
      {children}
    </TrailStepContext.Provider>
  )
}

export default TrailStepProvider
