import { jsPDF } from 'jspdf'

import moment from 'moment'

import pages from './pages'
import templates from '../templates'

import '../fonts/Raleway-Bold-normal'
import '../fonts/Raleway-Thin-normal'
import '../fonts/Roboto-Regular-normal'
import '../fonts/Roboto-Bold-normal'

moment.locale('pt-br')
var dateNow = moment().format('MMM YYYY')

dateNow = dateNow.charAt(0).toUpperCase() + dateNow.slice(1)

const exportPdf = async (
  customStyles,
  customLogo,
  questionnaires,
  questionControls,
  supplier,
  setExportingPdf,
  snackbar,
) => {
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    userUnit: 300,
  })

  try {
    templates.firstCover(
      pdf,
      supplier?.name,
      supplier?.document,
      dateNow,
      customStyles,
      customLogo,
      'Relatório de\nMedidas de\nSegurança',
    )

    pages.generalInformations(
      pdf,
      `Medidas de Segurança | ${dateNow}`,
      questionnaires,
      customStyles,
      questionControls,
    )

    pages.questionControls(
      pdf,
      `Medidas de Segurança | ${dateNow}`,
      questionControls,
    )

    templates.lastCover(pdf, customStyles, customLogo)

    const reportName = `Relatório_de_Medidas_de_Seguranca_do_Fornecedor_${supplier?.name
      ?.split(' ')
      ?.join('_')
      ?.toLowerCase()}_${dateNow}.pdf`

    pdf.save(reportName)

    snackbar.open({
      variant: 'success',
      message: 'Relatório gerado com sucesso, aguarde o download começar',
    })
  } catch (error) {
    console.error(error)
    snackbar.open({
      variant: 'error',
      message: 'Ocorreu um erro ao gerar o relatório',
    })
  } finally {
    setExportingPdf(false)
  }
}

export default exportPdf
