import React, { useEffect, useState } from 'react'

import {
  Box,
  Typography,
  Grid,
  makeStyles,
  CircularProgress,
  Chip,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import helpers from 'helpers'
import constants from 'constants/index'

import styles from './styles'

import { RHFInput } from 'react-hook-form-input'
import { useFormContext } from 'react-hook-form'

import { Select as SelectComponent } from 'components'
import {
  Volumetry,
  ContextDataProcessSource,
  DataProcessTemplateForm,
} from '../../'

import useDataProcess from 'hooks/useDataProcess'

const useStyles = makeStyles(styles)

const GeneralInformation = ({
  dataProcess,
  isLoading = false,
  laws,
  setLaws,
  setVolumetryType,
  volumetryType,
}) => {
  const classes = useStyles()

  const { register, setValue, getValues, errors, control, watch } =
    useFormContext()
  const [loading, setLoading] = useState(true)
  const [allSources, setAllSources] = useState([])
  const { loadOptions, setData, departments } = useDataProcess()

  const fetchData = async () => {
    await loadOptions(dataProcess?.companyId)
  }

  useEffect(() => {
    setData({ dataProcess })
    setAllSources(
      helpers.dataProcess.covertDataSourcesToOptions(
        dataProcess.dataProcessDataSources,
      ),
    )
    fetchData()
    setLoading(false)

    //eslint-disable-next-line
  }, [])

  const watchSourceDescription = watch(
    constants.dataProcess.SOURCE_DESCRIPTION_FIELD,
  )
  return (
    <>
      {isLoading || loading ? (
        <Box
          id="loadBox"
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid
                id={constants.dataProcess.DATA_PROCESS_EDIT_DRIVER_STEP_1}
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  <Typography variant="h5">Informações Gerais</Typography>
                </Grid>
                <Grid item xl={3} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary">
                      Identificador
                    </Typography>
                    <Typography color="textPrimary" variant="body2">
                      {dataProcess?.id}
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <Typography variant="subtitle2" color="textSecondary">
                      Versão
                    </Typography>
                    <Typography color="textPrimary" variant="body2">
                      {dataProcess?.version}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xl={6} sm={6} xs={12}>
                  <Box>
                    <RHFInput
                      as={
                        <TextField
                          label="Nome do processo"
                          color="primary"
                          variant="outlined"
                          error={!!errors.name}
                          helperText={errors?.name?.message}
                          fullWidth
                        />
                      }
                      register={register({
                        required: 'Preencha o nome do processo',
                      })}
                      setValue={setValue}
                      name="name"
                      mode="onBlur"
                    />
                  </Box>
                  <Box mt={3}>
                    <RHFInput
                      as={
                        <SelectComponent
                          label="Departamento"
                          items={departments}
                          error={!!errors.departmentId}
                          helperText={errors?.departmentId?.message}
                        />
                      }
                      register={register}
                      setValue={setValue}
                      name="departmentId"
                      mode="onChange"
                    />
                  </Box>
                  <Box mt={3}>
                    <RHFInput
                      as={
                        <TextField
                          label="Código"
                          color="primary"
                          variant="outlined"
                          error={!!errors.code}
                          helperText={errors?.code?.message}
                          fullWidth
                        />
                      }
                      register={register({
                        required: 'Preencha o código',
                      })}
                      setValue={setValue}
                      name="code"
                      mode="onBlur"
                    />
                  </Box>
                </Grid>
                <Grid item xl={2} md={2} sm={12} xs={12}>
                  <Grid item xl={2} xs={6}>
                    <Box>
                      <Typography variant="subtitle2" color="textSecondary">
                        Status
                      </Typography>
                      <Chip
                        className={clsx(
                          helpers.dataProcess.textStatusColor(
                            dataProcess?.statusId,
                            classes,
                          ),
                          helpers.dataProcess.statusColor(
                            dataProcess?.statusId,
                            classes,
                          ),
                        )}
                        align="center"
                        size="small"
                        label={helpers.dataProcess.status(
                          dataProcess?.statusId,
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                id={constants.dataProcess.DATA_PROCESS_EDIT_DRIVER_STEP_2}
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  <Box>
                    <ContextDataProcessSource
                      control={control}
                      errors={errors}
                      classes={classes}
                      setValue={setValue}
                      watchSourceDescription={watchSourceDescription}
                      isEdit
                      getValues={getValues}
                      allSources={allSources}
                      setAllSources={setAllSources}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={3}>
                    <RHFInput
                      as={
                        <TextField
                          label="Por que o dado é tratado?"
                          type="text"
                          multiline
                          rows={6}
                          color="primary"
                          variant="outlined"
                          error={!!errors.finality}
                          helperText={errors?.finality?.message}
                          fullWidth
                        />
                      }
                      register={register({
                        required:
                          'Preencha o campo de por que o dado é tratado',
                      })}
                      setValue={setValue}
                      name="finality"
                      mode="onBlur"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                id={constants.dataProcess.DATA_PROCESS_EDIT_DRIVER_STEP_3}
                container
                spacing={2}
              >
                <Grid item xs={12}>
                  <Volumetry
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    setVolumetryType={setVolumetryType}
                    volumetryType={volumetryType}
                    laws={laws}
                    setLaws={setLaws}
                    data={dataProcess}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DataProcessTemplateForm step="general_information" />
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

GeneralInformation.propTypes = {
  dataProcess: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  sourceValues: PropTypes.array,
}

GeneralInformation.defaultProps = {
  isLoading: false,
}

export default GeneralInformation
