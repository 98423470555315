import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  loadingButton: {
    width: '20%',
    padding: '2px !important',
    margin: '0px !important',
  },
}))

export default styles
