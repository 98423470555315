const styles = (theme) => ({
  alertBox: {
    backgroundColor: theme.palette.azure,
    borderRadius: '4px',
    margin: '24px 0px',
    width: '100%',
  },
  items: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
  },
  icon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    color: theme.palette.background.default,
    marginRight: '20px',
  },
  text: {
    color: theme.palette.background.default,
  },
})

export default styles
