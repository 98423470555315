import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  multiSelect: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
  },
}))

export default styles
