import splitCategories from './splitCategories'
import getItemsPerArray from './getItemsPerArray'
import addIconForArray from './addIconForArray'
import descriptionForTypeCategory from './descriptionForTypeCategory'
import getIcon from './getIcon'
import getImage from './getImage'
import getIconthus from './getIconthus'
import destructureId from './destructureId'

const partners = {
  splitCategories,
  getItemsPerArray,
  addIconForArray,
  descriptionForTypeCategory,
  getIcon,
  getImage,
  getIconthus,
  destructureId,
}

export default partners
