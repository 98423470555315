import React, { useEffect } from 'react'

import { useForm, Controller } from 'react-hook-form'
import { Box, Grid, TextField } from '@material-ui/core'

import schemaUser from './schema'
import helpers from 'helpers'

const NewUserTab = ({ onSubmit, disabled, setDisabled = () => {} }) => {
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schemaUser,
    defaultValues: {
      name: '',
      email: '',
      phone: '',
    },
  })

  useEffect(() => {
    if (disabled) {
      setDisabled(false)
    }
    //eslint-disable-next-line
  }, [])

  return (
    <Box my={2}>
      <form onSubmit={handleSubmit(onSubmit)} id="newResponsableForm">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  label="Nome"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  fullWidth
                />
              }
              control={control}
              name="name"
              mode="onChange"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  label="Email"
                  type="email"
                  color="primary"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  fullWidth
                />
              }
              control={control}
              name="email"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  label="Telefone"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.phone}
                  helperText={
                    errors?.phone?.message || 'Exemplo: (00) 00000-0000'
                  }
                  fullWidth
                />
              }
              control={control}
              onChange={([e]) => {
                return helpers.formatters.phoneWithoutCountryCode(
                  e.target.value,
                )
              }}
              name="phone"
              mode="onBlur"
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default NewUserTab
