import palette from 'theme/palette'
import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  telephoneGridItem: {
    paddingTop: '0px !important',
  },
  termsToPolicy: {
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: palette.subscription.primary.main,
    },
  },
  helperTextFontSize: {
    fontSize: '9px',
  },
}))

export default styles
