import { isEmpty } from 'lodash'

import components from '../../components'
import palette from 'theme/palette'

import {
  GAP,
  PADDING,
  WIDTH,
  defaultColumStyles,
  defaultHeaderStyles,
} from '../../constants'

import { ratio } from '../../helpers'
import helpers from 'helpers'
import constants from 'constants/index'

const audit = (pdf, dateNow, companyAudit = {}) => {
  const customHeader = {
    textColor: palette.white,
    font: 'Roboto-Bold',
    fillColor: '#707990',
    cellPadding: 6,
  }

  const customHeaderLine = {
    fillColor: palette.white,
    lineWidth: 0.5,
    lineColor: [202, 202, 202],
    halign: 'center',
  }

  const centerColumStyles = {
    0: {
      cellWidth: 0.15 * WIDTH(pdf) - PADDING / 2,
      halign: 'center',
    },
    1: {
      cellWidth: 0.35 * WIDTH(pdf) - PADDING / 2,
      halign: 'center',
    },
    2: {
      font: 'Roboto-Bold',
      cellWidth: 0.25 * WIDTH(pdf) - PADDING / 2,
      halign: 'center',
      fontStyle: 'bold',
    },
    3: {
      cellWidth: 0.25 * WIDTH(pdf) - PADDING / 2,
      halign: 'center',
    },
  }

  const {
    CONFIRMED_STATUS,
    UPDATED_STATUS,
    WAITING_ACTION_STATUS,
    WAITING_UPDATE_STATUS,
  } = constants.auditProcess

  let totalDataProcesses = 0
  let updateDataProcesses = 0
  let confirmedDataProcesses = 0
  let pendingDataProcesses = 0

  companyAudit.departments.forEach((department) => {
    department.dataProcesses.forEach((process) => {
      totalDataProcesses++

      if (process.auditStatus === CONFIRMED_STATUS) {
        confirmedDataProcesses++
      }

      if (process.auditStatus === UPDATED_STATUS) {
        updateDataProcesses++
      }

      if (
        process.auditStatus === WAITING_ACTION_STATUS ||
        process.auditStatus === WAITING_UPDATE_STATUS
      ) {
        pendingDataProcesses++
      }
    })
  })

  pdf.addPage()
  components.subtitle(
    pdf,
    `Auditoria de Processos - ${helpers.formatters.date(
      companyAudit?.startDate,
    )}`,
    GAP,
    palette.primary.main,
  )

  components.lineWithDate(pdf, `Visão geral | ${dateNow}`)
  components.numberPage(pdf, palette.primary.main, false, 1)

  components.rectangle(
    pdf,
    PADDING,
    GAP * 1.25,
    ratio(345),
    palette.primary.main,
    ratio,
    'Total Auditado',
    totalDataProcesses,
  )

  components.rectangle(
    pdf,
    PADDING * 2.85,
    GAP * 1.25,
    ratio(345),
    palette.primary.main,
    ratio,
    'Confirmados',
    confirmedDataProcesses,
  )

  components.rectangle(
    pdf,
    PADDING * 4.7,
    GAP * 1.25,
    ratio(345),
    palette.primary.main,
    ratio,
    'Atualizados',
    updateDataProcesses,
  )

  components.rectangle(
    pdf,
    PADDING * 6.55,
    GAP * 1.25,
    ratio(345),
    palette.primary.main,
    ratio,
    'Pendentes',
    pendingDataProcesses,
  )

  var mountQuestionnaireRow = () => [
    ['Data de ínicio', 'Data fim'],
    [
      helpers.formatters.date(companyAudit?.startDate),
      helpers.formatters.date(companyAudit?.endDate),
    ],
  ]

  components.table(
    pdf,
    [['Periódo', '']],
    mountQuestionnaireRow(),
    GAP * 2.75,
    0.45,
    PADDING,
    true,
    null,
    defaultHeaderStyles,
  )

  const departments = companyAudit?.departments

  departments.forEach((department, index) => {
    const startY = index === 0 ? GAP * 3.95 : pdf.autoTable.previous.finalY + 16

    const mountResponsibleRow = () => {
      if (isEmpty(department.responsibleUsers)) return [['N/A', 'N/A']]

      return department.responsibleUsers.map((user) => [
        user?.name,
        user?.email,
      ])
    }

    const mountDataProcessRow = () => {
      return department.dataProcesses.map((process) => [
        process?.id,
        process?.name,
        helpers.auditProcess.reportStatus(process?.auditStatus),
        helpers.formatters.date(process?.updatedAt),
      ])
    }

    components.table(
      pdf,
      [[`${department?.name}`, '']],
      null,
      startY,
      0.5,
      PADDING,
      true,
      null,
      defaultHeaderStyles,
      16,
    )

    components.table(
      pdf,
      [['Responsáveis', '']],
      mountResponsibleRow(),
      pdf.autoTable.previous.finalY,
      0.5,
      PADDING,
      true,
      defaultColumStyles(0.45, pdf),
      customHeader,
    )

    components.table(
      pdf,
      [['Processos']],
      null,
      pdf.autoTable.previous.finalY,
      0.5,
      PADDING,
      true,
      null,
      customHeader,
    )

    components.table(
      pdf,
      [['ID', 'Nome do Processo', 'Status', 'Última atualização']],
      mountDataProcessRow(),
      pdf.autoTable.previous.finalY,
      0.2,
      PADDING,
      true,
      centerColumStyles,
      customHeaderLine,
    )
    components.numberPage(pdf, palette.primary.main, false, 1)
  })
}

export default audit
