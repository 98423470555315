import constants from 'constants/index'

const isPmo = (user) => {
  if (!user?.profile) {
    return false
  }

  return parseInt(user.profile.id) === constants.profile.PMO_ID
}

export default isPmo
