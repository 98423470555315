import React, { useState } from 'react'

import { TableContainer, Button } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { Permitted, MenuButton } from 'components'

import { DialogExclusion, DialogEdition } from './components'

import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import constants from 'constants/index'

const TableDataTreatmentAgents = ({
  dataProcessId,
  dataTreatmentAgents,
  refresh,
}) => {
  const snackbar = useSnackbar()
  const [dialogRemove, setDialogRemove] = useState(false)
  const [dialogEdit, setDialogEdit] = useState(false)
  const [handleDataAgent, setHangleDataAgent] = useState(null)
  const [type, setType] = useState(null)
  const [document, setDocument] = useState(null)

  const handleRemove = (dataTreatmentAgent) => {
    setHangleDataAgent(dataTreatmentAgent)
    setDialogRemove(true)
  }

  const handleEdit = (dataTreatmentAgent) => {
    setHangleDataAgent(dataTreatmentAgent)
    setDialogEdit(true)
  }

  const handleChange = async (actionExclusion) => {
    try {
      if (actionExclusion) {
        await service.dponet.dataTreatmentAgents.destroy({
          dataProcessId,
          dataTreatmentAgentId: handleDataAgent?.id,
        })
      } else {
        await service.dponet.dataTreatmentAgents.changeType({
          dataProcessId,
          dataTreatmentAgentId: handleDataAgent?.id,
          agentType: type === null ? handleDataAgent?.agentType : type,
          document: document === null ? handleDataAgent?.document : document,
        })
      }

      snackbar.open({
        message: `Agente de tratamento ${
          actionExclusion ? 'excluido' : 'editado'
        } com sucesso`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: `Falha ao ${
          actionExclusion ? 'excluir' : 'editar'
        } o agente de tratamento`,
        variant: 'error',
      })
    } finally {
      setHangleDataAgent(null)
      setDialogRemove(false)
      setDialogEdit(false)
      refresh()
    }
  }

  return (
    <>
      <TableContainer>
        <Table
          size="small"
          emptyMessage="Nenhum agente de tratamento foi encontrado"
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell width="40%">Organização</TableCell>
              <TableCell width="25%">CNPJ/CPF</TableCell>
              <TableCell width="25%">Tipo</TableCell>
              <TableCell width="10%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTreatmentAgents?.map((dataTreatmentAgent, index) => (
              <TableRow key={index}>
                <TableCell>{dataTreatmentAgent?.name}</TableCell>
                <TableCell>
                  {helpers.formatters.cnpjOrCpf(dataTreatmentAgent?.document)}
                </TableCell>
                <TableCell>
                  {
                    constants.dataTreatmentAgents.TRANSLATED_TYPE[
                      dataTreatmentAgent?.agentType
                    ]
                  }
                </TableCell>
                <TableCell align="right">
                  <MenuButton>
                    <Permitted tag="create_data_process">
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() => handleEdit(dataTreatmentAgent)}
                      >
                        Editar
                      </Button>
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() => handleRemove(dataTreatmentAgent)}
                      >
                        Excluir
                      </Button>
                    </Permitted>
                  </MenuButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {dialogEdit && (
        <DialogEdition
          open={dialogEdit}
          setOpen={setDialogEdit}
          handleChange={handleChange}
          agent={handleDataAgent}
          setType={setType}
          type={type}
          setDocument={setDocument}
          document={document}
        />
      )}
      {dialogRemove && (
        <DialogExclusion
          open={dialogRemove}
          setOpen={setDialogRemove}
          handleRemove={handleChange}
        />
      )}
    </>
  )
}

TableDataTreatmentAgents.defaultProps = {
  refresh: () => {},
}

export default TableDataTreatmentAgents
