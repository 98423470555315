import { makeStyles } from '@material-ui/core'
import hexagon from 'images/hexagon.png'

const styles = makeStyles((theme) => ({
  box: {
    minHeight: 300,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'end',
    marginBottom: 20,
    color: 'white',
    padding: 35,
    backgroundImage: `url(${hexagon})`,
    backgroundSize: 150,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '83%',
    backgroundPositionY: '23%',
    '&::before': {
      content: '-',
      display: 'block',
    },
  },
  noTimeTitle: {
    fontSize: 22,
    fontWeight: 900,
  },
  informationText: {
    fontWeight: 'normal',
    color: theme.palette.white,
  },
}))

export default styles
