import React from 'react'

import { Permitted } from 'components'

import { Link, Typography, makeStyles } from '@material-ui/core'

import { Alert } from '@material-ui/lab'

// import { useHistory } from 'react-router-dom'

// import { routes } from 'Routes'

// import { reverse } from 'named-urls'

import styles from './styles'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

function FinalizedAlert({ nonComplianceReport, finalized = false, isRisk }) {
  // const history = useHistory()
  const classes = useStyles()
  const fragilityFinality =
    helpers.nonComplianceReports.finalityCheck.fragility(nonComplianceReport)

  const toNonComplianceReport = () => {
    // history.push(
    //   reverse(routes.nonComplianceReport.edit, {
    //     nonComplianceReportId: nonComplianceReport.id,
    //   }),
    // )
  }

  return (
    <Permitted tag="edit_non_compliance_report">
      <Alert variant="filled" severity={finalized ? 'info' : 'warning'}>
        <Typography>
          {helpers.nonComplianceReports.getFinalizedText(
            finalized,
            fragilityFinality,
            isRisk,
            nonComplianceReport,
          )}
        </Typography>
        <Typography>
          <Link
            href="#"
            className={finalized ? classes.linkInfo : classes.link}
            onClick={toNonComplianceReport}
          >
            Clique aqui
          </Link>
          {fragilityFinality && ' para visualizar o RMC de risco.'}
          {helpers.nonComplianceReports.finalityCheck.necessary(
            nonComplianceReport,
          ) && ' para visualizar o RMC de necessidade e proporcionalidade.'}
          {helpers.nonComplianceReports.finalityCheck.fragilityAndNecessary(
            nonComplianceReport,
          ) &&
            ' para visualizar o RMC de risco e necessidade e proporcionalidade.'}
        </Typography>
      </Alert>
    </Permitted>
  )
}

export default FinalizedAlert
