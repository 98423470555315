import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  blackTypography: {
    color: theme.black,
  },
  childrenContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gridGap: 8,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}))

export default useStyles
