import constants from 'constants/index'

const retentionFinality = (dataProcess, mapLaws, dataLifeCycle) => {
  return dataProcess?.storageMode ===
    constants.dataProcess.STORY_MODE_PERMANENT_TYPE
    ? mapLaws
    : dataLifeCycle?.retentionFinality || ''
}

export default retentionFinality
