import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
    paddingTop: 126,
  },
}))

export default styles
