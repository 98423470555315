import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Paper, Typography } from '@material-ui/core'
import { orderBy, flatMap } from 'lodash'
import { useForm } from 'react-hook-form'

import { Questions } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useDataProcess from 'hooks/useDataProcess'

import * as service from 'service'
import schemaBalancingSafeguarding from './schema'
import constants from 'constants/index'
import { routes } from 'Routes'

const PendingLia = ({
  dataProcess = {},
  questions = [],
  questionsPendingAnswers = [],
  questionsApprovedAnswers = [],
  show = false,
}) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const { setLoadingReplyLiaAnswer } = useDataProcess()

  const { LEGITIMATE_EXPECTATION, SAFEGUARDS_AND_MECHANISMS } =
    constants.liaQuestions

  const { INACTIVE } = constants.liaAnswers.STATUSES

  const questionsLegitimateExpectation = () => {
    return questions?.filter((question) => {
      return question?.kind === LEGITIMATE_EXPECTATION
    })
  }

  const questionsSafeguardMechanisms = () => {
    return questions?.filter((question) => {
      return question?.kind === SAFEGUARDS_AND_MECHANISMS
    })
  }

  const filterAnswerByKind = (questions, kind) => {
    const liaQuestions = questions?.filter((question) => {
      return question?.kind === kind
    })

    const liaAnswers = flatMap(
      liaQuestions?.map((question) => question.liaAnswers),
    )
    return liaAnswers?.filter((answer) => answer?.status !== INACTIVE)
  }

  const answerPendingLegitimateExpectation = orderBy(
    filterAnswerByKind(questionsPendingAnswers, LEGITIMATE_EXPECTATION),
    'liaQuestion.order',
  )

  const answersPendingSafeguardsMechanisms = orderBy(
    filterAnswerByKind(questionsPendingAnswers, SAFEGUARDS_AND_MECHANISMS),
    'liaQuestion.order',
  )

  const answerApprovedLegitimateExpectation = orderBy(
    filterAnswerByKind(questionsApprovedAnswers, LEGITIMATE_EXPECTATION),
    'liaQuestion.order',
  )

  const answersApprovedSafeguardsMechanisms = orderBy(
    filterAnswerByKind(questionsApprovedAnswers, SAFEGUARDS_AND_MECHANISMS),
    'liaQuestion.order',
  )

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schemaBalancingSafeguarding(show),
    defaultValues: {
      question5:
        answerPendingLegitimateExpectation?.[0]?.answer ||
        answerApprovedLegitimateExpectation?.[0]?.answer ||
        '',
      question6:
        answerPendingLegitimateExpectation?.[1]?.answer ||
        answerApprovedLegitimateExpectation?.[1]?.answer ||
        '',
      question7:
        answerPendingLegitimateExpectation?.[2]?.answer ||
        answerApprovedLegitimateExpectation?.[2]?.answer ||
        '',
      question8:
        answerPendingLegitimateExpectation?.[3]?.answer ||
        answerApprovedLegitimateExpectation?.[3]?.answer ||
        '',
      question9:
        answersPendingSafeguardsMechanisms?.[0]?.answer ||
        answersApprovedSafeguardsMechanisms?.[0]?.answer ||
        '',
      question10:
        answersPendingSafeguardsMechanisms?.[1]?.answer ||
        answersApprovedSafeguardsMechanisms?.[1]?.answer ||
        '',
      question11:
        answersPendingSafeguardsMechanisms?.[2]?.answer ||
        answersApprovedSafeguardsMechanisms?.[2]?.answer ||
        '',
    },
  })

  const onSubmit = async (data) => {
    if (show) return history.push(routes.dataProcess.all)

    setLoadingReplyLiaAnswer(true)
    var liaAnswers = []

    Object.keys(data).map((key) => {
      return liaAnswers.push({
        order: key.replace(/^question/, ''),
        answer: data[key],
      })
    })

    try {
      await service.dponet.liaAnswers.create({
        liaAnswers,
        dataProcessId: dataProcess.id,
        balancingStep: true,
      })
      return history.push(routes.dataProcess.all)
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.error?.message ||
          'Erro ao responder as respostas para LIA!',
        variant: 'error',
      })
    } finally {
      setLoadingReplyLiaAnswer(false)
    }
  }

  return (
    <Box mt={2}>
      <form id="lia_form" onSubmit={handleSubmit(onSubmit)}>
        <Paper component={Box} padding={2}>
          <Box mb={2}>
            <Typography variant="h5">Legítima expectativa</Typography>
          </Box>
          {orderBy(questionsLegitimateExpectation(), 'order')?.map(
            (question) => (
              <Questions
                key={question?.id}
                order={question?.order}
                text={question?.text}
                control={control}
                errors={errors}
                show={show}
              />
            ),
          )}
        </Paper>
        <Paper component={Box} padding={2} mt={2}>
          <Box mb={2}>
            <Typography variant="h5">
              Salvaguardas e mecanismos de opt-out e de oposição
            </Typography>
          </Box>
          {orderBy(questionsSafeguardMechanisms(), 'order')?.map((question) => (
            <Questions
              key={question?.id}
              order={question?.order}
              text={question?.text}
              control={control}
              errors={errors}
              show={show}
            />
          ))}
        </Paper>
      </form>
    </Box>
  )
}

export default PendingLia
