import React from 'react'
import PropTypes from 'prop-types'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { Box, CircularProgress } from '@material-ui/core'

import PerfectScrollbar from 'react-perfect-scrollbar'

import TableRowProfile from '../TableRowProfile'

const ProfilesTable = ({ profiles, isLoading, refresh }) => {
  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          <PerfectScrollbar>
            <Table
              size="medium"
              emptyMessage="Nenhum perfil encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Identificador</TableCell>
                  <TableCell width="30%">Nome</TableCell>
                  <TableCell width="30%">Descrição</TableCell>
                  <TableCell width="10%">Status</TableCell>
                  <TableCell width="10%" align="right">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {profiles?.map((profile) => (
                  <TableRowProfile
                    profile={profile}
                    refresh={refresh}
                    key={profile.id}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}

ProfilesTable.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  refresh: PropTypes.func,
}

ProfilesTable.defaultProps = {
  refresh: () => {},
}

export default ProfilesTable
