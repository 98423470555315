import Main from './QuestionControlsMain'
import Edit from './QuestionControlsEdit'
import Show from './QuestionControlsShow'
import New from './QuestionControlsNew'

export default {
  Main,
  Edit,
  Show,
  New,
}
