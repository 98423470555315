import palette from 'theme/palette'

import { logoBlue } from '../../assets/logoBlue64'

const secondCover = (
  pdf,
  companyName,
  document,
  date,
  padding,
  width,
  height,
  ratio,
) => {
  pdf.addPage()
  pdf.setFillColor(palette.white)
  pdf.rect(0, 0, width, height, 'F')

  pdf.addImage(
    logoBlue,
    'png',
    width / 2 - padding * 1.25,
    padding,
    ratio(500),
    ratio(158),
  )
  pdf.setFontSize(ratio(260))
  pdf.setLineHeightFactor(1.2)
  pdf.setFont('Raleway-Bold')
  var splited_title = pdf.splitTextToSize(
    'Relatório Gerencial da Adequação à LGPD \nVisão Geral',
    ratio(1700),
  )
  pdf.setTextColor(palette.subscription.primary.main)
  pdf.text(splited_title, width / 2, ratio(750), null, null, 'center')

  pdf.setFontSize(ratio(80))
  pdf.setFont('Raleway-Regular')

  var splited_name = pdf.splitTextToSize(companyName, ratio(1024))

  pdf.text(splited_name, width / 2, ratio(1940), null, null, 'center')
  pdf.text(
    document,
    width / 2,
    ratio(2000 + 50 * splited_name.length),
    null,
    null,
    'center',
  )
  pdf.text(date, width / 2, height - padding, null, null, 'center')
}

export default secondCover
