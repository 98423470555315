import React, { useState } from 'react'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { Box, Container, Button, Tooltip } from '@material-ui/core'

import { Page, ContentHeader, DriveTour } from 'components'
import { CompanyFragilityForm } from './components'

import useStyles from './styles'
import constants from 'constants/index'
import helpers from 'helpers'

const CompanyFragilityNew = () => {
  const [openTour, setOpenTour] = useState(false)
  const classes = useStyles()

  const handleOpenTour = () => {
    const where = 'Ameaças'
    const title = 'Tour'

    const params = {
      action: 'tour-nova-ameaça-diagnostico',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Nova ameaça">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Nova ameaça">
          <Box className={classes.contentHeader}>
            <Tooltip title="Iniciar o tour guiado">
              <Box className={classes.contentHeaderBox}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<HelpCircleIcon size={20} />}
                  onClick={handleOpenTour}
                  fullWidth
                >
                  Tutorial
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </ContentHeader>
        <Box width="100%" mt={1}>
          <CompanyFragilityForm />
        </Box>
      </Container>

      <DriveTour
        stepsMatrix={constants.fragilities.NEW_FRAGILITIES_DRIVER_STEPS_OBJECT}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default CompanyFragilityNew
