import React, { memo, useState } from 'react'
import { Box, Button, Container, Typography } from '@material-ui/core'
import { useLocation, Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Form, ResendEmail } from './components'

import useStyles from './styles'
import { routes } from 'Routes'

const TwoFactorAuthenticationMain = ({ match }) => {
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const location = useLocation()

  const accessToken = match.params.accessToken

  return (
    <Box className={classes.root}>
      <Container maxWidth="xs">
        <Typography variant="h4" color="textPrimary">
          Enviamos um código de verificação no seu e-mail{' '}
          <strong className={classes.highlightedText}>
            {location.state?.email}
          </strong>
        </Typography>

        <Form accessToken={accessToken} setLoading={setLoading} />
        <ResendEmail accessToken={accessToken} />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={4}
        >
          <Button
            type="submit"
            variant="contained"
            className={classes.buttonPrimary}
            form="2fa-form"
            disabled={loading}
          >
            {loading ? 'Carregando...' : 'Acessar'}
          </Button>

          <Link to={routes.root}>
            <Typography>Voltar</Typography>
          </Link>
        </Box>
      </Container>
    </Box>
  )
}

TwoFactorAuthenticationMain.propTypes = {
  match: PropTypes.object.isRequired,
}

export default memo(TwoFactorAuthenticationMain)
