import React from 'react'
import { Paper, IconButton } from '@material-ui/core'
import { ExternalLink } from 'react-feather'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import helpers from 'helpers'

const DocumentTable = ({ companyDocuments }) => {
  const redirectToDocument = (link) => {
    window.open(helpers.formatters.website(link), '_blank')
  }

  return (
    <Paper>
      <Table noWrap emptyMessage="Nenhum documento encontrado!">
        <TableHead>
          <TableRow>
            <TableCell width="10%">Identificador</TableCell>
            <TableCell width="80%">Documento</TableCell>
            <TableCell width="10%" align="right">
              Ações
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companyDocuments?.map((document) => (
            <TableRow key={document.id}>
              <TableCell>{document.id}</TableCell>
              <TableCell>{document.title}</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => redirectToDocument(document.link)}>
                  <ExternalLink size={16} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default DocumentTable
