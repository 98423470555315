import companyTrailByItem from './companyTrailByItem'
import defineCardStatus from './defineCardStatus'
import defineProgress from './defineProgress'
import defineStatus from './defineStatus'
import defineSubtitle from './defineSubtitle'
import mountTrails from './mountTrails'
import trailsConcluded from './trailsConcluded'

const companyTrails = {
  companyTrailByItem,
  defineCardStatus,
  defineProgress,
  defineStatus,
  defineSubtitle,
  mountTrails,
  trailsConcluded,
}

export default companyTrails
