const alertInfo = (status) => {
  const convertStatus = {
    in_progress: 'Este controle está em andamento',
    adopted:
      'A ausência de plano de um plano de ação para esse controle se deve à resposta "Sim" no questionário.',
    treated: 'O plano de ação foi adotado e finalizado.',
  }

  return convertStatus[status] || ''
}

export default alertInfo
