import { makeStyles } from '@material-ui/core'
const styles = (fontSize, fontHeight) =>
  makeStyles((theme) => ({
    text: {
      [theme.breakpoints.up('md')]: {
        fontSize: fontSize,
        fontWeight: fontHeight,
      },
    },
    primary: {
      color: theme.colors.firstStep.text.primary,
    },
    secondary: {
      color: theme.colors.firstStep.text.secondary,
      lineHeight: 1.435,
    },
  }))

export default styles
