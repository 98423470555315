import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

const BaseAccessData = ({ children, onSubmit, isDemoSubscription }) => {
  if (isDemoSubscription) {
    return <Box mt={4}>{children}</Box>
  }

  return (
    <Box p={2}>
      <form onSubmit={onSubmit} id="form-submit-main">
        {children}
      </form>
    </Box>
  )
}

BaseAccessData.propTypes = {
  onSubmit: PropTypes.func,
  isDemoSubscription: PropTypes.bool,
}

BaseAccessData.defaultProps = {
  isDemoSubscription: false,
}

export default BaseAccessData
