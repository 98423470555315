import constants from 'constants/index'
import helpers from 'helpers'

const getStepId = (step, userSteps) => {
  const {
    STEP_DPO_INDEX,
    STEP_LGPD_TAG,
    STEP_LGPD_INDEX,
    STEP_STAMP_TAG,
    STEP_STAMP_INDEX,
    STEP_CERTIFICATION_TAG,
    STEP_CERTIFICATION_INDEX,
    STEP_COOKIE_TAG,
    STEP_COOKIE_INDEX,
    STEP_DPO_TAG,
  } = constants.userSteps

  switch (step) {
    case STEP_DPO_INDEX[0]:
      return helpers.userSteps.getStepIdByTag(STEP_DPO_TAG, userSteps)
    case STEP_LGPD_INDEX[0]:
      return helpers.userSteps.getStepIdByTag(STEP_LGPD_TAG, userSteps)
    case STEP_STAMP_INDEX[0]:
      return helpers.userSteps.getStepIdByTag(STEP_STAMP_TAG, userSteps)
    case STEP_CERTIFICATION_INDEX[0]:
      return helpers.userSteps.getStepIdByTag(STEP_CERTIFICATION_TAG, userSteps)
    case STEP_COOKIE_INDEX[0]:
      return helpers.userSteps.getStepIdByTag(STEP_COOKIE_TAG, userSteps)
    default:
      return null
  }
}

export default getStepId
