import React from 'react'
import { Link2 as Link2Icon } from 'react-feather'
import PropTypes from 'prop-types'
import { Box, Link, Tooltip, Typography } from '@material-ui/core'

import { ScannerStatus } from '../../../'

import helpers from 'helpers'

import useStyles from './styles'
import theme from 'theme'

const DetailsReadingCard = ({ scanner }) => {
  const classes = useStyles()

  return (
    <Box className={classes.detailsContainer}>
      <Box>
        <Typography color="primary" gutterBottom>
          Escaneamento feito por
        </Typography>
        <Box
          display="flex"
          gridGap={theme.spacing(1)}
          alignItems="center"
          component={Link}
          href={scanner?.url}
          target="_blank"
          underline="none"
          width="fit-content"
        >
          <Box>
            <Link2Icon size={20} />
          </Box>
          <Tooltip title={scanner?.url || 'Não encontrado'}>
            <Typography
              color="primary"
              variant="h6"
              className={classes.urlTypography}
            >
              {scanner?.url || 'Não encontrado'}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      <Box>
        <Typography color="primary" gutterBottom>
          Status
        </Typography>
        <ScannerStatus status={scanner?.status} />
      </Box>
      <Box>
        <Typography color="primary" gutterBottom>
          Data
        </Typography>
        <Typography color="primary" variant="h6">
          {helpers.formatters.ptBrFormatDateTime(
            scanner?.scannedAt,
            'Não encontrada',
          )}
        </Typography>
      </Box>
      <Box>
        <Typography color="primary" gutterBottom>
          Frequência do scanner
        </Typography>
        <Typography color="primary" variant="h6">
          Manual
        </Typography>
      </Box>
    </Box>
  )
}

DetailsReadingCard.propTypes = {
  scanner: PropTypes.object,
}

export default DetailsReadingCard
