import React from 'react'
import { Box, Typography, makeStyles, Tooltip } from '@material-ui/core'

import useAuth from 'hooks/useAuth'

import styles from './styles'

const useStyles = makeStyles(styles)

const UserBox = () => {
  const auth = useAuth()
  const classes = useStyles()

  return (
    <Tooltip title={auth?.company?.name ?? 'Administrador'}>
      <Box p={1} className={classes.root}>
        <Typography variant="h6" className={classes.defaultTypography}>
          {auth?.company?.name ?? auth?.user?.name}
        </Typography>
        <Box>
          <Typography
            align="left"
            variant="body2"
            className={classes.defaultTypography}
          >
            {auth?.company?.document}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  )
}

export default UserBox
