import permissions from '../permissions'

const MARKETPLACE_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.VISUALIZE,
    tag: permissions.MARKETPLACE.VIEW,
  },
]

export default MARKETPLACE_LIST
