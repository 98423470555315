import accountabilityStatement from './accountabilityStatement'
import audit from './audit'
import components from './components'
import consent from './consent'
import dashboard from './dashboard'
import fragilities from './fragilities'
import lia from './lia'
import questionControl from './questionControl'
import rmcs from './rmcs'
import supplierCompliance from './supplierCompliance'
import training from './training'

const reports = {
  accountabilityStatement,
  audit,
  components,
  consent,
  dashboard,
  fragilities,
  lia,
  questionControl,
  rmcs,
  supplierCompliance,
  training,
}

export default reports
