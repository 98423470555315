import constants from 'constants/index'

const defineStatus = (trail) => {
  if (trail.completed) {
    return constants.companyTrail.CONCLUDED_STATUS
  }

  if (trail.jumped) {
    return constants.companyTrail.DOING_STATUS
  }

  return constants.companyTrail.NOT_INITIATED_STATUS
}

export default defineStatus
