import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  cardContainer: {
    border: 'none',
    padding: 0,
    cursor: 'pointer',
  },
  imageContainer: {
    borderRadius: 10,

    [theme.breakpoints.down('sm')]: {
      width: 220,
    },
  },
}))

export default styles
