const validUrl = (str) => {
  var protocolRegex = new RegExp(
    '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?',
  )
  var withoutProtocolRegex = new RegExp(
    '^([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?',
  )

  return protocolRegex.test(str) || withoutProtocolRegex.test(str)
}

export default validUrl
