import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  makeStyles,
  TablePagination,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useTheme } from '@material-ui/styles'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import {
  ContentHeader,
  DriveTour,
  FilterButton,
  Filters,
  Page,
} from 'components'
import { AdvisorCard, SuggestionTable } from './components'

import usePagination from 'hooks/usePagination'
import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'

import constants from 'constants/index'
import helpers from 'helpers'

import * as service from 'service'
import styles from './styles'

const useStyles = makeStyles(styles)

const SuppliersMain = () => {
  const filter = useFilter()
  const classes = useStyles()
  const theme = useTheme()

  const [openTour, setOpenTour] = useState(false)
  const [showAdvisor, setShowAdvisor] = useState(
    localStorage.getItem(constants.companySuppliers.ADVISOR_CARD_SUGGESTED) !==
      constants.companySuppliers.ADVISOR_CARD_CLOSED,
  )

  const { mixpanel } = helpers

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.suggestedSuppliers.get,
    {
      perPage,
      page,
      ...{ ...filter.filters },
    },
    [page, perPage, filter.filters],
  )

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Sugestão de fornecedores', 'Tour', {
      action: `tour-sugestão-de-fornecedores-fornecedores`,
    })
  }

  const filteredSteps =
    constants.companySuppliers.SUPPLIERS_SUGGESTIONS_DRIVER_STEPS_OBJECT.filter(
      (step) => {
        if (isEmpty(response?.data?.supplierSuggestions))
          return (
            step.element !==
            '#' +
              constants.companySuppliers
                .SUPPLIERS_SUGGESTIONS_MAIN_DRIVER_STEP_2
          )
        return true
      },
    )

  return (
    <Page title="Sugestão de fornecedores">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Sugestão de fornecedores">
          <Tooltip title="Iniciar o tour guiado">
            <Button
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
              id={
                constants.companySuppliers
                  .SUPPLIERS_SUGGESTIONS_MAIN_DRIVER_STEP_1
              }
            >
              Tutorial
            </Button>
          </Tooltip>
          <Box pl={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen}></FilterButton>
          </Box>
        </ContentHeader>
        {showAdvisor && (
          <AdvisorCard
            setShowAdvisor={setShowAdvisor}
            isSuggestion
            isSupplier={false}
          />
        )}
        <Card>
          <Box display="flex" width="100%" mt={2}>
            <Grid container>
              <SuggestionTable
                supplierSuggestions={response?.data?.supplierSuggestions}
                isLoading={isLoading}
                refresh={refresh}
              />
            </Grid>
          </Box>
          <Box px={2} display="flex" justifyContent="flex-end">
            {!isLoading && response ? (
              <TablePagination
                component="div"
                count={response.data.meta.totalCount}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            ) : (
              <Box py="11px">
                <Skeleton variant="rect" width={200} height={30} />
              </Box>
            )}
          </Box>
        </Card>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input textfieldinput="true" label="Documento" name="document" />
          <input textfieldinput="true" label="Nome" name="name" />
        </Filters>
      </Container>
      <DriveTour
        stepsMatrix={filteredSteps}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default SuppliersMain
