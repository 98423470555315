import React from 'react'

import { ApprovedLia } from '../../components'

import constants from 'constants/index'

const Necessity = ({
  handleNext = () => {},
  answersReviewAndApproved = [],
  questions = [],
}) => {
  const { STEP_LABELS } = constants.liaQuestions

  return (
    <ApprovedLia
      handleNext={handleNext}
      answersReviewAndApproved={answersReviewAndApproved}
      questions={questions}
      stepLabel={STEP_LABELS.NECESSITY_LABEL}
    />
  )
}

export default Necessity
