import mountListOfAnotherOptions from './mountListOfAnotherOptions'
import mountDataCollectedOptionsIDs from './mountDataCollectedOptionsIDs'
import mountLabelList from './mountLabelList'
import mountSelectedList from './mountSelectedList'
import messagesMultiSelect from './messagesMultiSelect'
import treatDataToSubmit from './treatDataToSubmit'
import dataTypeLabel from './dataTypeLabel'
import mountNecessityAndProportionalityParams from './mountNecessityAndProportionalityParams'
import mountUnnecessaryFields from './mountUnnecessaryFields'
import nameOfTitularAgeRange from './nameOfTitularAgeRange'
import rangeAgeContent from './rangeAgeContent'

export default {
  mountListOfAnotherOptions,
  mountDataCollectedOptionsIDs,
  mountLabelList,
  messagesMultiSelect,
  mountSelectedList,
  dataTypeLabel,
  treatDataToSubmit,
  mountNecessityAndProportionalityParams,
  mountUnnecessaryFields,
  nameOfTitularAgeRange,
  rangeAgeContent,
}
