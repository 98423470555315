import pages from './pages'

const CATEGORIES_IDS = {
  training: ['2', '3', '4', '5', '15'],
  service: [
    '1',
    '8',
    '18',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '29',
    '31',
    '35',
  ],
  journeyHelp: ['7', '30'],
  mapping: ['6', '20', '28'],
  rmc: ['9', '10', '11', '32'],
  cookie: ['14', '19', '21'],
}

const marketplace = { pages, CATEGORIES_IDS }

export default marketplace
