import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  link: {
    color: theme.palette.grapePurple,
    cursor: 'pointer',
  },
}))

export default styles
