import dataDiscoveryCover from 'images/data-discovery-cover.png'

import visualizacaoImage from 'images/pentest-white-box.png'
import apoioImage from 'images/pentest-network-service.png'
import descobertaImage from 'images/pentest-web-app.png'

import benefitProtecaoPerdasImage from 'images/cyber-benefit-protecao.png'
import benefitSuporteImage from 'images/cyber-benefit-suporte.png'
import benefitComplianceImage from 'images/cyber-benefit-compliance.png'
import benefitPreparacaoImage from 'images/cyber-benefit-preparacao.png'
import benefitProtecaoEmpresasImage from 'images/cyber-benefit-protecao-empresa.png'
import benefitGerenciamentoImage from 'images/cyber-benefit-gerenciamento.png'

const cyberIcons = [
  {
    title: 'Visualização Intuitiva',
    altText: 'Visualização Intuitiva',
    src: visualizacaoImage,
  },
  {
    title: 'Apoio á Tomada de Decisões',
    altText: 'Apoio á Tomada de Decisões',
    src: apoioImage,
  },
  {
    title: 'Descoberta Automatizada de Padrões',
    altText: 'Descoberta Automatizada de Padrões',
    src: descobertaImage,
  },
]

const dataDiscovery = {
  id: 20,
  sectionTitle: 'Data Discovery',
  description:
    'O Data Discovery é como um detetive digital: encontra e organiza dados, revelando informações valiosas para empresas tomarem decisões mais inteligentes.',
  whatIs: {
    primaryText: 'O que é o Data Discovery?',
    text: 'O Data Discovery é uma solução que realiza a descoberta de dados (nome, cpf, rg etc.) em HDs, partições, servidores, entre outros. Ao realizar a busca pelos dados solicitados é possível tomar algumas ações com os dados encontrados como Anonimiza-los ou apaga-los.',
    icons: cyberIcons,
  },
  forWho: {
    text: 'Um dos direitos do titular de dados, previstos na LGPD, é a exclusão de seus dados quando solicitado. Nem todas as empresas conseguem encontrar, com facilidade, os dados solicitados para a exclusão, imagine o seguinte cenário, uma planilha de cadastro compartilhada entre departamentos em que cada um tem sua cópia e faz suas respectivas alterações, além de outros documentos necessários para o cadastro. Quão difícil será certificar-se que todos os campos contendo os dado do solicitante foram devidamente excluido? O Data Discovery faz sentido para empresas que:',
    cover: dataDiscoveryCover,
    coverText: 'Imagem de uma mão segurando uma lapa através de um escudo',
    benefitsList: [
      'Armazene um ou mais documentos com dados de pessoas físicas',
      'Compartilha dados pessoais com 1 ou mais computadores',
      'Necessitam de conformidade com a LGPD',
    ],
  },
  benefits: [
    {
      title: 'Busca de forma autônoma',
      altText: 'Busca de forma autônoma',
      description: 'Sem a necessidade de acompanhamento de um humano.',
      icon: benefitProtecaoPerdasImage,
    },
    {
      title: 'Realiza buscas simultâneas',
      altText: 'Realiza buscas simultâneas',
      description:
        'É possível fazer a mesma descoberta de dados em mais de um computador por vez.',
      icon: benefitSuporteImage,
    },
    {
      title: 'Acurácia de 100% nas buscas',
      altText: 'Acurácia de 100% nas buscas',
      description: 'Não haverá perdas na busca pelo dado.',
      icon: benefitComplianceImage,
    },
    {
      title: 'Conectividade',
      altText: 'Conectividade',
      description:
        'Computadores não precisam estar na mesma rede, apenas conectados à internet.',
      icon: benefitPreparacaoImage,
    },
    {
      title: 'Produtividade',
      altText: 'Produtividade',
      description:
        'Possibilidade de anonimizar e excluir dados em mais de um computador por vez.',
      icon: benefitProtecaoEmpresasImage,
    },
    {
      title: 'Conformidade',
      altText: 'Conformidade',
      description:
        'Auxilia no processo de descarte caso solicitado pelo titular de dados, previsto na LGPD.',
      icon: benefitGerenciamentoImage,
    },
  ],
}

export default dataDiscovery
