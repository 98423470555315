import React, { useEffect } from 'react'
import { Grid, Radio, RadioGroup, FormControlLabel } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { isEmpty } from 'lodash'

import ConsentPeopleForm from '../ConsentPeopleForm'
import ConsentForm from '../ConsentForm'

import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'

const ReportSelection = ({
  reportSelected,
  setReportSelected,
  setConsentForms,
  exportingPdf,
  consentForms,
  setExportingPdf,
  setLoadingConsentStats,
}) => {
  const snackbar = useSnackbar()
  const classes = useStyles()

  const { control, watch, errors, clearError } = useFormContext()

  useEffect(() => {
    if (exportingPdf && isEmpty(consentForms)) {
      setExportingPdf(false)
      return snackbar.open({
        message: 'Não existem consentimentos para gerar esse relatório',
        variant: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!exportingPdf])

  const handleChange = (event) => {
    setReportSelected(event.target.value)
  }

  return (
    <Grid item xs={12}>
      <form>
        <RadioGroup value={reportSelected} onChange={handleChange}>
          <FormControlLabel
            value="reportByPeople"
            control={<Radio classes={{ checked: classes.checked }} />}
            className={classes.radioLabel}
            label="Relatório por pessoa"
          />
          {reportSelected === 'reportByPeople' && (
            <ConsentPeopleForm
              control={control}
              watch={watch}
              errors={errors}
              clearError={clearError}
              setConsentForms={setConsentForms}
              setLoadingConsentStats={setLoadingConsentStats}
            />
          )}
          <FormControlLabel
            value="reportByForm"
            control={<Radio classes={{ checked: classes.checked }} />}
            className={classes.radioLabel}
            label="Relatório por Formulário"
          />
          {reportSelected === 'reportByForm' && (
            <Grid item xs={12}>
              <ConsentForm
                control={control}
                watch={watch}
                errors={errors}
                clearError={clearError}
                setConsentForms={setConsentForms}
              />
            </Grid>
          )}
          <FormControlLabel
            value="reportComplete"
            control={<Radio classes={{ checked: classes.checked }} />}
            className={classes.radioLabel}
            label="Relatório Completo (todos os formulários com todos os recibos)"
          />
        </RadioGroup>
      </form>
    </Grid>
  )
}

export default ReportSelection
