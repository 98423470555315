import * as yup from 'yup'

export const schemaUser = yup.object().shape({
  name: yup
    .string()
    .required()
    .max(280, 'O nome deve ter no máximo 280 caracteres'),
  description: yup
    .string()
    .required()
    .max(280, 'A descrição deve ter no máximo 280 caracteres'),
})

export default schemaUser
