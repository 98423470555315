import React from 'react'
import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core'

import { CompanyServiceForm, PartnerServiceCard } from '../'
import { ButtonAction, LoadingButton, LoadingFeedback } from 'components'

import helpers from 'helpers'

import styles from './styles'

import useAuth from 'hooks/useAuth'

import constants from 'constants/index'

const useStyles = makeStyles(styles)

const CompanyServiceDialog = ({
  open,
  onClose,
  partnerService,
  setIsLoading,
  isLoading,
}) => {
  const classes = useStyles()
  const { user, userCompany, company } = useAuth()

  const { mixpanel } = helpers

  const partnerName = partnerService?.partner?.name

  const mixPanelAction = () => {
    mixpanel.track('Marktplace', 'Modal de solicitação de serviço', {
      action: `Marketplace_Enviar_Solicitacao_Parceiro_${partnerName}`,
    })
  }

  return (
    <>
      {isLoading ? (
        <LoadingFeedback open={isLoading} />
      ) : (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
          <DialogTitle>
            <Box>
              <Typography variant="h4">Solicite o serviço</Typography>
            </Box>
          </DialogTitle>
          <Divider />
          <Box my={1}>
            <DialogContent>
              <Box marginBottom={3}>
                <PartnerServiceCard
                  partnerService={partnerService}
                  isDisabled
                />
              </Box>
              <CompanyServiceForm
                companyName={userCompany?.company?.name}
                companyId={userCompany?.company?.id}
                responsable={user?.name}
                email={user?.email}
                partnerServiceId={partnerService?.id}
                setIsLoading={setIsLoading}
              />
            </DialogContent>
          </Box>
          <Divider />
          <DialogActions p={2}>
            <Box
              p={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Button type="button" variant="outlined" onClick={onClose}>
                Cancelar
              </Button>
              {company?.statusId ===
              constants.userCompanies.TRIAL_LIMITED_STATUS ? (
                <ButtonAction
                  action={mixPanelAction}
                  className={classes.button}
                  variant="contained"
                  type="submit"
                  color="primary"
                  name="Enviar solicitação"
                />
              ) : (
                <LoadingButton
                  variant="contained"
                  type="submit"
                  color="primary"
                  text="Enviar solicitação"
                  loading={isLoading}
                  form="partner-service-form"
                />
              )}
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

CompanyServiceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  partnerService: PropTypes.object,
}

export default CompanyServiceDialog
