import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  stepper: {
    width: '45%',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  horizontalStep: {
    padding: 0,
  },
  alternativeLabel: {
    top: 19.5,
    [theme.breakpoints.down('sm')]: {
      top: 14,
      left: 'calc(-50% + 14px)',
      right: 'calc(50% + 14px)',
    },
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.secondary.main,
    },
  },
  stepperLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
    color: `${theme.colors.firstStep.text.secondary} !important`,
  },
}))

export default styles
