import dataMultiOptions from './dataMultiOptions'
import dataToSubmit from './dataToSubmit'
import defaultDataMultiOptions from './defaultDataMultiOptions'
import entitiesByCategory from './entitiesByCategory'
import idsToDestroy from './idsToDestroy'
import labels from './labels'
import loadDefaultDepartments from './loadDefaultDepartments'
import manageFragilities from './manageFragilities'
import manageOptions from './manageOptions'

const fragilityEntities = {
  dataMultiOptions,
  dataToSubmit,
  defaultDataMultiOptions,
  entitiesByCategory,
  idsToDestroy,
  labels,
  loadDefaultDepartments,
  manageFragilities,
  manageOptions,
}

export default fragilityEntities
