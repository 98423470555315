import helpers from 'helpers'
import components from '../../components'

import { GAP } from '../../constants'

import palette from 'theme/palette'

const treatment = async (pdf, date, { incident }) => {
  pdf.addPage('a4', 'p')
  components.subtitle(pdf, 'Tratamento', GAP)
  components.lineWithDate(pdf, date)

  const tableData = [
    ['ID', incident?.nonComplianceReport?.id || 'N/A'],
    ['Descrição (Causa)', incident?.nonComplianceReport?.cause || 'N/A'],
    [
      'Risco',
      incident?.nonComplianceReport?.specificRisks
        .map((riskSpecific) => riskSpecific?.description + '\n')
        .join('') || 'N/A',
    ],
    [
      'Prazo',
      helpers.formatters.date(incident?.nonComplianceReport?.deadlineDate) ||
        'N/A',
    ],
    [
      'Status',
      helpers.nonComplianceReports.status(
        incident?.nonComplianceReport?.status,
      ) || 'N/A',
    ],
  ]

  components.table(pdf, [], tableData, GAP * 1.5, 0.25)

  components.numberPage(pdf, palette.black)

  pdf.addPage('a4', 'p')
  components.subtitle(pdf, 'Plano de Ação', GAP)
  components.lineWithDate(pdf, date)

  if (incident?.nonComplianceReport?.nonComplianceReportActions?.length > 0) {
    const nonComplianceReport =
      incident.nonComplianceReport.nonComplianceReportActions.map((data) => {
        return [
          ['ID do plano de ação', data?.id],
          ['Responsável', data?.responsableUser?.name],
          [
            'Prazo do plano de ação',
            helpers.formatters.date(data?.deadlineDate),
          ],
          ['Ação Corretiva', data?.correctiveAction],
          ['Evidência', data?.correctionEvidence],
        ]
      })

    nonComplianceReport.forEach((data, index) => {
      components.lineWithDate(pdf, date)
      components.table(
        pdf,
        [],
        data,
        index === 0 ? GAP * 1.5 : pdf.lastAutoTable.finalY + 35,
        0.25,
      )
      components.numberPage(pdf, palette.black)
    })
  } else {
    const nonComplianceReport = [
      ['ID do plano de ação', 'N/A'],
      ['Responsável', 'N/A'],
      ['Prazo do plano de ação', 'N/A'],
      ['Ação Corretiva', 'N/A'],
      ['Evidência', 'N/A'],
    ]

    components.table(pdf, [], nonComplianceReport, GAP * 1.5, 0.25)
  }

  components.numberPage(pdf, palette.black)
}

export default treatment
