import React, { useState } from 'react'

import { Box, Typography, Button, makeStyles } from '@material-ui/core'

import { BaseConfirmationDialog } from 'components'

import { reverse } from 'named-urls'
import { routes } from 'Routes'

import * as service from 'service'
import history from 'service/history'
import constants from 'constants/index'

import helpers from 'helpers'
import useSnackbar from 'hooks/useSnackbar'

import styles from './styles'

const ProcessOnAuditAlert = ({ auditProcess, refresh }) => {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const snackbar = useSnackbar()

  const [baseConfirmationDialog, setBaseConfirmationDialog] = useState(false)

  const startDate = helpers.formatters.date(auditProcess.startDate)

  const endDate = helpers.formatters.date(auditProcess.endDate)

  const handleChoose = async (choose) => {
    try {
      await service.dponet.auditProcesses.chooseAction({
        auditProcessId: auditProcess.id,
        actionChoosed: choose,
      })
      if (choose === constants.auditProcess.WAITING_UPDATE_CHOOSE) {
        setBaseConfirmationDialog(false)
        return history.push(
          reverse(routes.dataProcess.edit, {
            dataProcessId: auditProcess.dataProcessId,
          }),
        )
      } else {
        refresh()
      }
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Box className={classes.alertBox}>
        <Box className={classes.text}>
          <Typography variant="subtitle1" className={classes.title}>
            Processo em auditoria!
          </Typography>
          <Typography variant="subtitle1" className={classes.description}>
            Este processo foi incluído na auditoria de processos que iniciou em
            {' ' + startDate} e termina em {endDate}. Você deve confirmar se o
            processo está atualizado, de acordo com a realidade da sua empresa,
            ou se é necessário editar o processo para alterar as informações.
          </Typography>
          <Button
            disableElevation
            className={classes.button}
            variant="outlined"
            onClick={() => setBaseConfirmationDialog(true)}
          >
            ESTÁ ATUALIZADO
          </Button>
          <Button
            disableElevation
            className={classes.button}
            variant="outlined"
            onClick={() => {
              handleChoose(constants.auditProcess.WAITING_UPDATE_CHOOSE)
            }}
          >
            NECESSÁRIO EDITAR
          </Button>
        </Box>
      </Box>
      <BaseConfirmationDialog
        variant="warning"
        open={baseConfirmationDialog}
        setOpen={setBaseConfirmationDialog}
        actionButton={() =>
          handleChoose(constants.auditProcess.CONFIRMED_CHOOSE)
        }
        actionButtonText="Confirmar"
        title="Auditoria de processo"
        text="Deseja confirmar que este processo está atualizado, de acordo com a realidade da empresa?"
      />
    </>
  )
}

export default ProcessOnAuditAlert
