import React from 'react'
import DOMPurify from 'dompurify'
import { Box, Typography } from '@material-ui/core'
import { User as UserIcon } from 'react-feather'

import { MessageAttachment } from './components'

import useStyles from './styles'
import helpers from 'helpers'

const TicketMessage = ({ message }) => {
  const classes = useStyles()

  const messageSanitizer = DOMPurify.sanitize(message?.content, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  })

  const messageClass = (organization) => {
    if (organization) return classes.responsibleHeader

    return classes.requesterHeader
  }

  return (
    <Box>
      <Box className={classes.requesterBoxContainer}>
        <Box className={messageClass(message?.author?.organization)}>
          <UserIcon className={classes.userIconBox} />
          <Box display="flex" alignItems="center">
            {!message?.author?.organization && (
              <Typography>{`${message?.author?.name} |`}</Typography>
            )}
            <Typography>
              &nbsp;{`${message?.author?.organization || ' Solicitante'} | `}
              {message?.author?.profile ? `${message?.author?.profile} | ` : ''}
              {helpers.formatters.date(message?.createdAt)} às&nbsp;
              {helpers.formatters.hours(message?.createdAt)}
            </Typography>
          </Box>
        </Box>

        <Box
          className={classes.contentRequesterLine}
          dangerouslySetInnerHTML={{
            __html: messageSanitizer,
          }}
        />

        {message?.attachments?.length > 0 && (
          <Box pb={2} pl={1} gap={3}>
            {message?.attachments?.map((attachment) => (
              <MessageAttachment key={attachment.url} attachment={attachment} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default TicketMessage
