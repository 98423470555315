import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Button,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import {
  Documents,
  Revision,
  GeneralInformation,
  DataLifeCycle,
  DataTreatment,
  DataCollected,
  NecessityProportionality,
  DataTreatmentAGent,
  FragilityEntities,
} from './components/New'
import { Page, ContentHeader, DriveTour } from 'components'
import constants from 'constants/index'
import Stepper from './components/New/Stepper'

import DataProcessTemplateQuestionProvider from 'providers/DataProcessTemplateQuestionProvider'

import useDataProcess from 'hooks/useDataProcess'
import useAuth from 'hooks/useAuth'

import helpers from 'helpers'
import useStyles from './styles'

const DataProcessesNew = () => {
  const getTextsSteps = () => {
    return [
      {
        title: 'Informações gerais',
        description:
          'Preencha os campos de informações gerais do tratamento de dados',
        step: 'Informações gerais',
        buttonId: 'id-newdataprocess-button-generalinformation',
      },
      {
        title: 'Dados tratados',
        description: 'Preencha os dados da atividade de tratamento',
        step: 'Dados tratados',
        buttonId: 'id-newdataprocess-button-datatreatments',
      },
      {
        title: 'Salvaguarda e ciclo de vida',
        description:
          'Preencha os campos de salvaguarda e ciclo de vida do tratamento de dados.',
        step: 'Salvaguarda e ciclo de vida',
        buttonId: 'id-newdataprocess-button-lifecircle',
      },
      {
        title: 'Dados compartilhados',
        description: 'Preencha os campos de compartilhamento de dados.',
        step: 'Dados compartilhados',
        buttonId: 'id-newdataprocess-button-shareddata',
      },
      {
        title: 'Agentes de tratamento',
        description: 'Preencha os campos de agentes de tratamento.',
        step: 'Agentes de tratamento',
        buttonId: 'id-newdataprocess-button-datatreatmentAgent',
      },
      {
        title: 'Análise de ameaças',
        description: 'Preencha os campos de análise de ameaças.',
        step: 'Análise de ameaças',
        buttonId: 'id-newdataprocess-button-fragiltyentities',
      },
      {
        title: 'Necessidade e proporcionalidade',
        description:
          'Indique possíveis alterações quantos aos dados tratados, forma de proteção e compartilhamento, sob o ponto de vista da necessidade e proporcionalidade.',
        step: 'Necessidade e proporcionalidade',
        buttonId: 'id-newdataprocess-button-necessityproportionality',
      },
      {
        title: 'Informação documentada',
        description:
          'Anexe o modelo/template do documento referente à atividade de tratamento. Não anexe documentos com dados pessoais.',
        step: 'Informação documentada',
        buttonId: 'id-newdataprocess-button-documentedinformation',
      },
      {
        title: 'Revisão',
        description:
          'Revise todos os campos preenchidos para confirmar que as informações correspondem à realidade do tratamento de dados.',
        step: 'Revisão',
        buttonId: 'id-newdataprocess-button-revision',
      },
    ]
  }

  const classes = useStyles()
  const steps = getTextsSteps().map((textStep) => textStep.step)
  const { company } = useAuth()
  const {
    lastActiveStep,
    activeStep,
    backStep,
    setToNextStep,
    loadOptions,
    counter,
    toNextStep,
    dataProcessTemplate,
    data,
  } = useDataProcess()

  const [openTour, setOpenTour] = useState(false)

  const handleGoNextStep = () => {
    if (toNextStep) return

    setToNextStep(true)
  }

  const handleOpenTour = () => {
    const where = 'Processos'
    const title = 'Tour'

    const params = {
      action: 'tour-novo-processo',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  useEffect(() => {
    loadOptions(company.id)
    // eslint-disable-next-line
  }, [counter])

  return (
    <Page title="Novo processo">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Novo processo">
          <Box className={classes.contentHeader}>
            <Tooltip title="Iniciar o tour guiado">
              <Box
                className={clsx(
                  classes.contentHeaderBox,
                  classes.contentHeaderBoxXs,
                )}
              >
                <Button
                  id={constants.dataProcess.DATA_PROCESS_NEW_DRIVER_STEP_9}
                  variant="contained"
                  color="primary"
                  startIcon={<HelpCircleIcon size={20} />}
                  onClick={handleOpenTour}
                  fullWidth
                >
                  Tutorial
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </ContentHeader>
        <Box display="flex" width="100%" height="auto">
          <Paper className={classes.fullCardWidth}>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              fontWeight={500}
            >
              <Typography variant="subtitle1">
                <Box fontWeight="bold">{getTextsSteps()[activeStep].title}</Box>
              </Typography>
              <Typography variant="subtitle1">
                Etapa {activeStep + 1} de 9
              </Typography>
            </Box>
            <Divider />
            <Grid container>
              <Grid item xl={9} lg={9} md={8} xs={12}>
                <Box width="100%" mt={2}>
                  {activeStep ===
                    constants.dataProcess.GENERAL_INFORMATION_STEP && (
                    <DataProcessTemplateQuestionProvider
                      dataProcessTemplate={dataProcessTemplate}
                      dataProcessTemplateAnswers={
                        data?.dataProcess?.dataProcessTemplateAnswers
                      }
                      step="general_information"
                    >
                      <GeneralInformation
                        description={getTextsSteps()[activeStep].description}
                      />
                    </DataProcessTemplateQuestionProvider>
                  )}
                  {activeStep === constants.dataProcess.DATA_COLLECTED_STEP && (
                    <DataCollected
                      description={getTextsSteps()[activeStep].description}
                    />
                  )}
                  {activeStep ===
                    constants.dataProcess.DATA_LIFE_CICLE_STEP && (
                    <DataLifeCycle
                      description={getTextsSteps()[activeStep].description}
                    />
                  )}
                  {activeStep === constants.dataProcess.DATA_TREATMENT_STEP && (
                    <DataTreatment
                      description={getTextsSteps()[activeStep].description}
                    />
                  )}
                  {activeStep ===
                    constants.dataProcess.DATA_TREATMENT_AGENT_STEP && (
                    <DataTreatmentAGent
                      description={getTextsSteps()[activeStep].description}
                    />
                  )}
                  {activeStep ===
                    constants.dataProcess.FRAGILITY_ENTITIES_STEP && (
                    <FragilityEntities
                      description={getTextsSteps()[activeStep].description}
                    />
                  )}
                  {activeStep ===
                    constants.dataProcess.NECESSITY_PROPORTIONALITY_STEP && (
                    <NecessityProportionality
                      description={getTextsSteps()[activeStep].description}
                    />
                  )}
                  {activeStep === constants.dataProcess.DOCUMENT_STEP && (
                    <Documents
                      description={getTextsSteps()[activeStep].description}
                    />
                  )}
                  {activeStep === constants.dataProcess.REVISION_STEP && (
                    <Revision
                      description={getTextsSteps()[activeStep].description}
                    />
                  )}
                </Box>
              </Grid>

              <Grid item xl={3} lg={3} md={4} xs={12}>
                <Stepper lastActiveStep={lastActiveStep} steps={steps}>
                  <Box p={1}>
                    <Grid
                      container
                      display="flex"
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Button
                        variant="outlined"
                        onClick={backStep}
                        className={classes.button}
                        disabled={activeStep === 0}
                      >
                        VOLTAR
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<CheckIcon />}
                        onClick={handleGoNextStep}
                        id={getTextsSteps()[activeStep].buttonId}
                        disabled={toNextStep}
                      >
                        {activeStep === steps.length - 1 ? 'SALVAR' : 'PRÓXIMO'}
                      </Button>
                    </Grid>
                  </Box>
                </Stepper>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
      <DriveTour
        stepsMatrix={helpers.dataProcess.driverJs.chooseStepsObject(activeStep)}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default DataProcessesNew
