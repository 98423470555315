const styles = (theme) => ({
  drawer: {
    width: 256,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    marginTop: 64,
    height: 'calc(100% - 64px)',
    background: 'transparent',
    border: 0,
    backgroundColor: theme.palette.layout.main,
  },
  drawerOpen: {
    width: 256,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
})

export default styles
