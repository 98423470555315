import { colors } from '@material-ui/core'

const styles = (theme) => ({
  linkButton: {
    color: colors.lightBlue[500],
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  linkText: {
    color: colors.lightBlue[500],
    fontWeight: 700,
  },
  iconColor: {
    color: theme.palette.icon,
  },
})

export default styles
