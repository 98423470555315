import { useContext } from 'react'

import CompanyFragilityContext from 'contexts/CompanyFragilityContext'

const useCompanyFragility = () => {
  const context = useContext(CompanyFragilityContext)

  if (context === undefined) {
    throw new Error(
      'useCompanyFragility must be used within a CompanyFragilityContext.Provider',
    )
  }

  return {
    companyFragility: context.companyFragility,
    fragilitiesSelected: context.fragilitiesSelected,
    fragilityEntities: context.fragilityEntities,
    loadCompanyFragility: context.loadCompanyFragility,
    loading: context.loading,
    loadProcessesOptions: context.loadProcessesOptions,
    measuresSelected: context.measuresSelected,
    questionControls: context.questionControls,
    setFragilitiesSelected: context.setFragilitiesSelected,
    setFragilityEntities: context.setFragilityEntities,
    setQuestionControls: context.setQuestionControls,
    setMeasuresSelected: context.setMeasuresSelected,
  }
}

export default useCompanyFragility
