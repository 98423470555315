const styles = (theme) => ({
  titleContainer: {
    paddingTop: theme.spacing(3),
  },
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  button: {
    padding: 'none',
  },
  buttonLabel: {
    textTransform: 'capitalize',
  },
  buttonTitle: {
    paddingLeft: '5px',
  },
  textTableRight: {
    textAlign: 'center',
  },
  actionHeader: {
    paddingRight: '70px',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperModal: {
    margin: theme.spacing(1),
    padding: theme.spacing(2, 2, 3),
    outline: 'none',
  },
  tableContainerModal: {
    marginTop: theme.spacing(2),
    maxHeight: 'auto',
  },
  iconMargin: {
    margin: theme.spacing(1),
  },
  nameLifeCycle: {
    height: '30px',
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'rigth',
  },
})

export default styles
