import React from 'react'
import { Box, Button, makeStyles } from '@material-ui/core'
import {
  ChevronsLeft as ChevronsLeftIcon,
  ChevronsRight as ChevronsRightIcon,
} from 'react-feather'

import styles from './styles'

import useDrawer from 'hooks/useDrawer'

const useStyles = makeStyles(styles)

const HelpCentralBox = () => {
  const classes = useStyles()
  const {
    handleFixDrawer,
    handleUnfixDrawer,
    fixedDrawer,
    openedDrawer,
    handleCloseDrawer,
    showEntireMenu,
  } = useDrawer()

  const openOrCloseDrawer = () => {
    if (openedDrawer) return handleCloseDrawer()
    if (fixedDrawer) return handleUnfixDrawer()

    handleFixDrawer()
  }

  return (
    <Box
      onClick={openOrCloseDrawer}
      component={Button}
      className={classes.root}
    >
      {showEntireMenu() ? (
        <ChevronsLeftIcon className={classes.icon} size="20" />
      ) : (
        <ChevronsRightIcon className={classes.icon} size="20" />
      )}
    </Box>
  )
}

export default HelpCentralBox
