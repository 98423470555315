const gapCharts = (statsGap, gapPanels, gapTabs, permitted) => {
  return {
    title: 'RMCs de questionário',
    visible: permitted('view_dashboard_gap'),
    type: 'gap',
    tabs: [
      {
        value: 0,
        label: 'Todos',
      },
      ...gapTabs,
    ],
    tabsPanels: [
      {
        value: 0,
        id: 'gap-0',
        data: statsGap?.questionnaires
          ?.map((questionnaire) => questionnaire.rncs)
          ?.reverse(),
        labels: statsGap?.questionnaires
          ?.map((questionnaire) => questionnaire.name)
          ?.reverse(),
        items:
          statsGap?.questionnaires?.map((questionnaire) => ({
            title: questionnaire.name,
            subtitle: questionnaire.rncs,
          })) || [],
      },
      ...gapPanels,
    ],
  }
}

export default gapCharts
