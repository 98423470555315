import React, { useState } from 'react'

import * as service from 'service'
import DataLifeCycleContext from 'contexts/DataLifeCycleContext'

const DataLifeCycleProvider = ({ children, dataLifeCycles, dataProcessId }) => {
  const [dataLifeCycle, setDataLifeCycle] = useState(dataLifeCycles)
  const [isLoading, setLoading] = useState(false)

  const loadDataLifeCycle = async () => {
    setLoading(true)
    const response = await service.dponet.dataLifeCycles.get({
      dataProcessId,
    })

    setDataLifeCycle(response.data.lifeCycles)
    setLoading(false)
  }

  return (
    <DataLifeCycleContext.Provider
      value={{
        dataLifeCycle,
        setDataLifeCycle,
        loadDataLifeCycle,
        isLoading,
        dataProcessId,
      }}
    >
      {children}
    </DataLifeCycleContext.Provider>
  )
}

export default DataLifeCycleProvider
