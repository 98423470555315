import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  progressColorGreen: {
    backgroundColor: theme.palette.greenPantone,
  },
  progressColorRed: {
    backgroundColor: theme.palette.redErrorDark,
  },
  progressColorOrange: {
    backgroundColor: theme.palette.yellowOrange,
  },
  linearProgressRoot: {
    backgroundColor: colors.grey[300],
    paddingBottom: 7,
    borderRadius: 5,
  },
  materialCardRoot: {
    height: 182.3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardContent: {
    padding: theme.spacing(0, 2.5),
  },
  cardActions: {
    marginTop: 20,
    marginBottom: 5,
    marginLeft: 2,
  },
  title: {
    [theme.breakpoints.down(1550)]: {
      minHeight: 52,
    },
  },
  cardButton: {
    fontSize: 14,
    fontWeight: 530,
    letterSpacing: 1.5,
  },
}))

export default styles
