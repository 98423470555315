import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  h3: {
    fontWeight: 600,
  },
  title: {
    color: theme.palette.text.menuTitle,
    cursor: 'pointer',
  },
  menu: {
    color: theme.palette.dashboard.text,
  },
  menuNavigation: {
    color: theme.palette.text.subtitle2,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  textLighten: {
    color: theme.palette.common.white,
  },
}))

export default useStyles
