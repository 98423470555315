import { Button, IconButton } from '@material-ui/core'
import { MenuButton } from 'components'
import React from 'react'
import { Eye as EyeIcon, PlusCircle as PlusCircleIcon } from 'react-feather'

const ActionButton = ({ mode, children, onClick }) => {
  return (
    <>
      {mode === 'edit' && (
        <MenuButton>
          <Button color="secondary" fullWidth onClick={onClick}>
            Editar
          </Button>
          {children}
        </MenuButton>
      )}

      {mode === 'show' && (
        <IconButton onClick={onClick}>
          <EyeIcon size={20} />
        </IconButton>
      )}

      {mode === 'create' && (
        <Button
          onClick={onClick}
          variant="outlined"
          size="small"
          startIcon={<PlusCircleIcon size={20} />}
        >
          Adicionar dados tratados
        </Button>
      )}
    </>
  )
}

export default ActionButton
