const styles = (theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
  },
  boxButton: {
    display: 'flex',
    justifyContent: 'end',
  },
  collapse: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.text.backgroundInputColor,
  },
  collapseLight: {
    backgroundColor: theme.palette.white,
  },
  iconRemove: {
    color: theme.palette.error.main,
  },
})
export default styles
