import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from '@material-ui/core'

import constants from 'constants/index'
import { Label } from 'components'

import { Controller, useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'
import schema from './schema'

const DialogChange = ({ open, setOpen, refresh, supplierData }) => {
  const snackbar = useSnackbar()

  const aproved =
    supplierData?.action === constants.companySuppliers.APPROVED_STATUS

  const isRequired = aproved && !supplierData?.email

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema(isRequired),
    defaultValues: {
      email: '',
    },
  })

  const acceptedOrDeclined = async (email) => {
    await service.dponet.suggestedSuppliers.acceptedOrDeclined({
      supplierSuggestionId: supplierData?.id,
      supplier: { email: email, status: supplierData?.action },
    })
  }

  const handleReproved = async () => {
    try {
      await acceptedOrDeclined(supplierData?.email)
      snackbar.open({
        message: `Organização reprovada como fornecedora!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          `Falha em reprovar a organização!`,
        variant: 'error',
      })
    } finally {
      refresh()
      setOpen(false)
    }
  }

  const onSubmit = async (data) => {
    try {
      if (aproved) {
        await acceptedOrDeclined(supplierData?.email || data?.email)
        refresh()
      }

      setOpen(false)
      snackbar.open({
        message: `Organização ${
          aproved ? 'aprovada' : 'reprovada'
        } como fornecedora!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          `Falha em ${aproved ? 'aprovar' : 'reprovar'} a organização!`,
        variant: 'error',
      })
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={false}>
      <DialogTitle>
        {aproved ? 'Aprovar ' : 'Reprovar '} a sugestão de fornecedor
      </DialogTitle>
      <Box minWidth="40vw">
        <DialogContent dividers>
          {!supplierData?.email && aproved ? (
            <Label title="Para aprovar essa organização como fornecedora, é necessário fornecer o e-mail do responsável">
              <form onSubmit={handleSubmit(onSubmit)} id="suggestedForm">
                <Controller
                  as={
                    <TextField
                      label="E-mail do responsável"
                      color="primary"
                      variant="outlined"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="email"
                  mode="onChange"
                />
              </form>
            </Label>
          ) : (
            <Typography>
              Você tem certeza de que deseja {aproved ? 'aprovar' : 'reprovar'}{' '}
              essa organização como fornecedora?
            </Typography>
          )}
        </DialogContent>
      </Box>
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        {aproved ? (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            form="suggestedForm"
          >
            Salvar
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleReproved}
          >
            Salvar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

DialogChange.propTypes = {
  refresh: PropTypes.func,
  supplierData: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

DialogChange.defaultProps = {
  setOpen: () => {},
  refresh: () => {},
}

export default DialogChange
