import { colors } from '@material-ui/core'

const styles = (theme) => ({
  link: {
    color: colors.blue[500],
    fontWeight: 700,
  },
})

export default styles
