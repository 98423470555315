import React from 'react'
import PropTypes from 'prop-types'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core'

import { ChevronDown as ExpandMoreIcon } from 'react-feather'

import helpers from 'helpers'

import useStyles from './styles'

const FaqsSection = ({ faqs }) => {
  const classes = useStyles()

  const { mixpanel } = helpers

  const mixpanelTrack = (faqTitle) => {
    mixpanel.track('Marketplace', 'Tela de Início', {
      action: `Marketplace_perguntas_${faqTitle}`,
    })
  }

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Perguntas frequentes</Typography>
      <Box className={classes.cardsContainer}>
        {faqs.map((faq, key) => (
          <Accordion
            classes={{
              root: classes.card,
            }}
            key={key}
            onChange={() => mixpanelTrack(faq.title.replaceAll(/ /g, '_'))}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.question}>{faq.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.answer}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  )
}

FaqsSection.propTypes = {
  faqs: PropTypes.array.isRequired,
}

export default FaqsSection
