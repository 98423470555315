import { colors } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  avatar: {
    height: 110,
    width: 110,
    marginRight: theme.spacing(1),
    boxShadow: `0px 0px 8px -2px ${colors.grey[500]}`,
    padding: theme.spacing(2),
    border: `10px solid ${colors.common.white}`,
  },
  img: {
    width: 90,
    height: 90,
    objectFit: 'contain',
    backgroundColor: theme.palette.background.default,
  },
}))

export default styles
