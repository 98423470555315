import * as yup from 'yup'

export const schema = yup.object().shape({
  startDate: yup.mixed().when('responsibleId', {
    is: (responsible) => !!responsible,
    then: yup
      .date()
      .typeError('Preencha a data do início')
      .required('Preencha a data do início'),
  }),
  dueDate: yup.mixed().when('responsibleId', {
    is: (responsible) => !!responsible,
    then: yup
      .date()
      .typeError('Preencha o prazo da atividade')
      .required('Preencha o prazo da atividade'),
  }),
  name: yup.string().max(120).required(),
  responsibleId: yup.string().nullable(),
  description: yup.string().required().max(1200),
  observations: yup.string().max(250),
})

export default schema
