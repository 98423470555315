import React from 'react'

import useDataCollected from 'hooks/useDataCollected'
import useDataTreatment from 'hooks/useDataTreatment'
import useDataLifeCycle from 'hooks/useDataLifeCycle'

import { Box, Grid, CircularProgress, Typography } from '@material-ui/core'

import TableNecPropDataCollected from '../../TableNecPropDataCollected'
import TableNecPropDataTreatments from '../../TableNecPropDataTreatments'
import TableNecPropDataLifeCycle from '../../TableNecPropDataLifeCycle'

const NecessityProportionality = ({ isLoading, dataProcess }) => {
  const { data: dataCollecteds, isLoading: dataCollectedLoading } =
    useDataCollected()
  const { data: dataTreatments, isLoading: dataTreatmentLoading } =
    useDataTreatment()
  const { dataLifeCycle, isLoading: dataLifeCycleLoading } = useDataLifeCycle()

  return (
    <>
      <Grid container>
        {isLoading ||
        dataCollectedLoading ||
        dataTreatmentLoading ||
        dataLifeCycleLoading ? (
          <Box
            id="loadBox"
            display="flex"
            width="100%"
            minHeight="700px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box my={3}>
              <Typography variant="h5">
                Necessidade e Proporcionalidade
              </Typography>
            </Box>
            <Box height="auto" width="100%">
              <Box width="100%" mb={3}>
                {dataCollecteds && (
                  <TableNecPropDataCollected dataCollecteds={dataCollecteds} />
                )}
              </Box>
              {dataTreatments && (
                <Box width="100%" mb={3}>
                  <TableNecPropDataTreatments dataTreatments={dataTreatments} />
                </Box>
              )}
              {dataLifeCycle && (
                <Box width="100%" mb={3}>
                  <TableNecPropDataLifeCycle dataLifeCycles={dataLifeCycle} />
                </Box>
              )}
            </Box>
          </>
        )}
      </Grid>
    </>
  )
}

export default NecessityProportionality
