import { makeStyles } from '@material-ui/core'

import Polygon from 'images/Polygon_2.svg'

const styles = makeStyles((theme) => ({
  paperBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 10,
    marginBottom: 10,
    cursor: 'pointer',
  },
  boxInfos: {
    width: '80%',
    color: theme.palette.blueDark,
    marginInline: 5,

    [theme.breakpoints.down('xs')]: {
      marginLeft: 10,
    },
  },
  avatarBox: {
    width: 40,
    height: 43,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundImage: `url(${Polygon})`,
    backgroundRepeat: 'no-repeat',
  },
  avatarIcon: {
    padding: 10,
    marginTop: 3,
    color: theme.palette.dashboard.main,
  },
  arrowIcon: {
    padding: 7,
    color: theme.palette.secondary.main,
  },
  lineTitle: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  lineDesc: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 14,
    fontWeight: 400,

    [theme.breakpoints.down('xs')]: {
      maxWidth: '35ch',
    },
  },
}))

export default styles
