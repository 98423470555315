import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Box,
  Collapse,
  Grid,
  Paper,
  IconButton,
  Typography,
} from '@material-ui/core'

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons/'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import useStyles from './styles'

import helpers from 'helpers'

const TableNotificationsCollapse = ({ notifiedUsers, open = false }) => {
  const classes = useStyles()

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box margin={1} className={classes.collapseBackground}>
        <Box mx={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.justifyHeight}>
                <Box
                  display="flex"
                  justifyContent="center"
                  p={1}
                  flexWrap="wrap"
                >
                  <Box width="100%" display="flex" justifyContent="center">
                    <Typography variant="h6">Tipo de registro</Typography>
                  </Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    <Typography variant="body1">
                      Disparo de e-mail de notificação de atualização do
                      processo
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box my={2} ml={2}>
          <Typography variant="h6" gutterBottom component="div">
            Notificações
          </Typography>
        </Box>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="33%">Informação</TableCell>
              <TableCell width="33%">Usuário</TableCell>
              <TableCell width="33%">E-mail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifiedUsers?.map((notifiedUser, index) => (
              <TableRow key={index}>
                <TableCell>Notificado</TableCell>
                <TableCell>{notifiedUser?.name}</TableCell>
                <TableCell>{notifiedUser?.email}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Collapse>
  )
}

TableNotificationsCollapse.propTypes = {
  notifiedUsers: PropTypes.array.isRequired,
  open: PropTypes.bool,
}

const TableRowNotification = ({ notification, ...rest }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const handleOpenColapse = () => setOpen(!open)

  return (
    <>
      <TableRow className={classes.root} {...rest}>
        <TableCell>
          <IconButton size="small" onClick={handleOpenColapse}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {helpers.formatters.date(notification?.createdAt)}
          </Typography>
          <Box pt={1}>
            <Typography variant="body2" color="secondary">
              {helpers.formatters.hours(notification?.createdAt)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Typography variant="body2">Ação automática do sistema</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={3} className={classes.noCellPadding}>
          <TableNotificationsCollapse
            notifiedUsers={notification?.notifiedEntities?.users}
            open={open}
            setOpen={setOpen}
          />
        </TableCell>
      </TableRow>
    </>
  )
}

TableRowNotification.propTypes = {
  notification: PropTypes.object.isRequired,
}

const NotificationsTable = ({ notifications }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar>
        <Table size="medium" noWrap stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="10%" />
              <TableCell width="40%">Data</TableCell>
              <TableCell width="50%">Autor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications?.map((notification, index) => (
              <TableRowNotification key={index} notification={notification} />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

NotificationsTable.propTypes = {
  notifications: PropTypes.array.isRequired,
}

export default NotificationsTable
