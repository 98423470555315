import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { AddressForm } from '../'
import { FormAccessData } from 'components/PreRegistration'
import { LoadingFeedback } from 'components'
import { MainForm } from './components'

import useSubscription from 'hooks/useSubscription'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'
import schema from './schema'
import { TOKEN_PRE_REGISTRATION } from 'service/constants'
import constants from 'constants/index'
import { routes } from 'Routes'

const FormPersonal = () => {
  const {
    coupon,
    currentKind,
    data,
    isDemoSubscription,
    recaptcha,
    resetRecaptcha,
    responsibleData,
    selectedPlan,
    selectedSegment,
    setData,
    setValidForm,
    toNextStep,
    setCurrentDocument,
  } = useSubscription()
  const history = useHistory()

  const [inputTimeout, setInputTimeout] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const isEdit = localStorage.getItem(TOKEN_PRE_REGISTRATION) && data
  const isPersonal = data?.kind === 'P' || !!data?.cpf

  const formMethods = useForm({
    validationSchema: schema(isDemoSubscription),
    reValidateMode: 'onBlur',
    defaultValues: {
      name: isPersonal ? data?.name || '' : '',
      description: isPersonal ? data?.description || '' : '',
      cpf: isPersonal ? helpers.formatters.cpf(data?.document) || '' : '',
      email: isPersonal ? data?.email || '' : '',
      phone: isPersonal ? data?.phone || '' : '',
      street: data?.address?.street || '',
      number: data?.address?.number || '',
      neighborhood: data?.address?.neighborhood || '',
      complement: data?.address?.complement || '',
      postalCode: data?.address?.postalCode || '',
      city: data?.address?.city || '',
      country: data?.address?.country || 'Brasil',
      state: data?.address?.state || '',
      ...(isDemoSubscription && {
        responsibleName: data?.responsibleUser?.name || '',
        responsibleEmail: data?.responsibleUser?.email || '',
        responsiblePhone: data?.responsibleUser?.phone || '',
      }),
    },
  })
  const snackbar = useSnackbar()

  const { getValues, handleSubmit, errors, clearError, setError } = formMethods

  const verifyDocument = (value) => {
    if (inputTimeout) clearTimeout(inputTimeout)
    setInputTimeout(
      setTimeout(async () => {
        if (value === '') {
          clearError('cpf')
          setCurrentDocument('')
          return
        }
        try {
          await service.dponet.companies.verifyDocument(
            helpers.formatters.onlyNumbers(getValues('cpf')),
          )
          clearError('cpf')
        } catch ({ response }) {
          if (response?.status === 404) {
            clearError('cpf')
          } else {
            setError('cpf', {
              type: {
                notMatch:
                  response?.data?.error?.message ||
                  'Este documento já está em uso, portanto não é possível realizar a contratação.',
              },
            })
          }
          setCurrentDocument(getValues('cpf'))
        }
      }, 1250),
    )
  }

  const mountPreRegistrationParams = () => {
    const values = getValues()
    const couponValue = coupon?.value || coupon?.code || coupon || ''

    return {
      preRegistration: {
        recaptcha,
        name: values?.name,
        discountCouponCode: couponValue,
        document: values?.cpf,
        kind: currentKind,
        phone: values?.phone,
        email: values?.email,
        plan_id: selectedPlan?.id,
        type: constants.preRegistrations.REAL_TYPE,
        membership_registration: isDemoSubscription && {
          segmentId: selectedSegment,
        },
        address: {
          postal_code: values?.postalCode,
          country: values?.country || 'Brasil',
          state: values?.state,
          city: values?.city,
          complement: values?.complement,
          neighborhood: values?.neighborhood,
          street: values?.street,
          number: values?.number,
        },
        responsibleUser: {
          name: values?.responsibleName,
          phone: values?.responsiblePhone,
          email: values?.responsibleEmail,
          ...responsibleData,
        },
      },
    }
  }

  const invalidateForm = () => {
    setValidForm(false)

    if (selectedPlan)
      return snackbar.open({
        message: 'Erro ao realizar o pré-cadastro.',
        variant: 'error',
      })

    snackbar.open({
      message: 'Por favor, selecione um plano para sua empresa.',
      variant: 'error',
    })
  }
  const handleSubmitAction = async () => {
    try {
      setIsLoading(true)
      if (!isEmpty(errors) || !currentKind || !selectedPlan) {
        invalidateForm()
      } else {
        setValidForm(true)
        const payload = mountPreRegistrationParams()

        var response

        if (isEdit) {
          response = await service.dponet.preRegistrations.put(payload)
        } else {
          response = await service.dponet.preRegistrations.create(payload)
        }

        if (isDemoSubscription) {
          history.push(routes.subscription.demoFinish)
        } else {
          if (!isEdit)
            localStorage.setItem(
              TOKEN_PRE_REGISTRATION,
              response?.data?.preRegistration?.token,
            )

          setData(response?.data?.preRegistration)
          toNextStep()
        }
      }
    } catch (error) {
      resetRecaptcha()
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro, tente novamente mais tarde.',
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitAction)} id="form-submit-main">
      <LoadingFeedback open={isLoading} />
      <Box mb={2.5}>
        <Typography variant="h5">Dados cadastrais</Typography>
      </Box>
      <MainForm formMethods={formMethods} verifyDocument={verifyDocument} />
      <Box mt={5} mb={2.5}>
        <Typography variant="h5">Endereço</Typography>
      </Box>
      <AddressForm formMethods={formMethods} />
      {isDemoSubscription && (
        <FormAccessData isDemoSubscription demoFormMethods={formMethods} />
      )}
    </form>
  )
}

export default FormPersonal
