import { useState } from 'react'

import useSnackbar from 'hooks/useSnackbar'

const useClipboard = () => {
  const [isLoading, setIsLoading] = useState(false)

  const snackbar = useSnackbar()

  const snackbarSuccess = (
    message = 'Conteúdo copiado para a área de transferência',
  ) =>
    snackbar.open({
      message: message,
      variant: 'success',
    })

  const snackbarError = (
    message = 'Ocorreu uma falha inesperada ao tentar copiar o conteúdo',
  ) =>
    snackbar.open({
      message: message,
      variant: 'error',
    })

  const copy = (value) => {
    try {
      setIsLoading(true)
      navigator.clipboard.writeText(value)

      snackbarSuccess()
    } catch (error) {
      snackbarError()
    } finally {
      setIsLoading(false)
    }
  }

  return {
    copy,
    isLoading,
  }
}

export default useClipboard
