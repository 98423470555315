const styles = (theme) => ({
  container: {
    marginTop: -25,
  },
  itemContainer: {
    cursor: 'pointer',
    backgroundColor: theme.palette.marketplace.banner.cookies,
    borderRadius: 5,
  },
  imageContainer: {
    height: 170,
    objectPosition: 'center',
  },
  banner: {
    objectFit: 'scale-down',
  },
})

export default styles
