import React from 'react'
import { routes } from 'Routes'
import { useHistory } from 'react-router-dom'
import { Controller, FormContext, useForm } from 'react-hook-form'
import { TextField, Grid, Box } from '@material-ui/core'

import { FileUploadInput, Label } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'
import schema from './schema'

const ConsentRevocationForm = ({ setLoading, consent }) => {
  const snackbar = useSnackbar()
  const history = useHistory()

  const formMethods = useForm({
    validationSchema: schema,
    defaultValues: {
      document: undefined,
    },
  })

  const { control, errors, handleSubmit } = formMethods

  const onSubmit = async (data) => {
    setLoading(true)

    try {
      await service.dponet.consent.changeReceiptStatus({
        consentFormId: consent?.consentForm?.id,
        consentFormAnswerId: consent?.id,
        data: data,
      })

      snackbar.open({
        message: 'Consentimento revogado com sucesso!',
        variant: 'success',
      })

      history.push(routes.consent.consentReceipt)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormContext {...formMethods}>
      <form id="consent-revocation-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <Label title="Observação do Consentimento" />
            <Controller
              as={
                <TextField
                  fullWidth
                  multiline
                  error={!!errors.observation}
                  helperText={errors?.observation?.message}
                />
              }
              control={control}
              name="observation"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12}>
            <Box marginTop={1}>
              <Label title="Upload de documento" />
            </Box>
            <FileUploadInput
              title="Arraste e solte ou selecione o documento a ser anexado"
              controlName="document"
              accept={constants.consent.SUPPORTED_DOCUMENT_FILE_INPUT_TYPES}
              multiple
            />
          </Grid>
        </Grid>
      </form>
    </FormContext>
  )
}

export default ConsentRevocationForm
