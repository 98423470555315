import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundColor: theme.colors.firstStep.secondary,
    border: 'none',
    borderRadius: 20,
    textAlign: 'center',
  },
  modalTextGrid: {
    paddingInline: '18px',
    marginBlock: '10px',
  },
  textFormat: {
    fontFamily: 'Raleway',
    color: 'white',
    fontSize: theme.typography.pxToRem(54),
    fontWeight: 700,
    '& span': {
      backgroundColor: theme.palette.subscription.warning.error,
    },
  },
  titleText: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(26),
    textAlign: 'left',
    padding: '12px 35px',
  },
  bodyText: {
    fontSize: theme.typography.pxToRem(20),
    paddingInline: '8px',
    lineHeight: 1.1,
    display: 'flex',
    alignItems: 'center',
  },
  textSpacing: {
    minHeight: 170,
  },
  dponetDivider: {
    textAlign: 'center',
    backgroundColor: theme.palette.subscription.warning.success,
    padding: 0,
    display: 'inline',
  },
  description: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.subscription.warning.success,
    lineHeight: 100,
  },
  button: {
    width: 325,
    borderRadius: 29,
    fontSize: 18,
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    boxShadow: `0px 0px 12px ${theme.colors.firstStep.button.primary}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.colors.firstStep.button.primary,
    },
    backgroundColor: theme.colors.firstStep.button.primary,
    borderColor: theme.colors.firstStep.button.primary,
    color: theme.palette.common.white,
    textTransform: 'none',
    justifyContent: 'center',
  },
  outlined: {
    boxShadow: 'none',
    border: '2px solid',
    borderColor: theme.palette.common.white,
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginTop: 10,
  },
  divider: {
    width: '100%',
  },
}))

export default styles
