import components from '../../components'
import palette from 'theme/palette'

import { GAP, PADDING } from '../../constants'
import { cleanConsentContent, customSubtitle, ratio } from '../../helpers'

import constants from 'constants/index'
import helpers from 'helpers'

const consentsComplete = (
  pdf,
  dateNow,
  consentFormArray = [],
  customStyles,
  reportSelected,
) => {
  const { STATUS_LABEL_RECEIPT } = constants.consent
  const { RECEIPT_COMPLETE, RECEIPT_PERSONAL } =
    constants.reports.TYPES_CONSENTS

  const firstTableColsStyle = {
    0: {
      halign: 'left',
    },
  }

  let totalAuthorized = 0
  let totalRevoked = 0
  let totalAnswers = 0
  let startY = 0

  consentFormArray.forEach((consentForm, formIndex) => {
    const { answersCollected = { authorized: 0, revoked: 0, total: 0 } } =
      consentForm

    totalAuthorized += answersCollected.authorized
    totalRevoked += answersCollected.revoked
    totalAnswers += answersCollected.total

    if (reportSelected === RECEIPT_PERSONAL ? formIndex === 0 : true) {
      pdf.addPage()
      components.subtitle(
        pdf,
        customSubtitle(reportSelected, consentForm),
        GAP,
        palette.primary.hover,
      )
    }

    components.lineWithDate(pdf, `Consentimentos | ${dateNow}`)
    components.numberPage(pdf, palette.primary.main, true)

    components.rectangle(
      pdf,
      PADDING,
      GAP * 1.25,
      ratio(470),
      palette.primary.main,
      ratio,
      'Total de consentimentos',
      reportSelected === RECEIPT_COMPLETE
        ? answersCollected.total
        : totalAnswers,
    )

    components.rectangle(
      pdf,
      PADDING * 3.5,
      GAP * 1.25,
      ratio(470),
      palette.primary.main,
      ratio,
      'Autorizados',
      reportSelected === RECEIPT_COMPLETE
        ? answersCollected.authorized
        : totalAuthorized,
    )

    components.rectangle(
      pdf,
      PADDING * 6,
      GAP * 1.25,
      ratio(470),
      palette.primary.main,
      ratio,
      'Revogados',
      reportSelected === RECEIPT_COMPLETE
        ? answersCollected.revoked
        : totalRevoked,
    )

    const consentFormAnswers = consentForm?.consentFormAnswers || []

    consentFormAnswers.forEach((consent, index) => {
      if (reportSelected === RECEIPT_COMPLETE) {
        startY = index === 0 ? GAP * 3 : pdf.autoTable.previous.finalY + 16
      } else {
        startY =
          index === 0 && formIndex === 0
            ? GAP * 3
            : pdf.autoTable.previous.finalY + 16
      }

      const generatePlanRows = () => [
        ['Formulário', consentForm?.title || 'N/A'],
        ['Status', STATUS_LABEL_RECEIPT[consent?.status] || 'N/A'],
        ['Consentido em', helpers.formatters.date(consent?.createdAt) || 'N/A'],
        ['Nome', consent?.answers?.nomeCompleto || 'N/A'],
        ['E-mail', consent.userEmail || 'N/A'],
      ]

      components.table(
        pdf,
        null,
        generatePlanRows(),
        startY,
        0.25,
        PADDING,
        true,
      )

      components.table(
        pdf,
        null,
        [[cleanConsentContent(consentForm?.content)]],
        pdf.lastAutoTable.finalY,
        0.25,
        PADDING,
        true,
        firstTableColsStyle,
      )
    })
  })
}

export default consentsComplete
