import React, { Fragment } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from '@material-ui/core'

import theme from 'theme'
import useStyles from './styles'
import constants from 'constants/index'

const PositionCustomization = () => {
  const classes = useStyles()

  const { control, errors, watch } = useFormContext()

  const xlUp = useMediaQuery(theme.breakpoints.up('xl'), {
    defaultMatches: true,
  })

  const position = watch('position')

  return (
    <Fragment>
      <Box>
        {
          <Controller
            as={
              <FormControl error={!!errors?.position}>
                <FormLabel className={classes.formLabel} color="primary">
                  Defina o posicionamento do banner de cookies no seu site:
                </FormLabel>
                <RadioGroup value={position}>
                  {constants.cookies.banner.CUSTOMIZATION_POSITION_RADIO_GROUP_ITEMS.map(
                    (item, index) => (
                      <Box>
                        <FormControlLabel
                          key={index}
                          control={<Radio color="primary" />}
                          value={item.value}
                          label={item.label}
                        />
                      </Box>
                    ),
                  )}
                </RadioGroup>
                {!!errors?.position && (
                  <FormHelperText error={!!errors?.position}>
                    {<>{errors?.position?.message}</>}
                  </FormHelperText>
                )}
              </FormControl>
            }
            control={control}
            name="position"
            mode="onChange"
          />
        }
      </Box>
      {xlUp && <Divider orientation="vertical" variant="middle" />}
    </Fragment>
  )
}

export default PositionCustomization
