const styles = (theme) => ({
  root: {
    border: `1px solid ${theme.palette.navigator.borderColor}`,
    borderRadius: '4px',
    margin: theme.spacing(4, 2, 0, 2),
  },
  defaultTypography: {
    color: theme.palette.navigator.text.secondary,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
})

export default styles
