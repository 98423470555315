import React, { useState } from 'react'

import { LoadingFeedback, ToggleButton } from 'components'
import {
  Collapse as CollapseMaterial,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import useStyles from './styles'

const CollapseAnotherTrail = ({ children, companyTrail }) => {
  const snackbar = useSnackbar()
  const [expanded, setExpanded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState(companyTrail?.completed)
  const classes = useStyles()

  const handleConcludeStep = async () => {
    if (companyTrail.completed) {
      return
    }

    try {
      setIsLoading(true)
      setChecked(true)
      await service.dponet.companyTrails.concludedStep({
        companyTrailId: companyTrail.id,
      })
      snackbar.open({
        message: 'Etapa alterada para concluída com sucesso.',
        variant: 'success',
      })
      setIsLoading(false)
    } catch (error) {
      setChecked(false)
      snackbar.open({
        message: 'Erro ao alterar etapa para concluída.',
        variant: 'error',
      })
      setIsLoading(false)
    }
  }

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <LoadingFeedback open={isLoading} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        mx={2}
        my={1}
      >
        <Typography variant="subtitle1">{companyTrail?.name}</Typography>
        <Box className={classes.boxButton} mr={2}>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleConcludeStep}
                  size="large"
                  color="primary"
                  checked={checked}
                />
              }
              classes={{ label: classes.checkboxLabel }}
              label="CONCLUÍDO"
            />
          </Box>

          <ToggleButton
            size={22}
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          />
        </Box>
      </Box>
      <CollapseMaterial in={expanded} timeout="auto" unmountOnExit>
        {children}
      </CollapseMaterial>
    </Box>
  )
}

export default CollapseAnotherTrail
