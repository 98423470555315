import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Box, TextField, Typography } from '@material-ui/core'

import { BaseDialog } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import schema from './schema'
import * as service from 'service'
import constants from 'constants/index'

const AuditsFinalizeModal = ({
  companyAuditId,
  open,
  refresh = () => {},
  setLoading,
  setOpen,
}) => {
  const snackbar = useSnackbar()

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: { observation: '' },
  })

  const onSubmit = async (data) => {
    try {
      setLoading(true)

      await service.dponet.companyAudits.changeStatus({
        companyAuditId,
        status: constants.audits.FINALIZED_STATUS_ID,
        ...data,
      })

      snackbar.open({
        message: `Auditoria finalizada com sucesso!`,
        variant: 'success',
      })
      refresh()
      setOpen(false)
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Finalizar auditoria"
      fullWidth
      dialogSize="md"
      actionButtonText="Finalizar"
      closeButtonText="Cancelar"
      justifyActions="end"
      formRef="audits-finalize-modal-form"
    >
      <Box
        component="form"
        id="audits-finalize-modal-form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        textAlign="justify"
        gridGap={theme.spacing(2)}
      >
        <Typography color="secondary">
          Ao finalizar a auditoria, os processos que não foram revisados e ainda
          estão marcados como 'Pendentes' retornarão ao status ‘Aprovado’. No
          entanto, essa falta de revisão será registrada no histórico.
        </Typography>
        <Box>
          <Typography variant="h6">
            Comentários e/ou pareceres a respeito da auditoria
          </Typography>
          <Controller
            control={control}
            name="observation"
            as={
              <TextField
                variant="outlined"
                error={!!errors?.observation}
                helperText={<>{errors?.observation?.message}</>}
                minRows={4}
                maxRows={12}
                fullWidth
                multiline
              />
            }
          />
        </Box>
      </Box>
    </BaseDialog>
  )
}

AuditsFinalizeModal.propTypes = {
  companyAuditId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  open: PropTypes.bool.isRequired,
  refresh: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default AuditsFinalizeModal
