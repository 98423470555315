import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 60,
    padding: 52,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  title: {
    fontSize: 36,
    fontFamily: 'Raleway',
    fontWeight: 700,
    textAlign: 'left',
    color: theme.palette.black,
  },
  description: {
    fontSize: 20,
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: theme.palette.text.mainMenu,
  },
  descriptionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    gap: 30,
    padding: 20,
  },
  benefitContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
  },
  image: {
    borderRadius: 10,
    objectFit: 'fit',
    width: '100%',
    heigth: '100%',
    maxWidth: 680,
  },
}))

export default styles
