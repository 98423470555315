import * as yup from 'yup'

const schema = yup.object().shape({
  title: yup.string().required(),
  content: yup
    .string()
    .max(3000, 'Quantidade de caracteres acima do permitido!')
    .required(),
})

export default schema
