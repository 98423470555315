import React from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import { orderBy, last } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { QuestionsReview } from '../../components'

import constants from 'constants/index'
import { routes } from 'Routes'

const ReviewLia = ({
  handleNext = () => {},
  questionsPendingAnswers = [],
  questionsReviewDisapprovedAnswers = [],
  questionsApprovedAnswers = [],
  finality = false,
}) => {
  const history = useHistory()

  const allQuestionsStatus = [
    ...questionsPendingAnswers,
    ...questionsReviewDisapprovedAnswers,
    ...questionsApprovedAnswers,
  ]

  const {
    LEGITIMATE_INTEREST_FINALITY,
    CONCRETE_SITUATION,
    LEGITIMATE_EXPECTATION,
    SAFEGUARDS_AND_MECHANISMS,
  } = constants.liaQuestions
  const { STATUSES } = constants.liaAnswers

  const filterQuestionsInterestOrExpectation = () => {
    const kindToCompare = finality
      ? LEGITIMATE_INTEREST_FINALITY
      : LEGITIMATE_EXPECTATION
    return allQuestionsStatus?.filter((question) => {
      return question?.kind === kindToCompare
    })
  }

  const filterQuestionsConcreteOrSafeguards = () => {
    const kindToCompare = finality
      ? CONCRETE_SITUATION
      : SAFEGUARDS_AND_MECHANISMS
    return allQuestionsStatus?.filter((question) => {
      return question?.kind === kindToCompare
    })
  }

  const questionsLegitimateOrExpectation =
    filterQuestionsInterestOrExpectation()
  const questionsConcreteOrSafeguard = filterQuestionsConcreteOrSafeguards()

  const filterLastAnswerPending = (liaAnswers) => {
    const answers = liaAnswers?.filter((answer) => {
      return [
        STATUSES.PENDING,
        STATUSES.APPROVED,
        STATUSES.REVIEW_DISAPPROVED,
      ].includes(answer.status)
    })

    return last(answers)
  }

  const { handleSubmit } = useForm()

  const onSubmit = () => {
    if (finality) {
      return handleNext()
    }
    return history.push(routes.dataProcess.all)
  }

  return (
    <Box mt={2}>
      <form form id="lia_form" onSubmit={handleSubmit(onSubmit)}>
        <Paper component={Box} padding={2}>
          <Box mb={2}>
            <Typography variant="h5">
              {finality
                ? 'Interesse e finalidades legítimas'
                : 'Legítima expectativa'}
            </Typography>
          </Box>
          {orderBy(questionsLegitimateOrExpectation, 'order')?.map(
            (question) => {
              const lastAnswer = filterLastAnswerPending(question.liaAnswers)
              return (
                <QuestionsReview
                  key={question.id}
                  order={question.order}
                  text={question.text}
                  answer={lastAnswer}
                />
              )
            },
          )}
        </Paper>
        <Paper component={Box} padding={2} mt={2}>
          <Box mb={2}>
            <Typography variant="h5">
              {finality
                ? 'Situação concreta'
                : 'Salvaguardas e mecanismos de opt-out e de oposição'}
            </Typography>
          </Box>
          {orderBy(questionsConcreteOrSafeguard, 'order')?.map((question) => {
            const lastAnswer = filterLastAnswerPending(question.liaAnswers)
            return (
              <QuestionsReview
                key={question.id}
                order={question.order}
                text={question.text}
                answer={lastAnswer}
              />
            )
          })}
        </Paper>
      </form>
    </Box>
  )
}

export default ReviewLia
