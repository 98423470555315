import React from 'react'
import { isEmpty } from 'lodash'
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { Alert, AlertTitle } from '@material-ui/lab'
import { SuggestionAlert } from 'components/RmcActionContent/components'

import { Collapse } from 'components'
import {
  DataProcessSourcesTable,
  DataProcessTemplateForm,
} from '../../../components'
import { Volumetry } from '../'

import helpers from 'helpers'
import constants from 'constants/index'

import clsx from 'clsx'

import styles from './styles'
const useStyles = makeStyles(styles)

const GeneralInformation = ({
  data,
  isLoading,
  hiddenSuggestionAlert,
  origin,
  dataProcessTemplateAnswers,
}) => {
  const classes = useStyles()

  const defaultStatus = () => {
    if (!origin) return data.statusId

    if (origin === 'DataProcessSuggestion') {
      return constants.dataProcess.SUGGESTED
    }

    if (origin === 'DataProcessDiscarded') {
      return constants.dataProcess.DISCARDED
    }
  }

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {helpers.dataProcess.statusesCheck.disapproved(data) &&
            data.reproveJustification && (
              <Box mb={4}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Alert severity="warning">
                    <AlertTitle>Último motivo de reprova</AlertTitle>
                    {data.reproveJustification}
                  </Alert>
                </Grid>
              </Box>
            )}
          <Typography variant="h5">Informações Gerais</Typography>
          <SuggestionAlert
            hidden={hiddenSuggestionAlert}
            sectionName="Informações gerais"
            dataProcessId={data?.id}
            sectionTab={constants.suggestionChanges.TAB_VIEW_VALUE.GENERAL}
          />
          <Grid container className={classes.root}>
            <Grid item md={4} sm={6} xs={12}>
              <Box mt={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Identificador
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {data?.id}
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Departamento
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {helpers.functions.dig(data.department, 'name')}
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Versão
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {data.version}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Box mt={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Nome do Registro
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {data.name}
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Código
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {data.code}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid item xs={12}>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Status
                  </Typography>
                  <Chip
                    className={clsx(
                      helpers.dataProcess.textStatusColor(
                        defaultStatus(),
                        classes,
                      ),
                      helpers.dataProcess.statusColor(defaultStatus(), classes),
                    )}
                    align="center"
                    size="small"
                    label={helpers.dataProcess.status(defaultStatus())}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Risco
                  </Typography>
                  <Chip
                    className={clsx(
                      helpers.dataProcess.fragilityColor(
                        data?.adoptedFragilityId,
                        classes,
                      ),
                      helpers.dataProcess.textFragilityColor(
                        data?.adoptedFragilityId,
                        classes,
                      ),
                    )}
                    align="center"
                    size="small"
                    label={helpers.functions.upperText(data.fragility)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} />
            </Grid>
            <Grid item xs />
            <Grid item xs={12}>
              <Box mt={2}>
                {!isEmpty(data.dataProcessDataSources) && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">
                      De onde vêm os dados (origem)
                    </Typography>
                    {data?.dataProcessDataSources.map((dataSource) => (
                      <Box key={dataSource?.id}>
                        <Collapse
                          mx={0}
                          title={
                            <Typography>
                              {dataSource.sourceDescription}
                            </Typography>
                          }
                          visibilityChildren={
                            !isEmpty(dataSource.dataProcessSources)
                          }
                        >
                          {!isEmpty(dataSource.dataProcessSources) && (
                            <>
                              <Typography variant="body2" color="textSecondary">
                                {helpers.dataProcess.labelDataProcessSourcesTable(
                                  dataSource?.dataProcessSources.map(
                                    (dataType) => dataType.type,
                                  ),
                                )}
                              </Typography>
                              <DataProcessSourcesTable
                                dataProcessSources={
                                  dataSource?.dataProcessSources
                                }
                                type={dataSource.dataProcessSources[0].type}
                              />
                            </>
                          )}
                        </Collapse>
                      </Box>
                    ))}
                  </Grid>
                )}
              </Box>
            </Grid>
            <Volumetry dataProcess={data} />
            <Grid item xl={8} xs={12}>
              <Box mt={2}>
                <Typography variant="subtitle2" color="textSecondary">
                  Por que o dado é tratado?
                </Typography>
                <Typography align="justify" color="textPrimary" variant="body2">
                  {data.finality}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={8} xs={12}>
              <Box mt={2}>
                <DataProcessTemplateForm
                  spacing={2}
                  show={true}
                  dataProcessTemplateAnswers={dataProcessTemplateAnswers}
                />
              </Box>
            </Grid>
          </Grid>
          {/* Ocultado apos remoção de rmcs */}
          {/* {showAlert && !loading && rmc && (
            <Box mb={2}>
              <FinalizedOrContinuedAlert
                nonComplianceReport={rmc}
                isRisk
                unnecessaryDatas={data?.unnecessaryDatas}
              />
            </Box>
          )} */}
        </>
      )}
    </>
  )
}

GeneralInformation.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  origin: PropTypes.string,
  hiddenSuggestionAlert: PropTypes.bool,
}

GeneralInformation.defaultProps = {
  isLoading: false,
  origin: undefined,
}

export default GeneralInformation
