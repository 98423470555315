import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Box, Button, Paper, Typography } from '@material-ui/core'

import helpers from 'helpers'
import { getWebsiteURL } from 'service/env'

import useStyles from './styles'

const SupplierNotInvited = ({ inviter, isNotLinked }) => {
  const classes = useStyles()
  const { mixpanel } = helpers

  const handleFinish = () => {
    mixpanel.track(
      'Convite de Fornecedores',
      `Etapa de ${
        isNotLinked ? 'Não encontramos o seu vínculo!' : 'Algo deu errado!'
      }`,
      {
        action: `Finalizar-${
          isNotLinked ? 'Nao-Encontramos-Vinculo!' : 'Algo-Deu-Erado!'
        }`,
      },
    )
    return window.open(getWebsiteURL(), '_self')
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box className={classes.groupTypography}>
        <Typography className={clsx(classes.text, classes.textWelcome)}>
          {isNotLinked ? 'Não encontramos o seu vínculo!' : 'Algo deu errado!'}
        </Typography>
        {isNotLinked ? (
          <Typography className={classes.text}>
            Por favor, entre em contato com a empresa{' '}
            <span className={classes.highlight}>{inviter?.name}</span>.
          </Typography>
        ) : (
          <Typography className={classes.text}>
            Você não é o responsavél pela empresa, contate-o ou entre em contato
            conosco através do email{' '}
            <span className={classes.highlight}>atendimento@dponet.com.br</span>
          </Typography>
        )}
      </Box>
      <Paper
        onClick={handleFinish}
        variant="outlined"
        component={Button}
        className={classes.button}
        fullWidth
      >
        Finalizar
      </Paper>
    </Box>
  )
}

SupplierNotInvited.propTypes = {
  isNotLinked: PropTypes.bool,
  inviter: PropTypes.object,
}

export default SupplierNotInvited
