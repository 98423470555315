import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import useStyles from './styles'

const MonthProductCard = ({ action, srcImg, title }) => {
  const classes = useStyles()

  return (
    <Box className={classes.cardContainer} title={title} onClick={action}>
      <img src={srcImg} alt={title} className={classes.imageContainer} />
    </Box>
  )
}

export default MonthProductCard

MonthProductCard.propTypes = {
  action: PropTypes.func.isRequired,
  srcImg: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
