import React from 'react'
import { Filter as FilterIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { Button, Tooltip } from '@material-ui/core'

const FilterButton = ({ setDrawerOpen = () => {}, fullWidth = false }) => {
  return (
    <Tooltip title="Filtros">
      <Button
        onClick={() => setDrawerOpen(true)}
        startIcon={<FilterIcon width="18" />}
        variant="outlined"
        fullWidth={fullWidth}
      >
        Filtros
      </Button>
    </Tooltip>
  )
}

FilterButton.propTypes = {
  setDrawerOpen: PropTypes.func,
  fullWidth: PropTypes.bool,
}

export default FilterButton
