import React, { useState } from 'react'
import { Box, Grid } from '@material-ui/core'

import { LoadingFeedback } from 'components'
import {
  CommunicationDate,
  CommunicationHolder,
  Description,
} from './components'

import { useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import * as service from 'service'

const OpportunityCommunication = ({
  idForm,
  incident,
  handleNext,
  stepName,
}) => {
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const { handleSubmit, control, setValue, reset, getValues, watch } = useForm({
    defaultValues: {
      incidentAware: incident?.incidentReporting?.incidentAware || null,
      occurred: incident?.incidentReporting?.occurred || null,
      anpdNotificationDate:
        incident?.incidentReporting?.anpdNotificationDate || null,
      informedHolder: incident?.incidentReporting?.informedHolder || null,
      justificationNotNotifyingAnpd:
        incident?.incidentReporting?.justificationNotNotifyingAnpd || '',
      informedOtherAuthority:
        incident?.incidentReporting?.informedOtherAuthority || '',
      holderAffectedInformed:
        incident?.communicationIncidentHolder?.holderAffectedInformed || '',
      howWasIncidentReportedOwner:
        incident?.dataOptionIncidents?.howWasIncidentReportedOwner || [],
      howWasIncidentReportedOwnerOther: helpers.incidents.otherOption(
        incident?.dataOptionIncidents?.howWasIncidentReportedOwner,
      ),
      metAboveRequirement:
        incident?.communicationIncidentHolder?.metAboveRequirement + '' || '',
      holderNotCommunicatedIndividually:
        incident?.communicationIncidentHolder
          ?.holderNotCommunicatedIndividually || '',
      holderCommunicatedIndividually:
        incident?.communicationIncidentHolder?.holderCommunicatedIndividually ||
        '',
      holderCommunicationOccurred:
        incident?.communicationIncidentHolder?.holderCommunicationOccurred ||
        '',
      holderWillCommunicateIn:
        incident?.communicationIncidentHolder?.holderWillCommunicateIn || '',
    },
  })

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      await service.dponet.incidents.put({
        incidentId: incident?.id,
        incident: {
          ...helpers.incidents.mountDefault(incident),
          ...helpers.incidents.mountStepOpportunity(data),
          simplifiedForm: false,
        },
      })
      setLoading(false)
      handleNext()
      snackbar.open({
        message: `${stepName} atualizada com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: `Ocorreu um erro ao salvar a ${stepName}!`,
        variant: 'error',
      })
      setLoading(false)
      reset(getValues())
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <form onSubmit={handleSubmit(onSubmit)} id={idForm}>
        <Box pb={3} mt={3} mb={1}>
          <Grid container spacing={1}>
            <CommunicationDate control={control} />
            <CommunicationHolder
              control={control}
              setValue={setValue}
              checkedValue={
                incident?.communicationIncidentHolder?.holderAffectedInformed
              }
              watch={watch}
              incident={incident}
              setLoading={setLoading}
            />
            <Description
              control={control}
              checkedValue={
                incident?.communicationIncidentHolder?.metAboveRequirement +
                  '' || ''
              }
              setValue={setValue}
            />
          </Grid>
        </Box>
      </form>
    </>
  )
}

export default OpportunityCommunication
