import React from 'react'
import {
  Box,
  Card,
  Container,
  Grid,
  makeStyles,
  TablePagination,
  useMediaQuery,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useTheme } from '@material-ui/styles'

import { ContentHeader, FilterButton, Filters, Page } from 'components'
import { AdvisorCard, InvitesTable } from './components'

import usePagination from 'hooks/usePagination'
import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'
import * as service from 'service'

import styles from './styles'

const useStyles = makeStyles(styles)

const SuppliersMain = () => {
  const filter = useFilter()
  const classes = useStyles()
  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.supplierInvite.listInvites,
    {
      perPage,
      page,
      ...{ ...filter.filters },
    },
    [page, perPage, filter.filters],
  )

  return (
    <Page title="Convite de fornecedores">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Convite de fornecedores">
          <Box pl={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen}></FilterButton>
          </Box>
        </ContentHeader>
        <AdvisorCard isInvite isSuggestion={false} isSupplier={false} />
        <Card>
          <Box display="flex" width="100%" mt={2}>
            <Grid container>
              <InvitesTable
                supplierInvites={response?.data?.supplierInvites}
                isLoading={isLoading}
                refresh={refresh}
              />
            </Grid>
          </Box>
          <Box px={2} display="flex" justifyContent="flex-end">
            {!isLoading && response ? (
              <TablePagination
                component="div"
                count={response.data.meta.totalCount}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            ) : (
              <Box py="11px">
                <Skeleton variant="rect" width={200} height={30} />
              </Box>
            )}
          </Box>
        </Card>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input textfieldinput="true" label="Documento" name="document" />
          <input
            datepickerinput="true"
            label="Data de criação inicial"
            name="createdAtGt"
            data-target="createdAtLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de criação final"
            name="createdAtLt"
            data-target="createdAtGt"
            data-max
          />
        </Filters>
      </Container>
    </Page>
  )
}

export default SuppliersMain
