import constants from 'constants/index'

const defineMixpanelId = (activeStep, activeSubStep, action = '') => {
  const {
    STEP_LGPD_INDEX,
    STEP_STAMP_INDEX,
    STEP_CERTIFICATION_INDEX,
    STEP_COOKIE_INDEX,
    STEP_CONCLUSION_INDEX,
    STEP_WELCOME_INDEX,
    STEP_CHECKPOINT_INDEX,
    STEP_DPO_INDEX,
  } = constants.userSteps

  const label = () => {
    switch (activeStep) {
      case STEP_DPO_INDEX[0]:
        if (activeSubStep === STEP_WELCOME_INDEX[1]) {
          return 'boas_vindas'
        }
        return 'etapa_dponet'
      case STEP_LGPD_INDEX[0]:
        return 'etapa_lgpd'
      case STEP_STAMP_INDEX[0]:
        return 'etapa_selo'
      case STEP_CERTIFICATION_INDEX[0]:
        return 'etapa_certificado'
      case STEP_COOKIE_INDEX[0]:
        if (activeSubStep === STEP_CHECKPOINT_INDEX[1]) {
          return 'segunda_etapa_primeiro_acesso'
        }
        return 'etapa_cookies'
      case STEP_CONCLUSION_INDEX[0]:
        return 'etapa_finalizar'
      default:
        return 'etapa_nao_identificada'
    }
  }

  return `${action.split(' ').join('-').toLowerCase()}-${label()}`
}

export default defineMixpanelId
