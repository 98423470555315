import constants from 'constants/index'

const convertFragilityValue = (value, toSlider = true) => {
  if (toSlider) {
    return value === constants.fragilities.UNDEFINED_ID ? 0 : value
  } else {
    return value === 0 ? constants.fragilities.UNDEFINED_ID : value
  }
}

export default convertFragilityValue
