const styles = (theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  textPending: {
    color: '#007bff',
    fontWeight: 700,
  },
  textWaitingReview: {
    color: '#FF9800',
    fontWeight: 700,
  },
  textReviewDisapproved: {
    color: '#E53935',
    fontWeight: 700,
  },
  textReviewApproved: {
    color: '#4CAF50',
    fontWeight: 700,
  },
  textReviewing: {
    color: '#FF9800',
    fontWeight: 700,
  },
  pending: {
    backgroundColor: '#087DF138',
  },
  waitingReview: {
    backgroundColor: '#FFF3E0',
  },
  reviewDisapproved: {
    backgroundColor: '#FEEBEE',
  },
  reviewApproved: {
    backgroundColor: '#E8F5E9',
  },
  reviewing: {
    backgroundColor: '#FFF3E0',
  },
  lowFragility: {
    backgroundColor: '#087DF138',
  },
  mediumFragility: {
    backgroundColor: '#E8F5E9',
  },
  highFragility: {
    backgroundColor: '#FFF3E0',
  },
  severeFragility: {
    backgroundColor: '#FEEBEE',
  },
  textLowFragility: {
    color: '#007bff',
    fontWeight: 700,
  },
  textMediumFragility: {
    color: '#4CAF50',
    fontWeight: 700,
  },
  textHighFragility: {
    color: '#F4A460',
    fontWeight: 700,
  },
  textSevereFragility: {
    color: '#E53935',
    fontWeight: 700,
  },
  otherSource: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  otherInputSource: {
    backgroundColor: theme.palette.text.backgroundInputColor,
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
})

export default styles
