import moment from 'moment'

const calcDays = (endDate) => {
  const dateNow = moment()
  const endDateConvert = moment(endDate)
  const diffDays = endDateConvert.diff(dateNow, 'days')

  if (diffDays > 0) {
    return diffDays
  }

  return 0
}

export default calcDays
