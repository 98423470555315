import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import { reverse } from 'named-urls'

import {
  Box,
  Button,
  Container,
  Grid,
  Tabs,
  Tab,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  LoadingFeedback,
  MenuButton,
  Page,
  Permitted,
  RoundedBorder,
} from 'components'
import {
  GeneralInformation,
  Header,
  QuestionnaireDetails,
  SupplierAvatar,
} from './components/Show'

import constants from 'constants/index'
import helpers from 'helpers'
import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'

import {
  ResendSolicitationDialog,
  ConfirmationDialog,
  SupplierCompliancePdf,
} from './components'

import styles from './styles'
const useStyles = makeStyles(styles)

const ClientShow = ({ match }) => {
  const {
    params: { supplierId },
  } = match

  const history = useHistory()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { mixpanel } = helpers

  const [loading, setLoading] = useState(true)
  const [openResendSolicitation, setOpenResendSolicitation] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [company, setCompany] = useState({})
  const [questionnaires, setQuestionnaires] = useState([])
  const [questionnaire, setQuestionnaire] = useState({})
  const [exportingPdf, setExportingPdf] = useState(false)
  const [currentTab, setCurrentTab] = useState(
    constants.companySuppliers.GENERAL_INFORMATION_VALUE,
  )

  const mixpanelTrack = () => {
    mixpanel.track('Fornecedores', 'Visualizar fornecedor', {
      action: `visualizou o questionário "${questionnaire.name}" e clicou em voltar`,
    })
  }

  const confirmationCallback = () => {
    snackbar.open({
      message: `Você inativou o fornecedor ${company?.supplier?.name}`,
      variant: 'success',
    })
    handleBack()
  }

  const inactivateSubmit = async () => {
    try {
      await service.dponet.suppliers.inactivate({ supplierId })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Ocorreu um erro ao inativar o fornecedor',
        variant: 'error',
      })

      throw error
    }
  }

  const handleInactivate = () => {
    setOpenConfirmation(true)
  }

  const handleTabsChange = (e, value) => {
    setCurrentTab(value)
    if (value !== constants.companySuppliers.GENERAL_INFORMATION_VALUE) {
      setQuestionnaire(
        questionnaires.find((questionnaire) => questionnaire.id === value),
      )
    } else {
      setQuestionnaire({})
    }
  }

  const loadCompany = async () => {
    const responseSuppliers = await service.dponet.suppliers.get({
      supplierId,
    })
    const { companySupplier } = responseSuppliers.data
    setCompany(companySupplier)
  }

  useEffect(() => {
    const loadData = async () => {
      await loadCompany()
      const responseQuestionnaires =
        await service.dponet.suppliers.supplierQuestionnaires({ supplierId })

      const { questionnaires } = responseQuestionnaires.data

      setQuestionnaires(questionnaires)
      setLoading(false)
    }
    loadData()
    //eslint-disable-next-line
  }, [])

  const companySupplierId = company?.supplier?.id

  const { response, isLoading } = useFetch(
    service.dponet.questionControls.list,
    { withActionPlans: false, companyId: companySupplierId, perPage: 999 },
    [],
    !!exportingPdf,
  )

  const questionControls = response?.data?.questionControls

  const tabs =
    helpers.companySuppliers.mountTabsWithQuestionnaires(questionnaires)

  const handleBack = () => {
    if (!openConfirmation) {
      mixpanelTrack()
    }
    history.push(routes.mySuppliers.all)
  }

  const handleEdit = () => {
    history.push(reverse(routes.mySuppliers.edit, { supplierId }))
  }

  const handleLogs = () => {
    history.push(reverse(routes.mySuppliers.logs, { supplierId }))
  }

  const handleResendSolicitation = () => {
    setOpenResendSolicitation(true)
  }

  const handleReportButton = () => {
    setExportingPdf(true)
  }

  const supplier = company?.supplier

  const isInactive =
    company?.status === constants.companySuppliers.INACTIVE_STATUS

  const avatarLetters = (company?.supplier?.name || 'Empresa Fornecedora')
    .split(' ')
    .map((word) => word[0])
    .join('')

  return (
    <Page title="Visualizar fornecedor">
      <LoadingFeedback open={loading || isLoading} />
      <Container
        maxWidth={false}
        className={clsx(classes.blueContainer, classes.containerSpacing)}
      >
        <Header>
          <Grid item xs={2} sm={6}>
            <Box width="100%" display="flex" justifyContent="flex-end">
              <MenuButton
                anchorElement={Button}
                anchorOptions={{
                  className: classes.buttonOptions,
                  variant: 'contained',
                }}
                anchorChildren="Opções"
              >
                <Button color="secondary" onClick={handleLogs} fullWidth>
                  Histórico
                </Button>
                <Permitted tag={constants.permissions.COMPANY_SUPPLIERS.UPDATE}>
                  {company?.canResendSolicitation && (
                    <Button
                      color="secondary"
                      fullWidth
                      onClick={handleResendSolicitation}
                    >
                      {isInactive ? 'Reativar vínculo' : 'Reenviar solicitação'}
                    </Button>
                  )}
                  {!isInactive && (
                    <Button
                      color="secondary"
                      fullWidth
                      onClick={handleInactivate}
                    >
                      Inativar
                    </Button>
                  )}
                  {company.editionPermitted && (
                    <Button color="secondary" fullWidth onClick={handleEdit}>
                      Editar
                    </Button>
                  )}
                </Permitted>
                <Button
                  color="secondary"
                  fullWidth
                  onClick={handleReportButton}
                >
                  Gerar Relatório
                </Button>
              </MenuButton>
            </Box>
          </Grid>
          <SupplierAvatar company={company} avatarLetters={avatarLetters} />
        </Header>
      </Container>
      <Box position="relative">
        <RoundedBorder
          showRoundedWhite
          showRoundedGray={false}
          whiteLabel={false}
        />
      </Box>
      <Container
        maxWidth={false}
        className={clsx(classes.whiteContainer, classes.containerSpacing)}
      >
        <Box
          pt={14}
          pb={4}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h3">
            <b>{company?.supplier?.name}</b>
          </Typography>
        </Box>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={(e) => handleTabsChange(e, tab.value)}
            />
          ))}
        </Tabs>
      </Container>
      <Container maxWidth={false} className={classes.containerSpacing}>
        {currentTab === constants.companySuppliers.GENERAL_INFORMATION_VALUE ? (
          <GeneralInformation
            questionnaires={questionnaires}
            company={{
              supplierResponsibleEmail: company?.supplierResponsibleEmail,
              ...company?.supplier,
            }}
          />
        ) : (
          <QuestionnaireDetails questionnaire={questionnaire} />
        )}
        <Box mt={4}>
          <Button variant="contained" onClick={handleBack}>
            Voltar
          </Button>
        </Box>
      </Container>
      <ResendSolicitationDialog
        companySupplier={company}
        open={openResendSolicitation}
        setOpen={setOpenResendSolicitation}
        refresh={loadCompany}
      />
      <ConfirmationDialog
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        title="Inativar vínculo?"
        subtitle="Deseja confirmar a inativação de vínculo com o fornecedor"
        callback={confirmationCallback}
        handleConfirm={inactivateSubmit}
        isSupplier={false}
        supplier={company}
      />
      <SupplierCompliancePdf
        exportingPdf={exportingPdf}
        setExportingPdf={setExportingPdf}
        questionControls={questionControls}
        questionnaires={questionnaires}
        supplier={supplier}
      />
    </Page>
  )
}

export default ClientShow
