import constants from 'constants/index'

const translateCategory = (category) => {
  return (
    constants.privacyPolicy.TRANSLATED_CATEGORIES[category] ||
    'Sem categoria cadastrada'
  )
}

export default translateCategory
