import React from 'react'

import { Box, makeStyles } from '@material-ui/core'

import { FormTabs } from './components'

import useSubscription from 'hooks/useSubscription'

import styles from './styles'

const useStyles = makeStyles(styles)

const FormMain = () => {
  const classes = useStyles()

  const { isLoading, selectedSegment } = useSubscription()

  return (
    <Box className={classes.root}>
      {!!selectedSegment && <FormTabs isLoading={isLoading} />}
    </Box>
  )
}

export default FormMain
