import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  rootTab: {
    cursor: 'pointer',
    width: 'auto%',
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.subscription.primary.main,
    backgroundColor: theme.palette.white,
    marginTop: theme.spacing(1),
  },
  selectedTab: {
    backgroundColor: theme.palette.subscription.primary.main,
    color: theme.palette.white,
  },
  tabInactive: {
    color: theme.palette.blueDark,
    fontWeight: 500,
    paddingTop: theme.spacing(1),
  },
  tabActive: {
    color: theme.palette.blueDark,
    fontWeight: 500,
    paddingTop: theme.spacing(1),
  },
  icon: {
    fontSize: '14px',
    marginRight: theme.spacing(2),
  },
  subtitle: {
    color: theme.palette.blueDark,
    fontWeight: 500,
    paddingTop: theme.spacing(1),
  },
  label: {
    fontSize: 12,
  },
  processItems: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  processContainer: {
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(3),
    },
  },
  processRiskTable: {
    display: 'flex',
    width: '100%',
    alignItems: 'end',
  },
}))

export default styles
