import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import { Box, Button } from '@material-ui/core'

import { useHistory, useLocation } from 'react-router-dom'

import constants from 'constants/index'
import useSuggestedChange from 'hooks/useSuggestedChange'
import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'

import qs from 'query-string'

import {
  Page,
  ContainerWithClassName,
  ContentHeader,
  LoadingFeedback,
  ConfirmationDialog,
} from 'components'
import {
  AlertSaveInfo,
  CardProcessOrigin,
  TabSections,
  TabsSuggestions,
} from './components/SuggestionChanges'

import { reverse } from 'named-urls'
import { routes } from 'Routes'

import * as service from 'service'
import helpers from 'helpers'
import useStyles from './styles'

const SuggestionChanges = ({ match }) => {
  const location = useLocation()
  const history = useHistory()
  const snackbar = useSnackbar()
  const [tabsClicked, setTabsClicked] = useState({})
  const [saveDisabled, setSaveDisabled] = useState(true)

  const { dataProcessId } = match.params

  const { response, isLoading, error } = useFetch(
    service.dponet.suggestedChanges.get,
    { dataProcessId },
  )

  const {
    submit,
    loading,
    suggestionsQuantity,
    data,
    setDepartments,
    loadDataProcess,
    dataProcess,
  } = useSuggestedChange()

  const {
    DATA_COLLECTED_ENTITY_TYPE,
    DATA_PROCESS_ENTITY_TYPE,
    DATA_SOURCE_ENTITY_TYPE,
    TABS_SUGGESTIONS,
    LIFE_CYCLE_ENTITY_TYPE,
    DATA_TREATMENT_ENTITY_TYPE,
  } = constants.suggestionChanges

  const classes = useStyles()
  const search = qs.parse(location.search)

  const [currentTab, setCurrentTab] = useState(search.tab)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)

  const apiData = response?.data || []

  const filterByEntities = (entities) =>
    apiData.filter((item) => entities.includes(item.entityType))

  const suggestions = {
    general: filterByEntities([
      DATA_PROCESS_ENTITY_TYPE,
      DATA_SOURCE_ENTITY_TYPE,
    ]),
    dataCollecteds: filterByEntities(DATA_COLLECTED_ENTITY_TYPE),
    lifeCycles: filterByEntities(LIFE_CYCLE_ENTITY_TYPE),
    dataTreatments: filterByEntities(DATA_TREATMENT_ENTITY_TYPE),
  }

  useEffect(() => {
    const loadDepartments = async () => {
      const response = await service.dponet.departments.get({})
      setDepartments(
        response.data.departments?.map((department) => ({
          id: department.id,
          name: department.name,
        })),
      )
    }

    loadDepartments()
    loadDataProcess(dataProcessId)
    //eslint-disable-next-line
  }, [])

  useEffect(
    () => {
      if (error) {
        return snackbar.open({
          message: helpers.formatters.errorMessage(error.response.data.error),
          variant: 'error',
        })
      }
      if (!isLoading) {
        const tabsClicked = {
          general: isEmpty(suggestions.general),
          dataCollecteds: isEmpty(suggestions.dataCollecteds),
          lifeCycles: isEmpty(suggestions.lifeCycles),
          dataTreatments: isEmpty(suggestions.dataTreatments),
          [search.tab]: true,
        }

        const notEmptyTab = TABS_SUGGESTIONS.find(
          (tab) => !isEmpty(suggestions[tab.value]),
        )?.value

        if (isEmpty(suggestions[currentTab])) {
          tabsClicked[notEmptyTab] = true
          setCurrentTab(notEmptyTab)
        }

        setTabsClicked(tabsClicked)
      }
    },
    //eslint-disable-next-line
    [isLoading],
  )

  useEffect(() => {
    const allTabsClicked = isEmpty(
      Object.values(tabsClicked).filter((tab) => !tab),
    )

    setSaveDisabled(!allTabsClicked || data.length !== suggestionsQuantity)
  }, [tabsClicked, data, suggestionsQuantity])

  const replaceLocation = (origin) => {
    history.replace(`${location.pathname}?tab=${origin}`)
  }

  const handleTabsChange = (_, value) => {
    setTabsClicked({ ...tabsClicked, [value]: true })
    setCurrentTab(value)
    replaceLocation(value)
  }

  const handleBack = () => {
    history.push(reverse(routes.dataProcess.view, { dataProcessId }))
  }

  const handleSubmit = async () => {
    const changes = data

    if (!!changes.find((change) => change.answer === 'refuse')) {
      setOpenConfirmationDialog(true)
    } else {
      await sendRequest()
    }
  }

  const sendRequest = async () => {
    try {
      await submit(dataProcessId)
      snackbar.open({
        variant: 'success',
        message: 'Escolhas de sugestões para o processo aplicadas com sucesso',
      })
      setOpenConfirmationDialog(false)
      handleBack()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Page
        title="Sugestões de melhorias do processo"
        className={classes.whitePage}
      >
        <LoadingFeedback open={loading || isLoading} />
        <ContainerWithClassName className={classes.container}>
          <Box py={2} mt={1}>
            <ContentHeader title="Alterações sugeridas" />
          </Box>
          <CardProcessOrigin
            handleBack={handleBack}
            dataProcessName={dataProcess?.name}
            isLoading={loading || isLoading}
          />
          {currentTab && (
            <TabsSuggestions
              currentTab={currentTab}
              handleTabsChange={handleTabsChange}
              suggestions={suggestions}
            />
          )}
        </ContainerWithClassName>
        <ContainerWithClassName className={classes.defaultColorContainer}>
          <AlertSaveInfo />
          {currentTab && (
            <TabSections
              currentTabData={TABS_SUGGESTIONS.find(
                (tab) => tab.value === currentTab,
              )}
              suggestions={suggestions[currentTab]}
            />
          )}
          <Box display="flex" mb={4}>
            <Box mr={4}>
              <Button onClick={handleBack} variant="contained">
                Voltar
              </Button>
            </Box>
            <Button
              disabled={saveDisabled}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </Box>
        </ContainerWithClassName>
      </Page>
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        actionAcceptButton={sendRequest}
        text="Ao rejeitar essas sugestões, elas serão eliminadas em definitivo. Você realmente deseja continuar e rejeitar as alterações sugeridas para o processo?"
        textButton="Continuar"
      />
    </>
  )
}

export default SuggestionChanges
