/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import { Box } from '@material-ui/core'
import { Container } from 'components'
import { Form, FreeTrialAlert, Congratulations } from './components'

import useSubscription from 'hooks/useSubscription'

import { getGoogleRecaptchaToken } from 'service/env'

const Main = () => {
  const [congratulation, setCongratulation] = useState(false)

  const { selectedSegment, setTermsAccept, setPolicyAccept, setRecaptcha } =
    useSubscription()

  useEffect(() => {
    const getPlansBySegment = async () => {
      if (!selectedSegment) return
    }
    setTermsAccept(false)
    setPolicyAccept(false)
    setRecaptcha(undefined)
    getPlansBySegment()
  }, [selectedSegment])

  useEffect(() => {
    localStorage.removeItem(getGoogleRecaptchaToken())
  }, [])

  return (
    <Box display="flex" justifyContent="center">
      <Container>
        {congratulation ? (
          <Congratulations />
        ) : (
          <>
            <FreeTrialAlert />
            <Form setCongratulation={setCongratulation} />
          </>
        )}
      </Container>
    </Box>
  )
}

export default Main
