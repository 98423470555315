import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import { BaseDialog } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import useStyles from './styles'
import * as service from 'service'

const InactiveFormModal = ({
  consentForm,
  isActive,
  loading,
  open,
  refresh,
  setLoading,
  setOpen,
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const formRef = 'inactive-consent-form'

  const onSubmit = async (event) => {
    try {
      event.preventDefault()
      setLoading(true)

      await service.dponet.consent.changeStatus({
        consentFormId: consentForm?.id,
      })

      snackbar.open({
        message: `Formulário ${
          isActive ? 'inativado' : 'ativado'
        } com sucesso!`,
        variant: 'success',
      })
      refresh()
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title={`${isActive ? 'Inativar' : 'Ativar'} Formulário`}
      dialogSize="md"
      closeButtonText="Cancelar"
      actionButtonText={isActive ? 'Inativar' : 'Ativar'}
      formRef={formRef}
      loading={loading}
      fullWidth
    >
      <Box
        py={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
        component="form"
        id={formRef}
        onSubmit={onSubmit}
      >
        <Box
          bgcolor={theme.palette.greenSnowFlurry}
          borderRadius={theme.spacing(1)}
          px={2}
          py={1}
        >
          <Typography className={classes.title} variant="h4">
            {consentForm?.title}
          </Typography>
        </Box>
        <Typography variant="h5">
          Você deseja realmente {isActive ? 'inativar' : 'ativar'} o formulário?
        </Typography>
      </Box>
    </BaseDialog>
  )
}

InactiveFormModal.propTypes = {
  consentForm: PropTypes.object,
  isActive: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default InactiveFormModal
