import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chip: {
    fontWeight: 600,
    textTransform: 'uppercase',
    borderRadius: 4,
  },
}))

export default styles
