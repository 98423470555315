import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import constants from 'constants/index'

const styles = (status) =>
  makeStyles((theme) => ({
    label: {
      textTransform: 'uppercase',
    },
    iconBox: {
      animation:
        status === constants.cookies.banner.INITIATED_SCANNER_STATUS_ID
          ? '$spin 1s linear infinite'
          : 'none',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }))

styles.propTypes = {
  status: PropTypes.oneOf([
    constants.cookies.banner.COMPLETED_SCANNER_STATUS_ID,
    constants.cookies.banner.FAILED_SCANNER_STATUS_ID,
    constants.cookies.banner.INITIATED_SCANNER_STATUS_ID,
  ]),
}

export default styles
