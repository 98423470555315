import mountDataCollectedOptionsIDs from './mountDataCollectedOptionsIDs'
import mountListOfAnotherOptions from './mountListOfAnotherOptions'

const treatData = (data, selectedItems, edit) => {
  if (selectedItems.length > 0) {
    data['dataCollectedOptionsIds'] =
      mountDataCollectedOptionsIDs(selectedItems)
  } else {
    data['dataCollectedOptionsIds'] = []
  }

  delete data.dataCollectedId
  delete data.dataProcessId

  const {
    anotherPersonalNames,
    anotherComportamentalNames,
    anotherFinancialNames,
  } = data
  if (anotherPersonalNames !== '') {
    data['anotherPersonalNames'] =
      mountListOfAnotherOptions(anotherPersonalNames)
  } else {
    if (edit) {
      delete data['anotherPersonalNames']
    } else {
      data['anotherPersonalNames'] = []
    }
  }
  if (anotherComportamentalNames !== '') {
    data['anotherComportamentalNames'] = mountListOfAnotherOptions(
      anotherComportamentalNames,
    )
  } else {
    if (edit) {
      delete data['anotherComportamentalNames']
    } else {
      data['anotherComportamentalNames'] = []
    }
  }
  if (anotherFinancialNames !== '') {
    data['anotherFinancialNames'] = mountListOfAnotherOptions(
      anotherFinancialNames,
    )
  } else {
    if (edit) {
      delete data['anotherFinancialNames']
    } else {
      data['anotherFinancialNames'] = []
    }
  }
  return data
}

export default treatData
