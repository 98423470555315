import { useContext } from 'react'

import trailStepContext from 'contexts/TrailStepContext'

const useTrailStep = () => {
  const context = useContext(trailStepContext)

  if (context === undefined) {
    throw new Error(
      'useTrailStep must be used within a trailStepContext.Provider',
    )
  }

  const {
    activeStep,
    activeSubStep,
    concludeStep,
    cookieBannerId,
    cookieBannerTag,
    getActivePage,
    handleBack,
    handleNext,
    hasBanner,
    helpObject,
    hideStepper,
    isFinalStep,
    isSiteFormStep,
    jumpStep,
    jumpToDocuments,
    loadCookieBanner,
    loadCookieBannerTag,
    loaded,
    nextDisabled,
    pagesLength,
    privacyPolicy,
    privacyStamp,
    setActiveStep,
    setActiveSubStep,
    setHasBanner,
    setLoaded,
    setNextDisabled,
    setPrivacyPolicy,
    setPrivacyStamp,
    showBackButton,
    showHelpButton,
    showJumpButton,
    showNextButton,
  } = context

  const isFirstStep = activeStep === 0 && activeSubStep === 0

  const formSubmitConfigs = () => {
    const baseFormConfig = { type: 'submit' }
    if (isSiteFormStep)
      return {
        ...baseFormConfig,
        form: 'site-form',
        onClick: () => {},
      }
    return {}
  }

  return {
    activeStep,
    activeSubStep,
    concludeStep,
    cookieBannerId,
    cookieBannerTag,
    formSubmitConfigs,
    getActivePage,
    handleBack,
    handleNext,
    hasBanner,
    helpObject,
    hideStepper,
    isFinalStep,
    isFirstStep,
    jumpStep,
    jumpToDocuments,
    loadCookieBanner,
    loaded,
    nextDisabled,
    pagesLength,
    privacyPolicy,
    privacyStamp,
    setActiveStep,
    setActiveSubStep,
    setHasBanner,
    setLoaded,
    setNextDisabled,
    setPrivacyPolicy,
    setPrivacyStamp,
    showBackButton,
    showHelpButton,
    showJumpButton,
    showNextButton,
    loadCookieBannerTag,
  }
}

export default useTrailStep
