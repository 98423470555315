import { WIDTH as WIDTHPdf, HEIGHT as HEIGHTPdf, PADDING } from '../constants'

import { ratio } from '../helpers'

const lastCover = (pdf, customStyles, customLogo) => {
  const WIDTH = WIDTHPdf(pdf)
  const HEIGHT = HEIGHTPdf(pdf)

  const imageY = HEIGHT / 2
  const imageWidth = ratio(500)
  const imageHeight = ratio(158)

  pdf.addPage('a4', 'p')
  pdf.setFillColor(customStyles?.main)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')
  pdf.addImage(
    customLogo,
    'png',
    WIDTH / 2 - PADDING * 1.25,
    imageY,
    imageWidth,
    imageHeight,
  )
}

export default lastCover
