import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get('/segments', { params })
}

const segments = {
  get,
}

export default segments
