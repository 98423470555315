import disclaimer from './disclaimer'
import incident from './incident'
import treatment from './treatment'

const pages = {
  disclaimer,
  incident,
  treatment,
}

export default pages
