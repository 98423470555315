import auth from './auth'
import banner from './banner'
import consent from './consent'
import cookie from './cookie'

const cookies = {
  auth,
  banner,
  consent,
  cookie,
}

export default cookies
