import React, { useState } from 'react'
import { IconButton, Tooltip, Typography } from '@material-ui/core'
import { Eye as EyeIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

import helpers from 'helpers'
import useStyles from './styles'
import { DataLifeCycleModal } from 'views/DataProcesses/components/DataLifeCycleModal'
import DataLifeCycleProvider from 'providers/DataLifeCycleProvider'

const DataLifeCycleTable = ({ lifeCycles = [], dataProcess }) => {
  const [lifeCycle, setLifeCycle] = useState()
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const handleOpenModal = (lifeCycle) => {
    setLifeCycle(lifeCycle)
    setOpen(true)
  }

  const handleCloseModal = () => {
    setLifeCycle()
    setOpen(false)
  }

  return (
    <PerfectScrollbar>
      <Table
        emptyMessage="NENHUM SALVAGUARDA E CICLO DE VIDA FOI ENCONTRADO"
        noWrap
      >
        <TableHead>
          <TableRow>
            <TableCell width="20%">Ambiente de alocação</TableCell>
            <TableCell width="20%">Onde os dados são armazenados</TableCell>
            <TableCell width="20%">
              Por quanto tempo os dados serão armazenados
            </TableCell>
            <TableCell width="30%">Como os dados são dispostos</TableCell>
            <TableCell width="10%" align="right">
              Ações
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {lifeCycles.map((dataLifeCycle) => (
            <TableRow key={dataLifeCycle.id}>
              <TableCell>
                <Tooltip title={dataLifeCycle?.internalAllocationMode?.name}>
                  <Typography className={classes.ellipsisTable}>
                    {dataLifeCycle?.internalAllocationMode?.name}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title={dataLifeCycle?.storageLocation?.name}>
                  <Typography className={classes.ellipsisTable}>
                    {dataLifeCycle?.storageLocation?.name}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={helpers.dataProcess.storageModeType(
                    dataLifeCycle?.storageType,
                    dataLifeCycle?.value,
                    dataLifeCycle?.volumetry,
                    !!dataLifeCycle?.value && !!dataLifeCycle?.volumetry,
                  )}
                >
                  <Typography className={classes.ellipsisTable}>
                    {helpers.dataProcess.storageModeType(
                      dataLifeCycle?.storageType,
                      dataLifeCycle?.value,
                      dataLifeCycle?.volumetry,
                      !!dataLifeCycle?.value && !!dataLifeCycle?.volumetry,
                    )}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title={helpers.dataProcess.discardMode(dataLifeCycle)}>
                  <Typography className={classes.ellipsisTable}>
                    {helpers.dataProcess.discardMode(dataLifeCycle)}
                  </Typography>
                </Tooltip>
              </TableCell>

              <TableCell align="right">
                <IconButton onClick={() => handleOpenModal(dataLifeCycle)}>
                  <EyeIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <DataLifeCycleProvider
        dataLifeCycles={lifeCycles}
        dataProcessId={dataProcess?.id}
      >
        <DataLifeCycleModal
          dataProcess={dataProcess}
          dataProcessId={dataProcess?.id}
          classes={classes}
          open={open}
          setOpen={handleCloseModal}
          dataLifeCycle={lifeCycle}
          mode="show"
        />
      </DataLifeCycleProvider>
    </PerfectScrollbar>
  )
}

export default DataLifeCycleTable
