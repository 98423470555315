import helpers from 'helpers'
import constants from 'constants/index'
const { TYPE_CATEGORIES, DESCRITION_CATEGORIES } = constants.partner

const descriptionForTypeCategory = (category) => {
  const named = helpers.formatters.formattedConstantName(category?.name)
  switch (named) {
    case TYPE_CATEGORIES[0]:
      return DESCRITION_CATEGORIES[0]
    case TYPE_CATEGORIES[1]:
      return DESCRITION_CATEGORIES[1]
    case TYPE_CATEGORIES[2]:
      return DESCRITION_CATEGORIES[2]
    case TYPE_CATEGORIES[3]:
      return DESCRITION_CATEGORIES[3]
    default:
      return category?.description || ''
  }
}

export default descriptionForTypeCategory
