import constants from 'constants/index'
import {
  Loader as LoaderIcon,
  AlertCircle as AlertCircleIcon,
  Check as CheckIcon,
  Moon as MoonIcon,
} from 'react-feather'

const iconByStatus = (status) => {
  switch (status) {
    case constants.companySuppliers.APPROVED_STATUS:
      return CheckIcon
    case constants.companySuppliers.REFUSED_STATUS:
      return AlertCircleIcon
    case constants.companySuppliers.INACTIVE_STATUS:
      return MoonIcon
    default:
      return LoaderIcon
  }
}

export default iconByStatus
