import helpers from 'helpers'

const addIconForArray = (serviceCategories) => {
  if (serviceCategories) {
    return serviceCategories.map((item) => {
      // eslint-disable-next-line react/react-in-jsx-scope
      return { ...item, icon: helpers.partners.getIcon(item?.name) }
    })
  }
}
export default addIconForArray
