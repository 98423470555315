import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import { BaseDialog } from 'components'

import helpers from 'helpers'
import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'

const NextLevelModal = ({
  companyId,
  level,
  unlockLevel,
  loading,
  setLoading,
  open,
  setOpen,
  refresh,
  refreshQuestionnaireLevel,
}) => {
  const snackbar = useSnackbar()

  const handleUpdate = async () => {
    try {
      setLoading(true)

      const conformityLevel = unlockLevel ? level : level + 1

      await service.dponet.companies.updateComplianceLevel({
        companyId,
        company: { conformityLevel },
      })

      refresh()
      refreshQuestionnaireLevel()
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseDialog
      actionButtonText="Avançar"
      actionButton={handleUpdate}
      closeButtonText="Cancelar"
      dialogSize="sm"
      fullWidth
      justifyActions="end"
      loading={loading}
      open={open}
      setOpen={setOpen}
      title={unlockLevel ? 'Atenção!' : 'Parabéns'}
    >
      <Box
        py={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
        component="form"
      >
        {unlockLevel ? (
          <>
            <Box>
              <Typography>
                Ao atualizar os questionários para o próximo nível, estaremos
                implementando novas medidas de segurança para garantir um maior
                nível de conformidade para sua organização. É importante
                ressaltar que uma vez feita a atualização, não será possível
                retornar ao nível anterior.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">
                Gostaria de aumentar seu nível?
              </Typography>
            </Box>
          </>
        ) : (
          <Box>
            <Box mb={1}>
              <Typography variant="h6">
                Você concluiu o nível {level} de conformidade.
              </Typography>
            </Box>
            <Typography>
              Desbloqueie o nível {level + 1} do questionário para avançar e
              aumentar a conformidade da sua organização. Com isso, você
              implementará mais medidas de segurança, tornando sua empresa ainda
              mais protegida.
            </Typography>
          </Box>
        )}
      </Box>
    </BaseDialog>
  )
}

NextLevelModal.propTypes = {
  consentForm: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default NextLevelModal
