import React, { useEffect } from 'react'

import { useFormContext, Controller } from 'react-hook-form'

import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'

import { Label, SelectCountry } from 'components'

import constants from 'constants/index'

import PropTypes from 'prop-types'

import styles from '../DialogDataTreatment/components/NewForm/styles.js'

const useStyles = makeStyles(styles)

const SharedSoftwareForm = ({
  sharedSoftware,
  index,
  removeSharedSoftware,
  disabled,
}) => {
  const { control, errors, setValue, watch } = useFormContext()
  const classes = useStyles()

  useEffect(() => {
    setValue(`sharedSoftwares[${index}].name`, sharedSoftware?.name)
    setValue(
      `sharedSoftwares[${index}].softwareAllocationType`,
      softwareAllocationTypeDefaultValue(),
    )
    // eslint-disable-next-line
  }, [])

  const softwareAllocationTypeDefaultValue = () => {
    if (
      sharedSoftware.softwareAllocationType ===
      constants.sharedSoftware.COMPANY_ALLOCATION_TYPE
    ) {
      return constants.sharedSoftware.COMPANY_ALLOCATION_ID
    }

    if (
      sharedSoftware.softwareAllocationType ===
      constants.sharedSoftware.THIRDY_ALLOCATION_TYPE
    ) {
      return constants.sharedSoftware.THIRDY_ALLOCATION_ID
    }

    return sharedSoftware.softwareAllocationType
  }

  return (
    <Box className={classes.internationalTransferField} px={2} pb={2} mb={2}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Box mt={2}>
              <Grid item xs={12}>
                <Label
                  description="O servidor é nacional, internacional ou ambos?"
                  item
                >
                  <Controller
                    as={
                      <TextField
                        select
                        className={classes.inputBackground}
                        color="primary"
                        variant="outlined"
                        disabled={disabled}
                        error={
                          !!errors?.sharedSoftwares &&
                          errors?.sharedSoftwares[index]?.serverAllocation
                        }
                        helperText={
                          errors?.sharedSoftwares &&
                          errors?.sharedSoftwares[index]?.serverAllocation &&
                          'Preencha este campo'
                        }
                        fullWidth
                      >
                        <MenuItem
                          value={
                            constants.sharedSoftware
                              .NATIONAL_SERVER_ALLOCATION_TYPE
                          }
                        >
                          <Typography>
                            {
                              constants.sharedSoftware
                                .NATIONAL_SERVER_ALLOCATION_LABEL
                            }
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          value={
                            constants.sharedSoftware
                              .INTERNATIONAL_SERVER_ALLOCATION_TYPE
                          }
                        >
                          <Typography>
                            {
                              constants.sharedSoftware
                                .INTERNATIONAL_SERVER_ALLOCATION_LABEL
                            }
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          value={
                            constants.sharedSoftware.BOTH_SERVER_ALLOCATION_TYPE
                          }
                        >
                          <Typography>
                            {
                              constants.sharedSoftware
                                .BOTH_SERVER_ALLOCATION_LABEL
                            }
                          </Typography>
                        </MenuItem>
                      </TextField>
                    }
                    rules={{ required: true }}
                    defaultValue={sharedSoftware.serverAllocation || ''}
                    control={control}
                    name={`sharedSoftwares[${index}].serverAllocation`}
                  />
                </Label>
              </Grid>
              {[
                constants.sharedSoftware.INTERNATIONAL_SERVER_ALLOCATION_TYPE,
                constants.sharedSoftware.BOTH_SERVER_ALLOCATION_TYPE,
              ].includes(
                watch(`sharedSoftwares[${index}].serverAllocation`),
              ) && (
                <>
                  <Grid item xs={12}>
                    <Box>
                      <SelectCountry
                        withTable
                        manyFields
                        disabled={disabled}
                        label="Qual o país destinatário?"
                        fieldName={`sharedSoftwares-${index}`}
                        indexField={index}
                      />
                    </Box>
                  </Grid>
                </>
              )}
              <Label
                description="Qual o nome da plataforma, software ou aplicativo utilizado?"
                item
              >
                <Controller
                  as={
                    <TextField
                      color="primary"
                      className={classes.inputBackground}
                      variant="outlined"
                      disabled={disabled}
                      error={
                        errors?.sharedSoftwares &&
                        !!errors?.sharedSoftwares[index]?.name
                      }
                      helperText={
                        errors?.sharedSoftwares &&
                        errors?.sharedSoftwares[index]?.name &&
                        'Preencha este campo'
                      }
                      fullWidth
                    />
                  }
                  rules={{ required: true }}
                  defaultValue={sharedSoftware.name || ''}
                  control={control}
                  name={`sharedSoftwares[${index}].name`}
                />
              </Label>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box mt={2}>
              <Label
                description="O servidor dessa plataforma, software ou aplicativo, é de propriedade da sua empresa ou é contratado, de terceiro?"
                item
              >
                <Controller
                  as={
                    <TextField
                      select
                      className={classes.inputBackground}
                      variant="outlined"
                      disabled={disabled}
                      error={
                        errors.sharedSoftwares &&
                        !!errors?.sharedSoftwares[index]?.softwareAllocationType
                      }
                      helperText={
                        errors?.sharedSoftwares &&
                        errors?.sharedSoftwares[index]
                          ?.softwareAllocationType &&
                        'Preencha este campo'
                      }
                      fullWidth
                    >
                      <MenuItem value={1}>
                        <Typography>É da minha empresa</Typography>
                      </MenuItem>
                      <MenuItem value={2}>
                        <Typography>É contratado de terceiros</Typography>
                      </MenuItem>
                    </TextField>
                  }
                  rules={{ required: true }}
                  control={control}
                  defaultValue={softwareAllocationTypeDefaultValue()}
                  name={`sharedSoftwares[${index}].softwareAllocationType`}
                />
              </Label>
            </Box>
          </Grid>
          <Controller
            as={<></>}
            name={`sharedSoftwares[${index}].id`}
            control={control}
            defaultValue={sharedSoftware?.id}
          />
        </Grid>
        {!disabled && (
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              height="100%"
              mt={2}
              mb={1}
            >
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.removeButton}
                  onClick={() => removeSharedSoftware(index, sharedSoftware)}
                >
                  Remover
                </Button>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

SharedSoftwareForm.propTypes = {
  sharedSoftware: PropTypes.object,
  index: PropTypes.number,
  removeSharedSoftware: PropTypes.func,
  disabled: PropTypes.bool,
}

SharedSoftwareForm.defaultValues = {
  removeSharedSoftware: () => {},
  disabled: false,
}

export default SharedSoftwareForm
