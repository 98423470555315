import React, { useState } from 'react'

import SpecificRisksContext from 'contexts/SpecificRisksContext'

import * as service from 'service'

const SpecificRisksProvider = ({ children, nonComplianceSpecificRisks }) => {
  const [data, setData] = useState(nonComplianceSpecificRisks)
  const [loading, setLoading] = useState(false)

  const loadData = async (nonComplianceReportId) => {
    setLoading(true)

    let response = await service.dponet.nonComplianceReports.get({
      nonComplianceReportId,
    })

    setData(response?.data?.nonComplianceReport?.specificRisks)

    setLoading(false)
  }

  return (
    <SpecificRisksContext.Provider
      value={{
        data,
        setData,
        loading,
        setLoading,
        loadData,
      }}
    >
      {children}
    </SpecificRisksContext.Provider>
  )
}

export default SpecificRisksProvider
