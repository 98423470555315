import constants from 'constants/index'

const chipStatusLabel = (status) => {
  return (status && constants.audits.CHIP_STATUS_LABEL[status]) || 'N/A'
}

const chipStatusColor = (status) => {
  return (status && constants.audits.CHIP_STATUS_COLOR[status]) || undefined
}

const chipStatusBgColor = (status) => {
  return (
    (status && constants.audits.CHIP_STATUS_BACKGROUND_COLOR[status]) ||
    undefined
  )
}

const processChipStatusLabel = (status) => {
  return (
    (status && constants.audits.DATA_PROCESS_CHIP_STATUS_LABEL[status]) || 'N/A'
  )
}

const processChipStatusColor = (status) => {
  return (
    (status && constants.audits.DATA_PROCESS_CHIP_STATUS_COLOR[status]) ||
    undefined
  )
}

const processChipStatusBgColor = (status) => {
  return (
    (status &&
      constants.audits.DATA_PROCESS_CHIP_STATUS_BACKGROUND_COLOR[status]) ||
    undefined
  )
}

const kindTranslation = (kind) => {
  return (kind && constants.audits.KIND_TRANSLATION[kind]) || 'N/A'
}

export default {
  chipStatusLabel,
  chipStatusColor,
  chipStatusBgColor,
  processChipStatusLabel,
  processChipStatusColor,
  processChipStatusBgColor,
  kindTranslation,
}
