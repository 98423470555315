import React, { useState, useEffect } from 'react'

import { useForm, Controller } from 'react-hook-form'

import RncDocumentsProvider from 'providers/RncDocumentsProvider'
import RncActionProvider from 'providers/RncActionProvider'
import SpecificRisksProvider from 'providers/SpecificRisksProvider'

import { Box, Grid, TextField, Button, Typography } from '@material-ui/core'

import { DatePicker } from '@material-ui/pickers'
import { DataProcessIncidentTable, LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import usePermission from 'hooks/usePermissions'

import helpers from 'helpers'
import moment from 'moment'
import * as service from 'service'
import schema from './schema'
import constants from 'constants/index'

import TableDocuments from '../NonComplianceReportsDocuments'
import SpecificRisksTable from '../SpecificRisksTable'
import NonComplianceReportActionsTable from '../NonComplianceReportsActionsTable'

const NonComplianceReportsForm = ({
  nonComplianceReportId,
  nonComplianceReport,
  setDialogFinalize,
  isLoading,
  onEvent,
}) => {
  const { isDpo } = usePermission()
  const snackbar = useSnackbar()
  const [isFinalized, setIsFinalized] = useState(false)
  const [loading, setLoading] = useState(false)
  const [minDate, setMinDate] = useState()
  const { ON_TIME_STATUS, OVERDUE_STATUS } = constants.nonComplianceReports
  const status = nonComplianceReport?.status

  useEffect(() => {
    if (constants.nonComplianceReports.NOT_EDITABLE_STATUSES.includes(status)) {
      setIsFinalized(true)
    } else {
      setIsFinalized(false)
    }
    setMinDate(moment(new Date()).format('YYYY-MM-DD'))

    // eslint-disable-next-line
  }, [status])

  const { handleSubmit, getValues, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: {
      ...nonComplianceReport,
      cause: nonComplianceReport?.cause || '',
      deadlineDate: nonComplianceReport?.deadlineDate || null,
      nonCompliance: nonComplianceReport?.nonCompliance || '',
      actionPlanRecommendation:
        nonComplianceReport?.actionPlanRecommendation || '',
    },
  })

  const onSubmit = async (nonComplianceReport, finalizing = false) => {
    let originalDeadlineDate = nonComplianceReport.deadlineDate

    try {
      setLoading(!finalizing)

      if (nonComplianceReport.responsableUser) {
        nonComplianceReport.responsableUserId =
          nonComplianceReport.responsableUser.id
      }

      if (nonComplianceReport.deadlineDate) {
        nonComplianceReport.deadlineDate = moment(
          nonComplianceReport.deadlineDate,
        )
          .utc()
          .format('DD/MM/YYYY')
      }

      await service.dponet.nonComplianceReports.put({
        nonComplianceReportId,
        nonComplianceReport,
      })

      setLoading(false)

      if (finalizing) {
        if (nonComplianceReport.deadlineDate) {
          nonComplianceReport.deadlineDate = originalDeadlineDate
        }
      } else {
        onEvent()
        snackbar.open({
          message: 'Registro de melhoria contínua atualizado com sucesso',
          variant: 'success',
        })
      }
    } catch (error) {
      setLoading(false)

      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  const finalize = async () => {
    await onSubmit(getValues(), true)
    setDialogFinalize(true)
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <Grid container>
        <form
          id="rnc-form"
          onSubmit={handleSubmit((data) => onSubmit(data, false))}
        >
          <Grid item sm={8} xs={12}>
            <Box mt={3}>
              <Typography variant="subtitle2" color="textSecondary">
                Melhoria contínua
              </Typography>
              <Typography color="textPrimary" variant="body1">
                {nonComplianceReport?.nonCompliance ?? '-'}
              </Typography>
            </Box>
          </Grid>
          <Grid container id={constants.incidents.EDIT_INCIDENT_DRIVER_STEP_3}>
            <Grid item sm={4} xs={12}>
              <Box mt={3}>
                <Controller
                  as={
                    <DatePicker
                      fullWidth
                      format="DD/MM/yyyy"
                      label="Prazo"
                      disabled={isFinalized}
                      helperText={errors?.deadlineDate?.message}
                      error={!!errors.deadlineDate}
                      minDateMessage="O prazo não pode estar no passado"
                      inputVariant="outlined"
                      onChange={(newValue) => ({ value: newValue })}
                      minDate={minDate}
                    />
                  }
                  disabled={isDpo()}
                  control={control}
                  name="deadlineDate"
                  mode="onBlur"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              {nonComplianceReport?.entityType ===
              constants.nonComplianceReports.INCIDENT_FINALITY ? (
                <Box mt={3} mb={2}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        multiline
                        disabled={
                          nonComplianceReport?.entityType !==
                            constants.nonComplianceReports.ENTITY_INCIDENT ||
                          isFinalized
                        }
                        minRows={8}
                        label="Causa"
                        error={!!errors.cause}
                        helperText={errors?.cause?.message}
                        maxRows={30}
                      />
                    }
                    control={control}
                    disabled={isDpo()}
                    name="cause"
                    mode="onChange"
                  />
                </Box>
              ) : (
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Causa
                  </Typography>
                  {nonComplianceReport?.cause
                    ?.split('\n\n')
                    ?.map((causeParagraph, index) => (
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        align="justify"
                        paragraph
                        key={`cause-${index}`}
                      >
                        {causeParagraph}
                      </Typography>
                    ))}
                </Box>
              )}
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12}>
          <Box mt={1} mb={2}>
            <Typography variant="h5">
              Riscos específicos para a empresa
            </Typography>
          </Box>
          <SpecificRisksProvider
            nonComplianceSpecificRisks={nonComplianceReport?.specificRisks}
          >
            <SpecificRisksTable
              nonComplianceReportId={nonComplianceReport?.id}
              isLoading={isLoading}
              viewOnly={constants.nonComplianceReports.NOT_EDITABLE_STATUSES.includes(
                nonComplianceReport.status,
              )}
            />
          </SpecificRisksProvider>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3}>
            <Typography variant="subtitle2" color="textSecondary">
              Recomendação do plano de ação
            </Typography>
            {nonComplianceReport?.actionPlanRecommendation
              ?.split('\n\n')
              ?.map((actionPlanRecommendationParagraph, index) => (
                <Typography
                  color="textPrimary"
                  variant="body1"
                  align="justify"
                  paragraph
                  key={`action-plan-recommendation-${index}`}
                >
                  {actionPlanRecommendationParagraph}
                </Typography>
              ))}
          </Box>
        </Grid>
      </Grid>
      <Box mt={1} mb={2}>
        <Typography variant="h5">Planos de ação</Typography>
      </Box>
      <RncActionProvider
        nonComplianceReportActions={
          nonComplianceReport?.nonComplianceReportActions
        }
      >
        <NonComplianceReportActionsTable
          nonComplianceReportId={nonComplianceReport?.id}
          nonComplianceReport={nonComplianceReport}
          isLoading={isLoading}
          viewOnly={constants.nonComplianceReports.NOT_EDITABLE_STATUSES.includes(
            nonComplianceReport?.status,
          )}
        />
      </RncActionProvider>
      <Box mt={3} mb={2}>
        <Typography variant="h5">Documentos</Typography>
      </Box>
      <RncDocumentsProvider documents={nonComplianceReport?.documents}>
        <TableDocuments
          nonComplianceReportId={nonComplianceReport?.id}
          viewOnly={constants.nonComplianceReports.NOT_EDITABLE_STATUSES.includes(
            nonComplianceReport?.status,
          )}
        />
      </RncDocumentsProvider>
      {nonComplianceReport?.entityType === 'Incident' && (
        <Box my={3}>
          <DataProcessIncidentTable
            dataProcesses={nonComplianceReport?.dataProcesses}
            isLoading={isLoading}
          />
        </Box>
      )}
      <Box
        mt={2}
        display="flex"
        justifyContent="flex-end"
        width="100%"
        id={constants.incidents.EDIT_INCIDENT_DRIVER_STEP_7}
      >
        {!isFinalized && !isDpo() && (
          <Box>
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              form="rnc-form"
            >
              Salvar
            </Button>
          </Box>
        )}
        <Box pl={1}>
          <Button
            color="secondary"
            variant="contained"
            type="button"
            onClick={finalize}
            disabled={![ON_TIME_STATUS, OVERDUE_STATUS].includes(status)}
          >
            Finalizar
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default NonComplianceReportsForm
