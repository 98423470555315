import * as yup from 'yup'

const schemaPassword = (isNewUser) =>
  yup.object().shape({
    password: yup
      .string()
      .required('Senha é obrigatória!')
      .min(8, 'A senha deve ter pelo menos 8 caracteres')
      .matches(
        //eslint-disable-next-line
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ `!*@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/,
        '',
      ),
    passwordConfirmation: yup.string().when([], {
      is: () => isNewUser,
      then: yup
        .string()
        .required('Confirmação de senha é obrigatória!')
        .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais'),
      otherwise: yup.string(),
    }),
  })

export default schemaPassword
