import React, { useState } from 'react'
import { isEmpty } from 'lodash'

import helpers from 'helpers'
import * as service from 'service'

import useFetch from 'hooks/useFetch'

import { Page, Container, LoadingFeedback } from 'components'

import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'

import { CompanyServiceDialog, PartnerServiceCard } from './components'

import { TableCell } from 'components/Table'

import styles from './styles'

const useStyles = makeStyles(styles)

const MarketplaceMain = ({
  match: {
    params: { partnerServiceId },
  },
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [serviceLoading, setServiceLoading] = useState(false)
  const { mixpanel } = helpers

  const { response, isLoading } = useFetch(service.marketPlace.partners.get, {
    partnerServiceId,
  })

  const partnerService = response?.data?.partnerService
  const partnerName = partnerService?.partner?.name

  const handleClose = () => {
    setOpenModal(false)
    mixpanel.track('Marktplace', 'Modal de solicitação de serviço', {
      action: 'Clique no botão cancelar',
    })
  }

  const handleOpen = () => {
    setOpenModal(true)
    mixpanel.track('Marktplace', 'Serviço de parceiro', {
      action: `Marketplace_Solicitar_Serviço_Parceiro_${partnerName}`,
    })
  }

  const classes = useStyles()

  return (
    <Page title="Listagem">
      <Container maxWidth={false} className={classes.container}>
        <Paper className={classes.contentContainer}>
          <Grid container spacing={4}>
            <Grid xs={12} item>
              {isLoading ? (
                <LoadingFeedback open={isLoading} />
              ) : (
                <PartnerServiceCard
                  partnerService={partnerService}
                  isDisabled
                />
              )}
            </Grid>
            <Grid xs={8} item>
              <Typography variant="h3">Sobre a empresa</Typography>
              <Typography variant="body1" className={classes.description}>
                {partnerService?.partner?.description}
              </Typography>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="a dense table">
                  <TableBody>
                    <TableRow className={classes.tableHeader}>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.label}>
                          Fornecedor
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {partnerService?.partner?.tradeName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography className={classes.label}>
                          Categoria
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {partnerService?.companyService?.serviceCategory?.name}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                display="flex"
                mt={2}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={handleOpen}
                >
                  Solicitar serviço
                </Button>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h3">Características da solução</Typography>
              <ul className={classes.listContainer}>
                {isEmpty(partnerService?.resources?.attributes) ? (
                  <Typography variant="body1">
                    Nenhuma característica fornecida
                  </Typography>
                ) : (
                  partnerService?.resources?.attributes.map((attribute) => (
                    <li className={classes.listItem} key={attribute}>
                      <Typography variant="body1">{attribute}</Typography>
                    </li>
                  ))
                )}
              </ul>
            </Grid>
          </Grid>
        </Paper>
      </Container>
      {!isLoading && (
        <CompanyServiceDialog
          open={openModal}
          categoryName={partnerService?.companyService?.serviceCategory?.name}
          partnerName={partnerService?.partner?.name}
          serviceName={partnerService?.companyService?.name}
          partnerService={partnerService}
          onClose={handleClose}
          isLoading={serviceLoading}
          setIsLoading={setServiceLoading}
        />
      )}
    </Page>
  )
}

export default MarketplaceMain
