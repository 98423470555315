import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  wrapper: {
    justifyContent: 'center',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  main: {
    maginTop: '100px',
  },
}))

export default styles
