const STEP_LABELS = ['LOGO', 'SITE', 'COOKIES', 'CONFIGURAÇÃO', 'DOCUMENTOS']

const COMPANY_LOGO_TAG = 'company_logo'
const COMPANY_SITE_TAG = 'company_site'
const COOKIE_BANNER_TAG = 'cookie_banner'
const SITE_CONFIGURATION_TAG = 'site_configuration'
const COMPANY_DOCUMENTS_TAG = 'company_documents'
const CONCLUSION_TAG = 'conclusion'

const LOGO_FIRST_STEP_INDEX = [0, 0]
const LOGO_STEP_INDEX = [0, 1]
const SITE_CONFIRM_STEP_INDEX = [1, 0]
const SITE_FORM_INDEX = [1, 1]
const STEP_COOKIE_INFO_INDEX = [2, 0]
const STEP_COOKIE_CONFIRM_INDEX = [2, 1]
const STEP_COOKIE_CONFIG_INDEX = [3, 0]
const STEP_DOCS_INDEX = [4, 0]
const STEP_DOCS_FORM_INDEX = [4, 1]
const STEP_CONCLUSION_INDEX = [5, 0]

const STEP_LOGO = 0
const STEP_SITE = 1
const STEP_COOKIE = 2
const STEP_CONFIG = 3
const STEP_DOCS = 4
const STEP_CONCLUSION = 5

const HAS_BANNER_OBS = 'Já possui banner'

const userTrailSteps = {
  COMPANY_DOCUMENTS_TAG,
  COMPANY_LOGO_TAG,
  COMPANY_SITE_TAG,
  CONCLUSION_TAG,
  COOKIE_BANNER_TAG,
  HAS_BANNER_OBS,
  LOGO_FIRST_STEP_INDEX,
  LOGO_STEP_INDEX,
  SITE_CONFIGURATION_TAG,
  SITE_CONFIRM_STEP_INDEX,
  SITE_FORM_INDEX,
  STEP_CONCLUSION_INDEX,
  STEP_CONCLUSION,
  STEP_CONFIG,
  STEP_COOKIE_CONFIG_INDEX,
  STEP_COOKIE_CONFIRM_INDEX,
  STEP_COOKIE_INFO_INDEX,
  STEP_COOKIE,
  STEP_DOCS_FORM_INDEX,
  STEP_DOCS_INDEX,
  STEP_DOCS,
  STEP_LABELS,
  STEP_LOGO,
  STEP_SITE,
}

export default userTrailSteps
