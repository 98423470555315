import React, { memo, useRef } from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles'

const Input = ({
  disabled,
  index,
  onBackspace,
  onChange,
  onFocus,
  onPaste,
  value,
}) => {
  const ref = useRef()
  const classes = useStyles()

  const handleChange = (e) => {
    onChange(index, e.target.value)
  }

  const handlePaste = (e) => {
    onPaste(e.clipboardData.getData('text'))
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') onBackspace()
  }

  return (
    // Estou utilizando input pois a implementação é mais certeira, com o componente
    // do material estava dando conflito na utilização dos eventListeners
    <input
      className={classes.input}
      disabled={disabled}
      id={index}
      maxLength="1"
      onChange={handleChange}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      ref={ref}
      type="text"
      value={value}
    />
  )
}

Input.propTypes = {
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onBackspace: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onPaste: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

const arePropsEqual = (oldProps, newProps) => {
  return (
    oldProps.value === newProps.oldProps &&
    oldProps.disabled === newProps.disabled
  )
}

export default memo(Input, arePropsEqual)
