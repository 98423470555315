import logo from 'images/dponet-logo-dashboard.svg'
import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
  },
  container: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  headerText: {
    color: theme.palette.common.white,
  },
  containerBody: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  graphicCardContainer: {
    flexGrow: 1,
  },
  boxLogo: {
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'center',
    backgroundSize: 'contain',
  },
  tabs: {
    backgroundColor: theme.palette.white,
  },
  bannerContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  itemContainer: {
    cursor: 'pointer',
    maxWidth: theme.breakpoints.values.lg,
  },
  imageContainer: {
    objectPosition: 'center',
    width: '100%',
  },
  customPopover: {
    maxWidth: 500,
  },
  link: {
    fontWeight: 500,
    cursor: 'pointer',
  },
}))

export default styles
