import constants from 'constants/index'

const selectSourceOptions = (anotherSource) => {
  const options = [
    {
      id: constants.dataProcess.TITULAR_DATA_SOURCE,
      name:
        'O próprio titular fornece os dados (diretamente para a organização).',
    },
    {
      id: constants.dataProcess.DEPARTMENT_SOURCE,
      name: 'Outro departamento compartilha os dados com este departamento.',
    },
    {
      id: constants.dataProcess.THIRD_PARTY_SOURCE,
      name:
        'Um terceiro (de fora da organização) compartilha os dados com a organização.',
    },
    { id: constants.dataProcess.PUBLIC_SOURCE, name: 'Os dados são públicos.' },
  ]

  if (anotherSource) {
    options.push({ id: constants.dataProcess.ANOTHER_SOURCE, name: 'Outro' })
  }

  return options
}

export default selectSourceOptions
