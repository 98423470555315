import constants from 'constants/index'

const holderAffectedInformedStatus = (id) => {
  switch (id) {
    case constants.incidents.COMMUNICATION_HOLDER_OPTIONS[0].id:
      return constants.incidents.COMMUNICATION_HOLDER_OPTIONS[0].name
    case constants.incidents.COMMUNICATION_HOLDER_OPTIONS[1].id:
      return constants.incidents.COMMUNICATION_HOLDER_OPTIONS[1].name
    case constants.incidents.COMMUNICATION_HOLDER_OPTIONS[2].id:
      return constants.incidents.COMMUNICATION_HOLDER_OPTIONS[2].name
    case constants.incidents.COMMUNICATION_HOLDER_OPTIONS[3].id:
      return constants.incidents.COMMUNICATION_HOLDER_OPTIONS[3].name
    default:
      return 'N/A'
  }
}

export default holderAffectedInformedStatus
