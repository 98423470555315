import permissions from '../permissions'

const DOCUMENT_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.DOCUMENT.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.DOCUMENT.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.DOCUMENT.DESTROY,
  },
  {
    name: permissions.PERMISSIONS_PRIVATE_DOCUMENT_LABEL.LIST_PRIVATE_DOCUMENT,
    tag: permissions.DOCUMENT.LIST_PRIVATE_DOCUMENTS,
  },
]

export default DOCUMENT_LIST
