import { isEmpty } from 'lodash'

const defineProgress = (trails) => {
  if (isEmpty(trails)) return 0
  const concludedTrails = trails.filter((trail) => !!trail.concluded).length

  return parseInt((concludedTrails / trails.length) * 100)
}

export default defineProgress
