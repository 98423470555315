import api from 'service/api'

const dponetAPI = api.create('dponet')

const put = async ({ dataProcessId, dataCollectedId, ...dataCollecteds }) => {
  return await dponetAPI.put(
    `/data_processes/${dataProcessId}/data_collecteds/${dataCollectedId}/necessity_proporcionalities`,
    dataCollecteds,
  )
}

export default { put }
