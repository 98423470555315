import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { sum } from 'lodash'

import { Box, Container, IconButton } from '@material-ui/core'
import { GreenHighlighted, LoadingFeedback, RoundedButton } from 'components'
import { Text } from '../'
import { Download as DownloadIcon } from 'react-feather'

import { saveAs } from 'file-saver'

import useAuth from 'hooks/useAuth'

import * as service from 'service'

import useStyles from './styles'
import { getBaseURL } from 'service/env'

const Certification = ({
  viewerStep,
  trainningJourney,
  setShowActions,
  setStep,
}) => {
  const { loadUser } = useAuth()
  const [loading, setLoading] = useState(false)
  const [validCertification, setValidCertification] = useState()
  const totalQuestions = sum(
    trainningJourney?.trainningSteps?.map(
      (trainningStep) => trainningStep.questions.length,
    ),
  )
  const classes = useStyles()
  const backToFirstStep = () => {
    service.dponet.trainningJourneys.removeAnswers({
      trainningJourneyId: trainningJourney?.id,
    })
    setShowActions(true)
    setStep(1)
  }

  useEffect(
    () => {
      const validateCertification = async () => {
        setLoading(true)
        const response =
          await service.dponet.trainningJourneys.validateCertification({
            trainningJourneyId: trainningJourney?.id,
          })
        setValidCertification(response?.data)
        loadUser()
        setShowActions(response?.data?.success)
        setLoading(false)
      }

      if (!viewerStep) {
        validateCertification()
      }
    },
    //eslint-disable-next-line
  [])

  const certificationLink = `${getBaseURL('dponet')}/${
    validCertification?.object?.certification?.certification
  }`

  const downloadCertification = () => {
    saveAs(certificationLink, 'Treinamento de Conceitos da LGPD.png')
  }

  if (loading) return <LoadingFeedback open />

  return (
    <Container maxWidth="lg">
      {validCertification?.success ? (
        <>
          <Text color="primary" fontSize="7vh" variant="h1">
            Certificado
          </Text>
          <Box mt={4}>
            <Text fontSize="3.5vh" variant="h4">
              Parabéns, você acertou{' '}
              <GreenHighlighted>
                {validCertification?.object?.rightAnswers}/{totalQuestions}{' '}
                perguntas
              </GreenHighlighted>{' '}
              e conquistou seu certificado!
            </Text>
          </Box>

          <Box my={4} className={classes.boxCertification}>
            <img
              src={certificationLink}
              alt="certification-introduction"
              className={classes.certification}
            />
            <Box className={classes.boxButton}>
              <IconButton
                className={classes.button}
                onClick={downloadCertification}
              >
                <DownloadIcon size={24} />
              </IconButton>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Text color="primary" fontSize="7vh" variant="h1">
            Infelizmente você não atingiu o número mínimo de acertos para
            desbloquear o certificado.
          </Text>
          <Box mt={4}>
            <Text fontSize="3.5vh" variant="h4">
              Não se preocupe, você pode repetir o treinamento quantas vezes for
              necessário.
            </Text>
          </Box>
          <Box py={2} mt={2} display="flex" justifyContent="center">
            <RoundedButton
              withPadding
              variant="primary"
              onClick={backToFirstStep}
            >
              Repetir o treinamento
            </RoundedButton>
          </Box>
        </>
      )}
    </Container>
  )
}

Certification.propTypes = {
  viewerStep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  trainningJourney: PropTypes.object,
  setShowActions: PropTypes.func,
  setStep: PropTypes.func,
}
export default Certification
