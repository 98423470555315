const rncCharts = (statsRnc, permitted) => {
  return {
    title: 'RMCs em aberto',
    type: 'rnc',
    visible: permitted('view_dashboard_rnc'),
    tabs: [
      {
        value: 0,
        label: 'ORIGEM',
      },
      {
        value: 1,
        label: 'PROCESSOS',
      },
    ],
    tabsPanels: [
      {
        value: 0,
        id: 'rnc-0',
        data: [
          statsRnc?.incidentNonComplianceReports?.total,
          statsRnc?.questionNonComplianceReports?.total,
          statsRnc?.processNonComplianceReports?.total,
        ],
        labels: ['INCIDENTES', 'Questionários', 'PROCESSOS'],
        items: [
          {
            title: 'PROCESSOS',
            subtitle: statsRnc?.processNonComplianceReports?.total,
          },
          {
            title: 'Questionários',
            subtitle: statsRnc?.questionNonComplianceReports?.total,
          },
          {
            title: 'INCIDENTES',
            subtitle: statsRnc?.incidentNonComplianceReports?.total,
          },
        ],
      },
      {
        value: 1,
        id: 'rnc-1',
        data: [
          statsRnc?.processNonComplianceReports?.necessityAndFragility,
          statsRnc?.processNonComplianceReports?.necessity,
          statsRnc?.processNonComplianceReports?.fragility,
        ],
        labels: [
          'RISCO E NECESSIDADE E PROPORCIONALIDADE',
          'NECESSIDADE E PROPORCIONALIDADE',
          'RISCO',
        ],
        items: [
          {
            title: 'RISCO',
            subtitle: statsRnc?.processNonComplianceReports?.fragility,
          },
          {
            title: 'NECESSIDADE E PROPORCIONALIDADE',
            subtitle: statsRnc?.processNonComplianceReports?.necessity,
          },
          {
            title: 'RISCO E NECESSIDADE E PROPORCIONALIDADE',
            subtitle:
              statsRnc?.processNonComplianceReports?.necessityAndFragility,
          },
        ],
      },
    ],
  }
}

export default rncCharts
