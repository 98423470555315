import React from 'react'
import { Box, Button, Chip, Typography } from '@material-ui/core'
import { User as UserIcon } from 'react-feather'
import { Paperclip as PaperclipIcon } from 'react-feather'

import humps from 'humps'

import constants from 'constants/index'

import useStyles from './styles'
import helpers from 'helpers'

const ConsentLogBox = ({ consentFormAnswer }) => {
  const classes = useStyles()

  return (
    <Box paddingTop={2}>
      <Typography variant="h5" color="primary">
        Histórico de Consentimentos
      </Typography>

      {consentFormAnswer?.answerAudits.map((answerAudit, index) => {
        const platform =
          humps.decamelizeKeys(consentFormAnswer?.platform) ?? 'privacy_portal'

        return (
          <Box className={classes.requesterBoxContainer} key={index}>
            <Box className={classes.requesterHeader}>
              <Box display="flex">
                <UserIcon className={classes.userIconBox} />
                <Box>
                  <Typography color="primary">
                    {answerAudit?.name +
                      ' - ' +
                      constants.consent.PLATFORM_LABEL_CONSENT_HISTORY[
                        platform
                      ]}
                  </Typography>
                  <Typography color="textSecondary">
                    {answerAudit?.remoteAddress} |&nbsp;
                    {helpers.formatters.date(answerAudit?.createdAt)} às&nbsp;
                    {helpers.formatters.hours(answerAudit?.createdAt)}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Chip
                  className={
                    answerAudit?.status === constants.consent.AUTHORIZED_CONSENT
                      ? classes.statusLabelAuthorized
                      : classes.statusLabelRevoked
                  }
                  label={
                    constants.consent.STATUS_LABEL_RECEIPT[answerAudit?.status]
                  }
                />
              </Box>
            </Box>
            <Typography
              className={classes.contentRequesterLine}
              color="textSecondary"
            >
              {constants.consent.CONSENT_HISTORY_CONTENT[platform]}
            </Typography>

            {consentFormAnswer.attachments?.map((attachment) => (
              <Box
                width="100%"
                className={classes.lineObservation}
                paddingLeft={1}
              >
                <Button
                  startIcon={<PaperclipIcon />}
                  className={classes.buttonLink}
                  variant="text"
                  color="primary"
                  href={attachment.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {attachment.name}
                </Button>
              </Box>
            ))}

            {consentFormAnswer?.observation && (
              <Box p={1} className={classes.lineObservation}>
                <Typography color="textSecondary">
                  Observação: {consentFormAnswer?.observation}
                </Typography>
              </Box>
            )}
          </Box>
        )
      })}
    </Box>
  )
}

export default ConsentLogBox
