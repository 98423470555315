import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async () => {
  return await dponetAPI.get('/data_processes/template')
}

export default {
  get,
}
