import helpers from 'helpers'

const mountNecessityAndProportionalityParams = (data, dataTreatmentId) => {
  let dataTreatment = {}

  Object.keys(data).map((key) => {
    let splitKey = key.split('-')
    let keyType = splitKey[0]
    let keyId = splitKey[1]

    if (keyType === 'dataTreatment' && +keyId === dataTreatmentId) {
      return (dataTreatment = {
        necessary: helpers.formatters.convertBinaryToBool(data[key]),
      })
    }

    return key
  })

  return dataTreatment
}

export default mountNecessityAndProportionalityParams
