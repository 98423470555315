import { colors } from '@material-ui/core'

import constants from 'constants/index'

const readingTypeTranslation = (kind) => {
  return (
    constants.cookies.consent.READING_TYPE_TRANSLATION[kind] || 'indeferido'
  )
}

const chipStatusColor = (kind) => {
  return (
    (kind && constants.cookies.consent.STATUS_CHIP_COLOR[kind]) ||
    colors.grey[300]
  )
}

const chipFontStatusColor = (kind) => {
  return (
    (kind && constants.cookies.consent.STATUS_CHIP_FONT_COLOR[kind]) ||
    colors.grey[800]
  )
}

export default {
  readingTypeTranslation,
  chipStatusColor,
  chipFontStatusColor,
}
