import helpers from 'helpers'

import components from '../../components'

import { WIDTH as WIDTHPdf, GAP, PADDING } from '../../constants'

const securityMeasures = (pdf, date, securityMeasuresData) => {
  const WIDTH = WIDTHPdf(pdf) - PADDING * 2
  const columStyles = {
    0: {
      cellWidth: 0.25 * WIDTH,
      halign: 'left',
    },
    1: {
      cellWidth: 0.5 * WIDTH,
      halign: 'center',
    },
    2: {
      halign: 'center',
    },
    3: {
      halign: 'center',
    },
  }

  const mountedSecurityMeasures = {}

  securityMeasuresData.forEach((securityMeasure) => {
    const { questionnaireKind } = securityMeasure
    if (!mountedSecurityMeasures[questionnaireKind]) {
      mountedSecurityMeasures[questionnaireKind] = []
    }
    mountedSecurityMeasures[questionnaireKind].push(securityMeasure)
  })

  const generateRows = (array) => {
    array = array.map((securityMeasure) => [
      securityMeasure.title,
      securityMeasure.subtitle,
      securityMeasure.answer ?? '-',
      helpers.questionControls.status(securityMeasure.status, true),
    ])
    array.unshift(['Categoria', 'Medida de Segurança', 'Resposta', 'Status'])
    return array
  }

  const securityMeasureKeys = Object.keys(mountedSecurityMeasures)

  pdf.addPage()
  components.lineWithDate(pdf, date)
  components.subtitle(pdf, 'Medidas de Segurança', GAP)

  securityMeasureKeys.forEach((securityMeasureKind, index) => {
    let startY = index === 0 ? GAP * 1.25 : pdf.autoTable.previous.finalY + 16

    components.table(
      pdf,
      [[securityMeasureKind, null, null, null]],
      generateRows(mountedSecurityMeasures[securityMeasureKind]),
      startY,
      0.2,
      PADDING,
      false,
      columStyles,
    )
  })
}

export default securityMeasures
