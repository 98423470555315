import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { LoadingFeedback } from 'components'

import useAuth from 'hooks/useAuth'
import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'
import { Controller, useForm } from 'react-hook-form'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'
import schema from './schema'
import useStyles from './styles'
import countText from 'helpers/functions/countText'

const RipdForm = ({
  ropa,
  ripd,
  callback,
  renewReportVersion,
  setTriggerValidation,
}) => {
  const classes = useStyles()
  const { company, setFreeTrialBlock } = useAuth()
  const snackbar = useSnackbar()

  const [open, setOpen] = useState(false)
  const [willGetData, setWillGetData] = useState(true)

  const { response, isLoading } = useFetch(
    service.dponet.departments.byProcess,
    null,
    [willGetData],
    open && willGetData,
  )
  const { response: legalFrameworks } = useFetch(
    service.dponet.legalFrameworks.listByCompany,
    null,
    [willGetData],
    open && willGetData,
  )

  const { handleSubmit, control, errors, reset, watch, triggerValidation } =
    useForm({
      validationSchema: schema(
        company?.statusId !== constants.userCompanies.TRIAL_LIMITED_STATUS &&
          ripd,
      ),
      defaultValues: {
        justificationReport: '',
        comment: '',
        fragilities: [],
        departments: [],
        legalFrameworks: [],
        anotherJustificationReport: '',
      },
    })

  useEffect(
    () => {
      setTriggerValidation(() => triggerValidation)
    },
    //eslint-disable-next-line
    [],
  )

  const handleClick = () => {
    if (open && willGetData) setWillGetData(false)
    setOpen((open) => !open)
  }

  const onSubmit = async (data) => {
    if (company?.statusId === constants.userCompanies.TRIAL_LIMITED_STATUS) {
      return setFreeTrialBlock(true)
    }

    var justificationReport = ''

    if (ripd) {
      justificationReport =
        data?.justificationReport === 'another'
          ? data?.anotherJustificationReport
          : data?.justificationReport
    }

    const fragilities = helpers?.ripds?.convertRisk(data?.fragilities, ripd)

    try {
      const response = await service.dponet.ripds.exportsRipd({
        ripds: {
          ...data,
          justificationReport,
          fragilities,
          ropa,
          ripd,
          renewReportVersion,
        },
      })

      snackbar.open({
        message:
          response?.data?.message ||
          `O relatório de ${
            ropa ? 'RoPA' : 'conformidade'
          } vai ser enviado para o seu email.`,
        variant: 'success',
      })
      reset()
      if (!!callback) callback()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) ||
          `Não foi possível gerar o relatório ${
            ropa ? 'RoPA' : 'de conformidade'
          }.`,
        variant: 'error',
      })
    }
  }

  const riskOptions = ropa
    ? constants?.ripdRisk?.RISK_ROPA
    : constants?.ripdRisk?.RISK

  const anotherJustification = watch('justificationReport') === 'another'
  const justificationReport = watch('justificationReport')

  return (
    <Box>
      <LoadingFeedback open={open && isLoading} />
      <form id="report-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          {ripd && (
            <>
              <Grid item xs={12}>
                <InputLabel className={classes.label}>
                  Justificativas para geração do relatório*
                </InputLabel>
                <Typography variant="caption" className={classes.subLabel}>
                  A justificativa selecionada não irá alterar as informações
                  contidas no relatório.
                </Typography>
                <Controller
                  control={control}
                  name="justificationReport"
                  mode="onChange"
                  error={!!errors.justificationReport}
                  as={
                    <Select
                      IconComponent={ExpandMore}
                      className={classes.input}
                      variant="outlined"
                      fullWidth
                      placement="bottom"
                      displayEmpty
                    >
                      {constants?.ripdRisk?.JUSTIFICATION?.map((item) => (
                        <MenuItem key={item?.name} value={item?.name}>
                          <Tooltip title={item?.name} placement="bottom">
                            <Typography className={classes.menuItem}>
                              {item?.name}
                            </Typography>
                          </Tooltip>
                        </MenuItem>
                      ))}
                      <MenuItem key="another" value="another">
                        <Typography className={classes.menuItem}>
                          Outros
                        </Typography>
                      </MenuItem>
                    </Select>
                  }
                />
                {!!errors.justificationReport && (
                  <FormHelperText error className={classes.textHelper}>
                    {errors.justificationReport.message}
                  </FormHelperText>
                )}
                {anotherJustification ? (
                  <Controller
                    control={control}
                    mode="onChange"
                    name="anotherJustificationReport"
                    error={!!errors.anotherJustificationReport}
                    helperText={countText(
                      errors?.anotherJustificationReport?.message,
                      watch('anotherJustificationReport')?.length,
                      constants?.ripdRisk?.CHARACTER_LIMIT,
                    )}
                    as={
                      <TextField
                        className={clsx(classes.extraInput, classes.textInput)}
                        variant="outlined"
                        multiline
                        minRows={3}
                        maxRows={10}
                        placeholder="Descreva a outra justificativa"
                        fullWidth
                      />
                    }
                  />
                ) : (
                  justificationReport && (
                    <Box mt={1}>
                      <Typography className={classes.alert}>
                        {helpers?.ripds?.descriptionReport(
                          justificationReport,
                          ropa,
                        )}
                      </Typography>
                    </Box>
                  )
                )}
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  <InputLabel className={classes.label}>Comentário</InputLabel>
                  <Typography className={classes.subLabel}>
                    (opcional)
                  </Typography>
                </Box>
                <Controller
                  control={control}
                  name="comment"
                  mode="onChange"
                  error={!!errors.comment}
                  helperText={countText(
                    errors?.comment?.message,
                    watch('comment')?.length,
                    constants?.ripdRisk?.CHARACTER_LIMIT,
                  )}
                  as={
                    <TextField
                      className={classes.textInput}
                      variant="outlined"
                      multiline
                      minRows={3}
                      maxRows={10}
                      fullWidth
                    />
                  }
                />
              </Grid>
            </>
          )}
          <>
            <Grid item xs={12}>
              {open && (
                <Box pb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Box display="flex">
                        <InputLabel className={classes.label}>
                          Riscos de processos
                        </InputLabel>
                        <Typography className={classes.subLabel}>
                          (opcional)
                        </Typography>
                      </Box>
                      <Controller
                        as={
                          <Select
                            IconComponent={ExpandMore}
                            className={classes.input}
                            variant="outlined"
                            fullWidth
                            placement="bottom"
                            displayEmpty
                          >
                            {riskOptions.map((item) => (
                              <MenuItem key={item?.name} value={item?.name}>
                                <Tooltip title={item?.name} placement="bottom">
                                  <Typography className={classes.menuItem}>
                                    {item?.name}
                                  </Typography>
                                </Tooltip>
                              </MenuItem>
                            ))}
                          </Select>
                        }
                        control={control}
                        name="fragilities"
                        mode="onChange"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex">
                        <InputLabel className={classes.label}>
                          Departamento(s)
                        </InputLabel>
                        <Typography className={classes.subLabel}>
                          (opcional)
                        </Typography>
                      </Box>
                      <Controller
                        control={control}
                        name="departments"
                        onChange={([, data]) => data}
                        mode="onChange"
                        as={
                          <Autocomplete
                            popupIcon={<ExpandMore />}
                            className={classes.input}
                            multiple
                            options={response?.data?.departments ?? []}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) =>
                              option.id === value.id || value.id === 0
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                error={!!errors.departments}
                                helperText={errors?.departments?.message}
                              />
                            )}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex">
                        <InputLabel className={classes.label}>
                          Hipótese Legal
                        </InputLabel>
                        <Typography className={classes.subLabel}>
                          (opcional)
                        </Typography>
                      </Box>
                      <Controller
                        control={control}
                        name="legalFrameworks"
                        onChange={([, data]) => data}
                        mode="onChange"
                        as={
                          <Autocomplete
                            popupIcon={<ExpandMore />}
                            className={classes.input}
                            multiple
                            options={
                              legalFrameworks?.data?.legalFrameworks ?? []
                            }
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) =>
                              option.id === value.id || value.id === 0
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                error={!!errors?.legalFrameworks}
                                helperText={errors?.legalFrameworks?.message}
                              />
                            )}
                          />
                        }
                      />
                    </Grid>
                    {/* Conteudo comentado sera implementado na proxima issue! */}

                    {/* <Box display="flex" alignItems="center">
                    <Checkbox color="primary" />
                    <Box display="flex" flexDirection="column">
                      <Typography className={classes.checkboxTitle}>
                        Gerar versão pública do RIPD
                      </Typography>
                      <Typography className={classes.checkboxDescription}>
                        Essa opção esconderá informações sigilosas e sensíveis
                        sobre os riscos da empresa.
                      </Typography>
                    </Box>
                  </Box>
                  <Grid item xs={12} className={classes.alert}>
                    <Typography>
                      <strong>Atenção:</strong> Caso alguma autoridade solicite
                      seu RIPD, a versão COMPLETA deverá ser entregue.
                    </Typography>
                    <Typography>
                      Nesse caso, não utilize a versão pública!
                    </Typography>
                  </Grid> */}
                  </Grid>
                </Box>
              )}
              <Button
                variant="text"
                startIcon={open ? <ExpandLess /> : <ExpandMore />}
                onClick={handleClick}
                color="primary"
              >
                {open ? 'Ocultar' : 'Filtros'}
              </Button>
              <Divider className={classes.divider} />
            </Grid>
          </>
        </Grid>
      </form>
    </Box>
  )
}

RipdForm.propTypes = {
  ropa: PropTypes.bool,
  ripd: PropTypes.bool,
  callback: PropTypes.func,
  renewReportVersion: PropTypes.bool,
  setTriggerValidation: PropTypes.func,
}

RipdForm.defaultProps = {
  ropa: false,
  callback: () => {},
  setTriggerValidation: () => {},
}

export default RipdForm
