import React, { useState } from 'react'

import useBeforeunload from 'hooks/useBeforeunload'
import IncidentContext from 'contexts/IncidentContext'

const IncidentProvider = ({ children }) => {
  useBeforeunload(() => {
    return 'Deseja realmente sair do site?'
  })

  const [activeStep, setActiveStep] = useState(0)
  const [lastActiveStep, setLastActiveStep] = useState(0)
  const [toNextStep, setToNextStep] = useState(false)
  const [counter, setCounter] = useState(0)

  const reloadOptions = () => {
    setCounter(counter + 1)
  }

  return (
    <IncidentContext.Provider
      value={{
        activeStep,
        setActiveStep,
        lastActiveStep,
        setLastActiveStep,
        toNextStep,
        setToNextStep,
        reloadOptions,
        counter,
      }}
    >
      {children}
    </IncidentContext.Provider>
  )
}

export default IncidentProvider
