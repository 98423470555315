import { routes } from 'Routes'
import constants from 'constants/index'

import definestatus from './defineStatus'
import defineSubtitle from './defineSubtitle'

const { COMPANY_DOCUMENTS_TAG, COMPANY_LOGO_TAG, COMPANY_SITE_TAG } =
  constants.userTrailSteps

const mountTrails = (trails, history) => {
  const goToStep = (step) =>
    history.push(`${routes.userTrailSteps}?step=${step}`)

  const getLabel = (tag) => {
    if (
      [COMPANY_DOCUMENTS_TAG, COMPANY_LOGO_TAG, COMPANY_SITE_TAG].includes(tag)
    ) {
      return 'Editar'
    }

    return 'Visualizar'
  }

  var lastTrail = {}

  return trails
    ?.filter((trail) => !!trail.tag && trail.tag !== 'conclusion')
    .map((trail) => {
      const newTrail = {
        title: trail.name,
        subtitle: defineSubtitle(trail),
        status: definestatus(trail),
        concluded: trail.completed,
        jumped: trail.jumped,
        action: {
          text: getLabel(trail.tag),
          method: () => goToStep(trail.tag),
        },
      }
      if (lastTrail.completed && !trail.completed) {
        newTrail.status = constants.companyTrail.DOING_STATUS
      }
      lastTrail = trail
      return newTrail
    })
}

export default mountTrails
