const mountRowName = (index) => {
  switch (index) {
    case 0:
      return 'Pendente'
    case 1:
      return 'No prazo'
    case 2:
      return 'Vencido'
    case 3:
      return 'Risco assumido'
    default:
      return 'Finalizado'
  }
}

export default mountRowName
