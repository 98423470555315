import React, { useEffect, useState } from 'react'

import {
  TextField,
  Grid,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
} from '@material-ui/core'

import { Controller } from 'react-hook-form'

import { Label } from 'components'

import constants from 'constants/index'

const Description = ({ control, checkedValue, setValue }) => {
  const [selected, setSelected] = useState('')

  useEffect(() => {
    if (checkedValue) {
      setSelected(checkedValue)
    }
    // eslint-disable-next-line
  }, [])

  const isChecked = (value) => selected === value

  useEffect(() => {
    setValue('metAboveRequirement', selected)
  }, [selected, setValue])

  return (
    <>
      <Grid item xs={12}>
        <Box px={2}>
          <Label title="Descreva, como ocorreu a comunicação">
            <Controller
              as={
                <TextField
                  type="text"
                  color="primary"
                  variant="outlined"
                  fullWidth
                />
              }
              control={control}
              name="holderCommunicationOccurred"
              mode="onBlur"
            />
          </Label>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Label title="Quantos titulares foram comunicados individualmente sobre o incidente?">
            <Controller
              as={
                <TextField
                  type="text"
                  color="primary"
                  variant="outlined"
                  fullWidth
                />
              }
              control={control}
              name="holderCommunicatedIndividually"
              mode="onBlur"
            />
          </Label>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Label title="Justifique, se cabível, o que motivou a não realização da comunicação individual aos titulares:">
            <Controller
              as={
                <TextField
                  type="text"
                  rows={5}
                  multiline
                  color="primary"
                  variant="outlined"
                  fullWidth
                />
              }
              control={control}
              name="holderNotCommunicatedIndividually"
              mode="onBlur"
            />
          </Label>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Box p={2} mt={2} style={{ backgroundColor: '#FFF7E0' }}>
            <Box mb={1}>
              <Typography variant="body1" style={{ fontWeight: '900' }}>
                O comunicado ao titulares deve utilizar linguagem clara e
                conter, ao menos, as seguintes informações:
              </Typography>
            </Box>
            <Box ml={0.5}>
              <Typography variant="body2">
                1. resumo e data da ocorrência do incidente;
              </Typography>
              <Typography variant="body2">
                2. descrição dos dados pessoais afetados;
              </Typography>
              <Typography variant="body2">
                3. risco e consequências aos titulares de dados;
              </Typography>
              <Typography variant="body2">
                4. medidas tomadas e recomendadas para mitigar seus efeitos, se
                cabíveis;
              </Typography>
              <Typography variant="body2">
                5. dados de contato do controlador para obtenção de informações
                adicionais sobre o incidente.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2} mt={2}>
          <Box p={1} style={{ backgroundColor: '#F4F6F8' }}>
            <Typography variant="body1" style={{ fontWeight: '900' }}>
              O comunicado aos titulares atendeu os requisitos acima?
            </Typography>
            <FormControl size="small" variant="outlined">
              <FormGroup row>
                {constants.incidents.COMMUNICATION_REQUIRED?.map((option) => {
                  return (
                    <FormControlLabel
                      control={
                        <Controller
                          name="metAboveRequirement"
                          onChange={() => setSelected(option?.id)}
                          as={
                            <Radio
                              size="medium"
                              checked={isChecked(option?.id)}
                            />
                          }
                          control={control}
                        />
                      }
                      label={option?.name}
                      key={option?.id}
                    />
                  )
                })}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box px={2}>
          <Box p={2} mt={2} style={{ backgroundColor: '#FFF7E0' }}>
            <Typography variant="body1">
              Se não atendidos os requsitos, o comunicado aos titulares deverá
              ser devidamente retificado. Poderá ser solicitada pela ANPD, a
              qualquer tempo, cópia do comunicado aos titulares para fins de
              fiscalização.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default Description
