import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
  },
}))

export default styles
