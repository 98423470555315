import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

import { CardsList } from '..'

import useStyles from './styles'
import theme from 'theme'

const CardsListSection = ({
  listItems,
  title,
  description,
  cardWithoutAction,
  id,
  iconsSize,
  ...props
}) => {
  const classes = useStyles(props)()

  return (
    <Box className={classes.container} id={id}>
      <Typography className={classes.sectionTitle}>{title}</Typography>
      {description && <Typography>{description}</Typography>}
      <CardsList
        listItems={listItems}
        cardWithoutAction={cardWithoutAction}
        iconsSize={iconsSize}
        {...props}
      />
    </Box>
  )
}

CardsListSection.propTypes = {
  listItems: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  bgColor: PropTypes.string,
  primaryColor: PropTypes.string,
  cardWithoutAction: PropTypes.bool,
  id: PropTypes.string,
}

CardsListSection.defaultProps = {
  description: '',
  id: '',
  bgColor: theme.palette.white,
  primaryColor: theme.palette.white,
  cardWithoutAction: false,
  iconsSize: 72,
}

export default CardsListSection
