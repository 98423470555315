const statusLabel = (status) => {
  switch (status) {
    case '1':
      return 'Rascunho'
    case '2':
      return 'Ativo'
    case '3':
      return 'Inativo'
    default:
      return ''
  }
}

export default statusLabel
