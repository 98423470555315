const options = (theme) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 75,

    legend: {
      display: false,
    },
    elements: {
      arc: {
        borderWidth: 12,
        borderColor: '#FFF',
        angle: 90,
      },
    },
    layout: {
      padding: 0,
    },
    tooltips: {
      displayColors: false,
      enabled: true,
      mode: 'point',
      intersect: false,
      caretSize: 5,
      yPadding: 10,
      xPadding: 10,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.primary.main,
      titleFontColor: theme.palette.primary.contrastText,
      bodyFontColor: theme.palette.primary.contrastText,
      footerFontColor: theme.palette.primary.contrastText,
      callbacks: {
        label(tooltipItem, _data) {
          const label = _data.labels[tooltipItem.index]
          const value = _data.datasets[0].data[tooltipItem.index]

          return `${label}: ${value}`
        },
      },
    },
  }
}

export default options
