import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Box, Button, Card, CardHeader, Divider } from '@material-ui/core'

import { Container, ContentHeader, Page } from 'components'
import {
  SupplierForm,
  ConfirmationDialog,
  SuccessDialog,
  QuestionnaireSupplier,
} from './components'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'

const ClientNew = () => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const { mixpanel } = helpers

  const [submitted, setSubmitted] = useState(false)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [international, setInternational] = useState(false)

  const [supplier, setSupplier] = useState({})
  const [getValues, setGetValues] = useState(() => () => {})
  const [questionnaires, setQuestionnaires] = useState([])

  const handleBack = () => {
    mixpanel.track('Fornecedores', 'Novo fornecedor', {
      action: !openSuccess
        ? 'clique no botão voltar'
        : 'novo fornecedor cadastrado',
      name: getValues('name'),
      document: getValues('document'),
      email: getValues('email'),
    })
    return history.goBack()
  }

  const confirmationCallback = async () => {
    try {
      setSubmitted(true)
      if (!international) {
        if (supplier.document.length > 14) {
          supplier.cnpj = supplier.document
        } else {
          supplier.cpf = supplier.document
        }
        delete supplier.document
      }
      if (questionnaires) {
        supplier.questionnaireKinds = questionnaires
      }

      await service.dponet.suppliers.create({
        companySupplier: { supplier },
      })
      setOpenSuccess(true)
    } catch (error) {
      setSubmitted(false)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro ao criar um novo fornecedor.',
        variant: 'error',
      })
    }
  }

  const onSubmit = (supplier) => {
    setOpenConfirmation(true)
    setSupplier(supplier)
  }

  return (
    <Page title="Novo fornecedor">
      <Container spacingXl={8} spacingLg={12}>
        <ContentHeader title="Novo fornecedor" />
        <Card>
          <CardHeader title="Dados básicos" />
          <Divider />
          <Box p={2}>
            <SupplierForm
              onSubmit={onSubmit}
              companySupplier={supplier}
              setGetValues={setGetValues}
              setInternational={setInternational}
              international={international}
              questionnaires={questionnaires}
              setOpenSuccess={setOpenSuccess}
            />
          </Box>
        </Card>

        <Box mt={2}>
          <QuestionnaireSupplier
            questionnaires={questionnaires}
            setQuestionnaires={setQuestionnaires}
          />
        </Box>

        {/* Implementar quando for definido o upload de contrato do fornecedor
        <Box mt={3}>
          <Card>
            <CardHeader title="Contrato" />
            <Divider />
            <Box p={2}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<UploadIcon size={18} />}
                component="label"
              >
                <Hidden xsDown>
                  <Typography>Adicionar contrato</Typography>
                </Hidden>
                <input
                  type="file"
                  id="logo"
                  className={classes.input}
                  onChange={() => {}}
                />
              </Button>
            </Box>
          </Card>
        </Box> */}

        <Box mt={4} display="flex">
          <Button variant="contained" onClick={handleBack}>
            Voltar
          </Button>

          <Box ml={4}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form="form-supplier"
              disabled={submitted}
            >
              Salvar
            </Button>
          </Box>
        </Box>
      </Container>
      <ConfirmationDialog
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        handleConfirm={confirmationCallback}
        actionType={constants.companySuppliers.CREATE_ACTION}
        title="O fornecedor será notificado!"
        subtitle={constants.companySuppliers.CREATION_TEXT}
      />
      <SuccessDialog
        open={openSuccess}
        setOpen={setOpenSuccess}
        isSupplier={false}
        title="Cadastrado!"
        subtitle="O fornecedor foi cadastrado com sucesso!"
        reload={handleBack}
      />
    </Page>
  )
}

export default ClientNew
