import dataType from './dataType'
import reportStatus from './reportStatus'
import status from './status'

const auditProcess = {
  dataType,
  reportStatus,
  status,
}

export default auditProcess
