const activeProcess = (processStats) => {
  return processStats?.departments?.reduce(
    (value, department) =>
      value +
      department.statuses?.pending +
      department.statuses?.reviewing +
      department.statuses?.approved +
      department.statuses?.disapproved +
      department.statuses?.reviewingLia,
    0,
  )
}

export default activeProcess
