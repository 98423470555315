import React from 'react'

import { makeStyles, Box } from '@material-ui/core'

import { Container } from 'components'

import styles from './styles'
const useStyles = makeStyles(styles)

const Footer = () => {
  const classes = useStyles()

  return (
    <Box className={classes.footer}>
      <Container
        maxWidth={false}
        spacingXl={50}
        spacingLg={30}
        spacingMd={10}
        spacingSm={8}
        spacingXs={5}
      >
        <Box display="flex" justifyContent="center">
          <img
            src="https://api.dponet.com.br/selo_portal_de_privacidade.png"
            alt="selo dponet"
            className={classes.privacyImage}
          />
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
