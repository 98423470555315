import React from 'react'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Box, Paper } from '@material-ui/core'

import { SVG } from 'components'

import useStyles from './styles'

const LogoPreview = ({ banner }) => {
  const classes = useStyles()
  const { watch } = useFormContext()

  const selectedFile = watch('logo')

  const handleFileChange = () => {
    if (selectedFile) return URL.createObjectURL(selectedFile)
    return banner?.logo?.url
  }

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Paper variant="outlined" className={classes.paperContainer}>
        {selectedFile || banner?.logo?.url ? (
          <img src={handleFileChange()} alt="Logotipo do banner" height={70} />
        ) : (
          <SVG.DPOEasy />
        )}
      </Paper>
    </Box>
  )
}

LogoPreview.propTypes = {
  banner: PropTypes.object,
}

export default LogoPreview
