import React from 'react'
import { Copy as CopyIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { Box, IconButton, Paper, Typography } from '@material-ui/core'

import useClipboard from 'hooks/useClipboard'

import theme from 'theme'
import useStyles from './styles'
import { getBaseURL } from 'service/env'

const BannerCode = ({ bannerToken }) => {
  const classes = useStyles()
  const { copy } = useClipboard()

  const divCode = '<div id="dpoeasy-banner">'
  const scriptCode = `<script src="${getBaseURL(
    'cookies',
  )}/banners/install_code?token=${bannerToken}"/></div>`

  const handleCodeCopying = () => copy(`${divCode}\n${scriptCode}`)

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
      <Box>
        <Typography color="primary" variant="h6" gutterBottom>
          Código HTML de instalação do banner
        </Typography>
        <Typography color="primary" variant="body2">
          {`Selecione, copie e cole o código no <body> do seu arquivo HTML`}
        </Typography>
      </Box>
      <Paper variant="outlined">
        <Box bgcolor={theme.palette.background.default}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" className={classes.divCodeTypography}>
              {divCode}
            </Typography>
            <Box height="fit-content">
              <IconButton
                size="small"
                className={classes.iconButton}
                onClick={handleCodeCopying}
              >
                <CopyIcon size={18} color={theme.palette.custom.superDark} />
              </IconButton>
            </Box>
          </Box>
          <Typography variant="body2" className={classes.scriptCodeTypography}>
            {scriptCode}
          </Typography>
        </Box>
      </Paper>
    </Box>
  )
}

BannerCode.propTypes = {
  bannerToken: PropTypes.string,
}

export default BannerCode
