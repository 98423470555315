import React from 'react'
import { sum } from 'lodash'
import PropTypes from 'prop-types'

import { DashboardChartPaper, DonutChart } from 'components'

import theme from 'theme'

const StatusByProcess = ({ processStats, id, ...rest }) => {
  const getDataByStatus = (department, status) =>
    department?.statuses[status] || 0

  const dataPending = sum(
    processStats?.departments?.map((department) =>
      getDataByStatus(department, 'pending'),
    ),
  )

  const dataApproved = sum(
    processStats?.departments?.map((department) =>
      getDataByStatus(department, 'approved'),
    ),
  )
  const dataDisapproved = sum(
    processStats?.departments?.map((department) =>
      getDataByStatus(department, 'disapproved'),
    ),
  )
  const dataInactive = sum(
    processStats?.departments?.map((department) =>
      getDataByStatus(department, 'Inactive'),
    ),
  )
  const dataReviewing = sum(
    processStats?.departments?.map((department) =>
      getDataByStatus(department, 'reviewing'),
    ),
  )
  const dataReviewingLia = sum(
    processStats?.departments?.map((department) =>
      getDataByStatus(department, 'reviewingLia'),
    ),
  )

  return (
    <DashboardChartPaper
      title="Status dos processos"
      subtitle="Pendente, em revisão, em revisão lia, reprovados, aprovados, inativos"
      heightContainer="fit-content"
      {...rest}
    >
      <DonutChart
        legendShow
        names={[
          'Pendente',
          'Em Revisão',
          'Reprovados',
          'Aprovados',
          'Inativos',
          'Revisão LIA',
        ]}
        values={[
          dataPending,
          dataReviewing,
          dataDisapproved,
          dataApproved,
          dataInactive,
          dataReviewingLia,
        ]}
        title="Processos"
        colors={[
          theme.palette.dashboard.chart.primary.pending,
          theme.palette.dashboard.chart.primary.revision,
          theme.palette.dashboard.chart.primary.disapproved,
          theme.palette.dashboard.chart.primary.approved,
          theme.palette.dashboard.chart.primary.inactive,
          theme.palette.dashboard.chart.primary.reviewLia,
        ]}
        showName={false}
        id={id}
      />
    </DashboardChartPaper>
  )
}

StatusByProcess.propTypes = {
  processStats: PropTypes.object.isRequired,
}

export default StatusByProcess
