import React, { Fragment, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  File as FileIcon,
  Upload as UploadIcon,
  X as XIcon,
} from 'react-feather'
import { isNil, size, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'

import helpers from 'helpers'

import styles from './styles'

import theme from 'theme'
import constants from 'constants/index'

const FileUploadInput = ({
  accept = constants.validations.ALL_FILE_TYPES,
  attachmentsBgcolor = theme.palette.custom.light,
  backgroundColor,
  controllerBar = false,
  controlName,
  disabled = false,
  EndIcon = FileIcon,
  multiple = false,
  StartIcon = UploadIcon,
  subCaption = '',
  title,
  width,
  isViewFile = false,
  hasDownloadFile = false,
}) => {
  const { isClear } = helpers.validates.lodash
  const { arrayToFileList, isFileInList } = helpers.validates.file

  const [isDragging, setIsDragging] = useState(false)

  const { control, watch, setValue, errors } = useFormContext()

  const inputRef = useRef(null)

  const colorInput = () => {
    if (disabled) return theme.palette.secondary.light
    else if (errors?.[controlName])
      return isDragging ? theme.palette.error.dark : theme.palette.error.main
    return isDragging ? theme.palette.primary.hover : theme.palette.custom.dark
  }

  const useStyles = styles({
    attachmentsBgcolor,
    backgroundColor,
    color: colorInput(),
    isDragging,
    width,
    disabled,
    error: !!errors?.[controlName],
  })
  const classes = useStyles()

  const selectedFile = watch(controlName)
  const isMultiple = size(selectedFile) > 1

  const isPreview =
    selectedFile instanceof File &&
    constants.validations.OPENABLE_FILE_MIME_TYPES.includes(selectedFile.type)

  const disabledText = 'O envio de anexos está desabilitado'

  const handleClickFormControl = () => inputRef?.current?.click()

  const updateFileList = (files, isDrop = false) => {
    if (isClear(files)) return null

    const currentFiles = selectedFile ? Array.from(selectedFile) : []
    let updatedFiles

    if (multiple && controllerBar) {
      const newFiles =
        Array.from(files).filter(
          (newFile) => !isFileInList(newFile, currentFiles),
        ) || []

      updatedFiles = arrayToFileList([...currentFiles, ...newFiles])
    } else if (multiple) {
      updatedFiles = files
    } else {
      updatedFiles = files[0]
    }

    if (isDrop) setValue(controlName, updatedFiles)
    else return updatedFiles
  }

  const handleFileChange = (event) => {
    const files = event.target.files
    const updatedFiles = updateFileList(files)

    return updatedFiles
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    if (disabled) return

    setIsDragging(true)
  }

  const handleDragLeave = () => setIsDragging(false)

  const handleDrop = (event) => {
    event.preventDefault()
    if (disabled) return

    handleDragLeave()
    let files = event?.dataTransfer?.files

    updateFileList(files, true)
  }

  const handleRemoveFile = (event, index) => {
    event.stopPropagation()
    if (disabled) return

    let currentFiles = Array.from(selectedFile) || []
    currentFiles.splice(index, 1)

    const files = arrayToFileList(currentFiles)

    setValue(controlName, size(files) > 0 ? files : null)

    if (inputRef.current) inputRef.current.value = ''
  }

  const handleDownloadFile = (file) => {
    const url = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  const handleOpenFile = (file) => {
    if (
      constants.validations.OPENABLE_FILE_MIME_TYPES.includes(file.type) &&
      isViewFile
    ) {
      const url = URL.createObjectURL(file)
      window.open(url, '_blank')
    } else {
      handleDownloadFile(file)
    }
  }

  return (
    <Box>
      <Controller
        as={
          <FormControl
            className={classes.formControl}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleClickFormControl}
            disabled={disabled}
          >
            <input
              ref={inputRef}
              type="file"
              accept={accept || '*/*'}
              aria-label="Selecionar arquivo"
              className={classes.input}
              multiple={multiple}
              disabled={disabled}
            />
            {isNil(selectedFile) ? (
              <Fragment>
                <StartIcon />
                <Typography component="span" className={classes.title}>
                  {disabled ? disabledText : title}
                </Typography>
                {accept && !disabled && (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="caption">
                      Arquivos suportados:&nbsp;
                      {helpers.formatters.file.allowedTypes(accept)}
                    </Typography>
                    {subCaption && (
                      <Typography variant="caption">{subCaption}</Typography>
                    )}
                  </Box>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Box
                  display="flex"
                  alignItems="center"
                  maxWidth="90%"
                  gridGap={theme.spacing(2)}
                >
                  <Box>
                    <EndIcon size={20} />
                  </Box>
                  <Typography noWrap>
                    {!isMultiple
                      ? multiple
                        ? selectedFile[0]?.name
                        : selectedFile?.name
                      : `${size(selectedFile)} arquivos selecionados`}
                  </Typography>
                  {!isMultiple && (
                    <Box>
                      <Typography variant="caption" noWrap width="fit-content">
                        {helpers.formatters.file.size(
                          multiple ? selectedFile[0]?.size : selectedFile?.size,
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Typography variant="caption">
                  {disabled
                    ? disabledText
                    : 'Clique ou arraste para adicionar um novo arquivo'}
                </Typography>
                {!multiple && hasDownloadFile && (
                  <Tooltip
                    title={`Clique aqui para ${
                      isPreview && isViewFile ? 'visualizar' : 'baixar'
                    } o arquivo`}
                    arrow
                  >
                    <Box>
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.previewButton}
                        onClick={(event) => {
                          event.stopPropagation()
                          handleOpenFile(selectedFile)
                        }}
                      >
                        {`${
                          isPreview && isViewFile ? 'Visualizar' : 'Baixar'
                        } o arquivo`}
                      </Button>
                    </Box>
                  </Tooltip>
                )}
              </Fragment>
            )}
          </FormControl>
        }
        control={control}
        name={controlName}
        onChange={([event]) => handleFileChange(event)}
        mode="onChange"
      />
      {!!errors?.[controlName] && (
        <FormHelperText error={!!errors?.[controlName]}>
          {<>{String(errors?.[controlName]?.message)}</>}
        </FormHelperText>
      )}
      {controllerBar && multiple && !isEmpty(selectedFile) && (
        <Box className={classes.controlBar}>
          <PerfectScrollbar
            options={{ wheelPropagation: true, useBothWheelAxes: true }}
          >
            <Box
              display="flex"
              alignItems="center"
              gridGap={theme.spacing(2)}
              pb={2}
            >
              {Array.from(selectedFile)?.map((file, index) => {
                const fileItemIsPreview =
                  file instanceof File &&
                  constants.validations.OPENABLE_FILE_MIME_TYPES.includes(
                    file.type,
                  )

                return (
                  <Box
                    key={index}
                    className={classes.attachmentItem}
                    onClick={() => handleOpenFile(file)}
                  >
                    <Tooltip
                      title={`Clique aqui para ${
                        fileItemIsPreview && isViewFile
                          ? 'visualizar'
                          : 'baixar'
                      } o arquivo`}
                      placement="top"
                      arrow
                    >
                      <Box display="flex" gridGap={theme.spacing(1)}>
                        <EndIcon size={18} />
                        <Typography noWrap>{file?.name}</Typography>
                      </Box>
                    </Tooltip>
                    {!disabled && (
                      <IconButton
                        size="small"
                        onClick={(event) => handleRemoveFile(event, index)}
                      >
                        <XIcon size={14} />
                      </IconButton>
                    )}
                  </Box>
                )
              })}
            </Box>
          </PerfectScrollbar>
        </Box>
      )}
    </Box>
  )
}

FileUploadInput.propTypes = {
  accept: PropTypes.string.isRequired,
  attachmentsBgcolor: PropTypes.string,
  backgroundColor: PropTypes.string,
  controllerBar: PropTypes.bool,
  controlName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  EndIcon: PropTypes.elementType,
  hasDownloadFile: PropTypes.bool,
  isViewFile: PropTypes.bool,
  multiple: PropTypes.bool,
  StartIcon: PropTypes.elementType,
  subCaption: PropTypes.string,
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default FileUploadInput
