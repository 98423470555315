import React from 'react'
import {
  Grid,
  Paper,
  CardHeader,
  Divider,
  Dialog,
  makeStyles,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const Modal = ({ children, open, onClose, title = '', ...rest }) => {
  const classes = useStyles()

  return (
    <Dialog
      className={classes.modal}
      open={open}
      onClose={onClose}
      disableAutoFocus
      {...rest}
    >
      <Paper className={classes.paperModal}>
        <Grid container>
          <Grid item xs={12}>
            <CardHeader title={title} />
            <Divider />
          </Grid>
          {children}
        </Grid>
      </Paper>
    </Dialog>
  )
}

export default Modal
