import React from 'react'

import { Text } from '../'
import { Box } from '@material-ui/core'
import { GreenHighlighted } from 'components'

const Conclusion = () => {
  return (
    <>
      <Text color="primary" fontSize="7vh" variant="h2">
        Você concluiu a jornada de configuração do seu canal de comunicação!
      </Text>
      <Box mt={3} mb={6}>
        <Text fontSize="3vh" variant="h5" gutterBottom>
          Caso deseje alterar o <GreenHighlighted>logotipo</GreenHighlighted> e
          a <GreenHighlighted>URL</GreenHighlighted> da organização, acesse as
          configurações da empresa.
        </Text>
        <Text fontSize="3vh" variant="h5" gutterBottom>
          Para encontrar esse e outros modelos de{' '}
          <GreenHighlighted>documentos</GreenHighlighted>, acesse o menu
          “Documentos”
        </Text>
      </Box>
    </>
  )
}

export default Conclusion
