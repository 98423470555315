import PropTypes from 'prop-types'

import usePermissions from 'hooks/usePermissions'
import useAuth from 'hooks/useAuth'

const Permitted = ({
  tag,
  someTags,
  admin,
  fallback,
  children,
  show = true,
}) => {
  const auth = useAuth()
  const permissions = usePermissions()

  if (!auth.permissions) {
    return null
  }

  if (!show) {
    return fallback || null
  }

  if (admin && !permissions.isAdmin()) {
    return fallback || null
  }

  if (!tag && !someTags) {
    return children
  }

  if (tag && !permissions.permitted(tag)) {
    return fallback || null
  }

  if (someTags && !permissions.some(someTags)) {
    return fallback || null
  }

  return children
}

Permitted.propTypes = {
  tag: PropTypes.any,
  someTags: PropTypes.array,
  admin: PropTypes.bool,
  fallback: PropTypes.any,
  children: PropTypes.any,
}

export default Permitted
