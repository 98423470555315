import React, { useState } from 'react'
import {
  MinusCircle as MinusCircleIcon,
  PauseCircle as PauseCircleIcon,
} from 'react-feather'
import clsx from 'clsx'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { Box, Button, Paper, Tooltip } from '@material-ui/core'

import {
  Container,
  ContentHeader,
  DriveTour,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'
import {
  ConsentDetailsForm,
  ConsentDetailsInfo,
  ConsentDetailsPublishSection,
  DeleteFormModal,
  InactiveFormModal,
} from './components'

import useFetch from 'hooks/useFetch'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'

import useStyles from './styles'

const ConsentDetails = ({ match }) => {
  const { consentFormId } = match.params

  const [loading, setLoading] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openInactiveModal, setOpenInactiveModal] = useState(false)
  const [openTour, setOpenTour] = useState(false)

  const classes = useStyles()

  const {
    response: consentFormResponse,
    isLoading: consentFormIsLoading,
    refresh: consentFormRefresh,
  } = useFetch(
    service.dponet.consent.get,
    {
      consentFormId,
    },
    [consentFormId],
  )

  const {
    response: profilesResponse,
    isLoading: profilesIsLoading,
    refresh: profilesRefresh,
  } = useFetch(service.dponet.profiles.get, {
    status: true,
    profileType: constants.profile.PROFILE_CLIENT_TYPE,
    withLinkedUsers: true,
    perPage: 1000,
  })

  const consentForm = consentFormResponse?.data?.consentForm
  const profiles = [
    constants.profile.RESPONSIBLE_PROFILE_OPTION,
    ...(profilesResponse?.data?.profiles ?? []),
  ]

  const isActive =
    consentForm?.status === constants.consent.ACTIVE_CONSENT_FORM_STATUS_ID

  const handleOpenDeleteModal = () => setOpenDeleteModal(true)
  const handleOpenInactiveModal = () => setOpenInactiveModal(true)

  const handleOpenTour = () => {
    const where = 'Visualização de formulário'
    const title = 'Tour'

    const params = {
      action: 'tour-ver-detalhes-formularios-consentimento',
    }

    helpers.mixpanel.track(where, title, params)
    setOpenTour(true)
  }

  return (
    <Page title="Visualização de formulário">
      <LoadingFeedback
        open={loading || consentFormIsLoading || profilesIsLoading}
      />
      <Container maxWidth={false}>
        <ContentHeader title="Ver detalhes">
          <Box className={classes.consentDetailsButtonContainer}>
            <Tooltip title="Iniciar o tour guiado">
              <Box className={classes.contentHeaderBox}>
                <Button
                  id={constants.consent.DETAILS_DRIVER_STEP_0}
                  variant="contained"
                  color="primary"
                  startIcon={<HelpCircleIcon size={20} />}
                  onClick={handleOpenTour}
                  fullWidth
                >
                  Tutorial
                </Button>
              </Box>
            </Tooltip>
            <Permitted tag={constants.permissions.CONSENTS.CHANGE_FORM_STATUS}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<PauseCircleIcon size={18} />}
                className={classes.consentDetailsButton}
                onClick={handleOpenInactiveModal}
              >
                {isActive ? 'Inativar' : 'Ativar'} Formulário
              </Button>
            </Permitted>
            <Permitted tag={constants.permissions.CONSENTS.DESTROY}>
              <Button
                variant="contained"
                className={clsx(
                  classes.buttonError,
                  classes.consentDetailsButton,
                )}
                startIcon={<MinusCircleIcon size={18} />}
                onClick={handleOpenDeleteModal}
              >
                Excluir Formulário
              </Button>
            </Permitted>
          </Box>
        </ContentHeader>
        <Paper variant="outlined" className={classes.consentDetailsContainer}>
          <ConsentDetailsInfo
            consentForm={consentForm}
            refresh={consentFormRefresh}
          />
          {!consentFormIsLoading && !profilesIsLoading && (
            <ConsentDetailsForm
              setLoading={setLoading}
              profiles={profiles}
              profilesRefresh={profilesRefresh}
              consentForm={consentForm}
              consentFormRefresh={consentFormRefresh}
            />
          )}
          <ConsentDetailsPublishSection
            consentForm={consentForm}
            setLoading={setLoading}
          />
        </Paper>
      </Container>
      <DeleteFormModal
        consentForm={consentForm}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        loading={loading}
        setLoading={setLoading}
      />
      <InactiveFormModal
        consentForm={consentForm}
        open={openInactiveModal}
        setOpen={setOpenInactiveModal}
        loading={loading}
        setLoading={setLoading}
        refresh={consentFormRefresh}
        isActive={isActive}
      />
      <DriveTour
        stepsMatrix={constants.consent.DETAILS_DRIVER_STEPS_OBJECT}
        customStyle={classes.customPopover}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default ConsentDetails
