const kindType = (kind) => {
  const kinded = {
    0: 'Plano de ação',
    1: 'Evidência',
  }

  return kinded[kind]
}

export default kindType
