import React from 'react'
import PropTypes from 'prop-types'

import { Box, CircularProgress } from '@material-ui/core'

const LoadingBox = ({ minHeight = '700px', width = '100%' }) => {
  return (
    <Box
      display="flex"
      width={width}
      minHeight={minHeight}
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  )
}

LoadingBox.propTypes = {
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default LoadingBox
