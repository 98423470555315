import React, { useState } from 'react'
import { Alert } from '@material-ui/lab'
import { Button, Grid, Tooltip, Typography } from '@material-ui/core'

import { LoadingFeedback, DialogUpdateDataQuestionnaires } from 'components'
import { QuestionControlPdf } from 'views/Reports/components'

import helpers from 'helpers'
import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'
import usePermission from 'hooks/usePermissions'

import constants from 'constants/index'

import useStyles from './styles'

const LevelAlert = ({
  questionnaireLevelInfo,
  refresh,
  refreshQuestionnaireLevel,
}) => {
  const { isResponsible } = usePermission()

  const classes = useStyles()
  const snackbar = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [gapStats, setGapStats] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [exportingPdf, setExportingPdf] = useState(false)
  const [questionControls, setQuestionControls] = useState(null)

  const { COMPLIANCE_LEVEL_NOT_STARTED, COMPLIANCE_LEVEL_THREE } =
    constants.questionnaire

  const conformityLevelCompleted =
    questionnaireLevelInfo?.conformityLevelCompleted
  const conformityLevel = questionnaireLevelInfo?.conformityLevel

  const showLevelCompletedAlert =
    conformityLevelCompleted &&
    conformityLevel !== COMPLIANCE_LEVEL_THREE &&
    conformityLevel !== COMPLIANCE_LEVEL_NOT_STARTED

  const handleAction = async () => {
    setLoading(true)
    try {
      const [questionControlsResponse, gapStatsResponse] = await Promise.all([
        service.dponet.questionControls.list({
          withActionPlans: true,
          perPage: 999,
        }),
        service.dponet.myLgpd.getGapStats({}),
      ])

      setQuestionControls(questionControlsResponse?.data?.questionControls)
      setGapStats(gapStatsResponse?.data?.stats)
      setExportingPdf(true)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      refresh()
    }
  }

  const handleUpdateDataModal = () => setOpenModal(true)

  return (
    <>
      <LoadingFeedback open={loading} />

      {showLevelCompletedAlert && (
        <Alert className={classes.levelAlert} variant="filled" severity="info">
          <Typography className={classes.levelAlertTitle}>
            Parabéns! Você concluiu o nível #{conformityLevel} de conformidade.
          </Typography>
          <Typography>
            Desbloqueie o nível {conformityLevel + 1} do questionário para
            avançar e aumentar a conformidade da sua organização. Com isso, você
            implementará mais medidas de segurança, tornando sua empresa ainda
            mais protegida.
          </Typography>
        </Alert>
      )}

      {conformityLevel === COMPLIANCE_LEVEL_NOT_STARTED && (
        <Alert className={classes.levelAlert} variant="filled" severity="info">
          <Grid container>
            <Grid item xs={12} md={9} lg={10}>
              <Typography className={classes.levelAlertTitle}>
                Novidade! Atualizamos nossos questionários para facilitar o
                entendimento e adequá-los às novas normas. A atualização é
                opcional, mas recomendamos que seja feita.
              </Typography>
              <Typography className={classes.levelAlertSubtitle}>
                Atenção! Ao optar pela atualização, os questionários antigos
                serão desativados e você precisará preencher os novos do zero.
                As medidas de segurança estão diretamente ligadas às perguntas
                dos questionários, portanto,{' '}
                <strong>
                  {' '}
                  ao desativar os antigos, as medidas correspondentes também
                  serão desativadas, e novas medidas serão geradas com base nas
                  novas respostas. Isso pode gerar a necessidade de criar novos
                  planos de ação para tratar de pendências que não existiam
                  anteriormente.{' '}
                </strong>
              </Typography>
              <Typography>
                Os questionários e medidas inativados não ficarão mais visíveis,
                mas enviaremos um relatório por e-mail com suas medidas de
                segurança e planos de ação antigos antes da inativação.
              </Typography>
              <Typography className={classes.levelAlertSubtitle}>
                Se precisar de ajuda, nossa equipe de suporte está disponível, e
                lembre-se, após a atualização, não será possível reverter a
                ação.
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={3}
              lg={2}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Tooltip
                title={
                  isResponsible() ? '' : 'Somente o responsável pode atualizar!'
                }
              >
                <span>
                  <Button
                    variant="contained"
                    onClick={handleUpdateDataModal}
                    disabled={!isResponsible()}
                  >
                    Atualizar
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Alert>
      )}

      <DialogUpdateDataQuestionnaires
        open={openModal}
        setOpen={setOpenModal}
        handleAction={handleAction}
        title="Atenção!"
        subtitle="Essa ação não poderá ser desfeita, você tem certeza?"
      />
      <QuestionControlPdf
        isComplete
        notSave
        updateQuestionnaire
        shouldCreateVersion
        exportingPdf={exportingPdf}
        setExportingPdf={setExportingPdf}
        questionControls={questionControls}
        gapStats={gapStats}
        refresh={refresh}
        refreshQuestionnaireLevel={refreshQuestionnaireLevel}
      />
    </>
  )
}

export default LevelAlert
