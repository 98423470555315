import React, { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { useFormContext, Controller } from 'react-hook-form'

import {
  Box,
  Typography,
  MenuItem,
  TextField,
  Checkbox,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

import helpers from 'helpers'

const TableNecPropDataLifeCycle = ({ dataLifeCycles }) => {
  const { control, errors, setValue } = useFormContext()

  const [checked, setChecked] = useState(false)

  useEffect(() => {
    dataLifeCycles.map((dataLifeCycle) => {
      if (checked) {
        setValue(`dataLifeCycle-${dataLifeCycle.id}`, 1)
        return dataLifeCycle
      } else {
        setValue(
          `dataLifeCycle-${dataLifeCycle.id}`,
          helpers.formatters.convertBoolToBinary(dataLifeCycle.necessary),
        )
        return dataLifeCycle
      }
    })
    //eslint-disable-next-line
  }, [checked])

  return (
    <>
      <Box
        width="100%"
        mb={2}
        justifyContent="space-between"
        display="flex"
        alignItems="flex-end"
      >
        <Typography variant="h6">Proteção</Typography>

        <Box padding="checkbox">
          <Box
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            <Box fontWeight="fontWeightBold">
              <Typography>
                Deseja selecionar <u>todos</u> com SIM?
              </Typography>
            </Box>
            <Checkbox
              type="checkbox"
              onChange={() => {
                setChecked(!checked)
              }}
            />
          </Box>
        </Box>
      </Box>
      <PerfectScrollbar>
        <Box width="100%">
          <Table size="small" emptyMessage="Nenhuma proteção foi encontrada">
            <TableHead>
              <TableRow>
                <TableCell width="40%">Proteção</TableCell>
                <TableCell width="35%">Armazenamento de referência</TableCell>
                <TableCell width="25%">
                  Considera esta forma de proteção necessária e proporcional
                  para o tratamento?
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dataLifeCycles?.map((dataLifeCycle) => (
                <TableRow key={dataLifeCycle.id}>
                  <TableCell>{dataLifeCycle.protection?.name}</TableCell>
                  <TableCell>{dataLifeCycle.storageLocation?.name}</TableCell>
                  <TableCell>
                    <Controller
                      as={
                        <TextField
                          label="Necessário?"
                          select
                          color="primary"
                          variant="outlined"
                          error={
                            !!errors[
                              `dataLifeCycle-${
                                dataLifeCycle && dataLifeCycle.id
                              }`
                            ]
                          }
                          helperText={
                            errors &&
                            errors[
                              `dataLifeCycle-${
                                dataLifeCycle && dataLifeCycle.id
                              }`
                            ] &&
                            'Preencha este campo'
                          }
                          fullWidth
                        >
                          <MenuItem key="sim" value={1}>
                            Sim
                          </MenuItem>
                          <MenuItem key="não" value={0}>
                            Não
                          </MenuItem>
                        </TextField>
                      }
                      name={`dataLifeCycle-${
                        dataLifeCycle && dataLifeCycle.id
                      }`}
                      defaultValue={helpers.formatters.convertBoolToBinary(
                        dataLifeCycle.necessary,
                      )}
                      rules={{ required: true }}
                      control={control}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </>
  )
}

export default TableNecPropDataLifeCycle
