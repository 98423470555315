const ITEMS_COMPANY_TRAILS = [
  {
    accordionId: 'firstAccess',
    title: 'Primeiro Acesso',
    subtitle:
      'Os usuários cadastrados concluíram o curso e receberam a certificação',
    description:
      'Representante, Diretoria e Líderes de Departamento realizaram o seu curso inicial e receberam o certificado.',
  },
  {
    accordionId: 'cookies',
    title: 'Banner de Cookies',
    subtitle: 'A organização incluiu em seu site o aviso de cookies',
    description:
      'A organização incluiu o aviso de cookies para gerenciar consentimentos e dar transparência aos usuários.',
  },
  {
    accordionId: 'conceptTraining',
    title: 'Treinamento de Conceitos Fundamentais',
    subtitle:
      'Os usuários cadastrados concluíram o curso e receberam a certificação',
    description:
      'Representante, Diretoria e Líderes de Departamento realizaram o seu curso inicial e receberam o certificado.',
  },
  {
    accordionId: 'repTraining',
    title: 'Treinamento do Representante',
    subtitle:
      'Os usuários cadastrados concluíram o curso e receberam a certificação',
    description:
      'Representante, Diretoria e Líderes de Departamento realizaram o seu curso inicial e receberam o certificado.',
  },
  {
    accordionId: 'setUpTeam',
    title: 'Monte sua Equipe',
    subtitle:
      'Os usuários cadastrados concluíram o curso e receberam a certificação',
    description:
      'Representante, Diretoria e Líderes de Departamento realizaram o seu curso inicial e receberam o certificado.',
  },
  {
    accordionId: 'channelOfCommunication',
    title: 'Estabeleça o canal de comunicação',
    subtitle:
      'Os usuários cadastrados concluíram o curso e receberam a certificação',
    description:
      'Representante, Diretoria e Líderes de Departamento realizaram o seu curso inicial e receberam o certificado.',
  },
  {
    accordionId: 'gapAnalysis',
    title: 'Faça seu Diagnóstico',
    subtitle:
      'O questionário sugerido foi validado ou a resolução própria foi concluída',
    description:
      'O representante e os líderes validaram os questionários sugeridos com as respostas preenchidas de acordo com as melhores práticas legais e de medidas de segurança ou concluíram a resolução própria dos questionários.',
  },
  {
    accordionId: 'dataMapping',
    title: 'Mapeamento de Processos',
    subtitle:
      'Os processos sugeridos foram validados ou o mapeamento próprio foi concluído',
    description:
      'O representante e os líderes validaram o mapeamento de processos sugeridos, com enquadramento legal e riscos analisados, ou concluíram o próprio mapeamento.',
  },
  {
    accordionId: 'tomticket',
    title: 'Como solucionar suas dúvidas',
    subtitle:
      'Os processos sugeridos foram validados ou o mapeamento próprio foi concluído',
    description:
      'O representante e os líderes validaram o mapeamento de processos sugeridos, com enquadramento legal e riscos analisados, ou concluíram o próprio mapeamento.',
  },
]

const CONCLUDED_STATUS = 'concluded'
const DOING_STATUS = 'doing'
const NOT_INITIATED_STATUS = 'notInitiated'

const LGPD_CONCEPTS_TRAINING_JOURNEY = {
  title: '3 - Treinamento Conceitos LGPD',
  progress: 0,
  status: NOT_INITIATED_STATUS,
  trails: [
    {
      title: 'Dados Pessoais',
    },
    {
      title: 'Categorias de Dados pessoais',
    },
    {
      title: 'Tratamento de Dados Pessoais',
    },
    {
      title: 'Incidentes envolvendo Dados Pessoais',
    },
    {
      title: 'Certificado',
    },
  ],
  url: '/trainning/1',
}

const USER_STEP_JOURNEY_CARD_TITLE = '1 - Primeiro Acesso'
const COMMUNICATION_JOURNEY_CARD_TITLE = '2 - Canal de Comunicação'

const USER_STEP_JOURNEY = 'user_steps'
const COMMUNICATION_JOURNEY = 'communication'
const TRAINNING_JOURNEY = 'trainning'

const JOURNEYS = {
  USER_STEP_JOURNEY,
  COMMUNICATION_JOURNEY,
  TRAINNING_JOURNEY,
}

const CARD_TITLES = {
  USER_STEP_JOURNEY_CARD_TITLE,
  COMMUNICATION_JOURNEY_CARD_TITLE,
}
export default {
  CARD_TITLES,
  JOURNEYS,
  CONCLUDED_STATUS,
  DOING_STATUS,
  ITEMS_COMPANY_TRAILS,
  LGPD_CONCEPTS_TRAINING_JOURNEY,
  NOT_INITIATED_STATUS,
}
