import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  iconBox: {
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    color: theme.palette.subscription.primary,
  },
  number: {
    color: theme.palette.subscription.primary.main,
    fontWeight: 600,
  },
  rootBox: {
    width: 'auto',
    height: 250,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      height: 'fit-content',
    },
  },
  statsBox: {
    backgroundColor: theme.palette.white,
    height: 75,
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    paddingInline: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      height: 'fit-content',
      paddingBlock: theme.spacing(2),
    },
  },
  welcomeText: {
    color: theme.palette.white,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '5vw',
    backgroundPositionY: 125,
    backgroundSize: '13vh',
    width: '75%',
    padding: theme.spacing(4.5),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      backgroundPositionX: 'center',
      backgroundSize: '18vh',
      backgroundPositionY: '100%',
    },
  },
  welcomeBox: {
    backgroundColor: theme.palette.subscription.primary.main,
    height: 175,
    [theme.breakpoints.down('sm')]: {
      height: 'fit-content',
    },
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  statsContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    gap: 10,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
  },
}))

export default styles
