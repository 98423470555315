import { makeStyles } from '@material-ui/core'
import backgroundBlue from 'images/Polygon_2.svg'
import backgroundGreen from 'images/Polygon_2_Complete.svg'

const styles = makeStyles((theme) => ({
  stepIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${backgroundBlue})`,
    backgroundSize: 'contain',
    width: 39,
    height: 43,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      width: 28,
      height: 31,
    },
  },
  stepComplete: {
    backgroundImage: `url(${backgroundGreen}) !important`,
  },
  iconCheck: {
    color: theme.colors.firstStep.text.primary,
  },
  iconClose: {
    color: theme.colors.firstStep.primary,
  },
}))

export default styles
