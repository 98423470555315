import React, { useState } from 'react'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
} from '@material-ui/core'

import { Check as CheckIcon } from '@material-ui/icons'

import { NewUserTab, RegisteredUsersTab } from './components'
import { LoadingFeedback } from 'components'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import * as service from 'service'

const DialogChangeResponsable = ({ open, setOpen = () => {} }) => {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const handleCancel = () => {
    setOpen(false)
  }

  const snackbar = useSnackbar()

  const [currentTab, setCurrentTab] = useState('newUser')
  const tabs = [
    { value: 'newUser', label: 'Novo Usuário' },
    { value: 'registeredUsers', label: 'Usuários Cadastrados' },
  ]

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  const onSubmit = async (userFormData) => {
    try {
      setLoading(true)
      await service.dponet.users.changeResponsable(userFormData)
      service.dponet.auth.logout()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-change"
        maxWidth="md"
      >
        <DialogTitle>
          <Box>
            <Typography variant="h4">Adicionar Representante</Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box mx={1}>
            <Box mb={1}>
              <Typography variant="h5" gutterBottom>
                Atribuir papel a outro usuário
              </Typography>
            </Box>
            <Typography variant="body1">
              Preencha as informações do usuário que será o Responsável.
              Lembre-se que este usuário será o principal, quem irá conduzir as
              atividades no DPOnet e terá acesso total às configurações da sua
              organização.
            </Typography>
            <Box my={1}>
              <Typography variant="body1" style={{ fontWeight: 600 }}>
                Você perderá o seu acesso ao DPOnet até que o novo usuário possa
                fazer o seu cadastro, se for o caso.
              </Typography>
            </Box>
          </Box>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="primary"
            indicatorColor="primary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                onClick={(e) => handleTabsChange(e, tab.value)}
              />
            ))}
          </Tabs>
          {currentTab === 'newUser' && (
            <NewUserTab
              disabled={disabled}
              setDisabled={setDisabled}
              onSubmit={onSubmit}
            />
          )}
          {currentTab === 'registeredUsers' && (
            <RegisteredUsersTab onSubmit={onSubmit} setDisabled={setDisabled} />
          )}
        </DialogContent>
        <Box display="flex" justifyContent="flex-end" my={2} mr={2}>
          <Box pr={3}>
            <Button
              variant="outlined"
              type="button"
              onClick={handleCancel}
              disableElevation
            >
              CANCELAR
            </Button>
          </Box>

          <Button
            startIcon={<CheckIcon />}
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
            form="newResponsableForm"
            disabled={loading || disabled}
          >
            ADICIONAR
          </Button>
        </Box>
      </Dialog>
    </>
  )
}

export default DialogChangeResponsable
