import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  line: {
    flex: 1,
    borderBottom: '0.8px solid rgba(0, 0, 0, 0.12)',
  },
  text: {
    padding: theme.spacing(0, 1),
  },
}))
