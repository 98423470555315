import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  tagRed: {
    color: colors.red[900],
  },
  tagOrange: {
    color: colors.orange[900],
  },
  tagBlue: {
    color: colors.blue[800],
  },
  orderedList: {
    paddingLeft: 40,
  },
  divCodeTypography: {
    wordBreak: 'break-all',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  scriptCodeTypography: {
    wordBreak: 'break-all',
    paddingBottom: theme.spacing(2),
    paddingInline: theme.spacing(2),
  },
  iconButton: {
    padding: 8,
  },
}))

export default styles
