import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Store as StoreIcon } from '@material-ui/icons'
import { Box, Card, CardActionArea, Grid, Typography } from '@material-ui/core'

import helpers from 'helpers'

import useStyles from './styles'
import theme from 'theme'

const PartnerServiceCard = ({ action, isDisabled, partnerService }) => {
  const [imageError, setImageError] = useState(false)

  const classes = useStyles()
  const { mixpanel } = helpers

  const handleClick = (serviceName) => {
    if (!isDisabled) {
      mixpanel.track('Marktplace', 'Listagem de serviços', {
        action: `Click no card do serviço ${serviceName}`,
      })
      action()
    }
  }

  const handleImageError = () => setImageError(true)

  const companyServiceTitle = partnerService?.companyService?.name
  const serviceCategoryTitle =
    partnerService?.companyService?.serviceCategory?.name
  const partnerTitle = partnerService?.partner?.tradeName

  return (
    <Card className={classes.cardContainer} title="Solicitar serviço">
      <CardActionArea
        disabled={isDisabled}
        onClick={() => {
          handleClick(companyServiceTitle)
        }}
      >
        <Grid
          component={Box}
          container
          spacing={2}
          textAlign="justify"
          alignItems="center"
          p={2}
        >
          <Grid item xs={12} lg={4} xl={3} className={classes.logoLayout}>
            <Box display="flex" justifyContent="center">
              {imageError || !partnerService?.partner?.logo ? (
                <StoreIcon className={classes.storeIcon} />
              ) : (
                <img
                  className={classes.logoContainer}
                  src={partnerService?.partner?.logo}
                  alt={`logo ${partnerTitle}`}
                  onError={handleImageError}
                />
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            xl={9}
            component={Box}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight="100%"
            gridGap={theme.spacing(2)}
          >
            <Box
              display="flex"
              flexDirection="column"
              gridGap={theme.spacing(2)}
            >
              <Typography variant="h4">
                {serviceCategoryTitle + ' - ' + companyServiceTitle}
              </Typography>
              <Box height={130}>
                <PerfectScrollbar>
                  <Typography className={classes.description}>
                    {partnerService?.description}
                  </Typography>
                </PerfectScrollbar>
              </Box>
            </Box>
            {partnerService?.partner && (
              <Typography color="primary">
                Oferecido por:&nbsp;
                <strong>{partnerTitle}</strong>
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  )
}

PartnerServiceCard.defaultProps = {
  isDisabled: false,
}

PartnerServiceCard.propTypes = {
  isDisabled: PropTypes.bool,
  action: PropTypes.func.isRequired,
  partnerService: PropTypes.object.isRequired,
}

export default PartnerServiceCard
