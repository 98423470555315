import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Image as ImageIcon } from 'react-feather'
import { LoadingIcon } from 'components'

import * as service from 'service'
import { getBaseURL } from 'service/env'

import stamp from 'images/position_signature.png'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'

import helpers from 'helpers'

import styles from './styles'
const useStyles = makeStyles(styles)

const apiUrl = getBaseURL('dponet')

const EmailSignature = () => {
  const auth = useAuth()
  const reference = useRef()
  const snackbar = useSnackbar()
  const classes = useStyles()

  const [copySuccess, setCopySuccess] = useState(false)
  const [component, setComponent] = useState(reference.current)

  useEffect(() => {
    if (!component) {
      return setComponent(reference.current)
    }
    //eslint-disable-next-line
  }, [reference])

  const { response, isLoading } = useFetch(
    service.dponet.privacyStamps.consult,
    {
      cnpj: helpers.company.documentType(auth.company, true),
    },
  )

  const copyToClipboard = (e) => {
    try {
      const selection = window.getSelection()
      var range = document.createRange()
      range.selectNodeContents(component)
      selection.addRange(range)
      document.execCommand('copy')
      selection.removeAllRanges()
      e.target.focus()
      setCopySuccess(true)
      snackbar.open({
        message: 'Assinatura copiada com sucesso',
        variant: 'success',
      })
    } catch {
      snackbar.open({
        message: 'Ocorreu um erro ao copiar a assinatura',
        variant: 'error',
      })
    }
  }

  return (
    <Card>
      <Box mt={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box px={2} mb={2}>
              <Typography variant="h6" color="textPrimary">
                Insira na sua assinatura
              </Typography>
            </Box>
            <Divider />
          </Grid>
          <Grid item lg={6} md={12}>
            <Box p={2}>
              <img src={stamp} alt="stamp-signature" width="100%" />
            </Box>
          </Grid>
          <Grid item lg={6} md={12}>
            <Box p={2}>
              <Typography
                paragraph
                variant="body1"
                color="textPrimary"
                align="justify"
              >
                Copie o código abaixo e incorpore-o à sua assinatura de e-mail
                para estabelecer um Canal de Comunicação com os titulares de
                dados e a ANPD (Autoridade Nacional de Proteção de Dados).
              </Typography>
              <Typography variant="body1" color="textPrimary" align="justify">
                Através dele, direcionamos todos os interessados para o Portal
                da Privacidade, onde encontrarão informações como: o DPO
                (Encarregado de Proteção de Dados) nomeado em sua organização; o
                canal de atendimento aos titulares de dados; o canal de
                atendimento à ANPD; além de acesso aos termos, avisos e
                políticas que foram cadastrados na plataforma.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              mx={2}
              py={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={classes.stampBox}
            >
              <a
                href={helpers.company.formatPortalUrl(
                  response?.data?.portalUrl,
                )}
                ref={reference}
              >
                <img
                  src={`${apiUrl}/selo_portal_de_privacidade.png`}
                  alt="selo-para-emails"
                  width="150px"
                />
              </a>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={1}>
              <Divider />
            </Box>
            <Box px={2} my={2} display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                onClick={copyToClipboard}
                disabled={!component || isLoading}
                startIcon={
                  <LoadingIcon
                    icon={ImageIcon}
                    size={18}
                    isLoading={isLoading}
                  />
                }
              >
                {copySuccess ? 'Copiado' : 'Copiar'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default EmailSignature
