import React, { Fragment, useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Divider,
  Link,
  Typography,
} from '@material-ui/core'

import {
  FinancialFilters,
  Invoices,
  PaymentMethod,
  ViewContract,
} from './components'
import {
  DialogTomticket,
  FilterButton,
  InfoCard,
  LoadingFeedback,
} from 'components'

import { useHistory } from 'react-router-dom'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import useFetch from 'hooks/useFetch'
import useAuth from 'hooks/useAuth'

import constants from 'constants/index'
import * as service from 'service'

import theme from 'theme'
import useStyles from './styles'

const Financial = () => {
  const { company } = useAuth()

  const [dialogContactTickets, setDialogContactTickets] = useState(false)

  const [contract, setContract] = useState()

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const filter = useFilter()
  const history = useHistory()
  const [currentTab, setCurrentTab] = useState(
    constants.settings.INVOICES_STATUS_ID,
  )

  const classes = useStyles()
  const PAYMENT_METHOD_STATUS_ID = constants.settings.PAYMENT_METHOD_STATUS_ID

  const menus = constants?.settings.FINANCIAL_MENU_ITEMS

  useEffect(() => {
    return () => history.replace()
  }, [history])

  const paymentOrderFetch = useFetch(
    service.dponet.paymentOrders.listingByCompany,
    {
      page,
      perPage,
      ...filter.filters,
    },
    [page, perPage, filter.filters],
  )

  const contractsFetch = useFetch(
    service.dponet.financialPanel.listContracts,
    {},
    [],
  )

  const handleViewContract = (contractIdentifier) =>
    setContract(
      contractsFetch?.response?.data?.paymentContracts?.find(
        (paymentContract) =>
          paymentContract?.contractIdentifier === contractIdentifier,
      ),
    )

  const onCloseContract = () => setContract()

  const handleOpenTickets = () => setDialogContactTickets(true)

  return (
    <Fragment>
      <LoadingFeedback open={paymentOrderFetch.isLoading} />
      <Card component={Box}>
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
          <Box display="flex" gridGap={theme.spacing(2)} p={2}>
            {menus.map((item, index) => (
              <Card
                key={index}
                variant="outlined"
                className={clsx(classes.financialCard, {
                  [classes.financialBoxHighlight]: currentTab === item?.id,
                })}
              >
                <CardActionArea
                  className={classes.financialCardActionArea}
                  onClick={() => setCurrentTab(item?.id)}
                >
                  <Box className={classes.financialBox}>
                    <item.Icon />
                    <Typography variant="h6" align="center">
                      {item?.label}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Box>
          {currentTab === constants.settings.INVOICES_STATUS_ID && (
            <Box p={2}>
              {company?.haveOverdueOrders && (
                <InfoCard
                  mb={2}
                  title="Detectamos um problema com o pagamento da sua fatura. Por favor,
        regularize-o para evitar a interrupção do seu acesso."
                  endActions={
                    <Link
                      variant="body1"
                      color="initial"
                      underline="always"
                      className={classes.link}
                      onClick={() =>
                        filter.setFilters({
                          ...filter.filters,
                          status: constants.settings.FINANCIAL_OVERDUE_STATUS,
                        })
                      }
                    >
                      Pagar agora
                    </Link>
                  }
                />
              )}
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />

              <Invoices
                paymentOrderFetch={paymentOrderFetch}
                page={page}
                perPage={perPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleViewContract={handleViewContract}
              />
            </Box>
          )}
          {currentTab === PAYMENT_METHOD_STATUS_ID && (
            <PaymentMethod contractsFetch={contractsFetch} />
          )}
          <Divider />
        </Box>
        <Box p={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenTickets}
          >
            Entrar em contato
          </Button>
        </Box>
      </Card>
      <FinancialFilters filter={filter} />
      <DialogTomticket
        open={dialogContactTickets}
        setOpen={setDialogContactTickets}
        defaultType="financial"
      />
      <ViewContract contract={contract} onClose={onCloseContract} />
    </Fragment>
  )
}

export default Financial
