import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Box, Typography, Chip, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { LoadingFeedback, MenuButton, ButtonAction } from 'components'
import { DialogChange } from './components'

import helpers from 'helpers'
import constants from 'constants/index'

import styles from '../../styles'
import clsx from 'clsx'
const useStyles = makeStyles(styles)

const TableRowSupplier = ({ supplier, refresh, handleChange, ...rest }) => {
  const classes = useStyles()

  const { APPROVED_STATUS, SUGGESTED_STATUS, DISAPPROVED_STATUS } =
    constants.companySuppliers

  return (
    <TableRow {...rest}>
      <TableCell>{supplier?.id}</TableCell>
      <TableCell>
        <Typography>{supplier?.name}</Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>
          {helpers.formatters.cnpjOrCpf(supplier?.document)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Chip
          size="small"
          className={clsx(
            classes.chipRoot,
            helpers.companySuppliers.statusChipColor(supplier?.status, classes),
          )}
          label={helpers.companySuppliers.label(supplier?.status)}
        />
      </TableCell>

      <TableCell align="right">
        {supplier?.status !== APPROVED_STATUS && (
          <Grid>
            <Box display="flex" justifyContent="flex-end">
              <MenuButton
                id={
                  constants.companySuppliers
                    .SUPPLIERS_SUGGESTIONS_MAIN_DRIVER_STEP_2
                }
              >
                <ButtonAction
                  color="secondary"
                  fullWidth
                  action={() =>
                    handleChange(APPROVED_STATUS, supplier?.email, supplier?.id)
                  }
                  name="Aprovar"
                />

                {supplier?.status === SUGGESTED_STATUS && (
                  <ButtonAction
                    color="secondary"
                    fullWidth
                    action={() =>
                      handleChange(
                        DISAPPROVED_STATUS,
                        supplier?.email,
                        supplier?.id,
                      )
                    }
                    name="Reprovar"
                  />
                )}
              </MenuButton>
            </Box>
          </Grid>
        )}
      </TableCell>
    </TableRow>
  )
}

const SuggestionTable = ({ supplierSuggestions, isLoading, refresh }) => {
  const [openChange, setOpenChange] = useState(false)
  const [supplierData, setSupplierData] = useState(null)

  const handleChange = (action, email, id) => {
    setSupplierData({ email, action, id })
    setOpenChange(true)
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <Box width="100%">
        <PerfectScrollbar
          options={{
            useBothWheelAxes: true,
            suppressScrollX: false,
          }}
        >
          <Table
            size="medium"
            emptyMessage="Nenhuma sugestão encontrada"
            isLoading={isLoading}
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="15%">Identificador</TableCell>
                <TableCell width="30%">Organização</TableCell>
                <TableCell width="25%" align="center">
                  CNPJ/CPF
                </TableCell>
                <TableCell width="15%" align="center">
                  Status
                </TableCell>
                <TableCell width="15%" align="right">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {supplierSuggestions?.map((supplier) => (
                <TableRowSupplier
                  supplier={supplier}
                  key={supplier?.id}
                  handleChange={handleChange}
                  refresh={refresh}
                  hover
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
      {supplierData && (
        <DialogChange
          open={openChange}
          setOpen={setOpenChange}
          refresh={refresh}
          supplierData={supplierData}
        />
      )}
    </>
  )
}

SuggestionTable.propTypes = {
  supplierSuggestions: PropTypes.array,
  isLoading: PropTypes.bool,
  refresh: PropTypes.func,
}

TableRowSupplier.propTypes = {
  handleChange: PropTypes.func,
  refresh: PropTypes.func,
  supplier: PropTypes.object,
}

SuggestionTable.defaultProps = {
  refresh: () => {},
  isLoading: false,
}

TableRowSupplier.defaultProps = {
  handleChange: () => {},
  refresh: () => {},
}

export default SuggestionTable
