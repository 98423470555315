import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Typography,
  CardContent,
  CardActionArea,
} from '@material-ui/core'

import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import useStyles from './styles'

import constants from 'constants/index'

const DialogTickets = ({ open, setOpen }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleClose = () => setOpen(false)

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Typography color="primary" variant="h4">
          Atendimento
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContentContainer}>
        <Box>
          <Typography className={classes.subtitle} color="secondary">
            Selecione qual sua dúvida
          </Typography>
          <Box className={classes.contentContainer}>
            <Box className={classes.cardsContainer}>
              {constants.tickets
                .CONTACT_CARDS_DATA(history, setOpen)
                .map((card, index) => (
                  <Card
                    className={classes.card}
                    onClick={card.actionButton}
                    key={index}
                  >
                    <CardActionArea>
                      <CardContent className={classes.cardContentContainer}>
                        <Box className={classes.descriptionContainer}>
                          <Typography color="primary" variant="h5">
                            {card.title}
                          </Typography>
                          <Typography variant="body2" color="secondary">
                            {card.description}
                          </Typography>
                        </Box>
                        <ArrowForwardIcon />
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
            </Box>
            <Box className={classes.videoPlayerContainer}>
              <iframe
                className={classes.videoPlayer}
                src="https://player.vimeo.com/video/973366265?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="fullscreen; picture-in-picture; clipboard-write"
                title="Tickets"
              ></iframe>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

DialogTickets.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}

DialogTickets.defaultProps = {
  open: false,
  setOpen: () => {},
}

export default DialogTickets
