const formatByNameInFilter = (dataOptions) => {
  const arrayOption = []

  dataOptions.map((dataOption) => {
    let objectOption = {
      id: dataOption?.name,
      name: dataOption?.name,
    }

    return arrayOption.push(objectOption)
  })

  return arrayOption
}

export default formatByNameInFilter
