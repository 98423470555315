import constants from 'constants/index'

const customSubtitle = (reportSelected, consentForm) => {
  const fullName =
    consentForm?.consentFormAnswers?.[0]?.answers?.nomeCompleto ?? ''

  const { RECEIPT_COMPLETE, RECEIPT_PERSONAL, RECEIPT_FORM } =
    constants.reports.TYPES_CONSENTS

  switch (reportSelected) {
    case RECEIPT_PERSONAL:
      return `Recibo(s) ${fullName}`
    case RECEIPT_FORM:
      return `Formulário - ${consentForm?.title}`
    case RECEIPT_COMPLETE:
      return consentForm?.title
    default:
      return ''
  }
}

export default customSubtitle
