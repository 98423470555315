const styles = (theme) => ({
  childrenContainer: {
    backgroundColor: theme.palette.white,
  },
  approved: {
    backgroundColor: theme.palette.subscription.warning.success,
    color: theme.palette.white,
  },
  disapproved: {
    backgroundColor: theme.palette.subscription.warning.error,
    color: theme.palette.white,
  },
  processing: {
    backgroundColor: theme.palette.subscription.warning.info.secondary,
    color: theme.palette.white,
  },
})

export default styles
