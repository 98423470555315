import { map } from 'lodash'

const manageFragilities = (existant = [], newState = []) => {
  const toCreate = newState.filter(
    (fragility) =>
      !existant.find(
        (existantFragility) => existantFragility.id === fragility.id,
      ),
  )

  const toDestroy = existant.filter(
    (existantFragility) =>
      !newState.find((fragility) => fragility.id === existantFragility.id),
  )

  return {
    toCreate: map(toCreate, ({ id }) => ({ id })),
    toDestroy: map(toDestroy, ({ id }) => ({ id })),
  }
}

export default manageFragilities
