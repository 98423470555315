import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Paper,
  Typography,
  IconButton,
  Hidden,
  Button,
} from '@material-ui/core'
import { X as CloseIcon } from 'react-feather'

import { getApplicationUrl } from 'service/env'

import useStyles from './styles'
import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'

import supplierBoxImage from 'images/supplier_box_image.jpg'
import constants from 'constants/index'
import helpers from 'helpers'

const AdvisorCard = ({
  setShowAdvisor,
  isSupplier,
  isSuggestion,
  isInvite = false,
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { company } = useAuth()

  const handleClose = () => {
    setShowAdvisor(false)

    if (isSuggestion)
      return localStorage.setItem(
        constants.companySuppliers.ADVISOR_CARD_SUGGESTED,
        constants.companySuppliers.ADVISOR_CARD_CLOSED,
      )

    if (isSupplier)
      return localStorage.setItem(
        constants.companySuppliers.ADVISOR_CARD_SUPPLIER,
        constants.companySuppliers.ADVISOR_CARD_CLOSED,
      )

    return sessionStorage.setItem(
      constants.companySuppliers.ADVISOR_CARD_CLIENT,
      constants.companySuppliers.ADVISOR_CARD_CLOSED,
    )
  }

  const objectText = helpers.companySuppliers.advisorContent(
    isSupplier,
    isSuggestion,
    isInvite,
  )

  const handleCopyText = () => {
    const textToCopy = `${getApplicationUrl()}/supplier-invite/${
      company?.token
    }`

    navigator.clipboard
      .writeText(textToCopy)
      .then(() =>
        snackbar.open({
          message: 'Texto copiado com sucesso!',
          variant: 'success',
        }),
      )
      .catch((err) => console.error('Erro ao copiar texto: ', err))
  }

  return (
    <Paper component={Box} elevation={2} mb={4} px={2} py={1} display="flex">
      <Box className={classes.cardContainer}>
        <Hidden smDown>
          <Box ml={2}>
            <img src={supplierBoxImage} alt="Logo" width={160} />
          </Box>
        </Hidden>
        <Box className={classes.contentContainer}>
          <Typography variant="h4">{objectText.title}</Typography>
          {!isSuggestion && !isSupplier && !isInvite ? (
            <Button onClick={handleCopyText} className={classes.button}>
              Copiar link
            </Button>
          ) : (
            <Typography variant="subtitle1" paragraph>
              {objectText.content}
            </Typography>
          )}
        </Box>
        {(isSuggestion || isSupplier) && (
          <Box ml={2} alignSelf="flex-start">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Paper>
  )
}

AdvisorCard.propTypes = {
  setShowAdvisor: PropTypes.func,
  isSupplier: PropTypes.bool,
  isSuggestion: PropTypes.bool,
}

AdvisorCard.defaultProps = {
  isSupplier: true,
  isSuggestion: false,
}

export default AdvisorCard
