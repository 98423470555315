import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  root: ({ currentStep }) => ({
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: '100vh',
    paddingTop: currentStep === 1 ? 40 : currentStep === 6 ? 100 : 152,
  }),
}))

export default styles
