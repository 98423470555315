import React from 'react'

import {
  Box,
  Button,
  Typography,
  Divider,
  makeStyles,
  Dialog,
} from '@material-ui/core'

import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'

import styles from './styles'
const useStyles = makeStyles(styles)

const DialogFinalize = ({
  nonComplianceReport,
  open,
  setOpen = () => {},
  onEvent = () => {},
}) => {
  const snackbar = useSnackbar()
  const classes = useStyles()

  const finalize = async () => {
    try {
      const response = await service.dponet.nonComplianceReports.finalize({
        nonComplianceReportId: nonComplianceReport.id,
      })
      setOpen(false)

      snackbar.open({ message: response.data.message, variant: 'success' })
      onEvent()
    } catch (err) {
      setOpen(false)
      onEvent()
      snackbar.open({
        message:
          err.response?.data?.error?.errors ||
          'Ocorreu um erro ao finalizar a RMC',
        variant: 'error',
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      className={classes.modal}
      disableAutoFocus
      title="Reabrir RMC"
    >
      <Box>
        <Box m={3}>
          <Typography>
            Você tem certeza de que deseja finalizar o RMC? Verifique se todas
            as evidências implementadas correspondem a todas as ações corretivas
            propostas.
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-start" my={2} mr={2}>
          <Box pl={2} pr={1}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
          </Box>
          <Button onClick={finalize} variant="contained" color="primary">
            Confirmar
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default DialogFinalize
