const advisorContent = (isSupplier, isSuggestion, isInvite) => {
  const contents = [
    {
      title:
        'Avalie as sugestões dos fornecedores vinculados aos processos, seja para aprová-las ou rejeitá-las.',
      content:
        'A seguir, é solicitado que você avalie as sugestões dos fornecedores vinculados aos processos, com a finalidade de tomar decisões de aprovação ou rejeição. ' +
        'Quando você rejeita as sugestões, os fornecedores ainda estarão disponíveis para edição ou até mesmo para aprovação. Por outro lado, quando uma sugestão é aprovada, não será mais possível tomar qualquer ação com ela.',
      isSupplier: false,
      isSuggestion: true,
      isInvite: false,
    },
    {
      title:
        'Visualize e aprove os vínculos das empresas que te indicaram como fornecedor.',
      content:
        'A seguir estão listados os clientes da plataforma que indicaram a sua empresa como um fornecedor. Como fornecedor, você presta um serviço, oferece produtos ou trabalha como terceirizado de alguma atividade.',
      isSupplier: true,
      isSuggestion: false,
      isInvite: false,
    },
    {
      title:
        'Envie o link a seguir para que seus fornecedores acessem e respondam o questionário de conformidade. Lembre-se de cadastrá-los antes.',
      content: '',
      isSupplier: false,
      isSuggestion: false,
      isInvite: false,
    },
    {
      title: 'Listagem dos documentos cadastrados via link.',
      content:
        'A seguir, estão os documentos dos fornecedores cadastrados via link. Assim que o responsável aceitar o vínculo, o documento da empresa fornecedora será removido da listagem e movido para a aba "Meus Fornecedores".',
      isSupplier: false,
      isSuggestion: false,
      isInvite: true,
    },
  ]

  return contents.find(
    (content) =>
      content.isSuggestion === isSuggestion &&
      content.isSupplier === isSupplier &&
      content.isInvite === isInvite,
  )
}

export default advisorContent
