import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  complaintDescription: {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
    textAlign: 'justify',
  },
  complaintDescriptionContainer: {
    maxHeight: 300,
    paddingRight: theme.spacing(2),
  },
}))

export default styles
