const ON_TIME_STATUS = 1
const OVERDUE_STATUS = 2
const FINALIZED_STATUS = 3
const PENDING_STATUS = 4
const CLOSED_STATUS = 5
const RISK_ACCEPTED_STATUS = 6
const SUBSTITUTED_STATUS = 7
const FROZEN_STATUS = 8
const INACTIVE_STATUS = 9

const NOT_EDITABLE_STATUSES = [
  FINALIZED_STATUS,
  CLOSED_STATUS,
  RISK_ACCEPTED_STATUS,
  FROZEN_STATUS,
  SUBSTITUTED_STATUS,
  INACTIVE_STATUS,
]

const NOT_ACCEPT_RISK_STATUSES = [
  FINALIZED_STATUS,
  CLOSED_STATUS,
  SUBSTITUTED_STATUS,
  FROZEN_STATUS,
  INACTIVE_STATUS,
]

const NOT_MEASURES_TAKEN_STATUS = [
  ON_TIME_STATUS,
  OVERDUE_STATUS,
  PENDING_STATUS,
  RISK_ACCEPTED_STATUS,
]

const ENTITY_DATA_PROCESS = 'DataProcess'
const ENTITY_QUESTION = 'Question'
const ENTITY_INCIDENT = 'Incident'

const FRAGILITY_FINALITY = 'Fragility'
const NECESSARY_FINALITY = 'Necessary'
const UNNECESSARY_FINALITY = 'Unnecessary'
const ELIMINATED_FINALITY = 'Eliminated'
const FRAGILITY_AND_NECESSARY_FINALITY = 'FragilityAndNecessary'
const QUESTION_FINALITY = 'Question'
const INCIDENT_FINALITY = 'Incident'
const ANOTHER_FINALITY = 'Another'

const CONTINUED_CHOSEN_ACTION = 'Continued'
const FINALIZED_CHOSEN_ACTION = 'Finalized'

const MEASURE_CONFIRMED = 'measure_confirmed'
const MEASURE_IMPLEMENTED = 'measure_implemented'

const TECHNOLOGY_KIND = 'Infraestrutura e Tecnologia'
const CULTURE_KIND = 'Governança e Cultura'
const JURIDIC_KIND = 'Jurídico'

const LOW_FRAGILITY_STATUS = 1
const MEDIUM_FRAGILITY_STATUS = 2
const HIGH_FRAGILITY_STATUS = 3
const SEVERE_FRAGILITY_STATUS = 4

const ELIMINATE_LIFE_CYCLES_TEXTS = {
  text: 'Eliminar esta forma de proteção significa eliminar toda a salvaguarda e ciclo de vida. Você confirma que quer eliminar esta forma de proteção e que ela será eliminada na rotina da sua organização?',
  textButton: 'Confirmar e eliminar',
}
const ELIMINATE_DATA_TREATMENT_TEXTS = {
  text: 'Você confirma que quer eliminar este compartilhamento e que ele será eliminado na rotina da sua organização?',
  textButton: 'Confirmar e eliminar',
}

const ELIMINATE_COLLECTED_FIELD_TEXTS = {
  text: 'Você confirma que quer eliminar este dado do processo e que ele será eliminado na rotina da sua organização?',
  textButton: 'Confirmar e eliminar',
}

const NOT_ELIMINATE_LIFE_CYCLES_TEXTS = {
  text: 'Você confirma que, avaliando melhor, este dado na verdade é necessário e proporcional para o processo?',
  textButton: 'Confirmar e não eliminar',
}

const NOT_ELIMINATE_DATA_TREATMENT_TEXTS = {
  text: 'Você confirma que, avaliando melhor, este compartilhamento na verdade é necessário e proporcional para o processo?',
  textButton: 'Confirmar e não eliminar',
}

const NOT_ELIMINATE_COLLECTED_FIELD_TEXTS = {
  text: 'Você confirma que, avaliando melhor, este dado na verdade é necessário e proporcional para o processo?',
  textButton: 'Confirmar e não eliminar',
}

const ELIMINATE_COLLECTED_FIELD_MESSAGES =
  'Este dado foi eliminado do processo após reavaliação de necessidade e proporcionalidade.'
const ELIMINATE_DATA_TREATMENT_MESSAGES =
  'Este compartilhamento foi eliminado do processo após reavaliação de necessidade e proporcionalidade.'
const ELIMINATE_LIFE_CYCLES_MESSAGES =
  'Esta forma de proteção foi eliminada do processo após reavaliação de necessidade e proporcionalidade.'

const NOT_ELIMINATE_COLLECTED_FIELD_MESSAGES =
  'Depois de melhor avaliação, este dado foi considerado necessário e proporcional para o processo.'
const NOT_ELIMINATE_DATA_TREATMENT_MESSAGES =
  'Depois de melhor avaliação, este compartilhamento foi considerado necessário e proporcional para o processo.'
const NOT_ELIMINATE_LIFE_CYCLES_MESSAGES =
  'Depois de melhor avaliação, esta forma de proteção foi considerada necessária e proporcional para o processo.'

const GENERAL_INFORMATION_TABS = [
  {
    value: 'GENERAL_INFORMATION',
    label: 'Informações Gerais',
  },
  {
    value: 'RISKS',
    label: 'Riscos',
  },
  {
    value: 'ACTION_PLANS',
    label: 'Planos de ação',
  },
]

const OBSERVATION_NECESSITY_PROPORTIONALITY = [
  'Este RMC foi gerado apenas por causa da indicação de itens desnecessários e/ou desproporcionais. Isso quer dizer que no processo que gerou esse RMC, o usuário que fez o cadastro indicou que existe dado, compartilhamento ou forma de proteção desnecessários e/ou desproporcionais.',
  'Existe apenas uma ação possível: reavaliação de necessidade e proporcionalidade de cada um dos itens indicados como desnecessários e/ou desproporcionais.',
  'Você poderá eliminar o dado, compartilhamento ou forma de proteção ou voltar atrás e indicar que na verdade ele é necessário e proporcional. Esta é a única ação possível para finalizar este RMC.',
]

const OBSERVATION_FRAGILITY = [
  'Este RMC foi gerado porque a atividade de tratamento tem risco elevado às liberdades civis e aos direitos fundamentais dos titulares de dados. Isso quer dizer que no processo que gerou este RMC, a combinação entre os tipos de dados, compartilhamentos e formas de proteção, elevou o risco.',
  'Existem duas ações possíveis',
  '(1) Deixar de realizar a atividade de tratamento (inativar). Neste caso, a empresa irá deixar de realizar este processo com dados pessoais, o que irá evitar o risco',
  '(2) Prosseguir com a atividade de tratamento e vincular medidas de segurança administrativas e técnicas de proteção de dados que se aplicam ao processo que gerou este RMC',
  'Quais medidas vincular?',
  `     Medidas de segurança que a empresa respondeu que já pratica ao responder os questionários
     Medidas de segurança que a empresa implementou devido aos RMCs dos questionários
     Outras medidas de segurança praticadas especificamente pela empresa`,
]

const OBSERVATION_NECESSITY_FRAGILITY = [
  'Este RMC foi gerado por causa da indicação de itens desnecessários e/ou desproporcionais. Isso quer dizer que no processo que gerou esse RMC, o usuário que fez o cadastro indicou que existe dado, compartilhamento ou forma de proteção desnecessários e/ou desproporcionais.',
  'Este RMC foi gerado, também, porque a atividade de tratamento tem risco elevado às liberdades civis e aos direitos fundamentais dos titulares de dados. Isso quer dizer que no processo que gerou este RMC, a combinação entre os tipos de dados, compartilhamentos e formas de proteção, elevou o risco.',
  'Existem três ações possíveis, neste caso:',
  '(1) reavaliação de necessidade e proporcionalidade de cada um dos itens indicados como desnecessários e/ou desproporcionais.',
  'Nesta reavaliação, caso sejam eliminados dados, compartilhamentos ou formas de proteção, é possível que o risco do processo seja diminuído para baixo ou médio. Se o risco for diminuído, o RMC será automaticamente finalizado.',
  'Mas se o risco não for diminuído para baixo ou médio, ou seja, permanecer alto ou severo, ou for reduzido de severo para alto, será necessário abordar o risco. Assim, será iniciada a segunda ação.',
  '(2) Deixar de realizar a atividade de tratamento (inativar). Neste caso, a organização irá deixar de realizar este processo com dados pessoais, o que irá evitar o risco',
  '(3) Prosseguir com a atividade de tratamento e vincular medidas de segurança administrativas e técnicas de proteção de dados que se aplicam ao processo que gerou este RMC',
  'Quais medidas vincular?',
  '- Medidas de segurança que a organização respondeu que já pratica ao responder os questionários;',
  '- Medidas de segurança que a organização implementou devido aos RMCs dos questionários;',
  '- Outras medidas de segurança praticadas especificamente pela organização.',
]

const NOT_ACCEPT_RISK_INCIDENT_STATUSES = [
  FINALIZED_STATUS,
  CLOSED_STATUS,
  SUBSTITUTED_STATUS,
  FROZEN_STATUS,
  INACTIVE_STATUS,
  RISK_ACCEPTED_STATUS,
]

const ON_TIME_STATUS_LABEL = 'No prazo'
const PENDING_STATUS_LABEL = 'Pendente'
const OVERDUE_STATUS_LABEL = 'Vencido'
const RISK_ACCEPTED_STATUS_LABEL = 'Risco Assumido'
const FINALIZED_STATUS_LABEL = 'Finalizado'
const SUBSTITUTED_STATUS_LABEL = 'Substituído'
const FROZEN_STATUS_LABEL = 'Congelado'
const CLOSED_STATUS_LABEL = 'Fechado'
const INACTIVE_STATUS_LABEL = 'Inativo'

export default {
  ANOTHER_FINALITY,
  CLOSED_STATUS_LABEL,
  CLOSED_STATUS,
  CONTINUED_CHOSEN_ACTION,
  CULTURE_KIND,
  ELIMINATE_COLLECTED_FIELD_MESSAGES,
  ELIMINATE_COLLECTED_FIELD_TEXTS,
  ELIMINATE_DATA_TREATMENT_MESSAGES,
  ELIMINATE_DATA_TREATMENT_TEXTS,
  ELIMINATE_LIFE_CYCLES_MESSAGES,
  ELIMINATE_LIFE_CYCLES_TEXTS,
  ENTITY_DATA_PROCESS,
  ENTITY_INCIDENT,
  ENTITY_QUESTION,
  FINALIZED_CHOSEN_ACTION,
  FINALIZED_STATUS_LABEL,
  FINALIZED_STATUS,
  FRAGILITY_AND_NECESSARY_FINALITY,
  FRAGILITY_FINALITY,
  FROZEN_STATUS_LABEL,
  FROZEN_STATUS,
  GENERAL_INFORMATION_TABS,
  HIGH_FRAGILITY_STATUS,
  INACTIVE_STATUS_LABEL,
  INACTIVE_STATUS,
  INCIDENT_FINALITY,
  JURIDIC_KIND,
  LOW_FRAGILITY_STATUS,
  MEASURE_CONFIRMED,
  MEASURE_IMPLEMENTED,
  MEDIUM_FRAGILITY_STATUS,
  NECESSARY_FINALITY,
  NOT_ACCEPT_RISK_STATUSES,
  NOT_EDITABLE_STATUSES,
  NOT_ELIMINATE_COLLECTED_FIELD_MESSAGES,
  NOT_ELIMINATE_COLLECTED_FIELD_TEXTS,
  NOT_ELIMINATE_DATA_TREATMENT_MESSAGES,
  NOT_ELIMINATE_DATA_TREATMENT_TEXTS,
  NOT_ELIMINATE_LIFE_CYCLES_MESSAGES,
  NOT_ELIMINATE_LIFE_CYCLES_TEXTS,
  NOT_MEASURES_TAKEN_STATUS,
  NOT_ACCEPT_RISK_INCIDENT_STATUSES,
  OBSERVATION_FRAGILITY,
  OBSERVATION_NECESSITY_FRAGILITY,
  OBSERVATION_NECESSITY_PROPORTIONALITY,
  ON_TIME_STATUS_LABEL,
  ON_TIME_STATUS,
  OVERDUE_STATUS_LABEL,
  OVERDUE_STATUS,
  PENDING_STATUS_LABEL,
  PENDING_STATUS,
  QUESTION_FINALITY,
  RISK_ACCEPTED_STATUS_LABEL,
  RISK_ACCEPTED_STATUS,
  SEVERE_FRAGILITY_STATUS,
  SUBSTITUTED_STATUS_LABEL,
  SUBSTITUTED_STATUS,
  TECHNOLOGY_KIND,
  UNNECESSARY_FINALITY,
  ELIMINATED_FINALITY,
}
