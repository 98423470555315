import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  rowText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0em',
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headText: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '0em',
  },
  statusLabelAuthorized: {
    color: '#16A63C',
    backgroundColor: '#B4FFC8',
  },
  statusLabelRevoked: {
    color: '#DC3545',
    backgroundColor: '#FFE0E3',
  },
}))

export default styles
