import React from 'react'

import theme from 'theme'

const DPOEasy = ({
  alt = 'Logo da DPOEasy',
  fill = theme.palette.primary.main,
  height = 70,
  viewBox = '0 0 895.3 408.9', // Valor total da imagem
  width = 153.22,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={alt}
    >
      <style type="text/css">{`.st0{fill:${fill};}`}</style>
      <g>
        <path
          className="st0"
          d="M395,369.1v12.2h-49.8v-71.7h48.9v12.2h-34.9v17.3h30.2v11.3h-30.2v18.7H395z"
        />
        <path
          className="st0"
          d="M442.5,309.6h14.1l26.4,71.7h-14.3l-6-17.9h-26.3l-6,17.9H416L442.5,309.6z M459.4,353.6l-9.9-29.6l-9.9,29.6
		H459.4z"
        />
        <path
          className="st0"
          d="M549.7,328.4c-0.5-0.5-1.3-1.1-2.6-1.9c-1.2-0.8-2.8-1.6-4.5-2.3c-1.8-0.7-3.7-1.4-5.8-2
		c-2.1-0.6-4.2-0.9-6.4-0.9c-3.8,0-6.6,0.7-8.5,2.1c-1.9,1.4-2.9,3.4-2.9,5.9c0,1.9,0.6,3.5,1.8,4.6c1.2,1.1,3,2.1,5.5,2.9
		s5.5,1.7,9.1,2.6c4.7,1.1,8.8,2.5,12.3,4.2c3.5,1.6,6.1,3.8,8,6.4c1.9,2.6,2.8,6.1,2.8,10.5c0,3.8-0.7,7.1-2.1,9.8
		c-1.4,2.7-3.4,4.9-5.9,6.6c-2.5,1.7-5.3,2.9-8.5,3.7c-3.2,0.8-6.5,1.2-10.1,1.2c-3.6,0-7.1-0.4-10.7-1.1c-3.6-0.7-7-1.8-10.3-3.2
		c-3.3-1.4-6.3-3-9.1-5l6.2-12c0.6,0.6,1.7,1.4,3.2,2.4c1.5,1,3.4,2,5.7,2.9c2.2,1,4.6,1.8,7.3,2.5c2.6,0.7,5.3,1,8,1
		c3.8,0,6.6-0.6,8.6-1.9s2.9-3.1,2.9-5.4c0-2.1-0.8-3.8-2.3-5c-1.6-1.2-3.7-2.3-6.5-3.2c-2.8-0.9-6-1.9-9.8-3
		c-4.5-1.3-8.3-2.7-11.3-4.3c-3-1.6-5.3-3.6-6.8-6c-1.5-2.4-2.2-5.4-2.2-9.1c0-4.9,1.2-9,3.5-12.3c2.3-3.3,5.5-5.8,9.4-7.5
		c4-1.7,8.4-2.6,13.2-2.6c3.4,0,6.5,0.4,9.5,1.1c3,0.7,5.8,1.7,8.4,2.9c2.6,1.2,5,2.5,7.1,3.9L549.7,328.4z"
        />
        <path
          className="st0"
          d="M592.2,309.6l17.7,33.7l18-33.7h15l-26.1,46.7v25H603V356l-26-46.5H592.2z"
        />
      </g>
      <path
        className="st0"
        d="M881.3,186c0-50.1-40.7-90.8-90.8-90.8s-90.8,40.7-90.8,90.8c0,50.1,40.7,90.8,90.8,90.8S881.3,236,881.3,186z
	 M728.1,186c0-34.4,28-62.3,62.3-62.3s62.3,28,62.3,62.3c0,34.4-28,62.3-62.3,62.3S728.1,220.3,728.1,186z"
      />
      <path
        className="st0"
        d="M430.1,95.2c-50.1,0-90.8,40.7-90.8,90.8v90.7h28.5V186c0-34.4,28-62.3,62.3-62.3c34.4,0,62.3,28,62.3,62.3
	c0,34.4-28,62.3-62.3,62.3h-42.4v28.5h42.4c50.1,0,90.8-40.7,90.8-90.8C520.9,135.9,480.2,95.2,430.1,95.2z"
      />
      <path
        className="st0"
        d="M689.5,173.7c0-21-8.4-40.9-23.6-55.8c-14.7-14.5-34.8-22.7-54.9-22.7c0,0,0,0,0,0c-19.7,0-38.7,7.9-53.4,22.3
	C544.8,129.8,536,146,533.2,162c-0.9,5.1-0.9,10.3-0.9,11.9v102.8h28.5c0,0,0.1-107.5,0.5-109.9c3-17.4,20.8-43.1,49.6-43.1
	c0,0,0,0,0,0c27.2,0,50.1,22.9,50.1,50.1c0,27.6-22.5,50.1-50.1,50.1l-2,0h-30.6v28.5h22.4c1.5,0.1,6.9,0,10.2,0
	C654.2,252.3,689.5,217,689.5,173.7z"
      />
      <g>
        <path
          className="st0"
          d="M290.4,91.7L176.3,26.2c-10.4-6-23.2-6-33.6,0L28.6,91.7c-10.5,6-16.9,17.1-16.9,29.2v130.8
		c0,12.1,6.4,23.2,16.9,29.2l114.1,65.5c10.4,6,23.2,6,33.6,0l114.1-65.5c10.5-6,16.9-17.1,16.9-29.2V120.9
		C307.3,108.9,300.9,97.7,290.4,91.7z M148.3,305.8c-0.5,0.4-1,0.8-1.5,1.2c0,0,0,0,0,0c-1.4,1.1-2.9,2.2-4.5,3.2
		c-0.2,0.1-0.3,0.2-0.5,0.3c-0.4,0.3-0.8,0.5-1.2,0.8c-0.2,0.1-0.5,0.3-0.7,0.4c-0.4,0.2-0.8,0.5-1.2,0.7c-0.3,0.2-0.6,0.3-0.9,0.5
		c-0.6,0.3-1.1,0.6-1.7,0.9c-0.4,0.2-0.8,0.4-1.2,0.6c-0.3,0.1-0.6,0.3-0.9,0.4c-0.4,0.2-0.8,0.3-1.2,0.5c-0.3,0.1-0.6,0.2-0.9,0.3
		c-0.5,0.2-1,0.3-1.4,0.5c-0.2,0.1-0.5,0.2-0.7,0.2c-0.7,0.2-1.4,0.4-2.1,0.6c-0.2,0-0.4,0.1-0.6,0.1c-0.5,0.1-1.1,0.2-1.7,0.3
		c-0.3,0-0.5,0.1-0.8,0.1c-0.5,0.1-1.1,0.2-1.6,0.2c-0.2,0-0.5,0.1-0.7,0.1c-0.8,0.1-1.5,0.1-2.3,0.1c-2.3-0.1-13-6.6-14.4-7.5
		c-25.7-15.8-37.6-21.2-40.7-25c-0.9-1.1-1.1-4.1-1-5.2c0.1-1.8,0.1-1.7,0.6-4.9c0.1-0.4,0.1-0.8,0.2-1.2c0-0.1,0-0.2,0-0.3
		c0.1-0.8,0.3-1.7,0.4-2.6c0-0.1,0-0.2,0.1-0.3c0.2-0.9,0.3-1.9,0.5-3c0-0.1,0-0.2,0-0.3c0.2-1,0.4-2.1,0.6-3.2
		c0-0.2,0.1-0.4,0.1-0.6c0.1-0.4,0.1-0.9,0.2-1.3c4-22.6,10.8-58.2,10.8-58.2c0.7-4.4,4-14.2,9-14.3c2.5-0.1,4.4,0.5,5.6,1.7
		c1.4,1.4,1.8,3.4,1.9,3.9c-0.2,3.1,0.7,18.5,1,25c0,0.4,0.1,0.8,0.2,1.1l-4.1,4.1c-1.3,1.3-2,3-2,4.8c0,1.8,0.7,3.5,2,4.8
		c1.3,1.3,3,2,4.8,2c1.8,0,3.5-0.7,4.8-2l58.2-58.2c1.6-1,6.4-3.3,9.9,0.2c5.2,5.2,3.3,9.1,2.1,10.7l-34.6,34.6
		c-2.7,2.7-2.7,7,0,9.6c1.3,1.3,3,2,4.8,2c1.8,0,3.5-0.7,4.8-2l8.9-8.9c4.9,5.1,3.1,9,1.9,10.5l-13.1,13.1c-1.3,1.3-2,3-2,4.8
		c0,1.8,0.7,3.5,2,4.8c2.7,2.7,7,2.7,9.6,0l9.8-9.8c4.3,4.8,2.5,8.5,1.4,9.9l-13.1,13.1c-2.7,2.7-2.7,7,0,9.6c2.7,2.7,7,2.7,9.6,0
		l7.2-7.2c4.4,4.9,2.6,8.5,1.5,10C169.4,282.5,161,295.5,148.3,305.8z M165.3,306.2c8.4-7.7,14-14.3,14-14.3
		c0.1-0.1,0.3-0.3,0.4-0.4c4.2-5,9.1-17.4-2.8-29.2c0,0,0,0,0,0c3.4-6.8,3.7-16.7-5.2-25.6c-0.4-0.4-0.7-0.7-1.1-1
		c1.4-4.9,1.9-13.3-6.4-21.7l16.3-16.3c0.1-0.1,0.3-0.3,0.4-0.4c4.2-4.9,9.1-17.4-2.8-29.3c-9.7-9.7-22.3-5.2-27.7-1.5
		c-0.3,0.2-0.6,0.5-0.9,0.8L107.9,209c-0.3-6.7-0.5-11.5-0.5-12.4c0.2-2.7-0.7-9.1-5.5-14.2c-2.7-2.9-7.8-6.3-16-6
		c-14.2,0.5-20.5,16.5-22,25.9c0,0-3.6,23.9-7,46.2c-11-18.1-17.4-39.4-17.4-62.2c0-66.3,53.7-120,120-120s120,53.7,120,120
		C279.5,250.7,228.9,303.2,165.3,306.2z"
        />
        <path
          className="st0"
          d="M113.7,129.8c-2.9-2.9-7.6-2.9-10.5,0c-2.9,2.9-2.9,7.6,0,10.5l14.5,14.5c1.4,1.4,3.3,2.2,5.2,2.2
		s3.8-0.7,5.2-2.2c1.4-1.4,2.2-3.3,2.2-5.2c0-2-0.8-3.8-2.2-5.2L113.7,129.8z"
        />
        <path
          className="st0"
          d="M215.5,140.3c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0l-14.5,14.5c-1.4,1.4-2.2,3.3-2.2,5.2
		s0.8,3.8,2.2,5.2c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L215.5,140.3z"
        />
        <path
          className="st0"
          d="M159.4,109.6c-4.1,0-7.4,3.3-7.4,7.4l0,20.5c0,4.1,3.3,7.4,7.4,7.4c4.1,0,7.4-3.3,7.4-7.4V117
		C166.8,112.9,163.4,109.6,159.4,109.6z"
        />
        <path
          className="st0"
          d="M225.2,178.5h-20.5c-4.1,0-7.4,3.3-7.4,7.4c0,4.1,3.3,7.4,7.4,7.4l20.5,0h0c2,0,3.8-0.8,5.2-2.2
		c1.4-1.4,2.2-3.3,2.2-5.2C232.6,181.8,229.3,178.5,225.2,178.5z"
        />
      </g>
    </svg>
  )
}

export default DPOEasy
