import { useContext } from 'react'

import IncidentManegementContext from 'contexts/IncidentManegementContext'

const useIncidentManegement = () => {
  const context = useContext(IncidentManegementContext)

  if (context === undefined) {
    throw new Error(
      'useIncidentManegement must be used within a IncidentManegementContext.Provider',
    )
  }

  return {
    activeStep: context.activeStep,
    setActiveStep: context.setActiveStep,
    handleAdvanceStep: context.handleAdvanceStep,
    handleReturnStep: context.handleReturnStep,
    resetAdvanceStep: context.resetAdvanceStep,
    resetReturnStep: context.resetReturnStep,
    handleBack: context.handleBack,
    handleNext: context.handleNext,
    handleStep: context.handleStep,
    isCompleted: context.isCompleted,
    activeButton: context.activeButton,
    setActiveButton: context.setActiveButton,
    showButtons: context.showButtons,
    setShowButtons: context.setShowButtons,
    incident: context.incident,
    setIncident: context.setIncident,
    wantsToAdvanceStep: context.wantsToAdvanceStep,
    wantsToReturnStep: context.wantsToReturnStep,
    isDpo: context.isDpo,
  }
}

export default useIncidentManegement
