import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  ellipsisTable: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '9rem',
  },

  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  paperModal: {
    margin: theme.spacing(1),
    padding: theme.spacing(2, 2, 3),
    outline: 'none',
  },
}))

export default styles
