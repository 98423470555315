const MAX_ITEMS = 5

const fragilityEntitiesLabel = (fragilityEntities = []) => {
  const names = fragilityEntities?.map(
    (fragilityEntity) => fragilityEntity?.name,
  )

  if (names.length <= MAX_ITEMS) {
    return names.join(', ')
  }

  return names?.slice(0, MAX_ITEMS).join(', ') + '...'
}

export default fragilityEntitiesLabel
