import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Controller, useFormContext } from 'react-hook-form'
import { Paperclip as PaperclipIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Cancel as CancelIcon } from '@material-ui/icons'
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import { ButtonGroup, TextEditor } from 'components'

import helpers from 'helpers'

import useAuth from 'hooks/useAuth'

import theme from 'theme'
import styles from './styles'
import constants from 'constants/index'

const TextBox = ({
  form = 'text-box-form',
  attachmentControlName = 'attachments',
  controlName = 'status',
  buttonItems = [],
}) => {
  const { avatarLetters } = helpers
  const { user } = useAuth()
  const { control, errors, setValue, watch } = useFormContext()

  const useStyles = styles({ errors, attachmentControlName })
  const classes = useStyles()

  const smDown = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  })

  const attachments = watch(attachmentControlName)

  const handleFileChange = (event) => {
    const newFiles = Array.from(event?.target?.files || [])
    setValue(attachmentControlName, [...attachments, ...newFiles])

    event.target.value = ''
  }

  const handleRemoveFile = (fileName) => {
    const updatedFiles = attachments?.filter((file) => file?.name !== fileName)
    setValue(attachmentControlName, updatedFiles)
  }

  return (
    <Box>
      <Paper
        elevation={1}
        component={Box}
        p={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
      >
        <Box display="flex" flexDirection="column">
          <Box width="100%" pb={2}>
            <Controller
              control={control}
              name="content"
              as={
                <TextEditor
                  error={!!errors?.content}
                  helperText={errors?.content?.message}
                />
              }
              mode="onBlur"
            />
          </Box>
          {!isEmpty(attachments) && (
            <PerfectScrollbar
              options={{ wheelPropagation: false, useBothWheelAxes: true }}
            >
              <Box
                display="flex"
                flexDirection="row"
                pb={!isEmpty(attachments) ? 2 : 0}
              >
                {attachments?.map((file, index) => (
                  <Box key={index} mr={2}>
                    <Button type="button" className={classes.styledButton}>
                      <Typography className={classes.nameFile}>
                        {file?.name}
                      </Typography>
                      <Typography
                        className={classes.cancelIcon}
                        onClick={() => handleRemoveFile(file?.name)}
                      >
                        <CancelIcon fontSize="small" />
                      </Typography>
                    </Button>
                  </Box>
                ))}
              </Box>
            </PerfectScrollbar>
          )}
          <Divider />
        </Box>
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={theme.spacing(4)}
          spacing={4}
        >
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent={smDown ? 'center' : 'start'}>
              <Avatar alt={user?.name}>{avatarLetters(user?.name)}</Avatar>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                ml={2}
                maxWidth="100%"
                width={0}
                flexGrow={1}
              >
                <Tooltip title={user?.name} placement="top">
                  <Typography
                    fontWeight={700}
                    color="primary"
                    variant="subtitle1"
                    noWrap
                  >
                    {user?.name}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box display="flex" justifyContent={smDown ? 'center' : 'end'}>
              <Button
                variant="outlined"
                component="label"
                className={classes.errorColor}
                startIcon={<PaperclipIcon size={14} />}
                fullWidth
              >
                ANEXAR
                <Box
                  display="none"
                  component="input"
                  type="file"
                  multiple
                  aria-label="Selecionar arquivo"
                  accept={constants.validations.ALL_FILE_TYPES}
                  onChange={handleFileChange}
                />
              </Button>
              <Controller
                as={<></>}
                control={control}
                name={attachmentControlName}
              />
            </Box>
          </Grid>
          {!isEmpty(buttonItems) && (
            <Grid item xs={12} md={6}>
              <Controller
                as={
                  <ButtonGroup
                    options={buttonItems}
                    controlName={controlName}
                    title="Enviar como"
                    type="submit"
                    form={form}
                    fullWidth
                  />
                }
                control={control}
                name={controlName}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      {!!errors?.[attachmentControlName] && (
        <FormHelperText error={!!errors?.[attachmentControlName]}>
          {<>{errors?.[attachmentControlName]?.message}</>}
        </FormHelperText>
      )}
    </Box>
  )
}

TextBox.propTypes = {
  form: PropTypes.string,
  attachmentControlName: PropTypes.string,
  controlName: PropTypes.string,
  buttonItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
}

export default TextBox
