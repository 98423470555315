import permissions from '../permissions'

const DASHBOARD_LIST = [
  {
    name: 'Visualizar',
    tag: permissions.DASHBOARD.VIEW,
  },
  {
    name: 'Ver a estatística de processamento de dados',
    tag: permissions.DASHBOARD.VIEW_PROCESS,
  },
  {
    name: 'Ver a estatística de relatório de não conformidade',
    tag: permissions.DASHBOARD.VIEW_RMC,
  },
  {
    name: 'Ver estatísticas de questionários',
    tag: permissions.DASHBOARD.VIEW_QUESTIONNAIRE,
  },
]

export default DASHBOARD_LIST
