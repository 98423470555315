import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import {
  PasswordInputField,
  PasswordValidator,
  TermsToPolicy,
} from 'components'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'

import { routes } from 'Routes'
import * as service from 'service'
import schemas from '../../schemas'
import useStyles from './styles'

export const Form = ({ defaultValues }) => {
  const [loading, setLoading] = useState(false)
  const [justSubmitted, setJustSubmitted] = useState(false)
  const [termsAccept, setTermsAccept] = useState(false)
  const [policyAccept, setPolicyAccept] = useState(false)

  const { response } = useFetch(service.dponet.auth.checkTerm, {
    token_raw: defaultValues.resetPasswordToken,
  })

  const history = useHistory()
  const snackbar = useSnackbar()
  const classes = useStyles()

  const { control, errors, handleSubmit, watch } = useForm({
    validationSchema: schemas.newPassword,
    defaultValues: defaultValues,
  })

  const onSubmit = async (data) => {
    setLoading(true)

    data.resetPasswordToken = defaultValues.resetPasswordToken

    try {
      await service.dponet.users.newPassword(data)

      snackbar.open({
        message:
          'Senha alterada com sucesso. Faça o login com as novas credenciais.',
        variant: 'success',
      })
      history.push(routes.root)
    } catch (error) {
      history.push(routes.root, {
        error:
          error?.response?.data?.error?.message || 'Email ou senha inválidos!',
      })
    }
  }

  const handleSubmitForm = (event) => {
    setJustSubmitted(true)
    handleSubmit(onSubmit)(event)
  }

  const confirmedAt = !!response?.data?.user?.confirmedAt

  const acceptCondition = confirmedAt || (policyAccept && termsAccept)

  return (
    <Box>
      <Box mb={3}>
        <PasswordValidator
          passwordInput={watch('password')}
          justSubmitted={justSubmitted}
        />
      </Box>
      <form onSubmit={handleSubmitForm}>
        <Controller
          as={
            <PasswordInputField
              label="Nova senha"
              color="primary"
              variant="outlined"
              error={!!errors.password}
              fullWidth
            />
          }
          control={control}
          name="password"
          mode="onChange"
        />
        <Controller
          as={
            <PasswordInputField
              label="Confirme a senha"
              variant="outlined"
              margin="normal"
              error={!!errors.passwordConfirm}
              helperText={errors?.passwordConfirm?.message}
              fullWidth
            />
          }
          control={control}
          name="passwordConfirm"
          mode="onChange"
        />
        <Box>
          {!confirmedAt && (
            <TermsToPolicy
              setPolicyAccept={setPolicyAccept}
              setTermsAccept={setTermsAccept}
            />
          )}
        </Box>
        <Box
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Button
            type="submit"
            variant="contained"
            disabled={!acceptCondition}
            className={classes.buttonPrimary}
          >
            {loading ? 'Carregando...' : 'Cadastrar nova senha'}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default Form
