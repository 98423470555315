import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 50,
    padding: 60,
    width: '100%',
  },
  title: {
    fontSize: 40,
    fontFamily: 'Raleway',
    fontWeight: 700,
    textAlign: 'center',
  },
  question: {
    fontSize: 20,
    fontFamily: 'Raleway',
    fontWeight: 700,
    textAlign: 'center',
  },
  answer: {
    fontSize: 16,
    fontFamily: 'Roboto',
    fontWeight: 400,
    textAlign: 'justify',
    color: theme.palette.text.mainMenu,
  },
  cardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
}))

export default styles
