import React, { useState } from 'react'

import * as service from 'service'
import DataCollectedModalContext from 'contexts/DataCollectedModalContext'

const DataCollectedModalProvider = ({ children, dataCollecteds }) => {
  const [data, setData] = useState(dataCollecteds)
  const [isLoading, setLoading] = useState(false)

  const loadData = async (dataProcessId, dataCollectedId = '') => {
    setLoading(true)
    const [response] = await Promise.all([
      service.dponet.dataCollecteds.get({
        dataCollectedId: dataCollectedId,
        dataProcessId: dataProcessId,
      }),
    ])
    setData(response.data.dataCollecteds)
    setLoading(false)
  }

  return (
    <DataCollectedModalContext.Provider
      value={{
        data,
        setData,
        loadData,
        isLoading,
      }}
    >
      {children}
    </DataCollectedModalContext.Provider>
  )
}

export default DataCollectedModalProvider
