import { useEffect } from 'react'

const useBeforeunload = (handler = () => {}) => {
  if (typeof handler !== 'function') {
    throw new TypeError(
      `Expected "handler" to be a function, not ${typeof handler}.`,
    )
  }

  useEffect(() => {
    const handleBeforeunload = (event) => {
      let returnValue

      if (typeof handler === 'function') {
        returnValue = handler(event)
      }

      if (event.defaultPrevented) {
        event.returnValue = ''
      }

      if (typeof returnValue === 'string') {
        event.returnValue = returnValue
        return returnValue
      }
    }

    window.addEventListener('beforeunload', handleBeforeunload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload)
    }
  }, [handler])
}

export default useBeforeunload
