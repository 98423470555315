import defineMixpanelId from './defineMixpanelId'
import getIndexByTag from './getIndexByTag'
import getStepId from './getStepId'
import getStepIdByTag from './getStepIdByTag'
import getTagByIndex from './getTagByIndex'
import homeTrails from './homeTrails'
import validRedirectionStep from './validRedirectionStep'

const userSteps = {
  getIndexByTag,
  getStepId,
  getStepIdByTag,
  getTagByIndex,
  homeTrails,
  defineMixpanelId,
  validRedirectionStep,
}

export default userSteps
