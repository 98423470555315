const oppenedQuestions = (subtitle, questionnaireFormatted) => {
  let oppenedQuestions
  if (subtitle === 'Medidas adotadas') {
    oppenedQuestions = questionnaireFormatted?.addoptedQuestions
  } else if (subtitle === 'Medidas não adotadas') {
    oppenedQuestions = questionnaireFormatted?.nonAddoptedQuestions
  } else {
    oppenedQuestions = questionnaireFormatted?.oppenedQuestions
  }
  return oppenedQuestions
}

export default oppenedQuestions
