import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { Box } from '@material-ui/core'

import { RoundedButton, TextEditor } from 'components'
import { Text } from 'views/UserSteps/components'

import helpers from 'helpers'

import useTrailStep from 'hooks/useTrailStep'
import useAuth from 'hooks/useAuth'

import * as service from 'service'
import schema from './schema'
import constants from 'constants/index'

const TextEditorTrail = () => {
  const { handleNext, jumpStep, privacyPolicy } = useTrailStep()
  const [loading, setLoading] = useState(false)
  const { company, userCompany } = useAuth()

  const { handleSubmit, control, errors } = useForm({
    mode: 'onSubmit',
    validationSchema: schema,
    defaultValues: {
      title: privacyPolicy?.title ?? 'Aviso de Privacidade',
      stauts: '2',
      content:
        privacyPolicy?.content ??
        helpers.privacyPolicies.defaultPrivacy({
          ...company,
          ...userCompany?.company,
        }),
    },
  })

  const onSubmit = async (data) => {
    setLoading(true)
    const privacyPolicyData = {
      title: 'Aviso de Privacidade',
      status: data.status,
      content: data.content,
      category: constants.privacyPolicy.CATEGORIES_LIST.PRIVACY_POLICY,
    }
    if (!!privacyPolicy?.id) {
      await service.dponet.privacyPolicies.put({
        privacyPolicyId: privacyPolicy?.id,
        privacyPolicy: privacyPolicyData,
      })
    } else {
      await service.dponet.privacyPolicies.create({
        privacyPolicy: privacyPolicyData,
      })
    }
    setLoading(false)
    handleNext()
  }

  const handleJump = () => {
    if (!!privacyPolicy?.id) {
      service.dponet.privacyPolicies.put({
        privacyPolicyId: privacyPolicy?.id,
        privacyPolicy: { status: '1' },
      })
    }
    jumpStep()
  }

  return (
    <Box width="100%" pb={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box width="100%" pb={2}>
          <Controller
            control={control}
            name="content"
            as={
              <TextEditor
                error={!!errors?.content}
                helperText={errors?.content?.message}
              />
            }
            mode="onBlur"
          />
          <Controller
            as={<input type="hidden" />}
            defaultValue="2"
            name="status"
            control={control}
          />
          <Controller
            as={<input type="hidden" value="'Política de Privacidade" />}
            name="title"
            control={control}
          />
        </Box>
        <Text variant="subtitle1" gutterBottom>
          *Ao avançar, este documento será automaticamente disponibilizado no
          seu canal de comunicação
        </Text>
        <Box mt={2} display="flex" alignItems="center" justifyContent="center">
          <Box mr={2}>
            <RoundedButton
              disabled={loading}
              variant="outlined"
              withPadding
              onClick={handleJump}
            >
              Pular
            </RoundedButton>
          </Box>
          <RoundedButton
            disabled={loading}
            type="submit"
            withPadding
            variant="primary"
          >
            Avançar
          </RoundedButton>
        </Box>
      </form>
    </Box>
  )
}

export default TextEditorTrail
