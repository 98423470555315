import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Grid, TextField, Typography, Button, Box } from '@material-ui/core'
import { Select as SelectComponent, LoadingFeedback } from 'components'
import { FormTerms } from 'components/TermsToPolicy/components'

import { useForm, Controller } from 'react-hook-form'
import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'
import useSubscription from 'hooks/useSubscription'

import * as service from 'service'
import { getGoogleRecaptchaToken } from 'service/env'
import ReCAPTCHA from 'react-google-recaptcha'

import helpers from 'helpers'
import schema from './schema'

const Form = ({ setCongratulation }) => {
  const [LoadingReq, setLoadingReq] = useState(false)
  const [termsAccept, setTermsAccept] = useState(false)
  const [recaptcha, setRecaptcha] = useState(false)
  const [inputTimeout, setInputTimeout] = useState(null)
  const [documentNotExist, setDocumentNotExist] = useState(true)

  const snackbar = useSnackbar()
  const { recaptchaRef } = useSubscription()

  const disabledBtn = !termsAccept || !recaptcha

  const {
    control,
    handleSubmit,
    errors,
    reset,
    clearError,
    getValues,
    setError,
    watch,
  } = useForm({
    validationSchema: schema,
    defaultValues: {
      responsibleName: '',
      responsibleEmail: '',
      companyName: '',
      document: '',
      phone: '',
      segmentId: '',
      planId: '',
    },
  })

  const { response, isLoading } = useFetch(service.dponet.segments.get, {
    order: 'name ASC',
    active: true,
    perPage: 10000,
    activePlans: true,
    partnership: true,
  })

  const { response: responsePlan, isLoading: isLoadingPlan } = useFetch(
    service.dponet.plans.get,
    {
      active: true,
      segmentId: watch('segmentId'),
      perPage: 10000,
    },
    [watch('segmentId')],
    !!watch('segmentId'),
  )

  const onSubmit = async (data) => {
    if (!documentNotExist) {
      return snackbar.open({
        message:
          'O CNPJ/CPF já esta em uso, verifique se os dados estão corretos!',
        variant: 'error',
      })
    }
    setLoadingReq(true)
    try {
      const dataFormatted = helpers.companyFreeTrials.mountCreate(
        data,
        recaptcha,
      )
      const response = await service.dponet.preRegistrations.create({
        preRegistration: dataFormatted,
      })
      if (response?.status === 201) {
        reset()
        setCongratulation(true)
      } else {
        snackbar.open({
          message: 'O sistema está fora do ar, tente novamente mais tarde.',
          variant: 'error',
        })
      }
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Erro ao criar empresa!',
        variant: 'error',
      })
    } finally {
      setLoadingReq(false)
    }
  }

  const verifyDocument = (value) => {
    if (inputTimeout) clearTimeout(inputTimeout)
    setInputTimeout(
      setTimeout(async () => {
        if (value === '') {
          clearError('document')
          return
        }
        try {
          const document = getValues('document')
          await service.dponet.companies.verifyDocument(
            helpers.formatters.onlyNumbers(document),
          )
          clearError('document')
          setDocumentNotExist(true)
        } catch ({ response }) {
          if (response?.status === 404) {
            setDocumentNotExist(true)

            clearError('document')
          } else {
            setDocumentNotExist(false)
            setError('document', {
              type: {
                notMatch:
                  response?.data?.error?.message ||
                  'Este documento já está em uso, portanto não é possível realizar a contratação.',
              },
            })
          }
        }
      }, 1250),
    )
  }

  return (
    <>
      <LoadingFeedback
        open={isLoading || LoadingReq}
        style={{ color: 'green' }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box pt={6}>
          <Box my={2}>
            <Typography variant="h3">Cadastro</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    error={!!errors.responsibleName}
                    helperText={errors?.responsibleName?.message}
                    label="Nome do responsável"
                  />
                }
                control={control}
                name="responsibleName"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    error={!!errors.responsibleEmail}
                    helperText={errors?.responsibleEmail?.message}
                    label="E-mail do responsável"
                  />
                }
                control={control}
                name="responsibleEmail"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    error={!!errors.companyName}
                    helperText={errors?.companyName?.message}
                    label="Nome da empresa"
                  />
                }
                control={control}
                name="companyName"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    inputProps={{
                      maxLength: 18,
                    }}
                    error={!!errors.document}
                    helperText={errors?.document?.message}
                    label="CNPJ/CPF"
                  />
                }
                onChange={([event]) => {
                  verifyDocument(event.target.value)
                  return helpers.formatters.cnpjOrCpf(event.target.value)
                }}
                control={control}
                name="document"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    inputProps={{
                      maxLength: 18,
                      minLength: 14,
                    }}
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    label="Telefone"
                  />
                }
                onChange={([event]) => {
                  return helpers.formatters.phone(event.target.value, true)
                }}
                control={control}
                name="phone"
                mode="onChange"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                as={
                  <SelectComponent
                    items={response?.data?.segments || []}
                    label="Segmentos"
                    error={!!errors.segmentId}
                    helperText={errors?.segmentId?.message}
                  />
                }
                control={control}
                name="segmentId"
                mode="onChange"
              />
            </Grid>
            {watch('segmentId') !== '' && (
              <Grid item xs={12}>
                <Controller
                  as={
                    <SelectComponent
                      items={responsePlan?.data?.plans || []}
                      label={
                        isLoadingPlan ? 'Carregando...' : 'Tamanho da empresa'
                      }
                      error={!!errors.planId}
                      helperText={errors?.planId?.message}
                      disabled={isLoadingPlan}
                    />
                  }
                  control={control}
                  name="planId"
                  mode="onChange"
                />
              </Grid>
            )}
          </Grid>

          <Box mt={2}>
            <FormTerms
              istrail
              setTermsAccept={setTermsAccept}
              stylesProps={{ paddingLeft: 0 }}
            />
            <ReCAPTCHA
              ref={recaptchaRef}
              theme="light"
              sitekey={getGoogleRecaptchaToken()}
              onChange={(token) => {
                setRecaptcha(token)
              }}
              onExpired={() => {
                setRecaptcha(false)
              }}
            />
          </Box>

          <Grid item xs={12}>
            <Box my={4}>
              <Button
                disabled={disabledBtn}
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                CADASTRAR
              </Button>
            </Box>
          </Grid>
        </Box>
      </form>
    </>
  )
}

Form.propTypes = {
  setCongratulation: PropTypes.func,
}

Form.propTypes = {
  setCongratulation: () => {},
}

export default Form
