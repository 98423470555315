import React from 'react'
import clsx from 'clsx'
import { Grid, Card, Box, Tooltip, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import palette from 'theme/palette'

import useStyles from './styles'

const StatusCard = ({
  selectedCard,
  handleClick,
  backgroundIconColor,
  icon,
  label,
  value,
}) => {
  const classes = useStyles()

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Card
        className={clsx(classes.root, {
          [classes.selectedCard]: selectedCard,
        })}
      >
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          p={1}
          px={2}
          onClick={handleClick}
        >
          <Box pr={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.statusCard}
              style={{ backgroundColor: backgroundIconColor }}
            >
              {icon}
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              className={classes.titleBox}
            >
              <Tooltip title={label} placement="bottom-start">
                <Typography
                  color="textSecondary"
                  variant="h6"
                  className={classes.titleCard}
                  noWrap
                >
                  {label}
                </Typography>
              </Tooltip>
              <Typography
                color="textPrimary"
                variant="body1"
                className={classes.valueCard}
              >
                {value}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </Grid>
  )
}

StatusCard.propTypes = {
  selectedCard: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  backgroundIconColor: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
}

StatusCard.defaultProps = {
  selectedCard: false,
  handleClick: () => {},
  backgroundIconColor: palette.black,
}

export default StatusCard
