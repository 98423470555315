import React, { useState, useEffect, createRef } from 'react'
import { AlertCircle as AlertCircleIcon } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import { isBoolean, isEmpty } from 'lodash'
import clsx from 'clsx'

import useSnackbar from 'hooks/useSnackbar'
import usePermissions from 'hooks/usePermissions'

import { useHistory, useLocation } from 'react-router-dom'
import { routes } from 'Routes'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'
// import { reverse } from 'named-urls'
import theme from 'theme'

import {
  Chip,
  Card,
  Box,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Button,
  makeStyles,
  Tab,
  Tabs,
  useMediaQuery,
  Tooltip,
  IconButton,
  FormControl,
} from '@material-ui/core'

import styles from './styles'
import { GapAnalysisHeader, LoadingFeedback } from 'components'
import DialogNotApplicableQuestion from '../DialogNotApplicableQuestion'
import DialogConfirmChangeAnswer from '../DialogConfirmChangeAnswer'
import DialogQuestionInformation from '../DialogQuestionInformation'
import { Alert } from '@material-ui/lab'
import DialogTechnologyCompany from '../DialogTechnologyCompany'

const useStyles = makeStyles(styles)

const QuestionsReply = ({
  questionnaire,
  isLoading,
  refresh = () => {},
  companyKind,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()
  const location = useLocation()
  const permissions = usePermissions()

  const isQuestionnaireDisabled =
    questionnaire?.name === constants.questionnaire.FUNDAMENTAL_REQUIREMENTS &&
    !permissions.isSupplier()

  const isNotApplicableDisabled =
    questionnaire?.name === constants.questionnaire.FUNDAMENTAL_REQUIREMENTS &&
    permissions.isSupplier()

  const { handleSubmit, control, setValue } = useForm()

  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState()
  const [openDialogNotApplicable, setOpenDialogNotApplicable] = useState(false)
  const [openDialogConfirmChangeAnswer, setOpenDialogConfirmChangeAnswer] =
    useState(false)
  const [openDialogQuestionInformation, setOpenDialogQuestionInformation] =
    useState(false)
  const [openDialogTechnologyCompany, setOpenDialogTechnologyCompany] =
    useState(false)

  const [changeReply, setChangeReply] = useState()
  const [questionnaireData, setQuestionnaireData] = useState({ answers: [] })
  const [notApplicableQuestions, setNotApplicableQuestions] = useState([])
  const [questionOutsourceSoftware, setQuestionOutsourceSoftware] = useState()
  const [questionSelected, setQuestionSelected] = useState()

  const detachedId = location?.hash?.substring(1)
  const questionnaireId = questionnaire?.id
  const hasDynamicQuestions = questionnaire?.hasDynamicQuestions

  const refs = questionnaire?.applicableChoices?.reduce((acc, value) => {
    acc[value.id] = createRef()
    return acc
  }, {})

  useEffect(() => {
    if (!isEmpty(questionnaire)) {
      if (!currentTab) {
        setCurrentTab(
          helpers.questionnaires.defaultTab(questionnaire?.activeQuestions),
        )
      }

      if (currentTab === 'all') {
        setDisplayQuestions(
          questionnaire?.activeQuestions?.filter(filterActiveQuestion),
        )
      }

      if (currentTab === 'pending') {
        setDisplayQuestions(questionnaire.pendingQuestions)
      }

      if (currentTab === 'answered') {
        setDisplayQuestions(
          questionnaire.answeredQuestions.filter(filterAnsweredQuestion),
        )
      }
    }
  }, [currentTab, questionnaire])

  useEffect(() => {
    if (isLoading || !detachedId || isEmpty(refs)) {
      return
    }

    if (!refs[detachedId]) {
      return history.replace()
    }

    if (!!refs[detachedId].current) {
      refs[detachedId].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }

    // eslint-disable-next-line
  }, [detachedId, questionnaire, isLoading])

  const filterActiveQuestion = (question) => question?.applicableChoice

  const filterAnsweredQuestion = (question) =>
    question?.answer !== 'Não se aplica'
  const [displayQuestions, setDisplayQuestions] = useState(
    questionnaire?.activeQuestions?.filter(filterActiveQuestion),
  )

  const submitAnswers = async (questionnaire) => {
    try {
      setLoading(true)
      await service.dponet.questionnaires.reply({
        questionnaireId,
        questionnaire,
      })
      setLoading(false)
      setOpenDialogNotApplicable(false)
      refresh()
      snackbar.open({
        message: 'Questionário respondido com sucesso',
        variant: 'success',
      })
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error &&
            error.response &&
            error.response.data &&
            error.response.data.error,
        ),
        variant: 'error',
      })
      setOpenDialogNotApplicable(false)
    }
  }

  const onSubmit = async (data) => {
    var questionnaireAnswers = { answers: [] }
    Object.keys(data).map((key) => {
      return questionnaireAnswers.answers.push({
        id: key,
        answer: data[key],
      })
    })

    const notApplicableAnswers = questionnaireAnswers.answers.filter(
      (question) => question.answer === 'Não se aplica',
    )

    const questions = questionnaire.questions.map((question, index) => ({
      index: index + 1,
      ...question,
    }))

    const notApplicableQuestions = questions.filter((question) =>
      notApplicableAnswers
        .map((notApplicableQuestion) => notApplicableQuestion.id)
        .includes(question.id.toString()),
    )

    if (notApplicableAnswers.length > 0) {
      setQuestionnaireData(questionnaireAnswers)
      setNotApplicableQuestions(notApplicableQuestions)
      return setOpenDialogNotApplicable(true)
    }
    await submitAnswers(questionnaireAnswers)
  }

  const getCardStyle = (question) => {
    if (detachedId && parseInt(detachedId) === question.id) {
      return classes.detachedBox
    }

    return ''
  }

  const handleChangeTabs = (_, value) => {
    if (['all', 'pending', 'answered'].includes(value)) {
      setCurrentTab(value)
    }
  }

  const getBadgeValue = (tagValue) => {
    if (tagValue === 'all') {
      return questionnaire?.activeQuestions?.filter(filterActiveQuestion).length
    }

    if (tagValue === 'pending') {
      return questionnaire?.pendingQuestions?.length
    }

    if (tagValue === 'answered') {
      return questionnaire?.answeredQuestions?.filter(filterAnsweredQuestion)
        .length
    }
  }

  const isActive = useMediaQuery(theme.breakpoints.up('md'))
  const flagResponsive = () => {
    return isActive ? 'flex-end' : 'flex-start'
  }

  const viewDialogConfirmChangeAnswer = (question, target) =>
    question.nonComplianceReport && target.value !== question.openWith

  useEffect(() => {
    if (
      questionnaire?.name === constants.questionnaire.TECHNOLOGICAL_CONTROLS &&
      !isBoolean(hasDynamicQuestions) &&
      companyKind !== constants.company.SUPPLIER_KIND
    ) {
      setOpenDialogTechnologyCompany(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaire?.name])

  const handleChange = async (event) => {
    setQuestionOutsourceSoftware(event.target.value === 'true')
  }

  useEffect(() => {
    if (questionOutsourceSoftware !== undefined) {
      updateQuestionnaire()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionOutsourceSoftware])

  const updateQuestionnaire = async () => {
    setLoading(true)
    try {
      await service.dponet.questionnaires.handleDynamicQestions({
        questionnaireId: questionnaireId,
        hasDynamicQuestions: questionOutsourceSoftware,
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleAction = async (question) => {
    setOpenDialogQuestionInformation(true)
    setQuestionSelected(question)
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <GapAnalysisHeader title='As perguntas desta aba "Minhas Questões" são as aplicáveis para a sua organização. Inicialmente, o DPOnet recomenda certas perguntas para o tamanho e ramo de atuação da sua empresa, mas você pode adaptar os questionários e indicar que alguma pergunta "Não se aplica" ou então buscar novas perguntas em "Outras Questões"' />

      {questionnaire.name === constants.questionnaire.TECHNOLOGICAL_CONTROLS &&
        companyKind !== constants.company.SUPPLIER_KIND && (
          <Card className={classes.CardTechnologyCompany}>
            <Typography variant="h4">
              Sua empresa faz ou terceiriza software?
            </Typography>

            <Box py={1} display="flex">
              <FormControl component="fieldset">
                <RadioGroup
                  name="hasDynamicQuestions"
                  value={String(questionOutsourceSoftware)}
                  onChange={handleChange}
                  className={classes.boxButtons}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Sim"
                    checked={hasDynamicQuestions}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Não"
                    checked={hasDynamicQuestions === false}
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <Alert
              className={classes.levelAlert}
              variant="filled"
              severity="info"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.levelAlertTitle}>
                    Essa pergunta poderá ser modificada, mas qualquer alteração
                    resultará em mudanças nos questionários correspondentes.
                  </Typography>
                  <Typography>
                    “Desenvolvimento de software é o processo de conceber,
                    especificar, programar, testar e corrigir programas de
                    computador para resolver problemas ou automatizar tarefas
                    específicas, seguindo metodologias e boas práticas para
                    garantir eficiência e qualidade no produto final.”
                  </Typography>
                </Grid>
              </Grid>
            </Alert>
          </Card>
        )}

      {!!currentTab && (
        <Tabs
          scrollButtons="auto"
          onChange={handleChangeTabs}
          textColor="primary"
          indicatorColor="primary"
          value={currentTab}
          variant="standard"
        >
          {constants.questions.TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={
                <Box>
                  {tab.label} ({getBadgeValue(tab.value)})
                </Box>
              }
            />
          ))}
        </Tabs>
      )}
      <Card>
        <Box pb={3}>
          {!isEmpty(displayQuestions) ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              {displayQuestions.map((question, index) => (
                <Box key={question.id}>
                  <Box
                    mx={3}
                    my={2}
                    id={question.id}
                    ref={refs[question.id]}
                    className={getCardStyle(question)}
                  >
                    <Grid container>
                      <Grid item md={9} sm={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="h5">
                              {index + 1}. {question.theme}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box width="100%" mt={1} ml={2}>
                              <Typography variant="body1">
                                {question.question}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <Box width="100%" mt={1} ml={2}>
                              <Controller
                                control={control}
                                name={question.id.toString()}
                                defaultValue={question.answer}
                                as={
                                  <Tooltip
                                    title={
                                      isQuestionnaireDisabled
                                        ? 'Sua empresa já adota essa medida porque tem o DPOnet.'
                                        : ''
                                    }
                                  >
                                    <RadioGroup row>
                                      <Box
                                        id={
                                          constants.questionnaire
                                            .QUESTIONNAIRE_REPLY_DRIVER_STEP_3
                                        }
                                      >
                                        <FormControlLabel
                                          value="Sim"
                                          control={<Radio color="primary" />}
                                          label="Sim"
                                          disabled={
                                            isQuestionnaireDisabled ||
                                            permissions.isDpo()
                                          }
                                        />
                                        <FormControlLabel
                                          value="Parcial"
                                          control={<Radio color="primary" />}
                                          label="Parcial"
                                          disabled={isQuestionnaireDisabled}
                                        />
                                        <FormControlLabel
                                          value="Não"
                                          control={<Radio color="primary" />}
                                          label="Não"
                                          disabled={
                                            isQuestionnaireDisabled ||
                                            permissions.isDpo()
                                          }
                                          className={clsx({
                                            [classes.endFormControl]:
                                              isNotApplicableDisabled,
                                          })}
                                        />
                                        {!isNotApplicableDisabled && (
                                          <FormControlLabel
                                            value="Não se aplica"
                                            control={<Radio color="primary" />}
                                            label="Não se aplica"
                                            disabled={
                                              isQuestionnaireDisabled ||
                                              permissions.isDpo()
                                            }
                                            className={
                                              !isNotApplicableDisabled &&
                                              classes.endFormControl
                                            }
                                          />
                                        )}
                                      </Box>
                                    </RadioGroup>
                                  </Tooltip>
                                }
                                onChange={([event]) => {
                                  if (
                                    viewDialogConfirmChangeAnswer(
                                      question,
                                      event.target,
                                    )
                                  ) {
                                    setChangeReply(event.target)
                                    setOpenDialogConfirmChangeAnswer(true)
                                    event.stopPropagation()
                                    event.target.value = question.answer
                                  }
                                  return event
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={3} sm={12}>
                        <Box
                          width="100%"
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          alignItems={flagResponsive()}
                          height="100%"
                          ml={2}
                          pr={2}
                        >
                          <Box>
                            <Chip
                              size="small"
                              className={helpers.questions.chipStatusColor(
                                question.status,
                                classes,
                              )}
                              label={helpers.questions.statusName(
                                question.status,
                              )}
                            />
                            {question?.explanation && question?.purpose && (
                              <IconButton
                                onClick={() => handleAction(question)}
                              >
                                <AlertCircleIcon size={24} />
                              </IconButton>
                            )}
                          </Box>
                          {!!question.answer && !!question.user && (
                            <Box mt={2}>
                              <Typography variant="body2">
                                Respondido por: {question?.user?.name}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider />
                </Box>
              ))}
              <Box ml={2} mt={2} display="flex" justifyContent="flex-start">
                <Box pr={1}>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      history.push(routes.questionnaire.all)
                    }}
                  >
                    Voltar
                  </Button>
                </Box>
                {!isQuestionnaireDisabled && !permissions.isDpo() && (
                  <Box>
                    <Button color="primary" type="submit" variant="contained">
                      Salvar
                    </Button>
                  </Box>
                )}
              </Box>
            </form>
          ) : (
            <Box mx={4} mt={3}>
              <Typography variant="body1">
                Não existe nenhuma questão com este status.
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
      <DialogNotApplicableQuestion
        open={openDialogNotApplicable}
        setOpen={setOpenDialogNotApplicable}
        handleConfirmation={() => submitAnswers(questionnaireData)}
        questionnaire={notApplicableQuestions}
      />
      <DialogConfirmChangeAnswer
        open={openDialogConfirmChangeAnswer}
        setOpen={setOpenDialogConfirmChangeAnswer}
        changeReply={changeReply}
        setValue={setValue}
      />
      <DialogQuestionInformation
        open={openDialogQuestionInformation}
        setOpen={setOpenDialogQuestionInformation}
        setLoading={setLoading}
        refresh={refresh}
        question={questionSelected}
        questionnaireName={questionnaire?.name}
        questionnaireId={questionnaireId}
      />
      <DialogTechnologyCompany
        questionnaireId={questionnaireId}
        open={openDialogTechnologyCompany}
        setOpen={setOpenDialogTechnologyCompany}
        setLoading={setLoading}
        refresh={refresh}
      />
    </>
  )
}

export default QuestionsReply
