import helpers from 'helpers'

const cnpjCpf = (value, method) => {
  const document = helpers.formatters.onlyNumbers(value + '')

  if (document.length < 12) {
    return helpers.functions.cpf[method](document)
  }

  return helpers.functions.cnpj[method](document)
}

export default cnpjCpf
