import constants from 'constants/index'

const getSourceFilterLabel = (source) => {
  switch (source) {
    case constants.dataProcess.DEPARTMENT_SOURCE:
      return 'Departamentos'
    case constants.dataProcess.THIRD_PARTY_SOURCE:
      return 'Organizações'
    default:
      return 'Fontes públicas'
  }
}

export default getSourceFilterLabel
