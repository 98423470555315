import React, { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'

import { Box, Button, Divider, TextField } from '@material-ui/core'

import schema from './schema'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'

const FormModal = ({
  nonComplianceReportId,
  specificRisk,
  handleCloseModal = () => {},
  submitSuccess = () => {},
}) => {
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)
  const { handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: {
      nonComplianceReportId: nonComplianceReportId,
      description: specificRisk?.description || '',
    },
  })

  const specificRiskId = specificRisk?.id || false

  const onSubmit = async (data) => {
    const submitMethod = specificRiskId
      ? service.dponet.specificRisks.update
      : service.dponet.specificRisks.create

    try {
      setLoading(true)
      await submitMethod({
        nonComplianceReportId,
        specificRiskId,
        ...{ specificRisk: data },
      })
      setLoading(false)
      snackbar.open({
        message: `Risco especifico ${
          specificRiskId ? 'editado' : 'criado'
        } com sucesso`,
        variant: 'success',
      })
      await submitSuccess()
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error.response && error.response.data && error.response.data.error,
        ),
        variant: 'error',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box p={2}>
        <Controller
          as={
            <TextField
              label="Descrição do risco"
              fullWidth
              multiline
              error={!!errors.description}
              helperText={errors?.description?.message}
              rows={8}
            />
          }
          control={control}
          name="description"
          mode="onBlur"
        />
        <Controller
          as={<></>}
          control={control}
          name="nonComplianceReportId"
          mode="onChange"
        />
      </Box>
      <Divider />
      <Box py={2} pr={2} display="flex" justifyContent="flex-end">
        <Box pr={2}>
          <Button
            color="secondary"
            type="button"
            variant="outlined"
            onClick={() => handleCloseModal()}
          >
            Voltar
          </Button>
        </Box>
        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
        >
          {loading ? 'Aguarde...' : 'Salvar'}
        </Button>
      </Box>
    </form>
  )
}

export default FormModal
