import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

import useStyles from './styles'

const Section = ({ title, subtitle }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subtitle}>{subtitle}</Typography>
    </Box>
  )
}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

export default Section
