const getCurrentScopeByUri = (uri) => {
  const scope = uri.split('/')[1]

  const relationBetweenScopeAndUri = {
    'data-processes': 'Processos',
    'privacy-policies': 'Politicas de Privacidade',
    'non-compliance-reports': "RMC's",
    departments: 'Departamentos',
    users: 'Usuários',
    marketplaces: 'Marketplace',
    questionnaires: 'Questionários',
    incidents: 'Incidentes',
    profiles: 'Perfis',
    'my-suppliers': 'Meus Fornecedores',
    suppliers: 'Sou Fornecedor',
  }

  return relationBetweenScopeAndUri[scope] || ''
}
export default getCurrentScopeByUri
