import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import { Label } from 'components'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'
import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'
import NotificationPreferences from '../NotificationPreferences'

const CompanyForm = ({ company }) => {
  const { loadData, user } = useAuth()
  const snackbar = useSnackbar()
  const isJuridic = company?.kind === constants.preRegistrations.JURIDIC_KIND
  const [postalCodeEmpty, setPostalCodeEmpty] = useState(true)

  const {
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    setError,
    clearError,
    getValues,
    formState,
  } = useForm({
    validationSchema: schema(isJuridic),
    defaultValues: {
      name: company?.name ?? '',
      description: company?.description ?? '',
      ...(isJuridic && {
        stateRegistration: company?.stateRegistration ?? '',
        municipalRegistration: company?.municipalRegistration ?? '',
      }),
      email: company?.email ?? '',
      phone: helpers.formatters.phone(company?.phone, true) ?? '',
      siteUrl: helpers.formatters.url(company?.siteUrl) ?? '',
      postalCode: company?.address?.postalCode ?? '',
      country: company?.address?.country ?? '',
      state: company?.address?.state ?? '',
      city: company?.address?.city ?? '',
      notifyDataProcessChanges: company?.notifyDataProcessChanges ?? false,
      neighborhood: company?.address?.neighborhood ?? '',
      street: company?.address?.street ?? '',
      number: company?.address?.number ?? '',
      complement: company?.address?.complement ?? '',
      twoFactorAuthentication:
        company?.twoFactorAuthentication?.toString() ?? 'false',
    },
  })

  const onSubmit = async (data) => {
    try {
      await service.dponet.companies.updateSelf({
        company: helpers.company.formatData(data),
      })
      await loadData()
      snackbar.open({
        variant: 'success',
        message: 'Sua empresa foi atualizada com sucesso',
      })
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: helpers.formatters.errorMessage(
          error.response.data.error,
          false,
        ),
      })
    }
  }

  const setAddressValues = (data, validate = false) => {
    if (data) {
      clearError('postalCode')
      setPostalCodeEmpty(false)
      setValue([
        { country: 'Brasil' },
        { city: data.localidade },
        { state: data.uf },
        { neighborhood: data.bairro },
        { street: data.logradouro },
      ])
    } else {
      if (validate) {
        setError('postalCode', 'invalid', 'CEP não encontrado')
      }
    }
  }

  useEffect(() => {
    const postalCode = getValues('postalCode')
    const validateCep =
      postalCode.length === 9 && postalCode !== company?.address?.postalCode

    const getAddressByPostalCode = async (cep) => {
      const response = await service.externalAPIs.viaCEP.get(cep)

      if (response?.data?.erro) {
        return setAddressValues(false, true)
      }
      setAddressValues(response?.data)
    }

    if (validateCep) {
      getAddressByPostalCode(postalCode)
    } else {
      setPostalCodeEmpty(true)
      setAddressValues(false)
    }
    // eslint-disable-next-line
  }, [watch('postalCode')])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <NotificationPreferences
        control={control}
        loading={formState.isSubmitting}
      />

      <Box mt={4}>
        <Card>
          <CardHeader title="Dados básicos" />
          <Box mb={1}>
            <Divider />
          </Box>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      label="Nome fantasia"
                    />
                  }
                  control={control}
                  name="name"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      multiline
                      error={!!errors.description}
                      helperText={errors?.description?.message}
                      label="Breve descrição da empresa"
                      rows={3}
                    />
                  }
                  control={control}
                  name="description"
                  mode="onBlur"
                />
              </Grid>
              {isJuridic && (
                <>
                  <Grid item md={6} xs={12}>
                    <Controller
                      as={
                        <TextField
                          fullWidth
                          error={!!errors.stateRegistration}
                          helperText={errors?.stateRegistration?.message}
                          label="Inscrição Estadual"
                        />
                      }
                      control={control}
                      name="stateRegistration"
                      mode="onBlur"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controller
                      as={
                        <TextField
                          fullWidth
                          error={!!errors.municipalRegistration}
                          helperText={errors?.municipalRegistration?.message}
                          label="Inscrição municipal"
                        />
                      }
                      control={control}
                      name="municipalRegistration"
                      mode="onBlur"
                    />
                  </Grid>
                </>
              )}
              <Grid item md={6} xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      label="E-mail de cobrança"
                    />
                  }
                  control={control}
                  name="email"
                  mode="onBlur"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      inputProps={{
                        maxLength: 18,
                      }}
                      error={!!errors.phone}
                      helperText={errors?.phone?.message}
                      label="Telefone da empresa"
                    />
                  }
                  onChange={([event]) =>
                    helpers.formatters.phone(event.target.value, true)
                  }
                  control={control}
                  name="phone"
                  mode="onChange"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      error={!!errors.siteUrl}
                      helperText={errors?.siteUrl?.message}
                      label="Url"
                    />
                  }
                  onChange={([event]) =>
                    helpers.formatters.url(event.target.value)
                  }
                  control={control}
                  name="siteUrl"
                  mode="onBlur"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box my={4}>
          <Card>
            <CardHeader title="Endereço" />
            <Box mb={1}>
              <Divider />
            </Box>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        error={!!errors.postalCode}
                        helperText={errors?.postalCode?.message}
                        label="CEP"
                      />
                    }
                    onChange={([text]) =>
                      helpers.formatters.cep(text.target.value)
                    }
                    control={control}
                    name="postalCode"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        error={!!errors.country}
                        helperText={errors?.country?.message}
                        label="País"
                        disabled={postalCodeEmpty}
                      />
                    }
                    control={control}
                    name="country"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        inputProps={{
                          maxLength: 2,
                        }}
                        error={!!errors.state}
                        helperText={errors?.state?.message}
                        label="Estado"
                        disabled={postalCodeEmpty}
                      />
                    }
                    control={control}
                    name="state"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        error={!!errors.city}
                        helperText={errors?.city?.message}
                        label="Cidade"
                        disabled={postalCodeEmpty}
                      />
                    }
                    control={control}
                    name="city"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        error={!!errors.neighborhood}
                        helperText={errors?.neighborhood?.message}
                        label="Bairro"
                        disabled={postalCodeEmpty}
                      />
                    }
                    control={control}
                    name="neighborhood"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        error={!!errors.street}
                        helperText={errors?.street?.message}
                        label="Rua"
                        disabled={postalCodeEmpty}
                      />
                    }
                    control={control}
                    name="street"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        error={!!errors.number}
                        helperText={errors?.number?.message}
                        label="Número"
                        disabled={postalCodeEmpty}
                      />
                    }
                    control={control}
                    name="number"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        error={!!errors.complement}
                        helperText={errors?.complement?.message}
                        label="Complemento"
                        disabled={postalCodeEmpty}
                      />
                    }
                    control={control}
                    name="complement"
                    mode="onBlur"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box my={4}>
          <Card>
            <CardHeader title="Verificação em duas etapas" />
            <Box mb={1}>
              <Divider />
            </Box>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" color="textPrimary">
                    A verificação em duas etapas é uma medida de segurança que
                    adiciona um segundo fator de autenticação para acessar sua
                    conta, além da sua senha.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <Label
                      title="Ativar verificação em duas etapas?"
                      item
                      xs={12}
                    >
                      <Controller
                        control={control}
                        name="twoFactorAuthentication"
                        as={
                          <RadioGroup row>
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="Não"
                            />
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              label="Sim"
                            />
                          </RadioGroup>
                        }
                      />
                    </Label>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="textPrimary">
                    Seu e-mail de confirmação é <strong>{user.email}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box display="flex" justifyContent="flex-end" mb={5}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={formState.isSubmitting}
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </form>
  )
}

CompanyForm.propTypes = {
  company: PropTypes.object,
}

export default CompanyForm
