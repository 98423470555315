import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  certification: {
    [theme.breakpoints.down('sm')]: {
      width: 'inherit',
    },
  },
  boxCertification: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '45vh',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  boxButton: {
    marginLeft: -theme.spacing(6),
  },
  button: {
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.colors.firstStep.button.light,
    },
  },
}))

export default styles
