import permissionsConstants from 'constants/permissions'

const DEPARTMENT_MAIN_DRIVER_STEP_0 = 'department-main-driver-step-0'
const DEPARTMENT_MAIN_DRIVER_STEP_1 = 'department-main-driver-step-1'
const DEPARTMENT_MAIN_DRIVER_STEP_2 = 'department-main-driver-step-2'

const DEPARTMENTS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DEPARTMENT_MAIN_DRIVER_STEP_0,
    popover: {
      description:
        'Os departamentos criados automaticamente estão associados aos processos criados de acordo com o seu segmento. Recomenda-se realizar uma análise dos departamentos da sua organização para verificar a existência dos mesmos, possibilitando a inclusão de novos ou a exclusão dos existentes, conforme necessário.',
    },
  },
  {
    element: '#' + DEPARTMENT_MAIN_DRIVER_STEP_1,
    popover: {
      description: 'Crie um novo departamento.',
    },
  },
  {
    element: '#' + DEPARTMENT_MAIN_DRIVER_STEP_2,
    popover: {
      description: 'Edite e vincule usuários a este departamento.',
    },
  },
]

const DEPARTMENTS_DRIVER_STEPS_WITH_PERMISSIONS = {
  ['#' + DEPARTMENT_MAIN_DRIVER_STEP_1]:
    permissionsConstants.DEPARTMENTS.CREATE,
}

const TEXTFIELD_CONTROL_ITEMS = [
  { name: 'name', label: 'Nome' },
  { name: 'description', label: 'Descrição', multiline: true },
]

const NEW_DEPARTMENT_DRIVER_STEP_0 = 'new-department-driver-step-0'

const NEW_DEPARTMENTS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + NEW_DEPARTMENT_DRIVER_STEP_0,
    popover: {
      description:
        'Crie um novo departamento de acordo com a sua empresa. Certifique-se de designar um responsável para esse departamento.',
    },
  },
]

const departments = {
  DEPARTMENT_MAIN_DRIVER_STEP_0,
  DEPARTMENT_MAIN_DRIVER_STEP_1,
  DEPARTMENT_MAIN_DRIVER_STEP_2,
  DEPARTMENTS_DRIVER_STEPS_OBJECT,
  DEPARTMENTS_DRIVER_STEPS_WITH_PERMISSIONS,
  TEXTFIELD_CONTROL_ITEMS,
  NEW_DEPARTMENT_DRIVER_STEP_0,
  NEW_DEPARTMENTS_DRIVER_STEPS_OBJECT,
}

export default departments
