const ACTIVE = 'ACTIVE'
const INACTIVE = 'INACTIVE'
const COMPANY_AVALIATION_STATUS = 2
const TRIAL_LIMITED_STATUS = 8
const TRIAL_CANCEL_STATUS = 9
const TRIAL_COMPLETE_STATUS = 11

const TRIAL_STATUSES = [TRIAL_LIMITED_STATUS, TRIAL_COMPLETE_STATUS]

const PARTNER_TRIAL = 'DPONET_TRIAL'
const PARTNER_ANPPD = 'ANPPD'

const KIND_TEMPLATE = 'T'

export default {
  ACTIVE,
  COMPANY_AVALIATION_STATUS,
  INACTIVE,
  KIND_TEMPLATE,
  PARTNER_ANPPD,
  PARTNER_TRIAL,
  TRIAL_CANCEL_STATUS,
  TRIAL_COMPLETE_STATUS,
  TRIAL_LIMITED_STATUS,
  TRIAL_STATUSES,
}
