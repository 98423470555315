import constants from 'constants/index'

const { STEP_DPO_TAG, STEP_LGPD_TAG, STEP_STAMP_TAG, STEP_CERTIFICATION_TAG } =
  constants.userSteps

const validRedirectionStep = (step_tag) =>
  [
    STEP_DPO_TAG,
    STEP_LGPD_TAG,
    STEP_STAMP_TAG,
    STEP_CERTIFICATION_TAG,
  ].includes(step_tag)

export default validRedirectionStep
