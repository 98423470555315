import { jsPDF } from 'jspdf'
import moment from 'moment'

import templates from '../templates'
import pages from './pages'

import * as service from 'service'
import constants from 'constants/index'

moment.locale('pt-br')
var dateNow = moment().format('MMM YYYY')

dateNow = dateNow.charAt(0).toUpperCase() + dateNow.slice(1)

const exportPdf = async (
  userCompany,
  customStyles,
  customLogo,
  consentForms = [],
  snackbar = () => {},
  shouldCreateVersion = false,
  refresh = () => {},
  setExportingPdf = () => {},
  reportSelected,
) => {
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    userUnit: 300,
  })

  const { TYPES_CONSENTS, REPORT_CONSENT_LABELS } = constants.reports

  const reportLabel = REPORT_CONSENT_LABELS[reportSelected]
  const formattedLabel = reportLabel.replace(/ /g, '_')

  try {
    templates.firstCover(
      pdf,
      userCompany?.company?.name,
      userCompany?.company?.cpf || userCompany?.company?.cnpj,
      dateNow,
      customStyles,
      customLogo,
      reportLabel,
    )

    if (reportSelected === TYPES_CONSENTS.RECEIPT_CONSENT)
      pages.receipts(pdf, dateNow, consentForms, customStyles)

    if (reportSelected !== TYPES_CONSENTS.RECEIPT_CONSENT)
      pages.consents(pdf, dateNow, consentForms, customStyles, reportSelected)

    templates.lastCover(pdf, customStyles, customLogo)

    const reportName_formatted = `${formattedLabel}_${userCompany?.company?.name
      ?.split(' ')
      ?.join('_')
      ?.toLowerCase()}_${dateNow}.pdf`

    pdf.save(reportName_formatted)

    if (shouldCreateVersion) {
      const file = new File([pdf.output('blob')], reportName_formatted)
      await service.dponet.reportVersions.create({
        file,
        name: 'Relatório de Consentimento',
        kind: constants.reportVersions.KIND.CONSENT,
      })
      refresh()
    }

    snackbar.open({
      message: 'Relatório de Consentimento gerado com sucesso',
      variant: 'success',
    })
  } catch (error) {
    console.error(error)
    snackbar.open({
      message:
        error?.response?.data?.message ||
        'Erro ao gerar o Relatório de Consentimento!',
      variant: 'error',
    })
  }
  setExportingPdf(false)
}

export default exportPdf
