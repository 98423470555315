import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  containerBox: {
    margin: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  cardInfo: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(3),
    borderRadius: 10,
  },
  cardItem: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    height: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      transition: '0.5s',
    },
  },
  mainButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  gridButton: {
    display: 'flex',
    justifyContent: 'end',
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.primary.hover,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

export default styles
