import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  boxImageDpo: {
    width: '50px',
    height: '50px',
    marginRight: '10px',
    backgroundColor: 'white',
    '& img': {
      objectFit: 'fill',
    },
  },
  boxLastAnswer: {
    border: '1px solid rgba(128, 128, 128, 0.5)',
    padding: theme.spacing(2),
  },
  boxWrong: {
    border: '1px solid rgba(255, 0, 0, 0.5)',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}))

export default styles
