import React, { useEffect, useState } from 'react'
import { createTheme } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale'

import typography from 'theme/typography'
import overrides from 'theme/overrides'
import palette from 'theme/palette'
import colors from 'theme/colors'

import { logowhite } from 'helpers/reports/assets/logoWhite64'

import CustomPaletteContext from 'contexts/CustomPaletteContext'
import useAuth from 'hooks/useAuth'

import * as service from 'service'

const CustomThemeProvider = ({ children }) => {
  const [customTheme, setCustomTheme] = useState({})
  const [customPalette, setCustomPalette] = useState({})
  const [customLogo, setCustomLogo] = useState(logowhite)
  const [loading, setLoading] = useState(false)

  const { company } = useAuth()

  const fetchData = async () => {
    setLoading(true)
    try {
      let layout = {}

      if (company?.id) {
        const response = await service.dponet.whiteLabel.getStatusTheme({})
        const statusTheme = response?.data?.clientPortalThemeCompany?.status
        const themeData = response?.data?.clientPortalThemeCompany
        const logo =
          response?.data?.clientPortalThemeCompany?.clientPortalTheme?.logo

        setCustomPalette(themeData)

        if (statusTheme) {
          layout = {
            main: themeData?.clientPortalTheme?.primaryColor,
            secondary: themeData?.clientPortalTheme?.secondaryColor,
            textPrimary: themeData?.clientPortalTheme?.primaryFontColor,
          }

          const getLogoUrl = async () => {
            const cachedLogoUrl = sessionStorage.getItem(logo)

            if (cachedLogoUrl) {
              return cachedLogoUrl
            } else {
              const logoUrl =
                response?.data?.clientPortalThemeCompany?.clientPortalTheme
                  ?.logo

              const responseImage = await fetch(logoUrl)
              const blob = await responseImage.blob()

              const localLogoUrl = URL.createObjectURL(blob)

              sessionStorage.setItem(logo, localLogoUrl)
              return localLogoUrl
            }
          }

          const logoUrl = await getLogoUrl()
          setCustomLogo(logoUrl)
        }
      }

      const theme = createTheme(
        {
          colors: { ...colors },
          typography,
          overrides,
          zIndex: {
            appBar: 1200,
            drawer: 1100,
          },
          props: {
            MuiTextField: {
              variant: 'outlined',
            },
            MuiPaper: {
              variant: 'outlined',
            },
          },
          palette: {
            ...palette,
            ...(Object.keys(layout).length > 0 && { layout }),
          },
        },
        ptBR,
      )
      setCustomTheme(theme)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.id])

  return (
    <CustomPaletteContext.Provider
      value={{
        customTheme,
        customPalette,
        customLogo,
        loading,
        setCustomLogo,
      }}
    >
      {children}
    </CustomPaletteContext.Provider>
  )
}

export default CustomThemeProvider
