import React from 'react'
import PropTypes from 'prop-types'

import { isArray, uniq } from 'lodash'
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'

import { X as CloseIcon, AlertTriangle as AlertIcon } from 'react-feather'
import Autocomplete from '@material-ui/lab/Autocomplete'

import styles from './styles'
const useStyles = makeStyles(styles)

const DialogSelectDepartment = ({
  open,
  closeDialog,
  departments,
  handleConfirm,
  setParams,
  notFoundDepartments,
  defaultValue,
  dataProcessName,
  params,
  error,
}) => {
  const classes = useStyles(styles)
  const isMultiple = notFoundDepartments.length > 1

  const multipleSuffix = isMultiple ? 's' : ''

  const notFoundDepartmentNames = notFoundDepartments
    .map((department) => department.name)
    .join(', ')

  const onChange = (e, val) => {
    const value = isMultiple
      ? val.map((department) => department.id)
      : [val?.id]

    const finalValue = isArray(params.departments) ? uniq(value) : val?.id

    setParams({
      departments: finalValue,
    })
    return e
  }

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      classes={{ paper: classes.dialog }}
    >
      <Box m={2.5}>
        <Box width="100%" display="flex" justifyContent="flex-end" pb={5}>
          <IconButton
            size="small"
            onClick={closeDialog}
            color="primary"
            aria-label="close modal"
          >
            <CloseIcon color="white" size={20} />
          </IconButton>
        </Box>
        <Box
          mb={2.5}
          display="flex"
          flexDirection="column"
          alignItems="center"
          alignContent="center"
        >
          <Box mb={2.5}>
            <AlertIcon color="white" size={40} />
          </Box>
          <Typography
            align="center"
            gutterBottom
            variant="h4"
            className={classes.colorWhite}
          >
            Aceitar sugestão de origem de dados
          </Typography>
          <Typography
            align="center"
            variant="h6"
            className={classes.colorWhite}
          >
            Atenção! Notamos que sua organização não contém o{multipleSuffix}{' '}
            departamento{multipleSuffix} {notFoundDepartmentNames}, que estamos
            sugerindo como origem dos dados do processo {dataProcessName}.
          </Typography>
        </Box>

        <Typography
          variant="h6"
          gutterBottom
          align="center"
          className={classes.colorWhite}
        >
          Atualmente, o{multipleSuffix} departamento{multipleSuffix}{' '}
          {notFoundDepartmentNames} é que está indicado como origem dos dados
          para este processo. Por favor, selecione para qual departamento você
          deseja alterar:
        </Typography>
      </Box>
      <Box m={2}>
        <Paper variant="outlined">
          <Box
            p={2}
            display="flex"
            width="100%"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Autocomplete
              disableClearable
              multiple={isMultiple}
              options={departments}
              value={defaultValue}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={onChange}
              fullWidth
              renderInput={(rest) => (
                <TextField
                  {...rest}
                  variant="outlined"
                  label="Departamentos"
                  placeholder="Departamentos"
                  error={error}
                  helperText={
                    error &&
                    `Por favor, selecione ${
                      isMultiple
                        ? notFoundDepartments?.length?.toString()
                        : 'um'
                    } departamento${multipleSuffix}`
                  }
                />
              )}
            />
          </Box>
        </Paper>
      </Box>

      <Box p={2} display="flex" justifyContent="space-between">
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="text"
            className={classes.colorWhite}
            onClick={closeDialog}
          >
            Cancelar
          </Button>
        </Box>
        <Button variant="contained" type="submit" onClick={handleConfirm}>
          Salvar
        </Button>
      </Box>
    </Dialog>
  )
}

DialogSelectDepartment.propTypes = {
  open: PropTypes.bool,
  closeDialog: PropTypes.func,
  departments: PropTypes.array,
  handleConfirm: PropTypes.func,
  setParams: PropTypes.func,
  notFoundDepartments: PropTypes.array,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  dataProcessName: PropTypes.string,
  params: PropTypes.object,
  error: PropTypes.bool,
}

export default DialogSelectDepartment
