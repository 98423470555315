import React from 'react'
import {
  TextField,
  makeStyles
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const InputFieldDisabled = ({ value }) => {
  const classes = useStyles()

  return (
    <TextField
      variant="outlined"
      className={classes.root}
      disabled
      value={value}
    />
  )
}

export default InputFieldDisabled
