import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@material-ui/core'

import {
  ThumbsDown as ThumbsDownIcon,
  ThumbsUp as ThumbsUpIcon,
} from 'react-feather'
import useStyles from './styles'

const StretchedButton = ({ text, onClick, thumbs }) => {
  const classes = useStyles()
  return (
    <Box
      p={2}
      mb={thumbs ? 2 : 0}
      onClick={onClick}
      className={classes.buttonBox}
      display="flex"
      alignItems="center"
    >
      <Box p={1}>{thumbs ? <ThumbsUpIcon /> : <ThumbsDownIcon />}</Box>
      <Box pl={2} display="flex" alignItems="center">
        <Typography variant="body1">{text}</Typography>
      </Box>
    </Box>
  )
}

StretchedButton.prototype = {
  text: PropTypes.string,
  thumbs: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

StretchedButton.defaultProps = {
  thumbs: false,
}

export default StretchedButton
