import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'

import styles from './styles'

const Text = ({
  children,
  color,
  fontSize,
  fontHeight,
  className,
  ...rest
}) => {
  const useStyles = styles(fontSize, fontHeight)
  const classes = useStyles()
  const isPrimary = color === 'primary'
  const isSecondary = color === 'secondary'

  return (
    <Typography
      className={clsx(classes.text, className, {
        [classes.primary]: isPrimary,
        [classes.secondary]: isSecondary,
      })}
      {...rest}
    >
      {children}
    </Typography>
  )
}

Text.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  fontHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Text.defaultProps = {
  color: 'primary',
  fontHeight: 700,
}

export default Text
