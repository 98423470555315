import permissions from '../permissions'

const USER_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.USERS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.USERS.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.USERS.EDIT,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.SOFT_DELETE,
    tag: permissions.USERS.SOFT_DELETE,
  },
]

export default USER_LIST
