import React from 'react'
import { Avatar, Box, Typography } from '@material-ui/core'

import useStyles from './styles'

import theme from 'theme'
import logo from 'images/favicon.svg'
import helpers from 'helpers'

const DpoReason = ({ answer = {} }) => {
  const classes = useStyles()

  return (
    <>
      <Box mt={2}>
        <Box
          minHeight={80}
          bgcolor={theme.palette.greenSnowFlurry}
          display="flex"
          p={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <Avatar src={logo} className={classes.boxImageDpo} />
            <Typography variant="h3">DPO</Typography>
          </Box>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Typography variant="h6">Última atualização</Typography>
            <Typography>
              {helpers.formatters.date(answer?.updatedAt)}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.boxLastAnswer}>
          <Typography>{answer?.reasonRejection}</Typography>
        </Box>
      </Box>
      <Box className={classes.boxWrong}>
        <Typography>{answer?.answer}</Typography>
      </Box>
    </>
  )
}

export default DpoReason
