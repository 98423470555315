const lightBlue = '#B8DAF7'

const colors = {
  primary: '#076EA0',
  secondary: '#233154',
  background: {
    secondary: '#3E4A69',
    card: '#151E07',
  },
  button: {
    primary: '#80B92B',
    light: lightBlue,
  },
  info: {
    primary: '#D4E9FB',
  },
  text: {
    primary: '#FFF',
    secondary: lightBlue,
  },
}

export default colors
