const mountGapPanels = (statsGap) => {
  return (
    statsGap?.questionnaires?.map((questionnaire, index) => ({
      value: index + 1,
      id: `gap-${index + 1}`,
      name: questionnaire.name,
      data: [questionnaire.rncs, questionnaire.questions],
      labels: ['RMCS ABERTOS', 'TOTAL DE PERGUNTAS'],
      items: [
        {
          title: 'TOTAL DE PERGUNTAS',
          subtitle: questionnaire.questions,
        },
        {
          title: 'RMCS ABERTOS',
          subtitle: questionnaire.rncs,
        },
      ],
    })) || []
  )
}

export default mountGapPanels
