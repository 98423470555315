import React from 'react'
import { useHistory } from 'react-router-dom'
import { Bell } from 'react-feather'
import { IconButton, Tooltip, makeStyles } from '@material-ui/core'

import { routes } from 'Routes'
import styles from './styles'
import constants from 'constants/index'

const LastUpdatesButton = () => {
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const history = useHistory()

  const handleClick = () => {
    history.push(routes.lastUpdates.all)
  }

  return (
    <Tooltip title="Últimas Atualizações">
      <IconButton onClick={handleClick}>
        <Bell
          className={classes.notificationIcon}
          size={20}
          id={constants.myLgpd.HOME_DRIVER_STEP_3}
        />
      </IconButton>
    </Tooltip>
  )
}

export default LastUpdatesButton
