import * as yup from 'yup'

const createDocumentField = () => {
  const cpfCnpjPattern =
    /^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$/

  return yup.string().required().matches(cpfCnpjPattern, {
    message: 'Digite um documento válido',
    excludeEmptyString: true,
  })
}

const createDateToSendEmailsField = () => {
  return yup.date().typeError('Preencha uma data válida')
}

const createInternationalDocumentField = () => {
  return yup.string().required()
}

export const schemaSupplier = (
  international,
  disabledApproved,
  isSupplierInvite,
) => {
  const hasDocument = !international || isSupplierInvite
  const hasDateToSendEmails = !disabledApproved && !isSupplierInvite
  const hasInternationalDocument = international

  const schema = yup.object().shape({
    name: isSupplierInvite ? yup.string() : yup.string().required(),
    email: isSupplierInvite
      ? yup.string()
      : yup.string().email('Adicione um e-mail válido').required(),

    ...(hasDocument && {
      document: createDocumentField(),
    }),

    ...(hasDateToSendEmails && {
      dateToSendEmails: createDateToSendEmailsField(),
    }),

    ...(hasInternationalDocument && {
      internationalDocument: createInternationalDocumentField(),
    }),
  })

  return schema
}

export default schemaSupplier
