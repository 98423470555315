import React from 'react'
import { Paperclip as PaperclipIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { Box, Link, Typography } from '@material-ui/core'

import theme from 'theme'

const AttachmentItems = ({ name, url }) => {
  return (
    <Box
      display="flex"
      gridGap="4px"
      color={theme.palette.grapePurple}
      component={Link}
      href={url}
      target="_blank"
      rel="noopener"
      underline="none"
      download
    >
      <PaperclipIcon size={14} />
      <Typography variant="body2" noWrap>
        {name}
      </Typography>
    </Box>
  )
}

AttachmentItems.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default AttachmentItems
