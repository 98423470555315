import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  FormControlLabel,
  Grid,
  Hidden,
  Radio,
  Typography,
  makeStyles,
  RadioGroup,
} from '@material-ui/core'

import {
  ChevronsRight as CrevronsRightIcon,
  ChevronsDown as ChevronsDownIcon,
} from 'react-feather'

import { useTranslation } from 'react-i18next'

import useSuggestedChange from 'hooks/useSuggestedChange'

import helpers from 'helpers'
import humps from 'humps'

import styles from './styles'
const useStyles = makeStyles(styles)

const UpdateActionInput = ({
  changeId,
  suggestion,
  registerType,
  entityType,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    handleSelect,
    data,
    incrementSuggestionsQuantity,
  } = useSuggestedChange()

  useEffect(() => {
    incrementSuggestionsQuantity(changeId)
    //eslint-disable-next-line
  }, [])

  const [value, setValue] = useState(
    data.find((item) => changeId === item.id)?.answer || '',
  )

  const handleChange = (e) => {
    const val = e.target.value
    setValue(val)
    handleSelect({ value: val, changeId })
  }

  return (
    <RadioGroup
      name={changeId.toString()}
      value={value}
      onChange={handleChange}
    >
      <Grid container spacing={1}>
        <Grid item md={5} xs={12}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="h6">
                {t(
                  `${entityType}.${humps
                    .decamelize(registerType)
                    .replace('_id', '')}`,
                )}
              </Typography>
              <Typography gutterBottom>
                {helpers.suggestedChanges.formattedValue(
                  suggestion[registerType].oldValue,
                  registerType,
                  t,
                )}
              </Typography>
            </Box>
            <FormControlLabel
              classes={{ root: classes.radioRoot }}
              value="refuse"
              control={<Radio size="medium" />}
              label="Não atualizar"
            />
          </Box>
        </Grid>
        <Grid item md={2} xs={12}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            height="100%"
            pt={1}
          >
            <Hidden smDown>
              <CrevronsRightIcon size={32} />
            </Hidden>
            <Hidden mdUp>
              <ChevronsDownIcon size={32} />
            </Hidden>
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="h6">
                {t(
                  `${entityType}.${humps
                    .decamelize(registerType)
                    .replace('_id', '')}`,
                )}
              </Typography>
              <Typography gutterBottom>
                {helpers.suggestedChanges.formattedValue(
                  suggestion[registerType].newValue,
                  registerType,
                  t,
                )}
              </Typography>
            </Box>
            <FormControlLabel
              classes={{ root: classes.radioRoot }}
              value="accept"
              control={<Radio size="medium" />}
              label="Atualizar"
            />
          </Box>
        </Grid>
      </Grid>
    </RadioGroup>
  )
}

UpdateActionInput.propTypes = {
  changeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suggestion: PropTypes.object,
  registerType: PropTypes.string,
  entityType: PropTypes.string,
}

export default UpdateActionInput
