import * as yup from 'yup'

export const schema = yup.object().shape({
  buttonText: yup
    .string()
    .required()
    .max(50, 'O nome deve ter no máximo 50 caracteres'),
})

export default schema
