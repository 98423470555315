import React, { useState } from 'react'
import { Box, Button, Divider, Tab, Tabs } from '@material-ui/core'

import { PaymentBillet, PaymentCard } from './components'

import theme from 'theme'

import constants from 'constants/index'
import * as service from 'service'

const ChangePaymentMethod = ({
  handleChangePaymentMethod,
  paymentContract,
  setPaymentMethod,
  refresh,
}) => {
  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(
    paymentContract?.methodPayment === 'billet'
      ? constants.settings.BILLET_STATUS_ID
      : constants.settings.PAYMENT_CARD_STATUS_ID,
  )

  const handleTabChange = (_, value) => setCurrentTab(value)

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      await service.dponet.financialPanel.changePaymentMethod(
        paymentContract?.id,
        { paymentContract: data },
      )
      refresh()
    } catch (e) {
      throw e
    } finally {
      setPaymentMethod(false)
      setLoading(false)
    }
  }

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
      <Box>
        <Box display="flex" justifyContent="center">
          <Tabs
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            textColor="primary"
            onChange={handleTabChange}
          >
            {constants.settings.FINANCIAL_PAYMENTS_METHOD_TABS.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                icon={<tab.icon />}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
      </Box>
      {currentTab === constants.settings.BILLET_STATUS_ID && (
        <PaymentBillet
          submit={onSubmit}
          loading={loading}
          paymentContract={paymentContract}
        />
      )}
      {currentTab === constants.settings.PAYMENT_CARD_STATUS_ID && (
        <PaymentCard submit={onSubmit} loading={loading} />
      )}
      <Button
        variant="contained"
        color="secondary"
        onClick={handleChangePaymentMethod}
        disabled={loading}
      >
        Cancelar
      </Button>
    </Box>
  )
}

export default ChangePaymentMethod
