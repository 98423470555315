import React, { useState } from 'react'

import * as service from 'service'
import DataTreatmentModalContext from 'contexts/DataTreatmentModalContext'

const DataTreatmentModalProvider = ({
  children,
  dataTreatments,
  dataProcessId,
}) => {
  const [data, setData] = useState(dataTreatments)
  const [isLoading, setLoading] = useState(false)

  const loadData = async (dataTreatmentId = '') => {
    setLoading(true)
    const [response] = await Promise.all([
      service.dponet.dataTreatments.get({
        dataTreatmentId: dataTreatmentId,
        dataProcessId: dataProcessId,
      }),
    ])
    setData(response.data.dataTreatments)
    setLoading(false)
  }

  return (
    <DataTreatmentModalContext.Provider
      value={{
        data,
        setData,
        loadData,
        isLoading,
      }}
    >
      {children}
    </DataTreatmentModalContext.Provider>
  )
}

export default DataTreatmentModalProvider
