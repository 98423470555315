import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  pending: {
    backgroundColor: colors.blue[100],
    color: colors.blue[800],
    fontWeight: 700,
    borderRadius: 4,
    height: '21px',
  },
  onGoing: {
    backgroundColor: colors.yellow[100],
    color: colors.yellow[800],
    fontWeight: 700,
    borderRadius: 4,
    height: '21px',
  },
  finalized: {
    backgroundColor: colors.green[100],
    color: colors.green[800],
    fontWeight: 700,
    borderRadius: 4,
    height: '21px',
  },
  overdue: {
    backgroundColor: colors.red[100],
    color: colors.red[800],
    fontWeight: 700,
    borderRadius: 4,
    height: '21px',
  },
}))

export default styles
