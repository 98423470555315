import { makeStyles } from '@material-ui/core'

const styles = (imageHeight, titleColor) =>
  makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      gap: 24,
    },
    title: {
      fontSize: 21,
      height: 50,
      fontFamily: 'Roboto',
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: 12,
      color: titleColor,
    },
    image: {
      objectFit: 'contain',
      height: imageHeight,
    },
  }))

export default styles
