import React, { useEffect, useState } from 'react'
import { Container, Box, Button, Grid } from '@material-ui/core'
import {
  User as UserIcon,
  Check as CheckIcon,
  Moon as MoonIcon,
} from 'react-feather'

import { ProfilesForm } from './components'
import {
  Page,
  ContentHeader,
  Permitted,
  StatusCard,
  LoadingFeedback,
  ConfirmationDialog,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'

import constants from 'constants/index'
import palette from 'theme/palette'
import useStyles from './styles'
import * as service from 'service'

const ProfilesEdit = ({ match }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const [profile, setProfile] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [openInactivateDialog, setOpenInactivateDialog] = useState(false)

  const { profileId } = match.params
  const labelToChangeStatus = profile?.status ? 'Inativar' : 'Ativar '

  const loadProfile = async () => {
    setIsLoading(true)
    const response = await service.dponet.profiles.get({
      profileId: parseInt(profileId),
    })
    setProfile(response?.data?.profile)
    setIsLoading(false)
  }

  const changeProfileStatus = async () => {
    setIsLoading(true)
    try {
      handleActionDialog()
      await service.dponet.profiles.changeStatus(profile?.id)

      loadProfile()

      snackbar.open({
        message: 'Status do perfil alterado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
    setIsLoading(false)
  }

  const handleActionDialog = () => {
    setOpenInactivateDialog((openInactivateDialog) => !openInactivateDialog)
  }

  useEffect(() => {
    loadProfile()
    //eslint-disable-next-line
  }, [profileId])

  return (
    <>
      <Page title="Editar perfil">
        <LoadingFeedback open={isLoading} />
        <Container maxWidth={false} className={classes.container}>
          <ContentHeader title="Editar perfil">
            <Permitted tag={constants.permissions.PROFILES.CHANGE_STATUS}>
              <Button onClick={handleActionDialog} variant="contained">
                {labelToChangeStatus} perfil
              </Button>
            </Permitted>
          </ContentHeader>
          <Box width="100%" mt={4}>
            <Box mb={4}>
              <Grid container spacing={2}>
                <StatusCard
                  backgroundIconColor={palette.primary.main}
                  icon={<UserIcon color={palette.white} />}
                  label="Usuários vínculados"
                  value={String(profile?.linkedUsers)}
                />
                <StatusCard
                  backgroundIconColor={
                    profile?.status
                      ? palette.greenPantone
                      : palette.secondary.light
                  }
                  icon={
                    profile?.status ? (
                      <CheckIcon size={18} color="white" />
                    ) : (
                      <MoonIcon size={18} color="white" />
                    )
                  }
                  label="STATUS"
                  value={profile?.status ? 'Ativo' : 'Inativo'}
                />
              </Grid>
            </Box>

            {!isLoading && <ProfilesForm isEdit={true} profile={profile} />}
          </Box>
        </Container>
      </Page>

      <ConfirmationDialog
        open={openInactivateDialog}
        setOpen={setOpenInactivateDialog}
        text={`Você tem certeza que deseja ${labelToChangeStatus} o perfil?`}
        textButtonCancel="Cancelar"
        textButton="Confirmar"
        actionAcceptButton={changeProfileStatus}
      />
    </>
  )
}

export default ProfilesEdit
