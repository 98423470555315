import { size } from 'lodash'
const BASE = 0.03
const MINIMUM = 11

const trimChartLegend = (legend, offsetWidth) => {
  const maxCharacters = offsetWidth * BASE
  const legendSize = size(legend)

  const slice = (max) => `${legend.slice(0, -(legendSize - max))}...`
  if (maxCharacters < MINIMUM) {
    if (legendSize <= MINIMUM) {
      return legend
    }

    return slice(MINIMUM)
  }

  if (legendSize <= maxCharacters) {
    return legend
  }

  return slice(maxCharacters)
}

export default trimChartLegend
