import api from 'service/api'

const dponetAPIWithoutErrorValidation = api.create('dponet', false)

const get = async ({ ...params }) => {
  return await dponetAPIWithoutErrorValidation.get('/lia_answers/', { params })
}

const create = async (params) => {
  return await dponetAPIWithoutErrorValidation.post(
    '/lia_answers/reply',
    params,
  )
}

const liaAnswers = { get, create }

export default liaAnswers
