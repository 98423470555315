import * as yup from 'yup'

import constants from 'constants/index'

export const schema = (isAutomation) =>
  yup.object().shape({
    applicationChoice: yup.string().required('Escolha uma opção'),
    departments: yup.array().when(['applicationChoice'], {
      is: constants.audits.APPLICATION_CHOICE_DEPARTMENTS_ID,
      then: yup.array().required(),
    }),
    sampling: yup.string().required(),
    startDate: yup.date().required().typeError('Precisa ser uma data válida'),
    ...(isAutomation
      ? {
          recurrence: yup.string().max(5).required(),
          periodType: yup
            .string()
            .required('Escolha um intervalo para a recorrência'),
        }
      : {
          endDate: yup
            .date()
            .required()
            .typeError('Precisa ser uma data válida'),
        }),
  })

export default schema
