import React from 'react'
import PropTypes from 'prop-types'

import { RoundedButton as RoundedButtonComponent } from 'components'

import useFirstStep from 'hooks/useFirstStep'

const RoundedButton = ({
  children,
  variant,
  icon,
  withPadding,
  withShadow,
  mixpanelId,
  ...rest
}) => {
  const { registerTrack } = useFirstStep()

  const handleClick = () => {
    if (!!rest?.onClick) {
      rest.onClick()
    }
    registerTrack(mixpanelId)
  }

  return (
    <RoundedButtonComponent
      icon={icon}
      variant={variant}
      onClick={handleClick}
      withPadding={withPadding}
      withShadow={withShadow}
      {...rest}
    >
      {children}
    </RoundedButtonComponent>
  )
}

RoundedButton.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'outlined', 'text']),
  icon: PropTypes.any,
  withPadding: PropTypes.bool,
  withShadow: PropTypes.bool,
  mixpanelId: PropTypes.string,
}

RoundedButton.defaultProps = {
  withPadding: false,
  withShadow: false,
  mixpanelId: 'botão-não-identificado',
}

export default RoundedButton
