import React, { useState } from 'react'
import PropTypes from 'prop-types'

import * as service from 'service'

import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'

import { CategoryAccordion } from './components'

import useFetch from 'hooks/useFetch'
import constants from 'constants/index'
import { SorterInput } from 'components'

const Filter = ({
  defaultOrder,
  filterByCategory,
  filter,
  alphabeticalFieldOrder,
  alphabeticalOrder,
}) => {
  const { response: serviceCategoriesResponse } = useFetch(
    service.marketPlace.categories.get,
    { order: 'created_at asc' },
  )

  const setDefaultOrderValue = () => {
    switch ({ ...filter.filters }.order) {
      case 'created_at DESC':
        return constants.orderFilter.NEWLY_CREATED
      case 'created_at ASC':
        return constants.orderFilter.OLDER_CREATED
      case 'updated_at DESC':
        return constants.orderFilter.NEWLY_UPDATED
      case 'updated_at ASC':
        return constants.orderFilter.OLDER_UPDATED
      case `${alphabeticalFieldOrder} ASC`:
        return constants.orderFilter.ALPHABETICAL_A_TO_Z
      case `${alphabeticalFieldOrder} DESC`:
        return constants.orderFilter.ALPHABETICAL_Z_TO_A
      default:
        return defaultOrder
    }
  }

  const [orderValue, setOrderValue] = useState(setDefaultOrderValue())

  const handleChangeOrder = (event) => {
    const filters = filter.filters
    delete filters.order

    switch (event.target.value) {
      case constants.orderFilter.NEWLY_CREATED:
        setOrderValue(constants.orderFilter.NEWLY_CREATED)
        filter.setFilters({ order: 'created_at DESC', ...filters })
        break
      case constants.orderFilter.OLDER_CREATED:
        setOrderValue(constants.orderFilter.OLDER_CREATED)
        filter.setFilters({ order: 'created_at ASC', ...filters })
        break
      case constants.orderFilter.NEWLY_UPDATED:
        setOrderValue(constants.orderFilter.NEWLY_UPDATED)
        filter.setFilters({ order: 'updated_at DESC', ...filters })
        break
      case constants.orderFilter.ALPHABETICAL_A_TO_Z:
        setOrderValue(constants.orderFilter.ALPHABETICAL_A_TO_Z)
        filter.setFilters({
          order: `${alphabeticalFieldOrder} ASC`,
          ...filters,
        })
        break
      case constants.orderFilter.ALPHABETICAL_Z_TO_A:
        setOrderValue(constants.orderFilter.ALPHABETICAL_Z_TO_A)
        filter.setFilters({
          order: `${alphabeticalFieldOrder} DESC`,
          ...filters,
        })
        break
      default:
        setOrderValue(constants.orderFilter.OLDER_UPDATED)
        filter.setFilters({ order: 'updated_at ASC', ...filters })
        break
    }
  }

  return (
    <Paper>
      <Box px={2} py={4}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Filtros
          </Typography>
          <Button onClick={filterByCategory} variant="outlined" color="primary">
            Filtrar
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          <Box>
            <Typography>Ordenar por</Typography>
          </Box>
          <FormControl>
            <Select
              id="order-select"
              value={orderValue}
              onChange={handleChangeOrder}
              input={<SorterInput />}
            >
              <MenuItem value={constants.orderFilter.NEWLY_CREATED}>
                <Typography variant="body1" color="secondary">
                  Criação recente
                </Typography>
              </MenuItem>
              <MenuItem value={constants.orderFilter.OLDER_CREATED}>
                <Typography variant="body1" color="secondary">
                  Criação antiga
                </Typography>
              </MenuItem>
              <MenuItem value={constants.orderFilter.NEWLY_UPDATED}>
                <Typography variant="body1" color="secondary">
                  Atualização recente
                </Typography>
              </MenuItem>
              <MenuItem value={constants.orderFilter.OLDER_UPDATED}>
                <Typography variant="body1" color="secondary">
                  Atualização antiga
                </Typography>
              </MenuItem>
              {alphabeticalOrder && (
                <MenuItem value={constants.orderFilter.ALPHABETICAL_A_TO_Z}>
                  <Typography variant="body1" color="secondary">
                    De A-Z
                  </Typography>
                </MenuItem>
              )}
              {alphabeticalOrder && (
                <MenuItem value={constants.orderFilter.ALPHABETICAL_Z_TO_A}>
                  <Typography variant="body1" color="secondary">
                    De Z-A
                  </Typography>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {serviceCategoriesResponse?.data?.serviceCategories?.map((category) => (
        <CategoryAccordion
          key={category.id}
          category={category}
          checked={false}
          label={category.name}
          onChange={() => {}}
        />
      ))}
    </Paper>
  )
}

export default Filter

Filter.propTypes = {
  filter: PropTypes.object,
  defaultOrder: PropTypes.string,
  children: PropTypes.node,
  isDataProcess: PropTypes.bool,
  alphabeticalOrder: PropTypes.bool,
  alphabeticalFieldOrder: PropTypes.string,
}

Filter.defaultProps = {
  defaultOrder: constants.orderFilter.NEWLY_CREATED,
  isDataProcess: false,
  alphabeticalOrder: false,
  alphabeticalFieldOrder: 'name',
}
