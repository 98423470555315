import React from 'react'
import PropTypes from 'prop-types'
import { colors, Box } from '@material-ui/core'

import { DashboardChartPaper, DonutChart } from 'components'

import { routes } from 'Routes'

const PendingRmcByQuestionnaireGraphic = ({ gapStats, ...rest }) => {
  const questionnaires = gapStats?.questionnaires?.sort((a, b) => {
    return a.nonComplianceReports.pending - b.nonComplianceReports.pending
  })

  const getDataByStatus = (questionnaire, status) =>
    questionnaire?.nonComplianceReports[status] || 0

  const names = questionnaires?.map((questionnaire) => questionnaire.name)

  const dataPending = questionnaires?.map((department) =>
    getDataByStatus(department, 'pending'),
  )

  const colorsMui = Object.values(colors)
    .map((color) => Object.values(color).slice(-3))
    .flat()
    .sort()

  return (
    <DashboardChartPaper
      title="Status dos RMCs de questionários por segmento"
      subtitle="RMCs pendentes"
      redirect={routes.questionnaire.all}
      {...rest}
    >
      <Box px={2}>
        <DonutChart
          names={names || []}
          values={dataPending || []}
          title="RMCs"
          legendPosition="right"
          heightValue={225}
          id="questions"
          colors={colorsMui}
          fixedTitle
          scale={0.9}
        />
      </Box>
    </DashboardChartPaper>
  )
}

PendingRmcByQuestionnaireGraphic.propTypes = {
  gapStats: PropTypes.object.isRequired,
}

export default PendingRmcByQuestionnaireGraphic
