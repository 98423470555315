import { isEmpty } from 'lodash'

import palette from 'theme/palette'

import { logoBlue } from '../assets/logoBlue64'

import { getBaseURL } from 'service/env'

import {
  WIDTH as WIDTHPdf,
  HEIGHT as HEIGHTPdf,
  fontSize,
  PADDING as PADDINGpdf,
} from '../constants'

import { ratio } from '../helpers'

const firstCover = (
  pdf,
  companyName,
  document,
  date,
  customStyles,
  customLogo,
  reportName,
  pathQrCode = '',
) => {
  const WIDTH = WIDTHPdf(pdf)
  const HEIGHT = HEIGHTPdf(pdf)

  pdf.setFillColor(customStyles?.main)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')

  var PADDING = PADDINGpdf

  pdf.addImage(customLogo, 'png', PADDING, PADDING - 10, ratio(500), ratio(158))
  pdf.setFontSize(fontSize.documentTitle)
  pdf.setLineHeightFactor(1.2)
  pdf.setFont('Raleway-Bold')
  var splited_title = pdf.splitTextToSize(reportName, ratio(1700))
  pdf.setTextColor(customStyles?.textPrimary)
  pdf.text(splited_title, PADDING, ratio(600), null, null, 'left')

  pdf.setFontSize(fontSize.subtitle)
  pdf.setFont('Raleway-Regular')
  var splited_name = pdf.splitTextToSize(companyName, WIDTH - PADDING * 3)

  pdf.text(splited_name, PADDING, ratio(1940))
  pdf.text(document, PADDING, ratio(2000 + 50 * splited_name.length))
  pdf.rect(PADDING, HEIGHT - ratio(374), WIDTH - PADDING * 2, ratio(8), 'F')
  pdf.text(date, WIDTH / 2, HEIGHT - PADDING, null, null, 'center')

  if (customStyles.main === palette.layout.main) {
    pdf.addPage()

    pdf.setFillColor(palette.white)
    pdf.rect(0, 0, WIDTH, HEIGHT, 'F')

    pdf.addImage(
      logoBlue,
      'png',
      WIDTH / 2 - PADDING * 1.25,
      PADDING - 10,
      ratio(500),
      ratio(158),
    )
    pdf.setFontSize(fontSize.documentTitle)
    pdf.setLineHeightFactor(1.2)
    pdf.setFont('Raleway-Bold')
    splited_title = pdf.splitTextToSize(reportName, ratio(1700))
    pdf.setTextColor(palette.subscription.primary.main)
    pdf.text(splited_title, WIDTH / 2, ratio(600), null, null, 'center')

    pdf.setFont('Raleway-Regular')

    pdf.setFontSize(fontSize.subtitle)

    splited_name = pdf.splitTextToSize(companyName, ratio(1024))

    pdf.text(splited_name, WIDTH / 2, ratio(1940), null, null, 'center')
    pdf.text(
      document,
      WIDTH / 2,
      ratio(2000 + 50 * splited_name.length),
      null,
      null,
      'center',
    )
    pdf.text(date, WIDTH / 2, HEIGHT - PADDING, null, null, 'center')

    if (!isEmpty(pathQrCode)) {
      pdf.setFontSize(fontSize.paragraph)

      pdf.text(
        'Verifique a autenticidade deste documento',
        WIDTH / 2,
        HEIGHT / 2,
        null,
        null,
        'center',
      )

      const qrCode = `${getBaseURL('dponet')}/${pathQrCode}`

      pdf.addImage(
        qrCode,
        'png',
        WIDTH / 2 - 40,
        HEIGHT / 2 + 5,
        ratio(380),
        ratio(380),
      )
    }
  }
}

export default firstCover
