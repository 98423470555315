import inviteAdvisorConstants from 'constants/inviteAdvisor'

const statusLabel = (status) => {
  const statusLabels = {
    [inviteAdvisorConstants.STATUSES.ACTIVE_STATUS]: 'Ativo',
    [inviteAdvisorConstants.STATUSES.CANCELED_STATUS]: 'Cancelado',
    [inviteAdvisorConstants.STATUSES.PENDING_STATUS]: 'Pendente',
    [inviteAdvisorConstants.STATUSES.REFUSED_STATUS]: 'Recusado',
  }

  return statusLabels[status]
}

export default statusLabel
