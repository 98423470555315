import api from 'service/api'

import { TOKEN_PRE_REGISTRATION } from 'service/constants'

const dponetAPI = api.create('dponet')

const create = async ({ ...params }) => {
  return await dponetAPI.post(`/pre_registration`, params)
}

const get = async () => {
  return await dponetAPI.get(
    `/pre_registrations/${localStorage.getItem(TOKEN_PRE_REGISTRATION)}`,
  )
}

const put = async ({ ...params }) => {
  return await dponetAPI.put(
    `pre_registration/${localStorage.getItem(TOKEN_PRE_REGISTRATION)}`,
    params,
  )
}

export default { create, get, put }
