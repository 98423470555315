import React, { useState } from 'react'

import DocumentContext from 'contexts/DocumentContext'

import * as service from 'service'

const RncDocumentsProvider = ({ children, documents }) => {
  const [data, setData] = useState(documents)
  const [loading, setLoading] = useState(false)

  const loadData = async (nonComplianceReportId) => {
    setLoading(true)

    let response = await service.dponet.nonComplianceReports.get({
      nonComplianceReportId,
    })

    setData(
      response &&
        response.data &&
        response.data.nonComplianceReport &&
        response.data.nonComplianceReport.documents,
    )

    setLoading(false)
  }

  return (
    <DocumentContext.Provider
      value={{
        data,
        setData,
        loading,
        setLoading,
        loadData,
      }}
    >
      {children}
    </DocumentContext.Provider>
  )
}

export default RncDocumentsProvider
