import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 80,
    backgroundColor: theme.palette.subscription.primary.main,
    padding: 52,
  },
  title: {
    fontSize: 40,
    fontFamily: 'Raleway',
    color: theme.palette.white,
    fontWeight: 700,
  },
  subtitle: {
    fontSize: 21,
    fontFamily: 'Roboto',
    color: theme.palette.white,
    fontWeight: 400,
  },
}))

export default styles
