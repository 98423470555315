import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ faqsDocumentationsCategoryId = '', ...params }) => {
  return await dponetAPI.get(
    `/faqs_documentations_categories/${faqsDocumentationsCategoryId}`,
    {
      params,
    },
  )
}

const faqsDocumentationsCategories = {
  get,
}

export default faqsDocumentationsCategories
