import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import { reverse } from 'named-urls'
import qs from 'query-string'

import { Container } from '@material-ui/core'
import { LoadingFeedback, Page } from 'components'
import {
  Certification,
  Conclusion,
  ContentBase,
  FirstPage,
  QuestionPage,
  VideoPage,
} from './components'

import useAuth from 'hooks/useAuth'
import * as service from 'service'

import helpers from 'helpers'

const TrainningJourney = ({ match }) => {
  const history = useHistory()
  const search = qs.parse(history?.location?.search)
  const { step: viewerStep } = search

  const { homeRoute } = useAuth()
  const [trainningJourney, setTrainningJourney] = useState()
  const [loading, setLoading] = useState(true)

  const { arrayIndexSize } = helpers.functions
  const { trainningJourneyId } = match?.params

  const [step, setStep] = useState(0)
  const [subStep, setSubtep] = useState(0)
  const [showActions, setShowActions] = useState(true)

  const isFirstPage = step === 0 && subStep === 0
  const isFinalStep = step === 6

  useEffect(() => {
    if (!!viewerStep && !loading) {
      const actualStep = parseInt(viewerStep) || 0
      setStep(actualStep)
      setSubtep(0)
      history.replace(reverse(routes.trainningJourney, { trainningJourneyId }))
    }
    //eslint-disable-next-line
}, [loading, viewerStep])

  useEffect(
    () => {
      const loadTrainningJourney = async () => {
        const response = await service.dponet.trainningJourneys.show({
          trainningJourneyId,
        })
        const responseState = await service.dponet.trainningJourneys.showByUser(
          {
            trainningJourneyId,
          },
        )
        const userTrainningSteps = responseState?.data?.userTrainningSteps
        const stepsConcluded = userTrainningSteps.filter(
          (step) => step?.concluded,
        ).length

        setTrainningJourney(response?.data?.trainningJourney)

        setStep(stepsConcluded + 1)
        setLoading(false)
      }

      loadTrainningJourney()
    },
    //eslint-disable-next-line
  [])

  const handleNext = () => {
    if (isFinalStep) {
      return window.location.replace(homeRoute)
    }
    if (subStep === arrayIndexSize(steps[step])) {
      setStep(step + 1)
      setShowActions(true)
      return setSubtep(0)
    }

    setShowActions(false)
    setSubtep(subStep + 1)
  }

  const trainningStepComponents = trainningJourney?.trainningSteps?.map(
    (trainningStep) => {
      const components = [
        <VideoPage
          title={trainningStep?.description}
          url={trainningStep?.videoUrl}
        />,
      ]

      for (let question of trainningStep.questions) {
        components.push(
          <QuestionPage question={question} handleNext={handleNext} />,
        )
      }
      return components
    },
  )

  const steps = [
    [<FirstPage trainningJourney={trainningJourney} />],
    ...(trainningJourney ? trainningStepComponents : []),
    [
      <Certification
        viewerStep={viewerStep}
        trainningJourney={trainningJourney}
        setShowActions={setShowActions}
        setStep={setStep}
      />,
    ],
    [<Conclusion />],
  ]

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1)
      setSubtep(0)
    }
  }

  return (
    <Page title="Treinamento" transparent>
      <LoadingFeedback open={loading} />
      <Container maxWidth="xl">
        <ContentBase
          handleNext={handleNext}
          handleBack={handleBack}
          isFirstStep={isFirstPage}
          hideStepper={isFirstPage}
          hideBackButton={isFirstPage || isFinalStep}
          showActions={showActions}
          buttonAlign="center"
          nextButtonText={
            isFirstPage
              ? 'Iniciar minha trilha'
              : isFinalStep
              ? 'Finalizar'
              : 'Avançar'
          }
          activeStep={step - 1}
        >
          {steps[step][subStep]}
        </ContentBase>
      </Container>
    </Page>
  )
}

export default TrainningJourney
