import React, { useState, useEffect } from 'react'

import { Page } from 'components'
import { DashboardGraphs } from './components'

import * as service from 'service'

import useStyles from './styles'

const Dashboard = () => {
  const classes = useStyles()
  const [gapStats, setGapStats] = useState({})
  const [processStats, setProcessStats] = useState({})
  const [generalStats, setGeneralStats] = useState({})
  const [supplierStats, setSupplierStats] = useState({})
  const [fragilityStats, setFragilityStats] = useState({})
  const [questionControlStats, setQuestionControlStats] = useState({})
  const [loading, setLoading] = useState()

  useEffect(() => {
    const loadStats = async () => {
      setLoading(true)
      const [
        gapStatsResponse,
        processStatsResponse,
        generalStatsResponse,
        supplierStatsResponse,
        fragilityStatsResponse,
        questionControlResponse,
      ] = await Promise.all([
        service.dponet.myLgpd.getGapStats(),
        service.dponet.myLgpd.getProcessStats(),
        service.dponet.myLgpd.getGeneralStats(),
        service.dponet.myLgpd.getSupplierStats(),
        service.dponet.myLgpd.getFragilityStats(),
        service.dponet.myLgpd.getQuestionControlStats(),
      ])
      setGapStats(gapStatsResponse.data.stats)
      setProcessStats(processStatsResponse.data.stats)
      setGeneralStats(generalStatsResponse.data.stats)
      setSupplierStats(supplierStatsResponse.data.stats)
      setFragilityStats(fragilityStatsResponse.data.stats)
      setQuestionControlStats(questionControlResponse.data.stats)
      setLoading(false)
    }

    loadStats()
    //eslint-disable-next-line
  }, [])

  return (
    <Page className={classes.root} title="Dashboard">
      <DashboardGraphs
        gapStats={gapStats}
        processStats={processStats}
        generalStats={generalStats}
        supplierStats={supplierStats}
        fragilityStats={fragilityStats}
        questionnaires={gapStats?.questionnaires || []}
        questionControlStats={questionControlStats}
        loading={loading}
      />
    </Page>
  )
}

export default Dashboard
