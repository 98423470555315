import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import { SVG } from 'components'

const BoxPolygonOutlined = ({ data, classes }) => {
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      className={classes.polygonContainer}
    >
      {data.map((values, index) => (
        <Box key={index} {...values.properties} className={classes.polygonBox}>
          <SVG.PolygonOutline
            width="85"
            height="92"
            stroke={values?.polygonProps?.stroke}
          />
          <Box className={classes.polygonValues}>
            <Typography variant="h3" className={classes.labelTypography}>
              {values.value}
            </Typography>
            <Typography variant="body1" className={classes.labelTypography}>
              {values.label}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

BoxPolygonOutlined.propTypes = {
  data: PropTypes.array,
}

export default BoxPolygonOutlined
