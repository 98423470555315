import constants from 'constants/index'

const statusLabelColor = (statusId, classes) => {
  switch (statusId) {
    case constants.tickets.NEW_STATUS_ID:
      return classes.newTicket
    case constants.tickets.PENDING_STATUS_ID:
      return classes.newTicket
    case constants.tickets.COMPLETED_STATUS_ID:
      return classes.completedTicket
    case constants.tickets.CANCELED_STATUS_ID:
      return classes.canceledTicket
    default:
      return classes.newTicket
  }
}

export default statusLabelColor
