import palette from 'theme/palette'

import { WIDTH as WIDTHPdf, PADDING } from '../constants'

import { ratio } from '../helpers'

const lineWithDate = (pdf, date, color = palette.text.mainMenu) => {
  const WIDTH = WIDTHPdf(pdf)
  pdf.setFont('Raleway-Regular')
  pdf.setFillColor(color)
  pdf.rect(PADDING, PADDING - 20, WIDTH - PADDING * 2, ratio(4), 'F')
  pdf.setFontSize(ratio(44))
  pdf.setTextColor(color)
  pdf.text(date, WIDTH - PADDING, PADDING - 25, null, null, 'right')
}

export default lineWithDate
