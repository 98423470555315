import React from 'react'

import useFetch from 'hooks/useFetch'

import { Container, Card, Box, CircularProgress } from '@material-ui/core'

import PrivacyPoliciesForm from './components/PrivacyPoliciesForm'
import { ContentHeader, Page } from 'components'

import * as service from 'service'
import useStyles from './styles'

const PrivacyPoliciesEdit = ({ match }) => {
  const classes = useStyles()
  const { privacyPolicyId } = match?.params

  const { response, isLoading } = useFetch(service.dponet.privacyPolicies.get, {
    privacyPolicyId,
  })

  const privacyPolicy = response?.data?.privacyPolicy

  return (
    <Page title="Editar política de privacidade">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader title="Editar política de privacidade" />
        <Card>
          <Box px={3} pb={3}>
            <Box mt={3}>
              {isLoading && !response ? (
                <Box
                  display="flex"
                  width="100%"
                  minHeight="700px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <PrivacyPoliciesForm privacyPolicy={privacyPolicy} />
              )}
            </Box>
          </Box>
        </Card>
      </Container>
    </Page>
  )
}

export default PrivacyPoliciesEdit
