import palette from 'theme/palette'

import { redBox } from '../../assets/redBox64'
import { greenBox } from '../../assets/greenBox64'

const box = (pdf, positionX, positionY, label, data, color, ratio) => {
  pdf.setTextColor(palette.white)
  pdf.setFontSize(ratio(48))
  color === 'green'
    ? pdf.addImage(
        greenBox,
        'png',
        positionX,
        positionY,
        ratio(650),
        ratio(270),
      )
    : pdf.addImage(redBox, 'png', positionX, positionY, ratio(650), ratio(270))

  pdf.setFont('Raleway-Regular')
  pdf.text(
    label,
    positionX + ratio(40),
    positionY + ratio(145),
    null,
    null,
    'left',
  )

  pdf.setFontSize(ratio(80))
  pdf.setFont('Raleway-Bold')
  pdf.text(
    data,
    positionX + ratio(40),
    positionY + ratio(195),
    null,
    null,
    'left',
  )
}

export default box
