import React, { useState } from 'react'
import useSnackbar from 'hooks/useSnackbar'

import { Grid, TableContainer, Box, Button } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton } from 'components'
import DialogRemoveDocument from '../../../../DialogRemoveDocument'

import helpers from 'helpers'
import * as service from 'service'
import { getBaseURL } from 'service/env'

const TableDocuments = ({ documents, dataProcessId, onEvent = () => {} }) => {
  const snackbar = useSnackbar()
  const [dialogRemoveDocument, setDialogRemoveDocument] = useState(false)
  const [removableDocument, setRemovableDocument] = useState(false)

  const handleRemove = (document) => {
    setRemovableDocument(document)
    setDialogRemoveDocument(true)
  }

  const onClickDeleteDocument = async (document) => {
    const documentName = helpers.functions.documentName(document.url)

    try {
      await service.dponet.documents.deleteDocument({
        dataProcessesId: dataProcessId,
        documentName: documentName,
      })

      onEvent()
      snackbar.open({
        message: 'Documento removido com sucesso!',
        variant: 'success',
      })
    } catch {
      snackbar.open({
        message: `Ocorreu algum erro ao deletar ${documentName}!\n`,
        variant: 'error',
      })
    }
  }

  return (
    <>
      <TableContainer>
        <Table size="small" emptyMessage="Nenhum documento encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell>Documentos</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.map((document, index) => (
              <TableRow key={index}>
                <TableCell>
                  {helpers.functions.documentName(document?.url)}
                </TableCell>
                <TableCell align="right">
                  <Grid container item justify="flex-end">
                    <Box display="flex" justifyContent="flex-end">
                      <MenuButton>
                        <Button
                          color="secondary"
                          fullWidth
                          onClick={() =>
                            window.open(
                              `${getBaseURL('dponet')}${document?.url}`,
                              '_blank',
                            )
                          }
                        >
                          Visualizar
                        </Button>
                        <Button
                          color="secondary"
                          fullWidth
                          onClick={() => handleRemove(document, index)}
                        >
                          Remover
                        </Button>
                      </MenuButton>
                    </Box>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogRemoveDocument
        open={dialogRemoveDocument}
        setOpen={setDialogRemoveDocument}
        handleRemove={onClickDeleteDocument}
        document={removableDocument}
      />
    </>
  )
}

export default TableDocuments
