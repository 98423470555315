import React from 'react'

import { Step, StepLabel } from '@material-ui/core'

import { StepIcon, Stepper as StepperComponent } from 'components'

import useStyles from './styles'

const Stepper = ({ activeStep }) => {
  const classes = useStyles()
  const stepLabels = [
    'CONCEITO',
    'CATEGORIAS',
    'TRATAMENTOS',
    'INCIDENTES',
    'CERTIFICADO',
  ]

  return (
    <StepperComponent activeStep={activeStep}>
      {stepLabels.map((label) => (
        <Step
          key={label}
          classes={{
            horizontal: classes.horizontalStep,
          }}
        >
          <StepLabel
            StepIconComponent={StepIcon}
            classes={{ label: classes.stepperLabel }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </StepperComponent>
  )
}

export default Stepper
