import constants from 'constants/index'

const descriptionReport = (value, ropa) => {
  if (ropa) {
    return constants.reports.JUSTIFICATION_LABEL[value].replace(
      /ripd/gi,
      'relatório',
    )
  }

  return constants.reports.JUSTIFICATION_LABEL[value]
}

export default descriptionReport
