/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Grid,
  Hidden,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { Payment, SelectCompanySize, FormAccessData, FormMain } from '../'
import { LoadingButton, HorizontalStepper } from './components'
import { LoadingBox } from 'components'
import ReCAPTCHA from 'react-google-recaptcha'

import RegistrationResume from '../RegistrationResume'
import SubscriptionAdvantages from '../SubscriptionAdvantages'

import useSubscription from 'hooks/useSubscription'
import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'
import helpers from 'helpers'
import constants from 'constants/index'
import theme from 'theme'
import * as service from 'service'
import { getGoogleRecaptchaToken } from 'service/env'

import { TOKEN_PRE_REGISTRATION } from 'service/constants'

const StepperComponent = ({ demo, greatherStep, setGreatherStep }) => {
  const snackbar = useSnackbar()
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(
    constants.preRegistrations.FIRST_STEP[1],
  )
  const {
    activeSubStep,
    data,
    disabledButton,
    isLoading,
    loadDataByToken,
    nextStep,
    nextSubStep,
    payloadPayment,
    plans,
    recaptchaRef,
    selectedDateExpirationBillet,
    selectedMethod,
    selectedPlan,
    setActiveSubStep,
    setIsLoading,
    setPaymentData,
    setPolicyAccept,
    setRecaptcha,
    setStatus,
    setTermsAccept,
    status,
    validForm,
    selectedSegment,
  } = useSubscription()

  const logout = () => {
    localStorage.removeItem('@dponet-pre-registration')
    service.dponet.auth.logout()
  }

  const getSteps = () => {
    return [
      { label: 'Sobre você', index: constants.preRegistrations.FIRST_STEP[0] },
      {
        label: 'Sobre a empresa',
        index: constants.preRegistrations.ABOUT_COMPANY_STEP[0],
      },
      { label: 'Pagamento', index: constants.preRegistrations.PAYMENT_STEP[0] },
    ]
  }

  const getButtonIdByStep = () => {
    return [
      'id-checkout-button-hiring',
      'id-checkout-button-about',
      'id-checkout-button-payment',
    ][activeStep]
  }

  const handleDataLayer = () => {
    if (process.env.NODE_ENV === 'production') {
      const exist = window.dataLayer?.find(
        (layer) =>
          layer.event === 'checkout_step' &&
          layer.etapa === activeStep + 1 &&
          layer.tamanho === selectedPlan?.name,
      )
      if (exist) return

      window.dataLayer.push({
        event: 'checkout_step',
        etapa: activeStep + 1,
        segmento: selectedSegment,
        tamanho: selectedPlan?.name,
      })
    }
  }

  const submitPayment = async (payload) => {
    try {
      setIsLoading(true)
      const payloadFormatted = helpers.preRegistration.paymentFormatObject(
        selectedMethod,
        payload || payloadPayment,
        selectedDateExpirationBillet,
      )
      const response = await service.dponet.payments.create(payloadFormatted)

      setPaymentData(response?.data?.order)
      setStatus(response?.data?.order?.status)
      if (selectedMethod.id === constants.preRegistrations.BILLET_METHOD) {
        setActiveSubStep(constants.preRegistrations.BILLET_STEP[1])
      } else {
        setActiveSubStep(constants.preRegistrations.PAYMENT_STEP[0])
      }
      setIsLoading(false)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      setIsLoading(false)
    }
  }

  const getFunctionToExecute = async (event) => {
    if (
      activeSubStep === constants.preRegistrations.PAYMENT_STATUS_STEP[1] &&
      activeStep === constants.preRegistrations.PAYMENT_STATUS_STEP[0]
    ) {
      event.preventDefault()
      setActiveSubStep(constants.preRegistrations.PAYMENT_STEP[1])
    }
    if (
      selectedMethod.id === constants.preRegistrations.BILLET_METHOD &&
      activeSubStep === constants.preRegistrations.PAYMENT_STEP[1] &&
      activeStep === constants.preRegistrations.PAYMENT_STEP[0]
    ) {
      await submitPayment()

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  const getStepContent = (stepIndex) => {
    if (
      ['AWAITING_PAYMENT', 'FINALIZED'].includes(data?.status) &&
      localStorage.getItem(TOKEN_PRE_REGISTRATION)
    ) {
      setActiveStep(constants.preRegistrations.FINALIZED_STATUS_STEP[0])
    }
    handleDataLayer()
    switch (stepIndex) {
      case constants.preRegistrations.FIRST_STEP[0]:
        return <FormAccessData />
      case constants.preRegistrations.ABOUT_COMPANY_STEP[0]:
        return <FormMain />
      case constants.preRegistrations.PAYMENT_STEP[0]:
        return <Payment submitPayment={submitPayment} />
      default:
        return <></>
    }
  }

  useEffect(() => {
    if (activeStep > greatherStep) setGreatherStep(activeStep)
  }, [activeStep, greatherStep])

  const steps = getSteps()

  useEffect(() => {
    const handleNext = () => {
      if (validForm) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    }
    handleNext()
    // eslint-disable-next-line
  }, [nextStep])

  useEffect(() => {
    const handleNextSubStep = () => {
      if (validForm) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
        setActiveSubStep((prevActiveStep) => prevActiveStep + 1)
      }
    }
    handleNextSubStep()
    // eslint-disable-next-line
  }, [nextSubStep])

  const handlePrevious = () => {
    if (localStorage.getItem(TOKEN_PRE_REGISTRATION)) {
      loadDataByToken()
    }
    if (activeStep === constants.preRegistrations.ABOUT_COMPANY_STEP[0]) {
      setTermsAccept(false)
      setPolicyAccept(false)
    }
    if (activeStep === constants.preRegistrations.PAYMENT_STEP[0]) {
      setRecaptcha(undefined)
    }
    if (activeSubStep > 0) {
      setActiveSubStep((prevActiveStep) => prevActiveStep - 1)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const showActionButton = () => {
    if (
      helpers.preRegistration.buttonTextByStatus(status) === '' && //TODO retirar o mokado
      activeStep === constants.preRegistrations.PAYMENT_STATUS_STEP[0] &&
      activeSubStep === constants.preRegistrations.PAYMENT_STATUS_STEP[1]
    ) {
      return false
    }
    return true
  }

  const showPreviousButton = () => {
    if (activeStep === constants.preRegistrations.FIRST_STEP[0]) return false
    if (
      activeStep === constants.preRegistrations.PAYMENT_STATUS_STEP[0] &&
      activeSubStep === constants.preRegistrations.PAYMENT_STATUS_STEP[1]
    ) {
      return false
    }
    return true
  }

  const showBothButtons = () => {
    if (
      activeStep === constants.preRegistrations.FINALIZED_STATUS_STEP[0] ||
      (activeStep === constants.preRegistrations.BILLET_STEP[0] &&
        activeSubStep === constants.preRegistrations.BILLET_STEP[1])
    ) {
      return false
    }
    return true
  }

  const isFullScreen = activeSubStep === 0
  const aboutCompanyStep =
    activeStep === constants.preRegistrations.ABOUT_COMPANY_STEP[0]
  const notPaymentStep =
    isFullScreen && activeStep !== constants.preRegistrations.PAYMENT_STEP[0]
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <Box className={classes.root} mb={5}>
      {!demo && isFullScreen && (
        <Box width="100%" display="flex" justifyContent="center">
          <HorizontalStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            greatherStep={greatherStep}
            steps={steps}
            isFinalized={data?.status === 'FINALIZED'}
          />
        </Box>
      )}
      <Box mt={isDesktop ? 5 : 2}>
        {activeStep === steps.length ? (
          <>
            <Box display="flex" justifyContent="center">
              {data?.status === 'AWAITING_PAYMENT' ? (
                <Typography className={classes.instructions}>
                  Já existe uma cobrança em aberto. Verifique seu e-mail.
                </Typography>
              ) : (
                <Typography className={classes.instructions}>
                  Todas as etapas foram concluídas.
                </Typography>
              )}
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                onClick={logout}
              >
                sair
              </Button>
            </Box>
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid item {...(isFullScreen && { xl: 9, lg: 8 })} xs={12}>
              {isFullScreen && (
                <>
                  {isEmpty(plans) ? (
                    <Box mb={2} mt={5.5}>
                      <Alert variant="filled" severity="error">
                        <AlertTitle>
                          <Typography variant="h5">
                            Segmento não identificado
                          </Typography>
                        </AlertTitle>
                        <Typography variant="h6">
                          É necessário um segmento válido para prosseguir com a
                          contratação. Por gentileza, entre em contato com o
                          Atendimento para soluções personalizadas para sua
                          empresa.
                        </Typography>
                      </Alert>
                    </Box>
                  ) : (
                    <>
                      <Typography variant="h4" className={classes.styleText}>
                        Selecione o tamanho da sua empresa
                      </Typography>
                      <SelectCompanySize />
                    </>
                  )}
                </>
              )}
              {isLoading ? (
                <LoadingBox minHeight="35vh" />
              ) : (
                <Box
                  mb={isDesktop ? 5 : 2}
                  {...(notPaymentStep && { component: Paper })}
                  variant="outlined"
                >
                  {getStepContent(activeStep)}
                  {notPaymentStep && (
                    <Box px={2} pb={2} pt={aboutCompanyStep ? 2 : 0}>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        theme="light"
                        sitekey={getGoogleRecaptchaToken()}
                        onChange={(token) => {
                          setRecaptcha(token)
                        }}
                        onExpired={() => {
                          setRecaptcha(undefined)
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
              {showBothButtons() && (
                <Box display="flex" justifyContent="flex-end">
                  {showPreviousButton() && (
                    <Box mr={2.5}>
                      <Button variant="text" onClick={handlePrevious}>
                        Voltar
                      </Button>
                    </Box>
                  )}
                  {showActionButton() && (
                    <LoadingButton
                      className={classes.loadingButton}
                      variant="contained"
                      type="submit"
                      color="primary"
                      form="form-submit-main"
                      text="Avançar"
                      id={getButtonIdByStep()}
                      loading={isLoading}
                      disabledButton={
                        (disabledButton &&
                          [
                            constants.preRegistrations.FIRST_STEP[0],
                            constants.preRegistrations.ABOUT_COMPANY_STEP[0],
                          ].includes(activeStep)) ||
                        isLoading
                      }
                      onClick={(event) => getFunctionToExecute(event)}
                    />
                  )}
                </Box>
              )}
            </Grid>
            {isFullScreen && (
              <Hidden mdDown>
                <Grid item {...(isFullScreen && { xl: 3, lg: 4 })}>
                  <Typography variant="h4" className={classes.styleText}>
                    Pedido
                  </Typography>
                  <Box mt={2.5}>
                    <RegistrationResume />
                  </Box>
                  <Box>
                    <SubscriptionAdvantages />
                  </Box>
                </Grid>
              </Hidden>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  )
}

StepperComponent.propTypes = {
  greatherStep: PropTypes.number.isRequired,
  setGreatherStep: PropTypes.func.isRequired,
  demo: PropTypes.bool,
}

StepperComponent.defaultProps = {
  setGreatherStep: () => {},
  demo: false,
}

export default StepperComponent
