import React from 'react'
import { Box, Typography } from '@material-ui/core'

import BaseStatus from '../BaseStatus'

const Disapproved = () => {
  return (
    <BaseStatus status="disapproved" description="Pagamento foi reprovado!">
      <Box>
        <Typography variant="h4" color="textPrimary">
          Olá
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Seu pagamento foi reprovado. Realize o pagamento novamente para
          efetivar sua compra
        </Typography>
      </Box>
    </BaseStatus>
  )
}

export default Disapproved
