import React from 'react'
import PropTypes from 'prop-types'
import { usePalette } from 'react-palette'
import { isNil } from 'lodash'

import clsx from 'clsx'

import clientLogo from 'images/default-partner-image.svg'

import { Avatar, Box, Card, Typography } from '@material-ui/core'
import styles from './styles'

const ProfileCard = ({ avatarImagePath, title, subtitle, children }) => {
  const { data } = usePalette(avatarImagePath || clientLogo)

  const useStyles = styles(data.lightVibrant)
  const classes = useStyles(data.vibrant)

  return (
    <Card>
      <Box maxHeight="40px">
        <Box className={classes.backgroundBanner} />
      </Box>
      <Box mx={2} my={3}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Avatar
            className={clsx(classes.avatar, {
              [classes.avatarBackground]: !isNil(avatarImagePath),
            })}
            src={avatarImagePath}
          />
          {children}
        </Box>
        <Box display="flex" flexDirection="column" mt={3}>
          <Typography variant="h3" color="textPrimary" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Card>
  )
}

ProfileCard.propTypes = {
  avatarImagePath: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default ProfileCard
