import activeProcess from './activeProcess'
import approvedProcess from './approvedProcess'
import chartOptions from './chartOptions'
import dashboard from './dashboard'
import finalizedRmcs from './finalizedRmcs'
import gapCharts from './gapCharts'
import highRiskProcess from './highRiskProcess'
import inactiveProcess from './inactiveProcess'
import lowRiskProcess from './lowRiskProcess'
import mediumRiskProcess from './mediumRiskProcess'
import mountComplianceRows from './mountComplianceRows'
import mountGapPanels from './mountGapPanels'
import mountGapRmcRows from './mountGapRmcRows'
import mountGapTabs from './mountGapTabs'
import mountProgressRows from './mountProgressRows'
import mountRowName from './mountRowName'
import onTimeRmcs from './onTimeRmcs'
import overdueRmcs from './overdueRmcs'
import overviewPdfTable from './overviewPdfTable'
import pendingRmcs from './pendingRmcs'
import processCharts from './processCharts'
import reprovedProcess from './reprovedProcess'
import riskAcceptedRmcs from './riskAcceptedRmcs'
import rncCharts from './rncCharts'
import severeRiskProcess from './severeRiskProcess'
import totalProcess from './totalProcess'

export default {
  ...dashboard,
  activeProcess,
  approvedProcess,
  chartOptions,
  finalizedRmcs,
  gapCharts,
  highRiskProcess,
  inactiveProcess,
  lowRiskProcess,
  mediumRiskProcess,
  mountComplianceRows,
  mountGapPanels,
  mountGapRmcRows,
  mountGapTabs,
  mountProgressRows,
  mountRowName,
  onTimeRmcs,
  overdueRmcs,
  overviewPdfTable,
  pendingRmcs,
  processCharts,
  reprovedProcess,
  riskAcceptedRmcs,
  rncCharts,
  severeRiskProcess,
  totalProcess,
}
