const textFragilityColor = (statusId, classes) => {
  switch (statusId) {
    case 1:
      return classes.textLowFragility
    case 2:
      return classes.textMediumFragility
    case 3:
      return classes.textHighFragility
    case 4:
      return classes.textSevereFragility
    case 5:
      return classes.textUndefinedFragility
    default:
      return ''
  }
}

export default textFragilityColor
