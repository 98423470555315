import React from 'react'
import { useForm, Controller } from 'react-hook-form'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import * as service from 'service'
import helpers from 'helpers'

const DialogHandleUsers = ({
  questionnaireId,
  linkedUsers,
  open,
  setOpen,
  onEvent = () => {},
}) => {
  const snackbar = useSnackbar()
  const options = []
  const selectOptions = []
  const { handleSubmit, control } = useForm()

  const { response, isLoading } = useFetch(
    service.dponet.questionnaires.availableUsers,
    { questionnaireId },
  )

  if (!isLoading && response && response.data) {
    response.data.users.map((user) => {
      return options.push({ name: user.name, id: user.id })
    })
  }

  linkedUsers.map((user) => {
    if (options.find((obj) => obj.id === user.id))
      return selectOptions.push({ name: user.name, id: user.id })

    return null
  })

  const onSubmit = async (data) => {
    const users = []

    data.users.map((user) => {
      return users.push(user.id)
    })

    try {
      await service.dponet.questionnaires.linkUsers({
        questionnaireId,
        users,
      })
      onEvent()
      setOpen(false)
      snackbar.open({
        message: 'Usuários definidos com sucesso',
        variant: 'success',
      })
    } catch (error) {
      setOpen(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h5">
            Usuários com permissão para responder o questionário
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box my={1}>
        <DialogContent p={1}>
          {!isLoading && (
            <form onSubmit={handleSubmit(onSubmit)} id="handle-users-form">
              <Controller
                name="users"
                control={control}
                onChange={([, data]) => data}
                defaultValue={selectOptions}
                as={
                  <Autocomplete
                    multiple
                    options={options}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    limitTags={3}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Usuários"
                        placeholder="Usuários"
                      />
                    )}
                  />
                }
              />
            </form>
          )}
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          disabled={!response || !response.data.users.length}
          color="primary"
          form="handle-users-form"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogHandleUsers
