import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Typography, Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { LoadingFeedback } from 'components'

import helpers from 'helpers'

import styles from '../../styles'

const useStyles = makeStyles(styles)

const TableRowSupplier = ({ supplier, refresh, ...rest }) => {
  const classes = useStyles()

  return (
    <TableRow {...rest}>
      <TableCell>{supplier?.id}</TableCell>
      <TableCell>
        <Typography>
          {helpers.formatters.cnpjOrCpf(supplier?.document)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography>{helpers.formatters.date(supplier?.createdAt)}</Typography>
      </TableCell>
      <TableCell align="center">
        <Chip
          size="small"
          className={clsx(
            classes.chipRoot,
            helpers.companySuppliers.statusChipColor(supplier?.status, classes),
          )}
          label="PENDENTE"
        />
      </TableCell>
    </TableRow>
  )
}

const SupplierInvitesTable = ({ supplierInvites, isLoading, refresh }) => {
  return (
    <>
      <LoadingFeedback open={isLoading} />
      <Box width="100%">
        <PerfectScrollbar
          options={{
            useBothWheelAxes: true,
            suppressScrollX: false,
          }}
        >
          <Table
            size="medium"
            emptyMessage="Nenhuma sugestão encontrada"
            isLoading={isLoading}
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="25%">Identificador</TableCell>
                <TableCell width="25%">CNPJ/CPF</TableCell>
                <TableCell width="25%" align="center">
                  Criado em
                </TableCell>
                <TableCell width="15%" align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {supplierInvites?.map((supplier) => (
                <TableRowSupplier
                  supplier={supplier}
                  key={supplier?.id}
                  refresh={refresh}
                  hover
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
    </>
  )
}

SupplierInvitesTable.propTypes = {
  supplierInvites: PropTypes.array,
  isLoading: PropTypes.bool,
  refresh: PropTypes.func,
}

TableRowSupplier.propTypes = {
  handleChange: PropTypes.func,
  refresh: PropTypes.func,
  supplier: PropTypes.object,
}

SupplierInvitesTable.defaultProps = {
  refresh: () => {},
  isLoading: false,
}

TableRowSupplier.defaultProps = {
  handleChange: () => {},
  refresh: () => {},
}

export default SupplierInvitesTable
