const styles = (theme) => ({
  button: {
    padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
    textTransform: 'capitalize',
    borderRadius: 0,
    color: theme.palette.text.menu,
  },
  textWhite: {
    color: 'white',
  },
})

export default styles
