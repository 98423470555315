import React from 'react'
import {
  Box,
  Typography,
  Grid,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'

import TableDataTreatments from '../../TableDataTreatments'
import { DataTreatmentModal } from '../../DataTreatmentModal'

import useDataTreatment from 'hooks/useDataTreatment'

import constants from 'constants/index'
import styles from './styles'

const useStyles = makeStyles(styles)

const DataTreatment = ({ dataProcessId, isLoading }) => {
  const dataTreatmentHook = useDataTreatment()

  const dataTreatments = dataTreatmentHook.data
  const classes = useStyles()

  const loading = isLoading || dataTreatmentHook.isLoading

  return (
    <>
      {loading ? (
        <Box
          id="loadBox"
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box id={constants.dataProcess.DATA_PROCESS_EDIT_DRIVER_STEP_5}>
          <Grid container justifyContent="space-between">
            <Box mt={2}>
              <Typography variant="h5">
                Com quem os dados são compartilhados?
              </Typography>
            </Box>
            <Box mt={2}>
              {!isLoading && (
                <DataTreatmentModal
                  dataTreatment={{ dataProcessId }}
                  classes={classes}
                  mode="create"
                />
              )}
            </Box>
          </Grid>

          <Grid container className={classes.dataCollectedContainer}>
            <Grid
              className={classes.dataCollectedTable}
              item
              xl={12}
              lg={12}
              md={12}
              xs={12}
            >
              {!isLoading && (
                <TableDataTreatments
                  loadData={dataTreatmentHook.loadData}
                  dataProcessId={dataProcessId}
                  dataTreatments={dataTreatments}
                  edit
                  classes={classes}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default DataTreatment
