import ConsentShow from './ConsentShow'
import ConsentMain from './ConsentMain'
import ConsentNew from './ConsentNew'
import ConsentReceiptDetails from './ConsentReceiptDetails'
import ConsentReceiptMain from './ConsentReceiptMain'
import ConsentReceiptNew from './ConsentReceiptNew'

export default {
  ConsentShow,
  ConsentMain,
  ConsentNew,
  ConsentReceiptDetails,
  ConsentReceiptMain,
  ConsentReceiptNew,
}
