import React from 'react'
import PropTypes from 'prop-types'

import { Grid, TextField } from '@material-ui/core'
import { Card } from 'components'

import { Controller } from 'react-hook-form'

const GeneralInformationForm = ({ errors, control }) => {
  return (
    <Card title="Informações gerais">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Nome do Perfil"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.name}
                helperText={errors?.name?.message}
                fullWidth
              />
            }
            control={control}
            name="name"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Descrição"
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.description}
                helperText={errors?.description?.message}
                fullWidth
              />
            }
            control={control}
            name="description"
            mode="onBlur"
          />
        </Grid>
      </Grid>
    </Card>
  )
}

GeneralInformationForm.propTypes = {
  errors: PropTypes.object,
  control: PropTypes.object,
}

export default GeneralInformationForm
