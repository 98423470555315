import palette from 'theme/palette'
import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerSegment: {
    width: '100%',
    padding: theme.spacing(2.5),
    borderRadius: '0px 0px 4px 4px',
    color: '#14171A',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  pointer: {
    cursor: 'pointer !important',
  },
  styleText: {
    [theme.breakpoints.down('xs')]: { fontSize: 17 },
    fontWeight: 500,
  },
  loadingButton: {
    backgroundColor: palette.subscription.primary.main,
    '&:hover': {
      backgroundColor: palette.subscription.primary.light,
      color: palette.subscription.primary.main,
    },
  },
}))

export default styles
