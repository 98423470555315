import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ technicalFaqId = '', ...params }) => {
  return await dponetAPI.get(`/technical_faqs/${technicalFaqId}`, {
    params,
  })
}

const getSubcategory = async ({ subCategoryId = '', ...params }) => {
  return await dponetAPI.get(`/knowledge_base_subcategories/${subCategoryId}`, {
    params,
  })
}

const technicalFaqs = {
  get,
  getSubcategory,
}

export default technicalFaqs
