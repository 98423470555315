const styles = (theme) => ({
  avatar: {
    height: 39,
    width: 39,
    backgroundColor: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
    fontSize: 15,
  },
})

export default styles
