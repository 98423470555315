import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { ConfirmationDialog, LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import * as service from 'service'

const DeleteModal = ({ consentFormId, receiptId, open, setOpen, refresh }) => {
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const handleDelete = async () => {
    setLoading(true)

    try {
      await service.dponet.consent.destroyReceipt({
        consentFormId: consentFormId,
        id: receiptId,
      })

      snackbar.open({
        message: `Recibo excluído com sucesso!`,
        variant: 'success',
      })

      setOpen(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro ao tentar excluir o recibo',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <ConfirmationDialog
        open={open}
        setOpen={setOpen}
        text="Tem certeza de que deseja excluir este consentimento? Depois não será possível recuperá-lo!"
        textButtonCancel="Cancelar"
        textButton="Confirmar"
        actionAcceptButton={handleDelete}
      />
    </>
  )
}

DeleteModal.propTypes = {
  consentFormId: PropTypes.number,
  receiptId: PropTypes.number,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
}

export default DeleteModal
