import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  dialogContent: {
    paddingBlock: theme.spacing(1),
  },
  dialogActions: {
    paddingInline: theme.spacing(2),
  },
}))

export default styles
