import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: 10,
  },
  subLabel: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginBottom: 10,
    marginLeft: 5,
  },
  input: {
    backgroundColor: theme.palette.white,
  },
  textInput: {
    '& .MuiInputBase-root-1126': {
      backgroundColor: theme.palette.white,
    },
  },
  extraInput: {
    marginTop: theme.spacing(1),
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  menuItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
  checkboxTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  checkboxDescription: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  alert: {
    color: theme.palette.redErrorDark,
  },
  textHelper: {
    textAlign: 'end',
  },
}))

export default styles
