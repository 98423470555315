import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Grid,
  Box,
  Card,
  Typography,
  TextField,
  Button,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { PlusCircle } from 'react-feather'

import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

import { Container, Label } from 'components'
import { TableDocuments } from '../'

import schema from './schema'
import * as service from 'service'
import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'

const Evidence = ({ planEvidence, questionControl, isEdit, refresh }) => {
  const history = useHistory()
  const snackbar = useSnackbar()
  const [files, setFiles] = useState([])
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      evidence: planEvidence?.evidence || '',
    },
  })

  const isDisabled =
    !isEdit || !questionControl?.openWrite || planEvidence?.evidenceConclusion

  const handleReturn = () => history.push(routes.questionControls.all)

  const {
    response: responseDocuments,
    refresh: reload,
    isLoading: documentLoading,
  } = useFetch(
    service.dponet.documents.listQuestionPlanActionEvidency,
    { questionPlanActionEvidencyId: planEvidence?.id },
    [],
  )

  const documents = responseDocuments?.data?.documents || []

  const handleUploaderFile = async () => {
    if (isEmpty(files)) return null
    try {
      await service.dponet.documents.addQuestionPlanActionEvidency({
        questionPlanActionEvidencyId: planEvidence?.id,
        files: files,
      })
      reload()
      snackbar.open({
        message: 'Arquivo(s) enviado com sucesso!',
        variant: 'success',
      })
      setFiles([])
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Erro no envio dos arquivo(s)!',
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (!isEmpty(files)) {
      handleUploaderFile()
    }

    //eslint-disable-next-line
  }, [files])

  const onSubmit = async (data) => {
    try {
      await service.dponet.questionControls.update({
        questionPlanActionEvidencyId: planEvidence?.id,
        ...data,
      })
      refresh()

      snackbar.open({
        message:
          'Evidência da implementação salva com sucesso! para atualizar basta reabrir.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro! Tente novamente.',
        variant: 'error',
      })
    }
  }

  return (
    <Container maxWidth="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="h4">Evidência</Typography>
            </Box>
            <Card>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  {!questionControl?.openWrite && (
                    <Box m={(2, 2, 0, 2)}>
                      <Alert severity="warning">
                        <AlertTitle>
                          Para editar esta evidência, é necessário reabri-la.
                        </AlertTitle>
                      </Alert>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box m={2}>
                    <Label title="Evidência da implementação (O que foi feito)">
                      <Controller
                        control={control}
                        as={
                          <TextField
                            type="text"
                            color="primary"
                            variant="outlined"
                            multiline
                            rows={6}
                            error={!!errors.evidence}
                            helperText={errors?.evidence?.message}
                            fullWidth
                            disabled={isDisabled}
                          />
                        }
                        name="evidence"
                        mode="onBlur"
                      />
                    </Label>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between" mx={2}>
                    <Box>
                      <Typography variant="subtitle2" color="textPrimary">
                        Adicionar documentos
                      </Typography>
                      <Typography variant="caption">
                        Arquivos suportados: jpg, jpeg, png, pdf, docx, doc.
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      startIcon={<PlusCircle size={20} />}
                      htmlFor="documents"
                      size="small"
                      component="label"
                      disabled={isDisabled}
                    >
                      <Typography>Adicionar documento(s)</Typography>
                      <input
                        type="file"
                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.pdf"
                        multiple
                        id="documents"
                        style={{ display: 'none' }}
                        onChange={(e) => setFiles(e.target.files)}
                      />
                    </Button>
                  </Box>
                </Grid>
                {!isEmpty(documents) && (
                  <Grid xs={12}>
                    <Box m={2}>
                      <TableDocuments
                        files={documents}
                        planEvidence={planEvidence}
                        reload={reload}
                        isLoading={documentLoading}
                        isDisabled={isDisabled}
                      />
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end" m={2}>
                    <Box mr={isDisabled ? 0 : 2}>
                      <Button
                        variant="outlined"
                        type="button"
                        color="primary"
                        onClick={handleReturn}
                      >
                        Voltar
                      </Button>
                    </Box>
                    {!isDisabled && (
                      <Button variant="contained" type="submit" color="primary">
                        Salvar
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

export default Evidence
