import React, { useState } from 'react'

import {
  TableContainer,
  Box,
  CircularProgress,
  Button,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { Permitted } from 'components'

import { DataCollectedModal } from '../DataCollectedModal'
import { DialogRemoveDataCollected } from '../DialogRemoveDataCollected'
import * as service from 'service'
import useDataCollected from 'hooks/useDataCollected'
import helpers from 'helpers'
import useSnackbar from 'hooks/useSnackbar'

const TableDataCollecteds = ({
  classes,
  edit,
  dataCollectedOptions,
  dataProcessId,
  isLoading,
  refresh,
}) => {
  const [dialogRemoveDataCollected, setDialogRemoveDataCollected] =
    useState(false)
  const [removeDataCollected, setRemoveDataCollected] = useState()

  const snackbar = useSnackbar()
  const dataCollectedHook = useDataCollected()

  const dataCollecteds = dataCollectedHook.data
  const toSelectItems = helpers.dataCollected.mountLabelList(
    dataCollectedOptions,
    !edit,
  )

  const handleRemove = (dataCollectedId) => {
    setRemoveDataCollected(dataCollectedId)
    setDialogRemoveDataCollected(true)
  }

  const deleteDataCollected = async (dataProcessId, dataCollectedId) => {
    try {
      await service.dponet.dataCollecteds.destroy({
        dataProcessId,
        dataCollectedId,
      })
      setRemoveDataCollected(null)
      setDialogRemoveDataCollected(false)
      dataCollectedHook.loadData(dataProcessId, '')
      snackbar.open({
        message: `Dado tratado removido com sucesso`,
        variant: 'success',
      })
    } catch (error) {
      setRemoveDataCollected(null)
      setDialogRemoveDataCollected(true)
      snackbar.open({
        message: `Falha ao remover dado tratado`,
        variant: 'error',
      })
    }
  }

  return (
    <>
      {isLoading ? (
        <Box
          id="loadBox"
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer>
          <Table
            size="small"
            emptyMessage="Nenhum dado tratado encontrado"
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="30%">Categoria do titular</TableCell>
                <TableCell width="30%">Faixa Etária</TableCell>
                <TableCell width="20%">
                  Quantidade aproximada de titulares
                </TableCell>
                <TableCell width="20%" align="right">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataCollecteds.map((dataCollected) => {
                const collectedFields = dataCollected.collectedFields.map(
                  (collectedField) => collectedField.dataCollectedOption,
                )
                const selectedFields =
                  helpers.dataCollected.mountLabelList(collectedFields)

                return (
                  <TableRow key={dataCollected.id}>
                    <TableCell>
                      {helpers.functions.dig(
                        dataCollected.titularCategory,
                        'name',
                      )}
                    </TableCell>
                    <TableCell>
                      {helpers.dataCollected.rangeAgeContent(
                        dataCollected?.titularAgeRange,
                      )}
                    </TableCell>
                    <TableCell>
                      {dataCollected?.holderNumber || 'Não definido'}
                    </TableCell>
                    <TableCell align="right">
                      <DataCollectedModal
                        classes={classes}
                        dataCollected={dataCollected}
                        toSelectItems={toSelectItems}
                        selectedFields={selectedFields}
                        dataProcessId={dataProcessId}
                        mode={edit ? 'edit' : 'show'}
                        refresh={refresh}
                      >
                        {edit && (
                          <Permitted tag="create_data_process">
                            <Button
                              color="secondary"
                              fullWidth
                              onClick={() => handleRemove(dataCollected.id)}
                            >
                              Excluir
                            </Button>
                          </Permitted>
                        )}
                      </DataCollectedModal>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <DialogRemoveDataCollected
        open={dialogRemoveDataCollected}
        setOpen={setDialogRemoveDataCollected}
        handleRemove={deleteDataCollected}
        dataProcessId={dataProcessId}
        dataCollectedId={removeDataCollected}
      />
    </>
  )
}

export default TableDataCollecteds
