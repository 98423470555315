import Details from './DataProcessShow'
import EditPage from './DataProcessEdit'
import Main from './DataProcessesMain'
import New from './DataProcessesNew'
import Resume from './DataProcessesResume'
import Suggested from './DataProcessesSuggested'
import SuggestionChanges from './DataProcessSuggestionChanges'
import SuggestionChangesMain from './SuggestionChangesMain'

export default {
  Details,
  EditPage,
  Main,
  New,
  Resume,
  Suggested,
  SuggestionChanges,
  SuggestionChangesMain,
}
