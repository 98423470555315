import { Fragment } from 'react'

import constants from 'constants/index'
import helpers from 'helpers'

const id = (
  cardNumber,
  permittedFlags = constants.cardFlag.CARD_FLAG_PATTERN_STANDARD_VALIDATION,
) => {
  const cardNumberDiff = cardNumber?.replace(/[^0-9]+/g, '')

  if (cardNumberDiff) {
    for (const flagId in permittedFlags) {
      if (permittedFlags[flagId].test(cardNumberDiff)) {
        return Number(flagId)
      }
    }
  }

  return null
}

const format = (id) => {
  return (
    (id && constants.cardFlag.CARD_FLAG_FORMAT_VALIDATION[id]) ||
    constants?.cardFlag.DEFAULT_FORMAT
  )
}

const cardFlagDefinitionByLabel = (label) => {
  label = helpers.functions.capitalizeText(label)
  return constants.cardFlag.CARD_FLAG_DEFINITION_BY_LABEL[label] || Fragment
}

export default {
  cardFlagDefinitionByLabel,
  format,
  id,
}
