const selectVolumetryOptions = () => {
  return [
    { id: 'Minutos', name: 'Minutos' },
    { id: 'Horas', name: 'Horas' },
    { id: 'Dias', name: 'Dias' },
    { id: 'Meses', name: 'Meses' },
    { id: 'Anos', name: 'Anos' },
  ]
}

export default selectVolumetryOptions
