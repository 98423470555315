import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography, Link, Checkbox, makeStyles } from '@material-ui/core'

import { getBaseURL } from 'service/env'

import styles from './styles'

const useStyles = makeStyles(styles)
const FormTerms = ({ setTermsAccept, stylesProps, istrail }) => {
  const classes = useStyles()

  const redirectToTerms = () => {
    window.open(`${getBaseURL('website')}/termos-de-uso/`)
  }

  return (
    <Box display="flex" alignItems="center">
      <Checkbox
        style={stylesProps}
        color="primary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        onChange={(event) => setTermsAccept(event.target.checked)}
      />
      <Typography>
        {istrail ? 'Eu aceito os ' : 'Li e concordo com os '}
        <Link className={classes.pointer} onClick={redirectToTerms}>
          {istrail ? 'Termos & Condições' : 'Termos e condições de uso'}
        </Link>
      </Typography>
    </Box>
  )
}

FormTerms.propTypes = {
  setTermsAccept: PropTypes.func,
  stylesProps: PropTypes.object,
  istrail: PropTypes.bool,
}

FormTerms.defaultProps = {
  setTermsAccept: () => {},
  istrail: false,
}

export default FormTerms
