import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.common.white,
  },
  boxButton: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  checkboxLabel: {
    lineHeight: '1 !important',
  },
}))

export default styles
