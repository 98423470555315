import api from 'service/api'

const dponetAPI = api.create('dponet')

const listContracts = async () => {
  return await dponetAPI.get('client_financial_panel/list_contracts')
}

const changePaymentMethod = async (paymentContractId, { paymentContract }) => {
  return await dponetAPI.put(
    `client_financial_panel//payment_method/${paymentContractId}`,
    { paymentContract },
  )
}

const notifyPaymentOrder = async (paymentOrderId) => {
  return await dponetAPI.get(
    `client_financial_panel/notify_payment_order/${paymentOrderId}`,
  )
}
export default {
  listContracts,
  changePaymentMethod,
  notifyPaymentOrder,
}
