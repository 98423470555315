import moment from 'moment'
import 'moment/locale/pt-br'

moment.locale('pt-br')

const dateFromNow = (date) => {
  if (!date) {
    return null
  }

  const dateObject = new Date(date)
  return moment(dateObject).fromNow()
}

export default dateFromNow
