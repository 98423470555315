import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import { LoadingFeedback } from 'components'
import { DialogFinalize } from 'views/NonComplianceReports/components'
import NonComplianceEditContent from 'views/NonComplianceReports/Edit/RNC/components/NonComplianceEditContent'

import useRmc from 'hooks/useRmc'
import useIncidentManegement from 'hooks/useIncidentManegement'

const NonComplianceForm = ({ refresh }) => {
  const [dialogFinalize, setDialogFinalize] = useState(false)

  const { incident } = useIncidentManegement()
  const { data: nonComplianceReport, loadData, loading } = useRmc()

  const loadNonComplianceReport = async () => {
    if (isEmpty(incident?.nonComplianceReport)) return

    await loadData(incident?.nonComplianceReport?.id)
  }

  useEffect(() => {
    loadNonComplianceReport()
    // eslint-disable-next-line
  }, [incident])

  return (
    <>
      <LoadingFeedback open={loading} />

      {!isEmpty(nonComplianceReport) && (
        <>
          <NonComplianceEditContent
            loading={loading}
            nonComplianceReport={nonComplianceReport}
            nonComplianceReportId={nonComplianceReport?.id}
            setDialogFinalize={setDialogFinalize}
            onEvent={refresh}
          />
          <DialogFinalize
            nonComplianceReport={nonComplianceReport}
            open={dialogFinalize}
            setOpen={setDialogFinalize}
            onEvent={refresh}
          />
        </>
      )}
    </>
  )
}

export default NonComplianceForm
