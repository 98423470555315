const styles = (theme) => ({
  root: {
    margin: 'auto',
    overflowY: 'auto',
    maxHeight: '70vh',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  multiSelect: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
  },
  accordionHeader: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryAccordionHeader: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  accordionSummaryColor: {
    backgroundColor: theme.palette.secondary.superLight,
  },
})

export default styles
