import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  cardContainer: {
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 24,
    width: '100%',
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 16,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    width: 140,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
}))

export default styles
