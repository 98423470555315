import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  formControlLabelRootGroup: {
    margin: 0,
    paddingLeft: 3,
  },
  formControlLabelRoot: {
    margin: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  formControlLabelGroup: {
    fontWeight: 700,
    fontSize: 20,
  },
  checked: {
    backgroundColor: colors.grey[100],
  },
}))

export default styles
