import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.white,
    cursor: 'pointer',
  },
  selected: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.hover,
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    boxShadow:
      '0px 2px 2px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 2px 3px 0px rgb(0 0 0 / 12%)',
  },
  title: {
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.h2.fontWeight,
    lineHeight: 1,
  },
  subTitle: {
    fontSize: '14px',
    fontWeight: 400,
  },
  price: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

export default styles
