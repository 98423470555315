import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonBox: {
    justifyContent: 'end',
  },
  optionTitle: {
    fontWeight: 'bolder',
  },

  optionBox: {
    marginBlock: theme.spacing(1),
  },

  dialogContent: {
    overflow: 'hidden',
  },
}))

export default styles
