const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 20,
  },
  cardLabel: {
    display: 'flex',
    padding: '22px 14px',
  },
  cardContainer: {
    display: 'flex',
    padding: 20,
    cursor: 'pointer',
    transition: '0.3s ease-in-out',

    [theme.breakpoints.down('md')]: {
      padding: 8,
    },
    '&:hover': {
      backgroundColor: theme.palette.marketplace.card.hover,
    },
  },

  description: {
    display: 'flex',
    paddingTop: 5,
    paddingBottom: 5,
    height: 160,
  },

  logoContainer: {
    borderRadius: 10,
    width: 'auto',
    height: 150,
    [theme.breakpoints.down('md')]: {
      height: 130,
    },
    [theme.breakpoints.down('sm')]: {
      height: 110,
    },
  },

  partnerName: {
    fontWeight: 700,
  },
})

export default styles
