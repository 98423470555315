import React from 'react'
import { Box, Typography } from '@material-ui/core'

import BaseStatus from '../BaseStatus'
import useSubscription from 'hooks/useSubscription'

const Approved = () => {
  const { data } = useSubscription()

  return (
    <BaseStatus status="approved" description="Pagamento foi aprovado!">
      <Box>
        <Typography variant="h4" color="textPrimary">
          Passo 1
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Enviaremos um e-mail para {data?.email || 'você'}. Faça o cadastro da
          sua senha para ter acesso aos treinamentos do DPOnet.
        </Typography>
      </Box>
    </BaseStatus>
  )
}

export default Approved
