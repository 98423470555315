import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Dialog, Typography } from '@material-ui/core'
import { Check as CheckIcon } from 'react-feather'

import { LoadingFeedback } from 'components'

import * as service from 'service'
import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

const SolicitationDialog = ({ open, setOpen, categoryService }) => {
  const [isLoading, setIsLoading] = useState(false)

  const snackbar = useSnackbar()

  const solicitationData = {
    title: 'Dúvidas no Marketplace',
    description:
      'Um usuário criou uma solicitação de dúvidas sobre a categoria: ' +
      categoryService,
  }

  const handleSolicitation = async () => {
    try {
      setIsLoading(true)

      await service.dponet.tomticketIntermediator.createTicket({
        ...helpers.formatters.formatDataTomticket(solicitationData),
      })

      snackbar.open({
        message: 'Ticket enviado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.error?.message ||
          'O sistema de abertura de tickets está indisponível no momento. Você pode entrar em contato através do e-mail atendimento@dponet.com.br',
        variant: 'error',
      })
      setOpen(false)
    } finally {
      setIsLoading(false)
      setOpen(false)
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <LoadingFeedback open={isLoading} />
      <Box
        m={4}
        p={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h3" align="center">
          Nosso time entrará em contato em breve por e-mail para esclarecer
          todas as suas dúvidas. Agradecemos o seu interesse.
        </Typography>
      </Box>
      <Box mx={3} mb={2} display="flex" justifyContent="center">
        <Box pr={1}>
          <Button
            disableElevation
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          disableElevation
          startIcon={<CheckIcon />}
          variant="contained"
          color="primary"
          onClick={handleSolicitation}
        >
          Solicitar contato
        </Button>
      </Box>
    </Dialog>
  )
}

SolicitationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  categoryService: PropTypes.string.isRequired,
}

export default SolicitationDialog
