import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Typography } from '@material-ui/core'

import constants from 'constants/index'
import theme from 'theme'

const CardFlagsSection = ({ cardFlagId, title }) => {
  return (
    <PerfectScrollbar
      options={{ wheelPropagation: false, useBothWheelAxes: true }}
    >
      <Box pb={1.5}>
        {title && (
          <Typography variant="caption" gutterBottom>
            {title}
          </Typography>
        )}
        <Box display="flex" gridGap={theme.spacing(2)}>
          {constants.cardFlag.STANDARD_FLAGS_ITEMS.map((flag, index) => {
            const Logo = flag?.Flag
            const cardBgcolor =
              flag?.id !== cardFlagId
                ? flag?.grayscale ?? theme.palette.common.white
                : flag?.bgcolor ?? theme.palette.common.white

            return (
              <Box
                px={1}
                bgcolor={cardBgcolor}
                border={`solid 1px ${theme.palette.paper.border}`}
                borderRadius={4}
                display="flex"
                alignItems="center"
                key={index}
              >
                <Box width={46} height={32} py={0.5}>
                  <Logo
                    grayscale={flag?.id !== cardFlagId}
                    width="100%"
                    height="100%"
                  />
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>
    </PerfectScrollbar>
  )
}

export default CardFlagsSection
