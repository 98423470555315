import * as yup from 'yup'

export const schema = yup.object().shape({
  userCompany: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
  }),
  phone: yup.string().required(),
})

export default schema
