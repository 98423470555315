import React, { useEffect, useState } from 'react'

import {
  Modal,
  Grid,
  Typography,
  Paper,
  IconButton as IconButtonMaterial,
  makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import usePermissions from 'hooks/usePermissions'

import FormModal from './Form'

import styles from './styles'

import * as service from 'service'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const NonComplianceReportsActionModal = ({
  open,
  edit,
  nonComplianceReportId,
  nonComplianceReportAction,
  setOpen = () => {},
  onEvent = () => {},
}) => {
  const { permitted } = usePermissions()

  const listUserPermitted = permitted('list_users')
  const submitSuccess = async () => {
    await onEvent()
    setOpen(false)
  }

  const classes = useStyles()
  const [users, setUsers] = useState()

  useEffect(() => {
    setUsers(undefined)

    const loadUsers = async () => {
      if (listUserPermitted) {
        let response = await service.dponet.usersCompanies.list({
          perPage: 1000000,
          listByCompany: true,
          showSelf: true,
          status: constants.userCompanies.ACTIVE,
        })

        setUsers(
          response?.data?.userCompanies?.map(
            (userCompany) => userCompany?.user,
          ),
        )
      }
    }
    loadUsers()
  }, [listUserPermitted])

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className={classes.modal}
      aria-labelledby="modal-rnc-action"
      aria-describedby="modal-rnc-action-description"
      disableAutoFocus
    >
      <Paper className={classes.paperModal}>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h4" id="modal-rnc-action">
            {edit ? 'Editar' : 'Adicionar'} plano de ação
          </Typography>
          <IconButtonMaterial
            onClick={() => setOpen(false)}
            color="primary"
            aria-label="close modal"
          >
            <CloseIcon />
          </IconButtonMaterial>
        </Grid>
        <FormModal
          nonComplianceReportAction={nonComplianceReportAction}
          handleCloseModal={() => setOpen(false)}
          nonComplianceReportId={nonComplianceReportId}
          submitSuccess={submitSuccess}
          users={users}
          listUserPermitted={listUserPermitted}
        />
      </Paper>
    </Modal>
  )
}

export default NonComplianceReportsActionModal
