import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'

import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'
import helpers from 'helpers'

const ReopenTicket = ({ ticketId, refresh }) => {
  const snackbar = useSnackbar()

  const handleReopenTicket = async () => {
    try {
      await service.dponet.tickets.reopenTicket({
        ticketId,
      })
      snackbar.open({
        message: 'Ticket foi reaberto!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }

  const handleCloseTicket = async () => {
    try {
      await service.dponet.tickets.closeTicket({
        ticketId,
      })
      snackbar.open({
        message: 'Ticket foi encerrado!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="start"
      mt={2}
      flexDirection="column"
      maxWidth="480px"
    >
      <Typography variant="h5" color="primary">
        O ticket está prestes a ser finalizado. A dúvida foi esclarecida?
      </Typography>
      <Box
        display="flex"
        justifyContent="flex-start"
        style={{ gap: '8px' }}
        mt={2}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleReopenTicket()}
        >
          Reabrir ticket
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCloseTicket()}
        >
          Encerrar ticket
        </Button>
      </Box>
    </Box>
  )
}

export default ReopenTicket
