import * as yup from 'yup'

const schema = (unitary = false) => {
  return yup.object().shape({
    ...(unitary && {
      departmentId: yup.string().required(),
      dataProcess: yup
        .object()
        .when('departmentId', {
          is: true,
          then: yup.object().shape({
            id: yup.number().required(),
            name: yup.string().required(),
          }),
        })
        .typeError('Selecione uma opção'),
    }),
  })
}
export default schema
