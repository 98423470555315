import React, { useEffect, useState } from 'react'
import { Alert } from '@material-ui/lab'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core'
import { flatMap, isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

import { BalancingSafeguarding, Finality, Necessity } from './components'
import StepperLia from '../Edit/StepperLia/StepperLia'

import useFetch from 'hooks/useFetch'
import useDataProcess from 'hooks/useDataProcess'

import constants from 'constants/index'
import useStyles from './styles'
import * as service from 'service'
import { routes } from 'Routes'

const LegitimateInterest = ({
  dataProcess = {},
  show = false,
  statusPendingLia = false,
}) => {
  const [showAlertLia, setShowAlertLia] = useState(false)
  const [messageAlertLia, setMessageAlertLia] = useState('')
  const [activeStep, setActiveStep] = useState(0)

  const history = useHistory()

  const classes = useStyles()

  const { loadingReplyLiaAnswer } = useDataProcess()

  useEffect(() => {
    if (!!dataProcess) {
      dataProcessLegalFrameworkType(dataProcess?.legalFrameworks)
    }
  }, [dataProcess])

  const { DISAPPROVED, PENDING, REVIEW_DISAPPROVED, APPROVED, REVIEW_DPO } =
    constants.liaAnswers.STATUSES
  const { BALANCING_SAFEGUARING_STEPS, FINALITY_STEPS, ROLES, KINDS_ARRAY } =
    constants.liaQuestions
  const {
    DISAPPROVED_LIA,
    PENDING_LIA,
    REVIEW_LIA_CLIENT,
    APPROVED_STATUS,
    REVIEW_LIA_DPO,
  } = constants.dataProcess

  const dataProcessLegalFrameworkType = (legalFrameworks) => {
    const {
      FRAUD_PREVENTION_HOLDER_SECURITY,
      LEGITIMATE_INTEREST,
      LEGITIMATE_INTEREST_SHORTNED,
    } = constants.legalFrameworks

    const legalFrameworksNames = legalFrameworks.map(
      (legalFrameworks) => legalFrameworks.name,
    )

    if (
      !legalFrameworksNames.includes(FRAUD_PREVENTION_HOLDER_SECURITY) &&
      !legalFrameworksNames.includes(LEGITIMATE_INTEREST)
    ) {
      return
    }

    if (
      legalFrameworksNames.includes(FRAUD_PREVENTION_HOLDER_SECURITY) &&
      legalFrameworksNames.includes(LEGITIMATE_INTEREST)
    ) {
      setShowAlertLia(true)
      return setMessageAlertLia(
        `${LEGITIMATE_INTEREST_SHORTNED} e ${FRAUD_PREVENTION_HOLDER_SECURITY}`,
      )
    }

    if (legalFrameworksNames.includes(FRAUD_PREVENTION_HOLDER_SECURITY)) {
      setShowAlertLia(true)
      return setMessageAlertLia(FRAUD_PREVENTION_HOLDER_SECURITY)
    }

    setShowAlertLia(true)
    setMessageAlertLia(LEGITIMATE_INTEREST_SHORTNED)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const DATA_PROCESS_REVIEW_OR_APPROVED_STATUS = [
    REVIEW_LIA_DPO,
    APPROVED_STATUS,
  ].includes(dataProcess?.statusId)

  const handleBack = () => {
    if (activeStep === 0) {
      return history.push(routes.dataProcess.all)
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const {
    response: liaQuestionsWithAnswersResponse,
    isLoading: liaQuestionsLoading,
  } = useFetch(
    service.dponet.liaQuestions.getWithAnswers,
    {
      dataProcessId: dataProcess.id,
    },
    [activeStep],
  )

  const {
    response: onlyQuestionsResponse,
    isLoading: loadingOnlyQuestionLoading,
  } = useFetch(
    service.dponet.liaQuestions.get,
    {
      kinds: KINDS_ARRAY,
      roles: DATA_PROCESS_REVIEW_OR_APPROVED_STATUS
        ? [ROLES.DPO]
        : [ROLES.CLIENT],
      perPage: 50,
    },
    [],
  )

  const onlyQuestions = onlyQuestionsResponse?.data?.liaQuestions

  const liaQuestions = liaQuestionsWithAnswersResponse?.data?.liaQuestions

  const filterPendingAnswers = () => {
    return liaQuestions?.filter((question) =>
      question.liaAnswers.some((answer) => answer.status === PENDING),
    )
  }

  const filterDisapprovedAnswer = () => {
    return liaQuestions?.filter((question) =>
      question.liaAnswers.some((answer) => answer.status === DISAPPROVED),
    )
  }

  const filterApprovedAnswers = () => {
    return liaQuestions?.filter((question) =>
      question.liaAnswers.some((answer) => answer.status === APPROVED),
    )
  }

  const filterReviewDisapprovedAnswer = () => {
    return liaQuestions?.filter((question) =>
      question.liaAnswers.some(
        (answer) => answer.status === REVIEW_DISAPPROVED,
      ),
    )
  }

  const filterReviewAndApprovedAnswers = () => {
    return dataProcess?.liaAnswers?.filter((answer) =>
      [REVIEW_DPO, APPROVED].includes(answer.status),
    )
  }

  const questionsDisapprovedAnswers = flatMap(filterDisapprovedAnswer())
  const questionsPendingAnswers = flatMap(filterPendingAnswers())
  const questionsApprovedAnswers = flatMap(filterApprovedAnswers())
  const questionsReviewDisapprovedAnswers = flatMap(
    filterReviewDisapprovedAnswer(),
  )
  const answersReviewAndApproved = flatMap(filterReviewAndApprovedAnswers())

  const whichStepsHave = () => {
    var liaAnswerStepFinalityStep = false
    var liaAnswersStepBalanceStep = false

    const finalityStep = liaQuestions?.filter((question) => {
      return FINALITY_STEPS.includes(question?.kind)
    })

    const balancingStep = liaQuestions?.filter((question) => {
      return BALANCING_SAFEGUARING_STEPS.includes(question?.kind)
    })

    if (!isEmpty(finalityStep)) {
      liaAnswerStepFinalityStep = finalityStep.some((question) => {
        return question.liaAnswers.some(
          (answer) => answer.status === DISAPPROVED,
        )
      })
    }

    if (!isEmpty(balancingStep)) {
      liaAnswersStepBalanceStep = balancingStep.some((question) => {
        return question.liaAnswers.some(
          (answer) => answer.status === DISAPPROVED,
        )
      })
    }

    const hasFinalityStep =
      liaAnswerStepFinalityStep && dataProcess?.statusId === DISAPPROVED_LIA
    const hasBalancingStep =
      liaAnswersStepBalanceStep && dataProcess?.statusId === DISAPPROVED_LIA

    return {
      hasFinalityStep,
      hasBalancingStep,
    }
  }

  const { hasFinalityStep, hasBalancingStep } = whichStepsHave()

  const getSteps = () => {
    if (DATA_PROCESS_REVIEW_OR_APPROVED_STATUS) {
      return ['Finalidade', 'Necessidade', 'Balanceamento e Salvaguarda']
    }
    if (
      dataProcess?.statusId === PENDING_LIA ||
      dataProcess?.statusId === REVIEW_LIA_CLIENT ||
      (hasFinalityStep && hasBalancingStep)
    ) {
      return ['Finalidade', 'Balanceamento e Salvaguarda']
    }
    if (hasFinalityStep) return ['Finalidade']
    if (hasBalancingStep) return ['Balanceamento e Salvaguarda']

    return []
  }

  const steps = getSteps()

  useEffect(() => {
    if (
      JSON.stringify(steps) === JSON.stringify(['Balanceamento e Salvaguarda'])
    ) {
      setActiveStep(1)
    }
  }, [steps])

  const showButtonFinish =
    activeStep === steps.length - 1 ||
    (!hasBalancingStep && dataProcess?.statusId === DISAPPROVED_LIA)

  const activeStepNumber = () => {
    let finalityStep = null
    let necessityStep = null
    let balancingStep = null

    if (DATA_PROCESS_REVIEW_OR_APPROVED_STATUS) {
      finalityStep = 0
      necessityStep = 1
      balancingStep = 2
    } else {
      finalityStep = 0
      balancingStep = 1
    }

    return { finalityStep, necessityStep, balancingStep }
  }
  const { finalityStep, balancingStep, necessityStep } = activeStepNumber()

  return (
    <>
      {!show &&
        (showAlertLia && statusPendingLia ? (
          <Alert variant="filled" severity="info" component={Box} mb={3}>
            <Typography variant="body1">
              {`Este processo foi enquadrado com ${messageAlertLia}.
              Nestes casos, a Autoridade Nacional de Proteção de Dados
              (ANPD) exige uma análise detalhada. Por favor, responda as
              perguntas abaixo:`}
            </Typography>
          </Alert>
        ) : (
          <Alert variant="filled" severity="info" component={Box} mb={3}>
            <Typography variant="body1">
              Algumas respostas foram reprovadas pelo DPO, por favor, responda
              novamente.
            </Typography>
          </Alert>
        ))}
      <Grid container>
        <Grid item xl={9} lg={9} md={8} xs={12}>
          {(liaQuestionsLoading || loadingOnlyQuestionLoading) && (
            <Box
              display="flex"
              width="100%"
              minHeight="200px"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )}
          {activeStep === finalityStep &&
            !liaQuestionsLoading &&
            !loadingOnlyQuestionLoading && (
              <Finality
                dataProcess={dataProcess}
                handleNext={handleNext}
                questionsPendingAnswers={questionsPendingAnswers}
                questionsDisapprovedAnswers={questionsDisapprovedAnswers}
                questionsApprovedAnswers={questionsApprovedAnswers}
                questionsReviewDisapprovedAnswers={
                  questionsReviewDisapprovedAnswers
                }
                answersReviewAndApproved={answersReviewAndApproved}
                hasBalancingStep={hasBalancingStep}
                questions={onlyQuestions}
                show={show}
              />
            )}
          {activeStep === necessityStep &&
            !liaQuestionsLoading &&
            !loadingOnlyQuestionLoading && (
              <Necessity
                handleNext={handleNext}
                questions={onlyQuestions}
                answersReviewAndApproved={answersReviewAndApproved}
              />
            )}
          {activeStep === balancingStep &&
            !liaQuestionsLoading &&
            !loadingOnlyQuestionLoading && (
              <BalancingSafeguarding
                dataProcess={dataProcess}
                questionsPendingAnswers={questionsPendingAnswers}
                questionsDisapprovedAnswers={questionsDisapprovedAnswers}
                questionsApprovedAnswers={questionsApprovedAnswers}
                answersReviewAndApproved={answersReviewAndApproved}
                questionsReviewDisapprovedAnswers={
                  questionsReviewDisapprovedAnswers
                }
                questions={onlyQuestions}
                show={show}
              />
            )}
        </Grid>
        <Grid item xl={3} lg={3} md={4} xs={12}>
          <StepperLia lastActiveStep={activeStep} steps={steps} show={show} />
          <Box p={1} display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={handleBack}
              className={classes.button}
            >
              VOLTAR
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form="lia_form"
              className={classes.button}
              disabled={loadingReplyLiaAnswer}
            >
              {showButtonFinish ? 'FINALIZAR' : 'AVANÇAR'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default LegitimateInterest
