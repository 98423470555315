import { colors, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  root: {
    padding: theme.spacing(2),
    outline: 'none',
  },
  fullPaperWidth: {
    width: '100%',
  },
  receiptTitleBox: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(2),
  },
  statusLabelAuthorized: {
    color: '#16A63C',
    backgroundColor: '#B4FFC8',
  },
  statusLabelRevoked: {
    color: '#DC3545',
    backgroundColor: '#FFE0E3',
  },
  formTitleBox: {
    backgroundColor: theme.palette.greenSnowFlurry,
  },
  buttonError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  consentDetailsContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gridGap: theme.spacing(3),
  },
  consentDetailsButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gridGap: theme.spacing(2),
    width: '100%',
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  consentDetailsButton: {
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  optionsButton: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    color: colors.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
}))

export default useStyles
