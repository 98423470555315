import constants from 'constants/index'

const loadDefaultDepartments = (entities = []) => {
  const formattedEntities = entities
    .filter(
      (entity) =>
        entity?.entityType !== constants.fragilityEntities.COMPANY_ENTITY_TYPE,
    )
    .map((entity) => ({
      id: entity?.departmentId ?? entity.id,
      name:
        entity?.entityType ===
        constants.fragilityEntities.DEPARTMENT_ENTITY_TYPE
          ? entity?.label
          : entity?.group,
      entityType: entity?.entityType,
    }))

  return formattedEntities
}

export default loadDefaultDepartments
