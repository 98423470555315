import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'

import helpers from 'helpers'

import useClipboard from 'hooks/useClipboard'
import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import { getBaseURL } from 'service/env'
import * as service from 'service'
import schema from './schema'
import theme from 'theme'

import styles from './styles'

const ConsentDetailsPublishSection = ({ consentForm, setLoading }) => {
  const snackbar = useSnackbar()
  const { copy } = useClipboard()
  const { control, errors, reset, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: { email: '' },
  })

  const useStyles = styles(errors)
  const classes = useStyles()
  const portalURL = getBaseURL('privacyPortal')

  const url = `${portalURL}/consent-forms?token=${consentForm?.token}`

  const handleCopyContent = () => copy(url)

  const onSubmit = async (data) => {
    try {
      setLoading(true)

      await service.dponet.consent.sendLink({
        token: consentForm?.token,
        ...data,
      })

      snackbar.open({
        message: 'Formulário compartilhado com sucesso!',
        variant: 'success',
      })
      reset()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(3)}
      id={constants.consent.DETAILS_DRIVER_STEP_2}
    >
      <Box>
        <Typography variant="h4">Publicar Formulário</Typography>
        <Typography variant="caption" color="secondary">
          Torne o seu formulário público, compartilhando o endereço eletrônico
          do formulário com os titulares de dados.
        </Typography>
      </Box>
      <TextField
        fullWidth
        label="Link para o formulário"
        value={url}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleCopyContent}
                className={classes.button}
              >
                Copiar
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <Box p={2} bgcolor={theme.palette.background.default}>
        <Typography variant="h6">Compartilhar por e-mail</Typography>
        <Typography>
          Indique um e-mail para receber um link para o formulário, podendo ser
          compartilhado com os titulares de dados
        </Typography>
      </Box>
      <form
        id="share-by-email-consent-details-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          as={
            <TextField
              fullWidth
              label="Compartilhar por e-mail"
              variant="outlined"
              error={!!errors?.email}
              helperText={<>{errors?.email?.message}</>}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={clsx(classes.buttonColor, classes.button)}
                    >
                      Enviar
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          }
          control={control}
          name="email"
        />
      </form>
    </Box>
  )
}

ConsentDetailsPublishSection.propTypes = {
  setLoading: PropTypes.func.isRequired,
  consentForm: PropTypes.object,
}

export default ConsentDetailsPublishSection
