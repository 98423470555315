import * as yup from 'yup'

const schema = yup.object().shape({
  incidentDate: yup
    .date()
    .required('Preencha a data do incidente')
    .typeError('Preencha a data do incidente'),
  incidentHour: yup
    .date()
    .required('Preencha a hora do incidente')
    .typeError('Preencha a hora do incidente'),
  affectedHolders: yup
    .number()
    .required()
    .typeError('Preencha esse campo corretamente'),
  nameController: yup.string().required(),
  emailController: yup.string().email().required(),
  documentController: yup.string().required(),
  phoneController: yup.string().required(),
  city: yup.string().required(),
  cep: yup.string().required(),
  state: yup.string().required(),
  treatmentHolder: yup
    .number()
    .typeError('Preencha esse campo corretamente')
    .required('Preencha esse campo corretamente.')
    .min(0, 'O valor deve ser maior de que 0'),
  nameCommissioner: yup.string().required(),
  emailCommissioner: yup.string().email().required(),
  documentCommissioner: yup.string().required(),
  phoneCommissioner: yup.string().required(),
  nameRepresentative: yup.string().required(),
  emailRepresentative: yup.string().email().required(),
  documentRepresentative: yup.string().required(),
  phoneRepresentative: yup.string().required(),
})

export default schema
