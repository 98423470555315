import constants from 'constants/index'
import theme from 'theme'

const consentFormStatusLabel = (status) => {
  return (
    (status &&
      constants.consent.CONSENT_FORM_STATUS_LABEL_TRANSLATION[status]) ||
    'N/A'
  )
}

const consentFormChipColor = (status) => {
  return (
    (status && constants.consent.CONSENT_FORM_STATUS_CHIP_COLOR[status]) ||
    theme.palette.secondary.light
  )
}

const legalFrameworkLabel = (id) => {
  return (id && constants.consent.LEGAL_BASE_LABEL_CONSENT[id]) || 'N/A'
}

export default {
  consentFormStatusLabel,
  consentFormChipColor,
  legalFrameworkLabel,
}
