import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

const DialogInformation = ({ title, description, open, setOpen }) => {
  const handleClose = () => setOpen(false)

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick>
      <DialogTitle>
        <Box>
          <Typography variant="h4">{title}</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box my={1}>
        <DialogContent>
          <Box>
            <Typography>{description}</Typography>
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Button type="button" variant="outlined" onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogInformation
