import React from 'react'
import { Box, Container, Typography } from '@material-ui/core'
import { Redirect } from 'react-router-dom'

import NewPasswordForm from './components/Form'

import routes from 'Routes'
import useStyles from './styles'

const NewPassword = ({ match }) => {
  const classes = useStyles()

  if (!match.params.reset_password_token) {
    return (
      <Redirect
        to={{
          pathname: routes.root,
          state: {
            error:
              'Você não pode acessar essa página para redefinir sua senha. Certifique-se de estar usando a URL correta.',
          },
        }}
      />
    )
  }

  const defaultValues = {
    password: '',
    passwordConfirm: '',
    resetPasswordToken: match.params.reset_password_token,
  }

  return (
    <Box className={classes.root}>
      <Container maxWidth="xs" fullWidth>
        <Typography variant="h4" color="textPrimary">
          Cadastre sua nova conta!
        </Typography>
        <Box className={classes.card}>
          <Box mt={3}>
            <NewPasswordForm defaultValues={defaultValues} />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default NewPassword
