import { colors } from '@material-ui/core'

const styles = (theme) => ({
  cardMain: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    background: theme.palette.primary.main,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardButton: {
    backgroundColor: theme.palette.primary.main,
    color: colors.common.white,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
  whiteTypography: {
    color: colors.common.white,
  },
  determinate: {
    borderRadius: '5px',
    height: '6px',
  },
  barColorPrimary: {
    backgroundColor: '#4CAF50',
  },
})

export default styles
