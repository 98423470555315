import palette from 'theme/palette'

import { fontSize, PADDING } from '../constants'

const text = ({
  pdf,
  sub,
  positionY,
  positionX = PADDING,
  textColor = palette.primary.main,
  customFontSize = fontSize.subtitle,
}) => {
  pdf.setFont('Raleway-Bold')
  pdf.setFontSize(customFontSize)
  pdf.setTextColor(textColor)
  pdf.text(sub, positionX, positionY, null, null, 'left')
}

export default text
