import React from 'react'
import PropTypes from 'prop-types'

import { Typography, Box } from '@material-ui/core'

import useStyles from '../../styles'

const CalcDaysToFree = ({ endDate }) => {
  const classes = useStyles()
  return (
    <Box display="flex" alignItems="center">
      <Box ml={1}>
        <Typography className={classes.number}>{endDate}</Typography>
      </Box>
      <Box ml={1}>
        <Typography>dias restantes</Typography>
      </Box>
    </Box>
  )
}

CalcDaysToFree.propTypes = {
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default CalcDaysToFree
