const styles = (theme) => ({
  buttonPayment: {
    backgroundColor: theme.palette.subscription.primary.main,
    color: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.subscription.primary.dark,
    },
  },
})

export default styles
