import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  pieChartBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    paddingBottom: 16,
  },
}))

export default styles
