import React from 'react'
import { Info as InfoIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'

import { BaseDialog } from 'components'
import BannerCode from '../BannerCode'

import theme from 'theme'

const SourceCodeDialog = ({ open, setOpen, bannerToken }) => {
  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Como instalar no Código Fonte"
      fullWidth
      dialogSize="md"
      closeButtonText="Fechar"
    >
      <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
          <Typography color={theme.palette.neutral.main} variant="h6">
            Instalação direta no Código Fonte:
          </Typography>
          <Box
            p={2}
            bgcolor={theme.palette.custom.light}
            borderRadius={1}
            display="flex"
            alignItems="center"
            gridGap={theme.spacing(1)}
          >
            <Box>
              <InfoIcon color={theme.palette.primary.main} />
            </Box>
            <Typography color="primary" mt={1}>
              É necessário você ou um especialista instalar o código HTML em seu
              site.
            </Typography>
          </Box>
          <Typography color={theme.palette.neutral.main} variant="body2">
            Vá até a seção de&nbsp;
            <strong>
              Instalação de Cookie e copie o código conforme mostrado abaixo.
            </strong>
          </Typography>
        </Box>
        <BannerCode bannerToken={bannerToken} />
        <Box>
          <Typography variant="body2" color={theme.palette.neutral.main}>
            Agora, cole no corpo/body do seu site.
          </Typography>
          <Typography variant="body2" color={theme.palette.neutral.main}>
            <strong>
              Lembre-se de salvar e publicar a alteração no seu site.
            </strong>
          </Typography>
        </Box>
      </Box>
    </BaseDialog>
  )
}

SourceCodeDialog.propTypes = {
  bannerToken: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default SourceCodeDialog
