import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Typography, Box } from '@material-ui/core'

const FreeTrialAlert = () => {
  return (
    <Box my={4}>
      <Alert variant="filled" severity="info">
        <AlertTitle>
          <Typography variant="h5">
            Desfrute gratuitamente da DPOnet.
          </Typography>
        </AlertTitle>
        <ul>
          <Box my={1}>
            <li>
              <Typography variant="h6">
                Aproveite os recursos da DPOnet para iniciar facilmente sua
                jornada de conformidade com a LGPD, de forma rápida e
                descomplicada.
              </Typography>
            </li>
          </Box>
          <li>
            <Typography variant="h6">
              Após o teste grátis por 30 dias, não haverá nenhum custo envolvido
              ao não contratar o serviço.
            </Typography>
          </li>
        </ul>
      </Alert>
    </Box>
  )
}

export default FreeTrialAlert
