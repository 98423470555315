const getItemsPerArray = (isMobile, isTablet, isLaptop) => {
  var itemsPerArray = ''

  if (isMobile) {
    itemsPerArray = 1
  } else if (isTablet) {
    itemsPerArray = 2
  } else if (isLaptop) {
    itemsPerArray = 3
  } else {
    itemsPerArray = 4
  }

  return itemsPerArray
}

export default getItemsPerArray
