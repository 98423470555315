import * as yup from 'yup'

export const schema = yup.object().shape({
  collectionDate: yup.string(),
  invoiceAmount: yup.string().max(9),
  billingEmails: yup
    .array()
    .of(
      yup.object({
        email: yup.string().email().max(255),
      }),
    )
    .required('Precisa ter pelo menos um email'),
})

export default schema
