import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: 10,
  },
  radioLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  checked: {
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
}))

export default styles
