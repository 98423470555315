import React from 'react'
import { Briefcase as BriefCaseIcon, User as UserIcon } from 'react-feather'
import { Grid, Typography, Box, Paper } from '@material-ui/core'
import PropTypes from 'prop-types'

import useAuth from 'hooks/useAuth'

import useStyles from './styles'

const WelcomeCard = ({ generalStats, ...rest }) => {
  const { user } = useAuth()
  const classes = useStyles()
  const stats = [
    {
      title: 'USUÁRIOS',
      value: generalStats?.quantityUsers,
      icon: <UserIcon size={30} />,
    },
    {
      title: 'DEPARTAMENTOS',
      value: generalStats?.quantityDepartments,
      icon: <BriefCaseIcon size={30} />,
    },
  ]

  return (
    <Paper className={classes.rootBox} variant="outlined" {...rest}>
      <Box display="flex" className={classes.welcomeBox}>
        <Box display="column" className={classes.welcomeText}>
          <Box>
            <Typography variant="h2">Olá, {user.name}! </Typography>
          </Box>
          <Box pt={1}>
            <Typography variant="h5">Seja bem-vindo(a).</Typography>
          </Box>
        </Box>
      </Box>
      <Grid container className={classes.statsBox}>
        {stats.map((stat, index) => (
          <Grid key={index} item md={6} xs={12}>
            <Box className={classes.statsContent}>
              <Box className={classes.iconBox}>{stat.icon}</Box>
              <Box p={1} display="column">
                <Typography
                  variant="body2"
                  color="secondary"
                  className={classes.text}
                >
                  {stat.title}
                </Typography>
                <Typography variant="h4" className={classes.number}>
                  {stat.value}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}

WelcomeCard.propTypes = {
  generalStats: PropTypes.object.isRequired,
}

export default WelcomeCard
