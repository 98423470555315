import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Card, Collapse, Divider, Typography } from '@material-ui/core'
import { ToggleButton } from 'components'

import useStyles from './styles'

const ResumeCard = ({
  children,
  collapsible,
  defaultExpanded,
  icon,
  title,
}) => {
  const Icon = icon
  const classes = useStyles()
  const [expanded, setExpanded] = useState(defaultExpanded)

  return (
    <Card>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        ml={2}
        my={1.5}
      >
        <Box display="flex" alignItems="center">
          <Box display="flex">
            <Icon className={classes.backgroundIcon} />
          </Box>
          <Box ml={2}>
            <Typography variant="h6">{title}</Typography>
          </Box>
        </Box>
        {collapsible && (
          <ToggleButton
            size={25}
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          />
        )}
      </Box>
      <Divider />
      <Collapse
        className={classes.collapse}
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>
    </Card>
  )
}

ResumeCard.propTypes = {
  children: PropTypes.node,
  collapsible: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  icon: PropTypes.any,
  title: PropTypes.string,
}

ResumeCard.defaultProps = {
  collapsible: false,
  defaultExpanded: false,
}

export default ResumeCard
