import React, { useEffect, useRef } from 'react'

import { Box, Grid, useMediaQuery } from '@material-ui/core'
import { CardCompanySize } from './components'

import useSubscription from 'hooks/useSubscription'
import useStyles from './styles'
import theme from 'theme'

const SelectCompanySize = () => {
  const classes = useStyles()
  const { isDemoSubscription, plans, selectedPlan, setSelectedPlan } =
    useSubscription()
  const scrollableBox = useRef(null)

  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  useEffect(() => {
    if (smallScreen) {
      scrollToCard(
        plans?.findIndex((value) => value?.name === selectedPlan?.name),
      )
    }
    //eslint-disable-next-line
  }, [scrollableBox?.current, selectedPlan])

  const scrollToCard = (index) => {
    const reference = scrollableBox?.current
    const xCoord = (reference?.scrollWidth / plans.length) * index
    if (smallScreen) {
      return reference?.scrollTo({
        left: xCoord,
        behavior: 'smooth',
      })
    }
  }

  const calculateGridSize = (screenSize) => {
    const plansLength = plans?.length || 1
    const defaultSize = 3
    if (screenSize === 'md') {
      if (plansLength > defaultSize) {
        return defaultSize
      }
      return 12 / plansLength
    }
    if (screenSize === 'xl') {
      return plansLength > defaultSize ? defaultSize : 4
    }
    return defaultSize
  }

  return (
    <Box
      mt={smallScreen ? 1.5 : 2.5}
      mb={smallScreen || isDemoSubscription ? 1 : 4}
      pb={1}
      sm={6}
      className={classes.cardScroll}
      ref={scrollableBox}
    >
      <Grid
        container
        spacing={2}
        {...(smallScreen && { wrap: 'nowrap' })}
        alignItems="center"
        className={classes.container}
      >
        {plans.map((plan, index) => (
          <Grid
            item
            xs={12}
            md={calculateGridSize('md')}
            xl={calculateGridSize('xl')}
            key={plan?.id}
            className={classes.cardWidth}
          >
            <CardCompanySize
              title={plan?.name}
              subTitle={plan?.segment?.name}
              price={plan?.price}
              selected={selectedPlan?.name === plan.name}
              handleSelect={() => {
                scrollToCard(index)
                setSelectedPlan(plan)
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default SelectCompanySize
