import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { TextField, Grid, Box, Button } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { Card } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import { routes } from 'Routes'
import * as service from 'service'
import schema from './schema'
import constants from 'constants/index'

const DepartmentsForm = ({
  availableUsers = [],
  department,
  formType = 'new',
  refresh = () => {},
  setLoading,
}) => {
  const history = useHistory()
  const snackbar = useSnackbar()

  const isNew = formType === 'new'

  const { control, handleSubmit, errors, reset } = useForm({
    validationSchema: schema({ isNew }),
    defaultValues: {
      name: department?.name ?? '',
      description: department?.description ?? '',
    },
  })

  const handleReturn = () => history.push(routes.department.all)

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      if (isNew) {
        await service.dponet.departments.create({ department: data })
        history.push(routes.department.all)
      } else {
        await service.dponet.departments.put({
          departmentId: department?.id,
          department: data,
        })
        refresh()
      }
      snackbar.open({
        message: `Departamento ${isNew ? 'criado' : 'atualizado'} com sucesso`,
        variant: 'success',
      })
      reset()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      reset(data)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(1)}
    >
      <Card title={isNew ? 'Novo departamento' : 'Edição de departamento'}>
        <Grid container spacing={2}>
          {constants.departments.TEXTFIELD_CONTROL_ITEMS.map((item, index) => (
            <Grid key={index} item xs={12}>
              <Controller
                control={control}
                name={item?.name}
                mode="onChange"
                as={
                  <TextField
                    label={item?.label}
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors?.[item?.name]}
                    helperText={errors?.[item?.name]?.message}
                    multiline={!!item?.multiline}
                    fullWidth
                  />
                }
              />
            </Grid>
          ))}
          {isNew && (
            <Grid item xs={12}>
              <Controller
                control={control}
                name="responsible"
                mode="onChange"
                onChange={([, data]) => data}
                as={
                  <Autocomplete
                    id="combo-box-user"
                    options={availableUsers}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id || value.id === 0
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Responsáveis"
                        error={!!errors.responsible}
                        helperText={errors?.responsible?.message}
                      />
                    )}
                    multiple
                  />
                }
              />
            </Grid>
          )}
        </Grid>
      </Card>
      <Box
        display="flex"
        justifyContent="flex-start"
        gridGap={theme.spacing(1)}
      >
        <Button variant="outlined" onClick={handleReturn}>
          Voltar
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Salvar
        </Button>
      </Box>
    </Box>
  )
}

DepartmentsForm.propTypes = {
  availableUsers: PropTypes.arrayOf(PropTypes.object),
  department: PropTypes.object,
  formType: PropTypes.oneOf(['new', 'edit']).isRequired,
  refresh: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
}

export default DepartmentsForm
