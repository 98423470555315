import React, { Fragment } from 'react'
import { Box, Divider, useMediaQuery } from '@material-ui/core'

import { ColorInput } from 'components'

import theme from 'theme'

const ColorCustomization = () => {
  const mdUp = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <Fragment>
      <Box
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
        width="100%"
      >
        <ColorInput controlName="primaryColor" label="Cor Primária" />
        <ColorInput controlName="secondaryColor" label="Cor Secundária" />
      </Box>
      {mdUp && <Divider orientation="vertical" variant="middle" />}
    </Fragment>
  )
}

export default ColorCustomization
