import React from 'react'

import { Box, Container } from '@material-ui/core'
import { Text } from '../'

const Certification = () => {
  return (
    <Container maxWidth="lg">
      <Box mb={4}>
        <Text color="primary" fontSize="7vh" variant="h1">
          Você concluiu a jornada de conceitos fundamentais!
        </Text>
      </Box>
    </Container>
  )
}

export default Certification
