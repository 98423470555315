import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography, Link, Checkbox, makeStyles } from '@material-ui/core'

import { getBaseURL } from 'service/env'

import styles from './styles'

const useStyles = makeStyles(styles)
const FormPolicy = ({ setPolicyAccept }) => {
  const classes = useStyles()

  const redirectToPolicy = () => {
    window.open(`${getBaseURL('website')}/politica-de-privacidade/`)
  }

  return (
    <Box display="flex" alignItems="center">
      <Checkbox
        color="primary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        onChange={(event) => setPolicyAccept(event.target.checked)}
      />
      <Typography>
        Li e concordo com o{' '}
        <Link className={classes.pointer} onClick={redirectToPolicy}>
          Aviso de privacidade
        </Link>
      </Typography>
    </Box>
  )
}

FormPolicy.propTypes = {
  setPolicyAccept: PropTypes.func,
}

FormPolicy.defaultProps = {
  setPolicyAccept: () => {},
}

export default FormPolicy
