import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import {
  Grid,
  Typography,
  Box,
  Link,
  CircularProgress,
} from '@material-ui/core'

import DocumentsTable from '../DocumentTable'

import useSnackbar from 'hooks/useSnackbar'

import useStyles from '../../styles'

const TomticketsDocuments = ({ dataDocument, setDataDocument }) => {
  const [confirmAddDocument, setConfirmAddDocument] = useState(false)
  const [loadingDocument, setLoadingDocument] = useState(false)
  const classes = useStyles()
  const snackbar = useSnackbar()

  useEffect(() => {
    if (!confirmAddDocument) {
      document.getElementById('documents-tomticket').value = ''
    }
  }, [confirmAddDocument])

  const onChangeCreate = async (e) => {
    e.persist()
    const files = e.target.files
    const arrayData = []

    if (files.length === 0) return

    try {
      setLoadingDocument(true)

      for (const file of files) {
        arrayData.push(file)
      }

      setDataDocument((documents) => [...documents, ...arrayData])
      setConfirmAddDocument(true)

      e.target.value = ''
    } catch (error) {
      snackbar.open({
        message: error?.response?.data?.message
          ? 'Sistema de atendimento está fora do ar, tente novamente mais tarde!'
          : 'Ocorreu um erro ao adicionar o(s) documento(s)!',
        variant: 'error',
      })
    }
    setLoadingDocument(false)
  }

  const handleDelete = (file) => {
    const filterDelete = dataDocument.filter(
      (document) => document.name !== file,
    )
    setDataDocument(filterDelete)
  }

  return (
    <>
      <Box>
        {!loadingDocument ? (
          <Box>
            <Typography variant="body1">
              Para anexar arquivos,{' '}
              <Link href="#" htmlFor="documents-tomticket" component="label">
                Clique aqui.
              </Link>
            </Typography>
            <Typography variant="caption">
              Arquivos suportados: xlsx, xls, imagens, doc, docx, ppt, pptx,
              txt, pdf
            </Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center">
            <CircularProgress className={classes.loadingButton} />
          </Box>
        )}
      </Box>
      <div>
        <input
          multiple
          type="file"
          id="documents-tomticket"
          accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
          style={{ display: 'none' }}
          onChange={onChangeCreate}
        />
      </div>
      <Grid container xs={12} item>
        {!isEmpty(dataDocument) && (
          <DocumentsTable
            dataDocument={dataDocument}
            handleDelete={handleDelete}
          />
        )}
      </Grid>
    </>
  )
}

TomticketsDocuments.propTypes = {
  dataDocument: PropTypes.arrayOf(PropTypes.object),
  setDataDocument: PropTypes.func,
}

TomticketsDocuments.defultProps = {
  dataDocument: [],
  setDataDocument: () => {},
}

export default TomticketsDocuments
