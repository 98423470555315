import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Divider, Paper, Typography } from '@material-ui/core'

import BannerHeadCard from '../BannerHeadCard'
import { SourceCodeDialog } from './components'

import useStyles from './styles'

import theme from 'theme'
import constants from 'constants/index'

const BannerInstallationCard = ({ bannerToken }) => {
  const [openSourceCodeDialog, setOpenSourceCodeDialog] = useState(false)

  const classes = useStyles()

  const handleOpenSourceDialog = () => setOpenSourceCodeDialog(true)
  const handleOpenTagManagerDialog = () =>
    window.open(constants.cookies.banner.LINK_DOCUMENTATION_TAG_MANAGER)

  return (
    <>
      <Paper
        variant="outlined"
        id={constants.cookies.banner.COOKIES_BANNER_SETTING_DRIVER_STEP_4}
      >
        <BannerHeadCard name="Instalação do Banner" stage="03" />
        <Divider />
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(2)}
        >
          <Box
            bgcolor={theme.palette.custom.light}
            borderRadius={1}
            display="flex"
            flexDirection="column"
            gridGap={theme.spacing(3)}
            p={2}
            textAlign="justify"
          >
            <Typography color="primary">
              Para instalar cookies, insira um script no corpo/body&nbsp;
              <strong>HTML</strong> do seu site ou use o&nbsp;
              <strong>Google Tag Manager</strong>, criando uma tag "HTML
              personalizado", inserindo o código JavaScript para o cookie,
              definindo acionadores e publicando as alterações; sempre informe
              os usuários conforme as normas de privacidade. Clique em um dos
              botões abaixo para saber mais:
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              gridGap={theme.spacing(3)}
              className={classes.buttonContainer}
            >
              <Box className={classes.buttonBox}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleOpenSourceDialog}
                >
                  Como instalar com HTML
                </Button>
              </Box>
              <Box className={classes.buttonBox}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleOpenTagManagerDialog}
                >
                  Como instalar com Tag Manager
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
      <SourceCodeDialog
        bannerToken={bannerToken}
        open={openSourceCodeDialog}
        setOpen={setOpenSourceCodeDialog}
      />
    </>
  )
}

BannerInstallationCard.propTypes = {
  bannerToken: PropTypes.string,
}

export default BannerInstallationCard
