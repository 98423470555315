import React, { useState, useEffect, useRef } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { Box, IconButton, Card, Typography } from '@material-ui/core'
import { HelpCircle as HelpCircleIcon, X as XIcon } from 'react-feather'

import { routes } from 'Routes'

import { DialogTomticket } from 'components'

import useStyles from './styles'

const PopUpRedirectMarketplace = ({
  handleOpenTomticketDialog,
  dialogTomticket,
  setDialogTomticket,
}) => {
  const classes = useStyles()
  const { pathname } = useLocation()

  const [openPopUp, setOpenPopUp] = useState(true)
  const nodeRef = useRef(null)

  const handleClosePopUp = () => setOpenPopUp(false)

  const showPopUp = () => {
    return matchPath(pathname, {
      path: [
        routes.dataProcess.all,
        routes.questionnaire.all,
        // routes.nonComplianceReport.all,
        routes.incidents.all,
      ],
      exact: true,
    })
  }

  useEffect(() => {
    if (showPopUp()) {
      if (!openPopUp) setOpenPopUp(true)
    } else {
      if (openPopUp) setOpenPopUp(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <CSSTransition
      in={openPopUp}
      nodeRef={nodeRef}
      timeout={300}
      classNames={{
        enter: classes.transitionEnter,
        enterActive: classes.transitionEnterActive,
        exit: classes.transitionExit,
        exitActive: classes.transitionExitActive,
      }}
      unmountOnExit
    >
      <Box className={classes.root} ref={nodeRef}>
        <Card
          variant="elevation"
          elevation={2}
          className={classes.card}
          onClick={handleOpenTomticketDialog}
        >
          <Box className={classes.iconBox}>
            <HelpCircleIcon className={classes.icon} />
          </Box>
          <Box className={classes.infoBox}>
            <Typography variant="body2" className={classes.title}>
              Está com dificuldade?
            </Typography>
            <Typography variant="caption" className={classes.body}>
              Um de nossos especialistas pode te ajudar!
            </Typography>
            <Typography variant="caption" className={classes.body}>
              Clique aqui e entre em contato!
            </Typography>
          </Box>
        </Card>

        <IconButton className={classes.closeIcon} onClick={handleClosePopUp}>
          <XIcon size={15} />
        </IconButton>

        <DialogTomticket open={dialogTomticket} setOpen={setDialogTomticket} />
      </Box>
    </CSSTransition>
  )
}

export default PopUpRedirectMarketplace
