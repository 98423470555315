import React, { useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'

import { Box, makeStyles } from '@material-ui/core'

import { Page, Container, LoadingFeedback } from 'components'

import * as service from 'service'
import useFetch from 'hooks/useFetch'

import helpers from 'helpers'
import styles from './styles'

import {
  CardsListSection,
  FooterSection,
  ForWhoSection,
  Section,
  ServicesSection,
  WhatIsSection,
} from './components'
import theme from 'theme'

const useStyles = makeStyles(styles)

const ServicesMain = ({ match }) => {
  const classes = useStyles()
  const ref = useRef()

  const { id } = match.params
  const { benefits, description, forWho, sectionTitle, whatIs } =
    helpers.marketplace.mountServiceData(id)

  const { response, isLoading } = useFetch(
    service.marketPlace.partners.getAll,
    { companyServiceId: id },
  )

  const services = response?.data?.partnerServices

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
      }
    }, 400)

    return () => clearTimeout(timer)
  }, [])

  return (
    <Page title="Marketplace - Serviços" ref={ref}>
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false} className={classes.container}>
        <Box className={classes.sectionContainer}>
          <Section title={sectionTitle} subtitle={description} />
        </Box>
        <Box bgcolor="white">
          <WhatIsSection
            description={whatIs.text}
            sectionTitle={whatIs.primaryText}
            icons={whatIs.icons}
          />
          <ForWhoSection
            description={forWho.text}
            sectionTitle={sectionTitle}
            cover={forWho.cover}
            coverText={forWho.coverText}
            benefitsList={forWho.benefitsList}
          />
          <CardsListSection
            title="Benefícios disponíveis"
            listItems={benefits}
            bgColor="transparent"
            primaryColor={theme.palette.black}
            cardWithoutAction
            iconsSize={84}
          />
          {!isEmpty(services) && <ServicesSection services={services} />}
          <FooterSection categoryService={sectionTitle} />
        </Box>
      </Container>
    </Page>
  )
}

export default ServicesMain
