import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  detailsContainer: {
    border: '1px solid',
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.primary.main,
    borderRadius: 4,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gridGap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  urlTypography: {
    width: 'fit-content',
    wordBreak: 'break-all',
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 300,
    },
  },
}))

export default styles
