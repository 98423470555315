import React, { useState, useRef } from 'react'
import clsx from 'clsx'
import { Box, Button, Card, Divider, Grid, Typography } from '@material-ui/core'

import useStyles from './styles'

import privacyPortalSeal from 'images/selo_site.png'

const Seal = ({ title, htmlTag, color }) => {
  const [copySuccess, setCopySuccess] = useState(false)
  const classes = useStyles()

  const textAreaRef = useRef(null)
  const copyToClipboard = (element) => {
    textAreaRef.current.select()
    document.execCommand('copy')
    element.target.focus()
    setCopySuccess(true)
  }

  const correctSealImage = () => {
    return privacyPortalSeal
  }

  return (
    <Grid item xs={12}>
      <Box mb={2}>
        <Card>
          <Grid item xs={12}>
            <Box pl={2} mb={2} mt={2}>
              <Typography variant="h6" color="textPrimary">
                {title}
              </Typography>
            </Box>
            <Box mb={2}>
              <Divider />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              mx={2}
              my={1}
              p={1}
              display="inline-flex"
              className={clsx({
                [classes.sealWhite]: color === 'white',
              })}
            >
              <img src={correctSealImage()} alt="Selo" />
            </Box>
          </Grid>
          <Box p={2} mt={1} mx={2} className={classes.scriptBox}>
            <textarea
              className={classes.customTextArea}
              defaultValue={htmlTag}
              ref={textAreaRef}
              readOnly
            />
          </Box>
          <Grid item xs={12}>
            <Box px={2} my={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={copyToClipboard}
              >
                {copySuccess ? 'Copiado' : 'Copiar'}
              </Button>
            </Box>
          </Grid>
        </Card>
      </Box>
    </Grid>
  )
}

export default Seal
