import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  groupTypography: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  groupButton: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '35px',
    alignItems: 'center',
  },
  button: {
    width: '325px',
    borderRadius: '29px',
    fontSize: 18,
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.common.white,
    },
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.primary,
    textTransform: 'none',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  logoContainer: {
    width: '280px',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '55%',
      maxWidth: '240px',
    },
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  outlined: {
    border: '2px solid',
    borderColor: theme.palette.common.white,
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginTop: '10px',
  },
  text: {
    fontSize: 30,
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    lineHeight: 1.2,
  },
  textWelcome: {
    fontSize: 50,
    paddingBottom: '30px',
  },
  textQuestion: {
    padding: '30px',
  },
  highlight: {
    backgroundColor: theme.palette.subscription.warning.success,
  },
}))

export default styles
