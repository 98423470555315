import React from 'react'
import clsx from 'clsx'
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import {
  ChevronDown as ChevronDownIcon,
  Check as CheckIcon,
} from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'

import helpers from 'helpers'
import constants from 'constants/index'

import theme from 'theme'
import useStyle from './styles'
import { ButtonAction } from 'components'

const LevelCard = ({
  statsCard,
  conformityLevel,
  conformityLevelCompleted,
  expanded,
  setExpanded,
  setOpenNextLevelModal,
  setUnlockLevel,
  index,
}) => {
  const classes = useStyle()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleExpandClick = (panel) => setExpanded(panel)

  const handleOpenNextLevelModal = () => {
    setUnlockLevel(true)
    setOpenNextLevelModal(true)
  }

  const isDisabled = () =>
    conformityLevel === constants.questionnaire.COMPLIANCE_LEVEL_NOT_STARTED

  const LevelIcon = ({ level }) => (
    <Box className={classes.boxIcon}>
      {(conformityLevel === level && conformityLevelCompleted) ||
      level < conformityLevel ? (
        <CheckIcon color={theme.palette.primary.main} />
      ) : (
        <Typography color="primary" variant="h6">
          {level}
        </Typography>
      )}
    </Box>
  )

  return isMobile ? (
    <Accordion
      className={classes.accordionLevel}
      key={index}
      expanded={expanded === index}
      onChange={() => handleExpandClick(index)}
    >
      <AccordionSummary expandIcon={<ChevronDownIcon color="white" />}>
        <LevelIcon level={index + 1} />
        <Typography variant="h6">{statsCard.title}</Typography>
      </AccordionSummary>
      <Box padding={2}>
        <Typography variant="h6">{statsCard.content}</Typography>
        <Box display="flex" justifyContent="end" alignItems="end">
          {conformityLevel < index && (
            <Box mr={2} mt={2}>
              <Button
                variant="contained"
                onClick={handleOpenNextLevelModal}
                disabled={isDisabled()}
              >
                DESBLOQUEAR
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Accordion>
  ) : (
    <Card
      className={clsx(
        classes.card,
        helpers.questionnaires.getCardStyle(classes, expanded, index),
        {
          [classes.expandedCard]: expanded === index,
          [classes.disabled]: isDisabled(),
        },
      )}
      key={index}
      onClick={() => handleExpandClick(index)}
    >
      <CardContent>
        <Box display="flex" alignItems="center">
          <LevelIcon level={index + 1} />
          <Typography variant="h6">{statsCard.title}</Typography>
        </Box>
        <Box
          className={clsx(classes.details, {
            [classes.detailsOpen]: expanded === index,
          })}
        >
          <PerfectScrollbar>
            <Typography
              variant="h6"
              className={classes.contextText}
              align="justify"
            >
              {statsCard.content}
            </Typography>
          </PerfectScrollbar>
          <Box display="flex" justifyContent="end" alignItems="end">
            {conformityLevel <= index && (
              <Box mr={2}>
                <ButtonAction
                  color="default"
                  variant="contained"
                  disabled={isDisabled()}
                  action={handleOpenNextLevelModal}
                  name="DESBLOQUEAR"
                />
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default LevelCard
