import React from 'react'

import { Box } from '@material-ui/core'
import { Text } from '..'

import useStyles from './styles'

const VideoPage = ({ title, url }) => {
  const classes = useStyles()

  return (
    <Box width="100%" display="flex" flexDirection="column" mb={2}>
      <Text fontSize="7vh" variant="h4" gutterBottom>
        {title}
      </Text>
      <iframe
        title="Nomeacao e papel representante (simplificado)"
        src={url}
        frameborder="0"
        className={classes.video}
        allowFullScreen
        width="100%"
        allow={'picture-in-picture; fullscreen'}
      />
    </Box>
  )
}

export default VideoPage
