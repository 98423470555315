const dataGridTexts = {
  toolbarColumns: 'Colunas',
  toolbarFilters: 'Filtros',
  toolbarFiltersLabel: 'Ver Filtros',
  toolbarFiltersTooltipHide: 'Esconder Filtros',
  toolbarFiltersTooltipShow: 'Ver Filtros',
  toolbarFiltersTooltipActive: (count) => `${count} filtro(s) ativos`,
  filterPanelAddFilter: 'Adicionar Filtro',
  filterPanelDeleteIconLabel: 'Remover',
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Mostrar colunas',
  columnMenuFilter: 'Filtro',
  columnMenuHideColumn: 'Esconder',
  columnHeaderFiltersTooltipActive: (count) => `${count} filtro(s) ativo(s)`,
  columnHeaderFiltersLabel: 'Mostrar Filtros',
  columnHeaderSortIconLabel: 'Ordenar',
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} registros selecionados`
      : `${count.toLocaleString()} registro selecionado`,
  footerTotalRows: 'Total de Registros:',
  footerPaginationRowsPerPage: 'Registros por página: ',
}

export default dataGridTexts
