import React from 'react'
import {
  Box,
  Button,
  Grid,
  useMediaQuery,
  makeStyles,
  Container,
} from '@material-ui/core'

import { Approved, Disapproved, InternalError, Processing } from './components'
import { LoadingFeedback } from 'components'

import useSubscription from 'hooks/useSubscription'

import constants from 'constants/index'
import theme from 'theme'
import styles from './styles'

const useStyles = makeStyles(styles)
const Base = ({ children, action }) => {
  const isMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container maxWidth="md">
      <Grid container>
        <Grid item xs={12}>
          <Box {...(!isMd && { mt: 'calc(100vh/3.5)' })}>{children}</Box>
        </Grid>
        <Grid xs={12} item>
          {!!action && action}
        </Grid>
      </Grid>
    </Container>
  )
}

const PaymentFeedbackScreen = () => {
  const classes = useStyles()
  const { status, setStatus, setActiveSubStep } = useSubscription()
  const {
    WAITING_PAYMENT_STATUS,
    BILLET_NOT_PAID_STATUS,
    PAID_STATUS,
    INTERNAL_ERROR_STATUS,
    FAILURE_STATUS,
    CANCELED_STATUS,
    PRE_CAPTURED_STATUS,
  } = constants.preRegistrations

  if (!status) {
    return <LoadingFeedback open />
  }

  const handlePayment = () => {
    setStatus()
    setActiveSubStep(constants.preRegistrations.PAYMENT_STEP[1])
  }

  if (status === INTERNAL_ERROR_STATUS) {
    return (
      <Base>
        <InternalError />
      </Base>
    )
  }

  if ([FAILURE_STATUS, CANCELED_STATUS].includes(status)) {
    return (
      <>
        <Base
          action={
            <Box display="flex" justifyContent="end" mt={4}>
              <Button
                variant="contained"
                onClick={handlePayment}
                className={classes.buttonPayment}
              >
                Realizar Pagamento
              </Button>
            </Box>
          }
        >
          <Disapproved />
        </Base>
      </>
    )
  }

  if (status === PAID_STATUS) {
    localStorage.removeItem('@dponet-pre-registration')

    return (
      <Base>
        <Approved />
      </Base>
    )
  }

  if (
    [
      WAITING_PAYMENT_STATUS,
      BILLET_NOT_PAID_STATUS,
      PRE_CAPTURED_STATUS,
    ].includes(status)
  ) {
    return (
      <Base>
        <Processing />
      </Base>
    )
  }
}

export default PaymentFeedbackScreen
