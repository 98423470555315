import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'

import { Container, Page } from 'components'
import {
  AuditForm,
  AuditReportPdf,
  Header,
  ReportVersionExistsDialog,
  ReportVersionTable,
} from './components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import useStyles from './styles'
import constants from 'constants/index'
import helpers from 'helpers'

const AuditReport = () => {
  const [exportingPdf, setExportingPdf] = useState(false)
  const [refresher, setRefresher] = useState(false)
  const [reportVersionExistsOpen, setReportVersionExistsOpen] = useState(false)
  const [currentReportVersion, setCurrentReportVersion] = useState()
  const [isComplete, setIsComplete] = useState(true)
  const [companyAudits, setCompanyAudts] = useState([])
  const [loadingDataProcess, setLoadingDataProcess] = useState(false)

  const classes = useStyles()

  const snackbar = useSnackbar()

  const { audit } = constants.reports.DESCRIPTION

  const getDataProcesses = async () => {
    setLoadingDataProcess(true)
    try {
      const response = await service.dponet.companyAudits.getStats({})

      setCompanyAudts(response?.data?.companyAudits)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
    setLoadingDataProcess(false)
  }

  const { control, watch, errors, triggerValidation } = useForm({
    validationSchema: AuditForm.schema(!isComplete),
    defaultValues: {
      departmentId: '',
      dataProcess: null,
    },
  })

  const generatePdfAuditReport = async () => {
    if (!isComplete) await triggerValidation()
    if (!isEmpty(errors)) return

    if (isComplete) await getDataProcesses()

    if (!loadingDataProcess) {
      if (!!currentReportVersion?.id) {
        return setReportVersionExistsOpen(true)
      }
      setExportingPdf(true)
    }
  }

  const handleChange = (event) => {
    setIsComplete(event.target.value === 'complete')
  }

  useEffect(() => {
    if (exportingPdf && isEmpty(companyAudits)) {
      setExportingPdf(false)
      return snackbar.open({
        message: 'Não existem processos disponíveis para gerar esse relátorio',
        vaiant: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyAudits, exportingPdf])

  return (
    <Page className={classes.root} title="Relatório de Auditoria">
      <Container maxWidth={false} spacingXl={15} spacingLg={10} spacingMd={5}>
        <Box py={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Header
                type="audit"
                reportTour="audit"
                isLoading={loadingDataProcess}
                buttonAction={generatePdfAuditReport}
                disabled={!!exportingPdf}
                title={audit.title}
                description={audit.content}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                Escolha a versão do relatório:
              </InputLabel>
              <RadioGroup
                value={isComplete ? 'complete' : 'unitary'}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="complete"
                  control={<Radio classes={{ checked: classes.checked }} />}
                  className={classes.radioLabel}
                  label="Relatório Completo"
                />
                <FormControlLabel
                  value="unitary"
                  className={classes.radioLabel}
                  control={<Radio classes={{ checked: classes.checked }} />}
                  label="Relatório Simplificado"
                />
              </RadioGroup>
            </Grid>
            {!isComplete && (
              <Grid item xs={12}>
                <AuditForm.Form
                  control={control}
                  watch={watch}
                  errors={errors}
                  setCompanyAudts={setCompanyAudts}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ReportVersionTable
                currentReportVersion={currentReportVersion}
                kind={constants.reportVersions.KIND.AUDIT}
                refresher={refresher}
                setCurrentReportVersion={setCurrentReportVersion}
              />
            </Grid>
          </Grid>
        </Box>
        <AuditReportPdf
          companyAudits={companyAudits}
          exportingPdf={exportingPdf}
          shouldCreateVersion={!currentReportVersion?.id}
          refresh={() => setRefresher(!refresher)}
          setExportingPdf={setExportingPdf}
        />
        <ReportVersionExistsDialog
          currentReportVersion={currentReportVersion}
          exportAction={() => setExportingPdf(true)}
          open={reportVersionExistsOpen}
          setCurrentReportVersion={setCurrentReportVersion}
          setOpen={setReportVersionExistsOpen}
        />
      </Container>
    </Page>
  )
}
export default AuditReport
