import moment from 'moment'

import components from '../components'
import palette from 'theme/palette'
import liaQuestionsConstants from 'constants/liaQuestions'

const createSubTableReportLia = (
  pdf,
  lia_answer,
  padding,
  columnStyle,
  noOrder,
  dateNow,
) => {
  const { questionOrder, questionText, answer: rawAnswer } = lia_answer
  const { QUESTIONS_POSSIBLE_USE_DATA_TREATMENT, QUESTION_DATE } =
    liaQuestionsConstants.QUESTIONS_ORDERS

  const getQuestion = (noOrder, order, text) => {
    if (noOrder) return text

    return `${order + 1} - ${text}`
  }

  const formatAnswer = (order, answer) => {
    if (order === QUESTION_DATE) {
      return moment(answer).format('DD/MM/YYYY')
    } else if (QUESTIONS_POSSIBLE_USE_DATA_TREATMENT.includes(order)) {
      return answer === 'yes' ? 'SIM' : 'NÃO'
    }
    return answer
  }

  const question = getQuestion(noOrder, questionOrder, questionText)
  const answer = formatAnswer(questionOrder, rawAnswer)

  const afterDrawTable = () => {
    components.numberPage(pdf, palette.primary.main, true)
    components.lineWithDate(pdf, `Visão Geral | ${dateNow}`)
  }

  return components.table(
    pdf,
    null,
    [[question, answer]],
    pdf.autoTable.previous.finalY,
    0.5,
    padding,
    true,
    columnStyle,
    undefined,
    undefined,
    afterDrawTable,
  )
}

export default createSubTableReportLia
