const statusChipColor = (statusId, classes) => {
  switch (statusId) {
    case 1:
      return classes.onTime
    case 2:
      return classes.overdue
    case 3:
      return classes.finalized
    case 4:
      return classes.pending
    case 6:
      return classes.finalized
    case 7:
      return classes.substituted
    case 8:
      return classes.frozen
    case 9:
      return classes.inactive
    default:
      return ''
  }
}

export default statusChipColor
