import palette from 'theme/palette'
import { rectangleSize } from '../constants'

const addRectangle = (
  pdf,
  positionX,
  positionY,
  width,
  color,
  ratio,
  label,
  data,
  labelY = positionY + ratio(150) - 5,
  fontSize = 80,
  dataBold = true,
  fontColor = palette.white,
  labelColor = palette.white,
  splited = false,
) => {
  const RADIUS = ratio(20)

  pdf.setFillColor(color)
  pdf.roundedRect(
    positionX,
    positionY,
    width,
    rectangleSize.height,
    RADIUS,
    RADIUS,
    'F',
  )

  pdf.setFontSize(ratio(48))
  dataBold ? pdf.setFont('Raleway-Regular') : pdf.setFont('Raleway-Bold')
  pdf.setTextColor(labelColor)
  pdf.text(label, positionX + ratio(40), labelY, null, null, 'left')
  pdf.setFontSize(ratio(fontSize))
  dataBold ? pdf.setFont('Raleway-Bold') : pdf.setFont('Raleway-Regular')

  var dataSplited = pdf.splitTextToSize(data, width - 20)
  pdf.setTextColor(fontColor)

  pdf.text(
    dataSplited,
    positionX + ratio(40),
    splited ? labelY + ratio(90) : labelY + ratio(60),
    null,
    null,
    'left',
  )
}

export default addRectangle
