import React from 'react'
import { isNil } from 'lodash'
import { Container as ContainerMaterial } from '@material-ui/core'
import clsx from 'clsx'

import styles from './styles'

const Container = ({
  children,
  spacingXl = 8,
  spacingLg = 8,
  spacingMd = 8,
  spacingSm = 8,
  spacingXs = 2,
  ...rest
}) => {
  const useStyles = styles(
    spacingXl,
    spacingLg,
    spacingMd,
    spacingSm,
    spacingXs,
  )
  const classes = useStyles()

  return (
    <ContainerMaterial
      {...rest}
      className={clsx(classes.container, {
        [rest.className]: !isNil(rest.className),
      })}
    >
      {children}
    </ContainerMaterial>
  )
}

export default Container
