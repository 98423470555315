import api from 'service/api'
import * as service from 'service'

import auth from './auth'

const dponetAPI = api.create('dponet', false)

const validateCode = async (data) => {
  const validateCodeResponse = await dponetAPI.post('/2FA/validate', data)

  if (validateCodeResponse.status === 200) {
    const token = validateCodeResponse.data.authToken

    auth.setToken(token)

    const identifyResponse = await service.dponet.usersCompanies.identify()
    if (identifyResponse.data?.company)
      auth.setCompany(identifyResponse.data.company)
  }

  return validateCodeResponse
}

const resendCode = async (data) => {
  return await dponetAPI.post('/2FA/resend', data)
}

export default {
  validateCode,
  resendCode,
}
