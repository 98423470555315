import React from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { orderBy, isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

import { Questions, DpoReason } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useDataProcess from 'hooks/useDataProcess'

import * as service from 'service'
import constants from 'constants/index'
import { routes } from 'Routes'

const DisapprovedLia = ({
  dataProcess = {},
  questionsDisapprovedAnswers = [],
}) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const { setLoadingReplyLiaAnswer } = useDataProcess()

  const { LEGITIMATE_EXPECTATION, SAFEGUARDS_AND_MECHANISMS } =
    constants.liaQuestions
  const { DISAPPROVED } = constants.liaAnswers.STATUSES

  const filterAnswerLegitimateExpectation = (questions) => {
    return questions.filter((question) => {
      return question.kind === LEGITIMATE_EXPECTATION
    })
  }

  const filterAnswersSafeguardMechanisms = (questions) => {
    return questions.filter((question) => {
      return question.kind === SAFEGUARDS_AND_MECHANISMS
    })
  }

  const answersDisapprovedLegitimateExpectation = orderBy(
    filterAnswerLegitimateExpectation(questionsDisapprovedAnswers),
    'order',
  )

  const answersDisapprovedSafeguardMechanisms = orderBy(
    filterAnswersSafeguardMechanisms(questionsDisapprovedAnswers),
    'order',
  )

  const filterDisapprovedAnswers = (liaAnswers) => {
    return liaAnswers.filter((answer) => answer.status === DISAPPROVED)
  }

  const { control, errors, handleSubmit } = useForm()

  const onSubmit = async (data) => {
    setLoadingReplyLiaAnswer(true)
    var liaAnswers = []

    Object.keys(data).map((key) => {
      return liaAnswers.push({
        order: key.replace(/^question/, ''),
        answer: data[key],
      })
    })

    try {
      await service.dponet.liaAnswers.create({
        liaAnswers,
        dataProcessId: dataProcess.id,
        reviewDisapproved: true,
        reviewStatusLia: true,
      })
      history.push(routes.dataProcess.all)
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.error?.message ||
          'Erro ao responder as respostas para LIA!',
        variant: 'error',
      })
    } finally {
      setLoadingReplyLiaAnswer(false)
    }
  }

  return (
    <Box mt={2}>
      <form id="lia_form" onSubmit={handleSubmit(onSubmit)}>
        {!isEmpty(answersDisapprovedLegitimateExpectation) && (
          <Paper component={Box} padding={2}>
            <Box mb={2}>
              <Typography variant="h5">Legítima expectativa</Typography>
            </Box>
            {answersDisapprovedLegitimateExpectation?.map((question) => (
              <Box key={question?.id}>
                <Questions
                  order={question?.order}
                  text={question?.text}
                  control={control}
                  errors={errors}
                />
                {filterDisapprovedAnswers(question?.liaAnswers)?.map(
                  (answer) => (
                    <DpoReason answer={answer} key={answer?.id} />
                  ),
                )}
              </Box>
            ))}
          </Paper>
        )}
        {!isEmpty(answersDisapprovedSafeguardMechanisms) && (
          <Paper component={Box} padding={2} mt={2}>
            <Box mb={2}>
              <Typography variant="h5">
                Salvaguardas e mecanismos de opt-out e de oposição
              </Typography>
            </Box>
            {answersDisapprovedSafeguardMechanisms?.map((question) => (
              <Box key={question?.id}>
                <Questions
                  order={question?.order}
                  text={question?.text}
                  control={control}
                  errors={errors}
                />
                {filterDisapprovedAnswers(question?.liaAnswers)?.map(
                  (answer) => (
                    <DpoReason answer={answer} key={answer?.id} />
                  ),
                )}
              </Box>
            ))}
          </Paper>
        )}
      </form>
    </Box>
  )
}

export default DisapprovedLia
