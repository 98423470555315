import React from 'react'
import helpers from 'helpers'
import { Box, Tooltip, Typography } from '@material-ui/core'

const CardColumn = ({
  icon,
  title,
  subtitle,
  width = '50%',
  justifyContent = 'flex-start',
  ...rest
}) => (
  <Box
    width={width}
    display="flex"
    alignItems="center"
    p={1}
    justifyContent={justifyContent}
  >
    <Box px={1} py={2}>
      {icon}
    </Box>
    <Box maxWidth="87%">
      <Typography variant="subtitle2">{title}</Typography>
      <Box>
        <Tooltip title={subtitle} placement="bottom-start">
          <Typography color="secondary" {...rest}>
            {helpers.functions.capitalizeText(subtitle)}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  </Box>
)

export default CardColumn
