import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  scriptBox: {
    backgroundColor: colors.grey[200],
    borderRadius: '10px',
  },
  customTextArea: {
    width: '100%',
    border: 'none',
    backgroundColor: colors.grey[200],
    color: colors.grey[700],
    fontWeight: 700,
    outline: 'none',
    boxShadow: 'none',
    resize: 'none',
    height: '50px',
    overflow: 'auto',
    wordBreak: 'break-all',
  },
  sealWhite: {
    backgroundColor: '#E9E9E9',
    padding: theme.spacing(1),
    borderRadius: 10,
  },
}))

export default styles
