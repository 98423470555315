import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

import { LoadingButton } from 'components'
import { ConsentRevocationForm } from './components'

import useStyles from './styles'

const ConsentRevoactionDialog = ({ open, onClose, consent }) => {
  const [loading, setLoading] = useState(false)

  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Revogar Consentimento</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography className={classes.consentTitleLine}>
          {consent?.consentForm?.title}
        </Typography>
        <ConsentRevocationForm consent={consent} setLoading={setLoading} />
      </DialogContent>
      <Divider />
      <DialogActions p={2}>
        <Box display="flex" justifyContent="end" width="100%">
          <Button type="button" variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Box marginX={1}>
            <LoadingButton
              variant="contained"
              type="submit"
              color="primary"
              form="consent-revocation-form"
              text="Revogar"
              loading={loading}
            />
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

ConsentRevoactionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  consent: PropTypes.object,
}

export default ConsentRevoactionDialog
