import helpers from 'helpers'

const ticketInfo = (ticket) => [
  { label: 'Solicitante:', value: ticket?.userName },
  { label: 'CPF:', value: `${ticket?.userDocument}.***.***-**` },
  {
    label: 'Tipo de relação:',
    value: ticket?.info?.questionRelationshipSelf?.answer?.title,
  },
  { label: 'Email:', value: ticket?.userEmail },
  { label: 'Telefone:', value: ticket?.userPhone },
  {
    label: 'Criado em:',
    value: ticket?.createdAt && helpers.formatters.date(ticket?.createdAt),
  },
  {
    label: 'Prazo:',
    value: ticket?.companyDeadlineDate
      ? helpers.formatters.date(ticket?.companyDeadlineDate)
      : 'Não definido',
  },
]

export default ticketInfo
