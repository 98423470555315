const stylesForSocialMedia = (companyName, classes) => {
  const largeText = companyName?.length >= 60
  const MediumText = companyName?.length >= 24 && companyName?.length <= 59

  if (largeText) return classes.largeSocialMediaText
  if (MediumText) return classes.mediumSocialMediaText

  return classes.socialMediaText
}

export default stylesForSocialMedia
