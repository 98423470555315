import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { Box, TextField } from '@material-ui/core'

import schema from './schema'

const AddInviteAdvisor = ({ onSubmit, disabled, setDisabled, invite }) => {
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      email: invite?.email ?? '',
    },
  })

  useEffect(() => {
    if (disabled) setDisabled(false)
    //eslint-disable-next-line
  }, [disabled])

  return (
    <Box my={2}>
      <form onSubmit={handleSubmit(onSubmit)} id="inviteAdvisorForm">
        <Controller
          as={
            <TextField
              label="Email"
              type="email"
              color="primary"
              variant="outlined"
              error={!!errors.email}
              helperText={errors?.email?.message}
              fullWidth
            />
          }
          control={control}
          name="email"
          mode="onBlur"
        />
      </form>
    </Box>
  )
}

AddInviteAdvisor.propTypes = {
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  setDisabled: PropTypes.func,
  invite: PropTypes.object,
}

AddInviteAdvisor.defaultProps = {
  onSubmit: () => {},
  setDisabled: () => {},
  invite: {},
}

export default AddInviteAdvisor
