const NECESSARY_ID = 3
const ANALYTICAL_PERFORMANCE_ID = 5
const FUNCTIONALITY_ID = 6
const ADVERTISING_ID = 7
const UNKNOWN_ID = 10

const NECESSARY_LABEL = 'Necessários'
const ANALYTICAL_PERFORMANCE_LABEL = 'Analíticos ou de desempenho'
const FUNCTIONALITY_LABEL = 'Funcionalidade'
const ADVERTISING_LABEL = 'Publicidade'
const UNKNOWN_LABEL = 'Desconhecidos'

const SECURITY_STORAGE_TYPE = 'security_storage'
const ANALYTICAL_PERFORMANCE_TYPE = 'analytics_storage'
const FUNCTIONALITY_TYPE = 'functionality_storage'
const ADVERTISING_TYPE = 'ad_storage'

const VALID_CATEGORIES = [
  NECESSARY_ID,
  ANALYTICAL_PERFORMANCE_ID,
  FUNCTIONALITY_ID,
  ADVERTISING_ID,
]

const MENU_ITEMS = [
  {
    id: NECESSARY_ID,
    name: NECESSARY_LABEL,
    disabled: true,
  },
  {
    id: ANALYTICAL_PERFORMANCE_ID,
    name: ANALYTICAL_PERFORMANCE_LABEL,
  },
  {
    id: FUNCTIONALITY_ID,
    name: FUNCTIONALITY_LABEL,
  },
  {
    id: ADVERTISING_ID,
    name: ADVERTISING_LABEL,
  },
  {
    id: UNKNOWN_ID,
    name: UNKNOWN_LABEL,
  },
]

const STORAGE_TYPES = {
  [NECESSARY_ID]: SECURITY_STORAGE_TYPE,
  [ANALYTICAL_PERFORMANCE_ID]: ANALYTICAL_PERFORMANCE_TYPE,
  [FUNCTIONALITY_ID]: FUNCTIONALITY_TYPE,
  [ADVERTISING_ID]: ADVERTISING_TYPE,
}

const categories = {
  ADVERTISING_ID,
  ANALYTICAL_PERFORMANCE_ID,
  FUNCTIONALITY_ID,
  MENU_ITEMS,
  NECESSARY_ID,
  VALID_CATEGORIES,
  STORAGE_TYPES,
}

export default categories
