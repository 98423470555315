import { colors } from '@material-ui/core'

const styles = () => {
  return {
    stampBox: {
      backgroundColor: colors.grey[200],
      borderRadius: '10px',
    },
  }
}
export default styles
