import { colors } from '@material-ui/core'

const styles = (theme) => ({
  avatar: {
    backgroundColor: colors.common.white,
    color: theme.palette.primary.main,
    fontSize: 45,
    height: 175,
    width: 175,
    marginBottom: -95,
  },
  avatarImage: {
    padding: '10%',
    width: '100%',
  },
  avatarBadge: {
    position: 'absolute',
    right: '12%',
    bottom: '-90%',
    height: 45,
    width: 45,
    borderRadius: '50%',
  },
  badgeSize: {
    height: 45,
    width: 45,
  },
  indicated: {
    backgroundColor: theme.palette.statuses.pending,
  },
  refused: {
    backgroundColor: theme.palette.statuses.disapproved,
  },
  approved: {
    backgroundColor: theme.palette.statuses.approved,
  },
  inactive: {
    backgroundColor: theme.palette.statuses.inactive,
  },
})

export default styles
