import React, { useState, useEffect, useRef } from 'react'

import { Box, Typography, makeStyles } from '@material-ui/core'

import { FormCompany, FormPersonal } from '../'
import { CardRadio } from 'components'

import constants from 'constants/index'

import useSubscription from 'hooks/useSubscription'

import styles from './styles'
const useStyles = makeStyles(styles)

const FormTabs = ({ isLoading }) => {
  const classes = useStyles()

  const { currentKind, setCurrentKind, setValidForm } = useSubscription()
  const refContainerTab = useRef(null)

  useEffect(() => {
    setSelectedTabIndex(
      currentKind && currentKind === constants.preRegistrations.PERSONAL_KIND
        ? 1
        : 0,
    )
    setCurrentKind(currentKind || constants.preRegistrations.JURIDIC_KIND)
    handleScroll(currentKind || constants.preRegistrations.JURIDIC_KIND)
    setValidForm(false)
    // eslint-disable-next-line
  }, [])

  const tabs = [
    {
      value: constants.preRegistrations.JURIDIC_KIND,
      label: 'Pessoa Jurídica',
    },
    {
      value: constants.preRegistrations.PERSONAL_KIND,
      label: 'Pessoa Física',
    },
  ]
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const handleScroll = (tabValue) => {
    return refContainerTab?.current?.scrollTo({
      behavior: 'smooth',
      left: tabValue === constants.preRegistrations.PERSONAL_KIND ? 100 : 0,
    })
  }

  const handleChangeTab = (tab, index) => {
    setCurrentKind(tab.value)
    setSelectedTabIndex(index)
    handleScroll(tab.value)
  }

  const getCurrentTab = () => {
    return [<FormCompany />, <FormPersonal />][selectedTabIndex]
  }

  return (
    <Box className={classes.root} p={2}>
      <Box className={classes.tabContainer} ref={refContainerTab}>
        {tabs.map((tab, index) => (
          <Box mr={2} mb={1.5} key={tab?.value}>
            <CardRadio
              key={tab.value}
              checked={selectedTabIndex === index}
              alignX="center"
              action={() => {
                handleChangeTab(tab, index)
              }}
            >
              <Box p={2}>
                <Typography className={classes.title}>{tab.label}</Typography>
              </Box>
            </CardRadio>
          </Box>
        ))}
      </Box>
      {!isLoading && <Box mt={2}>{getCurrentTab()}</Box>}
    </Box>
  )
}

export default FormTabs
