import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { isEmpty, filter, uniqBy } from 'lodash'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Box,
  Button,
  Chip,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { MenuButton } from 'components'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { Edit as EditIcon, PlusCircle as PlusCircleIcon } from 'react-feather'
import { QuestionControlsDialog } from './components'
import { ConfirmationDialog } from 'views/CompanyFragilities/components'

import useSnackbar from 'hooks/useSnackbar'
import useCompanyFragility from 'hooks/useCompanyFragility'

import * as service from 'service'
import helpers from 'helpers'

import styles from './styles'

const QuestionControls = ({ setLoading }) => {
  const snackbar = useSnackbar()
  const classes = styles()

  const [destroyOpen, setDestroyOpen] = useState(false)
  const [openMeasuresDialog, setOpenMeasuresDialog] = useState(false)
  const [categories, setCategories] = useState([])
  const [securityMeasure, setSecurityMeasure] = useState([])

  const {
    companyFragility,
    measuresSelected,
    setMeasuresSelected,
    questionControls,
    setQuestionControls,
  } = useCompanyFragility()

  const loadCategories = async () => {
    if (isEmpty(categories)) {
      setLoading(true)
      const response = await service.dponet.questionControls.listCustomKinds()

      setCategories(uniqBy([...(response?.data?.questionControls ?? [])], 'id'))
      setLoading(false)
    }
  }

  const destroySecurityMeasure = async () => {
    try {
      setLoading(true)
      if (!!companyFragility?.id) {
        await service.dponet.companyFragilities.destroyQuestionControl({
          companyFragilityId: companyFragility.id,
          questionControlId: securityMeasure?.id,
        })
        setQuestionControls(
          filter(
            questionControls,
            (measure) => measure?.questionControlId !== securityMeasure?.id,
          ),
        )
      }
      setMeasuresSelected(
        filter(
          measuresSelected,
          (measure) => measure?.id !== securityMeasure?.id,
        ),
      )
      setSecurityMeasure()
      setDestroyOpen(false)
      snackbar.open({
        variant: 'success',
        message: 'Medida de segurança excluida com sucesso',
      })
      setLoading(false)
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
      })
    }
  }

  const handleConfirmDestroy = (securityMeasure) => {
    setSecurityMeasure(securityMeasure)
    setDestroyOpen(true)
  }

  const questionControlsByCategory =
    helpers.questionControls.controlByCategory(measuresSelected)
  const emptyMeasures = isEmpty(questionControlsByCategory)

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={2}
      >
        <Typography variant="h4">Medidas de segurança</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={emptyMeasures ? <PlusCircleIcon /> : <EditIcon />}
          onClick={() => setOpenMeasuresDialog(true)}
        >
          {emptyMeasures ? 'Adicionar medida' : 'Editar medidas'}
        </Button>
      </Box>
      {emptyMeasures && (
        <Typography variant="overline">
          Nenhuma medida de segurança foi adicionada
        </Typography>
      )}

      {questionControlsByCategory?.map((measureByCategory) => (
        <Paper
          variant="outlined"
          key={measureByCategory?.id}
          component={Box}
          mb={2}
        >
          <PerfectScrollbar options={{ useBothWheelAxes: true }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="75%">
                    <Box my={1}>{measureByCategory?.name}</Box>
                  </TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {measureByCategory?.questionControls?.map((questionControl) => (
                  <TableRow key={questionControl?.id}>
                    <TableCell width="75%">
                      <Box my={1}>{questionControl?.label}</Box>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip
                        title={helpers.questionControls.status(
                          questionControl?.status,
                        )}
                      >
                        <Chip
                          size="small"
                          className={helpers.questionControls.statusChipColor(
                            questionControl?.status,
                            classes,
                          )}
                          label={helpers.questionControls.status(
                            questionControl?.status,
                          )}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="right">
                      <MenuButton>
                        <Button
                          onClick={() => handleConfirmDestroy(questionControl)}
                        >
                          Excluir
                        </Button>
                      </MenuButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Paper>
      ))}
      <QuestionControlsDialog
        defaultSelected={measuresSelected}
        loadCategories={loadCategories}
        categories={categories}
        open={openMeasuresDialog}
        setOpen={setOpenMeasuresDialog}
      />
      <ConfirmationDialog
        open={destroyOpen}
        setOpen={setDestroyOpen}
        title="Você tem certeza que deseja excluir esta medida de segurança?"
        actionButtonText="Excluir"
        handleSave={destroySecurityMeasure}
        description='Caso queira vincular esta medida posteriormente, poderá fazer isso através do botão "Editar Medidas”.'
      />
    </>
  )
}

QuestionControls.propTypes = {
  setLoading: PropTypes.func,
}

export default QuestionControls
