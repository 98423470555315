import helpers from 'helpers'

const mountNecessityAndProportionalityParams = (data, dataLifeCycleId) => {
  let dataLifeCycle = {}

  Object.keys(data).map((key) => {
    let splitKey = key.split('-')
    let keyType = splitKey[0]
    let keyId = splitKey[1]

    if (keyType === 'dataLifeCycle' && +keyId === dataLifeCycleId) {
      return (dataLifeCycle = {
        necessary: helpers.formatters.convertBinaryToBool(data[key]),
      })
    }

    return key
  })

  return dataLifeCycle
}

export default mountNecessityAndProportionalityParams
