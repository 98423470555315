import React from 'react'
import PropTypes, { bool } from 'prop-types'

import { Simplified, Complete } from './components'

import usePermissions from 'hooks/usePermissions'

const IncidentsForm = ({ incident, complete, refresh }) => {
  const { isDpo } = usePermissions()

  const redirectForm =
    complete || incident?.simplifiedForm === false ? false : true

  return (
    <>
      {isDpo() || redirectForm ? (
        <Simplified incident={incident} />
      ) : (
        <Complete incident={incident} refresh={refresh} />
      )}
    </>
  )
}

IncidentsForm.propTypes = {
  incident: PropTypes.object,
  complete: bool,
}

IncidentsForm.defaultProps = {
  complete: false,
}

export default IncidentsForm
