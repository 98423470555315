import { isPlainObject, isBoolean, isNil } from 'lodash'
import constants from 'constants/index'

const formattedValue = function (value, key, t) {
  if (isNil(value)) return '-'

  if (isBoolean(value)) {
    if (value) {
      return 'Sim'
    } else {
      return 'Não'
    }
  }
  if (isPlainObject(value)) {
    let keys = Object.keys(value)
    if (keys.includes('name')) {
      return value.name
    }
    if (keys.includes('description')) {
      return value.description
    }
    return value[keys[0]]
  }

  if (key === 'entityType') {
    return t(`registerTypes.${value}`)
  }

  if (value === constants.sharedSoftware.COMPANY_ALLOCATION_TYPE) {
    return constants.sharedSoftware.COMPANY_ALLOCATION_LABEL
  }

  if (value === constants.sharedSoftware.THIRDY_ALLOCATION_TYPE) {
    return constants.sharedSoftware.THIRDY_ALLOCATION_LABEL
  }

  return value
}

export default formattedValue
