import constants from 'constants/index'

const eliminateNotEliminateTexts = (type, chosen) => {
  switch (type) {
    case 'lifeCycle':
      if (chosen === 'Eliminated') {
        return constants.nonComplianceReports.ELIMINATE_LIFE_CYCLES_TEXTS
      }
      return constants.nonComplianceReports.NOT_ELIMINATE_LIFE_CYCLES_TEXTS
    case 'collectedField':
      if (chosen === 'Eliminated') {
        return constants.nonComplianceReports.ELIMINATE_COLLECTED_FIELD_TEXTS
      }
      return constants.nonComplianceReports.NOT_ELIMINATE_COLLECTED_FIELD_TEXTS
    case 'dataTreatment':
      if (chosen === 'Eliminated') {
        return constants.nonComplianceReports.ELIMINATE_DATA_TREATMENT_TEXTS
      }
      return constants.nonComplianceReports.NOT_ELIMINATE_DATA_TREATMENT_TEXTS
    default:
      return { text: '-', textButton: '-' }
  }
}

export default eliminateNotEliminateTexts
